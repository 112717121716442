import { gql } from "graphql-request";

import type { GetOrganizationOwnersQuery } from "#shared/generated/graphql";
import { useGqlQuery } from "#shared/utils";

import { QUERY_KEYS } from "#organization/pages/consts";

export const OrganizationOwners = gql`
  query getOrganizationOwners($where: OrganizationMembershipBoolExp) {
    organizationMemberships(where: $where) {
      edges {
        node {
          user {
            name
            email
            id
          }
          role
          id
        }
      }
      totalCount
    }
  }
`;

export const useOrganizationOwners = () => {
  const findBy = {
    where: { role: { eq: "owner" } },
  };
  const { isError, isLoading, data, refetch } =
    useGqlQuery<GetOrganizationOwnersQuery>(
      [QUERY_KEYS.ORGANIZATION_OWNER, findBy],
      OrganizationOwners,
      findBy,
    );

  return {
    error: isError,
    loading: isLoading,
    data: data?.organizationMemberships?.edges,
    total: data?.organizationMemberships?.totalCount,
    refetch,
  };
};
