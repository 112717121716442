import { FiberManualRecord } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { type FC } from "react";

const FlexBox = styled(Box)({
  display: "flex",
});

type StatusContentProps = {
  status: boolean;
};

export const StatusContent: FC<StatusContentProps> = ({ status }) => {
  const accept = {
    color: "success",
    status: "OK",
  };
  const decline = {
    color: "error",
    status: "Block",
  };

  return (
    <FlexBox
      sx={{
        alignItems: "center",
      }}
    >
      <FiberManualRecord
        sx={{ fontSize: 30 }}
        color={status ? "success" : "error"}
      />
      <Typography
        sx={{
          color: `${status ? "green" : "red"}`,
        }}
      >
        {status ? accept.status : decline.status}
      </Typography>
    </FlexBox>
  );
};
