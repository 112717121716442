import { SvgIcon, type SvgIconProps } from "@mui/material";
import React, { type FC, forwardRef, type PropsWithRef } from "react";

export const DashboardIcon: FC<PropsWithRef<SvgIconProps>> = forwardRef(
  (props, ref) => (
    <SvgIcon {...props} ref={ref}>
      <path d="M17.5201 6.2401H2.48008C1.71008 6.2401 1.08008 5.6101 1.08008 4.8401V2.0001C1.08008 1.2301 1.71008 0.600098 2.48008 0.600098H17.5301C18.3001 0.600098 18.9301 1.2301 18.9301 2.0001V4.8401C18.9301 5.6101 18.3001 6.2401 17.5301 6.2401H17.5201ZM2.48008 1.4001C2.15008 1.4001 1.88008 1.6701 1.88008 2.0001V4.8401C1.88008 5.1701 2.15008 5.4401 2.48008 5.4401H17.5301C17.8601 5.4401 18.1301 5.1701 18.1301 4.8401V2.0001C18.1301 1.6701 17.8601 1.4001 17.5301 1.4001H2.48008Z" />
      <path d="M9.00008 19.4H2.48008C1.71008 19.4 1.08008 18.77 1.08008 18V8.65C1.08008 7.88 1.71008 7.25 2.48008 7.25H9.00008C9.77008 7.25 10.4001 7.88 10.4001 8.65V18C10.4001 18.77 9.77008 19.4 9.00008 19.4ZM2.48008 8.05C2.15008 8.05 1.88008 8.32 1.88008 8.65V18C1.88008 18.33 2.15008 18.6 2.48008 18.6H9.00008C9.33008 18.6 9.60008 18.33 9.60008 18V8.65C9.60008 8.32 9.33008 8.05 9.00008 8.05H2.48008Z" />
      <path d="M17.5203 12.56H12.7003C11.9303 12.56 11.3003 11.93 11.3003 11.16V8.65C11.3003 7.88 11.9303 7.25 12.7003 7.25H17.5203C18.2903 7.25 18.9203 7.88 18.9203 8.65V11.16C18.9203 11.93 18.2903 12.56 17.5203 12.56ZM12.7003 8.05C12.3703 8.05 12.1003 8.32 12.1003 8.65V11.16C12.1003 11.49 12.3703 11.76 12.7003 11.76H17.5203C17.8503 11.76 18.1203 11.49 18.1203 11.16V8.65C18.1203 8.32 17.8503 8.05 17.5203 8.05H12.7003Z" />
      <path d="M17.5203 19.3998H12.7003C11.9303 19.3998 11.3003 18.7698 11.3003 17.9998V15.4898C11.3003 14.7198 11.9303 14.0898 12.7003 14.0898H17.5203C18.2903 14.0898 18.9203 14.7198 18.9203 15.4898V17.9998C18.9203 18.7698 18.2903 19.3998 17.5203 19.3998ZM12.7003 14.8898C12.3703 14.8898 12.1003 15.1598 12.1003 15.4898V17.9998C12.1003 18.3298 12.3703 18.5998 12.7003 18.5998H17.5203C17.8503 18.5998 18.1203 18.3298 18.1203 17.9998V15.4898C18.1203 15.1598 17.8503 14.8898 17.5203 14.8898H12.7003V14.8898Z" />
    </SvgIcon>
  ),
);
