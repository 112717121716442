import { gql } from "graphql-request";
import type { UseQueryResult } from "react-query";

import type {
  GetOrgUsersQuery,
  UserOrgMembershipsQuery,
} from "#shared/generated/graphql";
import { useGqlQuery } from "#shared/utils";

import { useGridData, type DataGridHook } from "#organization/hooks";

import type { MembersPageData } from "./components/table";

import { QUERY_KEYS } from "../../../../consts";

const UsersQuery = gql`
  query getOrgUsers(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $orderBy: [UserOrderBy]
    $where: UserBoolExp
  ) {
    users(
      first: $first
      last: $last
      after: $after
      before: $before
      orderBy: $orderBy
      where: $where
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          email
          name
          userGroupMemberships {
            totalCount
          }
          organizationMemberships {
            edges {
              node {
                id
                role
                status
                end
              }
            }
          }
        }
      }
    }
  }
`;

type MembersGroupData = GetOrgUsersQuery["users"]["edges"][0]["node"];

type OrganizationMembershipStatus = "inactive" | "active";

const listedStatus: OrganizationMembershipStatus[] = ["active", "inactive"];

export const useMembersData: DataGridHook<{ edges: MembersPageData[] }> = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  enabled,
  filterVariables,
) => {
  const { query, ...other } = useGridData<MembersGroupData, MembersPageData>(
    QUERY_KEYS.SETTINGS_MEMBERS_QUERY_KEY,
    UsersQuery,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    filterVariables,
    ({
      id,
      name,
      userGroupMemberships,
      email,
      organizationMemberships: {
        edges: [
          { node: organizationMemberships } = {
            node: { role: "", status: "", id: "", end: null },
          },
        ],
      },
    }) => ({
      id,
      name,
      email,
      userGroupCount: userGroupMemberships.totalCount,
      role: organizationMemberships.role,
      status: organizationMemberships.status,
      organizationMembershipId: organizationMemberships.id,
      end: organizationMemberships.end,
    }),
    enabled,
    false,
  );

  // NOTE: quick fix - do not list members with end value
  // TODO: should be done in gql's "where"
  const filteredEdges: MembersPageData[] =
    query.data?.edges.filter(
      (data) =>
        data.organizationMembershipId &&
        data.status &&
        listedStatus.includes(data.status as OrganizationMembershipStatus) &&
        !data?.end,
    ) || ([] as MembersPageData[]);

  const filteredQuery: UseQueryResult<
    { edges: MembersPageData[]; totalCount: number | undefined },
    unknown
  >["data"] = {
    edges: filteredEdges,
    totalCount: query.data?.totalCount,
  };

  const filteredResult = {
    query: {
      ...query,
      data: filteredQuery,
    },
    ...other,
  } as ReturnType<DataGridHook<{ edges: MembersPageData[] }>>;

  return filteredResult;
};

export const orgMembershipGql = gql`
  query userOrgMemberships($where: OrganizationMembershipBoolExp) {
    organizationMemberships(where: $where) {
      totalCount
    }
  }
`;

export const useActiveOwnerMembership = () =>
  useGqlQuery<UserOrgMembershipsQuery>(
    ["orgMembership", "owners"],
    orgMembershipGql,
    {
      where: {
        role: { eq: "owner" },
        status: { eq: "active" },
      },
    },
  );
