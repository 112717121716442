import { Add, Search } from "@mui/icons-material";
import {
  Box,
  Divider,
  Fade,
  InputBase,
  Paper,
  Popper,
  Typography,
  ClickAwayListener,
  Button,
} from "@mui/material";
import { styled, useTheme } from "@mui/system";
import React, {
  type ChangeEventHandler,
  type FC,
  useState,
  type MouseEvent,
  type PropsWithChildren,
} from "react";

import type { FnTheme } from "../../../../../app-theme-provider/types";

const StyledPaper = styled(Paper)(() => ({
  boxShadow:
    "0px 0px 2px rgba(0, 0, 0, 0.1), 0px 3px 8px rgba(9, 32, 77, 0.12)",
}));

const ListRoot = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  "& a": {
    color: theme.palette.primary.main,
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(2),
}));

const HeaderDropdown = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(2, 1, 1, 2),
  minWidth: 300,
  "& p": {
    textTransform: "uppercase",
    fontWeight: 600,
    fontSize: "0.75rem",
  },
}));

export interface DropdownControl {
  id: string | undefined;
  open: boolean;
  dropdownElement: null | HTMLElement;
  togglePopper: (event: MouseEvent<HTMLElement>) => void;
  closePopper: () => void;
}

export const useDropdownSearchBox = () => {
  const [dropdownElement, setDropdownElement] = useState<null | HTMLElement>(
    null,
  );

  const open = Boolean(dropdownElement);
  const id = open ? "transitions-popper" : undefined;

  const togglePopper = (event: MouseEvent<HTMLElement>) => {
    setDropdownElement(dropdownElement ? null : event.currentTarget);
  };

  const closePopper = () => {
    setDropdownElement(null);
  };

  return {
    id,
    open,
    dropdownElement,
    togglePopper,
    closePopper,
  };
};

export type DropdownSearchBoxTypes = PropsWithChildren<{
  idDropdown: string | undefined;
  isOpen: boolean;
  anchorEl: null | HTMLElement | (() => HTMLElement);
  dropdownHeaderName: string;
  inputOnChange: ChangeEventHandler<HTMLInputElement>;
  defaultInputValue: string;
  onClickAwayHandler: () => void;
  onClickOpenDialog?: () => void;
  inviteMembers?: boolean;
}>;

export const DropdownSearchBox: FC<DropdownSearchBoxTypes> = ({
  idDropdown,
  isOpen,
  anchorEl,
  dropdownHeaderName,
  inputOnChange,
  children,
  defaultInputValue,
  onClickAwayHandler,
  onClickOpenDialog,
  inviteMembers = false,
}) => {
  const theme = useTheme() as unknown as FnTheme;

  return (
    <Popper
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [0, 4],
          },
        },
      ]}
      style={{ zIndex: theme.zIndex.popover }}
      placement="bottom-end"
      id={idDropdown}
      open={isOpen}
      anchorEl={anchorEl}
      transition
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={onClickAwayHandler}>
          <Fade {...TransitionProps} timeout={350}>
            <StyledPaper elevation={0}>
              <Box>
                <StyledInputBase
                  startAdornment={
                    <Search sx={{ mr: 2, color: "text.secondary" }} />
                  }
                  onChange={inputOnChange}
                  placeholder="Search"
                  defaultValue={defaultInputValue}
                />
              </Box>
              <Divider />
              <HeaderDropdown>
                <Typography
                  sx={{
                    color: "primary.main",
                  }}
                  variant="body2"
                >
                  {dropdownHeaderName}
                </Typography>
                {inviteMembers && (
                  <Button
                    startIcon={<Add />}
                    variant="outlined"
                    size="small"
                    onClick={onClickOpenDialog}
                  >
                    Invite member
                  </Button>
                )}
              </HeaderDropdown>
              <Divider />
              <ListRoot>{children}</ListRoot>
            </StyledPaper>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
};

DropdownSearchBox.defaultProps = {
  onClickOpenDialog: undefined,
  inviteMembers: false,
};
