import { Warning } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useMemo, type FC } from "react";

import { useBillingPageInfo } from "#shared/billing";
import { useAlert } from "#shared/components/alerts-provider";
import { LoggerService } from "#shared/services";
import { FEATURE_FLAGS } from "#shared/utils";

import { useOrganizationState } from "#organization/recoil/organization";

import { useCreateProjectSubscription } from "../create-project-dialog/hooks";

type ProjectSubscriptionStatusProps = {
  projectID: string;
};

export const ProjectSubscriptionStatus: FC<ProjectSubscriptionStatusProps> = ({
  projectID,
}) => {
  const { id } = useOrganizationState();

  const {
    data: subscriptionInfo,
    isLoading: isLoadingBillingInfo,
    isError: isErrorBillingInfo,
  } = useBillingPageInfo({ organization_id: id });

  const subscriptionStatus = useMemo(
    () =>
      subscriptionInfo?.data.list.find(
        (sub) => sub.subscription.cf_project_id === projectID,
      )?.subscription.status,
    [projectID, subscriptionInfo?.data.list],
  );

  const { createProjectSubscription } = useCreateProjectSubscription();

  const { addAlert } = useAlert();

  const onClick = () => {
    createProjectSubscription(projectID).catch((error) => {
      LoggerService.error("error", error);

      addAlert({
        type: "error",
        message: "Failed to create project subscription.",
      });
    });
  };

  if (
    subscriptionStatus != null ||
    isLoadingBillingInfo ||
    isErrorBillingInfo ||
    !FEATURE_FLAGS.globalBilling
  ) {
    return null;
  }

  return (
    <Tooltip title="Project subscription is pending">
      <IconButton
        color="warning"
        aria-label="checkout project subscription"
        onClick={onClick}
      >
        <Warning sx={{ fontSize: "18px" }} />
      </IconButton>
    </Tooltip>
  );
};
