import { Typography, Stepper, Step, Box, StepButton } from "@mui/material";
import React, { type FC } from "react";

import { Logo } from "./logo";

import {
  Steps,
  type Invitation,
  type UseCreatorView,
  type UseInvite,
} from "../hooks";
import {
  CreateProjectTab,
  CreateUserGroupTab,
  InviteYourTeamTab,
} from "../tabs";

export interface CreatorViewProps {
  emptyMember: Invitation;
  creator: UseCreatorView;
  invite: UseInvite;
}

const titles: { [key in Steps]: string } = {
  [Steps.CreateProject]: "Create a Project",
  [Steps.CreateUserGroup]: "Create a User Group",
  [Steps.InviteTeam]: "Invite Team Members",
};

const descriptions: { [key in Steps]: string } = {
  [Steps.CreateProject]:
    "Create the first project in your organization. Each project gets its own Aperture endpoint in the chosen region. Aperture SDKs inside your application connect with this endpoint for flow control decisions and caching. Projects also provide isolation of policies and analytics.",
  [Steps.CreateUserGroup]:
    "Create the first user group in your organization. User groups simplify the process of assigning permissions to users. You can assign user groups with read or write permissions to a project. All users in a user group will also inherit the same permissions.",
  [Steps.InviteTeam]:
    "Invite your team members to join this organization and the newly created user group.",
};

export const CreatorView: FC<CreatorViewProps> = ({ creator, invite }) => {
  const {
    activeStepIndex,
    activeStep,
    project,
    setProject,
    userGroupName,
    setUserGroupName,
    steps,
    onStepChange,
  } = creator;

  return (
    <>
      <Box display="grid" gridTemplateColumns="1fr 0.3fr">
        <Stepper
          nonLinear
          activeStep={activeStepIndex}
          sx={{ gridColumn: "1/4", mt: 2, mb: 6 }}
        >
          {steps.map((step, index) => (
            <Step key={step} completed={index < activeStepIndex}>
              <StepButton color="inherit" onClick={() => onStepChange(step)}>
                {step}
              </StepButton>
            </Step>
          ))}
        </Stepper>

        <Box gridColumn="1">
          <Typography variant="h3" pb={3} fontSize={34}>
            {titles[activeStep]}
          </Typography>
          <Typography color="text.secondary" minHeight={48}>
            {descriptions[activeStep]}
          </Typography>
        </Box>

        <Logo />
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="1fr"
        gridTemplateRows="1fr auto"
        mt={2}
      >
        <Box gridColumn="1" pt={5} height="100%">
          {activeStep === Steps.CreateProject && (
            <CreateProjectTab project={project} setProject={setProject} />
          )}

          {activeStep === Steps.CreateUserGroup && (
            <CreateUserGroupTab
              userGroupName={userGroupName}
              setUserGroupName={setUserGroupName}
            />
          )}

          {activeStep === Steps.InviteTeam && (
            <InviteYourTeamTab
              {...{
                invite,
              }}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
