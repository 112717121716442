import type {
  PortalCallbacks,
  PortalForwardOptions,
} from "@chargebee/chargebee-js-types";
import type { ValidationValueMessage } from "react-hook-form";

import { APP_PORTS } from "#shared/dev-config";
import { ENV } from "#shared/env";

import type { Texts } from "./types";

export type ApiLogoutUrl = IabLogoutUrl | GlobalLogoutUrl;

type Origin = typeof API_SERVICE_URL.origin;

export type IabLogoutUrl = `${Origin}/account/logout/iab`;
export type GlobalLogoutUrl = `${Origin}/account/logout`;
export type OrganizationLogoutUrl = `${Origin}/auth/account/logout`;

type OrganizationGlobalMap<G extends string, O extends string> = {
  GLOBAL: (isGlobalContext: boolean) => G;
  ORGANIZATION: O;
};

export type AuthnProvider =
  | "google"
  | "github"
  | "password"
  | "code"
  | "sso"
  | "session"
  | "email";

export const STATUS_CODES = {
  Ok: 200,
  BadRequest: 400,
  Unauthenticated: 401,
  NotFound: 404,
  Forbidden: 403,
  Conflict: 409,
  InternalServerError: 500,
} as const;

export const SIGN_IN_ROUTE = {
  TITLE: "Sign in",
  KEY: "sign-in",
  ABSOLUTE_PATH: "/sign-in",
  PARENT_PATH: "sign-in/*",
};

export const EMAIL_SENT_ROUTE = {
  TITLE: "Email sent",
  KEY: "email-sent",
  ABSOLUTE_PATH: "/email-sent",
  PARENT_PATH: "email-sent/*",
};

export const SSO_ROUTE = {
  TITLE: "SSO",
  KEY: "sso",
  ABSOLUTE_PATH: `${SIGN_IN_ROUTE.ABSOLUTE_PATH}/sso`,
};

export const GLOBAL_APP_ABSOLUTE_URL = `https://${ENV.VITE_APP_DOMAIN}${
  ENV.DEV ? `:${APP_PORTS.global}` : ""
}`;

export const QUERY_PARAMS = {
  SSO_TOKEN: "token",
  CUSTOM_TOKEN: "customToken",
  EMAIL: "email",
  INVITATION_TOKEN: "token",
  ERROR_MESSAGE: "errorMessage",
  DOMAIN_NAME: "domainName",
  MEMBERSHIP_ID: "orgId",
  ORGANIZATION_ID: "organizationId",
  REDIRECT_URL: "redirectUrl",
  AUTHN_PROVIDER: "authnProvider",
  USERS: "users",
  TENANT_ID: "tenantId",
  FIREBASE_API_KEY: "apiKey",
  FIREBASE_OOB_CODE: "oobCode",
  INBOUND_SAML_CONFIG_ID: "inboundSamlConfigId",
  DEFAULT_CONFIG_ID: "idpId",
  SIGN_IN_CONFIGS: "signInConfigs",
  ENABLED: "enabled",
};

export type FirebaseSignInMethod = "email" | "password";

export const AUTH_TYPES = {
  GOOGLE: "google",
  GITHUB: "github",
  SSO: "sso",
} as const;

export const LOCAL_STORAGE_KEYS = {
  signInWithEmail: "signInWithEmail",
};

export const IAB_PROXY_PATHNAME = "/iab-proxy";
export const IDP_PROXY_PATHNAME = "/idp-proxy";

export const IDP_SERVICE_URL = new URL(ENV.VITE_IDP_URL).origin;

export const API_SERVICE_URL = new URL(
  [ENV.BASE_URL.replace(/\/$/, ""), "api"].join("/"),
  // NOTE: fallback to current origin if BASE_URL itself does not contain an origin
  window.location.origin,
);

// Replaces http/https with ws/wss
export const API_SERVICE_URL_WS = new URL(
  API_SERVICE_URL.href.replace(/^http/, "ws"),
);

export const API_LOGOUT_URLS: OrganizationGlobalMap<
  IabLogoutUrl | GlobalLogoutUrl,
  OrganizationLogoutUrl
> = {
  GLOBAL: (isGlobalContext = true) =>
    isGlobalContext
      ? `${API_SERVICE_URL}/account/logout/iab`
      : `${API_SERVICE_URL}/auth/account/logout`,
  ORGANIZATION: `${API_SERVICE_URL}/auth/account/logout`,
};

export const API_ACCOUNT_URLS = {
  GLOBAL: (isGlobalContext = true) =>
    `${API_SERVICE_URL}${isGlobalContext ? "" : "/iab"}/account`,
  ORGANIZATION: `${API_SERVICE_URL}/auth/account`,
  TENANTS: {
    /**
     *
     * FluxNinja's custom authentication logic. See AUTHENTICATION.md in cloud.ui
     * @returns
     */
    CONFIG: (tenantId: string | null | undefined) =>
      tenantId
        ? [
            API_SERVICE_URL,
            "/auth",
            "/idp",
            "/tenants",
            `/${tenantId}`,
            "/configs",
          ].join("")
        : [API_SERVICE_URL, "/idp", "/configs"].join(""),
  },
  ALLOW_SIGN_IN_TO_ORG: `${API_SERVICE_URL}/auth/idp/allow-sign-in-to-org`,
};

export const API_SUBSCRIPTIONS_URL = {
  GLOBAL: `${API_SERVICE_URL}/billing/subscriptions`,
  ORGANIZATION: `${API_SERVICE_URL}/auth${IAB_PROXY_PATHNAME}/billing/subscriptions`,
};

export const HEALTH_STATUSES = {
  HEALTHY: "1-online",
  UNHEALTHY: "unhealthy",
  DISCONNECTED: "2-disconnected",
  ARCHIVED: "3-archived",
  DRAFT: "draft",
};

// TODO: Move those values to FNTheme (into the "shape" key)
export const DRAWER_WIDTH = 250;
export const DRAWER_HEIGHT = "100%";

export const FLUXNINJA = "FluxNinja";

export const EMAIL_PATTERN: ValidationValueMessage<RegExp> = {
  value:
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  message: "Invalid email",
};

export const URL_PATTERN: ValidationValueMessage<RegExp> = {
  value:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
  message: "Invalid URL",
};

export const EMAIL_REGEXP =
  /^[a-zA-Z\d.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z\d](?:[a-zA-Z\d-]{0,61}[a-zA-Z\d])?(?:\.[a-zA-Z\d](?:[a-zA-Z\d-]{0,61}[a-zA-Z\d])?)*$/;

export const MUI_DISABLED_CLASSNAME = "Mui-disabled";

export const QUERY_RETRY_COUNT = 3;

export type SharedText = "error";

export const sharedEnTexts: Texts<SharedText> = {
  error: "Something went wrong...",
};

export const ATOM_KEYS = {
  breadcrumbsInTopBar: "breadcrumbs-in-top-bar",
  breadcrumbsInFlyoutMenu: "breadcrumbs-in-flyout-menu",
  user: "user",
  headerSection: "headerSection",
  labelStatus: "labelStatus",
  nodes: "nodes",
  edges: "edges",
  organization: "organization",
  hoverNode: "hoverNode",
  hoverSignal: "hoverSignal",
  time: "time",
  theme: "theme",
  sidebar: "sidebar",
  flyout: "flyout",
  selectedUserGroup: "selectedUserGroup",
  projectContext: "projectContext",
  isUserLoading: "isUserLoading",
  isUserAuthenticated: "isUserAuthenticated",
  userEmail: "userEmail",
  membershipId: "membershipId",
  userHttpState: "userHttpState",
  userAuthnProviderState: "userAuthnProviderState",
  userErrorState: "userErrorState",
  setPartialUser: "setPartialUser",
  isLoadingHttp: "isLoadingHttp",
  isIdleHttp: "isIdleHttp",
  isSuccessHttp: "isSuccessHttp",
  errorHttp: "errorHttp",
  newUser: "newUser",
  firebaseUser: "firebaseUser",
  yamlEditor: "yamlEditor",
  firebaseErrorMessage: "firebaseErrorMessage",
};

export const BACKDROP_GRADIENT_LIGHT_MODE = `radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0.5) 0%, rgba(142, 196, 173, 0.5) 33.33%, rgba(255, 255, 255, 0.5) 66.67%, rgba(255, 255, 255, 0) 100%), linear-gradient(315deg, rgba(255, 255, 255, 0.2) 0%, rgba(242, 122, 64, 0.2) 50%, rgba(255, 255, 255, 0) 100%), #F6F5F3;`;
export const BACKDROP_GRADIENT_DARK_MODE = `linear-gradient(252.58deg, rgba(142, 196, 173, 0.2) -0.66%, rgba(142, 196, 173, 0.1) 31.58%, rgba(49, 45, 43, 0) 100%), radial-gradient(50% 50% at 50% 50%, #312D2B 0%, rgba(49, 45, 43, 0.8) 49.55%, rgba(49, 45, 43, 0.38) 100%), linear-gradient(135deg, rgba(49, 45, 43, 0.5) 0%, rgba(142, 196, 173, 0.5) 33.33%, rgba(49, 45, 43, 0.5) 66.67%, rgba(49, 45, 43, 0) 100%), linear-gradient(315deg, rgba(49, 45, 43, 0.8) 0%, rgba(242, 122, 64, 0.8) 50%, rgba(49, 45, 43, 0) 100%), #312D2B;`;

export const BASE_DOCUMENTATION_URL = "https://docs.fluxninja.com";

export const SUPPORT_EMAIL = "support@fluxninja.com";

export const DEFAULT_CHARGEBEE_OPEN_PORTAL_OPTIONS: PortalCallbacks = {
  close: () => {},
  loaded: () => {},
  visit: () => {},
  paymentSourceAdd: () => {},
  paymentSourceUpdate: () => {},
  paymentSourceRemove: () => {},
  subscriptionChanged: () => {},
  subscriptionCustomFieldsChanged: () => {},
  subscriptionCancelled: () => {},
  subscriptionResumed: () => {},
  subscriptionPaused: () => {},
  scheduledPauseRemoved: () => {},
  scheduledCancellationRemoved: () => {},
  subscriptionReactivated: () => {},
};

export const DEFAULT_CHARGEBEE_OPEN_PORTAL_FORWARD_OPTIONS: PortalForwardOptions =
  {
    sectionType: "",
    params: {
      subscriptionId: "",
      paymentSourceId: "",
    },
  };
