import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  type Breakpoint,
  type DialogProps,
  Box,
} from "@mui/material";
import type { ButtonProps } from "@mui/material/Button";
import type { ModalProps } from "@mui/material/Modal";
import { styled } from "@mui/system";
import React, {
  useState,
  type FC,
  type ReactElement,
  type ReactNode,
  Fragment,
} from "react";

import type { Texts } from "#shared/types";

// TODO: Do it in theme overrides
const DialogActionStyled = styled(DialogActions)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(3),
  borderTop: `1px solid ${theme.palette.divider}`,
  display: "grid",
  gridTemplateColumns: "1fr auto",
}));

export interface DialogControl {
  open: () => void;
  close: () => void;
  isOpen: boolean;
}

export const useControlDialog = () => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    open,
    close,
  };
};

/**
 * TODO: 1. take confirm text from 'texts'
 * TODO: 2.  Move  cancel and confirm buttons to actions - more generic approach,  shorter code, we should be able to pass all props to button (like "disable")
 */
export type DialogLayoutProps = {
  dialogTitle: string | ReactElement;
  dialogContentText?: string | ReactElement;
  onCloseDialog?: ModalProps["onClose"];
  openDialog: ModalProps["open"];
  onClickDialogClose?: () => void;
  onConfimrDialog?: () => void;

  buttonConfirmText?: string;
  buttonConfirmClass?: string;
  buttonConfirmVariant?: ButtonProps["variant"];
  buttonConfirmColor?: ButtonProps["color"];
  buttonConfirmDisabled?: boolean;

  buttonCancelClass?: string;
  buttonCancelVariant?: ButtonProps["variant"];
  buttonCancelColor?: ButtonProps["color"];
  buttonCancelDisabled?: boolean;

  maxWidth?: Breakpoint;
  actions?: Action[];
  dialogAction?: boolean;

  cancelButtonProps?: ButtonProps;
  confirmButtonProps?: ButtonProps;

  texts?: Texts<Text>;

  // TODO Do not omit maxWidth, open and onClose. Just remove those props from the interface, and pass them always within the dialogProps
  dialogProps?: Omit<DialogProps, "maxWidth" | "open" | "onClose" | "children">;
};

type Action = { id: string; node: ReactNode };

type Text = "cancel";

const enTexts = { cancel: "Cancel" };

export const DialogLayout: FC<ButtonProps & DialogLayoutProps> = ({
  dialogTitle,
  dialogContentText = "",
  children,
  openDialog,

  onCloseDialog,
  onClickDialogClose,
  onConfimrDialog,

  buttonConfirmText,
  buttonConfirmClass,
  buttonConfirmVariant = "contained",
  buttonConfirmColor = "primary",

  buttonCancelClass,
  buttonCancelVariant = "text",
  buttonCancelColor = "secondary",
  buttonCancelDisabled,

  maxWidth = "sm",
  dialogAction = true,
  buttonConfirmDisabled = false,
  texts = enTexts,
  actions,
  dialogProps,

  cancelButtonProps,
  confirmButtonProps,
}) => (
  <Dialog
    fullWidth
    aria-labelledby="dialog-title"
    aria-describedby="dialog-description"
    {...dialogProps}
    maxWidth={maxWidth}
    open={openDialog}
    onClose={onCloseDialog}
  >
    <DialogTitle>{dialogTitle}</DialogTitle>
    <DialogContent>
      <DialogContentText>{dialogContentText}</DialogContentText>
      {children}
    </DialogContent>
    {(dialogAction || actions?.length) && (
      <DialogActionStyled>
        {actions?.map(({ id, node }) => <Fragment key={id}>{node}</Fragment>)}
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          <Button
            size="medium"
            className={buttonCancelClass}
            variant={buttonCancelVariant}
            color={buttonCancelColor}
            onClick={onClickDialogClose}
            disabled={buttonCancelDisabled}
            {...cancelButtonProps}
          >
            {texts.cancel}
          </Button>
          <Button
            size="medium"
            className={buttonConfirmClass}
            variant={buttonConfirmVariant}
            color={buttonConfirmColor}
            onClick={onConfimrDialog}
            autoFocus
            type="submit"
            {...confirmButtonProps}
            disabled={buttonConfirmDisabled || confirmButtonProps?.disabled}
          >
            {buttonConfirmText}
          </Button>
        </Box>
      </DialogActionStyled>
    )}
  </Dialog>
);
