import { gql } from "graphql-request";
import { type CurriedFunction2, curry, uniqBy } from "lodash";

import type {
  FluxMeterDashboardQuery,
  ClassifierDashboardQuery,
  CircuitDashboardQuery,
  Policy,
  ComponentView,
  SignalName,
} from "#shared/generated/graphql";

import { PolicyUtils } from "#organization/pages/authenticated/policies/policy/policy-utils";
import type {
  NodeData,
  EdgeData,
  UiData,
  FluxMeter,
  Classifier,
} from "#organization/pages/authenticated/policies/policy/types";
import type { DashboardType, QueryKey } from "#organization/pages/consts";
import type {
  ConcurrencyLimiterDashboardQueryParam,
  RateLimiterDashboardQueryParam,
  FlowAnalyticsDashboardQueryParam,
  ResourceWithDashboard,
  PrometheusDashboardQueryParam,
  SignalDashboardQueryParam,
  WithUiData,
  ResourceWithGenericDashboard,
} from "#organization/pages/types";

import { mapEdge, mapNode } from "../../config-flow-chart/setup-nodes-edges";

type QueriesByResourceAndDashboardType = {
  [R in ResourceWithGenericDashboard]: {
    [D in DashboardType]?: {
      gql: string;
      // nodeAccessor: NodeAccessor<R>;
      queryKey: QueryKey;
      /**
       * Map the received from API data to dashboard's data (query params and policy details)
       */
      mapToDashboardData: MapToDashboardData<R, D>;
      accessor: NodeAccessor<R>;
    };
  };
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type NodeAccessor<R extends ResourceWithDashboard, D = any> = (
  data: D,
  nodeId: string,
) => MapResourceTypeToQueryData<R> | null;

export type MapToDashboardData<
  R extends ResourceWithDashboard = ResourceWithDashboard,
  D extends DashboardType = DashboardType,
> = CurriedFunction2<
  Policy | null,
  MapResourceTypeToQueryData<R> | null,
  DashboardData<R, D>
>;

export type DashboardData<
  R extends ResourceWithDashboard = ResourceWithDashboard,
  D extends DashboardType = DashboardType,
> = {
  resourceName: string | null;
  queryParams:
    | {
        [Q in MapResourceTypeAndDashboardToQueryParams<R, D>]?: string;
      }
    | null;
};

/**
 * NOTE:
 * Map resource type to gql data
 */
export type MapResourceTypeToQueryData<R extends ResourceWithDashboard> =
  R extends "FluxMeter"
    ? Omit<FluxMeter, "cursor">
    : R extends "Classifier"
    ? Classifier
    : R extends "ConcurrencyLimiter"
    ? WithUiData<NodeData, UiData>
    : R extends "RateLimiter"
    ? WithUiData<NodeData, UiData>
    : R extends "Signal"
    ? WithUiData<EdgeData, UiData>
    : never;

type MapResourceTypeAndDashboardToQueryParams<
  R extends ResourceWithDashboard,
  D extends DashboardType,
> = R extends "FluxMeter"
  ? D extends "FLOW_ANALYTICS"
    ? FluxMeterHttpQueryParam
    : D extends "PROMETHEUS"
    ? FluxMeterPrometheusQueryParam
    : never
  : R extends "Classifier"
  ? ClassifierHttpQueryParam
  : R extends "ConcurrencyLimiter"
  ? ConcurrencyLimiterHttpQueryParam
  : R extends "RateLimiter"
  ? RateLimiterHttpQueryParam
  : R extends "Signal"
  ? SignalDashboardQueryParam
  : never;

type FluxMeterHttpQueryParam = Extract<
  FlowAnalyticsDashboardQueryParam,
  "var-controller_id" | "var-flux_meter_name"
>;

type FluxMeterPrometheusQueryParam = Extract<
  PrometheusDashboardQueryParam,
  "var-flux_meter_name"
>;

type ClassifierHttpQueryParam = Extract<
  FlowAnalyticsDashboardQueryParam,
  "var-controller_id" | "var-classifiers"
>;

type ConcurrencyLimiterHttpQueryParam = Extract<
  ConcurrencyLimiterDashboardQueryParam,
  | "var-controller_id"
  | "var-policy_name"
  | "var-component_id"
  | "var-policy_hash"
>;

type RateLimiterHttpQueryParam = Extract<
  RateLimiterDashboardQueryParam,
  | "var-controller_id"
  | "var-policy_name"
  | "var-component_id"
  | "var-policy_hash"
>;

/**
 * NOTE:
 * DashboardsUtils contains (per resource type and per dashboard type):
 * - gql,
 * - functions that map query data to policy and to the query parameters that are required by the dashboard
 * */
export class DashboardsUtils {
  public static emptyDashboard<
    R extends ResourceWithDashboard,
    D extends DashboardType,
  >(): MapToDashboardData<R, D> {
    const mapToEmptyDashboard: MapToDashboardData<R, D> = curry(
      (_: Policy | null, __: MapResourceTypeToQueryData<R> | null) => ({
        queryParams: null,
        resourceName: null,
      }),
    );

    return mapToEmptyDashboard;
  }

  private static readonly classifierGql = gql`
    query ClassifierDashboard(
      $first: Int
      $last: Int
      $after: String
      $before: String
      $where: ClassifierBoolExp
      $distinctOn: [ClassifierSelectColumn!]
    ) {
      classifiers(
        first: $first
        last: $last
        after: $after
        before: $before
        where: $where
        distinctOn: $distinctOn
      ) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            flowLabel
            policy {
              id
              name
              body
              controller {
                name
              }
            }
          }
        }
      }
    }
  `;

  private static readonly fluxMeterGql = gql`
    query FluxMeterDashboard(
      $first: Int
      $last: Int
      $after: String
      $before: String
      $where: FluxMeterBoolExp
      $distinctOn: [FluxMeterSelectColumn!]
    ) {
      fluxMeters(
        first: $first
        last: $last
        after: $after
        before: $before
        where: $where
        distinctOn: $distinctOn
      ) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            name
            id
            policy {
              id
              name
              controller {
                name
              }
            }
          }
        }
      }
    }
  `;

  private static readonly circuitGql = gql`
    query CircuitDashboard(
      $first: Int
      $last: Int
      $after: String
      $before: String
      $where: PolicyBoolExp
      $distinctOn: [PolicySelectColumn!]
    ) {
      policies(
        first: $first
        last: $last
        after: $after
        before: $before
        where: $where
        distinctOn: $distinctOn
      ) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            name
            id
            hash
            body
            circuit {
              actuators {
                component
                componentId
                componentName
                componentType
                componentDescription
              }
              graph {
                internalLinks {
                  subCircuitId
                  source {
                    componentId
                    portName
                  }
                  target {
                    componentId
                    portName
                  }
                  looped
                  value {
                    ... on SignalName {
                      signalName
                    }
                    ... on ConstantValue {
                      constantValue
                    }
                  }
                }
                externalLinks {
                  subCircuitId
                  source {
                    componentId
                    portName
                  }
                  target {
                    componentId
                    portName
                  }
                  looped
                  value {
                    ... on SignalName {
                      signalName
                    }
                    ... on ConstantValue {
                      constantValue
                    }
                  }
                }
              }
              children {
                hasChildren
                node {
                  componentId
                  componentDescription
                  componentType
                  component
                  componentName
                  inPorts {
                    subCircuitId
                    portName
                    looped
                    value {
                      ... on SignalName {
                        signalName
                      }
                      ... on ConstantValue {
                        constantValue
                      }
                    }
                  }
                  outPorts {
                    subCircuitId
                    portName
                    looped
                    value {
                      ... on SignalName {
                        signalName
                      }
                      ... on ConstantValue {
                        constantValue
                      }
                    }
                  }
                }
              }
            }
            controller {
              name
            }
          }
        }
      }
    }
  `;

  /** NOTE: get fluxMeter from query data */
  private static get fluxMeterAccessor(): NodeAccessor<
    "FluxMeter",
    FluxMeterDashboardQuery
  > {
    return (query) => query.fluxMeters?.edges[0];
  }

  /** NOTE: get classifier from query data */
  private static get classifierAccessor(): NodeAccessor<
    "Classifier",
    ClassifierDashboardQuery
  > {
    return (query) => query.classifiers?.edges[0];
  }

  /** NOTE: get concurrencyLimiter from query data */
  private static get concurrencyLimiterAccessor(): NodeAccessor<
    "ConcurrencyLimiter",
    CircuitDashboardQuery
  > {
    return (query, concurrencyLimiterId) => {
      const { edges = [] } = query.policies || {};

      const [{ node: policy } = { node: null }] = edges;

      if (!policy) {
        return null;
      }

      const graphAllComponents = DashboardsUtils.getAllPolicyComponents(policy);

      const node = graphAllComponents
        ? PolicyUtils.findNodeById(
            graphAllComponents as unknown as ComponentView[],
            concurrencyLimiterId,
          )
        : null;

      return node && graphAllComponents
        ? mapNode(graphAllComponents as unknown as ComponentView[])(node).data
        : null;
    };
  }

  private static getAllPolicyComponents(
    policy: CircuitDashboardQuery["policies"]["edges"][0]["node"] | null,
  ) {
    const graphAllComponents = uniqBy(
      [
        ...(policy?.circuit.children?.map((d) => ({
          ...d?.node,
        })) || []),
        ...(policy?.circuit.actuators || []),
      ],
      (d) => d?.componentId,
    );

    return graphAllComponents;
  }

  /** NOTE: get rateLimiter from query data */
  private static get rateLimiterAccessor(): NodeAccessor<
    "RateLimiter",
    CircuitDashboardQuery
  > {
    return (query, rateLimiterId) => {
      const graphAllComponents = DashboardsUtils.getAllPolicyComponents(
        query.policies?.edges[0]?.node,
      );

      const node = graphAllComponents
        ? PolicyUtils.findNodeById(
            graphAllComponents as unknown as ComponentView[],
            rateLimiterId,
          )
        : null;

      return node && graphAllComponents
        ? mapNode(graphAllComponents as unknown as ComponentView[])(node).data
        : null;
    };
  }

  /** NOTE: get signal from query data */
  private static get signalAccessor(): NodeAccessor<
    "Signal",
    CircuitDashboardQuery
  > {
    return (query, signalId) => {
      const graphAllLinks = PolicyUtils.mergeInternalAndExternalLinks(
        query?.policies?.edges[0]?.node.circuit.graph,
      );

      if (!graphAllLinks) {
        return null;
      }

      const signal = PolicyUtils.findSignalById(
        graphAllLinks,
        signalId,
      ) as EdgeData;

      return signal ? mapEdge()(signal, 0, graphAllLinks).data || null : null;
    };
  }

  /**
   * Map classifier, that was received from API, to dashboard's data (query params and policy details)
   */
  private static classifierToDashboardData(dashboardType: DashboardType) {
    if (dashboardType === "FLOW_ANALYTICS") {
      return curry(
        (
          policy: Policy | null,
          resource: MapResourceTypeToQueryData<"Classifier"> | null,
        ): ReturnType<MapToDashboardData<"Classifier", "FLOW_ANALYTICS">> => {
          if (!policy || !resource?.node) {
            return DashboardsUtils.emptyDashboard()(null, null);
          }

          const { node: classifier } = resource;
          const classifierIndex = PolicyUtils.findClassifierIndex(
            { flowLabel: classifier.flowLabel },
            { body: policy.body },
          );

          const result: ReturnType<
            MapToDashboardData<"Classifier", "FLOW_ANALYTICS">
          > = {
            queryParams: {
              "var-controller_id": policy?.controller?.name || "",
              "var-classifiers": `policy_name:${policy.name},classifier_index:${classifierIndex}`,
            },
            resourceName: classifier.flowLabel,
          };

          return result;
        },
      );
    }

    return DashboardsUtils.emptyDashboard();
  }

  /**
   * Map fluxMeter, that was received from API, to dashboard's data (query params and policy details)
   */
  private static fluxMeterToDashboardData = (dashboardType: DashboardType) => {
    if (dashboardType === "FLOW_ANALYTICS") {
      return curry(
        (
          policy: Policy | null,
          resource: MapResourceTypeToQueryData<"FluxMeter"> | null,
        ): ReturnType<MapToDashboardData<"FluxMeter", "FLOW_ANALYTICS">> => {
          if (!policy || !resource?.node) {
            return DashboardsUtils.emptyDashboard()(null, null);
          }

          const { node: fluxMeter } = resource;

          const result: ReturnType<
            MapToDashboardData<"FluxMeter", "FLOW_ANALYTICS">
          > = {
            queryParams: {
              "var-controller_id": policy?.controller?.name || "",
              "var-flux_meter_name": fluxMeter.name,
            },
            resourceName: fluxMeter.name,
          };

          return result;
        },
      );
    }

    if (dashboardType === "PROMETHEUS") {
      return curry(
        (
          policy: Policy | null,
          resource: MapResourceTypeToQueryData<"FluxMeter"> | null,
        ): ReturnType<MapToDashboardData<"FluxMeter", "PROMETHEUS">> => {
          if (!policy || !resource?.node) {
            return DashboardsUtils.emptyDashboard()(null, null);
          }

          const { node: fluxMeter } = resource;

          const result: ReturnType<
            MapToDashboardData<"FluxMeter", "PROMETHEUS">
          > = {
            queryParams: {
              "var-flux_meter_name": fluxMeter.name,
            },

            resourceName: fluxMeter.name,
          };

          return result;
        },
      );
    }

    return DashboardsUtils.emptyDashboard();
  };

  /**
   * Map concurrency limiter, that was received from API, to dashboard's data (query params and policy details)
   * If there is an actuator other then concurrency limiter or rate limiter, then we return concurrency limiter dashboard.
   * We change the name of component in uiData to do so. So, we will hit concurrency limiter if dashboard is not found.
   */
  private static concurrencyLimiterToDashboardData = (
    dashboardType: DashboardType,
  ) => {
    if (dashboardType === "CONCURRENCY_LIMITER") {
      return curry(
        (
          policy: Policy | null,
          resource: MapResourceTypeToQueryData<"ConcurrencyLimiter"> | null,
        ): ReturnType<
          MapToDashboardData<"ConcurrencyLimiter", "CONCURRENCY_LIMITER">
        > => {
          if (!policy || !resource) {
            return DashboardsUtils.emptyDashboard()(null, null);
          }

          const limitersQueryParams =
            PolicyUtils.getLimitersQueryParamValueByComponentIndex(
              resource.componentId,
              "ConcurrencyLimiter",
              policy,
            ) || {};

          const result: ReturnType<
            MapToDashboardData<"ConcurrencyLimiter", "CONCURRENCY_LIMITER">
          > = {
            queryParams: {
              "var-controller_id": policy?.controller?.name || "",
              ...limitersQueryParams,
            },
            resourceName: resource.uiData.name,
          };

          return result;
        },
      );
    }

    return DashboardsUtils.emptyDashboard();
  };

  /**
   * Map rate limiter, that was received from API, to dashboard's data (query params and policy details)
   */
  private static rateLimiterToDashboardData = (
    dashboardType: DashboardType,
  ) => {
    if (dashboardType !== "RATE_LIMITER") {
      return DashboardsUtils.emptyDashboard();
    }

    return curry(
      (
        policy: Policy | null,
        resource: MapResourceTypeToQueryData<"RateLimiter"> | null,
      ): ReturnType<MapToDashboardData<"RateLimiter", "RATE_LIMITER">> => {
        if (!policy || !resource) {
          return DashboardsUtils.emptyDashboard()(null, null);
        }

        const limitersQueryParams =
          PolicyUtils.getLimitersQueryParamValueByComponentIndex(
            resource.componentId,
            "RateLimiter",
            policy,
          ) || {};

        const result: ReturnType<
          MapToDashboardData<"RateLimiter", "RATE_LIMITER">
        > = {
          queryParams: {
            "var-controller_id": policy?.controller?.name || "",
            ...limitersQueryParams,
          },
          resourceName: resource.uiData.name,
        };

        return result;
      },
    );
  };

  private static signalToDashboardData = (
    dashboardType: DashboardType,
  ): MapToDashboardData<"Signal", "SIGNAL"> => {
    if (dashboardType !== "SIGNAL") {
      return DashboardsUtils.emptyDashboard();
    }

    return curry(
      (
        policy: Policy | null,
        resource: MapResourceTypeToQueryData<"Signal"> | null,
      ): ReturnType<MapToDashboardData<"Signal", "FLOW_ANALYTICS">> => {
        if (!policy || !resource?.value) {
          return DashboardsUtils.emptyDashboard()(null, null);
        }

        const signalName =
          (resource.value as unknown as SignalName | null)?.signalName || "";

        const result: ReturnType<MapToDashboardData<"Signal", "SIGNAL">> = {
          queryParams: {
            "var-policy_name": encodeURIComponent(policy.name),
            "var-signal_name": encodeURIComponent(signalName),
            "var-sub_circuit_id": encodeURIComponent(resource.subCircuitId),
          },
          resourceName: signalName,
        };

        return result;
      },
    );
  };

  public static readonly optionsByResourceAndDashboardType: QueriesByResourceAndDashboardType =
    {
      FluxMeter: {
        FLOW_ANALYTICS: {
          queryKey: "DASHBOARD_FLUX_METER",
          gql: DashboardsUtils.fluxMeterGql,
          mapToDashboardData:
            DashboardsUtils.fluxMeterToDashboardData("FLOW_ANALYTICS"),
          accessor: DashboardsUtils.fluxMeterAccessor,
        },
        PROMETHEUS: {
          queryKey: "DASHBOARD_FLUX_METER",
          gql: DashboardsUtils.fluxMeterGql,
          mapToDashboardData:
            DashboardsUtils.fluxMeterToDashboardData("PROMETHEUS"),
          accessor: DashboardsUtils.fluxMeterAccessor,
        },
      },
      Classifier: {
        FLOW_ANALYTICS: {
          mapToDashboardData:
            DashboardsUtils.classifierToDashboardData("FLOW_ANALYTICS"),
          queryKey: "DASHBOARD_CLASSIFIER",
          gql: DashboardsUtils.classifierGql,
          accessor: DashboardsUtils.classifierAccessor,
        },
      },
      ConcurrencyLimiter: {
        CONCURRENCY_LIMITER: {
          mapToDashboardData: DashboardsUtils.concurrencyLimiterToDashboardData(
            "CONCURRENCY_LIMITER",
          ),
          queryKey: "DASHBOARD_CIRCUIT",
          gql: DashboardsUtils.circuitGql,
          accessor: DashboardsUtils.concurrencyLimiterAccessor,
        },
      },
      RateLimiter: {
        RATE_LIMITER: {
          mapToDashboardData:
            DashboardsUtils.rateLimiterToDashboardData("RATE_LIMITER"),
          queryKey: "DASHBOARD_CIRCUIT",
          gql: DashboardsUtils.circuitGql,
          accessor: DashboardsUtils.rateLimiterAccessor,
        },
      },
      Signal: {
        SIGNAL: {
          mapToDashboardData: DashboardsUtils.signalToDashboardData("SIGNAL"),
          queryKey: "DASHBOARD_CIRCUIT",
          gql: DashboardsUtils.circuitGql,
          accessor: DashboardsUtils.signalAccessor,
        },
      },
    };
}
