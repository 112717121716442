import type { RJSFSchema } from "@rjsf/utils";

import { fetchAgentGroups } from "../hooks/agentGroups";
import { fetchControlPoints } from "../hooks/controlPoints";

/**
 * Removes intricate versions from a version string
 * e.g: 2.7.0-rc.1-b.1 -> 2.7.0-rc.1
 */
export const normalizeVersion = (version: string): string =>
  version.split("-").length <= 2
    ? version
    : version.slice(0, version.lastIndexOf("-"));

/**
 * Removes the dashboard property from a JSON schema
 * e.g: { properties: { policy: { ... }, dashboard: { ... }, blueprint: "foo", uri: "bar" } } }
 *      ->
 *      { properties: { policy: { ... }, blueprint: "foo", uri: "bar" } }
 */
export const removeDashboardProp = (schema: RJSFSchema): RJSFSchema => {
  const newSchema = { ...schema };

  if (newSchema.properties && newSchema.properties.dashboard) {
    delete newSchema.properties.dashboard;
  }

  return newSchema;
};

/**
 * Update the blueprint name prop to use anyOf as the monaco editor does not autofill
 * it with the default value when it uses just the enum prop
 *
 * e.g: { properties: { blueprint: { default: "foo", enum: ["foo"] } } }
 *      ->
 *      { properties: { blueprint: { default: "foo", anyOf: [ { type: "string" }, { type: "string", enum: ["foo"] } ] } } }
 */
export const updateBlueprintNameProp = (schema: RJSFSchema): RJSFSchema => {
  const newSchema = { ...schema };

  if (
    newSchema.properties &&
    typeof newSchema.properties.blueprint === "object" &&
    newSchema.properties.blueprint.enum
  ) {
    newSchema.properties.blueprint = {
      ...newSchema.properties.blueprint,
      anyOf: [
        { type: "string" },
        {
          type: "string",
          enum: newSchema.properties.blueprint.enum,
        },
      ],
    };

    delete newSchema.properties.blueprint.enum;
  }

  return newSchema;
};

/**
 * Replaces all references in a JSON schema with absolute URLs and returns the new schema and the list of new references
 * e.g: "../../../../gen/jsonschema/_definitions.json#/definitions/Component"
 *      ->
 *      "#/definitions/Component"
 */
export function replaceRefs(
  schema: RJSFSchema,
  basePath: string,
  newRefs: string[] = [],
  localRefs: string[] = [],
): [RJSFSchema, string[], string[]] {
  if (typeof schema === "object") {
    if (!schema) {
      return [schema, newRefs, localRefs];
    }

    if (schema.$ref && typeof schema.$ref === "string") {
      const ref = schema.$ref;

      if (ref.startsWith("#")) {
        // Local reference, no change needed
        localRefs.push(ref);
      } else {
        // Replace local reference with an absolute URL
        const [refPath, refName] = ref.split("#");
        const newPath = new URL(refPath, basePath).href;
        // eslint-disable-next-line no-param-reassign
        schema.$ref = `#${refName}`;
        newRefs.push(`${newPath}#${refName}`);
      }
    } else {
      Object.keys(schema).forEach((key) => {
        const [updatedObj, updatedRefs] = replaceRefs(
          schema[key],
          basePath,
          newRefs,
          localRefs,
        );
        // eslint-disable-next-line no-param-reassign
        schema[key] = updatedObj;
        // eslint-disable-next-line no-param-reassign
        newRefs = updatedRefs;
      });
    }
  }

  return [schema, newRefs, localRefs];
}

/**
 * Returns the name of a definition from its reference
 * e.g: "#/definitions/Component" -> "Component"
 */
export const getDefName = (ref: string) => ref.slice(ref.lastIndexOf("/") + 1);

/**
 * Optimizes a list of references by grouping them by file
 * e.g: [ file1#/definitions/Component, file1#/definitions/Component2, file2#/definitions/Component ]
 *      ->
 *      { file1: ["Component", "Component2"], file2: ["Component"] }
 */
export const optimizeRefs = (refs: string[]) =>
  refs.reduce<{ [key: string]: string[] }>((acc, url) => {
    const [file, ref] = url.split("#");
    const defName = getDefName(ref);

    if (acc[file]) {
      acc[file].push(defName);
    } else {
      acc[file] = [defName];
    }

    return acc;
  }, {});

/**
 * Aggregates a list of definitions into a single schema
 * e.g: [ "Component", "Component2" ]
 *      ->
 *      { definitions: { Component: { ... }, Component2: { ... } } }
 */
export const aggregateDefs = (schema: RJSFSchema, defs: string[]) =>
  defs.reduce<RJSFSchema>(
    (acc, def) => ({
      ...acc,
      [def]: schema.definitions![def],
    }),
    {},
  );

/**
 * Merges a list of schemas into a single schema
 * WARNING: This might result in unexpected behavior if the schemas have conflicting definitions
 * e.g: [ { definitions: { Component: { ... } } }, { definitions: { Component2: { ... } } } ]
 *      ->
 *      { definitions: { Component: { ... }, Component2: { ... } } }
 */
export const mergeDefs = (defSchemaList: RJSFSchema[]) =>
  defSchemaList.reduce<RJSFSchema>(
    (acc, ref) => ({
      ...acc,
      ...ref,
    }),
    {},
  );

/**
 * Returns a new schema with the given policy names disallowed
 * e.g: { policy_name: { type: string } }
 *      ->
 *      { policy_name: { type: string, not: { enum: ["policy1", "policy2"] } } }
 */
export const disallowPolicyNames = (
  schema: RJSFSchema,
  policyNames: string[],
): RJSFSchema => {
  if (typeof schema !== "object" || schema === null) {
    return schema;
  }

  if (schema.policy_name) {
    // eslint-disable-next-line no-param-reassign
    schema.policy_name = {
      ...schema.policy_name,
      not: {
        enum: policyNames,
      },
    };

    return schema;
  }

  Object.keys(schema).forEach((key) => {
    // eslint-disable-next-line no-param-reassign
    schema[key] = disallowPolicyNames(schema[key], policyNames);
  });

  return schema;
};

/**
 * Injects an enum for agent_groups in the policy schema
 * Note: Unlike the other functions, this function expects the definitions instead of the entire schema
 *
 * e.g: { properties: { agent_group: { type: string, default: "default" } } }
 *      ->
 *      { properties: { agent_group: { type: string, anyOf: [ { type: string }, { type: "string", enum: ["default", "group1"] }, default: "default" } } }
 */
export const addAgentGroupsEnum = async (
  definitions: RJSFSchema,
): Promise<RJSFSchema> => {
  let agentGroups: string[];

  try {
    agentGroups = await fetchAgentGroups();
  } catch {
    return definitions;
  }

  if (
    typeof definitions !== "object" ||
    definitions === null ||
    agentGroups.length === 0
  ) {
    return definitions;
  }

  if (
    typeof definitions.Selector === "object" &&
    typeof definitions.Selector.properties?.agent_group === "object"
  ) {
    const defaultValue = definitions.Selector.properties.agent_group.default;

    if (defaultValue && !agentGroups.includes(defaultValue)) {
      agentGroups.unshift(defaultValue);
    }

    // eslint-disable-next-line no-param-reassign
    definitions.Selector.properties.agent_group = {
      ...definitions.Selector.properties.agent_group,
      anyOf: [
        {
          type: "string",
        },
        {
          type: "string",
          enum: agentGroups,
        },
      ],
    };
  }

  return definitions;
};

/**
 * Injects an enum for control_points in the policy schema
 * Note: Unlike the other functions, this function expects the definitions instead of the entire schema
 *
 * e.g: { properties: { control_point: { type: string, default: "default" } } }
 *      ->
 *      { properties: { control_point: { type: string, anyOf: [ { type: string }, { type: "string", enum: ["default", "cp1"] }, default: "default" } } }
 */
export const addControlPointsEnum = async (
  definitions: RJSFSchema,
): Promise<RJSFSchema> => {
  let controlPoints: string[];

  try {
    controlPoints = await fetchControlPoints();
  } catch {
    return definitions;
  }

  if (
    typeof definitions !== "object" ||
    definitions === null ||
    controlPoints.length === 0
  ) {
    return definitions;
  }

  if (
    typeof definitions.Selector === "object" &&
    typeof definitions.Selector.properties?.control_point === "object"
  ) {
    const defaultValue = definitions.Selector.properties.control_point.default;

    if (defaultValue && !controlPoints.includes(defaultValue)) {
      controlPoints.unshift(defaultValue);
    }

    // eslint-disable-next-line no-param-reassign
    definitions.Selector.properties.control_point = {
      ...definitions.Selector.properties.control_point,
      anyOf: [
        {
          type: "string",
        },
        {
          type: "string",
          enum: controlPoints,
        },
      ],
    };
  }

  return definitions;
};

/**
 * Removes circular not references for the MatchExpression definition in a jsonschema.
 * This is a workaround for the react-jsonschema-form library not being able to handle direct circular references.
 * e.g: { not: { $ref: "#/definitions/MatchExpression" } }
 *      ->
 *      {}
 */
export const removeCircularNotReference = (obj: RJSFSchema): RJSFSchema => {
  if (typeof obj !== "object" || obj === null) {
    return obj; // Return unchanged if not an object
  }

  if (Array.isArray(obj)) {
    return obj.map(removeCircularNotReference); // Recursively process array elements
  }

  const result: RJSFSchema = {};

  Object.keys(obj).forEach((key) => {
    if (key !== "not" || !isNotMatchExpressionRef(obj[key])) {
      result[key] = removeCircularNotReference(obj[key]); // Recursively process nested objects
    }
  });

  return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isNotMatchExpressionRef = (value: any) =>
  typeof value === "object" &&
  value !== null &&
  value.$ref === "#/definitions/MatchExpression";
