import { alpha, useTheme } from "@mui/material";
import { motion, type MotionProps } from "framer-motion";
import React, { type FC, type PropsWithChildren } from "react";

export const AnimateHighlightText: FC<PropsWithChildren<MotionProps>> = ({
  children,
  ...motionProps
}) => {
  const { palette, spacing } = useTheme();

  return (
    <motion.span
      {...motionProps}
      {...{
        initial: {
          scale: 1,
          backgroundColor: alpha(palette.primary.main, 0.9),
        },
        animate: {
          backgroundColor: alpha(palette.primary.main, 0.2),
          scale: [1, 1.05, 1],
        },
      }}
      transition={{
        type: "tween",
        ease: "easeInOut",
        duration: 1,
        times: [0, 0.5, 1],
        repeatDelay: 1,
      }}
      style={{
        borderRadius: spacing(0.5),
        padding: "1px 4px",
        marginRight: 4,
      }}
    >
      {children}
    </motion.span>
  );
};
