import { gql } from "graphql-request";
import { useCallback, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import type { UserGroupPermissionQuery } from "#shared/generated/graphql";
import { userNewState } from "#shared/recoil";
import { useGqlQuery } from "#shared/utils";

import { QUERY_KEYS } from "#organization/pages/consts";
import { projectContextState } from "#organization/recoil/project";

export const UserGroupPermissions = gql`
  query UserGroupPermission($where: UserGroupBoolExp) {
    userGroups(where: $where) {
      edges {
        node {
          userGroupProjectAssignments {
            edges {
              node {
                permissions
              }
            }
          }
        }
      }
    }
  }
`;

/**
 *
 * Updates permissions in global state for user as well.
 * Permissions for a user in current project based on their user group membership.
 */
export const useUserGroupPermissions = () => {
  const { data: user } = useRecoilValue(userNewState.state);
  const { id: projectID } = useRecoilValue(projectContextState);

  const [, setNewUserPermissions] = useRecoilState(userNewState.permissions);

  const { data, ...other } = useGqlQuery<UserGroupPermissionQuery>(
    [QUERY_KEYS.GET_USER_GROUPS, projectID, user?.id],
    UserGroupPermissions,
    {
      where: {
        userGroupProjectAssignments: {
          projectID: {
            eq: projectID,
          },
        },
        userGroupMemberships: {
          userID: {
            eq: user?.id,
          },
        },
      },
    },
  );

  const permissions = useMemo(
    () =>
      data?.userGroups.edges?.[0]?.node.userGroupProjectAssignments.edges?.[0]
        ?.node.permissions || null,
    [data],
  );

  const updateGlobalPermissions = useCallback(() => {
    if (!permissions) {
      return;
    }

    setNewUserPermissions((prev) =>
      prev !== permissions ? permissions : prev,
    );
  }, [permissions, setNewUserPermissions]);

  return {
    ugPermissions: permissions,
    updateGlobalPermissions,
    ...other,
  };
};
