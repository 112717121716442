import { cloneDeep, curry, find, isEmpty, isEqual } from "lodash";
import React, {
  useState,
  useMemo,
  type FC,
  type PropsWithChildren,
  useRef,
} from "react";
import { useParams } from "react-router-dom";

import type { ResourceWithDashboard } from "#organization/pages/types";

import {
  type SelectedResources,
  CIRCUIT_VIEW_RESOURCES,
  RESOURCES_VIEW_RESOURCES,
  type SelectedResource,
  type CircuitViewResource,
} from "./consts";
import {
  SelectedResourcesContext,
  type SelectedResourcesContextValue,
} from "./context";

import type { PolicyDetailsParams } from "../../routes.definitions";

type SetPartialSelectedResource = (
  selectedResources: Partial<SelectedResources>,
) => void;

const DEFAULT_SELECTED_RESOURCES: SelectedResources = {
  Classifier: [],
  FluxMeter: [],
  Signal: [],
  ConcurrencyLimiter: [],
  RateLimiter: [],
  Dashboards: [],
};

/**
 * NOTE:
 * Provider of dynamic policy's selected resources context.
 */
export const SelectedResourcesProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { resourceType, resourceId } = useParams<PolicyDetailsParams>();

  const [selectedResources, setSelectedResources] = useState<SelectedResources>(
    {
      ...cloneDeep(DEFAULT_SELECTED_RESOURCES),
      ...(resourceType && resourceId
        ? { [resourceType]: resourceId.split("&") }
        : {}),
    },
  );

  const prevResourcesRef = useRef(cloneDeep(selectedResources));

  const isResourceTypeSelectedPredicate = useMemo(
    () => (type: ResourceWithDashboard) => !!selectedResources[type]?.length,
    [selectedResources],
  );

  const isCircuitResourceSelected = useMemo(
    () => CIRCUIT_VIEW_RESOURCES.some(isResourceTypeSelectedPredicate),
    [isResourceTypeSelectedPredicate],
  );

  const isResourcesResourceSelected = useMemo(
    () => RESOURCES_VIEW_RESOURCES.some(isResourceTypeSelectedPredicate),
    [isResourceTypeSelectedPredicate],
  );

  const selectedCircuitResources = useMemo(
    () =>
      Object.entries(selectedResources).find((entry) => {
        const [type, resources] = entry as [
          CircuitViewResource,
          SelectedResource[],
        ];

        return CIRCUIT_VIEW_RESOURCES.includes(type) && !!resources?.length;
      }) as [CircuitViewResource, SelectedResource[]],
    [selectedResources],
  );

  const setPartialSelectedResources: SetPartialSelectedResource = useMemo(
    () => (resources) => {
      const mergedSelectedResources: SelectedResources = {
        // NOTE: Clone defaults because only one resource can be selected
        ...cloneDeep(DEFAULT_SELECTED_RESOURCES),
        ...resources,
      };

      if (isEqual(mergedSelectedResources, selectedResources)) {
        return;
      }

      prevResourcesRef.current = selectedResources;

      setSelectedResources(mergedSelectedResources);
    },
    [selectedResources],
  );

  const selectedResourcesContextValue =
    useMemo<SelectedResourcesContextValue>(() => {
      const selectedResource =
        (Object.entries(selectedResources).find(([, ids]) => ids?.length) as
          | [ResourceWithDashboard, SelectedResource[]]
          | undefined) || null;

      const value: SelectedResourcesContextValue = {
        selectedResources,
        selectedResource,
        selectedCircuitResources: selectedCircuitResources || null,
        prevSelectedResources: cloneDeep(prevResourcesRef.current),
        isCircuitResourceSelected,
        isResourcesResourceSelected,
        isResourceSelected: curry(
          (
            type: keyof SelectedResources,
            partial: Partial<SelectedResource>,
          ) =>
            type && partial && !isEmpty(partial)
              ? !!find(selectedResources[type], partial)
              : false,
        ),
        defaultSelectedResources: cloneDeep(DEFAULT_SELECTED_RESOURCES),
        resetSelectedResources: () => {
          if (isEqual(DEFAULT_SELECTED_RESOURCES, selectedResources)) {
            return;
          }

          prevResourcesRef.current = selectedResources;

          setSelectedResources(cloneDeep(DEFAULT_SELECTED_RESOURCES));
        },
        selectOne: curry(
          (type: keyof SelectedResources, { uiData }: SelectedResource) => {
            if (!uiData?.id && find(selectedResources[type], { uiData })) {
              return;
            }

            prevResourcesRef.current = selectedResources;

            setPartialSelectedResources({
              [type]: [{ uiData }],
            });
          },
        ),
        selectCircuitResources: curry(
          (type: CircuitViewResource, resources: SelectedResource[]) => {
            if (!CIRCUIT_VIEW_RESOURCES.includes(type)) {
              return;
            }

            setPartialSelectedResources({
              [type]: resources,
            });
          },
        ),
      };

      return value;
    }, [
      selectedResources,
      setSelectedResources,
      isCircuitResourceSelected,
      isResourcesResourceSelected,
      setPartialSelectedResources,
      selectedCircuitResources,
    ]);

  return (
    <SelectedResourcesContext.Provider value={selectedResourcesContextValue}>
      {children}
    </SelectedResourcesContext.Provider>
  );
};
