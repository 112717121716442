import React, { type FC, type PropsWithChildren } from "react";
import { useRecoilValue } from "recoil";

import { PermissionEnum, userNewState } from "#shared/recoil";

export type PermissionWrapperPros = {
  level: number;
} & PropsWithChildren;

export const PermissionWrapper: FC<PermissionWrapperPros> = ({
  children,
  level,
}) => {
  const userRole = useRecoilValue(userNewState.userRole)!;
  const currentLevel = PermissionEnum[userRole];

  return currentLevel >= level ? <>{children}</> : null;
};
