import { Box, Button, Typography } from "@mui/material";
import React, { useState, type FC, useEffect, type ChangeEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { useAlert } from "#shared/components/alerts-provider";
import { Input } from "#shared/components/inputs/input";
import {
  ErrorLayout,
  ErrorText,
  LoaderLayout,
} from "#shared/components/layouts";
import {
  ConfirmDialog,
  useControlDialog,
} from "#shared/components/layouts/dialog";
import { PermissionEnum, userNewState } from "#shared/recoil";
import { LoggerService } from "#shared/services/logger";
import type { Texts } from "#shared/types";

import {
  useChangeUserGroupName,
  useRemoveUserGroup,
} from "#organization/graphql";

import { MembersSection } from "./components/members-section";
import { ProjectsSection } from "./components/projects-section";
import { useUserGroup } from "./hooks";

import { SectionHeader } from "../../../components/section-header";
import { SettingsButtons } from "../../../components/settings-buttons";
import { SettingsSectionLabel } from "../../../components/settings-section-label";
import { SectionBody, SectionWrapper } from "../../../components/styled";
import { useUpdateProjectAssignmentPermissionsHelper } from "../../projects/project/hooks";
import {
  USER_GROUPS_SETTINGS_ROUTE,
  type UserGroupIdParam,
} from "../routes.definitions";

type Text =
  | "removeSuccess"
  | "removeError"
  | "details"
  | "changeNameSuccess"
  | "changeNameError"
  | "userGroupName"
  | "removeUserGroup"
  | "removeUserGroupConfirm"
  | "deleteUserGroupMessage"
  | "deleteUserGroup";

interface UserGroupPageProps {
  texts?: Texts<Text>;
}

const enTexts: Required<UserGroupPageProps["texts"]> = {
  details: "Details",
  removeSuccess: "Successfully removed user group from organization.",
  removeError: "Could not remove user group from organization.",
  changeNameSuccess: "Successfully changed user group name.",
  changeNameError: "Could not change user group name.",
  userGroupName: "User group name",
  removeUserGroup: "Delete User Group",
  removeUserGroupConfirm: "remove $1 from organization?",
  deleteUserGroupMessage:
    "Deleting User Group will remove all its data, together with users permissions for Projects. Deleting the User Group cannot be undone.",
  deleteUserGroup: "Delete User Group",
};

export const UserGroupPage: FC<UserGroupPageProps> = ({ texts = enTexts }) => {
  const { addAlert } = useAlert();
  const navigate = useNavigate();
  const { userGroupId } = useParams<UserGroupIdParam>() as UserGroupIdParam;
  const userGroup = useUserGroup();
  const [userGroupName, setUserGroupName] = useState<string | undefined>("");
  const userGroupChangeName = useChangeUserGroupName();
  const removeUserGroupDialog = useControlDialog();
  const userGroupDelete = useRemoveUserGroup();

  const userRole = useRecoilValue(userNewState.userRole)!;

  const permissionLock =
    PermissionEnum[userRole] < PermissionEnum.user_group_admin;

  const handleUserGroupName = (event: ChangeEvent<HTMLInputElement>) => {
    setUserGroupName(event.currentTarget.value);
  };

  const deleteUserGroup = () => {
    userGroupDelete.remove(
      { id: userGroupId },
      {
        onSuccess: () => {
          navigate(USER_GROUPS_SETTINGS_ROUTE.ABSOLUTE_PATH);

          addAlert({
            type: "success",
            message: texts.removeSuccess,
          });
        },
        onError: () => {
          LoggerService.error(Error);

          addAlert({
            type: "error",
            message: texts.removeError,
          });
        },
      },
    );
  };

  const {
    updateUserGroupPermissionPayload,
    setUpdateUserGroupPermissionPayload,
    triggerPermissionUpdate,
  } = useUpdateProjectAssignmentPermissionsHelper();

  const onReset = () => {
    setUserGroupName(userGroup?.data?.node.name);
    setUpdateUserGroupPermissionPayload([]);
  };

  const onSave = () => {
    if (userGroupName !== userGroup?.data?.node.name) {
      userGroupChangeName.change(
        { id: userGroupId, name: userGroupName },
        {
          onSuccess: (response) => {
            addAlert({
              type: "success",
              message: texts.changeNameSuccess,
            });
            userGroup.refetch();
            setUserGroupName(response.data.updateUserGroupName.userGroup.name);
          },
          onError: (error) => {
            addAlert({
              type: "error",
              message: texts.changeNameError,
            });
            LoggerService.error(error);
          },
        },
      );
    }

    if (updateUserGroupPermissionPayload?.length) {
      triggerPermissionUpdate();
    }
  };

  useEffect(() => {
    if (!userGroup.loading) {
      setUserGroupName(userGroup?.data?.node.name);
    }
  }, [userGroup.loading, userGroup.data]);

  if (!userGroup.data && userGroup.loading) {
    return <LoaderLayout />;
  }

  if (userGroup.error) {
    return (
      <ErrorLayout>
        <ErrorText>{userGroup.error.message}</ErrorText>
      </ErrorLayout>
    );
  }

  return (
    <>
      <SettingsButtons
        onResetAll={onReset}
        onSave={onSave}
        backButton
        backGoTo={USER_GROUPS_SETTINGS_ROUTE.ABSOLUTE_PATH}
        disabled={permissionLock}
      />
      <Typography sx={{ fontSize: "34px", m: 4, mb: 0 }}>
        {userGroup?.data?.node.name}
      </Typography>

      <SectionWrapper>
        <SectionHeader title={texts.details} />
        <SectionBody>
          <SettingsSectionLabel primaryText={texts.userGroupName} asterisk />
          <Input
            size="small"
            value={userGroupName}
            onChange={handleUserGroupName}
            disabled={permissionLock}
          />
        </SectionBody>
      </SectionWrapper>

      <MembersSection
        userGroupId={userGroupId}
        userGroupName={userGroup?.data?.node.name}
      />

      <ProjectsSection
        userGroupName={userGroup?.data?.node.name}
        setUpdateUserGroupPermission={setUpdateUserGroupPermissionPayload}
      />

      <SectionWrapper>
        <SectionHeader title={texts.removeUserGroup} />
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          alignItems="flex-start"
          sx={{ padding: "30px 8px 30px 8px" }}
        >
          <Typography variant="body1" fontWeight={400}>
            {texts.deleteUserGroupMessage}
          </Typography>

          <Button
            size="small"
            onClick={removeUserGroupDialog.open}
            disabled={permissionLock}
            variant="outlined"
            color="error"
          >
            {texts.removeUserGroup}
          </Button>
        </Box>
      </SectionWrapper>

      <ConfirmDialog
        text={texts.removeUserGroupConfirm.replace("$1", userGroupName || "")}
        onConfirm={deleteUserGroup}
        close={removeUserGroupDialog.close}
        isOpen={removeUserGroupDialog.isOpen}
        confirmPhrase={userGroupName}
      />
    </>
  );
};
