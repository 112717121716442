import { Box, styled, Divider, type Theme, Paper } from "@mui/material";

import {
  BACKDROP_GRADIENT_DARK_MODE,
  BACKDROP_GRADIENT_LIGHT_MODE,
} from "#shared/consts";

const BAR_HEIGHT = "116px";
const PADDING = "40px";
const DIVIDER_HEIGHT = "auto";
const V_MARGIN = "50px";

export const OuterContainer = styled(Box)(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  minHeight: "100vh",
  overflowX: "hidden",
  background:
    theme.palette.mode === "light"
      ? BACKDROP_GRADIENT_LIGHT_MODE
      : BACKDROP_GRADIENT_DARK_MODE,
}));

export const InnerContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100%",
}));

export const ViewOuterContainer = styled(Paper)(({ theme }) => ({
  overflowX: "hidden",
  marginTop: 0,
  minHeight: "100%",
  gridTemplateRows: [
    BAR_HEIGHT,
    DIVIDER_HEIGHT,
    "1fr",
    DIVIDER_HEIGHT,
    BAR_HEIGHT,
  ].join(" "),
  display: "grid",
  ...styleWidth(theme),
  [theme.breakpoints.up("lg")]: {
    marginTop: V_MARGIN,
    marginBottom: V_MARGIN,
    minHeight: `calc(100% - ${V_MARGIN} - ${V_MARGIN})`,
  },
}));

export const ViewInnerContainer = styled(Paper)(({ theme }) => ({
  gridTemplateRows: ["auto", "1fr", "auto"].join(" "),
  display: "grid",
  overflowX: "hidden",
  gridRow: 3,
  padding: [theme.spacing(5), PADDING].join(" "),
  boxShadow: "none",
}));

export const ActionsOuterContainer = styled(Box)(({ theme }) => ({
  gridRow: "5",
  width: "100%",
  minWidth: "100%",
  background: theme.palette.background.paper,
}));

export const ActionsInnerContainer = styled(Box)(() => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  padding: [0, PADDING].join(" "),
}));

export const ActionsDivider = styled(Divider)(() => ({
  gridRow: "4",
}));

export const ToolbarDivider = styled(Divider)(() => ({
  gridRow: "2",
}));

export const ToolbarInnerContainer = styled(Box)(() => ({
  height: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: [0, PADDING].join(" "),
}));

export const ToolbarOuterContainer = styled(Box)(({ theme }) => ({
  gridRow: "1",
  width: "100%",
  minWidth: "100%",
  background: theme.palette.background.paper,
}));

const RESPONSIVE_WIDTH = {
  xs: "100vw",
  md: "1200px",
  lg: "1400px",
};

function styleWidth({ breakpoints }: Theme) {
  return {
    width: RESPONSIVE_WIDTH.xs,
    maxWidth: RESPONSIVE_WIDTH.xs,
    [breakpoints.up("md")]: {
      maxWidth: RESPONSIVE_WIDTH.md,
    },
    [breakpoints.up("lg")]: {
      maxWidth: RESPONSIVE_WIDTH.lg,
    },
  };
}
