import { isEmpty } from "lodash";
import React, { type FC } from "react";

import {
  ErrorLayout,
  ErrorText,
  LoaderLayout,
} from "#shared/components/layouts";

import {
  LoadDashboardPortal,
  type GenericDashboardType,
} from "#organization/components/grafana";
import type { DashboardType } from "#organization/pages/consts";
import type {
  DashboardConfig,
  ResourceWithGenericDashboard,
} from "#organization/pages/types";

import { useDashboardProps } from "../../../../dashboard/use-dashboard-props";

export type PolicyResourceDashboardProps<
  D extends DashboardType = DashboardType,
> = {
  resourceType: ResourceWithGenericDashboard;
  dashboardType: GenericDashboardType;
  queryParams?: DashboardConfig<D>["queryParams"] | null;
  isError?: boolean;
};

export const PolicyResourceDashboard: FC<PolicyResourceDashboardProps> = (
  props,
) => {
  const { resourceType, dashboardType, queryParams, isError } = props;

  const dashboardProps = useDashboardProps(
    resourceType,
    dashboardType,
    queryParams,
  );

  return (
    <>
      {isError && (
        <ErrorLayout>
          <ErrorText />
        </ErrorLayout>
      )}
      {!isError && (!dashboardProps?.queryParams || !dashboardProps.uuid) ? (
        <LoaderLayout />
      ) : null}
      {!dashboardProps?.queryParams ||
      isEmpty(dashboardProps.queryParams || {}) ||
      !dashboardProps.uuid ||
      isError ? null : (
        <LoadDashboardPortal
          queryParams={dashboardProps.queryParams}
          slug={dashboardProps.slug || ""}
          uid={dashboardProps.uuid}
          hiddenVariables={dashboardProps.hiddenVariables}
        />
      )}
    </>
  );
};
