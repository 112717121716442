import { Box, Container } from "@mui/material";
import { isLeft } from "fp-ts/lib/Either";
import React, {
  type FC,
  type PropsWithChildren,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import { useRecoilState } from "recoil";

import { PrimaryButton } from "#shared/components/buttons";
import { GlobalFrame, LoaderLayout } from "#shared/components/layouts";
import { ErrorLayout, ErrorText } from "#shared/components/layouts/error";
import { API_SERVICE_URL, GLOBAL_APP_ABSOLUTE_URL } from "#shared/consts";
import { ENV } from "#shared/env";
import { useFetch, useIsMounted } from "#shared/hooks";
import { LoggerService } from "#shared/services/logger";

import { getOrganizationSubdomain } from "./utils";

import { type OrganizationState, organizationState } from "../state";
import type { OrganizationBody } from "../types";

interface LoaderError {
  header: string;
  message: string;
}

/**
 * Loads information about the current organization and displays a spinner in the meantime
 */
export const OrganizationLoader: FC<PropsWithChildren> = ({ children }) => {
  const [orgState, setOrgState] = useRecoilState(organizationState);

  const { host } = window.location;
  const organizationSubdomain = useMemo(
    () => getOrganizationSubdomain(ENV.VITE_APP_DOMAIN, host),
    [host],
  );
  const [error, setError] = useState<LoaderError>();

  const loadOrganizationInformation = useLoadOrganizationInformation();

  // NOTE: load organization
  useEffect(() => {
    if (isLeft(organizationSubdomain)) {
      setError({
        header: "...when trying to find your organization:",
        message: organizationSubdomain.left,
      });

      return;
    }

    if (!loadOrganizationInformation.organization.httpState.isIdle) {
      LoggerService.debug(
        null,
        "OrganizationLoader: organization is already loading",
      );

      return;
    }

    loadOrganizationInformation.organization.request(
      organizationSubdomain.right,
      setError,
      setOrgState,
    );
  }, [organizationSubdomain, setOrgState, loadOrganizationInformation]);

  // NOTE: load tenant id only if organization is loaded
  useEffect(() => {
    if (isLeft(organizationSubdomain)) {
      setError({
        header: "...when trying to find your organization:",
        message: organizationSubdomain.left,
      });
    }
  }, [
    loadOrganizationInformation.organization.httpState.isSuccess,
    organizationSubdomain,
    setOrgState,
  ]);

  if (error) {
    return (
      <GlobalFrame hideLogout hideHelp hideLogo>
        <Box mb={4}>
          <ErrorLayout>
            <Container maxWidth="xs">
              <ErrorText align="center">{error.header}</ErrorText>
              <ErrorText align="center">{error.message}</ErrorText>

              <Box mt={4}>
                <PrimaryButton fullWidth href={GLOBAL_APP_ABSOLUTE_URL}>
                  Take me back
                </PrimaryButton>
              </Box>
            </Container>
          </ErrorLayout>
        </Box>
      </GlobalFrame>
    );
  }

  if (orgState === null) {
    return (
      <LoaderLayout
        outerContainerProps={{ sx: { height: "100vh", width: "100vw" } }}
      />
    );
  }

  return <>{children}</>;
};

function useLoadOrganizationInformation() {
  const organization = useFetchOrganization();

  return useMemo(() => ({ organization }), [organization]);
}

function useFetchOrganization() {
  const fetchOrg = useFetch<OrganizationBody, "get">("get");

  const isMounted = useIsMounted();

  const request = useCallback(
    async (
      subdomain: string,
      setError: (loaderError: LoaderError) => void,
      setOrgState: (orgState: OrganizationState) => void,
    ) => {
      const errorHeader = "...when loading organization information:";

      try {
        const res = await fetchOrg.request({
          url: `${API_SERVICE_URL}/organization/${subdomain}`,
          shouldParse: true,
        });

        if (!res || res.error) {
          if (!isMounted()) {
            return;
          }

          setError({
            header: errorHeader,
            message: res?.error ? res?.error : "Something went wrong.",
          });

          return;
        }

        const { id, name, onboarded, idpTenantID } = res;

        if (!isMounted()) {
          return;
        }

        setOrgState({
          id,
          name,
          subdomain,
          onboarded,
          idpTenantID,
        });
      } catch (err) {
        // TODO: user err.message
        LoggerService.error("Error when loading organization info", err);

        if (!isMounted()) {
          return;
        }

        setError({
          header: errorHeader,
          // TODO: user err.message
          message: "Network error. Please try again later.",
        });
      }
    },
    [fetchOrg, isMounted],
  );

  return useMemo(() => ({ ...fetchOrg, request }), [request, fetchOrg]);
}
