import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const RowWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: theme.spacing(1),
}));

export const ColumnWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: theme.spacing(3),
  flexGrow: 1,
}));

const WIDTH = "150px";

export const GridWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: [WIDTH, "1fr"].join(" "),
  alignItems: "center",
  textAlign: "start",
  [theme.breakpoints.down("lg")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: theme.spacing(0.5),
  },
}));
