import type { CardInfo, Customer } from "@chargebee/chargebee-js-types";
import { useQuery } from "react-query";

import type { Subscription } from "#shared/types";

import { API_URLS } from "#global/consts";

export const useIsSubscriptionValid = (
  id: string | undefined,
  hasuraId: string | undefined,
) => {
  const getSubscription = async () => {
    if (id && hasuraId) {
      const params = new URLSearchParams({
        cf_billing_organization_id: id,
        cf_organization_id: hasuraId,
        operator: "is",
      });
      const request = await fetch(
        `${API_URLS.BILLING.SUBSCRIPTIONS}?${params.toString()}`,
      );

      return request.json();
    }

    return null;
  };

  const { data, isLoading, isError } = useQuery<
    unknown,
    unknown,
    {
      list: {
        subscription: Subscription;
        card: CardInfo;
        customer: Customer;
      }[];
    }
  >(`getSubscription-${id}-${hasuraId}`, getSubscription);

  return {
    isLoading,
    isError,
    isValid: !!(data?.list?.length ?? false),
    data,
  };
};
