import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { QUERY_PARAMS } from "#shared/consts";
import { type HttpState, useFetch } from "#shared/hooks";
import { userEmailState, userNewState } from "#shared/recoil";
import { type OrganizationMembership } from "#shared/types";
import { getOrganizationAbsoluteUrl } from "#shared/utils";

import { API_URLS } from "#global/consts";

import { useCustomToken } from "./use-organizations";

export type UseMemberships = {
  httpState: HttpState<string | null>;
  result: OrganizationMembership[] | null;
  request: () => void;
  reset: () => void;
};

export const useMemberships = (): UseMemberships => {
  const [memberships, setMemberships] = useState<UseMemberships["result"]>([]);

  const navigate = useNavigate();

  const { httpState, request, result, reset, updateHttpState } =
    useFetch<OrganizationMembership[]>();

  const firebaseEmail = useRecoilValue(userNewState.email);
  const email = useRecoilValue(userEmailState);
  const token = useRecoilValue(userNewState.firebaseToken);

  const emailQueryParam = firebaseEmail ?? "";

  const requestMemberships = useMemo(
    () => () => {
      if (httpState.isSuccess || httpState.isLoading || !httpState.isIdle) {
        return;
      }

      if (!emailQueryParam) {
        updateHttpState.setError(
          "Failed to fetch organizations due to missing email.",
        );

        return;
      }

      const url = new URL(API_URLS.ORGANIZATIONS.MEMBERSHIPS);

      url.searchParams.append(QUERY_PARAMS.EMAIL, emailQueryParam);

      request({
        url,
        shouldParse: true,
      });
    },
    [
      emailQueryParam,
      httpState.isIdle,
      httpState.isLoading,
      httpState.isSuccess,
      request,
      updateHttpState,
    ],
  );

  useEffect(() => {
    if (httpState.isSuccess && result) {
      setMemberships(
        result.map((membership) => ({
          ...membership,
          Organization: {
            ...membership.Organization,
            link: getOrganizationAbsoluteUrl(
              membership.Organization.domain_name,
            ),
          },
        })),
      );
    }
  }, [result, navigate, httpState.isSuccess, emailQueryParam]);

  const membershipOrganizations = useMemo(
    () => memberships?.map((membership) => membership.Organization) || [],
    [memberships],
  );

  const orgs = useCustomToken(
    membershipOrganizations,
    {
      headers: token ? { Authorization: token } : {},
    },
    firebaseEmail || email || "",
  );

  return useMemo<UseMemberships>(
    () => ({
      result:
        memberships?.map((membership, index) => ({
          ...membership,
          Organization: orgs[index],
        })) || null,
      httpState,
      request: requestMemberships,
      reset,
    }),
    [memberships, httpState, requestMemberships, reset, orgs],
  );
};
