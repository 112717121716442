import axios, { type AxiosResponse } from "axios";
import { gql } from "graphql-request";
import { useCallback, useMemo } from "react";
import { useQuery, type UseQueryOptions } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { ChargebeePlans, useBillingPageInfo } from "#shared/billing";
import type { GetProjectNamesQuery } from "#shared/generated/graphql";
import { userNewState } from "#shared/recoil";
import { useGqlQuery } from "#shared/utils";
import { httpClient } from "#shared/utils/http-client";

import { API_URLS, QUERY_KEYS } from "#organization/pages/consts";
import { useOrganizationState } from "#organization/recoil/organization";

export const usePlanID = () => {
  const { id } = useOrganizationState();

  const {
    data: subscriptionInfo,
    isLoading: isLoadingBillingInfo,
    isError: isErrorBillingInfo,
  } = useBillingPageInfo({ organization_id: id });

  const planID = useMemo(() => {
    const subscription = subscriptionInfo?.data.list.find(
      (subs) =>
        subs.subscription.subscription_items?.find(
          (subItem) => subItem.item_type === "plan",
        ),
    );

    return (
      ChargebeePlans.find(
        (plan) =>
          subscription?.subscription.subscription_items?.find(
            (subItem) =>
              subItem.item_type === "plan" &&
              // The subscriptions endpoint does not have the plan ID, but instead has the plan price ID (item_price_id)
              // So we check if item_price_id starts with the plan ID to get the plan ID
              // E.g: Pro-cloud-USD-Monthly (Plan price ID) -> Pro-cloud (Plan ID)
              subItem.item_price_id?.startsWith(plan.planID),
          ),
        // Default to first plan if unavailable
      )?.planID || ChargebeePlans[0].planID
    );
  }, [subscriptionInfo?.data.list]);

  return {
    isLoadingPlan: isLoadingBillingInfo,
    isErrorPlan: isErrorBillingInfo,
    planID,
  };
};

const GetProjectNames = gql`
  query GetProjectNames($where: ProjectBoolExp) {
    projects(where: $where) {
      edges {
        node {
          name
          id
        }
      }
    }
  }
`;

export const useGetProjectNames = (projectID: string[] | undefined) => {
  const { isError, isLoading, refetch, data } =
    useGqlQuery<GetProjectNamesQuery>(
      [QUERY_KEYS.PROJECT_NAMES, projectID],
      GetProjectNames,
      { where: { or: projectID?.map((id) => ({ id: { eq: id } })) } },
      { enabled: !!projectID?.length },
    );

  return {
    error: isError,
    loading: isLoading,
    refetch,
    data: data?.projects.edges,
  };
};

type CancelSubscriptionBody = {
  organization_id: string;
  subscription_id: string;
  redirect_url: string;
  user: {
    email: string;
  };
};

type CancelSubscriptionResp = {
  success: true;
  subscription_id: string;
};

export const useCancelSubscription = () => {
  const { id: orgHasuraID } = useOrganizationState();

  const firebaseEmail = useRecoilValue(userNewState.email);
  const [searchParams, setSearchParams] = useSearchParams();

  const cancelSubscription = useCallback(
    (subscriptionID: string) =>
      httpClient
        .post<CancelSubscriptionResp, false, CancelSubscriptionBody>({
          url: API_URLS.BILLING.CANCEL_SUBSCRIPTION,
          body: {
            organization_id: orgHasuraID,
            subscription_id: subscriptionID,
            redirect_url: window.location.href,
            user: {
              email: firebaseEmail || "",
            },
          },
        })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to cancel subscription`);
          }

          return response.json();
        })
        .then((resp) => {
          setSearchParams({
            ...searchParams,
            state: "succeeded",
            action: "cancel",
          });

          return resp;
        }),
    [firebaseEmail, orgHasuraID, searchParams, setSearchParams],
  );

  return {
    cancelSubscription,
  };
};

export declare type IsSubscribedData = {
  email?: string;
  organization_id?: string;
};

export const useIsSubscribed = (
  { organization_id, email }: IsSubscribedData = {},
  isEnabled = true,
  options: UseQueryOptions<AxiosResponse<boolean>> = {},
) => {
  const { id } = useOrganizationState();

  const orgID = organization_id || id;

  const params = useMemo(
    () =>
      new URLSearchParams({
        cf_organization_id: orgID,
        organizationId: orgID,
        operator: "is",
      }),
    [orgID],
  );

  const firebaseEmail = useRecoilValue(userNewState.email);
  const firebaseToken = useRecoilValue(userNewState.firebaseToken);

  const dataBody = { user: { email: email || firebaseEmail } };

  return useQuery({
    queryKey: ["isSubscribed", orgID, firebaseEmail],
    queryFn: () =>
      axios({
        url: [API_URLS.BILLING.IS_SUBSCRIBED, params.toString()].join("?"),
        method: "POST",
        data: dataBody,
        headers: {
          Authorization: `Bearer ${firebaseToken}`,
        },
      }),
    enabled: !!dataBody.user.email && isEnabled,
    ...options,
  });
};
