import { Box, Container } from "@mui/material";
import React, { type ReactElement } from "react";

import { ErrorLayout, ErrorText } from "#shared/components/layouts/error";
import { ExternalTextLink } from "#shared/components/text-link";
import { APP_PORTS } from "#shared/dev-config";
import { ENV } from "#shared/env";
import { getGlobalAppUrl } from "#shared/utils/urls";

/**
 * Ensures that the URL matches the expected organization's UI URL.
 * Only affects development environment.
 */
export const verifyUrlInDevMode = (children: ReactElement) => {
  if (!ENV.DEV) {
    return children;
  }

  const { hostname, port, host } = window.location;

  const portMatches = parseInt(port, 10) === APP_PORTS.organization;
  const correctDomain = hostname.endsWith(ENV.VITE_APP_DOMAIN);
  const subdomainExists =
    hostname !== ENV.VITE_APP_DOMAIN && hostname.includes(".");

  if (!portMatches || !correctDomain || !subdomainExists) {
    const correctUrl =
      // NOTE: there is no easy logic to figure out the correct hostname
      correctDomain && subdomainExists
        ? getCorrectedOrganizationAppUrl(window.location.href)
        : null;

    return (
      <ErrorLayout>
        <Container maxWidth="sm">
          <Box textAlign="center">
            <ErrorText gutterBottom>
              You are accessing the organization application using an unexpected
              URL (<ExternalTextLink>{host}</ExternalTextLink>).
            </ErrorText>

            {!portMatches && (
              <ErrorText gutterBottom>The port does not match.</ErrorText>
            )}
            {!correctDomain && (
              <ErrorText gutterBottom>The domain does not match.</ErrorText>
            )}
            {!subdomainExists && (
              <ErrorText gutterBottom>
                There is no subdomain specified.
              </ErrorText>
            )}

            <ErrorText>
              {correctUrl ? (
                <>
                  The organization application is available at{" "}
                  <ExternalTextLink href={correctUrl.href}>
                    {correctUrl.host}
                  </ExternalTextLink>
                </>
              ) : (
                <>
                  Try to adjust it manually or go to{" "}
                  <ExternalTextLink href={getGlobalAppUrl("/")}>
                    the global application
                  </ExternalTextLink>{" "}
                  first.
                </>
              )}
            </ErrorText>
          </Box>
        </Container>
      </ErrorLayout>
    );
  }

  return children;
};

const getCorrectedOrganizationAppUrl = (href: string) => {
  const correctUrl = new URL(href);
  correctUrl.port = `${APP_PORTS.organization}`;

  return correctUrl;
};
