import { useTheme } from "@mui/material";
import { withSentryReactRouterV6Routing } from "@sentry/react";
import React, {
  useEffect,
  type FC,
  type PropsWithChildren,
  useState,
} from "react";
import { Route, Routes } from "react-router-dom";

import { LoaderLayout } from "#shared/components/layouts/loader";
import { useCheckAuthn } from "#shared/recoil";

import { GrafanaProvider } from "#organization/components/grafana";

import { AuthenticatedPages } from "./authenticated";
import { useAutoSignIn } from "./hooks";
import { UnauthenticatedPagesFirebase } from "./unauthenticated";

/**
 *
 * Adding hard refresh on login to fix grafana loading issue.
 * Reload flag is reset on logout.
 */
const RefreshOnSignIn: FC<PropsWithChildren> = ({ children }) => {
  const [showApp, setShowApp] = useState<Boolean>(false);

  useEffect(() => {
    const reloadFlag = window.localStorage.getItem("reloadFlag");

    if (!reloadFlag?.length) {
      window.localStorage.setItem("reloadFlag", "true");
      window.location.reload();
    } else {
      setShowApp(true);
    }
  }, []);

  if (!showApp) {
    return <LoaderLayout />;
  }

  return <>{children}</>;
};

const Firebase: FC = () => {
  const { isLoading, isAuthenticatedWithFn } = useCheckAuthn();
  const { isLoading: autoSignInLoading } = useAutoSignIn();

  if (isLoading || autoSignInLoading) {
    return (
      <LoaderLayout outerContainerProps={{ height: "100vh", width: "100vw" }} />
    );
  }

  return isAuthenticatedWithFn ? (
    <RefreshOnSignIn>
      <AuthenticatedPages />
    </RefreshOnSignIn>
  ) : (
    <UnauthenticatedPagesFirebase />
  );
};

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

export const OrganizationAppRoutes: FC = () => {
  const {
    palette: { mode },
  } = useTheme();

  return (
    <GrafanaProvider {...{ mode }}>
      <SentryRoutes>
        <Route path="/*" element={<Firebase />} />
      </SentryRoutes>
    </GrafanaProvider>
  );
};
