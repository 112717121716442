import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";

import { API_ACCOUNT_URLS } from "#shared/consts";
import type { TenantIdpConfigResponse } from "#shared/types";
import { httpClient } from "#shared/utils/http-client";

import { QUERY_KEYS } from "#organization/pages/consts";
import { organizationState } from "#organization/recoil/organization";

export const useGetIdpConfigs = () => {
  const organization = useRecoilValue(organizationState);

  return useQuery<TenantIdpConfigResponse>({
    queryKey: QUERY_KEYS.CONFIGURED_IDP_PROVIDERS,
    queryFn: async () => {
      const response = await httpClient.get<TenantIdpConfigResponse>({
        url: new URL(
          API_ACCOUNT_URLS.TENANTS.CONFIG(organization?.idpTenantID),
        ),
      });

      return response.json();
    },
  });
};
