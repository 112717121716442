import { gql, type Variables } from "graphql-request";
import { useQuery } from "react-query";

import type { ControlPointsQuery } from "#shared/generated/graphql";
import { gqlRequest } from "#shared/utils";

export const controlPointsQuery = gql`
  query ControlPoints($distinctOn: [ControlPointSelectColumn!]) {
    controlPoints(distinctOn: $distinctOn) {
      edges {
        node {
          name
        }
      }
    }
  }
`;

export const fetchControlPoints = async () => {
  const query = await gqlRequest<ControlPointsQuery, Variables>(
    controlPointsQuery,
    {
      distinctOn: ["name"],
    },
  );

  return query.controlPoints.edges
    .map((edge) => edge.node.name)
    .filter((name) => name.trim());
};

export const useUniqueControlPointNames = () =>
  useQuery<string[]>({
    queryKey: ["controlPoints", "distinctOn", "name"],
    queryFn: fetchControlPoints,
  });
