import { alpha, Box, Chip, Radio } from "@mui/material";
import { styled } from "@mui/system";

export const FilterChip = styled(Chip)(({ theme }) => ({
  backgroundColor: "transparent",
  color: "primary.light",
  border: `1px solid ${theme.palette.primary.light}`,
  height: "24px",
}));

export const FilterBody = styled(Box)(() => ({
  padding: "8px 16px 8px 16px",
  display: "grid",
  gridTemplateRows: "50px 1fr",
  gridTemplateColumns: "1fr",
  maxHeight: "340px",
}));

export const ChipsWrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  alignSelf: "center",
  alignItems: "center",
  columnGap: "10px",
  rowGap: "10px",
  width: "100%",
  padding: "5px 5px 5px 5px",
  height: "100%",
}));

export const InputGrid = styled(Box)(() => ({
  backgroundColor: "#E6EBF5",
  display: "grid",
  gridTemplateColumns: "120px 1fr",
  height: "auto",
  alignItems: "top",
  cursor: "pointer",
}));

export const FilterPopperBox = styled(Box)(({ theme }) => ({
  borderRadius: "4px",
  backgroundColor: theme.palette.background.secondNavigation,
  border: `0.5px solid ${alpha(theme.palette.primary.main, 0.5)}`,
  marginTop: "20px",
}));

export const FilterPopperHeader = styled(Box)(({ theme }) => ({
  textTransform: "uppercase",
  display: "grid",
  alignItems: "center",
  padding: "0 10px 0 10px",
  background: alpha(theme.palette.primary.main, 0.15),
  borderBottom: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
  borderTop: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
  fontWeight: "bold",
  fontSize: "13px",
}));

export const FilterRadio = styled(Radio)(() => ({
  "& .MuiSvgIcon-root": {
    fontSize: "18px",
  },
}));
