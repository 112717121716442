import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { type ListItemButtonProps, type BoxProps } from "@mui/material";
import React, { type FC, type PropsWithChildren } from "react";

import { ListItemButton, ListItemTextStyled } from "./menu-list-button.styled";

export type MenuListButtonProps = PropsWithChildren<
  ListItemButtonProps & {
    listItemTextProps?: Omit<BoxProps, "children">;
    isOpen?: boolean;
  }
>;

export const MenuListButton: FC<MenuListButtonProps> = ({
  children,
  listItemTextProps,
  isOpen,
  ...listItemButtonProps
}) => (
  <ListItemButton {...listItemButtonProps}>
    <ListItemTextStyled {...listItemTextProps}>{children}</ListItemTextStyled>
    {isOpen ? <ArrowDropDown /> : <ArrowDropUp />}
  </ListItemButton>
);
