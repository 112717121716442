import { get } from "lodash";
import React, {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";

import { useProjectContextState } from "#organization/recoil/project";

import type { UseAlertsOrganizationConfigs } from "./hooks";

export interface ProviderInitialState {
  reFetchFlag: {
    fetchFlag: boolean;
    setFetchFlag: (flagState: boolean) => void;
  };
  alertsOrganizationConfigID: string;
  projectID: string;
}

const initialState: ProviderInitialState = {
  reFetchFlag: { fetchFlag: false, setFetchFlag: () => {} },
  alertsOrganizationConfigID: "",
  projectID: "",
};

export const DataCenterContext = createContext(initialState);

export type DataCenterProviderProps = {
  useAlertsOrganizationConfigs: UseAlertsOrganizationConfigs;
} & PropsWithChildren;

export const DataCenterProvider: FC<DataCenterProviderProps> = ({
  children,
  useAlertsOrganizationConfigs,
}) => {
  const [state, setStatusFlag] = useState<ProviderInitialState>(initialState);
  const { id } = useProjectContextState();
  const { data, isError } = useAlertsOrganizationConfigs(id);

  const alertsOrganizationConfigID =
    !isError && data
      ? get(data, "alertsOrganizationConfigs.edges[0].node.id") || ""
      : "";

  const value = useMemo(() => {
    const refetch = (flagState: boolean) => {
      setStatusFlag((prev) => ({
        ...prev,
        reFetchFlag: { fetchFlag: flagState, setFetchFlag: refetch },
      }));
    };

    return {
      ...state,
      reFetchFlag: {
        fetchFlag: state.reFetchFlag.fetchFlag,
        setFetchFlag: refetch,
      },
      projectID: id,
      alertsOrganizationConfigID,
    };
  }, [state, id, alertsOrganizationConfigID]);

  return (
    <DataCenterContext.Provider value={value}>
      {children}
    </DataCenterContext.Provider>
  );
};

export const useDataCenterContext = () => useContext(DataCenterContext);
