type ViteKey = Extract<keyof FnEnv, "VITE_FN_VERSION" | "VITE_APP">;

/**
 * NOTE:
 *
 * Read from env.js
 */
type EnvKey = Extract<
  keyof FnEnv,
  "SENTRY_DSN" | "SENTRY_ENVIRONMENT" | "SENTRY_TRACES_SAMPLE_RATE"
>;

const DEFAULT_FN_ENV: Omit<
  FnEnv,
  ViteConfigKey | EnvKey | ViteKey | Dashboard
> & {
  BASE_URL: string;
} & Partial<Pick<FnEnv, "VITE_APP">> = {
  BASE_URL: "/",
  IS_WAIT_FOR_CLOUD_SIGN_UP: false,
  GLOBAL_BILLING_ENABLED: "false",
  VITE_APP_DOMAIN: "localfluxninja.com",
  VITE_APP_TITLE: "FluxNinja",
  VITE_IDP_URL: "http://localhost:8086",
  FN_VERSION: VITE_FN_VERSION || window?.VITE_FN_VERSION || "unknown",
  FIREBASE_API_KEY: FIREBASE_API_KEY || "unknown",
  FIREBASE_DOMAIN: FIREBASE_DOMAIN || "unknown",
  IS_DEV: "false",
  LOG_LEVEL: "debug",
  MAX_CHECK_PROVISIONING_BACKOFF_MS: 10 * 1000,
  TWILIO_SEGMENTS_API_KEY: TWILIO_SEGMENTS_API_KEY || "unknown",
  CHARGEBEE_SITE: CHARGEBEE_SITE || "unknown",
};

export const ENV: Omit<FnEnv, ViteKey | Dashboard> &
  Partial<Pick<FnEnv, "VITE_APP">> = {
  ...DEFAULT_FN_ENV,
  ...import.meta.env,
  ...((window.ENV as Pick<FnEnv, EnvKey>) || {}),
};
