import { difference } from "lodash";

import type { DashboardType } from "#organization/pages/consts";
import {
  httpDashboardQueryParams,
  prometheusDashboardQueryParams,
  signalDashboardQueryParams,
  type DashboardQueryParam,
  rateLimiterDashboardQueryParams,
  concurrencyLimiterDashboardQueryParams,
} from "#organization/pages/types";

function getHiddenFilters<F extends string = DashboardQueryParam>(
  availableFilters: F[] | ReadonlyArray<F>,
) {
  return (enabledFilters: F[] | ReadonlyArray<F>) =>
    difference([...availableFilters], [...enabledFilters]) as F[];
}

export type GenericDashboardType = Extract<
  DashboardType,
  | "FLOW_ANALYTICS"
  | "FLOW_ANALYTICS_BY_FLOW_LABEL"
  | "PROMETHEUS"
  | "SIGNAL"
  | "FLOW_MAP"
  | "RATE_LIMITER"
  | "CONCURRENCY_LIMITER"
>;

export const HIDE_FILTERS_BY_DASHBOARD_TYPE: {
  [D in GenericDashboardType]: (
    enabledFilters: DashboardQueryParam[] | ReadonlyArray<DashboardQueryParam>,
  ) => DashboardQueryParam[];
} = {
  FLOW_ANALYTICS: getHiddenFilters(httpDashboardQueryParams),
  FLOW_ANALYTICS_BY_FLOW_LABEL: getHiddenFilters(httpDashboardQueryParams),
  PROMETHEUS: getHiddenFilters(prometheusDashboardQueryParams),
  SIGNAL: getHiddenFilters(signalDashboardQueryParams),
  FLOW_MAP: getHiddenFilters(httpDashboardQueryParams),
  RATE_LIMITER: getHiddenFilters(rateLimiterDashboardQueryParams),
  CONCURRENCY_LIMITER: getHiddenFilters(concurrencyLimiterDashboardQueryParams),
};

export const GRAFANA_PROVIDER_VIEWPORT_ID = "grafana-provider-viewport";

export const GRAFANA_APP_NAME = "grafana-full-app";
export const GRAFANA_PORTAL_DIV = "grafana-portal";
