import { type Either, left, right } from "fp-ts/lib/Either";

/**
 * Returns the organization's subdomain based on the app domain
 *
 * @param host See `location.host`
 */
export const getOrganizationSubdomain = (
  appDomain: string,
  host: string,
): Either<string, string> => {
  if (!host.includes(appDomain)) {
    return left(`Unexpected host - ${appDomain} is not included in the URL`);
  }

  const [hostDomainPrefix] = host.split(appDomain);

  if (hostDomainPrefix.length === 0) {
    return left("No subdomain provided in the URL");
  }

  const hostDomainPrefixWithoutFinalDot = hostDomainPrefix.slice(0, -1);
  const domainPrefixParts = hostDomainPrefixWithoutFinalDot.split(".");

  return right(domainPrefixParts[domainPrefixParts.length - 1]);
};
