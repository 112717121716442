import { Box } from "@mui/system";
import React, { useState, type FC } from "react";
import { useQueryClient } from "react-query";
import { useRecoilState } from "recoil";

import { PrimaryButton } from "#shared/components/buttons";
import type { FilterLabelsType } from "#shared/components/filtering";
import {
  FilteringGroup,
  useChipsContainer,
} from "#shared/components/filtering/filtering-group";
import { useParseLinkAttributesToFilterVariables } from "#shared/components/filtering/utils";
import { useControlDialog } from "#shared/components/layouts/dialog";
import {
  ApertureSecretSelectColumn,
  type SecretsGroupDataQuery,
} from "#shared/generated/graphql";
import type { Texts } from "#shared/types";

import {
  DataGrid,
  DataGridGroups,
  DataGridWrapper,
  FiltersBarWrapper,
  GroupByButtonWrapper,
  GroupBySelect,
  type GroupByOption,
} from "#organization/components/data-grid";
import { MainPageLayout } from "#organization/components/layouts";
import { useUgMemberPermission } from "#organization/pages/authenticated/settings/account/hooks";
import { QUERY_KEYS } from "#organization/pages/consts";
import { projectContextState } from "#organization/recoil/project";

import { AddSecretDialog } from "./add-secret-dialog";
import { DeleteSecretDialog } from "./delete-secret-dialog";
import {
  SECRETS_ENTITY_KEY,
  useSecretGroupData,
  useSecretGroupsTitles,
  useSecretsData,
} from "./hooks";
import { headCells } from "./table";
import { filters } from "./table/filter";

const GROUP_BYS: GroupByOption<ApertureSecretSelectColumn>[] = [
  {
    title: "No value",
    value: "",
  },
  /* {
    title: 'Name',
    value: ApertureSecretSelectColumn.Name
  } */
];

export interface SecretsPageProps {
  texts?: Texts<Text>;
}

type Secret = SecretsGroupDataQuery["apertureSecrets"]["edges"][number]["node"];

const INITIAL_ORDER_BY: keyof Secret = "createdAt";
const INITIAL_ORDER = "desc";
const TABLE_UNIQUE_ID = "secrets-table";

type Text = "create";

const enTexts: Required<SecretsPageProps["texts"]> = {
  create: "Create New Secret",
};

export const SecretsPage: FC<SecretsPageProps> = ({ texts = enTexts }) => {
  const chipsContainer = useChipsContainer();
  const [projectContext] = useRecoilState(projectContextState);
  const filterHeaders: FilterLabelsType[] = filters(projectContext.id);
  const [filterVariables, setFilterVariables] = useState(
    useParseLinkAttributesToFilterVariables(TABLE_UNIQUE_ID),
  );

  const [groupByKey, setGroupByKey] =
    useState<(typeof GROUP_BYS)[number]["value"]>("");

  const deleteSecretDialog = useControlDialog();
  const addSecretDialog = useControlDialog();

  const [selectedSecretID, setSelectedSecretID] = useState<string>();

  /* Members cannot create secrets if the user group does not have write permissions */
  const permissionLock = useUgMemberPermission();

  const openDeleteSecretDialog = (secretID: typeof selectedSecretID) => {
    setSelectedSecretID(secretID);
    deleteSecretDialog.open();
  };

  const openAddSecretDialog = () => {
    setSelectedSecretID(undefined);
    addSecretDialog.open();
  };

  const headerTable = headCells(openDeleteSecretDialog);

  const queryClient = useQueryClient();
  const refetch = () =>
    queryClient.invalidateQueries([QUERY_KEYS.DATA_GRID, SECRETS_ENTITY_KEY]);

  return (
    <MainPageLayout>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 2,
          pb: 2,
        }}
      >
        <PrimaryButton disabled={permissionLock} onClick={openAddSecretDialog}>
          {texts.create}
        </PrimaryButton>
      </Box>

      <FiltersBarWrapper>
        <FilteringGroup
          labels={filterHeaders}
          onFilter={setFilterVariables}
          chipsContainer={chipsContainer}
          uniqueId={TABLE_UNIQUE_ID}
        />
        <GroupByButtonWrapper>
          <GroupBySelect
            options={GROUP_BYS}
            setGroupByKey={setGroupByKey}
            groupByKey={groupByKey}
          />
        </GroupByButtonWrapper>
      </FiltersBarWrapper>

      <Box py={4}>
        <DataGridWrapper>
          {groupByKey ? (
            <DataGridGroups
              headCells={headerTable}
              useGroupsTitles={useSecretGroupsTitles}
              useGroupData={useSecretGroupData}
              groupByKey={groupByKey}
              filterVariables={filterVariables}
              initialOrderBy={INITIAL_ORDER_BY}
              initialOrder={INITIAL_ORDER}
            />
          ) : (
            <DataGrid
              headCells={headerTable}
              useGridData={useSecretsData}
              enabled={!groupByKey}
              filterVariables={filterVariables}
              initialOrderBy={INITIAL_ORDER_BY}
              initialOrder={INITIAL_ORDER}
            />
          )}
        </DataGridWrapper>
      </Box>

      <DeleteSecretDialog
        secretID={selectedSecretID}
        dialogControl={deleteSecretDialog}
        onDeleteSecret={refetch}
      />

      <AddSecretDialog
        dialogControl={addSecretDialog}
        onSecretCreated={refetch}
      />
    </MainPageLayout>
  );
};
