import type { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Box, Typography } from "@mui/material";
import React, { type FC } from "react";

interface SectionHeaderType {
  title: string;
  action?: undefined | ReactJSXElement;
  filter?: undefined | ReactJSXElement;
}

export const SectionHeader: FC<SectionHeaderType> = ({
  title,
  action,
  filter,
}) => (
  <Box
    color="primary"
    mb={1}
    sx={{
      display: "grid",
      gridTemplateColumns: "auto 1fr repeat(2, auto)",
      alignItems: "center",
      padding: "11px 10px 11px 10px",
      borderBottom: "1.5px",
      borderBottomColor: "primary.main",
      borderBottomStyle: "solid",
    }}
  >
    <Typography variant="body1" fontWeight="600" color="primary.main">
      {title}
    </Typography>
    <Box />
    {filter}
    {action}
  </Box>
);

SectionHeader.defaultProps = {
  action: undefined,
  filter: undefined,
};
