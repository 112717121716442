import { gql } from "graphql-request";

import { useGqlMutation } from "#shared/utils";

/**
 *
 * Mutation that allow changing user role
 * input = { id: membershipId, role: string }
 *
 */

export const useChangeUserRole = () => {
  const { mutate, error, data } = useGqlMutation<
    unknown,
    unknown,
    { id: string | undefined; role: string | undefined }
  >(ChangeUserRole);

  return {
    change: mutate,
    data,
    error,
  };
};

const ChangeUserRole = gql`
  mutation ChangeUserRole($input: UpdateUserRoleInput!) {
    updateUserRole(input: $input) {
      organizationMembership {
        role
      }
    }
  }
`;
