import { Typography } from "@mui/material";
import React, { type Dispatch, type SetStateAction } from "react";

import { emptyColumnValue, type HeadCell } from "#shared/components/data-grid";

import { ArchivedStatus } from "#organization/components";
import { PolicyCountFinder } from "#organization/pages/authenticated/control-points/tabs/flow-control/table";
import { globalTimeParser } from "#organization/utils";

import type { CacheDashboardConfig } from "../../../cache-dashboard/types";
import type { AdaptedResultCacheData } from "../hooks";

export const headCells = (
  setIsFlyoutMenuVisible: Dispatch<SetStateAction<boolean>>,
  setCacheDashboardConfig: Dispatch<SetStateAction<CacheDashboardConfig>>,
): HeadCell<AdaptedResultCacheData>[] => [
  {
    accessor: (row) => (
      <ArchivedStatus status={row.status}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            cursor: "pointer",
            "&:hover": { color: (theme) => theme.palette.primary.main },
          }}
          onClick={() => {
            setCacheDashboardConfig({
              controllerId: row.service?.controller.name || "",
              controlPoint: row.name,
              agentGroup: row.service?.agentGroup || "",
              type: "result",
            });

            setIsFlyoutMenuVisible(true);
          }}
        >
          {row.name || emptyColumnValue}
        </Typography>
      </ArchivedStatus>
    ),
    label: "Control point",
    key: "name",
  },
  {
    accessor: (row) => <PolicyCountFinder row={row} />,
    label: "Policies",
    key: "policesCount",
    noSorting: true,
  },
  {
    accessor: (row) => row.service?.agentGroup,
    label: "Agent group",
    key: "agentGroup",
  },
  {
    accessor: (row) => row.service?.controller.name,
    label: "Controller",
    key: "controller",
  },
  {
    accessor: (row) => globalTimeParser(row.lastSeen),
    label: "Last seen",
    key: "lastSeen",
  },
];
