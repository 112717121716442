import { DeleteSweep } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { format } from "date-fns";
import React from "react";

import type { HeadCell } from "#shared/components/data-grid";
import { PermissionEnum } from "#shared/recoil";

import { PermissionWrapper } from "#organization/components/permission-wrapper";

import type { AdaptedSecretGroupData } from "../hooks";

export const headCells = (
  onDeleteSecret: (secretID: string) => void,
): HeadCell<AdaptedSecretGroupData>[] => [
  {
    accessor: "name",
    label: "Name",
    key: "name",
  },
  {
    accessor: "value",
    label: "Value",
    key: "value",
  },
  {
    accessor: (row) => (row.createdBy ? row.createdBy.name : "FluxNinja Admin"),
    label: "Created by",
    key: "createdBy.name",
  },
  {
    accessor: (row) => format(new Date(row.createdAt), "dd/MM/yyyy HH:mm:ss"),
    label: "Creation date",
    key: "createdAt",
  },
  {
    accessor: (row) => (
      <Box>
        <PermissionWrapper level={PermissionEnum.user_group_admin}>
          <Button
            color="error"
            startIcon={<DeleteSweep />}
            onClick={() => onDeleteSecret(row.id)}
          >
            Delete
          </Button>
        </PermissionWrapper>
      </Box>
    ),
    label: "Actions",
    key: "delete-secret",
    noSorting: true,
  },
];
