import { CodeSnippet } from "#organization/components"

export const language = "ts";

# Workload Label Key

## Overview

`workload_label_key` is a key for a flow label that can be used to provide workloads for this request. If this parameter is not provided, the workloads for the flow will be determined by the matched workload's name in the policy.

## Example

<CodeSnippet lang={language} snippetName="UIQSWorkload" />
