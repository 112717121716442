import { Box, type BoxProps } from "@mui/material";
import { lowerCase } from "lodash";
import React, {
  type FC,
  type ComponentType,
  useState,
  useRef,
  useContext,
  useMemo,
  useEffect,
} from "react";
import { useReactFlow } from "react-flow-renderer";

import {
  type EventOutsideNodeCallback,
  useEventOutsideNode,
} from "#shared/hooks";
import type { Texts } from "#shared/types";

import { CIRCUIT_ANIMATION_DURATION } from "#organization/pages/authenticated/policies/components/const";

import { type AdornmentProps, EndAdornment, StartAdornment } from "./adornment";
import { SearchBarList } from "./search-bar-list";
import { SearchBarInput, OnSelectStartAdornment } from "./styled";

import type { PolicyMenuListProps } from "../../../../components";
import type { FlowChartStateResults } from "../../../../config-flow-chart";
import { PolicyUtils } from "../../../../policy-utils";
import {
  type CircuitViewResource,
  SelectedResourcesContext,
} from "../../../../selected-resource-context";

export interface SearchBarProps {
  policy: PolicyMenuListProps["policy"];
  texts?: Texts<Text>;
  flowChart: FlowChartStateResults;
  Container?: ComponentType<Omit<BoxProps, "children">>;
  containerProps?: Omit<BoxProps, "children">;
}

type Text = "placeholder" | "actuators" | "signals";

const enTexts: Required<SearchBarProps["texts"]> = {
  placeholder: "Search",
  actuators: "Actuators",
  signals: "Signals",
};

const INPUT_ID = "fn-policy-circuit-search-box";

export const SearchBar: FC<SearchBarProps> = ({
  policy,
  flowChart,
  texts = enTexts,
  Container = Box,
  containerProps,
}) => {
  const [showList, setShowList] = useState(false);

  const [search, setSearch] = useState("");
  const { setViewport } = useReactFlow();

  const searchBarRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const eventOutsideNodeCallback: EventOutsideNodeCallback = useMemo(
    () => (isWithinNode, event) => {
      if (isWithinNode) {
        return;
      }

      if (showList) {
        event.preventDefault();
        event.stopPropagation();
        setShowList(false);
      }
    },
    [showList, setShowList],
  );

  useEventOutsideNode(
    [searchBarRef, dropdownRef],
    "click",
    eventOutsideNodeCallback,
  );

  const { resetSelectedResources, selectedResource } = useContext(
    SelectedResourcesContext,
  );

  const [selectedResourceType, [resource] = []] = selectedResource || [];

  const resourceTypeAdornmentTypeMap: {
    [Resource in CircuitViewResource]: string;
  } = useMemo(
    () => ({
      ConcurrencyLimiter: texts.actuators,
      RateLimiter: texts.actuators,
      Signal: texts.signals,
    }),
    [texts],
  );

  const isOnSelectStartAdornment =
    selectedResourceType &&
    PolicyUtils.circuitViewResources.includes(
      selectedResourceType as CircuitViewResource,
    );

  const adornmentType =
    resourceTypeAdornmentTypeMap[selectedResourceType as CircuitViewResource];

  const adornmentValue = resource?.uiData?.name || "";

  const startAdornment = isOnSelectStartAdornment ? (
    <OnSelectStartAdornment
      type={adornmentType}
      value={
        selectedResourceType === "Signal"
          ? lowerCase(adornmentValue)
          : adornmentValue
      }
    />
  ) : (
    <StartAdornment />
  );

  useEffect(() => {
    if (!isOnSelectStartAdornment) {
      return;
    }

    setSearch("");
  }, [isOnSelectStartAdornment]);

  const onEndAdornmentClick: AdornmentProps["onClick"] = useMemo(
    () => () => {
      if (showList) {
        setShowList(false);
      }

      if (search) {
        setSearch("");
      }

      if (selectedResourceType) {
        resetSelectedResources();

        if (selectedResourceType !== "Signal") {
          setViewport(
            { x: 100, y: 100, zoom: 0.5 },
            { duration: CIRCUIT_ANIMATION_DURATION },
          );
        }
      }
    },
    [
      showList,
      search,
      selectedResourceType,
      setShowList,
      setSearch,
      resetSelectedResources,
      setViewport,
    ],
  );

  const endAdornment = useMemo(
    () =>
      showList || search || selectedResource ? (
        <EndAdornment onClick={onEndAdornmentClick} />
      ) : null,
    [onEndAdornmentClick, search, selectedResource, showList],
  );

  return (
    <Container sx={{ position: "relative" }} {...containerProps}>
      <SearchBarInput
        width="100%"
        autoComplete="off"
        ref={searchBarRef}
        onFocus={() => {
          setShowList(true);
        }}
        value={search}
        onChange={({ target: { value } }) => {
          // NOTE: Disable input when some resource is selected
          if (selectedResourceType) {
            return;
          }

          setSearch(value);
        }}
        size="small"
        placeholder={
          !search.trim() && !selectedResourceType ? texts.placeholder : ""
        }
        InputProps={{
          id: INPUT_ID,
          startAdornment,
          endAdornment,
        }}
      />
      {showList && !selectedResourceType && (
        <SearchBarList
          {...{
            policy,
            search,
            setShowList,
            flowChart,
            ref: dropdownRef,
          }}
        />
      )}
    </Container>
  );
};
