import { Typography } from "@mui/material";
import React, { type Dispatch, type SetStateAction } from "react";

import { emptyColumnValue, type HeadCell } from "#shared/components/data-grid";
import { HealthStatus } from "#shared/components/data-grid/components/health-status";

import { ArchivedStatus } from "#organization/components";
import { globalTimeParser } from "#organization/utils";

import type { Data } from "./types";

import type { ControllersFlyoutMenuConfig } from "../types";

export const headCells = (
  setIsFlyoutMenuVisible: Dispatch<SetStateAction<boolean>>,
  setSelectedControllerId: Dispatch<
    SetStateAction<ControllersFlyoutMenuConfig>
  >,
): HeadCell<Data>[] => [
  {
    accessor: (row) => (
      <ArchivedStatus status={row.status}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            cursor: "pointer",
            "&:hover": { color: (theme) => theme.palette.primary.main },
          }}
          onClick={() => {
            setSelectedControllerId({
              id: row.id,
              name: row.name,
              status: row.status,
            });

            setIsFlyoutMenuVisible(true);
          }}
        >
          {row.name || emptyColumnValue}
        </Typography>
      </ArchivedStatus>
    ),
    label: "Controller name",
    key: "name",
  },
  {
    accessor: "agentKey",
    label: "Controller keys",
    key: "agentKeyID",
  },
  {
    accessor: "version",
    label: "Version",
    key: "version",
  },
  {
    accessor: (row) => row.agents.totalCount.toString(),
    label: "Agents",
    key: "agentsAggregate.count",
  },
  {
    accessor: (row) => <HealthStatus status={row.status} />,
    label: "Status",
    key: "status",
  },
  {
    accessor: (row) => globalTimeParser(row.lastSeen),
    label: "Last seen",
    key: "lastSeen",
  },
];
