import { type Theme, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, {
  type CSSProperties,
  type FC,
  type PropsWithChildren,
  useRef,
} from "react";
import { Handle, type HandleProps, Position } from "react-flow-renderer";

import { OnOverflowTooltip } from "#organization/components/custom-tooltip";

import { useFindHeight } from "./node-dimensions";
import { useHandleStyle } from "./style-nodes-edges";

import { useCircuitContext } from "../../tabs/circuit/circuit-context";
import type { NodeData } from "../../types";

export type HandleWrapperProps = {
  componentHeight: number;
  styles?: CSSProperties;
} & PropsWithChildren;

export type CustomHandlesProps = Partial<HandleProps> &
  Pick<
    NodeData,
    | "inPorts"
    | "outPorts"
    | "componentId"
    | "componentType"
    | "componentDescription"
  > & {
    handleStyles: CSSProperties;
  };

const HandleWrapper: FC<HandleWrapperProps> = (props) => {
  const { children, styles, componentHeight } = props;

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        ...styles,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 1,
          minHeight: componentHeight,
          marginTop: 1,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

// TODO: Make more generic
export const CustomHandles: FC<CustomHandlesProps> = ({
  inPorts,
  componentId,
  outPorts,
  componentType,
  componentDescription,
  handleStyles: { backgroundColor, color },
}) => {
  const { circuitLabelStatus: showLabel } = useCircuitContext();

  const styleHandle = useHandleStyle();
  const componentHeight = useFindHeight(
    { componentDescription, componentType },
    inPorts?.length,
    outPorts?.length,
  );

  return (
    <>
      {inPorts && (
        <HandleWrapper
          componentHeight={componentHeight}
          styles={{ left: showLabel ? -100 : -10 }}
        >
          {inPorts.map((port, i) => {
            const id = [componentId, port?.portName].join(".");

            return (
              <Handle
                key={[id, componentType, componentDescription, i].join(".")}
                id={id}
                type="target"
                position={Position.Left}
                className="react-flow__handle"
                style={{
                  ...styleHandle,
                  whiteSpace: "nowrap",
                  backgroundColor,
                  borderStartStartRadius: 5,
                  borderEndStartRadius: 5,
                  textAlign: "end",
                }}
              >
                {showLabel ? (
                  <PortNamesWithTooltip
                    portName={port?.portName || ""}
                    color={color}
                  />
                ) : null}
              </Handle>
            );
          })}
        </HandleWrapper>
      )}
      {outPorts && (
        <HandleWrapper
          componentHeight={componentHeight}
          styles={{ right: showLabel ? -100 : -10 }}
        >
          {outPorts.map((port, i) => {
            const id = [componentId, port?.portName].join(".");

            return (
              <Handle
                key={[id, componentType, componentDescription, i].join(".")}
                id={id}
                type="source"
                position={Position.Right}
                style={{
                  ...styleHandle,
                  whiteSpace: "nowrap",
                  position: "static",
                  backgroundColor,
                  borderStartEndRadius: 5,
                  borderEndEndRadius: 5,
                  textAlign: "start",
                }}
              >
                {showLabel ? (
                  <PortNamesWithTooltip
                    portName={port?.portName || ""}
                    color={color}
                  />
                ) : null}
              </Handle>
            );
          })}
        </HandleWrapper>
      )}
    </>
  );
};

export interface PortNamesWithTooltipProps {
  portName: string;
  color: string | undefined;
}

const PortNamesWithTooltip: FC<PortNamesWithTooltipProps> = ({
  portName,
  color,
}) => {
  const passedRef = useRef<HTMLDivElement>(null);

  return (
    <OnOverflowTooltip
      passedRef={passedRef}
      title={portName}
      typographyProps={{
        sx: (theme: Theme) => ({
          color: theme.palette.text.primary,
          fontSize: 12,
          fontWight: 500,
        }),
      }}
    >
      <Typography
        ref={passedRef}
        fontSize={12}
        sx={{
          padding: 0.5,
          color,
        }}
      >
        {portName}
      </Typography>
    </OnOverflowTooltip>
  );
};
