import { Typography } from "@mui/material";
import { styled, Box } from "@mui/system";

export const MainPageLayout = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(3)}`,
}));

export const MainPageTitle = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(5),
}));
