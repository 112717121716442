import type { PaletteMode, ThemeOptions } from "@mui/material";

import { components } from "./components";
import { commonPalette, lightPalette, darkPalette } from "./palette";

import { customTypography } from "../theme/components/typography";

export function getNavigationTheme(mode: PaletteMode) {
  return {
    typography: {
      fontFamily: "Source Sans Pro, Nunito, Roboto, Arial",
      ...customTypography,
    },
    components,
    palette: {
      mode,
      ...commonPalette,
      ...(mode === "light" ? lightPalette : {}),
      ...(mode === "dark" ? darkPalette : {}),
    },
  } as ThemeOptions;
}
