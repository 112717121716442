import type { SVGDrawFunction } from "@tisoap/react-flow-smart-edge";
import type { XYPosition } from "react-flow-renderer";

/**
 * smart edge library has a bug where it mess up edge from start and end point
 * this custom function is to fix that bug and make the edge more rounded.
 */
export const svgDrawFunctionCurve: SVGDrawFunction = (_, __, path) => {
  const start = [path[0][0] - 10, path[0][1]];
  const end = [path[path.length - 1][0] + 8, path[path.length - 1][1]];

  const points = [start, ...path, end];

  const pointsXY = points.map(([x, y]) => ({ x, y }));

  const svgPath = pointsXY.reduce<string>((res, p, i) => {
    let segment = "";

    if (i > 0 && i < points.length - 1) {
      segment = getBend(pointsXY[i - 1], p, pointsXY[i + 1], 20);
    } else {
      segment = `${i === 0 ? "M" : "L"}${p.x} ${p.y}`;
    }

    // eslint-disable-next-line no-param-reassign
    res += segment;

    return res;
  }, "");

  return svgPath;
};

function getBend(
  a: XYPosition,
  b: XYPosition,
  c: XYPosition,
  size: number,
): string {
  const bendSize = Math.min(distance(a, b) / 2, distance(b, c) / 2, size);
  const { x, y } = b;

  // no bend
  if ((a.x === x && x === c.x) || (a.y === y && y === c.y)) {
    return `L${x} ${y}`;
  }

  // first segment is horizontal
  if (a.y === y) {
    const xDir = a.x < c.x ? -1 : 1;
    const yDir = a.y < c.y ? 1 : -1;

    return `L ${x + bendSize * xDir * 3},${y}Q ${x},${y} ${x},${
      y + bendSize * yDir * 3
    }`;
  }

  const xDir = a.x < c.x ? 1 : -1;
  const yDir = a.y < c.y ? -1 : 1;

  return `L ${x},${y + bendSize * yDir * 3}Q ${x},${y} ${
    x + bendSize * xDir * 3
  },${y}`;
}

const distance = (a: XYPosition, b: XYPosition) =>
  Math.sqrt((b.x - a.x) ** 2 + (b.y - a.y) ** 2);
