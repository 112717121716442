import { gql } from "graphql-request";

import type {
  GenerateUserApiKeyMutation,
  RevokeUserApiKeyInput,
  RevokeUserApiKeyMutation,
  UserApiKeysDataQuery,
} from "#shared/generated/graphql";
import { useGqlMutation } from "#shared/utils";

import { type DataGridHook, useGridData } from "#organization/hooks";

export type ApiKeysGroupBy = "key";

export const API_KEYS_ENTITY_KEY = "userAPIKeys";

type UserApiKeysData = UserApiKeysDataQuery["userAPIKeys"]["edges"][0]["node"];

export type AdaptedApiKeysGroupData = UserApiKeysData;

export const userApiKeysData = gql`
  query UserApiKeysData(
    $where: UserAPIKeyBoolExp
    $first: Int
    $last: Int
    $after: String
    $before: String
    $orderBy: [UserAPIKeyOrderBy]
  ) {
    userAPIKeys(
      where: $where
      first: $first
      last: $last
      after: $after
      before: $before
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          key
          id
          createdAt
          expiresAt
        }
      }
    }
  }
`;

export const useUserApiKeysData = (email: string) => {
  const useApiKeysData: DataGridHook = (
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    enabled,
    filterVariables,
  ) =>
    useGridData<UserApiKeysData, AdaptedApiKeysGroupData>(
      API_KEYS_ENTITY_KEY,
      userApiKeysData,
      pageSize,
      page,
      setPage,
      order,
      orderBy,
      {
        ...filterVariables,
        organizationMembership: {
          user: {
            email: {
              eq: email,
            },
          },
        },
      },
      (apiKeys: UserApiKeysData) => ({
        ...apiKeys,
        key: formatKey(apiKeys.key),
      }),
      enabled,
      false,
    );

  return useApiKeysData;
};

export const generateUserApiKeyGql = gql`
  mutation generateUserApiKey {
    generateUserAPIKey {
      userAPIKey {
        id
        key
        createdAt
      }
    }
  }
`;

export const useGenerateUserApiKey = () =>
  useGqlMutation<GenerateUserApiKeyMutation, unknown>(generateUserApiKeyGql);

export const revokeUserApiKeyGql = gql`
  mutation revokeUserApiKey($input: RevokeUserAPIKeyInput!) {
    revokeUserAPIKey(input: $input) {
      userAPIKey {
        key
        id
        createdAt
        expiresAt
      }
    }
  }
`;

export const useRevokeUserApiKey = () =>
  useGqlMutation<RevokeUserApiKeyMutation, unknown, RevokeUserApiKeyInput>(
    revokeUserApiKeyGql,
  );

export function formatKey(key: string) {
  if (key.length <= 15) {
    return key;
  }

  const first10 = key.substring(0, 10);
  const middle20 = "*".repeat(30);
  const last5 = key.substring(key.length - 5);

  return [first10, middle20, last5].join("");
}
