import React, { type FC } from "react";
import { Route, Routes } from "react-router-dom";

import { useSetPageTitle } from "#shared/hooks";

import { TopBarLayout } from "#organization/components/layouts";

import {
  DataCenterProvider,
  useAlertsOrganizationConfigs,
} from "./data-center";
import { AlertManager } from "./page";
import { ALERT_MANAGER_ROUTE } from "./routes.definitions";

import { DeleteBoxProvider } from "../../../../shared/components/pop-up/delete-confirm-box";

export const AlertManagerRoutes: FC = () => {
  useSetPageTitle(ALERT_MANAGER_ROUTE.TITLE);

  return (
    <TopBarLayout>
      <DataCenterProvider
        useAlertsOrganizationConfigs={useAlertsOrganizationConfigs}
      >
        <DeleteBoxProvider>
          <Routes>
            <Route index element={<AlertManager />} />
            <Route path="*" element={<AlertManager />} />
          </Routes>
        </DeleteBoxProvider>
      </DataCenterProvider>
    </TopBarLayout>
  );
};
