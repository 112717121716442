import { gql, type Variables } from "graphql-request";
import type { UseQueryResult } from "react-query";

import type { GetUsersQuery } from "#shared/generated/graphql";
import type { UserRole } from "#shared/recoil";
import { useGqlQuery } from "#shared/utils";

import { QUERY_KEYS } from "#organization/pages/consts";

const UsersQuery = gql`
  query getUsers(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $orderBy: [UserOrderBy]
    $where: UserBoolExp
  ) {
    users(
      first: $first
      last: $last
      after: $after
      before: $before
      orderBy: $orderBy
      where: $where
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          email
          name
          organizationMemberships {
            edges {
              node {
                id
                role
                status
                end
              }
            }
          }
        }
      }
    }
  }
`;

type MemberData = Omit<Member["node"], "organizationMemberships"> &
  Omit<OrganizationMembership, "id" | "role"> & {
    organizationMembershipId: string;
    role: UserRole;
  };
type Member = GetUsersQuery["users"]["edges"][number];

type OrganizationMembership =
  GetUsersQuery["users"]["edges"][number]["node"]["organizationMemberships"]["edges"][number]["node"];

type OrganizationMembershipStatus = "inactive" | "active";

const listedStatus: OrganizationMembershipStatus[] = ["active", "inactive"];

export const useMembersData = (where: Variables = {}) => {
  const query = useGqlQuery<GetUsersQuery>(
    QUERY_KEYS.WELCOME_MEMBERS_QUERY_KEY,
    UsersQuery,
    where,
    {},
  );

  // NOTE: quick fix - do not list members with end value
  // TODO: should be done in gql's "where"
  const filteredEdges: MemberData[] =
    query.data?.users.edges
      .map(mapData())
      .filter(
        (data) =>
          data.organizationMembershipId &&
          data.status &&
          listedStatus.includes(data.status as OrganizationMembershipStatus) &&
          !data?.end,
      ) || ([] as MemberData[]);

  const filteredQuery: UseQueryResult<
    { edges: MemberData[]; totalCount: number | undefined },
    unknown
  >["data"] = {
    edges: filteredEdges,
    totalCount: query.data?.users.totalCount,
  };

  return {
    ...query,
    data: filteredQuery,
  };
};

function mapData() {
  return ({ node }: Member): MemberData => {
    const {
      id,
      name,
      email,
      organizationMemberships: {
        edges: [
          { node: organizationMemberships } = {
            node: { role: "", status: "", id: "", end: null },
          },
        ],
      },
    } = node;

    return {
      id,
      name,
      email,
      role: organizationMemberships.role as UserRole,
      status: organizationMemberships.status,
      organizationMembershipId: organizationMemberships.id,
      end: organizationMemberships.end,
    };
  };
}
