import React from "react";

export const requestPrioritizationIcon = (
  <>
    <path d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H56C57.933 0.5 59.5 2.067 59.5 4V56C59.5 57.933 57.933 59.5 56 59.5H4C2.067 59.5 0.5 57.933 0.5 56V4Z" />
    <path d="M16 15L19 18L16 21" strokeWidth="2" strokeLinecap="square" />
    <rect x="25" y="15" width="18" height="6" strokeWidth="2" />
    <rect x="31" y="27" width="18" height="6" strokeWidth="2" />
    <rect x="25" y="39" width="18" height="6" strokeWidth="2" />
    <path d="M26 30H12.5V18H18" strokeWidth="2" />
  </>
);

export const rateLimitingIcon = (
  <>
    <path d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H56C57.933 0.5 59.5 2.067 59.5 4V56C59.5 57.933 57.933 59.5 56 59.5H4C2.067 59.5 0.5 57.933 0.5 56V4Z" />
    <path d="M27 15L24 18L21 15" strokeWidth="2" strokeLinecap="square" />
    <path d="M39 15L36 18L33 15" strokeWidth="2" strokeLinecap="square" />
    <path
      d="M39 22H21L28.2 30.4089V36.2222L31.8 38V30.4089L39 22Z"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M24 11V17"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M36 11V17"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M30 42V48"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path d="M33 46L30 49L27 46" strokeWidth="2" strokeLinecap="square" />
  </>
);
