import { Add, ExpandMore } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import React, { type FC, useState } from "react";
import { useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";

import { useAlert } from "#shared/components/alerts-provider";
import {
  ConfirmDialog,
  useControlDialog,
} from "#shared/components/layouts/dialog";
import { PermissionEnum, userNewState } from "#shared/recoil";
import { LoggerService } from "#shared/services/logger";
import type { Texts } from "#shared/types";

import { DataGrid } from "#organization/components/data-grid";
import { useDropdownSearchBox } from "#organization/components/layouts/dropdown-search-box";
import { useRemoveUserGroupFromProject } from "#organization/graphql";
import { SettingsSearch } from "#organization/pages/authenticated/settings/components/settings-search";

import { headCells } from "./components/table";
import { useUserGroupPerProject } from "./hooks";

import { SectionHeader } from "../../../../../components/section-header";
import { SectionWrapper } from "../../../../../components/styled";
import { CreateUserGroupDialog } from "../../../../components/create-user-group-dialog";
import { AddUserGroupToProjectDropdown } from "../add-user-group-to-project-dropdown";

const INITIAL_ORDER_BY = "";
const INITIAL_ORDER = "asc";

type Text =
  | "createNewUserGroup"
  | "removeSuccess"
  | "removeError"
  | "assignUserGroups"
  | "removeConfirmText"
  | "userGroups";

export interface UserGroupsSectionProps {
  projectName: string | undefined;
  projectId: string | undefined;
  texts?: Texts<Text>;
  hideCreateNewUserGroupButton?: boolean;
}

const enText: Required<UserGroupsSectionProps["texts"]> = {
  createNewUserGroup: "Create new user group",
  removeSuccess: "Successfully removed the user group.",
  removeError: "Could not remove the user group.",
  assignUserGroups: "Assign User Groups",
  removeConfirmText: "remove user group from this project?",
  userGroups: "user groups",
};

export const UserGroupsSection: FC<UserGroupsSectionProps> = ({
  projectName,
  projectId,
  texts = enText,
  hideCreateNewUserGroupButton = false,
}) => {
  const userRole = useRecoilValue(userNewState.userRole)!;

  const permissionLock =
    PermissionEnum[userRole] < PermissionEnum.user_group_admin;
  const { addAlert } = useAlert();
  const addUserGroupDropdown = useDropdownSearchBox();
  const confirmDeleteDialogControl = useControlDialog();

  const { mutate: removeUserGroupFromProject } =
    useRemoveUserGroupFromProject();

  const [selectedUserGroup, setSelectedUserGroup] = useState<string>("");
  const createUserGroupDialog = useControlDialog();

  const [searchPhrase, setSearchPhrase] = useState<string>("");

  const dataGridVariables = {
    projectID: { eq: projectId },
    userGroup: { name: { ilike: `%${searchPhrase}%` } },
  };
  const queryClient = useQueryClient();

  const refetch = () => {
    queryClient.invalidateQueries([
      "gridData",
      "userGroupProjectAssignments",
      "",
      "asc",
      10,
      0,
      `{"projectID":{"eq":"${projectId}"}}`,
    ]);

    queryClient.invalidateQueries([
      "'allUserGroupsQuery'",
      {
        first: 4,
        where: {
          not: {
            userGroupProjectAssignments: {
              projectID: {
                eq: projectId,
              },
            },
          },
          name: {
            like: "%",
          },
        },
      },
    ]);
  };

  const header = headCells(permissionLock, (id) => {
    setSelectedUserGroup(id);
    confirmDeleteDialogControl.open();
  });

  const removeUserGroup = () => {
    removeUserGroupFromProject(
      { id: selectedUserGroup },
      {
        onSuccess: () => {
          refetch();
          confirmDeleteDialogControl.close();
          setSelectedUserGroup("");

          addAlert({
            type: "success",
            message: texts.removeSuccess,
          });
        },
        onError: (error) => {
          LoggerService.error(error);

          addAlert({
            type: "error",
            message: texts.removeError,
          });
        },
      },
    );
  };

  return (
    <SectionWrapper>
      <SectionHeader title={`${projectName} ${texts.userGroups}`} />

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        mb={2}
        mt={2}
      >
        <SettingsSearch
          onSearch={(search: string) => setSearchPhrase(search)}
        />
        <Box display="flex" gap={2}>
          <>
            {hideCreateNewUserGroupButton ? null : (
              <>
                <Button
                  onClick={createUserGroupDialog.open}
                  variant="outlined"
                  size="small"
                  disabled={permissionLock}
                  startIcon={<Add />}
                >
                  {texts.createNewUserGroup}
                </Button>

                <CreateUserGroupDialog
                  dialogControl={createUserGroupDialog}
                  refetch={refetch}
                />
              </>
            )}

            <Button
              onClick={addUserGroupDropdown.togglePopper}
              variant="contained"
              size="small"
              endIcon={<ExpandMore />}
              disabled={permissionLock}
            >
              {texts.assignUserGroups}
            </Button>

            <AddUserGroupToProjectDropdown
              dropdownControl={addUserGroupDropdown}
              refetch={refetch}
            />
          </>
        </Box>
      </Box>

      <DataGrid
        headCells={header}
        useGridData={useUserGroupPerProject}
        enabled
        filterVariables={dataGridVariables}
        initialOrderBy={INITIAL_ORDER_BY}
        initialOrder={INITIAL_ORDER}
      />

      <ConfirmDialog
        isOpen={confirmDeleteDialogControl.isOpen}
        close={confirmDeleteDialogControl.close}
        onConfirm={removeUserGroup}
        text={texts.removeConfirmText}
      />
    </SectionWrapper>
  );
};
