import { Box, Paper, Stack } from "@mui/material";
import { noop } from "lodash";
import React, {
  useEffect,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
} from "react";
import type { FieldValues, UseFormReturn } from "react-hook-form";

import { DocFlyout } from "../../doc-flyout";
import { PolicyNameInput, type PolicyNameInputProps } from "../common";
import type { RecursiveStringify } from "../types";

export declare type PolicyFormTemplateProps<TFields extends FieldValues> = {
  methods: UseFormReturn<TFields>;
  recordIsDirty: [boolean, Dispatch<SetStateAction<boolean>>];
  submitBuilder?: (data: TFields) => void;
  children: ReactNode;
  docTree: RecursiveStringify<TFields>;
};

export const PolicyFormTemplate = <TFields extends FieldValues>({
  methods,
  recordIsDirty,
  children,
  submitBuilder = noop,
  docTree,
  ...policyNameInputProps
}: PolicyFormTemplateProps<TFields> & PolicyNameInputProps) => {
  const [, setIsDirty] = recordIsDirty;

  useEffect(() => {
    setIsDirty(methods.formState.isDirty);
  }, [methods.formState.isDirty, setIsDirty]);

  return (
    <>
      <DocFlyout<TFields> docTree={docTree} />
      <Stack component="form" onSubmit={methods.handleSubmit(submitBuilder)}>
        <Box component={Paper} p={2} mb={2}>
          <PolicyNameInput {...policyNameInputProps} />
        </Box>
        {children}
      </Stack>
    </>
  );
};
