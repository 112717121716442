import type { GraphQLError } from "graphql";
import { gql } from "graphql-request";

import type {
  ApplyBlueprintDefinedPolicyInput,
  ApplyBlueprintDefinedPolicyPayload,
  ApplyCustomDefinedPolicyInput,
  ApplyCustomDefinedPolicyPayload,
  BlueprintPolicyDefinitionsQuery,
  BlueprintPolicyDefinitionsQueryVariables,
  CreateBlueprintPolicyDefinitionInput,
  CreateBlueprintPolicyDefinitionPayload,
  CreateCustomPolicyDefinitionInput,
  CreateCustomPolicyDefinitionPayload,
  CustomPolicyDefinitionsQuery,
  CustomPolicyDefinitionsQueryVariables,
  UpdateBlueprintPolicyDefinitionInput,
  UpdateBlueprintPolicyDefinitionPayload,
  UpdateCustomPolicyDefinitionInput,
  UpdateCustomPolicyDefinitionPayload,
} from "#shared/generated/graphql";
import {
  useGqlMutation,
  useGqlQuery,
  type UseGqlMutationOptions,
} from "#shared/utils";

export const createBlueprintPolicyDefinitionGql = gql`
  mutation CreateBlueprintPolicyDefinition(
    $input: CreateBlueprintPolicyDefinitionInput!
  ) {
    createBlueprintPolicyDefinition(input: $input) {
      blueprintPolicyDefinition {
        blueprintName
        blueprintVersion
        id
        name
        status
        values
        editedAt
      }
    }
  }
`;

export const useCreateBlueprintPolicyDefinition = () =>
  useGqlMutation<
    CreateBlueprintPolicyDefinitionPayload,
    unknown,
    CreateBlueprintPolicyDefinitionInput
  >(createBlueprintPolicyDefinitionGql);

export const createCustomPolicyDefinitionGql = gql`
  mutation CreateCustomPolicyDefinition(
    $input: CreateCustomPolicyDefinitionInput!
  ) {
    createCustomPolicyDefinition(input: $input) {
      customPolicyDefinition {
        id
        name
        body
        status
        editedAt
      }
    }
  }
`;

export const useCreateCustomPolicyDefinition = () =>
  useGqlMutation<
    CreateCustomPolicyDefinitionPayload,
    unknown,
    CreateCustomPolicyDefinitionInput
  >(createCustomPolicyDefinitionGql);

export const updateBlueprintPolicyDefinitionGql = gql`
  mutation UpdateBlueprintPolicyDefinition(
    $input: UpdateBlueprintPolicyDefinitionInput!
  ) {
    updateBlueprintPolicyDefinition(input: $input) {
      blueprintPolicyDefinition {
        blueprintName
        blueprintVersion
        values
        id
        editedAt
      }
    }
  }
`;

export const useUpdateBlueprintPolicyDefinition = () =>
  useGqlMutation<
    UpdateBlueprintPolicyDefinitionPayload,
    unknown,
    UpdateBlueprintPolicyDefinitionInput
  >(updateBlueprintPolicyDefinitionGql);

export const updateCustomPolicyDefinitionGql = gql`
  mutation UpdateCustomPolicyDefinition(
    $input: UpdateCustomPolicyDefinitionInput!
  ) {
    updateCustomPolicyDefinition(input: $input) {
      customPolicyDefinition {
        body
        id
        name
        body
        editedAt
      }
    }
  }
`;

export const useUpdateCustomPolicyDefinition = () =>
  useGqlMutation<
    UpdateCustomPolicyDefinitionPayload,
    unknown,
    UpdateCustomPolicyDefinitionInput
  >(updateCustomPolicyDefinitionGql);

export const blueprintPolicyDefinitionGql = gql`
  query blueprintPolicyDefinitions($where: BlueprintPolicyDefinitionBoolExp) {
    blueprintPolicyDefinitions(where: $where) {
      edges {
        node {
          values
          id
          name
          status
          blueprintName
          blueprintVersion
          editedAt
        }
      }
    }
  }
`;

export const useBlueprintPolicyDefinition = (
  id: string,
  enabled: boolean,
  queryKey?: string[],
) =>
  useGqlQuery<
    BlueprintPolicyDefinitionsQuery,
    GraphQLError,
    BlueprintPolicyDefinitionsQuery,
    string[],
    BlueprintPolicyDefinitionsQueryVariables
  >(
    queryKey || ["getBlueprintPolicyDefinitions", id],
    blueprintPolicyDefinitionGql,
    {
      where: {
        id: {
          eq: id,
        },
      },
    },
    {
      enabled,
    },
  );

export const customPolicyDefinitionGql = gql`
  query customPolicyDefinitions($where: CustomPolicyDefinitionBoolExp) {
    customPolicyDefinitions(where: $where) {
      edges {
        node {
          id
          name
          body
          editedAt
          status
        }
      }
    }
  }
`;

export const useCustomPolicyDefinitions = (
  id: string,
  enabled: boolean,
  queryKey?: string[],
) =>
  useGqlQuery<
    CustomPolicyDefinitionsQuery,
    GraphQLError,
    CustomPolicyDefinitionsQuery,
    string[],
    CustomPolicyDefinitionsQueryVariables
  >(
    queryKey || ["getCustomPolicyDefinitions", id],
    customPolicyDefinitionGql,
    {
      where: {
        id: {
          eq: id,
        },
      },
    },
    {
      enabled,
    },
  );

export const applyBlueprintPolicyGql = gql`
  mutation applyBlueprintPolicy($input: ApplyBlueprintDefinedPolicyInput!) {
    applyBlueprintDefinedPolicy(input: $input) {
      workflowID
    }
  }
`;

export const applyCustomPolicyGql = gql`
  mutation applyCustomDefinedPolicy($input: ApplyCustomDefinedPolicyInput!) {
    applyCustomDefinedPolicy(input: $input) {
      workflowID
    }
  }
`;

export const useApplyBlueprintPolicy = (options: UseGqlMutationOptions = {}) =>
  useGqlMutation<
    ApplyBlueprintDefinedPolicyPayload,
    unknown,
    ApplyBlueprintDefinedPolicyInput
  >(applyBlueprintPolicyGql, options);

export const useApplyCustomDefinedPolicy = (
  options: UseGqlMutationOptions = {},
) =>
  useGqlMutation<
    ApplyCustomDefinedPolicyPayload,
    unknown,
    ApplyCustomDefinedPolicyInput
  >(applyCustomPolicyGql, options);
