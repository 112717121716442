import { CodeSnippet, CodeBlockWithCopyButton } from "#organization/components";

export const language = "ts";

[Aperture TypeScript SDK](https://www.npmjs.com/package/@fluxninja/aperture-js)
can be used to manually set feature control points within a JavaScript service.

<CodeBlockWithCopyButton language="shell" code={` npm i @fluxninja/aperture-js `} />

To do so, first create an instance of ApertureClient:

<CodeSnippet lang={language} snippetName="clientConstructor" />

The created instance can then be used to start a flow:

<CodeSnippet lang={language} snippetName="handleRequestRateLimit" />

You can use the caching feature as follows:

<CodeSnippet lang={language} snippetName="setResultCache" />

For more context on using the Aperture JavaScript SDK to set feature control
points, refer to the [example app][example] available in the repository.

[example]: https://github.com/fluxninja/aperture-js/tree/main/example
[api-keys]: /reference/cloud-ui/api-keys.md
