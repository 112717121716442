import {
  type Dispatch,
  type SetStateAction,
  type SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

export type OnTabChange = (_: SyntheticEvent, newValue: number) => void;

export type UseTabNavigation = <T extends string>(
  tabs: T[],
) => {
  onTabsChange: OnTabChange;
  currentTab: number;
  setCurrentTab: Dispatch<SetStateAction<number>>;
};

export const useTabNavigation: UseTabNavigation = (tabs) => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const tab = tabs.findIndex((tabName) => pathname.includes(tabName));

    if (tab !== -1) {
      setCurrentTab(tab);
    } else {
      setCurrentTab(0);
      navigate(tabs[0]);
    }
  }, [pathname, navigate, tabs]);

  const onTabsChange: OnTabChange = (_, newValue) => {
    setCurrentTab(newValue);
    navigate(tabs[newValue]);
  };

  return { onTabsChange, currentTab, setCurrentTab };
};
