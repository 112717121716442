import { CodeSnippet, CodeBlockWithCopyButton } from "#organization/components"

export const language = "ts";

# Limit By Label Key

## Overview

The `limit_by_label_key` determines the specific label key used for enforcing concurrency scheduling.

## Example

For instance, `limit_by_label_key` can be set as `user_id`. This way, each user will be allowed to make a maximum number of concurrenct requests from which some will get prioritized when about to hit the limit. If the user exceeds the limit, the following requests will be queued.

<CodeSnippet lang={language} snippetName="UICS" />

## Note

In the absence of a specified `limit_by_label_key`, concurrency scheduling will default to global scheduling for all requests matching the criteria. Defining a label key allows for more granular concurrency limiting per user or per device.
