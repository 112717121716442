import type { Variables } from "graphql-request";
import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  type MouseEvent,
  type ReactNode,
  useMemo,
} from "react";
import {
  type QueryOptions,
  type UseMutateFunction,
  useQueries,
  type UseQueryResult,
} from "react-query";

import type {
  AlertsOrganizationConfigsQuery,
  CreateAlertsChannelInput,
  CreateAlertsChannelMutation,
  CreateAlertsEmailNotifierInput,
  CreateAlertsEmailNotifierMutation,
  CreateAlertsSlackNotifierMutation,
  CreateAlertsWebhookNotifierInput,
  CreateAlertsWebhookNotifierMutation,
  CreateAlertsSlackNotifierInput,
  AlertsChannelsQuery,
  DeleteAlertsChannelMutation,
  DeleteAlertsChannelInput,
  UpdateAlertsChannelMutation,
  UpdateAlertsChannelInput,
  UpdateAlertsEmailNotifierMutation,
  UpdateAlertsEmailNotifierInput,
  UpdateAlertsSlackNotifierMutation,
  UpdateAlertsSlackNotifierInput,
  UpdateAlertsWebhookNotifierMutation,
  UpdateAlertsWebhookNotifierInput,
  DeleteAlertsEmailNotifierMutation,
  DeleteAlertsEmailNotifierInput,
  DeleteAlertsWebhookNotifierMutation,
  DeleteAlertsWebhookNotifierInput,
  DeleteAlertsSlackNotifierMutation,
  DeleteAlertsSlackNotifierInput,
  AlertsQuery,
  AlertsCountBySeverityQuery,
  SilenceAlertsChannelInput,
  SilenceAlertsChannelMutation,
  UnmuteAlertsChannelInput,
  UnmuteAlertsChannelMutation,
} from "#shared/generated/graphql";
import type { AnyObject, JSONObject } from "#shared/types";
import { gqlRequest, useGqlMutation, useGqlQuery } from "#shared/utils";

import { useGraphqlPagination } from "#organization/hooks";
import { QUERY_KEYS } from "#organization/pages/consts";

import { useDataCenterContext } from ".";
import type { AlertSeverity } from "../../../../../../../types";
import {
  alertLogsGQL,
  alertsChannelsGQL,
  alertsCountBySeverityGQL,
  alertsOrganizationConfigsGQL,
  createAlertsChannelGQL,
  createAlertsEmailNotifierGQL,
  createAlertsSlackNotifierGQL,
  createAlertsWebhookNotifierGQL,
  deleteAlertsChannelGQL,
  deleteAlertsEmailNotifierGQL,
  deleteAlertsSlackNotifierGQL,
  deleteAlertsWebhookNotifierGQL,
  silentChannelGQL,
  unmuteAlertsChannelGQL,
  updateAlertsChannelGQL,
  updateAlertsEmailNotifierGQL,
  updateAlertsSlackNotifierGQL,
  updateAlertsWebhookNotifierGQL,
} from "../alerts-graphQL";

// Mutation hooks for alerts channel and notifier

type CommonReturnType = {
  isError: boolean;
  isLoading: boolean;
  error: unknown;
};

export interface AlertsMutationFunctions {
  createAlertsChannel: UseMutateFunction<
    CreateAlertsChannelMutation,
    unknown,
    CreateAlertsChannelInput,
    unknown
  >;
  createEmailNotifier: UseMutateFunction<
    CreateAlertsEmailNotifierMutation,
    unknown,
    CreateAlertsEmailNotifierInput,
    unknown
  >;
  createSlackNotifier: UseMutateFunction<
    CreateAlertsSlackNotifierMutation,
    unknown,
    CreateAlertsSlackNotifierInput,
    unknown
  >;
  createWebhookNotifier: UseMutateFunction<
    CreateAlertsWebhookNotifierMutation,
    unknown,
    CreateAlertsWebhookNotifierInput,
    unknown
  >;
}

export type UseCreateAlertsChannel = () => {
  createAlertsChannel: AlertsMutationFunctions["createAlertsChannel"];
  createAlertsChannelResponse: CreateAlertsChannelMutation | undefined;
} & CommonReturnType;

export const useCreateAlertsChannel: UseCreateAlertsChannel = () => {
  const { data, isError, mutate, isLoading, error } = useGqlMutation<
    CreateAlertsChannelMutation,
    unknown,
    CreateAlertsChannelInput
  >(createAlertsChannelGQL);

  return {
    createAlertsChannel: mutate,
    createAlertsChannelResponse: data,
    isError,
    isLoading,
    error,
  };
};

export type UseCreateAlertsEmailNotifier = () => {
  createEmailNotifier: AlertsMutationFunctions["createEmailNotifier"];
} & CommonReturnType;

export const useCreateAlertsEmailNotifier: UseCreateAlertsEmailNotifier =
  () => {
    const { data, isError, mutate, isLoading, error } = useGqlMutation<
      CreateAlertsEmailNotifierMutation,
      unknown,
      CreateAlertsEmailNotifierInput
    >(createAlertsEmailNotifierGQL);

    return {
      createEmailNotifier: mutate,
      data,
      isError,
      isLoading,
      error,
    };
  };

export type UseCreateAlertsSlackNotifier = () => {
  createSlackNotifier: AlertsMutationFunctions["createSlackNotifier"];
} & CommonReturnType;

export const useCreateAlertsSlackNotifier: UseCreateAlertsSlackNotifier =
  () => {
    const { data, isError, mutate, isLoading, error } = useGqlMutation<
      CreateAlertsSlackNotifierMutation,
      unknown,
      CreateAlertsSlackNotifierInput
    >(createAlertsSlackNotifierGQL);

    return {
      createSlackNotifier: mutate,
      data,
      isError,
      isLoading,
      error,
    };
  };

export type UseCreateAlertsWebhookNotifier = () => {
  createWebhookNotifier: AlertsMutationFunctions["createWebhookNotifier"];
} & CommonReturnType;

export const useCreateAlertsWebhookNotifier: UseCreateAlertsWebhookNotifier =
  () => {
    const { data, isError, mutate, isLoading, error } = useGqlMutation<
      CreateAlertsWebhookNotifierMutation,
      unknown,
      CreateAlertsWebhookNotifierInput
    >(createAlertsWebhookNotifierGQL);

    return {
      createWebhookNotifier: mutate,
      data,
      isError,
      isLoading,
      error,
    };
  };

export type DeleteAlertsChannelFnc = UseMutateFunction<
  DeleteAlertsChannelMutation,
  unknown,
  DeleteAlertsChannelInput,
  unknown
>;

export type UseDeleteAlertsChannel = () => {
  deleteAlertsChannel: DeleteAlertsChannelFnc;
} & CommonReturnType;

export const useDeleteAlertsChannel: UseDeleteAlertsChannel = () => {
  const { data, isError, mutate, isLoading, error } = useGqlMutation<
    DeleteAlertsChannelMutation,
    unknown,
    DeleteAlertsChannelInput
  >(deleteAlertsChannelGQL);

  return {
    deleteAlertsChannel: mutate,
    data,
    isError,
    error,
    isLoading,
  };
};

export type UpdateAlertsChannelFunc = UseMutateFunction<
  UpdateAlertsChannelMutation,
  unknown,
  UpdateAlertsChannelInput,
  unknown
>;

export type UseUpdateAlertsChannel = () => {
  updateAlertsChannel: UpdateAlertsChannelFunc;
} & CommonReturnType;

export const useUpdateAlertsChannel: UseUpdateAlertsChannel = () => {
  const { data, isError, mutate, isLoading, error } = useGqlMutation<
    UpdateAlertsChannelMutation,
    unknown,
    UpdateAlertsChannelInput
  >(updateAlertsChannelGQL);

  return {
    updateAlertsChannel: mutate,
    data,
    isError,
    error,
    isLoading,
  };
};

export type UpdateAlertsEmailNotifierFnc = UseMutateFunction<
  UpdateAlertsEmailNotifierMutation,
  unknown,
  UpdateAlertsEmailNotifierInput,
  unknown
>;

export type UseUpdateAlertsEmailNotifier = () => {
  updateAlertsEmailNotifier: UpdateAlertsEmailNotifierFnc;
} & CommonReturnType;

export const useUpdateAlertsEmailNotifier: UseUpdateAlertsEmailNotifier =
  () => {
    const { data, isError, mutate, isLoading, error } = useGqlMutation<
      UpdateAlertsEmailNotifierMutation,
      unknown,
      UpdateAlertsEmailNotifierInput
    >(updateAlertsEmailNotifierGQL);

    return {
      updateAlertsEmailNotifier: mutate,
      data,
      isError,
      error,
      isLoading,
    };
  };

export type UpdateAlertsSlackNotifierFnc = UseMutateFunction<
  UpdateAlertsSlackNotifierMutation,
  unknown,
  UpdateAlertsSlackNotifierInput,
  unknown
>;

export type UseUpdateAlertsSlackNotifier = () => {
  updateAlertsSlackNotifier: UpdateAlertsSlackNotifierFnc;
} & CommonReturnType;

export const useUpdateAlertsSlackNotifier: UseUpdateAlertsSlackNotifier =
  () => {
    const { data, isError, mutate, isLoading, error } = useGqlMutation<
      UpdateAlertsSlackNotifierMutation,
      unknown,
      UpdateAlertsSlackNotifierInput
    >(updateAlertsSlackNotifierGQL);

    return {
      updateAlertsSlackNotifier: mutate,
      data,
      isError,
      error,
      isLoading,
    };
  };

export type UpdateAlertsWebhookNotifierFnc = UseMutateFunction<
  UpdateAlertsWebhookNotifierMutation,
  unknown,
  UpdateAlertsWebhookNotifierInput,
  unknown
>;

export type UseUpdateWebhookNotifier = () => {
  updateAlertsWebhookNotifier: UpdateAlertsWebhookNotifierFnc;
} & CommonReturnType;

export const useUpdateAlertsWebhookNotifier: UseUpdateWebhookNotifier = () => {
  const { data, isError, mutate, isLoading, error } = useGqlMutation<
    UpdateAlertsWebhookNotifierMutation,
    unknown,
    UpdateAlertsWebhookNotifierInput
  >(updateAlertsWebhookNotifierGQL);

  return {
    updateAlertsWebhookNotifier: mutate,
    data,
    isError,
    error,
    isLoading,
  };
};

export type DeleteAlertsEmailNotifierFnc = UseMutateFunction<
  DeleteAlertsEmailNotifierMutation,
  unknown,
  DeleteAlertsEmailNotifierInput,
  unknown
>;

export type UseDeleteAlertsEmailNotifier = () => {
  deleteAlertsEmailNotifier: DeleteAlertsEmailNotifierFnc;
} & CommonReturnType;

export const useDeleteAlertsEmailNotifier: UseDeleteAlertsEmailNotifier =
  () => {
    const { data, isError, mutate, isLoading, error } = useGqlMutation<
      DeleteAlertsEmailNotifierMutation,
      unknown,
      DeleteAlertsEmailNotifierInput
    >(deleteAlertsEmailNotifierGQL);

    return {
      deleteAlertsEmailNotifier: mutate,
      data,
      isError,
      error,
      isLoading,
    };
  };

export type DeleteAlertsWebhookNotifierFnc = UseMutateFunction<
  DeleteAlertsWebhookNotifierMutation,
  unknown,
  DeleteAlertsWebhookNotifierInput,
  unknown
>;

export type UseDeleteAlertsWebhookNotifier = () => {
  deleteAlertsWebhookNotifier: DeleteAlertsWebhookNotifierFnc;
} & CommonReturnType;

export const useDeleteAlertsWebhookNotifier: UseDeleteAlertsWebhookNotifier =
  () => {
    const { data, isError, mutate, isLoading, error } = useGqlMutation<
      DeleteAlertsWebhookNotifierMutation,
      unknown,
      DeleteAlertsWebhookNotifierInput
    >(deleteAlertsWebhookNotifierGQL);

    return {
      deleteAlertsWebhookNotifier: mutate,
      data,
      isError,
      error,
      isLoading,
    };
  };

export type DeleteAlertsSlackNotifierFnc = UseMutateFunction<
  DeleteAlertsSlackNotifierMutation,
  unknown,
  DeleteAlertsSlackNotifierInput,
  unknown
>;

export type UseDeleteAlertsSlackNotifier = () => {
  deleteAlertsSlackNotifier: DeleteAlertsSlackNotifierFnc;
} & CommonReturnType;

export const useDeleteAlertsSlackNotifier: UseDeleteAlertsSlackNotifier =
  () => {
    const { data, isError, mutate, isLoading, error } = useGqlMutation<
      DeleteAlertsSlackNotifierMutation,
      unknown,
      DeleteAlertsSlackNotifierInput
    >(deleteAlertsSlackNotifierGQL);

    return {
      deleteAlertsSlackNotifier: mutate,
      data,
      isError,
      error,
      isLoading,
    };
  };

export type AlertsDeleteNotifierFunctions = {
  deleteAlertsSlackNotifier: DeleteAlertsSlackNotifierFnc;
  deleteAlertsEmailNotifier: DeleteAlertsEmailNotifierFnc;
  deleteAlertsWebhookNotifier: DeleteAlertsWebhookNotifierFnc;
};

export const useAlertsDeleteNotifierFunctions =
  (): AlertsDeleteNotifierFunctions => ({
    deleteAlertsEmailNotifier:
      useDeleteAlertsEmailNotifier().deleteAlertsEmailNotifier,
    deleteAlertsSlackNotifier:
      useDeleteAlertsSlackNotifier().deleteAlertsSlackNotifier,
    deleteAlertsWebhookNotifier:
      useDeleteAlertsWebhookNotifier().deleteAlertsWebhookNotifier,
  });

export type AlertsUpdateFunctions = {
  updateAlertsWebhookNotifier: UpdateAlertsWebhookNotifierFnc;
  updateAlertsEmailNotifier: UpdateAlertsEmailNotifierFnc;
  updateAlertsSlackNotifier: UpdateAlertsSlackNotifierFnc;
};

export type UseSilentChannelReturn = {
  silentChannel: UseMutateFunction<
    SilenceAlertsChannelMutation,
    unknown,
    SilenceAlertsChannelInput,
    unknown
  >;
  isError: boolean;
  data: SilenceAlertsChannelMutation | undefined;
  isLoading: boolean;
  error: unknown;
};

export type UseSilentChannel = () => UseSilentChannelReturn;

export const useSilentChannel: UseSilentChannel = () => {
  const { data, isError, mutate, isLoading, error } = useGqlMutation<
    SilenceAlertsChannelMutation,
    unknown,
    SilenceAlertsChannelInput
  >(silentChannelGQL);

  return {
    silentChannel: mutate,
    isError,
    data,
    isLoading,
    error,
  };
};

export type UseUnmuteChannelReturn = {
  unmuteChannel: UseMutateFunction<
    UnmuteAlertsChannelMutation,
    unknown,
    UnmuteAlertsChannelInput,
    unknown
  >;
  isError: boolean;
  data: UnmuteAlertsChannelMutation | undefined;
  isLoading: boolean;
  error: unknown;
};

export type UseUnmuteChannel = () => UseUnmuteChannelReturn;

export const useUnmuteChannel: UseUnmuteChannel = () => {
  const { data, isError, mutate, isLoading, error } = useGqlMutation<
    UnmuteAlertsChannelMutation,
    unknown,
    UnmuteAlertsChannelInput
  >(unmuteAlertsChannelGQL);

  return {
    unmuteChannel: mutate,
    isError,
    data,
    isLoading,
    error,
  };
};

// Query hooks for alerts

export type UseAlertsOrganizationConfigs = (projectID: string) => {
  data: AlertsOrganizationConfigsQuery | undefined;
} & CommonReturnType;

export const useAlertsOrganizationConfigs: UseAlertsOrganizationConfigs = (
  projectID,
) => {
  const variables = {
    where: {
      organization: {
        projects: {
          id: { eq: projectID },
        },
      },
    },
  };
  const { data, isError, isLoading, error } =
    useGqlQuery<AlertsOrganizationConfigsQuery>(
      QUERY_KEYS.ALERT_MANAGER_ORGANIZATION_CONFIG,
      alertsOrganizationConfigsGQL,
      variables,
    );

  return {
    data,
    isError,
    isLoading,
    error,
  };
};

export type ChannelsQueryResponse = Omit<
  UseQueryResult<AlertsChannelsQuery, unknown>,
  "data"
> & {
  data: Partial<AlertsChannelsQuery["alertsChannels"]>;
};

export type UseAlertsChannelsResponse = {
  query: ChannelsQueryResponse;
  onPageChange: (
    _event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void;
};

export const useAlertsChannels = (
  pageSize: number,
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  order: string,
  orderBy: string,
  enabled: boolean = true,
  filterVariables: JSONObject = {},
): UseAlertsChannelsResponse => {
  const { paginationVariables, onPageChange, resetPagination } =
    useGraphqlPagination(setPage);
  const {
    reFetchFlag: { fetchFlag, setFetchFlag },
  } = useDataCenterContext();

  const variables: Variables = useMemo(
    () => ({
      ...filterVariables,
      orderBy: { [orderBy]: order },
      ...paginationVariables(pageSize),
    }),
    [filterVariables, order, orderBy, pageSize, paginationVariables],
  );

  useEffect(() => {
    resetPagination();
  }, [filterVariables, resetPagination]);

  const queryKeys = [
    QUERY_KEYS.DATA_GRID,
    QUERY_KEYS.ALERT_CHANNELS,
    orderBy,
    order,
    pageSize,
    page,
    JSON.stringify(filterVariables),
    enabled,
    fetchFlag,
  ];

  const query = useGqlQuery<AlertsChannelsQuery>(
    queryKeys,
    alertsChannelsGQL,
    variables,
    {
      keepPreviousData: true,
    },
  );

  const { isPreviousData, refetch, isRefetching } = query;

  useEffect(() => {
    if (fetchFlag) {
      refetch();

      if (!isPreviousData && !isRefetching) {
        setFetchFlag(false);
      }
    }
  }, [fetchFlag, isPreviousData, refetch, setFetchFlag, isRefetching]);

  return {
    query: {
      ...query,
      data: {
        ...query.data?.alertsChannels,
      },
    },
    onPageChange: onPageChange(
      query?.data?.alertsChannels.pageInfo || {
        startCursor: null,
        endCursor: null,
        hasNextPage: false,
        hasPreviousPage: false,
      },
    ),
  };
};

export const useAlertLogs = (where: Variables = {}) => {
  const { projectID } = useDataCenterContext();

  return useGqlQuery<AlertsQuery>(QUERY_KEYS.ALERT_LOGS, alertLogsGQL, {
    where: {
      projectID: { eq: projectID },
      ...where,
    },
  });
};

export type AlertSeverityData = Pick<
  UseQueryResult<AlertsCountBySeverityQuery>,
  "isError" | "isLoading" | "isIdle" | "isFetched"
> & {
  data: ReactNode;
};

type SeverityMap<V> = AnyObject<AlertSeverity, V>;

export type AlertsCountBySeverity = SeverityMap<AlertSeverityData>;

type AlertCountBySeverityQueryResult = AlertsCountBySeverityQuery & {
  severity: AlertSeverity;
};

export const useAlertsCountBySeverity = (
  severity: AlertSeverity[],
  where: Variables = {},
): AlertsCountBySeverity => {
  const { projectID } = useDataCenterContext();

  const queries = useMemo(
    () => severity.map(mapSeverityToQueryOptions(projectID, where)),
    [projectID, severity, where],
  );

  const response = useQueries(queries);

  const result = response.reduce<SeverityMap<AlertSeverityData>>(
    reduceAlertsBySeverity(),
    {
      crit: {
        data: 0,
        isLoading: false,
        isError: false,
        isIdle: true,
        isFetched: false,
      },
      info: {
        data: 0,
        isLoading: false,
        isError: false,
        isIdle: true,
        isFetched: false,
      },
      warn: {
        data: 0,
        isLoading: false,
        isError: false,
        isIdle: true,
        isFetched: false,
      },
    },
  );

  return result;
};

function reduceAlertsBySeverity() {
  return (
    severityMap: SeverityMap<AlertSeverityData>,
    response: UseQueryResult<AlertCountBySeverityQueryResult>,
  ): SeverityMap<AlertSeverityData> => {
    const { data, isError, isIdle, isLoading, isFetched } = response;

    const { alerts: { totalCount = 0 } = {}, severity = "undefined" } =
      data || {
        alerts: {},
      };

    const result: AlertSeverityData = {
      data: totalCount,
      isError,
      isIdle,
      isLoading,
      isFetched,
    };

    return {
      ...severityMap,
      [severity]: result,
    };
  };
}

function mapSeverityToQueryOptions<V extends Variables>(
  projectID: string,
  where?: V,
) {
  return (
    severity: AlertSeverity,
  ): QueryOptions<
    AlertsCountBySeverityQuery & { severity: AlertSeverity }
  > => ({
    queryKey: [QUERY_KEYS.ALERTS_COUNT_BY_SEVERITY, severity],
    queryFn: async () => {
      const result = await gqlRequest<AlertsCountBySeverityQuery, Variables>(
        alertsCountBySeverityGQL,
        {
          where: {
            projectID: { eq: projectID },
            severity: { eq: severity },
            ...(where || {}),
          },
        },
      );

      return { ...result, severity };
    },
  });
}
