import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { type FC } from "react";

const FlexBox = styled(Box)({
  display: "flex",
});

type PercentChangeProps = {
  positive?: boolean;
  percentageCount: string;
};

export const PercentChange: FC<PercentChangeProps> = ({
  positive = true,
  percentageCount,
}) => {
  const red = { color: "red" };
  const green = { color: "green" };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "column",
        position: "relative",
        top: 4,
      }}
    >
      <FlexBox
        sx={{
          alignItems: "center",
          color: `${positive ? green.color : red.color}`,
        }}
      >
        <FlexBox
          sx={{
            position: "relative",
            left: 6,
          }}
        >
          {positive ? (
            <ArrowDropUp sx={{ fontSize: 30 }} />
          ) : (
            <ArrowDropDown sx={{ fontSize: 30 }} />
          )}
        </FlexBox>
        <Box>
          <Typography
            sx={{
              fontSize: 12,
            }}
          >
            {percentageCount} %
          </Typography>
        </Box>
      </FlexBox>
    </Box>
  );
};

PercentChange.defaultProps = {
  positive: false,
};
