import { KeyboardArrowRight } from "@mui/icons-material";
import {
  Grow,
  Paper,
  Popper,
  Box,
  Avatar,
  ListItemAvatar,
  List,
} from "@mui/material";
import React, { type ReactNode, useRef, useState, type FC } from "react";

import { MenuListItemRow } from "#shared/components/menu-list-item-row";

export interface NestedMenuItemProps {
  title: ReactNode;
  nestedMenu: ReactNode;
}

export const NestedMenuItem: FC<NestedMenuItemProps> = ({
  title,
  nestedMenu,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Box ref={anchorRef}>
      <MenuListItemRow
        disableRipple
        listItemProps={{
          onMouseEnter: toggleMenu,
          onMouseLeave: () => {
            setIsOpen(false);
          },
        }}
        listItemTextProps={{
          primary: title,
        }}
        startIcon={
          <ListItemAvatar
            sx={{
              minWidth: 36,
            }}
          >
            <Avatar sx={{ width: 24, height: 24 }} variant="rounded" />
          </ListItemAvatar>
        }
        endIcon={
          <KeyboardArrowRight
            sx={{
              ml: 2,
              color: "text.primary",
            }}
            fontSize="small"
          />
        }
      >
        <Box
          component={Popper}
          open={isOpen}
          sx={{ zIndex: "modal" }}
          anchorEl={anchorRef.current}
          placement="right-start"
        >
          <Grow in={isOpen} timeout={500}>
            <Paper elevation={4}>
              <List
                disablePadding
                sx={{
                  bgcolor: "background.paper",
                  borderRadius: 2,
                }}
              >
                {nestedMenu}
              </List>
            </Paper>
          </Grow>
        </Box>
      </MenuListItemRow>
    </Box>
  );
};
