import {
  ListItem as MuiListItem,
  styled,
  ListItemText as MuiListItemText,
} from "@mui/material";

export const ListItem = styled(MuiListItem)(({ theme: { palette } }) => ({
  "&$selected": {
    backgroundColor: palette.primary.light,
  },
  "&:hover": {
    backgroundColor:
      palette.mode === "dark" ? palette.grey[600] : palette.grey[200],
  },
  padding: "4px 10px",
  textTransform: "none",
  cursor: "pointer",
}));

export const ListItemText = styled(MuiListItemText)(() => ({
  primaryTypographyProps: {
    fontSize: 12,
    textAlign: "start",
    padding: "1px",
    textTransform: "lowercase",
  },
}));
