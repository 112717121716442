import type { MonacoYamlOptions } from "monaco-yaml";
import { atom, useRecoilState } from "recoil";

import { ATOM_KEYS } from "#shared/consts";

export type FNYamlEditor = {
  editorUpdateFunc: ((options: MonacoYamlOptions) => undefined) | null;
};

export const fnYamlEditor = atom<FNYamlEditor>({
  key: ATOM_KEYS.yamlEditor,
  default: {
    editorUpdateFunc: null,
  },
});

export const useRecoilYamlEditor = () => useRecoilState(fnYamlEditor);
