import { ArrowDropDown, ErrorOutline } from "@mui/icons-material";
import {
  Box,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  List,
  CircularProgress,
  Tooltip,
  Button,
} from "@mui/material";
import React, { useState, type FC, useRef, type ReactNode } from "react";

import { MenuListItemRow } from "#shared/components/menu-list-item-row";
import type { Texts } from "#shared/types";

import { useOrganizationState } from "#organization/recoil/organization";

import { useOrganization, useProject } from "./hooks";

import { ORGANIZATION_SETTINGS_ROUTE } from "../../../settings/organization";
import { GENERAL_SETTINGS_ROUTE } from "../../../settings/organization/general-settings";
import { MEMBERS_SETTINGS_ROUTE } from "../../../settings/organization/members";
import { USER_GROUPS_SETTINGS_ROUTE } from "../../../settings/organization/user-groups";

export interface UserDropdownProps {
  organizationId: string;
  // NOTE: When using list of child components, must add unique key props
  subMenuItem: ReactNode | ReactNode[] | null;
  texts?: Texts<Text>;
}

type Text = "organization" | "error";

const enTexts: Required<UserDropdownProps["texts"]> = {
  organization: "Organization name",
  error: "Error when fetching data",
};

export const UserDropdown: FC<UserDropdownProps> = ({
  organizationId,
  subMenuItem,
  texts = enTexts,
}) => {
  const projectCountResult = useProject();
  const organizationResult = useOrganization(organizationId);
  const organizationState = useOrganizationState();

  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen((prevState) => !prevState);
  };

  const handleMenuItemClose = () => {
    setOpen(false);
  };

  const errors = projectCountResult.error || organizationResult.error;
  const loadings = projectCountResult.loading || organizationResult.loading;

  if (loadings) {
    return (
      <Box
        display="flex"
        component="nav"
        ref={anchorRef}
        alignSelf="center"
        justifyContent="center"
        alignContent="center"
      >
        <CircularProgress size="1em" />
      </Box>
    );
  }

  if (errors) {
    return (
      <Box
        display="flex"
        component="nav"
        ref={anchorRef}
        alignSelf="center"
        justifyContent="center"
        alignContent="center"
      >
        <Tooltip title={texts.error} arrow placement="right">
          <ErrorOutline color="error" />
        </Tooltip>
      </Box>
    );
  }

  return (
    <Box component="nav" ref={anchorRef} sx={{ alignSelf: "center" }}>
      <Button
        variant="outlined"
        endIcon={<ArrowDropDown />}
        sx={{
          marginLeft: "12px",
          borderRadius: "4px",
          fontSize: "14px",
          height: "28px",
          border: "0.5px solid rgba(240, 228, 182, 0.50)",
          padding: "4px 8px",
          color: "#F0E4B6",
          textTransform: "none",
          "&:hover": {
            borderColor: "rgba(240, 228, 182, 0.50)",
          },
        }}
        onClick={toggleMenu}
      >
        {organizationState.name}
      </Button>

      <Box
        component={Popper}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [12, 4],
            },
          },
        ]}
        sx={{ zIndex: "modal" }}
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
      >
        <Grow in={open} timeout={500} style={{ transformOrigin: "0 0 0" }}>
          <Paper elevation={4}>
            <ClickAwayListener onClickAway={handleMenuItemClose}>
              <List
                disablePadding
                sx={{
                  bgcolor: "background.paper",
                  borderRadius: 2,
                }}
              >
                {subMenuItem}
                <MenuListItemRow
                  to={GENERAL_SETTINGS_ROUTE.ABSOLUTE_PATH}
                  onClick={handleMenuItemClose}
                  listItemTextProps={{
                    primary: ORGANIZATION_SETTINGS_ROUTE.TITLE,
                  }}
                />
                <MenuListItemRow
                  to={MEMBERS_SETTINGS_ROUTE.ABSOLUTE_PATH}
                  onClick={handleMenuItemClose}
                  listItemTextProps={{
                    primary: MEMBERS_SETTINGS_ROUTE.TITLE,
                  }}
                />
                <MenuListItemRow
                  to={USER_GROUPS_SETTINGS_ROUTE.ABSOLUTE_PATH}
                  onClick={handleMenuItemClose}
                  listItemTextProps={{
                    primary: USER_GROUPS_SETTINGS_ROUTE.TITLE,
                  }}
                />
              </List>
            </ClickAwayListener>
          </Paper>
        </Grow>
      </Box>
    </Box>
  );
};
