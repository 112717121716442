import { CodeSnippet, CodeBlockWithCopyButton } from "#organization/components"

export const language = "ts";

# Control Point

## Overview

Control Point identifies the location within services where policies can act on flows. For an SDK based insertion, a Control Point can represent a particular feature or execution block within a service. In case of service mesh or middleware insertion, a Control Point can identify ingress or egress calls or distinct listeners or filter chains.

## SDK Example

SDKs provide the functionality to pass a `control_point` directly within your code when starting a flow. For instance, if you designate `quota-scheduling-example` as the `control_point` in the policy, quota scheduling will be applied to all incoming requests to that particular feature.

<CodeSnippet lang={language} snippetName="QSUI" />

## Infrastructure Example

<CodeBlockWithCopyButton language="yaml" code={`
 blueprint: quota-scheduling/base
 policy:
  policy_name: quota-scheduler
  quota_scheduler:
    bucket_capacity: 500
    fill_amount: 25
    selectors:
      - agent_group: default
        service: catalog-service.prod.svc.cluster.local
        control_point: egress
    rate_limiter:
      limit_by_label_key: http.request.header.api_key
      interval: 1s
      lazy_sync:
        enabled: false
        num_sync: 4
    scheduler:
      workloads:
        - label_matcher:
            match_labels:
              http.request.header.user_type: guest
          parameters:
            priority: 50.0
          name: guest
        - label_matcher:
            match_labels:
              http.request.header.user_type: subscriber
          parameters:
            priority: 200.0
          name: subscriber

`} />

This infrastructure based configuration will apply quota scheduling at the `egress` control point on the service `catalog-service.prod.svc.cluster.local` and identifies unique users by referencing the `user_id` header present in the HTTP traffic. Provided by the Envoy proxy, this header can be located under the label key `http.request.header.user_id`.

## Note

Regardless of choosing an SDK or an infrastructure-based approach, it's crucial to accurately define control points for quota scheduling.
