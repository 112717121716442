export const APERTURE_SYSTEM_ROUTE = {
  TITLE: "Aperture",
  KEY: "aperture-system",
  CHILD_KEY: "apertureSystemId",
  ABSOLUTE_PATH: "/aperture-system",
  PARENT_PATH: "aperture-system/*",
  CHILD_PATH: ":apertureSystemId/*",
};

export const AGENTS_ROUTE = {
  TITLE: "Agents",
  KEY: "agents",
  CHILD_KEY: "agentId",
  ABSOLUTE_PATH: "/agents",
  PARENT_PATH: "agents/*",
  CHILD_PATH: ":agentId/*",
};

export type AgentIdParam = {
  agentId: string;
};

export const CONTROLLERS_ROUTE = {
  TITLE: "Controllers",
  KEY: "controllers",
  CHILD_KEY: "controllerId",
  ABSOLUTE_PATH: "/controllers",
  PARENT_PATH: "controllers/*",
  CHILD_PATH: ":controllerId",
};

export type ControllerIdParam = {
  controllerId: string;
};

export const CONTROLLER_SUMMARY_ROUTE = {
  TITLE: "Summary",
  PATH: "summary",
  ABSOLUTE_PATH: (id: string) =>
    `${APERTURE_SYSTEM_ROUTE.ABSOLUTE_PATH}${CONTROLLERS_ROUTE.ABSOLUTE_PATH}/${id}/summary`,
};

export const CONTROLLER_AGENTS_ROUTE = {
  TITLE: "Agents",
  PATH: "agents",
  ABSOLUTE_PATH: (id: string) =>
    `${APERTURE_SYSTEM_ROUTE.ABSOLUTE_PATH}${CONTROLLERS_ROUTE.ABSOLUTE_PATH}/${id}/agents`,
};
