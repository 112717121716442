import { Check, InfoOutlined } from "@mui/icons-material";
import { Badge, Paper, Typography } from "@mui/material";
import { alpha, Box, hexToRgb } from "@mui/system";
import React, { type FC } from "react";

import type { ChargebeePlans } from "#shared/billing";

interface PlanChooserProps {
  plans: typeof ChargebeePlans;
  selectedPlan: (typeof ChargebeePlans)[number]["planID"] | null;
  onSelectPlan: (planID: (typeof ChargebeePlans)[number]["planID"]) => void;
}

export const PlanChooser: FC<PlanChooserProps> = ({
  plans,
  selectedPlan,
  onSelectPlan,
}) => {
  const isPlanSelectionEnabled = plans.length > 1;

  return (
    <Box display="flex" flexWrap="wrap" gap={2}>
      {plans.map((plan) => (
        <Badge
          key={plan.planID}
          color="secondary"
          badgeContent="SELECTED"
          variant="standard"
          overlap="rectangular"
          invisible={!isPlanSelectionEnabled || selectedPlan !== plan.planID}
          sx={{
            cursor: isPlanSelectionEnabled ? "pointer" : undefined,
            flexShrink: "unset",
            flex: 1,
            ".MuiBadge-badge": {
              borderRadius: 1,
              right: "calc(100% - 56px)",
            },
          }}
        >
          <Box
            onClick={() => onSelectPlan(plan.planID)}
            component={Paper}
            role="button"
            tabIndex={0}
            width="100%"
            p={3}
            sx={{
              boxShadow: (theme) =>
                theme.palette.mode === "light"
                  ? "0px 3px 3px -2px rgba(96, 101, 117, 0.2), 0px 3px 4px rgba(96, 101, 117, 0.14), 0px 1px 8px rgba(96, 101, 117, 0.12)"
                  : "0px 4px 5px -2px rgba(27, 25, 24, 0.2), 0px 7px 10px 1px rgba(27, 25, 24, 0.14), 0px 2px 16px 1px rgba(27, 25, 24, 0.12);",
              background: (theme) => theme.palette.background.bgOnPaper,
              ...(isPlanSelectionEnabled && {
                outline: (theme) =>
                  `2px solid ${
                    selectedPlan === plan.planID
                      ? theme.palette.secondary.dark
                      : "transparent"
                  }`,
              }),
              transition: "all 0.2s ease-in-out",
              ...(isPlanSelectionEnabled && {
                "&:hover": {
                  outline: (theme) =>
                    `2px solid ${
                      selectedPlan === plan.planID
                        ? theme.palette.secondary.dark
                        : theme.palette.primary.main
                    }`,
                },
              }),
            }}
          >
            <Typography variant="h5" fontWeight="600">
              {plan.title}
            </Typography>
            {plan.description && (
              <Typography variant="body2">{plan.description}</Typography>
            )}

            <Box display="flex" flexWrap="wrap" gap={1} my={3}>
              {plan.billing.map((billing) => (
                <Box
                  key={billing.title}
                  p={2}
                  sx={{
                    backgroundColor: (theme) =>
                      alpha(hexToRgb(theme.palette.primary.main), 0.08),
                    borderRadius: 1,
                    wordBreak: "break-word",
                    display: "flex",
                    flex: 1,
                    minWidth: "200px",
                    alignItems: "start",
                    flexDirection: "column",
                  }}
                >
                  <Box display="flex" gap={1} alignItems="center">
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      fontSize="18px"
                      pb={0.5}
                    >
                      {billing.title}
                    </Typography>
                  </Box>

                  {billing.description && (
                    <Typography variant="body2" mb={0.5}>
                      {billing.description}
                    </Typography>
                  )}

                  <Typography
                    variant="h4"
                    fontSize="40px"
                    color="primary.main"
                    component="span"
                    flexShrink={0}
                  >
                    {billing.cost}
                  </Typography>

                  <Box
                    component="span"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Typography
                      variant="body1"
                      color="text.primary"
                      fontWeight="400"
                      component="span"
                    >
                      {billing.unit}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      component="span"
                    >
                      {billing.quota}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>

            <Box display="flex" flexDirection="column" gap={1}>
              {plan.features.map((feature) => (
                <Box display="flex" gap={1} alignItems="center" key={feature}>
                  <Check sx={{ color: "primary.main", fontSize: "18px" }} />
                  <Typography variant="body2">{feature}</Typography>
                </Box>
              ))}

              {plan.warnings.map((warning) => (
                <Box display="flex" gap={1} alignItems="center" key={warning}>
                  <InfoOutlined
                    sx={{ color: "warning.main", fontSize: "18px" }}
                  />
                  <Typography variant="body2">{warning}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Badge>
      ))}
    </Box>
  );
};
