import { gql } from "graphql-request";

import type {
  GetOrganizationQuery,
  GetProjectQuery,
} from "#shared/generated/graphql";
import { useGqlQuery } from "#shared/utils";

const QUERY_KEY_COUNT = "projectCountQuery";
const QUERY_KEY_ORG = "organizationQuery";
export const projectCountQuery = gql`
  query GetProject {
    projects {
      totalCount
    }
  }
`;

export const organizationQuery = gql`
  query GetOrganization($where: OrganizationBoolExp) {
    organizations(where: $where) {
      edges {
        node {
          name
          domainName
          id
        }
      }
    }
  }
`;

export const useProject = () => {
  const { isError, isLoading, data } = useGqlQuery<GetProjectQuery>(
    [QUERY_KEY_COUNT, projectCountQuery],
    projectCountQuery,
    {},
  );

  return {
    error: isError,
    loading: isLoading,
    projects: data?.projects,
  };
};

export const useOrganization = (organizationId: string) => {
  const findBy = { where: { id: { eq: organizationId } } };
  const { isError, isLoading, data } = useGqlQuery<GetOrganizationQuery>(
    [QUERY_KEY_ORG, findBy],
    organizationQuery,
    findBy,
  );

  return {
    error: isError,
    loading: isLoading,
    organizations: data?.organizations.edges,
  };
};
