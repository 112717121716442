import { SvgIcon, type SvgIconProps } from "@mui/material";
import React, { type FC } from "react";

export type ExpendedIconProps = SvgIconProps & { strokeColor?: string };

export const ExpendIcon: FC<ExpendedIconProps> = ({
  strokeColor,
  ...props
}) => (
  <SvgIcon height="12" width="12" viewBox="0 0 12 12" {...props}>
    <path
      d="M11.2611 3.36633V0.699666M11.2611 0.699666H8.59448M11.2611 0.699666L7.92782 4.033M0.594482 8.69967V11.3663M0.594482 11.3663H3.26115M0.594482 11.3663L3.92782 8.033"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
