import {
  Box,
  type BoxProps,
  type IconButtonProps,
  IconButton,
  DialogActions,
} from "@mui/material";
import { type CSSSelectorObjectOrCssVariables, useTheme } from "@mui/system";
import classNames from "classnames";
import React, { type FC, forwardRef, type PropsWithChildren } from "react";

import type { FnTheme } from "../../../../../../../../app-theme-provider/types";

export type WidgetTitleProps<K extends string = string> = ClosableProps &
  ModalModeProps & {
    key: K;
    containerProps?: Omit<BoxProps, "children" | "id">;
    innerContainerProps?: Omit<BoxProps, "children">;
    contentContainerProps?: Omit<BoxProps, "children">;
    titleContainerProps?: BoxProps;
    className?: string;
    id?: string;
  } & PropsWithChildren;

interface ClosableProps {
  isClosable?: boolean;
  isClosed?: boolean;
  closeButtonProps?: IconButtonProps;
}

interface ModalModeProps {
  isOpenInFull?: boolean;
  openInFullButtonProps?: IconButtonProps;
}

export const WidgetTitle: FC<WidgetTitleProps> = forwardRef((props, ref) => {
  const {
    containerProps,
    titleContainerProps: {
      children: titleChildren = null,
      ...titleContainerProps
    } = {},
    contentContainerProps,
    children,
    key,
    isClosable,
    closeButtonProps,
    className,
    isClosed,
    isOpenInFull,
    openInFullButtonProps,
    innerContainerProps,
    id,
    ...otherProps
  } = props;

  const isTitle = isClosable || isOpenInFull || !!titleChildren;

  const contentStyle: CSSSelectorObjectOrCssVariables<FnTheme> = isClosed
    ? {
        visibility: "hidden",
        overflow: "hidden",
        height: 0,
        width: 0,
        padding: 0,
      }
    : { minHeight: "100%", width: "100%" };

  const { palette } = useTheme();

  return (
    <Box
      {...containerProps}
      key={key}
      ref={ref}
      {...otherProps}
      id={id}
      className={classNames(className, containerProps?.className)}
    >
      <Box
        {...innerContainerProps}
        sx={{
          backgroundColor: palette.background.paper,
          overflowX: "hidden",
          overflowY: "scroll",
          height: "100%",
          width: "100%",
        }}
      >
        {isTitle && (
          <Box
            sx={{ display: "flex", justifyContent: "space-between" }}
            {...titleContainerProps}
          >
            <Box>{titleChildren}</Box>
            <DialogActions>
              {isOpenInFull && (
                <IconButton
                  sx={{ display: "inline-flex" }}
                  {...openInFullButtonProps}
                />
              )}
              {isClosable && (
                <IconButton
                  sx={{ display: "inline-flex" }}
                  {...closeButtonProps}
                />
              )}
            </DialogActions>
          </Box>
        )}
        <Box {...contentContainerProps} sx={contentStyle}>
          {children}
        </Box>
      </Box>
    </Box>
  );
});
