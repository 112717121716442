import CloseIcon from "@mui/icons-material/Close";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import {
  Box,
  type SvgIconProps,
  useTheme,
  type IconButtonProps,
  IconButton,
} from "@mui/material";
import type { CSSSelectorObjectOrCssVariables } from "@mui/system";
import React, { type ComponentType, type FC, type HTMLAttributes } from "react";

import type { FnTheme } from "../../../../../../../../app-theme-provider/types";

export interface GridViewAreaActionsProps
  extends ClosableProps,
    ModalModeProps {
  Container?: ComponentType<ContainerProps>;
  containerProps?: ContainerProps;
}

type ContainerProps = HTMLAttributes<HTMLElement> & {
  sx?: CSSSelectorObjectOrCssVariables<FnTheme>;
};

interface ClosableProps {
  isClosable?: boolean;
  closeButtonProps?: IconButtonProps;
}

interface ModalModeProps {
  isOpenInFull?: boolean;
  openInFullButtonProps?: IconButtonProps;
}

const BUTTON_SIZE = "24px";
const ICON_SIZE = "16px";
const SPACING = 1;

export const GridViewAreaActions: FC<GridViewAreaActionsProps> = (props) => {
  const {
    isClosable,
    closeButtonProps,
    isOpenInFull,
    openInFullButtonProps,
    Container = Box,
    containerProps,
  } = props;

  const { palette, spacing } = useTheme() as FnTheme;

  if (!isOpenInFull && !isClosable) {
    return null;
  }

  const containerSx: ContainerProps["sx"] = {
    display: "flex",
    gap: spacing(SPACING),
    ...containerProps?.sx,
  };

  const buttonSx: Required<IconButtonProps>["sx"] = {
    height: BUTTON_SIZE,
    width: BUTTON_SIZE,
    "&:focus": {
      outline: "none!important",
    },
  };

  const iconSx: SvgIconProps["sx"] = {
    display: "inline-flex",
    height: ICON_SIZE,
    width: ICON_SIZE,
    fill: palette.text.primary,
    cursor: "pointer",
  };

  return (
    <Container {...containerProps} sx={containerSx}>
      {isOpenInFull && (
        <IconButton
          {...openInFullButtonProps}
          // @ts-ignore
          sx={{ ...openInFullButtonProps?.sx, ...buttonSx }}
        >
          <OpenInFullIcon sx={iconSx} />
        </IconButton>
      )}
      {isClosable && (
        <IconButton
          {...closeButtonProps}
          // @ts-ignore
          sx={{ ...closeButtonProps?.sx, ...buttonSx }}
        >
          <CloseIcon sx={iconSx} />
        </IconButton>
      )}
    </Container>
  );
};
