import { get } from "lodash";
import type { FieldValues } from "react-hook-form";

import {
  type AlertsDeleteNotifierFunctions,
  useAlertsDeleteNotifierFunctions,
} from "../../../data-center";

export type DeleteNotifier = (
  values: FieldValues,
  cb: (status: boolean, res?: FieldValues | null) => void,
) => boolean;

export type UseDeleteAlertsNotifiers = () => {
  deleteNotifier: DeleteNotifier;
};

export const useDeleteAlertsNotifiers: UseDeleteAlertsNotifiers = () => {
  const deleteNotifierFnc = useAlertsDeleteNotifierFunctions();

  const deleteNotifier: DeleteNotifier = (values, cb) => {
    const { id, notifierType } = values;

    if (!notifierType?.length) {
      cb(false);

      return false;
    }

    const findNotifier: keyof AlertsDeleteNotifierFunctions =
      notifierType.replace("create", "deleteAlerts");

    get(deleteNotifierFnc, findNotifier)(
      { id },
      {
        onSuccess: (res) =>
          cb(
            true,
            get(
              res,
              [
                findNotifier,
                findNotifier.replace("deleteAlerts", "alerts"),
              ].join("."),
            ),
          ),
        onError: () => cb(false),
      },
    );

    return true;
  };

  return { deleteNotifier };
};
