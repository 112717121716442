import { alpha } from "@mui/system";

import type { CircuitPalette, FnTheme, CircuitEdgePalette } from "../../types";

const circuitComponents: CircuitPalette = {
  Other: {
    Other: "#E0E0E0",
    concurrencylimiter: "#f27a40",
    ratelimiter: "#f27a40",
    promql: "#8ec4ad",
  },
};

const circuitEdges: CircuitPalette & CircuitEdgePalette = {
  Other: { Other: "#dddee3" },
  selected: {
    SignalProcessor: "#B6B9C4",
    Source: "#BFDED1",
    Sink: "#FC9A69",
    StandAlone: "#FC9A69",
  },
  controlLoop: "#f27a40",
  onHover: "#59A6F3",
};

const circuitPorts: CircuitPalette = {
  Other: {
    Other: "#EEEEEE",
    concurrencylimiter: "#fc9a69",
    ratelimiter: "#fc9a69",
    promql: "#bfded1",
  },
};

const circuitComponentLabels: CircuitPalette = {
  Other: { Other: "#20242c" },
};
const circuitPortLabels: CircuitPalette = {
  Other: { Other: "#20242c" },
};

export const lightPalette: FnTheme["palette"] = {
  primary: {
    main: "#3a785e",
    light: "#6bac8e",
    dark: "#2c5945",
    mainHover: "#316650",
    contrastText: "#fff",
    text: {
      dark: "#3f8367",
    },
  },
  secondary: {
    main: "#6c4083",
    light: "#a97ab5",
    dark: "#453061",
    contrastText: "#fff",
  },
  form: {
    actionButton: {
      background: "#e0e0e0",
      color: "#2d333e",
    },
  },
  background: {
    default: "#f6f5f3",
    paper: "#fdfdfc",
    bgOnPaper: "#F8F8F7",
    inputField: "#FFFFFF",
    secondNavigation: "#fdfdfc",
    tooltip: "#eeeeee",
    other: "#F8F8F7",
  },
  grey: {
    A50: "#f4f3f1",
    listHeading: "rgba(50, 57, 69, 0.10)",
  },
  text: {
    black: "#2d333e",
    primary: "#2d333e",
    secondary: "#637088",
    disabled: "#929cb0",
    white: "#ffffff",
  },
  plum: {
    main: "#9446aa",
    contrastText: "#ffffff",
  },
  info: {
    main: "#1180ee",
    contrastText: "#ffffff",
  },
  error: {
    main: "#f13c37",
    contrastText: "#ffffff",
  },
  infoAlert: {
    main: "#1180ee",
    contrastText: "#ffffff",
  },
  critAlert: {
    main: "#f13c37",
    contrastText: "#ffffff",
  },
  warnAlert: {
    main: "#d58f15",
    contrastText: "#ffffff",
  },
  totalAlert: {
    main: "#ffffff",
    contrastText: "#2d333e",
  },
  action: {
    active: "#323945",
  },
  circuit: {
    components: circuitComponents,
    edges: circuitEdges,
    ports: circuitPorts,
    componentLabels: circuitComponentLabels,
    portLabels: circuitPortLabels,
    searchBox: {
      backgroundColor: "#6BAC8E",
      color: "#FFFFFF",
      border: `1px solid ${alpha("#ffffff", 0.5)}`,
      stroke: "#FFFFFF",
    },
  },
  divider: "#3239451f",
  other: {
    gridPlaceholder: "#b6b9c4",
    plum: "#9446aa",
  },
  flyoutMenu: {
    background: "#F6F5F3",
    textColor: "#2D333E",
  },
  codeBlock: {
    background: "#F1F1F1",
    shadow: "#606575",
    text: "#2F86D2",
  },
  tableGrid: {
    headColor: "#E7E4DF",
  },
  filterBar: {
    background: {
      default: "#E7E4DF",
      paper: "#FDFDFC",
    },
  },
};
