import { gql } from "graphql-request";

import type {
  RemoveUserGroupFromAssignedProjectMutation,
  RemoveUserGroupFromAssignedProjectMutationVariables,
} from "#shared/generated/graphql";
import { useGqlMutation, type UseGqlMutationOptions } from "#shared/utils";

/**
 *
 * Mutation to add user to user group
 * input = { user: UserId, userGroup: userGroupId }
 */

export const useAddUserToUserGroup = () => {
  const { mutate, isError, data } = useGqlMutation<unknown, unknown, {}>(
    AddUserToUserGroupMutation,
  );

  return {
    add: mutate,
    data,
    error: isError,
  };
};

const AddUserToUserGroupMutation = gql`
  mutation addUserToUserGroup($input: CreateUserGroupMembershipInput!) {
    addUserToUserGroup(input: $input) {
      userGroupMembership {
        user {
          email
        }
        userGroup {
          id
          name
        }
      }
    }
  }
`;

/**
 *
 * useGqlMutation to update user group name
 * input = { id: userGroupId, name: string }
 *
 */

export const useChangeUserGroupName = () => {
  const { mutate, isError, data } = useGqlMutation<
    { data: { updateUserGroupName: { userGroup: { name: string } } } },
    unknown,
    {}
  >(ChangeUserGroupName);

  return {
    change: mutate,
    data,
    error: isError,
  };
};

const ChangeUserGroupName = gql`
  mutation ChangeUserGroupName($input: UpdateUserGroupNameInput!) {
    updateUserGroupName(input: $input) {
      userGroup {
        name
      }
    }
  }
`;

/**
 *
 * useGqlMutation to remove user group
 * input = { id: userGroupId }
 *
 */

export const useRemoveUserGroup = () => {
  const { mutate, isError, data } = useGqlMutation<unknown, unknown, {}>(
    RemoveUserGroup,
  );

  return {
    remove: mutate,
    data,
    error: isError,
  };
};

const RemoveUserGroup = gql`
  mutation RemoveUserGroup($input: DeleteUserGroupInput!) {
    deleteUserGroup(input: $input) {
      userGroup {
        name
      }
    }
  }
`;

const removeUserGroupFromProject = gql`
  mutation removeUserGroupFromAssignedProject(
    $input: DeleteUserGroupProjectAssignmentInput!
  ) {
    removeUserGroupFromProject(input: $input) {
      userGroupProjectAssignment {
        id
        userGroup {
          id
          name
        }
      }
    }
  }
`;

export const useRemoveUserGroupFromProject = (
  options: UseGqlMutationOptions = {},
) =>
  useGqlMutation<
    RemoveUserGroupFromAssignedProjectMutation,
    unknown,
    RemoveUserGroupFromAssignedProjectMutationVariables["input"]
  >(removeUserGroupFromProject, options);
