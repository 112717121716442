import { gql } from "graphql-request";

import type { GetOrganizationDefaultRoleQuery } from "#shared/generated/graphql";
import { useGqlMutation, useGqlQuery } from "#shared/utils";

import { QUERY_KEYS } from "#organization/pages/consts";

const ChangeOrganizationName = gql`
  mutation updateOrganizationName($input: UpdateOrganizationNameInput!) {
    updateOrganizationName(input: $input) {
      organization {
        name
      }
    }
  }
`;

export const useChangeOrganizationName = () => {
  const { mutate, data, isError } = useGqlMutation<
    unknown,
    unknown,
    { id: string; name: string }
  >(ChangeOrganizationName);

  return {
    change: mutate,
    data,
    error: isError,
  };
};

const OrganizationDefaultRole = gql`
  query getOrganizationDefaultRole($where: OrganizationBoolExp) {
    organizations(where: $where) {
      edges {
        node {
          defaultRole
        }
      }
    }
  }
`;

export const useOrganizationDefaultRole = (organizationId: string) => {
  const options = { where: { id: { eq: organizationId } } };
  const { isError, isLoading, refetch, data } =
    useGqlQuery<GetOrganizationDefaultRoleQuery>(
      [QUERY_KEYS.ORGANIZATION_DEFAULT_ROLE, options],
      OrganizationDefaultRole,
      options,
    );

  return {
    error: isError,
    loading: isLoading,
    refetch,
    data: data?.organizations?.edges[0].node.defaultRole,
  };
};

const UpdateOrganizationDefaultRole = gql`
  mutation updateOrganizationDefaultRole(
    $input: UpdateOrganizationDefaultRoleInput!
  ) {
    updateOrganizationDefaultRole(input: $input) {
      organization {
        defaultRole
      }
    }
  }
`;

export const useChangeOrganizationDefaultRole = () => {
  const { mutate, data, isError } = useGqlMutation<
    unknown,
    unknown,
    { id: string; defaultRole: string | unknown }
  >(UpdateOrganizationDefaultRole);

  return {
    change: mutate,
    data,
    error: isError,
  };
};
