import { Typography, type ButtonProps } from "@mui/material";
import { Box } from "@mui/system";
import { SAMLAuthProvider, signInWithPopup } from "firebase/auth";
import { noop } from "lodash";
import React, { type FC, useCallback, useContext, useMemo } from "react";

import { SignInWithSamlButton } from "#shared/components/buttons";
import { FirebaseContext } from "#shared/contexts/firebase";
import { type UseFetch } from "#shared/hooks";
import { LoggerService } from "#shared/services";
import {
  type MapIdpConfigResponseKeyToIdpConfigObject,
  type UnsecuredInboundSamlConfig,
} from "#shared/types";

import { ErrorLayout } from "../layouts";

export type SignInWithSamlProps = ButtonProps & {
  config: NonNullable<
    UseFetch<
      MapIdpConfigResponseKeyToIdpConfigObject<"inboundSamlConfigs">
    >["result"]
  >;
};

export const SignInWithSaml: FC<SignInWithSamlProps> = ({
  config,
  ...buttonProps
}) => {
  const { onClick, provider, error } = useSignInWithSaml(config, buttonProps);

  if (provider) {
    return (
      <>
        <SignInWithSamlButton
          {...{ ...buttonProps, onClick, displayName: config.displayName }}
        />
        {error ? (
          <Box marginTop={1} marginBottom={2}>
            <Typography color="error">{error}</Typography>
          </Box>
        ) : null}
      </>
    );
  }

  return <ErrorLayout />;
};

export const useSignInWithSaml = (
  config: UnsecuredInboundSamlConfig,
  buttonProps: ButtonProps = {},
) => {
  const { firebase } = useContext(FirebaseContext);

  const [errorMsg, setErrorMsg] = React.useState<string>();

  const provider = useMemo(
    () => (config?.name ? createSamlProvider(config?.name) : null),
    [config?.name],
  );

  const onClick = useCallback<Required<ButtonProps>["onClick"]>(
    async (event) => {
      setErrorMsg(undefined);

      (buttonProps.onClick || noop)(event);

      try {
        if (!firebase) {
          throw new Error("Missing firebase.");
        }

        if (!provider) {
          throw new Error("Missing provider.");
        }

        await signInWithPopup(firebase, provider);
      } catch (err) {
        const error = err instanceof Error ? err.message : String(err);
        LoggerService.error(null, "Failed to sign in with saml", error);
        setErrorMsg(error);
      }
    },
    [firebase, provider, buttonProps],
  );

  return { onClick, provider, error: errorMsg };
};

export const createSamlProvider = (name: string) =>
  new SAMLAuthProvider(name.slice(name.lastIndexOf("/") + 1));
