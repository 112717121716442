import { type RecoilValueReadOnly, selectorFamily } from "recoil";

import { type FirebaseUser } from "../firebase.types";
import { HttpRequestRecoilState } from "../http-request-state";

type StateId = string;

export class FirebaseUserRecoilState extends HttpRequestRecoilState<
  StateId,
  FirebaseUser | null
> {
  public static override readonly ATOM_KEYS = {
    ...HttpRequestRecoilState.ATOM_KEYS,
    email: "email",
    isAuthenticated: "isAuthenticated",
    provider: "provider",
    token: "token",
    role: "role",
  };

  // NOTE: Firebase user's properties that are stored in the state.
  public static PROPERTIES: ReadonlyArray<keyof FirebaseUser> = [
    "uid",
    "providerData",
    "email",
    "stsTokenManager",
    "role",
  ];

  public email: RecoilValueReadOnly<string | null>;

  public isAuthenticated: RecoilValueReadOnly<boolean>;

  public provider: RecoilValueReadOnly<
    NonNullable<FirebaseUser["providerData"]>[number] | null
  >;

  public override id: RecoilValueReadOnly<string | null>;

  public token: RecoilValueReadOnly<string | null>;

  constructor(atomPrefix: StateId) {
    super(atomPrefix);

    FirebaseUserRecoilState.ATOM_PREFIX = atomPrefix;

    this.email = selectorFamily<string | null, StateId>({
      key: FirebaseUserRecoilState.ATOM_KEYS.email,
      get:
        () =>
        ({ get }) =>
          get(this.state).data?.email || null,
    })(this.atomPrefix);

    this.id = selectorFamily<string | null, StateId>({
      key: FirebaseUserRecoilState.ATOM_KEYS.id,
      get:
        () =>
        ({ get }) =>
          get(this.state).data?.uid || null,
    })(this.atomPrefix);

    this.provider = selectorFamily<
      NonNullable<FirebaseUser["providerData"]>[number] | null,
      StateId
    >({
      key: FirebaseUserRecoilState.ATOM_KEYS.provider,
      get:
        () =>
        ({ get }) =>
          get(this.state).data?.providerData?.[0] || null,
    })(this.atomPrefix);

    this.isAuthenticated = selectorFamily<boolean, StateId>({
      key: FirebaseUserRecoilState.ATOM_KEYS.isAuthenticated,
      get:
        () =>
        ({ get }) =>
          !!get(this.state).data?.uid,
    })(this.atomPrefix);

    this.isLoading = selectorFamily({
      key: FirebaseUserRecoilState.ATOM_KEYS.isLoading,
      get:
        () =>
        ({ get }) =>
          get(this.state).httpState.isLoading,
    })(this.atomPrefix);

    this.token = selectorFamily({
      key: FirebaseUserRecoilState.ATOM_KEYS.token,
      get:
        () =>
        ({ get }) =>
          get(this.state).data?.stsTokenManager.accessToken || null,
    })(this.atomPrefix);
  }
}
