import { Button } from "@mui/material";
import React from "react";

import type { HeadCell } from "#shared/components/data-grid";

import type { UserGroupData } from "./types";

export const headCells = (
  permissionLock: boolean,
  onLeaveUserGroup: (id: string) => void,
): HeadCell<UserGroupData>[] => [
  {
    accessor: (row) => row.name,
    label: "User Group",
    key: "user group",
  },
  {
    accessor: (row) => row.membersCount.toString(),
    label: "Users",
    key: "users",
  },
  {
    accessor: (row) => (
      <Button
        variant="text"
        size="small"
        disabled={permissionLock}
        color="error"
        onClick={() => onLeaveUserGroup(row.id)}
      >
        Remove from user group
      </Button>
    ),
    label: "Actions",
    noSorting: true,
    key: "actions",
  },
];
