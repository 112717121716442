import { Button } from "@mui/material";
import React, { type FC } from "react";

import type { HeadCell } from "#shared/components/data-grid";
import { getUserRoleName, type UserRole } from "#shared/recoil";

import { useMemberPagePermissionLock } from "#organization/pages/authenticated/settings/organization/members/member";

import type { UserGroupMembersData } from "./types";

export const headCells = (
  permissionLock: boolean,
  currentUserRole: UserRole,
  onRemove: (email: string, membershipId: string) => void,
): HeadCell<UserGroupMembersData>[] => [
  {
    accessor: "name",
    label: "Name",
    key: "name",
  },
  {
    accessor: "email",
    label: "Email",
    key: "email",
  },
  {
    accessor: (row) => getUserRoleName(row.role as UserRole),
    label: "Role",
    key: "role",
  },
  {
    accessor: (row) => (
      <RemoveUserFromUserGroup
        {...{
          row,
          currentUserRole,
          permissionLock,
          onRemove,
        }}
      />
    ),
    label: "Actions",
    key: "membershipId",
  },
];

export interface RemoveUserFromUserGroupProps {
  row: UserGroupMembersData;
  currentUserRole: UserRole;
  permissionLock: boolean;
  onRemove: (email: string, membershipId: string) => void;
}

export const RemoveUserFromUserGroup: FC<RemoveUserFromUserGroupProps> = ({
  row,
  currentUserRole,
  permissionLock,
  onRemove,
}) => {
  const lock = useMemberPagePermissionLock(
    currentUserRole,
    row?.status || "",
    row.role as UserRole,
  );

  return (
    <Button
      size="small"
      variant="text"
      disabled={permissionLock || lock}
      onClick={() => onRemove(row.email, row.membershipId)}
      color="error"
    >
      Delete
    </Button>
  );
};
