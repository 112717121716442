import { gql } from "graphql-request";

export const filters = (id: string) => [
  {
    id: 0,
    name: "Secret name",
    key: "name",
    category: "apertureSecrets",
    gql: gql`
      query SecretsName($where: ApertureSecretBoolExp, $first: Int) {
        apertureSecrets(where: $where, first: $first, distinctOn: [name]) {
          totalCount
          edges {
            node {
              name
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => ({
      first: 5,
      where: {
        projectID: { eq: id },
        name: {
          like: `%${prop}%`,
        },
      },
    }),
  },
];
