import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import type { AnyObject } from "#shared/types";

import { TOP_BAR_ID } from "#organization/consts";

import { useSidebarWidth } from "../../../recoil/sidebar";

export type Size = { width: number; height: number };

type ContentViewPortSize = Size &
  AnyObject<"topBarHeight" | "sidebarWidth", number>;

/**
 *
 * NOTE:
 *
 * Returns { width: root.DomRect.width - sidebar width; height: window.innerHeight - top bar height }
 */
export function useContentViewPortSize() {
  const { width: sidebarWidth } = useSidebarWidth();

  const topBarRef = useRef(
    document.getElementById(TOP_BAR_ID) as HTMLDivElement,
  );

  const topBarHeight = useMemo(
    () => topBarRef.current?.getBoundingClientRect().height || 0,
    [],
  );

  const [height, setHeight] = useState(window.innerHeight);

  const [width, setWidth] = useState(window.innerWidth);

  const onWindowResize = useCallback(() => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, [onWindowResize]);

  return useMemo<ContentViewPortSize>(
    () => ({
      width: Math.max(0, width - sidebarWidth),
      height: Math.max(0, height - topBarHeight),
      topBarHeight,
      sidebarWidth,
    }),
    [height, width, topBarHeight, sidebarWidth],
  );
}
