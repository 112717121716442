import { Box, styled } from "@mui/material";
import React, { type FC, type ReactNode } from "react";

import type { Texts } from "#shared/types";

import { BoolField, type BoolFieldProps } from "../../inputs";

export type SchedulerTextNames = "title";

export declare type MethodNames = "Static workloads" | "Dynamic workloads";

export declare type SelectFieldChange = (
  value: boolean,
  setSelected: React.Dispatch<React.SetStateAction<boolean>>,
) => void;

export declare type SchedulerTemplateProps = {
  texts?: Texts<SchedulerTextNames>;
  fieldsPrefix?: string;
  children: (method: MethodNames) => ReactNode;
  useSelection: [
    value: boolean,
    setSelected: React.Dispatch<React.SetStateAction<boolean>>,
  ];
} & Partial<Omit<BoolFieldProps, "onChange" | "value">>;

const defaultTexts: Texts<SchedulerTextNames> = {
  title: "Select type of workloads",
};

export const SchedulerTemplate: FC<SchedulerTemplateProps> = ({
  texts = defaultTexts,
  fieldsPrefix = "",
  children,
  useSelection,
  ...boolFieldProps
}) => {
  const [selected, setSelected] = useSelection;

  return (
    <SchedulerTemplateWrapper>
      <BoolField
        {...{
          label: texts.title,
          fieldAddress: fieldsPrefix,
          value: selected,
          onChange(value) {
            setSelected(value);
          },
          texts: {
            true: "Dynamic",
            false: "Static",
          },
          ...boolFieldProps,
        }}
      />
      {children(selected ? "Dynamic workloads" : "Static workloads")}
    </SchedulerTemplateWrapper>
  );
};

export const SchedulerTemplateWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateRows: "auto 1fr",
  rowGap: theme.spacing(2),
}));
