import {
  type Auth,
  signInWithEmailLink,
  type UserCredential,
} from "firebase/auth";
import { merge, noop } from "lodash";
import React, { useCallback, useMemo } from "react";
import { type DeepPartial } from "react-hook-form";

import { usePopUp } from "#shared/components/pop-up";
import { type HttpRequestState, FirebaseUserRecoilState } from "#shared/recoil";
import { type FirebaseUser } from "#shared/recoil/firebase.types";
import { LoggerService } from "#shared/services";

import { ConfirmEmailForm } from "../../components";

export type SetUser = (
  user: DeepPartial<HttpRequestState<FirebaseUser | null, string | null>>,
) => void;

export type UseSignInWithEmailLink = {
  signIn: (
    signInEmail: string,
    setUser: SetUser,
  ) => Promise<null | UserCredential>;
};

/**
 * NOTE:
 *
 * Signs in with email link
 * (i.e. when user clicks link in the email)
 */
export function useSignInWithEmailLink(
  firebase: Auth | null,
  isSignInEmailWithLink: boolean | null,
  href: string,
): UseSignInWithEmailLink | null {
  const { setShowDeleteBox: setShowPopUpBox } = usePopUp();
  const applySignIn = useMemo<NonNullable<UseSignInWithEmailLink>["signIn"]>(
    () => async (signInEmail, setUser) => {
      const initial: HttpRequestState<null, null> = merge<
        HttpRequestState<null, null>,
        DeepPartial<HttpRequestState<unknown, null>>
      >(FirebaseUserRecoilState.INITIAL_STATE, {
        httpState: { isIdle: false, isLoading: true },
      });

      setUser(initial);

      try {
        const response = await signInWithEmailLink(
          firebase!,
          signInEmail,
          href,
        );

        LoggerService.debug("Sign in", { response });
        setShowPopUpBox(false);
        setUser({ httpState: { isSuccess: true, isLoading: false } });

        return response;
      } catch (err) {
        setUser({
          httpState: {
            isLoading: false,
            error: err instanceof Error ? err.message : String(err),
          },
        });

        return null;
      }
    },
    [firebase, href, setShowPopUpBox],
  );

  const signIn = useMemo<NonNullable<UseSignInWithEmailLink>["signIn"]>(
    () => async (signInEmail, setUser) => {
      if (!firebase) {
        LoggerService.debug("Missing firebase in signIn.");

        return null;
      }

      if (!isSignInEmailWithLink) {
        LoggerService.debug(
          null,
          "!isSignInWithEmailLink",
          window.location.href,
        );

        return null;
      }

      if (!signInEmail?.length) {
        LoggerService.debug("Missing email in signIn.");

        return null;
      }

      return applySignIn(signInEmail, setUser);
    },
    [applySignIn, firebase, isSignInEmailWithLink],
  );

  return useMemo(
    () => ({
      signIn,
      applySignIn,
    }),
    [signIn, applySignIn],
  );
}

export const useConfirmEmailWhenSignInWithEmailLink = () => {
  const { addDeleteBox: addPopUpBox } = usePopUp();
  const askEmailAgain = useCallback(
    (callback: (email: string) => void) => {
      addPopUpBox(
        {
          message: (
            <ConfirmEmailForm
              {...{
                callback,
              }}
            />
          ),
          callback: noop,
        },
        {
          outerBoxProps: {
            PaperProps: {
              sx: {
                height: 450,
                padding: 3,
              },
            },
            disableEscapeKeyDown: true,
          },
          yesButtonProps: {
            showYesButton: false,
            props: {},
          },
          noButtonProps: {
            showNoButton: false,
            props: {},
          },
          titleProps: {
            showTitle: false,
            props: {},
          },
          texts: {
            title: "",
            yes: "",
            no: "",
          },
        },
      );
    },
    [addPopUpBox],
  );

  return {
    askEmailAgain,
  };
};
