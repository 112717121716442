import React, { useEffect, type FC } from "react";
import { useRecoilState } from "recoil";

import { LoaderLayout } from "#shared/components/layouts";

import {
  NoKubernetesLayout,
  useKubernetesCheck,
} from "#organization/components/layouts";
import { projectContextState } from "#organization/recoil/project";

import { DiscoveryServicesTab, DiscoveryKubernetesTab } from "./tabs";

type DiscoveryPageProps = {
  currentTab: number;
};

export const DiscoveryPage: FC<DiscoveryPageProps> = ({ currentTab }) => {
  const [projectContext] = useRecoilState(projectContextState);
  const isKubernetesConfigured = useKubernetesCheck(projectContext.id);

  // refetch check for kubernetes flag when project context changes
  useEffect(() => {
    isKubernetesConfigured.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectContext]);

  return (
    <>
      {currentTab === 0 && <DiscoveryServicesTab />}
      {currentTab === 1 && (
        <>
          {isKubernetesConfigured.isLoading && <LoaderLayout />}

          {!isKubernetesConfigured.isLoading &&
            isKubernetesConfigured.isConfigured && <DiscoveryKubernetesTab />}

          {!isKubernetesConfigured.isLoading &&
            !isKubernetesConfigured.isConfigured && <NoKubernetesLayout />}
        </>
      )}
    </>
  );
};
