import type { ButtonProps } from "@mui/material";
import { noop } from "lodash";
import { useCallback, useMemo } from "react";
import { useQueryClient, type UseMutationResult } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { useAlert } from "#shared/components/alerts-provider";
import type {
  CreateBlueprintPolicyDefinitionInput,
  CreateBlueprintPolicyDefinitionPayload,
  CreateCustomPolicyDefinitionInput,
  CreateCustomPolicyDefinitionPayload,
  UpdateBlueprintPolicyDefinitionInput,
  UpdateBlueprintPolicyDefinitionPayload,
  UpdateCustomPolicyDefinitionInput,
  UpdateCustomPolicyDefinitionPayload,
} from "#shared/generated/graphql";
import { userNewState } from "#shared/recoil";
import { LoggerService } from "#shared/services";
import type { AnyObject } from "#shared/types";

import { useProjectContextState } from "#organization/recoil/project";

import { POLICIES_ROUTE } from "../../../routes.definitions";
import { useCreatePolicyContext, type CreatePolicyCtx } from "../../context";
import {
  useCreateBlueprintPolicyDefinition,
  useCreateCustomPolicyDefinition,
  useCreatePolicySearchParams,
  useUpdateBlueprintPolicyDefinition,
  useUpdateCustomPolicyDefinition,
} from "../../hooks";

const popUpMessage = (name: string) =>
  `Your configuration for ${name} Policy blueprint has been saved
and can be accessed now from Policies list.`;

const defaultPopUpTexts = {
  title: "Draft for your Policy has been saved",
  yes: "Leave",
  no: "Get back to editing",
};

const commonButtonProps: ButtonProps = {
  color: "primary",
  size: "small",
};

export declare type PolicyMutationResponse<
  T extends "Custom" | "Blueprint",
  IsEdit extends boolean,
> = T extends "Custom"
  ? IsEdit extends true
    ? {
        mutation: UseMutationResult<
          UpdateCustomPolicyDefinitionPayload,
          unknown,
          UpdateCustomPolicyDefinitionInput,
          unknown
        >;
        input?: UpdateCustomPolicyDefinitionInput;
        partialInput: Omit<UpdateCustomPolicyDefinitionInput, "body">;
        payload?: UpdateCustomPolicyDefinitionPayload;
      }
    : {
        mutation: UseMutationResult<
          CreateCustomPolicyDefinitionPayload,
          unknown,
          CreateCustomPolicyDefinitionInput,
          unknown
        >;
        input?: CreateCustomPolicyDefinitionInput;
        partialInput: Omit<CreateCustomPolicyDefinitionInput, "body">;
        payload?: CreateCustomPolicyDefinitionPayload;
      }
  : T extends "Blueprint"
  ? IsEdit extends true
    ? {
        mutation: UseMutationResult<
          UpdateBlueprintPolicyDefinitionPayload,
          unknown,
          UpdateBlueprintPolicyDefinitionInput,
          unknown
        >;
        input?: UpdateBlueprintPolicyDefinitionInput;
        partialInput: Omit<
          UpdateBlueprintPolicyDefinitionInput,
          "values" | "name"
        >;
        payload?: UpdateBlueprintPolicyDefinitionPayload;
      }
    : {
        mutation: UseMutationResult<
          CreateBlueprintPolicyDefinitionPayload,
          unknown,
          CreateBlueprintPolicyDefinitionInput,
          unknown
        >;
        input?: CreateBlueprintPolicyDefinitionInput;
        partialInput: Omit<
          CreateBlueprintPolicyDefinitionInput,
          "values" | "name"
        >;
        payload?: CreateBlueprintPolicyDefinitionPayload;
      }
  : never;

/**
 *
 * This hook returns mutation and payload for the policy create/update draft.
 *
 * Default case is policy create using FN blueprint. Hook decide to update according to the search parameters.
 */
export const usePolicyMutation = (
  origin: "Blueprint" | "Custom" = "Blueprint",
  updateDraftedPolicy: boolean = false,
): PolicyMutationResponse<typeof origin, typeof updateDraftedPolicy> => {
  const { data: user } = useRecoilValue(userNewState.state);
  /**
   * Create policy using FluxNinja blueprint
   */
  const createBlueprintPolicy = useCreateBlueprintPolicyDefinition();

  /**
   * Create a customize policy. This policy is created by user from scratch.
   */
  const createCustomPolicy = useCreateCustomPolicyDefinition();

  /**
   * Update policy using FluxNinja blueprint
   */
  const updateBlueprintPolicy = useUpdateBlueprintPolicyDefinition();

  /**
   * Update a customize policy. This policy is created by user from scratch.
   */
  const updateCustomPolicy = useUpdateCustomPolicyDefinition();

  const {
    searchInfo: {
      policyID,
      blueprintName,
      name,
      editedAt,
      blueprintVersion: version,
    },
  } = useCreatePolicySearchParams();

  const { id } = useProjectContextState();

  // create custom policy
  if (origin === "Custom" && !updateDraftedPolicy) {
    const input: Omit<CreateCustomPolicyDefinitionInput, "body"> = {
      name,
      project: id,
    };

    return {
      mutation: createCustomPolicy,
      partialInput: input,
    };
  }

  // update custom policy
  if (origin === "Custom" && updateDraftedPolicy && policyID) {
    const partialInput: Omit<UpdateCustomPolicyDefinitionInput, "body"> = {
      id: policyID,
      name,
      editedAt,
      lastModifiedByID: user?.id || "",
      message: null,
    };

    return {
      mutation: updateCustomPolicy,
      partialInput,
    };
  }

  // update blueprint policy
  if (origin === "Blueprint" && updateDraftedPolicy && policyID) {
    const blueprintPolicyUpdateDraftPayload: Omit<
      UpdateBlueprintPolicyDefinitionInput,
      "values" | "name"
    > = {
      id: policyID,
      blueprintVersion: version,
      blueprintName,
      editedAt,
      lastModifiedByID: user?.id || "",
      message: null,
    };

    return {
      mutation: updateBlueprintPolicy,
      partialInput: blueprintPolicyUpdateDraftPayload,
    };
  }

  // default case is draft blueprint policy creation
  const blueprintPolicyCreateDraftPayload: Omit<
    CreateBlueprintPolicyDefinitionInput,
    "values" | "name"
  > = {
    blueprintVersion: version,
    blueprintName,
    project: id,
  };

  return {
    mutation: createBlueprintPolicy,
    partialInput: blueprintPolicyCreateDraftPayload,
  };
};

export const useDraftPolicyHelper = (
  updateDraftedPolicy = false,
  hideSavedDraftPopUp = false,
) => {
  const {
    searchInfo: {
      origin = "Blueprint",
      name: policyName,
      policyID,
      status: policyStatus,
    },
  } = useCreatePolicySearchParams();

  const {
    submitPolicyValues,
    addPopUpBox,
    blueprintJson: savedJson,
  } = useCreatePolicyContext();

  const policyNameFromJson = useMemo(
    () =>
      (savedJson as unknown as { policy: { policy_name: string } })?.policy
        ?.policy_name,
    [savedJson],
  );

  const {
    mutation: { isLoading, isError, mutate, data },
    partialInput,
  } = usePolicyMutation(origin, updateDraftedPolicy);

  const resetQueryKey = useMemo(() => {
    if (!updateDraftedPolicy) return null;

    return origin === "Blueprint"
      ? ["getBlueprintPolicyDefinitions", policyID]
      : ["getCustomPolicyDefinitions", policyID];
  }, [origin, policyID, updateDraftedPolicy]);

  const { addAlert } = useAlert();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const disableUpdate = useMemo(
    () =>
      updateDraftedPolicy &&
      policyStatus !== "1-draft" &&
      policyStatus !== "0-error" &&
      !!policyStatus?.length,
    [policyStatus, updateDraftedPolicy],
  );

  const alerter = useCallback(
    () => ({
      onSuccess: () => {
        if (hideSavedDraftPopUp) {
          addAlert({
            type: "success",
            message: "Draft has been saved successfully.",
          });

          return;
        }

        addPopUpBox(
          {
            message: popUpMessage(policyName || policyNameFromJson),
            callback: (status) => {
              if (status) {
                navigate(POLICIES_ROUTE.ABSOLUTE_PATH);
              }
            },
          },
          {
            texts: defaultPopUpTexts,
            yesButtonProps: {
              showYesButton: true,
              props: {
                variant: "outlined",
                ...commonButtonProps,
              },
            },
            noButtonProps: {
              showNoButton: true,
              props: {
                variant: "contained",
                ...commonButtonProps,
              },
            },
          },
        );

        if (resetQueryKey) {
          queryClient.invalidateQueries(resetQueryKey);
        }
      },
      onError: (err: unknown) => {
        addAlert({
          type: "error",
          message: "Failed to save blueprint draft.",
        });

        LoggerService.error([err, "Error saving blueprint draft."]);
      },
    }),
    [
      addAlert,
      addPopUpBox,
      hideSavedDraftPopUp,
      navigate,
      policyName,
      policyNameFromJson,
      queryClient,
      resetQueryKey,
    ],
  );

  const handleSubmitYamlValues = useCallback(
    (saveEditor: () => void = noop) =>
      (
        isErr: boolean,
        {
          blueprintJson,
        }: Pick<CreatePolicyCtx, "blueprintJson" | "blueprintYaml">,
      ) => {
        if (isErr || !blueprintJson) {
          return;
        }

        const policyPayload = {
          ...partialInput,
          ...(origin === "Blueprint" && {
            values: blueprintJson,
            name: (
              blueprintJson as unknown as { policy: { policy_name: string } }
            ).policy.policy_name,
          }),
          ...(origin === "Custom" && {
            body: blueprintJson,
          }),
        };

        saveEditor();

        if (disableUpdate) return;

        mutate(
          // @ts-ignore
          policyPayload,
          alerter(),
        );
      },
    [alerter, disableUpdate, mutate, origin, partialInput],
  );

  return {
    handleSubmitYamlValues,
    isError,
    isLoading,
    submitPolicyValues,
    data,
    policyNameFromJson,
    disabled: disableUpdate,
  };
};

export function findLastEditedAt(
  isInEditMode: boolean,
  origin: "Custom" | "Blueprint",
  responseAfterSave?:
    | UpdateCustomPolicyDefinitionPayload
    | CreateCustomPolicyDefinitionPayload
    | UpdateBlueprintPolicyDefinitionPayload
    | CreateBlueprintPolicyDefinitionPayload,
) {
  if (!responseAfterSave) {
    return null;
  }

  const operation = isInEditMode ? "update" : "create";

  return extractResponse(responseAfterSave, origin, operation)?.editedAt;
}

export function findPolicyIdOutOfResponse(
  isInEditMode: boolean,
  origin: "Custom" | "Blueprint",
  responseAfterSave?:
    | UpdateCustomPolicyDefinitionPayload
    | CreateCustomPolicyDefinitionPayload
    | UpdateBlueprintPolicyDefinitionPayload
    | CreateBlueprintPolicyDefinitionPayload,
) {
  if (!responseAfterSave) {
    return null;
  }

  const operation = isInEditMode ? "update" : "create";

  return extractResponse(responseAfterSave, origin, operation)?.id;
}

export function extractResponse(
  responseAfterSave: AnyObject,
  origin: "Custom" | "Blueprint",
  operation: "create" | "update",
) {
  const key = `${operation}${origin}PolicyDefinition`;

  return (
    responseAfterSave?.[key]?.[`${origin.toLowerCase()}PolicyDefinition`] ||
    null
  );
}
