# Bucket Capacity

## Overview

The `bucket_capacity` sets the limit for requests per label key, defining the maximum capacity of the bucket in the rate limiter.

## Example

With a capacity set to 40, a user is allowed to send up to 40 requests. If a user makes more than 40 requests in a burst, their following requests will be placed in queue.

## Note

In a quota scheduling policy, aligning the bucket capacity with external API rate limits allows users' requests to be queued as they approach these limits, effectively preventing rate limit.
Once tokens are withdrawn from the bucket as requests are coming in, tokens will be refilled on a `fill_amount x interval` basis.
