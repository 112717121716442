/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/no-extraneous-dependencies
import * as monaco from "monaco-editor";
// @ts-ignore
import EditorWorker from "monaco-editor/esm/vs/editor/editor.worker?worker";

// @ts-ignore
import YamlWorker from "./yaml.worker?worker";

// @ts-ignore
// eslint-disable-next-line no-restricted-globals
self.MonacoEnvironment = {
  getWorker(_: any, label: string) {
    if (label === "yaml") {
      return new YamlWorker();
    }

    return new EditorWorker();
  },
};

monaco.languages.typescript.typescriptDefaults.setEagerModelSync(true);
