import { gql } from "graphql-request";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";

import type {
  GetProjectCountQuery,
  GetProjectsSettingsQuery,
} from "#shared/generated/graphql";
import { userNewState } from "#shared/recoil";
import type { ProvisioningStatusText, StandardResponse } from "#shared/types";
import { useGqlQuery } from "#shared/utils";
import { httpClient } from "#shared/utils/http-client";

import { useGridData, type DataGridHook } from "#organization/hooks";
import { API_URLS, QUERY_KEYS } from "#organization/pages/consts";
import { useOrganizationState } from "#organization/recoil/organization";

export const ProjectsQuery = gql`
  query GetProjectsSettings(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $orderBy: [ProjectOrderBy]
    $where: ProjectBoolExp
  ) {
    projects(
      first: $first
      last: $last
      after: $after
      before: $before
      orderBy: $orderBy
      where: $where
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          name
          status
          region
          cloud_provider
          userGroupProjectAssignments {
            totalCount
          }
        }
      }
    }
  }
`;
type ProjectsGroupData =
  GetProjectsSettingsQuery["projects"]["edges"][0]["node"];

export type AdaptedProjects = {
  id: string;
  name: string;
  status: string;
  region: string;
  cloudProvider: string;
  userGroupCount: number;
};

export const useProjectData: DataGridHook = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  enabled,
  filterVariables,
) =>
  useGridData<ProjectsGroupData, AdaptedProjects>(
    "projects",
    ProjectsQuery,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    filterVariables,
    (projects) => ({
      id: projects.id,
      name: projects.name,
      status: projects.status,
      region: projects.region,
      cloudProvider: projects.cloud_provider,
      userGroupCount: projects.userGroupProjectAssignments.totalCount,
    }),
    enabled,
    false,
  );

export const ProjectCountQuery = gql`
  query GetProjectCount {
    projects {
      totalCount
    }
  }
`;

export const useProjectCount = () => {
  const { data } = useGqlQuery<GetProjectCountQuery>(
    ["projects", QUERY_KEYS.PROJECT_COUNT],
    ProjectCountQuery,
  );

  return data?.projects.totalCount || 0;
};

type UpstreamProjectProvisioningStatus = {
  provisioning_status: ProvisioningStatusText;
  id: string;
  error?: string;
};

export type ProjectProvisioningStatus =
  | UpstreamProjectProvisioningStatus["provisioning_status"]
  | "unknown";

export const useProjectProvisioningStatus = (
  projectID: string,
): ProjectProvisioningStatus => {
  const { id: orgID, subdomain } = useOrganizationState();
  const firebaseEmail = useRecoilValue(userNewState.email);

  const query = useQuery(
    ["projects", QUERY_KEYS.PROJECT_PROVISIONING_STATUS, projectID],
    {
      queryFn: async () => {
        const response = await httpClient.post<
          StandardResponse<UpstreamProjectProvisioningStatus>
        >({
          url: API_URLS.ORGANIZATIONS.PROJECT_PROVISIONING_STATUS,
          body: {
            organization: {
              domain_name: subdomain,
              id: orgID,
            },
            user: { email: firebaseEmail },
            projectID,
          },
        });

        return response.json();
      },
      retry: 3,
      refetchInterval: (response) =>
        (response as undefined | { status: number })?.status !== 500 &&
        response?.data?.provisioning_status !== "PROVISIONED"
          ? 2500
          : false,
    },
  );

  return query.data?.data?.provisioning_status || "unknown";
};
