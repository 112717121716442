import {
  MenuItem,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Divider,
  Box,
} from "@mui/material";
import React, {
  type Dispatch,
  type FC,
  type PropsWithChildren,
  type SetStateAction,
  useEffect,
} from "react";
import {
  type FieldErrorsImpl,
  type FieldValues,
  type UseFieldArrayUpdate,
  useForm,
  type UseFormRegister,
  type UseFormWatch,
} from "react-hook-form";

import { EMAIL_PATTERN, URL_PATTERN } from "#shared/consts";
import { LoggerService } from "#shared/services";
import type { Texts } from "#shared/types";

import { ChannelTextInput, TEXT_FIELD_PROPS } from "./channel-text-input";

import type { AlertsMutationFunctions } from "../../../data-center";
import { ColumnWrapper, GridWrapper } from "../../../styled";

export type NotifierTypeMenuList = {
  name: string;
  key: number;
  value: keyof Omit<AlertsMutationFunctions, "createAlertsChannel">;
};

const NOTIFIER_TYPE: NotifierTypeMenuList[] = [
  { name: "Email", key: 0, value: "createEmailNotifier" },
  { name: "Slack", key: 1, value: "createSlackNotifier" },
  { name: "Webhook", key: 2, value: "createWebhookNotifier" },
];

export type GetFormByNotifierTypeProps = PropsWithChildren<{
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  index: number;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: unknown;
    }>
  >;
  texts?: Texts<Text>;
}>;

export interface FormByNotifierTypeProps {
  value: FieldValues;
  storeDataFlagState: [string, Dispatch<SetStateAction<string>>];
  update: UseFieldArrayUpdate<FieldValues, "newChannel">;
  index: number;
  defaultValues?: FieldValues;
  texts?: Texts<Text>;
}

type Text =
  | "selectNotifier"
  | "sendResolvedMessage"
  | "emailAddress"
  | "emailIsRequired"
  | "slackNameIsRequired"
  | "slackUrlIsRequired"
  | "slackUrl"
  | "slackChannel"
  | "webhookUrl"
  | "webhookUrlIsRequired";

const enTexts: Required<FormByNotifierTypeProps["texts"]> = {
  selectNotifier: "Select a notifier",
  sendResolvedMessage: "Send resolved message",
  emailAddress: "Email address",
  emailIsRequired: "Email address is required",
  slackNameIsRequired: "Slack channel name is required",
  slackUrlIsRequired: "Slack url is required",
  slackUrl: "Slack url",
  slackChannel: "Slack channel",
  webhookUrl: "Webhook url",
  webhookUrlIsRequired: "Webhook url is required",
};

export const FormByNotifierType: FC<FormByNotifierTypeProps> = ({
  value,
  storeDataFlagState,
  update,
  index,
  defaultValues = { notifierType: "createEmailNotifier", sendResolved: false },
  texts = enTexts,
}) => {
  const [storeDataFlag, setStoreDataFlag] = storeDataFlagState;

  const loadableDefault: FieldValues =
    storeDataFlag === "infoCollected" ? value : defaultValues;

  const {
    register,
    watch,
    getValues,
    formState: { errors },
    trigger,
  } = useForm({
    defaultValues: {
      id: value.id,
      ...loadableDefault,
    } as FieldValues,
    shouldUnregister: true,
    mode: "onChange",
  });

  useEffect(() => {
    if (storeDataFlag === "submitted") {
      trigger()
        .then((isValid) => {
          if (!isValid) {
            setStoreDataFlag("noChange");

            return;
          }

          update(index, getValues());
          setStoreDataFlag("infoCollected");
        })
        .catch((err) => {
          LoggerService.error({ err });
        });
    }
  }, [getValues, index, setStoreDataFlag, storeDataFlag, trigger, update]);

  return (
    <ColumnWrapper>
      <GridWrapper sx={{ width: "90%" }}>
        <Typography fontSize={14} fontWeight={700}>
          {texts.selectNotifier}
        </Typography>
        <TextField
          select
          {...register("notifierType")}
          value={watch("notifierType")}
          {...TEXT_FIELD_PROPS}
        >
          {NOTIFIER_TYPE.map((type) => (
            <MenuItem key={type.key} value={type.value}>
              {type.name}
            </MenuItem>
          ))}
        </TextField>
      </GridWrapper>

      <GetFormByNotifierType {...{ register, watch, index, errors }} />

      <Divider />

      <GridWrapper
        sx={{
          gridTemplateColumns: "180px 1fr",
          width: "90%",
        }}
      >
        <Typography fontWeight={700} fontSize={14}>
          {texts.sendResolvedMessage}
        </Typography>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            ...TEXT_FIELD_PROPS.sx(theme),
          })}
        >
          <FormControlLabel
            control={
              <Checkbox
                {...register("sendResolved")}
                checked={watch("sendResolved") || false}
              />
            }
            label="Enable the resolve message [OK] that is sent when alerting state returns to false"
            style={{ ...TEXT_FIELD_PROPS.InputProps.style, marginRight: 0 }}
          />
        </Box>
      </GridWrapper>
    </ColumnWrapper>
  );
};

const GetFormByNotifierType: FC<GetFormByNotifierTypeProps> = ({
  register,
  watch,
  children,
  index,
  errors,
  texts = enTexts,
}) => {
  const notifierType = watch("notifierType") || "createEmailNotifier";

  switch (notifierType) {
    case "createEmailNotifier":
      return (
        <ChannelTextInput
          textFieldProps={{
            id: createInputFieldID("email", index),
            type: "email",
            title: "Email address",
            placeholder: "email",
            ...register("email", {
              required: "Email address is required",
              pattern: EMAIL_PATTERN,
            }),
            error: !!errors.email,
            helperText: errors.email?.message,
          }}
        />
      );
    case "createSlackNotifier":
      return (
        <ChannelTextInput
          textFieldProps={[
            {
              title: texts.slackUrl,
              id: createInputFieldID("url", index),
              type: "text",
              placeholder: texts.slackUrl,
              ...register("url", {
                required: texts.slackUrlIsRequired,
                pattern: URL_PATTERN,
              }),
              error: !!errors.url,
              helperText: errors.url?.message,
            },
            {
              title: texts.slackChannel,
              id: createInputFieldID("channel", index),
              type: "text",
              placeholder: texts.slackChannel,
              ...register("channel", {
                required: texts.slackNameIsRequired,
              }),
              value:
                !watch("channel")?.includes("#") && watch("channel")
                  ? ["#", watch("channel")?.toLowerCase()].join("")
                  : watch("channel")?.toLowerCase(),
              error: !!errors.channel,
              helperText: errors.channel?.message,
            },
          ]}
        />
      );
    case "createWebhookNotifier":
      return (
        <ChannelTextInput
          textFieldProps={[
            {
              title: texts.webhookUrl,
              id: createInputFieldID("url", index),
              type: "text",
              placeholder: texts.webhookUrl,
              ...register("url", {
                required: texts.webhookUrlIsRequired,
                pattern: URL_PATTERN,
              }),
              error: !!errors.url,
              helperText: errors.url?.message,
            },
          ]}
        />
      );
    default:
      return <>{children}</>;
  }
};

const createInputFieldID = (name: string, index: number): string =>
  [name, index].join("-");
