import type { Variables } from "graphql-request";
import {
  type Dispatch,
  type MouseEvent,
  type SetStateAction,
  useState,
  useCallback,
} from "react";

import type { PageInfo } from "#shared/generated/graphql";

export const useGraphqlPagination = (
  setPage: Dispatch<SetStateAction<number>>,
) => {
  const [info, setPageInfo] = useState<PageInfo | null>(null);
  const [isNext, setIsNext] = useState<boolean>(true);

  const paginationVariables = useCallback(
    (pageSize: number) => {
      const variables: Variables = {};
      variables.first = pageSize;

      if (info?.endCursor) {
        if (isNext) {
          variables.after = info.endCursor;
        } else {
          variables.before = info.startCursor;
          variables.last = pageSize;
          variables.first = null;
        }
      }

      return variables;
    },
    [info?.endCursor, info?.startCursor, isNext],
  );

  const onPageChange = useCallback(
    (pageInfo: PageInfo) =>
      (_event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage((oldPage) => {
          setIsNext(newPage - oldPage === 1);

          return newPage;
        });

        setPageInfo(pageInfo);
      },
    [setPage],
  );

  const resetPagination = useCallback(() => {
    setPage(0);
    setPageInfo(null);
  }, [setPage]);

  return {
    onPageChange,
    paginationVariables,
    resetPagination,
  };
};
