import { type Dispatch, type SetStateAction } from "react";

import { type DataGridProps } from "#shared/components/data-grid";

import type { CacheDashboardConfig } from "../../../cache-dashboard/types";
import type { AdaptedResultCacheData } from "../hooks";

export const rowClick: (
  setIsFlyoutMenuVisible: Dispatch<SetStateAction<boolean>>,
  setCacheDashboardConfig: Dispatch<SetStateAction<CacheDashboardConfig>>,
) => DataGridProps<AdaptedResultCacheData>["rowOnClick"] =
  (setIsFlyoutMenuVisible, setCacheDashboardConfig) => (row) => () => {
    setCacheDashboardConfig({
      controllerId: row.service?.controller.name || "",
      controlPoint: row.name,
      agentGroup: row.service?.agentGroup || "",
      type: "result",
    });

    setIsFlyoutMenuVisible(true);
  };
