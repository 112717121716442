import {
  Box,
  Button,
  FormControl,
  type ButtonProps,
  Typography,
} from "@mui/material";
import { noop } from "lodash";
import React, {
  type FC,
  type FormHTMLAttributes,
  useEffect,
  useCallback,
} from "react";
import { useForm, Controller } from "react-hook-form";

import { Input, type InputProps } from "#shared/components/inputs/input";
import { EMAIL_PATTERN } from "#shared/consts";
import type { PermissionEnum } from "#shared/recoil";
import type { Texts } from "#shared/types";

import { InviteUserResultIcon } from "../../../../../common/use-invite-members/invite-user-result-icon";
import type { InviteMembersFormData } from "../../../../../common/use-invite-members/types";
import type { PartialInvitedUserByEmailResponse } from "../../../../../common/use-invite-members/use-invite-members";
import {
  MemberRoleSelect,
  type MemberRoleSelectProps,
} from "../member-role-select";
import {
  UserGroupSelect,
  type UserGroupSelectProps,
  FAKE_SELECT_LINE_HEIGHT,
} from "../user-group-select";

export interface InviteMemberItemProps {
  setMember: (member: InviteMembersFormData) => void;
  member: InviteMembersFormData;
  emailInputProps: Pick<InputProps, "disabled">;
  deleteButtonProps: Omit<ButtonProps, "onClick" | "children"> &
    Required<Pick<ButtonProps, "onClick">>;
  userGroupSelectProps: Pick<
    UserGroupSelectProps,
    "initialData" | "selectProps"
  > | null;
  invitationResponse: PartialInvitedUserByEmailResponse | null;
  formProps?: FormHTMLAttributes<HTMLFormElement>;
  memberRoleSelectProps?: Partial<MemberRoleSelectProps>;
  texts?: Texts<Text>;
}

type Text = "delete" | "userRole" | "userGroup" | "emailAddress";

const enTexts: Required<NonNullable<InviteMemberItemProps["texts"]>> = {
  delete: "Delete",
  userRole: "User role",
  userGroup: "User group",
  emailAddress: "Email address",
};

export const InviteMemberItem: FC<InviteMemberItemProps> = ({
  setMember,
  member,
  userGroupSelectProps,
  deleteButtonProps,
  texts = enTexts,
  formProps,
  emailInputProps,
  memberRoleSelectProps: {
    onChange: onMemberRoleChange,
    ...memberRoleSelectProps
  } = {},
  invitationResponse,
}) => {
  const {
    register,
    formState: { errors, isDirty },
    control,
    getValues,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    shouldFocusError: true,
  });

  const onChange = useCallback(
    <K extends keyof InviteMembersFormData>(key: K) =>
      (value: InviteMembersFormData[K]) => {
        if (value === member[key]) {
          return;
        }

        setMember({
          ...member,
          [key]: value,
        });
      },
    [setMember, member],
  );

  const onRoleChange: Required<MemberRoleSelectProps>["onChange"] = useCallback(
    ({ target: { value: userRole } }) => {
      (onMemberRoleChange || noop)();

      if (member.userRole === userRole) {
        return;
      }

      onChange("userRole")(userRole as PermissionEnum);
    },
    [onChange, onMemberRoleChange, member.userRole],
  );

  const onUserGroupChange: UserGroupSelectProps["onChange"] = useCallback(
    (userGroup) => {
      if (member.userGroup === userGroup) {
        return;
      }

      onChange("userGroup")(userGroup);
    },
    [onChange, member.userGroup],
  );

  useEffect(() => {
    if (member.email === getValues("email") && !isDirty) {
      return;
    }

    setValue("email", member.email, {
      shouldValidate: isDirty,
      shouldDirty: true,
    });
  }, [member.email, setValue, isDirty, getValues]);

  useEffect(() => {
    const isValid = !!(!errors.email && member.email);

    if (isValid === member.isValid) {
      return;
    }

    setMember({ ...member, isValid });
  }, [errors.email, setMember, member]);

  return (
    <form {...formProps}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "45% 1fr 1fr auto auto",
          mt: 4,
          columnGap: 1,
          alignItems: "top",
        }}
      >
        <Box display="flex" flexDirection="column" position="relative">
          <Input
            size="medium"
            placeholder="email@example.com"
            type="email"
            error={!!errors.email}
            width="auto"
            inputProps={{
              /**
               * NOTE:
               *
               * If one removes the autoComplete off then I recommend
               * - checking the style of the input on autocomplete
               * - styling input:-webkit-autofill
               */
              autoComplete: "off",
            }}
            {...register("email", {
              required: "Required",
              pattern: EMAIL_PATTERN,
              onChange: ({ target: { value } }) => {
                onChange("email")(value.toLowerCase());
              },
            })}
            {...emailInputProps}
            sx={
              emailInputProps?.disabled
                ? { fieldset: { backgroundColor: "initial" } }
                : {}
            }
          />
          {errors.email && (
            <Typography
              sx={({ palette, ...theme }) => ({
                color: palette.error.main,
                position: "absolute",
                top: `calc(${FAKE_SELECT_LINE_HEIGHT} + ${theme.spacing(2)})`,
                left: 1,
              })}
            >
              {String(errors.email.message)}
            </Typography>
          )}
        </Box>
        <FormControl>
          <Controller
            control={control}
            name="userRole"
            render={({ field }) => (
              <MemberRoleSelect
                {...field}
                {...memberRoleSelectProps}
                onChange={onRoleChange}
              />
            )}
          />
        </FormControl>
        {userGroupSelectProps && (
          <UserGroupSelect
            onChange={onUserGroupChange}
            {...userGroupSelectProps}
          />
        )}

        {!invitationResponse ? (
          <Button {...deleteButtonProps}>{texts.delete}</Button>
        ) : (
          <InviteUserResultIcon
            isSuccess={!!invitationResponse?.success}
            isError={!!invitationResponse?.error}
          />
        )}
      </Box>
    </form>
  );
};
