import { IconButton, type IconButtonProps } from "@mui/material";
import React, { type FC, type MouseEvent, useContext } from "react";

import { ExpendIcon, type ExpendedIconProps } from "./expend-icon";

import { SelectedResourcesContext } from "../../../../selected-resource-context";
import { useCircuitContext } from "../../circuit-context";

export interface NestedCircuitButtonProps
  extends Omit<IconButtonProps, "onClick"> {
  componentID: string;
  componentName: string;
  iconProps?: ExpendedIconProps;
}

export const NestedCircuitButton: FC<NestedCircuitButtonProps> = ({
  componentID,
  componentName,
  iconProps,
  ...props
}) => {
  const { circuitDispatch, setIsFlyoutMenuVisible } = useCircuitContext();
  const { resetSelectedResources } = useContext(SelectedResourcesContext);

  const onClick = (e: MouseEvent) => {
    e.stopPropagation();

    if (!circuitDispatch) return;

    setIsFlyoutMenuVisible(false);
    resetSelectedResources();

    circuitDispatch({
      type: "selectedNestedCircuit",
      payload: {
        nestedCircuitSelected: true,
        nestedCircuitGqlString: componentID,
        circuitBreadcrumb: {
          id: componentID,
          name: componentName,
        },
      },
    });
  };

  return (
    <IconButton onClick={onClick} {...props}>
      <ExpendIcon {...iconProps} />
    </IconButton>
  );
};
