import { Typography, Box, useTheme } from "@mui/material";
import { isString, isNumber } from "lodash";
import React, { type FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { breadcrumbsInTopBarAtom } from "#organization/recoil/breadcrumbs/top-bar/atom";
import { useHeaderSectionValue } from "#organization/recoil/organization/header-section";

import { SectionContainer } from "./styled";

import type { FnTheme } from "../../../../app-theme-provider/types";
import { BreadcrumbsBar } from "../layouts/top-bar/top-bar";

export const HeaderSection: FC = () => {
  const headerSectionValue = useHeaderSectionValue() || {};
  const breadcrumbs = useRecoilValue(breadcrumbsInTopBarAtom);

  const {
    children = null,
    key: headerKey = null,
    pageTitle = null,
    outerContainerProps,
  } = headerSectionValue || {};

  const [auth, setAuth] = useState(false);
  const { key } = useLocation();
  const { spacing } = useTheme() as FnTheme;

  useEffect(() => setAuth(key === headerKey), [key, setAuth, headerKey]);

  const isTitle = pageTitle && auth;
  const isPrimitiveTitleValue = isString(pageTitle) || isNumber(pageTitle);

  const noContent =
    (!isTitle || !pageTitle) && !children && !breadcrumbs?.length;

  if (noContent) {
    return null;
  }

  return (
    <SectionContainer
      px={3}
      {...outerContainerProps}
      sx={{
        paddingTop: spacing(1),
        position: "relative",
        ...(outerContainerProps?.sx || {}),
      }}
    >
      <SectionContainer
        sx={{ justifyContent: "flex-start", gap: 2, minHeight: 0 }}
      >
        <BreadcrumbsBar />
        {isTitle && isPrimitiveTitleValue ? (
          <Typography component="h1" variant="h1">
            {pageTitle}
          </Typography>
        ) : null}
        {isTitle && !isPrimitiveTitleValue ? (
          <Box sx={{ minHeight: "100%" }}>{pageTitle}</Box>
        ) : null}
      </SectionContainer>
      {auth ? children : null}
    </SectionContainer>
  );
};
