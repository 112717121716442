# Bucket Capacity

## Overview

The `bucket_capacity` sets the limit for requests per label key, defining the maximum capacity of the bucket in the rate limiter.

## Example

With a capacity set to 40, a user is allowed to send up to 40 requests. If a user makes more than 40 requests in a burst, they will start getting rate limited.

## Note

Once tokens are withdrawn from the bucket as requests are coming in, tokens will be refilled on a `fill_amount x interval` basis.
The optimal setting of `bucket_capacity` is essential for balancing user experience and application performance. Consider basing bucket capacity on system capacity and average user interactions, ensuring enough flexibility to accommodate unexpected bursts of traffic without compromising system stability.
