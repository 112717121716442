import { CodeSnippet, CodeBlockWithCopyButton } from "#organization/components"

export const language = "ts";

# Limit By Label Key

## Overview

The `limit_by_label_key` determines the specific label key used for enforcing concurrency limits.

## Example

For instance, `limit_by_label_key` can be set as `user_id`. This way, each user will be allowed to make a maximum number of concurrenct requests. If the user exceeds the limit, the request will be rejected.

<CodeSnippet lang={language} snippetName="CLStartFlow" />

## Note

In the absence of a specified `limit_by_label_key`, concurrency limiting will default to global limiting for all requests matching the criteria. Defining a label key allows for more granular concurrency limiting per user or per device.
