import React, { type FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { FEATURE_FLAGS } from "#shared/utils";

import { BillingRoutes } from "./billing";
import { BILLING_ROUTE } from "./billing/routes.definitions";
import { OrganizationsRoutes } from "./organizations";
import { ORGANIZATIONS_ROUTE } from "./organizations/routes.definitions";

export const AuthenticatedPages: FC = () => (
  <Routes>
    <Route path="/*">
      <Route
        path={ORGANIZATIONS_ROUTE.PARENT_PATH}
        element={<OrganizationsRoutes />}
      />

      {FEATURE_FLAGS.globalBilling && (
        <Route path={BILLING_ROUTE.BILLING_PATH} element={<BillingRoutes />} />
      )}

      <Route
        path="*"
        element={<Navigate to={ORGANIZATIONS_ROUTE.ABSOLUTE_PATH} />}
      />
    </Route>
  </Routes>
);
