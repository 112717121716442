import {
  alpha,
  Box,
  Typography,
  useTheme,
  type PaletteColor,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { type FC } from "react";

import { ALERT_SEVERITY_COLOR_MAP } from "../../../../../../../../../app-theme-provider/theme/palette";
import type { FnTheme } from "../../../../../../../../../app-theme-provider/types";
import type { AlertSeverity } from "../../../../../../../../../types";

const AlertsCountTypography = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  padding: `0 ${theme.spacing(0.5)}`,
}));

interface AlertsCountBoxProps {
  count: number;
  severity: AlertSeverity;
  radius: RadiusType;
}

export type RadiusType = "left" | "right" | "none" | "all";

export const AlertsCountBox: FC<AlertsCountBoxProps> = ({
  count,
  severity,
  radius,
}) => {
  const { palette } = useTheme() as unknown as FnTheme;
  const severityColor = ALERT_SEVERITY_COLOR_MAP[severity] || "primary";
  const severityPalette = palette[severityColor] as Pick<
    PaletteColor,
    "main" | "contrastText"
  >;
  const color = severityPalette.main;

  return (
    <Box display="grid" gridTemplateColumns="auto 1fr">
      <AlertsCountTypography
        sx={{
          backgroundColor: alpha(color, 0.3),
          borderRadius: radiusStyles[radius],
        }}
        variant="body2"
        color={color}
      >
        {count}
      </AlertsCountTypography>
    </Box>
  );
};

const radiusStyles = {
  left: `4px 0px 0px 4px`,
  none: `0px 0px 0px 0px`,
  right: `0px 4px 4px 0px`,
  all: `4px`,
};

export const AlertRowWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  gap: 5,
});
