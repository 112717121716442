import type { FieldValues, Path } from "react-hook-form";

import * as RateLimitBlueprint from "../rate-limiting/@types";

export const createFieldNameWithPrefix = <
  TKey extends FieldValues = RateLimitBlueprint.Coordinate,
>(
  prefix: Path<TKey>,
  field: string,
) => [prefix, field].join(".") as Path<TKey>;
