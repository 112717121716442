import { Box, type BoxProps } from "@mui/material";
import React, { type FC } from "react";

import {
  AlertTilesSection,
  type AlertTilesSectionProps,
  AlertTable,
  type AlertTableProps,
} from "./components";

import type { AlertSeverity } from "../../../../../../../types";

export type AlertLogsGroupBy = "name" | "severity";

export interface AlertLogsProps {
  containerProps?: Omit<BoxProps, "children">;
  alertTableProps?: AlertTableProps;
  alertTilesSectionProps?: AlertTilesSectionProps;
}

const DEFAULT_TILES_SEVERITY: AlertSeverity[] = ["crit", "info", "warn"];

export const AlertLogs: FC<AlertLogsProps> = ({
  containerProps,
  alertTableProps,
  alertTilesSectionProps,
}) => (
  <Box px={3} {...containerProps}>
    <AlertTilesSection
      {...{
        severity: DEFAULT_TILES_SEVERITY,
        ...alertTilesSectionProps,
      }}
    />
    <AlertTable {...alertTableProps} />
  </Box>
);
