import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  ListItem,
  ListItemIcon,
  Paper,
  Popper,
  Typography,
  styled,
} from "@mui/material";
import React, { useState, type FC, useRef, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import type { Texts } from "#shared/types";
import { FEATURE_FLAGS, FeatureFlag } from "#shared/utils";

import { useUgMemberPermission } from "#organization/pages/authenticated/settings/account/hooks";

import {
  BlueprintPolicyIcon,
  CreatePolicyListIcon,
  CustomPolicyIcon,
} from "./page-icons.";

import { CREATE_POLICY_ROUTE, POLICIES_ROUTE } from "../../routes.definitions";

type TextsKeys =
  | "title"
  | "button"
  | "createCustomPolicy"
  | "createPolicyBlueprint";

export const defaultTexts: Texts<TextsKeys> = {
  title: POLICIES_ROUTE.TITLE,
  button: "Create Policy",
  createCustomPolicy: "Custom",
  createPolicyBlueprint: "Blueprint",
};

export interface PoliciesPageTitleProps {
  texts?: Texts<TextsKeys>;
}

export const PoliciesPageTitle: FC<PoliciesPageTitleProps> = ({
  texts = defaultTexts,
}) => {
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const ref = useRef<HTMLButtonElement>(null);

  const onClick = useCallback(() => {
    setAnchorEl(ref.current);
    setOpenDialog(!openDialog);
  }, [openDialog, ref, setAnchorEl, setOpenDialog]);

  const onClickBlueprint = useCallback(() => {
    navigate(
      ["policies", CREATE_POLICY_ROUTE.ABSOLUTE_PATH, "?origin=Blueprint"].join(
        "",
      ),
    );
    setOpenDialog(false);
  }, [navigate, setOpenDialog]);

  const onClickCustom = useCallback(() => {
    navigate(["policies/create-custom-policy", "origin=Custom"].join("?"));
    setOpenDialog(false);
  }, [navigate, setOpenDialog]);

  /* Members cannot create policies if the user group does not have write permissions */
  const permissionLock = useUgMemberPermission();

  const buttons = useMemo(
    () => [
      <Button key="one" onClick={onClickBlueprint}>
        {texts.button}
      </Button>,
      ...(FEATURE_FLAGS.showCreateCustomPolicy
        ? [
            <Button key="two" ref={ref} onClick={onClick}>
              {!openDialog ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
            </Button>,
          ]
        : []),
    ],
    [onClick, onClickBlueprint, openDialog, texts.button],
  );

  return (
    <>
      <TitleWrapper>
        <Typography variant="h1" component="h1">
          {texts.title}
        </Typography>
        <FeatureFlag
          flag="canCreatePolicy"
          renderOn={
            <ButtonGroup
              variant="contained"
              size="small"
              aria-label="small button group"
              disabled={permissionLock}
            >
              {buttons}
            </ButtonGroup>
          }
        />
      </TitleWrapper>
      {openDialog && (
        <ClickAwayListener onClickAway={() => setOpenDialog(false)}>
          <Popper
            id="fn-create-policy-popper"
            open={Boolean(anchorEl) && openDialog}
            placement="bottom-start"
            anchorEl={anchorEl}
          >
            <NavigationDropDownStyled component={Paper}>
              <ListItem
                sx={{
                  cursor: "pointer",
                }}
                onClick={onClickBlueprint}
              >
                <ListItemIcon>
                  <CreatePolicyListIcon {...BlueprintPolicyIcon.iconProps}>
                    {BlueprintPolicyIcon.Svg}
                  </CreatePolicyListIcon>
                </ListItemIcon>
                {texts.createPolicyBlueprint}
              </ListItem>
              <ListItem
                sx={{
                  cursor: "pointer",
                }}
                onClick={onClickCustom}
              >
                <ListItemIcon>
                  <CreatePolicyListIcon {...CustomPolicyIcon.iconProps}>
                    {CustomPolicyIcon.Svg}
                  </CreatePolicyListIcon>
                </ListItemIcon>
                {texts.createCustomPolicy}
              </ListItem>
            </NavigationDropDownStyled>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};

export const TitleWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const NavigationDropDownStyled = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  width: 225,
  minHeight: 88,
  marginTop: theme.spacing(1),
}));
