import {
  Box,
  Typography,
  Avatar,
  type BoxProps,
  type TypographyProps,
} from "@mui/material";
import React, { type FC, type ReactNode, useRef } from "react";

import {
  TopBarBadge,
  type TopBarBadgeProps,
} from "#shared/components/top-bar-badge";
import { getUserRoleName, type User } from "#shared/recoil";

export interface TopBarUserProps<Id extends string = string> {
  user: User;
  badgeProps?: Omit<TopBarBadgeProps, "children">;
  outerContainerProps?: Omit<BoxProps, "children">;
  attributes?: TopBarUserAttribute<Id>[];
}

type Accessor = (user: User) => ReactNode;

type TopBarUserAttribute<Id extends string = string> = {
  id: Id;
  key: (user: User) => string;
  accessor: Accessor;
  containerProps?: Omit<BoxProps, "children">;
  typographyProps?: Omit<TypographyProps, "children">;
};

export const DEFAULT_TOP_BAR_USER_ATTRIBUTES: Required<
  TopBarUserProps<"name" | "role">
>["attributes"] = [
  {
    id: "name",
    accessor: ({ name, email }) => name || email,
    typographyProps: {
      variant: "body2",
      color: "text.primary",
      fontWeight: 600,
      lineHeight: "1.15",
    },
    key: ({ id }) => ["name", id].join("-"),
  },
  {
    id: "role",
    accessor: ({ organization }) =>
      organization?.role ? getUserRoleName(organization.role) : null,
    typographyProps: {
      variant: "subtitle2",
      color: "text.secondary",
      lineHeight: "1.15",
    },
    key: ({ id }) => ["role", id].join("-"),
  },
];

export const TopBarUser: FC<TopBarUserProps> = ({
  user,
  outerContainerProps,
  badgeProps,
  attributes = DEFAULT_TOP_BAR_USER_ATTRIBUTES,
}) => {
  const badgeRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Box
        {...outerContainerProps}
        sx={{
          ml: 6,
          mr: 1.5,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "center",
          cursor: "pointer",
          ...outerContainerProps?.sx,
        }}
      >
        {attributes.map(
          ({ accessor, containerProps, typographyProps, key }) => {
            const value = accessor(user);

            if (!value) {
              return null;
            }

            return (
              <Box {...containerProps} key={key(user)}>
                <Typography {...typographyProps}>{value}</Typography>
              </Box>
            );
          },
        )}
      </Box>
      <TopBarBadge ref={badgeRef} {...badgeProps}>
        <Avatar
          sx={{ width: 35, height: 35, top: 2 }}
          alt={user.name || ""}
          src={user.picture || ""}
        />
      </TopBarBadge>
    </>
  );
};
