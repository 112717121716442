import { Check, Cancel, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  Popper,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  RadioGroup,
  ClickAwayListener,
  hexToRgb,
  alpha,
  InputAdornment,
  Radio,
  useTheme,
} from "@mui/material";
import type { Variables } from "graphql-request";
import { get, uniqBy } from "lodash";
import React, {
  type ChangeEvent,
  type Dispatch,
  type FC,
  useCallback,
  useState,
  type MouseEvent,
  useEffect,
  type SetStateAction,
} from "react";

import { Input } from "#shared/components/inputs/input";
import { useGqlQuery } from "#shared/utils";

import {
  FilterBody,
  FilterChip,
  FilterPopperBox,
  FilterPopperHeader,
  FilterRadio,
} from "./filter.styled";

import type { FnTheme } from "../../../../app-theme-provider/types";

export const OPERATORS: string[] = [
  "Is Empty",
  "Is Not Empty",
  "Exact Match",
  "Regex",
  "Like",
];

const DEBOUNCE_TIME = 300;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export declare type ModifyFilterQueryResponseData = (data: any) => any;

export const useFilterQuery = (
  initialQuery: string,
  initialVariable: Variables,
  requestKey: string,
  modifyFilterQueryResponseData?: ModifyFilterQueryResponseData,
) => {
  const [query, setQuery] = useState(initialQuery);
  const [variables, setVariables] = useState(initialVariable);
  const { data, isLoading, isError, refetch } = useGqlQuery(
    [`filters-${requestKey}`],
    query,
    variables,
  );

  return {
    data:
      modifyFilterQueryResponseData && data
        ? modifyFilterQueryResponseData(data)
        : data,
    loading: isLoading,
    error: isError,
    variables,
    setVariables,
    setQuery,
    refetch,
  };
};

interface FilterQueryType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  loading: boolean;
  error: boolean;
  setVariables: Dispatch<SetStateAction<Variables>>;
  setQuery: Dispatch<SetStateAction<string>>;
}

export interface FilterLabelsType {
  id: number;
  name: string;
  gql: string;
  selected: boolean;
  key: string;
  category: string;
  variable: (prop?: string) => Variables;
  uniqueKey?: string;
  filterPath?: string;
  isLikeFilterBlocked?: boolean;
}

export interface ChipsType {
  key: string;
  name: string;
  value: string | number | boolean;
  operator: number;
  uniqueKey: string;
  filterPath: string;
}

interface FilterProps {
  selectedKey: FilterLabelsType;
  setAllChips: (data: ChipsType[]) => void;
  allChips: ChipsType[];
  onConfirm: () => void;
  clearChips: () => void;
  deleteWholeFilter: () => void;
  modifyFilterQueryResponseData?: ModifyFilterQueryResponseData;
}

const getData = (filterData: FilterQueryType, key: FilterLabelsType) => {
  const { category } = key;
  const { data, loading } = filterData;

  const nestedKeys = category.split(".");

  if (
    !category?.length ||
    !Object.hasOwn(data, nestedKeys[0]) ||
    loading ||
    !data
  ) {
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (uniqBy(get(data, category)?.edges, `node.${key.key}`) || []) as any;
};

const createChipsLabel = (
  value: number | string | boolean,
  operator: number,
) => {
  if (operator === 0 || operator === 1) {
    return `is ${value ? "" : "not"}  empty`;
  }

  if (operator === 2) {
    return `${value}`;
  }

  if (operator === 4) {
    return `Like: ${value}`;
  }

  return "";
};

export const Filter: FC<FilterProps> = ({
  selectedKey,
  setAllChips,
  allChips,
  onConfirm,
  clearChips,
  deleteWholeFilter,
  modifyFilterQueryResponseData,
}) => {
  const filterData = useFilterQuery(
    selectedKey.gql,
    selectedKey.variable(),
    selectedKey.key,
    modifyFilterQueryResponseData,
  );
  const [operatorPopperAnchor, setOperatorPopperAnchor] =
    useState<null | HTMLElement>();
  const isOperatorPopperOpen = Boolean(operatorPopperAnchor);
  const [operator, setOperator] = useState<number>(2);
  const [like, setLike] = useState<string>("");
  const [likePrefix, setLikePrefix] = useState<string>("start");
  const [deleteButton, setDeleteButton] = useState<boolean>(false);
  const [debounceEqualSearchInput, setDebounceEqualSearchInput] = useState<
    number | null
  >(null);

  const theme = useTheme() as unknown as FnTheme;

  useEffect(() => {
    onConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allChips]);

  const chipsWrapperElement = useCallback<
    <E extends HTMLElement>(node: E) => void
  >(
    (node) => {
      if (node !== null && allChips.length === 0) {
        setOperatorPopperAnchor(node);
      }
    },
    [allChips.length],
  );

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (debounceEqualSearchInput) {
      clearTimeout(debounceEqualSearchInput);
      setDebounceEqualSearchInput(null);
    }

    setDebounceEqualSearchInput(
      window.setTimeout(() => {
        filterData.setVariables(selectedKey.variable(event.target.value));
      }, DEBOUNCE_TIME),
    );
  };

  // update filter list on search when equal type is selected
  useEffect(() => {
    filterData.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData.variables]);

  const toggleChips = (checked: boolean, chips: ChipsType) => {
    if (checked) {
      setAllChips([...allChips, chips]);
    } else {
      removeChips(chips);
    }
  };

  const removeChips = (chips: ChipsType) => {
    setAllChips(allChips.filter((item) => item.value !== chips.value));
  };

  const checkIfSelected = (chips: ChipsType) => {
    const filtered = allChips.filter((item) => item.name === chips.name);

    if (filtered.length) {
      if (filtered.filter((item) => item.value === chips.value).length) {
        return true;
      }
    }

    return false;
  };

  const openOperatorsPopper = (event: MouseEvent<HTMLDivElement>) => {
    setOperatorPopperAnchor(operatorPopperAnchor ? null : event.currentTarget);
  };

  const closeOperatorPopper = () => {
    setOperatorPopperAnchor(null);

    if (!allChips.length) {
      deleteWholeFilter();
    }
  };

  const handleOperatorChange = (event: ChangeEvent<HTMLInputElement>) => {
    clearChips();
    setLike("");
    setOperator(parseInt(event.currentTarget.value, 10));
    addIsEmptyChips(event.currentTarget.value);
  };

  const addIsEmptyChips = (chipsOperator: string) => {
    const chips: ChipsType = {
      key: selectedKey.key,
      name: selectedKey.name,
      value: "",
      operator: parseInt(chipsOperator, 10),
      uniqueKey: selectedKey.uniqueKey || selectedKey.key,
      filterPath: selectedKey.filterPath || selectedKey.key,
    };

    if (chipsOperator === "0" || chipsOperator === "1") {
      chips.value = chipsOperator === "0";
      setAllChips([chips]);
    } else if (chipsOperator === "4") {
      chips.value = like;
      setAllChips([chips]);
    }
  };

  const handleLike = (event: ChangeEvent<HTMLInputElement>) => {
    setLike(event.currentTarget.value);
  };

  const handleLikePrefixChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLikePrefix(event.currentTarget.value);
  };

  const createLikeChips = () => {
    const chips: ChipsType = {
      key: selectedKey.key,
      name: selectedKey.name,
      uniqueKey: selectedKey.uniqueKey || selectedKey.key,
      filterPath: selectedKey.filterPath || selectedKey.key,
      value: "",
      operator,
    };

    if (likePrefix === "start") {
      chips.value = `${like}%`;
    } else {
      chips.value = `%${like}`;
    }
    setAllChips([chips]);
  };

  useEffect(() => {
    if (isOperatorPopperOpen) {
      createLikeChips();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [like, likePrefix]);

  if (filterData.loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      sx={{ position: "relative" }}
      onMouseEnter={() => {
        setDeleteButton(true);
      }}
      onMouseLeave={() => {
        setDeleteButton(false);
      }}
    >
      <Box
        hidden={!deleteButton}
        sx={{ position: "absolute", right: "-3px", top: "-3px" }}
        onClick={() => {
          deleteWholeFilter();
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            height: "8px",
            width: "8px",
            position: "relative",
          }}
        />
        <Cancel
          color="primary"
          style={{
            fontSize: "14px",
            position: "absolute",
            right: "-3px",
            top: "-3px",
            cursor: "pointer",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto minmax(80px, auto) 1fr",
        }}
      >
        <Box
          sx={{
            display: "grid",
            justifyItems: "center",
            alignItems: "center",
            border: "0.5px solid #8099CC",
            borderColor: "primary.light",
            color: "primary.light",
            padding: "8px",
            borderRadius: "4px 0 0 4px",
          }}
        >
          <Typography fontSize={13}>{selectedKey.name}</Typography>
        </Box>

        <Box
          ref={chipsWrapperElement}
          onClick={openOperatorsPopper}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignSelf: "center",
            alignItems: "center",
            columnGap: "10px",
            rowGap: "10px",
            border: "0.5px solid #8099CC",
            borderLeftStyle: "none",
            borderColor: "primary.light",
            backgroundColor: ({ palette }) =>
              alpha(hexToRgb(palette.primary.light), 0.1),
            color: "primary.light",
            padding: "2px",
            height: "100%",
            borderRadius: "0 4px 4px 0",
          }}
        >
          {allChips.map((chips, index) => (
            <Box
              key={`chips${chips.value}${chips.key}`}
              sx={{
                paddingLeft: index === 0 ? 1 : 0,
                paddingRight: index === allChips.length - 1 ? 1 : 0,
              }}
            >
              <FilterChip
                deleteIcon={
                  <Cancel
                    color="primary"
                    style={{ fontSize: "14px", marginLeft: "1px" }}
                  />
                }
                label={createChipsLabel(chips.value, chips.operator)}
                onDelete={() => removeChips(chips)}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Popper
        open={isOperatorPopperOpen}
        anchorEl={operatorPopperAnchor}
        placement="bottom-start"
        sx={{ zIndex: theme.zIndex.popover }}
      >
        <ClickAwayListener onClickAway={closeOperatorPopper}>
          <FilterPopperBox
            sx={{
              width: "400px",
              display: "grid",
              gridTemplateColumns: "1fr",
              gridTemplateRows: "38px auto 38px 1fr 42px",
            }}
          >
            <FilterPopperHeader>Operators</FilterPopperHeader>

            <FormControl
              component="fieldset"
              sx={{
                padding: "0 10px 0 10px",
                backgroundColor: "background.paper",
              }}
            >
              <RadioGroup defaultValue="2" onChange={handleOperatorChange}>
                <FormControlLabel
                  value="0"
                  control={<FilterRadio />}
                  label="Is Empty"
                />
                <FormControlLabel
                  value="1"
                  control={<FilterRadio />}
                  label="Is Not Empty"
                />
                <FormControlLabel
                  value="2"
                  control={<FilterRadio />}
                  label="Exact Match"
                />
                <FormControlLabel
                  value="3"
                  control={<FilterRadio />}
                  label="Regex"
                  disabled
                />
                <FormControlLabel
                  value="4"
                  control={<FilterRadio />}
                  disabled={selectedKey.isLikeFilterBlocked}
                  label="Like"
                />
              </RadioGroup>
            </FormControl>

            <FilterPopperHeader>{OPERATORS[operator]}</FilterPopperHeader>

            <Box sx={{ backgroundColor: "background.paper" }}>
              {operator === 2 && (
                <FilterBody>
                  <Input
                    fullWidth
                    onChange={handleSearchChange}
                    placeholder="Search"
                    width="100%"
                    InputProps={{
                      sx: { height: "40px" },
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <FormGroup>
                    {getData(filterData, selectedKey).map(
                      (key: { node: { [key: string]: string | number } }) => {
                        const chips: ChipsType = {
                          key: selectedKey.key,
                          name: selectedKey.name,
                          uniqueKey: selectedKey.uniqueKey || selectedKey.key,
                          filterPath: selectedKey.filterPath || selectedKey.key,
                          value: "",
                          operator: 2,
                        };

                        chips.value = get(key.node, selectedKey.key);

                        return (
                          <Box key={`${chips.key}${chips.value}`}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={checkIfSelected(chips)}
                                  onChange={(event) => {
                                    toggleChips(event.target.checked, chips);
                                  }}
                                />
                              }
                              label={`${chips.value}`}
                            />
                          </Box>
                        );
                      },
                    )}
                  </FormGroup>

                  {getData(filterData, selectedKey).totalCount > 5 &&
                  getData(filterData, selectedKey).edges.length >= 5 ? (
                    <Typography align="center">
                      And {getData(filterData, selectedKey).totalCount - 5} more
                    </Typography>
                  ) : (
                    <Box />
                  )}
                </FilterBody>
              )}

              {operator === 4 && (
                <FilterBody>
                  <RadioGroup
                    aria-label="like-prefix"
                    defaultValue="start"
                    name="radio-buttons-group"
                    value={likePrefix}
                    onChange={handleLikePrefixChange}
                  >
                    <FormControlLabel
                      value="start"
                      control={<Radio size="small" />}
                      label="Start with"
                    />
                    <FormControlLabel
                      value="end"
                      control={<Radio size="small" />}
                      label="Ends with"
                    />
                  </RadioGroup>
                  <Input
                    fullWidth
                    onChange={handleLike}
                    placeholder="Like"
                    width="100%"
                    value={like}
                    InputProps={{
                      sx: { height: "40px" },
                    }}
                  />
                </FilterBody>
              )}
            </Box>

            <Box
              sx={({ palette }) => ({
                display: "flex",
                flex: 1,
                flexFlow: "row-reverse",
                borderTop: `1px solid ${alpha(palette.primary.main, 0.5)}`,
              })}
            >
              <Button
                sx={{ justifySelf: "end" }}
                onClick={() => {
                  onConfirm();
                  closeOperatorPopper();
                }}
              >
                <Check sx={{ marginRight: "10px" }} />
                <Typography sx={{ fontSize: "13px" }}>Confirm</Typography>
              </Button>
            </Box>
          </FilterPopperBox>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};
