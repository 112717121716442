import type { CardInfo, Customer } from "@chargebee/chargebee-js-types";
import axios, { type AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery, type UseQueryOptions } from "react-query";
import { useRecoilValue } from "recoil";

import { API_SUBSCRIPTIONS_URL } from "#shared/consts";
import { userNewState } from "#shared/recoil";
import type { AnyObject, Subscription } from "#shared/types";

export declare type BillingInfo = {
  email?: string;
  proxy?: boolean;
  organization_id: string;
};

export const useBillingPageInfo = (
  { organization_id, email, proxy = true }: BillingInfo,
  isEnabled = true,
  options: UseQueryOptions<
    AxiosResponse<
      {
        list: {
          subscription: Subscription;
          card: CardInfo;
          customer: Customer;
        }[];
      } & AnyObject
    >
  > = {},
) => {
  const params = useMemo(
    () =>
      new URLSearchParams({
        cf_organization_id: organization_id,
        operator: "is",
      }),
    [organization_id],
  );

  const firebaseEmail = useRecoilValue(userNewState.email);

  const dataBody = { user: { email: email || firebaseEmail } };

  return useQuery({
    queryKey: ["orgBillingInformation", organization_id, firebaseEmail],
    queryFn: () =>
      axios({
        url: [
          proxy
            ? API_SUBSCRIPTIONS_URL.ORGANIZATION
            : API_SUBSCRIPTIONS_URL.GLOBAL,
          params.toString(),
        ].join("?"),
        method: "POST",
        data: dataBody,
      }),
    enabled: !!dataBody.user.email && isEnabled,
    ...options,
  });
};
