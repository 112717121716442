import { isEqual, pick } from "lodash";
import { type RefObject, useEffect, useState } from "react";

const rectProps: (keyof DOMRect)[] = [
  "height",
  "width",
  "x",
  "y",
  "bottom",
  "top",
  "left",
  "right",
];

export const useDomRect = (
  { current }: RefObject<HTMLElement | null | undefined>,
  takeParent = true,
) => {
  const [domRect, setDomRect] = useState<null | DOMRect>(null);

  useEffect(() => {
    const element = takeParent ? current?.parentElement || current : current;

    const rect = element ? element.getBoundingClientRect() : null;

    if (
      (!rect && !domRect) ||
      (rect &&
        domRect &&
        isEqual(pick(rect, ...rectProps), pick(domRect, ...rectProps)))
    ) {
      return;
    }

    setDomRect(rect);
  }, [current, takeParent, domRect]);

  return domRect;
};
