# Service

## Overview

By default, the `service` is set to 'any', since developers are not required to specify this particular field.
However, in Aperture, a service represents a collection of entities that deliver a common functionality, such as checkout or billing in an e-commerce example, or provide a specific service, such as a database or a search service. It maps to the concept of services in platforms like Kubernetes or Consul. Services in Aperture are typically referred to by their fully qualified domain names (FQDN).

Aperture Agent maintains a mapping of entities' (Kubernetes pod or VM) IP addresses to service names. Each agent is responsible for discovering the entities in its vicinity and the services that they belong to. The discovery database is local to each Aperture Agent. For instance, the service name can be set as `checkout.myns.svc.cluster.local`.

## Note

Upon receiving a flow control decision request from an entity, Aperture uses the IP address to service mapping to identify the service name(s). The service name(s) along with flow labels determine which flow control components to execute.

In the case of a Kubernetes DaemonSet installation, each Aperture Agent maintains a mapping of IP addresses to services for the pods running on its worker node. Changes in services and entities are actively watched by the Agent to ensure that the discovery remains up to date. This allows for accurate and reliable identification of services during flow control decision-making.
