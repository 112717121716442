import type { ThemeOptions } from "@mui/material";

const styleOverrides: Required<
  Required<ThemeOptions>["components"]
>["MuiDialogActions"]["styleOverrides"] = {
  root: {
    display: "flex",
    justifyContent: "flex-end",
  },
};

export const MuiDialogActions: Required<
  Required<ThemeOptions>["components"]
>["MuiDialogActions"] = { styleOverrides };
