import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
import { Box, IconButton, styled, useTheme } from "@mui/material";
import React, { useState, type FC } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  gruvboxLight,
  gruvboxDark,
} from "react-syntax-highlighter/dist/esm/styles/prism";

export interface CodeBlockWithCopyButtonProps {
  code: string;
  language: string;
}

/**
 * Pre tag is mutated by Grafana's global styles, matching this color to fix styles.
 */
const DARK_COLOR =
  gruvboxDark[':not(pre) > code[class*="language-"]']?.background || "#141414";
const LIGHT_COLOR =
  gruvboxLight[':not(pre) > code[class*="language-"]']?.background || "#f4f5f8";

export const CodeBlockWithCopyButton: FC<CodeBlockWithCopyButtonProps> = ({
  code,
  language,
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };

  const { palette } = useTheme();

  return (
    <Box sx={{ position: "relative", my: 1 }}>
      <CopyToClipboard text={code} onCopy={handleCopy}>
        <IconButtonStyled color="primary">
          {copied ? (
            <DoneIcon fontSize="small" />
          ) : (
            <ContentCopyIcon fontSize="small" />
          )}
        </IconButtonStyled>
      </CopyToClipboard>
      <Box
        sx={{
          backgroundColor: palette.mode === "dark" ? DARK_COLOR : LIGHT_COLOR,
        }}
      >
        <SyntaxHighlighter
          language={language}
          style={palette.mode === "dark" ? gruvboxDark : gruvboxLight}
          customStyle={{
            border: "none",
            margin: 0,
            fontSize: "14px",
            /* width: "calc(100% - 110px)" Subtract 110px to prevent code from overlapping with the copy button */
          }}
        >
          {code.trim()}
        </SyntaxHighlighter>
      </Box>
    </Box>
  );
};

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: 5,
  right: 5,
  color: theme.palette.primary.main,
}));
