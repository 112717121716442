import { useTheme } from "@mui/system";
import {
  getSmartEdge,
  pathfindingJumpPointNoDiagonal,
} from "@tisoap/react-flow-smart-edge";
import { get, find, pick } from "lodash";
import React, {
  type FC,
  useEffect,
  useState,
  useMemo,
  useContext,
  type MouseEventHandler,
  type SVGProps,
  type CSSProperties,
} from "react";
import {
  type EdgeProps,
  SmoothStepEdge,
  type EdgeSmoothStepProps,
  useNodes,
  type Node,
  getEdgeCenter,
  useEdges,
} from "react-flow-renderer";
import type { IconType } from "react-icons";
import type { IconBaseProps } from "react-icons/lib/cjs/iconBase";

import { useHoverSignalValue } from "#organization/recoil/organization/flow-chart";

import {
  findSelectedEdgeColor,
  loopedSignal,
  getCircuitColors,
  useOnHoverEdge,
} from "./style-nodes-edges";
import { EdgeButtonStyled } from "./styled";
import { svgDrawFunctionCurve } from "./utils";

import type { FnTheme } from "../../../../../../../../app-theme-provider/types";
import type { NodeName, NodeType } from "../../../../../../../../types";
import type { HoverSignalProps } from "../../../../../../recoil/organization/flow-chart/flow-chart";
import { SelectedResourcesContext } from "../../selected-resource-context";
import { useCircuitContext } from "../../tabs/circuit/circuit-context";
import type {
  CircuitEdge,
  CircuitComponent,
  ResourceWithUiData,
  NodeData,
} from "../../types";

export type SmartEdgeProps = EdgeProps<CircuitEdge>;

interface GetEdgeComponentTypeOptions {
  edge: EdgeProps<CircuitEdge>;
  hoverSignal: HoverSignalProps | null;
  sourceNode: Node<CircuitComponent> | null;
}

interface EdgeComponentNameAndType {
  componentName: NodeName | null;
  componentType: NodeType | null;
}

const FOREIGN_OBJECT_SIZE = 38;

const FOREIGN_OBJECT_PROPS: SVGProps<SVGForeignObjectElement> = {
  width: FOREIGN_OBJECT_SIZE,
  height: FOREIGN_OBJECT_SIZE,
  requiredExtensions: "http://www.w3.org/1999/xhtml",
};

const DashboardIcon: IconType = (props: IconBaseProps) => {
  const { color } = props;

  return (
    <svg {...props}>
      <path
        fill={color}
        d="M23 48.5h-3v-15h-6v15h-3v-18h12v18M38 48.5h-3v-24h-6v24h-3v-27h12v27M53 48.5h-3v-33h-6v33h-3v-36h12v36M8 48.5h48v3H8z"
      />
    </svg>
  );
};

export const SmartEdge: FC<SmartEdgeProps> = (edge) => {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    data,
    style: edgeStyle,
    ...otherProps
  } = edge;

  const nodes = useNodes<CircuitComponent>();
  const edges = useEdges<CircuitEdge>();

  const hoverSignal = useHoverSignalValue();
  const [sourceNode, setSourceNode] = useState<Node<CircuitComponent> | null>(
    null,
  );
  const theme = useTheme() as FnTheme;
  const { onEdgeMouseEnter, onEdgeMouseLeave, onHoverEdgeColor } =
    useOnHoverEdge(theme);

  const { selectedResources, selectCircuitResources } = useContext(
    SelectedResourcesContext,
  );

  const { circuitDispatch } = useCircuitContext();

  useEffect(() => {
    if (!circuitDispatch) return;

    circuitDispatch({
      type: "setIsOnHoverSignal",
      payload: {
        animated: Boolean(onHoverEdgeColor?.style?.stroke),
        id,
      },
    });
  }, [circuitDispatch, id, onHoverEdgeColor?.style?.stroke]);

  useEffect(() => {
    const node = data?.source
      ? find(nodes, { data: { componentId: data.source.componentId } }) || null
      : null;

    setSourceNode(node);
  }, [data?.source, nodes, edges]);

  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const isSelected = useMemo(
    () =>
      data
        ? isSelectedPredicate(selectedResources.Signal)(pick(data, "uiData"))
        : false,
    [data, selectedResources.Signal],
  );

  const onClick: MouseEventHandler<HTMLElement> = useMemo(
    () => () => {
      selectCircuitResources("Signal", data ? [data] : []);
    },
    [selectCircuitResources, data],
  );

  const selectedStyle = useSelectedEdgeStyle(
    nodes,
    { edge, hoverSignal, sourceNode },
    isSelected,
  );

  const { componentName, componentType } = findLinkParentDetails(data, nodes);

  const circuitStyle = getCircuitColors(
    theme.palette,
    componentType as NodeType,
    componentName as NodeName,
    false,
  );

  const edgeProps: EdgeSmoothStepProps = {
    ...otherProps,
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    data,
    style: {
      strokeWidth: 2,
      stroke: circuitStyle.component.backgroundColor,
      ...edgeStyle,
      ...loopedSignal(sourceNode)?.style,
      ...selectedStyle,
      ...onHoverEdgeColor?.style,
    },
    markerEnd: otherProps.markerEnd,
    borderRadius: 20,
  };

  const getSmartEdgeResponse = getSmartEdge({
    sourcePosition: otherProps.sourcePosition,
    targetPosition: otherProps.targetPosition,
    sourceX,
    sourceY,
    targetX,
    targetY,
    nodes,
    options: {
      generatePath: pathfindingJumpPointNoDiagonal,
      drawEdge: svgDrawFunctionCurve,
      gridRatio: 8,
    },
  });

  const buttonCenterValue = (centerXorY: number): number =>
    centerXorY - FOREIGN_OBJECT_SIZE / 2;

  const edgeForeignObject = () => (
    <foreignObject
      style={{ padding: "4px" }}
      {...{
        ...FOREIGN_OBJECT_PROPS,
        x: getSmartEdgeResponse?.edgeCenterX
          ? buttonCenterValue(getSmartEdgeResponse.edgeCenterX)
          : buttonCenterValue(edgeCenterX),
        y: getSmartEdgeResponse?.edgeCenterY
          ? buttonCenterValue(getSmartEdgeResponse.edgeCenterY)
          : buttonCenterValue(edgeCenterY),
      }}
    >
      {!edgeProps.id.includes("FakeConstant") && (
        <EdgeButtonStyled
          id={[id, "button"].join("-")}
          onClick={onClick}
          sx={{
            borderColor: isSelected
              ? findSelectedEdgeColor(
                  theme.palette,
                  sourceNode?.data.componentType as NodeType,
                )
              : circuitStyle.component.backgroundColor,
          }}
          onMouseEnter={onEdgeMouseEnter}
          onMouseLeave={onEdgeMouseLeave}
        >
          <DashboardIcon
            width={25}
            height={25}
            viewBox="0 0 64 64"
            color={
              isSelected
                ? findSelectedEdgeColor(
                    theme.palette,
                    sourceNode?.data?.componentType as NodeType,
                  )
                : circuitStyle.component.backgroundColor
            }
          />
        </EdgeButtonStyled>
      )}
    </foreignObject>
  );

  if (!getSmartEdgeResponse) {
    return (
      <>
        <SmoothStepEdge {...edgeProps} />
        {edgeForeignObject()}
      </>
    );
  }

  const { svgPathString } = getSmartEdgeResponse;

  return (
    <>
      <path
        style={edgeProps.style}
        className="react-flow__edge-path"
        d={svgPathString}
        markerEnd={edgeProps.markerEnd}
      />
      {edgeForeignObject()}
    </>
  );
};

function useSelectedEdgeStyle(
  nodes: Node<CircuitComponent>[],
  options: GetEdgeComponentTypeOptions,
  isSelected: boolean,
) {
  const { componentType, componentName } = getEdgeComponentType(nodes, options);
  const { palette } = useTheme() as FnTheme;

  const {
    edge: { stroke },
  } = getCircuitColors(palette, componentType, componentName, isSelected);

  if (!isSelected) {
    return {};
  }

  const style: CSSProperties = {
    strokeWidth: 3,
    ...(stroke ? { stroke } : {}),
  };

  return style;
}

function getEdgeComponentType(
  nodes: Node<CircuitComponent>[],
  options: GetEdgeComponentTypeOptions,
): EdgeComponentNameAndType {
  const { edge, hoverSignal, sourceNode } = options;
  const { sourceComponentID, hoverSourceSignal } = hoverSignal || {};
  const { data } = sourceNode || {};

  if (!sourceComponentID && !hoverSourceSignal && !data) {
    return { componentName: null, componentType: null };
  }

  const sourceNodeTypeAndName: EdgeComponentNameAndType = {
    componentType: (data?.componentType as NodeType) || null,
    componentName: (data?.componentName as NodeName) || null,
  };

  const sourceComponent = sourceComponentID
    ? (get(nodes[parseInt(sourceComponentID, 10)], "data") as NodeData)
    : null;

  if (!sourceComponent) {
    return sourceNodeTypeAndName;
  }

  const { componentType, componentName } = sourceComponent;

  if (componentType && edge?.sourceHandleId === hoverSourceSignal) {
    return { componentType, componentName } as EdgeComponentNameAndType;
  }

  return sourceNodeTypeAndName;
}

function isSelectedPredicate(selectedSignals: ResourceWithUiData[]) {
  const selectedSignalNames = selectedSignals.map(
    ({ uiData: { name } }) => name,
  );

  return ({ uiData: { name } }: ResourceWithUiData) =>
    selectedSignalNames.includes(name);
}

export function findLinkParentDetails(
  data: CircuitEdge | undefined,
  nodes: Node<CircuitComponent>[],
) {
  if (!data || !nodes?.length) return {};

  const parentComponent = nodes.find(
    (n) => n.data?.componentId === data?.source?.componentId,
  );

  return {
    componentType: parentComponent?.data.componentType,
    componentName: parentComponent?.data.componentName,
  };
}
