import { useTheme } from "@mui/material";
import { isEmpty } from "lodash";
import {
  type ComponentType,
  type SetStateAction,
  useEffect,
  useMemo,
  type FC,
  type Dispatch,
} from "react";
import {
  useEdgesState,
  useNodesState,
  type Edge,
  type Node,
  type NodeProps,
  type EdgeChange,
  type NodeChange,
  MarkerType,
  useViewport,
  type EdgeMarker,
} from "react-flow-renderer";

import type { ComponentView, Policy } from "#shared/generated/graphql";

import {
  CommonNode,
  SmartEdge,
  type SmartEdgeProps,
} from "./custom-nodes-edges";
import { setupEdges, setupNodes } from "./setup-nodes-edges";
import { useBubbleUpActuators } from "./utils";

import type { FnTheme } from "../../../../../../../app-theme-provider/types";
import { usePolicy } from "../../hooks";
import type { PolicyDetailsParams } from "../../routes.definitions";
import { PolicyUtils } from "../policy-utils";
import { useCircuitContext } from "../tabs/circuit/circuit-context";
import {
  d3AutoLayoutDag,
  d3AutoLayoutNodesOnly,
  getExternalComponentsLayout,
  mapToAddHasChildren,
} from "../tabs/circuit/components/flow-chart";
import { useInitialViewport } from "../tabs/circuit/hooks";
import type { CircuitComponent, CircuitEdge } from "../types";

export type UseFlowChartState = PolicyDetailsParams;

export type FlowChartState = ReturnType<typeof useFlowChartState>;

const customEdgeTypes = {
  smart: SmartEdge,
};

const nodeTypes = {
  common: CommonNode,
};

type FlowData = {
  initialEdges: Edge<CircuitEdge>[];
  initialNodes: Node<CircuitComponent>[];
};

const emptyResponse: FlowData = {
  initialEdges: [],
  initialNodes: [],
};

export interface FlowChartStateResults {
  nodes: Node<CircuitComponent>[];
  setNodes: Dispatch<SetStateAction<Node<CircuitComponent>[]>>;
  edges: Edge<CircuitEdge>[];
  onNodesChange: (changes: NodeChange[]) => void;
  onEdgesChange: (changes: EdgeChange[]) => void;
  nodeTypes: { common: ComponentType<NodeProps> };
  edgeTypes: { smart: FC<SmartEdgeProps> };
  isLoading: boolean;
  error: unknown;
  noData: boolean;
}

export const useFlowChartState = (
  props: UseFlowChartState,
): {
  flowChart: FlowChartStateResults;
  policy: Policy;
} => {
  const { policyId } = props;
  const [nodes, setNodes, onNodesChange] = useNodesState<CircuitComponent>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<CircuitEdge>([]);
  const theme = useTheme() as unknown as FnTheme;

  const {
    nestedCircuitGqlString,
    circuitDispatch,
    circuitLabelStatus,
    onHoverSignal,
    nestedCircuitSelected,
  } = useCircuitContext();

  const { applyInitialViewport } = useInitialViewport();
  const { zoom } = useViewport();

  const { query } = usePolicy(
    {
      variables: { where: { id: { eq: policyId } } },
    },
    nestedCircuitGqlString,
  );

  const { data, error, isLoading, isFetched } = query;
  const { policies: { edges: [{ node: policy = {} } = {}] = [] } = {} } =
    data || {};
  const { circuit, name: policyName } = policy as Policy;

  const { bubbleUpActuators, includeBubbleUpActuators } =
    useBubbleUpActuators();

  useEffect(() => {
    if (!circuit?.actuators?.length && !bubbleUpActuators?.length) return;

    circuit?.actuators?.map(includeBubbleUpActuators);
  }, [bubbleUpActuators, circuit?.actuators, includeBubbleUpActuators]);

  const actuatorsAndBubbledUpActuators = useMemo(
    () =>
      circuit?.actuators?.length
        ? [...bubbleUpActuators, ...(circuit?.actuators || [])]
        : [],
    [bubbleUpActuators, circuit?.actuators],
  );

  useEffect(() => {
    if (!circuitDispatch) return;

    circuitDispatch({
      type: "updateCurrentPolicyName",
      payload: policyName,
    });

    circuitDispatch({
      type: "setExternalComponents",
      payload: circuit?.graph?.externalComponents || null,
    });
  }, [circuit?.graph?.externalComponents, circuitDispatch, policyName]);

  const flowData = useMemo(() => {
    if (isLoading || error || isEmpty(circuit) || isEmpty(circuit.graph)) {
      return emptyResponse;
    }

    const { graph } = circuit;

    const allComponents = graph?.internalComponents as ComponentView[];
    const allLinks = PolicyUtils.mergeInternalAndExternalLinks(graph);

    if (!allComponents?.length || !allLinks?.length) return emptyResponse;

    const withChildrenMarker = allComponents.map(
      mapToAddHasChildren(circuit?.children),
    );

    const initialRawNodes = setupNodes(
      withChildrenMarker,
      actuatorsAndBubbledUpActuators,
      theme.palette,
    );

    const initialRawEdges = setupEdges(
      allLinks,
      theme.palette,
      initialRawNodes,
    );

    const response: FlowData = {
      initialNodes: !initialRawEdges?.length
        ? d3AutoLayoutNodesOnly(initialRawNodes)
        : d3AutoLayoutDag(initialRawNodes, initialRawEdges, circuitLabelStatus),
      initialEdges: initialRawEdges,
    };

    return response;
  }, [
    isLoading,
    error,
    circuit,
    actuatorsAndBubbledUpActuators,
    theme.palette,
    circuitLabelStatus,
  ]);

  useEffect(() => {
    if (!nestedCircuitSelected && !flowData.initialEdges.length) return;

    applyInitialViewport(flowData.initialEdges.length);
  }, [
    applyInitialViewport,
    flowData.initialEdges.length,
    nestedCircuitSelected,
  ]);

  useEffect(() => {
    if (!circuit?.graph) return;

    setNodes(
      getExternalComponentsLayout(
        circuit?.graph?.externalComponents,
        flowData.initialNodes,
        circuit?.children,
        circuit?.graph?.externalLinks,
        zoom,
        {
          actuators: actuatorsAndBubbledUpActuators,
          palette: theme.palette,
        },
      ),
    );
  }, [
    setNodes,
    flowData.initialNodes,
    circuit,
    zoom,
    actuatorsAndBubbledUpActuators,
    theme,
  ]);

  useEffect(() => {
    const { id, animated } = onHoverSignal;

    setEdges(
      flowData.initialEdges.map((link) =>
        link.id === id
          ? {
              ...link,
              animated,
              markerEnd: {
                type: animated ? MarkerType.ArrowClosed : MarkerType.Arrow,
                color: animated
                  ? theme.palette.circuit?.edges.onHover
                  : (link.markerEnd as EdgeMarker)?.color,
              },
            }
          : link,
      ),
    );
  }, [
    flowData.initialEdges,
    onHoverSignal,
    setEdges,
    theme.palette.circuit?.edges.onHover,
  ]);

  return {
    flowChart: {
      nodes,
      setNodes,
      edges,
      onNodesChange,
      onEdgesChange,
      nodeTypes,
      edgeTypes: customEdgeTypes,
      isLoading,
      error,
      noData: isFetched && isEmpty(circuit),
    },
    policy: policy as Policy,
  };
};
