import { useTheme } from "@mui/material";
import type { RJSFSchema } from "@rjsf/utils";
// eslint-disable-next-line import/no-extraneous-dependencies
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { useCallback, useEffect, useMemo } from "react";

import { useAlert } from "#shared/components/alerts-provider";

import { useCreatePolicyContext, type SubmitPolicyValues } from "../context";

const EDITOR_THEME_NAME = "fnTheme";
const DELAY = 500;

export const useYamlEditor = (
  editor: monaco.editor.IStandaloneCodeEditor | null,
  triggerSuggestion: boolean = true,
  schema?: RJSFSchema,
  updateGlobalState: boolean = true,
) => {
  const { addAlert } = useAlert();

  const {
    palette,
    palette: { mode },
  } = useTheme();

  const { convertToJson, setCreatePolicyCtx } = useCreatePolicyContext();

  const editorTheme = useMemo(
    () => (mode === "dark" ? "hc-black" : "hc-light"),
    [mode],
  );

  /**
   * Creating a custom theme for the editor with FluxNinja colors
   */
  useEffect(() => {
    monaco.editor.defineTheme(EDITOR_THEME_NAME, {
      base: editorTheme,
      inherit: true,
      rules: [
        {
          background: palette.background.paper,
          token: "",
        },
      ],
      colors: {
        "editor.background": palette.background.paper,
      },
    });

    monaco.editor.setTheme(EDITOR_THEME_NAME);
  }, [editorTheme, palette.background.paper]);

  /**
   * Used to save the editor state when user hit continue button
   */
  const saveEditorState = useCallback(() => {
    if (!editor) {
      return;
    }

    const editorModel = editor.getModel();
    const editorSavedState = editor.saveViewState();

    setCreatePolicyCtx((prev) => ({
      ...prev,
      editorSavedState,
      editorModel,
      editor,
    }));
  }, [editor, setCreatePolicyCtx]);

  /**
   * Triggering the suggestions window.
   *
   * and then accepting the first suggestion which is a FluxNinja Blueprint.
   */
  useEffect(() => {
    if (!editor || !triggerSuggestion || editor.getValue().trim().length) {
      return;
    }

    const triggerSuggestionAction = editor.getAction(
      "editor.action.triggerSuggest",
    );

    if (!triggerSuggestionAction) {
      return;
    }

    triggerSuggestionAction.run().then(() => {
      setTimeout(() => {
        editor.trigger("", "acceptSelectedSuggestion", "");
      }, DELAY);
    });
  }, [editor, triggerSuggestion]);

  /**
   * Checking if there are any errors in the YAML file.
   */
  const errorCheck = useCallback(() => {
    if (!editor) {
      return true;
    }

    if (getYamlEditorErrors(editor).length > 0) {
      addAlert({
        message: "There are errors in the YAML file. Please fix them",
        type: "error",
      });

      return true;
    }

    const value = editor.getValue();

    if (!value.trim().length) {
      addAlert({
        message: "The YAML file is empty",
        type: "error",
      });

      return true;
    }

    if (value.includes("__REQUIRED_FIELD__")) {
      addAlert({
        message: "'__REQUIRED_FIELD__' is not allowed in the YAML file",
        type: "error",
      });

      return true;
    }

    return false;
  }, [addAlert, editor]);

  /**
   * This submit user edited YAML file and returns the JSON and YAML values.
   * These values are saved inside the context and is used in other components.
   */
  const submitPolicyValues: SubmitPolicyValues = useCallback(
    (callback) => {
      if (errorCheck() || !convertToJson) {
        callback(true, {
          blueprintJson: null,
          blueprintYaml: null,
        });

        return;
      }

      const yaml = editor?.getValue() || "";

      const json = convertToJson(yaml);

      setCreatePolicyCtx((prev) => ({
        ...prev,
        blueprintJson: json,
        blueprintYaml: yaml,
      }));

      callback(false, {
        blueprintJson: json,
        blueprintYaml: yaml,
      });
    },
    [convertToJson, editor, errorCheck, setCreatePolicyCtx],
  );

  useEffect(() => {
    if (!editor || !schema || !updateGlobalState) {
      return;
    }

    setCreatePolicyCtx((ctx) => ({
      ...ctx,
      schema,
      editor,
      submitPolicyValues,
      saveEditorState,
    }));
  }, [
    editor,
    saveEditorState,
    schema,
    setCreatePolicyCtx,
    submitPolicyValues,
    updateGlobalState,
  ]);

  const getYAMLErrors = useCallback(() => {
    if (!editor) {
      return [];
    }

    return getYamlEditorErrors(editor);
  }, [editor]);

  return {
    getYAMLErrors,
    editorTheme,
    submitPolicyValues,
    saveEditorState,
  };
};

/**
 * Looking for errors in the YAML file.
 */

export const getYamlEditorErrors = (
  editor: monaco.editor.IStandaloneCodeEditor,
) => {
  const model = editor.getModel();

  if (model) {
    return monaco.editor.getModelMarkers({ resource: model.uri });
  }

  return [];
};
