import { Typography } from "@mui/material";
import React, { type FC, useCallback, useContext, useMemo } from "react";
import { useReactFlow } from "react-flow-renderer";

import {
  ResourcesMenu,
  type ResourcesMenuListProps,
  ParentNameAndId,
} from "#organization/components/resources-menu";
import { CIRCUIT_ANIMATION_DURATION } from "#organization/pages/authenticated/policies/components/const";

import { useIsSelected } from "./is-selected";
import { CircuitIcons, policySubmenuIcons } from "./menu-icons";
import type { PolicyMenuListProps } from "./types";

import type { FlowChartStateResults } from "../../../config-flow-chart";
import {
  SelectedResourcesContext,
  type SelectedResource,
  type CircuitViewResource,
} from "../../../selected-resource-context";
import { useComponentsMenu } from "../hooks";

export type ComponentsMenuListProps = PolicyMenuListProps & {
  flowChart: FlowChartStateResults;
};

const enTexts: Required<ComponentsMenuListProps["texts"]> = {
  title: "Actuators",
};

const BOUND_SIZE: number = 650;

export const ComponentsMenuList: FC<ComponentsMenuListProps> = ({
  policy,
  texts = enTexts,
  search,
  flowChart,
}) => {
  const { isResourceSelected, selectCircuitResources } = useContext(
    SelectedResourcesContext,
  );

  const isSelected = useIsSelected();

  const { fitBounds } = useReactFlow();
  const { listItems } = useComponentsMenu({
    policy,
    search,
  });

  const disabled = useCallback<(data: Partial<SelectedResource>) => boolean>(
    ({ uiData, ...other }) => {
      const resourceType = uiData?.componentName;

      if (
        resourceType === "ConcurrencyLimiter" ||
        resourceType === "RateLimiter"
      ) {
        return isResourceSelected(resourceType)({ uiData, ...other }) || false;
      }

      return false;
    },
    [isResourceSelected],
  );

  const data = useMemo(
    () =>
      listItems.map((item) => {
        const resourceType = item.uiData?.componentName;
        const result: ResourcesMenuListProps["data"][number] = {
          item,
          onClick: () => {
            const position = flowChart.nodes.find(
              (element) => element.data.uiData.name === item.uiData?.name,
            )?.position || { x: 0, y: 0 };

            fitBounds(
              {
                x: position.x,
                y: position.y - BOUND_SIZE / 2,
                width: BOUND_SIZE,
                height: BOUND_SIZE,
              },
              { duration: CIRCUIT_ANIMATION_DURATION },
            );

            selectCircuitResources(resourceType as CircuitViewResource, [item]);
          },
          disabled,
          children: <ParentNameAndId {...{ item }} />,
        };

        return result;
      }),
    [listItems, disabled, flowChart.nodes, fitBounds, selectCircuitResources],
  );

  return (
    <ResourcesMenu
      menuListButtonProps={{
        sx: {
          borderRadius: `8px 8px 0px 0px`,
        },
        children: (
          <>
            <CircuitIcons icon={policySubmenuIcons.components} />
            <Typography component="span">{texts.title}</Typography>
          </>
        ),
      }}
      {...{ data, isSelected }}
    />
  );
};
