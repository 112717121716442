# Denied Response Status Code

## Overview

This field allows you to override the default HTTP status code (429 Too Many Requests) that is returned when a request is denied.

## Example

Instead of sending the classic 429 Too Many Requests response, you can custom responses depending on your needs.
For example, in a login form, you can send a `Unauthorized` response instead of a `Too Many Requests` response.

## Note

Setting this field can be useful when creating a policy a for specific feature.
