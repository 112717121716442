import { noop } from "lodash";
import { useEffect, useMemo, type MutableRefObject } from "react";

export type EventOutsideNodeCallback<E extends Event = Event> = (
  isEventWithinNode: boolean,
  event: E,
) => void;

type EventName = "click" | "keydown";

export const useEventOutsideNode = <E extends Event, H extends HTMLElement>(
  nodeRefs: (MutableRefObject<H | null | unknown> | null)[],
  eventName: EventName,
  onEventCallback: EventOutsideNodeCallback<E> = noop,
) => {
  const onEvent: EventListenerOrEventListenerObject = useMemo(
    () => (event) => {
      const node = event.target as Node;

      const isEventWithinNode = !!(
        node &&
        nodeRefs.some(
          (nodeRef) =>
            (nodeRef as MutableRefObject<H>)?.current?.contains(node),
        )
      );

      onEventCallback(isEventWithinNode, event as E);
    },
    [nodeRefs, onEventCallback],
  );

  useEffect(() => {
    document.addEventListener(eventName, onEvent);

    return () => {
      document.removeEventListener(eventName, onEvent);
    };
  });
};
