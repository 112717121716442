import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Typography, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React, { type FC } from "react";

import type { AlertsQuery } from "#shared/generated/graphql";
import type { Texts } from "#shared/types";

import { ColumnWrapper } from "#organization/pages/authenticated/alert-manager/styled";

import { ChannelsLink, ChannelsWrapper } from "./styled";

import { SilentChannelIcon } from "../../../components/silent-channel-icon";

export interface NotificationChannelProps {
  alertsChannelAssignments: AlertsQuery["alerts"]["edges"][0]["node"]["alertsChannelAssignments"];
  texts?: Texts<Text>;
}

type Text = "notConfigured";

const enTexts: Required<NotificationChannelProps["texts"]> = {
  notConfigured: "Channel has no notifications configured",
};

export const NotificationChannel: FC<NotificationChannelProps> = ({
  alertsChannelAssignments,
  texts = enTexts,
}) => {
  const notEmptyAlertsChannelAssignments =
    alertsChannelAssignments.edges.filter(
      (a) => a.node.alertsChannelName !== "",
    );

  return (
    <ColumnWrapper sx={{ gap: 0.5 }}>
      {notEmptyAlertsChannelAssignments.length ? (
        notEmptyAlertsChannelAssignments.map((assignment, index) => (
          <ChannelsWrapper key={[assignment, index].join("-")}>
            <ChannelsLink to="/alert-manager/channels">
              <Box
                display="grid"
                gridTemplateColumns="auto 1fr"
                alignItems="center"
                columnGap={1}
              >
                <Typography>#{assignment.node.alertsChannelName}</Typography>
                {assignment.node.alertsChannel === null ? (
                  <Tooltip title={texts.notConfigured} arrow placement="top">
                    <WarningAmberIcon color="warning" />
                  </Tooltip>
                ) : (
                  <SilentChannelIcon
                    silencedUntil={assignment.node.alertsChannel?.silencedUntil}
                  />
                )}
              </Box>
            </ChannelsLink>
          </ChannelsWrapper>
        ))
      ) : (
        <Typography>-</Typography>
      )}
    </ColumnWrapper>
  );
};
