import { buttonClasses, type ThemeOptions } from "@mui/material";

import type { FnTheme } from "../../types";

const styleOverrides: Required<
  Required<ThemeOptions>["components"]
>["MuiListItemButton"]["styleOverrides"] = {
  root: ({ theme }) => {
    const palette = theme.palette as unknown as FnTheme["palette"];

    const padding = ["3px", theme.spacing(1), "3px", theme.spacing(1)].join(
      " ",
    );

    return {
      textTransform: "none",
      padding,
      [`&.${buttonClasses.outlinedPrimary}`]: {
        color: palette.primary.contrastText,
        backgroundColor: palette.primary.main,
        "&:hover": {
          backgroundColor: palette.primary.mainHover,
        },
      },
    };
  },
};

export const MuiListItemButton: Required<
  Required<ThemeOptions>["components"]
>["MuiListItemButton"] = { styleOverrides };
