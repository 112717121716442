import { Box, type PaletteMode } from "@mui/material";
import React, {
  type FC,
  type PropsWithChildren,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { useRecoilValue } from "recoil";

import { userNewState } from "#shared/recoil";

import { GRAFANA_PROVIDER_VIEWPORT_ID, GRAFANA_APP_NAME } from "./consts";
import {
  GrafanaContext,
  grafanaContextInitial,
  type GrafanaContextProps,
  type GrafanaReducer,
  grafanaReducer,
  useGrafanaContext,
} from "./grafana-context";
import { useRootGrafanaApp } from "./hooks";
import { GrafanaUtils } from "./utils";

export interface GrafanaProviderProps {
  mode: PaletteMode;
}

export const GrafanaProvider: FC<PropsWithChildren<GrafanaProviderProps>> = ({
  children,
  mode,
}) => {
  const [state, dispatch] = useReducer<GrafanaReducer>(
    grafanaReducer,
    grafanaContextInitial,
  );

  const isAuthenticatedWithFn = useRecoilValue(userNewState.isAuthenticated);

  const value: GrafanaContextProps = useMemo(
    () => ({
      ...state,
      action: dispatch,
    }),
    [state],
  );

  return (
    <GrafanaContext.Provider value={value}>
      {isAuthenticatedWithFn && <RootGrafanaApp {...{ mode }} />}
      {children}
    </GrafanaContext.Provider>
  );
};

interface RootGrafanaAppProps extends GrafanaProviderProps {}

export const RootGrafanaApp: FC<RootGrafanaAppProps> = ({ mode }) => {
  const containerRef = useRef<HTMLElement>(null);

  const { action } = useGrafanaContext();

  useEffect(() => {
    if (!action || !containerRef?.current) return;

    // store inside grafana context
    action({
      type: "SET_GRAFANA_PROVIDER",
      payload: {
        name: GRAFANA_APP_NAME,
        container: containerRef.current,
        entry: GrafanaUtils.DEFAULT_ENTRY,
        props: {
          mode,
        },
        configuration: {
          singular: true,
          sandbox: false,
        },
      },
    });
  }, [action, mode]);

  const { load, updateThemeMode } = useRootGrafanaApp(mode, containerRef);

  useEffect(() => {
    load();
    updateThemeMode();
  }, [load, updateThemeMode]);

  return <Box ref={containerRef} id={GRAFANA_PROVIDER_VIEWPORT_ID} hidden />;
};
