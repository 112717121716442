import { gql } from "graphql-request";

import type { FilterLabelsType } from "#shared/components/filtering";

export const discoveryServicesFilters = (
  projectId: string,
): FilterLabelsType[] => [
  {
    id: 0,
    name: "Service name",
    key: "name",
    category: "services",
    gql: gql`
      query DiscoveryServicesName($where: ServiceBoolExp, $first: Int) {
        services(where: $where, first: $first, distinctOn: [name]) {
          edges {
            node {
              name
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => ({
      first: 5,
      where: {
        projectID: { eq: projectId },
        and: [
          { name: { neq: "" } },
          { name: { neq: "any" } },
          {
            name: { like: `%${prop}%` },
          },
        ],
      },
    }),
  },
  {
    id: 1,
    name: "Agent group",
    key: "agentGroup",
    category: "services",
    uniqueKey: "agentGroup",
    filterPath: "agentGroup",
    variable: (prop = "") => {
      if (prop === "") {
        return {
          first: 5,
          where: {
            projectID: { eq: projectId },
          },
        };
      }

      return {
        first: 5,
        where: {
          projectID: { eq: projectId },
          agentGroup: {
            like: `${prop}%`,
          },
        },
      };
    },
    gql: gql`
      query DiscoveryServicesAgentGroup($where: ServiceBoolExp, $first: Int) {
        services(where: $where, first: $first, distinctOn: [agentGroup]) {
          edges {
            node {
              agentGroup
            }
          }
        }
      }
    `,
    selected: false,
  },
  {
    id: 2,
    name: "Controller",
    key: "controller.name",
    category: "services",
    uniqueKey: "controllerName",
    filterPath: "controller.name",
    variable: (prop = "") => {
      if (prop === "") {
        return {
          first: 5,
          where: {
            projectID: { eq: projectId },
          },
        };
      }

      return {
        first: 5,
        where: {
          projectID: { eq: projectId },
          controller: {
            name: {
              like: `${prop}%`,
            },
          },
        },
      };
    },
    gql: gql`
      query DiscoveryServicesControllerName(
        $where: ServiceBoolExp
        $first: Int
      ) {
        services(where: $where, first: $first, distinctOn: [controllerID]) {
          edges {
            node {
              controller {
                name
              }
            }
          }
        }
      }
    `,
    selected: false,
  },
];
