import { SvgIcon, type SvgIconProps } from "@mui/material";
import classnames from "classnames";
import React, { type FC, type PropsWithChildren, type ReactNode } from "react";

export const CreatePolicyListIcon: FC<PropsWithChildren<SvgIconProps>> = ({
  children,
  ...iconProps
}) => <SvgIcon {...iconProps}>{children}</SvgIcon>;

const policyClasses = {
  path: {
    strokeMiterlimit: 10,
  },
  circle: {
    strokeLinecap: "round",
    strokeLinejoin: "round",
  },
};

export const pathClass = classnames(policyClasses.path);
export const circleClass = classnames(policyClasses.circle);

export interface BlueprintPolicyIconProps {
  Svg: ReactNode;
  iconProps: SvgIconProps;
}

export const BlueprintPolicyIcon: BlueprintPolicyIconProps = {
  Svg: (
    <>
      <path
        className={pathClass}
        d="m14.01,21.58v-4.32c.5-.23.98-.54,1.4-.96.08-.08.13-.17.21-.25,1.65-1.89,1.6-4.76-.21-6.56-.52-.52-1.14-.89-1.8-1.12v4.53c0,.24-.06.46-.15.66-.25.55-.8.94-1.45.94-.44,0-.84-.18-1.14-.47-.13-.13-.24-.3-.32-.47-.1-.21-.15-.43-.15-.66v-4.53c-.68.23-1.29.62-1.8,1.12-1.8,1.8-1.86,4.67-.21,6.56.07.08.13.17.21.25.42.42.89.72,1.4.96v4.32"
      />
      <circle className={circleClass} cx="12" cy="12" r="10" />
    </>
  ),
  iconProps: {
    viewBox: "0 0 24 24",
    fill: "none",
    sx: (theme) => ({
      stroke: theme.palette.text.primary,
      color: "transparent",
    }),
  },
};

export interface CustomPolicyIconProps extends BlueprintPolicyIconProps {}

export const CustomPolicyIcon: CustomPolicyIconProps = {
  Svg: (
    <>
      <circle className={circleClass} cx="12" cy="12.24" r="10" />
      <g>
        <polyline
          className={pathClass}
          points="7.19 21.01 7.19 16.16 12 8.18 12 8.18 16.81 16.16 16.81 21.01"
        />
        <path
          className={pathClass}
          d="m16.81,16.17c-1.33.38-3,.6-4.81.6s-3.48-.22-4.81-.6"
        />
        <path
          className={pathClass}
          d="m13.5,10.67c-.49.03-.99.21-1.5.21s-1.01-.18-1.5-.21"
        />
        <g>
          <line
            className={pathClass}
            x1="10.08"
            y1="16.77"
            x2="10.08"
            y2="22.06"
          />
          <line
            className={pathClass}
            x1="13.92"
            y1="16.77"
            x2="13.92"
            y2="22.06"
          />
        </g>
      </g>
    </>
  ),
  iconProps: {
    viewBox: "0 0 24 24",
    fill: "none",
    sx: (theme) => ({
      stroke: theme.palette.text.primary,
      color: "transparent",
    }),
  },
};
