import type { ThemeOptions } from "@mui/material";

import type { FnTheme } from "../../types";

const styleOverrides: Required<
  Required<ThemeOptions>["components"]
>["MuiChip"]["styleOverrides"] = {
  root: {
    fontFamily: "Source Sans Pro, Nunito",
    fontStyle: "normal",
    fontWeight: 400,
    borderRadius: "4px",
    height: "auto",
    maxHeight: "24px",
  },
  label: {
    padding: "0 4px",
    fontSize: "14px",
    lineHeight: "1.5",
    letterSpacing: "0.15px",
  },
  colorSuccess: ({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: (theme as unknown as FnTheme).palette.text.white,
  }),
};

export const MuiChip: Required<
  Required<ThemeOptions>["components"]
>["MuiChip"] = { styleOverrides };
