import {
  type Auth,
  signInWithEmailAndPassword,
  type UserCredential,
} from "firebase/auth";
import { cloneDeep, merge } from "lodash";
import { useMemo } from "react";
import { type DeepPartial } from "react-hook-form";

import { type HttpRequestState, FirebaseUserRecoilState } from "#shared/recoil";
import { type FirebaseUser } from "#shared/recoil/firebase.types";
import { LoggerService } from "#shared/services";

type SetUser = (
  user: DeepPartial<HttpRequestState<FirebaseUser | null, string | null>>,
) => void;

export type UseSignInWithEmailAndPassword = {
  signIn: (
    signInEmail: string,
    signInPassword: string,
    setPartialUser: SetUser,
  ) => Promise<null | UserCredential>;
};

/**
 * NOTE:
 *
 * Signs in with email link
 * (i.e. when user clicks link in the email)
 */
export function useSignInWithEmailAndPassword(
  firebase: Auth | null,
): UseSignInWithEmailAndPassword | null {
  const signIn = useMemo<NonNullable<UseSignInWithEmailAndPassword>["signIn"]>(
    () => async (signInEmail, signInPassword, setPartialUser) => {
      if (!firebase) {
        LoggerService.debug(
          null,
          "Missing firebase in signInWithEmailAndPassword.",
        );

        return null;
      }

      if (!signInEmail) {
        LoggerService.debug(
          null,
          "Missing email in signInWithEmailAndPassword.",
        );

        return null;
      }

      const initial: HttpRequestState<null, null> = merge<
        HttpRequestState<null, null>,
        DeepPartial<HttpRequestState<unknown, null>>
      >(FirebaseUserRecoilState.INITIAL_STATE, {
        httpState: { isIdle: false, isLoading: true, error: null },
      });

      setPartialUser(initial);

      try {
        LoggerService.debug(
          null,
          "Trying to sign in in with email and password",
          { initial, signInEmail },
        );

        const response = await signInWithEmailAndPassword(
          firebase,
          signInEmail,
          signInPassword,
        );

        LoggerService.debug("Signed in with email and password", {
          response,
        });

        setPartialUser({
          httpState: { isSuccess: true, isLoading: false },
          data: cloneDeep(response.user) as unknown as FirebaseUser,
        });

        return response;
      } catch (err) {
        LoggerService.error("Failed to signInWithEmailAndPassword.", err);

        setPartialUser({
          httpState: {
            isLoading: false,
            error: err instanceof Error ? err.message : String(err),
          },
        });

        return null;
      }
    },
    [firebase],
  );

  return useMemo(
    () => ({
      signIn,
    }),
    [signIn],
  );
}
