import React, { type FC } from "react";
import { Route, Routes } from "react-router-dom";

import { BillingPage } from "./billing";

export const BillingRoutes: FC = () => (
  <Routes>
    <Route index element={<BillingPage />} />
  </Routes>
);
