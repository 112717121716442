import { cloneDeep, isEqual } from "lodash";
import { useState, useCallback, useEffect, useRef, useMemo } from "react";
import type { Props as RndProps } from "react-rnd";
import { atom, useRecoilState } from "recoil";

import { ATOM_KEYS } from "#shared/consts";
import type { AnyObject } from "#shared/types";

import { ROOT_ID } from "#organization/consts";

import { useContentViewPortSize } from "./use-content-view-port-size";
import {
  type OtherSizeCss,
  type SizeCss,
  useFullScreenSize,
} from "./use-full-screen-size";

export type Size = Partial<AnyObject<OtherSizeCss, number>> &
  Required<AnyObject<SizeCss, number>>;

const MIN_WIDTH = 0.4;
const MAX_WIDTH = 0.75;

const INITIAL_SIZE: Required<Size> = {
  height: 0,
  width: 0,
  minHeight: 0,
  minWidth: 0,
  maxWidth: 0,
};

export const flyoutState = atom<Size>({
  key: ATOM_KEYS.flyout,
  default: INITIAL_SIZE,
});

export function useFlyoutMenuSize(isOpen: boolean) {
  const [isFullScreenMode, setIsFullScreenMode] = useState(false);

  const fullScreenSize = useFullScreenSize();

  const contentViewPortSize = useContentViewPortSize();

  const rootRef = useRef(document.getElementById(ROOT_ID));

  const [size, setSize] = useRecoilState(flyoutState);
  const prevSizeRef = useRef(size);

  const setSizeOnlyIfChanged = useCallback(
    (newSize: Size) => {
      if (isEqual(newSize, size)) {
        return;
      }

      prevSizeRef.current = size;
      setSize(newSize);
    },
    [setSize, size],
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const maxWidth = useMemo(
    () => contentViewPortSize.width * MAX_WIDTH,
    [contentViewPortSize.width],
  );

  const minWidth = useMemo(
    () => contentViewPortSize.width * MIN_WIDTH,
    [contentViewPortSize.width],
  );

  const onWindowResize = useCallback(() => {
    if (isFullScreenMode) {
      setSizeOnlyIfChanged(fullScreenSize());

      return;
    }

    if (maxWidth >= Math.max(size.width, size.maxWidth || 0)) {
      return;
    }

    setSizeOnlyIfChanged({
      ...size,
      width: maxWidth,
      maxWidth,
    });
  }, [size, maxWidth, setSizeOnlyIfChanged, fullScreenSize, isFullScreenMode]);

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, [onWindowResize]);

  /**
   * NOTE:
   *
   * Set the anchorEl on open/close.
   */
  useEffect(() => {
    setAnchorEl(isOpen ? rootRef.current : null);
  }, [isOpen, size]);

  /**
   * NOTE:
   *
   * Set the initial size on open.
   */
  useEffect(() => {
    /**
     * NOTE:
     *
     * Do nothing when:
     * - the menu is closed, OR
     * - there is already width (i.e. it is not an "open flyout menu" event), OR
     * - there is the full screen mode (dealt with in separate useEffect)
     */
    if (!isOpen || size.width || isFullScreenMode) {
      return;
    }

    const width = prevSizeRef.current.width || minWidth;
    const { height } = contentViewPortSize;

    setSizeOnlyIfChanged({
      ...INITIAL_SIZE,
      height,
      minHeight: height,
      width,
      minWidth,
    });
  }, [
    isOpen,
    isFullScreenMode,
    size,
    contentViewPortSize,
    setSizeOnlyIfChanged,
    minWidth,
  ]);

  useEffect(() => {
    // NOTE: when turning on full screen mode
    if (isFullScreenMode) {
      setSizeOnlyIfChanged(fullScreenSize());

      return;
    }

    // NOTE: when turning off full screen mode
    if (!isFullScreenMode && size.width === fullScreenSize().width) {
      setSize(prevSizeRef.current);
    }
  }, [isFullScreenMode, size, fullScreenSize, setSizeOnlyIfChanged, setSize]);

  const onResize = useCallback<Required<RndProps>["onResize"]>(
    (_, __, resizeRef, delta) => {
      const width = Math.max(
        size.minWidth || 0,
        Math.min(
          maxWidth,
          resizeRef.getBoundingClientRect().width + delta.width,
        ),
      );

      setSizeOnlyIfChanged({
        ...size,
        maxWidth: width,
        width,
      });
    },
    [size, maxWidth, setSizeOnlyIfChanged],
  );

  const resetSize = useCallback(() => {
    setSizeOnlyIfChanged(cloneDeep(INITIAL_SIZE));
    prevSizeRef.current = cloneDeep(INITIAL_SIZE);
  }, [setSizeOnlyIfChanged]);

  return useMemo(
    () => ({
      anchorEl,
      size,
      isFullScreenMode,
      setIsFullScreenMode,
      resetSize,
      onResize,
    }),
    [anchorEl, size, isFullScreenMode, resetSize, onResize],
  );
}
