import { CodeSnippet, CodeBlockWithCopyButton } from "#organization/components"

export const language = "ts";

# Concurrency Scheduler

## Overview

This blueprint provides a concurrency scheduling policy. This policy uses a combination of a Scheduler and a Concurrency Limiter components.

The Concurrency Scheduler is used to schedule requests based on importance while ensuring that the application adheres to the API's simultaneous requests processing.
This policy optimizes concurrent request processing by prioritizing business-critical workloads, ensuring that crucial requests are addressed first.
If the limit is reached, the Concurrency Scheduler queues the following requests.

## Example

Here is a code example where priorities are defined according to business logic.

<CodeSnippet lang={language} snippetName="Priority" />

Next is an example of how to pass the priorities when making a `startFlow` call, so that high priority can get precedence over low priority.

<CodeSnippet lang={language} snippetName="handleConcurrencyScheduler" />

## Note

In a scenario where the simultaneous requests processing capacity (concurrency) is known upfront, the Concurrency Scheduler becomes particularly beneficial to enforce client-side limits. The `max_concurrency` represents a fixed limit that is divided among the Agents. Each agent has access to this global ledger and consumes limits from it when admitting requests. If the ledger runs out of limit, new requests are queued until more the flow end is triggered for a request or the `max_inflight_duration` exceeds.
