import { gql } from "graphql-request";
import { noop } from "lodash";
import { useCallback, useState } from "react";

import { useAlert } from "#shared/components/alerts-provider";
import {
  type UpdateUserGroupProjectAssignmentPermissionsInput,
  type UpdateUserGroupProjectAssignmentPermissionsMutation,
} from "#shared/generated/graphql";
import type { Texts } from "#shared/types";
import { useGqlMutation, type UseGqlMutationOptions } from "#shared/utils";

const ChangeProjectName = gql`
  mutation ChangeProjectName($input: UpdateProjectNameInput!) {
    updateProjectName(input: $input) {
      project {
        id
        name
      }
    }
  }
`;

const ChangeProjectRegion = gql`
  mutation UpdateProjectRegion($input: UpdateProjectRegionInput!) {
    updateProjectRegion(input: $input) {
      project {
        id
        region
        cloud_provider
      }
    }
  }
`;

export const useChangeProjectName = () => {
  const { mutate, data, isError } = useGqlMutation<unknown, unknown, {}>(
    ChangeProjectName,
  );

  return {
    change: mutate,
    data,
    error: isError,
  };
};

export const useChangeProjectRegion = () => {
  const { mutate, data, isError } = useGqlMutation<unknown, unknown, {}>(
    ChangeProjectRegion,
  );

  return {
    change: mutate,
    data,
    error: isError,
  };
};

const ChangeProjectDescription = gql`
  mutation ChangeProjectDescription($input: UpdateProjectDescriptionInput!) {
    updateProjectDescription(input: $input) {
      project {
        id
        description
      }
    }
  }
`;

export const useChangeProjectDescription = () => {
  const { mutate, data, isError } = useGqlMutation<unknown, unknown, {}>(
    ChangeProjectDescription,
  );

  return {
    change: mutate,
    data,
    error: isError,
  };
};

export const updateUserGroupProjectAssignmentPermissionsGql = gql`
  mutation updateUserGroupProjectAssignmentPermissions(
    $input: UpdateUserGroupProjectAssignmentPermissionsInput!
  ) {
    updateUserGroupProjectAssignmentPermissions(input: $input) {
      userGroupProjectAssignment {
        id
        permissions
        userGroup {
          name
        }
      }
    }
  }
`;

export const useAddUserGroupProjectAssignmentPermissions = (
  options: UseGqlMutationOptions = {},
) =>
  useGqlMutation<
    UpdateUserGroupProjectAssignmentPermissionsMutation,
    unknown,
    UpdateUserGroupProjectAssignmentPermissionsInput
  >(updateUserGroupProjectAssignmentPermissionsGql, options);

export const useUpdateProjectAssignmentPermissionsHelper = () => {
  const { mutate: updatePermission } =
    useAddUserGroupProjectAssignmentPermissions();

  const [
    updateUserGroupPermissionPayload,
    setUpdateUserGroupPermissionPayload,
  ] = useState<UpdateUserGroupProjectAssignmentPermissionsInput[] | null>(null);

  const { addAlert } = useAlert();

  const triggerPermissionUpdate = useCallback(
    (
      onSuccess: () => void = noop,
      onError: () => void = noop,
      texts: Texts<"permissionsChangeError" | "permissionsChangeSuccess"> = {
        permissionsChangeError: "Could not change project permissions.",
        permissionsChangeSuccess: "Successfully changed project permissions.",
      },
    ) => {
      updateUserGroupPermissionPayload?.forEach((p) => {
        updatePermission(p, {
          onSuccess: () => {
            addAlert({
              type: "success",
              message: texts.permissionsChangeSuccess,
            });

            setUpdateUserGroupPermissionPayload(
              (prev) => prev?.filter((prv) => prv.id !== p.id) || [],
            );
            onSuccess();
          },
          onError: () => {
            addAlert({
              type: "error",
              message: texts.permissionsChangeError,
            });

            onError();
          },
        });
      });
    },
    [addAlert, updatePermission, updateUserGroupPermissionPayload],
  );

  return {
    updateUserGroupPermissionPayload,
    setUpdateUserGroupPermissionPayload,
    triggerPermissionUpdate,
    updatePermission,
  };
};
