import { List as MuiList, type ListProps, styled } from "@mui/material";
import type { ComponentType } from "react";

export interface OuterListProps extends ListProps<"ul", {}> {
  open: boolean;
  length: number;
}

export interface InnerListProps extends ListProps<"div", {}> {
  component: keyof HTMLElementTagNameMap;
}

export const OuterList = styled<ComponentType<OuterListProps>>(MuiList)(
  ({ length }) =>
    // const listHeight = `calc(100% - ${theme.spacing(2)})`;

    ({
      paddingTop: 0,
      overflowY: length ? "auto" : "hidden",
      // maxHeight: open ? listHeight : "auto",
    }),
);

export const InnerList = styled<ComponentType<InnerListProps>>(MuiList)(() => ({
  borderBottom: "1px solid #12345530",
}));
