import { gracefulRequest } from "@fluxninja/graceful-js";
import axios from "axios";
import type { RequestDocument, Variables } from "graphql-request";
import { v4 } from "uuid";

import { API_SERVICE_URL } from "#shared/consts";

const X_REQUEST_ID = "X-Request-ID";

export const axiosApi = axios.create({
  baseURL: API_SERVICE_URL.toString(),
  headers: {
    "Content-Type": "application/json",
  },
});

export const gqlRequest = <T, V extends Variables>(
  query: RequestDocument,
  variables: V,
): Promise<T> =>
  new Promise((resolve, reject) => {
    gracefulRequest<"Axios", { data: T }>(
      "Axios",
      () =>
        axiosApi({
          url: "query",
          method: "POST",
          headers: {
            [X_REQUEST_ID]: v4(),
          },
          data: {
            query,
            variables,
          },
        }),
      (err, res) => {
        if (res && !err) {
          resolve(res.data.data);
        }
      },
    ).catch(reject);
  });
