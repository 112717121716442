import { Box } from "@mui/system";
import { capitalize } from "lodash";
import React, { memo, type FC } from "react";
import { type UseFormReturn } from "react-hook-form";

import { TextInput } from "#shared/components/inputs";
import {
  type Texts,
  type TenantIdpConfigResponse,
  type DeepPartial,
} from "#shared/types";

import { SectionButtons } from "#organization/components/button";

import { SectionHeader, SettingsSectionLabel } from "../../../components";
import { SectionBody, SectionButtonWrapper } from "../../../components/styled";

export interface DefaultSupportedIdpConfigFormProps {
  texts?: Texts<Text>;
  disabled: boolean;
  onChange: (value: DeepPartial<DefaultSupportedIdpConfig>) => void;
  register: UseFormReturn<DefaultIdpConfigFormValues, unknown>["register"];
  errors: UseFormReturn<
    DefaultIdpConfigFormValues,
    unknown
  >["formState"]["errors"];
  displayName: string | undefined;
  data: DefaultIdpConfigFormValues | undefined;
  isDirty: boolean;
  onSubmit: () => void;
}

type Text =
  | Exclude<keyof DefaultIdpConfigFormValues, "displayName" | "name">
  | "submit"
  | ErrorKey;

type ErrorKey = `${Exclude<
  keyof DefaultIdpConfigFormValues,
  "enabled" | "displayName" | "name"
>}Error`;

export type DefaultIdpConfigFormValues = Omit<
  DefaultSupportedIdpConfig,
  "enabled"
>;

export type DefaultSupportedIdpConfig = NonNullable<
  TenantIdpConfigResponse["defaultSupportedIdpConfigs"]
>[number];

const enTexts: Required<DefaultSupportedIdpConfigFormProps["texts"]> = {
  clientId: "Client ID",
  clientSecret: "Client secret",
  submit: "Submit",
  clientIdError: "Client ID is required",
  clientSecretError: "Client secret is required",
};

export const DefaultSupportedIdpConfigForm: FC<DefaultSupportedIdpConfigFormProps> =
  memo(
    ({
      disabled,
      texts = enTexts,
      onChange,
      errors,
      register,
      displayName,
      data,
      isDirty,
      onSubmit,
    }) => (
      <>
        <SectionHeader
          title={`${capitalize(
            displayName?.replace(".com", ""),
          )} configuration`}
        />
        <form>
          <SectionBody gridTemplateColumns="20vw 36vw">
            <SettingsSectionLabel primaryText={texts.clientId} asterisk />
            <TextInput
              {...{
                ...register("clientId", {
                  required: isDirty,
                  disabled,
                  onChange: ({ target: { value } }) => {
                    onChange({ clientId: value });
                  },
                }),
                placeholder: texts.clientId,
                disabled,
                helperText: errors?.clientId ? texts.clientIdError : "",
                error: !!errors?.clientId,
                value: data?.clientId || "",
              }}
            />
            <SettingsSectionLabel primaryText={texts.clientSecret} asterisk />
            <TextInput
              {...{
                ...register("clientSecret", {
                  required: isDirty,
                  disabled,
                  onChange: ({ target: { value } }) => {
                    onChange({ clientSecret: value });
                  },
                }),
                helperText: errors?.clientSecret ? texts.clientSecretError : "",
                error: !!errors?.clientSecret,
                placeholder: texts.clientSecret,
                disabled,
                value: data?.clientSecret || "",
              }}
            />
            <Box />
            <SectionButtonWrapper sx={{ justifySelf: "end" }}>
              <SectionButtons
                variant="contained"
                disabled={disabled}
                onClick={onSubmit}
              >
                Save {capitalize(displayName?.replace(".com", ""))}{" "}
                configuration
              </SectionButtons>
            </SectionButtonWrapper>
          </SectionBody>
        </form>
      </>
    ),
  );
