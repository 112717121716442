import DoneIcon from "@mui/icons-material/Done";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Box, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { type FC, useState } from "react";

const FlexBox = styled(Box)({
  display: "flex",
});

type WidgetKeyProps = {
  keyValue: string;
};

export const WidgetKey: FC<WidgetKeyProps> = ({ keyValue }) => {
  const [copySuccess, setCopySuccess] = useState("");

  return (
    <FlexBox
      sx={{
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          display: "inline-block",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: 200,
        }}
      >
        {keyValue}
      </Typography>
      <Box component="span">
        <IconButton
          aria-label="copy password"
          onClick={() => setCopySuccess("Copied!")}
          edge="end"
          size="large"
        >
          {!copySuccess ? <FileCopyIcon /> : <DoneIcon />}
        </IconButton>
      </Box>
    </FlexBox>
  );
};
