import React, { type FC, type PropsWithChildren } from "react";

import { BreadcrumbAppender } from "#shared/components/breadcrumbs";

import { useNewTopBarBreadcrumb } from "./use-new-top-bar-breadcrumb";

export const TopBarBreadcrumbAppender: FC<PropsWithChildren> = ({
  children,
}) => (
  <BreadcrumbAppender
    {...{ children, useNewBreadcrumb: useNewTopBarBreadcrumb }}
  />
);
