import { Tabs, Tab } from "@mui/material";
import React, { type FC, useMemo } from "react";

import { useTabNavigation } from "#shared/hooks";

import { MainPageLayout } from "#organization/components/layouts";
import { useHeaderSectionUpdate } from "#organization/recoil/organization/header-section";

import { CACHE_ROUTE } from "./routes.definitions";
import { ResultCacheTab, GlobalCacheTab } from "./tabs";

const tabs = [
  {
    path: "global-cache",
    name: "Global Cache",
    component: GlobalCacheTab,
  },
  {
    path: "result-cache",
    name: "Result Cache",
    component: ResultCacheTab,
  },
];

export const CachePage: FC = () => {
  useHeaderSectionUpdate({
    pageTitle: CACHE_ROUTE.TITLE,
  });

  const { onTabsChange, currentTab } = useTabNavigation(
    tabs.map((tab) => tab.path),
    tabs[0].path,
  );

  useHeaderSectionUpdate({
    pageTitle: CACHE_ROUTE.TITLE,
    children: useMemo(
      () => (
        <Tabs
          value={currentTab}
          onChange={onTabsChange}
          aria-label="cache tabs"
        >
          {tabs.map((tab) => (
            <Tab label={tab.name} value={tab.path} key={tab.path} />
          ))}
        </Tabs>
      ),
      [currentTab, onTabsChange],
    ),
  });

  const TabComponent = tabs.find((tab) => tab.path === currentTab)?.component;

  return <MainPageLayout>{TabComponent && <TabComponent />}</MainPageLayout>;
};
