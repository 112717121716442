import { alpha } from "@mui/material";

import type { CircuitPalette, FnTheme, CircuitEdgePalette } from "../../types";

const circuitComponents: CircuitPalette = {
  Other: {
    Other: "#56524D",
    concurrencylimiter: "#f27a40",
    ratelimiter: "#f27a40",
    promql: "#8ec4ad",
  },
};

const circuitEdges: CircuitPalette & CircuitEdgePalette = {
  Other: { Other: "#B3B3B3" },
  selected: {
    SignalProcessor: "#56524D",
    Source: "#BFDED1",
    Sink: "#FC9A69",
    StandAlone: "#FC9A69",
  },
  controlLoop: "#f27a40",
  onHover: "#59A6F3",
};

const circuitPorts: CircuitPalette = {
  Other: {
    Other: "#868079",
    concurrencylimiter: "#fc9a69",
    ratelimiter: "#fc9a69",
    promql: "#bfded1",
  },
};

const circuitComponentLabels: CircuitPalette = {
  Other: {
    Other: "#dbd9d7",
    ratelimiter: "#2d333e",
    concurrencylimiter: "#2d333e",
    promql: "#2d333e",
  },
};

const circuitPortLabels: CircuitPalette = {
  Other: {
    Other: "#dbd9d7",
    ratelimiter: "#20242c",
    concurrencylimiter: "#20242c",
    promql: "#20242c",
  },
};

export const darkPalette: FnTheme["palette"] = {
  primary: {
    main: "#8ec4ad",
    light: "#bfded1",
    dark: "#56ae89",
    mainHover: "#79a793",
    contrastText: "#312d2b",
    text: {
      dark: "#3f8367",
    },
  },
  secondary: {
    main: "#b798cd",
    light: "#eee6f3",
    dark: "#884dad",
    contrastText: "#1e1226",
  },
  form: {
    actionButton: {
      background: "#9e9994",
      color: "#1b1918",
    },
  },
  background: {
    default: "#312d2b",
    paper: "#3b3835",
    bgOnPaper: "#363330",
    inputField: "#4E4A46",
    secondNavigation: "#3c3734",
    tooltip: "#56524d",
    other: "#56524d",
  },
  grey: {
    A50: "#312d2b",
    listHeading: "#757575",
  },
  text: {
    primary: "#dbd9d7",
    secondary: "#a6a19c",
    black: "#2d333e",
    disabled: "#716b65",
  },
  divider: "#494541",
  circuit: {
    components: circuitComponents,
    edges: circuitEdges,
    ports: circuitPorts,
    componentLabels: circuitComponentLabels,
    portLabels: circuitPortLabels,
    searchBox: {
      backgroundColor: "#6BAC8E",
      color: "#FFFFFF",
      border: `1px solid ${alpha("#ffffff", 0.5)}`,
      stroke: "#FFFFFF",
    },
  },
  action: {
    active: "#323945", // todo: is it correct?
  },
  other: {
    gridPlaceholder: "#b6b9c4",
    plum: "#c38fd1",
  },
  flyoutMenu: {
    background: "#3b3835", // todo: is it correct?
    textColor: "#F4F4F6",
  },
  codeBlock: {
    background: "#46433F",
    shadow: "#606575",
    text: "#9CDCFE",
  },
  plum: {
    main: "#c38fd1",
    contrastText: "#1b1918",
  },
  info: {
    main: "#59a6f3",
    contrastText: "#1b1918",
  },
  error: {
    main: "#f65e5a",
    contrastText: "#1b1918",
  },
  critAlert: {
    main: "#f65e5a",
    contrastText: "#1b1918",
  },
  infoAlert: {
    main: "#59a6f3",
    contrastText: "#1b1918",
  },
  warnAlert: {
    main: "#ecb43f",
    contrastText: "#1b1918",
  },
  totalAlert: {
    main: "#3b3835",
    contrastText: "#dbd9d7",
  },
  tableGrid: {
    headColor: "#514D48",
  },
  filterBar: {
    background: {
      default: "#514D48",
      paper: "#3B3835",
    },
  },
};
