const ONE_MINUTE = 1000 * 60;

export function exponentialBackoff(
  getDelay = defaultExponentialBackOffDelay(ONE_MINUTE),
) {
  let i = 0;

  return (count = i) => {
    const value = getDelay(count);

    i += 1;

    return value;
  };
}

export function defaultExponentialBackOffDelay(maximumDelayMs: number) {
  return (i: number) => Math.min(2 ** i * 1000, maximumDelayMs);
}
