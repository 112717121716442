import { CodeSnippet, CodeBlockWithCopyButton } from "#organization/components"

export const yaml = "yaml";
export const language = "ts";

# Control Point

## Overview

Control Point identifies location within services where policies can act on flows. For an SDK based insertion, a Control Point can represent a particular feature or execution block within a service. In case of service mesh or middleware insertion, a Control Point can identify ingress or egress calls or distinct listeners or filter chains.

## SDK Example

SDKs provide the functionality to pass a `control_point` directly within your code when starting a flow. For instance, if you designate `concurrency-limiting-feature` as the `control_point` in the policy, concurrency limiting will be applied to all incoming requests to that particular feature.

<CodeSnippet lang={language} snippetName="CLStartFlow" />


## Note

Regardless of choosing an SDK or an infrastructure-based approach, it's crucial to accurately define control points for concurrency limiting.
