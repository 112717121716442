import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  type IconButtonProps,
  InputAdornment,
} from "@mui/material";
import { noop } from "lodash";
import React, {
  forwardRef,
  type FC,
  useState,
  useCallback,
  useEffect,
} from "react";

import { TextInput, type TextInputProps } from "../text-input";

export type PasswordInputProps<Id extends string = string> = Omit<
  TextInputProps,
  "onClick"
> & {
  shown?: boolean;
  onClick?: (shown: boolean, id: Id | undefined) => void;
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const PasswordInput: FC<PasswordInputProps<any>> = forwardRef(
  ({ shown, onClick: onClickProp = noop, ...other }, ref) => {
    const [isVisible, setIsVisible] = useState(shown);

    useEffect(() => {
      setIsVisible(shown);
    }, [shown]);

    const onClick = useCallback<Required<IconButtonProps>["onClick"]>(
      (event) => {
        event.preventDefault();

        const value = !isVisible;
        setIsVisible(value);
        onClickProp(value, other.id);
      },
      [onClickProp, isVisible, other.id],
    );

    return (
      <TextInput
        type={isVisible ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={onClick}
                edge="end"
                size="large"
                tabIndex={-1}
              >
                {isVisible ? (
                  <Visibility color="primary" />
                ) : (
                  <VisibilityOff color="primary" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...other}
        ref={ref}
      />
    );
  },
);
