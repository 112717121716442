import type { FilterLabelsType } from "#shared/components/filtering";
import type { JSONObject } from "#shared/types";

import {
  controlPointAssignmentsGql,
  commonFiltersControlPointAssignments,
} from "./hooks";

export const flowControlComponentsFilters = (
  controlPointID: string,
): FilterLabelsType[] => [
  {
    id: 0,
    name: "Policy name",
    key: "policyName",
    category: "controlPoints",
    uniqueKey: "policyName",
    gql: controlPointAssignmentsGql,
    selected: false,
    variable: (prop = "") => {
      if (prop === "") {
        return {
          first: 5,
          where: {
            ...commonFiltersControlPointAssignments(controlPointID),
          },
        };
      }

      const additionalFilter: JSONObject = {
        project: {
          components: { policy: { name: { like: `%${prop}%` } } },
          classifiers: { policy: { name: { like: `%${prop}%` } } },
          fluxMeters: { policy: { name: { like: `%${prop}%` } } },
        },
      };

      return {
        first: 5,
        where: {
          ...commonFiltersControlPointAssignments(
            controlPointID,
            additionalFilter,
          ),
        },
      };
    },
  },
];
