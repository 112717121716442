import { InfoOutlined } from "@mui/icons-material";
import { CardActionArea, IconButton, Box, Tooltip } from "@mui/material";
import React, { type FC } from "react";

import { useControlDialog } from "#shared/components/layouts/dialog";
import type { ProvisioningStatusText, Texts } from "#shared/types";

import { BillingInfoDialog } from "../billing-info-dialog";

type Text = "billingInfo";
interface BillingInfoButtonProps {
  texts?: Texts<Text>;
  id: string;
  hasuraId: string;
  name: string;
  role: string;
  status: ProvisioningStatusText | null;
}

const enTexts: BillingInfoButtonProps["texts"] = {
  billingInfo: "Billing Info",
};

export const BillingInfoButton: FC<BillingInfoButtonProps> = ({
  id,
  hasuraId,
  name,
  status,
  role,
  texts = enTexts,
}) => {
  const billingInfoDialog = useControlDialog();

  if (role !== "owner" || status !== "PROVISIONED" || !status) {
    return null;
  }

  return (
    <>
      <CardActionArea onClick={() => billingInfoDialog.open()}>
        <Tooltip title={texts.billingInfo}>
          <Box width="50px">
            <IconButton component={Box} disableRipple>
              <InfoOutlined
                sx={{ fill: (theme) => theme.palette.text.secondary }}
              />
            </IconButton>
          </Box>
        </Tooltip>
      </CardActionArea>
      <BillingInfoDialog
        dialogControl={billingInfoDialog}
        id={id}
        hasuraId={hasuraId}
        name={name}
      />
    </>
  );
};
