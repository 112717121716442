import { useSearchParams } from "react-router-dom";

import type { ChipsType } from "./filter";
import { OPERATORS_SYMBOLS } from "./types";

type Filter = {
  key: string;
  values: Array<string | number | boolean>;
  operator: number;
  uniqueKey: string;
  filterPath: string;
};

export const createFiltersSharableLink = (
  chips: [ChipsType[]],
  uniqueId: string,
) => {
  const location = window.location.href;
  const labelKeys: string[] = [];
  let url: string = "?";

  chips.forEach((chip) => {
    let currentURLValues: string = "";

    chip.forEach((chi, index) => {
      if (!labelKeys.includes(chi.uniqueKey)) {
        labelKeys.push(chi.uniqueKey);

        currentURLValues += `${uniqueId}-${chi.uniqueKey}-operator=${
          OPERATORS_SYMBOLS[chi.operator]
        }&`;
        currentURLValues += `${uniqueId}-${chi.uniqueKey}-key=${chi.key}&`;
        currentURLValues += `${uniqueId}-${chi.uniqueKey}-path=${chi.filterPath}&`;
        currentURLValues += `${uniqueId}-${chi.uniqueKey}-values=`;
      }
      currentURLValues += chi.value;

      if (chip.length > 1 && index !== chip.length - 1) {
        currentURLValues += ",";
      }
    });

    if (currentURLValues) {
      url += `${currentURLValues}&`;
    }
  });
  url += `filters=${labelKeys.join(",")}`;

  if (location.includes("?")) {
    return `${location.split("?")[0]}${url}`;
  }

  return `${location}${url}`;
};

export const useGetFiltersFromURL = (uniqueId: string) => {
  const [searchParams] = useSearchParams();

  const uniqueKeys = searchParams.get("filters");
  const filters: Filter[] = [];

  if (uniqueKeys) {
    uniqueKeys.split(",").forEach((uniqueKey) => {
      const filter: Filter = {
        key: searchParams.get(`${uniqueId}-${uniqueKey}-key`) || "",
        filterPath:
          searchParams.get(`${uniqueId}-${uniqueKey}-path`) ||
          searchParams.get(`${uniqueId}-${uniqueKey}-key`) ||
          "",
        operator: 2,
        values: [],
        uniqueKey,
      };
      const values = searchParams.get(`${uniqueId}-${uniqueKey}-values`);

      if (values) {
        values.split(",").forEach((value) => {
          if (value === "true") {
            filter.values.push(true);
          } else if (value === "false") {
            filter.values.push(false);
          } else {
            filter.values.push(value);
          }
        });
      }
      const operator = searchParams.get(`${uniqueId}-${uniqueKey}-operator`);

      if (operator) {
        filter.operator = OPERATORS_SYMBOLS.indexOf(operator);
      }
      filters.push(filter);
    });
  }

  return filters;
};

export const useParseLinkAttributesToFilterVariables = (uniqueId: string) => {
  const [searchParams] = useSearchParams();

  if (!searchParams.toString().includes("filters")) {
    return {};
  }

  const filterUniqueKeys = searchParams.get("filters");

  let filterVariables = '{ "and" : [';

  if (filterUniqueKeys) {
    const filterKeysArray = filterUniqueKeys.split(",");

    const orArray: string[] = [];

    filterKeysArray.forEach((uniqueKey) => {
      const operator =
        searchParams.get(`${uniqueId}-${uniqueKey}-operator`) ||
        OPERATORS_SYMBOLS[2];
      const values = searchParams.get(`${uniqueId}-${uniqueKey}-values`);

      if (values) {
        let valuesString = `{ "or": [`;
        const valuesArray = values.split(",");

        valuesArray.forEach((value, index, data) => {
          const key = searchParams.get(`${uniqueId}-${uniqueKey}-path`) || "";
          const splitedKey = key.split(".");

          if (splitedKey.length > 1) {
            const valueTemplate = template(
              splitedKey.at(-1) || "",
              parseValueToType(value, operator),
              OPERATORS_SYMBOLS.indexOf(operator),
            );

            const lastValue = valueTemplate[splitedKey.at(-1) || ""];
            const nestedOr = JSON.parse(
              nestedGetDown(
                splitedKey.pop(),
                splitedKey,
                "",
                JSON.stringify(lastValue),
              ),
            );

            valuesString += JSON.stringify(nestedOr);
          } else {
            valuesString += `${JSON.stringify(
              template(
                key,
                parseValueToType(value, operator),
                OPERATORS_SYMBOLS.indexOf(operator),
              ),
            )}`;
          }

          if (index !== data.length - 1) {
            valuesString += ",";
          }
        });
        valuesString += "]}";
        orArray.push(valuesString);
      }
    });
    filterVariables += orArray.join(",");
    filterVariables += "]}";
  } else {
    return {};
  }

  return JSON.parse(filterVariables);
};

export const parseValueToType = (
  value: string | number | boolean,
  operator: string | number,
) => {
  switch (operator) {
    case OPERATORS_SYMBOLS[0]:
      return value === "true";
    case OPERATORS_SYMBOLS[1]:
      return value === "true";
    case OPERATORS_SYMBOLS[2]:
      return value;
    case OPERATORS_SYMBOLS[3]:
      return Number(value);
    default:
      return value;
  }
};

export const returnValue = (value: number | string | boolean) => {
  const type = typeof value;

  if (type === "string") return `"${value}"`;

  return value;
};

export const template = (
  name: string,
  value: string | number | boolean,
  operatorId: number,
) =>
  JSON.parse(
    `{ "${name}": { "${OPERATORS_SYMBOLS[operatorId]}": ${returnValue(
      value,
    )} } } `,
  );

export const nestedGetDown = (
  key: string | undefined,
  keys: string[],
  endString: string,
  lastValue?: string,
): string => {
  if (key !== undefined) {
    let newString;

    if (endString === "") {
      newString = `{ "${key}": ${lastValue} }`;
    } else {
      newString = `{"${key}":  ${endString}}`;
    }

    return nestedGetDown(keys.pop(), keys, newString);
  }

  return endString;
};
