import { Typography } from "@mui/material";
import { filter } from "lodash";
import React, { type FC, useMemo, useContext, useState, useRef } from "react";

import {
  ErrorLayout,
  LoaderLayout,
  NoDataLayout,
  CryingNinjaIcon,
} from "#shared/components/layouts";
import {
  AlternativeSignInHorizontalBar,
  SignInWithButtonStyle,
} from "#shared/components/layouts/sign-in";
import {
  SignInWithDefaultSupportedIdpConfig,
  SignInWithLinkForm,
  type LinkCredential,
} from "#shared/components/sign-in";
import { FirebaseContext } from "#shared/contexts/firebase";
import { type TenantIdpConfigUnsecuredResponse } from "#shared/types";

const ERROR_MESSAGE = {
  defaultError: "Oops!",
  message: `Looks like something's not quite right here. We're on it, trying to
  fix things up. While we're at it, feel free to give the page a quick
  refresh, or check back in a bit.`,
  thanks: "Thanks for your patience!",
};
const MAX_RETRIES = 3;
export interface SignInProps {}

/**
 * NOTE:
 *
 * Renders dynamic sign in form.
 *
 * Content of the form depends on the configured authentication methods.
 * The configured authentication methods are fetched from the api (the iab service).
 */
export const SignIn: FC<SignInProps> = () => {
  const {
    tenantConfig: { result, httpState },
    requestDefaultConfig,
  } = useContext(FirebaseContext);

  const defaultSupportedIdpConfigs = useMemo<
    TenantIdpConfigUnsecuredResponse["defaultSupportedIdpConfigs"]
  >(
    () =>
      filter(
        result?.defaultSupportedIdpConfigs,
        ({ displayName }) => !!displayName,
      ),
    [result],
  );

  const [linkCredentials, setLinkCredentials] = useState<LinkCredential>();
  const retryRef = useRef(0);

  if (httpState.isLoading) {
    return <LoaderLayout />;
  }

  if (httpState.error) {
    if (retryRef.current < MAX_RETRIES) {
      retryRef.current += 1;

      setTimeout(() => {
        requestDefaultConfig();
      }, 800);

      return <LoaderLayout />;
    }

    return (
      <ErrorLayout
        errorIcon={
          <CryingNinjaIcon style={{ height: "200px", width: "200px" }} />
        }
        texts={{
          defaultError: ERROR_MESSAGE.defaultError,
        }}
      >
        <>
          <Typography textAlign="center">{ERROR_MESSAGE.message}</Typography>
          <Typography textAlign="center" p={3}>
            {ERROR_MESSAGE.thanks}
          </Typography>
          <Typography color="error">{`Error: ${httpState.error}`}</Typography>
        </>
      </ErrorLayout>
    );
  }

  if (!result?.enableEmailLinkSignin && !defaultSupportedIdpConfigs?.length) {
    return <NoDataLayout />;
  }

  return (
    <>
      {/* Default supported idp configs */}
      {defaultSupportedIdpConfigs?.map((defaultSupportedIdpConfig) => (
        <SignInWithDefaultSupportedIdpConfig
          key={defaultSupportedIdpConfig.displayName}
          linkCredentials={linkCredentials}
          setLinkCredentials={setLinkCredentials}
          {...{ config: defaultSupportedIdpConfig }}
        />
      ))}

      {defaultSupportedIdpConfigs?.length && result?.enableEmailLinkSignin ? (
        <AlternativeSignInHorizontalBar />
      ) : null}

      {result?.enableEmailLinkSignin ? <SignInWithLinkForm /> : null}

      <SignInWithButtonStyle />
    </>
  );
};
