import React from "react";

import type { HeadCell } from "#shared/components/data-grid";

import { globalTimeParser } from "#organization/utils/utils";

import { PoliciesCountBox } from "../../components";
import type { AdaptedControlPointsKubernetesGroupData } from "../hooks";

export const headCells =
  (): HeadCell<AdaptedControlPointsKubernetesGroupData>[] => [
    {
      accessor: (row) => row.name,
      label: "Name",
      key: "name",
    },
    {
      accessor: "kind",
      label: "Kind",
      key: "kind",
    },
    {
      accessor: (row) => <PoliciesCountBox count={row.policies} />,
      label: "Policies",
      key: "policies",
      noSorting: true,
    },
    {
      accessor: "namespace",
      label: "Namespace",
      key: "namespace",
    },
    {
      accessor: "apiVersion",
      label: "API version",
      key: "apiVersion",
    },
    {
      accessor: "controller.name",
      label: "Controller",
      key: "controller.name",
    },
    {
      accessor: "alerts",
      label: "Triggering Alerts",
      key: "alerts",
      noSorting: true,
    },
    {
      accessor: (row) => globalTimeParser(row.lastSeen),
      label: "Last seen",
      key: "lastSeen",
    },
  ];
