import { Tab, Tabs } from "@mui/material";
import React, { type FC, useMemo } from "react";

import { useTabNavigation } from "#organization/hooks";
import { useHeaderSectionUpdate } from "#organization/recoil/organization/header-section";

import { ALERT_MANAGER_ROUTE } from "./routes.definitions";
import { Channels, AlertLogs } from "./tabs";

const alertTabs = [
  { name: "Triggering Alerts", path: "alerts", component: AlertLogs },
  { name: "Channels", path: "channels", component: Channels },
];

export const AlertManager: FC = () => {
  const { onTabsChange, currentTab } = useTabNavigation(
    alertTabs.map((t) => t.path),
  );

  useHeaderSectionUpdate({
    pageTitle: ALERT_MANAGER_ROUTE.TITLE,
    children: useMemo(
      () => (
        <Tabs onChange={onTabsChange} value={currentTab}>
          {alertTabs.map(({ name }) => (
            <Tab label={name} key={name} />
          ))}
        </Tabs>
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [currentTab],
    ),
  });

  const TabComponent = alertTabs[currentTab]?.component;

  return <TabComponent />;
};
