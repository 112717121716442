import { useState, type Dispatch, type SetStateAction } from "react";

import type { ComponentView, Maybe } from "#shared/generated/graphql";

export function includeBubbleUpActuators(
  setBubbleUpActuators: Dispatch<SetStateAction<ComponentView[]>>,
) {
  return (actuator: Maybe<ComponentView>) => {
    if (!actuator) return actuator;

    const { componentId } = actuator;
    const compIds = componentId.split(".");
    const [root, , subCircuitId] = compIds;

    /**
     * If there is a actuator in sub-circuit, then we also make it's parent component actuator.
     */
    const bubbleUpActuator: ComponentView[] = [];

    if (!subCircuitId) {
      return actuator;
    }

    compIds.pop();
    compIds.shift();

    const ids: string[] = [];

    compIds.forEach((v) => {
      const Id = [...ids, v].join(".");
      ids.push(Id);
    });

    if (!ids.length) {
      return actuator;
    }

    ids.forEach((compID) => {
      bubbleUpActuator.push({
        ...actuator,
        componentId: [root, compID].join("."),
      });
    });

    if (bubbleUpActuator.length) {
      setBubbleUpActuators((prev) => {
        if (
          prev.some((ac) =>
            bubbleUpActuator.filter((ba) => ac.componentId === ba.componentId),
          )
        ) {
          return prev;
        }

        return [...prev, ...bubbleUpActuator];
      });
    }

    return actuator;
  };
}

export const useBubbleUpActuators = () => {
  const [bubbleUpActuators, setBubbleUpActuators] = useState<ComponentView[]>(
    [],
  );

  return {
    bubbleUpActuators,
    includeBubbleUpActuators: includeBubbleUpActuators(setBubbleUpActuators),
  };
};
