import { Box, Typography } from "@mui/material";
import { filter } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  type FC,
} from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";

import { PrimaryButton } from "#shared/components/buttons";
import { TextInput, type TextInputProps } from "#shared/components/inputs";
import {
  ErrorText,
  LoaderLayout,
  NoDataLayout,
  GlobalFrame,
  ErrorLayout,
} from "#shared/components/layouts";
import { TermsAndConditions } from "#shared/components/toc";
import { EMAIL_PATTERN } from "#shared/consts";
import { FirebaseContext } from "#shared/contexts/firebase";
import { useConfirmEmailWhenSignInWithEmailLink } from "#shared/contexts/firebase/hooks";
import { firebaseUserState } from "#shared/recoil";
import { LoggerService } from "#shared/services";
import type { TenantIdpConfigUnsecuredResponse, Texts } from "#shared/types";

export interface CompleteSignInFirebaseProps {
  texts?: Texts<Text>;
}

interface CompleteSignInFormValues {
  email: string;
}

type Text =
  | "title"
  | "emailLabel"
  | "emailRequired"
  | "promptForEmailToCompleteSignIn"
  | "continue";

const enTexts: Required<CompleteSignInFirebaseProps["texts"]> = {
  title: "Create an account or sign in.",
  emailLabel: "Work Email Address",
  emailRequired: "Please enter a valid email.",
  promptForEmailToCompleteSignIn: [
    "It seems like you are signing in from a different browser or device.",
    "Please re-enter your email address.",
  ].join(" "),
  continue: "Continue",
};

const EMAIL_ERROR = "emailError";

/**
 * NOTE:
 *
 * Used when completing "sign in with email link".
 *
 * Firebase recommendation:
 *
 * "To prevent a sign-in link from being used to sign in as an unintended user or on an unintended device,
 * Firebase Auth requires the user's email address to be provided when completing the sign-in flow.
 * For sign-in to succeed, this email address must match the address to which the sign-in link was originally sent.
 *
 * You can streamline this flow for users who open the sign-in link on the same device they request the link,
 * by storing their email address locally - for instance using localStorage or cookies - when you send the sign-in email.
 * Then, use this address to complete the flow.
 * Do not pass the user’s email in the redirect URL parameters and reuse it as this may enable session injections."
 *
 * https://firebase.google.com/docs/auth/web/email-link-auth#send_an_authentication_link_to_the_users_email_address
 * https://firebase.google.com/docs/auth/web/email-link-auth#complete_sign_in_with_the_email_link
 *
 */
export const CompleteSignInFirebase: FC<CompleteSignInFirebaseProps> = ({
  texts = enTexts,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<CompleteSignInFormValues>({ mode: "onBlur" });

  const {
    signInWithEmailLink,
    signInEmail,
    isSignInWithEmailLink,
    refreshIsSignInWithEmailLink,
    tenantConfig: { result, httpState },
  } = useContext(FirebaseContext);

  const isAuthenticatedWithFirebase = useRecoilValue(
    firebaseUserState.isAuthenticated,
  );

  const isIdle = useRecoilValue(firebaseUserState.isIdle);
  const isSuccess = useRecoilValue(firebaseUserState.isSuccess);
  const isLoading = useRecoilValue(firebaseUserState.isLoading);
  const firebaseUser = useRecoilValue(firebaseUserState.data);
  const error = useRecoilValue(firebaseUserState.error);
  const resetHttpState = useResetRecoilState(firebaseUserState.state);
  const setPartialUser = useSetRecoilState(firebaseUserState.setPartialState);

  const { signIn = null } = signInWithEmailLink || {};

  const onChange = useCallback<Required<TextInputProps>["onChange"]>(() => {
    if (!isIdle && resetHttpState) {
      resetHttpState();
    }

    clearErrors("email");
  }, [clearErrors, resetHttpState, isIdle]);

  useEffect(() => {
    refreshIsSignInWithEmailLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSuccess) {
      refreshIsSignInWithEmailLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (errors.email?.type !== EMAIL_ERROR) {
      return;
    }

    setError("email", {
      message: EMAIL_PATTERN.message,
      type: EMAIL_ERROR,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.email?.type, errors.email?.message, errors]);

  const submit = useCallback<SubmitHandler<CompleteSignInFormValues>>(
    ({ email }) => {
      if (signIn && !isAuthenticatedWithFirebase) {
        signIn(email, setPartialUser);
      }
    },
    [signIn, isAuthenticatedWithFirebase, setPartialUser],
  );

  const disabled = useMemo(() => !!isLoading && !error, [isLoading, error]);

  const defaultSupportedIdpConfigs = useMemo<
    TenantIdpConfigUnsecuredResponse["defaultSupportedIdpConfigs"]
  >(
    () =>
      filter(
        result?.defaultSupportedIdpConfigs,
        ({ displayName }) => !!displayName,
      ),
    [result],
  );

  const shouldSignInAutomatically = useMemo(() => {
    const signInAutomatically =
      signInEmail &&
      !isAuthenticatedWithFirebase &&
      !isLoading &&
      isSignInWithEmailLink &&
      !error &&
      (!isSuccess || (isSuccess && !firebaseUser));

    LoggerService.debug("CompleteSignInFirebase", "shouldSignInAutomatically", {
      isAuthenticatedWithFirebase,
      isLoading,
      isSignInWithEmailLink,
      error,
      isSuccess,
      firebaseUser,
      shouldSignInAutomatically: signInAutomatically,
    });

    return signInAutomatically;
  }, [
    signInEmail,
    isAuthenticatedWithFirebase,
    isLoading,
    isSignInWithEmailLink,
    error,
    isSuccess,
    firebaseUser,
  ]);

  /**
   * NOTE:
   *
   * Automatic sign in.
   * Takes place when user clicks the sign in link in the email.
   */
  useEffect(() => {
    LoggerService.debug("CompleteSignInFirebase", "useEffect", {
      shouldSignInAutomatically,
      signInEmail: `${signInEmail}`,
      signIn: !signIn,
    });

    if (shouldSignInAutomatically && !!signIn && !!signInEmail) {
      LoggerService.debug(
        null,
        "CompleteSignInFirebase",
        "Trying to complete sign in automatically",
      );

      signIn(signInEmail, setPartialUser);
    } else {
      LoggerService.debug(
        null,
        "CompleteSignInFirebase",
        "Won't complete sign in automatically",
      );
    }
  }, [signInEmail, signIn, setPartialUser, shouldSignInAutomatically]);

  const promptForEmailToCompleteSignIn = useMemo(
    () => !isLoading && !signInEmail && !error && isSignInWithEmailLink,
    [error, isLoading, isSignInWithEmailLink, signInEmail],
  );

  const { askEmailAgain } = useConfirmEmailWhenSignInWithEmailLink();

  useEffect(() => {
    if (askEmailAgain && promptForEmailToCompleteSignIn) {
      LoggerService.debug(
        null,
        "CompleteSignInFirebase",
        "Asking for email again",
      );

      askEmailAgain((email) => {
        if (email.length && !!signIn) {
          LoggerService.debug(
            null,
            "CompleteSignInFirebase",
            "Completing sign in with email",
          );

          signIn(email, setPartialUser);
        }
      });
    }
  }, [askEmailAgain, promptForEmailToCompleteSignIn, setPartialUser, signIn]);

  const isNoData =
    httpState.isSuccess &&
    !result?.enableEmailLinkSignin &&
    !defaultSupportedIdpConfigs?.length;

  return (
    <GlobalFrame hideLogout showSignInErrorMsg>
      <Box width={["100%", "70%"]} mx="auto">
        <Box mt={4} mb={4} textAlign="left">
          <Typography variant="h5">{texts.title}</Typography>
          <Typography mt={2} mb={2} variant="body1" color="text.secondary">
            <TermsAndConditions />
          </Typography>
        </Box>
        {isLoading ? <LoaderLayout /> : null}
        {httpState.error ? (
          <ErrorLayout>{httpState.error || null}</ErrorLayout>
        ) : null}
        {isNoData ? <NoDataLayout /> : null}
        {!httpState.error && !isNoData && !isLoading ? (
          <Box pb={4} width="100%">
            <form onSubmit={handleSubmit(submit)} autoComplete="off">
              {promptForEmailToCompleteSignIn ? (
                <Typography color="warning.main" mb={2}>
                  {texts.promptForEmailToCompleteSignIn}
                </Typography>
              ) : null}
              <TextInput
                id="email"
                fullWidth
                type="email"
                label={texts.emailLabel}
                {...register("email", {
                  required: texts.emailRequired,
                  onChange,
                  value: signInEmail || "",
                  validate: {
                    [EMAIL_ERROR]: (email) => EMAIL_PATTERN.value.test(email),
                  },
                })}
                error={!!errors.email}
                helperText={errors.email?.message}
                disabled={disabled}
              />
              <Box marginTop={2.5} width="100%">
                <PrimaryButton
                  fullWidth
                  size="large"
                  type="submit"
                  disabled={disabled}
                >
                  {texts.continue}
                </PrimaryButton>

                {error ? <ErrorText {...{ error }} /> : null}
              </Box>
            </form>
          </Box>
        ) : null}
      </Box>
    </GlobalFrame>
  );
};
