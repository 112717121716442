import React, { type FC } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { CreateOrganizationPage } from "./create";
import { CREATE_ORGANIZATION_ROUTE } from "./create/routes.definitions";
import { OrganizationsPage } from "./organizations";
import { ORGANIZATIONS_ROUTE } from "./routes.definitions";

export const OrganizationsRoutes: FC = () => (
  <Routes>
    <Route
      path={CREATE_ORGANIZATION_ROUTE.KEY}
      element={<CreateOrganizationPage />}
    />
    <Route index element={<OrganizationsPage />} />
    <Route
      path="*"
      element={<Navigate to={ORGANIZATIONS_ROUTE.ABSOLUTE_PATH} />}
    />
  </Routes>
);
