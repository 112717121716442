import { useMemo } from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";

import { userNewState } from "#shared/recoil";
import { LoggerService } from "#shared/services";
import type { Organization } from "#shared/types";
import { httpClient } from "#shared/utils/http-client";

import { API_URLS } from "#organization/pages/consts";

export const useGetOrganizationData = (subdomain: string) => {
  const firebaseEmail = useRecoilValue(userNewState.email);

  const url = useMemo(() => {
    if (!firebaseEmail) {
      LoggerService.debug("Missing email in billing settings.");

      return null;
    }

    const organizationsUrl = new URL(API_URLS.ORGANIZATIONS.INDEX);
    organizationsUrl.searchParams.append("email", firebaseEmail);

    return organizationsUrl.toString();
  }, [firebaseEmail]);

  const { data: { data } = {}, ...rest } = useQuery({
    queryKey: ["organizations", url],
    queryFn: async () => {
      const request = await httpClient.get<{ data: Organization[] }>({
        url: url || "",
      });

      return request.json();
    },
    enabled: !!firebaseEmail,
  });

  const organization = useMemo(
    () => data?.find((org) => org.domain_name === subdomain),
    [data, subdomain],
  );

  return { organization, ...rest };
};
