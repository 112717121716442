import { Typography } from "@mui/material";
import React, { type FC, useContext, useMemo } from "react";

import {
  ResourcesMenu,
  type ResourcesMenuListProps,
  Name,
} from "#organization/components/resources-menu";

import { useIsSelected } from "./is-selected";
import { CircuitIcons, policySubmenuIcons } from "./menu-icons";
import type { PolicyMenuListProps } from "./types";

import { SelectedResourcesContext } from "../../../selected-resource-context";
import { useSignalsMenu } from "../hooks";

export type SignalsMenuListProps = PolicyMenuListProps;

const enTexts: Required<PolicyMenuListProps["texts"]> = {
  title: "Signals",
};

export const SignalsMenuList: FC<SignalsMenuListProps> = ({
  policy,
  texts = enTexts,
  search,
}) => {
  const { isResourceSelected, selectOne } = useContext(
    SelectedResourcesContext,
  );

  const isSelected = useIsSelected();

  const { listItems } = useSignalsMenu({
    policy,
    search,
  });

  const data = useMemo(
    () =>
      listItems.map((item) => {
        const result: ResourcesMenuListProps["data"][number] = {
          item,
          onClick: () => selectOne("Signal", item),
          disabled: isResourceSelected("Signal"),
          children: <Name {...{ item }} />,
        };

        return result;
      }),
    [listItems, isResourceSelected, selectOne],
  );

  return (
    <ResourcesMenu
      menuListButtonProps={{
        children: (
          <>
            <CircuitIcons icon={policySubmenuIcons.signals} />
            <Typography component="span">{texts.title}</Typography>
          </>
        ),
      }}
      {...{ data, isSelected }}
    />
  );
};
