import { InfoOutlined, OpenInNew } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Typography,
  Tooltip,
  type ButtonProps,
  Box,
  switchClasses,
} from "@mui/material";
import React, { type FC } from "react";

import { WelcomePageLogoutButton } from "#shared/components/logout-button";
import { LoggerService } from "#shared/services/logger";
import type { Texts } from "#shared/types";
import { FeatureFlag } from "#shared/utils";

import { ThemeSwitch } from "#organization/components/theme-switch/theme-switch";
import { useOrganizationState } from "#organization/recoil/organization";

import { BASE_DOCUMENTATION_URL } from "../../../consts";
import { useWelcomePageOnBoarding } from "../hooks";

export interface WelcomePageToolbarProps {
  texts?: Texts<Text>;
  skipButtonProps?: Partial<Omit<ButtonProps, "onClick">>;
}

type Text = "skipGuideTooltip" | "skipGuide" | "viewDocumentation";

const enTexts: Required<WelcomePageToolbarProps["texts"]> = {
  skipGuideTooltip: [
    'Clicking "Skip guide" will create a default project and assign an empty default user group to that project.',
    "You will be able to change it later.",
  ].join(" "),
  skipGuide: "Skip Guide",
  viewDocumentation: "View Documentation",
};

export const WelcomePageToolbar: FC<WelcomePageToolbarProps> = ({
  texts = enTexts,
  skipButtonProps,
}) => {
  const organizationState = useOrganizationState();
  const setBoardingFlag = useWelcomePageOnBoarding();

  const skipGuide = () => {
    const setOnboardPromise = setBoardingFlag.mutate({
      id: organizationState.id,
    });

    Promise.resolve(setOnboardPromise)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        LoggerService.error(error);
      });
  };

  return (
    <>
      <Box display="inline-flex" columnGap={2} alignItems="center">
        <Avatar variant="rounded">{organizationState.name.charAt(0)}</Avatar>
        <Typography>{organizationState.name}</Typography>
      </Box>
      <Box display="inline-flex" columnGap={2} alignItems="center">
        <ThemeSwitch
          sx={(theme) =>
            theme.palette.mode === "light"
              ? {
                  [`.${switchClasses.track}`]: {
                    backgroundColor: "#9e9994",
                    opacity: 0.2,
                  },
                }
              : {}
          }
        />
        <Box>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              window.open(BASE_DOCUMENTATION_URL, "_blank");
            }}
            endIcon={<OpenInNew />}
          >
            {texts.viewDocumentation}
          </Button>
        </Box>
        <FeatureFlag
          flag="skipWelcomeGuideButton"
          renderOn={
            <Box>
              <Button
                variant="contained"
                size="small"
                onClick={skipGuide}
                endIcon={
                  <Tooltip title={texts.skipGuideTooltip} arrow>
                    <InfoOutlined />
                  </Tooltip>
                }
                {...skipButtonProps}
              >
                {texts.skipGuide}
              </Button>
            </Box>
          }
        />
        <WelcomePageLogoutButton />
      </Box>
    </>
  );
};
