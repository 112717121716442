# Max Inflight Duration

## Overview

The `max_inflight_duration` sets the maximum duration after which flow is assumed to have ended in case the end call gets missed.

## Example

For instance, setting `max_inflight_duration` to '120s' means the flow would be assumed to be ended after 2 minutes if the end call is not initiated within that timeframe.

## Note

This field employs the [Duration](https://protobuf.dev/programming-guides/proto3/#json) JSON representation from Protocol Buffers. The format accommodates fractional seconds up to nine digits after the decimal point, offering nanosecond precision. Every duration value must be suffixed with an "s" to indicate 'seconds.'
