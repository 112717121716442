import { type CSSProperties, useMemo } from "react";

import type { AnyObject } from "#shared/types";

type Size = Partial<AnyObject<OtherSizeCss, number>> &
  Required<AnyObject<SizeCss, number>>;

export type OtherSizeCss = Extract<
  keyof CSSProperties,
  "minWidth" | "minHeight" | "maxWidth"
>;

export type SizeCss = Extract<keyof CSSProperties, "height" | "width">;

export function useFullScreenSize(): () => Required<Size> {
  return useMemo(
    () => (): Required<Size> => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      return {
        width,
        minWidth: width,
        maxWidth: width,
        height,
        minHeight: height,
      };
    },
    [],
  );
}
