import {
  Grow,
  Paper,
  ClickAwayListener,
  List,
  type ClickAwayListenerProps,
  type GrowProps,
  type ListProps,
  type PaperProps,
} from "@mui/material";
import { noop } from "lodash";
import React, { type FC, type PropsWithChildren, useCallback } from "react";

export type TopBarMenuProps = PropsWithChildren<{
  clickAwayListenerProps?: Omit<ClickAwayListenerProps, "children">;
  growProps?: Omit<GrowProps, "children">;
  listProps?: Omit<ListProps, "children">;
  paperProps?: Omit<PaperProps, "children">;
}>;

const MIN_WIDTH = "150px";
const MAX_WIDTH = "250px";
const TIMEOUT = 500;

export const TopBarMenu: FC<TopBarMenuProps> = ({
  children,
  growProps,
  listProps,
  paperProps,
  clickAwayListenerProps: {
    onClickAway: onClickAwayProp = noop,
    ...clickAwayListenerProps
  } = {},
}) => {
  const onClickAway: ClickAwayListenerProps["onClickAway"] = useCallback(
    (event) => {
      onClickAwayProp(event);
    },
    [onClickAwayProp],
  );

  return (
    <Grow
      timeout={TIMEOUT}
      {...growProps}
      style={{ minWidth: MIN_WIDTH, maxWidth: MAX_WIDTH, ...growProps?.style }}
    >
      <Paper elevation={4} {...paperProps}>
        <ClickAwayListener {...{ ...clickAwayListenerProps, onClickAway }}>
          <List
            disablePadding
            {...listProps}
            sx={{
              bgcolor: "background.paper",
              borderRadius: 2,
              ...listProps?.sx,
            }}
          >
            {children}
          </List>
        </ClickAwayListener>
      </Paper>
    </Grow>
  );
};
