import { merge, noop } from "lodash";
import { useCallback, useContext, useMemo, useEffect, useRef } from "react";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";

import { FirebaseContext } from "#shared/contexts/firebase";
import { FnIntercom } from "#shared/intercom";
import { HttpRequestRecoilState, userNewState } from "#shared/recoil";
import { httpClient } from "#shared/utils/http-client";

import { LoggerService, LogoutService } from "../services";

export function useLogout(
  isOrganizationContext: boolean,
  cb: (isSuc: boolean) => void = noop,
) {
  const resetNewUser = useSetRecoilState(userNewState.state);
  const resetFirebaseUser = useResetRecoilState(userNewState.firebase.state);

  const isAuthenticated = useRecoilValue(userNewState.isAuthenticated);
  const prevIsAuthenticated = useRef(isAuthenticated);

  const { firebase } = useContext(FirebaseContext);

  useEffect(() => {
    prevIsAuthenticated.current = !isAuthenticated;
  }, [isAuthenticated]);

  const logout = useMemo(
    () => () => async (url: string) => {
      if (!isAuthenticated) {
        return Promise.resolve();
      }

      const isFirebaseUser = !!firebase?.currentUser;

      LoggerService.debug("Logging out...", { isFirebaseUser });

      if (isFirebaseUser) {
        LoggerService.debug("Trying to sign out from Firebase...");

        try {
          await firebase?.signOut();
          FnIntercom.shutdown();
        } catch (err) {
          LoggerService.error(err, "Failed to sign out from Firebase.");
        }

        LoggerService.debug("Signed out from Firebase.");
      }

      return httpClient
        .get({
          url,
        })
        .then(async (response) => {
          if (!response.ok && response.status !== 401) {
            LoggerService.error("Failed to logout.", response.status);
            cb(false);

            return;
          }

          LoggerService.debug(
            null,
            "Logout response status",
            response.status,
            "Trying to reset user.",
          );

          try {
            /**
             * NOTE:
             *
             * Resetting to initial state.
             * Ensuring that isLoading selector will return false
             * (because both isIdle and isLoading are false)
             */
            const resetState = merge(HttpRequestRecoilState.INITIAL_STATE, {
              httpState: { isIdle: false, isLoading: false },
            });

            resetNewUser(resetState);
            resetFirebaseUser();
            LoggerService.debug("Reset user.");
            window.localStorage.removeItem("reloadFlag");
            cb(true);
          } catch (err) {
            LoggerService.error(err, "Failed to reset user.");
            cb(false);
          }
        });
    },
    [isAuthenticated, firebase, resetNewUser, resetFirebaseUser, cb],
  );

  return useCallback(
    () =>
      Promise.allSettled(
        LogoutService.getContextLogoutUrl(isOrganizationContext).map(logout()),
      ),
    [isOrganizationContext, logout],
  );
}
