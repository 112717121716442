import { Box } from "@mui/material";
import React, { type FC, useState } from "react";

import type { FilterLabelsType } from "#shared/components/filtering";
import {
  FilteringGroup,
  useChipsContainer,
} from "#shared/components/filtering/filtering-group";
import { useParseLinkAttributesToFilterVariables } from "#shared/components/filtering/utils";
import { ControllerSelectColumn } from "#shared/generated/graphql";

import {
  DataGrid,
  DataGridGroups,
  GroupBySelect,
  GroupByButtonWrapper,
  DataGridWrapper,
  FiltersBarWrapper,
  type GroupByOption,
} from "#organization/components/data-grid";
import { FlyoutMenu } from "#organization/components/flyout-menu";
import { MainPageLayout } from "#organization/components/layouts";
import { useProjectContextState } from "#organization/recoil/project";

import { ControllerFlyoutMenuWrapper } from "./flyout-menu";
import {
  useControllersData,
  useControllersGroupData,
  useControllersGroupsTitles,
} from "./hooks";
import { filters, headCells } from "./table";
import type { ControllersFlyoutMenuConfig } from "./types";

const GROUP_BYS: GroupByOption<ControllerSelectColumn>[] = [
  {
    title: "No value",
    value: "",
  },
  {
    title: "Controller keys",
    value: ControllerSelectColumn.AgentKeyId,
  },
  {
    title: "Version",
    value: ControllerSelectColumn.Version,
  },
  {
    title: "Status",
    value: ControllerSelectColumn.Status,
  },
];

const INITIAL_ORDER_BY = "version";
const INITIAL_ORDER = "asc";
const TABLE_UNIQUE_ID = "controllers-table";

export const ControllersPage: FC = () => {
  const projectContext = useProjectContextState();
  const filterHeaders: FilterLabelsType[] = filters(projectContext.id);
  const [filterVariables, setFilterVariables] = useState({
    ...useParseLinkAttributesToFilterVariables(TABLE_UNIQUE_ID),
  });

  const chipsContainer = useChipsContainer();
  const [groupByKey, setGroupByKey] =
    useState<(typeof GROUP_BYS)[number]["value"]>("");
  const [controllerFlyoutMenuVisibility, setControllerFlyoutMenuVisibility] =
    useState(false);

  const [selectedController, setSelectedController] =
    useState<ControllersFlyoutMenuConfig>({
      id: "",
      name: "",
      status: "",
    });

  const headerTable = headCells(
    setControllerFlyoutMenuVisibility,
    setSelectedController,
  );

  return (
    <MainPageLayout>
      <FlyoutMenu
        useShowHide={[
          controllerFlyoutMenuVisibility,
          setControllerFlyoutMenuVisibility,
        ]}
      >
        {controllerFlyoutMenuVisibility && (
          <ControllerFlyoutMenuWrapper
            controller={selectedController}
            hideFlyoutMenu={() => setControllerFlyoutMenuVisibility(false)}
          />
        )}
      </FlyoutMenu>

      <FiltersBarWrapper enableArchivedData>
        <FilteringGroup
          labels={filterHeaders}
          onFilter={setFilterVariables}
          chipsContainer={chipsContainer}
          uniqueId={TABLE_UNIQUE_ID}
        />
        <GroupByButtonWrapper>
          <GroupBySelect
            options={GROUP_BYS}
            setGroupByKey={setGroupByKey}
            groupByKey={groupByKey}
          />
        </GroupByButtonWrapper>
      </FiltersBarWrapper>

      <Box py={4}>
        <DataGridWrapper>
          {groupByKey ? (
            <DataGridGroups
              headCells={headerTable}
              useGroupsTitles={useControllersGroupsTitles}
              useGroupData={useControllersGroupData}
              groupByKey={groupByKey}
              filterVariables={filterVariables}
              initialOrderBy={INITIAL_ORDER_BY}
              initialOrder={INITIAL_ORDER}
              enableArchivedData
            />
          ) : (
            <DataGrid
              headCells={headerTable}
              useGridData={useControllersData}
              enabled={!groupByKey}
              filterVariables={filterVariables}
              initialOrderBy={INITIAL_ORDER_BY}
              initialOrder={INITIAL_ORDER}
              enableArchivedData
            />
          )}
        </DataGridWrapper>
      </Box>
    </MainPageLayout>
  );
};
