import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, type BoxProps } from "@mui/material";
import React, { type FC } from "react";

import { LoggerService } from "#shared/services";
import type { Texts } from "#shared/types";

export interface InviteUserResultIconProps {
  isSuccess: boolean;
  isError: boolean;
  message?: string;
  containerProps?: Omit<BoxProps, "children">;
  texts?: Texts<Text>;
}

type Text = "sent" | "unsent";

const enTexts: InviteUserResultIconProps["texts"] = {
  sent: "Sent",
  unsent: "Unsent",
};

export const InviteUserResultIcon: FC<InviteUserResultIconProps> = ({
  message,
  isSuccess,
  isError,
  containerProps,
  texts = enTexts,
}) => {
  if (!isSuccess && !isError) {
    LoggerService.warn("InviteUserResultIcon", "Neither success nor error");
  }

  return (
    <Box
      {...containerProps}
      sx={{
        color: ({ palette }) =>
          isError ? palette.error.main : palette.text.disabled,
        display: "flex",
        columnGap: ({ spacing }) => spacing(1),
        alignItems: "center",
        ...(containerProps?.sx || {}),
      }}
    >
      {isSuccess ? <CheckIcon /> : null}
      {isError ? <ClearIcon /> : null}
      {message || (isSuccess ? texts.sent : null)}
      {message || (isError ? texts.unsent : null)}
    </Box>
  );
};
