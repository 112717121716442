import { Box } from "@mui/material";
import { dump } from "js-yaml";
import React, { useMemo, useRef, type FC } from "react";
import { useForm } from "react-hook-form";

import { ErrorLayout, LoaderLayout } from "#shared/components/layouts";
import type { Texts } from "#shared/types";

import { useHeaderSectionUpdate } from "#organization/recoil/organization/header-section";
import { useProjectContextState } from "#organization/recoil/project";

import {
  CustomizeBlueprint,
  PolicyNameForm,
  ReviewAndApply,
  type PolicyFormNameValues,
  CreateOrEditPolicyHeader,
} from "./components";
import { CreatePolicyContextProvider } from "./context";
import {
  commonBlueprint,
  useBlueprints,
  useCreatePolicySearchParams,
  useEditBlueprint,
  useStepChange,
} from "./hooks";

export interface EditPolicyProps {
  texts?: Texts<"title">;
  updateDraftedPolicy?: boolean;
}

const EditPolicy: FC<EditPolicyProps> = ({
  texts = {
    title: "Edit Policy",
  },
  updateDraftedPolicy = true,
}) => {
  useHeaderSectionUpdate({
    // eslint-disable-next-line react-hooks/exhaustive-deps
    pageTitle: useMemo(() => <CreateOrEditPolicyHeader texts={texts} />, []),
  });
  const {
    searchInfo,
    searchInfo: { origin },
  } = useCreatePolicySearchParams();

  const defaultSteps = useRef(["Edit Policy", "Apply Policy"]);

  const {
    value: activeStep,
    handleNext,
    handleBack,
  } = useStepChange(defaultSteps.current);

  const { id } = useProjectContextState();

  const {
    isError,
    isLoading,
    blueprintVersion: version,
    controllerCommitSha: commitSha,
    values,
  } = useEditBlueprint();

  const { data: fnBlueprints } = useBlueprints(
    version,
    commitSha,
    origin === "Blueprint" && !isLoading,
  );

  const blueprintPath = useMemo(
    () =>
      fnBlueprints.find((p) => p.name === values?.blueprintName)?.path ||
      commonBlueprint.path,
    [fnBlueprints, values?.blueprintName],
  );

  const policyNameForm = useForm<PolicyFormNameValues>({
    defaultValues: {
      policyName: values?.name || searchInfo?.name || "",
    },
    mode: "onChange",
  });

  const defaultValues = useMemo(() => {
    const dumpValue = dump(values?.values ? values.values : values);

    return dumpValue.trim() === "null" ? undefined : dumpValue;
  }, [values]);

  if (isLoading && !isError) {
    return <LoaderLayout />;
  }

  if (isError) {
    return <ErrorLayout />;
  }

  const steps: JSX.Element[] = [
    /** One edit blueprint step: 1 */
    <CustomizeBlueprint
      {...{
        texts: {
          title: texts.title,
        },
        blueprintPath,
        controllerVersion: version,
        controllerCommitSha: commitSha,
        defaultValues,
        actionBarProps: {
          handleNext,
          activeStep,
          handleBack,
          updateDraftedPolicy,
          totalSteps: defaultSteps.current.length,
          policyNameFormControl:
            origin === "Custom" ? policyNameForm : undefined,
        },
        policyNameInput:
          !updateDraftedPolicy || origin === "Custom" ? (
            <PolicyNameForm
              {...{
                projectID: id,
                handleNext,
                useForm: policyNameForm,
              }}
            />
          ) : undefined,
      }}
    />,
    /** blueprint review step and apply: 2 */
    <ReviewAndApply
      {...{
        actionBarProps: {
          handleNext,
          activeStep,
          handleBack,
          totalSteps: defaultSteps.current.length,
        },
        ...(origin !== "Blueprint" && {
          texts: {
            title: "Review and apply your custom policy",
            basicConfig: "Basic configuration",
            fullBlueprint: "Full custom policy",
            fullPolicy: "Full policy",
            useCase: "Use Case",
            name: "Name",
            download: "Download",
          },
        }),
      }}
    />,
  ];

  return (
    <Box px={3} pb={3}>
      {steps[activeStep]}
    </Box>
  );
};

/**
 *
 * EditPolicyPage can edit both blueprint and custom policies.
 *
 * Edit Mode can be changed to create mode by setting updateDraftedPolicy to false.
 *
 * Create mode can only create custom policies.
 *
 */
export const EditPolicyPage: FC<EditPolicyProps> = (props) => (
  <CreatePolicyContextProvider>
    <EditPolicy {...props} />
  </CreatePolicyContextProvider>
);
