import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  Typography,
  styled,
  type TypographyProps,
  Paper,
  Button,
} from "@mui/material";
import { dump } from "js-yaml";
import React, { useMemo, type FC, useCallback } from "react";

import { ErrorLayout } from "#shared/components/layouts";
import type { Texts } from "#shared/types";

import { JsonToYaml } from "#organization/components/json-to-yaml";

import { addBreaks } from "./customize-blueprint";
import {
  ActionBar,
  type ActionBarProps,
} from "./customize-blueprint/action-bar";

import { useCreatePolicyContext } from "../context";
import { useCreatePolicySearchParams } from "../hooks";

type TextsKeys =
  | "title"
  | "basicConfig"
  | "fullBlueprint"
  | "fullPolicy"
  | "useCase"
  | "name"
  | "download";

export interface ReviewAndApplyProps {
  actionBarProps: ActionBarProps;
  texts?: Texts<TextsKeys>;
}

export const defaultTextReviewAndApply: Texts<TextsKeys> = {
  title: "Summary for your Policy",
  basicConfig: "Basic configuration",
  fullBlueprint: "Full blueprint",
  fullPolicy: "Full policy",
  useCase: "Use Case",
  name: "Blueprint",
  download: "Download",
};

export const ReviewAndApply: FC<ReviewAndApplyProps> = ({
  actionBarProps,
  texts = defaultTextReviewAndApply,
}) => {
  const { searchInfo } = useCreatePolicySearchParams();
  const { editor, blueprintJson } = useCreatePolicyContext();

  const details: {
    label: string;
    value: string;
  }[] = useMemo(
    () => [
      {
        label: texts.useCase,
        value:
          searchInfo?.selectedUseCase ||
          (searchInfo?.origin === "Custom" ? "Custom" : "-"),
      },
      {
        label: texts.name,
        value: searchInfo?.blueprintName || searchInfo?.name || "-",
      },
    ],
    [
      searchInfo?.blueprintName,
      searchInfo?.name,
      searchInfo?.origin,
      searchInfo?.selectedUseCase,
      texts.name,
      texts.useCase,
    ],
  );

  const downloadValuesFile = useCallback(
    (yaml?: string) => {
      const value = yaml || editor?.getValue() || "";

      if (!value) {
        return;
      }

      const blob = new Blob([value], { type: "text/yaml" });

      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "values.yaml";
      a.click();
    },
    [editor],
  );

  if (!blueprintJson) {
    return <ErrorLayout />;
  }

  return (
    <ReviewAndApplyWrapper>
      <Typography variant="h6" fontWeight={700} textAlign="center">
        {texts.title}
      </Typography>
      <ReviewAndApplyStyled pt={1}>
        <ColumnBox>
          <Typography {...commonTypographyStyle}>
            {texts.basicConfig}
          </Typography>
          <Box className="blueprint-details" component={Paper}>
            {details.map(({ label, value }, i) => (
              <React.Fragment key={[label, value, i].join("-")}>
                <Typography {...commonTypographyStyle}>{label}</Typography>
                <Typography {...commonTypographyStyle} color="primary">
                  {value}
                </Typography>
              </React.Fragment>
            ))}
          </Box>
          <Box
            {...{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography {...commonTypographyStyle}>
              {texts.fullBlueprint}
            </Typography>
            <Button
              size="small"
              variant="contained"
              onClick={() => downloadValuesFile(dump(blueprintJson))}
              startIcon={<DownloadIcon />}
            >
              {texts.download}
            </Button>
          </Box>

          <JsonToYaml
            jsonObject={blueprintJson}
            customStyle={{
              maxHeight: 500,
            }}
          />
        </ColumnBox>
      </ReviewAndApplyStyled>
      {addBreaks(3)}
      <ActionBar
        {...{
          ...actionBarProps,
          confirmAndApplyPolicy: true,
        }}
      />
    </ReviewAndApplyWrapper>
  );
};

export const ReviewAndApplyWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateRows: "auto 1fr",
  gap: theme.spacing(4),
}));

const ColumnBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const commonTypographyStyle: TypographyProps = {
  fontWeight: 600,
  fontSize: 16,
  variant: "body1",
};

export const ReviewAndApplyStyled = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "2fr 1fr",
  gap: theme.spacing(2),
  "& .blueprint-details": {
    display: "grid",
    gridTemplateRows: "auto",
    gridTemplateColumns: "200px 1fr",
    padding: theme.spacing(2),
  },
}));
