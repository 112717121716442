import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";

import { Analytics } from "#shared/analytics";
import { ChargebeePlans } from "#shared/billing";
import { useFetch, type UseFetch } from "#shared/hooks";
import { userNewState } from "#shared/recoil";
import type {
  AnyObject,
  Organization,
  ProvisioningStatusText,
} from "#shared/types";
import { FEATURE_FLAGS, getOrganizationAbsoluteUrl } from "#shared/utils";
import { httpClient } from "#shared/utils/http-client";

import { API_URLS, QUERY_KEYS } from "#global/consts";

import { useCheckProvisioningStatus } from "../../hooks/use-check-provisioning-status";
import { useOrganizationById } from "../../use-organization-by-id";
import { addSearchParams } from "../../use-organizations";

type OrgCreateResponse = {
  id: string;
  name: string;
  domain_name: string;
  hasura_id: string | null;
  provisioning_status: ProvisioningStatusText | "PENDING";
  workflow_id: string | null;
  idp_tenant_id: string | null;
};

/**
 * NOTE:
 *
 * Provision organization
 */
export const useOrganizationSubmit = () => {
  const [domainName, setDomainName] = useState<string>("");

  const triggerProvisioning = useFetch<OrgCreateResponse, "post">("post");

  const submit = useCallback(
    (domain: string) => {
      setDomainName(domain);

      triggerProvisioning.request({
        url: API_URLS.ORGANIZATIONS.PROVISION,
        body: {
          organization: {
            domain_name: domain,
          },
        },
        shouldParse: true,
      });
    },
    [triggerProvisioning],
  );

  const shouldTriggerProvisioning =
    triggerProvisioning.httpState.isSuccess === true;

  const { provisioningStatus, setProvisioningStatus, hasError } =
    useCheckProvisioningStatus(
      { domain_name: domainName, provisioning_status: "UNKNOWN" },
      shouldTriggerProvisioning,
    );

  const provisioningError = useMemo<string | null>(() => {
    const message =
      triggerProvisioning.httpState.error ||
      (hasError && "Something went wrong") ||
      (provisioningStatus === "FAILED" ? "Provisioning failed" : null);

    return message || null;
  }, [triggerProvisioning.httpState.error, hasError, provisioningStatus]);

  const {
    newOrgLink,
    query: { data },
  } = useFirebaseCustomToken(domainName, provisioningStatus);

  const {
    query: { data: subscriptionData },
  } = useCreateOrgSubscription(
    provisioningStatus !== "PROVISIONED" ? null : triggerProvisioning.result,
    FEATURE_FLAGS.globalBilling,
  );

  useEffect(() => {
    if (
      provisioningStatus !== "PROVISIONED" ||
      !triggerProvisioning.httpState.isSuccess ||
      !data?.token
    ) {
      return;
    }

    const trackID = provisioningError
      ? "org_provisioning_failed"
      : "org_provisioned";

    Analytics.track(trackID, {
      domainName,
    });

    if (!FEATURE_FLAGS.globalBilling) {
      window.location.href = newOrgLink;
    }

    // if (FEATURE_FLAGS.globalBilling) {
    //   navigate(
    //     `${BILLING_ROUTE.ABSOLUTE_PATH}${triggerProvisioning.result?.id}`,
    //   );
    // } else {
    //   navigate(ORGANIZATIONS_ROUTE.ABSOLUTE_PATH);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provisioningStatus, newOrgLink, data?.token]);

  useEffect(() => {
    if (
      FEATURE_FLAGS.globalBilling &&
      subscriptionData?.subscription_id &&
      provisioningStatus === "PROVISIONED"
    ) {
      window.location.href = newOrgLink;
    }
  }, [subscriptionData?.subscription_id, newOrgLink, provisioningStatus]);

  const reset = useCallback(() => {
    triggerProvisioning.reset();
    setProvisioningStatus("UNKNOWN");
  }, [setProvisioningStatus, triggerProvisioning]);

  return useMemo(
    () => ({
      submit,
      isProvisioned: provisioningStatus === "PROVISIONED",
      isLoading:
        !!triggerProvisioning.httpState.isLoading ||
        (shouldTriggerProvisioning &&
          (provisioningStatus === "PROVISIONING" ||
            provisioningStatus === "UNKNOWN")),
      provisioningError,
      reset,
    }),
    [
      provisioningError,
      provisioningStatus,
      reset,
      shouldTriggerProvisioning,
      submit,
      triggerProvisioning.httpState.isLoading,
    ],
  );
};

const useFirebaseCustomToken = (
  domainName: string,
  provisioningStatus: ProvisioningStatusText | null,
) => {
  const email = useRecoilValue(userNewState.email);
  const token = useRecoilValue(userNewState.firebaseToken);
  const { data } = useRecoilValue(userNewState.state);

  const { data: orgs } = useQuery<Organization[]>({
    queryKey: [QUERY_KEYS.CUSTOM_TOKEN, domainName, email],
    queryFn: async () => {
      const response = await httpClient.get<Organization[]>({
        url: `${API_URLS.ORGANIZATIONS.INDEX}?email=${email}`,
        headers: token
          ? { Authorization: httpClient.getTokenWithPrefix(token) }
          : {},
      });

      return response.json();
    },
    enabled:
      !!token &&
      !!email &&
      !!domainName &&
      provisioningStatus === "PROVISIONED",
  });

  const isFoundTenantID = useRef(false);

  const currentOrg = useMemo(() => {
    if (!orgs?.length || isFoundTenantID.current) {
      return null;
    }

    const org = orgs.find((o) => o.domain_name === domainName);

    if (org?.idp_tenant_id) {
      isFoundTenantID.current = true;
    }

    return org;
  }, [domainName, orgs]);

  const query = useQuery<{ token: string }>({
    queryKey: [QUERY_KEYS.CUSTOM_TOKEN, domainName, email],
    queryFn: async () => {
      const response = await httpClient.post<{ token: string }>({
        url: API_URLS.TOKEN.INDEX,
        body: {
          tenantId: currentOrg?.idp_tenant_id,
          email,
          claims: data,
        },
        headers: token
          ? { Authorization: httpClient.getTokenWithPrefix(token) }
          : {},
      });

      return response.json();
    },
    enabled: !!currentOrg?.idp_tenant_id && !!email && !!token,
  });

  return useMemo(
    () => ({
      query,
      email,
      newOrgLink:
        query.data?.token && email
          ? addSearchParams(
              getOrganizationAbsoluteUrl(domainName),
              query.data.token,
              email,
            )
          : getOrganizationAbsoluteUrl(domainName),
    }),
    [domainName, email, query],
  );
};

const useCreateOrgSubscription = (
  triggerProvisioningResult: UseFetch<OrgCreateResponse, "post">["result"],
  enabled = true,
) => {
  const email = useRecoilValue(userNewState.email);
  const token = useRecoilValue(userNewState.firebaseToken);

  const { organization } = useOrganizationById(
    triggerProvisioningResult?.id || "",
  );

  const orgHasuraId =
    triggerProvisioningResult?.hasura_id || organization?.hasura_id;

  const query = useQuery<{ subscription_id: string | undefined }>({
    queryKey: [QUERY_KEYS.CREATE_SUBSCRIPTION, orgHasuraId],
    queryFn: async () => {
      const response = await httpClient.post<{
        subscription_id: string | undefined;
      }>({
        url: API_URLS.BILLING.CREATE_SUBSCRIPTION,
        body: {
          organization_id: orgHasuraId!,
          plan_id: ChargebeePlans[0].planID,
        },
        headers: token ? { Authorization: token } : {},
      });

      const result = await response.json();

      if (!response.ok || !result?.subscription_id) {
        throw new Error(
          (response as AnyObject).errors ||
            response.statusText ||
            "checkout failed",
        );
      }

      return result;
    },
    enabled: !!orgHasuraId && !!email && !!token && enabled,
  });

  return {
    query,
  };
};
