import { ArrowBack } from "@mui/icons-material";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { Button, CircularProgress } from "@mui/material";
import React, {
  type FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRecoilValue } from "recoil";

import type { Texts } from "#shared/types";

import {
  domainNameSelector,
  useGetOrganization,
} from "#organization/recoil/organization";

import {
  useMembersData,
  useGetDefaultProject,
  useGetDefaultUserGroup,
  useGetAgentsCount,
} from "../hooks";

export interface SummaryViewActionsProps {
  texts?: Texts<Text>;
  onBack: () => void;
}

type Text = "proceedToApp" | "back";

const enTexts: Required<SummaryViewActionsProps["texts"]> = {
  proceedToApp: "Proceed to App",
  back: "Back",
};

export const SummaryViewActions: FC<SummaryViewActionsProps> = ({
  texts = enTexts,
  onBack,
}) => {
  const project = useGetDefaultProject();
  const userGroup = useGetDefaultUserGroup();
  const membersData = useMembersData();
  const agents = useGetAgentsCount();
  const domainName = useRecoilValue(domainNameSelector);
  const organization = useGetOrganization(domainName);

  const onProceedToApp = useCallback(() => {
    organization.getOrganization();
  }, [organization]);

  const [isDisabled, setIsDisabled] = useState(true);

  const summaryData = useMemo(
    () => [project, userGroup, membersData, agents],
    [project, userGroup, membersData, agents],
  );

  useEffect(() => {
    setIsDisabled(
      summaryData.some(({ isLoading, isIdle }) => isLoading || isIdle) ||
        organization.isLoading,
    );
  }, [summaryData, organization.isLoading]);

  return (
    <>
      <Button
        onClick={onBack}
        sx={{ mr: 1 }}
        variant="outlined"
        startIcon={<ArrowBack />}
      >
        {texts.back}
      </Button>

      <Button
        variant="contained"
        endIcon={
          isDisabled ? <CircularProgress size="1em" /> : <ArrowForward />
        }
        onClick={onProceedToApp}
        disabled={isDisabled}
      >
        {texts.proceedToApp}
      </Button>
    </>
  );
};
