import type { HeadCell } from "#shared/components/data-grid";

import { globalTimeParser } from "#organization/utils/utils";

import type { KubernetesControlPoint } from "../hooks";

export const headCells = (): HeadCell<KubernetesControlPoint>[] => [
  {
    accessor: (row) => row.name,
    label: "Name",
    key: "name",
  },
  {
    accessor: "kind",
    label: "Kind",
    key: "kind",
  },
  {
    accessor: "namespace",
    label: "Namespace",
    key: "namespace",
  },
  {
    accessor: "apiVersion",
    label: "API version",
    key: "apiVersion",
  },
  {
    accessor: "controller.name",
    label: "Controller",
    key: "controller.name",
  },
  {
    accessor: (row) => globalTimeParser(row.lastSeen),
    label: "Last seen",
    key: "lastSeen",
  },
];
