import { DialogContentText, type ButtonProps } from "@mui/material";
import { Box } from "@mui/system";
import React, {
  type ChangeEvent,
  type FC,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";

import { Input } from "#shared/components/inputs/input";

import { DialogLayout, type DialogLayoutProps } from "../dialog";

interface ConfirmDialogProps
  extends Omit<
    DialogLayoutProps,
    | "onConfimrDialog"
    | "onCloseDialog"
    | "onClickDialogClose"
    | "dialogTitle"
    | "openDialog"
  > {
  text: string;
  onConfirm: () => void;
  close: () => void;
  isOpen: boolean;
  confirmPhrase?: string;
  dialogLayoutProps?: DialogLayoutProps;

  cancelButtonProps?: ButtonProps;
  confirmButtonProps?: ButtonProps;
}

const CONFIRM_TEXT = "Yes";

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  text,
  close: closeProp,
  isOpen,
  onConfirm,
  confirmPhrase,
  ...other
}) => {
  const [confirm, setConfirm] = useState("");
  const confirmRef = useRef<HTMLDivElement>(null);

  const onCloseDialog = useCallback<
    Required<DialogLayoutProps>["onClickDialogClose"]
  >(() => {
    setConfirm("");
    closeProp();
  }, [closeProp]);

  useEffect(() => {
    if (isOpen) {
      setConfirm("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (!confirm) {
      confirmRef.current?.querySelector("input")?.focus();
    }
  }, [confirm]);

  const confirmDisabled = confirm !== confirmPhrase;

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter" && !confirmDisabled) {
        onConfirm();
      }
    },
    [confirmDisabled, onConfirm],
  );

  return (
    <DialogLayout
      dialogTitle="Confirmation"
      onCloseDialog={onCloseDialog}
      openDialog={isOpen}
      onClickDialogClose={onCloseDialog}
      onConfimrDialog={onConfirm}
      dialogContentText={`Are you sure you want to ${text}`}
      buttonConfirmText={CONFIRM_TEXT}
      buttonConfirmVariant="contained"
      buttonConfirmColor="primary"
      buttonCancelVariant="outlined"
      buttonCancelColor="primary"
      {...other}
      buttonConfirmDisabled={confirmDisabled || other.buttonConfirmDisabled}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto",
          columnGap: 1,
        }}
      >
        <DialogContentText>
          To confirm enter <b>{confirmPhrase}</b> in the input.
        </DialogContentText>
        <Input
          sx={{ mt: 3 }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setConfirm(event.currentTarget.value);
          }}
          onKeyDown={onKeyDown}
          size="small"
          autoFocus
          InputProps={{
            ref: confirmRef,
          }}
        />
      </Box>
    </DialogLayout>
  );
};

ConfirmDialog.defaultProps = {
  confirmPhrase: "confirm",
};
