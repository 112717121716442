import type { ThemeOptions } from "@mui/material";

const styleOverrides: Required<
  Required<ThemeOptions>["components"]
>["MuiTab"]["styleOverrides"] = {
  root: ({ theme }) => ({
    textTransform: "none",
    fontWeight: 700,
    outline: "none",
    padding: theme.spacing(2, 0),
    marginRight: theme.spacing(4),
    minWidth: 0,
    "&:focus": {
      outline: "none",
    },
  }),
};

export const MuiTab: Required<Required<ThemeOptions>["components"]>["MuiTab"] =
  { styleOverrides };
