import { hoursToMilliseconds } from "date-fns";
import { gql } from "graphql-request";
import { noop } from "lodash";
import { useMemo } from "react";

import type {
  FlowControlGroupDataQuery,
  FlowControlGroupsTitlesQuery,
} from "#shared/generated/graphql";

import {
  useGridData,
  useGroupData,
  useGroupsTitles,
  type DataGridHook,
  type GroupDataHook,
  type GroupsTitlesHook,
} from "#organization/hooks";
import type { GqlResponseEdgeMapped } from "#organization/utils";

export type FlowControlGroupBy = "name";

const FLOW_CONTROL_ENTITY_KEY = "controlPoints";

export const flowControlGroupsTitles = gql`
  query FlowControlGroupsTitles(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: ControlPointBoolExp
    $distinctOn: [ControlPointSelectColumn!]
  ) {
    controlPoints(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      distinctOn: $distinctOn
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          service {
            id
            agentGroup
            status
            controller {
              name
            }
          }
          name
          lastSeen
          status
          type
        }
      }
    }
  }
`;

type FlowControlGroupsTitleData =
  FlowControlGroupsTitlesQuery["controlPoints"]["edges"][0]["node"];

export const useFlowControlGroupsTitles: GroupsTitlesHook<
  FlowControlGroupBy
> = (pageSize, page, setPage, groupByKey, filterVariables) =>
  useGroupsTitles<FlowControlGroupBy, FlowControlGroupsTitleData>(
    FLOW_CONTROL_ENTITY_KEY,
    pageSize,
    page,
    setPage,
    groupByKey,
    flowControlGroupsTitles,
    (node) => node[groupByKey!],
    filterVariables,
  );

type FlowControlGroupData =
  FlowControlGroupDataQuery["controlPoints"]["edges"][0]["node"];

export type AdaptedFlowControlGroupData = FlowControlGroupData;

export const flowControlGroupData = gql`
  query FlowControlGroupData(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: ControlPointBoolExp
    $distinctOn: [ControlPointSelectColumn!]
    $orderBy: [ControlPointOrderBy]
  ) {
    controlPoints(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      distinctOn: $distinctOn
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          service {
            id
            name
            status
            agentGroup
            controller {
              name
              id
            }
          }
          controlPointComponentAssignments(
            where: { component: { policy: { status: { neq: "3-archived" } } } }
          ) {
            edges {
              node {
                component {
                  policy {
                    id
                  }
                }
              }
            }
          }
          name
          lastSeen
          discoveredOn
          status
          type
        }
      }
    }
  }
`;

export const useServicesControlPointsGroupData: GroupDataHook<
  FlowControlGroupBy
> = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  groupByKey,
  groupByValue,
  filterVariables,
) =>
  useGroupData<
    FlowControlGroupBy,
    FlowControlGroupData,
    AdaptedFlowControlGroupData
  >(
    FLOW_CONTROL_ENTITY_KEY,
    flowControlGroupData,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    groupByKey,
    groupByValue,
    (controlPoints: FlowControlGroupData) => ({
      ...controlPoints,
    }),
    filterVariables,
  );

export const useFlowControlData: DataGridHook = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  enabled,
  filterVariables,
) =>
  useGridData<FlowControlGroupData, AdaptedFlowControlGroupData>(
    FLOW_CONTROL_ENTITY_KEY,
    flowControlGroupData,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    filterVariables,
    (controlPoints: FlowControlGroupData) => ({
      ...controlPoints,
    }),
    enabled,
  );

export const useFlowControl = () => {
  const flowControlData = useFlowControlData(
    5,
    1,
    noop,
    "asc",
    "discoveredOn",
    true,
    {},
  );

  const policyData = flowControlData.query
    .data as GqlResponseEdgeMapped<AdaptedFlowControlGroupData> | null;
  const isEmpty =
    !flowControlData.query.isFetching &&
    !flowControlData.query.isError &&
    policyData?.edges?.length === 0;

  const justInstalled = useMemo(() => {
    if (!flowControlData.query.isFetching && !flowControlData.query.isError) {
      const currentDate = new Date();

      return (
        !isEmpty &&
        policyData?.edges?.every(
          (edge) =>
            currentDate.getTime() - new Date(edge.discoveredOn).getTime() <
            hoursToMilliseconds(1),
        )
      );
    }

    return false;
  }, [
    isEmpty,
    policyData?.edges,
    flowControlData.query.isError,
    flowControlData.query.isFetching,
  ]);

  return { isEmpty, justInstalled, query: flowControlData.query };
};
