import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useMemo } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { BreadcrumbLink } from "#shared/components/breadcrumbs";
import { type Route as RouteType } from "#shared/types";
import { FEATURE_FLAGS, FeatureFlag } from "#shared/utils";

import { TabsPanelLayout } from "#organization/components/layouts/tabs-panel";
import { TopBarBreadcrumbAppender } from "#organization/recoil/breadcrumbs";
import { useOrganizationState } from "#organization/recoil/organization";

import { AuthnSettingsRoutes } from "./authn/routes";
import { AUTHN_SETTINGS_ROUTE } from "./authn/routes.definitions";
import { BillingSettingsRoutes } from "./billing/routes";
import { BILLING_SETTINGS_ROUTE } from "./billing/routes.definitions";
import {
  GeneralSettingsPage,
  GENERAL_SETTINGS_ROUTE,
} from "./general-settings";
import { useIsAuthnSettings } from "./hooks";
import { MembersSettingsRoutes, MEMBERS_SETTINGS_ROUTE } from "./members";
import { SettingsProjectsRoutes, PROJECTS_SETTINGS_ROUTE } from "./projects";
import { ORGANIZATION_SETTINGS_ROUTE } from "./routes.definitions";
import {
  UserGroupsSettingsRoutes,
  USER_GROUPS_SETTINGS_ROUTE,
} from "./user-groups";

import { SETTINGS_ROUTE } from "../routes.definitions";
import { getActiveVerticalTab } from "../utils";

export const OrganizationSettingsRoutes = () => {
  const { pathname } = useLocation();
  const organizationState = useOrganizationState();

  const isAuthnSettings = useIsAuthnSettings();

  const verticalTabs = useMemo(
    () =>
      [
        GENERAL_SETTINGS_ROUTE,
        PROJECTS_SETTINGS_ROUTE,
        USER_GROUPS_SETTINGS_ROUTE,
        MEMBERS_SETTINGS_ROUTE,
        (FEATURE_FLAGS.googleSignInConfiguration ||
          FEATURE_FLAGS.samlSignInConfiguration) &&
        isAuthnSettings
          ? AUTHN_SETTINGS_ROUTE
          : undefined,
        isAuthnSettings ? BILLING_SETTINGS_ROUTE : undefined,
      ].filter(Boolean) as RouteType[],
    [isAuthnSettings],
  );

  return (
    <>
      <TopBarBreadcrumbAppender>
        <BreadcrumbLink to={SETTINGS_ROUTE.ABSOLUTE_PATH}>
          {SETTINGS_ROUTE.TITLE}
        </BreadcrumbLink>
      </TopBarBreadcrumbAppender>
      <TopBarBreadcrumbAppender>
        <Typography>{organizationState.name}</Typography>
      </TopBarBreadcrumbAppender>
      <Box px={3}>
        <TabsPanelLayout
          pageTitle={ORGANIZATION_SETTINGS_ROUTE.TITLE}
          activeTab={getActiveVerticalTab(pathname, verticalTabs)}
          tabsRoutes={verticalTabs}
          tabPanelRoutes={
            <Routes>
              <Route
                path={GENERAL_SETTINGS_ROUTE.KEY}
                element={<GeneralSettingsPage />}
              />
              <Route
                path={PROJECTS_SETTINGS_ROUTE.PARENT_PATH}
                element={<SettingsProjectsRoutes />}
              />
              <Route
                path={USER_GROUPS_SETTINGS_ROUTE.PARENT_PATH}
                element={<UserGroupsSettingsRoutes />}
              />
              <Route
                path={MEMBERS_SETTINGS_ROUTE.PARENT_PATH}
                element={<MembersSettingsRoutes />}
              />
              {isAuthnSettings && (
                <Route
                  path={AUTHN_SETTINGS_ROUTE.PARENT_PATH}
                  element={
                    <FeatureFlag
                      flag={[
                        "samlSignInConfiguration",
                        "googleSignInConfiguration",
                      ]}
                      all={false}
                      renderOn={<AuthnSettingsRoutes />}
                    />
                  }
                />
              )}
              {isAuthnSettings && (
                <>
                  <Route
                    path={BILLING_SETTINGS_ROUTE.PARENT_PATH}
                    element={<BillingSettingsRoutes />}
                  />
                  <Route
                    path={AUTHN_SETTINGS_ROUTE.PARENT_PATH}
                    element={
                      <FeatureFlag
                        flag={[
                          "samlSignInConfiguration",
                          "googleSignInConfiguration",
                        ]}
                        all={false}
                        renderOn={<AuthnSettingsRoutes />}
                      />
                    }
                  />
                </>
              )}
            </Routes>
          }
        />
      </Box>
    </>
  );
};
