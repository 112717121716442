import type { ThemeOptions } from "@mui/material";

const styleOverrides: Required<
  Required<ThemeOptions>["components"]
>["MuiDialog"]["styleOverrides"] = {
  paper: {
    backgroundImage: "none",
  },
};

export const MuiDialog: Required<
  Required<ThemeOptions>["components"]
>["MuiDialog"] = { styleOverrides };
