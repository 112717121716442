import type { ThemeOptions } from "@mui/material";

import { MuiBreadcrumbs } from "./breadcrumbs";
import { MuiButton } from "./button";
import { MuiChip } from "./chip";
import { MuiCssBaseline } from "./css-baseline";
import { MuiDialog } from "./dialog";
import { MuiDialogActions } from "./dialog-actions";
import { MuiDialogTitle } from "./dialog-title";
import { MuiIconButton } from "./icon-button";
import { MuiListItemButton } from "./list-item-button";
import { MuiListItemText } from "./list-item-text";
import { MuiPaper } from "./paper";
import { MuiPopover } from "./popover";
import { MuiTab } from "./tab";
import { MuiTableCell } from "./table-cell";
import { MuiTablePagination } from "./table-pagination";
import { MuiTooltip } from "./tooltip";
import { MuiTypography } from "./typography";

export const components: Required<ThemeOptions>["components"] = {
  MuiBreadcrumbs,
  MuiButton,
  MuiChip,
  MuiCssBaseline,
  MuiDialog,
  MuiDialogActions,
  MuiDialogTitle,
  MuiIconButton,
  MuiListItemButton,
  MuiListItemText,
  MuiPaper,
  MuiPopover,
  MuiTab,
  MuiTableCell,
  MuiTablePagination,
  MuiTooltip,
  MuiTypography,
};
