import { Typography } from "@mui/material";
import React, { type Dispatch, type SetStateAction } from "react";

import { emptyColumnValue, type HeadCell } from "#shared/components/data-grid";

import { ArchivedStatus } from "#organization/components";

import type { CacheDashboardConfig } from "../../../cache-dashboard/types";
import type { AdaptedGlobalCacheData } from "../hooks";

export const headCells = (
  setIsFlyoutMenuVisible: Dispatch<SetStateAction<boolean>>,
  setCacheDashboardConfig: Dispatch<SetStateAction<CacheDashboardConfig>>,
): HeadCell<AdaptedGlobalCacheData>[] => [
  {
    accessor: (row) => (
      <ArchivedStatus status={row.status}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            cursor: "pointer",
            "&:hover": { color: (theme) => theme.palette.primary.main },
          }}
          onClick={() => {
            setCacheDashboardConfig({
              controllerId: row.controller.name || "",
              controlPoint: ".*",
              agentGroup: row.agentGroup || "",
              type: "global",
            });

            setIsFlyoutMenuVisible(true);
          }}
        >
          {row.agentGroup || emptyColumnValue}
        </Typography>
      </ArchivedStatus>
    ),
    label: "Agent group",
    key: "agentGroup",
    noSorting: true,
  },
  {
    accessor: (row) => row.controller.name,
    label: "Controller",
    key: "controller",
    noSorting: true,
  },
];
