import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, Button, type ButtonProps, Paper } from "@mui/material";
import type { Theme } from "@mui/system";
import React, {
  type CSSProperties,
  type FC,
  type PropsWithChildren,
  useState,
} from "react";
import {
  type FieldValues,
  type SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";

import { FormActionButton, PrimaryButton } from "#shared/components/buttons";
import type { Texts } from "#shared/types";

import {
  ChannelTextInput,
  type ChannelTextInputProps,
} from "./channel-text-input";
import { FormByNotifierType } from "./form-by-notifier";
import { useExecuteSubmitForm } from "./submit-form-hook";
import { useOnClickDeleteNotifier } from "./utils";

import { useDeleteBox } from "../../../../../../../shared/components/pop-up/delete-confirm-box";
import { ColumnWrapper } from "../../../styled";

export const FIELD_ARRAY_NAME = "newChannel";

export interface ChannelFormProps {
  formMode?: "create" | "update";
  defaultValues?: FieldValues;
  texts?: Texts<Text>;
}

type Text =
  | "add"
  | "save"
  | "title"
  | "confirmationQuestion"
  | "deleteNotifier"
  | "channelNameIsRequired";

const enTexts: Required<ChannelFormProps["texts"]> = {
  title: "Channel name",
  add: "Add another notifier",
  save: "Save channel",
  confirmationQuestion: "Are you sure you want to remove this notifier?",
  deleteNotifier: "Delete notifier",
  channelNameIsRequired: "Channel name is required",
};

export const ChannelForm: FC<ChannelFormProps> = ({
  formMode = "create",
  defaultValues = {
    [FIELD_ARRAY_NAME]: [{ notifierType: "createEmailNotifier" }],
  },
  texts = enTexts,
}) => {
  const modifiedDefaultValues: FieldValues =
    formMode === "update"
      ? {
          ...defaultValues,
          name: `#${defaultValues.name}`,
          [FIELD_ARRAY_NAME]: defaultValues.newChannel.map(
            ({ channel, ...other }: FieldValues) => ({
              channel: `#${channel}`,
              ...other,
            }),
          ),
        }
      : defaultValues;
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    reset,
    watch,
  } = useForm({
    defaultValues: modifiedDefaultValues,
  });

  const { fields, append, remove, update, replace } = useFieldArray({
    control,
    name: FIELD_ARRAY_NAME,
    shouldUnregister: true,
  });

  const [storeDataFlag, setStoreDataFlag] = useState<string>("noChange");
  const [notifierFieldsArr, setNotifierFieldsArr] = useState<FieldValues[]>(
    modifiedDefaultValues[FIELD_ARRAY_NAME],
  );

  const { onClickDeleteNotifier } = useOnClickDeleteNotifier([
    notifierFieldsArr,
    setNotifierFieldsArr,
  ]);

  const { addDeleteBox } = useDeleteBox();

  useExecuteSubmitForm(formMode, getValues, reset, [
    storeDataFlag,
    setStoreDataFlag,
  ]);

  const onSubmit: SubmitHandler<FieldValues> = () => {
    setStoreDataFlag("submitted");
  };

  const titleTypographyProps: ChannelTextInputProps["titleTypographyProps"] = {
    sx: {
      color: (theme: Theme) => theme.palette.primary.main,
    },
  };

  const textFieldProps: ChannelTextInputProps["textFieldProps"] = {
    title: texts.title,
    id: "name",
    type: "text",
    placeholder: texts.title,
    ...register("name", {
      required: texts.channelNameIsRequired,
    }),
    error: !!errors.name,
    helperText: errors.name?.message as string,
    value:
      !watch("name")?.includes("#") && watch("name")
        ? ["#", watch("name")?.toLowerCase()].join("")
        : watch("name")?.toLowerCase() || "",
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <ColumnWrapper component={Paper} p={3}>
        <Box px={3}>
          <ChannelTextInput
            titleTypographyProps={titleTypographyProps}
            textFieldProps={textFieldProps}
          />
        </Box>
        {fields.map((field, index) => (
          <ColumnWrapper sx={notifierWrapperStyle} key={field.id}>
            <FormByNotifierType
              value={field}
              update={update}
              index={index}
              storeDataFlagState={[storeDataFlag, setStoreDataFlag]}
              defaultValues={notifierFieldsArr[index]}
            />
            {(index !== 0 || formMode === "update") && (
              <DeleteNotifierButton
                onClick={() => {
                  addDeleteBox({
                    message: texts.confirmationQuestion,
                    callback: (status: boolean) => {
                      if (status) {
                        onClickDeleteNotifier(index, remove, replace);
                      }
                    },
                  });
                }}
              >
                {texts.deleteNotifier}
              </DeleteNotifierButton>
            )}
          </ColumnWrapper>
        ))}
        <Box>
          <FormActionButton
            startIcon={<AddIcon />}
            onClick={() =>
              append({
                notifierType: "createEmailNotifier",
              })
            }
          >
            {texts.add}
          </FormActionButton>
        </Box>
      </ColumnWrapper>

      <Box sx={channelButtonCSS} py={3}>
        <PrimaryButton type="submit">{texts.save}</PrimaryButton>
      </Box>
    </form>
  );
};

export interface DeleteNotifierButtonProps
  extends Omit<
    ButtonProps,
    "color" | "startIcon" | "variant" | "size" | "color" | "sx"
  > {}
export const DeleteNotifierButton: FC<
  PropsWithChildren<DeleteNotifierButtonProps>
> = ({ children, ...buttonProps }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    }}
  >
    <Button
      startIcon={<DeleteOutlineIcon />}
      variant="outlined"
      size="small"
      color="inherit"
      sx={deleteButtonCSS}
      {...buttonProps}
    >
      {children}
    </Button>
  </Box>
);

const deleteButtonCSS: CSSProperties = {
  maxWidth: 180,
};

const channelButtonCSS: CSSProperties = {
  float: "right",
  clear: "both",
};

const notifierWrapperStyle = (theme: Theme) => ({
  position: "relative",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.other,
});
