import React, { type FC } from "react";
import { Route, Routes } from "react-router-dom";

import { BreadcrumbLink } from "#shared/components/breadcrumbs";

import { TopBarBreadcrumbAppender } from "#organization/recoil/breadcrumbs";

import { BillingSettingsPage } from "./page";
import { BILLING_SETTINGS_ROUTE } from "./routes.definitions";

export const BillingSettingsRoutes: FC = () => (
  <>
    <TopBarBreadcrumbAppender>
      <BreadcrumbLink to={BILLING_SETTINGS_ROUTE.ABSOLUTE_PATH}>
        {BILLING_SETTINGS_ROUTE.TITLE}
      </BreadcrumbLink>
    </TopBarBreadcrumbAppender>

    <Routes>
      <Route index element={<BillingSettingsPage />} />
    </Routes>
  </>
);
