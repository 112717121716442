import BrowserInteractionTime from "browser-interaction-time";

/**
 * Browser interaction time calculate time when user is active on page.
 * If user is ideal on the page for more than 3 seconds, then it will stop the timer.
 * Analytics will be sent to segment.io when user leaves the page.
 */
export class UserInteractionOnPage {
  private browserInteractionTime: BrowserInteractionTime;

  constructor() {
    this.browserInteractionTime = new BrowserInteractionTime({
      timeIntervalEllapsedCallbacks: [],
      absoluteTimeEllapsedCallbacks: [],
      browserTabInactiveCallbacks: [],
      browserTabActiveCallbacks: [],
      idleTimeoutMs: 3000,
      checkCallbacksIntervalMs: 250,
    });
  }

  public startTime() {
    this.browserInteractionTime.startTimer();
  }

  public stopTime() {
    this.browserInteractionTime.stopTimer();
  }

  public getInteractionTime() {
    const seconds = this.browserInteractionTime.getTimeInMilliseconds() / 1000;

    return UserInteractionOnPage.roundSeconds(seconds);
  }

  private static roundSeconds(seconds: number) {
    return Math.round((seconds + Number.EPSILON) * 100) / 100;
  }
}

export const FnUserInteractionOnPage = new UserInteractionOnPage();
