import { isEmpty } from "lodash";
import React, { type FC, type PropsWithChildren } from "react";

import { ErrorLayout, ErrorText } from "#shared/components/layouts";
import type { Policy } from "#shared/generated/graphql";
import type { Texts } from "#shared/types";

type FindMisconfiguredPolicyTexts = "message";

export type FindMisconfiguredPolicyProps = PropsWithChildren<{
  policy: Policy;
  texts?: Texts<FindMisconfiguredPolicyTexts>;
}>;

const findMisconfiguredPolicyTexts: Texts<FindMisconfiguredPolicyTexts> = {
  message: "Policy is misconfigured: circuit links or components are missing.",
};

export const FindMisconfiguredPolicy: FC<FindMisconfiguredPolicyProps> = ({
  policy,
  children,
  texts = findMisconfiguredPolicyTexts,
}) => {
  if (policy && isEmpty(policy.circuit.graph)) {
    return (
      <ErrorLayout>
        <ErrorText>{texts.message}</ErrorText>
      </ErrorLayout>
    );
  }

  return <>{children}</>;
};
