import { Box, styled } from "@mui/material";

import type { FnTheme } from "../../../../../../../../../../../app-theme-provider/types";

export declare type CircuitBoundaryStyledProps = {
  transform: {
    x: number;
    y: number;
    zoom: number;
  };
  dimensions: {
    height: number;
    width: number;
    top: number;
    left: number;
  };
};

export const CircuitBoundaryStyled = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== ("transform" || "dimensions" || "instructions" || "hide"),
})<CircuitBoundaryStyledProps>(({
  theme,
  transform: { x, y, zoom },
  dimensions: { height, width, top, left },
}) => {
  const { circuit } = (theme as unknown as FnTheme).palette;

  return {
    position: "absolute",
    top,
    left,
    border: `2px dotted ${circuit?.edges.onHover}`,
    width,
    height,
    borderRadius: theme.spacing(0.5),
    transformOrigin: "top left",
    transform: `translate(${x}px, ${y}px) scale(${zoom})`,
  };
});
