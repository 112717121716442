import React, { type FC, useMemo } from "react";

import { DASHBOARDVER } from "#shared/dashboard-versions";

import { LoadDashboardPortal } from "#organization/components/grafana";
import { DASHBOARDS } from "#organization/pages/consts";
import type {
  DashboardConfig,
  FlowMapDashboardQueryParam,
} from "#organization/pages/types";
import { useProjectContextState } from "#organization/recoil/project";

import type { FlowControlFlyoutConfig } from "../../../types";

export type FlowMapProps = {
  service: Pick<
    FlowControlFlyoutConfig,
    "agentGroup" | "serviceName" | "controlPoint" | "controllerId"
  >;
};

const config: Omit<DashboardConfig<"FLOW_MAP">, "queryParams"> = {
  name: "flow-map",
  slug: "flow-map",
  version: DASHBOARDVER.FLOW_MAP,
  uuid: DASHBOARDS.FLOW_MAP,
  hiddenVariables: [
    "var-fn_project_id",
    "var-agent_group",
    "var-controller_id",
    "var-services",
    "var-control_point",
  ],
  filters: [],
};

export const FlowMap: FC<FlowMapProps> = ({
  service: { agentGroup, serviceName, controllerId, controlPoint },
}) => {
  const projectState = useProjectContextState();

  const queryParams: { [Q in FlowMapDashboardQueryParam]?: string } = useMemo(
    () => ({
      "var-fn_project_id": projectState.id,
      "var-agent_group": agentGroup,
      "var-controller_id": controllerId,
      "var-services": serviceName || "All",
      "var-control_point": controlPoint,
    }),
    [projectState.id, agentGroup, controllerId, serviceName, controlPoint],
  );

  return (
    <>
      {queryParams && (
        <LoadDashboardPortal
          queryParams={queryParams}
          slug={config.slug}
          uid={config.uuid}
          hiddenVariables={config.hiddenVariables}
        />
      )}
    </>
  );
};
