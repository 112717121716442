import { ArrowForward, Close } from "@mui/icons-material";
import { Box, Button, Link, Typography, type ButtonProps } from "@mui/material";
import { styled } from "@mui/system";
import { noop } from "lodash";
import React, { useMemo, type FC } from "react";

import { BASE_DOCUMENTATION_URL, FLUXNINJA } from "#shared/consts";
import errorNinja from "#shared/logo-error.svg";
import confusedNinja from "#shared/logo-no-data.svg";
import sadNinja from "#shared/logo-sad.svg";
import happyNinja from "#shared/logo-succesfully.svg";

const LOGO_SIZE = "220px";

const WrapperStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  alignItems: "center",
  maxWidth: "900px",
  margin: "0 auto 50px auto",
}));

const ActionStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "24px",
  flexDirection: "column",
  justifyContent: "center",
  gap: "24px",
  flex: "1 0 0",
  minWidth: "250px",
  maxWidth: "400px",
  backgroundColor: theme.palette.background.paper,
  borderRadius: "8px",
  boxShadow:
    "0px 1px 8px 0px rgba(96, 101, 117, 0.12), 0px 3px 4px 0px rgba(96, 101, 117, 0.14), 0px 3px 3px -2px rgba(96, 101, 117, 0.20)",
}));

const ButtonStyled = styled(Button)(() => ({
  display: "flex",
  alignSelf: "end",
  alignItems: "center",
  gap: "8px",
  marginBottom: "-50px",
}));

export interface InfoDataPageProps {
  img?: keyof typeof imgMap | null;
  title?: string;
  description?: string;
  actions?: Action[];
  dismissable?: boolean;
  onDismiss?: () => void;
  docButtonText?: string;
  docButtonLink?: string;
}

export interface Action {
  title: string;
  description: string;
  buttonText: string;
  buttonProps?: ButtonProps;
}

export const InfoDataPage: FC<InfoDataPageProps> = ({
  img = "confused-ninja",
  title = "No data",
  description,
  actions = [],
  docButtonLink,
  docButtonText = "Read more",
  dismissable = false,
  onDismiss = noop,
}) => {
  const [dismissed, setDismissed] = React.useState<boolean>(false);
  const image = useMemo(() => getImg(img), [img]);

  if (dismissed) {
    return null;
  }

  return (
    <WrapperStyled>
      {dismissable && (
        <ButtonStyled
          onClick={() => {
            setDismissed(true);
            onDismiss();
          }}
        >
          <Close />
          <Typography>Dismiss</Typography>
        </ButtonStyled>
      )}
      {image && (
        <img
          src={image}
          alt={[FLUXNINJA, "logo"].join(" ")}
          style={{ height: LOGO_SIZE, width: LOGO_SIZE }}
        />
      )}
      {title && <Typography variant="h4">{title}</Typography>}
      {description && (
        <Typography
          variant="body1"
          sx={(theme) => ({
            textAlign: "center",
            color: theme.palette.secondary.main,
          })}
        >
          {description}
        </Typography>
      )}
      {actions.length > 0 && (
        <Box display="flex" gap="24px" flexWrap="wrap">
          {actions.map((action) => (
            <ActionStyled key={action.title}>
              <Box flex={1}>
                <Typography sx={{ fontWeight: 600 }}>{action.title}</Typography>
                <Typography mt={1}>{action.description}</Typography>
              </Box>
              <Button
                {...action.buttonProps}
                variant="outlined"
                endIcon={<ArrowForward />}
                sx={{ marginLeft: "auto" }}
              >
                {action.buttonText}
              </Button>
            </ActionStyled>
          ))}
        </Box>
      )}
      {docButtonLink && (
        <Link
          href={`${BASE_DOCUMENTATION_URL}${docButtonLink}`}
          target="__blank"
        >
          <Button
            color="secondary"
            variant="outlined"
            endIcon={<ArrowForward />}
            sx={{ margin: "0 auto" }}
          >
            {docButtonText}
          </Button>
        </Link>
      )}
    </WrapperStyled>
  );
};

const imgMap = {
  "confused-ninja": confusedNinja,
  "sad-ninja": sadNinja,
  "happy-ninja": happyNinja,
  "error-ninja": errorNinja,
};

const getImg = (img: InfoDataPageProps["img"]) =>
  img ? imgMap[img] : undefined;
