import { Box } from "@mui/system";
import React, { useState, type FC, useMemo } from "react";

import { DataGridPagination } from "#shared/components/data-grid";
import {
  ErrorLayout,
  LoaderLayout,
  NoDataLayout,
} from "#shared/components/layouts";
import { convertToString } from "#shared/components/layouts/error/convert-to-string";

import {
  AlertDataCard,
  type AlertDataCardType,
} from "../components/alert-datacard";
import { useAlertJobs } from "../hooks";

export interface AlertJobsProps {
  data: {
    type: "controller" | "agent";
    id: string;
  };
}

export const AlertJobs: FC<AlertJobsProps> = ({ data }) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const { query } = useAlertJobs(data);

  const alertJobs = useMemo<AlertDataCardType[]>(() => {
    if (
      query.status === "success" &&
      query.data &&
      query.data.edges.length > 0
    ) {
      const time = query.data.edges[0].lastSeen;

      return (
        query.data.edges[0].healthDetails.map((jobStatus: string) => {
          const isError = jobStatus.startsWith("Error");

          return {
            title: isError ? "Error" : "Status",
            type: isError ? "error" : "info",
            time,
            /* Transforms 'Status for XYZ' to 'XYZ' */
            description: jobStatus.split(" ").slice(2).join(" "),
          };
        }) ?? []
      );
    }

    return [];
  }, [query.data, query.status]);

  const paginatedAlertJobs = useMemo(() => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    return alertJobs.slice(startIndex, endIndex);
  }, [alertJobs, page, rowsPerPage]);

  if (query.status === "error") {
    return (
      <ErrorLayout texts={{ defaultError: convertToString(query.error) }} />
    );
  }

  if (query.status === "loading") {
    return <LoaderLayout />;
  }

  if (alertJobs.length === 0) {
    return <NoDataLayout />;
  }

  return (
    <Box display="flex" flexDirection="column" height="100%" gap={1}>
      {paginatedAlertJobs.map((alert) => (
        <AlertDataCard key={alert.description} {...alert} />
      ))}
      <DataGridPagination
        page={page}
        rowsPerPage={rowsPerPage}
        length={alertJobs.length}
        handleChangeRowsPerPage={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
        handleChangePage={(_, newPage) => setPage(newPage)}
      />
    </Box>
  );
};
