export const archiveFilterGroupData = {
  and: [
    {
      status: {
        nilike: "%archived%",
      },
    },
  ],
};

export const archiveFilterDataGrid = {
  status: {
    nilike: "%archived%",
  },
};
