import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, type FC } from "react";
import { useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";

import { useAlert } from "#shared/components/alerts-provider";
import {
  ConfirmDialog,
  useControlDialog,
} from "#shared/components/layouts/dialog";
import { PermissionEnum, userNewState } from "#shared/recoil";
import { LoggerService } from "#shared/services";
import type { Texts } from "#shared/types";

import { DataGrid } from "#organization/components/data-grid";
import { QUERY_KEYS } from "#organization/pages/consts";

import { headCells } from "./components/table";
import { useRemoveUserGroup } from "./hooks";
import { useUserGroupsQuery } from "./hooks-new";
import { USER_GROUPS_SETTINGS_ROUTE } from "./routes.definitions";

import { SettingsButtons } from "../../components";
import { SettingsSearch } from "../../components/settings-search";
import { SectionWrapper } from "../../components/styled";
import { CreateUserGroupDialog } from "../components/create-user-group-dialog";

const INITIAL_ORDER_BY = "name";
const INITIAL_ORDER = "asc";

type Text =
  | "createUserGroup"
  | "removeUserGroupSuccess"
  | "removeUserGroupError"
  | "removeUserGroup";

interface UserGroupsPageProps {
  texts?: Texts<Text>;
}

const enText: Required<UserGroupsPageProps["texts"]> = {
  createUserGroup: "Create New User Group",
  removeUserGroupSuccess: "Successfully removed user group.",
  removeUserGroupError: "Could not remove user group.",
  removeUserGroup: "remove user group?",
};

export const UserGroupsPage: FC<UserGroupsPageProps> = ({ texts = enText }) => {
  const [selectedUserGroup, setSelectedUserGroup] = useState<string>("");
  const createUserGroupDialog = useControlDialog();
  const confirmDeleteDialogControl = useControlDialog();
  const queryClient = useQueryClient();
  const refetchUserGroups = () =>
    queryClient.invalidateQueries([QUERY_KEYS.DATA_GRID, "userGroups"]);

  const userRole = useRecoilValue(userNewState.userRole)!;

  const permissionLock =
    PermissionEnum[userRole] < PermissionEnum.user_group_admin;

  const { addAlert } = useAlert();

  const [searchPhrase, setSearchPhrase] = useState("");

  const userGroupControl = useRemoveUserGroup();

  const removeUserGroup = () => {
    userGroupControl.remove(
      { id: selectedUserGroup },
      {
        onSuccess: () => {
          refetchUserGroups();
          queryClient.invalidateQueries([QUERY_KEYS.DATA_GRID, "userGroups"]);
          confirmDeleteDialogControl.close();
          setSelectedUserGroup("");

          addAlert({
            type: "success",
            message: texts.removeUserGroupSuccess,
          });
        },
        onError: (error) => {
          LoggerService.error(error);

          addAlert({
            type: "error",
            message: texts.removeUserGroupError,
          });
        },
      },
    );
  };

  const headCellsInstance = headCells(permissionLock, (id) => {
    setSelectedUserGroup(id);
    confirmDeleteDialogControl.open();
  });

  return (
    <>
      <SettingsButtons title={USER_GROUPS_SETTINGS_ROUTE.TITLE} />
      <SectionWrapper>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          mb={2}
        >
          <SettingsSearch
            onSearch={(search: string) => setSearchPhrase(search)}
          />
          <>
            <Button
              onClick={createUserGroupDialog.open}
              variant="contained"
              size="small"
              disabled={permissionLock}
              startIcon={<AddIcon />}
            >
              {texts.createUserGroup}
            </Button>

            <CreateUserGroupDialog
              dialogControl={createUserGroupDialog}
              refetch={refetchUserGroups}
            />
          </>
        </Box>

        <DataGrid
          headCells={headCellsInstance}
          useGridData={useUserGroupsQuery}
          enabled
          filterVariables={{
            name: { like: `${searchPhrase}%` },
          }}
          initialOrder={INITIAL_ORDER}
          initialOrderBy={INITIAL_ORDER_BY}
        />

        <ConfirmDialog
          isOpen={confirmDeleteDialogControl.isOpen}
          close={confirmDeleteDialogControl.close}
          onConfirm={removeUserGroup}
          text={texts.removeUserGroup}
        />
      </SectionWrapper>
    </>
  );
};
