import { Box, Button, SvgIcon, type SvgIconProps } from "@mui/material";
import React, { type FC } from "react";
import { useRecoilValue } from "recoil";

// import { BASE_DOCUMENTATION_URL } from "#shared/consts";
import type {
  GenerateUserApiKeyMutation,
  RevokeUserApiKeyInput,
  RevokeUserApiKeyMutation,
} from "#shared/generated/graphql";
import { userNewState } from "#shared/recoil";

import {
  ApiKeysPage,
  type ApiKeysPageProps,
} from "#organization/components/api-keys";
import { enTextsGenerateApiKeyDialog } from "#organization/components/api-keys/components/generate-agent-api-key-dialog";
import { BASE_DOCUMENTATION_URL, QUERY_KEYS } from "#organization/pages/consts";
import { TopBarBreadcrumbAppender } from "#organization/recoil/breadcrumbs";

import {
  API_KEYS_ENTITY_KEY,
  useGenerateUserApiKey,
  useUserApiKeysData,
  type AdaptedApiKeysGroupData,
  useRevokeUserApiKey,
} from "./hooks";
import { USER_API_KEYS_ROUTE } from "./routes.definitions";
import { headCells } from "./table";

import { SettingsButtons } from "../../components";

const API_PORTAL_KEY_ID = `api-keys-action-buttons-portal`;

export const UserApiKeysPage: FC = () => {
  const firebaseEmail = useRecoilValue(userNewState.email);

  const useGridData = useUserApiKeysData(firebaseEmail || "");

  const apiKeyPageProps: ApiKeysPageProps<
    AdaptedApiKeysGroupData,
    string,
    string,
    unknown,
    unknown,
    GenerateUserApiKeyMutation,
    void,
    RevokeUserApiKeyMutation,
    RevokeUserApiKeyInput
  > = {
    useGridData,
    hideFilterAndGroupBy: true,
    refetchQueryKey: [QUERY_KEYS.DATA_GRID, API_KEYS_ENTITY_KEY],
    headCells,
    permissionLock: false,
    generateApiKeyDialogProps: {
      useGenerateAPIKey: useGenerateUserApiKey,
      generateKeyHelperFunc: {
        mapToInput: () => {},
        findKey: (data) => data?.generateUserAPIKey?.userAPIKey?.key || "",
        findId: (data) => data?.generateUserAPIKey?.userAPIKey?.id || "",
      },
      useRevokeKey: useRevokeUserApiKey,
      revokeKeyHelperFunc: {
        mapToInput: (data) => ({
          id: data?.id,
        }),
      },
      texts: {
        ...enTextsGenerateApiKeyDialog,
        dialogTitle: "New Personal Access Token",
        dialogContentText: "New Personal Access Token has been created:",
      },
    },
    texts: {
      create: "New Personal Access Token",
    },

    extraInfoButton: (
      <Button
        {...{
          variant: "text",
          color: "inherit",
          endIcon: (
            <InfoButtonIcon
              sx={{
                height: "1rem",
                width: "1rem",
              }}
            />
          ),
          href: [
            BASE_DOCUMENTATION_URL,
            "reference/aperture-cli/configure-cli",
          ].join("/"),
          target: "_blank",
        }}
      >
        Personal Access Token Usage
      </Button>
    ),
    actionButtonsPortal: {
      show: true,
      props: {
        container: document.getElementById(API_PORTAL_KEY_ID),
      },
    },
  };

  return (
    <>
      <SettingsButtons
        title={USER_API_KEYS_ROUTE.TITLE}
        portalButtons={{
          show: true,
          boxProps: {
            id: API_PORTAL_KEY_ID,
          },
        }}
      />
      <Box pt={3}>
        <TopBarBreadcrumbAppender>
          {USER_API_KEYS_ROUTE.TITLE}
        </TopBarBreadcrumbAppender>
        <ApiKeysPage {...apiKeyPageProps} />
      </Box>
    </>
  );
};

export const InfoButtonIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 18 18" fill="none" {...props}>
    <path d="M15 16H3C2.45 16 2 15.55 2 15V3C2 2.45 2.45 2 3 2H8C8.55 2 9 1.55 9 1C9 0.45 8.55 0 8 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V10C18 9.45 17.55 9 17 9C16.45 9 16 9.45 16 10V15C16 15.55 15.55 16 15 16ZM11 1C11 1.55 11.45 2 12 2H14.59L5.46 11.13C5.07 11.52 5.07 12.15 5.46 12.54C5.85 12.93 6.48 12.93 6.87 12.54L16 3.41V6C16 6.55 16.45 7 17 7C17.55 7 18 6.55 18 6V0H12C11.45 0 11 0.45 11 1Z" />
  </SvgIcon>
);
