import {
  isSignInWithEmailLink as firebaseIsSignInWithEmailLink,
  type Auth,
  type User,
} from "firebase/auth";
import { cloneDeep, filter, isEqual, noop, pick } from "lodash";
import React, {
  createContext,
  useEffect,
  useMemo,
  useState,
  type FC,
  type PropsWithChildren,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";

import { Analytics } from "#shared/analytics";
import { AddPopUpProvider } from "#shared/components/pop-up";
import {
  getRecoilOutsideComponent,
  setRecoilOutsideComponent,
} from "#shared/components/recoil-outside-hooks";
import {
  API_ACCOUNT_URLS,
  LOCAL_STORAGE_KEYS,
  QUERY_PARAMS,
} from "#shared/consts";
import { ENV } from "#shared/env";
import { useLogout, useZohoCRM } from "#shared/hooks";
import {
  DEFAULT_HTTP_STATUS,
  type UseFetch,
  useFetch,
  type HttpState,
} from "#shared/hooks/use-fetch";
import {
  type UseGetAccount,
  useGetAccount,
} from "#shared/hooks/use-get-account";
import { type HttpRequestState, FirebaseUserRecoilState } from "#shared/recoil";
import { type FirebaseUser } from "#shared/recoil/firebase.types";
import { LoggerService } from "#shared/services";
import { type TenantIdpConfigUnsecuredResponse } from "#shared/types";
import { httpClient } from "#shared/utils/http-client";

import {
  useInitializeFirebase,
  useSendSignInLinkToEmail,
  type UseSendSignInLinkToEmail,
  useSignInWithEmailLink,
  type UseSignInWithEmailLink,
  useSignInWithEmailAndPassword,
  type UseSignInWithEmailAndPassword,
  useIntercomBootUser,
} from "./hooks";

import { UiGracefulProvider } from "../ui-graceful";

export interface FirebaseContextProviderProps {
  isOrganizationContext: boolean;
  organizationId: string | null | undefined;
  idpTenantID: string | null | undefined;
}

export type FirebaseContextValue = {
  firebase: Auth | null;
  /**
   * NOTE:
   * null when firebase not loaded
   */
  isSignInWithEmailLink: boolean | null;
  refreshIsSignInWithEmailLink: () => void;
  httpState: HttpState<string | null>;
  sendSignInLinkToEmail: UseSendSignInLinkToEmail | null;
  signInWithEmailLink: UseSignInWithEmailLink | null;
  signInWithEmailAndPassword: UseSignInWithEmailAndPassword | null;
  signInEmail: string | null;
  getAccount: UseGetAccount;
  tenantConfig: UseFetch<TenantIdpConfigUnsecuredResponse> & {
    tenantSignInProps: TenantSignInProps;
  };
  requestDefaultConfig: () => void;
};

export interface TenantSignInProps {
  inboundSamlConfigs: TenantIdpConfigUnsecuredResponse["inboundSamlConfigs"];
  oauthIdpConfigs: TenantIdpConfigUnsecuredResponse["oauthIdpConfigs"];
  defaultSupportedIdpConfigs: TenantIdpConfigUnsecuredResponse["defaultSupportedIdpConfigs"];
  isFallbackGoogleConfigured: TenantIdpConfigUnsecuredResponse["isFallbackGoogleConfigured"];
  /**
   * NOTE:
   *
   * Is google (not fallback google) or saml configured
   *
   *  FIXME: Could be received from the api
   */
  isSsoConfigured: boolean;
  isWithLink: boolean;
}

export const FirebaseContext = createContext<FirebaseContextValue>({
  firebase: null,
  isSignInWithEmailLink: false,
  refreshIsSignInWithEmailLink: () => {},
  signInEmail: null,
  /**
   * NOTE:
   *
   * State of firebase initializeApp
   */
  httpState: cloneDeep(DEFAULT_HTTP_STATUS),
  sendSignInLinkToEmail: null,
  signInWithEmailLink: null,
  signInWithEmailAndPassword: null,
  getAccount: {
    getAccount: () => Promise.resolve(null),
    result: null,
    reset: () => {},
    httpState: cloneDeep(DEFAULT_HTTP_STATUS),
  },
  tenantConfig: {
    reset: () => {},
    httpState: cloneDeep(DEFAULT_HTTP_STATUS),
    result: null,
    request: () => Promise.resolve(null),
    updateHttpState: {
      reset: () => {},
      setIsLoading: () => {},
      setIsIdle: () => {},
      setIsSuccess: () => {},
      setError: () => {},
    },
    tenantSignInProps: {
      inboundSamlConfigs: [],
      oauthIdpConfigs: [],
      defaultSupportedIdpConfigs: [],
      isWithLink: false,
      isFallbackGoogleConfigured: true,
      isSsoConfigured: false,
    },
  },
  requestDefaultConfig: noop,
});

const USER_STATE_KEY = `state__"${FirebaseUserRecoilState?.ATOM_PREFIX}"`;

/**
 * NOTE:
 *
 * Provides firebase.
 *
 * Sets "onAuthStateChanged".
 */
export const FirebaseContextProvider: FC<
  FirebaseContextProviderProps & PropsWithChildren
> = (props) => (
  <AddPopUpProvider>
    {/* add pop to ask user to confirm email */}
    <FirebaseContextProviderWithoutPopUpProvider {...props} />
  </AddPopUpProvider>
);

export const FirebaseContextProviderWithoutPopUpProvider: FC<
  FirebaseContextProviderProps & PropsWithChildren
> = ({ children, organizationId, isOrganizationContext, idpTenantID }) => {
  const { initialize, auth, isIdle, isLoading, error, isSuccess } =
    useInitializeFirebase();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const [signInEmail, setSignInEmail] = useState<string | null>(null);

  const { mutate: addLeadToCRM } = useZohoCRM();

  const logout = useLogout(ENV.VITE_APP === "organization");

  useEffect(() => {
    const initializeAsync = async () => {
      await initialize();
    };

    initializeAsync();

    setSignInEmail(
      window.localStorage.getItem(LOCAL_STORAGE_KEYS.signInWithEmail),
    );
  }, [initialize]);

  const tenantConfig = useFetch<TenantIdpConfigUnsecuredResponse>();

  const requestDefaultConfig = useCallback(() => {
    if (isOrganizationContext && !idpTenantID) {
      LoggerService.debug(
        null,
        "Is organization context.",
        "Missing idp tenant id",
        "Won't fetch tenant config.",
      );

      return;
    }

    const url = new URL(API_ACCOUNT_URLS.TENANTS.CONFIG(idpTenantID));

    url.searchParams.append(QUERY_PARAMS.ENABLED, "true");

    tenantConfig.request({
      url,
      shouldParse: true,
    });
  }, [tenantConfig, isOrganizationContext, idpTenantID]);

  useEffect(() => {
    if (!tenantConfig.httpState.isIdle) {
      LoggerService.debug("Tenant config already fetched.");

      return;
    }

    requestDefaultConfig();
  }, [requestDefaultConfig, tenantConfig.httpState.isIdle]);

  const firebase = useMemo(() => {
    if (!auth) {
      return null;
    }

    if (!isOrganizationContext) {
      return auth;
    }

    if (!idpTenantID) {
      return null;
    }

    if (auth.tenantId !== idpTenantID) {
      auth.tenantId = idpTenantID;
    }

    return auth;
  }, [idpTenantID, auth, isOrganizationContext]);

  const setUser = useMemo(
    () => (user: HttpRequestState<FirebaseUser | null>) =>
      setRecoilOutsideComponent([USER_STATE_KEY, user]),
    [],
  );

  const sendSignInLinkToEmail = useSendSignInLinkToEmail(firebase);

  const getAccount = useGetAccount(isOrganizationContext, organizationId);

  const onAuthStateChanged = useMemo(
    () => async (updatedUser: User | null) => {
      LoggerService.debug(
        "onAuthStateChanged",
        "Trying to update firebase user.",
      );

      const setUnAuth = (errMsg: string = "") => {
        setUser({
          httpState: {
            isIdle: false,
            isSuccess: true,
            error: errMsg,
            isLoading: false,
          },
          data: null,
        });

        sendSignInLinkToEmail?.resetSendEmailStatus();
      };

      if (!updatedUser) {
        LoggerService.debug("onAuthStateChanged", "Firebase user is null.");

        setUnAuth();

        return;
      }

      if (
        updatedUser.metadata.creationTime ===
          updatedUser.metadata.lastSignInTime &&
        ENV.VITE_APP === "organization"
      ) {
        const res = await httpClient.get({
          url: `${API_ACCOUNT_URLS.ALLOW_SIGN_IN_TO_ORG}/${
            updatedUser.email || queryParams.get("email")
          }/${idpTenantID}`,
        });

        if (!res.ok) {
          await updatedUser.delete();

          logout();
          setUnAuth(`You are not a member of this organization.`);

          return;
        }
      }

      // tracking user sign-in/sign-up events using segments
      if (updatedUser.email) {
        Analytics.identify(updatedUser.email, {
          email: updatedUser.email,
          name: updatedUser.displayName,
        });

        const traceID =
          updatedUser.metadata.creationTime ===
          updatedUser.metadata.lastSignInTime
            ? "sign_up"
            : "sign_in";

        Analytics.track(traceID, {
          email: updatedUser.email,
          name: updatedUser.displayName,
          signInMethod: updatedUser.providerId,
        });
      }

      const state =
        await getRecoilOutsideComponent<HttpRequestState<FirebaseUser | null>>(
          USER_STATE_KEY,
        );

      const { data: userState = null } = state || {};

      try {
        const data = pick(
          JSON.parse(JSON.stringify(updatedUser)),
          FirebaseUserRecoilState.PROPERTIES,
        ) as FirebaseUser;

        if (userState && isEqual(data, userState)) {
          LoggerService.debug(
            "onAuthStateChanged",
            "FirebaseUser state has not changed.",
            { user: !!data },
          );

          return;
        }

        LoggerService.debug(
          "onAuthStateChanged",
          "FirebaseUser state has changed.",
          { isUser: !!data },
        );

        setUser({
          httpState: {
            isIdle: false,
            isSuccess: true,
            error: null,
            isLoading: false,
          },
          data: cloneDeep(data),
        });
      } catch (err) {
        LoggerService.debug(
          null,
          "onAuthStateChanged",
          "Failed to store firebase user.",
          err,
        );

        setUser({
          httpState: {
            isIdle: false,
            isSuccess: false,
            error: err instanceof Error ? err.message : String(err),
            isLoading: false,
          },
          data: null,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setUser],
  );

  useEffect(() => {
    if (!auth) {
      LoggerService.debug(
        "onAuthStateChanged",
        "Failed to register callback",
        "Missing firebase auth.",
      );

      setUser(FirebaseUserRecoilState.INITIAL_STATE);

      return;
    }

    LoggerService.debug("onAuthStateChanged", "Trying to register callback.");

    auth.onAuthStateChanged(onAuthStateChanged);

    LoggerService.debug("onAuthStateChanged", "Registered callback.");
  }, [isSuccess, onAuthStateChanged, auth, setUser]);

  /**
   * NOTE:
   *
   * Store initial href
   */
  const [href, setHref] = useState(window.location.href);

  const isSignInWithEmailLink = useMemo<null | boolean>(
    () => (firebase ? firebaseIsSignInWithEmailLink(firebase, href) : null),
    [firebase, href],
  );

  const refreshIsSignInWithEmailLink = useMemo<
    FirebaseContextValue["refreshIsSignInWithEmailLink"]
  >(
    () => () => {
      setHref(window.location.href);
    },
    [],
  );

  const signInWithEmailLink = useSignInWithEmailLink(
    firebase,
    isSignInWithEmailLink,
    href,
  );

  const signInWithEmailAndPassword = useSignInWithEmailAndPassword(firebase);

  const { mutate: bootIntercomUser } = useIntercomBootUser();

  useEffect(() => {
    if (!getAccount.result?.email) {
      return;
    }

    const { email } = getAccount.result;

    /**
     * Boot user identity to intercom, so that we can identify the user
     * in intercom. This is required for intercom to work for logged in users.
     */
    bootIntercomUser(
      {
        email,
      },
      {
        onError: (err) => {
          LoggerService.error("Failed to boot intercom user.", err, { email });
        },
        onSuccess: (data) => {
          LoggerService.debug("Booted intercom user.", data, { email });
        },
      },
    );
  }, [bootIntercomUser, getAccount.result]);

  useEffect(() => {
    if (!getAccount.result?.email || !auth) {
      return;
    }

    const { email, name } = getAccount.result;
    const leadEmail = window.localStorage.getItem("crm-lead-added");

    /**
     * If it is a new user, add the user to zoho crm as a lead.
     */
    if (
      auth.currentUser?.metadata.creationTime !==
        auth.currentUser?.metadata.lastSignInTime ||
      ENV.VITE_APP === "organization" ||
      leadEmail === email
    ) {
      return;
    }

    window.localStorage.setItem("crm-lead-added", email);

    addLeadToCRM(
      {
        email,
        lastName: name || email.split("@")[0],
      },
      {
        onError: (err) => {
          LoggerService.error("Failed to add lead to crm.", err, { email });
        },
        onSuccess: (data) => {
          LoggerService.debug("Added lead to crm.", data, { email });
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccount.result]);

  const defaultSupportedIdpConfigs = useMemo<
    TenantSignInProps["defaultSupportedIdpConfigs"]
  >(
    () =>
      filter(
        tenantConfig.result?.defaultSupportedIdpConfigs,
        ({ displayName }) => !!displayName,
      ),
    [tenantConfig.result],
  );

  const inboundSamlConfigs = useMemo<TenantSignInProps["inboundSamlConfigs"]>(
    () =>
      filter(
        tenantConfig.result?.inboundSamlConfigs,
        ({ displayName }) => !!displayName,
      ),
    [tenantConfig.result],
  );

  const oauthIdpConfigs = useMemo<TenantSignInProps["oauthIdpConfigs"]>(
    () =>
      filter(
        tenantConfig.result?.oauthIdpConfigs,
        ({ displayName }) => !!displayName,
      ),
    [tenantConfig.result],
  );

  const isWithLink = useMemo<boolean>(
    () =>
      !!tenantConfig.result?.isFallbackGoogleConfigured &&
      !!tenantConfig.result?.enableEmailLinkSignin,
    [
      tenantConfig.result?.enableEmailLinkSignin,
      tenantConfig.result?.isFallbackGoogleConfigured,
    ],
  );

  const tenantSignInProps: TenantSignInProps = useMemo(
    () => ({
      inboundSamlConfigs,
      oauthIdpConfigs,
      defaultSupportedIdpConfigs,
      isWithLink,
      isFallbackGoogleConfigured:
        !!tenantConfig.result?.isFallbackGoogleConfigured,
      isSsoConfigured: !!(
        inboundSamlConfigs?.length ||
        (defaultSupportedIdpConfigs?.length &&
          !tenantConfig.result?.isFallbackGoogleConfigured) ||
        oauthIdpConfigs?.length ||
        oauthIdpConfigs?.length
      ),
    }),
    [
      inboundSamlConfigs,
      oauthIdpConfigs,
      defaultSupportedIdpConfigs,
      isWithLink,
      tenantConfig.result?.isFallbackGoogleConfigured,
    ],
  );

  const value = useMemo<FirebaseContextValue>(
    () => ({
      firebase,
      httpState: { isLoading, isIdle, isSuccess, error },
      isSignInWithEmailLink,
      refreshIsSignInWithEmailLink,
      sendSignInLinkToEmail,
      signInWithEmailLink,
      signInWithEmailAndPassword,
      signInEmail,
      getAccount,
      tenantConfig: { ...tenantConfig, tenantSignInProps },
      requestDefaultConfig,
    }),
    [
      requestDefaultConfig,
      firebase,
      isLoading,
      isIdle,
      isSuccess,
      error,
      isSignInWithEmailLink,
      refreshIsSignInWithEmailLink,
      sendSignInLinkToEmail,
      signInWithEmailLink,
      signInWithEmailAndPassword,
      getAccount,
      signInEmail,
      tenantConfig,
      tenantSignInProps,
    ],
  );

  return (
    <FirebaseContext.Provider {...{ value }}>
      <UiGracefulProvider>{children}</UiGracefulProvider>
    </FirebaseContext.Provider>
  );
};
