import type { TabProps } from "@mui/material";
import React, { useMemo, type FC } from "react";

import { DropdownTab } from "#organization/components";

const tabs = ["Services", "Kubernetes"];

type DiscoveryTabProps = {
  currentTab: number;
  onChangeTab: (tab: number) => void;
} & TabProps;

export const DiscoveryTab: FC<DiscoveryTabProps> = ({
  currentTab,
  onChangeTab,
  ...props
}) => {
  const menuItems = useMemo(
    () =>
      tabs.map((tab, index) => ({
        id: tab,
        node: tab,
        isSelected: index === currentTab,
        onClick: () => onChangeTab(index),
      })),
    [currentTab, onChangeTab],
  );

  return <DropdownTab menuItems={menuItems} {...props} />;
};
