import React from "react";

import { Chip } from "#shared/components/chip";
import type { HeadCell } from "#shared/components/data-grid";

import { Labels } from "./table-components";
import type { AlertsLogType } from "./types";

import { ALERT_SEVERITY_COLOR_MAP } from "../../../../../../../../../app-theme-provider/theme/palette";
import { NotificationChannel } from "../notification-channel";

export const alertsTableHead = (): HeadCell<AlertsLogType>[] => [
  {
    accessor: "startsAt",
    label: "Start",
    key: "startsAt",
  },
  {
    accessor: ({ severity }) => (
      <Chip
        size="small"
        label={severity}
        color={ALERT_SEVERITY_COLOR_MAP[severity]}
      />
    ),
    label: "Severity",
    key: "severity",
  },
  {
    accessor: "name",
    label: "Name",
    key: "name",
    align: "left",
  },
  {
    accessor: ({ alertsChannelAssignments }) => (
      <NotificationChannel
        alertsChannelAssignments={alertsChannelAssignments}
      />
    ),
    label: "Notification channels",
    key: "channels",
    align: "left",
    noSorting: true,
  },
  {
    accessor: ({ labels }) => <Labels labels={labels} />,
    label: "Labels",
    key: "labels",
    align: "left",
    width: "200px",
  },
];
