import { gql } from "graphql-request";

import type { FilterLabelsType } from "#shared/components/filtering";

export const filters = (projectId: string): FilterLabelsType[] => [
  {
    id: 0,
    name: "Name",
    key: "name",
    category: "kubernetesControlPoints",
    gql: gql`
      query ControlPointsKubernetesName(
        $where: KubernetesControlPointBoolExp
        $first: Int
      ) {
        kubernetesControlPoints(
          where: $where
          first: $first
          distinctOn: [name]
        ) {
          edges {
            node {
              name
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      if (prop === "") {
        return {
          first: 5,
          where: {
            projectID: { eq: projectId },
          },
        };
      }

      return {
        first: 5,
        where: {
          projectID: { eq: projectId },
          name: {
            like: `${prop}%`,
          },
        },
      };
    },
  },
  {
    id: 1,
    name: "Controller",
    key: "controller.name",
    category: "kubernetesControlPoints",
    uniqueKey: "controllerName",
    filterPath: "controller.name",
    variable: (prop = "") => {
      if (prop === "") {
        return {
          first: 5,
          where: {
            projectID: { eq: projectId },
          },
        };
      }

      return {
        first: 5,
        where: {
          projectID: { eq: projectId },
          controller: {
            name: {
              like: `${prop}%`,
            },
          },
        },
      };
    },
    gql: gql`
      query ControlPointsKubernetesControllerName(
        $where: KubernetesControlPointBoolExp
        $first: Int
      ) {
        kubernetesControlPoints(
          where: $where
          first: $first
          distinctOn: [controllerID]
        ) {
          edges {
            node {
              controller {
                name
              }
            }
          }
        }
      }
    `,
    selected: false,
  },
  {
    id: 2,
    name: "API version",
    key: "apiVersion",
    category: "kubernetesControlPoints",
    gql: gql`
      query ControlPointsKubernetesApiVersion(
        $where: KubernetesControlPointBoolExp
        $first: Int
      ) {
        kubernetesControlPoints(
          where: $where
          first: $first
          distinctOn: [apiVersion]
        ) {
          edges {
            node {
              apiVersion
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      if (prop === "") {
        return {
          first: 5,
          where: {
            projectID: { eq: projectId },
          },
        };
      }

      return {
        first: 5,
        where: {
          projectID: { eq: projectId },
          apiVersion: {
            like: `${prop}%`,
          },
        },
      };
    },
  },
  {
    id: 3,
    name: "Namespace",
    key: "namespace",
    category: "kubernetesControlPoints",
    gql: gql`
      query ControlPointsKubernetesNamespace(
        $where: KubernetesControlPointBoolExp
        $first: Int
      ) {
        kubernetesControlPoints(
          where: $where
          first: $first
          distinctOn: [namespace]
        ) {
          edges {
            node {
              namespace
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      if (prop === "") {
        return {
          first: 5,
          where: {
            projectID: { eq: projectId },
          },
        };
      }

      return {
        first: 5,
        where: {
          projectID: { eq: projectId },
          namespace: {
            like: `${prop}%`,
          },
        },
      };
    },
  },
  {
    id: 4,
    name: "Kind",
    key: "kind",
    category: "kubernetesControlPoints",
    gql: gql`
      query ControlPointsKubernetesKind(
        $where: KubernetesControlPointBoolExp
        $first: Int
      ) {
        kubernetesControlPoints(
          where: $where
          first: $first
          distinctOn: [kind]
        ) {
          edges {
            node {
              kind
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      if (prop === "") {
        return {
          first: 5,
          where: {
            projectID: { eq: projectId },
          },
        };
      }

      return {
        first: 5,
        where: {
          projectID: { eq: projectId },
          kind: {
            like: `${prop}%`,
          },
        },
      };
    },
  },
];
