import React from "react";

export const ChargebeePlans = [
  {
    planID: "Pro-cloud",
    title: "Pro Plan",
    description: null,
    billing: [
      {
        title: "Aperture Serverless",
        description: (
          <>
            No backend management, all infrastructure is owned and maintained by
            FluxNinja
          </>
        ),
        cost: "$0.05",
        unit: "per 10K good requests",
        quota: "Plus $50 Project/Month",
      },
      {
        title: "Aperture for your Infrastructure",
        description: (
          <>
            A fully hosted control plane with our agents on your infrastructure.
          </>
        ),
        cost: "$0.07",
        unit: "per Agent per hour",
        quota: "Plus $50 Project/Month",
      },
    ],
    features: [
      "First two million good requests free for each project per month",
      "30 day free trial for all features. No credit card required",
    ],
    warnings: [
      "Once the trial period is over, you will be billed based on your usage",
    ],
  },
];
