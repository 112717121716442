import type { Variables } from "graphql-request";
import {
  type Dispatch,
  type SetStateAction,
  type MouseEvent,
  useEffect,
  useMemo,
} from "react";
import { useQuery, type UseQueryResult } from "react-query";
import { useRecoilState } from "recoil";

import type { AnyObject, JSONObject } from "#shared/types";

import { QUERY_KEYS } from "#organization/pages/consts";
import { projectContextState } from "#organization/recoil/project";
import { adaptGqlResponse, gqlRequest } from "#organization/utils";

import { useGraphqlPagination } from "./graphql-pagination";

export const createSortingObject = (keys: string, order: string): {} => {
  if (keys) {
    const keysArray: string[] = keys.split(".");
    const lastKey = keysArray.pop();

    if (lastKey) {
      const lastObject = { [lastKey]: order };

      return keysArray.reduceRight(
        (obj: object, key) => ({ [key]: obj }),
        lastObject,
      );
    }

    return {};
  }

  return {};
};

export const useGridData = <T, P>(
  entityKey: string,
  gql: string,
  pageSize: number,
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  order: string,
  orderBy: string,
  filterVariables: JSONObject,
  gqlResponseMapFn: (node: T) => P,
  enabled = true,
  filteredByProject = true,
  orderByTemplate?: (orderBy: string, order: string) => JSONObject,
  customFilterVariables?: JSONObject,
  modifyResponseData?: (data: T) => AnyObject,
) => {
  const { paginationVariables, onPageChange, resetPagination } =
    useGraphqlPagination(setPage);
  const [projectContext] = useRecoilState(projectContextState);
  const variables: Variables = useMemo(() => {
    const orderByObject = orderByTemplate
      ? orderByTemplate(orderBy, order)
      : createSortingObject(orderBy, order);
    const useOrderBy = Object.keys(orderByObject).length > 0;

    return {
      where: {
        ...(filteredByProject ? { projectID: { eq: projectContext.id } } : {}),
        ...filterVariables,
      },
      ...(useOrderBy && { orderBy: orderByObject }),
      ...paginationVariables(pageSize),
      ...customFilterVariables,
    };
  }, [
    customFilterVariables,
    filterVariables,
    filteredByProject,
    order,
    orderBy,
    orderByTemplate,
    pageSize,
    paginationVariables,
    projectContext.id,
  ]);

  useEffect(() => {
    resetPagination();
  }, [filterVariables, filteredByProject, resetPagination]);

  const queryKeys = [
    QUERY_KEYS.DATA_GRID,
    entityKey,
    orderBy,
    order,
    pageSize,
    page,
    JSON.stringify(filterVariables),
  ];

  if (filteredByProject) {
    queryKeys.push(projectContext.id);
  }

  const query = useQuery(
    queryKeys,
    () =>
      gqlRequest<AnyObject, Variables>(gql, variables).then((gqlResponse) => {
        if (!gqlResponse) {
          return null;
        }

        if (modifyResponseData) {
          const response = modifyResponseData(gqlResponse as unknown as T);

          return adaptGqlResponse<T, P>(
            response?.[entityKey],
            gqlResponseMapFn,
          );
        }

        return adaptGqlResponse<T, P>(gqlResponse[entityKey], gqlResponseMapFn);
      }),
    {
      enabled,
      retry: false,
    },
  );

  return {
    query,
    onPageChange: onPageChange(
      query?.data?.pageInfo || {
        startCursor: null,
        endCursor: null,
        hasNextPage: false,
        hasPreviousPage: false,
      },
    ),
  };
};

export type DataGridHook<Data = unknown, Error = unknown> = (
  pageSize: number,
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  order: string,
  orderBy: string,
  enabled: boolean,
  filterVariables: JSONObject,
) => {
  query: UseQueryResult<Data, Error>;
  onPageChange: (
    _event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void;
};
