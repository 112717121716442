import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { TreeItem, TreeView, type TreeViewProps } from "@mui/x-tree-view";
import { get } from "lodash";
import React, { useMemo, type FC, type PropsWithChildren } from "react";

import { useCreatePolicyContext } from "../../context";

export const FormFieldWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateRows: "auto",
  gap: theme.spacing(1),
  rowGap: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  "& .MuiTextField-root": {
    width: "100%",
  },
}));

export const PolicyFormTreeView: FC<
  PropsWithChildren<{ treeViewProps?: TreeViewProps<true> }>
> = ({ children, treeViewProps }) => (
  <TreeView
    aria-label="file system navigator"
    defaultCollapseIcon={<ExpandMoreIcon />}
    defaultExpandIcon={<ChevronRightIcon />}
    defaultExpanded={["1"]}
    {...treeViewProps}
  >
    {children}
  </TreeView>
);

export const TreeItemStyled = styled(TreeItem)(({ theme }) => ({
  "& .MuiTreeItem-label": {
    padding: theme.spacing(1),
    fontSize: 15,
  },
}));

export const Heading: FC<
  PropsWithChildren<{ heading: string; showHeading?: boolean }>
> = ({ heading, children, showHeading = true }) => (
  <>
    {showHeading && (
      <Typography variant="body1" fontWeight={400} pb={1} fontSize={16}>
        {heading}
      </Typography>
    )}
    {children}
  </>
);

export const FieldTitleStyled = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  fontSize: 15,
  fontWeight: 500,
}));

export type FieldWithTitleProps = {
  label: string;
  hidden?: boolean;
  fieldAddress: string;
  hideInfoIcon?: boolean;
  required?: boolean;
};

export const FieldWithTitle: FC<PropsWithChildren<FieldWithTitleProps>> = ({
  children,
  label,
  fieldAddress,
  hidden = false,
  hideInfoIcon = false,
  required = false,
}) => {
  const { setCreatePolicyCtx, docTree } = useCreatePolicyContext();

  const onClickInfo = () => {
    setCreatePolicyCtx((prev) => ({
      ...prev,
      showDocumentation: true,
      docFieldAddress: fieldAddress,
    }));
  };

  const isDocAvailable = useMemo(
    () => !!get(docTree, fieldAddress),
    [docTree, fieldAddress],
  );

  return (
    <Box hidden={hidden}>
      <FieldTitleStyled variant="subtitle1">
        <Typography>
          {label}
          {required && (
            <Typography
              component="span"
              sx={{
                color: (theme) => theme.palette.error.light,
                fontSize: 18,
                fontWeight: 500,
              }}
            >
              *
            </Typography>
          )}
        </Typography>
        {!hideInfoIcon && isDocAvailable ? (
          <InfoOutlinedIcon
            fontSize="small"
            sx={{ cursor: "pointer" }}
            onClick={onClickInfo}
          />
        ) : null}
      </FieldTitleStyled>
      {children}
    </Box>
  );
};

export const DeleteSelectorButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  marginBottom: theme.spacing(2),
  height: 35,
}));
