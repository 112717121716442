import agent_group from "./agent_group.md";
import blueprint from "./blueprint.mdx";
import bucket_capacity from "./bucket_capacity.md";
import continuous_fill from "./continuous_fill.md";
import control_point from "./control_point.mdx";
import delay_initial_fill from "./delay_initial_fill.md";
import enabled from "./enabled.md";
import fairness_label_key from "./fairness_label_key.mdx";
import fill_amount from "./fill_amount.md";
import interval from "./interval.md";
import label_matcher from "./label_matcher.mdx";
import limit_by_label_key from "./limit_label_key.mdx";
import max_idle_time from "./max_idle_time.md";
import num_sync from "./num_sync.md";
import priority_label_key from "./priority_label_key.mdx";
import service from "./service.md";
import tokens_label_key from "./tokens_label_key.mdx";
import workload_label_key from "./workload_label_key.mdx";

import type { RecursiveStringify } from "../../types";
import * as QuotaScheduling from "../@types";

export type QuotaSchedulingDocsTree =
  RecursiveStringify<QuotaScheduling.Coordinate>;

export const quotaSchedulingDocsTree: QuotaSchedulingDocsTree = {
  blueprint,
  policy: {
    policy_name: "", // no docs needed
    quota_scheduler: {
      bucket_capacity,
      fill_amount,
      rate_limiter: {
        interval,
        limit_by_label_key,
        max_idle_time,
        continuous_fill,
        delay_initial_fill,
        lazy_sync: {
          enabled,
          num_sync,
        },
      },
      scheduler: {
        workload_label_key,
        tokens_label_key,
        priority_label_key,
        fairness_label_key,
        workloads: [
          {
            label_matcher,
          },
        ],
      },
      selectors: [
        {
          control_point,
          agent_group,
          service,
          label_matcher,
        },
      ],
    },
  },
};
