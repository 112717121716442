import { cloneDeep } from "lodash";
import { useCallback, useMemo } from "react";

import {
  type InviteMembersFormData,
  useInviteMembers,
} from "../../common/use-invite-members";

export interface Invitation extends InviteMembersFormData {}

export interface UseInviteOptions {
  userGroupId: string | null;
  emptyMember: Invitation;
}

export type UseInvite = ReturnType<typeof useInvite>;

export function useInvite({ userGroupId, emptyMember }: UseInviteOptions) {
  const {
    setMembers: setInviteList,
    members: inviteList,
    isInviteListValid: isValid,
    sendInvitations,
    validate,
    isSendingInvitations,
    isSuccess: isInviteSuccess,
    invitationResponse,
    sendInvitationsError,
    isAllInvitationsSent,
  } = useInviteMembers({
    emptyMember,
  });

  const resetInvitations = useCallback(() => {
    if (!userGroupId) {
      return;
    }

    setInviteList([{ ...emptyMember, userGroup: userGroupId }]);
  }, [setInviteList, userGroupId, emptyMember]);

  const addInvite = useCallback(() => {
    if (!userGroupId) {
      return;
    }

    setInviteList([...inviteList, { ...emptyMember, userGroup: userGroupId }]);
  }, [inviteList, userGroupId, emptyMember, setInviteList]);

  const removeInvite = useCallback(
    (index: number) => {
      if (!index) {
        const list = cloneDeep(inviteList);
        list[0] = cloneDeep(emptyMember);

        setInviteList(list);

        return;
      }

      setInviteList(inviteList.filter((_, i) => i !== index));
    },
    [inviteList, setInviteList, emptyMember],
  );

  const onInviteEmailChange = useCallback(
    (index: number, value: string) => {
      setInviteList(
        inviteList.map(
          (invite, i): InviteMembersFormData =>
            i === index ? { ...invite, email: value } : invite,
        ),
      );
    },
    [inviteList, setInviteList],
  );

  const onInviteErrorChange = useCallback(
    (index: number, isError: boolean) => {
      setInviteList(
        inviteList.map(
          (invite, i): InviteMembersFormData =>
            i === index ? { ...invite, isValid: !isError } : invite,
        ),
      );
    },
    [inviteList, setInviteList],
  );

  const onInviteRoleChange = useCallback(
    (index: number, value: number | string) => {
      setInviteList(
        inviteList.map(
          (invite, i): InviteMembersFormData =>
            i === index
              ? { ...invite, userRole: parseInt(value.toString(), 10) }
              : invite,
        ),
      );
    },
    [inviteList, setInviteList],
  );

  const isAnybodyInvited = useMemo(
    () => inviteList.length > 1 || !!inviteList[0].email,
    [inviteList],
  );

  const validateInvitations = useCallback(
    () => validate(isAnybodyInvited),
    [validate, isAnybodyInvited],
  );

  const isInviteListEmpty = useMemo(
    () => inviteList.length === 1 && !inviteList[0].email,
    [inviteList],
  );

  const isInviteListValid = useMemo(
    () => isInviteListEmpty || isValid,
    [isValid, isInviteListEmpty],
  );

  const isSomeInviteEmpty = useMemo(
    () => inviteList.some(({ email }) => !email),
    [inviteList],
  );

  const invitationsCount = useMemo(() => inviteList.length, [inviteList]);

  return {
    addInvite,
    removeInvite,
    onInviteEmailChange,
    onInviteErrorChange,
    onInviteRoleChange,
    inviteList,
    isInviteListValid,
    isInviteListEmpty,
    validateInvitations,
    sendInvitations,
    resetInvitations,
    isInviteSuccess,
    invitationResponse,
    isAllInvitationsSent,
    isSendingInvitations,
    isSomeInviteEmpty,
    invitationsCount,
    sendInvitationsError,
  };
}
