import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Box,
  CardActionArea,
  CircularProgress,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import MaterialCard from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { styled } from "@mui/system";
import React, { memo, type FC } from "react";

import type {
  Organization,
  OrganizationMembership,
  Texts,
} from "#shared/types";
import { FeatureFlag } from "#shared/utils";

import { BillingInfoButton } from "./billing-info-button";

const StyledCard = styled(MaterialCard)({
  width: "100%",
  height: "100%",
  display: "grid",
  gridTemplateColumns: "1fr auto auto",
  boxShadow:
    "0px 3px 3px -2px rgba(96, 101, 117, 0.2), 0px 3px 4px rgba(96, 101, 117, 0.14), 0px 1px 8px rgba(96, 101, 117, 0.12)",
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

export interface CardProps {
  organization: Omit<Organization, "provisioning_status"> & {
    provisioning_status: Organization["provisioning_status"] | null;
  };
  membership: OrganizationMembership;
  error: boolean;
  texts?: Texts<Text>;
}

type Text = "loading" | "provisioningFailed" | "error";

const enTexts: Required<CardProps["texts"]> = {
  loading: "provisioning",
  provisioningFailed: "Provisioning failed",
  error: "Something went wrong",
};

export const Card: FC<CardProps> = memo((props) => {
  const { organization, membership, texts = enTexts, error } = props;

  const [avatarLetter = ""] = organization.domain_name || "";

  const disabled =
    organization.provisioning_status !== "PROVISIONED" || !!error;

  return (
    <StyledCard sx={{ borderRadius: 1.5 }}>
      <CardActionArea
        sx={{
          cursor: "pointer",
          ...(disabled ? { color: "text.disabled" } : {}),
        }}
        {...{ disabled }}
      >
        <Link
          underline="none"
          target="_blank"
          href={organization.link}
          sx={{ cursor: "pointer", color: "inherit" }}
        >
          <CardHeader
            avatar={
              avatarLetter && (
                <StyledAvatar aria-label={avatarLetter}>
                  {avatarLetter}
                </StyledAvatar>
              )
            }
            title={organization.name}
            titleTypographyProps={{
              variant: "h6",
              sx: { cursor: "pointer", color: "inherit" },
            }}
            subheaderTypographyProps={{
              variant: "body2",
              color: "inherit",
            }}
            sx={{ cursor: "pointer", color: "inherit" }}
          />
        </Link>
      </CardActionArea>
      {organization.provisioning_status === "FAILED" && !error && (
        <Typography fontSize="12px" color="error" alignSelf="center" mr={2}>
          {texts.provisioningFailed}
        </Typography>
      )}
      {error && (
        <Box color="error.main" display="flex" alignItems="center" mr={2}>
          <Tooltip title={texts.error} arrow placement="top">
            <ErrorOutlineIcon />
          </Tooltip>
        </Box>
      )}
      {(organization.provisioning_status === "UNKNOWN" ||
        organization.provisioning_status === null) &&
        !error && (
          <Box display="flex" alignItems="center" mr={2}>
            <CircularProgress size={16} />
          </Box>
        )}
      {organization.provisioning_status === "PROVISIONING" && !error ? (
        <Typography
          fontSize="12px"
          color="text.secondary"
          alignSelf="center"
          mr={2}
          sx={{
            paddingRight: "20px",
            position: "relative",
            "&::after": {
              display: "inline-block",
              minWidth: "15px",
              position: "absolute",
              top: "50%",
              right: "5px",
              transform: "translateY(-50%)",
              animation: "dotty steps(1,end) 1s infinite",
              content: "''",
            },
            animation: "opacity 1.3s ease-in-out",
            "@keyframes dotty": {
              "0% ": { content: "''" },
              "25%": { content: '"."' },
              "50%": { content: '".."' },
              "75%": { content: '"..."' },
              "100%": { content: '""' },
            },
          }}
        >
          {texts.loading}
        </Typography>
      ) : null}

      <FeatureFlag
        flag="globalBilling"
        renderOn={
          <BillingInfoButton
            id={organization.id}
            hasuraId={organization.hasura_id}
            name={organization.name}
            role={membership.role}
            status={error ? null : organization.provisioning_status}
          />
        }
      />
    </StyledCard>
  );
});
