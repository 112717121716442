import * as Sentry from "@sentry/react";
import React, { type FC, StrictMode } from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";

import { AppLocalStorageVariablesProvider } from "./app-local-storage-variables-provider";
import { AppQueryProvider } from "./app-query-provider";
import { AppThemeProvider } from "./app-theme-provider";
import { GlobalApp } from "./apps/global";
import { OrganizationApp } from "./apps/organization";
import { IS_DEV } from "./apps/organization/pages/consts";
import { PageEventAnalytics } from "./apps/shared/analytics";
import { AlertsProvider } from "./apps/shared/components/alerts-provider";
import { AppGlobalStyles } from "./apps/shared/components/global-styles";
import { RecoilOutsideComponent } from "./apps/shared/components/recoil-outside-hooks";
import { ENV } from "./apps/shared/env";
import { ErrorBoundaryProvider } from "./apps/shared/hocs/error-boundary";
import { FeatureFlag } from "./apps/shared/utils/feature-flag";

const AppWithProviders: FC = () => (
  <RecoilRoot>
    <RecoilOutsideComponent />
    <BrowserRouter>
      <AppQueryProvider>
        <AppLocalStorageVariablesProvider>
          <AppThemeProvider>
            <ErrorBoundaryProvider error={null}>
              <AppGlobalStyles />
              <PageEventAnalytics />
              <AlertsProvider>
                {ENV.VITE_APP === "global" ? (
                  <GlobalApp />
                ) : (
                  <OrganizationApp />
                )}
              </AlertsProvider>
              {IS_DEV ? <ReactQueryDevtools position="bottom-right" /> : null}
            </ErrorBoundaryProvider>
          </AppThemeProvider>
        </AppLocalStorageVariablesProvider>
      </AppQueryProvider>
    </BrowserRouter>
  </RecoilRoot>
);

const AppWithoutSentry: FC = () => (
  <FeatureFlag
    flag="reactStrictMode"
    renderOn={
      <StrictMode>
        <AppWithProviders />
      </StrictMode>
    }
    renderOff={<AppWithProviders />}
  />
);

export const App = Sentry.withProfiler(AppWithoutSentry, {
  name: "FluxNinja UI",
  disabled: !ENV.SENTRY_DSN,
});
