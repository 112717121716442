import { Typography } from "@mui/material";
import React, { type FC } from "react";

import { Container } from "./styled";
import type { ListItemProps } from "./types";

export const Name: FC<ListItemProps> = ({
  item: {
    uiData: { name },
  },
}) => {
  if (!name) {
    return null;
  }

  return (
    <Container>
      <Typography sx={{ textTransform: "lowercase", textAlign: "left" }}>
        {name}
      </Typography>
    </Container>
  );
};
