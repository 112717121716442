import { Box, type BoxProps } from "@mui/material";
import { styled } from "@mui/system";
import React, { type FC } from "react";

const BoxStyled = styled(Box)(({ theme }) => ({
  backgroundColor: "#f5f5f5",
  border: `1px solid #e0e0e0`,
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(2),
  margin: theme.spacing(2),
}));

export const SignInInfoBox: FC<BoxProps> = ({ children }) => (
  <BoxStyled width="100%">{children}</BoxStyled>
);
