import { gql, type Variables } from "graphql-request";

import type { AgentsGroupsQuery } from "#shared/generated/graphql";
import { gqlRequest } from "#shared/utils";

export const agentGroupsQuery = gql`
  query AgentsGroups($distinctOn: [AgentSelectColumn!]) {
    agents(distinctOn: $distinctOn) {
      edges {
        node {
          agentGroup
        }
      }
    }
  }
`;

export const fetchAgentGroups = async () => {
  const query = await gqlRequest<AgentsGroupsQuery, Variables>(
    agentGroupsQuery,
    {
      distinctOn: ["agentGroup"],
    },
  );

  return query.agents.edges.map((edge) => edge.node.agentGroup);
};
