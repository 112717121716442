import { gql } from "graphql-request";
import { useRecoilValue } from "recoil";

import type { GetUserGroupPerMembershipQuery } from "#shared/generated/graphql";
import { userNewState } from "#shared/recoil";
import { useGqlQuery } from "#shared/utils";

import { QUERY_KEYS } from "#organization/pages/consts";

export const UserGroupsQuery = gql`
  query GetUserGroupPerMembership(
    $yours: UserGroupBoolExp
    $others: UserGroupBoolExp
  ) {
    yours: userGroups(where: $yours) {
      edges {
        node {
          id
          name
          userGroupMemberships {
            totalCount
          }
        }
      }
    }
    others: userGroups(where: $others) {
      edges {
        node {
          id
          name
          userGroupMemberships {
            totalCount
          }
        }
      }
    }
  }
`;

const getUserGroupQueryVariables = (userId: string | null) => {
  const userGroupMemberships = {
    userGroupMemberships: {
      user: {
        id: { eq: userId },
      },
    },
  };

  return {
    variables: {
      yours: userGroupMemberships,
      others: { not: userGroupMemberships },
    },
  };
};

export const useUserGroupsQuery = () => {
  const { data: user } = useRecoilValue(userNewState.state);
  const { variables } = getUserGroupQueryVariables(user?.id || null);

  const { isLoading, isError, refetch, data } =
    useGqlQuery<GetUserGroupPerMembershipQuery>(
      [QUERY_KEYS.USER_GROUP_PER_MEMBERSHIP, variables],
      UserGroupsQuery,
      variables,
    );

  return {
    loading: isLoading,
    error: !user?.id || isError,
    yourUserGroups: data?.yours.edges || [],
    otherUserGroups: data?.others.edges || [],
    refetch,
  };
};
