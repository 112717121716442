import { pick } from "lodash";
import { useCallback, useState, useMemo } from "react";
import { useRecoilState } from "recoil";

import { API_SERVICE_URL } from "#shared/consts";
import { httpClient } from "#shared/utils/http-client";

import { organizationState } from "./state";
import type { OrganizationBody } from "./types";

export function useGetOrganization(domainName: string | null) {
  const [error, setError] = useState<null | { message: string }>(null);
  const [isIdle, setIsIdle] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [, setOrgState] = useRecoilState(organizationState);

  const getOrganization = useCallback(async () => {
    try {
      setIsIdle(false);
      setIsLoading(true);
      setIsSuccess(false);
      setError(null);

      if (!domainName) {
        throw new Error("Missing domain name.");
      }

      const res = await getOrganizationInformation(domainName);
      const body = await res.json();

      if (res.ok) {
        setIsSuccess(true);

        setOrgState({
          ...pick(body, "name", "id", "onboarded", "idpTenantID"),
          subdomain: domainName,
        });
      } else {
        setIsSuccess(false);
      }
    } catch (err) {
      setError({ message: err instanceof Error ? err.message : String(err) });
    }

    setIsLoading(false);
  }, [domainName, setOrgState]);

  return useMemo(
    () => ({ getOrganization, error, isLoading, isSuccess, isIdle }),
    [getOrganization, error, isLoading, isSuccess, isIdle],
  );
}

async function getOrganizationInformation(subdomain: string) {
  return httpClient.get<OrganizationBody>({
    url: `${API_SERVICE_URL}/organization/${subdomain}`,
  });
}
