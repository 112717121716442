import { styled } from "@mui/material";
import Button from "@mui/material/Button";

import type { FnTheme } from "../../../../../app-theme-provider/types";

export const FormActionButton = styled(Button)((props) => {
  const theme = props.theme as unknown as FnTheme;

  return {
    backgroundColor: theme.palette.form.actionButton.background,
    color: theme.palette.form.actionButton.color,
  };
});
