import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { useTheme, Paper, IconButton } from "@mui/material";
import React, {
  type FC,
  useContext,
  useMemo,
  useEffect,
  useState,
} from "react";
import { type Node, useReactFlow, type Edge } from "react-flow-renderer";

import type { AnyObject, Texts } from "#shared/types";

import { FlyoutMenu } from "#organization/components/flyout-menu";

import { useCircuitContext } from "./circuit-context";
import {
  TopBar,
  PolicyCircuit,
  SearchBar,
  SearchBoxInnerContainer,
  CircuitFlyoutMenuTabs,
} from "./components";

import type { FnTheme } from "../../../../../../../../app-theme-provider/types";
import {
  PolicyResourceDashboardPage,
  type PolicyResourceDashboardPageProps,
  GridView,
  GridViewArea,
  GridViewAreaTitle,
  type GridViewAreaTitleProps,
} from "../../components";
import { useFlowChartState } from "../../config-flow-chart";
import { useDashboardQueryParams, usePolicyQueryParams } from "../../hooks";
import { SelectedResourcesContext } from "../../selected-resource-context";
import { type PolicyResourceType, policyResourceEnTexts } from "../consts";

export interface CircuitPageProps {
  policyId: string;
  width: number | null | undefined;
  texts?: Texts<Text>;
}

export type Text =
  | "closeModalLabel"
  | Extract<keyof PolicyResourceDashboardPageProps["texts"], "select">
  | PolicyResourceType
  | "Dashboards";

const enTexts: Required<CircuitPageProps["texts"]> = {
  closeModalLabel: "Close modal",
  select: "Select either component or signal.",
  Dashboards: "Dashboard",
  ...policyResourceEnTexts,
};

const CIRCUIT_CONTAINER_ID = ["fn-policy-circuit", "container"].join("-");

export const CircuitPage: FC<CircuitPageProps> = ({
  policyId,
  texts = enTexts,
}) => {
  const { spacing, shape } = useTheme() as FnTheme;

  const { isFlyoutMenuVisible, setIsFlyoutMenuVisible } = useCircuitContext();

  const {
    resetSelectedResources,
    selectedCircuitResources,
    isCircuitResourceSelected,
  } = useContext(SelectedResourcesContext);

  const circuitFlow = useReactFlow();
  const { flowChart, policy } = useFlowChartState({
    policyId,
  });

  // TODO: Let's unselect only selected edges
  const unselectEdges = useMemo(
    () => () => {
      circuitFlow.setEdges(
        circuitFlow.getEdges().map(updateObject<Edge>({ selected: false })),
      );
    },
    [circuitFlow],
  );

  useEffect(() => {
    setIsFlyoutMenuVisible(isCircuitResourceSelected);
  }, [isCircuitResourceSelected, setIsFlyoutMenuVisible]);

  // TODO: Let's unselect only selected nodes
  const unselectNodes = useMemo(
    () => () => {
      circuitFlow.setNodes(
        circuitFlow.getNodes().map(updateObject<Node>({ selected: false })),
      );
    },
    [circuitFlow],
  );

  const {
    policySearchParams,
    policySearchParams: { resourceType, resourceId, ...otherParams },
    setPolicySearchParams,
  } = usePolicyQueryParams();

  const queryParams = useDashboardQueryParams({
    resourceType,
    ...otherParams,
    resourceId,
    policy,
  });

  const dashboardProps: PolicyResourceDashboardPageProps = useMemo(
    () => ({
      texts,
      policy,
      queryParams: {
        ...queryParams,
        queryParams: {
          ...queryParams.queryParams,
          ...policySearchParams,
        },
      },
    }),
    [texts, policy, queryParams, policySearchParams],
  );

  const closeFlyoutMenu = () => {
    const [type, resources] = selectedCircuitResources || [];

    if (!type || !resources) {
      return;
    }

    if (type === "Signal") {
      unselectEdges();
    } else {
      unselectNodes();
    }

    resetSelectedResources();

    setIsFlyoutMenuVisible(false);
  };

  const dashboard = useMemo(
    () =>
      isCircuitResourceSelected ? (
        <PolicyResourceDashboardPage {...dashboardProps} />
      ) : null,
    [isCircuitResourceSelected, dashboardProps],
  );

  const [type, [selectedDashboard] = []] = selectedCircuitResources || [];

  const title: GridViewAreaTitleProps["texts"] = useMemo(
    () =>
      [
        [
          selectedDashboard?.uiData?.name,
          selectedDashboard?.uiData?.componentType,
        ].join(":"),
      ].filter(Boolean),
    [selectedDashboard],
  );

  const [snapToTop, setSnapToTop] = useState(false);

  return (
    <>
      <GridView snapToTop={snapToTop}>
        <GridViewArea
          id={CIRCUIT_CONTAINER_ID}
          Title={null}
          containerProps={{
            component: Paper,
            sx: {
              padding: 0,
              minHeight: "73vh",
            },
          }}
          innerContainerProps={{ sx: { overflowY: "hidden" } }}
          contentContainerProps={{ sx: { paddingRight: 0 } }}
        >
          {policy && (
            <>
              <TopBar
                px={1}
                sx={{
                  top: spacing(shape.circuitPaddingSpacing),
                  right: spacing(shape.circuitPaddingSpacing),
                }}
              >
                <SearchBar
                  {...{
                    policy,
                    flowChart,
                    Container: SearchBoxInnerContainer,
                  }}
                />

                <IconButton
                  onClick={() => setSnapToTop(!snapToTop)}
                  sx={(theme) => ({
                    color: theme.palette.text.primary,
                  })}
                >
                  {!snapToTop ? <FullscreenIcon /> : <FullscreenExitIcon />}
                </IconButton>
              </TopBar>
              <PolicyCircuit {...{ policy, flowChart }} />
            </>
          )}
        </GridViewArea>
      </GridView>
      <FlyoutMenu useShowHide={[isFlyoutMenuVisible, closeFlyoutMenu]}>
        <CircuitFlyoutMenuTabs
          selectedCircuitResource={
            type === "Signal" ? undefined : selectedDashboard?.uiData?.component
          }
          {...{
            dashboard,
            policyName: policy?.name || null,
          }}
          title={<GridViewAreaTitle texts={title} />}
          hideFlyoutMenu={() => {
            setPolicySearchParams("");
            setIsFlyoutMenuVisible(false);
          }}
        />
      </FlyoutMenu>
    </>
  );
};

function updateObject<O extends AnyObject>(partialObject: Partial<O>) {
  return (object: O): O => ({ ...object, ...partialObject });
}
