import { styled, Typography } from "@mui/material";

export const DialogContentTextWrapper = styled("span")(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  color: theme.palette.text.primary,
}));

export const ErrorMessageWrapper = styled(Typography)(
  ({ theme: { spacing, palette } }) => ({
    marginRight: spacing(1),
    display: "flex",
    justifyContent: "flex-start",
    gap: 8,
    alignItems: "center",
    color: palette.warning.main,
  }),
);
