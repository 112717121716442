import { Alert, Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState, type FC } from "react";
import { useRecoilValue } from "recoil";

import { PrimaryButton } from "#shared/components/buttons";
import { useControlDialog } from "#shared/components/layouts/dialog";
import { userNewState } from "#shared/recoil";

import { TopBarBreadcrumbAppender } from "#organization/recoil/breadcrumbs";
import { useOrganizationState } from "#organization/recoil/organization";

import { CloseAccountDialog } from "./components/close-account-dialog";
import { TransferOwnershipDialog } from "./components/transfer-ownership-dialog";
import { useOrganizationOwners } from "./hooks";

import { SectionHeader } from "../../components/section-header";
import { SettingsButtons } from "../../components/settings-buttons";
import { SectionWrapper } from "../../components/styled";

export const CloseAccountSettingsPage: FC = () => {
  const dialogCloseAccount = useControlDialog();
  const dialogTransferOwnership = useControlDialog();
  const ownersList = useOrganizationOwners();
  const organization = useOrganizationState();

  const { data: user } = useRecoilValue(userNewState.state);

  const [isOwner, setIsOwner] = useState<boolean>(true);

  // TODO: Fix console errors
  useEffect(() => {
    if (user?.organization?.role !== "owner") {
      setIsOwner(false);
    }
  }, [user?.organization?.role]);

  const amountOfOwners: number | undefined = ownersList.total;

  return (
    <>
      <TopBarBreadcrumbAppender>Close Account</TopBarBreadcrumbAppender>
      <SettingsButtons disabled />
      <SectionWrapper>
        <SectionHeader title="Close The Account" />
        <Box
          sx={{
            display: "grid",
            py: 5,
            gridTemplateColumns: "1fr",
          }}
        >
          <Typography
            sx={{
              pb: 3,
            }}
          >
            Performing this action will permanently remove all associated data
            for your user.
          </Typography>
          <Alert variant="outlined" severity="error">
            Closing your account is permanent and cannot be undone!
          </Alert>
          {isOwner && (
            <>
              <Alert sx={{ mt: 2 }} variant="outlined" severity="error">
                You cannot close your account without deleting an organization
                that you are the only owner of.
                <br />
                Boxes which can&apos;t be unchecked mean that you are the only
                organization owner. You can transfer the organization&apos;s
                ownership to some other user before you delete your account.
                <br />
                Otherwise the organization and all associated data will also be
                deleted.
              </Alert>
              <Box
                sx={{
                  mt: 4,
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography sx={{ fontWeight: 700 }}>
                    {organization.name}
                    <Typography component="span" sx={{ fontWeight: 400 }}>
                      {" "}
                      ({organization.subdomain})
                    </Typography>
                  </Typography>
                </Box>
                <Box>
                  {amountOfOwners === 1 ? (
                    <PrimaryButton onClick={dialogTransferOwnership.open}>
                      Transfer the ownership
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton onClick={dialogTransferOwnership.open}>
                      See owners
                    </PrimaryButton>
                  )}
                </Box>
              </Box>
            </>
          )}
          <Box
            sx={{
              mt: 4,
            }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={dialogCloseAccount.open}
            >
              {amountOfOwners === 1
                ? "Close the account and delete organizations"
                : "Close account"}
            </Button>
          </Box>
        </Box>
      </SectionWrapper>

      <CloseAccountDialog dialogControlCloseAccount={dialogCloseAccount} />
      <TransferOwnershipDialog
        dialogControlTransferOwnership={dialogTransferOwnership}
      />
    </>
  );
};
