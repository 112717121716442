import { Box } from "@mui/material";
import { get } from "lodash";
import React, { useMemo, type FC } from "react";
import { useRecoilState } from "recoil";

import { ErrorLayout, LoaderLayout } from "#shared/components/layouts";

import { useHeaderSectionUpdate } from "#organization/recoil/organization/header-section";
import { projectContextState } from "#organization/recoil/project";

import {
  ChooseBlueprint,
  CreateOrEditPolicyHeader,
  CustomizeBlueprint,
  ReviewAndApply,
} from "./components";
import { ActionBar } from "./components/customize-blueprint/action-bar";
import { defaultSteps } from "./consts";
import { CreatePolicyContextProvider } from "./context";
import {
  useStepChange,
  type HandleNext,
  useCreatePolicySearchParams,
  useCloudControllerVersion,
} from "./hooks";
import { normalizeVersion } from "./utils";

import { usePolicies } from "../hooks";
import { CREATE_POLICY_ROUTE } from "../routes.definitions";

/**
 *
 * This page creates a new policy using FN blueprint.
 */
export const CreatePolicyPage: FC = () => {
  useHeaderSectionUpdate({
    pageTitle: useMemo(
      () => (
        <CreateOrEditPolicyHeader
          texts={{ title: CREATE_POLICY_ROUTE.TITLE }}
        />
      ),
      [],
    ),
  });

  const {
    value: activeStep,
    handleNext,
    handleBack,
  } = useStepChange(defaultSteps);

  return (
    <CreatePolicyContextProvider>
      <Box px={3} pb={3}>
        <StepDecider
          {...{
            activeStep,
            handleNext,
            handleBack,
          }}
        />
        {activeStep < 1 && (
          <ActionBar
            {...{
              handleBack,
              activeStep,
              totalSteps: defaultSteps.length,
              backButtonOnly: true,
              handleNext,
            }}
          />
        )}
      </Box>
    </CreatePolicyContextProvider>
  );
};

export interface StepDeciderProps {
  activeStep: number;
  handleNext: HandleNext;
  handleBack: () => void;
}

export const StepDecider: FC<StepDeciderProps> = ({
  activeStep = 0,
  handleNext,
  handleBack,
}) => {
  const {
    searchInfo: { blueprintPath },
  } = useCreatePolicySearchParams();

  const {
    isLoading: isCloudControllerVersionLoading,
    data: cloudControllerVersion,
    isError: isCloudControllerVersionError,
  } = useCloudControllerVersion();

  const [projectContext] = useRecoilState(projectContextState);
  const {
    data: policyData,
    isLoading: isPolicyLoading,
    isError: isPolicyError,
  } = usePolicies(projectContext.id);
  const policyNames = useMemo(
    () =>
      policyData?.policiesWithDefinition.edges.map((edge) => edge.node.name) ||
      [],
    [policyData?.policiesWithDefinition.edges],
  );

  const { version, commitSha } = get(
    cloudControllerVersion,
    "controllers.edges.0.node",
    {
      version: "",
      commitSha: "",
    },
  );

  const normalizedVersion = useMemo(() => normalizeVersion(version), [version]);

  if (
    (isCloudControllerVersionLoading && !isCloudControllerVersionError) ||
    (isPolicyLoading && !isPolicyError)
  ) {
    return <LoaderLayout />;
  }

  if (isCloudControllerVersionError || isPolicyError) {
    return <ErrorLayout />;
  }

  const allSteps: JSX.Element[] = [
    /** First blueprint selection step: 0 */
    <ChooseBlueprint
      {...{
        handleNext,
        version: normalizedVersion,
        commitSha,
      }}
    />,
    /** Third blueprint customization step: 1 */
    <CustomizeBlueprint
      {...{
        blueprintPath,
        controllerVersion: normalizedVersion,
        controllerCommitSha: commitSha,
        actionBarProps: {
          handleNext,
          activeStep,
          handleBack,
          totalSteps: defaultSteps.length,
        },
        disallowPolicyNames: policyNames,
      }}
    />,
    /** Fourth blueprint review step and apply: 1 */
    <ReviewAndApply
      {...{
        actionBarProps: {
          handleNext,
          activeStep,
          handleBack,
          totalSteps: defaultSteps.length,
        },
      }}
    />,
  ];

  return allSteps[activeStep];
};
