import {
  type Dispatch,
  type SetStateAction,
  type SyntheticEvent,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

export type OnTabChange<TabValue extends string> = (
  _: SyntheticEvent,
  tabValue: TabValue,
) => void;

export type UseTabNavigation<TabValue extends string> = {
  onTabsChange: OnTabChange<TabValue>;
  currentTab: TabValue;
  setCurrentTab: Dispatch<SetStateAction<TabValue>>;
};

export const useTabNavigation = <TabValue extends string>(
  tabs: TabValue[],
  initialTabValue: TabValue,
  onChange?: OnTabChange<TabValue>,
): UseTabNavigation<TabValue> => {
  const [currentTab, setCurrentTab] = useState<TabValue>(initialTabValue);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const activeTabValue = useFindActiveTab(tabs);

  useEffect(() => {
    if (activeTabValue) {
      if (activeTabValue !== currentTab) {
        setCurrentTab(activeTabValue);
      }

      return;
    }

    setCurrentTab(initialTabValue);
    navigate(initialTabValue);
  }, [pathname, navigate, tabs, initialTabValue, activeTabValue, currentTab]);

  const onTabsChange: OnTabChange<TabValue> = useCallback(
    (_, newValue) => {
      if (onChange) {
        onChange(_, newValue);

        return;
      }

      setCurrentTab(newValue);
      navigate(newValue);
    },
    [setCurrentTab, navigate, onChange],
  );

  return { onTabsChange, currentTab, setCurrentTab };
};

function useFindActiveTab<TabValue extends string>(tabs: TabValue[]) {
  const { pathname } = useLocation();

  return useMemo(
    () => tabs.find((tabPathname) => pathname.includes(tabPathname)),
    [tabs, pathname],
  );
}
