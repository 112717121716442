import { hoursToMilliseconds } from "date-fns";
import { gql } from "graphql-request";
import { noop } from "lodash";
import { useMemo } from "react";

import type {
  ControlPointsKubernetesGroupsTitlesQuery,
  ControlPointsKubernetesGroupDataQuery,
} from "#shared/generated/graphql";

import {
  useGridData,
  useGroupData,
  useGroupsTitles,
  type DataGridHook,
  type GroupDataHook,
  type GroupsTitlesHook,
} from "#organization/hooks";
import type { GqlResponseEdgeMapped } from "#organization/utils";

export type ControlPointsKubernetesGroupBy = "name";

const CONTROL_POINTS_KUBERNETES_ENTITY_KEY = "kubernetesControlPoints";

export const controlPointsKubernetesGroupsTitles = gql`
  query ControlPointsKubernetesGroupsTitles(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: KubernetesControlPointBoolExp
    $distinctOn: [KubernetesControlPointSelectColumn!]
  ) {
    kubernetesControlPoints(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      distinctOn: $distinctOn
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          name
          kind
          namespace
          apiVersion
          status
          controller {
            name
          }
          agent {
            agentGroup
          }
          lastSeen
        }
      }
    }
  }
`;

type ControlPointsKubernetesGroupsTitleData =
  ControlPointsKubernetesGroupsTitlesQuery["kubernetesControlPoints"]["edges"][0]["node"];

export const useControlPointsKubernetesGroupsTitles: GroupsTitlesHook<
  ControlPointsKubernetesGroupBy
> = (pageSize, page, setPage, groupByKey, filterVariables) =>
  useGroupsTitles<
    ControlPointsKubernetesGroupBy,
    ControlPointsKubernetesGroupsTitleData
  >(
    CONTROL_POINTS_KUBERNETES_ENTITY_KEY,
    pageSize,
    page,
    setPage,
    groupByKey,
    controlPointsKubernetesGroupsTitles,
    (node) => node[groupByKey!],
    filterVariables,
  );

type ControlPointsKubernetesGroupData =
  ControlPointsKubernetesGroupDataQuery["kubernetesControlPoints"]["edges"][0]["node"];

export type AdaptedControlPointsKubernetesGroupData =
  ControlPointsKubernetesGroupData & { alerts: string; policies: number };

export const controlPointsKubernetesGroupData = gql`
  query ControlPointsKubernetesGroupData(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: KubernetesControlPointBoolExp
    $distinctOn: [KubernetesControlPointSelectColumn!]
    $orderBy: [KubernetesControlPointOrderBy!]
  ) {
    kubernetesControlPoints(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      distinctOn: $distinctOn
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          name
          kind
          namespace
          apiVersion
          status
          controller {
            name
          }
          agent {
            agentGroup
          }
          lastSeen
          discoveredOn
        }
      }
    }
  }
`;

export const useControlPointsKubernetesGroupData: GroupDataHook<
  ControlPointsKubernetesGroupBy
> = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  groupByKey,
  groupByValue,
  filterVariables,
) =>
  useGroupData<
    ControlPointsKubernetesGroupBy,
    ControlPointsKubernetesGroupData,
    AdaptedControlPointsKubernetesGroupData
  >(
    CONTROL_POINTS_KUBERNETES_ENTITY_KEY,
    controlPointsKubernetesGroupData,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    groupByKey,
    groupByValue,
    (kubernetesControlPoints: ControlPointsKubernetesGroupData) => ({
      ...kubernetesControlPoints,
      alerts: "N/A",
      policies: 0,
    }),
    filterVariables,
  );

export const useControlPointsKubernetesData: DataGridHook = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  enabled,
  filterVariables,
) =>
  useGridData<
    ControlPointsKubernetesGroupData,
    AdaptedControlPointsKubernetesGroupData
  >(
    CONTROL_POINTS_KUBERNETES_ENTITY_KEY,
    controlPointsKubernetesGroupData,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    filterVariables,
    (kubernetesControlPoints: ControlPointsKubernetesGroupData) => ({
      ...kubernetesControlPoints,
      alerts: "N/A",
      policies: 0,
    }),
    enabled,
  );

export const useControlPointsKubernetes = () => {
  const cpKubeData = useControlPointsKubernetesData(
    5,
    1,
    noop,
    "asc",
    "discoveredOn",
    true,
    {},
  );

  const policyData = cpKubeData.query
    .data as GqlResponseEdgeMapped<AdaptedControlPointsKubernetesGroupData> | null;
  const isEmpty =
    !cpKubeData.query.isFetching &&
    !cpKubeData.query.isError &&
    policyData?.edges?.length === 0;

  const justInstalled = useMemo(() => {
    if (!cpKubeData.query.isFetching && !cpKubeData.query.isError) {
      const currentDate = new Date();

      return (
        !isEmpty &&
        policyData?.edges?.every(
          (edge) =>
            currentDate.getTime() - new Date(edge.discoveredOn).getTime() <
            hoursToMilliseconds(1),
        )
      );
    }

    return false;
  }, [
    isEmpty,
    policyData?.edges,
    cpKubeData.query.isError,
    cpKubeData.query.isFetching,
  ]);

  return { isEmpty, justInstalled, query: cpKubeData.query };
};
