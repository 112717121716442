import { ORGANIZATION_SETTINGS_ROUTE } from "../routes.definitions";

export const PROJECTS_SETTINGS_ROUTE = {
  TITLE: "Projects",
  KEY: "projects",
  ABSOLUTE_PATH: `${ORGANIZATION_SETTINGS_ROUTE.ABSOLUTE_PATH}/projects`,
  PARENT_PATH: "projects/*",
  CHILD_KEY: "projectId",
  CHILD_PATH: ":projectId",
};

export type ProjectIdParam = {
  projectId: string;
};
