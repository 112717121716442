import { Box, Typography } from "@mui/material";
import { isEqual } from "lodash";
import React, {
  type FC,
  type ComponentType,
  useContext,
  useCallback,
  type MouseEvent,
} from "react";
import type { NodeProps } from "react-flow-renderer";

import { NestedCircuitIcon } from "./assets/nested-circuit-icon";
import { ConstantNode } from "./constant-node";
import { CustomHandles } from "./custom-handles";
import { useFindHeight } from "./node-dimensions";
import { NodeInfoTooltip, type NodeInfoTooltipProps } from "./node-info";
import { NodeStyled } from "./style-nodes-edges";
import {
  NodeDetailsWrapper,
  NodeNameWrapper,
  type NodeDetailWrapperProps,
} from "./styled";

import type { LimiterNodeName } from "../../../../../../../../types";
import { PolicyUtils } from "../../policy-utils";
import { SelectedResourcesContext } from "../../selected-resource-context";
import { NestedCircuitButton } from "../../tabs/circuit/components/nested-circuit-button";
import type { CircuitComponent } from "../../types";

export type CustomNodeProps = NodeProps<
  CircuitComponent & { hasChildren: boolean }
>;

const CustomNode: FC<CustomNodeProps> = ({ data }) => {
  const {
    inPorts,
    outPorts,
    componentId,
    componentType,
    uiData,
    hasChildren,
    componentDescription,
    componentName,
  } = data;

  const { selectedResources, selectCircuitResources } = useContext(
    SelectedResourcesContext,
  );

  const componentHeight = useFindHeight(
    { componentDescription, componentType },
    inPorts?.length,
    outPorts?.length,
  );

  const onClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();

      if (!data) {
        return;
      }

      const modifiedData = {
        ...data,
        componentName,
      };

      const isNodeWithDashboard = PolicyUtils.isNodeWithDashboard(modifiedData);

      const selectedNodes = [modifiedData];

      if (
        isNodeWithDashboard &&
        !isEqual(
          selectedResources[componentName as LimiterNodeName],
          selectedNodes,
        )
      ) {
        selectCircuitResources(componentName as LimiterNodeName, selectedNodes);
      }
    },
    [data, selectedResources, componentName, selectCircuitResources],
  );

  const { style } = uiData;

  if (componentId.includes("FakeConstant")) {
    return (
      <ConstantNode
        {...{
          data,
          styles: {
            fill: style?.backgroundColor,
          },
        }}
      />
    );
  }

  return (
    <NodeStyled
      sx={{
        backgroundColor: uiData?.style?.backgroundColor,
        flexDirection:
          componentDescription?.toLowerCase() === "mul"
            ? "column"
            : "column-reverse",
        position: "relative",
        height: componentHeight,
        minWidth: 120,
      }}
      onClick={onClick}
    >
      {hasChildren && (
        <NestedCircuitButton
          componentID={componentId}
          componentName={uiData.componentName}
          iconProps={{
            strokeColor: uiData?.style?.color,
          }}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        />
      )}
      <CustomHandles
        {...{
          componentDescription,
          componentId,
          inPorts,
          outPorts,
          componentType,
          handleStyles: style || {},
        }}
      />
      {componentDescription.length > 0 &&
        componentType.toLowerCase() !== "source" && (
          <NodeDetails
            {...{
              componentDescription,
              componentColor: style?.color || "",
            }}
          />
        )}

      <NodeNameWithIcon
        {...{
          color: style?.color || "",
          name: uiData.name,
          hasChildren,
          componentDescription,
          componentType,
          componentName,
        }}
      />
    </NodeStyled>
  );
};

export const CommonNode: ComponentType<NodeProps> = CustomNode;

export interface NodeNameWithIconProps extends NodeInfoTooltipProps {
  color: string;
  name: string;
  hasChildren: boolean;
}

export const NodeNameWithIcon: FC<NodeNameWithIconProps> = ({
  color,
  name,
  hasChildren,
  ...other
}) => (
  <Box
    {...{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 1,
      padding: 1,
    }}
  >
    <NodeNameWrapper componentColor={color}>
      {name}
      <NodeInfoTooltip {...other} />
    </NodeNameWrapper>
    {hasChildren && <NestedCircuitIcon color={color} />}
  </Box>
);

export interface NodeDetailsProps extends NodeDetailWrapperProps {
  componentDescription: string;
}

export const NodeDetails: FC<NodeDetailsProps> = ({
  componentDescription,
  componentColor,
}) => (
  <NodeDetailsWrapper componentColor={componentColor}>
    <Typography
      {...{
        fontSize: 12,
        fontWeight: 400,
        color: componentColor,
      }}
    >
      {componentDescription}
    </Typography>
  </NodeDetailsWrapper>
);
