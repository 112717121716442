import { paperClasses, type ThemeOptions } from "@mui/material";

import type { FnTheme } from "../../types";

const styleOverrides: Required<
  Required<ThemeOptions>["components"]
>["MuiPopover"]["styleOverrides"] = {
  root: (props) => {
    const { zIndex } = props.theme as unknown as FnTheme;

    return {
      boxShadow: `-3px 0px 30px rgba(80, 76, 73, 0.15)`,
      zIndex: zIndex.popover,
      [`.${paperClasses.root}`]: {
        top: 0,
        left: 0,
      },
    };
  },
};

export const MuiPopover: Required<
  Required<ThemeOptions>["components"]
>["MuiPopover"] = { styleOverrides };
