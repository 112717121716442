import { TablePagination } from "@mui/material";
import React, {
  type ChangeEvent,
  type FC,
  type MouseEvent,
  useState,
} from "react";

export const useDataGridPagination = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (
    _event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  return {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
  };
};

interface DataGridPaginationProps {
  page: number;
  rowsPerPage: number;
  length: number | undefined;
  handleChangeRowsPerPage: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  handleChangePage: (
    _event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void;
}

export const DataGridPagination: FC<DataGridPaginationProps> = ({
  page,
  rowsPerPage,
  length,
  handleChangeRowsPerPage,
  handleChangePage,
}) => {
  if (length === undefined || length === 0 || length <= 10) {
    return null;
  }

  return (
    <TablePagination
      component="div"
      count={length || 0}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};
