import { TableCell, TableHead } from "@mui/material";
import React, { type MouseEvent, type PropsWithChildren } from "react";

import { HeaderRow, HeaderTypography, SortArrow } from "./styled";
import type { DataGridVariants, HeadCell, Order } from "./types";
import { useTableHeadStickyCSS } from "./utils";

interface DataGridHeaderProps<T> {
  onRequestSort: (event: MouseEvent, property: string) => void;
  order: Order;
  orderBy: string;
  headers: HeadCell<T>[];
  variant: DataGridVariants;
}

// eslint-disable-next-line react/function-component-definition
export function DataGridHeader<T>(
  props: PropsWithChildren<DataGridHeaderProps<T>>,
) {
  const { order, orderBy, onRequestSort, headers, variant } = props;
  const { headCSS, tableHeadRef } = useTableHeadStickyCSS();

  const createSortHandler =
    (property: string, noSorting: boolean | undefined) =>
    (event: MouseEvent) => {
      if (!noSorting) {
        onRequestSort(event, property);
      }
    };

  const tableHeaderStyles = {
    pl: 4,
    borderColor: "",
    position: "relative",
    paddingLeft: 2,
    paddingRight: 2,
  };

  if (variant === "flow") {
    tableHeaderStyles.borderColor = "transparent";
  }

  if (variant === "primary") {
    tableHeaderStyles.borderColor = "";
  }

  return (
    <TableHead
      ref={tableHeadRef}
      sx={(theme) => ({
        ...headCSS,
        "& *:first-of-type": {
          borderRadius: theme.spacing(0.5, 0, 0, 0),
        },
        "& *:last-of-type": {
          borderRadius: theme.spacing(0, 0.5, 0, 0),
        },
      })}
    >
      <HeaderRow variant={variant}>
        {headers.map((headCell: HeadCell<T>) => (
          <TableCell
            key={headCell.key}
            align={headCell.align ? headCell.align : "left"}
            style={{ width: headCell.width ? headCell.width : "inherit" }}
            sx={tableHeaderStyles}
            sortDirection={orderBy === headCell.key ? order : false}
          >
            {headCell.noSorting ? (
              <HeaderTypography variant={variant}>
                {headCell.label}
              </HeaderTypography>
            ) : (
              <SortArrow
                variant={variant}
                active={orderBy === headCell.key}
                direction={orderBy === headCell.key ? order : "asc"}
                onClick={createSortHandler(headCell.key, headCell.noSorting)}
              >
                <HeaderTypography variant={variant}>
                  {headCell.label}
                </HeaderTypography>
              </SortArrow>
            )}
          </TableCell>
        ))}
      </HeaderRow>
    </TableHead>
  );
}
