import { Button } from "@mui/material";
import React, { type FC } from "react";

import type {
  GenerateAgentKeyInput,
  GenerateAgentKeyMutation,
  RevokeAgentKeyInput,
  RevokeAgentKeyMutation,
} from "#shared/generated/graphql";
import type { Texts } from "#shared/types";

import {
  ApiKeysPage as CommonApiKeyPage,
  type ApiKeysPageProps as CommonApiKeyProps,
} from "#organization/components/api-keys";
import { useUgMemberPermission } from "#organization/pages/authenticated/settings/account/hooks";
import { InfoButtonIcon } from "#organization/pages/authenticated/settings/account/user-api-keys";
import { BASE_DOCUMENTATION_URL, QUERY_KEYS } from "#organization/pages/consts";
import { useProjectContextState } from "#organization/recoil/project";

import {
  type AgentKeysGroupBy,
  useAgentKeysData,
  useAgentKeysGroupData,
  useAgentKeysGroupsTitles,
  AGENT_KEYS_ENTITY_KEY,
  type AdaptedAgentKeysGroupData,
  useGenerateAgentKey,
  useAgentRevokeKey,
} from "./hooks";
import { filters, headCells } from "./table";

export type ApiState = {
  id: string;
  key: string;
};

export interface AgentApiKeysPageProps {
  texts?: Texts<Text>;
}

type Text = "create" | "keyUsageLinkMessage";

const enTexts: Required<AgentApiKeysPageProps["texts"]> = {
  create: "Create API key",
  keyUsageLinkMessage: "API keys Usage",
};

export const AgentApiKeysPage: FC<AgentApiKeysPageProps> = ({
  texts = enTexts,
}) => {
  const currentProject = useProjectContextState();

  /* Members cannot create API keys if the user group does not have write permissions */
  const permissionLock = useUgMemberPermission();

  const agentApiKeyPageProps: CommonApiKeyProps<
    AdaptedAgentKeysGroupData,
    AgentKeysGroupBy,
    AgentKeysGroupBy,
    unknown,
    unknown,
    GenerateAgentKeyMutation,
    GenerateAgentKeyInput,
    RevokeAgentKeyMutation,
    RevokeAgentKeyInput
  > = {
    filters,
    headCells,
    useGridData: useAgentKeysData,
    useGroupData: useAgentKeysGroupData,
    useGroupsTitles: useAgentKeysGroupsTitles,
    refetchQueryKey: [QUERY_KEYS.DATA_GRID, AGENT_KEYS_ENTITY_KEY],
    permissionLock,
    generateApiKeyDialogProps: {
      useGenerateAPIKey: useGenerateAgentKey,
      generateKeyHelperFunc: {
        findId: (data: GenerateAgentKeyMutation) =>
          data?.generateAgentKey?.agentKey?.id || "",
        findKey: (data: GenerateAgentKeyMutation) =>
          data?.generateAgentKey?.agentKey?.key || "",
        mapToInput: () => ({
          project: currentProject.id,
          expiresAt: null,
        }),
      },
      useRevokeKey: useAgentRevokeKey,
      revokeKeyHelperFunc: {
        mapToInput: (data) => ({
          id: data.id,
        }),
      },
    },
    texts,
    extraInfoButton: (
      <Button
        {...{
          variant: "text",
          color: "inherit",
          endIcon: (
            <InfoButtonIcon
              sx={{
                height: "1rem",
                width: "1rem",
              }}
            />
          ),
          href: [BASE_DOCUMENTATION_URL, "get-started"].join("/"),
          target: "_blank",
        }}
      >
        {texts.keyUsageLinkMessage}
      </Button>
    ),
  };

  return <CommonApiKeyPage {...agentApiKeyPageProps} />;
};
