import { Box } from "@mui/material";
import React, { type FC, type PropsWithChildren } from "react";

export const GroupByButtonWrapper: FC<PropsWithChildren> = ({ children }) => (
  <Box
    sx={{
      gridArea: "group-by",
    }}
  >
    {children}
  </Box>
);
