# Delay Initial Fill

## Overview

The `delay_initial_fill` delays the initial filling of the bucket. By default, it is set to false.

## Example

If set to true, refilling will begin after `bucket_capacity`/`fill_amount` duration has elapsed.

## Note

This can potentially lead to more requests being sent than the specified limit during the first interval. When set to true, the token bucket will be given a chance to empty out before the filling starts.
