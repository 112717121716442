import { dagConnect, sugiyama, layeringSimplex, decrossOpt } from "d3-dag";
import { Position, type Edge, type Node } from "react-flow-renderer";

import type {
  ComponentView,
  Graph,
  Link,
  Maybe,
  Tree,
} from "#shared/generated/graphql";

import { findBoundaryDimensions } from "./circuit-boundary/hooks";

import {
  setupNodes,
  type SetupNodesPayload,
} from "../../../../config-flow-chart/setup-nodes-edges";
import type { CircuitComponent } from "../../../../types";

type Direction = "LR" | "TB" | "BT" | "RL";

const getPosition = (x: number, y: number, direction: Direction) => {
  switch (direction) {
    case "LR":
      return { x: y, y: x };
    case "RL":
      return { x: -y, y: -x };
    case "BT":
      return { x: -x, y: -y };
    default:
      return { x, y };
  }
};

const positionMap: Record<string, Position> = {
  T: Position.Top,
  L: Position.Left,
  R: Position.Right,
  B: Position.Bottom,
};

let width = 50;
let height = 50;

const layoutDag = sugiyama()
  .layering(layeringSimplex())
  .decross(decrossOpt())
  .nodeSize(() => [width, height]);

export const d3AutoLayoutDag = (
  nodes: Node[],
  edges: Edge[],
  circuitLabelStatus: boolean,
  direction: Direction = "LR",
) => {
  const create = dagConnect().decycle(true);
  const dag = create(edges.map((e) => [e.source, e.target]));

  width = !circuitLabelStatus ? 250 : 400;
  height = !circuitLabelStatus ? 350 : 500;

  layoutDag(dag);

  const newNodes: Array<Node> = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const node of dag) {
    const singleNode = nodes.find((n) => n.id === node.data.id);
    const newNode = {
      ...singleNode,
      targetPosition: positionMap[direction[0]],
      sourcePosition: positionMap[direction[1]],
      position: getPosition(node.x || 0, node.y || 0, direction),
    };

    newNodes.push(newNode as Node);
  }

  return newNodes;
};

export const d3AutoLayoutNodesOnly = (
  nodes: Node<CircuitComponent>[],
): Node<CircuitComponent>[] => {
  let x = -150;

  return nodes.map((n) => {
    x += 220;

    return {
      ...n,
      position: { x, y: 100 },
    };
  });
};

/**
 * This function change layout (x and y position) of external components.
 */
export const getExternalComponentsLayout = (
  externalComponents: Graph["externalComponents"],
  allNodes: Node<CircuitComponent>[],
  children: Maybe<readonly Maybe<Tree>[]>,
  externalLinks: Maybe<readonly Maybe<Link>[]>,
  zoom: number,
  payload: Pick<SetupNodesPayload, "actuators" | "palette">,
) => {
  if (!externalComponents?.length) return allNodes;

  const {
    left: startX,
    top: startY,
    width: boundaryWidth,
  } = findBoundaryDimensions(allNodes, zoom);

  const { actuators, palette } = payload;

  const externalNodes = setupNodes(
    (externalComponents as ComponentView[]).map(mapToAddHasChildren(children)),
    actuators,
    palette,
  );

  return [
    ...allNodes,
    ...externalNodes.map((n, count) => ({
      ...n,
      position: {
        ...n.position,
        y: startY + (n.height || 300) + count * 200,
        x: xPositionByLinks(n, externalLinks, boundaryWidth, startX),
      },
    })),
  ];
};

export const xPositionByLinks = (
  n: Node<CircuitComponent>,
  externalLinks: Maybe<readonly Maybe<Link>[]>,
  boundaryWidth: number,
  startX: number,
) => {
  if (externalLinks?.some((l) => l?.source?.componentId === n.id))
    return startX - 200;

  if (externalLinks?.some((l) => l?.target?.componentId === n.id))
    return startX + boundaryWidth + 400;

  return startX - 200;
};

export function mapToAddHasChildren(children: Maybe<readonly Maybe<Tree>[]>) {
  return (component: ComponentView) => ({
    ...component,
    hasChildren: children?.find(
      (child) => child?.node?.componentId === component.componentId,
    )?.hasChildren,
  });
}
