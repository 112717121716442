import { Box, type BoxProps } from "@mui/material";
import React, { type FC, type PropsWithChildren } from "react";

export type DataGridWrapperProps = PropsWithChildren<{
  boxProps?: Omit<BoxProps, "children">;
}>;

export const DataGridWrapper: FC<DataGridWrapperProps> = (props) => {
  const { children, boxProps } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      {...boxProps}
    >
      {children}
    </Box>
  );
};
