import { CodeBlockWithCopyButton } from "#organization/components"

export const language = "yaml";

# Service

By default, the `service` is set to 'any', since developers are not required to specify this particular field.

Services are not available when connecting SDKs directly to Aperture Cloud. You need to use Control points and Flow Labels to narrow down the selection criteria for rate limiting or request prioritization.

For Aperture Agents hosted in your infrastructure, Services can be used to further narrow down the selection criteria for rate limiting or request prioritization to specific service.
Note that service discovery is available only for self-hosted Aperture Agents.
