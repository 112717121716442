import { TextField } from "@mui/material";
import React, { useMemo } from "react";
import {
  Controller,
  useFormContext,
  type FieldValues,
  type Path,
} from "react-hook-form";

import type { Texts } from "#shared/types";

import { onChangeNumberField } from "./bucket-capacity-and-fill-amount";
import type { BlueprintFieldProps } from "./types";
import { createFieldNameWithPrefix } from "./utils";

import { FieldWithTitle, FormFieldWrapper } from "../../styled";
import { validateFieldWithRequiredSign } from "../consts";

type FieldLabelNames = "max_concurrency";

export declare type MaxConcurrencyProps<TFields extends FieldValues> = {
  texts?: Texts<FieldLabelNames>;
} & BlueprintFieldProps<TFields>;

const defaultInputTexts = {
  max_concurrency: "Max Concurrency",
};

export const MaxConcurrency = <TFields extends FieldValues>({
  texts = defaultInputTexts,
  fieldsPrefix = "policy.concurrency_limiter" as Path<TFields>,
}: MaxConcurrencyProps<TFields>) => {
  const { maxConcurrencyFieldName, defaultValues, control, setValue } =
    useMaxConcurrency<TFields>({ fieldsPrefix });

  return (
    <FormFieldWrapper>
      <FieldWithTitle
        fieldAddress={maxConcurrencyFieldName}
        label={texts.max_concurrency}
        required
      >
        <Controller
          control={control}
          defaultValue={defaultValues?.max_concurrency}
          rules={validateFieldWithRequiredSign<TFields>(
            "Max concurrency is required",
          )}
          name={maxConcurrencyFieldName}
          render={({ field, field: { onChange }, fieldState }) => (
            <TextField
              {...{
                ...field,
                value: `${field.value}`,
                onChange: onChangeNumberField(onChange, (v) => {
                  setValue(maxConcurrencyFieldName, v);
                }),
                placeholder: texts.max_concurrency,
                error: !!fieldState.error,
                helperText: <>{fieldState.error?.message}</>,
              }}
            />
          )}
        />
      </FieldWithTitle>
    </FormFieldWrapper>
  );
};

export const useMaxConcurrency = <TFields extends FieldValues>({
  fieldsPrefix = "policy.concurrency_limiter" as Path<TFields>,
}: Pick<MaxConcurrencyProps<TFields>, "fieldsPrefix">) => {
  const { control, setValue, getValues } = useFormContext<TFields>();

  const maxConcurrencyFieldName = useMemo(
    () => createFieldNameWithPrefix(fieldsPrefix, "max_concurrency"),
    [fieldsPrefix],
  );

  const defaultValues = getValues(fieldsPrefix);

  return {
    maxConcurrencyFieldName,
    defaultValues,
    control,
    setValue,
  };
};
