import {
  Alert,
  ClickAwayListener,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { pick } from "lodash";
import React, { type Dispatch, type FC, type SetStateAction } from "react";

import type { Texts } from "#shared/types";

import { globalTimeParser } from "#organization/utils";

import { SilentChannelForm } from "./silent-channel-form";
import { SilentChannelBoxStyled } from "./styled";

export interface SilentChannelBoxProps {
  id: string;
  openCloseHook: [boolean, Dispatch<SetStateAction<boolean>>];
  alertChannelName: string;
  silencedUntil: string | undefined;
  anchorEl: SVGSVGElement | null;
  texts?: SilentChannelBoxTexts;
}

type SilentChannelBoxTextsKeys = "title" | "subTitle" | "message";

export type SilentChannelBoxTexts = Texts<Required<SilentChannelBoxTextsKeys>>;

export const defaultTexts: SilentChannelBoxTexts = {
  title: `Mute channel`,
  subTitle: "Muting details for [channel]",
  message: "Channel is silent until",
};

export const replaceUtil = (
  replace: string,
  text: string,
  key: string = "[channel]",
) => text.replace(key, replace);

export const SilentChannelBox: FC<SilentChannelBoxProps> = (props) => {
  const {
    openCloseHook,
    alertChannelName,
    silencedUntil,
    anchorEl,
    texts = defaultTexts,
  } = props;

  const [openDialog, setOpenDialog] = openCloseHook;

  const onClickAway = () => {
    setOpenDialog(false);
  };

  return (
    <>
      {openDialog && (
        <ClickAwayListener onClickAway={onClickAway}>
          <Popper
            id="fn-mute-channel-popper"
            open={Boolean(anchorEl) && openDialog}
            placement="bottom-start"
            anchorEl={anchorEl}
          >
            <SilentChannelBoxStyled component={Paper}>
              <Typography variant="h6">{texts.title}</Typography>
              <Typography variant="body2">
                {replaceUtil(alertChannelName, texts.subTitle)}
              </Typography>
              {silencedUntil ? (
                <Alert severity="info" sx={{ marginTop: 1, marginBottom: 1 }}>
                  {[texts.message, globalTimeParser(silencedUntil)].join(" ")}
                </Alert>
              ) : null}
              <SilentChannelForm
                {...pick(props, "id", "openCloseHook", "silencedUntil")}
              />
            </SilentChannelBoxStyled>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};
