import { MenuItem, TextField } from "@mui/material";
import React from "react";
import {
  Controller,
  useFormContext,
  type FieldValues,
  type Path,
} from "react-hook-form";

import { FieldWithTitle, FormFieldWrapper } from "../../../styled";
import * as QuotaSchedulerBlueprint from "../../quota-scheduling/@types";
import type { BlueprintFieldProps } from "../types";
import { createFieldNameWithPrefix } from "../utils";

export const responseStatusCodeOptions = Object.keys(
  QuotaSchedulerBlueprint.HTTPResponseStatusCode,
).map((key) => ({
  value:
    QuotaSchedulerBlueprint.HTTPResponseStatusCode[
      key as keyof typeof QuotaSchedulerBlueprint.HTTPResponseStatusCode
    ],
  label: key,
}));

/**
 *
 * NOTE: There is no such term in schema called advanced in scheduler.
 * Fields which are less important to users are put in advanced.
 * `decision_deadline_margin` and `denied_response_status_code` are the fields in advanced.
 */
export const SchedulerAdvanced = <
  TFields extends FieldValues = QuotaSchedulerBlueprint.Coordinate,
>({
  fieldsPrefix = "policy.quota_scheduler.scheduler" as Path<TFields>,
}: BlueprintFieldProps<TFields>) => {
  const { control, getValues } = useFormContext<TFields>();

  const defaultValues = getValues(fieldsPrefix);

  return (
    <FormFieldWrapper>
      <FieldWithTitle
        fieldAddress={createFieldNameWithPrefix(
          fieldsPrefix,
          "decision_deadline_margin",
        )}
        label="Decision deadline margin"
      >
        <Controller
          control={control}
          defaultValue={defaultValues?.decision_deadline_margin}
          name={createFieldNameWithPrefix(
            fieldsPrefix,
            "decision_deadline_margin",
          )}
          rules={{
            pattern: {
              value: /^(\d+|\d+\.\d+)s$/,
              message: "Value should be of the form '<float>s'",
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...{
                ...field,
                value: field.value,
                onChange: field.onChange,
                error: !!fieldState.error,
                helperText: <>{fieldState.error?.message}</>,
              }}
            />
          )}
        />
      </FieldWithTitle>
      <DeniedResponseStatusCodeInput<TFields> fieldsPrefix={fieldsPrefix} />
    </FormFieldWrapper>
  );
};

export const DeniedResponseStatusCodeInput = <TFields extends FieldValues>({
  fieldsPrefix = "policy.quota_scheduler.scheduler" as Path<TFields>,
}: BlueprintFieldProps<TFields>) => {
  const { register, getValues } = useFormContext<TFields>();

  const fieldName = createFieldNameWithPrefix(
    fieldsPrefix,
    "denied_response_status_code",
  );

  return (
    <FieldWithTitle
      label="Denied response status code"
      fieldAddress={fieldName}
    >
      <TextField
        {...{
          ...register(fieldName),
          placeholder: "Denied response status code",
          select: true,
          defaultValue: getValues(fieldName),
          sx: (theme) => ({
            "& .MuiSelect-icon": {
              color: theme.palette.text.primary,
            },
          }),
        }}
      >
        {responseStatusCodeOptions.map(({ value, label }, i) => (
          <MenuItem key={[value, i].join(".")} value={value}>
            {label}
          </MenuItem>
        ))}
      </TextField>
    </FieldWithTitle>
  );
};
