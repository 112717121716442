import { alpha, Box, hexToRgb, Typography, styled } from "@mui/material";
import React, { type FC } from "react";

import type { FnTheme } from "../../../../../../../../app-theme-provider/types";

const PoliciesCountTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "count",
})<{ count: number }>(({ theme, count }) => {
  const { palette } = theme as unknown as FnTheme;

  return {
    borderRadius: theme.spacing(0.5),
    textAlign: "center",
    padding: `0 ${theme.spacing(0.5)}`,
    ...(count !== 0
      ? {
          color: palette.other?.plum,
          backgroundColor: alpha(hexToRgb(palette.other?.plum || ""), 0.3),
        }
      : {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.default,
        }),
  };
});

export interface PoliciesCountBoxProps {
  count: number;
}

export const PoliciesCountBox: FC<PoliciesCountBoxProps> = ({ count }) => (
  <Box display="grid" gridTemplateColumns="auto 1fr">
    <PoliciesCountTypography variant="body2" count={count}>
      {count !== 0 ? count : "none"}
    </PoliciesCountTypography>
  </Box>
);
