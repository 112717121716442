import { badgeClasses, type SxProps, type Theme } from "@mui/material";

export const styledBadge: (color?: string) => SxProps<Theme> = (color) => ({
  cursor: "pointer",
  [`& .${badgeClasses.badge}`]: {
    backgroundColor: ({ palette }) => color || palette.success.main,
    color: ({ palette }) => color || palette.success.main,
    boxShadow: ({ palette }) => `0 0 0 2px ${palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      border: "1px solid currentColor",
      content: '""',
    },
  },
});
