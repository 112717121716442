import { Box, type BoxProps, Paper, styled } from "@mui/material";
import React, { type FC, type PropsWithChildren } from "react";

import type { FnTheme } from "../../../../app-theme-provider/types";

export interface FiltersBarWrapperProps {
  enableArchivedData?: boolean | undefined;
  archiveSwitchProps?: BoxProps;
}

export const FiltersBarWrapper: FC<
  PropsWithChildren<FiltersBarWrapperProps>
> = ({ children, enableArchivedData = false, archiveSwitchProps }) => (
  <Box
    sx={(theme) => ({
      backgroundColor: (theme as unknown as FnTheme).palette.filterBar
        .background.default,
      display: "grid",
      gridTemplateAreas: '"table-actions archive-switch"',
      gridTemplateColumns: "1fr auto",

      boxShadow:
        "0px 3px 3px -2px rgba(96, 101, 117, 0.2), 0px 3px 4px rgba(96, 101, 117, 0.14), 0px 1px 8px rgba(96, 101, 117, 0.12)",
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1),
      alignItems: "center",
    })}
  >
    <Box
      {...{
        padding: 0.75,
        component: Paper,
        display: "grid",
        gridArea: "table-actions",
        gridTemplateAreas:
          '"filter-add-button filter group-by copy-and-delete"',
        gridTemplateColumns: "auto auto auto 1fr",
        alignItems: "center",
        pr: 0,
        sx: (theme) => ({
          backgroundColor: (theme as unknown as FnTheme).palette.filterBar
            .background.paper,
        }),
      }}
    >
      {children}
    </Box>
    {enableArchivedData && (
      <ArchiveSwitchWrapper
        component={Paper}
        className="__archive-switch"
        sx={{
          gridArea: "archive-switch",
        }}
        {...archiveSwitchProps}
      />
    )}
  </Box>
);

export const ArchiveSwitchWrapper = styled(Box)(({ theme }) => ({
  height: 50,
  width: 235,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: theme.spacing(0.5),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(0),
  backgroundColor: (theme as unknown as FnTheme).palette.filterBar.background
    .paper,
  "> label:not(:first-of-type)": {
    display: "none",
  },
}));
