import React, { type FC } from "react";

import { InfoDataPage } from "#shared/components/layouts";
import { BASE_DOCUMENTATION_URL } from "#shared/consts";
import type { Texts } from "#shared/types";

export interface NoControlPointProps {
  texts?: Texts<Text>;
}

type Text =
  | "noControl"
  | "controlDesc"
  | "flowControl"
  | "flowControlDesc"
  | "configureCP"
  | "autoScaling"
  | "autoScalingDesc"
  | "configureK8s";

const enTexts: NoControlPointProps["texts"] = {
  noControl: "We didn’t find anything to control",
  controlDesc:
    "In order to control your traffic and view metrics you need to take additional steps.",
  flowControl: "Control Points for Flow Control",
  flowControlDesc:
    "To do Flow Control you need to install Control Points for services (SDK, Envoy, middleware).",
  configureCP: "Configure Control Points",
  autoScaling: "Kubernetes Control Points for Auto Scaling",
  autoScalingDesc: "To use Auto Scaling, you need to configure Kubernetes.",
  configureK8s: "Configure Kubernetes",
};

export const NoControlPoints: FC<NoControlPointProps> = ({
  texts = enTexts,
}) => {
  const actions = [
    {
      title: texts.flowControl,
      description: texts.flowControlDesc,
      buttonText: texts.configureCP,
      buttonProps: {
        href: `${BASE_DOCUMENTATION_URL}/sdk`,
        target: "__blank",
      },
    },
    {
      title: texts.autoScaling,
      description: texts.autoScalingDesc,
      buttonText: texts.configureK8s,
      buttonProps: {
        href: `${BASE_DOCUMENTATION_URL}/aperture-for-infra/integrations/auto-scale/kubernetes/`,
        target: "__blank",
      },
    },
  ];

  return (
    <InfoDataPage
      title={texts.noControl}
      description={texts.controlDesc}
      actions={actions}
    />
  );
};
