import { type TextFieldProps, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import type {
  SxProps,
  SystemStyleObject,
  Theme,
  TypographyProps,
} from "@mui/system";
import { isArray, omit } from "lodash";
import React, { type FC, useMemo } from "react";

import { GridWrapper } from "../../../styled";

type ChannelTextFieldType = Omit<
  TextFieldProps,
  "variant" | "InputLabelProps" | "sx"
>;
interface TextFieldPropsType extends ChannelTextFieldType {
  title: string;
}

export interface ChannelTextInputProps {
  textFieldProps: TextFieldPropsType | TextFieldPropsType[];
  titleTypographyProps?:
    | (Omit<TypographyProps, "fontSize" | "fontWeight"> & SxProps<Theme>)
    | {};
  gridWrapperStyle?: SxProps<Theme>;
}

export const TEXT_FIELD_PROPS: Required<
  Pick<TextFieldProps, "InputProps"> & {
    sx: (theme: Theme) => SystemStyleObject<Theme>;
  }
> = {
  sx: (theme) => ({
    width: "100%",
    [theme.breakpoints.up("md")]: {
      alignItems: "flex-end",
    },
  }),
  InputProps: {
    style: {
      maxWidth: 600,
      minWidth: 200,
      width: "100%",
    },
  },
};

export const ChannelTextInput: FC<ChannelTextInputProps> = ({
  textFieldProps,
  titleTypographyProps = {},
  gridWrapperStyle = {
    rowGap: 2,
    width: "90%",
  },
}) => {
  const textFieldPropsArray = useMemo(
    () => (isArray(textFieldProps) ? textFieldProps : [textFieldProps]),
    [textFieldProps],
  );

  return (
    <GridWrapper sx={gridWrapperStyle}>
      {textFieldPropsArray.map((fieldProps, index) => (
        <React.Fragment key={[fieldProps.title, index].join("-")}>
          <Typography fontSize={14} fontWeight={700} {...titleTypographyProps}>
            {fieldProps.title}
          </Typography>
          <TextField
            {...omit(fieldProps, "title")}
            {...TEXT_FIELD_PROPS}
            InputLabelProps={{ shrink: false }}
            variant="outlined"
          />
        </React.Fragment>
      ))}
    </GridWrapper>
  );
};
