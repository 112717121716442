import { Box, Paper } from "@mui/material";
import React, { useState, type FC, type PropsWithChildren } from "react";
import { useFormContext, type FieldValues, type Path } from "react-hook-form";

import { LoaderLayout } from "#shared/components/layouts";

import * as QuotaSchedulingBlueprint from "./@types";
import { quotaSchedulingFormDefaultValues } from "./default-values";
import { quotaSchedulingDocsTree } from "./docs";

import { PolicyFormTreeView, TreeItemStyled } from "../../styled";
import {
  BucketCapacityAndFillAmount,
  ParameterInputs,
  ParametersAdvanced,
  Selectors,
} from "../common";
import { Scheduler, SchedulerAdvanced } from "../common/scheduler";
import type { BlueprintFieldProps } from "../common/types";
import { createFieldNameWithPrefix } from "../common/utils";
import { FormContextProvider } from "../form-context-provider";
import { useBlueprintJson, useSyncWithEditor } from "../hooks";
import { PolicyFormTemplate } from "../templates";

export declare type QuotaSchedulingInputsProps<TFields extends FieldValues> =
  {} & BlueprintFieldProps<TFields>;

export const QuotaSchedulingInputs = <
  TFields extends FieldValues = QuotaSchedulingBlueprint.Coordinate,
>({
  fieldsPrefix = "policy.quota_scheduler" as Path<TFields>,
  label = "Quota Scheduler",
}: QuotaSchedulingInputsProps<TFields>) => {
  const { watch } = useFormContext<TFields>();

  useSyncWithEditor<TFields>(watch);

  return (
    <TreeItemStyled nodeId="quotaScheduler" label={label}>
      <BucketCapacityAndFillAmount<TFields>
        {...{
          fieldsPrefix,
        }}
      />
      <ParameterInputs<TFields>
        {...{
          fieldsPrefix: createFieldNameWithPrefix(fieldsPrefix, "rate_limiter"),
        }}
      />
      <Scheduler<TFields>
        {...{
          fieldsPrefix: createFieldNameWithPrefix(fieldsPrefix, "scheduler"),
        }}
      />
      <Selectors<TFields>
        {...{
          fieldsPrefix,
        }}
      />
      <ParametersAdvanced<TFields>
        {...{
          fieldsPrefix: createFieldNameWithPrefix(fieldsPrefix, "rate_limiter"),
        }}
      >
        <SchedulerAdvanced<TFields>
          {...{
            fieldsPrefix: createFieldNameWithPrefix(fieldsPrefix, "scheduler"),
          }}
        />
      </ParametersAdvanced>
    </TreeItemStyled>
  );
};

export const QuotaSchedulingForm: FC<PropsWithChildren> = ({ children }) => {
  const [isDirty, setIsDirty] = useState(false);

  const { blueprintJson: policy } =
    useBlueprintJson<QuotaSchedulingBlueprint.Coordinate>(
      quotaSchedulingFormDefaultValues,
      isDirty,
    );

  if (!policy) {
    return <LoaderLayout />;
  }

  return (
    <FormContextProvider<QuotaSchedulingBlueprint.Coordinate>
      defaultValues={policy}
    >
      {(methods) => (
        <PolicyFormTemplate<QuotaSchedulingBlueprint.Coordinate>
          {...{
            methods,
            recordIsDirty: [isDirty, setIsDirty],
            docTree: quotaSchedulingDocsTree,
            texts: {
              policyName: "Policy name",
              blueprintLabel: "Quota Scheduling Blueprint",
            },
          }}
        >
          <PolicyFormTreeView
            treeViewProps={{
              defaultExpanded: ["quotaScheduler", "selectors"],
            }}
          >
            <Box component={Paper} p={2}>
              <QuotaSchedulingInputs />
            </Box>
          </PolicyFormTreeView>
          {children}
        </PolicyFormTemplate>
      )}
    </FormContextProvider>
  );
};
