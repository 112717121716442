import { useEffect, useMemo } from "react";
import { useSetRecoilState } from "recoil";

import { API_ACCOUNT_URLS } from "#shared/consts";
import { userNewState, type User } from "#shared/recoil";
import { LoggerService } from "#shared/services";
import { httpClient } from "#shared/utils/http-client";

import { useFetch, type UseFetch } from "./use-fetch";

export type UseGetAccount = Pick<
  UseFetch<User | null>,
  "result" | "reset" | "httpState"
> & {
  getAccount: (token?: string | null) => Promise<{ data: User } | null>;
};

export function useGetAccount(
  isOrganizationContext: boolean,
  organizationId: string | null | undefined,
): UseGetAccount {
  const { request, ...fetchAccount } = useFetch<{ data: User } | null>();

  const setUser = useSetRecoilState(userNewState.setState);

  const getAccount = useMemo<UseGetAccount["getAccount"]>(
    () =>
      async (token: string | null = null) => {
        if (isOrganizationContext && !organizationId) {
          LoggerService.warn(
            "Missing organizationId while isOrganizationContext.",
            "Won't get account.",
          );

          throw new Error(
            "Missing organizationId while isOrganizationContext.",
          );
        }

        return request({
          url: isOrganizationContext
            ? [API_ACCOUNT_URLS.ORGANIZATION, organizationId]
                .filter(Boolean)
                .join("/")
            : API_ACCOUNT_URLS.GLOBAL(!isOrganizationContext),
          headers: { Authorization: httpClient.getTokenWithPrefix(token) },
          shouldParse: true,
        });
      },
    [isOrganizationContext, request, organizationId],
  );

  /**
   * NOTE
   *
   * Store FN account is user state.
   */
  useEffect(() => {
    setUser({
      httpState: {
        error: fetchAccount.httpState.error,
        isIdle: fetchAccount.httpState.isIdle,
        isLoading: fetchAccount.httpState.isLoading,
        isSuccess: fetchAccount.httpState.isSuccess,
      },
      data: fetchAccount.result?.data || null,
    });
  }, [fetchAccount.httpState, setUser, fetchAccount.result]);

  return useMemo<UseGetAccount>(
    () => ({
      httpState: fetchAccount.httpState,
      result: fetchAccount.result?.data || null,
      reset: fetchAccount.reset,
      getAccount,
    }),
    [
      fetchAccount.httpState,
      fetchAccount.result,
      fetchAccount.reset,
      getAccount,
    ],
  );
}
