import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import React, { useCallback, type FC } from "react";
import { useForm, type SubmitHandler } from "react-hook-form";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { EMAIL_PATTERN, FLUXNINJA } from "#shared/consts";
import logo from "#shared/fn-aperture-logo-full.svg";
import { firebaseUserState } from "#shared/recoil";

export interface ConfirmEmailFormProps {
  callback: (email: string) => void;
}

/**
 * Used to confirm email address during sign in with link flow.
 * Firebase need to confirm email address before sign in.
 */
export const ConfirmEmailForm: FC<ConfirmEmailFormProps> = ({ callback }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm<{
    email: string;
  }>({ mode: "onBlur", defaultValues: { email: "" } });

  const errorUserState = useRecoilValue(firebaseUserState.error);
  const isLoading = useRecoilValue(firebaseUserState.isLoading);

  const submit = useCallback<SubmitHandler<{ email: string }>>(
    ({ email }) => {
      clearErrors();
      callback(email);
    },
    [callback, clearErrors],
  );

  return (
    <Box>
      <Box textAlign="center" pt={5}>
        <Link to="/">
          <img
            {...{
              src: logo,
              alt: [FLUXNINJA, logo].join(" "),
              style: { maxWidth: "350px" },
            }}
          />
        </Link>
      </Box>
      <Stack
        component="form"
        onSubmit={handleSubmit(submit)}
        autoComplete="off"
      >
        <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
          It seems like you are signing in from a different browser or device.
          Please re-enter your email address.
        </Typography>
        <TextField
          id="email"
          fullWidth
          type="email"
          label="Email"
          sx={{ mb: 2 }}
          {...register("email", {
            required: "Email address is required",
            pattern: EMAIL_PATTERN,
          })}
          error={!!errors.email || !!errorUserState}
          helperText={
            errors.email
              ? errors.email?.message || EMAIL_PATTERN.message
              : (errorUserState as string) || ""
          }
        />
        <Button
          disabled={!!isLoading}
          type="submit"
          variant="contained"
          color="primary"
        >
          Confirm Email
        </Button>
      </Stack>
    </Box>
  );
};
