import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { noop } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import {
  useFormContext,
  type FieldValues,
  type Path,
  Controller,
} from "react-hook-form";

import type { Texts } from "#shared/types";

import type { BlueprintFieldProps } from "./types";
import { createFieldNameWithPrefix } from "./utils";

import { FieldWithTitle, FormFieldWrapper } from "../../styled";
import { REQUIRED_FIELD_SIGN, validateFieldWithRequiredSign } from "../consts";

type FieldLabelNames = "fill_amount" | "bucket_capacity";

export declare type BucketCapacityAndFillAmountProps<
  TFields extends FieldValues,
> = {
  texts?: Texts<FieldLabelNames>;
} & BlueprintFieldProps<TFields>;

const defaultInputTexts = {
  fill_amount: "Fill amount",
  bucket_capacity: "Bucket capacity",
};

export const BucketCapacityAndFillAmount = <TFields extends FieldValues>({
  texts = defaultInputTexts,
  fieldsPrefix = "policy.rate_limiter" as Path<TFields>,
}: BucketCapacityAndFillAmountProps<TFields>) => {
  const {
    fillAmountFieldName,
    bucketCapacityFieldName,
    sameValueForBucketCapacity,
    onChangeCheckbox,
    defaultValues,
    control,
    getValues,
    setValue,
  } = useBucketCapacityAndFillAmount<TFields>({ fieldsPrefix });

  return (
    <FormFieldWrapper>
      <FieldWithTitle
        fieldAddress={fillAmountFieldName}
        label={texts.fill_amount}
        required
      >
        <Controller
          control={control}
          defaultValue={defaultValues?.fill_amount}
          rules={validateFieldWithRequiredSign<TFields>(
            "Fill amount is required",
          )}
          name={fillAmountFieldName}
          render={({ field, field: { onChange }, fieldState }) => (
            <TextField
              {...{
                ...field,
                value: `${field.value}`,
                onChange: onChangeNumberField(onChange, (v) => {
                  if (sameValueForBucketCapacity) {
                    /**
                     * Since we are watching and updating same in yaml editor.
                     * Need to delay the update to avoid overriding the value
                     */
                    setTimeout(() => {
                      setValue(bucketCapacityFieldName, v);
                    }, 100);
                  }
                }),
                placeholder: texts.fill_amount,
                error: !!fieldState.error,
                helperText: <>{fieldState.error?.message}</>,
              }}
            />
          )}
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={onChangeCheckbox}
              defaultChecked={
                getValues(fillAmountFieldName) ===
                getValues(bucketCapacityFieldName)
              }
            />
          }
          label="Same value for Bucket capacity"
        />
      </FieldWithTitle>
      <FieldWithTitle
        fieldAddress={bucketCapacityFieldName}
        label={texts.bucket_capacity}
        hidden={sameValueForBucketCapacity}
        required
      >
        <Controller
          control={control}
          defaultValue={defaultValues?.bucket_capacity}
          rules={validateFieldWithRequiredSign<TFields>(
            "Bucket capacity is required",
          )}
          name={bucketCapacityFieldName}
          render={({ field, field: { onChange }, fieldState }) => (
            <TextField
              {...{
                ...field,
                value: `${field.value}`,
                onChange: onChangeNumberField(onChange),
                placeholder: texts.bucket_capacity,
                error: !!fieldState.error,
                helperText: <>{fieldState.error?.message}</>,
              }}
            />
          )}
        />
      </FieldWithTitle>
    </FormFieldWrapper>
  );
};

export const useBucketCapacityAndFillAmount = <TFields extends FieldValues>({
  fieldsPrefix = "policy.rate_limiter" as Path<TFields>,
}: Pick<BucketCapacityAndFillAmountProps<TFields>, "fieldsPrefix">) => {
  const { control, setValue, getValues } = useFormContext<TFields>();

  const fillAmountFieldName = useMemo(
    () => createFieldNameWithPrefix(fieldsPrefix, "fill_amount"),
    [fieldsPrefix],
  );
  const bucketCapacityFieldName = useMemo(
    () => createFieldNameWithPrefix(fieldsPrefix, "bucket_capacity"),
    [fieldsPrefix],
  );

  const [sameValueForBucketCapacity, setSameValueCheckBox] = useState(
    getValues(fillAmountFieldName) === getValues(bucketCapacityFieldName),
  );

  const defaultValues = getValues(fieldsPrefix);

  const onChangeCheckbox = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setSameValueCheckBox(checked);

      if (checked) {
        setValue(bucketCapacityFieldName, getValues(fillAmountFieldName));
      }
    },
    [bucketCapacityFieldName, fillAmountFieldName, getValues, setValue],
  );

  return {
    fillAmountFieldName,
    bucketCapacityFieldName,
    sameValueForBucketCapacity,
    onChangeCheckbox,
    defaultValues,
    control,
    getValues,
    setValue,
  };
};

export function onChangeNumberField(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void,
  cb = noop,
) {
  return (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;

    const valueWithoutRequiredSign = value.replace(REQUIRED_FIELD_SIGN, "");

    const numValue = Number(valueWithoutRequiredSign);

    if (Number.isNaN(numValue)) {
      return;
    }
    onChange(Number(numValue));

    cb(Number(numValue));
  };
}
