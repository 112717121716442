import { Box, alpha } from "@mui/material";
import { styled } from "@mui/system";

export const FlowChartControlsContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 65,
  left: 5,
  p: 2,
  zIndex: 4,
  width: 300,
  display: "grid",
  gridTemplateColumns: "100px 1fr",
  gap: 1,
  padding: theme.spacing(1),
}));

export const BtnWrapper = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  cursor: "pointer",
  alignSelf: "center",
  textAlign: "center",
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
}));

export const s = {
  leftButton: {
    padding: 0.4,
    borderStartStartRadius: 5,
    borderEndStartRadius: 5,
    fontSize: 10,
  },
  rightButton: {
    padding: 0.4,
    borderLeft: "hidden",
    borderStartEndRadius: 5,
    borderEndEndRadius: 5,
    fontSize: 10,
  },
};
