import React, { type FC } from "react";
import { Navigate } from "react-router-dom";

import { LoaderLayout } from "#shared/components/layouts";

import { APERTURE_SYSTEM_ROUTE } from "./aperture-system";
import { CONTROL_POINTS_ROUTE } from "./control-points";
import { useFlowControl } from "./control-points/tabs/flow-control/hooks";
import { useControlPointsKubernetes } from "./control-points/tabs/kubernetes/hooks";

export const SidebarRedirect: FC = () => {
  const flowControl = useFlowControl();
  const controlPointsKubernetes = useControlPointsKubernetes();

  const isLoading =
    flowControl.query.isLoading || controlPointsKubernetes.query.isLoading;
  const hasError =
    flowControl.query.isError || controlPointsKubernetes.query.isError;
  const noControlPoints =
    flowControl.isEmpty && controlPointsKubernetes.isEmpty;

  if (isLoading) {
    return <LoaderLayout />;
  }

  return (
    <Navigate
      to={
        noControlPoints || hasError
          ? APERTURE_SYSTEM_ROUTE.ABSOLUTE_PATH
          : CONTROL_POINTS_ROUTE.ABSOLUTE_PATH
      }
    />
  );
};
