import { Box, Typography } from "@mui/material";
import React, { type FC, type PropsWithChildren, type ReactNode } from "react";

import {
  ConcurrencyLimitingForm,
  QuotaSchedulingForm,
  RateLimitingForm,
  ConcurrencySchedulingForm,
} from "./builder";

import { useCreatePolicySearchParams } from "../../hooks";

export interface PolicyFormBuilderProps {}

export const PolicyFormBuilder: FC<
  PropsWithChildren<PolicyFormBuilderProps>
> = ({ children }) => {
  const {
    searchInfo: { blueprintName },
  } = useCreatePolicySearchParams();

  return (
    policyBuilderForms[blueprintName as InBuildFNBlueprintNames]?.(
      children,
    ) ?? <PolicyFormBuilderMessage blueprintName={blueprintName} />
  );
};

export const PolicyFormBuilderMessage: FC<{ blueprintName: string }> = ({
  blueprintName,
}) => (
  <Box
    pt={4}
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Typography variant="h6" component="h2">
      {`Policy builder for blueprint: ${blueprintName} is not available yet.`}
    </Typography>
  </Box>
);

export declare type InBuildFNBlueprintNames =
  | "rate-limiting/base"
  | "quota-scheduling/base"
  | "concurrency-limiting/base"
  | "concurrency-scheduling/base";

export declare type InBuildFNBlueprintsForm = {
  [key in InBuildFNBlueprintNames]: (children: ReactNode) => JSX.Element;
};

export const availablePolicyBuilderForms: InBuildFNBlueprintNames[] = [
  "rate-limiting/base",
  "quota-scheduling/base",
  "concurrency-limiting/base",
  "concurrency-scheduling/base",
];

export const policyBuilderForms: InBuildFNBlueprintsForm = {
  "rate-limiting/base": (children) => (
    <RateLimitingForm>{children}</RateLimitingForm>
  ),
  "quota-scheduling/base": (children) => (
    <QuotaSchedulingForm>{children}</QuotaSchedulingForm>
  ),
  "concurrency-limiting/base": (children) => (
    <ConcurrencyLimitingForm>{children}</ConcurrencyLimitingForm>
  ),
  "concurrency-scheduling/base": (children) => (
    <ConcurrencySchedulingForm>{children}</ConcurrencySchedulingForm>
  ),
};
