import { styled } from "@mui/system";
import { Link } from "react-router-dom";

export const LinkWithoutUnderline = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.primary,
  "& :hover": {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}));
