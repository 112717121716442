import { FormControlLabel, Switch, type SwitchProps } from "@mui/material";
import React, { type FC } from "react";

import { useCircuitContext } from "../../../circuit-context";

const SWITCH_LABEL = "Show port label";

export interface PortLabelSwitchProps
  extends Omit<SwitchProps, "checked" | "onChange" | "color" | "size"> {}

export const PortLabelSwitch: FC<PortLabelSwitchProps> = (props) => {
  const { circuitDispatch, circuitLabelStatus } = useCircuitContext();

  const onChange = () => {
    if (!circuitDispatch) return;

    circuitDispatch({
      type: "setCircuitLabelStatus",
      payload: !circuitLabelStatus,
    });
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={circuitLabelStatus}
          onChange={onChange}
          color={circuitLabelStatus ? "primary" : "default"}
          size="medium"
          {...props}
        />
      }
      label={SWITCH_LABEL}
      labelPlacement="end"
    />
  );
};
