import { Badge, type BadgeProps } from "@mui/material";
import React, { type FC, forwardRef } from "react";

import { styledBadge } from "./styled";

export interface TopBarBadgeProps extends BadgeProps {}

export const TopBarBadge: FC<TopBarBadgeProps> = forwardRef((props, ref) => (
  <Badge
    sx={styledBadge()}
    overlap="circular"
    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    variant="dot"
    {...props}
    ref={ref}
  />
));
