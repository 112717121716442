import type { DocumentNode, GraphQLError } from "graphql";
import type { Variables } from "graphql-request";
import {
  useMutation,
  useQuery,
  type QueryKey,
  type UseMutationOptions,
  type UseQueryOptions,
} from "react-query";

import { gqlRequest } from "./graphql-request";

export type UseGqlMutationOptions = Omit<UseMutationOptions, "mutationFn">;
export type UseGqlQueryOptions = Omit<
  UseQueryOptions,
  "queryKey" | "queryFn" | "onError"
>;

export const useGqlMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
  TOptions = UseGqlMutationOptions,
>(
  mutationQuery: string | DocumentNode,
  options: TOptions | {} = {},
) =>
  useMutation<TData, TError, TVariables, TContext>({
    ...options,
    mutationFn: (data) => gqlRequest(mutationQuery, { input: data }),
  });

export const useGqlQuery = <
  TQueryFnData = unknown,
  TError extends unknown = GraphQLError,
  TData extends TQueryFnData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
  TVariables extends Variables = Variables,
  TOptions = UseGqlQueryOptions,
>(
  QUERY_KEY: TQueryKey,
  query: string | DocumentNode,
  variables: TVariables | {} = {},
  options: TOptions | {} = {},
) =>
  useQuery<TQueryFnData, TError, TData, TQueryKey>({
    ...options,
    queryKey: QUERY_KEY,
    queryFn: async () =>
      gqlRequest<TQueryFnData, TVariables>(
        query,
        variables as unknown as TVariables,
      ),
    retry: false,
  });
