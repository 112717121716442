import { CodeSnippet } from "#organization/components"

export const language = "ts";

# Tokens Label Key

## Overview

`tokens_label_key` is a key for a flow label that can be used to override the default number of tokens for this request. The value associated with this key must be a valid number. If this parameter is not provided, the number of tokens for the flow will be determined by the matched workload's token count.

## Example

<CodeSnippet lang={language} snippetName="UIQSTokens" />
