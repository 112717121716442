import React, {
  useMemo,
  type Dispatch,
  type FC,
  type SetStateAction,
} from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import type { UpdateUserGroupProjectAssignmentPermissionsInput } from "#shared/generated/graphql";
import { PermissionEnum, userNewState } from "#shared/recoil";
import type { Texts } from "#shared/types";

import { DataGrid } from "#organization/components/data-grid";
import { useDropdownSearchBox } from "#organization/components/layouts/dropdown-search-box";
import { QUERY_KEYS } from "#organization/pages/consts";

import { AddProjectToUserGroupDropdown } from "./components/add-project-to-user-group-dropdown";
import { headCells } from "./components/table";
import { useUserGroupProjects, PROJECT_SECTION_QUERY_KEY } from "./hooks";

import { SectionHeader } from "../../../../../components/section-header";
import { SectionWrapper } from "../../../../../components/styled";

const INITIAL_ORDER_BY = "";
const INITIAL_ORDER = "asc";

type Text = "title" | "assignUserGroupToProject";

interface ProjectsSectionProps {
  userGroupName: string | undefined;
  setUpdateUserGroupPermission: Dispatch<
    SetStateAction<UpdateUserGroupProjectAssignmentPermissionsInput[] | null>
  >;
  texts?: Texts<Text>;
}

const enTexts: Required<ProjectsSectionProps["texts"]> = {
  title: "Projects user group $1 is assigned to",
  assignUserGroupToProject: "Assign this user group to another project",
};

export const ProjectsSection: FC<ProjectsSectionProps> = ({
  userGroupName,
  setUpdateUserGroupPermission,
  texts = enTexts,
}) => {
  const { userGroupId } = useParams<{ userGroupId: string }>();
  const userRole = useRecoilValue(userNewState.userRole)!;

  const addProjectDropdown = useDropdownSearchBox();
  const dataGridVariables = useMemo(
    () => ({
      userGroupID: {
        eq: userGroupId,
      },
    }),
    [userGroupId],
  );
  const queryClient = useQueryClient();
  const refetch = () =>
    queryClient.invalidateQueries([
      QUERY_KEYS.DATA_GRID,
      PROJECT_SECTION_QUERY_KEY,
    ]);

  const cells = headCells(
    PermissionEnum[userRole] < PermissionEnum.user_group_admin,
    setUpdateUserGroupPermission,
  );

  return (
    <SectionWrapper>
      <SectionHeader
        title={texts.title.replace("$1", userGroupName || "")}
        /* action={
          <Button
            variant="contained"
            size="small"
            disabled={
              PermissionEnum[userRole] <
              PermissionEnum.user_group_admin
            }
            onClick={addProjectDropdown.togglePopper}
            startIcon={<Add />}
          >
            {texts.assignUserGroupToProject}
          </Button>
        } */
      />

      <DataGrid
        headCells={cells}
        useGridData={useUserGroupProjects}
        variant="settings"
        enabled
        filterVariables={dataGridVariables}
        initialOrderBy={INITIAL_ORDER_BY}
        initialOrder={INITIAL_ORDER}
      />

      <AddProjectToUserGroupDropdown
        dropdownControl={addProjectDropdown}
        refetch={refetch}
      />
    </SectionWrapper>
  );
};
