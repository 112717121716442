import { buttonBaseClasses, type ThemeOptions } from "@mui/material";

const styleOverrides: Required<
  Required<ThemeOptions>["components"]
>["MuiIconButton"]["styleOverrides"] = {
  root: {
    [`&.${buttonBaseClasses.root}`]: {
      outline: "none",
      "&:focus": {
        outline: "none",
      },
    },
  },
};

export const MuiIconButton: Required<
  Required<ThemeOptions>["components"]
>["MuiIconButton"] = { styleOverrides };
