import { find, identity } from "lodash";
import { type Reducer, useEffect, useMemo, useReducer } from "react";

import { LoggerService } from "#shared/services";
import { type TenantIdpConfigResponse } from "#shared/types";

import { type EnabledIdpConfigs } from "../types";

export function useIdpConfigsEnabledState(
  data: TenantIdpConfigResponse | undefined,
) {
  const isGoogleEnabled = useMemo(
    () =>
      !!find(data?.defaultSupportedIdpConfigs, {
        enabled: true,
        displayName: "google.com",
      }),
    [data?.defaultSupportedIdpConfigs],
  );

  const isSamlEnabled = useMemo(
    () =>
      !!find(data?.inboundSamlConfigs, {
        enabled: true,
      }),
    [data?.inboundSamlConfigs],
  );

  const [enabledIdpConfigs, setEnabledIdpConfigs] = useReducer<
    Reducer<EnabledIdpConfigs, EnabledIdpConfigs>,
    EnabledIdpConfigs
  >(
    (_, action) => {
      if (action.google) {
        return { saml: false, google: true };
      }

      if (action.saml) {
        return { saml: true, google: false };
      }

      return { saml: false, google: false };
    },
    { saml: isSamlEnabled, google: isGoogleEnabled },
    identity,
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    if (isSamlEnabled && isGoogleEnabled) {
      LoggerService.warn("Both SAML and Google are enabled");

      return;
    }

    if (isGoogleEnabled) {
      setEnabledIdpConfigs({ saml: false, google: true });

      return;
    }

    if (isSamlEnabled) {
      setEnabledIdpConfigs({ saml: true, google: false });
    }
  }, [data, isGoogleEnabled, isSamlEnabled]);

  return useMemo(
    () => ({ enabledIdpConfigs, setEnabledIdpConfigs }),
    [enabledIdpConfigs, setEnabledIdpConfigs],
  );
}
