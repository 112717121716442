import { Box, Button, Chip, MenuItem, Switch } from "@mui/material";
import React, { useState, type ChangeEvent, type FC } from "react";
import { useRecoilState } from "recoil";

import { Input } from "#shared/components/inputs/input";
import { userNewState } from "#shared/recoil";
import type { Texts } from "#shared/types";
import { FeatureFlag } from "#shared/utils";

import { TopBarBreadcrumbAppender } from "#organization/recoil/breadcrumbs";

import { useChangeUserName } from "./hooks";
import { ACCOUNT_DETAILS_ROUTE } from "./routes.definitions";

import { useAlert } from "../../../../../../shared/components/alerts-provider";
import { SectionHeader } from "../../components/section-header";
import { SettingsButtons } from "../../components/settings-buttons";
import { SettingsSectionLabel } from "../../components/settings-section-label";
import {
  SectionBody,
  SectionSelect,
  SectionWrapper,
} from "../../components/styled";

type Text =
  | "name"
  | "nameEmptyError"
  | "twoFourClock"
  | "nameDescription"
  | "email"
  | "emailDescription"
  | "settingsSavedSuccessfully"
  | "settingsSaveFailed"
  | "notVerified"
  | "resendVerificationEmail"
  | "timezone"
  | "timezoneAndClock"
  | "twoFourClockDescription";
interface AccountDetailsSettingsPageProps {
  texts?: Texts<Text>;
}

const enTexts: Required<AccountDetailsSettingsPageProps["texts"]> = {
  name: "Name",
  nameDescription: "Your full name",
  nameEmptyError: "Name cannot be empty",
  email: "Email address",
  emailDescription: "Your email address",
  settingsSavedSuccessfully: "Settings saved!",
  settingsSaveFailed: "Settings save failed!",
  notVerified: "Not verified",
  resendVerificationEmail: "Resend verification email",
  timezone: "Timezone",
  timezoneAndClock: "Timezone and Clock",
  twoFourClockDescription: "Display 18:19:56 instead of default 6:19:56 PM",
  twoFourClock: "Use 24-hour clock",
};

export const AccountDetailsSettingsPage: FC<
  AccountDetailsSettingsPageProps
> = ({ texts = enTexts }) => {
  const [userState, setUserState] = useRecoilState(userNewState.state);
  const user = userState.data;

  const { addAlert } = useAlert();
  const [name, setName] = useState(user?.name);
  const { mutate: changeUserName } = useChangeUserName(user?.id);
  const [email, setEmail] = useState(user?.email);
  const [disabled, setDisabled] = useState(false);

  const handleUserName = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  };

  const handleEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };

  const onSave = () => {
    if (!name?.trim()) {
      addAlert({ type: "error", message: texts.nameEmptyError });

      return;
    }

    setDisabled(true);

    setUserState({
      ...userState,
      data: {
        ...userState.data,
        id: user?.id ?? null,
        email: user?.email ?? null,
        picture: user?.picture ?? null,
        preferences: user?.preferences ?? null,
        organization: user?.organization ?? null,
        name,
      },
    });

    changeUserName(name, {
      onSuccess: () => {
        setDisabled(false);
        addAlert({ type: "success", message: texts.settingsSavedSuccessfully });
      },
      onError: () => {
        setDisabled(false);
        addAlert({ type: "error", message: texts.settingsSaveFailed });
      },
    });
  };

  const onReset = () => {
    setName(user?.name);
    setEmail(user?.email);
  };

  return (
    <Box>
      <TopBarBreadcrumbAppender>
        {ACCOUNT_DETAILS_ROUTE.TITLE}
      </TopBarBreadcrumbAppender>

      <SettingsButtons
        onSave={onSave}
        onResetAll={onReset}
        onSectionReset={() => setName("")}
        disabled={disabled}
        title="User Details"
      />
      <SectionWrapper>
        <SectionHeader title={ACCOUNT_DETAILS_ROUTE.TITLE} />
        <SectionBody>
          <SettingsSectionLabel
            primaryText={texts.name}
            secondaryText={texts.nameDescription}
            asterisk
          />

          <Input size="small" value={name} onChange={handleUserName} />

          <FeatureFlag
            flag="resendVerificationEmail"
            renderOff={
              <>
                <SettingsSectionLabel
                  primaryText={texts.email}
                  secondaryText={texts.emailDescription}
                  asterisk
                />

                <Input size="small" value={email} disabled />
              </>
            }
          />
        </SectionBody>

        <FeatureFlag
          flag="resendVerificationEmail"
          renderOn={
            <>
              <SectionHeader title={texts.email} />
              <SectionBody sx={{ gridTemplateColumns: "20vw auto auto 1fr" }}>
                <SettingsSectionLabel
                  primaryText={texts.email}
                  secondaryText={texts.emailDescription}
                  asterisk
                />
                <Input
                  size="small"
                  value={email}
                  onChange={handleEmail}
                  disabled
                />
                <Box sx={{ alignSelf: "center" }}>
                  <Chip label={texts.notVerified} />
                </Box>
                <Box sx={{ alignSelf: "center", justifySelf: "end" }}>
                  <Button variant="outlined" disabled>
                    {texts.resendVerificationEmail}
                  </Button>
                </Box>
              </SectionBody>
            </>
          }
        />

        <FeatureFlag
          flag="setTimeZone"
          renderOn={
            <>
              <SectionHeader title={texts.timezoneAndClock} />
              <SectionBody>
                <SettingsSectionLabel
                  primaryText={texts.timezone}
                  secondaryText=""
                  asterisk={false}
                />
                <SectionSelect defaultValue="UTC+0" size="small" disabled>
                  <MenuItem value="UTC-1">(UTC-0001)UTC</MenuItem>
                  <MenuItem value="UTC+0">(UTC+0000)UTC</MenuItem>
                  <MenuItem value="UTC+1">(UTC+0001)UTC</MenuItem>
                </SectionSelect>

                <SettingsSectionLabel
                  primaryText={texts.twoFourClock}
                  secondaryText={texts.twoFourClockDescription}
                  asterisk={false}
                />
                <Switch defaultChecked disabled />
              </SectionBody>
            </>
          }
        />
      </SectionWrapper>
    </Box>
  );
};
