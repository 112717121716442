import type { GlobalStylesProps } from "@mui/material";
import { useTheme } from "@mui/system";

import type { FnTheme } from "../../../../../../../../app-theme-provider/types";

export const GRID_AREA_CLASSNAMES = {
  area: "fn-policy_grid-area",
  innerContainer: "fn-policy_grid-area_container--inner",
  contentContainer: "fn-policy_grid-area_container--content",
  grid: "fn-policy_grid-view",
};

export const CUSTOM_GRID_HANDLE_WIDTH = 8;

export function useGridAreaStyles() {
  const theme = useTheme() as FnTheme;
  const palette = theme.palette as unknown as Required<FnTheme["palette"]>;

  const styles: GlobalStylesProps["styles"] = {
    [`.${GRID_AREA_CLASSNAMES.grid}`]: {
      maxHeight: "100%",
      height: "100%",
      [`.${GRID_AREA_CLASSNAMES.area}`]: {
        position: "relative",
        maxHeight: "100%",
        height: "100%",
        overflow: "hidden",
      },
      [`.${GRID_AREA_CLASSNAMES.innerContainer}`]: {
        backgroundColor: palette.background.paper,
        overflow: "hidden",
        height: "100%",
        width: "100%",
      },
      [`.${GRID_AREA_CLASSNAMES.contentContainer}`]: {
        height: "100%",
        overflow: "hidden",
        width: "100%",
      },
    },
  };

  return {
    styles,
    classNames: GRID_AREA_CLASSNAMES,
  };
}
