import React, { type CSSProperties, type FC } from "react";

import { FLUXNINJA } from "#shared/consts";
import logoUrl from "#shared/fn-aperture-logo-full.svg";

const WIDTH = "auto";
const HEIGHT = `${48 * 1.4}px`;
const TOP = "-33px";
const RIGHT = "-9px";

export interface LogoProps extends Omit<CSSProperties, "translate"> {}

export const Logo: FC<LogoProps> = (props) => (
  <img
    src={logoUrl}
    alt={[FLUXNINJA, "logo"].join(" ")}
    {...props}
    style={{
      gridColumn: 2,
      justifySelf: "end",
      alignSelf: "center",
      width: WIDTH,
      height: HEIGHT,
      position: "relative",
      top: TOP,
      right: RIGHT,
      ...(props || {}),
    }}
  />
);
