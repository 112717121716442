import { gql } from "graphql-request";
import { useParams } from "react-router-dom";

import type { GetUserGroupsQuery } from "#shared/generated/graphql";
import { useGqlQuery } from "#shared/utils";

import type { UserGroupIdParam } from "../routes.definitions";

const QUERY_KEY = "userGroupQuery";
const UserGroupQuery = gql`
  query UserGroups($where: UserGroupBoolExp!) {
    userGroups(where: $where) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const useUserGroup = () => {
  const { userGroupId } = useParams<UserGroupIdParam>() as UserGroupIdParam;
  const findBy = { where: { id: { eq: userGroupId } } };
  const { error, isLoading, data, refetch } = useGqlQuery<
    unknown,
    Error,
    GetUserGroupsQuery
  >([QUERY_KEY, findBy], UserGroupQuery, findBy);

  return {
    error,
    loading: isLoading,
    refetch,
    data: data?.userGroups?.edges[0],
  };
};
