import { merge, noop } from "lodash";
import React, { type CSSProperties, type FC } from "react";
import ReactFlow, { type ReactFlowProps } from "react-flow-renderer";

import { CircuitBoundary } from "./circuit-boundary";
import { CircuitBreadcrumbs } from "./circuit-breadcrumbs";
import { FlowChartControls } from "./flow-chart-controls";

export type FlowChartProps = Omit<ReactFlowProps, "proOptions"> & {
  policyId: string | null;
};

const DEFAULT_PROPS: Pick<
  ReactFlowProps,
  "proOptions" | "fitViewOptions" | "fitView" | "zoomOnPinch"
> = {
  proOptions: {
    account: "paid-pro",
    hideAttribution: true,
  },
  fitViewOptions: {},
  fitView: true,
  zoomOnPinch: true,
};

export const MIN_ZOOM = 0.1;
export const INITIAL_VIEWPORT_X = 0;
export const INITIAL_VIEWPORT_Y = 0;

export const FlowChart: FC<FlowChartProps> = (props) => {
  const { policyId, fitViewOptions = {}, ...otherProps } = props;

  const onInit: ReactFlowProps["onInit"] = (event) => {
    (otherProps.onLoad || noop)(event);
  };

  return (
    <ReactFlow
      minZoom={MIN_ZOOM}
      {...{ ...otherProps, onInit }}
      {...merge(DEFAULT_PROPS, { fitViewOptions })}
    >
      <CircuitBreadcrumbs sx={circuitBreadcrumbsStyles} />
      <CircuitBoundary />
      <FlowChartControls />
    </ReactFlow>
  );
};

const circuitBreadcrumbsStyles: CSSProperties = {
  position: "absolute",
  top: 0,
  left: 5,
  zIndex: 4,
};
