import { gql } from "graphql-request";
import { noop } from "lodash";
import { useMemo } from "react";

import type {
  SecretsGroupDataQuery,
  SecretsGroupTitlesQuery,
  AddApertureSecretMutation,
  DeleteApertureSecretInput,
  DeleteApertureSecretMutation,
  CreateApertureSecretInput,
} from "#shared/generated/graphql";
import { useGqlMutation } from "#shared/utils";

import {
  type DataGridHook,
  type GroupsTitlesHook,
  useGridData,
} from "#organization/hooks";
import type { GqlResponseEdgeMapped } from "#organization/utils";

import {
  type GroupDataHook,
  useGroupData,
  useGroupsTitles,
} from "../../../../../hooks/group-by";

export type SecretsGroupBy = "name";

export const SECRETS_ENTITY_KEY = "apertureSecrets";

export const secretsGroupTitles = gql`
  query SecretsGroupTitles(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $distinctOn: [ApertureSecretSelectColumn!]
  ) {
    apertureSecrets(
      first: $first
      last: $last
      after: $after
      before: $before
      distinctOn: $distinctOn
    ) {
      edges {
        node {
          name
        }
      }
    }
  }
`;

type SecretGroupTitleData =
  SecretsGroupTitlesQuery["apertureSecrets"]["edges"][0]["node"];

export const useSecretGroupsTitles: GroupsTitlesHook<SecretsGroupBy> = (
  pageSize,
  page,
  setPage,
  groupByKey,
) =>
  useGroupsTitles<SecretsGroupBy, SecretGroupTitleData>(
    SECRETS_ENTITY_KEY,
    pageSize,
    page,
    setPage,
    groupByKey,
    secretsGroupTitles,
    (node) => node[groupByKey],
  );

type SecretGroupData =
  SecretsGroupDataQuery["apertureSecrets"]["edges"][0]["node"];

export type AdaptedSecretGroupData = SecretGroupData;

export const secretGroupData = gql`
  query SecretsGroupData(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $distinctOn: [ApertureSecretSelectColumn!]
    $where: ApertureSecretBoolExp
    $orderBy: [ApertureSecretOrderBy]
  ) {
    apertureSecrets(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      orderBy: $orderBy
      distinctOn: $distinctOn
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          name
          value
          createdAt
          createdBy {
            name
          }
        }
      }
    }
  }
`;

export const useSecretGroupData: GroupDataHook<SecretsGroupBy> = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  groupByKey,
  groupByValue,
  filterVariables,
) =>
  useGroupData<SecretsGroupBy, SecretGroupData, AdaptedSecretGroupData>(
    SECRETS_ENTITY_KEY,
    secretGroupData,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    groupByKey,
    groupByValue,
    (secret) => ({
      ...secret,
    }),
    filterVariables,
  );

export const useSecretsData: DataGridHook = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  enabled,
  filterVariables,
) =>
  useGridData<SecretGroupData, AdaptedSecretGroupData>(
    SECRETS_ENTITY_KEY,
    secretGroupData,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    filterVariables,
    (secret: SecretGroupData) => ({
      ...secret,
    }),
    enabled,
  );

export const useSecretNames = () => {
  const s = useSecretGroupsTitles(25, 1, noop, "name", {});

  return useMemo(
    () =>
      (
        s.query.data as GqlResponseEdgeMapped<
          SecretGroupTitleData["name"]
        > | null
      )?.edges || [],
    [s.query.data],
  );
};

const AddSecret = gql`
  mutation AddApertureSecret($input: CreateApertureSecretInput!) {
    createApertureSecret(input: $input) {
      apertureSecret {
        id
      }
    }
  }
`;

export const useAddSecret = () =>
  useGqlMutation<AddApertureSecretMutation, unknown, CreateApertureSecretInput>(
    AddSecret,
  );

const DeleteSecret = gql`
  mutation DeleteApertureSecret($input: DeleteApertureSecretInput!) {
    deleteApertureSecret(input: $input) {
      apertureSecret {
        id
      }
    }
  }
`;

export const useDeleteSecret = () =>
  useGqlMutation<
    DeleteApertureSecretMutation,
    unknown,
    DeleteApertureSecretInput
  >(DeleteSecret);
