import { Avatar, Grid, Button, type GridProps } from "@mui/material";
import React, { type FC } from "react";
import { useRecoilValue } from "recoil";

import { ErrorLayout, ErrorText } from "#shared/components/layouts";
import { userNewState } from "#shared/recoil";
import type { Route, Texts } from "#shared/types";
import { FeatureFlag } from "#shared/utils";

import { Card } from "#organization/components/card";
import { LinkWithoutUnderline } from "#organization/components/link-without-underline";
import { useOrganizationState } from "#organization/recoil/organization";
import { useHeaderSectionUpdate } from "#organization/recoil/organization/header-section";

import { ACCOUNT_SETTINGS_ROUTE } from "./account";
import { ACCOUNT_DETAILS_ROUTE } from "./account/account-details";
import { CLOSE_ACCOUNT_ROUTE } from "./account/close-account";
import { USER_API_KEYS_ROUTE } from "./account/user-api-keys";
import { QuickLinks } from "./components";
import { ORGANIZATION_SETTINGS_ROUTE } from "./organization";
import { AUTHN_SETTINGS_ROUTE } from "./organization/authn/routes.definitions";
import { BILLING_SETTINGS_ROUTE } from "./organization/billing/routes.definitions";
import { GENERAL_SETTINGS_ROUTE } from "./organization/general-settings";
import { useIsAuthnSettings } from "./organization/hooks";
import { MEMBERS_SETTINGS_ROUTE } from "./organization/members";
import { PROJECTS_SETTINGS_ROUTE } from "./organization/projects";
import { USER_GROUPS_SETTINGS_ROUTE } from "./organization/user-groups";
import { SETTINGS_ROUTE } from "./routes.definitions";

export interface SettingsPageProps {
  texts?: Texts<Text>;
}

type Text = "seeAllAccountSettings" | "seeAllOrganizationSettings";

const enTexts: Required<SettingsPageProps["texts"]> = {
  seeAllAccountSettings: "See all account settings",
  seeAllOrganizationSettings: "See all organization settings",
};

export const SettingsPage: FC<SettingsPageProps> = ({ texts = enTexts }) => {
  const organizationState = useOrganizationState();
  const { data: user } = useRecoilValue(userNewState.state);

  useHeaderSectionUpdate({
    pageTitle: SETTINGS_ROUTE.TITLE,
  });

  const isAuthnSettings = useIsAuthnSettings();

  if (!user) {
    return (
      <ErrorLayout>
        <ErrorText />
      </ErrorLayout>
    );
  }

  const itemProps: GridProps = { xs: 12, sm: 6, md: 6, lg: 6, item: true };

  return (
    <Grid container spacing={2} px={3} columns={12}>
      <Grid {...itemProps}>
        <Card
          title={ACCOUNT_SETTINGS_ROUTE.TITLE}
          subtitle={user.email || ""}
          avatar={<Avatar variant="rounded" src={user.picture || ""} />}
          content={
            <FeatureFlag
              flag="closeAccount"
              renderOn={
                <QuickLinks
                  routes={[ACCOUNT_DETAILS_ROUTE, CLOSE_ACCOUNT_ROUTE]}
                />
              }
              renderOff={
                <QuickLinks
                  routes={[ACCOUNT_DETAILS_ROUTE, USER_API_KEYS_ROUTE]}
                />
              }
            />
          }
          actions={
            <LinkWithoutUnderline
              to={ACCOUNT_DETAILS_ROUTE.ABSOLUTE_PATH}
              sx={{ selfAlign: "flex-end", display: "flex" }}
            >
              <Button variant="outlined">{texts.seeAllAccountSettings}</Button>
            </LinkWithoutUnderline>
          }
        />
      </Grid>
      <Grid {...itemProps}>
        <Card
          title={ORGANIZATION_SETTINGS_ROUTE.TITLE}
          subtitle={organizationState.name}
          avatar={
            <Avatar variant="rounded">{organizationState.name[0]}</Avatar>
          }
          content={
            <FeatureFlag
              flag={["samlSignInConfiguration", "googleSignInConfiguration"]}
              all={false}
              renderOn={
                <QuickLinks
                  routes={
                    [
                      PROJECTS_SETTINGS_ROUTE,
                      USER_GROUPS_SETTINGS_ROUTE,
                      MEMBERS_SETTINGS_ROUTE,
                      isAuthnSettings ? AUTHN_SETTINGS_ROUTE : undefined,
                      isAuthnSettings ? BILLING_SETTINGS_ROUTE : undefined,
                    ].filter(Boolean) as Route[]
                  }
                />
              }
              renderOff={
                <QuickLinks
                  routes={
                    [
                      PROJECTS_SETTINGS_ROUTE,
                      USER_GROUPS_SETTINGS_ROUTE,
                      MEMBERS_SETTINGS_ROUTE,
                      isAuthnSettings ? BILLING_SETTINGS_ROUTE : undefined,
                    ].filter(Boolean) as Route[]
                  }
                />
              }
            />
          }
          actions={
            <LinkWithoutUnderline
              to={GENERAL_SETTINGS_ROUTE.ABSOLUTE_PATH}
              sx={{ display: "flex" }}
            >
              <Button variant="outlined">
                {texts.seeAllOrganizationSettings}
              </Button>
            </LinkWithoutUnderline>
          }
        />
      </Grid>
    </Grid>
  );
};
