import { Box, Paper, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { type FC, type PropsWithChildren, type ReactNode } from "react";

const StyledWidget = styled(Paper)(({ theme }) => ({
  width: "max-content",
  minWidth: 200,
  position: "relative",
  overflow: "hidden",
  boxShadow:
    "0px 0px 2px rgba(0, 0, 0, 0.1), 0px 3px 8px rgba(9, 32, 77, 0.12)",
  padding: "10px 20px",
  margin: "0 16px 16px 0",
  borderRadius: theme.spacing(0.5),
  display: "grid",
  gridTemplateRows: "28px 1fr",
}));

export type WidgetProps = PropsWithChildren<{
  header: ReactNode;
  footer?: ReactNode;
}>;

export const Widget: FC<WidgetProps> = ({ header, footer, children }) => (
  <StyledWidget
    variant="outlined"
    sx={footer ? { gridTemplateRows: "28px 1fr 20px" } : null}
  >
    <Box>
      <Typography variant="subtitle1">{header}</Typography>
    </Box>
    <Box
      sx={{
        py: "5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {children}
    </Box>
    {footer && (
      <Typography sx={{ alignSelf: "end" }} variant="body2">
        {footer}
      </Typography>
    )}
  </StyledWidget>
);

Widget.defaultProps = {
  footer: undefined,
};
