import { TextField, type TextFieldProps } from "@mui/material";
import { styled } from "@mui/system";

export type InputProps = TextFieldProps & {
  width?: string;
  disabled?: boolean;
};

// TODO: It can be done in theme overrides
export const Input = styled(TextField)<InputProps>(({
  width = "20vw",
  theme,
}) => {
  const borderColor =
    theme.palette.mode === "dark"
      ? theme.palette.grey["600"]
      : theme.palette.grey["300"];

  return {
    width,
    "& .MuiOutlinedInput-root": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.inputField,
      "& fieldset": {
        borderColor,
      },
      "&:hover fieldset": {
        borderColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&.Mui-disabled fieldset": {
        borderColor,
        backgroundColor: theme.palette.action.disabledBackground,
      },
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  };
});
