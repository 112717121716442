import type { TabProps, TabTypeMap } from "@mui/material";

import { DASHBOARDVER } from "#shared/dashboard-versions";

import { DASHBOARDS, type DashboardType } from "#organization/pages/consts";
import type {
  DashboardConfig,
  MapDashboardTypeToDashboardFilters,
  ResourceWithGenericDashboard,
} from "#organization/pages/types";

export type ResourceDashboardConfig<
  D extends DashboardType | undefined = undefined,
> = (D extends DashboardType
  ? Omit<DashboardConfig<D>, "hiddenVariables"> & {
      /**
       * Hidden filters derived from the context
       */
      hiddenVariables: MapDashboardTypeToDashboardFilters<D>[];
    }
  : {}) & {
  tabProps?: D extends DashboardType ? TabPropsByDashboardType<D> : undefined;
};

type DashboardsByResourceType = {
  [R in ResourceWithGenericDashboard]: {
    [D in DashboardType]?: ResourceDashboardConfig<D>;
  };
};

type TabPropsByDashboardType<D extends DashboardType> = Omit<
  TabProps<TabTypeMap["defaultComponent"]>,
  "id" | "value" | "label"
> & {
  label: string;
  id: D;
  value: D;
};

const tabPropsByDashboardType: {
  [D in DashboardType]?: TabPropsByDashboardType<D>;
} = {
  FLOW_ANALYTICS: {
    label: "Flow Analytics",
    id: "FLOW_ANALYTICS",
    value: "FLOW_ANALYTICS",
  },
  FLOW_ANALYTICS_BY_FLOW_LABEL: {
    label: "Flow Analytics By Flow Label",
    id: "FLOW_ANALYTICS_BY_FLOW_LABEL",
    value: "FLOW_ANALYTICS_BY_FLOW_LABEL",
  },
  PROMETHEUS: {
    label: "Latency Metrics",
    id: "PROMETHEUS",
    value: "PROMETHEUS",
  },
  RATE_LIMITER: {
    label: "Rate Limiter",
    id: "RATE_LIMITER",
    value: "RATE_LIMITER",
  },
  CONCURRENCY_LIMITER: {
    label: "Concurrency Limiter",
    id: "CONCURRENCY_LIMITER",
    value: "CONCURRENCY_LIMITER",
  },
};

export const dashboardsByResourceType: DashboardsByResourceType = {
  FluxMeter: {
    /**
     * NOTE: As in "FN Data Visualization System" spec
     * Listed in fluxMeters table
     * */
    FLOW_ANALYTICS: {
      uuid: DASHBOARDS.FLOW_ANALYTICS,
      filters: [],
      hiddenVariables: [
        "var-fn_project_id",
        "var-controller_id",
        "var-flux_meter_name",
      ],
      tabProps: tabPropsByDashboardType.FLOW_ANALYTICS,
      name: "fluxMeter",
      slug: "fluxMeter",
      version: DASHBOARDVER.FLOW_ANALYTICS,
      queryParams: {},
    },
    PROMETHEUS: {
      uuid: DASHBOARDS.PROMETHEUS,
      filters: [],
      hiddenVariables: ["var-fn_project_id", "var-flux_meter_name"],
      tabProps: tabPropsByDashboardType.PROMETHEUS,
      name: "fluxMeter",
      slug: "fluxMeter",
      version: DASHBOARDVER.FLUX_METER,
      queryParams: {},
    },
  },
  Classifier: {
    /**
     * NOTE: As in "FN Data Visualization System" spec
     * Listed in classifiers table
     * */
    FLOW_ANALYTICS: {
      uuid: DASHBOARDS.FLOW_ANALYTICS,
      hiddenVariables: [
        "var-controller_id",
        "var-fn_project_id",
        "var-classifiers",
      ],
      filters: [],
      tabProps: tabPropsByDashboardType.FLOW_ANALYTICS,
      name: "classifier",
      slug: "classifier",
      version: DASHBOARDVER.FLOW_ANALYTICS,
      queryParams: {},
    },
  },
  /**
   * NOTE: As in "FN Data Visualization System" spec
   * ConcurrencyLimiter is clickable in the policy circuit (graph)
   */
  ConcurrencyLimiter: {
    CONCURRENCY_LIMITER: {
      uuid: DASHBOARDS.CONCURRENCY_LIMITER,
      hiddenVariables: [
        "var-controller_id",
        "var-policy_name",
        "var-component_id",
        "var-policy_hash",
      ],
      name: "concurrencyLimiter",
      slug: "concurrencyLimiter",
      version: DASHBOARDVER.CONCURRENCY_LIMITER,
      filters: [],
      queryParams: {},
    },
  },
  /**
   * NOTE: As in "FN Data Visualization System" spec
   * RateLimiter is clickable in the policy circuit (graph)
   */
  RateLimiter: {
    RATE_LIMITER: {
      uuid: DASHBOARDS.RATE_LIMITER,
      hiddenVariables: [
        "var-controller_id",
        "var-policy_name",
        "var-component_id",
        "var-policy_hash",
      ],
      name: "rateLimiter",
      slug: "rateLimiter",
      version: DASHBOARDVER.RATE_LIMITER,
      filters: [],
      queryParams: {},
    },
  },
  Signal: {
    SIGNAL: {
      uuid: DASHBOARDS.SIGNAL,
      hiddenVariables: [
        "var-fn_project_id",
        "var-policy_name",
        "var-signal_name",
        "var-sub_circuit_id",
      ],
      name: "signal",
      slug: "signal",
      version: DASHBOARDVER.SIGNAL,
      filters: [],
      queryParams: {},
    },
  },
};
