import { FormGroup, type SwitchProps } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import React, { type FC } from "react";

import { useThemeState } from "#organization/recoil/theme";

import { Switch } from "./styled";

import { navigationTheme } from "../../../../app-theme-provider";
import { useFlipThemeState } from "../../recoil/theme";

export interface ThemeSwitchProps
  extends Omit<SwitchProps, "onChange" | "checked"> {}

export const ThemeSwitch: FC<ThemeSwitchProps> = (props) => {
  const themeController = useFlipThemeState();
  const theme = useThemeState();

  return (
    <ThemeProvider theme={navigationTheme(theme.mode)}>
      <FormGroup>
        <Switch
          onChange={themeController.flip}
          checked={themeController.theme.mode === "dark"}
          {...props}
        />
      </FormGroup>
    </ThemeProvider>
  );
};
