import { TextField } from "@mui/material";
import React from "react";
import { useFormContext, type Path, type FieldValues } from "react-hook-form";

import { FieldWithTitle, FormFieldWrapper } from "../../../styled";
import * as QuotaSchedulerBlueprint from "../../quota-scheduling/@types";
import type { BlueprintFieldProps } from "../types";
import { createFieldNameWithPrefix } from "../utils";

export declare type DynamicWorkloadsProps<TFields extends FieldValues> =
  {} & BlueprintFieldProps<TFields>;

export const DynamicWorkloads = <
  TFields extends FieldValues = QuotaSchedulerBlueprint.Coordinate,
>({
  fieldsPrefix = "policy.quota_scheduler.scheduler" as Path<TFields>,
}: DynamicWorkloadsProps<TFields>) => {
  const { register } = useFormContext<TFields>();

  return (
    <FormFieldWrapper>
      <FieldWithTitle
        label="Priority label key"
        fieldAddress={createFieldNameWithPrefix<TFields>(
          fieldsPrefix,
          "priority_label_key",
        )}
      >
        <TextField
          {...{
            ...register(
              createFieldNameWithPrefix<TFields>(
                fieldsPrefix,
                "priority_label_key",
              ),
            ),
            placeholder: "Priority label key",
          }}
        />
      </FieldWithTitle>
      <FieldWithTitle
        label="Token label key"
        fieldAddress={createFieldNameWithPrefix(
          fieldsPrefix,
          "tokens_label_key",
        )}
      >
        <TextField
          {...{
            ...register(
              createFieldNameWithPrefix<TFields>(
                fieldsPrefix,
                "tokens_label_key",
              ),
            ),
            placeholder: "Token label key",
          }}
        />
      </FieldWithTitle>

      <FieldWithTitle
        label="Workload label key"
        fieldAddress={createFieldNameWithPrefix(
          fieldsPrefix,
          "workload_label_key",
        )}
      >
        <TextField
          {...{
            ...register(
              createFieldNameWithPrefix<TFields>(
                fieldsPrefix,
                "workload_label_key",
              ),
            ),
            placeholder: "Workload label key",
          }}
        />
      </FieldWithTitle>

      <FieldWithTitle
        label="Fairness label key"
        fieldAddress={createFieldNameWithPrefix(
          fieldsPrefix,
          "fairness_label_key",
        )}
      >
        <TextField
          {...{
            ...register(
              createFieldNameWithPrefix<TFields>(
                fieldsPrefix,
                "fairness_label_key",
              ),
            ),
            placeholder: "Fairness label key",
          }}
        />
      </FieldWithTitle>
    </FormFieldWrapper>
  );
};
