import { gql } from "graphql-request";

import type { NoKubernetesCheckQuery } from "#shared/generated/graphql";
import { useGqlQuery } from "#shared/utils";

const noKubernetesCheck = gql`
  query NoKubernetesCheck($where: KubernetesControlPointBoolExp) {
    kubernetesControlPoints(where: $where) {
      totalCount
    }
  }
`;

export const useKubernetesCheck = (projectId: string) => {
  const { data, isLoading, isError, refetch } =
    useGqlQuery<NoKubernetesCheckQuery>(
      ["discoveryKubernetesCheck"],
      noKubernetesCheck,
      {
        where: {
          projectID: { eq: projectId },
        },
      },
    );

  return {
    isConfigured: (data?.kubernetesControlPoints.totalCount || 0) > 0,
    isLoading,
    isError,
    refetch,
  };
};
