import { Box, styled } from "@mui/material";

export const OuterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "grid",
  gridTemplateAreas: "'submenu child'",
  gridTemplateColumns: "250px 1fr",
  gridGap: theme.spacing(2),
  minHeight: "100%",
}));
