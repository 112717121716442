import { Box, type BoxProps } from "@mui/material";
import React, { type FC } from "react";

export type TabPanelProps<V extends string | number = string | number> =
  BoxProps & {
    value: V;
    activeValue: V;
  };

export const TabPanel: FC<TabPanelProps> = ({
  value,
  activeValue,
  ...boxProps
}) => (value !== activeValue ? null : <Box role="tabpanel" {...boxProps} />);
