import { SvgIcon, type SvgIconProps } from "@mui/material";
import React, { type FC, forwardRef, type PropsWithRef } from "react";

export const DocumentationIcon: FC<PropsWithRef<SvgIconProps>> = forwardRef(
  (props, ref) => (
    <SvgIcon {...props} ref={ref}>
      <path d="M15.0402 9.13984H7.58018C7.36018 9.13984 7.18018 8.95984 7.18018 8.73984C7.18018 8.51984 7.36018 8.33984 7.58018 8.33984H15.0402C15.2602 8.33984 15.4402 8.51984 15.4402 8.73984C15.4402 8.95984 15.2602 9.13984 15.0402 9.13984Z" />
      <path d="M15.04 11.2297H10.67C10.45 11.2297 10.27 11.0497 10.27 10.8297C10.27 10.6097 10.45 10.4297 10.67 10.4297H15.04C15.26 10.4297 15.44 10.6097 15.44 10.8297C15.44 11.0497 15.26 11.2297 15.04 11.2297Z" />
      <path d="M9.09018 11.2297H7.58018C7.36018 11.2297 7.18018 11.0497 7.18018 10.8297C7.18018 10.6097 7.36018 10.4297 7.58018 10.4297H9.09018C9.31018 10.4297 9.49018 10.6097 9.49018 10.8297C9.49018 11.0497 9.31018 11.2297 9.09018 11.2297Z" />
      <path d="M15.0403 13.3195H13.6803C13.4603 13.3195 13.2803 13.1395 13.2803 12.9195C13.2803 12.6995 13.4603 12.5195 13.6803 12.5195H15.0403C15.2603 12.5195 15.4403 12.6995 15.4403 12.9195C15.4403 13.1395 15.2603 13.3195 15.0403 13.3195Z" />
      <path d="M12.2502 13.3195H7.58018C7.36018 13.3195 7.18018 13.1395 7.18018 12.9195C7.18018 12.6995 7.36018 12.5195 7.58018 12.5195H12.2502C12.4702 12.5195 12.6502 12.6995 12.6502 12.9195C12.6502 13.1395 12.4702 13.3195 12.2502 13.3195Z" />
      <path d="M12.4502 7.05977H7.58018C7.36018 7.05977 7.18018 6.87977 7.18018 6.65977C7.18018 6.43977 7.36018 6.25977 7.58018 6.25977H12.4402C12.6602 6.25977 12.8402 6.43977 12.8402 6.65977C12.8402 6.87977 12.6602 7.05977 12.4402 7.05977H12.4502Z" />
      <path d="M17.3497 16.8401H5.21967C4.76967 16.8401 4.40967 16.4801 4.40967 16.0301V1.4101C4.40967 0.960098 4.76967 0.600098 5.21967 0.600098H14.9997C15.2097 0.600098 15.4197 0.690098 15.5697 0.840098L17.9097 3.1801C18.0597 3.3301 18.1497 3.5401 18.1497 3.7501V16.0201C18.1497 16.4701 17.7897 16.8301 17.3397 16.8301L17.3497 16.8401ZM5.21967 1.4001V16.0301H17.3497V3.7501L15.0197 1.4001H5.21967Z" />
      <path d="M14.7798 19.4002H2.64984C2.19984 19.4002 1.83984 19.0402 1.83984 18.5902V3.97016C1.83984 3.52016 2.19984 3.16016 2.64984 3.16016H3.51984C3.73984 3.16016 3.91984 3.34016 3.91984 3.56016C3.91984 3.78016 3.73984 3.96016 3.51984 3.96016H2.64984V18.5902H14.7798V17.7202C14.7898 17.5002 14.9698 17.3202 15.1898 17.3202C15.4098 17.3202 15.5898 17.5002 15.5898 17.7202V18.5902C15.5898 19.0402 15.2298 19.4002 14.7798 19.4002Z" />
      <path d="M17.7598 3.98H15.5898C15.1398 3.98 14.7798 3.62 14.7798 3.17V1H15.5798V3.17H17.7598V3.98Z" />
    </SvgIcon>
  ),
);
