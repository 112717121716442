import { TableCell, TableRow, TableBody, TableSortLabel } from "@mui/material";
import { alpha, styled } from "@mui/system";

import type { DataGridVariants } from "./types";

interface HeaderVariantProps {
  variant: DataGridVariants;
}

export const HeaderRow = styled(TableRow)<HeaderVariantProps>(({
  theme,
  theme: {
    palette: { mode },
  },
  variant = "basic",
}) => {
  if (variant === "settings" || variant === "flow" || variant === "primary") {
    return { backgroundColor: "transparent" };
  }

  return {
    height: "56px",
    backgroundColor: theme.palette.tableGrid.headColor,
    borderBottom: `3px solid ${
      mode === "light"
        ? alpha(theme.palette.divider, 0.2)
        : theme.palette.background.default
    }`,
  };
});

export const HeaderTypography = styled("span")<HeaderVariantProps>(({
  variant = "basic",
  theme,
}) => {
  let color = theme.palette.text.primary;

  if (variant === "settings") color = theme.palette.primary.dark;

  if (variant === "flow") color = theme.palette.secondary.light;

  if (variant === "primary") color = theme.palette.primary.main;

  return {
    fontSize: "14px",
    fontWeight: 500,
    color,
  };
});

export const BodyRow = styled(TableRow)<HeaderVariantProps>(({
  theme: {
    palette: { mode },
  },
  onClick,
}) => {
  let hover = "transparent";

  if (onClick) {
    hover = mode === "light" ? "#F4F3F0" : "#43403C";
  }

  const cursor = onClick ? "pointer" : "default";

  return {
    height: "46px",
    "&:hover": {
      backgroundColor: hover,
      cursor,
    },
  };
});

export const StyledTableBody = styled(TableBody)(
  ({
    theme,
    theme: {
      palette: { mode },
    },
  }) => ({
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.background.paper,
    "& tr:not(:last-of-type)": {
      borderBottom: `1px solid ${
        mode === "light"
          ? theme.palette.divider
          : theme.palette.background.default
      }`,
    },

    "& tr:last-of-type": {
      "& td:first-of-type": {
        borderRadius: theme.spacing(0, 0, 0, 0.5),
      },
      "& td:last-of-type": {
        borderRadius: theme.spacing(0, 0, 0.5, 0),
      },
    },
  }),
);

export const BodyCell = styled(TableCell)(() => ({
  padding: "0 6px 0 32px",
  borderBottom: "0px solid transparent",
}));

export const SortArrow = styled(TableSortLabel)<HeaderVariantProps>(
  ({ variant = "basic", theme }) => ({
    "& svg": {
      fill:
        variant !== "basic"
          ? theme.palette.primary.contrastText
          : theme.palette.text.primary,
    },
  }),
);
