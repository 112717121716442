import agent_group from "./agent_group.mdx";
import blueprint from "./blueprint.mdx";
import control_point from "./control_point.mdx";
import denied_response_status_code from "./denied_response_status_code.mdx";
import label_matcher from "./label_matcher.mdx";
import limit_label_key from "./limit_label_key.mdx";
import max_concurrency from "./max_concurrency.mdx";
import max_idle_time from "./max_idle_time.mdx";
import max_inflight_duration from "./max_inflight_duration.mdx";
import service from "./service.mdx";
import tokens_label_key from "./tokens_label_key.mdx";

import type { RecursiveStringify } from "../../types";
import * as ConcurrencyLimitingTypes from "../@types";

export type ConcurrencyLimitingDocsTree =
  RecursiveStringify<ConcurrencyLimitingTypes.Coordinate>;

export const concurrencyLimitingDocsTree: ConcurrencyLimitingDocsTree = {
  blueprint,
  policy: {
    policy_name: blueprint,
    concurrency_limiter: {
      max_concurrency,
      parameters: {
        limit_by_label_key: limit_label_key,
        max_idle_time,
        max_inflight_duration,
      },
      request_parameters: {
        tokens_label_key,
        denied_response_status_code,
      },
      selectors: [
        {
          control_point,
          agent_group,
          service,
          label_matcher,
        },
      ],
    },
  },
};
