import React, { useState, type FC, useEffect } from "react";

import { InfoDataPage } from "#shared/components/layouts";
import { BASE_DOCUMENTATION_URL } from "#shared/consts";
import type { Texts } from "#shared/types";
import { deleteFromLS, getFromLS, saveToLS } from "#shared/utils/local-storage";

export interface ControlPointsInstalledProps {
  texts?: Texts<Text>;
}

type Text =
  | "controlPointsInstalled"
  | "controlPointsInstalledDesc"
  | "blueprints"
  | "blueprintsDesc"
  | "policyBlueprints"
  | "customPolicies"
  | "customPoliciesDesc"
  | "writeOwnPolicy";

const enTexts: ControlPointsInstalledProps["texts"] = {
  controlPointsInstalled: "Great! You successfully installed Control Points!",
  controlPointsInstalledDesc:
    "Now you can start your journey with Policies to control your traffic. Check out policy blueprints and read how to write your first policy. ",
  blueprints: "Blueprints",
  blueprintsDesc:
    "FluxNinja provides pre-packaged blueprints as templates for easy policy creation. These blueprints cover many common scenarios, making it simpler to create complex policy circuits.",
  policyBlueprints: "Policy blueprints",
  customPolicies: "Creating Custom Policies",
  customPoliciesDesc:
    "FluxNinja Aperture lets you build policies from scratch. Choose this option to use circuit components and define your own control logic. ",
  writeOwnPolicy: "How to write your own policy",
};

const DISMISS_LS_KEY = "dismissedControlPointsInstalled";

export const ControlPointsInstalled: FC<ControlPointsInstalledProps> = ({
  texts = enTexts,
}) => {
  const [dismissed, setDismissed] = useState<boolean>(
    !!getFromLS(DISMISS_LS_KEY),
  );

  useEffect(() => {
    if (dismissed) {
      saveToLS(DISMISS_LS_KEY, "true");
    } else {
      deleteFromLS(DISMISS_LS_KEY);
    }
  }, [dismissed]);

  const actions = [
    {
      title: texts.blueprints,
      description: texts.blueprintsDesc,
      buttonText: texts.policyBlueprints,
      buttonProps: {
        href: `${BASE_DOCUMENTATION_URL}/reference/blueprints/`,
        target: "__blank",
      },
    },
    {
      title: texts.customPolicies,
      description: texts.customPoliciesDesc,
      buttonText: texts.writeOwnPolicy,
      buttonProps: {
        href: `${BASE_DOCUMENTATION_URL}/reference/configuration/spec#Policy`,
        target: "__blank",
      },
    },
  ];

  if (dismissed) {
    return null;
  }

  return (
    <InfoDataPage
      title={texts.controlPointsInstalled}
      description={texts.controlPointsInstalledDesc}
      actions={actions}
      img="happy-ninja"
      dismissable
      onDismiss={() => setDismissed(true)}
    />
  );
};
