import { filter } from "lodash";
import { type Edge, MarkerType, type Node } from "react-flow-renderer";

import type {
  ComponentView,
  Maybe,
  SignalName,
} from "#shared/generated/graphql";

import { findLinkParentDetails } from "./custom-nodes-edges";
import { getCircuitColors } from "./custom-nodes-edges/style-nodes-edges";

import type { FnPalette } from "../../../../../../../app-theme-provider/types";
import type { NodeName, NodeType } from "../../../../../../../types";
import { PolicyUtils } from "../policy-utils";
import type {
  EdgeData,
  NodeData,
  CircuitComponent,
  CircuitEdge,
} from "../types";

export const setupEdges = (
  edgeData: EdgeData[] | null,
  palette: FnPalette,
  nodes: Node<CircuitComponent>[],
) => (edgeData?.length ? edgeData.map(mapEdge(palette, nodes)) : []);

export type SetupNodesPayload = {
  nodesData: NodeData[];
  actuators?: Maybe<ComponentView>[];
  palette?: FnPalette;
};

export const setupNodes = (
  nodesData: NodeData[],
  actuators: Maybe<ComponentView>[] = [],
  palette?: FnPalette,
) => nodesData.map(mapNode(nodesData, undefined, actuators, palette));

export function mapNode(
  nodesData: NodeData[] | ReadonlyArray<NodeData>,
  _: (node: string | undefined) => NodeData | null = PolicyUtils.findNodeById(
    nodesData,
  ),
  actuators: Maybe<ComponentView>[] = [],
  palette?: FnPalette,
) {
  return (node: NodeData) => {
    const isActuator = actuators.some(
      (actuator) => actuator?.componentId === node.componentId,
    );

    const uiData: CircuitComponent["uiData"] = {
      name: node.componentName,
      id: node.componentId,
      componentName: node.componentName as NodeName,
      componentType: node.componentType,
      component: node.component,
      style: {
        ...(palette &&
          getCircuitColors(
            palette,
            node.componentType as NodeType,
            node.componentName as NodeName,
          ).component),
        ...(isActuator &&
          palette &&
          getCircuitColors(
            palette,
            node.componentType as NodeType,
            "ConcurrencyLimiter",
          ).component),
      },
    };

    const mappedNode: Node<CircuitComponent> = {
      id: node.componentId,
      position: { x: 0, y: 0 },
      style: { fontSize: "small" },
      type: `common`,
      data: {
        ...node,
        uiData,
        ...(isActuator &&
          !PolicyUtils.isNodeWithDashboard(node) && {
            componentName: "ConcurrencyLimiter",
          }),
      },
    };

    return mappedNode;
  };
}

export function mapEdge(palette?: FnPalette, nodes?: Node<CircuitComponent>[]) {
  return (
    edge: EdgeData,
    _: number,
    edges: EdgeData[] | ReadonlyArray<EdgeData>,
  ) => {
    if (!edge.source || !edge.value || !edge.target)
      return {} as Edge<CircuitEdge>;

    const source = edge.source.componentId;
    const target = edge.target.componentId;

    const sourceHandle = [source, edge.source.portName].join(".");
    const targetHandle = [target, edge.target.portName].join(".");

    const id = PolicyUtils.encodeEdgeId(edge);

    const edgeWithSignalNamePredicate = {
      value: {
        signalName: (edge.value as unknown as SignalName)?.signalName,
      },
    };

    const uiData: CircuitEdge["uiData"] = {
      id,
      name: edgeWithSignalNamePredicate.value.signalName,
      style: {
        strokeWidth: 2,
      },
      componentName: "SIGNAL",
      signalEdges: filter(edges, edgeWithSignalNamePredicate) as EdgeData[],
    };

    const mappedEdge: Edge<CircuitEdge> = {
      id,
      sourceHandle,
      targetHandle,
      source,
      target,
      type: "smart",
      data: {
        ...edge,
        uiData,
      },
    };

    if (!nodes?.length || !palette) return mappedEdge;

    const { componentName, componentType } = findLinkParentDetails(
      {
        ...edge,
        uiData,
      },
      nodes,
    );

    const { component } = getCircuitColors(
      palette,
      componentType as NodeType,
      componentName as NodeName,
      false,
    );

    return {
      ...mappedEdge,
      markerEnd: { type: MarkerType.Arrow, color: component.backgroundColor },
    };
  };
}
