import React, { type FC } from "react";
import { Route, Routes } from "react-router-dom";

import { useSetPageTitle } from "#shared/hooks";

import { WelcomePage } from "./page";
import { WELCOME_ROUTE } from "./routes.definitions";

export const WelcomeRoutes: FC = () => {
  useSetPageTitle(WELCOME_ROUTE.TITLE);

  return (
    <Routes>
      <Route index element={<WelcomePage />} />
    </Routes>
  );
};
