import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import type { HeadCell } from "#shared/components/data-grid";

import type { UserGroupPageData } from "./types";

const DELETE = "Delete";
const SETTINGS = "Settings";

export const headCells = (
  permissionLock: boolean,
  deleteUserGroup: (id: string) => void,
): HeadCell<UserGroupPageData>[] => [
  {
    accessor: "userGroupName",
    label: "User Group",
    key: "userGroupName",
  },
  {
    accessor: "noOfUsers",
    label: "Users",
    key: "noOfUsers",
  },
  {
    accessor: "noOfProjects",
    label: "Projects",
    key: "noOfProjects",
  },
  {
    accessor: (row) => (
      <>
        <Link to={row.id}>
          <Button variant="text" size="small">
            {SETTINGS}
          </Button>
        </Link>
        <Button
          variant="text"
          size="small"
          color="error"
          disabled={permissionLock}
          onClick={() => {
            deleteUserGroup(row.id);
          }}
        >
          {DELETE}
        </Button>
      </>
    ),
    label: "Actions",
    noSorting: true,
    key: "actions",
    width: "250px",
  },
];
