/* eslint-disable no-nested-ternary */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-empty */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-explicit-any */
// To parse this data:
//
//   import { Convert, Coordinate } from "./file";
//
//   const coordinate = Convert.toCoordinate(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export type Coordinate = {
  /**
   * Blueprint name
   */
  blueprint: string;
  policy: Policy;
  /**
   * Blueprint URI. E.g. github.com/fluxninja/aperture/blueprints@latest.
   */
  uri?: string;
};

export type Policy = {
  /**
   * List of additional circuit components.
   */
  components?: PolicyComponent[];
  /**
   * Name of the policy.
   */
  policy_name: string;
  quota_scheduler: PolicyQuotaScheduler;
  /**
   * Additional resources.
   */
  resources?: Resources;
};

/**
 * :::info
 *
 * See also [Components overview](/concepts/advanced/circuit.md#components).
 *
 * :::
 *
 * Signals flow into the components from input ports and results are emitted on output
 * ports.
 * Components are wired to each other based on signal names forming an execution graph of
 * the circuit.
 *
 * :::note
 *
 * Loops are broken by the runtime at the earliest component index that is part of the loop.
 * The looped signals are saved in the tick they're generated and served in the subsequent
 * tick.
 *
 * :::
 *
 * See also [Policy](#policy) for a higher-level explanation of circuits.
 */
export type PolicyComponent = {
  /**
   * Alerter reacts to a signal and generates alert to send to alert manager.
   */
  alerter?: Alerter;
  /**
   * Logical AND.
   */
  and?: And;
  /**
   * Applies the given operator on input operands (signals) and emits the result.
   */
  arithmetic_combinator?: TypeOfCombinatorThatComputesTheArithmeticOperationOnTheOperandSignals;
  /**
   * AutoScale components are used to scale the service.
   */
  auto_scale?: AutoScale;
  /**
   * BoolVariable emits a constant Boolean signal which can be changed at runtime through
   * dynamic configuration.
   */
  bool_variable?: BoolVariable;
  /**
   * Decider emits the binary result of comparison operator on two operands.
   */
  decider?: TypeOfCombinatorThatComputesTheComparisonOperationOnLHSAndRHSSignals;
  /**
   * Differentiator calculates rate of change per tick.
   * Deprecated: v3.0.0. Use `PIDController` instead.
   */
  differentiator?: Differentiator;
  /**
   * Exponential Moving Average filter.
   */
  ema?: ExponentialMovingAverageEMAIsATypeOfMovingAverageThatAppliesExponentiallyMoreWeightToRecentSignalReadings;
  /**
   * Takes an input signal and emits the extrapolated value; either mirroring the input value
   * or repeating the last known value up to the maximum extrapolation interval.
   */
  extrapolator?: ExtrapolatesTheInputSignalByRepeatingTheLastValidValueDuringThePeriodInWhichItIsInvalid;
  /**
   * Picks the first valid input signal and emits it.
   */
  first_valid?: PicksTheFirstValidInputSignalFromTheArrayOfInputSignalsAndEmitsItAsAnOutputSignal;
  /**
   * FlowControl components are used to regulate requests flow.
   */
  flow_control?: FlowControl;
  /**
   * Gradient controller calculates the ratio between the signal and the setpoint to determine
   * the magnitude of the correction that need to be applied.
   * This controller can be used to build AIMD (Additive Increase, Multiplicative Decrease) or
   * MIMD style response.
   */
  gradient_controller?: GradientControllerIsATypeOfControllerWhichTriesToAdjustTheControlVariableProportionallyToTheRelativeDifferenceBetweenSetpointAndActualValueOfTheSignal;
  /**
   * Holds the last valid signal value for the specified duration then waits for next valid
   * value to hold.
   */
  holder?: Holder;
  /**
   * Accumulates sum of signal every tick.
   */
  integrator?: Integrator;
  /**
   * Logical NOT.
   */
  inverter?: Inverter;
  /**
   * Emits the maximum of the input signals.
   */
  max?: TakesAListOfInputSignalsAndEmitsTheSignalWithTheMaximumValue;
  /**
   * Emits the minimum of the input signals.
   */
  min?: Min;
  /**
   * Nested circuit defines a sub-circuit as a high-level component. It consists of a list of
   * components and a map of input and output ports.
   */
  nested_circuit?: NestedCircuit;
  /**
   * Nested signal egress is a special type of component that allows to extract a signal from
   * a nested circuit.
   */
  nested_signal_egress?: NestedSignalEgress;
  /**
   * Nested signal ingress is a special type of component that allows to inject a signal into
   * a nested circuit.
   */
  nested_signal_ingress?: NestedSignalIngress;
  /**
   * Logical OR.
   */
  or?: Or;
  /**
   * PID Controller is a proportional–integral–derivative controller.
   */
  pid_controller?: PIDControllerIsAPopularControlMechanismForClosedLoopFeedbackControlItTakesASignalAndASetpointAsInputsAndUsesThePIDAlgorithmToComputeProportionalProportionalToTheMagnitudeOfErrorIntegralProportionalToAccumulationOfErrorAndDerivativeProportionalToHowFastTheSignalIsChangingTermsWhichAreSummedUpToGetADesiredOutputValeOffErrorSetpointSignalIntegralIIntegralI1KICdotErrorOutputIKPCdotErrorKDCdotSignalISignalI1IntegralIValeOn;
  /**
   * Polynomial Range Function is a function that maps a signal to a range of values following
   * a polynomial function.
   */
  polynomial_range_function?: PolynomialRangeFunction;
  /**
   * Generates 0 and 1 in turns.
   */
  pulse_generator?: PulseGenerator;
  /**
   * Query components that are query databases such as Prometheus.
   */
  query?: Query;
  /**
   * Generates the specified signal.
   */
  signal_generator?: SignalGenerator;
  /**
   * Simple Moving Average filter.
   */
  sma?: SMA;
  /**
   * Switcher acts as a switch that emits one of the two signals based on third signal.
   */
  switcher?: TypeOfCombinatorThatSwitchesBetweenOnSignalAndOffSignalSignalsBasedOnSwitchInput;
  /**
   * Takes an input signal and emits the square root of the input signal.
   */
  unary_operator?: TakesAnInputSignalAndEmitsTheOutputAfterApplyingTheSpecifiedUnaryOperator;
  /**
   * Emits a variable signal which can be changed at runtime through dynamic configuration.
   */
  variable?: Variable;
};

/**
 * Alerter reacts to a signal and generates alert to send to alert manager.
 */
export type Alerter = {
  /**
   * Input ports for the Alerter component.
   */
  in_ports?: AlerterIns;
  parameters: AlerterParameters;
};

/**
 * Input ports for the Alerter component.
 *
 * Inputs for the Alerter component.
 */
export type AlerterIns = {
  /**
   * Signal which Alerter is monitoring. If the signal greater than 0, Alerter generates an
   * alert.
   */
  signal?: SignalValue;
};

/**
 * Signal which Alerter is monitoring. If the signal greater than 0, Alerter generates an
 * alert.
 *
 * Left hand side of the arithmetic operation.
 *
 * Right hand side of the arithmetic operation.
 *
 * The setpoint to use for scale-in.
 *
 * The signal to use for scale-in.
 *
 * The setpoint to use for scale-out.
 *
 * The signal to use for scale-out.
 *
 * Left hand side input signal for the comparison operation.
 *
 * Right hand side input signal for the comparison operation.
 *
 * Input signal to be used for the EMA computation.
 *
 * Upper bound of the moving average.
 *
 * When the signal exceeds `max_envelope` it is multiplied by
 * `correction_factor_on_max_envelope_violation` **once per tick**.
 *
 * :::note
 *
 * If the signal deviates from `max_envelope` faster than the correction
 * faster, it might end up exceeding the envelope.
 *
 * :::
 *
 * Lower bound of the moving average.
 *
 * Behavior is similar to `max_envelope`.
 *
 * Input signal for the Extrapolator component.
 *
 * The `overload_confirmation` port provides additional criteria to determine overload state
 * which
 * results in _Flow_ throttling at the service.
 *
 * The setpoint input to the controller.
 *
 *
 *
 * The input signal to the controller.
 *
 *
 *
 * Whether to progress the _Load Ramp_ towards the previous step.
 *
 * Whether to progress the _Load Ramp_ towards the next step.
 *
 * Whether to reset the _Load Ramp_ to the first step.
 *
 * Load multiplier is proportion of incoming
 * token rate that needs to be accepted. The signal gets updated once every 10 seconds.
 *
 * Capacity of the bucket to allow for bursty traffic.
 *
 *
 *
 * Number of tokens to fill within an `interval`.
 *
 *
 *
 * The percentage of requests to accept.
 *
 * Actual current value of the control variable.
 *
 * This signal is multiplied by the gradient to produce the output.
 *
 * Maximum value to limit the output signal.
 *
 * Minimum value to limit the output signal.
 *
 * Setpoint to be used for the gradient computation.
 *
 * Signal to be used for the gradient computation.
 *
 * The input signal.
 *
 * Resets the holder output to the current input signal when reset signal is valid and
 * non-zero.
 *
 * The maximum output.
 *
 * The minimum output.
 *
 * Resets the integrator output to zero when reset signal is valid and non-zero. Reset also
 * resets the max and min constraints.
 *
 * Signal to be negated.
 *
 *
 *
 * Egress signal.
 *
 * Whether to progress the _Signal Generator_ towards the previous step.
 *
 * Whether to progress the _Signal Generator_ towards the next step.
 *
 * Whether to reset the _Signal Generator_ to the first step.
 *
 * Signal to be used for the moving average computation.
 *
 * Output signal when switch is invalid or 0.0.
 *
 * Output signal when switch is valid and not 0.0.
 *
 * Decides whether to return `on_signal` or `off_signal`.
 *
 * Input signal.
 */
export type SignalValue = {
  /**
   * Constant value to be used for this InPort instead of a signal.
   */
  constant_signal?: ConstantSignal;
  /**
   * Name of the incoming Signal on the InPort.
   */
  signal_name?: string;
};

/**
 * Constant value to be used for this InPort instead of a signal.
 *
 * Special constant input for ports and Variable component. Can provide either a constant
 * value or special Nan/+-Inf value.
 *
 * The value of the step.
 *
 * The constant signal emitted by this component. The value of the constant signal can be
 * overridden at runtime through dynamic configuration.
 */
export type ConstantSignal = {
  /**
   * A special value such as NaN, +Inf, -Inf.
   */
  special_value?: SpecialValue;
  /**
   * A constant value.
   */
  value?: number;
};

/**
 * A special value such as NaN, +Inf, -Inf.
 */
export enum SpecialValue {
  Inf = "+Inf",
  NaN = "NaN",
  SpecialValueInf = "-Inf",
}

/**
 * Alerter Parameters configure parameters such as alert name, severity, resolve timeout,
 * alert channels and labels.
 *
 * Configuration for embedded Alerter.
 *
 *
 *
 * Configuration for scale-in Alerter.
 *
 *
 *
 * Configuration for scale-out Alerter.
 *
 *
 *
 * Configuration parameters for the embedded Alerter.
 */
export type AlerterParameters = {
  /**
   * A list of alert channel strings.
   */
  alert_channels?: string[];
  /**
   * Name of the alert.
   */
  alert_name: string;
  /**
   * Additional labels to add to alert.
   */
  labels?: { [key: string]: string };
  /**
   * Duration of alert resolver.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  resolve_timeout?: string;
  /**
   * Severity of the alert, one of 'info', 'warn' or 'crit'.
   */
  severity?: Severity;
};

/**
 * Severity of the alert, one of 'info', 'warn' or 'crit'.
 */
export enum Severity {
  Crit = "crit",
  Info = "info",
  Warn = "warn",
}

/**
 * Logical AND.
 *
 * Logical AND.
 *
 * Signals are mapped to Boolean values as follows:
 * * Zero is treated as false.
 * * Any non-zero is treated as true.
 * * Invalid inputs are considered unknown.
 *
 * :::note
 *
 * Treating invalid inputs as "unknowns" has a consequence that the result
 * might end up being valid even when some inputs are invalid. For example, `unknown &&
 * false == false`,
 * because the result would end up false no matter if
 * first signal was true or false. Conversely, `unknown && true == unknown`.
 *
 * :::
 */
export type And = {
  /**
   * Input ports for the And component.
   */
  in_ports?: AndIns;
  /**
   * Output ports for the And component.
   */
  out_ports?: AndOuts;
};

/**
 * Input ports for the And component.
 *
 * Inputs for the And component.
 */
export type AndIns = {
  /**
   * Array of input signals.
   */
  inputs?: PurpleComponentsReceiveInputFromOtherComponentsThroughInPorts[];
};

/**
 * Signal which Alerter is monitoring. If the signal greater than 0, Alerter generates an
 * alert.
 *
 * Left hand side of the arithmetic operation.
 *
 * Right hand side of the arithmetic operation.
 *
 * The setpoint to use for scale-in.
 *
 * The signal to use for scale-in.
 *
 * The setpoint to use for scale-out.
 *
 * The signal to use for scale-out.
 *
 * Left hand side input signal for the comparison operation.
 *
 * Right hand side input signal for the comparison operation.
 *
 * Input signal to be used for the EMA computation.
 *
 * Upper bound of the moving average.
 *
 * When the signal exceeds `max_envelope` it is multiplied by
 * `correction_factor_on_max_envelope_violation` **once per tick**.
 *
 * :::note
 *
 * If the signal deviates from `max_envelope` faster than the correction
 * faster, it might end up exceeding the envelope.
 *
 * :::
 *
 * Lower bound of the moving average.
 *
 * Behavior is similar to `max_envelope`.
 *
 * Input signal for the Extrapolator component.
 *
 * The `overload_confirmation` port provides additional criteria to determine overload state
 * which
 * results in _Flow_ throttling at the service.
 *
 * The setpoint input to the controller.
 *
 *
 *
 * The input signal to the controller.
 *
 *
 *
 * Whether to progress the _Load Ramp_ towards the previous step.
 *
 * Whether to progress the _Load Ramp_ towards the next step.
 *
 * Whether to reset the _Load Ramp_ to the first step.
 *
 * Load multiplier is proportion of incoming
 * token rate that needs to be accepted. The signal gets updated once every 10 seconds.
 *
 * Capacity of the bucket to allow for bursty traffic.
 *
 *
 *
 * Number of tokens to fill within an `interval`.
 *
 *
 *
 * The percentage of requests to accept.
 *
 * Actual current value of the control variable.
 *
 * This signal is multiplied by the gradient to produce the output.
 *
 * Maximum value to limit the output signal.
 *
 * Minimum value to limit the output signal.
 *
 * Setpoint to be used for the gradient computation.
 *
 * Signal to be used for the gradient computation.
 *
 * The input signal.
 *
 * Resets the holder output to the current input signal when reset signal is valid and
 * non-zero.
 *
 * The maximum output.
 *
 * The minimum output.
 *
 * Resets the integrator output to zero when reset signal is valid and non-zero. Reset also
 * resets the max and min constraints.
 *
 * Signal to be negated.
 *
 *
 *
 * Egress signal.
 *
 * Whether to progress the _Signal Generator_ towards the previous step.
 *
 * Whether to progress the _Signal Generator_ towards the next step.
 *
 * Whether to reset the _Signal Generator_ to the first step.
 *
 * Signal to be used for the moving average computation.
 *
 * Output signal when switch is invalid or 0.0.
 *
 * Output signal when switch is valid and not 0.0.
 *
 * Decides whether to return `on_signal` or `off_signal`.
 *
 * Input signal.
 */
export type PurpleComponentsReceiveInputFromOtherComponentsThroughInPorts = {
  /**
   * Constant value to be used for this InPort instead of a signal.
   */
  constant_signal?: ConstantSignal;
  /**
   * Name of the incoming Signal on the InPort.
   */
  signal_name?: string;
};

/**
 * Output ports for the And component.
 */
export type AndOuts = {
  /**
   * Result of logical AND of all the input signals.
   *
   * Will always be 0 (false), 1 (true) or invalid (unknown).
   */
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Result of logical AND of all the input signals.
 *
 * Will always be 0 (false), 1 (true) or invalid (unknown).
 *
 * Result of arithmetic operation.
 *
 * The number of replicas that are currently running.
 *
 * The number of replicas that are desired.
 *
 * The value is emitted to the output port.
 *
 * Selected signal (1.0 or 0.0).
 *
 * Exponential moving average of the series of reading as an output signal.
 *
 * Extrapolated signal.
 *
 * First valid input signal as an output signal.
 *
 * Desired Load multiplier is the ratio of desired token rate to the incoming token rate.
 *
 * A Boolean signal that indicates whether the service is in overload state.
 *
 * Observed Load multiplier is the ratio of accepted token rate to the incoming token rate.
 *
 * The percentage of flows being accepted by the _Load Ramp_.
 *
 * A Boolean signal indicating whether the _Load Ramp_ is at the end of signal generation.
 *
 * A Boolean signal indicating whether the _Load Ramp_ is at the start of signal
 * generation.
 *
 * Observed load multiplier is the proportion of incoming token rate that is being accepted.
 * The signal gets updated once every 10 seconds.
 *
 * The percentage of flows being accepted by the _Quota Scheduler_.
 *
 * The percentage of flows being accepted by the _Rate Limiter_.
 *
 * Computed desired value of the control variable.
 *
 * The output signal.
 *
 * Logical negation of the input signal.
 *
 * Will always be 0 (false), 1 (true) or invalid (unknown).
 *
 * Signal with maximum value as an output signal.
 *
 * Signal with minimum value as an output signal.
 *
 * Ingress signal.
 *
 * Result of logical OR of all the input signals.
 *
 * Will always be 0 (false), 1 (true) or invalid (unknown).
 *
 * The result of the Prometheus query as an output signal.
 *
 * A Boolean signal indicating whether the _Signal Generator_ is at the end of signal
 * generation.
 *
 * A Boolean signal indicating whether the _Signal Generator_ is at the start of signal
 * generation.
 *
 * The generated signal.
 *
 * Computed moving average.
 *
 * Selected signal (`on_signal` or `off_signal`).
 *
 * Output signal.
 */
export type ComponentsProduceOutputForOtherComponentsThroughOutPorts = {
  /**
   * Name of the outgoing Signal on the OutPort.
   */
  signal_name?: string;
};

/**
 * Applies the given operator on input operands (signals) and emits the result.
 */
export type TypeOfCombinatorThatComputesTheArithmeticOperationOnTheOperandSignals =
  {
    /**
     * Input ports for the Arithmetic Combinator component.
     */
    in_ports?: ArithmeticCombinatorIns;
    /**
     * Operator of the arithmetic operation.
     *
     * The arithmetic operation can be addition, subtraction, multiplication, division, XOR,
     * right bit shift or left bit shift.
     * In case of XOR and bit shifts, value of signals is cast to integers before performing the
     * operation.
     */
    operator?: ArithmeticCombinatorOperator;
    /**
     * Output ports for the Arithmetic Combinator component.
     */
    out_ports?: ArithmeticCombinatorOuts;
  };

/**
 * Input ports for the Arithmetic Combinator component.
 *
 * Inputs for the Arithmetic Combinator component.
 */
export type ArithmeticCombinatorIns = {
  /**
   * Left hand side of the arithmetic operation.
   */
  lhs?: SignalValue;
  /**
   * Right hand side of the arithmetic operation.
   */
  rhs?: SignalValue;
};

/**
 * Operator of the arithmetic operation.
 *
 * The arithmetic operation can be addition, subtraction, multiplication, division, XOR,
 * right bit shift or left bit shift.
 * In case of XOR and bit shifts, value of signals is cast to integers before performing the
 * operation.
 */
export enum ArithmeticCombinatorOperator {
  Add = "add",
  Div = "div",
  Lshift = "lshift",
  Mul = "mul",
  Rshift = "rshift",
  Sub = "sub",
  Xor = "xor",
}

/**
 * Output ports for the Arithmetic Combinator component.
 *
 * Outputs for the Arithmetic Combinator component.
 */
export type ArithmeticCombinatorOuts = {
  /**
   * Result of arithmetic operation.
   */
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * AutoScale components are used to scale the service.
 *
 * AutoScale components are used to scale a service.
 */
export type AutoScale = {
  /**
   * _AutoScaler_ provides auto-scaling functionality for any scalable resource.
   */
  auto_scaler?: AutoScaler;
  /**
   * PodScaler provides pod horizontal scaling functionality for scalable Kubernetes resources.
   */
  pod_scaler?: PodScaler;
};

/**
 * _AutoScaler_ provides auto-scaling functionality for any scalable resource.
 *
 * _AutoScaler_ provides auto-scaling functionality for any scalable resource. Multiple
 * _Controllers_ can be defined on the _AutoScaler_ for performing scale-out or scale-in.
 * The _AutoScaler_ can interface with infrastructure APIs such as Kubernetes to perform
 * auto-scale.
 */
export type AutoScaler = {
  /**
   * Dry run mode ensures that no scaling is invoked by this auto scaler.
   * This is useful for observing the behavior of auto scaler without disrupting any real
   * deployment.
   * This parameter sets the default value of dry run setting which can be overridden at
   * runtime using dynamic configuration.
   */
  dry_run?: boolean;
  /**
   * Configuration key for overriding dry run setting through dynamic configuration.
   */
  dry_run_config_key?: string;
  /**
   * List of _Controllers_ for scaling in.
   */
  scale_in_controllers?: ScaleInController[];
  /**
   * List of _Controllers_ for scaling out.
   */
  scale_out_controllers?: ScaleOutController[];
  scaling_backend: AutoScalerScalingBackend;
  /**
   * Parameters that define the scaling behavior.
   */
  scaling_parameters: AutoScalerScalingParameters;
};

export type ScaleInController = {
  /**
   * Configuration for embedded Alerter.
   */
  alerter: AlerterParameters;
  controller: ScaleInControllerController;
};

export type ScaleInControllerController = {
  gradient?: DecreasingGradient;
  periodic?: PeriodicDecrease;
};

/**
 * Decreasing Gradient defines a controller for scaling in based on Gradient Controller.
 */
export type DecreasingGradient = {
  /**
   * Input ports for the Gradient.
   */
  in_ports?: DecreasingGradientIns;
  parameters?: DecreasingGradientParameters;
};

/**
 * Input ports for the Gradient.
 *
 * Inputs for Gradient.
 */
export type DecreasingGradientIns = {
  /**
   * The setpoint to use for scale-in.
   */
  setpoint?: SignalValue;
  /**
   * The signal to use for scale-in.
   */
  signal?: SignalValue;
};

/**
 * This allows subset of parameters with constrained values compared to a regular gradient
 * controller. For full documentation of these parameters, refer to the
 * [GradientControllerParameters](#gradient-controller-parameters).
 */
export type DecreasingGradientParameters = {
  min_gradient?: number;
  slope?: number;
};

/**
 * PeriodicDecrease defines a controller for scaling in based on a periodic timer.
 */
export type PeriodicDecrease = {
  /**
   * The period of the timer.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  period: string;
  /**
   * The percentage of scale to reduce.
   */
  scale_in_percentage: number;
};

export type ScaleOutController = {
  /**
   * Configuration for embedded Alerter.
   */
  alerter: AlerterParameters;
  controller: ScaleOutControllerController;
};

export type ScaleOutControllerController = {
  gradient?: IncreasingGradient;
};

/**
 * Increasing Gradient defines a controller for scaling out based on _Gradient Controller_.
 */
export type IncreasingGradient = {
  /**
   * Input ports for the Gradient.
   */
  in_ports?: IncreasingGradientIns;
  parameters?: IncreasingGradientParameters;
};

/**
 * Input ports for the Gradient.
 *
 * Inputs for Gradient.
 */
export type IncreasingGradientIns = {
  /**
   * The setpoint to use for scale-out.
   */
  setpoint?: SignalValue;
  /**
   * The signal to use for scale-out.
   */
  signal?: SignalValue;
};

/**
 * This allows subset of parameters with constrained values compared to a regular gradient
 * controller. For full documentation of these parameters, refer to the
 * [GradientControllerParameters](#gradient-controller-parameters).
 */
export type IncreasingGradientParameters = {
  max_gradient?: number;
  slope?: number;
};

export type AutoScalerScalingBackend = {
  kubernetes_replicas?: AutoScalerScalingBackendKubernetesReplicas;
};

/**
 * KubernetesReplicas defines a horizontal pod scaler for Kubernetes.
 */
export type AutoScalerScalingBackendKubernetesReplicas = {
  /**
   * The Kubernetes object on which horizontal scaling is applied.
   */
  kubernetes_object_selector: KubernetesObjectSelector;
  /**
   * The maximum replicas to which the _AutoScaler_ can scale-out.
   */
  max_replicas?: string;
  /**
   * The minimum replicas to which the _AutoScaler_ can scale-in.
   */
  min_replicas?: string;
  /**
   * Output ports for _Kubernetes Replicas_.
   */
  out_ports?: Outputs;
};

/**
 * The Kubernetes object on which horizontal scaling is applied.
 *
 *
 *
 * Describes which pods a control or observability
 * component should apply to.
 *
 * The Kubernetes object to which this pod scaler applies.
 */
export type KubernetesObjectSelector = {
  /**
   * Which [agent-group](/concepts/selector.md#agent-group) this
   * selector applies to.
   */
  agent_group?: string;
  api_version: string;
  /**
   * Kubernetes resource type.
   */
  kind: string;
  /**
   * Kubernetes resource name.
   */
  name: string;
  /**
   * Kubernetes namespace that the resource belongs to.
   */
  namespace: string;
};

/**
 * Output ports for _Kubernetes Replicas_.
 */
export type Outputs = {
  actual_replicas?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
  configured_replicas?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
  desired_replicas?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Parameters that define the scaling behavior.
 */
export type AutoScalerScalingParameters = {
  /**
   * Cooldown override percentage defines a threshold change in scale-out beyond which
   * previous cooldown is overridden.
   * For example, if the cooldown is 5 minutes and the cooldown override percentage is 10%,
   * then if the
   * scale-increases by 10% or more, the previous cooldown is canceled. Defaults to 50%.
   */
  cooldown_override_percentage?: number;
  /**
   * The maximum decrease of scale (for example, pods) at one time. Defined as percentage of
   * current scale value. Can never go below one even if percentage computation is less than
   * one. Defaults to 1% of current scale value.
   */
  max_scale_in_percentage?: number;
  /**
   * The maximum increase of scale (for example, pods) at one time. Defined as percentage of
   * current scale value. Can never go below one even if percentage computation is less than
   * one. Defaults to 10% of current scale value.
   */
  max_scale_out_percentage?: number;
  /**
   * Configuration for scale-in Alerter.
   */
  scale_in_alerter: AlerterParameters;
  /**
   * The amount of time to wait after a scale-in operation for another scale-in operation.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  scale_in_cooldown?: string;
  /**
   * Configuration for scale-out Alerter.
   */
  scale_out_alerter: AlerterParameters;
  /**
   * The amount of time to wait after a scale-out operation for another scale-out or scale-in
   * operation.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  scale_out_cooldown?: string;
};

/**
 * PodScaler provides pod horizontal scaling functionality for scalable Kubernetes
 * resources.
 *
 * Component for scaling pods based on a signal.
 */
export type PodScaler = {
  /**
   * Dry run mode ensures that no scaling is invoked by this pod scaler.
   * This is useful for observing the behavior of pod scaler without disrupting any real
   * deployment.
   * This parameter sets the default value of dry run setting which can be overridden at
   * runtime using dynamic configuration.
   */
  dry_run?: boolean;
  /**
   * Configuration key for overriding dry run setting through dynamic configuration.
   */
  dry_run_config_key?: string;
  /**
   * Input ports for the PodScaler component.
   */
  in_ports?: PodScalerIns;
  /**
   * The Kubernetes object to which this pod scaler applies.
   */
  kubernetes_object_selector: KubernetesObjectSelector;
  /**
   * Output ports for the PodScaler component.
   */
  out_ports?: PodScalerOuts;
};

/**
 * Input ports for the PodScaler component.
 *
 * Inputs for the PodScaler component.
 */
export type PodScalerIns = {
  replicas?: SignalValue;
};

/**
 * Output ports for the PodScaler component.
 *
 * Outputs for the PodScaler component.
 */
export type PodScalerOuts = {
  /**
   * The number of replicas that are currently running.
   */
  actual_replicas?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
  /**
   * The number of replicas that are desired.
   */
  configured_replicas?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * BoolVariable emits a constant Boolean signal which can be changed at runtime through
 * dynamic configuration.
 *
 * Component that emits a constant Boolean signal which can be changed at runtime through
 * dynamic configuration.
 */
export type BoolVariable = {
  /**
   * Configuration key for overriding value setting through dynamic configuration.
   */
  config_key?: string;
  /**
   * The constant Boolean signal emitted by this component. The value of the constant Boolean
   * signal can be overridden at runtime through dynamic configuration.
   */
  constant_output?: boolean;
  /**
   * Output ports for the BoolVariable component.
   */
  out_ports?: BoolVariableOuts;
};

/**
 * Output ports for the BoolVariable component.
 *
 * Outputs for the BoolVariable component.
 */
export type BoolVariableOuts = {
  /**
   * The value is emitted to the output port.
   */
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Decider emits the binary result of comparison operator on two operands.
 *
 * The comparison operator can be greater-than, less-than, greater-than-or-equal,
 * less-than-or-equal, equal, or not-equal.
 *
 * This component also supports time-based response (the output)
 * transitions between 1.0 or 0.0 signal if the decider condition is
 * true or false for at least `true_for` or `false_for` duration. If
 * `true_for` and `false_for` durations are zero then the transitions are
 * instantaneous.
 */
export type TypeOfCombinatorThatComputesTheComparisonOperationOnLHSAndRHSSignals =
  {
    /**
     * Duration of time to wait before changing to false state.
     * If the duration is zero, the change will happen instantaneously.
     * This field employs the
     * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
     * representation from Protocol Buffers. The format accommodates fractional seconds up to
     * nine digits after the decimal point, offering nanosecond precision. Every duration value
     * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
     * signify a duration of 10 seconds.
     */
    false_for?: string;
    /**
     * Input ports for the Decider component.
     */
    in_ports?: DeciderIns;
    /**
     * Comparison operator that computes operation on LHS and RHS input signals.
     */
    operator?: DeciderOperator;
    /**
     * Output ports for the Decider component.
     */
    out_ports?: DeciderOuts;
    /**
     * Duration of time to wait before changing to true state.
     * If the duration is zero, the change will happen instantaneously.```
     * This field employs the
     * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
     * representation from Protocol Buffers. The format accommodates fractional seconds up to
     * nine digits after the decimal point, offering nanosecond precision. Every duration value
     * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
     * signify a duration of 10 seconds.
     */
    true_for?: string;
  };

/**
 * Input ports for the Decider component.
 *
 * Inputs for the Decider component.
 */
export type DeciderIns = {
  /**
   * Left hand side input signal for the comparison operation.
   */
  lhs?: SignalValue;
  /**
   * Right hand side input signal for the comparison operation.
   */
  rhs?: SignalValue;
};

/**
 * Comparison operator that computes operation on LHS and RHS input signals.
 */
export enum DeciderOperator {
  Eq = "eq",
  Gt = "gt",
  Gte = "gte",
  LTE = "lte",
  Lt = "lt",
  Neq = "neq",
}

/**
 * Output ports for the Decider component.
 *
 * Outputs for the Decider component.
 */
export type DeciderOuts = {
  /**
   * Selected signal (1.0 or 0.0).
   */
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Differentiator calculates rate of change per tick.
 * Deprecated: v3.0.0. Use `PIDController` instead.
 *
 *
 *
 * Differentiator calculates rate of change per tick.
 */
export type Differentiator = {
  /**
   * Input ports for the Differentiator component.
   */
  in_ports?: DifferentiatorIns;
  /**
   * Output ports for the Differentiator component.
   */
  out_ports?: DifferentiatorOuts;
  /**
   * The window of time over which differentiator operates.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  window?: string;
};

/**
 * Input ports for the Differentiator component.
 *
 * Inputs for the Differentiator component.
 */
export type DifferentiatorIns = {
  input?: SignalValue;
};

/**
 * Output ports for the Differentiator component.
 *
 * Outputs for the Differentiator component.
 */
export type DifferentiatorOuts = {
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Exponential Moving Average filter.
 *
 * At any time EMA component operates in one of the following states:
 * 1. Warm up state: The first `warmup_window` samples are used to compute the initial
 * EMA.
 * If an invalid reading is received during the `warmup_window`, the last good average is
 * emitted and the state gets reset back to beginning of warm up state.
 * 2. Normal state: The EMA is computed using following formula.
 *
 * The EMA for a series $Y$ is calculated recursively as:
 * <!-- vale off -->
 * $$
 * \text{EMA} _t =
 * \begin{cases}
 * Y_0, &\text{for } t = 0 \\
 * \alpha Y_t + (1 - \alpha) \text{EMA}_{t-1}, &\text{for }t > 0
 * \end{cases}
 * $$
 *
 * The coefficient $\alpha$ represents the degree of weighting decrease, a constant
 * smoothing factor between 0 and 1.
 * A higher $\alpha$ discounts older observations faster.
 * The $\alpha$ is computed using ema\_window:
 *
 * $$
 * \alpha = \frac{2}{N + 1} \quad\text{where } N =
 * \frac{\text{ema\_window}}{\text{evaluation\_period}}
 * $$
 * <!-- vale on -->
 */
export type ExponentialMovingAverageEMAIsATypeOfMovingAverageThatAppliesExponentiallyMoreWeightToRecentSignalReadings =
  {
    /**
     * Input ports for the EMA component.
     */
    in_ports?: EMAIns;
    /**
     * Output ports for the EMA component.
     */
    out_ports?: EMAOuts;
    /**
     * Parameters for the EMA component.
     */
    parameters: EMAParameters;
  };

/**
 * Input ports for the EMA component.
 *
 * Inputs for the EMA component.
 */
export type EMAIns = {
  /**
   * Input signal to be used for the EMA computation.
   */
  input?: SignalValue;
  /**
   * Upper bound of the moving average.
   *
   * When the signal exceeds `max_envelope` it is multiplied by
   * `correction_factor_on_max_envelope_violation` **once per tick**.
   *
   * :::note
   *
   * If the signal deviates from `max_envelope` faster than the correction
   * faster, it might end up exceeding the envelope.
   *
   * :::
   */
  max_envelope?: SignalValue;
  /**
   * Lower bound of the moving average.
   *
   * Behavior is similar to `max_envelope`.
   */
  min_envelope?: SignalValue;
};

/**
 * Output ports for the EMA component.
 *
 * Outputs for the EMA component.
 */
export type EMAOuts = {
  /**
   * Exponential moving average of the series of reading as an output signal.
   */
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Parameters for the EMA component.
 *
 *
 *
 * Parameters for the EMA component.
 */
export type EMAParameters = {
  /**
   * Correction factor to apply on the output value if its in violation of the max envelope.
   */
  correction_factor_on_max_envelope_violation?: number;
  /**
   * Correction factor to apply on the output value if its in violation of the min envelope.
   */
  correction_factor_on_min_envelope_violation?: number;
  /**
   * EMA window duration.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  ema_window: string;
  /**
   * Whether the output is valid during the warm-up stage.
   */
  valid_during_warmup?: boolean;
  /**
   * Duration of EMA warming up window.
   *
   * The initial value of the EMA is the average of signal readings received during the warm
   * up window.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  warmup_window: string;
};

/**
 * Takes an input signal and emits the extrapolated value; either mirroring the input value
 * or repeating the last known value up to the maximum extrapolation interval.
 *
 * It does so until `maximum_extrapolation_interval` is reached, beyond which it emits
 * invalid signal unless input signal becomes valid again.
 */
export type ExtrapolatesTheInputSignalByRepeatingTheLastValidValueDuringThePeriodInWhichItIsInvalid =
  {
    /**
     * Input ports for the Extrapolator component.
     */
    in_ports?: ExtrapolatorIns;
    /**
     * Output ports for the Extrapolator component.
     */
    out_ports?: ExtrapolatorOuts;
    /**
     * Parameters for the Extrapolator component.
     */
    parameters: ExtrapolatorParameters;
  };

/**
 * Input ports for the Extrapolator component.
 *
 * Inputs for the Extrapolator component.
 */
export type ExtrapolatorIns = {
  /**
   * Input signal for the Extrapolator component.
   */
  input?: SignalValue;
};

/**
 * Output ports for the Extrapolator component.
 *
 * Outputs for the Extrapolator component.
 */
export type ExtrapolatorOuts = {
  /**
   * Extrapolated signal.
   */
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Parameters for the Extrapolator component.
 *
 *
 *
 * Parameters for the Extrapolator component.
 */
export type ExtrapolatorParameters = {
  /**
   * Maximum time interval to repeat the last valid value of input signal.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  max_extrapolation_interval: string;
};

/**
 * Picks the first valid input signal and emits it.
 */
export type PicksTheFirstValidInputSignalFromTheArrayOfInputSignalsAndEmitsItAsAnOutputSignal =
  {
    /**
     * Input ports for the FirstValid component.
     */
    in_ports?: FirstValidIns;
    /**
     * Output ports for the FirstValid component.
     */
    out_ports?: FirstValidOuts;
  };

/**
 * Input ports for the FirstValid component.
 *
 * Inputs for the FirstValid component.
 */
export type FirstValidIns = {
  /**
   * Array of input signals.
   */
  inputs?: FluffyComponentsReceiveInputFromOtherComponentsThroughInPorts[];
};

/**
 * Signal which Alerter is monitoring. If the signal greater than 0, Alerter generates an
 * alert.
 *
 * Left hand side of the arithmetic operation.
 *
 * Right hand side of the arithmetic operation.
 *
 * The setpoint to use for scale-in.
 *
 * The signal to use for scale-in.
 *
 * The setpoint to use for scale-out.
 *
 * The signal to use for scale-out.
 *
 * Left hand side input signal for the comparison operation.
 *
 * Right hand side input signal for the comparison operation.
 *
 * Input signal to be used for the EMA computation.
 *
 * Upper bound of the moving average.
 *
 * When the signal exceeds `max_envelope` it is multiplied by
 * `correction_factor_on_max_envelope_violation` **once per tick**.
 *
 * :::note
 *
 * If the signal deviates from `max_envelope` faster than the correction
 * faster, it might end up exceeding the envelope.
 *
 * :::
 *
 * Lower bound of the moving average.
 *
 * Behavior is similar to `max_envelope`.
 *
 * Input signal for the Extrapolator component.
 *
 * The `overload_confirmation` port provides additional criteria to determine overload state
 * which
 * results in _Flow_ throttling at the service.
 *
 * The setpoint input to the controller.
 *
 *
 *
 * The input signal to the controller.
 *
 *
 *
 * Whether to progress the _Load Ramp_ towards the previous step.
 *
 * Whether to progress the _Load Ramp_ towards the next step.
 *
 * Whether to reset the _Load Ramp_ to the first step.
 *
 * Load multiplier is proportion of incoming
 * token rate that needs to be accepted. The signal gets updated once every 10 seconds.
 *
 * Capacity of the bucket to allow for bursty traffic.
 *
 *
 *
 * Number of tokens to fill within an `interval`.
 *
 *
 *
 * The percentage of requests to accept.
 *
 * Actual current value of the control variable.
 *
 * This signal is multiplied by the gradient to produce the output.
 *
 * Maximum value to limit the output signal.
 *
 * Minimum value to limit the output signal.
 *
 * Setpoint to be used for the gradient computation.
 *
 * Signal to be used for the gradient computation.
 *
 * The input signal.
 *
 * Resets the holder output to the current input signal when reset signal is valid and
 * non-zero.
 *
 * The maximum output.
 *
 * The minimum output.
 *
 * Resets the integrator output to zero when reset signal is valid and non-zero. Reset also
 * resets the max and min constraints.
 *
 * Signal to be negated.
 *
 *
 *
 * Egress signal.
 *
 * Whether to progress the _Signal Generator_ towards the previous step.
 *
 * Whether to progress the _Signal Generator_ towards the next step.
 *
 * Whether to reset the _Signal Generator_ to the first step.
 *
 * Signal to be used for the moving average computation.
 *
 * Output signal when switch is invalid or 0.0.
 *
 * Output signal when switch is valid and not 0.0.
 *
 * Decides whether to return `on_signal` or `off_signal`.
 *
 * Input signal.
 */
export type FluffyComponentsReceiveInputFromOtherComponentsThroughInPorts = {
  /**
   * Constant value to be used for this InPort instead of a signal.
   */
  constant_signal?: ConstantSignal;
  /**
   * Name of the incoming Signal on the InPort.
   */
  signal_name?: string;
};

/**
 * Output ports for the FirstValid component.
 *
 * Outputs for the FirstValid component.
 */
export type FirstValidOuts = {
  /**
   * First valid input signal as an output signal.
   */
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * FlowControl components are used to regulate requests flow.
 *
 * _Flow Control_ encompasses components that manage the flow of requests or access to
 * features within a service.
 */
export type FlowControl = {
  /**
   * _Adaptive Load Scheduler_ component does additive increase of load multiplier during
   * non-overload state. During overload, the load multiplier is throttled based on the
   * provided strategy.
   * Deprecated: v3.0.0. Use _AIMD Load Scheduler_ instead.
   */
  adaptive_load_scheduler?: AdaptiveLoadScheduler;
  /**
   * AIAD Load Scheduler.
   */
  aiad_load_scheduler?: AIADLoadScheduler;
  /**
   * AIMD Load Scheduler.
   */
  aimd_load_scheduler?: AIMDLoadScheduler;
  /**
   * _Load Ramp_ smoothly regulates the flow of requests over specified steps.
   */
  load_ramp?: LoadRamp;
  /**
   * _Load Scheduler_ provides service protection by creating a prioritized workload queue in
   * front of the service using Weighted Fair Queuing.
   */
  load_scheduler?: LoadSchedulerCreatesAQueueForFlowsInFrontOfAServiceToProvideActiveServiceProtection;
  quota_scheduler?: QuotaScheduler;
  /**
   * Range Driven Load Scheduler.
   */
  range_driven_load_scheduler?: RangeDrivenLoadScheduler;
  /**
   * _Rate Limiter_ provides service protection by applying rate limits using the token bucket
   * algorithm.
   */
  rate_limiter?: LimitsTheTrafficOnAControlPointToSpecifiedRate;
  /**
   * Sampler is a component that regulates the flow of requests to the service by allowing
   * only the specified percentage of requests or sticky sessions.
   */
  sampler?: Sampler;
};

/**
 * _Adaptive Load Scheduler_ component does additive increase of load multiplier during
 * non-overload state. During overload, the load multiplier is throttled based on the
 * provided strategy.
 * Deprecated: v3.0.0. Use _AIMD Load Scheduler_ instead.
 *
 *
 *
 * The _Adaptive Load Scheduler_ adjusts the accepted token rate based on the deviation of
 * the input signal from the setpoint.
 * Deprecated: v3.0.0. Use _AIMD Load Scheduler_ instead.
 */
export type AdaptiveLoadScheduler = {
  /**
   * Decides whether to run the load scheduler in dry-run mode. In dry run mode the scheduler
   * acts as pass through to all flow and does not queue flows.
   * It is useful for observing the behavior of load scheduler without disrupting any real
   * traffic.
   */
  dry_run?: boolean;
  /**
   * Configuration key for setting dry run mode through dynamic configuration.
   */
  dry_run_config_key?: string;
  /**
   * Collection of input ports for the _Adaptive Load Scheduler_ component.
   */
  in_ports: AdaptiveLoadSchedulerIns;
  /**
   * Collection of output ports for the _Adaptive Load Scheduler_ component.
   */
  out_ports?: AdaptiveLoadSchedulerOuts;
  /**
   * Parameters for the _Adaptive Load Scheduler_ component.
   */
  parameters: AdaptiveLoadSchedulerParameters;
};

/**
 * Collection of input ports for the _Adaptive Load Scheduler_ component.
 *
 *
 *
 * Input ports for the _Adaptive Load Scheduler_ component.
 */
export type AdaptiveLoadSchedulerIns = {
  /**
   * The `overload_confirmation` port provides additional criteria to determine overload state
   * which
   * results in _Flow_ throttling at the service.
   */
  overload_confirmation?: SignalValue;
  /**
   * The setpoint input to the controller.
   */
  setpoint: SignalValue;
  /**
   * The input signal to the controller.
   */
  signal: SignalValue;
};

/**
 * Collection of output ports for the _Adaptive Load Scheduler_ component.
 *
 * Output ports for the _Adaptive Load Scheduler_ component.
 */
export type AdaptiveLoadSchedulerOuts = {
  /**
   * Desired Load multiplier is the ratio of desired token rate to the incoming token rate.
   */
  desired_load_multiplier?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
  /**
   * A Boolean signal that indicates whether the service is in overload state.
   */
  is_overload?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
  /**
   * Observed Load multiplier is the ratio of accepted token rate to the incoming token rate.
   */
  observed_load_multiplier?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Parameters for the _Adaptive Load Scheduler_ component.
 *
 *
 *
 * Parameters for the _Adaptive Load Scheduler_ component.
 */
export type AdaptiveLoadSchedulerParameters = {
  /**
   * Configuration parameters for the embedded Alerter.
   */
  alerter: AlerterParameters;
  /**
   * Parameters for the _Gradient Controller_.
   */
  gradient: GradientControllerParameters;
  /**
   * Linear increment to load multiplier every 10 seconds while the system is
   * not in the overloaded state, up until the `max_load_multiplier` is reached.
   */
  load_multiplier_linear_increment?: number;
  /**
   * Parameters for the _Load Scheduler_.
   */
  load_scheduler: ParametersForLoadSchedulerComponent;
  /**
   * The maximum load multiplier that can be reached during recovery from an overload state.
   * - Helps protect the service from request bursts while the system is still recovering.
   * - Once this value is reached, the scheduler enters the pass-through mode, allowing
   * requests to bypass the scheduler and be sent directly to the service.
   * - The pass-through mode gets disabled if the system enters the overload state again.
   */
  max_load_multiplier?: number;
};

/**
 * Parameters for the _Gradient Controller_.
 *
 *
 *
 * Gradient Parameters.
 *
 * Parameters for the Gradient Controller.
 *
 *
 *
 * Gradient Parameters.
 */
export type GradientControllerParameters = {
  /**
   * Maximum gradient which clamps the computed gradient value to the range, `[min_gradient,
   * max_gradient]`.
   */
  max_gradient?: number;
  /**
   * Minimum gradient which clamps the computed gradient value to the range, `[min_gradient,
   * max_gradient]`.
   */
  min_gradient?: number;
  /**
   * Slope controls the aggressiveness and direction of the Gradient Controller.
   *
   * Slope is used as exponent on the signal to setpoint ratio in computation
   * of the gradient (see the [main description](#gradient-controller) for
   * exact equation). This parameter decides how aggressive the controller
   * responds to the deviation of signal from the setpoint.
   * for example:
   * * $\text{slope} = 1$: when signal is too high, increase control variable,
   * * $\text{slope} = -1$: when signal is too high, decrease control variable,
   * * $\text{slope} = -0.5$: when signal is too high, decrease control variable gradually.
   *
   * The sign of slope depends on correlation between the signal and control variable:
   * * Use $\text{slope} < 0$ if there is a _positive_ correlation between the signal and
   * the control variable (for example, Per-pod CPU usage and total concurrency).
   * * Use $\text{slope} > 0$ if there is a _negative_ correlation between the signal and
   * the control variable (for example, Per-pod CPU usage and number of pods).
   *
   * :::note
   *
   * You need to set _negative_ slope for a _positive_ correlation, as you're
   * describing the _action_ which controller should make when the signal
   * increases.
   *
   * :::
   *
   * The magnitude of slope describes how aggressively should the controller
   * react to a deviation of signal.
   * With $|\text{slope}| = 1$, the controller will aim to bring the signal to
   * the setpoint in one tick (assuming linear correlation with signal and setpoint).
   * Smaller magnitudes of slope will make the controller adjust the control
   * variable gradually.
   *
   * Setting $|\text{slope}| < 1$ (for example, $\pm0.8$) is recommended.
   * If you experience overshooting, consider lowering the magnitude even more.
   * Values of $|\text{slope}| > 1$ aren't recommended.
   *
   * :::note
   *
   * Remember that the gradient and output signal can be (optionally) clamped,
   * so the _slope_ might not fully describe aggressiveness of the controller.
   *
   * :::
   */
  slope: number;
};

/**
 * Parameters for the _Load Scheduler_.
 */
export type ParametersForLoadSchedulerComponent = {
  /**
   * Configuration of Weighted Fair Queuing-based workload scheduler.
   *
   * Contains configuration of per-agent scheduler
   */
  scheduler?: WeightedFairQueuingBasedWorkloadScheduler;
  /**
   * Selectors for the component.
   */
  selectors: LoadSchedulerSelector[];
  /**
   * Automatically estimate the size of flows within each workload, based on
   * historical latency. Each workload's `tokens` will be set to average
   * latency of flows in that workload during the last few seconds (exact duration
   * of this average can change).
   * This setting is useful in concurrency limiting use-case, where the
   * concurrency is calculated as `(avg. latency \* in-flight flows)` (Little's Law).
   *
   * The value of tokens estimated takes a lower precedence
   * than the value of `tokens` specified in the workload definition
   * and `tokens` explicitly specified in the flow labels.
   */
  workload_latency_based_tokens?: boolean;
};

/**
 * Configuration of Weighted Fair Queuing-based workload scheduler.
 *
 * Contains configuration of per-agent scheduler
 *
 * :::note
 *
 * Each Agent instantiates an independent copy of the scheduler, but output
 * signals for accepted and incoming token rate are aggregated across all agents.
 *
 * :::
 */
export type WeightedFairQueuingBasedWorkloadScheduler = {
  /**
   * Decision deadline margin is the amount of time that the scheduler will
   * subtract from the request deadline to determine the deadline for the
   * decision. This is to ensure that the scheduler has enough time to
   * make a decision before the request deadline happens, accounting for
   * processing delays.
   * The request deadline is based on the
   * [gRPC deadline](https://grpc.io/blog/deadlines) or the
   * [`grpc-timeout` HTTP
   * header](https://github.com/grpc/grpc/blob/master/doc/PROTOCOL-HTTP2.md#requests).
   *
   * Fail-open logic is use for flow control APIs, so if the gRPC deadline
   * reaches, the flow will end up being unconditionally allowed while
   * it is still waiting on the scheduler.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  decision_deadline_margin?: string;
  /**
   * Parameters to be used if none of workloads specified in `workloads` match.
   */
  default_workload_parameters?: SchedulerWorkloadParameters;
  /**
   * This field allows you to override the default HTTP status code (`503 Service
   * Unavailable`) that is returned when a request is denied.
   */
  denied_response_status_code?: HTTPResponseStatusCode;
  /**
   * Key for a flow label that can be used to override the default priority for this flow.
   * The value associated with this key must be a valid number. Higher numbers means higher
   * priority.
   * If this parameter is not provided, the priority for the flow will be determined by the
   * matched workload's priority.
   */
  priority_label_key?: string;
  /**
   * Key for a flow label that can be used to override the default number of tokens for this
   * request.
   * The value associated with this key must be a valid number.
   * If this parameter is not provided, the number of tokens for the flow will be determined
   * by the matched workload's token count.
   */
  tokens_label_key?: string;
  /**
   * Key for a flow label that can be used to provide workloads for this request.
   * If this parameter is not provided, the workloads for the flow will be determined by the
   * matched workload's name in the policy.
   */
  workload_label_key?: string;
  /**
   * Key for a flow label that is used to enforce fairness among requests in a workload. If
   * not specified, requests within a workload of the same priority are admitted in a FIFO
   * manner.
   */
  fairness_label_key?: string;
  /**
   * List of workloads to be used in scheduler.
   *
   * Categorizing flows into workloads
   * allows for load throttling to be "intelligent" instead of queueing flows in an arbitrary
   * order.
   * There are two aspects of this "intelligence":
   * * Scheduler can more precisely calculate concurrency if it understands
   * that flows belonging to different classes have different weights (for example, insert
   * queries compared to select queries).
   * * Setting different priorities to different workloads lets the scheduler
   * avoid dropping important traffic during overload.
   *
   * Each workload in this list specifies also a matcher that is used to
   * determine which flow will be categorized into which workload.
   * In case of multiple matching workloads, the first matching one will be used.
   * If none of workloads match, `default_workload` will be used.
   *
   * :::info
   *
   * See also [workload definition in the concepts
   * section](/concepts/scheduler/scheduler.md#workload).
   *
   * :::
   */
  workloads?: SchedulerWorkload[];
};

/**
 * Parameters to be used if none of workloads specified in `workloads` match.
 *
 * Parameters such as priority and tokens that are applicable to flows within a workload.
 *
 * Parameters associated with flows matching the label matcher.
 */
export type SchedulerWorkloadParameters = {
  /**
   * $$
   * inverted\_priority = {\frac {1} {priority}}
   * $$
   *
   * $$
   * virtual\_finish\_time = virtual\_time + \left(tokens \cdot inverted\_priority\right)
   * $$
   */
  priority?: number;
  /**
   * Timeout for the flow in the workload.
   * If timeout is provided on the Check call as well, the minimum of the two is picked.
   * If this override is not provided, the timeout provided in the check call is used.
   * 0 timeout value implies that the request will not wait in the queue and will be accepted
   * or dropped immediately.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  queue_timeout?: string;
  /**
   * Tokens determines the cost of admitting a single flow in the workload,
   * which is typically defined as milliseconds of flow latency (time to response or duration
   * of a feature) or
   * simply equal to 1 if the resource being accessed is constrained by the
   * number of flows (3rd party rate limiters).
   * This override is applicable only if tokens for the flow aren't specified
   * in the flow labels.
   */
  tokens?: number;
};

/**
 * This field allows you to override the default HTTP status code (`503 Service
 * Unavailable`) that is returned when a request is denied.
 *
 * - Empty: Empty - This code not part of the HTTP status code specification, but it is
 * needed for `enum` type.
 *
 * This field allows you to override the default HTTP status code (`429 Too Many Requests`)
 * that is returned when a request is denied.
 */
export enum HTTPResponseStatusCode {
  Accepted = "Accepted",
  AlreadyReported = "AlreadyReported",
  BadGateway = "BadGateway",
  BadRequest = "BadRequest",
  Conflict = "Conflict",
  Continue = "Continue",
  Created = "Created",
  Empty = "Empty",
  ExpectationFailed = "ExpectationFailed",
  FailedDependency = "FailedDependency",
  Forbidden = "Forbidden",
  Found = "Found",
  GatewayTimeout = "GatewayTimeout",
  Gone = "Gone",
  HTTPVersionNotSupported = "HTTPVersionNotSupported",
  IMUsed = "IMUsed",
  InsufficientStorage = "InsufficientStorage",
  InternalServerError = "InternalServerError",
  LengthRequired = "LengthRequired",
  Locked = "Locked",
  LoopDetected = "LoopDetected",
  MethodNotAllowed = "MethodNotAllowed",
  MisdirectedRequest = "MisdirectedRequest",
  MovedPermanently = "MovedPermanently",
  MultiStatus = "MultiStatus",
  MultipleChoices = "MultipleChoices",
  NetworkAuthenticationRequired = "NetworkAuthenticationRequired",
  NoContent = "NoContent",
  NonAuthoritativeInformation = "NonAuthoritativeInformation",
  NotAcceptable = "NotAcceptable",
  NotExtended = "NotExtended",
  NotFound = "NotFound",
  NotImplemented = "NotImplemented",
  NotModified = "NotModified",
  Ok = "OK",
  PartialContent = "PartialContent",
  PayloadTooLarge = "PayloadTooLarge",
  PaymentRequired = "PaymentRequired",
  PermanentRedirect = "PermanentRedirect",
  PreconditionFailed = "PreconditionFailed",
  PreconditionRequired = "PreconditionRequired",
  ProxyAuthenticationRequired = "ProxyAuthenticationRequired",
  RangeNotSatisfiable = "RangeNotSatisfiable",
  RequestHeaderFieldsTooLarge = "RequestHeaderFieldsTooLarge",
  RequestTimeout = "RequestTimeout",
  ResetContent = "ResetContent",
  SeeOther = "SeeOther",
  ServiceUnavailable = "ServiceUnavailable",
  TemporaryRedirect = "TemporaryRedirect",
  TooManyRequests = "TooManyRequests",
  URITooLong = "URITooLong",
  Unauthorized = "Unauthorized",
  UnprocessableEntity = "UnprocessableEntity",
  UnsupportedMediaType = "UnsupportedMediaType",
  UpgradeRequired = "UpgradeRequired",
  UseProxy = "UseProxy",
  VariantAlsoNegotiates = "VariantAlsoNegotiates",
}

/**
 * Workload defines a class of flows that preferably have similar properties such as
 * response latency and desired priority.
 */
export type SchedulerWorkload = {
  /**
   * Label Matcher to select a Workload based on
   * [flow labels](/concepts/flow-label.md).
   */
  label_matcher: AllowsToDefineRulesWhetherAMapOfLabelsConceptsFlowLabelMdShouldBeConsideredAMatchOrNot;
  /**
   * Name of the workload.
   */
  name?: string;
  /**
   * Parameters associated with flows matching the label matcher.
   */
  parameters?: SchedulerWorkloadParameters;
};

/**
 * Label Matcher to select a Workload based on
 * [flow labels](/concepts/flow-label.md).
 *
 *
 *
 * It provides three ways to define requirements:
 * - match labels
 * - match expressions
 * - arbitrary expression
 *
 * If multiple requirements are set, they're all combined using the logical AND operator.
 * An empty label matcher always matches.
 *
 * [Label Matcher](/concepts/selector.md#label-matcher)
 * can be used to match flows based on flow labels.
 */
export type AllowsToDefineRulesWhetherAMapOfLabelsConceptsFlowLabelMdShouldBeConsideredAMatchOrNot =
  {
    /**
     * An arbitrary expression to be evaluated on the labels.
     */
    expression?: ExpressionClass;
    /**
     * List of Kubernetes-style label matcher requirements.
     *
     * Note: The requirements are combined using the logical AND operator.
     */
    match_expressions?: K8SLabelMatcherRequirement[];
    /**
     * A map of {key,value} pairs representing labels to be matched.
     * A single {key,value} in the `match_labels` requires that the label `key` is present and
     * equal to `value`.
     *
     * Note: The requirements are combined using the logical AND operator.
     */
    match_labels?: { [key: string]: string };
  };

/**
 * An arbitrary expression to be evaluated on the labels.
 *
 * MatchExpression has multiple variants, exactly one should be set.
 *
 * Example:
 * ```yaml
 * all:
 * of:
 * - label_exists: foo
 * - label_equals:
 * label: app
 * value: frobnicator
 * ```
 *
 * The expression negates the result of sub expression.
 */
export type OfElement = {
  /**
   * The expression is true when all sub expressions are true.
   */
  all?: ListOfMatchExpressionsThatIsUsedForAllOrAnyMatching;
  /**
   * The expression is true when any sub expression is true.
   */
  any?: ListOfMatchExpressionsThatIsUsedForAllOrAnyMatching;
  /**
   * The expression is true when label value equals given value.
   */
  label_equals?: EqualsMatchExpression;
  /**
   * The expression is true when label with given name exists.
   */
  label_exists?: string;
  /**
   * The expression is true when label matches given regular expression.
   */
  label_matches?: MatchesMatchExpression;
  /**
   * The expression negates the result of sub expression.
   */
  not?: ExpressionClass;
};

/**
 * The expression is true when all sub expressions are true.
 *
 * for example, `{any: {of: [expr1, expr2]}}`.
 *
 * The expression is true when any sub expression is true.
 */
export type ListOfMatchExpressionsThatIsUsedForAllOrAnyMatching = {
  /**
   * List of sub expressions of the match expression.
   */
  of?: OfElement[];
};

/**
 * An arbitrary expression to be evaluated on the labels.
 *
 * MatchExpression has multiple variants, exactly one should be set.
 *
 * Example:
 * ```yaml
 * all:
 * of:
 * - label_exists: foo
 * - label_equals:
 * label: app
 * value: frobnicator
 * ```
 *
 * The expression negates the result of sub expression.
 */
export type ExpressionClass = {
  /**
   * The expression is true when all sub expressions are true.
   */
  all?: ListOfMatchExpressionsThatIsUsedForAllOrAnyMatching;
  /**
   * The expression is true when any sub expression is true.
   */
  any?: ListOfMatchExpressionsThatIsUsedForAllOrAnyMatching;
  /**
   * The expression is true when label value equals given value.
   */
  label_equals?: EqualsMatchExpression;
  /**
   * The expression is true when label with given name exists.
   */
  label_exists?: string;
  /**
   * The expression is true when label matches given regular expression.
   */
  label_matches?: MatchesMatchExpression;
  /**
   * The expression negates the result of sub expression.
   */
  not?: ExpressionClass;
};

/**
 * The expression is true when label value equals given value.
 *
 * Label selector expression of the equal form `label == value`.
 */
export type EqualsMatchExpression = {
  /**
   * Name of the label to equal match the value.
   */
  label: string;
  /**
   * Exact value that the label should be equal to.
   */
  value?: string;
};

/**
 * The expression is true when label matches given regular expression.
 *
 * Label selector expression of the form `label matches regex`.
 */
export type MatchesMatchExpression = {
  /**
   * Name of the label to match the regular expression.
   */
  label: string;
  /**
   * Regular expression that should match the label value.
   * It uses [Go's regular expression syntax](https://github.com/google/re2/wiki/Syntax).
   */
  regex: string;
};

/**
 * Label selector requirement which is a selector that contains values, a key, and an
 * operator that relates the key and values.
 */
export type K8SLabelMatcherRequirement = {
  /**
   * Label key that the selector applies to.
   */
  key: string;
  /**
   * Logical operator which represents a key's relationship to a set of values.
   * Valid operators are In, NotIn, Exists and DoesNotExist.
   */
  operator: MatchExpressionOperator;
  /**
   * An array of string values that relates to the key by an operator.
   * If the operator is In or NotIn, the values array must be non-empty.
   * If the operator is Exists or DoesNotExist, the values array must be empty.
   */
  values?: string[];
};

/**
 * Logical operator which represents a key's relationship to a set of values.
 * Valid operators are In, NotIn, Exists and DoesNotExist.
 */
export enum MatchExpressionOperator {
  DoesNotExist = "DoesNotExist",
  Exists = "Exists",
  In = "In",
  NotIn = "NotIn",
}

/**
 * Selects flows based on control point, flow labels, agent group and the service
 * that the flow control component
 * will operate on.
 *
 * :::info
 *
 * See also [Selector overview](/concepts/selector.md).
 *
 * :::
 *
 * Example:
 * ```yaml
 * control_point: ingress
 * label_matcher:
 * match_labels:
 * user_tier: gold
 * match_expressions:
 * - key: query
 * operator: In
 * values:
 * - insert
 * - delete
 * expression:
 * label_matches:
 * - label: user_agent
 * regex: ^(?!.*Chrome).*Safari
 * ```
 */
export type LoadSchedulerSelector = {
  /**
   * [_Agent Group_](/concepts/selector.md#agent-group) this
   * selector applies to.
   *
   * :::info
   *
   * Agent Groups are used to scope policies to a subset of agents connected to the same
   * controller.
   * The agents within an agent group receive exact same policy configuration and
   * form a peer to peer cluster to constantly share state.
   *
   * :::
   */
  agent_group?: string;
  /**
   * [Control Point](/concepts/control-point.md)
   * identifies location within services where policies can act on flows.
   * For an SDK based insertion,
   * a _Control Point_ can represent a particular feature or execution
   * block within a service. In case of service mesh or middleware insertion, a
   * _Control Point_ can identify ingress or egress calls or distinct listeners
   * or filter chains.
   */
  control_point: string;
  /**
   * [Label Matcher](/concepts/selector.md#label-matcher)
   * can be used to match flows based on flow labels.
   */
  label_matcher?: AllowsToDefineRulesWhetherAMapOfLabelsConceptsFlowLabelMdShouldBeConsideredAMatchOrNot;
  /**
   * The Fully Qualified Domain Name of the
   * [service](/concepts/selector.md) to select.
   *
   * In Kubernetes, this is the FQDN of the Service object.
   *
   * :::info
   *
   * `any` matches all services.
   *
   * :::
   *
   * :::info
   *
   * An entity (for example, Kubernetes pod) might belong to multiple services.
   *
   * :::
   */
  service?: string;
};

/**
 * AIAD Load Scheduler.
 *
 * _AIAD Load Scheduler_ reduces the token rate linearly over time while in overload state.
 * During recovery, it increases the token rate linearly until the system is not overloaded.
 */
export type AIADLoadScheduler = {
  /**
   * Decides whether to run the load scheduler in dry-run mode. In dry run mode the scheduler
   * acts as pass through to all flow and does not queue flows.
   * It is useful for observing the behavior of load scheduler without disrupting any real
   * traffic.
   */
  dry_run?: boolean;
  /**
   * Configuration key for setting dry run mode through dynamic configuration.
   */
  dry_run_config_key?: string;
  /**
   * Input ports for the _AIAD Load Scheduler_.
   */
  in_ports?: AIADLoadSchedulerIns;
  /**
   * Output ports for the _AIAD Load Scheduler_.
   */
  out_ports?: AIADLoadSchedulerOuts;
  overload_condition: OverloadConditionDeterminesTheCriteriaToDetermineOverloadStateTheDefaultConditionIsGtThatIsWhenTheSignalIsGreaterThanTheSetpointTheConditionMustBeOneOfValeOffGtGreaterThanLtLessThanGteGreaterThanOrEqualToLTELessThanOrEqualToValeOn;
  /**
   * Parameters for the _AIAD Load Scheduler_.
   */
  parameters: AIADLoadSchedulerParameters;
};

/**
 * Input ports for the _AIAD Load Scheduler_.
 */
export type AIADLoadSchedulerIns = {
  /**
   * The `overload_confirmation` port provides additional criteria to determine overload state
   * which
   * results in _Flow_ throttling at the service.
   */
  overload_confirmation?: SignalValue;
  /**
   * The setpoint input to the controller.
   */
  setpoint: SignalValue;
  /**
   * The input signal to the controller.
   */
  signal: SignalValue;
};

/**
 * Output ports for the _AIAD Load Scheduler_.
 */
export type AIADLoadSchedulerOuts = {
  /**
   * Desired Load multiplier is the ratio of desired token rate to the incoming token rate.
   */
  desired_load_multiplier?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
  /**
   * A Boolean signal that indicates whether the service is in overload state.
   */
  is_overload?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
  /**
   * Observed Load multiplier is the ratio of accepted token rate to the incoming token rate.
   */
  observed_load_multiplier?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

export enum OverloadConditionDeterminesTheCriteriaToDetermineOverloadStateTheDefaultConditionIsGtThatIsWhenTheSignalIsGreaterThanTheSetpointTheConditionMustBeOneOfValeOffGtGreaterThanLtLessThanGteGreaterThanOrEqualToLTELessThanOrEqualToValeOn {
  Gt = "gt",
  Gte = "gte",
  LTE = "lte",
  Lt = "lt",
}

/**
 * Parameters for the _AIAD Load Scheduler_.
 */
export type AIADLoadSchedulerParameters = {
  /**
   * Configuration parameters for the embedded Alerter.
   */
  alerter: AlerterParameters;
  /**
   * Linear decrement to load multiplier every 10 seconds while the system is
   * in the overloaded state, up until the `min_load_multiplier` is reached.
   */
  load_multiplier_linear_decrement?: number;
  /**
   * Linear increment to load multiplier every 10 seconds while the system is
   * not in the overloaded state, up until the `max_load_multiplier` is reached.
   */
  load_multiplier_linear_increment?: number;
  /**
   * Parameters for the _Load Scheduler_.
   */
  load_scheduler: ParametersForLoadSchedulerComponent;
  /**
   * The maximum load multiplier that can be reached during recovery from an overload state.
   * - Helps protect the service from request bursts while the system is still recovering.
   * - Once this value is reached, the scheduler enters the pass-through mode, allowing
   * requests to bypass the scheduler and be sent directly to the service.
   * - The pass-through mode gets disabled if the system enters the overload state again.
   */
  max_load_multiplier?: number;
  /**
   * The minimum load multiplier that can be reached during an overload state.
   */
  min_load_multiplier?: number;
};

/**
 * AIMD Load Scheduler.
 *
 * _AIMD Load Scheduler_ uses a Gradient Controller to throttle the token rate based on the
 * deviation of the signal from the setpoint.
 * It takes a signal and setpoint as inputs and reduces token rate proportionally (or any
 * arbitrary power) based on deviation of the signal from setpoint.
 * During recovery, it increases the token rate linearly until the system is not overloaded.
 */
export type AIMDLoadScheduler = {
  /**
   * Decides whether to run the load scheduler in dry-run mode. In dry run mode the scheduler
   * acts as pass through to all flow and does not queue flows.
   * It is useful for observing the behavior of load scheduler without disrupting any real
   * traffic.
   */
  dry_run?: boolean;
  /**
   * Configuration key for setting dry run mode through dynamic configuration.
   */
  dry_run_config_key?: string;
  /**
   * Input ports for the _AIMD Load Scheduler_.
   */
  in_ports?: AIMDLoadSchedulerIns;
  /**
   * Output ports for the _AIMD Load Scheduler_.
   */
  out_ports?: AIMDLoadSchedulerOuts;
  /**
   * Parameters for the _AIMD Load Scheduler_.
   */
  parameters: AIMDLoadSchedulerParameters;
};

/**
 * Input ports for the _AIMD Load Scheduler_.
 */
export type AIMDLoadSchedulerIns = {
  /**
   * The `overload_confirmation` port provides additional criteria to determine overload state
   * which
   * results in _Flow_ throttling at the service.
   */
  overload_confirmation?: SignalValue;
  /**
   * The setpoint input to the controller.
   */
  setpoint: SignalValue;
  /**
   * The input signal to the controller.
   */
  signal: SignalValue;
};

/**
 * Output ports for the _AIMD Load Scheduler_.
 */
export type AIMDLoadSchedulerOuts = {
  /**
   * Desired Load multiplier is the ratio of desired token rate to the incoming token rate.
   */
  desired_load_multiplier?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
  /**
   * A Boolean signal that indicates whether the service is in overload state.
   */
  is_overload?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
  /**
   * Observed Load multiplier is the ratio of accepted token rate to the incoming token rate.
   */
  observed_load_multiplier?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Parameters for the _AIMD Load Scheduler_.
 */
export type AIMDLoadSchedulerParameters = {
  /**
   * Configuration parameters for the embedded Alerter.
   */
  alerter: AlerterParameters;
  /**
   * Parameters for the Gradient Controller.
   */
  gradient: GradientControllerParameters;
  /**
   * Linear increment to load multiplier every 10 seconds while the system is
   * not in the overloaded state, up until the `max_load_multiplier` is reached.
   */
  load_multiplier_linear_increment?: number;
  /**
   * Parameters for the _Load Scheduler_.
   */
  load_scheduler: ParametersForLoadSchedulerComponent;
  /**
   * The maximum load multiplier that can be reached during recovery from an overload state.
   * - Helps protect the service from request bursts while the system is still recovering.
   * - Once this value is reached, the scheduler enters the pass-through mode, allowing
   * requests to bypass the scheduler and be sent directly to the service.
   * - The pass-through mode gets disabled if the system enters the overload state again.
   */
  max_load_multiplier?: number;
};

/**
 * _Load Ramp_ smoothly regulates the flow of requests over specified steps.
 *
 * The _Load Ramp_ produces a smooth and continuous traffic load
 * that changes progressively over time, based on the specified steps.
 *
 * Each step is defined by two parameters:
 * - The `target_accept_percentage`.
 * - The `duration` for the signal to change from the
 * previous step's `target_accept_percentage` to the current step's
 * `target_accept_percentage`.
 *
 * The percentage of requests accepted starts at the `target_accept_percentage`
 * defined in the first step and gradually ramps up or down linearly from
 * the previous step's `target_accept_percentage` to the next
 * `target_accept_percentage`, over the `duration` specified for each step.
 */
export type LoadRamp = {
  in_ports?: LoadRampIns;
  out_ports?: LoadRampOuts;
  parameters: LoadRampParameters;
  /**
   * Specify certain label values to be always accepted by the _Sampler_ regardless of accept
   * percentage.
   */
  pass_through_label_values?: string[];
  /**
   * Configuration key for setting pass through label values through dynamic configuration.
   */
  pass_through_label_values_config_key?: string;
};

/**
 * Inputs for the _Load Ramp_ component.
 */
export type LoadRampIns = {
  /**
   * Whether to progress the _Load Ramp_ towards the previous step.
   */
  backward?: SignalValue;
  /**
   * Whether to progress the _Load Ramp_ towards the next step.
   */
  forward?: SignalValue;
  /**
   * Whether to reset the _Load Ramp_ to the first step.
   */
  reset?: SignalValue;
};

/**
 * Outputs for the _Load Ramp_ component.
 */
export type LoadRampOuts = {
  /**
   * The percentage of flows being accepted by the _Load Ramp_.
   */
  accept_percentage?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
  /**
   * A Boolean signal indicating whether the _Load Ramp_ is at the end of signal generation.
   */
  at_end?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
  /**
   * A Boolean signal indicating whether the _Load Ramp_ is at the start of signal generation.
   */
  at_start?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Parameters for the _Load Ramp_ component.
 */
export type LoadRampParameters = {
  /**
   * Parameters for the _Sampler_.
   */
  sampler: SamplerParameters;
  steps: LoadRampParametersStep[];
};

/**
 * Parameters for the _Sampler_.
 */
export type SamplerParameters = {
  /**
   * This field allows you to override the default HTTP status code (`503 Service
   * Unavailable`) that is returned when a request is denied.
   */
  denied_response_status_code?: HTTPResponseStatusCode;
  /**
   * Ramp component can accept flows with `ramp_mode` flag set.
   */
  ramp_mode?: boolean;
  /**
   * Selectors for the component.
   */
  selectors: SamplerSelector[];
  /**
   * The flow label key for identifying sessions.
   * - When label key is specified, _Sampler_ acts as a sticky filter.
   * The series of flows with the same value of label key get the same
   * decision provided that the `accept_percentage` is same or higher.
   * - When label key is not specified, _Sampler_ acts as a stateless filter.
   * Percentage of flows are selected randomly for rejection.
   */
  session_label_key?: string;
};

/**
 * Selects flows based on control point, flow labels, agent group and the service
 * that the flow control component
 * will operate on.
 *
 * :::info
 *
 * See also [Selector overview](/concepts/selector.md).
 *
 * :::
 *
 * Example:
 * ```yaml
 * control_point: ingress
 * label_matcher:
 * match_labels:
 * user_tier: gold
 * match_expressions:
 * - key: query
 * operator: In
 * values:
 * - insert
 * - delete
 * expression:
 * label_matches:
 * - label: user_agent
 * regex: ^(?!.*Chrome).*Safari
 * ```
 */
export type SamplerSelector = {
  /**
   * [_Agent Group_](/concepts/selector.md#agent-group) this
   * selector applies to.
   *
   * :::info
   *
   * Agent Groups are used to scope policies to a subset of agents connected to the same
   * controller.
   * The agents within an agent group receive exact same policy configuration and
   * form a peer to peer cluster to constantly share state.
   *
   * :::
   */
  agent_group?: string;
  /**
   * [Control Point](/concepts/control-point.md)
   * identifies location within services where policies can act on flows.
   * For an SDK based insertion,
   * a _Control Point_ can represent a particular feature or execution
   * block within a service. In case of service mesh or middleware insertion, a
   * _Control Point_ can identify ingress or egress calls or distinct listeners
   * or filter chains.
   */
  control_point: string;
  /**
   * [Label Matcher](/concepts/selector.md#label-matcher)
   * can be used to match flows based on flow labels.
   */
  label_matcher?: AllowsToDefineRulesWhetherAMapOfLabelsConceptsFlowLabelMdShouldBeConsideredAMatchOrNot;
  /**
   * The Fully Qualified Domain Name of the
   * [service](/concepts/selector.md) to select.
   *
   * In Kubernetes, this is the FQDN of the Service object.
   *
   * :::info
   *
   * `any` matches all services.
   *
   * :::
   *
   * :::info
   *
   * An entity (for example, Kubernetes pod) might belong to multiple services.
   *
   * :::
   */
  service?: string;
};

export type LoadRampParametersStep = {
  /**
   * This field allows you to override the default HTTP status code (`503 Service
   * Unavailable`) that is returned when a request is denied.
   */
  denied_response_status_code?: HTTPResponseStatusCode;
  /**
   * Duration for which the step is active.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  duration: string;
  /**
   * The value of the step.
   */
  target_accept_percentage?: number;
};

/**
 * _Load Scheduler_ provides service protection by creating a prioritized workload queue in
 * front of the service using Weighted Fair Queuing.
 *
 * :::info
 *
 * See also [_Load Scheduler_ overview](/concepts/scheduler/load-scheduler.md).
 *
 * :::
 *
 * To make scheduling decisions the Flows are mapped into Workloads by providing match
 * rules.
 * A workload determines the priority and cost of admitting each Flow that belongs to it.
 * Scheduling of Flows is based on Weighted Fair Queuing principles.
 *
 * The signal at port `load_multiplier` determines the fraction of incoming tokens that get
 * admitted. The signals gets acted on once every 10 seconds.
 */
export type LoadSchedulerCreatesAQueueForFlowsInFrontOfAServiceToProvideActiveServiceProtection =
  {
    /**
     * Decides whether to run the load scheduler in dry-run mode. In dry run mode the scheduler
     * acts as pass through to all flow and does not queue flows.
     * It is useful for observing the behavior of load scheduler without disrupting any real
     * traffic.
     */
    dry_run?: boolean;
    /**
     * Configuration key for setting dry run mode through dynamic configuration.
     */
    dry_run_config_key?: string;
    /**
     * Input ports for the LoadScheduler component.
     */
    in_ports: LoadSchedulerIns;
    /**
     * Output ports for the LoadScheduler component.
     */
    out_ports?: LoadSchedulerOuts;
    parameters: ParametersForLoadSchedulerComponent;
  };

/**
 * Input ports for the LoadScheduler component.
 *
 *
 *
 * Input for the LoadScheduler component.
 */
export type LoadSchedulerIns = {
  /**
   * Load multiplier is proportion of incoming
   * token rate that needs to be accepted. The signal gets updated once every 10 seconds.
   */
  load_multiplier?: SignalValue;
};

/**
 * Output ports for the LoadScheduler component.
 *
 * Output for the LoadScheduler component.
 */
export type LoadSchedulerOuts = {
  /**
   * Observed load multiplier is the proportion of incoming token rate that is being accepted.
   * The signal gets updated once every 10 seconds.
   */
  observed_load_multiplier?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Schedules the traffic based on token-bucket based quotas.
 */
export type QuotaScheduler = {
  in_ports: InputsForTheRateLimiterComponent;
  /**
   * Output ports for the _Quota Scheduler_ component.
   */
  out_ports?: QuotaSchedulerOuts;
  rate_limiter: RateLimiterParameters;
  scheduler?: WeightedFairQueuingBasedWorkloadScheduler;
  selectors: PurpleSelector[];
};

export type InputsForTheRateLimiterComponent = {
  /**
   * Capacity of the bucket to allow for bursty traffic.
   */
  bucket_capacity: SignalValue;
  /**
   * Number of tokens to fill within an `interval`.
   */
  fill_amount: SignalValue;
  pass_through?: SignalValue;
};

/**
 * Output ports for the _Quota Scheduler_ component.
 *
 * Outputs for the _Quota Scheduler_ component.
 */
export type QuotaSchedulerOuts = {
  /**
   * The percentage of flows being accepted by the _Quota Scheduler_.
   */
  accept_percentage?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

export type RateLimiterParameters = {
  adaptive_load_scheduler?: AdaptiveLoadScheduler;
  /**
   * Continuous fill determines whether the token bucket should be filled
   * continuously or only on discrete intervals.
   */
  continuous_fill?: boolean;
  /**
   * Delays the initial filling of the token bucket.
   * If set to false, the token bucket will start filling immediately
   * after the first request is received. This can potentially lead to
   * more requests being accepted than the specified rate limit during
   * the first interval.
   * When set to true, the token bucket will be given a chance to
   * empty out before the filling starts. The delay is equal to the
   * time it takes to fill the bucket.
   */
  delay_initial_fill?: boolean;
  /**
   * Interval defines the time interval in which the token bucket
   * will fill tokens specified by `fill_amount` signal.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  interval: string;
  /**
   * Specifies which label the rate limiter should be keyed by.
   *
   * Rate limiting is done independently for each value of the
   * [label](/concepts/flow-label.md) with given key.
   * For example, to give each user a separate limit, assuming you
   * have a _user_ flow
   * label set up, set `label_key: "user"`.
   * If no label key is specified, then all requests matching the
   * selectors will be rate limited based on the global bucket.
   * Deprecated: v3.0.0. Use _limit_by_label_key_ instead.
   */
  label_key?: string;
  lazy_sync?: RateLimiterParametersLazySync;
  /**
   * Specifies which label the rate limiter should be keyed by.
   *
   * Rate limiting is done independently for each value of the
   * [label](/concepts/flow-label.md) with given key.
   * For example, to give each user a separate limit, assuming you
   * have a _user_ flow
   * label set up, set `limit_by_label_key: "user"`.
   * If no label key is specified, then all requests matching the
   * selectors will be rate limited based on the global bucket.
   */
  limit_by_label_key?: string;
  /**
   * Max idle time before token bucket state for a label is removed.
   * If set to 0, the state is never removed.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  max_idle_time?: string;
};

export type RateLimiterParametersLazySync = {
  enabled?: boolean;
  /**
   * Number of times to lazy sync within the `interval`.
   */
  num_sync?: number;
};

/**
 * Selects flows based on control point, flow labels, agent group and the service
 * that the flow control component
 * will operate on.
 *
 * :::info
 *
 * See also [Selector overview](/concepts/selector.md).
 *
 * :::
 *
 * Example:
 * ```yaml
 * control_point: ingress
 * label_matcher:
 * match_labels:
 * user_tier: gold
 * match_expressions:
 * - key: query
 * operator: In
 * values:
 * - insert
 * - delete
 * expression:
 * label_matches:
 * - label: user_agent
 * regex: ^(?!.*Chrome).*Safari
 * ```
 */
export type PurpleSelector = {
  /**
   * [_Agent Group_](/concepts/selector.md#agent-group) this
   * selector applies to.
   *
   * :::info
   *
   * Agent Groups are used to scope policies to a subset of agents connected to the same
   * controller.
   * The agents within an agent group receive exact same policy configuration and
   * form a peer to peer cluster to constantly share state.
   *
   * :::
   */
  agent_group?: string;
  /**
   * [Control Point](/concepts/control-point.md)
   * identifies location within services where policies can act on flows.
   * For an SDK based insertion,
   * a _Control Point_ can represent a particular feature or execution
   * block within a service. In case of service mesh or middleware insertion, a
   * _Control Point_ can identify ingress or egress calls or distinct listeners
   * or filter chains.
   */
  control_point: string;
  /**
   * [Label Matcher](/concepts/selector.md#label-matcher)
   * can be used to match flows based on flow labels.
   */
  label_matcher?: AllowsToDefineRulesWhetherAMapOfLabelsConceptsFlowLabelMdShouldBeConsideredAMatchOrNot;
  /**
   * The Fully Qualified Domain Name of the
   * [service](/concepts/selector.md) to select.
   *
   * In Kubernetes, this is the FQDN of the Service object.
   *
   * :::info
   *
   * `any` matches all services.
   *
   * :::
   *
   * :::info
   *
   * An entity (for example, Kubernetes pod) might belong to multiple services.
   *
   * :::
   */
  service?: string;
};

/**
 * Range Driven Load Scheduler.
 *
 * _Range Load Scheduler_ uses the [polynomial range function](#polynomial-range-function)
 * to throttle the token rate based on the range of the signal.
 */
export type RangeDrivenLoadScheduler = {
  /**
   * Decides whether to run the load scheduler in dry-run mode. In dry run mode the scheduler
   * acts as pass through to all flow and does not queue flows.
   * It is useful for observing the behavior of load scheduler without disrupting any real
   * traffic.
   */
  dry_run?: boolean;
  /**
   * Configuration key for setting dry run mode through dynamic configuration.
   */
  dry_run_config_key?: string;
  /**
   * Input ports for the _Range Load Scheduler_.
   */
  in_ports?: RangeDrivenLoadSchedulerIns;
  /**
   * Output ports for the _Range Load Scheduler_.
   */
  out_ports?: RangeDrivenLoadSchedulerOuts;
  /**
   * Parameters for the _Range Load Scheduler_.
   */
  parameters: RangeDrivenLoadSchedulerParameters;
};

/**
 * Input ports for the _Range Load Scheduler_.
 */
export type RangeDrivenLoadSchedulerIns = {
  /**
   * The `overload_confirmation` port provides additional criteria to determine overload state
   * which
   * results in _Flow_ throttling at the service.
   */
  overload_confirmation?: SignalValue;
  /**
   * The input signal to the controller.
   */
  signal: SignalValue;
};

/**
 * Output ports for the _Range Load Scheduler_.
 */
export type RangeDrivenLoadSchedulerOuts = {
  /**
   * Desired Load multiplier is the ratio of desired token rate to the incoming token rate.
   */
  desired_load_multiplier?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
  /**
   * A Boolean signal that indicates whether the service is in overload state.
   */
  is_overload?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
  /**
   * Observed Load multiplier is the ratio of accepted token rate to the incoming token rate.
   */
  observed_load_multiplier?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Parameters for the _Range Load Scheduler_.
 */
export type RangeDrivenLoadSchedulerParameters = {
  /**
   * Configuration parameters for the embedded Alerter.
   */
  alerter: AlerterParameters;
  degree: number;
  high_throttle_threshold: RangeDrivenLoadSchedulerDatapoint;
  /**
   * Parameters for the _Load Scheduler_.
   */
  load_scheduler: ParametersForLoadSchedulerComponent;
  low_throttle_threshold: RangeDrivenLoadSchedulerDatapoint;
};

export type RangeDrivenLoadSchedulerDatapoint = {
  load_multiplier?: number;
  signal_value?: number;
};

/**
 * _Rate Limiter_ provides service protection by applying rate limits using the token bucket
 * algorithm.
 *
 * :::info
 *
 * See also [_Rate Limiter_ overview](/concepts/rate-limiter.md).
 *
 * :::
 *
 * RateLimiting is done on per-label-value (`label_key`) basis and it uses the _Token Bucket
 * Algorithm_.
 */
export type LimitsTheTrafficOnAControlPointToSpecifiedRate = {
  in_ports: InputsForTheRateLimiterComponent;
  /**
   * Output ports for the _Rate Limiter_ component.
   */
  out_ports?: RateLimiterOuts;
  parameters: RateLimiterParameters;
  request_parameters?: RateLimiterRequestParameters;
  /**
   * Selectors for the component.
   */
  selectors: RateLimiterSelector[];
};

/**
 * Output ports for the _Rate Limiter_ component.
 *
 * Outputs for the _Rate Limiter_ component.
 */
export type RateLimiterOuts = {
  /**
   * The percentage of flows being accepted by the _Rate Limiter_.
   */
  accept_percentage?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

export type RateLimiterRequestParameters = {
  /**
   * This field allows you to override the default HTTP status code (`429 Too Many Requests`)
   * that is returned when a request is denied.
   */
  denied_response_status_code?: HTTPResponseStatusCode;
  /**
   * Flow label key that will be used to override the number of tokens
   * for this request.
   * This is an optional parameter and takes highest precedence
   * when assigning tokens to a request.
   * The label value must be a valid number.
   */
  tokens_label_key?: string;
};

/**
 * Selects flows based on control point, flow labels, agent group and the service
 * that the flow control component
 * will operate on.
 *
 * :::info
 *
 * See also [Selector overview](/concepts/selector.md).
 *
 * :::
 *
 * Example:
 * ```yaml
 * control_point: ingress
 * label_matcher:
 * match_labels:
 * user_tier: gold
 * match_expressions:
 * - key: query
 * operator: In
 * values:
 * - insert
 * - delete
 * expression:
 * label_matches:
 * - label: user_agent
 * regex: ^(?!.*Chrome).*Safari
 * ```
 */
export type RateLimiterSelector = {
  /**
   * [_Agent Group_](/concepts/selector.md#agent-group) this
   * selector applies to.
   *
   * :::info
   *
   * Agent Groups are used to scope policies to a subset of agents connected to the same
   * controller.
   * The agents within an agent group receive exact same policy configuration and
   * form a peer to peer cluster to constantly share state.
   *
   * :::
   */
  agent_group?: string;
  /**
   * [Control Point](/concepts/control-point.md)
   * identifies location within services where policies can act on flows.
   * For an SDK based insertion,
   * a _Control Point_ can represent a particular feature or execution
   * block within a service. In case of service mesh or middleware insertion, a
   * _Control Point_ can identify ingress or egress calls or distinct listeners
   * or filter chains.
   */
  control_point: string;
  /**
   * [Label Matcher](/concepts/selector.md#label-matcher)
   * can be used to match flows based on flow labels.
   */
  label_matcher?: AllowsToDefineRulesWhetherAMapOfLabelsConceptsFlowLabelMdShouldBeConsideredAMatchOrNot;
  /**
   * The Fully Qualified Domain Name of the
   * [service](/concepts/selector.md) to select.
   *
   * In Kubernetes, this is the FQDN of the Service object.
   *
   * :::info
   *
   * `any` matches all services.
   *
   * :::
   *
   * :::info
   *
   * An entity (for example, Kubernetes pod) might belong to multiple services.
   *
   * :::
   */
  service?: string;
};

/**
 * Sampler is a component that regulates the flow of requests to the service by allowing
 * only the specified percentage of requests or sticky sessions.
 *
 * _Sampler_ is a component that regulates the load at a
 * [_Control Point_](/concepts/selector.md/#control-point) by allowing only a specified
 * percentage of
 * flows at random or by sticky sessions.
 *
 * :::info
 *
 * See also [_Sampler_ overview](/concepts/advanced/load-ramp.md#sampler).
 *
 * :::
 */
export type Sampler = {
  /**
   * Input ports for the _Sampler_.
   */
  in_ports?: SamplerIns;
  /**
   * Parameters for the _Sampler_.
   */
  parameters: SamplerParameters;
  /**
   * Specify certain label values to be always accepted by this _Sampler_ regardless of accept
   * percentage.
   */
  pass_through_label_values?: string[];
  /**
   * Configuration key for setting pass through label values through dynamic configuration.
   */
  pass_through_label_values_config_key?: string;
};

/**
 * Input ports for the _Sampler_.
 */
export type SamplerIns = {
  /**
   * The percentage of requests to accept.
   */
  accept_percentage?: SignalValue;
};

/**
 * Gradient controller calculates the ratio between the signal and the setpoint to determine
 * the magnitude of the correction that need to be applied.
 * This controller can be used to build AIMD (Additive Increase, Multiplicative Decrease) or
 * MIMD style response.
 *
 * The `gradient` describes a corrective factor that should be applied to the
 * control variable to get the signal closer to the setpoint. It's computed as follows:
 *
 * $$
 * \text{gradient} = \left(\frac{\text{signal}}{\text{setpoint}}\right)^{\text{slope}}
 * $$
 *
 * `gradient` is then clamped to `[min_gradient, max_gradient]` range.
 *
 * The output of gradient controller is computed as follows:
 * $$
 * \text{output} = \text{gradient}_{\text{clamped}} \cdot \text{control\_variable} +
 * \text{optimize}.
 * $$
 *
 * Note the additional `optimize` signal, that can be used to "nudge" the
 * controller into desired idle state.
 *
 * The output can be _optionally_ clamped to desired range using `max` and
 * `min` input.
 */
export type GradientControllerIsATypeOfControllerWhichTriesToAdjustTheControlVariableProportionallyToTheRelativeDifferenceBetweenSetpointAndActualValueOfTheSignal =
  {
    /**
     * Input ports of the Gradient Controller.
     */
    in_ports?: GradientControllerIns;
    /**
     * In manual mode, the controller does not adjust the control variable. It emits the same
     * output as the control variable input. This setting can be adjusted at runtime through
     * dynamic configuration without restarting the policy.
     */
    manual_mode?: boolean;
    /**
     * Configuration key for overriding `manual_mode` setting through dynamic configuration.
     */
    manual_mode_config_key?: string;
    /**
     * Output ports of the Gradient Controller.
     */
    out_ports?: GradientControllerOuts;
    /**
     * Gradient Parameters.
     */
    parameters: GradientControllerParameters;
  };

/**
 * Input ports of the Gradient Controller.
 *
 * Inputs for the Gradient Controller component.
 */
export type GradientControllerIns = {
  /**
   * Actual current value of the control variable.
   *
   * This signal is multiplied by the gradient to produce the output.
   */
  control_variable?: SignalValue;
  /**
   * Maximum value to limit the output signal.
   */
  max?: SignalValue;
  /**
   * Minimum value to limit the output signal.
   */
  min?: SignalValue;
  /**
   * Setpoint to be used for the gradient computation.
   */
  setpoint?: SignalValue;
  /**
   * Signal to be used for the gradient computation.
   */
  signal?: SignalValue;
};

/**
 * Output ports of the Gradient Controller.
 *
 * Outputs for the Gradient Controller component.
 */
export type GradientControllerOuts = {
  /**
   * Computed desired value of the control variable.
   */
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Holds the last valid signal value for the specified duration then waits for next valid
 * value to hold.
 *
 * Holds the last valid signal value for the specified duration then waits for next valid
 * value to hold.
 * If it is holding a value that means it ignores both valid and invalid new signals until
 * the `hold_for` duration is finished.
 */
export type Holder = {
  /**
   * Holding the last valid signal value for the `hold_for` duration.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  hold_for?: string;
  /**
   * Input ports for the Holder component.
   */
  in_ports?: HolderIns;
  /**
   * Output ports for the Holder component.
   */
  out_ports?: HolderOuts;
};

/**
 * Input ports for the Holder component.
 *
 * Inputs for the Holder component.
 */
export type HolderIns = {
  /**
   * The input signal.
   */
  input?: SignalValue;
  /**
   * Resets the holder output to the current input signal when reset signal is valid and
   * non-zero.
   */
  reset?: SignalValue;
};

/**
 * Output ports for the Holder component.
 *
 * Outputs for the Holder component.
 */
export type HolderOuts = {
  /**
   * The output signal.
   */
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Accumulates sum of signal every tick.
 */
export type Integrator = {
  /**
   * The evaluation interval of the Integrator. This determines how often the Integrator is
   * incremented. Defaults to the evaluation interval of the circuit.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  evaluation_interval?: string;
  /**
   * Input ports for the Integrator component.
   */
  in_ports?: IntegratorIns;
  /**
   * Initial value of the integrator.
   */
  initial_value?: number;
  /**
   * Output ports for the Integrator component.
   */
  out_ports?: IntegratorOuts;
};

/**
 * Input ports for the Integrator component.
 *
 * Inputs for the Integrator component.
 */
export type IntegratorIns = {
  /**
   * The input signal.
   */
  input?: SignalValue;
  /**
   * The maximum output.
   */
  max?: SignalValue;
  /**
   * The minimum output.
   */
  min?: SignalValue;
  /**
   * Resets the integrator output to zero when reset signal is valid and non-zero. Reset also
   * resets the max and min constraints.
   */
  reset?: SignalValue;
};

/**
 * Output ports for the Integrator component.
 *
 * Outputs for the Integrator component.
 */
export type IntegratorOuts = {
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Logical NOT.
 *
 * Logical NOT.
 *
 * See [And component](#and) on how signals are mapped onto Boolean values.
 */
export type Inverter = {
  /**
   * Input ports for the Inverter component.
   */
  in_ports?: InverterIns;
  /**
   * Output ports for the Inverter component.
   */
  out_ports?: InverterOuts;
};

/**
 * Input ports for the Inverter component.
 *
 * Inputs for the Inverter component.
 */
export type InverterIns = {
  /**
   * Signal to be negated.
   */
  input?: SignalValue;
};

/**
 * Output ports for the Inverter component.
 */
export type InverterOuts = {
  /**
   * Logical negation of the input signal.
   *
   * Will always be 0 (false), 1 (true) or invalid (unknown).
   */
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Emits the maximum of the input signals.
 *
 * Max: output = max([]inputs).
 */
export type TakesAListOfInputSignalsAndEmitsTheSignalWithTheMaximumValue = {
  /**
   * Input ports for the Max component.
   */
  in_ports?: MaxIns;
  /**
   * Output ports for the Max component.
   */
  out_ports?: MaxOuts;
};

/**
 * Input ports for the Max component.
 *
 * Inputs for the Max component.
 */
export type MaxIns = {
  /**
   * Array of input signals.
   */
  inputs?: TentacledComponentsReceiveInputFromOtherComponentsThroughInPorts[];
};

/**
 * Signal which Alerter is monitoring. If the signal greater than 0, Alerter generates an
 * alert.
 *
 * Left hand side of the arithmetic operation.
 *
 * Right hand side of the arithmetic operation.
 *
 * The setpoint to use for scale-in.
 *
 * The signal to use for scale-in.
 *
 * The setpoint to use for scale-out.
 *
 * The signal to use for scale-out.
 *
 * Left hand side input signal for the comparison operation.
 *
 * Right hand side input signal for the comparison operation.
 *
 * Input signal to be used for the EMA computation.
 *
 * Upper bound of the moving average.
 *
 * When the signal exceeds `max_envelope` it is multiplied by
 * `correction_factor_on_max_envelope_violation` **once per tick**.
 *
 * :::note
 *
 * If the signal deviates from `max_envelope` faster than the correction
 * faster, it might end up exceeding the envelope.
 *
 * :::
 *
 * Lower bound of the moving average.
 *
 * Behavior is similar to `max_envelope`.
 *
 * Input signal for the Extrapolator component.
 *
 * The `overload_confirmation` port provides additional criteria to determine overload state
 * which
 * results in _Flow_ throttling at the service.
 *
 * The setpoint input to the controller.
 *
 *
 *
 * The input signal to the controller.
 *
 *
 *
 * Whether to progress the _Load Ramp_ towards the previous step.
 *
 * Whether to progress the _Load Ramp_ towards the next step.
 *
 * Whether to reset the _Load Ramp_ to the first step.
 *
 * Load multiplier is proportion of incoming
 * token rate that needs to be accepted. The signal gets updated once every 10 seconds.
 *
 * Capacity of the bucket to allow for bursty traffic.
 *
 *
 *
 * Number of tokens to fill within an `interval`.
 *
 *
 *
 * The percentage of requests to accept.
 *
 * Actual current value of the control variable.
 *
 * This signal is multiplied by the gradient to produce the output.
 *
 * Maximum value to limit the output signal.
 *
 * Minimum value to limit the output signal.
 *
 * Setpoint to be used for the gradient computation.
 *
 * Signal to be used for the gradient computation.
 *
 * The input signal.
 *
 * Resets the holder output to the current input signal when reset signal is valid and
 * non-zero.
 *
 * The maximum output.
 *
 * The minimum output.
 *
 * Resets the integrator output to zero when reset signal is valid and non-zero. Reset also
 * resets the max and min constraints.
 *
 * Signal to be negated.
 *
 *
 *
 * Egress signal.
 *
 * Whether to progress the _Signal Generator_ towards the previous step.
 *
 * Whether to progress the _Signal Generator_ towards the next step.
 *
 * Whether to reset the _Signal Generator_ to the first step.
 *
 * Signal to be used for the moving average computation.
 *
 * Output signal when switch is invalid or 0.0.
 *
 * Output signal when switch is valid and not 0.0.
 *
 * Decides whether to return `on_signal` or `off_signal`.
 *
 * Input signal.
 */
export type TentacledComponentsReceiveInputFromOtherComponentsThroughInPorts = {
  /**
   * Constant value to be used for this InPort instead of a signal.
   */
  constant_signal?: ConstantSignal;
  /**
   * Name of the incoming Signal on the InPort.
   */
  signal_name?: string;
};

/**
 * Output ports for the Max component.
 *
 * Output for the Max component.
 */
export type MaxOuts = {
  /**
   * Signal with maximum value as an output signal.
   */
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Emits the minimum of the input signals.
 *
 * Takes an array of input signals and emits the signal with the minimum value
 * Min: output = min([]inputs).
 */
export type Min = {
  /**
   * Input ports for the Min component.
   */
  in_ports?: MinIns;
  /**
   * Output ports for the Min component.
   */
  out_ports?: MinOuts;
};

/**
 * Input ports for the Min component.
 *
 * Inputs for the Min component.
 */
export type MinIns = {
  /**
   * Array of input signals.
   */
  inputs?: StickyComponentsReceiveInputFromOtherComponentsThroughInPorts[];
};

/**
 * Signal which Alerter is monitoring. If the signal greater than 0, Alerter generates an
 * alert.
 *
 * Left hand side of the arithmetic operation.
 *
 * Right hand side of the arithmetic operation.
 *
 * The setpoint to use for scale-in.
 *
 * The signal to use for scale-in.
 *
 * The setpoint to use for scale-out.
 *
 * The signal to use for scale-out.
 *
 * Left hand side input signal for the comparison operation.
 *
 * Right hand side input signal for the comparison operation.
 *
 * Input signal to be used for the EMA computation.
 *
 * Upper bound of the moving average.
 *
 * When the signal exceeds `max_envelope` it is multiplied by
 * `correction_factor_on_max_envelope_violation` **once per tick**.
 *
 * :::note
 *
 * If the signal deviates from `max_envelope` faster than the correction
 * faster, it might end up exceeding the envelope.
 *
 * :::
 *
 * Lower bound of the moving average.
 *
 * Behavior is similar to `max_envelope`.
 *
 * Input signal for the Extrapolator component.
 *
 * The `overload_confirmation` port provides additional criteria to determine overload state
 * which
 * results in _Flow_ throttling at the service.
 *
 * The setpoint input to the controller.
 *
 *
 *
 * The input signal to the controller.
 *
 *
 *
 * Whether to progress the _Load Ramp_ towards the previous step.
 *
 * Whether to progress the _Load Ramp_ towards the next step.
 *
 * Whether to reset the _Load Ramp_ to the first step.
 *
 * Load multiplier is proportion of incoming
 * token rate that needs to be accepted. The signal gets updated once every 10 seconds.
 *
 * Capacity of the bucket to allow for bursty traffic.
 *
 *
 *
 * Number of tokens to fill within an `interval`.
 *
 *
 *
 * The percentage of requests to accept.
 *
 * Actual current value of the control variable.
 *
 * This signal is multiplied by the gradient to produce the output.
 *
 * Maximum value to limit the output signal.
 *
 * Minimum value to limit the output signal.
 *
 * Setpoint to be used for the gradient computation.
 *
 * Signal to be used for the gradient computation.
 *
 * The input signal.
 *
 * Resets the holder output to the current input signal when reset signal is valid and
 * non-zero.
 *
 * The maximum output.
 *
 * The minimum output.
 *
 * Resets the integrator output to zero when reset signal is valid and non-zero. Reset also
 * resets the max and min constraints.
 *
 * Signal to be negated.
 *
 *
 *
 * Egress signal.
 *
 * Whether to progress the _Signal Generator_ towards the previous step.
 *
 * Whether to progress the _Signal Generator_ towards the next step.
 *
 * Whether to reset the _Signal Generator_ to the first step.
 *
 * Signal to be used for the moving average computation.
 *
 * Output signal when switch is invalid or 0.0.
 *
 * Output signal when switch is valid and not 0.0.
 *
 * Decides whether to return `on_signal` or `off_signal`.
 *
 * Input signal.
 */
export type StickyComponentsReceiveInputFromOtherComponentsThroughInPorts = {
  /**
   * Constant value to be used for this InPort instead of a signal.
   */
  constant_signal?: ConstantSignal;
  /**
   * Name of the incoming Signal on the InPort.
   */
  signal_name?: string;
};

/**
 * Output ports for the Min component.
 */
export type MinOuts = {
  /**
   * Signal with minimum value as an output signal.
   */
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * :::info
 *
 * See also [Components overview](/concepts/advanced/circuit.md#components).
 *
 * :::
 *
 * Signals flow into the components from input ports and results are emitted on output
 * ports.
 * Components are wired to each other based on signal names forming an execution graph of
 * the circuit.
 *
 * :::note
 *
 * Loops are broken by the runtime at the earliest component index that is part of the loop.
 * The looped signals are saved in the tick they're generated and served in the subsequent
 * tick.
 *
 * :::
 *
 * See also [Policy](#policy) for a higher-level explanation of circuits.
 */
export type NestedCircuitComponent = {
  /**
   * Alerter reacts to a signal and generates alert to send to alert manager.
   */
  alerter?: Alerter;
  /**
   * Logical AND.
   */
  and?: And;
  /**
   * Applies the given operator on input operands (signals) and emits the result.
   */
  arithmetic_combinator?: TypeOfCombinatorThatComputesTheArithmeticOperationOnTheOperandSignals;
  /**
   * AutoScale components are used to scale the service.
   */
  auto_scale?: AutoScale;
  /**
   * BoolVariable emits a constant Boolean signal which can be changed at runtime through
   * dynamic configuration.
   */
  bool_variable?: BoolVariable;
  /**
   * Decider emits the binary result of comparison operator on two operands.
   */
  decider?: TypeOfCombinatorThatComputesTheComparisonOperationOnLHSAndRHSSignals;
  /**
   * Differentiator calculates rate of change per tick.
   * Deprecated: v3.0.0. Use `PIDController` instead.
   */
  differentiator?: Differentiator;
  /**
   * Exponential Moving Average filter.
   */
  ema?: ExponentialMovingAverageEMAIsATypeOfMovingAverageThatAppliesExponentiallyMoreWeightToRecentSignalReadings;
  /**
   * Takes an input signal and emits the extrapolated value; either mirroring the input value
   * or repeating the last known value up to the maximum extrapolation interval.
   */
  extrapolator?: ExtrapolatesTheInputSignalByRepeatingTheLastValidValueDuringThePeriodInWhichItIsInvalid;
  /**
   * Picks the first valid input signal and emits it.
   */
  first_valid?: PicksTheFirstValidInputSignalFromTheArrayOfInputSignalsAndEmitsItAsAnOutputSignal;
  /**
   * FlowControl components are used to regulate requests flow.
   */
  flow_control?: FlowControl;
  /**
   * Gradient controller calculates the ratio between the signal and the setpoint to determine
   * the magnitude of the correction that need to be applied.
   * This controller can be used to build AIMD (Additive Increase, Multiplicative Decrease) or
   * MIMD style response.
   */
  gradient_controller?: GradientControllerIsATypeOfControllerWhichTriesToAdjustTheControlVariableProportionallyToTheRelativeDifferenceBetweenSetpointAndActualValueOfTheSignal;
  /**
   * Holds the last valid signal value for the specified duration then waits for next valid
   * value to hold.
   */
  holder?: Holder;
  /**
   * Accumulates sum of signal every tick.
   */
  integrator?: Integrator;
  /**
   * Logical NOT.
   */
  inverter?: Inverter;
  /**
   * Emits the maximum of the input signals.
   */
  max?: TakesAListOfInputSignalsAndEmitsTheSignalWithTheMaximumValue;
  /**
   * Emits the minimum of the input signals.
   */
  min?: Min;
  /**
   * Nested circuit defines a sub-circuit as a high-level component. It consists of a list of
   * components and a map of input and output ports.
   */
  nested_circuit?: NestedCircuit;
  /**
   * Nested signal egress is a special type of component that allows to extract a signal from
   * a nested circuit.
   */
  nested_signal_egress?: NestedSignalEgress;
  /**
   * Nested signal ingress is a special type of component that allows to inject a signal into
   * a nested circuit.
   */
  nested_signal_ingress?: NestedSignalIngress;
  /**
   * Logical OR.
   */
  or?: Or;
  /**
   * PID Controller is a proportional–integral–derivative controller.
   */
  pid_controller?: PIDControllerIsAPopularControlMechanismForClosedLoopFeedbackControlItTakesASignalAndASetpointAsInputsAndUsesThePIDAlgorithmToComputeProportionalProportionalToTheMagnitudeOfErrorIntegralProportionalToAccumulationOfErrorAndDerivativeProportionalToHowFastTheSignalIsChangingTermsWhichAreSummedUpToGetADesiredOutputValeOffErrorSetpointSignalIntegralIIntegralI1KICdotErrorOutputIKPCdotErrorKDCdotSignalISignalI1IntegralIValeOn;
  /**
   * Polynomial Range Function is a function that maps a signal to a range of values following
   * a polynomial function.
   */
  polynomial_range_function?: PolynomialRangeFunction;
  /**
   * Generates 0 and 1 in turns.
   */
  pulse_generator?: PulseGenerator;
  /**
   * Query components that are query databases such as Prometheus.
   */
  query?: Query;
  /**
   * Generates the specified signal.
   */
  signal_generator?: SignalGenerator;
  /**
   * Simple Moving Average filter.
   */
  sma?: SMA;
  /**
   * Switcher acts as a switch that emits one of the two signals based on third signal.
   */
  switcher?: TypeOfCombinatorThatSwitchesBetweenOnSignalAndOffSignalSignalsBasedOnSwitchInput;
  /**
   * Takes an input signal and emits the square root of the input signal.
   */
  unary_operator?: TakesAnInputSignalAndEmitsTheOutputAfterApplyingTheSpecifiedUnaryOperator;
  /**
   * Emits a variable signal which can be changed at runtime through dynamic configuration.
   */
  variable?: Variable;
};

/**
 * Nested circuit defines a sub-circuit as a high-level component. It consists of a list of
 * components and a map of input and output ports.
 */
export type NestedCircuit = {
  /**
   * List of components in the nested circuit.
   */
  components?: NestedCircuitComponent[];
  /**
   * Maps input port names to input ports.
   */
  in_ports_map?: { [key: string]: SignalValue };
  /**
   * Maps output port names to output ports.
   */
  out_ports_map?: {
    [key: string]: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
  };
};

/**
 * Nested signal egress is a special type of component that allows to extract a signal from
 * a nested circuit.
 */
export type NestedSignalEgress = {
  /**
   * Input ports for the NestedSignalEgress component.
   */
  in_ports?: NestedSignalEgressIns;
  /**
   * Name of the port.
   */
  port_name?: string;
};

/**
 * Input ports for the NestedSignalEgress component.
 *
 * Inputs for the NestedSignalEgress component.
 */
export type NestedSignalEgressIns = {
  /**
   * Egress signal.
   */
  signal?: SignalValue;
};

/**
 * Nested signal ingress is a special type of component that allows to inject a signal into
 * a nested circuit.
 */
export type NestedSignalIngress = {
  /**
   * Output ports for the NestedSignalIngress component.
   */
  out_ports?: NestedSignalIngressOuts;
  /**
   * Name of the port.
   */
  port_name?: string;
};

/**
 * Output ports for the NestedSignalIngress component.
 *
 * Outputs for the NestedSignalIngress component.
 */
export type NestedSignalIngressOuts = {
  /**
   * Ingress signal.
   */
  signal?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Logical OR.
 *
 * Logical OR.
 *
 * See [And component](#and) on how signals are mapped onto Boolean values.
 */
export type Or = {
  /**
   * Input ports for the Or component.
   */
  in_ports?: OrIns;
  /**
   * Output ports for the Or component.
   */
  out_ports?: OrOuts;
};

/**
 * Input ports for the Or component.
 *
 * Inputs for the Or component.
 */
export type OrIns = {
  /**
   * Array of input signals.
   */
  inputs?: IndigoComponentsReceiveInputFromOtherComponentsThroughInPorts[];
};

/**
 * Signal which Alerter is monitoring. If the signal greater than 0, Alerter generates an
 * alert.
 *
 * Left hand side of the arithmetic operation.
 *
 * Right hand side of the arithmetic operation.
 *
 * The setpoint to use for scale-in.
 *
 * The signal to use for scale-in.
 *
 * The setpoint to use for scale-out.
 *
 * The signal to use for scale-out.
 *
 * Left hand side input signal for the comparison operation.
 *
 * Right hand side input signal for the comparison operation.
 *
 * Input signal to be used for the EMA computation.
 *
 * Upper bound of the moving average.
 *
 * When the signal exceeds `max_envelope` it is multiplied by
 * `correction_factor_on_max_envelope_violation` **once per tick**.
 *
 * :::note
 *
 * If the signal deviates from `max_envelope` faster than the correction
 * faster, it might end up exceeding the envelope.
 *
 * :::
 *
 * Lower bound of the moving average.
 *
 * Behavior is similar to `max_envelope`.
 *
 * Input signal for the Extrapolator component.
 *
 * The `overload_confirmation` port provides additional criteria to determine overload state
 * which
 * results in _Flow_ throttling at the service.
 *
 * The setpoint input to the controller.
 *
 *
 *
 * The input signal to the controller.
 *
 *
 *
 * Whether to progress the _Load Ramp_ towards the previous step.
 *
 * Whether to progress the _Load Ramp_ towards the next step.
 *
 * Whether to reset the _Load Ramp_ to the first step.
 *
 * Load multiplier is proportion of incoming
 * token rate that needs to be accepted. The signal gets updated once every 10 seconds.
 *
 * Capacity of the bucket to allow for bursty traffic.
 *
 *
 *
 * Number of tokens to fill within an `interval`.
 *
 *
 *
 * The percentage of requests to accept.
 *
 * Actual current value of the control variable.
 *
 * This signal is multiplied by the gradient to produce the output.
 *
 * Maximum value to limit the output signal.
 *
 * Minimum value to limit the output signal.
 *
 * Setpoint to be used for the gradient computation.
 *
 * Signal to be used for the gradient computation.
 *
 * The input signal.
 *
 * Resets the holder output to the current input signal when reset signal is valid and
 * non-zero.
 *
 * The maximum output.
 *
 * The minimum output.
 *
 * Resets the integrator output to zero when reset signal is valid and non-zero. Reset also
 * resets the max and min constraints.
 *
 * Signal to be negated.
 *
 *
 *
 * Egress signal.
 *
 * Whether to progress the _Signal Generator_ towards the previous step.
 *
 * Whether to progress the _Signal Generator_ towards the next step.
 *
 * Whether to reset the _Signal Generator_ to the first step.
 *
 * Signal to be used for the moving average computation.
 *
 * Output signal when switch is invalid or 0.0.
 *
 * Output signal when switch is valid and not 0.0.
 *
 * Decides whether to return `on_signal` or `off_signal`.
 *
 * Input signal.
 */
export type IndigoComponentsReceiveInputFromOtherComponentsThroughInPorts = {
  /**
   * Constant value to be used for this InPort instead of a signal.
   */
  constant_signal?: ConstantSignal;
  /**
   * Name of the incoming Signal on the InPort.
   */
  signal_name?: string;
};

/**
 * Output ports for the Or component.
 */
export type OrOuts = {
  /**
   * Result of logical OR of all the input signals.
   *
   * Will always be 0 (false), 1 (true) or invalid (unknown).
   */
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * PID Controller is a proportional–integral–derivative controller.
 */
export type PIDControllerIsAPopularControlMechanismForClosedLoopFeedbackControlItTakesASignalAndASetpointAsInputsAndUsesThePIDAlgorithmToComputeProportionalProportionalToTheMagnitudeOfErrorIntegralProportionalToAccumulationOfErrorAndDerivativeProportionalToHowFastTheSignalIsChangingTermsWhichAreSummedUpToGetADesiredOutputValeOffErrorSetpointSignalIntegralIIntegralI1KICdotErrorOutputIKPCdotErrorKDCdotSignalISignalI1IntegralIValeOn =
  {
    in_ports?: PIDControllerIns;
    out_ports?: PIDControllerOuts;
    parameters: PIDControllerParameters;
  };

export type PIDControllerIns = {
  max?: SignalValue;
  min?: SignalValue;
  setpoint?: SignalValue;
  signal?: SignalValue;
};

export type PIDControllerOuts = {
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

export type PIDControllerParameters = {
  /**
   * The evaluation interval of the PID controller. This determines how often the PID output
   * is computed. Defaults to the evaluation interval of the circuit.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  evaluation_interval?: string;
  /**
   * The derivative gain of the PID controller.
   */
  kd?: number;
  /**
   * The integral gain of the PID controller.
   */
  ki?: number;
  /**
   * The proportional gain of the PID controller.
   */
  kp?: number;
  /**
   * The integrator resets after the specified number of ticks if the signal or setpoint are
   * continuously invalid. Defaults to 4 invalid samples.
   */
  reset_after_invalid_samples?: number;
};

/**
 * Polynomial Range Function is a function that maps a signal to a range of values following
 * a polynomial function.
 *
 * Curve Types by Degree:
 * - Degree 1: Linear
 * - Degree 2: Quadratic
 * - Degree 3: Cubic
 * - and so on.
 */
export type PolynomialRangeFunction = {
  in_ports?: PolynomialRangeFunctionIns;
  out_ports?: PolynomialRangeFunctionOuts;
  parameters: PolynomialRangeFunctionParameters;
};

export type PolynomialRangeFunctionIns = {
  /**
   * The input signal.
   */
  input?: SignalValue;
};

export type PolynomialRangeFunctionOuts = {
  /**
   * The output signal.
   */
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

export type PolynomialRangeFunctionParameters = {
  clamp_to_custom_values?: PolynomialRangeFunctionParametersClampToCustomValues;
  clamp_to_datapoint?: boolean;
  continue_curve?: boolean;
  degree?: number;
  end?: PolynomialRangeFunctionParametersDatapoint;
  start?: PolynomialRangeFunctionParametersDatapoint;
};

export type PolynomialRangeFunctionParametersClampToCustomValues = {
  post_end?: number;
  pre_start?: number;
};

export type PolynomialRangeFunctionParametersDatapoint = {
  input?: number;
  output?: number;
};

/**
 * Generates 0 and 1 in turns.
 */
export type PulseGenerator = {
  /**
   * Emitting 0 for the `false_for` duration.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  false_for?: string;
  /**
   * Output ports for the PulseGenerator component.
   */
  out_ports?: PulseGeneratorOuts;
  /**
   * Emitting 1 for the `true_for` duration.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  true_for?: string;
};

/**
 * Output ports for the PulseGenerator component.
 *
 * Outputs for the PulseGenerator component.
 */
export type PulseGeneratorOuts = {
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Query components that are query databases such as Prometheus.
 */
export type Query = {
  /**
   * Periodically runs a Prometheus query in the background and emits the result.
   */
  promql?: ComponentThatRunsAPrometheusQueryPeriodicallyAndReturnsTheResultAsAnOutputSignal;
};

/**
 * Periodically runs a Prometheus query in the background and emits the result.
 */
export type ComponentThatRunsAPrometheusQueryPeriodicallyAndReturnsTheResultAsAnOutputSignal =
  {
    /**
     * Describes the interval between successive evaluations of the Prometheus query.
     * This field employs the
     * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
     * representation from Protocol Buffers. The format accommodates fractional seconds up to
     * nine digits after the decimal point, offering nanosecond precision. Every duration value
     * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
     * signify a duration of 10 seconds.
     */
    evaluation_interval?: string;
    /**
     * Output ports for the PromQL component.
     */
    out_ports?: PromQLOuts;
    /**
     * Describes the [PromQL](https://prometheus.io/docs/prometheus/latest/querying/basics/)
     * query to be run.
     *
     * :::note
     *
     * The query must return a single value either as a scalar or as a vector with a single
     * element.
     *
     * :::
     *
     * :::info Usage with Flux Meter
     *
     * [Flux Meter](/concepts/advanced/flux-meter.md) metrics can be queried using PromQL. Flux
     * Meter defines histogram type of metrics in Prometheus.
     * Therefore, one can refer to `flux_meter_sum`, `flux_meter_count` and `flux_meter_bucket`.
     * The particular Flux Meter can be identified with the `flux_meter_name` label.
     * There are additional labels available on a Flux Meter such as `valid`, `flow_status`,
     * `http_status_code` and `decision_type`.
     *
     * :::
     *
     * :::info Usage with OpenTelemetry Metrics
     *
     * Aperture supports OpenTelemetry metrics. See
     * [reference](/aperture-for-infra/integrations/metrics/metrics.md) for more details.
     *
     * :::
     */
    query_string?: string;
  };

/**
 * Output ports for the PromQL component.
 *
 * Output for the PromQL component.
 */
export type PromQLOuts = {
  /**
   * The result of the Prometheus query as an output signal.
   */
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Generates the specified signal.
 *
 * The _Signal Generator_ component generates a smooth and continuous signal
 * by following a sequence of specified steps. Each step has two parameters:
 * - `target_output`: The desired output value at the end of the step.
 * - `duration`: The time it takes for the signal to change linearly from the
 * previous step's `target_output` to the current step's `target_output`.
 *
 * The output signal starts at the `target_output` of the first step and
 * changes linearly between steps based on their `duration`. The _Signal
 * Generator_ can be controlled to move forwards, backwards, or reset to the
 * beginning based on input signals.
 */
export type SignalGenerator = {
  in_ports?: SignalGeneratorIns;
  out_ports?: SignalGeneratorOuts;
  /**
   * Parameters for the _Signal Generator_ component.
   */
  parameters: SignalGeneratorParameters;
};

/**
 * Inputs for the _Signal Generator_ component.
 */
export type SignalGeneratorIns = {
  /**
   * Whether to progress the _Signal Generator_ towards the previous step.
   */
  backward?: SignalValue;
  /**
   * Whether to progress the _Signal Generator_ towards the next step.
   */
  forward?: SignalValue;
  /**
   * Whether to reset the _Signal Generator_ to the first step.
   */
  reset?: SignalValue;
};

/**
 * Outputs for the _Signal Generator_ component.
 */
export type SignalGeneratorOuts = {
  /**
   * A Boolean signal indicating whether the _Signal Generator_ is at the end of signal
   * generation.
   */
  at_end?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
  /**
   * A Boolean signal indicating whether the _Signal Generator_ is at the start of signal
   * generation.
   */
  at_start?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
  /**
   * The generated signal.
   */
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Parameters for the _Signal Generator_ component.
 *
 *
 *
 * Parameters for the _Signal Generator_ component.
 */
export type SignalGeneratorParameters = {
  steps: SignalGeneratorParametersStep[];
};

export type SignalGeneratorParametersStep = {
  /**
   * Duration for which the step is active.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  duration: string;
  /**
   * The value of the step.
   */
  target_output?: ConstantSignal;
};

/**
 * Simple Moving Average filter.
 *
 * Simple Moving Average (SMA) is a type of moving average that computes the average of a
 * fixed number of signal readings.
 */
export type SMA = {
  /**
   * Input ports for the SMA component.
   */
  in_ports?: SMAIns;
  /**
   * Output ports for the SMA component.
   */
  out_ports?: SMAOuts;
  /**
   * Parameters for the SMA component.
   */
  parameters: SMAParameters;
};

/**
 * Input ports for the SMA component.
 */
export type SMAIns = {
  /**
   * Signal to be used for the moving average computation.
   */
  input?: SignalValue;
};

/**
 * Output ports for the SMA component.
 */
export type SMAOuts = {
  /**
   * Computed moving average.
   */
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Parameters for the SMA component.
 */
export type SMAParameters = {
  /**
   * Window of time over which the moving average is computed.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  sma_window: string;
  /**
   * Whether the output is valid during the warm-up stage.
   */
  valid_during_warmup?: boolean;
};

/**
 * Switcher acts as a switch that emits one of the two signals based on third signal.
 *
 * `on_signal` will be returned if switch input is valid and not equal to 0.0 ,
 * otherwise `off_signal` will be returned.
 */
export type TypeOfCombinatorThatSwitchesBetweenOnSignalAndOffSignalSignalsBasedOnSwitchInput =
  {
    /**
     * Input ports for the Switcher component.
     */
    in_ports?: SwitcherIns;
    /**
     * Output ports for the Switcher component.
     */
    out_ports?: SwitcherOuts;
  };

/**
 * Input ports for the Switcher component.
 *
 * Inputs for the Switcher component.
 */
export type SwitcherIns = {
  /**
   * Output signal when switch is invalid or 0.0.
   */
  off_signal?: SignalValue;
  /**
   * Output signal when switch is valid and not 0.0.
   */
  on_signal?: SignalValue;
  /**
   * Decides whether to return `on_signal` or `off_signal`.
   */
  switch?: SignalValue;
};

/**
 * Output ports for the Switcher component.
 *
 * Outputs for the Switcher component.
 */
export type SwitcherOuts = {
  /**
   * Selected signal (`on_signal` or `off_signal`).
   */
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Takes an input signal and emits the square root of the input signal.
 *
 * $$
 * \text{output} = \unary_operator{\text{input}}
 * $$
 */
export type TakesAnInputSignalAndEmitsTheOutputAfterApplyingTheSpecifiedUnaryOperator =
  {
    /**
     * Input ports for the UnaryOperator component.
     */
    in_ports?: UnaryOperatorIns;
    /**
     * Unary Operator to apply.
     *
     * The unary operator can be one of the following:
     * * `abs`: Absolute value with the sign removed.
     * * `acos`: `arccosine`, in radians.
     * * `acosh`: Inverse hyperbolic cosine.
     * * `asin`: `arcsine`, in radians.
     * * `asinh`: Inverse hyperbolic sine.
     * * `atan`: `arctangent`, in radians.
     * * `atanh`: Inverse hyperbolic tangent.
     * * `cbrt`: Cube root.
     * * `ceil`: Least integer value greater than or equal to input signal.
     * * `cos`: `cosine`, in radians.
     * * `cosh`: Hyperbolic cosine.
     * * `erf`: Error function.
     * * `erfc`: Complementary error function.
     * * `erfcinv`: Inverse complementary error function.
     * * `erfinv`: Inverse error function.
     * * `exp`: The base-e exponential of input signal.
     * * `exp2`: The base-2 exponential of input signal.
     * * `expm1`: The base-e exponential of input signal minus 1.
     * * `floor`: Greatest integer value less than or equal to input signal.
     * * `gamma`: Gamma function.
     * * `j0`: Bessel function of the first kind of order 0.
     * * `j1`: Bessel function of the first kind of order 1.
     * * `lgamma`: Natural logarithm of the absolute value of the gamma function.
     * * `log`: Natural logarithm of input signal.
     * * `log10`: Base-10 logarithm of input signal.
     * * `log1p`: Natural logarithm of input signal plus 1.
     * * `log2`: Base-2 logarithm of input signal.
     * * `round`: Round to nearest integer.
     * * `roundtoeven`: Round to nearest integer, with ties going to the nearest even integer.
     * * `sin`: `sine`, in radians.
     * * `sinh`: Hyperbolic sine.
     * * `sqrt`: Square root.
     * * `tan`: `tangent`, in radians.
     * * `tanh`: Hyperbolic tangent.
     * * `trunc`: Truncate to integer.
     * * `y0`: Bessel function of the second kind of order 0.
     * * `y1`: Bessel function of the second kind of order 1.
     */
    operator?: UnaryOperatorOperator;
    /**
     * Output ports for the UnaryOperator component.
     */
    out_ports?: UnaryOperatorOuts;
  };

/**
 * Input ports for the UnaryOperator component.
 *
 * Inputs for the UnaryOperator component.
 */
export type UnaryOperatorIns = {
  /**
   * Input signal.
   */
  input?: SignalValue;
};

/**
 * Unary Operator to apply.
 *
 * The unary operator can be one of the following:
 * * `abs`: Absolute value with the sign removed.
 * * `acos`: `arccosine`, in radians.
 * * `acosh`: Inverse hyperbolic cosine.
 * * `asin`: `arcsine`, in radians.
 * * `asinh`: Inverse hyperbolic sine.
 * * `atan`: `arctangent`, in radians.
 * * `atanh`: Inverse hyperbolic tangent.
 * * `cbrt`: Cube root.
 * * `ceil`: Least integer value greater than or equal to input signal.
 * * `cos`: `cosine`, in radians.
 * * `cosh`: Hyperbolic cosine.
 * * `erf`: Error function.
 * * `erfc`: Complementary error function.
 * * `erfcinv`: Inverse complementary error function.
 * * `erfinv`: Inverse error function.
 * * `exp`: The base-e exponential of input signal.
 * * `exp2`: The base-2 exponential of input signal.
 * * `expm1`: The base-e exponential of input signal minus 1.
 * * `floor`: Greatest integer value less than or equal to input signal.
 * * `gamma`: Gamma function.
 * * `j0`: Bessel function of the first kind of order 0.
 * * `j1`: Bessel function of the first kind of order 1.
 * * `lgamma`: Natural logarithm of the absolute value of the gamma function.
 * * `log`: Natural logarithm of input signal.
 * * `log10`: Base-10 logarithm of input signal.
 * * `log1p`: Natural logarithm of input signal plus 1.
 * * `log2`: Base-2 logarithm of input signal.
 * * `round`: Round to nearest integer.
 * * `roundtoeven`: Round to nearest integer, with ties going to the nearest even integer.
 * * `sin`: `sine`, in radians.
 * * `sinh`: Hyperbolic sine.
 * * `sqrt`: Square root.
 * * `tan`: `tangent`, in radians.
 * * `tanh`: Hyperbolic tangent.
 * * `trunc`: Truncate to integer.
 * * `y0`: Bessel function of the second kind of order 0.
 * * `y1`: Bessel function of the second kind of order 1.
 */
export enum UnaryOperatorOperator {
  Abs = "abs",
  Acos = "acos",
  Acosh = "acosh",
  Asin = "asin",
  Asinh = "asinh",
  Atan = "atan",
  Atanh = "atanh",
  Cbrt = "cbrt",
  Ceil = "ceil",
  Cos = "cos",
  Cosh = "cosh",
  Erf = "erf",
  Erfc = "erfc",
  Erfcinv = "erfcinv",
  Erfinv = "erfinv",
  Exp = "exp",
  Exp2 = "exp2",
  Expm1 = "expm1",
  Floor = "floor",
  Gamma = "gamma",
  J0 = "j0",
  J1 = "j1",
  Lgamma = "lgamma",
  Log = "log",
  Log10 = "log10",
  Log1P = "log1p",
  Log2 = "log2",
  Round = "round",
  Roundtoeven = "roundtoeven",
  Sin = "sin",
  Sinh = "sinh",
  Sqrt = "sqrt",
  Tan = "tan",
  Tanh = "tanh",
  Trunc = "trunc",
  Y0 = "y0",
  Y1 = "y1",
}

/**
 * Output ports for the UnaryOperator component.
 *
 * Outputs for the UnaryOperator component.
 */
export type UnaryOperatorOuts = {
  /**
   * Output signal.
   */
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

/**
 * Emits a variable signal which can be changed at runtime through dynamic configuration.
 *
 * Component that emits a constant signal which can be changed at runtime through dynamic
 * configuration.
 */
export type Variable = {
  /**
   * Configuration key for overriding value setting through dynamic configuration.
   */
  config_key?: string;
  /**
   * The constant signal emitted by this component. The value of the constant signal can be
   * overridden at runtime through dynamic configuration.
   */
  constant_output: ConstantSignal;
  /**
   * Output ports for the Variable component.
   */
  out_ports?: VariableOuts;
};

/**
 * Output ports for the Variable component.
 *
 * Outputs for the Variable component.
 */
export type VariableOuts = {
  /**
   * The value is emitted to the output port.
   */
  output?: ComponentsProduceOutputForOtherComponentsThroughOutPorts;
};

export type PolicyQuotaScheduler = {
  /**
   * Alerter.
   */
  alerter?: QuotaSchedulerAlerter;
  /**
   * Bucket capacity.
   */
  bucket_capacity: number;
  /**
   * Fill amount.
   */
  fill_amount: number;
  /**
   * Rate Limiter Parameters.
   */
  rate_limiter: RateLimiter;
  /**
   * Scheduler configuration.
   */
  scheduler?: Scheduler;
  /**
   * Flow selectors to match requests against.
   */
  selectors: FluffySelector[];
};

/**
 * Alerter.
 *
 * Alerter Parameters configure parameters such as alert name, severity, resolve timeout,
 * alert channels and labels.
 *
 * Configuration for embedded Alerter.
 *
 *
 *
 * Configuration for scale-in Alerter.
 *
 *
 *
 * Configuration for scale-out Alerter.
 *
 *
 *
 * Configuration parameters for the embedded Alerter.
 */
export type QuotaSchedulerAlerter = {
  /**
   * A list of alert channel strings.
   */
  alert_channels?: string[];
  /**
   * Name of the alert.
   */
  alert_name: string;
  /**
   * Additional labels to add to alert.
   */
  labels?: { [key: string]: string };
  /**
   * Duration of alert resolver.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  resolve_timeout?: string;
  /**
   * Severity of the alert, one of 'info', 'warn' or 'crit'.
   */
  severity?: Severity;
};

/**
 * Rate Limiter Parameters.
 */
export type RateLimiter = {
  adaptive_load_scheduler?: AdaptiveLoadScheduler;
  /**
   * Continuous fill determines whether the token bucket should be filled
   * continuously or only on discrete intervals.
   */
  continuous_fill?: boolean;
  /**
   * Delays the initial filling of the token bucket.
   * If set to false, the token bucket will start filling immediately
   * after the first request is received. This can potentially lead to
   * more requests being accepted than the specified rate limit during
   * the first interval.
   * When set to true, the token bucket will be given a chance to
   * empty out before the filling starts. The delay is equal to the
   * time it takes to fill the bucket.
   */
  delay_initial_fill?: boolean;
  /**
   * Interval defines the time interval in which the token bucket
   * will fill tokens specified by `fill_amount` signal.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  interval: string;
  /**
   * Specifies which label the rate limiter should be keyed by.
   *
   * Rate limiting is done independently for each value of the
   * [label](/concepts/flow-label.md) with given key.
   * For example, to give each user a separate limit, assuming you
   * have a _user_ flow
   * label set up, set `label_key: "user"`.
   * If no label key is specified, then all requests matching the
   * selectors will be rate limited based on the global bucket.
   * Deprecated: v3.0.0. Use _limit_by_label_key_ instead.
   */
  label_key?: string;
  lazy_sync?: RateLimiterParametersLazySync;
  /**
   * Specifies which label the rate limiter should be keyed by.
   *
   * Rate limiting is done independently for each value of the
   * [label](/concepts/flow-label.md) with given key.
   * For example, to give each user a separate limit, assuming you
   * have a _user_ flow
   * label set up, set `limit_by_label_key: "user"`.
   * If no label key is specified, then all requests matching the
   * selectors will be rate limited based on the global bucket.
   */
  limit_by_label_key?: string;
  /**
   * Max idle time before token bucket state for a label is removed.
   * If set to 0, the state is never removed.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  max_idle_time?: string;
};

/**
 * Scheduler configuration.
 *
 * Configuration of Weighted Fair Queuing-based workload scheduler.
 *
 * Contains configuration of per-agent scheduler
 *
 * :::note
 *
 * Each Agent instantiates an independent copy of the scheduler, but output
 * signals for accepted and incoming token rate are aggregated across all agents.
 *
 * :::
 */
export type Scheduler = {
  /**
   * Decision deadline margin is the amount of time that the scheduler will
   * subtract from the request deadline to determine the deadline for the
   * decision. This is to ensure that the scheduler has enough time to
   * make a decision before the request deadline happens, accounting for
   * processing delays.
   * The request deadline is based on the
   * [gRPC deadline](https://grpc.io/blog/deadlines) or the
   * [`grpc-timeout` HTTP
   * header](https://github.com/grpc/grpc/blob/master/doc/PROTOCOL-HTTP2.md#requests).
   *
   * Fail-open logic is use for flow control APIs, so if the gRPC deadline
   * reaches, the flow will end up being unconditionally allowed while
   * it is still waiting on the scheduler.
   * This field employs the
   * [Duration](https://developers.google.com/protocol-buffers/docs/proto3#json) JSON
   * representation from Protocol Buffers. The format accommodates fractional seconds up to
   * nine digits after the decimal point, offering nanosecond precision. Every duration value
   * must be suffixed with an "s" to indicate 'seconds.' For example, a value of "10s" would
   * signify a duration of 10 seconds.
   */
  decision_deadline_margin?: string;
  /**
   * Parameters to be used if none of workloads specified in `workloads` match.
   */
  default_workload_parameters?: SchedulerWorkloadParameters;
  /**
   * This field allows you to override the default HTTP status code (`503 Service
   * Unavailable`) that is returned when a request is denied.
   */
  denied_response_status_code?: HTTPResponseStatusCode;
  /**
   * Key for a flow label that can be used to override the default priority for this flow.
   * The value associated with this key must be a valid number. Higher numbers means higher
   * priority.
   * If this parameter is not provided, the priority for the flow will be determined by the
   * matched workload's priority.
   */
  priority_label_key?: string;
  /**
   * Key for a flow label that can be used to override the default number of tokens for this
   * request.
   * The value associated with this key must be a valid number.
   * If this parameter is not provided, the number of tokens for the flow will be determined
   * by the matched workload's token count.
   */
  tokens_label_key?: string;
  /**
   * Key for a flow label that can be used to provide workloads for this request.
   * If this parameter is not provided, the workloads for the flow will be determined by the
   * matched workload's name in the policy.
   */
  workload_label_key?: string;
  /**
   * Key for a flow label that is used to enforce fairness among requests in a workload. If
   * not specified, requests within a workload of the same priority are admitted in a FIFO
   * manner.
   */
  fairness_label_key?: string;
  /**
   * List of workloads to be used in scheduler.
   *
   * Categorizing flows into workloads
   * allows for load throttling to be "intelligent" instead of queueing flows in an arbitrary
   * order.
   * There are two aspects of this "intelligence":
   * * Scheduler can more precisely calculate concurrency if it understands
   * that flows belonging to different classes have different weights (for example, insert
   * queries compared to select queries).
   * * Setting different priorities to different workloads lets the scheduler
   * avoid dropping important traffic during overload.
   *
   * Each workload in this list specifies also a matcher that is used to
   * determine which flow will be categorized into which workload.
   * In case of multiple matching workloads, the first matching one will be used.
   * If none of workloads match, `default_workload` will be used.
   *
   * :::info
   *
   * See also [workload definition in the concepts
   * section](/concepts/scheduler/scheduler.md#workload).
   *
   * :::
   */
  workloads?: SchedulerWorkload[];
};

/**
 * Selects flows based on control point, flow labels, agent group and the service
 * that the flow control component
 * will operate on.
 *
 * :::info
 *
 * See also [Selector overview](/concepts/selector.md).
 *
 * :::
 *
 * Example:
 * ```yaml
 * control_point: ingress
 * label_matcher:
 * match_labels:
 * user_tier: gold
 * match_expressions:
 * - key: query
 * operator: In
 * values:
 * - insert
 * - delete
 * expression:
 * label_matches:
 * - label: user_agent
 * regex: ^(?!.*Chrome).*Safari
 * ```
 */
export type FluffySelector = {
  /**
   * [_Agent Group_](/concepts/selector.md#agent-group) this
   * selector applies to.
   *
   * :::info
   *
   * Agent Groups are used to scope policies to a subset of agents connected to the same
   * controller.
   * The agents within an agent group receive exact same policy configuration and
   * form a peer to peer cluster to constantly share state.
   *
   * :::
   */
  agent_group?: string;
  /**
   * [Control Point](/concepts/control-point.md)
   * identifies location within services where policies can act on flows.
   * For an SDK based insertion,
   * a _Control Point_ can represent a particular feature or execution
   * block within a service. In case of service mesh or middleware insertion, a
   * _Control Point_ can identify ingress or egress calls or distinct listeners
   * or filter chains.
   */
  control_point: string;
  /**
   * [Label Matcher](/concepts/selector.md#label-matcher)
   * can be used to match flows based on flow labels.
   */
  label_matcher?: AllowsToDefineRulesWhetherAMapOfLabelsConceptsFlowLabelMdShouldBeConsideredAMatchOrNot;
  /**
   * The Fully Qualified Domain Name of the
   * [service](/concepts/selector.md) to select.
   *
   * In Kubernetes, this is the FQDN of the Service object.
   *
   * :::info
   *
   * `any` matches all services.
   *
   * :::
   *
   * :::info
   *
   * An entity (for example, Kubernetes pod) might belong to multiple services.
   *
   * :::
   */
  service?: string;
};

/**
 * Additional resources.
 *
 * :::info
 *
 * See also [Resources overview](/concepts/advanced/policy.md).
 *
 * :::
 */
export type Resources = {
  /**
   * FlowControlResources are resources that are provided by flow control integration.
   */
  flow_control?: FlowControlResources;
  /**
   * _Infra Meters_ configure custom metrics OpenTelemetry collector pipelines, which will
   * receive and process telemetry at the agents and send metrics to the configured
   * Prometheus.
   * Key in this map refers to OTel pipeline name. Prefixing pipeline name with `metrics/`
   * is optional, as all the components and pipeline names would be normalized.
   *
   * Example:
   *
   * ```yaml
   * infra_meters:
   * rabbitmq:
   * agent_group: default
   * per_agent_group: true
   * processors:
   * batch:
   * send_batch_size: 10
   * timeout: 10s
   * receivers:
   * rabbitmq:
   * collection_interval: 10s
   * endpoint: http://<rabbitmq-svc-fqdn>:15672
   * password: secretpassword
   * username: admin
   *
   * ```
   *
   * :::caution
   *
   * Validate the OTel configuration before applying it to the
   * production cluster.
   * Incorrect configuration will get rejected at the agents and might cause
   * shutdown of the agent(s).
   *
   * :::
   */
  infra_meters?: { [key: string]: InfraMeter };
  /**
   * TelemetryCollector configures OpenTelemetry collector integration.
   * Deprecated: v3.0.0. Use `infra_meters` instead.
   */
  telemetry_collectors?: TelemetryCollectorDefinesTheTelemetryConfigurationToBeSyncedWithTheAgentsDeprecatedV300UseInfraMeterInsteadItConsistsOfTwoPartsAgentGroupAgentGroupToSyncTelemetryConfigurationWithInfraMetersOTelCompatibleMetricsPipelines[];
};

/**
 * FlowControlResources are resources that are provided by flow control integration.
 */
export type FlowControlResources = {
  /**
   * Classifiers are installed in the data-plane and are used to label the requests based on
   * payload content.
   *
   * The flow labels created by Classifiers can be matched by Flux Meters to create metrics
   * for control purposes.
   */
  classifiers?: SetOfClassificationRulesSharingACommonSelector[];
  /**
   * Flux Meters are installed in the data-plane and form the observability leg of the
   * feedback loop.
   *
   * Flux Meter created metrics can be consumed as input to the circuit through the PromQL
   * component.
   */
  flux_meters?: { [key: string]: FluxMeter };
};

/**
 * :::info
 *
 * See also [Classifier overview](/concepts/advanced/classifier.md).
 *
 * :::
 * Example
 * ```yaml
 * selectors:
 * - agent_group: demoapp
 * service: service1-demo-app.demoapp.svc.cluster.local
 * control_point: ingress
 * label_matcher:
 * match_labels:
 * user_tier: gold
 * match_expressions:
 * - key: user_type
 * operator: In
 * rules:
 * user:
 * extractor:
 * from: request.http.headers.user-agent
 * telemetry: false
 * ```
 */
export type SetOfClassificationRulesSharingACommonSelector = {
  /**
   * Rego is a policy language used to express complex policies in a concise and declarative
   * way.
   * It can be used to define flow classification rules by writing custom queries that extract
   * values from request metadata.
   * For simple cases, such as directly reading a value from header or a field from JSON body,
   * declarative extractors are recommended.
   */
  rego?: Rego;
  /**
   * A map of {key, value} pairs mapping from
   * [flow label](/concepts/flow-label.md) keys to rules that define
   * how to extract and propagate flow labels with that key.
   */
  rules?: { [key: string]: RuleDescribesASingleClassificationRule };
  /**
   * Selectors for flows that will be classified by this _Classifier_.
   */
  selectors: ClassifierSelector[];
};

/**
 * Rego is a policy language used to express complex policies in a concise and declarative
 * way.
 * It can be used to define flow classification rules by writing custom queries that extract
 * values from request metadata.
 * For simple cases, such as directly reading a value from header or a field from JSON body,
 * declarative extractors are recommended.
 *
 * Rego define a set of labels that are extracted after evaluating a Rego module.
 *
 * :::info
 *
 * You can use the [live-preview](/concepts/advanced/classifier.md#live-previewing-requests)
 * feature to first preview the input to the classifier before writing the labeling logic.
 *
 * :::
 *
 * Example of Rego module which also disables telemetry visibility of label:
 * ```yaml
 * rego:
 * labels:
 * user:
 * telemetry: false
 * module: |
 * package user_from_cookie
 * cookies := split(input.attributes.request.http.headers.cookie, "; ")
 * user := user {
 * cookie := cookies[_]
 * startswith(cookie, "session=")
 * session := substring(cookie, count("session="), -1)
 * parts := split(session, ".")
 * object := json.unmarshal(base64url.decode(parts[0]))
 * user := object.user
 * }
 * ```
 */
export type Rego = {
  /**
   * A map of {key, value} pairs mapping from
   * [flow label](/concepts/flow-label.md) keys to queries that define
   * how to extract and propagate flow labels with that key.
   * The name of the label maps to a variable in the Rego module. It maps to
   * `data.<package>.<label>` variable.
   */
  labels: { [key: string]: RegoLabelProperties };
  /**
   * Source code of the Rego module.
   *
   * :::note
   *
   * Must include a "package" declaration.
   *
   * :::
   */
  module: string;
};

export type RegoLabelProperties = {
  /**
   * :::note
   *
   * The flow label is always accessible in Aperture Policies regardless of this setting.
   *
   * :::
   *
   * :::caution
   *
   * When using [FluxNinja extension](/reference/fluxninja.md), telemetry enabled
   * labels are sent to Aperture Cloud for observability. Telemetry should
   * be disabled for sensitive labels.
   *
   * :::
   */
  telemetry?: boolean;
};

/**
 * Example of a JSON extractor:
 * ```yaml
 * extractor:
 * json:
 * from: request.http.body
 * pointer: /user/name
 * ```
 */
export type RuleDescribesASingleClassificationRule = {
  /**
   * High-level declarative extractor.
   */
  extractor?: DefinesAHighLevelWayToSpecifyHowToExtractAFlowLabelValueGivenHTTPRequestMetadataWithoutANeedToWriteRegoCode;
  /**
   * :::note
   *
   * The flow label is always accessible in Aperture Policies regardless of this setting.
   *
   * :::
   *
   * :::caution
   *
   * When using [FluxNinja extension](/reference/fluxninja.md), telemetry enabled
   * labels are sent to Aperture Cloud for observability. Telemetry should be
   * disabled for sensitive labels.
   *
   * :::
   */
  telemetry?: boolean;
};

/**
 * High-level declarative extractor.
 *
 * There are multiple variants of extractor, specify exactly one.
 */
export type DefinesAHighLevelWayToSpecifyHowToExtractAFlowLabelValueGivenHTTPRequestMetadataWithoutANeedToWriteRegoCode =
  {
    /**
     * Display an address as a single string - `<ip>:<port>`.
     */
    address?: DisplayAnAddressEXTAuthzAddressAsASingleStringForExampleIPPort;
    /**
     * Attribute path is a dot-separated path to attribute.
     *
     * Should be either:
     * * one of the fields of [Attribute
     * Context](https://www.envoyproxy.io/docs/envoy/latest/api-v3/service/auth/v3/attribute_context.proto),
     * or
     * * a special `request.http.bearer` pseudo-attribute.
     * For example, `request.http.method` or `request.http.header.user-agent`
     *
     * Note: The same attribute path syntax is shared by other extractor variants,
     * wherever attribute path is needed in their "from" syntax.
     *
     * Example:
     * ```yaml
     * from: request.http.headers.user-agent
     * ```
     */
    from?: string;
    /**
     * Parse JSON, and extract one of the fields.
     */
    json?: ParseJSONAndExtractOneOfTheFields;
    /**
     * Parse the attribute as JWT and read the payload.
     */
    jwt?: ParseTheAttributeAsJWTAndReadThePayload;
    /**
     * Match HTTP Path to given path templates.
     */
    path_templates?: MatchesHTTPPathToGivenPathTemplates;
  };

/**
 * Display an address as a single string - `<ip>:<port>`.
 *
 * IP addresses in attribute context are defined as objects with separate IP and port
 * fields.
 * This is a helper to display an address as a single string.
 *
 * :::caution
 *
 * This might introduce high-cardinality flow label values.
 *
 * :::
 *
 * [ext-authz-address]:
 * https://www.envoyproxy.io/docs/envoy/latest/api-v3/config/core/v3/address.proto#config-core-v3-address
 *
 * Example:
 * ```yaml
 * from: "source.address # or destination.address"
 * ```
 */
export type DisplayAnAddressEXTAuthzAddressAsASingleStringForExampleIPPort = {
  /**
   * Attribute path pointing to some string - for example, `source.address`.
   */
  from: string;
};

/**
 * Parse JSON, and extract one of the fields.
 *
 * Example:
 * ```yaml
 * from: request.http.body
 * pointer: /user/name
 * ```
 */
export type ParseJSONAndExtractOneOfTheFields = {
  /**
   * Attribute path pointing to some strings - for example, `request.http.body`.
   */
  from: string;
  /**
   * JSON pointer represents a parsed JSON pointer which allows to select a specified field
   * from the payload.
   *
   * Note: Uses [JSON pointer](https://datatracker.ietf.org/doc/html/rfc6901) syntax,
   * for example, `/foo/bar`. If the pointer points into an object, it'd be converted to a
   * string.
   */
  pointer?: string;
};

/**
 * Parse the attribute as JWT and read the payload.
 *
 * Specify a field to be extracted from payload using `json_pointer`.
 *
 * Note: The signature is not verified against the secret (assuming there's some
 * other part of the system that handles such verification).
 *
 * Example:
 * ```yaml
 * from: request.http.bearer
 * json_pointer: /user/email
 * ```
 */
export type ParseTheAttributeAsJWTAndReadThePayload = {
  /**
   * JWT (JSON Web Token) can be extracted from any input attribute, but most likely you'd
   * want to use `request.http.bearer`.
   */
  from: string;
  /**
   * JSON pointer allowing to select a specified field from the payload.
   *
   * Note: Uses [JSON pointer](https://datatracker.ietf.org/doc/html/rfc6901) syntax,
   * for example, `/foo/bar`. If the pointer points into an object, it'd be converted to a
   * string.
   */
  json_pointer?: string;
};

/**
 * Match HTTP Path to given path templates.
 *
 * HTTP path will be matched against given path templates.
 * If a match occurs, the value associated with the path template will be treated as a
 * result.
 * In case of multiple path templates matching, the most specific one will be chosen.
 */
export type MatchesHTTPPathToGivenPathTemplates = {
  /**
   * Template value keys are OpenAPI-inspired path templates.
   *
   * * Static path segment `/foo` matches a path segment exactly
   * * `/{param}` matches arbitrary path segment.
   * (The parameter name is ignored and can be omitted (`{}`))
   * * The parameter must cover whole segment.
   * * Additionally, path template can end with `/*` wildcard to match
   * arbitrary number of trailing segments (0 or more).
   * * Multiple consecutive `/` are ignored, as well as trailing `/`.
   * * Parametrized path segments must come after static segments.
   * * `*`, if present, must come last.
   * * Most specific template "wins" (`/foo` over `/{}` and `/{}` over `/*`).
   *
   * See also <https://swagger.io/specification/#path-templating-matching>
   *
   * Example:
   * ```yaml
   * /register: register
   * "/user/{userId}": user
   * /static/*: other
   * ```
   */
  template_values?: { [key: string]: string };
};

/**
 * Selects flows based on control point, flow labels, agent group and the service
 * that the flow control component
 * will operate on.
 *
 * :::info
 *
 * See also [Selector overview](/concepts/selector.md).
 *
 * :::
 *
 * Example:
 * ```yaml
 * control_point: ingress
 * label_matcher:
 * match_labels:
 * user_tier: gold
 * match_expressions:
 * - key: query
 * operator: In
 * values:
 * - insert
 * - delete
 * expression:
 * label_matches:
 * - label: user_agent
 * regex: ^(?!.*Chrome).*Safari
 * ```
 */
export type ClassifierSelector = {
  /**
   * [_Agent Group_](/concepts/selector.md#agent-group) this
   * selector applies to.
   *
   * :::info
   *
   * Agent Groups are used to scope policies to a subset of agents connected to the same
   * controller.
   * The agents within an agent group receive exact same policy configuration and
   * form a peer to peer cluster to constantly share state.
   *
   * :::
   */
  agent_group?: string;
  /**
   * [Control Point](/concepts/control-point.md)
   * identifies location within services where policies can act on flows.
   * For an SDK based insertion,
   * a _Control Point_ can represent a particular feature or execution
   * block within a service. In case of service mesh or middleware insertion, a
   * _Control Point_ can identify ingress or egress calls or distinct listeners
   * or filter chains.
   */
  control_point: string;
  /**
   * [Label Matcher](/concepts/selector.md#label-matcher)
   * can be used to match flows based on flow labels.
   */
  label_matcher?: AllowsToDefineRulesWhetherAMapOfLabelsConceptsFlowLabelMdShouldBeConsideredAMatchOrNot;
  /**
   * The Fully Qualified Domain Name of the
   * [service](/concepts/selector.md) to select.
   *
   * In Kubernetes, this is the FQDN of the Service object.
   *
   * :::info
   *
   * `any` matches all services.
   *
   * :::
   *
   * :::info
   *
   * An entity (for example, Kubernetes pod) might belong to multiple services.
   *
   * :::
   */
  service?: string;
};

/**
 * Flux Meter gathers metrics for the traffic that matches its selector.
 * The histogram created by Flux Meter measures the workload latency by default.
 *
 * :::info
 *
 * See also [Flux Meter overview](/concepts/advanced/flux-meter.md).
 *
 * :::
 * Example:
 * ```yaml
 * static_buckets:
 * buckets: [5.0,10.0,25.0,50.0,100.0,250.0,500.0,1000.0,2500.0,5000.0,10000.0]
 * selectors:
 * - agent_group: demoapp
 * service: service1-demo-app.demoapp.svc.cluster.local
 * control_point: ingress
 * attribute_key: response_duration_ms
 * ```
 */
export type FluxMeter = {
  /**
   * Key of the attribute in access log or span from which the metric for this flux meter is
   * read.
   *
   * :::info
   *
   * For list of available attributes in Envoy access logs, refer
   * [Envoy Filter](/aperture-for-infra/integrations/istio/istio.md#envoy-filter)
   *
   * :::
   */
  attribute_key?: string;
  exponential_buckets?: FluxMeterExponentialBuckets;
  exponential_buckets_range?: FluxMeterExponentialBucketsRange;
  linear_buckets?: FluxMeterLinearBuckets;
  /**
   * Selectors for flows that will be metered by this _Flux Meter_.
   */
  selectors: FluxMeterSelector[];
  static_buckets?: FluxMeterStaticBuckets;
};

/**
 * ExponentialBuckets creates `count` number of buckets where the lowest bucket has an upper
 * bound of `start`
 * and each following bucket's upper bound is `factor` times the previous bucket's upper
 * bound. The final +inf
 * bucket is not counted.
 */
export type FluxMeterExponentialBuckets = {
  /**
   * Number of buckets.
   */
  count?: number;
  /**
   * Factor to be multiplied to the previous bucket's upper bound to calculate the following
   * bucket's upper bound.
   */
  factor?: number;
  /**
   * Upper bound of the lowest bucket.
   */
  start?: number;
};

/**
 * ExponentialBucketsRange creates `count` number of buckets where the lowest bucket is
 * `min` and the highest
 * bucket is `max`. The final +inf bucket is not counted.
 */
export type FluxMeterExponentialBucketsRange = {
  /**
   * Number of buckets.
   */
  count?: number;
  /**
   * Highest bucket.
   */
  max?: number;
  /**
   * Lowest bucket.
   */
  min?: number;
};

/**
 * LinearBuckets creates `count` number of buckets, each `width` wide, where the lowest
 * bucket has an
 * upper bound of `start`. The final +inf bucket is not counted.
 */
export type FluxMeterLinearBuckets = {
  /**
   * Number of buckets.
   */
  count?: number;
  /**
   * Upper bound of the lowest bucket.
   */
  start?: number;
  /**
   * Width of each bucket.
   */
  width?: number;
};

/**
 * Selects flows based on control point, flow labels, agent group and the service
 * that the flow control component
 * will operate on.
 *
 * :::info
 *
 * See also [Selector overview](/concepts/selector.md).
 *
 * :::
 *
 * Example:
 * ```yaml
 * control_point: ingress
 * label_matcher:
 * match_labels:
 * user_tier: gold
 * match_expressions:
 * - key: query
 * operator: In
 * values:
 * - insert
 * - delete
 * expression:
 * label_matches:
 * - label: user_agent
 * regex: ^(?!.*Chrome).*Safari
 * ```
 */
export type FluxMeterSelector = {
  /**
   * [_Agent Group_](/concepts/selector.md#agent-group) this
   * selector applies to.
   *
   * :::info
   *
   * Agent Groups are used to scope policies to a subset of agents connected to the same
   * controller.
   * The agents within an agent group receive exact same policy configuration and
   * form a peer to peer cluster to constantly share state.
   *
   * :::
   */
  agent_group?: string;
  /**
   * [Control Point](/concepts/control-point.md)
   * identifies location within services where policies can act on flows.
   * For an SDK based insertion,
   * a _Control Point_ can represent a particular feature or execution
   * block within a service. In case of service mesh or middleware insertion, a
   * _Control Point_ can identify ingress or egress calls or distinct listeners
   * or filter chains.
   */
  control_point: string;
  /**
   * [Label Matcher](/concepts/selector.md#label-matcher)
   * can be used to match flows based on flow labels.
   */
  label_matcher?: AllowsToDefineRulesWhetherAMapOfLabelsConceptsFlowLabelMdShouldBeConsideredAMatchOrNot;
  /**
   * The Fully Qualified Domain Name of the
   * [service](/concepts/selector.md) to select.
   *
   * In Kubernetes, this is the FQDN of the Service object.
   *
   * :::info
   *
   * `any` matches all services.
   *
   * :::
   *
   * :::info
   *
   * An entity (for example, Kubernetes pod) might belong to multiple services.
   *
   * :::
   */
  service?: string;
};

/**
 * StaticBuckets holds the static value of the buckets where latency histogram will be
 * stored.
 */
export type FluxMeterStaticBuckets = {
  /**
   * The buckets in which latency histogram will be stored.
   */
  buckets?: number[];
};

/**
 * InfraMeter is a resource that sets up OpenTelemetry pipelines.
 * It defines receivers, processors, and a single metrics pipeline
 * which will be exported to the configured Prometheus instance.
 * Environment variables can be used in the configuration using format `${ENV_VAR_NAME}`.
 *
 * :::info
 *
 * See also [Get Started / Setup Integrations /
 * Metrics](/aperture-for-infra/integrations/metrics/metrics.md).
 *
 * :::
 */
export type InfraMeter = {
  /**
   * AgentGroup is the agent group to sync this InfraMeter with.
   */
  agent_group?: string;
  /**
   * PerAgentGroup marks the pipeline to be instantiated only once per agent
   * group. This is helpful for receivers that scrape for example, some cluster-wide
   * metrics. When not set, pipeline will be instantiated on every Agent.
   */
  per_agent_group?: boolean;
  /**
   * Pipeline is an OTel metrics pipeline definition, which **only** uses receivers
   * and processors defined above. Exporter would be added automatically.
   *
   * If there are no processors defined or only one processor is defined, the
   * pipeline definition can be omitted. In such cases, the pipeline will
   * automatically use all given receivers and the defined processor (if
   * any).  However, if there are more than one processor, the pipeline must
   * be defined explicitly.
   */
  pipeline?: InfraMeterMetricsPipeline;
  /**
   * Processors define processors to be used in custom metrics pipelines. This should
   * be in [OTel format](https://opentelemetry.io/docs/collector/configuration/#processors).
   */
  processors?: { [key: string]: { [key: string]: any } };
  /**
   * Receivers define receivers to be used in custom metrics pipelines. This should
   * be in [OTel format](https://opentelemetry.io/docs/collector/configuration/#receivers).
   */
  receivers?: { [key: string]: { [key: string]: any } };
};

/**
 * Pipeline is an OTel metrics pipeline definition, which **only** uses receivers
 * and processors defined above. Exporter would be added automatically.
 *
 * If there are no processors defined or only one processor is defined, the
 * pipeline definition can be omitted. In such cases, the pipeline will
 * automatically use all given receivers and the defined processor (if
 * any).  However, if there are more than one processor, the pipeline must
 * be defined explicitly.
 *
 * MetricsPipelineConfig defines a custom metrics pipeline.
 */
export type InfraMeterMetricsPipeline = {
  processors?: string[];
  receivers?: string[];
};

export type TelemetryCollectorDefinesTheTelemetryConfigurationToBeSyncedWithTheAgentsDeprecatedV300UseInfraMeterInsteadItConsistsOfTwoPartsAgentGroupAgentGroupToSyncTelemetryConfigurationWithInfraMetersOTelCompatibleMetricsPipelines =
  {
    agent_group?: string;
    /**
     * _Infra Meters_ configure custom metrics OpenTelemetry collector pipelines, which will
     * receive and process telemetry at the agents and send metrics to the configured
     * Prometheus.
     * Key in this map refers to OTel pipeline name. Prefixing pipeline name with `metrics/`
     * is optional, as all the components and pipeline names would be normalized.
     *
     * Example:
     *
     * ```yaml
     * telemetry_collectors:
     * - agent_group: default
     * infra_meters:
     * rabbitmq:
     * processors:
     * batch:
     * send_batch_size: 10
     * timeout: 10s
     * receivers:
     * rabbitmq:
     * collection_interval: 10s
     * endpoint: http://<rabbitmq-svc-fqdn>:15672
     * password: secretpassword
     * username: admin
     * per_agent_group: true
     *
     * ```
     *
     * :::caution
     *
     * Validate the OTel configuration before applying it to the
     * production cluster.
     * Incorrect configuration will get rejected at the agents and might cause
     * shutdown of the agent(s).
     *
     * :::
     */
    infra_meters?: { [key: string]: InfraMeter };
  };

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
  public static toCoordinate(json: string): Coordinate {
    return cast(JSON.parse(json), r("Coordinate"));
  }

  public static coordinateToJson(value: Coordinate): string {
    return JSON.stringify(uncast(value, r("Coordinate")), null, 2);
  }
}

function invalidValue(typ: any, val: any, key: any, parent: any = ""): never {
  const prettyTyp = prettyTypeName(typ);
  const parentText = parent ? ` on ${parent}` : "";
  const keyText = key ? ` for key "${key}"` : "";
  throw Error(
    `Invalid value${keyText}${parentText}. Expected ${prettyTyp} but got ${JSON.stringify(
      val,
    )}`,
  );
}

function prettyTypeName(typ: any): string {
  if (Array.isArray(typ)) {
    if (typ.length === 2 && typ[0] === undefined) {
      return `an optional ${prettyTypeName(typ[1])}`;
    }

    return `one of [${typ.map((a) => prettyTypeName(a)).join(", ")}]`;
  }

  if (typeof typ === "object" && typ.literal !== undefined) {
    return typ.literal;
  }

  return typeof typ;
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => (map[p.json] = { key: p.js, typ: p.typ }));
    typ.jsonToJS = map;
  }

  return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => (map[p.js] = { key: p.json, typ: p.typ }));
    typ.jsToJSON = map;
  }

  return typ.jsToJSON;
}

function transform(
  val: any,
  typ: any,
  getProps: any,
  key: any = "",
  parent: any = "",
): any {
  function transformPrimitive(typ: string, val: any): any {
    if (typeof typ === typeof val) return val;

    return invalidValue(typ, val, key, parent);
  }

  function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
    const l = typs.length;

    for (let i = 0; i < l; i++) {
      const typ = typs[i];

      try {
        return transform(val, typ, getProps);
      } catch (_) {}
    }

    return invalidValue(typs, val, key, parent);
  }

  function transformEnum(cases: string[], val: any): any {
    if (cases.indexOf(val) !== -1) return val;

    return invalidValue(
      cases.map((a) => l(a)),
      val,
      key,
      parent,
    );
  }

  function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
    if (!Array.isArray(val)) return invalidValue(l("array"), val, key, parent);

    return val.map((el) => transform(el, typ, getProps));
  }

  function transformDate(val: any): any {
    if (val === null) {
      return null;
    }
    const d = new Date(val);

    if (isNaN(d.valueOf())) {
      return invalidValue(l("Date"), val, key, parent);
    }

    return d;
  }

  function transformObject(
    props: { [k: string]: any },
    additional: any,
    val: any,
  ): any {
    if (val === null || typeof val !== "object" || Array.isArray(val)) {
      return invalidValue(l(ref || "object"), val, key, parent);
    }
    const result: any = {};

    Object.getOwnPropertyNames(props).forEach((key) => {
      const prop = props[key];
      const v = Object.prototype.hasOwnProperty.call(val, key)
        ? val[key]
        : undefined;
      result[prop.key] = transform(v, prop.typ, getProps, key, ref);
    });

    Object.getOwnPropertyNames(val).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(props, key)) {
        result[key] = transform(val[key], additional, getProps, key, ref);
      }
    });

    return result;
  }

  if (typ === "any") return val;

  if (typ === null) {
    if (val === null) return val;

    return invalidValue(typ, val, key, parent);
  }

  if (typ === false) return invalidValue(typ, val, key, parent);
  let ref: any;

  while (typeof typ === "object" && typ.ref !== undefined) {
    ref = typ.ref;
    typ = typeMap[typ.ref];
  }

  if (Array.isArray(typ)) return transformEnum(typ, val);

  if (typeof typ === "object") {
    return typ.hasOwnProperty("unionMembers")
      ? transformUnion(typ.unionMembers, val)
      : typ.hasOwnProperty("arrayItems")
      ? transformArray(typ.arrayItems, val)
      : typ.hasOwnProperty("props")
      ? transformObject(getProps(typ), typ.additional, val)
      : invalidValue(typ, val, key, parent);
  }

  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== "number") return transformDate(val);

  return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps);
}

function l(typ: any) {
  return { literal: typ };
}

function a(typ: any) {
  return { arrayItems: typ };
}

function u(...typs: any[]) {
  return { unionMembers: typs };
}

function o(props: any[], additional: any) {
  return { props, additional };
}

function m(additional: any) {
  return { props: [], additional };
}

function r(name: string) {
  return { ref: name };
}

const typeMap: any = {
  Coordinate: o(
    [
      { json: "blueprint", js: "blueprint", typ: "" },
      { json: "policy", js: "policy", typ: r("Policy") },
      { json: "uri", js: "uri", typ: u(undefined, "") },
    ],
    false,
  ),
  Policy: o(
    [
      {
        json: "components",
        js: "components",
        typ: u(undefined, a(r("PolicyComponent"))),
      },
      { json: "policy_name", js: "policy_name", typ: "" },
      {
        json: "quota_scheduler",
        js: "quota_scheduler",
        typ: r("PolicyQuotaScheduler"),
      },
      { json: "resources", js: "resources", typ: u(undefined, r("Resources")) },
    ],
    false,
  ),
  PolicyComponent: o(
    [
      { json: "alerter", js: "alerter", typ: u(undefined, r("Alerter")) },
      { json: "and", js: "and", typ: u(undefined, r("And")) },
      {
        json: "arithmetic_combinator",
        js: "arithmetic_combinator",
        typ: u(
          undefined,
          r(
            "TypeOfCombinatorThatComputesTheArithmeticOperationOnTheOperandSignals",
          ),
        ),
      },
      {
        json: "auto_scale",
        js: "auto_scale",
        typ: u(undefined, r("AutoScale")),
      },
      {
        json: "bool_variable",
        js: "bool_variable",
        typ: u(undefined, r("BoolVariable")),
      },
      {
        json: "decider",
        js: "decider",
        typ: u(
          undefined,
          r(
            "TypeOfCombinatorThatComputesTheComparisonOperationOnLHSAndRHSSignals",
          ),
        ),
      },
      {
        json: "differentiator",
        js: "differentiator",
        typ: u(undefined, r("Differentiator")),
      },
      {
        json: "ema",
        js: "ema",
        typ: u(
          undefined,
          r(
            "ExponentialMovingAverageEMAIsATypeOfMovingAverageThatAppliesExponentiallyMoreWeightToRecentSignalReadings",
          ),
        ),
      },
      {
        json: "extrapolator",
        js: "extrapolator",
        typ: u(
          undefined,
          r(
            "ExtrapolatesTheInputSignalByRepeatingTheLastValidValueDuringThePeriodInWhichItIsInvalid",
          ),
        ),
      },
      {
        json: "first_valid",
        js: "first_valid",
        typ: u(
          undefined,
          r(
            "PicksTheFirstValidInputSignalFromTheArrayOfInputSignalsAndEmitsItAsAnOutputSignal",
          ),
        ),
      },
      {
        json: "flow_control",
        js: "flow_control",
        typ: u(undefined, r("FlowControl")),
      },
      {
        json: "gradient_controller",
        js: "gradient_controller",
        typ: u(
          undefined,
          r(
            "GradientControllerIsATypeOfControllerWhichTriesToAdjustTheControlVariableProportionallyToTheRelativeDifferenceBetweenSetpointAndActualValueOfTheSignal",
          ),
        ),
      },
      { json: "holder", js: "holder", typ: u(undefined, r("Holder")) },
      {
        json: "integrator",
        js: "integrator",
        typ: u(undefined, r("Integrator")),
      },
      { json: "inverter", js: "inverter", typ: u(undefined, r("Inverter")) },
      {
        json: "max",
        js: "max",
        typ: u(
          undefined,
          r("TakesAListOfInputSignalsAndEmitsTheSignalWithTheMaximumValue"),
        ),
      },
      { json: "min", js: "min", typ: u(undefined, r("Min")) },
      {
        json: "nested_circuit",
        js: "nested_circuit",
        typ: u(undefined, r("NestedCircuit")),
      },
      {
        json: "nested_signal_egress",
        js: "nested_signal_egress",
        typ: u(undefined, r("NestedSignalEgress")),
      },
      {
        json: "nested_signal_ingress",
        js: "nested_signal_ingress",
        typ: u(undefined, r("NestedSignalIngress")),
      },
      { json: "or", js: "or", typ: u(undefined, r("Or")) },
      {
        json: "pid_controller",
        js: "pid_controller",
        typ: u(
          undefined,
          r(
            "PIDControllerIsAPopularControlMechanismForClosedLoopFeedbackControlItTakesASignalAndASetpointAsInputsAndUsesThePIDAlgorithmToComputeProportionalProportionalToTheMagnitudeOfErrorIntegralProportionalToAccumulationOfErrorAndDerivativeProportionalToHowFastTheSignalIsChangingTermsWhichAreSummedUpToGetADesiredOutputValeOffErrorSetpointSignalIntegralIIntegralI1KICdotErrorOutputIKPCdotErrorKDCdotSignalISignalI1IntegralIValeOn",
          ),
        ),
      },
      {
        json: "polynomial_range_function",
        js: "polynomial_range_function",
        typ: u(undefined, r("PolynomialRangeFunction")),
      },
      {
        json: "pulse_generator",
        js: "pulse_generator",
        typ: u(undefined, r("PulseGenerator")),
      },
      { json: "query", js: "query", typ: u(undefined, r("Query")) },
      {
        json: "signal_generator",
        js: "signal_generator",
        typ: u(undefined, r("SignalGenerator")),
      },
      { json: "sma", js: "sma", typ: u(undefined, r("SMA")) },
      {
        json: "switcher",
        js: "switcher",
        typ: u(
          undefined,
          r(
            "TypeOfCombinatorThatSwitchesBetweenOnSignalAndOffSignalSignalsBasedOnSwitchInput",
          ),
        ),
      },
      {
        json: "unary_operator",
        js: "unary_operator",
        typ: u(
          undefined,
          r(
            "TakesAnInputSignalAndEmitsTheOutputAfterApplyingTheSpecifiedUnaryOperator",
          ),
        ),
      },
      { json: "variable", js: "variable", typ: u(undefined, r("Variable")) },
    ],
    false,
  ),
  Alerter: o(
    [
      { json: "in_ports", js: "in_ports", typ: u(undefined, r("AlerterIns")) },
      { json: "parameters", js: "parameters", typ: r("AlerterParameters") },
    ],
    false,
  ),
  AlerterIns: o(
    [{ json: "signal", js: "signal", typ: u(undefined, r("SignalValue")) }],
    false,
  ),
  SignalValue: o(
    [
      {
        json: "constant_signal",
        js: "constant_signal",
        typ: u(undefined, r("ConstantSignal")),
      },
      { json: "signal_name", js: "signal_name", typ: u(undefined, "") },
    ],
    false,
  ),
  ConstantSignal: o(
    [
      {
        json: "special_value",
        js: "special_value",
        typ: u(undefined, r("SpecialValue")),
      },
      { json: "value", js: "value", typ: u(undefined, 3.14) },
    ],
    false,
  ),
  AlerterParameters: o(
    [
      {
        json: "alert_channels",
        js: "alert_channels",
        typ: u(undefined, a("")),
      },
      { json: "alert_name", js: "alert_name", typ: "" },
      { json: "labels", js: "labels", typ: u(undefined, m("")) },
      { json: "resolve_timeout", js: "resolve_timeout", typ: u(undefined, "") },
      { json: "severity", js: "severity", typ: u(undefined, r("Severity")) },
    ],
    false,
  ),
  And: o(
    [
      { json: "in_ports", js: "in_ports", typ: u(undefined, r("AndIns")) },
      { json: "out_ports", js: "out_ports", typ: u(undefined, r("AndOuts")) },
    ],
    false,
  ),
  AndIns: o(
    [
      {
        json: "inputs",
        js: "inputs",
        typ: u(
          undefined,
          a(r("PurpleComponentsReceiveInputFromOtherComponentsThroughInPorts")),
        ),
      },
    ],
    false,
  ),
  PurpleComponentsReceiveInputFromOtherComponentsThroughInPorts: o(
    [
      {
        json: "constant_signal",
        js: "constant_signal",
        typ: u(undefined, r("ConstantSignal")),
      },
      { json: "signal_name", js: "signal_name", typ: u(undefined, "") },
    ],
    false,
  ),
  AndOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  ComponentsProduceOutputForOtherComponentsThroughOutPorts: o(
    [{ json: "signal_name", js: "signal_name", typ: u(undefined, "") }],
    false,
  ),
  TypeOfCombinatorThatComputesTheArithmeticOperationOnTheOperandSignals: o(
    [
      {
        json: "in_ports",
        js: "in_ports",
        typ: u(undefined, r("ArithmeticCombinatorIns")),
      },
      {
        json: "operator",
        js: "operator",
        typ: u(undefined, r("ArithmeticCombinatorOperator")),
      },
      {
        json: "out_ports",
        js: "out_ports",
        typ: u(undefined, r("ArithmeticCombinatorOuts")),
      },
    ],
    false,
  ),
  ArithmeticCombinatorIns: o(
    [
      { json: "lhs", js: "lhs", typ: u(undefined, r("SignalValue")) },
      { json: "rhs", js: "rhs", typ: u(undefined, r("SignalValue")) },
    ],
    false,
  ),
  ArithmeticCombinatorOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  AutoScale: o(
    [
      {
        json: "auto_scaler",
        js: "auto_scaler",
        typ: u(undefined, r("AutoScaler")),
      },
      {
        json: "pod_scaler",
        js: "pod_scaler",
        typ: u(undefined, r("PodScaler")),
      },
    ],
    false,
  ),
  AutoScaler: o(
    [
      { json: "dry_run", js: "dry_run", typ: u(undefined, true) },
      {
        json: "dry_run_config_key",
        js: "dry_run_config_key",
        typ: u(undefined, ""),
      },
      {
        json: "scale_in_controllers",
        js: "scale_in_controllers",
        typ: u(undefined, a(r("ScaleInController"))),
      },
      {
        json: "scale_out_controllers",
        js: "scale_out_controllers",
        typ: u(undefined, a(r("ScaleOutController"))),
      },
      {
        json: "scaling_backend",
        js: "scaling_backend",
        typ: r("AutoScalerScalingBackend"),
      },
      {
        json: "scaling_parameters",
        js: "scaling_parameters",
        typ: r("AutoScalerScalingParameters"),
      },
    ],
    false,
  ),
  ScaleInController: o(
    [
      { json: "alerter", js: "alerter", typ: r("AlerterParameters") },
      {
        json: "controller",
        js: "controller",
        typ: r("ScaleInControllerController"),
      },
    ],
    false,
  ),
  ScaleInControllerController: o(
    [
      {
        json: "gradient",
        js: "gradient",
        typ: u(undefined, r("DecreasingGradient")),
      },
      {
        json: "periodic",
        js: "periodic",
        typ: u(undefined, r("PeriodicDecrease")),
      },
    ],
    false,
  ),
  DecreasingGradient: o(
    [
      {
        json: "in_ports",
        js: "in_ports",
        typ: u(undefined, r("DecreasingGradientIns")),
      },
      {
        json: "parameters",
        js: "parameters",
        typ: u(undefined, r("DecreasingGradientParameters")),
      },
    ],
    false,
  ),
  DecreasingGradientIns: o(
    [
      { json: "setpoint", js: "setpoint", typ: u(undefined, r("SignalValue")) },
      { json: "signal", js: "signal", typ: u(undefined, r("SignalValue")) },
    ],
    false,
  ),
  DecreasingGradientParameters: o(
    [
      { json: "min_gradient", js: "min_gradient", typ: u(undefined, 3.14) },
      { json: "slope", js: "slope", typ: u(undefined, 3.14) },
    ],
    false,
  ),
  PeriodicDecrease: o(
    [
      { json: "period", js: "period", typ: "" },
      { json: "scale_in_percentage", js: "scale_in_percentage", typ: 3.14 },
    ],
    false,
  ),
  ScaleOutController: o(
    [
      { json: "alerter", js: "alerter", typ: r("AlerterParameters") },
      {
        json: "controller",
        js: "controller",
        typ: r("ScaleOutControllerController"),
      },
    ],
    false,
  ),
  ScaleOutControllerController: o(
    [
      {
        json: "gradient",
        js: "gradient",
        typ: u(undefined, r("IncreasingGradient")),
      },
    ],
    false,
  ),
  IncreasingGradient: o(
    [
      {
        json: "in_ports",
        js: "in_ports",
        typ: u(undefined, r("IncreasingGradientIns")),
      },
      {
        json: "parameters",
        js: "parameters",
        typ: u(undefined, r("IncreasingGradientParameters")),
      },
    ],
    false,
  ),
  IncreasingGradientIns: o(
    [
      { json: "setpoint", js: "setpoint", typ: u(undefined, r("SignalValue")) },
      { json: "signal", js: "signal", typ: u(undefined, r("SignalValue")) },
    ],
    false,
  ),
  IncreasingGradientParameters: o(
    [
      { json: "max_gradient", js: "max_gradient", typ: u(undefined, 3.14) },
      { json: "slope", js: "slope", typ: u(undefined, 3.14) },
    ],
    false,
  ),
  AutoScalerScalingBackend: o(
    [
      {
        json: "kubernetes_replicas",
        js: "kubernetes_replicas",
        typ: u(undefined, r("AutoScalerScalingBackendKubernetesReplicas")),
      },
    ],
    false,
  ),
  AutoScalerScalingBackendKubernetesReplicas: o(
    [
      {
        json: "kubernetes_object_selector",
        js: "kubernetes_object_selector",
        typ: r("KubernetesObjectSelector"),
      },
      { json: "max_replicas", js: "max_replicas", typ: u(undefined, "") },
      { json: "min_replicas", js: "min_replicas", typ: u(undefined, "") },
      { json: "out_ports", js: "out_ports", typ: u(undefined, r("Outputs")) },
    ],
    false,
  ),
  KubernetesObjectSelector: o(
    [
      { json: "agent_group", js: "agent_group", typ: u(undefined, "") },
      { json: "api_version", js: "api_version", typ: "" },
      { json: "kind", js: "kind", typ: "" },
      { json: "name", js: "name", typ: "" },
      { json: "namespace", js: "namespace", typ: "" },
    ],
    false,
  ),
  Outputs: o(
    [
      {
        json: "actual_replicas",
        js: "actual_replicas",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
      {
        json: "configured_replicas",
        js: "configured_replicas",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
      {
        json: "desired_replicas",
        js: "desired_replicas",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  AutoScalerScalingParameters: o(
    [
      {
        json: "cooldown_override_percentage",
        js: "cooldown_override_percentage",
        typ: u(undefined, 3.14),
      },
      {
        json: "max_scale_in_percentage",
        js: "max_scale_in_percentage",
        typ: u(undefined, 3.14),
      },
      {
        json: "max_scale_out_percentage",
        js: "max_scale_out_percentage",
        typ: u(undefined, 3.14),
      },
      {
        json: "scale_in_alerter",
        js: "scale_in_alerter",
        typ: r("AlerterParameters"),
      },
      {
        json: "scale_in_cooldown",
        js: "scale_in_cooldown",
        typ: u(undefined, ""),
      },
      {
        json: "scale_out_alerter",
        js: "scale_out_alerter",
        typ: r("AlerterParameters"),
      },
      {
        json: "scale_out_cooldown",
        js: "scale_out_cooldown",
        typ: u(undefined, ""),
      },
    ],
    false,
  ),
  PodScaler: o(
    [
      { json: "dry_run", js: "dry_run", typ: u(undefined, true) },
      {
        json: "dry_run_config_key",
        js: "dry_run_config_key",
        typ: u(undefined, ""),
      },
      {
        json: "in_ports",
        js: "in_ports",
        typ: u(undefined, r("PodScalerIns")),
      },
      {
        json: "kubernetes_object_selector",
        js: "kubernetes_object_selector",
        typ: r("KubernetesObjectSelector"),
      },
      {
        json: "out_ports",
        js: "out_ports",
        typ: u(undefined, r("PodScalerOuts")),
      },
    ],
    false,
  ),
  PodScalerIns: o(
    [{ json: "replicas", js: "replicas", typ: u(undefined, r("SignalValue")) }],
    false,
  ),
  PodScalerOuts: o(
    [
      {
        json: "actual_replicas",
        js: "actual_replicas",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
      {
        json: "configured_replicas",
        js: "configured_replicas",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  BoolVariable: o(
    [
      { json: "config_key", js: "config_key", typ: u(undefined, "") },
      {
        json: "constant_output",
        js: "constant_output",
        typ: u(undefined, true),
      },
      {
        json: "out_ports",
        js: "out_ports",
        typ: u(undefined, r("BoolVariableOuts")),
      },
    ],
    false,
  ),
  BoolVariableOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  TypeOfCombinatorThatComputesTheComparisonOperationOnLHSAndRHSSignals: o(
    [
      { json: "false_for", js: "false_for", typ: u(undefined, "") },
      { json: "in_ports", js: "in_ports", typ: u(undefined, r("DeciderIns")) },
      {
        json: "operator",
        js: "operator",
        typ: u(undefined, r("DeciderOperator")),
      },
      {
        json: "out_ports",
        js: "out_ports",
        typ: u(undefined, r("DeciderOuts")),
      },
      { json: "true_for", js: "true_for", typ: u(undefined, "") },
    ],
    false,
  ),
  DeciderIns: o(
    [
      { json: "lhs", js: "lhs", typ: u(undefined, r("SignalValue")) },
      { json: "rhs", js: "rhs", typ: u(undefined, r("SignalValue")) },
    ],
    false,
  ),
  DeciderOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  Differentiator: o(
    [
      {
        json: "in_ports",
        js: "in_ports",
        typ: u(undefined, r("DifferentiatorIns")),
      },
      {
        json: "out_ports",
        js: "out_ports",
        typ: u(undefined, r("DifferentiatorOuts")),
      },
      { json: "window", js: "window", typ: u(undefined, "") },
    ],
    false,
  ),
  DifferentiatorIns: o(
    [{ json: "input", js: "input", typ: u(undefined, r("SignalValue")) }],
    false,
  ),
  DifferentiatorOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  ExponentialMovingAverageEMAIsATypeOfMovingAverageThatAppliesExponentiallyMoreWeightToRecentSignalReadings:
    o(
      [
        { json: "in_ports", js: "in_ports", typ: u(undefined, r("EMAIns")) },
        { json: "out_ports", js: "out_ports", typ: u(undefined, r("EMAOuts")) },
        { json: "parameters", js: "parameters", typ: r("EMAParameters") },
      ],
      false,
    ),
  EMAIns: o(
    [
      { json: "input", js: "input", typ: u(undefined, r("SignalValue")) },
      {
        json: "max_envelope",
        js: "max_envelope",
        typ: u(undefined, r("SignalValue")),
      },
      {
        json: "min_envelope",
        js: "min_envelope",
        typ: u(undefined, r("SignalValue")),
      },
    ],
    false,
  ),
  EMAOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  EMAParameters: o(
    [
      {
        json: "correction_factor_on_max_envelope_violation",
        js: "correction_factor_on_max_envelope_violation",
        typ: u(undefined, 3.14),
      },
      {
        json: "correction_factor_on_min_envelope_violation",
        js: "correction_factor_on_min_envelope_violation",
        typ: u(undefined, 3.14),
      },
      { json: "ema_window", js: "ema_window", typ: "" },
      {
        json: "valid_during_warmup",
        js: "valid_during_warmup",
        typ: u(undefined, true),
      },
      { json: "warmup_window", js: "warmup_window", typ: "" },
    ],
    false,
  ),
  ExtrapolatesTheInputSignalByRepeatingTheLastValidValueDuringThePeriodInWhichItIsInvalid:
    o(
      [
        {
          json: "in_ports",
          js: "in_ports",
          typ: u(undefined, r("ExtrapolatorIns")),
        },
        {
          json: "out_ports",
          js: "out_ports",
          typ: u(undefined, r("ExtrapolatorOuts")),
        },
        {
          json: "parameters",
          js: "parameters",
          typ: r("ExtrapolatorParameters"),
        },
      ],
      false,
    ),
  ExtrapolatorIns: o(
    [{ json: "input", js: "input", typ: u(undefined, r("SignalValue")) }],
    false,
  ),
  ExtrapolatorOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  ExtrapolatorParameters: o(
    [
      {
        json: "max_extrapolation_interval",
        js: "max_extrapolation_interval",
        typ: "",
      },
    ],
    false,
  ),
  PicksTheFirstValidInputSignalFromTheArrayOfInputSignalsAndEmitsItAsAnOutputSignal:
    o(
      [
        {
          json: "in_ports",
          js: "in_ports",
          typ: u(undefined, r("FirstValidIns")),
        },
        {
          json: "out_ports",
          js: "out_ports",
          typ: u(undefined, r("FirstValidOuts")),
        },
      ],
      false,
    ),
  FirstValidIns: o(
    [
      {
        json: "inputs",
        js: "inputs",
        typ: u(
          undefined,
          a(r("FluffyComponentsReceiveInputFromOtherComponentsThroughInPorts")),
        ),
      },
    ],
    false,
  ),
  FluffyComponentsReceiveInputFromOtherComponentsThroughInPorts: o(
    [
      {
        json: "constant_signal",
        js: "constant_signal",
        typ: u(undefined, r("ConstantSignal")),
      },
      { json: "signal_name", js: "signal_name", typ: u(undefined, "") },
    ],
    false,
  ),
  FirstValidOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  FlowControl: o(
    [
      {
        json: "adaptive_load_scheduler",
        js: "adaptive_load_scheduler",
        typ: u(undefined, r("AdaptiveLoadScheduler")),
      },
      {
        json: "aiad_load_scheduler",
        js: "aiad_load_scheduler",
        typ: u(undefined, r("AIADLoadScheduler")),
      },
      {
        json: "aimd_load_scheduler",
        js: "aimd_load_scheduler",
        typ: u(undefined, r("AIMDLoadScheduler")),
      },
      { json: "load_ramp", js: "load_ramp", typ: u(undefined, r("LoadRamp")) },
      {
        json: "load_scheduler",
        js: "load_scheduler",
        typ: u(
          undefined,
          r(
            "LoadSchedulerCreatesAQueueForFlowsInFrontOfAServiceToProvideActiveServiceProtection",
          ),
        ),
      },
      {
        json: "quota_scheduler",
        js: "quota_scheduler",
        typ: u(undefined, r("QuotaScheduler")),
      },
      {
        json: "range_driven_load_scheduler",
        js: "range_driven_load_scheduler",
        typ: u(undefined, r("RangeDrivenLoadScheduler")),
      },
      {
        json: "rate_limiter",
        js: "rate_limiter",
        typ: u(undefined, r("LimitsTheTrafficOnAControlPointToSpecifiedRate")),
      },
      { json: "sampler", js: "sampler", typ: u(undefined, r("Sampler")) },
    ],
    false,
  ),
  AdaptiveLoadScheduler: o(
    [
      { json: "dry_run", js: "dry_run", typ: u(undefined, true) },
      {
        json: "dry_run_config_key",
        js: "dry_run_config_key",
        typ: u(undefined, ""),
      },
      { json: "in_ports", js: "in_ports", typ: r("AdaptiveLoadSchedulerIns") },
      {
        json: "out_ports",
        js: "out_ports",
        typ: u(undefined, r("AdaptiveLoadSchedulerOuts")),
      },
      {
        json: "parameters",
        js: "parameters",
        typ: r("AdaptiveLoadSchedulerParameters"),
      },
    ],
    false,
  ),
  AdaptiveLoadSchedulerIns: o(
    [
      {
        json: "overload_confirmation",
        js: "overload_confirmation",
        typ: u(undefined, r("SignalValue")),
      },
      { json: "setpoint", js: "setpoint", typ: r("SignalValue") },
      { json: "signal", js: "signal", typ: r("SignalValue") },
    ],
    false,
  ),
  AdaptiveLoadSchedulerOuts: o(
    [
      {
        json: "desired_load_multiplier",
        js: "desired_load_multiplier",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
      {
        json: "is_overload",
        js: "is_overload",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
      {
        json: "observed_load_multiplier",
        js: "observed_load_multiplier",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  AdaptiveLoadSchedulerParameters: o(
    [
      { json: "alerter", js: "alerter", typ: r("AlerterParameters") },
      {
        json: "gradient",
        js: "gradient",
        typ: r("GradientControllerParameters"),
      },
      {
        json: "load_multiplier_linear_increment",
        js: "load_multiplier_linear_increment",
        typ: u(undefined, 3.14),
      },
      {
        json: "load_scheduler",
        js: "load_scheduler",
        typ: r("ParametersForLoadSchedulerComponent"),
      },
      {
        json: "max_load_multiplier",
        js: "max_load_multiplier",
        typ: u(undefined, 3.14),
      },
    ],
    false,
  ),
  GradientControllerParameters: o(
    [
      { json: "max_gradient", js: "max_gradient", typ: u(undefined, 3.14) },
      { json: "min_gradient", js: "min_gradient", typ: u(undefined, 3.14) },
      { json: "slope", js: "slope", typ: 3.14 },
    ],
    false,
  ),
  ParametersForLoadSchedulerComponent: o(
    [
      {
        json: "scheduler",
        js: "scheduler",
        typ: u(undefined, r("WeightedFairQueuingBasedWorkloadScheduler")),
      },
      {
        json: "selectors",
        js: "selectors",
        typ: a(r("LoadSchedulerSelector")),
      },
      {
        json: "workload_latency_based_tokens",
        js: "workload_latency_based_tokens",
        typ: u(undefined, true),
      },
    ],
    false,
  ),
  WeightedFairQueuingBasedWorkloadScheduler: o(
    [
      {
        json: "decision_deadline_margin",
        js: "decision_deadline_margin",
        typ: u(undefined, ""),
      },
      {
        json: "default_workload_parameters",
        js: "default_workload_parameters",
        typ: u(undefined, r("SchedulerWorkloadParameters")),
      },
      {
        json: "denied_response_status_code",
        js: "denied_response_status_code",
        typ: u(undefined, r("HTTPResponseStatusCode")),
      },
      {
        json: "priority_label_key",
        js: "priority_label_key",
        typ: u(undefined, ""),
      },
      {
        json: "tokens_label_key",
        js: "tokens_label_key",
        typ: u(undefined, ""),
      },
      {
        json: "workload_label_key",
        js: "workload_label_key",
        typ: u(undefined, ""),
      },
      {
        json: "fairness_label_key",
        js: "fairness_label_key",
        typ: u(undefined, ""),
      },
      {
        json: "workloads",
        js: "workloads",
        typ: u(undefined, a(r("SchedulerWorkload"))),
      },
    ],
    false,
  ),
  SchedulerWorkloadParameters: o(
    [
      { json: "priority", js: "priority", typ: u(undefined, 3.14) },
      { json: "queue_timeout", js: "queue_timeout", typ: u(undefined, "") },
      { json: "tokens", js: "tokens", typ: u(undefined, 3.14) },
    ],
    false,
  ),
  SchedulerWorkload: o(
    [
      {
        json: "label_matcher",
        js: "label_matcher",
        typ: r(
          "AllowsToDefineRulesWhetherAMapOfLabelsConceptsFlowLabelMdShouldBeConsideredAMatchOrNot",
        ),
      },
      { json: "name", js: "name", typ: u(undefined, "") },
      {
        json: "parameters",
        js: "parameters",
        typ: u(undefined, r("SchedulerWorkloadParameters")),
      },
    ],
    false,
  ),
  AllowsToDefineRulesWhetherAMapOfLabelsConceptsFlowLabelMdShouldBeConsideredAMatchOrNot:
    o(
      [
        {
          json: "expression",
          js: "expression",
          typ: u(undefined, r("ExpressionClass")),
        },
        {
          json: "match_expressions",
          js: "match_expressions",
          typ: u(undefined, a(r("K8SLabelMatcherRequirement"))),
        },
        { json: "match_labels", js: "match_labels", typ: u(undefined, m("")) },
      ],
      false,
    ),
  OfElement: o(
    [
      {
        json: "all",
        js: "all",
        typ: u(
          undefined,
          r("ListOfMatchExpressionsThatIsUsedForAllOrAnyMatching"),
        ),
      },
      {
        json: "any",
        js: "any",
        typ: u(
          undefined,
          r("ListOfMatchExpressionsThatIsUsedForAllOrAnyMatching"),
        ),
      },
      {
        json: "label_equals",
        js: "label_equals",
        typ: u(undefined, r("EqualsMatchExpression")),
      },
      { json: "label_exists", js: "label_exists", typ: u(undefined, "") },
      {
        json: "label_matches",
        js: "label_matches",
        typ: u(undefined, r("MatchesMatchExpression")),
      },
      { json: "not", js: "not", typ: u(undefined, r("ExpressionClass")) },
    ],
    false,
  ),
  ListOfMatchExpressionsThatIsUsedForAllOrAnyMatching: o(
    [{ json: "of", js: "of", typ: u(undefined, a(r("OfElement"))) }],
    false,
  ),
  ExpressionClass: o(
    [
      {
        json: "all",
        js: "all",
        typ: u(
          undefined,
          r("ListOfMatchExpressionsThatIsUsedForAllOrAnyMatching"),
        ),
      },
      {
        json: "any",
        js: "any",
        typ: u(
          undefined,
          r("ListOfMatchExpressionsThatIsUsedForAllOrAnyMatching"),
        ),
      },
      {
        json: "label_equals",
        js: "label_equals",
        typ: u(undefined, r("EqualsMatchExpression")),
      },
      { json: "label_exists", js: "label_exists", typ: u(undefined, "") },
      {
        json: "label_matches",
        js: "label_matches",
        typ: u(undefined, r("MatchesMatchExpression")),
      },
      { json: "not", js: "not", typ: u(undefined, r("ExpressionClass")) },
    ],
    false,
  ),
  EqualsMatchExpression: o(
    [
      { json: "label", js: "label", typ: "" },
      { json: "value", js: "value", typ: u(undefined, "") },
    ],
    false,
  ),
  MatchesMatchExpression: o(
    [
      { json: "label", js: "label", typ: "" },
      { json: "regex", js: "regex", typ: "" },
    ],
    false,
  ),
  K8SLabelMatcherRequirement: o(
    [
      { json: "key", js: "key", typ: "" },
      { json: "operator", js: "operator", typ: r("MatchExpressionOperator") },
      { json: "values", js: "values", typ: u(undefined, a("")) },
    ],
    false,
  ),
  LoadSchedulerSelector: o(
    [
      { json: "agent_group", js: "agent_group", typ: u(undefined, "") },
      { json: "control_point", js: "control_point", typ: "" },
      {
        json: "label_matcher",
        js: "label_matcher",
        typ: u(
          undefined,
          r(
            "AllowsToDefineRulesWhetherAMapOfLabelsConceptsFlowLabelMdShouldBeConsideredAMatchOrNot",
          ),
        ),
      },
      { json: "service", js: "service", typ: u(undefined, "") },
    ],
    false,
  ),
  AIADLoadScheduler: o(
    [
      { json: "dry_run", js: "dry_run", typ: u(undefined, true) },
      {
        json: "dry_run_config_key",
        js: "dry_run_config_key",
        typ: u(undefined, ""),
      },
      {
        json: "in_ports",
        js: "in_ports",
        typ: u(undefined, r("AIADLoadSchedulerIns")),
      },
      {
        json: "out_ports",
        js: "out_ports",
        typ: u(undefined, r("AIADLoadSchedulerOuts")),
      },
      {
        json: "overload_condition",
        js: "overload_condition",
        typ: r(
          "OverloadConditionDeterminesTheCriteriaToDetermineOverloadStateTheDefaultConditionIsGtThatIsWhenTheSignalIsGreaterThanTheSetpointTheConditionMustBeOneOfValeOffGtGreaterThanLtLessThanGteGreaterThanOrEqualToLTELessThanOrEqualToValeOn",
        ),
      },
      {
        json: "parameters",
        js: "parameters",
        typ: r("AIADLoadSchedulerParameters"),
      },
    ],
    false,
  ),
  AIADLoadSchedulerIns: o(
    [
      {
        json: "overload_confirmation",
        js: "overload_confirmation",
        typ: u(undefined, r("SignalValue")),
      },
      { json: "setpoint", js: "setpoint", typ: r("SignalValue") },
      { json: "signal", js: "signal", typ: r("SignalValue") },
    ],
    false,
  ),
  AIADLoadSchedulerOuts: o(
    [
      {
        json: "desired_load_multiplier",
        js: "desired_load_multiplier",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
      {
        json: "is_overload",
        js: "is_overload",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
      {
        json: "observed_load_multiplier",
        js: "observed_load_multiplier",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  AIADLoadSchedulerParameters: o(
    [
      { json: "alerter", js: "alerter", typ: r("AlerterParameters") },
      {
        json: "load_multiplier_linear_decrement",
        js: "load_multiplier_linear_decrement",
        typ: u(undefined, 3.14),
      },
      {
        json: "load_multiplier_linear_increment",
        js: "load_multiplier_linear_increment",
        typ: u(undefined, 3.14),
      },
      {
        json: "load_scheduler",
        js: "load_scheduler",
        typ: r("ParametersForLoadSchedulerComponent"),
      },
      {
        json: "max_load_multiplier",
        js: "max_load_multiplier",
        typ: u(undefined, 3.14),
      },
      {
        json: "min_load_multiplier",
        js: "min_load_multiplier",
        typ: u(undefined, 3.14),
      },
    ],
    false,
  ),
  AIMDLoadScheduler: o(
    [
      { json: "dry_run", js: "dry_run", typ: u(undefined, true) },
      {
        json: "dry_run_config_key",
        js: "dry_run_config_key",
        typ: u(undefined, ""),
      },
      {
        json: "in_ports",
        js: "in_ports",
        typ: u(undefined, r("AIMDLoadSchedulerIns")),
      },
      {
        json: "out_ports",
        js: "out_ports",
        typ: u(undefined, r("AIMDLoadSchedulerOuts")),
      },
      {
        json: "parameters",
        js: "parameters",
        typ: r("AIMDLoadSchedulerParameters"),
      },
    ],
    false,
  ),
  AIMDLoadSchedulerIns: o(
    [
      {
        json: "overload_confirmation",
        js: "overload_confirmation",
        typ: u(undefined, r("SignalValue")),
      },
      { json: "setpoint", js: "setpoint", typ: r("SignalValue") },
      { json: "signal", js: "signal", typ: r("SignalValue") },
    ],
    false,
  ),
  AIMDLoadSchedulerOuts: o(
    [
      {
        json: "desired_load_multiplier",
        js: "desired_load_multiplier",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
      {
        json: "is_overload",
        js: "is_overload",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
      {
        json: "observed_load_multiplier",
        js: "observed_load_multiplier",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  AIMDLoadSchedulerParameters: o(
    [
      { json: "alerter", js: "alerter", typ: r("AlerterParameters") },
      {
        json: "gradient",
        js: "gradient",
        typ: r("GradientControllerParameters"),
      },
      {
        json: "load_multiplier_linear_increment",
        js: "load_multiplier_linear_increment",
        typ: u(undefined, 3.14),
      },
      {
        json: "load_scheduler",
        js: "load_scheduler",
        typ: r("ParametersForLoadSchedulerComponent"),
      },
      {
        json: "max_load_multiplier",
        js: "max_load_multiplier",
        typ: u(undefined, 3.14),
      },
    ],
    false,
  ),
  LoadRamp: o(
    [
      { json: "in_ports", js: "in_ports", typ: u(undefined, r("LoadRampIns")) },
      {
        json: "out_ports",
        js: "out_ports",
        typ: u(undefined, r("LoadRampOuts")),
      },
      { json: "parameters", js: "parameters", typ: r("LoadRampParameters") },
      {
        json: "pass_through_label_values",
        js: "pass_through_label_values",
        typ: u(undefined, a("")),
      },
      {
        json: "pass_through_label_values_config_key",
        js: "pass_through_label_values_config_key",
        typ: u(undefined, ""),
      },
    ],
    false,
  ),
  LoadRampIns: o(
    [
      { json: "backward", js: "backward", typ: u(undefined, r("SignalValue")) },
      { json: "forward", js: "forward", typ: u(undefined, r("SignalValue")) },
      { json: "reset", js: "reset", typ: u(undefined, r("SignalValue")) },
    ],
    false,
  ),
  LoadRampOuts: o(
    [
      {
        json: "accept_percentage",
        js: "accept_percentage",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
      {
        json: "at_end",
        js: "at_end",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
      {
        json: "at_start",
        js: "at_start",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  LoadRampParameters: o(
    [
      { json: "sampler", js: "sampler", typ: r("SamplerParameters") },
      { json: "steps", js: "steps", typ: a(r("LoadRampParametersStep")) },
    ],
    false,
  ),
  SamplerParameters: o(
    [
      {
        json: "denied_response_status_code",
        js: "denied_response_status_code",
        typ: u(undefined, r("HTTPResponseStatusCode")),
      },
      { json: "ramp_mode", js: "ramp_mode", typ: u(undefined, true) },
      { json: "selectors", js: "selectors", typ: a(r("SamplerSelector")) },
      {
        json: "session_label_key",
        js: "session_label_key",
        typ: u(undefined, ""),
      },
    ],
    false,
  ),
  SamplerSelector: o(
    [
      { json: "agent_group", js: "agent_group", typ: u(undefined, "") },
      { json: "control_point", js: "control_point", typ: "" },
      {
        json: "label_matcher",
        js: "label_matcher",
        typ: u(
          undefined,
          r(
            "AllowsToDefineRulesWhetherAMapOfLabelsConceptsFlowLabelMdShouldBeConsideredAMatchOrNot",
          ),
        ),
      },
      { json: "service", js: "service", typ: u(undefined, "") },
    ],
    false,
  ),
  LoadRampParametersStep: o(
    [
      {
        json: "denied_response_status_code",
        js: "denied_response_status_code",
        typ: u(undefined, r("HTTPResponseStatusCode")),
      },
      { json: "duration", js: "duration", typ: "" },
      {
        json: "target_accept_percentage",
        js: "target_accept_percentage",
        typ: u(undefined, 3.14),
      },
    ],
    false,
  ),
  LoadSchedulerCreatesAQueueForFlowsInFrontOfAServiceToProvideActiveServiceProtection:
    o(
      [
        { json: "dry_run", js: "dry_run", typ: u(undefined, true) },
        {
          json: "dry_run_config_key",
          js: "dry_run_config_key",
          typ: u(undefined, ""),
        },
        { json: "in_ports", js: "in_ports", typ: r("LoadSchedulerIns") },
        {
          json: "out_ports",
          js: "out_ports",
          typ: u(undefined, r("LoadSchedulerOuts")),
        },
        {
          json: "parameters",
          js: "parameters",
          typ: r("ParametersForLoadSchedulerComponent"),
        },
      ],
      false,
    ),
  LoadSchedulerIns: o(
    [
      {
        json: "load_multiplier",
        js: "load_multiplier",
        typ: u(undefined, r("SignalValue")),
      },
    ],
    false,
  ),
  LoadSchedulerOuts: o(
    [
      {
        json: "observed_load_multiplier",
        js: "observed_load_multiplier",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  QuotaScheduler: o(
    [
      {
        json: "in_ports",
        js: "in_ports",
        typ: r("InputsForTheRateLimiterComponent"),
      },
      {
        json: "out_ports",
        js: "out_ports",
        typ: u(undefined, r("QuotaSchedulerOuts")),
      },
      {
        json: "rate_limiter",
        js: "rate_limiter",
        typ: r("RateLimiterParameters"),
      },
      {
        json: "scheduler",
        js: "scheduler",
        typ: u(undefined, r("WeightedFairQueuingBasedWorkloadScheduler")),
      },
      { json: "selectors", js: "selectors", typ: a(r("PurpleSelector")) },
    ],
    false,
  ),
  InputsForTheRateLimiterComponent: o(
    [
      { json: "bucket_capacity", js: "bucket_capacity", typ: r("SignalValue") },
      { json: "fill_amount", js: "fill_amount", typ: r("SignalValue") },
      {
        json: "pass_through",
        js: "pass_through",
        typ: u(undefined, r("SignalValue")),
      },
    ],
    false,
  ),
  QuotaSchedulerOuts: o(
    [
      {
        json: "accept_percentage",
        js: "accept_percentage",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  RateLimiterParameters: o(
    [
      {
        json: "adaptive_load_scheduler",
        js: "adaptive_load_scheduler",
        typ: u(undefined, r("AdaptiveLoadScheduler")),
      },
      {
        json: "continuous_fill",
        js: "continuous_fill",
        typ: u(undefined, true),
      },
      {
        json: "delay_initial_fill",
        js: "delay_initial_fill",
        typ: u(undefined, true),
      },
      { json: "interval", js: "interval", typ: "" },
      { json: "label_key", js: "label_key", typ: u(undefined, "") },
      {
        json: "lazy_sync",
        js: "lazy_sync",
        typ: u(undefined, r("RateLimiterParametersLazySync")),
      },
      {
        json: "limit_by_label_key",
        js: "limit_by_label_key",
        typ: u(undefined, ""),
      },
      { json: "max_idle_time", js: "max_idle_time", typ: u(undefined, "") },
    ],
    false,
  ),
  RateLimiterParametersLazySync: o(
    [
      { json: "enabled", js: "enabled", typ: u(undefined, true) },
      { json: "num_sync", js: "num_sync", typ: u(undefined, 0) },
    ],
    false,
  ),
  PurpleSelector: o(
    [
      { json: "agent_group", js: "agent_group", typ: u(undefined, "") },
      { json: "control_point", js: "control_point", typ: "" },
      {
        json: "label_matcher",
        js: "label_matcher",
        typ: u(
          undefined,
          r(
            "AllowsToDefineRulesWhetherAMapOfLabelsConceptsFlowLabelMdShouldBeConsideredAMatchOrNot",
          ),
        ),
      },
      { json: "service", js: "service", typ: u(undefined, "") },
    ],
    false,
  ),
  RangeDrivenLoadScheduler: o(
    [
      { json: "dry_run", js: "dry_run", typ: u(undefined, true) },
      {
        json: "dry_run_config_key",
        js: "dry_run_config_key",
        typ: u(undefined, ""),
      },
      {
        json: "in_ports",
        js: "in_ports",
        typ: u(undefined, r("RangeDrivenLoadSchedulerIns")),
      },
      {
        json: "out_ports",
        js: "out_ports",
        typ: u(undefined, r("RangeDrivenLoadSchedulerOuts")),
      },
      {
        json: "parameters",
        js: "parameters",
        typ: r("RangeDrivenLoadSchedulerParameters"),
      },
    ],
    false,
  ),
  RangeDrivenLoadSchedulerIns: o(
    [
      {
        json: "overload_confirmation",
        js: "overload_confirmation",
        typ: u(undefined, r("SignalValue")),
      },
      { json: "signal", js: "signal", typ: r("SignalValue") },
    ],
    false,
  ),
  RangeDrivenLoadSchedulerOuts: o(
    [
      {
        json: "desired_load_multiplier",
        js: "desired_load_multiplier",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
      {
        json: "is_overload",
        js: "is_overload",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
      {
        json: "observed_load_multiplier",
        js: "observed_load_multiplier",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  RangeDrivenLoadSchedulerParameters: o(
    [
      { json: "alerter", js: "alerter", typ: r("AlerterParameters") },
      { json: "degree", js: "degree", typ: 3.14 },
      {
        json: "high_throttle_threshold",
        js: "high_throttle_threshold",
        typ: r("RangeDrivenLoadSchedulerDatapoint"),
      },
      {
        json: "load_scheduler",
        js: "load_scheduler",
        typ: r("ParametersForLoadSchedulerComponent"),
      },
      {
        json: "low_throttle_threshold",
        js: "low_throttle_threshold",
        typ: r("RangeDrivenLoadSchedulerDatapoint"),
      },
    ],
    false,
  ),
  RangeDrivenLoadSchedulerDatapoint: o(
    [
      {
        json: "load_multiplier",
        js: "load_multiplier",
        typ: u(undefined, 3.14),
      },
      { json: "signal_value", js: "signal_value", typ: u(undefined, 3.14) },
    ],
    false,
  ),
  LimitsTheTrafficOnAControlPointToSpecifiedRate: o(
    [
      {
        json: "in_ports",
        js: "in_ports",
        typ: r("InputsForTheRateLimiterComponent"),
      },
      {
        json: "out_ports",
        js: "out_ports",
        typ: u(undefined, r("RateLimiterOuts")),
      },
      { json: "parameters", js: "parameters", typ: r("RateLimiterParameters") },
      {
        json: "request_parameters",
        js: "request_parameters",
        typ: u(undefined, r("RateLimiterRequestParameters")),
      },
      { json: "selectors", js: "selectors", typ: a(r("RateLimiterSelector")) },
    ],
    false,
  ),
  RateLimiterOuts: o(
    [
      {
        json: "accept_percentage",
        js: "accept_percentage",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  RateLimiterRequestParameters: o(
    [
      {
        json: "denied_response_status_code",
        js: "denied_response_status_code",
        typ: u(undefined, r("HTTPResponseStatusCode")),
      },
      {
        json: "tokens_label_key",
        js: "tokens_label_key",
        typ: u(undefined, ""),
      },
    ],
    false,
  ),
  RateLimiterSelector: o(
    [
      { json: "agent_group", js: "agent_group", typ: u(undefined, "") },
      { json: "control_point", js: "control_point", typ: "" },
      {
        json: "label_matcher",
        js: "label_matcher",
        typ: u(
          undefined,
          r(
            "AllowsToDefineRulesWhetherAMapOfLabelsConceptsFlowLabelMdShouldBeConsideredAMatchOrNot",
          ),
        ),
      },
      { json: "service", js: "service", typ: u(undefined, "") },
    ],
    false,
  ),
  Sampler: o(
    [
      { json: "in_ports", js: "in_ports", typ: u(undefined, r("SamplerIns")) },
      { json: "parameters", js: "parameters", typ: r("SamplerParameters") },
      {
        json: "pass_through_label_values",
        js: "pass_through_label_values",
        typ: u(undefined, a("")),
      },
      {
        json: "pass_through_label_values_config_key",
        js: "pass_through_label_values_config_key",
        typ: u(undefined, ""),
      },
    ],
    false,
  ),
  SamplerIns: o(
    [
      {
        json: "accept_percentage",
        js: "accept_percentage",
        typ: u(undefined, r("SignalValue")),
      },
    ],
    false,
  ),
  GradientControllerIsATypeOfControllerWhichTriesToAdjustTheControlVariableProportionallyToTheRelativeDifferenceBetweenSetpointAndActualValueOfTheSignal:
    o(
      [
        {
          json: "in_ports",
          js: "in_ports",
          typ: u(undefined, r("GradientControllerIns")),
        },
        { json: "manual_mode", js: "manual_mode", typ: u(undefined, true) },
        {
          json: "manual_mode_config_key",
          js: "manual_mode_config_key",
          typ: u(undefined, ""),
        },
        {
          json: "out_ports",
          js: "out_ports",
          typ: u(undefined, r("GradientControllerOuts")),
        },
        {
          json: "parameters",
          js: "parameters",
          typ: r("GradientControllerParameters"),
        },
      ],
      false,
    ),
  GradientControllerIns: o(
    [
      {
        json: "control_variable",
        js: "control_variable",
        typ: u(undefined, r("SignalValue")),
      },
      { json: "max", js: "max", typ: u(undefined, r("SignalValue")) },
      { json: "min", js: "min", typ: u(undefined, r("SignalValue")) },
      { json: "setpoint", js: "setpoint", typ: u(undefined, r("SignalValue")) },
      { json: "signal", js: "signal", typ: u(undefined, r("SignalValue")) },
    ],
    false,
  ),
  GradientControllerOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  Holder: o(
    [
      { json: "hold_for", js: "hold_for", typ: u(undefined, "") },
      { json: "in_ports", js: "in_ports", typ: u(undefined, r("HolderIns")) },
      {
        json: "out_ports",
        js: "out_ports",
        typ: u(undefined, r("HolderOuts")),
      },
    ],
    false,
  ),
  HolderIns: o(
    [
      { json: "input", js: "input", typ: u(undefined, r("SignalValue")) },
      { json: "reset", js: "reset", typ: u(undefined, r("SignalValue")) },
    ],
    false,
  ),
  HolderOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  Integrator: o(
    [
      {
        json: "evaluation_interval",
        js: "evaluation_interval",
        typ: u(undefined, ""),
      },
      {
        json: "in_ports",
        js: "in_ports",
        typ: u(undefined, r("IntegratorIns")),
      },
      { json: "initial_value", js: "initial_value", typ: u(undefined, 3.14) },
      {
        json: "out_ports",
        js: "out_ports",
        typ: u(undefined, r("IntegratorOuts")),
      },
    ],
    false,
  ),
  IntegratorIns: o(
    [
      { json: "input", js: "input", typ: u(undefined, r("SignalValue")) },
      { json: "max", js: "max", typ: u(undefined, r("SignalValue")) },
      { json: "min", js: "min", typ: u(undefined, r("SignalValue")) },
      { json: "reset", js: "reset", typ: u(undefined, r("SignalValue")) },
    ],
    false,
  ),
  IntegratorOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  Inverter: o(
    [
      { json: "in_ports", js: "in_ports", typ: u(undefined, r("InverterIns")) },
      {
        json: "out_ports",
        js: "out_ports",
        typ: u(undefined, r("InverterOuts")),
      },
    ],
    false,
  ),
  InverterIns: o(
    [{ json: "input", js: "input", typ: u(undefined, r("SignalValue")) }],
    false,
  ),
  InverterOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  TakesAListOfInputSignalsAndEmitsTheSignalWithTheMaximumValue: o(
    [
      { json: "in_ports", js: "in_ports", typ: u(undefined, r("MaxIns")) },
      { json: "out_ports", js: "out_ports", typ: u(undefined, r("MaxOuts")) },
    ],
    false,
  ),
  MaxIns: o(
    [
      {
        json: "inputs",
        js: "inputs",
        typ: u(
          undefined,
          a(
            r(
              "TentacledComponentsReceiveInputFromOtherComponentsThroughInPorts",
            ),
          ),
        ),
      },
    ],
    false,
  ),
  TentacledComponentsReceiveInputFromOtherComponentsThroughInPorts: o(
    [
      {
        json: "constant_signal",
        js: "constant_signal",
        typ: u(undefined, r("ConstantSignal")),
      },
      { json: "signal_name", js: "signal_name", typ: u(undefined, "") },
    ],
    false,
  ),
  MaxOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  Min: o(
    [
      { json: "in_ports", js: "in_ports", typ: u(undefined, r("MinIns")) },
      { json: "out_ports", js: "out_ports", typ: u(undefined, r("MinOuts")) },
    ],
    false,
  ),
  MinIns: o(
    [
      {
        json: "inputs",
        js: "inputs",
        typ: u(
          undefined,
          a(r("StickyComponentsReceiveInputFromOtherComponentsThroughInPorts")),
        ),
      },
    ],
    false,
  ),
  StickyComponentsReceiveInputFromOtherComponentsThroughInPorts: o(
    [
      {
        json: "constant_signal",
        js: "constant_signal",
        typ: u(undefined, r("ConstantSignal")),
      },
      { json: "signal_name", js: "signal_name", typ: u(undefined, "") },
    ],
    false,
  ),
  MinOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  NestedCircuitComponent: o(
    [
      { json: "alerter", js: "alerter", typ: u(undefined, r("Alerter")) },
      { json: "and", js: "and", typ: u(undefined, r("And")) },
      {
        json: "arithmetic_combinator",
        js: "arithmetic_combinator",
        typ: u(
          undefined,
          r(
            "TypeOfCombinatorThatComputesTheArithmeticOperationOnTheOperandSignals",
          ),
        ),
      },
      {
        json: "auto_scale",
        js: "auto_scale",
        typ: u(undefined, r("AutoScale")),
      },
      {
        json: "bool_variable",
        js: "bool_variable",
        typ: u(undefined, r("BoolVariable")),
      },
      {
        json: "decider",
        js: "decider",
        typ: u(
          undefined,
          r(
            "TypeOfCombinatorThatComputesTheComparisonOperationOnLHSAndRHSSignals",
          ),
        ),
      },
      {
        json: "differentiator",
        js: "differentiator",
        typ: u(undefined, r("Differentiator")),
      },
      {
        json: "ema",
        js: "ema",
        typ: u(
          undefined,
          r(
            "ExponentialMovingAverageEMAIsATypeOfMovingAverageThatAppliesExponentiallyMoreWeightToRecentSignalReadings",
          ),
        ),
      },
      {
        json: "extrapolator",
        js: "extrapolator",
        typ: u(
          undefined,
          r(
            "ExtrapolatesTheInputSignalByRepeatingTheLastValidValueDuringThePeriodInWhichItIsInvalid",
          ),
        ),
      },
      {
        json: "first_valid",
        js: "first_valid",
        typ: u(
          undefined,
          r(
            "PicksTheFirstValidInputSignalFromTheArrayOfInputSignalsAndEmitsItAsAnOutputSignal",
          ),
        ),
      },
      {
        json: "flow_control",
        js: "flow_control",
        typ: u(undefined, r("FlowControl")),
      },
      {
        json: "gradient_controller",
        js: "gradient_controller",
        typ: u(
          undefined,
          r(
            "GradientControllerIsATypeOfControllerWhichTriesToAdjustTheControlVariableProportionallyToTheRelativeDifferenceBetweenSetpointAndActualValueOfTheSignal",
          ),
        ),
      },
      { json: "holder", js: "holder", typ: u(undefined, r("Holder")) },
      {
        json: "integrator",
        js: "integrator",
        typ: u(undefined, r("Integrator")),
      },
      { json: "inverter", js: "inverter", typ: u(undefined, r("Inverter")) },
      {
        json: "max",
        js: "max",
        typ: u(
          undefined,
          r("TakesAListOfInputSignalsAndEmitsTheSignalWithTheMaximumValue"),
        ),
      },
      { json: "min", js: "min", typ: u(undefined, r("Min")) },
      {
        json: "nested_circuit",
        js: "nested_circuit",
        typ: u(undefined, r("NestedCircuit")),
      },
      {
        json: "nested_signal_egress",
        js: "nested_signal_egress",
        typ: u(undefined, r("NestedSignalEgress")),
      },
      {
        json: "nested_signal_ingress",
        js: "nested_signal_ingress",
        typ: u(undefined, r("NestedSignalIngress")),
      },
      { json: "or", js: "or", typ: u(undefined, r("Or")) },
      {
        json: "pid_controller",
        js: "pid_controller",
        typ: u(
          undefined,
          r(
            "PIDControllerIsAPopularControlMechanismForClosedLoopFeedbackControlItTakesASignalAndASetpointAsInputsAndUsesThePIDAlgorithmToComputeProportionalProportionalToTheMagnitudeOfErrorIntegralProportionalToAccumulationOfErrorAndDerivativeProportionalToHowFastTheSignalIsChangingTermsWhichAreSummedUpToGetADesiredOutputValeOffErrorSetpointSignalIntegralIIntegralI1KICdotErrorOutputIKPCdotErrorKDCdotSignalISignalI1IntegralIValeOn",
          ),
        ),
      },
      {
        json: "polynomial_range_function",
        js: "polynomial_range_function",
        typ: u(undefined, r("PolynomialRangeFunction")),
      },
      {
        json: "pulse_generator",
        js: "pulse_generator",
        typ: u(undefined, r("PulseGenerator")),
      },
      { json: "query", js: "query", typ: u(undefined, r("Query")) },
      {
        json: "signal_generator",
        js: "signal_generator",
        typ: u(undefined, r("SignalGenerator")),
      },
      { json: "sma", js: "sma", typ: u(undefined, r("SMA")) },
      {
        json: "switcher",
        js: "switcher",
        typ: u(
          undefined,
          r(
            "TypeOfCombinatorThatSwitchesBetweenOnSignalAndOffSignalSignalsBasedOnSwitchInput",
          ),
        ),
      },
      {
        json: "unary_operator",
        js: "unary_operator",
        typ: u(
          undefined,
          r(
            "TakesAnInputSignalAndEmitsTheOutputAfterApplyingTheSpecifiedUnaryOperator",
          ),
        ),
      },
      { json: "variable", js: "variable", typ: u(undefined, r("Variable")) },
    ],
    false,
  ),
  NestedCircuit: o(
    [
      {
        json: "components",
        js: "components",
        typ: u(undefined, a(r("NestedCircuitComponent"))),
      },
      {
        json: "in_ports_map",
        js: "in_ports_map",
        typ: u(undefined, m(r("SignalValue"))),
      },
      {
        json: "out_ports_map",
        js: "out_ports_map",
        typ: u(
          undefined,
          m(r("ComponentsProduceOutputForOtherComponentsThroughOutPorts")),
        ),
      },
    ],
    false,
  ),
  NestedSignalEgress: o(
    [
      {
        json: "in_ports",
        js: "in_ports",
        typ: u(undefined, r("NestedSignalEgressIns")),
      },
      { json: "port_name", js: "port_name", typ: u(undefined, "") },
    ],
    false,
  ),
  NestedSignalEgressIns: o(
    [{ json: "signal", js: "signal", typ: u(undefined, r("SignalValue")) }],
    false,
  ),
  NestedSignalIngress: o(
    [
      {
        json: "out_ports",
        js: "out_ports",
        typ: u(undefined, r("NestedSignalIngressOuts")),
      },
      { json: "port_name", js: "port_name", typ: u(undefined, "") },
    ],
    false,
  ),
  NestedSignalIngressOuts: o(
    [
      {
        json: "signal",
        js: "signal",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  Or: o(
    [
      { json: "in_ports", js: "in_ports", typ: u(undefined, r("OrIns")) },
      { json: "out_ports", js: "out_ports", typ: u(undefined, r("OrOuts")) },
    ],
    false,
  ),
  OrIns: o(
    [
      {
        json: "inputs",
        js: "inputs",
        typ: u(
          undefined,
          a(r("IndigoComponentsReceiveInputFromOtherComponentsThroughInPorts")),
        ),
      },
    ],
    false,
  ),
  IndigoComponentsReceiveInputFromOtherComponentsThroughInPorts: o(
    [
      {
        json: "constant_signal",
        js: "constant_signal",
        typ: u(undefined, r("ConstantSignal")),
      },
      { json: "signal_name", js: "signal_name", typ: u(undefined, "") },
    ],
    false,
  ),
  OrOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  PIDControllerIsAPopularControlMechanismForClosedLoopFeedbackControlItTakesASignalAndASetpointAsInputsAndUsesThePIDAlgorithmToComputeProportionalProportionalToTheMagnitudeOfErrorIntegralProportionalToAccumulationOfErrorAndDerivativeProportionalToHowFastTheSignalIsChangingTermsWhichAreSummedUpToGetADesiredOutputValeOffErrorSetpointSignalIntegralIIntegralI1KICdotErrorOutputIKPCdotErrorKDCdotSignalISignalI1IntegralIValeOn:
    o(
      [
        {
          json: "in_ports",
          js: "in_ports",
          typ: u(undefined, r("PIDControllerIns")),
        },
        {
          json: "out_ports",
          js: "out_ports",
          typ: u(undefined, r("PIDControllerOuts")),
        },
        {
          json: "parameters",
          js: "parameters",
          typ: r("PIDControllerParameters"),
        },
      ],
      false,
    ),
  PIDControllerIns: o(
    [
      { json: "max", js: "max", typ: u(undefined, r("SignalValue")) },
      { json: "min", js: "min", typ: u(undefined, r("SignalValue")) },
      { json: "setpoint", js: "setpoint", typ: u(undefined, r("SignalValue")) },
      { json: "signal", js: "signal", typ: u(undefined, r("SignalValue")) },
    ],
    false,
  ),
  PIDControllerOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  PIDControllerParameters: o(
    [
      {
        json: "evaluation_interval",
        js: "evaluation_interval",
        typ: u(undefined, ""),
      },
      { json: "kd", js: "kd", typ: u(undefined, 3.14) },
      { json: "ki", js: "ki", typ: u(undefined, 3.14) },
      { json: "kp", js: "kp", typ: u(undefined, 3.14) },
      {
        json: "reset_after_invalid_samples",
        js: "reset_after_invalid_samples",
        typ: u(undefined, 0),
      },
    ],
    false,
  ),
  PolynomialRangeFunction: o(
    [
      {
        json: "in_ports",
        js: "in_ports",
        typ: u(undefined, r("PolynomialRangeFunctionIns")),
      },
      {
        json: "out_ports",
        js: "out_ports",
        typ: u(undefined, r("PolynomialRangeFunctionOuts")),
      },
      {
        json: "parameters",
        js: "parameters",
        typ: r("PolynomialRangeFunctionParameters"),
      },
    ],
    false,
  ),
  PolynomialRangeFunctionIns: o(
    [{ json: "input", js: "input", typ: u(undefined, r("SignalValue")) }],
    false,
  ),
  PolynomialRangeFunctionOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  PolynomialRangeFunctionParameters: o(
    [
      {
        json: "clamp_to_custom_values",
        js: "clamp_to_custom_values",
        typ: u(
          undefined,
          r("PolynomialRangeFunctionParametersClampToCustomValues"),
        ),
      },
      {
        json: "clamp_to_datapoint",
        js: "clamp_to_datapoint",
        typ: u(undefined, true),
      },
      { json: "continue_curve", js: "continue_curve", typ: u(undefined, true) },
      { json: "degree", js: "degree", typ: u(undefined, 3.14) },
      {
        json: "end",
        js: "end",
        typ: u(undefined, r("PolynomialRangeFunctionParametersDatapoint")),
      },
      {
        json: "start",
        js: "start",
        typ: u(undefined, r("PolynomialRangeFunctionParametersDatapoint")),
      },
    ],
    false,
  ),
  PolynomialRangeFunctionParametersClampToCustomValues: o(
    [
      { json: "post_end", js: "post_end", typ: u(undefined, 3.14) },
      { json: "pre_start", js: "pre_start", typ: u(undefined, 3.14) },
    ],
    false,
  ),
  PolynomialRangeFunctionParametersDatapoint: o(
    [
      { json: "input", js: "input", typ: u(undefined, 3.14) },
      { json: "output", js: "output", typ: u(undefined, 3.14) },
    ],
    false,
  ),
  PulseGenerator: o(
    [
      { json: "false_for", js: "false_for", typ: u(undefined, "") },
      {
        json: "out_ports",
        js: "out_ports",
        typ: u(undefined, r("PulseGeneratorOuts")),
      },
      { json: "true_for", js: "true_for", typ: u(undefined, "") },
    ],
    false,
  ),
  PulseGeneratorOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  Query: o(
    [
      {
        json: "promql",
        js: "promql",
        typ: u(
          undefined,
          r(
            "ComponentThatRunsAPrometheusQueryPeriodicallyAndReturnsTheResultAsAnOutputSignal",
          ),
        ),
      },
    ],
    false,
  ),
  ComponentThatRunsAPrometheusQueryPeriodicallyAndReturnsTheResultAsAnOutputSignal:
    o(
      [
        {
          json: "evaluation_interval",
          js: "evaluation_interval",
          typ: u(undefined, ""),
        },
        {
          json: "out_ports",
          js: "out_ports",
          typ: u(undefined, r("PromQLOuts")),
        },
        { json: "query_string", js: "query_string", typ: u(undefined, "") },
      ],
      false,
    ),
  PromQLOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  SignalGenerator: o(
    [
      {
        json: "in_ports",
        js: "in_ports",
        typ: u(undefined, r("SignalGeneratorIns")),
      },
      {
        json: "out_ports",
        js: "out_ports",
        typ: u(undefined, r("SignalGeneratorOuts")),
      },
      {
        json: "parameters",
        js: "parameters",
        typ: r("SignalGeneratorParameters"),
      },
    ],
    false,
  ),
  SignalGeneratorIns: o(
    [
      { json: "backward", js: "backward", typ: u(undefined, r("SignalValue")) },
      { json: "forward", js: "forward", typ: u(undefined, r("SignalValue")) },
      { json: "reset", js: "reset", typ: u(undefined, r("SignalValue")) },
    ],
    false,
  ),
  SignalGeneratorOuts: o(
    [
      {
        json: "at_end",
        js: "at_end",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
      {
        json: "at_start",
        js: "at_start",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  SignalGeneratorParameters: o(
    [
      {
        json: "steps",
        js: "steps",
        typ: a(r("SignalGeneratorParametersStep")),
      },
    ],
    false,
  ),
  SignalGeneratorParametersStep: o(
    [
      { json: "duration", js: "duration", typ: "" },
      {
        json: "target_output",
        js: "target_output",
        typ: u(undefined, r("ConstantSignal")),
      },
    ],
    false,
  ),
  SMA: o(
    [
      { json: "in_ports", js: "in_ports", typ: u(undefined, r("SMAIns")) },
      { json: "out_ports", js: "out_ports", typ: u(undefined, r("SMAOuts")) },
      { json: "parameters", js: "parameters", typ: r("SMAParameters") },
    ],
    false,
  ),
  SMAIns: o(
    [{ json: "input", js: "input", typ: u(undefined, r("SignalValue")) }],
    false,
  ),
  SMAOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  SMAParameters: o(
    [
      { json: "sma_window", js: "sma_window", typ: "" },
      {
        json: "valid_during_warmup",
        js: "valid_during_warmup",
        typ: u(undefined, true),
      },
    ],
    false,
  ),
  TypeOfCombinatorThatSwitchesBetweenOnSignalAndOffSignalSignalsBasedOnSwitchInput:
    o(
      [
        {
          json: "in_ports",
          js: "in_ports",
          typ: u(undefined, r("SwitcherIns")),
        },
        {
          json: "out_ports",
          js: "out_ports",
          typ: u(undefined, r("SwitcherOuts")),
        },
      ],
      false,
    ),
  SwitcherIns: o(
    [
      {
        json: "off_signal",
        js: "off_signal",
        typ: u(undefined, r("SignalValue")),
      },
      {
        json: "on_signal",
        js: "on_signal",
        typ: u(undefined, r("SignalValue")),
      },
      { json: "switch", js: "switch", typ: u(undefined, r("SignalValue")) },
    ],
    false,
  ),
  SwitcherOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  TakesAnInputSignalAndEmitsTheOutputAfterApplyingTheSpecifiedUnaryOperator: o(
    [
      {
        json: "in_ports",
        js: "in_ports",
        typ: u(undefined, r("UnaryOperatorIns")),
      },
      {
        json: "operator",
        js: "operator",
        typ: u(undefined, r("UnaryOperatorOperator")),
      },
      {
        json: "out_ports",
        js: "out_ports",
        typ: u(undefined, r("UnaryOperatorOuts")),
      },
    ],
    false,
  ),
  UnaryOperatorIns: o(
    [{ json: "input", js: "input", typ: u(undefined, r("SignalValue")) }],
    false,
  ),
  UnaryOperatorOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  Variable: o(
    [
      { json: "config_key", js: "config_key", typ: u(undefined, "") },
      {
        json: "constant_output",
        js: "constant_output",
        typ: r("ConstantSignal"),
      },
      {
        json: "out_ports",
        js: "out_ports",
        typ: u(undefined, r("VariableOuts")),
      },
    ],
    false,
  ),
  VariableOuts: o(
    [
      {
        json: "output",
        js: "output",
        typ: u(
          undefined,
          r("ComponentsProduceOutputForOtherComponentsThroughOutPorts"),
        ),
      },
    ],
    false,
  ),
  PolicyQuotaScheduler: o(
    [
      {
        json: "alerter",
        js: "alerter",
        typ: u(undefined, r("QuotaSchedulerAlerter")),
      },
      { json: "bucket_capacity", js: "bucket_capacity", typ: 3.14 },
      { json: "fill_amount", js: "fill_amount", typ: 3.14 },
      { json: "rate_limiter", js: "rate_limiter", typ: r("RateLimiter") },
      { json: "scheduler", js: "scheduler", typ: u(undefined, r("Scheduler")) },
      { json: "selectors", js: "selectors", typ: a(r("FluffySelector")) },
    ],
    false,
  ),
  QuotaSchedulerAlerter: o(
    [
      {
        json: "alert_channels",
        js: "alert_channels",
        typ: u(undefined, a("")),
      },
      { json: "alert_name", js: "alert_name", typ: "" },
      { json: "labels", js: "labels", typ: u(undefined, m("")) },
      { json: "resolve_timeout", js: "resolve_timeout", typ: u(undefined, "") },
      { json: "severity", js: "severity", typ: u(undefined, r("Severity")) },
    ],
    false,
  ),
  RateLimiter: o(
    [
      {
        json: "adaptive_load_scheduler",
        js: "adaptive_load_scheduler",
        typ: u(undefined, r("AdaptiveLoadScheduler")),
      },
      {
        json: "continuous_fill",
        js: "continuous_fill",
        typ: u(undefined, true),
      },
      {
        json: "delay_initial_fill",
        js: "delay_initial_fill",
        typ: u(undefined, true),
      },
      { json: "interval", js: "interval", typ: "" },
      { json: "label_key", js: "label_key", typ: u(undefined, "") },
      {
        json: "lazy_sync",
        js: "lazy_sync",
        typ: u(undefined, r("RateLimiterParametersLazySync")),
      },
      {
        json: "limit_by_label_key",
        js: "limit_by_label_key",
        typ: u(undefined, ""),
      },
      { json: "max_idle_time", js: "max_idle_time", typ: u(undefined, "") },
    ],
    false,
  ),
  Scheduler: o(
    [
      {
        json: "decision_deadline_margin",
        js: "decision_deadline_margin",
        typ: u(undefined, ""),
      },
      {
        json: "default_workload_parameters",
        js: "default_workload_parameters",
        typ: u(undefined, r("SchedulerWorkloadParameters")),
      },
      {
        json: "denied_response_status_code",
        js: "denied_response_status_code",
        typ: u(undefined, r("HTTPResponseStatusCode")),
      },
      {
        json: "priority_label_key",
        js: "priority_label_key",
        typ: u(undefined, ""),
      },
      {
        json: "tokens_label_key",
        js: "tokens_label_key",
        typ: u(undefined, ""),
      },
      {
        json: "workload_label_key",
        js: "workload_label_key",
        typ: u(undefined, ""),
      },
      {
        json: "fairness_label_key",
        js: "fairness_label_key",
        typ: u(undefined, ""),
      },
      {
        json: "workloads",
        js: "workloads",
        typ: u(undefined, a(r("SchedulerWorkload"))),
      },
    ],
    false,
  ),
  FluffySelector: o(
    [
      { json: "agent_group", js: "agent_group", typ: u(undefined, "") },
      { json: "control_point", js: "control_point", typ: "" },
      {
        json: "label_matcher",
        js: "label_matcher",
        typ: u(
          undefined,
          r(
            "AllowsToDefineRulesWhetherAMapOfLabelsConceptsFlowLabelMdShouldBeConsideredAMatchOrNot",
          ),
        ),
      },
      { json: "service", js: "service", typ: u(undefined, "") },
    ],
    false,
  ),
  Resources: o(
    [
      {
        json: "flow_control",
        js: "flow_control",
        typ: u(undefined, r("FlowControlResources")),
      },
      {
        json: "infra_meters",
        js: "infra_meters",
        typ: u(undefined, m(r("InfraMeter"))),
      },
      {
        json: "telemetry_collectors",
        js: "telemetry_collectors",
        typ: u(
          undefined,
          a(
            r(
              "TelemetryCollectorDefinesTheTelemetryConfigurationToBeSyncedWithTheAgentsDeprecatedV300UseInfraMeterInsteadItConsistsOfTwoPartsAgentGroupAgentGroupToSyncTelemetryConfigurationWithInfraMetersOTelCompatibleMetricsPipelines",
            ),
          ),
        ),
      },
    ],
    false,
  ),
  FlowControlResources: o(
    [
      {
        json: "classifiers",
        js: "classifiers",
        typ: u(
          undefined,
          a(r("SetOfClassificationRulesSharingACommonSelector")),
        ),
      },
      {
        json: "flux_meters",
        js: "flux_meters",
        typ: u(undefined, m(r("FluxMeter"))),
      },
    ],
    false,
  ),
  SetOfClassificationRulesSharingACommonSelector: o(
    [
      { json: "rego", js: "rego", typ: u(undefined, r("Rego")) },
      {
        json: "rules",
        js: "rules",
        typ: u(undefined, m(r("RuleDescribesASingleClassificationRule"))),
      },
      { json: "selectors", js: "selectors", typ: a(r("ClassifierSelector")) },
    ],
    false,
  ),
  Rego: o(
    [
      { json: "labels", js: "labels", typ: m(r("RegoLabelProperties")) },
      { json: "module", js: "module", typ: "" },
    ],
    false,
  ),
  RegoLabelProperties: o(
    [{ json: "telemetry", js: "telemetry", typ: u(undefined, true) }],
    false,
  ),
  RuleDescribesASingleClassificationRule: o(
    [
      {
        json: "extractor",
        js: "extractor",
        typ: u(
          undefined,
          r(
            "DefinesAHighLevelWayToSpecifyHowToExtractAFlowLabelValueGivenHTTPRequestMetadataWithoutANeedToWriteRegoCode",
          ),
        ),
      },
      { json: "telemetry", js: "telemetry", typ: u(undefined, true) },
    ],
    false,
  ),
  DefinesAHighLevelWayToSpecifyHowToExtractAFlowLabelValueGivenHTTPRequestMetadataWithoutANeedToWriteRegoCode:
    o(
      [
        {
          json: "address",
          js: "address",
          typ: u(
            undefined,
            r("DisplayAnAddressEXTAuthzAddressAsASingleStringForExampleIPPort"),
          ),
        },
        { json: "from", js: "from", typ: u(undefined, "") },
        {
          json: "json",
          js: "json",
          typ: u(undefined, r("ParseJSONAndExtractOneOfTheFields")),
        },
        {
          json: "jwt",
          js: "jwt",
          typ: u(undefined, r("ParseTheAttributeAsJWTAndReadThePayload")),
        },
        {
          json: "path_templates",
          js: "path_templates",
          typ: u(undefined, r("MatchesHTTPPathToGivenPathTemplates")),
        },
      ],
      false,
    ),
  DisplayAnAddressEXTAuthzAddressAsASingleStringForExampleIPPort: o(
    [{ json: "from", js: "from", typ: "" }],
    false,
  ),
  ParseJSONAndExtractOneOfTheFields: o(
    [
      { json: "from", js: "from", typ: "" },
      { json: "pointer", js: "pointer", typ: u(undefined, "") },
    ],
    false,
  ),
  ParseTheAttributeAsJWTAndReadThePayload: o(
    [
      { json: "from", js: "from", typ: "" },
      { json: "json_pointer", js: "json_pointer", typ: u(undefined, "") },
    ],
    false,
  ),
  MatchesHTTPPathToGivenPathTemplates: o(
    [
      {
        json: "template_values",
        js: "template_values",
        typ: u(undefined, m("")),
      },
    ],
    false,
  ),
  ClassifierSelector: o(
    [
      { json: "agent_group", js: "agent_group", typ: u(undefined, "") },
      { json: "control_point", js: "control_point", typ: "" },
      {
        json: "label_matcher",
        js: "label_matcher",
        typ: u(
          undefined,
          r(
            "AllowsToDefineRulesWhetherAMapOfLabelsConceptsFlowLabelMdShouldBeConsideredAMatchOrNot",
          ),
        ),
      },
      { json: "service", js: "service", typ: u(undefined, "") },
    ],
    false,
  ),
  FluxMeter: o(
    [
      { json: "attribute_key", js: "attribute_key", typ: u(undefined, "") },
      {
        json: "exponential_buckets",
        js: "exponential_buckets",
        typ: u(undefined, r("FluxMeterExponentialBuckets")),
      },
      {
        json: "exponential_buckets_range",
        js: "exponential_buckets_range",
        typ: u(undefined, r("FluxMeterExponentialBucketsRange")),
      },
      {
        json: "linear_buckets",
        js: "linear_buckets",
        typ: u(undefined, r("FluxMeterLinearBuckets")),
      },
      { json: "selectors", js: "selectors", typ: a(r("FluxMeterSelector")) },
      {
        json: "static_buckets",
        js: "static_buckets",
        typ: u(undefined, r("FluxMeterStaticBuckets")),
      },
    ],
    false,
  ),
  FluxMeterExponentialBuckets: o(
    [
      { json: "count", js: "count", typ: u(undefined, 0) },
      { json: "factor", js: "factor", typ: u(undefined, 3.14) },
      { json: "start", js: "start", typ: u(undefined, 3.14) },
    ],
    false,
  ),
  FluxMeterExponentialBucketsRange: o(
    [
      { json: "count", js: "count", typ: u(undefined, 0) },
      { json: "max", js: "max", typ: u(undefined, 3.14) },
      { json: "min", js: "min", typ: u(undefined, 3.14) },
    ],
    false,
  ),
  FluxMeterLinearBuckets: o(
    [
      { json: "count", js: "count", typ: u(undefined, 0) },
      { json: "start", js: "start", typ: u(undefined, 3.14) },
      { json: "width", js: "width", typ: u(undefined, 3.14) },
    ],
    false,
  ),
  FluxMeterSelector: o(
    [
      { json: "agent_group", js: "agent_group", typ: u(undefined, "") },
      { json: "control_point", js: "control_point", typ: "" },
      {
        json: "label_matcher",
        js: "label_matcher",
        typ: u(
          undefined,
          r(
            "AllowsToDefineRulesWhetherAMapOfLabelsConceptsFlowLabelMdShouldBeConsideredAMatchOrNot",
          ),
        ),
      },
      { json: "service", js: "service", typ: u(undefined, "") },
    ],
    false,
  ),
  FluxMeterStaticBuckets: o(
    [{ json: "buckets", js: "buckets", typ: u(undefined, a(3.14)) }],
    false,
  ),
  InfraMeter: o(
    [
      { json: "agent_group", js: "agent_group", typ: u(undefined, "") },
      {
        json: "per_agent_group",
        js: "per_agent_group",
        typ: u(undefined, true),
      },
      {
        json: "pipeline",
        js: "pipeline",
        typ: u(undefined, r("InfraMeterMetricsPipeline")),
      },
      { json: "processors", js: "processors", typ: u(undefined, m(m("any"))) },
      { json: "receivers", js: "receivers", typ: u(undefined, m(m("any"))) },
    ],
    false,
  ),
  InfraMeterMetricsPipeline: o(
    [
      { json: "processors", js: "processors", typ: u(undefined, a("")) },
      { json: "receivers", js: "receivers", typ: u(undefined, a("")) },
    ],
    false,
  ),
  TelemetryCollectorDefinesTheTelemetryConfigurationToBeSyncedWithTheAgentsDeprecatedV300UseInfraMeterInsteadItConsistsOfTwoPartsAgentGroupAgentGroupToSyncTelemetryConfigurationWithInfraMetersOTelCompatibleMetricsPipelines:
    o(
      [
        { json: "agent_group", js: "agent_group", typ: u(undefined, "") },
        {
          json: "infra_meters",
          js: "infra_meters",
          typ: u(undefined, m(r("InfraMeter"))),
        },
      ],
      false,
    ),
  SpecialValue: ["+Inf", "NaN", "-Inf"],
  Severity: ["crit", "info", "warn"],
  ArithmeticCombinatorOperator: [
    "add",
    "div",
    "lshift",
    "mul",
    "rshift",
    "sub",
    "xor",
  ],
  DeciderOperator: ["eq", "gt", "gte", "lte", "lt", "neq"],
  HTTPResponseStatusCode: [
    "Accepted",
    "AlreadyReported",
    "BadGateway",
    "BadRequest",
    "Conflict",
    "Continue",
    "Created",
    "Empty",
    "ExpectationFailed",
    "FailedDependency",
    "Forbidden",
    "Found",
    "GatewayTimeout",
    "Gone",
    "HTTPVersionNotSupported",
    "IMUsed",
    "InsufficientStorage",
    "InternalServerError",
    "LengthRequired",
    "Locked",
    "LoopDetected",
    "MethodNotAllowed",
    "MisdirectedRequest",
    "MovedPermanently",
    "MultiStatus",
    "MultipleChoices",
    "NetworkAuthenticationRequired",
    "NoContent",
    "NonAuthoritativeInformation",
    "NotAcceptable",
    "NotExtended",
    "NotFound",
    "NotImplemented",
    "NotModified",
    "OK",
    "PartialContent",
    "PayloadTooLarge",
    "PaymentRequired",
    "PermanentRedirect",
    "PreconditionFailed",
    "PreconditionRequired",
    "ProxyAuthenticationRequired",
    "RangeNotSatisfiable",
    "RequestHeaderFieldsTooLarge",
    "RequestTimeout",
    "ResetContent",
    "SeeOther",
    "ServiceUnavailable",
    "TemporaryRedirect",
    "TooManyRequests",
    "URITooLong",
    "Unauthorized",
    "UnprocessableEntity",
    "UnsupportedMediaType",
    "UpgradeRequired",
    "UseProxy",
    "VariantAlsoNegotiates",
  ],
  MatchExpressionOperator: ["DoesNotExist", "Exists", "In", "NotIn"],
  OverloadConditionDeterminesTheCriteriaToDetermineOverloadStateTheDefaultConditionIsGtThatIsWhenTheSignalIsGreaterThanTheSetpointTheConditionMustBeOneOfValeOffGtGreaterThanLtLessThanGteGreaterThanOrEqualToLTELessThanOrEqualToValeOn:
    ["gt", "gte", "lte", "lt"],
  UnaryOperatorOperator: [
    "abs",
    "acos",
    "acosh",
    "asin",
    "asinh",
    "atan",
    "atanh",
    "cbrt",
    "ceil",
    "cos",
    "cosh",
    "erf",
    "erfc",
    "erfcinv",
    "erfinv",
    "exp",
    "exp2",
    "expm1",
    "floor",
    "gamma",
    "j0",
    "j1",
    "lgamma",
    "log",
    "log10",
    "log1p",
    "log2",
    "round",
    "roundtoeven",
    "sin",
    "sinh",
    "sqrt",
    "tan",
    "tanh",
    "trunc",
    "y0",
    "y1",
  ],
};
