import { Box, styled, type PaletteColor } from "@mui/material";

import { ALERT_SEVERITY_COLOR_MAP } from "../../../../../../../../../app-theme-provider/theme/palette";
import type { FnTheme } from "../../../../../../../../../app-theme-provider/types";
import type { AlertsLogType } from "../alerts-table/types";

export type AlertTileStyledExtraProps = {
  severity: AlertsLogType["severity"];
};

export const AlertTileStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "severity",
})<AlertTileStyledExtraProps>(({ theme, severity }) => {
  const { palette } = theme as unknown as FnTheme;
  const severityColor = ALERT_SEVERITY_COLOR_MAP[severity] || "primary";
  const severityPalette = palette[severityColor] as Pick<
    PaletteColor,
    "main" | "contrastText"
  >;

  const backgroundColor = severityPalette.main;
  const color = severityPalette.contrastText;

  return {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "20%",
    height: "100%",
    backgroundColor,
    color,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(2),
    "& > .__titleOfTile": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      gap: 5,
      alignItems: "center",
      fontSize: 16,
      padding: theme.spacing(2),
    },
    "& > .__bodyOfTile": {
      fontSize: 34,
      fontWeight: "400",
      width: "100%",
      marginTop: 25,
      paddingLeft: 25,
    },
  };
});

export const TileRowWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  gap: 25,
  paddingBottom: 20,
  height: 120,
});
