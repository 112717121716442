import { Select, styled } from "@mui/material";

export const StyledSelect = styled(Select)(({ theme }) => {
  const {
    palette: {
      primary: { main },
    },
  } = theme;

  return {
    color: main,
    "& fieldset": {
      borderColor: main,
    },
    "&:hover fieldset": {
      borderColor: main,
    },
    "&.Mui-focused fieldset": {
      borderColor: main,
    },
    "&.Mui-disabled fieldset": {
      borderColor: main,
    },
  };
});
