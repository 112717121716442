import { Box, CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { noop } from "lodash";
import React, { type FC, useContext, useCallback } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useSetRecoilState, useRecoilValue } from "recoil";

import { PrimaryButton } from "#shared/components/buttons";
import { PasswordInput, TextInput } from "#shared/components/inputs";
import { EMAIL_PATTERN, sharedEnTexts } from "#shared/consts";
import { FirebaseContext } from "#shared/contexts/firebase";
import { firebaseUserState } from "#shared/recoil";
import { LoggerService } from "#shared/services";
import type { Texts } from "#shared/types";

import type { FormValues, SignInFormField } from "./types";

export interface EmailPasswordSignInFormProps {
  onChange?: (field: SignInFormField) => (event: Event) => void;
  texts?: Texts<Text>;
}

type Text =
  | "unknownError"
  | "invalidClaims"
  | "email"
  | "password"
  | "generalError";

export const StyledTextInput = styled(TextInput)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const enTexts: Required<EmailPasswordSignInFormProps["texts"]> = {
  unknownError: sharedEnTexts.error,
  invalidClaims: "Invalid email or password.",
  email: "Email address",
  password: "Password",
  generalError: sharedEnTexts.error,
};

export const EmailPasswordSignInForm: FC<EmailPasswordSignInFormProps> = ({
  onChange = () => noop,
  texts = enTexts,
}) => {
  const { signInWithEmailAndPassword } = useContext(FirebaseContext);
  const {
    register,
    handleSubmit,
    formState: { isSubmitted, isValid, errors },
  } = useForm<FormValues>({ mode: "onChange" });

  // const isIdle = useRecoilValue(firebaseUserState.isIdle);
  // const isSuccess = useRecoilValue(firebaseUserState.isSuccess);
  const isLoading = useRecoilValue(firebaseUserState.isLoading);
  const error = useRecoilValue(firebaseUserState.error);
  // const resetHttpState = useResetRecoilState(firebaseUserState.state);
  const setPartialUser = useSetRecoilState(firebaseUserState.setPartialState);

  const onSubmit = useCallback<SubmitHandler<FormValues>>(
    async (formValues) => {
      if (!signInWithEmailAndPassword?.signIn) {
        LoggerService.verbose(
          null,
          "Cannot submit password.",
          "Missing signIn method.",
        );

        return;
      }

      LoggerService.verbose("Signing in with email and password.");

      signInWithEmailAndPassword.signIn(
        formValues.email,
        formValues.password,
        setPartialUser,
      );
    },
    [signInWithEmailAndPassword, setPartialUser],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledTextInput
        id="email"
        fullWidth
        label={texts.email}
        {...register("email", {
          required: "Required",
          pattern: EMAIL_PATTERN,
          onChange: onChange("email"),
        })}
        error={!!errors.email}
        helperText={errors.email?.message}
        disabled={!!isLoading}
      />
      <PasswordInput
        id="password"
        fullWidth
        label={texts.password}
        {...register("password", {
          required: "Required",
          onChange: onChange("password"),
        })}
        error={!!errors.password}
        helperText={errors.password?.message}
        disabled={!!isLoading}
      />
      {error ? (
        <Box marginTop={1} marginBottom={2}>
          <Typography color="error">
            {error instanceof Error ? error.message : String(error)}
          </Typography>
        </Box>
      ) : null}
      <Box marginTop={2.5} width="100%">
        <PrimaryButton
          fullWidth
          size="large"
          type="submit"
          disabled={
            isLoading ||
            (!isValid && isSubmitted) ||
            !!errors.email ||
            !!errors.password
          }
        >
          {isLoading ? <CircularProgress size="1.5rem" /> : "Sign in"}
        </PrimaryButton>
      </Box>
    </form>
  );
};
