import { gql } from "graphql-request";

import type { GetProjectsByIdQuery } from "#shared/generated/graphql";
import { useGqlMutation, useGqlQuery } from "#shared/utils";

import { QUERY_KEYS } from "#organization/pages/consts";

export const ProjectsByIdQuery = gql`
  query getProjectsById($where: ProjectBoolExp) {
    projects(where: $where) {
      edges {
        node {
          id
          name
          description
          status
          region
          cloud_provider
          userGroupProjectAssignments {
            edges {
              node {
                id
                permissions
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetProjectById = (projectId: string) => {
  const findBy = { where: { id: { eq: projectId } } };
  const { isError, isLoading, data, refetch } =
    useGqlQuery<GetProjectsByIdQuery>(
      [QUERY_KEYS.PROJECT_BY_ID, findBy],
      ProjectsByIdQuery,
      findBy,
    );

  return {
    error: isError,
    loading: isLoading,
    data: data?.projects.edges[0].node,
    refetch,
  };
};

// mutations

export const DeleteProjectById = gql`
  mutation removeProject($input: DeleteProjectInput!) {
    deleteProject(input: $input) {
      project {
        name
      }
    }
  }
`;

export const useDeleteProjectById = () => {
  const { mutate, data, isError } = useGqlMutation<unknown, unknown, {}>(
    DeleteProjectById,
  );

  return {
    remove: mutate,
    data,
    error: isError,
  };
};
