import { useCallback, useState } from "react";
import { useRecoilValue } from "recoil";

import { userNewState } from "#shared/recoil";
import { LoggerService } from "#shared/services";
import type { StandardResponse } from "#shared/types";
import {
  httpClient,
  type ResponseWithTypedJson,
} from "#shared/utils/http-client";

import { API_URLS } from "#organization/pages/consts";
import { useOrganizationState } from "#organization/recoil/organization";

export type ContactSupportBody = {
  subject: string;
  message: string;
  organization: { id: string; domain_name: string };
  user: {
    name: string;
    id: string;
    email: string;
  };
};

type SendContactSupportMessage = (
  partialBody: Pick<ContactSupportBody, "message" | "subject">,
) => Promise<null | ResponseWithTypedJson<StandardResponse>>;

export function useSendContactSupportMessage() {
  const organization = useOrganizationState();
  const { data: user } = useRecoilValue(userNewState.state);

  const [isPending, setIsPending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const reset = useCallback(() => {
    setIsPending(false);
    setIsSuccess(false);
    setError(null);
  }, []);

  const sendMessage = useCallback<SendContactSupportMessage>(
    async (partialBody) => {
      if (!user?.id || !user?.email || !user.name) {
        LoggerService.warn(
          "Failed to send contact support request. Missing user.",
        );

        setError("Failed to send contact support request. Missing user.");

        return null;
      }

      const body: ContactSupportBody = {
        ...partialBody,
        organization: {
          id: organization.id,
          domain_name: organization.subdomain,
        },
        user: { id: user.id, email: user.email, name: user.name },
      };

      setIsPending(true);
      setError(null);
      setIsSuccess(false);

      try {
        const response = await httpClient.post<
          Partial<StandardResponse>,
          false,
          ContactSupportBody
        >({
          url: API_URLS.CONTACT_SUPPORT.POST,
          body,
        });

        setIsPending(false);

        if (!response.ok) {
          setError("Something went wrong.");
        } else {
          setIsSuccess(true);
        }

        return response;
      } catch (err) {
        setIsPending(false);

        setError(
          err instanceof Error
            ? err.message || "Something went wrong."
            : String(err),
        );

        return null;
      }
    },
    [
      organization.id,
      organization.subdomain,
      user?.id,
      user?.email,
      user?.name,
    ],
  );

  return {
    sendMessage,
    isPending,
    isSuccess,
    error,
    reset,
  };
}
