import { Box } from "@mui/system";
import React, { type FC, type ReactNode } from "react";

import { Chip } from "#shared/components/chip";

interface ArchivedStatusProps {
  status: string | undefined;
  children: ReactNode;
}

export const ArchivedStatus: FC<ArchivedStatusProps> = ({
  status,
  children,
}) => (
  <Box display="flex" columnGap={1}>
    {children}
    {status?.includes("archived") && (
      <Chip label="archived" variant="outlined" />
    )}
  </Box>
);
