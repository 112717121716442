import { Typography } from "@mui/material";
import { capitalize } from "lodash";
import React, { type FC } from "react";

import { BreadcrumbButton } from "#shared/components/breadcrumbs";
import type { Texts } from "#shared/types";

import {
  FlyoutMenuContentContainer,
  FlyoutMenuHeaderContainer,
} from "#organization/components/flyout-menu/styled";
import { FlyoutMenuBreadcrumbAppender } from "#organization/recoil/breadcrumbs";

import { CacheDashboard } from "./cache-dashboard";
import type { CacheDashboardConfig } from "./types";

export interface CacheFlyoutMenuWrapperProps {
  config: CacheDashboardConfig;
  texts?: Texts<Text>;
  hideFlyoutMenu: () => void;
}

type Text = "cache" | "cacheDashboard";

export const enTexts: Required<CacheFlyoutMenuWrapperProps>["texts"] = {
  cache: "Cache",
  cacheDashboard: "Cache Dashboard",
};

const HEADER_HEIGHT = "90px";

export const CacheFlyoutMenuWrapper: FC<CacheFlyoutMenuWrapperProps> = ({
  config,
  texts = enTexts,
  hideFlyoutMenu,
}) => {
  const { type } = config;

  return (
    <>
      <FlyoutMenuHeaderContainer
        sx={{
          height: HEADER_HEIGHT,
          justifyContent: "space-between",
        }}
      >
        <FlyoutMenuBreadcrumbAppender>
          <BreadcrumbButton onClick={hideFlyoutMenu}>
            {texts.cache}
          </BreadcrumbButton>
        </FlyoutMenuBreadcrumbAppender>
        <FlyoutMenuBreadcrumbAppender>
          <BreadcrumbButton>{type}</BreadcrumbButton>
        </FlyoutMenuBreadcrumbAppender>

        <Typography component="h1" variant="h1">
          {capitalize(type)} {texts.cacheDashboard}
        </Typography>
      </FlyoutMenuHeaderContainer>

      <FlyoutMenuContentContainer>
        <CacheDashboard {...{ vars: config }} />
      </FlyoutMenuContentContainer>
    </>
  );
};
