import {
  type CSSProperties,
  type RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

export type UseTableHeadStickyCSS = () => {
  headCSS: CSSProperties;
  tableHeadRef: RefObject<HTMLTableSectionElement>;
};

export const useTableHeadStickyCSS: UseTableHeadStickyCSS = () => {
  const [topState, setTop] = useState<number | undefined>();
  const [leftState, setLeft] = useState<number | undefined>();
  const tableHeadRef = useRef<HTMLTableSectionElement>(null);

  const getPosition = (): void => {
    const dimensions = tableHeadRef.current?.getClientRects()[0];

    if (!dimensions) {
      return;
    }

    const { x, y, top, left } = dimensions;
    setLeft(x / left);
    setTop(y / top);
  };

  useEffect(() => {
    getPosition();
  }, []);

  const headCSS: CSSProperties = useMemo(
    () => ({
      position: "sticky",
      top: topState,
      left: leftState,
    }),
    [leftState, topState],
  );

  return { headCSS, tableHeadRef };
};
