import type { PaletteMode } from "@mui/material";
import { useEffect } from "react";
import { atom, useRecoilState, useRecoilValue } from "recoil";

import { ATOM_KEYS } from "#shared/consts";

export type ThemeState = { mode: PaletteMode };

export const themeState = atom<ThemeState>({
  key: ATOM_KEYS.theme,
  default: { mode: "light" },
});

export const useThemeState = () => useRecoilValue(themeState);

export const useFlipThemeState = () => {
  const [theme, setTheme] = useRecoilState(themeState);

  useEffect(() => {
    const mode = window.localStorage.getItem("theme") as PaletteMode;

    if (mode) {
      setTheme({ mode });
    }
  }, [setTheme]);

  const flip = () => {
    const mode = theme.mode === "light" ? "dark" : "light";

    setTheme({ mode });
    window.localStorage.setItem("theme", mode);
  };

  return {
    flip,
    theme,
  };
};
