import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  Button,
  ButtonGroup,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { type FormEvent, type FC, useRef } from "react";
import { useRecoilValue } from "recoil";

import { useAlert } from "#shared/components/alerts-provider";
import { SecondaryButton } from "#shared/components/buttons";
import {
  DialogLayout,
  type DialogControl,
} from "#shared/components/layouts/dialog";
import { userNewState } from "#shared/recoil";
import { LoggerService } from "#shared/services/logger";

import {
  DropdownSearchBox,
  useDropdownSearchBox,
} from "#organization/components/layouts/dropdown-search-box";
import { useOrganizationState } from "#organization/recoil/organization";

import { useOrganizationMembers, useUpdateUserRole } from "./hooks";

import { useOrganizationOwners } from "../../hooks";

type ComponentProps = {
  dialogControlTransferOwnership: DialogControl;
};

export const TransferOwnershipDialog: FC<ComponentProps> = ({
  dialogControlTransferOwnership,
}) => {
  const { data: user } = useRecoilValue(userNewState.state);

  const organization = useOrganizationState();
  const membersList = useOrganizationMembers();
  const dropdownControl = useDropdownSearchBox();
  const ownersList = useOrganizationOwners();
  const changeRole = useUpdateUserRole();
  const { addAlert } = useAlert();

  const anchorRef = useRef<HTMLDivElement>(null);

  const searchUser = (event: FormEvent<HTMLInputElement>) => {
    membersList.setSearch(event.currentTarget?.value);
  };

  const handleChangeUserRole = (id: string | undefined, userRole: string) => {
    changeRole.change(
      { id, role: userRole },
      {
        onSuccess: () => {
          ownersList.refetch();

          addAlert({
            type: "success",
            message: `Successfully ${
              userRole === "owner" ? "added" : "deleted"
            } Owner role!`,
          });
        },
        onError: (error) => {
          LoggerService.error(error);

          addAlert({
            type: "error",
            message: `Could not ${
              userRole === "owner" ? "added" : "deleted"
            } Owner role`,
          });
        },
      },
    );
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <DialogLayout
        dialogTitle={
          <Box>
            <Typography
              sx={{
                color: "primary.main",
                fontWeight: 700,
              }}
              variant="h5"
            >
              {organization.name}
              <Typography component="span" sx={{ fontWeight: 400 }}>
                {" "}
                ({organization.subdomain})
              </Typography>
              <Typography component="span"> - owners</Typography>
            </Typography>
          </Box>
        }
        dialogContentText={
          <Typography color="text.primary" component="span">
            Below is the list of users with{" "}
            <Typography sx={{ fontWeight: 600 }} component="strong">
              organization owner
            </Typography>{" "}
            role for{" "}
            <Typography sx={{ fontWeight: 600 }} component="strong">
              {organization.name}{" "}
            </Typography>
          </Typography>
        }
        onCloseDialog={dialogControlTransferOwnership.close}
        openDialog={dialogControlTransferOwnership.isOpen}
        onClickDialogClose={dialogControlTransferOwnership.close}
        buttonConfirmText="Confirm action"
        buttonConfirmVariant="contained"
        buttonConfirmColor="primary"
        buttonCancelVariant="outlined"
        buttonCancelColor="primary"
        dialogAction={false}
      >
        <Box pt={3} pb={2}>
          {ownersList?.data?.map((row) => (
            <Box
              key={row.node.user.name}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                py: 1,
              }}
            >
              <Typography
                sx={{
                  minWidth: {
                    sm: 160,
                    xs: 100,
                  },
                  overflow: "hidden",
                }}
              >
                {row.node.user.name}
                {row.node.user.id === user.id && (
                  <Typography component="span"> (you)</Typography>
                )}
              </Typography>
              <Typography
                sx={{
                  border: `1px solid black`,
                  borderColor: "primary.100",
                  maxHeight: "37px",
                  overflow: "hidden",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 1,
                  mx: 2,
                  px: 2,
                  py: 1,
                  color: "primary.500",
                  flexGrow: 1,
                }}
              >
                {row.node.user.email}
              </Typography>
              <SecondaryButton
                onClick={() =>
                  handleChangeUserRole(row.node.id, "user_group_member")
                }
                disabled={row.node.user.id === user.id}
              >
                Revert
              </SecondaryButton>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            height: {
              sm: 160,
              xs: 180,
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              bottom: 80,
              left: 0,
            }}
          >
            <Box
              sx={{
                maxHeight: 120,
                overflowY: "hidden",
                p: 0,
                m: 0,
                backgroundColor: "grey.700",
              }}
            >
              <Box
                sx={{
                  px: 3,
                  py: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: {
                    xs: "flex-start",
                    sm: "center",
                  },
                }}
              >
                <Typography>
                  If you want to assign{" "}
                  <Typography component="span" sx={{ fontWeight: 600 }}>
                    {" "}
                    organization owner
                  </Typography>{" "}
                  role to another user, you can choose from the users from your
                  organization.
                </Typography>
                <Box pl={2}>
                  <ButtonGroup variant="contained" ref={anchorRef}>
                    <Button onClick={dropdownControl.togglePopper}>
                      Assign
                    </Button>
                    <Button size="small" onClick={dropdownControl.togglePopper}>
                      <ArrowDropDownIcon />
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: 24,
              right: 24,
            }}
          >
            <SecondaryButton onClick={dialogControlTransferOwnership.close}>
              Close
            </SecondaryButton>
          </Box>
        </Box>
      </DialogLayout>

      <DropdownSearchBox
        idDropdown={dropdownControl.id}
        isOpen={dropdownControl.open}
        anchorEl={dropdownControl.dropdownElement}
        dropdownHeaderName="Users in organization"
        inputOnChange={searchUser}
        defaultInputValue={membersList.search}
        onClickAwayHandler={dropdownControl.closePopper}
      >
        <List disablePadding aria-label="dropdown list">
          {membersList.users?.map((member) => (
            <ListItem
              key={member.node.id}
              button
              onClick={() =>
                handleChangeUserRole(
                  membersList?.organizationMemberId?.node.id,
                  "owner",
                )
              }
            >
              <ListItemText primary={member.node.email} />
            </ListItem>
          ))}
        </List>
      </DropdownSearchBox>
    </>
  );
};
