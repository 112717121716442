import { get, isEqual, isString } from "lodash";
import { useRef, useState, useEffect } from "react";

import type { Policy } from "#shared/generated/graphql";
import type { AnyObject, Texts } from "#shared/types";

import type { UiData } from "#organization/pages/authenticated/policies/policy/types";
import type { WithUiData } from "#organization/pages/types";

import type { NodeName } from "../../../../../../../../../types";

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export type Accessor<D = any, R extends string = string> =
  | R
  | ((data: D) => R | undefined);

type Text = "emptyName" | "emptyId" | "emptyComponentName";

export type MapToListItem<D extends AnyObject = AnyObject> = (
  data: D,
) => WithUiData<D, UiData>;

const enTexts: Texts<Text> = {
  emptyName: "undefined",
  emptyId: "undefined",
  emptyComponentName: "Other" as NodeName,
};

export function mapToListItem<D extends AnyObject = AnyObject>(
  nameAccessor: Accessor<D>,
  idAccessor: Accessor<D>,
  nodeNameAccessor: Accessor<D>,
): MapToListItem<D> {
  return (data) => {
    const name = isString(nameAccessor)
      ? get(data, nameAccessor)
      : nameAccessor(data);

    const id = isString(idAccessor) ? get(data, idAccessor) : idAccessor(data);

    const componentName = isString(nodeNameAccessor)
      ? get(data, nodeNameAccessor)
      : nodeNameAccessor(data);

    const uiData: UiData = {
      name: name || enTexts.emptyName,
      id: id || enTexts.emptyId,
      componentName:
        (componentName as NodeName) || (enTexts.emptyComponentName as NodeName),
    };

    const mappedData: WithUiData<D, UiData> = {
      ...data,
      uiData,
    };

    return mappedData;
  };
}

export function useIsPolicyChanged(policy: Policy) {
  const prevPolicyRef = useRef(policy);

  const [isPolicyChanged, setIsPolicyChanged] = useState(true);

  useEffect(() => {
    const prevPolicy = prevPolicyRef.current;
    prevPolicyRef.current = policy;
    setIsPolicyChanged(!isEqual(prevPolicy, policy));
  }, [policy]);

  return isPolicyChanged;
}
