import { ORGANIZATION_SETTINGS_ROUTE } from "../routes.definitions";

export const MEMBERS_SETTINGS_ROUTE = {
  TITLE: "Users",
  KEY: "members",
  ABSOLUTE_PATH: `${ORGANIZATION_SETTINGS_ROUTE.ABSOLUTE_PATH}/members`,
  PARENT_PATH: "members/*",
  CHILD_KEY: "memberId",
  CHILD_PATH: ":memberId",
};

export type MemberIdParam = {
  memberId: string;
};
