import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { pick } from "lodash";
import React, {
  type CSSProperties,
  type Dispatch,
  type FC,
  type MouseEvent,
  type SetStateAction,
  useCallback,
  useState,
} from "react";
import type { FieldValues } from "react-hook-form";

import { useAlert } from "#shared/components/alerts-provider";
import { useDeleteBox } from "#shared/components/pop-up/delete-confirm-box";
import { LoggerService } from "#shared/services";

import { FIELD_ARRAY_NAME } from "#organization/pages/authenticated/alert-manager/components/add-edit-channel";
import {
  useDataCenterContext,
  useDeleteAlertsChannel,
} from "#organization/pages/authenticated/alert-manager/data-center";
import { RowWrapper } from "#organization/pages/authenticated/alert-manager/styled";

import type { ChannelData } from "./types";

import { SilentChannelIcon } from "../../../components/silent-channel-icon";
import { SilentChannelBox } from "../silent-channel-box";

export interface ActionButtonProps {
  action: Dispatch<SetStateAction<boolean>>;
  setEditFormValues: Dispatch<SetStateAction<FieldValues>>;
  row: ChannelData;
}

export const ActionsButtons: FC<ActionButtonProps> = ({
  action,
  setEditFormValues,
  row,
}) => {
  const rowPayload = useCallback(
    () => ({
      name: row.node.name,
      id: row.node.id,
      [FIELD_ARRAY_NAME]: [
        ...row.node.alertsEmailNotifiers.edges.map((d) => ({
          ...d.node,
          notifierType: "createEmailNotifier",
        })),
        ...row.node.alertsSlackNotifiers.edges.map((d) => ({
          ...d.node,
          notifierType: "createSlackNotifier",
        })),
        ...row.node.alertsWebhookNotifiers.edges.map((d) => ({
          ...d.node,
          notifierType: "createWebhookNotifier",
        })),
      ],
    }),
    [row],
  );

  const onClickEdit = () => {
    setEditFormValues(rowPayload());
    action(true);
  };

  return (
    <RowWrapper sx={rowWrapperSx}>
      <SilentChannelButton {...pick(row.node, "id", "name", "silencedUntil")} />
      <EditOutlinedIcon sx={iconSx} onClick={onClickEdit} />
      <DeleteChannelButton channelID={row.node.id} />
    </RowWrapper>
  );
};

const iconSx: CSSProperties = {
  cursor: "pointer",
  opacity: 0.5,
};
const rowWrapperSx: CSSProperties = {
  position: "relative",
  justifyContent: "flex-start",
  gap: 0.5,
};

export interface DeleteChannelButtonProps {
  channelID: string;
}

export const DeleteChannelButton: FC<DeleteChannelButtonProps> = ({
  channelID,
}) => {
  const {
    reFetchFlag: { setFetchFlag },
  } = useDataCenterContext();
  const { deleteAlertsChannel } = useDeleteAlertsChannel();
  const { addAlert } = useAlert();
  const { addDeleteBox } = useDeleteBox();

  const onClick = () => {
    deleteAlertsChannel(
      { id: channelID },
      {
        onError: (err) => {
          addAlert({
            type: "error",
            message: "Error deleting channel",
          });

          LoggerService.error(err);
        },
        onSuccess: () => {
          addAlert({
            type: "success",
            message: "Channel deleted successfully",
          });
          setFetchFlag(true);
        },
      },
    );
  };

  return (
    <DeleteOutlineIcon
      sx={iconSx}
      onClick={() => {
        addDeleteBox({
          message: "Are you sure you want to remove this channel?",
          callback: (status: boolean) => {
            if (status) {
              onClick();
            }
          },
        });
      }}
    />
  );
};

export interface SilentChannelButtonProps {
  id: string;
  name: string;
  silencedUntil: string | undefined;
}

export const SilentChannelButton: FC<SilentChannelButtonProps> = ({
  id,
  name,
  silencedUntil,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | SVGSVGElement>(null);

  const onClick = (event: MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenDialog(true);
  };

  return (
    <>
      <SilentChannelIcon silencedUntil={silencedUntil} onClick={onClick} />
      <SilentChannelBox
        alertChannelName={name}
        id={id}
        anchorEl={anchorEl}
        openCloseHook={[openDialog, setOpenDialog]}
        silencedUntil={silencedUntil}
      />
    </>
  );
};
