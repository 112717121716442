import { Box, Container } from "@mui/material";
import React, { type ReactElement } from "react";

import { ErrorLayout, ErrorText } from "#shared/components/layouts/error";
import { ExternalTextLink } from "#shared/components/text-link";
import { APP_PORTS } from "#shared/dev-config";
import { ENV } from "#shared/env";

/**
 * Ensures that the URL matches the expected global service's URL.
 * Only affects development environment.
 */
export const verifyUrlInDevMode = (children: ReactElement) => {
  if (!ENV.DEV) {
    return children;
  }

  const { hostname, port, host } = window.location;

  const portMatches = parseInt(port, 10) === APP_PORTS.global;
  const hostnameMatches = hostname === ENV.VITE_APP_DOMAIN;

  if (!portMatches || !hostnameMatches) {
    const correctUrl = getGlobalAppUrl(window.location.href);

    return (
      <ErrorLayout>
        <Container maxWidth="sm">
          <Box textAlign="center">
            <ErrorText gutterBottom>
              You are accessing the global application using an unexpected URL (
              <ExternalTextLink>{host}</ExternalTextLink>).
            </ErrorText>

            {!portMatches && (
              <ErrorText gutterBottom>The port does not match.</ErrorText>
            )}
            {!hostnameMatches && (
              <ErrorText gutterBottom>The hostname does not match.</ErrorText>
            )}

            <ErrorText>
              The global application is available at{" "}
              <ExternalTextLink href={correctUrl.href}>
                {correctUrl.host}
              </ExternalTextLink>
            </ErrorText>
          </Box>
        </Container>
      </ErrorLayout>
    );
  }

  return children;
};

function getGlobalAppUrl(href: string) {
  const globalAppUrl = new URL(href);

  // NOTE: Assumed that VITE_APP_DOMAIN is always received in env.js
  globalAppUrl.hostname = ENV.VITE_APP_DOMAIN as string;
  globalAppUrl.port = `${APP_PORTS.global}`;

  return globalAppUrl;
}
