import {
  Box,
  Paper,
  SvgIcon,
  Typography,
  styled,
  type BoxProps,
} from "@mui/material";
import React, { type FC, type ReactNode } from "react";

import { WidgetDropdown, type WidgetDropdownProps } from "./widget-dropdown";

export interface WidgetProps extends WidgetDropdownProps {
  title: string;
  subText?: string;
  boxProps?: BoxProps;
  Icon?: ReactNode;
  hideDropdown?: boolean;
}

export const Widget: FC<WidgetProps> = ({
  title,
  subText,
  boxProps,
  Icon = (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="none"
      sx={{
        width: 40,
        height: 40,
      }}
    >
      {widgetIcon}
    </SvgIcon>
  ),
  hideDropdown = false,
  ...widgetDropdownProps
}) => {
  const widget = (
    <WidgetTitle p={!hideDropdown ? 0 : 2}>
      {Icon}
      <Box display="grid" rowGap={0.5}>
        <Typography className="__title">{title}</Typography>
        {subText && <Typography variant="body2">{subText}</Typography>}
      </Box>
    </WidgetTitle>
  );

  return (
    <>
      {!hideDropdown ? (
        <WidgetDropdown
          {...{
            title: widget,
            ...widgetDropdownProps,
          }}
        />
      ) : (
        <WidgetCard component={Paper} {...boxProps}>
          {widget}
        </WidgetCard>
      )}
    </>
  );
};

export const WidgetTitle = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  gap: theme.spacing(1),
  "& .__title": {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 16,
  },
}));

export const WidgetCard = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: 400,
  minHeight: 120,
  height: "fit-content",
  boxShadow: theme.shadows[2],
  "&:hover": {
    boxShadow: theme.shadows[8],
  },
}));

const widgetIcon = (
  <>
    <path d="M32.27 20.36C32.0048 20.36 31.7504 20.4653 31.5629 20.6529C31.3754 20.8404 31.27 21.0948 31.27 21.36V32.27C31.27 32.7271 31.0891 33.1656 30.7668 33.4898C30.4446 33.8139 30.0071 33.9973 29.55 34H7.73C7.27118 34 6.83114 33.8177 6.50671 33.4933C6.18227 33.1688 6 32.7288 6 32.27V10.45C6 10.2233 6.04482 9.99878 6.13188 9.78945C6.21894 9.58011 6.34653 9.39006 6.50731 9.23021C6.66808 9.07036 6.85888 8.94387 7.06871 8.85803C7.27855 8.77218 7.50329 8.72866 7.73 8.72998H18.64C18.9052 8.72998 19.1596 8.62462 19.3471 8.43709C19.5346 8.24955 19.64 7.9952 19.64 7.72998C19.64 7.46476 19.5346 7.21041 19.3471 7.02287C19.1596 6.83534 18.9052 6.72998 18.64 6.72998H7.73C6.74247 6.72998 5.79525 7.12159 5.09602 7.81893C4.3968 8.51628 4.00265 9.46246 4 10.45V32.27C4 33.2592 4.39298 34.208 5.09249 34.9075C5.792 35.607 6.74074 36 7.73 36H29.55C30.5375 35.9973 31.4837 35.6032 32.1811 34.904C32.8784 34.2047 33.27 33.2575 33.27 32.27V21.36C33.27 21.0948 33.1647 20.8404 32.9771 20.6529C32.7896 20.4653 32.5352 20.36 32.27 20.36Z" />
    <path d="M34.6499 4H24.0899C23.8247 4 23.5704 4.10536 23.3828 4.29289C23.1953 4.48043 23.0899 4.73478 23.0899 5C23.0899 5.26522 23.1953 5.51957 23.3828 5.70711C23.5704 5.89464 23.8247 6 24.0899 6H32.5899L20.6599 17.93C20.4737 18.1174 20.3691 18.3708 20.3691 18.635C20.3691 18.8992 20.4737 19.1526 20.6599 19.34C20.8473 19.5263 21.1007 19.6308 21.3649 19.6308C21.6291 19.6308 21.8826 19.5263 22.0699 19.34L33.9999 7.41V15.91C33.9999 16.1752 34.1053 16.4296 34.2928 16.6171C34.4804 16.8046 34.7347 16.91 34.9999 16.91C35.2652 16.91 35.5195 16.8046 35.707 16.6171C35.8946 16.4296 35.9999 16.1752 35.9999 15.91V5.35C35.9973 4.99277 35.8542 4.65091 35.6016 4.3983C35.349 4.14569 35.0072 4.00262 34.6499 4Z" />
  </>
);
