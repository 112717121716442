import type { PaletteMode, ThemeOptions } from "@mui/material";

import { components } from "./components";
import { customTypography } from "./components/typography";
import { commonPalette, lightPalette, darkPalette } from "./palette";

import type { FnShape, FnTheme } from "../types";

export const SIDEBAR_WIDTH: Required<FnShape>["sidebar"]["width"] = {
  closed: 0,
  small: 60,
  large: 160,
};

export function getTheme(mode: PaletteMode) {
  const shape: FnTheme["shape"] = {
    topBarHeight: "64px",
    pageBarYM: 3,
    borderRadius: 4,
    circuitPaddingSpacing: 1,
    flyoutMenu: {
      topBarHeight: "65px",
    },
    policyView: {
      headerHeight: "185px",
      circuitBarHeight: "65px",
    },
    sidebar: {
      width: SIDEBAR_WIDTH,
    },
  };

  const zIndex: FnTheme["zIndex"] = {
    tooltip: 1500,
    snackbar: 1400,
    popover: 1000,
    modal: 1300,
    popoverInDialog: 2000, // sometime popovers (select, our custom poppers) are inside of modals so we need them to be above them
    drawer: 1200,
    appBar: 950,
    fab: 1050,
    speedDial: 1050,
    mobileStepper: 1000,
    alert: 2000,
  };

  return {
    typography: {
      fontFamily: "Source Sans Pro, Nunito, Roboto, Arial",
      ...customTypography,
    },
    shape,
    components,
    palette: {
      mode,
      ...commonPalette,
      ...(mode === "light" ? lightPalette : {}),
      ...(mode === "dark" ? darkPalette : {}),
    },
    zIndex,
  } as ThemeOptions;
}
