import type { ThemeOptions } from "@mui/material";

const HEIGHT = "40px";

const styleOverrides: Required<
  Required<ThemeOptions>["components"]
>["MuiButton"]["styleOverrides"] = {
  root: ({ theme }) => ({
    fontFamily: "Source Sans Pro, Nunito",
    fontStyle: "normal",
    fontWeight: 500,
    padding: [theme.spacing(1), theme.spacing(2)].join(" "),
    "&:focus": {
      outline: "none",
    },
    maxHeight: HEIGHT,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }),
  sizeLarge: {
    fontSize: "16px",
    lineHeight: "26px",
    letterSpacing: "0.46px",
    textTransform: "inherit",
  },
  sizeMedium: {
    fontSize: "15px",
    lineHeight: "24px",
    letterSpacing: "0.4px",
    textTransform: "inherit",
  },
  sizeSmall: {
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.46px",
    textTransform: "inherit",
  },
};

export const MuiButton: Required<
  Required<ThemeOptions>["components"]
>["MuiButton"] = { styleOverrides };
