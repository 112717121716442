import { CodeSnippet } from "#organization/components"

export const language = "ts";

# Priority Label Key

## Overview

`priority_label_key` is a key for a flow label that can be used to override the default priority for a flow. The value associated with this key must be a valid number. Higher numbers means higher priority. If this parameter is not provided, the priority for the flow will be determined by the matched workload's priority.

## Example

Here is a code example where priorities are defined.

<CodeSnippet lang={language} snippetName="Priority" />

Next is an example of how to pass the priorities when making a `startFlow` call.

<CodeSnippet lang={language} snippetName="QSPriority" />
