import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import { IconButton } from "@mui/material";
import React, { type PropsWithChildren, useState, useMemo } from "react";

import {
  DataGrid as BaseDataGrid,
  DataGridPagination,
  useDataGridOrder,
  useDataGridPagination,
  type HeadCell,
  type DataGridVariants,
  type Order,
} from "#shared/components/data-grid";
import {
  ErrorLayout,
  ErrorText,
  LoaderLayout,
} from "#shared/components/layouts";
import { sharedEnTexts } from "#shared/consts";
import type { JSONObject, Texts } from "#shared/types";

import type { DataGridHook } from "#organization/hooks/data-grid";

import { CreateArchiveButton } from "./archive-button";
import { archiveFilterDataGrid } from "./filters";

type Text = "errorMessage";

const enTexts: Texts<Text> = {
  errorMessage: sharedEnTexts.error,
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export type DataGridProps<T = any> = {
  useGridData: DataGridHook;
  enabled: boolean;
  headCells: HeadCell<T>[];
  initialOrderBy: string;
  initialOrder: Order;
  filterVariables?: JSONObject;
  variant?: DataGridVariants;
  texts?: Texts<Text>;
  enableArchivedData?: boolean;
  modifyDataFunc?: (data: T[]) => T[] | null;
  rowOnClick?: (dataItem: T) => null | (() => void);
};

// eslint-disable-next-line react/function-component-definition
export function DataGrid<T>({
  useGridData,
  enabled,
  headCells,
  initialOrderBy,
  initialOrder,
  filterVariables,
  variant,
  enableArchivedData,
  texts = enTexts,
  modifyDataFunc,
  rowOnClick,
}: PropsWithChildren<DataGridProps<T>>) {
  const [showArchives, setShowArchives] = useState(false);
  const filters = useMemo(
    () =>
      showArchives
        ? filterVariables
        : { ...filterVariables, ...archiveFilterDataGrid },
    [filterVariables, showArchives],
  );

  const dataGridOrderController = useDataGridOrder(
    initialOrderBy,
    initialOrder,
  );

  const pagingControl = useDataGridPagination();
  const gridData = useGridData(
    pagingControl.rowsPerPage,
    pagingControl.page,
    pagingControl.setPage,
    dataGridOrderController.order,
    dataGridOrderController.orderBy,
    enabled,
    enableArchivedData ? filters! : filterVariables!,
  );

  const checkLoading =
    // @ts-ignore
    !gridData?.query?.data?.edges?.length && gridData.query?.isLoading;

  if (checkLoading) {
    return <LoaderLayout />;
  }

  if (gridData.query.error) {
    return (
      <ErrorLayout>
        <ErrorText>{texts.errorMessage}</ErrorText>
      </ErrorLayout>
    );
  }

  const tableEdges = modifyDataFunc
    ? // @ts-ignore
      modifyDataFunc(gridData.query?.data?.edges)
    : // @ts-ignore
      gridData.query?.data?.edges;

  return (
    <>
      <CreateArchiveButton
        {...{
          enableArchivedData,
          useSwitch: { showArchives, setShowArchives },
          checkLoading,
        }}
      >
        <IconButton onClick={() => gridData.query?.refetch()} color="inherit">
          <ReplayOutlinedIcon />
        </IconButton>
      </CreateArchiveButton>

      <BaseDataGrid
        headers={headCells}
        // @ts-ignore
        data={tableEdges || []}
        rowOnClick={rowOnClick}
        orderController={dataGridOrderController}
        variant={variant}
      />
      <DataGridPagination
        page={pagingControl.page}
        rowsPerPage={pagingControl.rowsPerPage}
        handleChangePage={gridData.onPageChange}
        handleChangeRowsPerPage={pagingControl.handleChangeRowsPerPage}
        // @ts-ignore
        length={gridData.query?.data?.totalCount}
      />
    </>
  );
}
