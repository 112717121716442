import { gql } from "graphql-request";

export const filters = (id: string) => [
  {
    id: 0,
    name: "key value",
    key: "key",
    category: "agentKeys",
    gql: gql`
      query agentKeysKey($where: AgentKeyBoolExp, $first: Int) {
        agentKeys(where: $where, first: $first, distinctOn: [key]) {
          totalCount
          edges {
            node {
              key
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      if (prop === "") {
        return {
          first: 5,
          where: {
            projectID: { eq: id },
          },
        };
      }

      return {
        first: 5,
        where: {
          projectID: { eq: id },
          key: {
            like: `${prop}%`,
          },
        },
      };
    },
  },
];
