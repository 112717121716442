import { SHA1 } from "crypto-js";
import { useQuery } from "react-query";

import { API_SERVICE_URL } from "#shared/consts";
import { httpClient } from "#shared/utils/http-client";

export type DashboardFolderSearch = {
  id: number;
  uid: string;
  title: string;
  url: string;
  type: string;
  tags: string[];
  isStarred: boolean;
  uri: string;
  slug: string;
};

/**
 * Grafana API docs: https://grafana.com/docs/grafana/latest/developers/http_api/folder_dashboard_search/
 */
const grafanaSearchFolderURL = new URL(`${API_SERVICE_URL}/grafana/api/search`);

export const usePolicyDashboards = (policyID: string, policyHash: string) => {
  const searchParams = new URLSearchParams(grafanaSearchFolderURL.search);

  searchParams.set("folderIds", "policy-dashboards");
  searchParams.set("tag", calculateDashboardTag(policyID, policyHash));

  const url = `${grafanaSearchFolderURL}?${searchParams.toString()}`;

  const { isError, data, isLoading, error } = useQuery<DashboardFolderSearch[]>(
    {
      queryKey: ["policy", "summary", "dashboards", policyID, policyHash],
      queryFn: async () => {
        const response = await httpClient.get<DashboardFolderSearch[]>({
          url,
        });

        return response.json();
      },
      enabled: !!policyID && !!policyHash,
    },
  );

  return {
    data,
    isError,
    isLoading,
    error,
  };
};

const calculateDashboardTag = (policyID: string, policyHash: string) => {
  const dataToHash = `${policyID}-${policyHash}`;
  const hash = SHA1(dataToHash).toString();

  return hash;
};
