import { Box, List } from "@mui/material";
import React, { type ReactNode, type FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

import { projectContextState } from "#organization/recoil/project";
import { useIsLarge } from "#organization/recoil/sidebar";

import {
  StyledListItemButton,
  StyledBox,
  StyledTypography,
  StyledNav,
} from "./styled";

import { CustomTooltip } from "../../custom-tooltip";

export interface MenuItem {
  id?: string;
  title: string;
  route: string | null;
  icon: ReactNode;
  menu: MenuItem[] | null;
  clickHandler: (() => void) | null;
}

export interface MenuListProps {
  menuList: MenuItem[];
}

export const MenuList: FC<MenuListProps> = ({ menuList }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [projectContext] = useRecoilState(projectContextState);
  const isSidebarLarge = useIsLarge();
  const disabledIfNoContext: string[] = [];

  const isSelected = (route: string | null) =>
    !!route && location.pathname.indexOf(route) === 0;

  const checkIfContext = (route: string | null) => {
    if (!projectContext.id) {
      return disabledIfNoContext.includes(route || "");
    }

    return false;
  };

  const checkIfDisabled = (
    route: string | null,
    clickHandler: (() => void) | null,
  ) => {
    const routeFlag = checkIfContext(route) || route === null;
    const clickHandlerFlag = clickHandler === null;

    if (clickHandlerFlag) {
      return routeFlag;
    }

    return clickHandlerFlag;
  };

  return (
    <StyledNav>
      <List
        sx={{
          py: 0,
          borderTop: (theme) => `1px solid ${theme.palette.grey[200]}`,
        }}
      >
        {menuList.map(({ id, title, route, icon, clickHandler }) => (
          <StyledListItemButton
            disableGutters
            divider
            onClick={() => {
              if (route) {
                navigate(route);
              } else if (clickHandler) {
                clickHandler();
              }
            }}
            id={id}
            disabled={checkIfDisabled(route, clickHandler)}
            key={title}
          >
            <StyledBox
              {...{
                selected: isSelected(route),
                drawerOpen: isSidebarLarge,
                borderRadius: isSidebarLarge
                  ? "0px 4px 4px 0px"
                  : "4px 4px 4px 4px",
              }}
            >
              {isSidebarLarge ? (
                <Box
                  sx={{
                    display: "flex",
                    ml: 1,
                  }}
                >
                  {icon}
                </Box>
              ) : (
                <CustomTooltip title={title}>
                  <Box
                    sx={{
                      display: "flex",
                      mx: 0.75,
                    }}
                  >
                    {icon}
                  </Box>
                </CustomTooltip>
              )}
              {isSidebarLarge && (
                <StyledTypography {...{ selected: isSelected(route) }}>
                  {title}
                </StyledTypography>
              )}
            </StyledBox>
          </StyledListItemButton>
        ))}
      </List>
    </StyledNav>
  );
};
