import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import {
  type TabProps,
  Popper,
  Paper,
  Box,
  MenuItem,
  Tab,
  ClickAwayListener,
} from "@mui/material";
import React, { useState, type FC, useRef } from "react";

export type DropdownTabProps = {
  menuItems: Array<{
    id: string;
    node: React.ReactNode;
    isSelected: boolean;
    onClick?: () => void;
  }>;
} & TabProps;

export const DropdownTab: FC<DropdownTabProps> = ({ menuItems, ...props }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const tabRef = useRef<HTMLDivElement | null>(null);

  const open = Boolean(anchorEl);

  return (
    <>
      <Tab
        ref={tabRef}
        {...props}
        onClick={(e) => {
          setAnchorEl(tabRef.current);
          props?.onClick?.(e);
        }}
        icon={!open ? <ExpandMoreOutlinedIcon /> : <ExpandLessOutlinedIcon />}
        iconPosition="end"
        sx={{ minHeight: 0 }}
      />
      <Popper open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <Box
            sx={{ minWidth: 180, width: "fit-content" }}
            component={Paper}
            elevation={5}
          >
            {menuItems.map((menuItem) => (
              <MenuItem
                key={menuItem.id}
                selected={menuItem.isSelected}
                onClick={() => {
                  setAnchorEl(null);
                  menuItem.onClick?.();
                }}
              >
                {menuItem.node}
              </MenuItem>
            ))}
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
