import type { PaletteMode } from "@mui/material";
import { isEmpty, isFunction, noop } from "lodash";
import {
  loadMicroApp,
  type LoadableApp,
  type MicroApp,
  type FrameworkLifeCycles,
  type LifeCycleFn,
  addGlobalUncaughtErrorHandler,
} from "qiankun";
import type { ReactNode } from "react";

import { sharedEnTexts } from "#shared/consts";
import { LoggerService } from "#shared/services/logger";
import type { AnyObject } from "#shared/types";

import { GRAFANA_APP_NAME } from "./consts";

export type FailedToMountGrafanaErrorName =
  (typeof GrafanaUtils)["FAILED_TO_MOUNT_GRAFANA_ERROR_NAME"];

export interface GrafanaProps<Q extends string = string> {
  name: string;
  uid: string;
  /**
   * NOTE:
   * If undefined then the latest will be used
   */
  version?: number;
  slug: string;
  mode: PaletteMode;
  queryParams?: Partial<AnyObject<Q, string>> | null;
  fnError?: ReactNode;
  pageTitle?: string;
  controlsContainer?: HTMLElement | null;
  isLoading: (isLoading: boolean) => void;
  setErrors: (errors: { [K: number | string]: string }) => void;
  hiddenVariables: string[];
  container?: HTMLElement | null;
}

export type LoadAppOptions<Q extends string = string> = Pick<
  GrafanaProps<Q>,
  | "container"
  | "controlsContainer"
  | "fnError"
  | "hiddenVariables"
  | "isLoading"
  | "mode"
  | "name"
  | "pageTitle"
  | "queryParams"
  | "setErrors"
  | "slug"
  | "version"
  | "uid"
> & {
  entry?: string;
};

export type MutableGrafanaProps = Partial<
  Pick<
    GrafanaProps,
    "mode" | "queryParams" | "hiddenVariables" | "uid" | "slug" | "version"
  >
>;

export type EnhancedLifeCycles = {
  [K in keyof FrameworkLifeCycles<GrafanaProps>]?: LifeCycleFn<GrafanaProps>;
};

export const SHOULD_LOG_GRAFANA = false;

type MicroAppData = {
  props: LoadableApp<Partial<GrafanaProps>> & {
    container: HTMLElement | null;
  };
  app: MicroApp;
};

export class GrafanaUtils {
  public static readonly FAILED_TO_MOUNT_GRAFANA_ERROR_NAME =
    "FailedToMountGrafana";

  public static readonly DEFAULT_ENTRY = "/public/microfrontends/fn_dashboard/";

  public static readonly VIEW_PORT_ID = "grafana-viewport";

  public static readonly CONTROLS_ID = "grafana-controls";

  public static readonly ERROR_MESSAGE =
    "Error Loading Dashboards. Try refreshing page.";

  private logPrefix = "[FN GrafanaUtils]";

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  log = (...args: any[]) => {
    LoggerService.verbose(SHOULD_LOG_GRAFANA, this.logPrefix, ...args);
  };

  private readonly onUncaughtError: OnErrorEventHandlerNonNull;

  public appData: MicroAppData | null = null;

  constructor(onUncaughtError: OnErrorEventHandlerNonNull = noop) {
    this.onUncaughtError = (err) => {
      if (
        err instanceof Error &&
        err?.name === GrafanaUtils.FAILED_TO_MOUNT_GRAFANA_ERROR_NAME
      ) {
        this.log("Failed to mount grafana app.", err);

        return;
      }

      const message = getErrorMessage(err);

      this.log("Global uncaught Grafana error:", message, err);

      onUncaughtError(err);
    };

    addGlobalUncaughtErrorHandler(this.onUncaughtError);
  }

  public update = async (options: MutableGrafanaProps) => {
    if (!options || isEmpty(options)) {
      this.log("Failed to update app");

      return;
    }

    const app = this.appData?.app;

    // TODO Render error if app is undefined
    if (!app || !isFunction(app?.update)) {
      this.log("Failed to update app");

      return;
    }

    await app.update(options);

    this.log("updated grafana app");
  };

  public load = async (
    opt: LoadableApp<Partial<GrafanaProps>> & {
      container: HTMLElement | null;
      configuration?: Parameters<typeof loadMicroApp>[1];
    },
  ) => {
    const { configuration = {}, ...options } = opt;
    const container = options.container || this.queryViewport()();

    const controlsContainer =
      options.props?.controlsContainer || this.queryControlsContainer()();

    this.log("Trying to load app", "...");

    const microApp = loadMicroApp<Partial<GrafanaProps>>(
      {
        ...options,
        container,
        name: options?.name || GRAFANA_APP_NAME,
        props: {
          ...options.props,
          controlsContainer,
        },
      },
      configuration,
    );

    this.appData = {
      props: options,
      app: microApp,
    };

    this.log("MicroApp", "has been loaded:");
  };

  public unload = async () => {
    this.log("Unloading app");

    await this.appData?.app?.unmount();

    this.log("Unloaded app");
  };

  /* eslint-disable-next-line  */
  private queryControlsContainer =
    (global = window) =>
    (selector = `#${GrafanaUtils.CONTROLS_ID}`) =>
      global.document.querySelector(selector) as HTMLElement;

  /* eslint-disable-next-line  */
  private queryViewport =
    (global = window) =>
    (selector = `#${GrafanaUtils.VIEW_PORT_ID}`) =>
      global.document.querySelector(selector) as HTMLElement;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getErrorMessage(err: any, defaultMessage = sharedEnTexts.error) {
  // https://blog.sentry.io/what-is-script-error/#an-alternative-solution-trycatch
  try {
    throw err as unknown as Error;
  } catch (error) {
    // eslint-disable-next-line no-param-reassign
    err = error as typeof err;
  }

  let message =
    err?.reason?.message ||
    err?.reason ||
    err?.message ||
    (typeof err === "string" ? err : "");

  message =
    message ||
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (err instanceof ErrorEvent ? (err as any).error?.statusText : "");

  return message || defaultMessage;
}
