import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { QUERY_PARAMS } from "#shared/consts";
import { useFetch } from "#shared/hooks";
import { userNewState } from "#shared/recoil";
import type { Organization } from "#shared/types";

import { API_URLS } from "#global/consts";

/**
 * NOTE:
 *
 * Fetch organizations on authenticated
 */
export const useOrganizationById = (id: string) => {
  const [organization, setOrganization] = useState<Organization | undefined>();
  const navigate = useNavigate();

  const token = useRecoilValue(userNewState.firebaseToken);
  const email = useRecoilValue(userNewState.email);

  const fetch = useFetch<Organization[]>();

  const { httpState } = fetch;

  useEffect(() => {
    if (httpState.isSuccess || httpState.isLoading || httpState.error) {
      return;
    }

    if (!token || !id) {
      return;
    }

    const url = new URL(API_URLS.ORGANIZATIONS.BY_ID.replace(":id", id));
    url.searchParams.append(QUERY_PARAMS.EMAIL, email || "");

    fetch.request({
      url,
      shouldParse: true,
      headers: token ? { Authorization: token } : {},
    });
  }, [navigate, token, email, fetch, httpState, id]);

  useEffect(() => {
    if (httpState.isSuccess && fetch.result) {
      setOrganization(fetch.result[0]);
    }
  }, [fetch, navigate, httpState.isSuccess]);

  return useMemo(
    () => ({
      errorMessage: httpState.error,
      isLoading: httpState.isLoading,
      organization,
      isSuccess: httpState.isSuccess,
    }),
    [organization, httpState],
  );
};
