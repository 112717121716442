import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import React, { type FC } from "react";

import {
  useIsSidebarOpen,
  useSidebarController,
} from "#organization/recoil/sidebar";

const MIN_HEIGHT = 60;

const HamburgerWrapperStyled = styled(Box)({
  minHeight: MIN_HEIGHT,
  backgroundColor: "background.default",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "45px",
  paddingLeft: "3px",
});

export const HamburgerButton: FC = () => {
  const { toggleSidebar } = useSidebarController();
  const isSidebarOpen = useIsSidebarOpen();

  return (
    <HamburgerWrapperStyled>
      {!isSidebarOpen ? (
        <MenuIcon
          onClick={toggleSidebar}
          sx={{ color: (theme) => theme.palette.text.primary }}
        />
      ) : (
        <CloseIcon
          onClick={toggleSidebar}
          sx={{ color: (theme) => theme.palette.text.primary }}
        />
      )}
    </HamburgerWrapperStyled>
  );
};
