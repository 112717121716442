import { Search } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Button,
  type ButtonProps,
  ClickAwayListener,
  InputAdornment,
  List,
  ListItemButton,
  Popper,
  useTheme,
} from "@mui/material";
import React, { type FC, useState } from "react";

import { Input } from "#shared/components/inputs/input";

import type { FilterLabelsType } from "./filter";
import { FilterPopperBox, FilterPopperHeader } from "./filter.styled";

import type { FnTheme } from "../../../../app-theme-provider/types";

interface FilteringKeyChooserProps {
  labels: FilterLabelsType[];
  onKeySelect: (key: FilterLabelsType) => void;
  createNewChipsContainer: () => void;
  selectedKeys: [FilterLabelsType | null];
}

export const FilteringKeyChooser: FC<FilteringKeyChooserProps> = ({
  labels,
  onKeySelect,
  createNewChipsContainer,
  selectedKeys,
}) => {
  const [element, setElement] = useState<null | HTMLElement>(null);
  const open = Boolean(element);

  const theme = useTheme() as unknown as FnTheme;

  const onClick: Required<ButtonProps>["onClick"] = (event) => {
    setElement(element ? null : event.currentTarget);
  };

  const closePopper = () => {
    setElement(null);
  };

  const selectedKeysList = selectedKeys.map((selected) => {
    if (selected?.uniqueKey) {
      return selected.uniqueKey;
    }

    return selected?.key;
  });

  const isDisabled = (label: FilterLabelsType) => {
    if (label.uniqueKey) {
      return selectedKeysList.includes(label.uniqueKey);
    }

    return selectedKeysList.includes(label.key);
  };

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto",
          columnGap: "25px",
          gridArea: "filter-add-button",
          marginRight: 2,
        }}
      >
        <Button
          variant="contained"
          onClick={onClick}
          size="small"
          startIcon={<FilterListIcon />}
        >
          Add filter
        </Button>
      </Box>

      <Popper
        id="key-selector"
        open={open}
        anchorEl={element}
        style={{ zIndex: theme.zIndex.popover }}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={closePopper}>
          <FilterPopperBox
            sx={{
              display: "grid",
              gridTemplateRows: "38px 50px 1fr",
              gridTemplateColumns: "1fr",
              width: "400px",
              boxShadow: 5,
            }}
          >
            <FilterPopperHeader>Choose Filtering Key</FilterPopperHeader>
            <Box sx={{ padding: "10px" }}>
              <Input
                fullWidth
                width="100%"
                placeholder="Search"
                InputProps={{
                  sx: { height: "40px" },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <List>
              {labels.map((label) => (
                <ListItemButton
                  key={label.id}
                  disabled={isDisabled(label)}
                  onClick={() => {
                    onKeySelect(label);
                    createNewChipsContainer();
                    closePopper();
                  }}
                >
                  {label.name}
                </ListItemButton>
              ))}
            </List>
          </FilterPopperBox>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
