import { gql } from "graphql-request";

import type {
  AgentKeysDataQuery,
  AgentKeysGroupsTitleQuery,
  GenerateAgentKeyMutation,
  GenerateAgentKeyMutationVariables,
  RevokeAgentKeyInput,
  RevokeAgentKeyMutation,
} from "#shared/generated/graphql";
import { useGqlMutation } from "#shared/utils";

import {
  type DataGridHook,
  type GroupsTitlesHook,
  useGridData,
} from "#organization/hooks";

import {
  type GroupDataHook,
  useGroupData,
  useGroupsTitles,
} from "../../../../../hooks/group-by";

export type AgentKeysGroupBy = "key";

export const AGENT_KEYS_ENTITY_KEY = "agentKeys";

export const agentKeysGroupsTitles = gql`
  query AgentKeysGroupsTitle(
    $where: AgentKeyBoolExp!
    $first: Int
    $last: Int
    $after: String
    $before: String
    $orderBy: [AgentKeyOrderBy]
  ) {
    agentKeys(
      where: $where
      first: $first
      last: $last
      after: $after
      before: $before
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          key
          id
          createdAt
          createdBy {
            name
          }
          agents {
            totalCount
          }
          controllers {
            totalCount
          }
        }
      }
    }
  }
`;

type AgentKeysGroupTitleData =
  AgentKeysGroupsTitleQuery["agentKeys"]["edges"][0]["node"];

export const useAgentKeysGroupsTitles: GroupsTitlesHook<AgentKeysGroupBy> = (
  pageSize,
  page,
  setPage,
  groupByKey,
) =>
  useGroupsTitles<AgentKeysGroupBy, AgentKeysGroupTitleData>(
    AGENT_KEYS_ENTITY_KEY,
    pageSize,
    page,
    setPage,
    groupByKey,
    agentKeysGroupsTitles,
    (node) => node[groupByKey!],
  );

type AgentKeysGroupData = AgentKeysDataQuery["agentKeys"]["edges"][0]["node"];

export type AdaptedAgentKeysGroupData = AgentKeysGroupData;

export const agentKeysGroupData = gql`
  query AgentKeysData(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: AgentKeyBoolExp
    $orderBy: [AgentKeyOrderBy]
  ) {
    agentKeys(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          key
          id
          createdAt
          createdBy {
            name
          }
          agents {
            totalCount
          }
          controllers {
            totalCount
          }
        }
      }
    }
  }
`;

export const useAgentKeysGroupData: GroupDataHook<AgentKeysGroupBy> = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  groupByKey,
  groupByValue,
  filterVariables,
) =>
  useGroupData<AgentKeysGroupBy, AgentKeysGroupData, AdaptedAgentKeysGroupData>(
    AGENT_KEYS_ENTITY_KEY,
    agentKeysGroupData,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    groupByKey,
    groupByValue,
    (agentKeys) => ({
      ...agentKeys,
    }),
    filterVariables,
  );

export const useAgentKeysData: DataGridHook = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  enabled,
  filterVariables,
) =>
  useGridData<AgentKeysGroupData, AdaptedAgentKeysGroupData>(
    AGENT_KEYS_ENTITY_KEY,
    agentKeysGroupData,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    filterVariables,
    (agentKeys: AgentKeysGroupData) => ({
      ...agentKeys,
    }),
    enabled,
  );

const GenerateAgentKey = gql`
  mutation GenerateAgentKey($input: GenerateAgentKeyInput!) {
    generateAgentKey(input: $input) {
      agentKey {
        id
        key
      }
    }
  }
`;

export const useGenerateAgentKey = () =>
  useGqlMutation<
    GenerateAgentKeyMutation,
    unknown,
    GenerateAgentKeyMutationVariables["input"]
  >(GenerateAgentKey);

const agentRevokeKeyMutation = gql`
  mutation RevokeAgentKey($input: RevokeAgentKeyInput!) {
    revokeAgentKey(input: $input) {
      agentKey {
        id
      }
    }
  }
`;

export const useAgentRevokeKey = () =>
  useGqlMutation<RevokeAgentKeyMutation, unknown, RevokeAgentKeyInput>(
    agentRevokeKeyMutation,
  );
