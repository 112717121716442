import { Box as MuiBox, type BoxProps, styled } from "@mui/system";
import React, { type CSSProperties, type FC, forwardRef } from "react";

export type GrafanaViewPortProps = BoxProps & {
  isHidden?: boolean;
};

const HIDDEN_STYLE: CSSProperties = {
  display: "none",
};

const Box: FC<GrafanaViewPortProps> = forwardRef(
  ({ isHidden, children, ...props }, ref) => (
    <MuiBox
      {...{ ...props, ref }}
      style={isHidden ? { ...HIDDEN_STYLE, ...props.style } : props.style}
    />
  ),
);

// TODO: I'm thinking of fixing the 16px padding in grafana
export const GrafanaViewport = styled(Box)({
  marginLeft: "-16px",
  marginRight: "-16px",
  "& > div[data-qiankun]": {
    position: "inherit !important",
  },
});
