import { type TabsProps, Typography, useTheme } from "@mui/material";
import React, { type FC, useCallback, useState } from "react";

import { BreadcrumbButton } from "#shared/components/breadcrumbs";
import { TabPanel } from "#shared/components/tab-panel";
import type { Texts } from "#shared/types";

import { AlertTable } from "#organization/pages/authenticated/alert-manager/tabs/alert-logs/components/alerts-table";
import { FlyoutMenuBreadcrumbAppender } from "#organization/recoil/breadcrumbs";

import {
  FlowAnalytics,
  FlowAnalyticsByLabel,
  FlowAnalyticsSDK,
  FlowAnalyticsSDKByLabel,
  FlowControlComponents,
  FlowMap,
  FlyoutMenuTabs,
} from "./tabs";

import type { FnTheme } from "../../../../../../../../app-theme-provider/types";
import {
  FlyoutMenuContentContainer,
  FlyoutMenuHeaderContainer,
} from "../../../../../../components/flyout-menu/styled";
import type { FlowControlFlyoutConfig } from "../types";

export interface FlowControlFlyoutMenuWrapperProps {
  service: FlowControlFlyoutConfig;
  texts?: Texts<Text>;
  hideFlyoutMenu: () => void;
}

type Text = "flowControl";

export const enTexts: Required<FlowControlFlyoutMenuWrapperProps>["texts"] = {
  flowControl: "Flow Control",
};

const HEADER_HEIGHT = "100px";

export const FlowControlFlyoutMenuWrapper: FC<
  FlowControlFlyoutMenuWrapperProps
> = ({ service, texts = enTexts, hideFlyoutMenu }) => {
  const { shape } = useTheme() as FnTheme;
  const { serviceName, serviceId, controlPoint, agentGroup } = service;
  const [activeTab, setActiveTab] = useState(0);
  const isHTTPControlPoint = service.controlPointType === "http";

  const onTabsChange = useCallback<Required<TabsProps>["onChange"]>(
    (_, tabValue: number) => {
      if (tabValue === activeTab) {
        return;
      }
      setActiveTab(tabValue);
    },
    [activeTab],
  );

  return (
    <>
      <FlyoutMenuHeaderContainer
        sx={{
          height: HEADER_HEIGHT,
          paddingBottom: 0,
          justifyContent: "space-between",
        }}
      >
        <FlyoutMenuBreadcrumbAppender>
          <BreadcrumbButton onClick={hideFlyoutMenu}>
            {texts.flowControl}
          </BreadcrumbButton>
        </FlyoutMenuBreadcrumbAppender>
        <FlyoutMenuBreadcrumbAppender>
          <Typography>
            {controlPoint !== "%" || serviceName !== "%"
              ? [controlPoint, serviceName].filter(Boolean).join("@")
              : agentGroup}
          </Typography>
        </FlyoutMenuBreadcrumbAppender>
        <FlyoutMenuTabs
          {...{
            activeTab,
            onChange: onTabsChange,
            controlPointType: service.controlPointType,
          }}
        />
      </FlyoutMenuHeaderContainer>

      <TabPanel value={0} activeValue={activeTab}>
        <FlyoutMenuContentContainer>
          {isHTTPControlPoint ? (
            <FlowAnalytics {...{ service }} />
          ) : (
            <FlowAnalyticsSDK {...{ service }} />
          )}
        </FlyoutMenuContentContainer>
      </TabPanel>
      <TabPanel value={1} activeValue={activeTab}>
        <FlyoutMenuContentContainer>
          {isHTTPControlPoint ? (
            <FlowAnalyticsByLabel {...{ service }} />
          ) : (
            <FlowAnalyticsSDKByLabel {...{ service }} />
          )}
        </FlyoutMenuContentContainer>
      </TabPanel>
      {isHTTPControlPoint && (
        <TabPanel value={2} activeValue={activeTab}>
          <FlyoutMenuContentContainer>
            <FlowMap {...{ service }} />
          </FlyoutMenuContentContainer>
        </TabPanel>
      )}
      <TabPanel value={isHTTPControlPoint ? 3 : 2} activeValue={activeTab}>
        <FlyoutMenuContentContainer>
          <AlertTable
            additionalFilterVariables={{
              labels: {
                contains: {
                  service: serviceName,
                },
              },
            }}
          />
        </FlyoutMenuContentContainer>
      </TabPanel>
      <TabPanel
        value={isHTTPControlPoint ? 4 : 3}
        activeValue={activeTab}
        sx={() => ({
          height: `calc(100% - 2 * ${shape.flyoutMenu.topBarHeight})`,
          width: "100%",
        })}
      >
        <FlyoutMenuContentContainer>
          <FlowControlComponents
            service={{
              name: serviceName,
              id: serviceId,
              controlPoint,
              controlPointID: service.controlPointId,
            }}
          />
        </FlyoutMenuContentContainer>
      </TabPanel>
    </>
  );
};
