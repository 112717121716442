import type { ThemeOptions } from "@mui/material";

const styleOverrides: Required<
  Required<ThemeOptions>["components"]
>["MuiTypography"]["styleOverrides"] = {
  root: {
    cursor: "default",
  },
  h1: {
    fontWeight: 700,
    fontSize: "20px",
    textAlign: "left",
  },
  h6: {
    fontFamily: "Source Sans Pro, Nunito",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "160%",
    letterSpacing: "0.15px",
  },
  body2: {
    lineHeight: "20.02px",
  },
};

export const MuiTypography: Required<
  Required<ThemeOptions>["components"]
>["MuiTypography"] = { styleOverrides };

export const customTypography = {
  body3: {
    fontWeight: 400,
    fontSize: "12px",
  },
  "body3-semibold": {
    fontWeight: 500,
    fontSize: "12px",
  },
};
