import { Box, type TextFieldProps } from "@mui/material";
import { styled, type SystemStyleObject, type Theme } from "@mui/system";

const WIDTH = "150px";

export const GridWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: [WIDTH, "1fr"].join(" "),
  alignItems: "center",
  textAlign: "start",
  [theme.breakpoints.down("lg")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: theme.spacing(0.5),
  },
}));

export const TEXT_FIELD_PROPS: Required<
  Pick<TextFieldProps, "InputProps"> & {
    sx: (theme: Theme) => SystemStyleObject<Theme>;
  }
> = {
  sx: (theme) => ({
    width: "100%",
    [theme.breakpoints.up("md")]: {
      alignItems: "flex-end",
    },
  }),
  InputProps: {
    style: {
      maxWidth: 600,
      minWidth: 200,
      width: "100%",
    },
  },
};
