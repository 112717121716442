import { cloneDeep, isBoolean, noop, pick } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  atom,
  selector,
  useRecoilValue,
  useSetRecoilState,
  DefaultValue,
} from "recoil";

import { ATOM_KEYS } from "#shared/consts";
import { LoggerService, LogoutService } from "#shared/services";
import {
  httpClient,
  type ResponseWithTypedJson,
} from "#shared/utils/http-client";

import { userRoleEnTexts, type UserRole } from "./consts";
import {
  type UnauthenticatedUser,
  type UserState,
  type AuthenticatedUser,
  type UserRoleDictionary,
} from "./types";
import { userNewState } from "./user-firebase";

export const INITIAL_USER: UnauthenticatedUser = {
  data: null,
  httpStatus: {
    isLoading: false,
    isFetched: false,
    error: null,
    isIdle: true,
  },
  isAuthenticated: false,
  provider: null,
};
type WithOptionalIsAuthenticated<U extends UserState = UserState> = Omit<
  U,
  "isAuthenticated"
> &
  Partial<Pick<U, "isAuthenticated">>;

const UNAUTHENTICATED_USER_STATE: UnauthenticatedUser = cloneDeep(INITIAL_USER);

export const userState = atom<UserState>({
  key: ATOM_KEYS.user,
  default: cloneDeep(UNAUTHENTICATED_USER_STATE),
});

export const userHttpState = selector<
  Pick<UserState, "httpStatus"> & Partial<Pick<UserState, "provider">>
>({
  key: ATOM_KEYS.userHttpState,
  get: ({ get }) => pick(get(userState), "httpStatus", "provider"),
  set: ({ set, get }, state) => {
    set(
      userState,
      state instanceof DefaultValue
        ? { ...get(userState), httpStatus: INITIAL_USER.httpStatus }
        : { ...get(userState), ...state },
    );
  },
});

export const userEmailState = selector<string | null>({
  key: ATOM_KEYS.userEmail,
  get: ({ get }) => get(userState).data?.email || null,
});

export const membershipIdState = selector<string | null>({
  key: ATOM_KEYS.membershipId,
  get: ({ get }) => get(userState).data?.organization?.id || null,
});

export const userAuthnProviderState = selector<UserState["provider"]>({
  key: ATOM_KEYS.userAuthnProviderState,
  get: ({ get }) => get(userState).provider,
  set: ({ set, get }, provider) => {
    set(
      userState,
      provider instanceof DefaultValue
        ? { ...get(userState), provider: INITIAL_USER.provider }
        : { ...get(userState), provider },
    );
  },
});

export const isUserLoadingState = selector({
  key: ATOM_KEYS.isUserLoading,
  get: ({ get }) => get(userState).httpStatus.isLoading,
});

export const userErrorState = selector({
  key: ATOM_KEYS.userErrorState,
  get: ({ get }) => get(userState).httpStatus.error,
});

export const useSetPartialUser = selector<Partial<UserState>>({
  key: ATOM_KEYS.setPartialUser,
  get: ({ get }) => get(userState),
  set: ({ set, get }, state) => {
    set(
      userState,
      state instanceof DefaultValue
        ? (INITIAL_USER as UserState)
        : ({ ...get(userState), ...state } as UserState),
    );
  },
});

export const useSetUser = () => {
  const setUserState = useSetRecoilState(userState);

  return useCallback(
    (user: WithOptionalIsAuthenticated) => {
      if (!user.data || !user.data.id) {
        setUserState({
          ...user,
          isAuthenticated: false,
        });

        return;
      }

      const { isAuthenticated, ...other } = user as AuthenticatedUser;

      setUserState({
        ...other,
        isAuthenticated: isBoolean(isAuthenticated)
          ? isAuthenticated
          : !!other.data.id,
      });
    },
    [setUserState],
  );
};

export const USER_ROLE_DICTIONARY: UserRoleDictionary = {
  ...userRoleEnTexts,
};

export function getUserRoleName(role: UserRole | undefined) {
  return role ? USER_ROLE_DICTIONARY[role] : "";
}

const ON_NOT_AUTHENTICATED_WHITELIST: RegExp[] = [];

const useIsInOnNotAuthenticatedWhitelist = (
  whitelist = ON_NOT_AUTHENTICATED_WHITELIST,
) =>
  useCallback(
    (url: string) => whitelist.some((whitelisted) => whitelisted.test(url)),
    [whitelist],
  );

/**
 * NOTE:
 *
 * Responsible for logging out when 401
 */
export const useOnNotAuthenticated = (isOrganizationContext: boolean) => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const isAuthenticated = useRecoilValue(userNewState.isAuthenticated);

  const prevIsAuthenticatedRef = useRef(isAuthenticated);

  const isInOnNotAuthenticatedWhitelist = useIsInOnNotAuthenticatedWhitelist();

  const onNotAuthenticated = useCallback(async () => {
    setIsLoggingOut(true);

    const logoutResults = await Promise.allSettled(
      LogoutService.getContextLogoutUrl(isOrganizationContext).map(
        (logoutUrl) =>
          httpClient.get({
            url: logoutUrl,
          }) as Promise<ResponseWithTypedJson<{}>>,
      ),
    );

    const failed = logoutResults.filter(
      (response) => response.status === "rejected" || !response.value.ok,
    );

    if (failed.length) {
      LoggerService.warn(
        "Failed to log out.",
        ["Failed:", failed.length].join(" "),
      );
    }

    setIsLoggingOut(false);
  }, [isOrganizationContext]);

  useEffect(() => {
    const { current: prevIsAuthenticated } = prevIsAuthenticatedRef;
    prevIsAuthenticatedRef.current = isAuthenticated;

    if (isAuthenticated && !prevIsAuthenticated) {
      httpClient.onNotAuthenticated = (url) => {
        if (!isAuthenticated || isInOnNotAuthenticatedWhitelist(url)) {
          return;
        }

        onNotAuthenticated();
      };
    } else if (!isAuthenticated && prevIsAuthenticated) {
      httpClient.onNotAuthenticated = noop;
    }
  }, [isAuthenticated, isInOnNotAuthenticatedWhitelist, onNotAuthenticated]);

  return { isLoggingOut };
};
