import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { Box } from "@mui/material";
import React, { type FC } from "react";

import {
  type MenuItem,
  MenuList,
} from "#organization/components/layouts/sidebar/menu-list";
import { BASE_DOCUMENTATION_URL } from "#organization/pages/consts";

import {
  ControlPointsIcon,
  SettingsIcon,
  PoliciesIcon,
  DocumentationIcon,
  HelpIcon,
  ApertureIcon,
} from "./assets";
import { CacheIcon } from "./assets/cache";
import { ProjectSelectorSidebar } from "./components/project-selector";

import { ALERT_MANAGER_ROUTE } from "../alert-manager";
import { APERTURE_SYSTEM_ROUTE } from "../aperture-system";
import { CACHE_ROUTE } from "../cache";
import { CONTROL_POINTS_ROUTE } from "../control-points";
import { POLICIES_ROUTE } from "../policies";
import { SETTINGS_ROUTE } from "../settings";

export const AuthenticatedSidebar: FC = () => (
  <>
    <Box sx={{ py: 3, mb: 1 }}>
      <ProjectSelectorSidebar />
    </Box>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <MenuList menuList={TOP_MENU_LIST} />
      <MenuList menuList={BOTTOM_MENU_LIST} />
    </Box>
  </>
);

const TOP_MENU_LIST: MenuItem[] = [
  {
    title: CONTROL_POINTS_ROUTE.TITLE,
    route: CONTROL_POINTS_ROUTE.ABSOLUTE_PATH,
    icon: (
      <ControlPointsIcon sx={{ fontSize: "1.125rem" }} viewBox="0 0 24 24" />
    ),
    menu: null,
    clickHandler: null,
  },
  {
    title: POLICIES_ROUTE.TITLE,
    route: POLICIES_ROUTE.ABSOLUTE_PATH,
    icon: <PoliciesIcon sx={{ fontSize: "1.125rem" }} viewBox="0 0 20 20" />,
    menu: null,
    clickHandler: null,
  },
  {
    title: CACHE_ROUTE.TITLE,
    route: CACHE_ROUTE.ABSOLUTE_PATH,
    icon: (
      <CacheIcon
        sx={{ fontSize: "1.125rem", marginLeft: "3px", marginTop: "2px" }}
        viewBox="0 0 20 20"
      />
    ),
    menu: null,
    clickHandler: null,
  },
  {
    title: APERTURE_SYSTEM_ROUTE.TITLE,
    route: APERTURE_SYSTEM_ROUTE.ABSOLUTE_PATH,
    icon: <ApertureIcon sx={{ fontSize: "1.125rem" }} viewBox="0 0 23 23" />,
    menu: null,
    clickHandler: null,
  },
  {
    title: ALERT_MANAGER_ROUTE.TITLE,
    route: ALERT_MANAGER_ROUTE.ABSOLUTE_PATH,
    icon: (
      <NotificationsOutlinedIcon
        sx={{ fontSize: "1.125rem" }}
        viewBox="0 0 23 23"
      />
    ),
    menu: null,
    clickHandler: null,
  },
];

const BOTTOM_MENU_LIST: MenuItem[] = [
  /* {
    title: INTEGRATIONS_ROUTE.TITLE,
    route: INTEGRATIONS_ROUTE.ABSOLUTE_PATH,
    icon: (
      <IntegrationsIcon sx={{ fontSize: "1.125rem" }} viewBox="0 0 23 23" />
    ),
    menu: null,
    clickHandler: null,
  }, */
  {
    title: SETTINGS_ROUTE.TITLE,
    route: SETTINGS_ROUTE.ABSOLUTE_PATH,
    icon: <SettingsIcon sx={{ fontSize: "1.125rem" }} viewBox="0 0 20 20" />,
    menu: null,
    clickHandler: null,
  },
  {
    title: "Help",
    route: null,
    id: "help_intercom",
    icon: <HelpIcon sx={{ fontSize: "1.125rem" }} viewBox="0 0 20 20" />,
    menu: null,
    clickHandler: () => {},
  },
  {
    title: "Documentation",
    route: null,
    icon: (
      <DocumentationIcon sx={{ fontSize: "1.125rem" }} viewBox="0 0 20 20" />
    ),
    menu: null,
    clickHandler: () => {
      window.open(BASE_DOCUMENTATION_URL, "_blank");
    },
  },
];
