# Interval

## Overview

The `interval` specifies the time frequency for refilling the token bucket, directly influencing the rate at which `fill_amount` is applied.

## Example

If the `interval` is set to 60 second and the `fill_amount` is 20, the bucket will be replenished with 20 tokens within a minute.

## Note

The `interval` works in conjunction with the `fill_amount` to ensure a constant refilling of the bucket. This will allow users to keep making steady requests. Adjust this setting to match the anticipated frequency of user requests and the request processing capacity of third-party applications, ensuring a steady flow of requests while avoiding hitting external rate limits.
