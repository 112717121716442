import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  type IconButtonProps,
  type SvgIconProps,
  Tooltip,
  type TooltipProps,
} from "@mui/material";
import React, { type Dispatch, type FC, type SetStateAction } from "react";

import type { Texts } from "#shared/types";

export interface CloseButtonProps
  extends Omit<IconButtonProps, "children" | "onClick"> {
  useShowHide: [boolean, Dispatch<SetStateAction<boolean>>];
  iconProps?: SvgIconProps;
  texts?: Texts<Text>;
  tooltipProps?: Omit<TooltipProps, "children">;
}

type Text = "close";

const enTexts: Required<CloseButtonProps["texts"]> = {
  close: "Close side panel",
};

export const CloseButton: FC<CloseButtonProps> = ({
  useShowHide,
  iconProps,
  texts = enTexts,
  tooltipProps,
  ...props
}) => {
  const [, setShowHide] = useShowHide;

  const onClick = () => {
    setShowHide((prev) => !prev);
  };

  return (
    <Tooltip
      title={texts.close}
      placement="top"
      arrow
      {...tooltipProps}
      PopperProps={{
        sx: { margin: 0 },
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
        ...tooltipProps?.PopperProps,
      }}
    >
      <IconButton color="inherit" onClick={onClick} {...props}>
        <CloseIcon />
      </IconButton>
    </Tooltip>
  );
};
