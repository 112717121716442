import { Box, Typography } from "@mui/material";
import React, { type FC } from "react";

import { GlobalFrame } from "#shared/components/layouts";
import { TermsAndConditions } from "#shared/components/toc";
import type { Texts } from "#shared/types";

import { SignIn } from "./components";

export interface SignInFirebaseProps {
  texts?: Texts<Text>;
}

type Text = "title";

const enTexts: Required<SignInFirebaseProps["texts"]> = {
  title: "Create an account or sign in.",
};

export const SignInFirebase: FC<SignInFirebaseProps> = ({
  texts = enTexts,
}) => (
  <GlobalFrame hideLogout showSignInErrorMsg>
    <Box width={["100%", "70%"]} mx="auto">
      <Box mt={4} mb={4} textAlign="left">
        <Typography variant="h5">{texts.title}</Typography>
        <Typography mt={2} mb={2} variant="body1" color="text.secondary">
          <TermsAndConditions />
        </Typography>
      </Box>
      <SignIn />
    </Box>
  </GlobalFrame>
);
