import { Tabs, Tab } from "@mui/material";
import React, { type FC, useEffect, useMemo } from "react";
import { useRecoilState } from "recoil";

import { LoaderLayout } from "#shared/components/layouts";
import { useTabNavigation } from "#shared/hooks";

import {
  MainPageLayout,
  useKubernetesCheck,
} from "#organization/components/layouts";
import { useHeaderSectionUpdate } from "#organization/recoil/organization/header-section";
import { projectContextState } from "#organization/recoil/project";

import { NoControlPoints } from "./no-control-points";
import { CONTROL_POINTS_ROUTE } from "./routes.definitions";
import {
  ControlPointsFlowControlTab,
  ControlPointsKubernetesTab,
} from "./tabs";
import { useFlowControl } from "./tabs/flow-control/hooks";
import { useControlPointsKubernetes } from "./tabs/kubernetes/hooks";

const tabs = [
  {
    path: "flow-control",
    name: "Flow Control",
    component: ControlPointsFlowControlTab,
  },
  {
    path: "kubernetes",
    name: "Kubernetes",
    component: ControlPointsKubernetesTab,
  },
];

export const ControlPointsPage: FC = () => {
  const [projectContext] = useRecoilState(projectContextState);
  const kubernetesControlPoints = useKubernetesCheck(projectContext.id);

  useHeaderSectionUpdate({
    pageTitle: CONTROL_POINTS_ROUTE.TITLE,
  });

  const { onTabsChange, currentTab } = useTabNavigation(
    tabs.map((tab) => tab.path),
    tabs[0].path,
  );

  const flowControl = useFlowControl();
  const controlPointsKubernetes = useControlPointsKubernetes();
  const noControlPoints =
    flowControl.isEmpty && controlPointsKubernetes.isEmpty;

  useHeaderSectionUpdate({
    pageTitle: CONTROL_POINTS_ROUTE.TITLE,
    children: useMemo(() => {
      if (noControlPoints) {
        return null;
      }

      let filteredTabs = tabs;

      if (!kubernetesControlPoints.isConfigured) {
        filteredTabs = filteredTabs.filter((tab) => tab.path !== "kubernetes");
      }

      return (
        <Tabs
          value={currentTab}
          onChange={onTabsChange}
          aria-label="control point tabs"
        >
          {filteredTabs.map((tab) => (
            <Tab label={tab.name} value={tab.path} key={tab.path} />
          ))}
        </Tabs>
      );
    }, [
      currentTab,
      noControlPoints,
      kubernetesControlPoints.isConfigured,
      onTabsChange,
    ]),
  });

  // refetch check for kubernetes flag when project context changes
  useEffect(() => {
    kubernetesControlPoints.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectContext]);

  if (kubernetesControlPoints.isLoading) {
    return <LoaderLayout />;
  }

  if (noControlPoints) {
    return <NoControlPoints />;
  }

  const TabComponent = tabs.find((tab) => tab.path === currentTab)?.component;

  return <MainPageLayout>{TabComponent && <TabComponent />}</MainPageLayout>;
};
