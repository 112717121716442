import { MenuItem, useTheme, type SelectProps } from "@mui/material";
import React, { type FC, forwardRef, useMemo } from "react";
import { useRecoilValue } from "recoil";

import {
  PermissionEnum,
  userRoleEnTexts,
  type UserRole,
  userNewState,
} from "#shared/recoil";
import type { Texts } from "#shared/types";

import { StyledSelect } from "./styled";

import type { FnTheme } from "../../../../../../../../../app-theme-provider/types";

export interface MemberRoleSelectProps
  extends Omit<SelectProps, "children" | "onChange"> {
  onChange: Required<SelectProps>["onChange"];
  texts?: Texts<Text>;
}

type Text = "delete" | UserRole;

const enTexts: Required<NonNullable<MemberRoleSelectProps["texts"]>> = {
  delete: "Delete",
  ...userRoleEnTexts,
};

export type InviteSelectListOptions = {
  value: PermissionEnum;
  text: string;
};

const valueAndText: InviteSelectListOptions[] = [
  {
    value: PermissionEnum.user_group_member,
    text: enTexts.user_group_member,
  },
  {
    value: PermissionEnum.user_group_admin,
    text: enTexts.user_group_admin,
  },
  {
    value: PermissionEnum.owner,
    text: enTexts.owner,
  },
];

export const useRoleListByPermission = () => {
  const currentUserRole = useRecoilValue(userNewState.userRole);

  const listOptions = useMemo(() => {
    if (!currentUserRole) {
      return [];
    }

    const options: Partial<InviteSelectListOptions>[] = [];

    valueAndText.forEach((item) => {
      if (
        item.value <
        PermissionEnum[currentUserRole as keyof typeof PermissionEnum]
      ) {
        options.push(item);
      }
    });

    return PermissionEnum[currentUserRole as keyof typeof PermissionEnum] !==
      PermissionEnum.owner
      ? options
      : valueAndText;
  }, [currentUserRole]);

  return listOptions;
};

export const MemberRoleSelect: FC<MemberRoleSelectProps> = forwardRef(
  ({ texts = enTexts, ...selectProps }, ref) => {
    const theme = useTheme() as unknown as FnTheme;

    const listOptions = useRoleListByPermission();

    return (
      <StyledSelect
        size="medium"
        defaultValue={PermissionEnum.user_group_member}
        MenuProps={{ sx: { zIndex: theme.zIndex.popoverInDialog } }}
        {...selectProps}
        ref={ref}
      >
        {listOptions.map(({ value, text }, i) => (
          <MenuItem key={[value, i].join("discoDancer")} value={value}>
            {text}
          </MenuItem>
        ))}
      </StyledSelect>
    );
  },
);
