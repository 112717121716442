import {
  Collapse,
  type CollapseProps,
  type ListItemProps,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { noop } from "lodash";
import React, { type FC, useRef, useState } from "react";

import type { Texts } from "#shared/types";

import {
  useHoverNodeState,
  useHoverSignalState,
} from "#organization/recoil/organization/flow-chart";

import { MenuListButton, type MenuListButtonProps } from "./menu-list-button";
import {
  ResourceMenuListItem as ListItem,
  type ResourcesMenuListItemProps,
} from "./menu-list-item";
import {
  OuterList,
  InnerList,
  type InnerListProps,
  type OuterListProps,
} from "./menu-list.styled";

type Text = "noItems";

export interface ResourcesMenuListProps {
  data: ResourcesMenuListItemProps[];
  outerListProps?: OuterListProps;
  innerListProps?: InnerListProps;
  menuListButtonProps?: MenuListButtonProps;
  collapseProps?: CollapseProps;
  texts?: Texts<Text>;
  isSelected: (listItem: ResourcesMenuListItemProps) => boolean;
}

const enTexts: Required<ResourcesMenuListProps["texts"]> = {
  noItems: "No items found",
};

export const ResourcesMenu: FC<ResourcesMenuListProps> = ({
  data,
  outerListProps,
  innerListProps,
  menuListButtonProps,
  collapseProps,
  texts = enTexts,
  isSelected,
}) => {
  const prevOpenRef = useRef(true);

  const [open, setOpen] = useState(prevOpenRef.current);

  const [, hoverNode] = useHoverNodeState();
  const [, hoverSignal] = useHoverSignalState();

  const onMouseEnter: ResourcesMenuListItemProps["onMouseEnter"] = (
    metaData,
  ) => {
    if ("componentId" in metaData) {
      hoverNode({
        componentId: metaData.componentId,
      });
    }

    if ("source" in metaData) {
      hoverSignal({
        hoverSourceSignal: `${metaData.source.componentId}.${metaData.source.portName}`,
        sourceComponentID: metaData.source.componentId,
      });
    }
  };

  const onMouseLeave: ListItemProps["onMouseLeave"] = () => {
    hoverNode(null);
    hoverSignal(null);
  };

  const onClick: MenuListButtonProps["onClick"] = (event) => {
    (menuListButtonProps?.onClick || noop)(event);

    setOpen(() => {
      prevOpenRef.current = open;

      return !open;
    });
  };

  return (
    <OuterList {...{ ...outerListProps, ...{ length: data.length }, open }}>
      <MenuListButton {...{ ...menuListButtonProps, onClick }} isOpen={open} />
      <Collapse in={open} timeout="auto" unmountOnExit {...collapseProps}>
        <InnerList component="div" disablePadding {...innerListProps}>
          {open &&
            data.length > 0 &&
            data.map((item) => (
              <ListItem
                sx={{
                  backgroundColor: isSelected(item)
                    ? "grey.listHeading"
                    : "transparent",
                }}
                key={item.item.uiData.id}
                {...item}
                {...{
                  ...item,
                  onMouseEnter,
                  onMouseLeave,
                }}
              />
            ))}
          {open && data.length === 0 && (
            <Box display="grid" pt={1} pl={1}>
              <Typography
                variant="subtitle1"
                color="text.disabled"
                fontStyle="italic"
              >
                {texts.noItems}
              </Typography>
            </Box>
          )}
        </InnerList>
      </Collapse>
    </OuterList>
  );
};
