import { Box, Paper, Typography } from "@mui/material";
import type { RJSFSchema } from "@rjsf/utils";
// eslint-disable-next-line import/no-extraneous-dependencies
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { configureMonacoYaml } from "monaco-yaml";
import React, {
  type FC,
  useRef,
  useEffect,
  useState,
  useCallback,
} from "react";

import { AnimateHighlightText } from "#shared/components/animate";

import { useRecoilYamlEditor } from "#organization/recoil/monaco-editor";

import { YAMLErrors } from "./yaml-errors";

import { useCreatePolicyContext } from "../context";
import { useYamlEditor } from "../hooks";

const FN_FONT_FAMILY = "Consolas, monaco, monospace";
const SUGGESTION_CMD = "Ctrl/Cmd + Space (for suggestions)";
export interface BlueprintPolicyEditorProps {
  schemaData: {
    schema: RJSFSchema;
    path: string;
  };
  defaultValue?: string;
  enableAutoTriggerSuggestion?: boolean;
  editorHeight?: number | string;
  updateGlobalState?: boolean;
}

export const BlueprintPolicyEditor: FC<BlueprintPolicyEditorProps> = ({
  schemaData: { schema, path: uri },
  defaultValue,
  enableAutoTriggerSuggestion = true,
  editorHeight,
  updateGlobalState = true,
}) => {
  const props = usePolicyEditor({
    defaultValue,
    enableAutoTriggerSuggestion,
    schema,
    uri,
    updateGlobalState,
  });

  return <YAMLEditor {...{ ...props, editorHeight }} />;
};

export interface YAMLEditorProps {
  editor: monaco.editor.IStandaloneCodeEditor | null;
  monacoEl: React.RefObject<HTMLElement>;
  editorHeight?: number | string;
  configureEditor?: () => void;
  createEditor?: () => void;
}

export const YAMLEditor: FC<YAMLEditorProps> = ({
  editor,
  monacoEl,
  editorHeight,
  configureEditor,
  createEditor,
}) => {
  useEffect(() => {
    if (!configureEditor || !createEditor) return;

    if (!editor) {
      configureEditor();
      createEditor();
    }
  }, [configureEditor, createEditor, editor]);

  return (
    <Box
      sx={{
        overflowX: "hidden",
      }}
    >
      <Typography
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        variant="body1"
        fontSize="small"
        fontWeight={500}
        color="primary"
        pb={1}
      >
        <AnimateHighlightText>{SUGGESTION_CMD}</AnimateHighlightText>
      </Typography>

      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: editorHeight || "calc(100vh - 500px)",
        }}
      >
        <Box
          elevation={2}
          component={Paper}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          ref={monacoEl}
          className="react-monaco-editor-container"
        />
      </Box>
      <YAMLErrors
        {...{
          editor,
        }}
      />
    </Box>
  );
};

export declare type UsePolicyEditorProps = {
  defaultValue?: string;
  enableAutoTriggerSuggestion?: boolean;
  schema?: RJSFSchema;
  updateGlobalState?: boolean;
  uri: string;
  monacoEditorOptions?: monaco.editor.IStandaloneEditorConstructionOptions;
};

export const usePolicyEditor = ({
  defaultValue,
  enableAutoTriggerSuggestion = true,
  schema,
  uri,
  updateGlobalState = true,
  monacoEditorOptions = {},
}: UsePolicyEditorProps) => {
  const [editor, setEditor] =
    useState<monaco.editor.IStandaloneCodeEditor | null>(null);
  const monacoEl = useRef<HTMLElement>(null);

  useYamlEditor(
    editor,
    enableAutoTriggerSuggestion && Boolean(!defaultValue) && !!schema,
    schema,
    updateGlobalState,
  );

  const { editorSavedState, editorModel } = useCreatePolicyContext();

  const [{ editorUpdateFunc }, setEditorUpdateFunc] = useRecoilYamlEditor();

  const createEditor = useCallback(() => {
    setEditor((ed) => {
      if (ed) return ed;

      const monacoEditor = monaco.editor.create(monacoEl.current!, {
        language: "yaml",
        automaticLayout: true,
        value: defaultValue,
        fontFamily: FN_FONT_FAMILY,
        fontSize: 16,
        lineHeight: 25,
        tabSize: 2,
        padding: {
          top: 10,
          bottom: 10,
        },
        fixedOverflowWidgets: true,
        wordBasedSuggestions: false,
        scrollBeyondLastLine: false,
        minimap: { enabled: false },
        overviewRulerBorder: false,
        wordWrap: "on",
        ...monacoEditorOptions,
      });

      if (editorSavedState && editorModel && updateGlobalState) {
        monacoEditor.setModel(editorModel);
        monacoEditor.restoreViewState(editorSavedState);
      }

      return monacoEditor;
    });
  }, [
    defaultValue,
    editorModel,
    editorSavedState,
    monacoEditorOptions,
    updateGlobalState,
  ]);

  const configureEditor = useCallback(() => {
    if (!monacoEl.current) return;

    const schemaOptions = {
      uri,
      schema,
      fileMatch: ["*"],
    };

    if (editorUpdateFunc) {
      editorUpdateFunc({
        schemas: [schemaOptions],
      });

      return;
    }

    const { update } = configureMonacoYaml(monaco, {
      validate: true,
      format: true,
      enableSchemaRequest: true,
      hover: true,
      completion: true,
      schemas: [schemaOptions],
    });

    setEditorUpdateFunc({
      editorUpdateFunc: update,
    });
  }, [editorUpdateFunc, schema, setEditorUpdateFunc, uri]);

  return {
    editor,
    monacoEl,
    configureEditor,
    createEditor,
  };
};
