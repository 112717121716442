import React, {
  type FC,
  type PropsWithChildren,
  useMemo,
  useReducer,
  useState,
} from "react";

import {
  CircuitContext,
  circuitContext,
  type CircuitContextProps,
  type CircuitReducer,
  circuitReducer,
} from "./context";

export const CircuitProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer<CircuitReducer>(
    circuitReducer,
    circuitContext,
  );
  const [isFlyoutMenuVisible, setIsFlyoutMenuVisible] = useState(false);

  const value: CircuitContextProps = useMemo(
    () => ({
      ...state,
      circuitDispatch: dispatch,
      isFlyoutMenuVisible,
      setIsFlyoutMenuVisible,
    }),
    [isFlyoutMenuVisible, state],
  );

  return (
    <CircuitContext.Provider value={value}>{children}</CircuitContext.Provider>
  );
};
