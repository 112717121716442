import React, { type FC, type PropsWithChildren } from "react";
import { Route, Routes } from "react-router-dom";

import { useSetPageTitle } from "#shared/hooks";

import { TopBarLayout } from "#organization/components/layouts";

import { ACCOUNT_SETTINGS_ROUTE, AccountSettingsRoutes } from "./account";
import { CompleteSubscriptionRoutes } from "./complete-subscription";
import { COMPLETE_SUBSCRIPTION_ROUTE } from "./complete-subscription/routes.definitions";
import {
  OrganizationSettingsRoutes,
  ORGANIZATION_SETTINGS_ROUTE,
} from "./organization";
import { SettingsPage } from "./page";
import { SETTINGS_ROUTE } from "./routes.definitions";

export const SettingsRoutes: FC<PropsWithChildren> = () => {
  useSetPageTitle(SETTINGS_ROUTE.TITLE);

  return (
    <TopBarLayout>
      <Routes>
        <Route
          path={ORGANIZATION_SETTINGS_ROUTE.PARENT_PATH}
          element={<OrganizationSettingsRoutes />}
        />
        <Route
          path={ACCOUNT_SETTINGS_ROUTE.PARENT_PATH}
          element={<AccountSettingsRoutes />}
        />
        <Route
          path={COMPLETE_SUBSCRIPTION_ROUTE.PARENT_PATH}
          element={<CompleteSubscriptionRoutes />}
        />
        <Route index element={<SettingsPage />} />
      </Routes>
    </TopBarLayout>
  );
};
