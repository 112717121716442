import { gql } from "graphql-request";

import { HEALTH_STATUSES } from "#shared/consts";
import type {
  GetControllersDataQuery,
  ControllersGroupsTitlesQuery,
} from "#shared/generated/graphql";

import {
  useGridData,
  useGroupData,
  useGroupsTitles,
  type DataGridHook,
  type GroupDataHook,
  type GroupsTitlesHook,
} from "#organization/hooks";

import { orderByStatusTemplate } from "../utils";

export type ControllersGroupBy = "name" | "version" | "agentKeyID" | "status";

export const CONTROLLERS_ENTITY_KEY = "controllers";

export const controllersGroupsTitles = gql`
  query ControllersGroupsTitles(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: ControllerBoolExp
    $distinctOn: [ControllerSelectColumn!]
  ) {
    controllers(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      distinctOn: $distinctOn
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          name
          version
          lastSeen
          id
          status
          agentKey {
            key
          }
        }
      }
    }
  }
`;

type ControllersGroupTitleData =
  ControllersGroupsTitlesQuery["controllers"]["edges"][0]["node"];

export const useControllersGroupsTitles: GroupsTitlesHook<
  ControllersGroupBy
> = (pageSize, page, setPage, groupByKey, filterVariables) =>
  useGroupsTitles<ControllersGroupBy, ControllersGroupTitleData>(
    CONTROLLERS_ENTITY_KEY,
    pageSize,
    page,
    setPage,
    groupByKey,
    controllersGroupsTitles,
    (node) => {
      if (groupByKey === "agentKeyID") {
        return node.agentKey.key;
      }

      return node[groupByKey!];
    },
    filterVariables,
  );

type ControllersGroupData =
  GetControllersDataQuery["controllers"]["edges"][0]["node"];
type AdaptedControllersGroupData = Omit<ControllersGroupData, "agentKey"> & {
  agentKey: string;
};

export const controllersGroupData = gql`
  query GetControllersData(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: ControllerBoolExp
    $orderBy: [ControllerOrderBy]
  ) {
    controllers(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          name
          id
          version
          lastSeen
          status
          agents(first: 100, where: { status: { eq: "${HEALTH_STATUSES.HEALTHY}" } }) {
            totalCount
          }
          agentKey {
            key
          }
        }
      }
    }
  }
`;

export const useControllersGroupData: GroupDataHook<ControllersGroupBy> = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  groupByKey,
  groupByValue,
  filterVariables,
) =>
  useGroupData<
    ControllersGroupBy,
    ControllersGroupData,
    AdaptedControllersGroupData
  >(
    CONTROLLERS_ENTITY_KEY,
    controllersGroupData,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    groupByKey,
    groupByValue,
    (controller) => ({
      ...controller,
      agentKey: controller.agentKey.key,
    }),
    filterVariables,
    {
      agentKeyID: {
        agentKey: {
          key: {
            eq: groupByValue,
          },
        },
      },
    },
  );

export const useControllersData: DataGridHook = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  enabled,
  filterVariables,
) =>
  useGridData<ControllersGroupData, AdaptedControllersGroupData>(
    CONTROLLERS_ENTITY_KEY,
    controllersGroupData,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    filterVariables,
    (controller: ControllersGroupData) => ({
      ...controller,
      agentKey: controller.agentKey.key,
    }),
    enabled,
    true,
    orderByStatusTemplate,
  );
