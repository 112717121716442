import type { ListItemProps, ListItemTextProps } from "@mui/material";
import { noop } from "lodash";
import React, { type FC } from "react";

import type { AnyObject } from "#shared/types";

import type { UiData } from "#organization/pages/authenticated/policies/policy/types";
import type { WithUiData } from "#organization/pages/types";

import { ListItem, ListItemText } from "./menu-list-item.styled";

export type ResourcesMenuListItemProps = Omit<
  ListItemProps,
  "onMouseEnter" | "disabled" | "onClick"
> & {
  item: MenuItem;
  onMouseEnter?: Callback<void>;
  onClick?: Callback<void>;
  disabled?: Callback<boolean>;
  listItemTextProps?: ListItemTextProps;
};

export type MenuItem<O extends AnyObject = AnyObject> = WithUiData<O, UiData>;

type Callback<V> = (item: MenuItem) => V;

export const ResourceMenuListItem: FC<ResourcesMenuListItemProps> = ({
  item,
  listItemTextProps,
  onClick = noop,
  onMouseEnter = noop,
  disabled = () => false,
  children,
  ...listItemProps
}) => (
  <ListItem
    {...listItemProps}
    onClick={() => onClick(item)}
    onMouseEnter={() => onMouseEnter(item)}
    disabled={disabled(item)}
  >
    <ListItemText {...listItemTextProps}>{children}</ListItemText>
  </ListItem>
);
