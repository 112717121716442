import { useMemo } from "react";

import type {
  UseDashboardQueryParamsOptions,
  UseDashboardQueryParamsReturnType,
} from "./types";

import type { NodeName } from "../../../../../../../types";
import { addPathToCircuitGql } from "../../hooks";
import { DashboardsUtils, type DashboardData } from "../components";
import { useDashboardDataQuery } from "../components/dashboard/hooks";
import { PolicyUtils } from "../policy-utils";
import { useCircuitContext } from "../tabs/circuit/circuit-context";

/**
 *
 * This hook is used to fill values in query param for grafana dashboards.
 *
 * These filters are used in generic dashboards.
 *
 * For example: ConcurrencyLimiter, RateLimiter, Signal, etc.
 *
 */
export function useDashboardQueryParams({
  policy,
  resourceType,
  dashboardType,
  ...options
}: UseDashboardQueryParamsOptions): UseDashboardQueryParamsReturnType {
  const resourceId = options.resourceId
    ? decodeURIComponent(options.resourceId)
    : null;

  const isCircuitItem =
    PolicyUtils.isLimiter(null, resourceType as NodeName) ||
    resourceType?.toLowerCase() === "signal";

  const dashboardOptions =
    resourceType &&
    dashboardType &&
    DashboardsUtils.optionsByResourceAndDashboardType[resourceType]
      ? DashboardsUtils.optionsByResourceAndDashboardType[resourceType][
          dashboardType
        ]
      : null;

  const { nestedCircuitGqlString } = useCircuitContext();

  const dashboardOptionsUpdated = useMemo(
    () =>
      dashboardOptions?.queryKey === "DASHBOARD_CIRCUIT" &&
      nestedCircuitGqlString.length
        ? {
            ...dashboardOptions,
            queryKey: ["DASHBOARD_CIRCUIT", nestedCircuitGqlString].join(
              "-",
            ) as "DASHBOARD_CIRCUIT",
            gql: addPathToCircuitGql(
              dashboardOptions.gql,
              nestedCircuitGqlString,
            ),
          }
        : dashboardOptions,
    [dashboardOptions, nestedCircuitGqlString],
  );

  // NOTE: could be replaced with policy data
  const { isLoading, isError, isIdle, isFetched, data } = useDashboardDataQuery(
    {
      queryKey: dashboardOptionsUpdated?.queryKey || "INVALID_QUERY",
      gql: dashboardOptionsUpdated?.gql || "",
      ...(isCircuitItem
        ? {
            variables: {
              where: {
                id: { eq: policy.id },
              },
            },
          }
        : {
            variables: {
              where: {
                id: { eq: resourceId },
              },
            },
          }),
    },
  );

  const { resourceName = null, queryParams = null } =
    resourceId && data && dashboardOptionsUpdated
      ? (dashboardOptionsUpdated.mapToDashboardData(
          policy,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          dashboardOptionsUpdated.accessor(data as any, resourceId) as any,
        ) as DashboardData)
      : {};

  return resourceId
    ? {
        resourceName,
        queryParams,
        isLoading,
        isError,
        isIdle,
        isFetched,
      }
    : {
        resourceName: "",
        queryParams: {},
        isLoading: false,
        isError: false,
        isFetched: false,
        isIdle,
      };
}
