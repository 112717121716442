import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import {
  IconButton,
  type IconButtonProps,
  type SvgIconProps,
  Tooltip,
  type TooltipProps,
} from "@mui/material";
import React, { type Dispatch, type FC, type SetStateAction } from "react";

import type { Texts } from "#shared/types";

export interface FullScreenModeButtonProps
  extends Omit<IconButtonProps, "children" | "onClick"> {
  useFullScreen: [boolean, Dispatch<SetStateAction<boolean>>];
  iconProps?: SvgIconProps;
  texts?: Texts<Text>;
  tooltipProps?: Omit<TooltipProps, "children">;
}

type Text = "fullPage" | "sidePanel";

const enTexts: Required<FullScreenModeButtonProps["texts"]> = {
  fullPage: "Open as full page",
  sidePanel: "Exit full page mode",
};

export const FullScreenModeButton: FC<FullScreenModeButtonProps> = ({
  useFullScreen,
  iconProps,
  texts = enTexts,
  tooltipProps,
  ...props
}) => {
  const [fullscreen, setFullScreen] = useFullScreen;

  const onClick = () => {
    setFullScreen((prev) => !prev);
  };

  return (
    <Tooltip
      placement="top"
      title={fullscreen ? texts.sidePanel : texts.fullPage}
      arrow
      {...tooltipProps}
      PopperProps={{
        sx: { margin: 0 },
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
        ...tooltipProps?.PopperProps,
      }}
    >
      <IconButton onClick={onClick} color="inherit" {...props}>
        {fullscreen ? (
          <FullscreenExitIcon {...iconProps} />
        ) : (
          <FullscreenIcon {...iconProps} />
        )}
      </IconButton>
    </Tooltip>
  );
};
