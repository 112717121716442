import { Tab, type TabProps } from "@mui/material";
import type { CurriedFunction2 } from "lodash";
import React, { type FC, useMemo } from "react";

import { DropdownTab } from "#organization/components";
import type { ResourcesMenuListItemProps } from "#organization/components/resources-menu";
import type { WithUiData } from "#organization/pages/types";

import type { DashboardFolderSearch } from "../../../../hooks";
import type {
  SelectedResource,
  SelectedResources,
} from "../../../../selected-resource-context";
import type { UiData } from "../../../../types";

export type DashboardTabProps = {
  dashboards: WithUiData<DashboardFolderSearch, UiData>[];
  isSelected: (listItem: ResourcesMenuListItemProps) => boolean;
  selectOne: CurriedFunction2<keyof SelectedResources, SelectedResource, void>;
} & TabProps;

export const DashboardTab: FC<DashboardTabProps> = ({
  dashboards,
  isSelected,
  selectOne,
  ...props
}) => {
  const menuItems = useMemo(
    () =>
      dashboards.map((dashboard, index) => {
        const dashboardName =
          dashboard?.uiData?.componentName?.split(" - ")?.[0] ||
          `Dashboard ${index + 1}`;

        return {
          id: dashboardName,
          node: dashboardName,
          isSelected: isSelected({
            item: dashboard,
          }),
          onClick: () => {
            selectOne("Dashboards", dashboard);
          },
        };
      }),
    [dashboards, isSelected, selectOne],
  );

  if (menuItems.length > 1) {
    return <DropdownTab menuItems={menuItems} {...props} />;
  }

  return <Tab {...props} />;
};
