import {
  type IabLogoutUrl,
  type GlobalLogoutUrl,
  API_LOGOUT_URLS,
  type OrganizationLogoutUrl,
} from "../consts";

export class LogoutService {
  public static get globalUrl(): (
    | GlobalLogoutUrl
    | OrganizationLogoutUrl
    | IabLogoutUrl
  )[] {
    return [API_LOGOUT_URLS.GLOBAL(true)];
  }

  public static get organizationUrl(): (
    | OrganizationLogoutUrl
    | IabLogoutUrl
  )[] {
    return [
      API_LOGOUT_URLS.GLOBAL(false) as IabLogoutUrl,
      API_LOGOUT_URLS.ORGANIZATION,
    ];
  }

  public static getContextLogoutUrl(isOrganizationContext: boolean) {
    return isOrganizationContext
      ? LogoutService.organizationUrl
      : LogoutService.globalUrl;
  }

  public static get allLogoutUrls() {
    return (
      LogoutService.globalUrl as (
        | GlobalLogoutUrl
        | IabLogoutUrl
        | OrganizationLogoutUrl
      )[]
    ).concat(LogoutService.organizationUrl);
  }
}
