import React, { type FC, useContext } from "react";

import { FirebaseContext } from "#shared/contexts/firebase";
import { LoggerService } from "#shared/services";

import { SignInFirebase } from "../components";
import { CompleteSignInFirebase } from "../components/sign-in/complete-sign-in-firebase";

export const SignInPageFirebase: FC = () => {
  const { isSignInWithEmailLink } = useContext(FirebaseContext);

  LoggerService.debug("SignInPageFirebase", { isSignInWithEmailLink });

  return isSignInWithEmailLink ? (
    <CompleteSignInFirebase />
  ) : (
    <SignInFirebase />
  );
};
