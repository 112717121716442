import { Typography } from "@mui/material";
import { styled, ThemeProvider } from "@mui/system";
import React, { type FC } from "react";
import { useMatch } from "react-router-dom";

import { TextLink, type TextLinkProps } from "#shared/components/text-link";

import { useThemeState } from "#organization/recoil/theme";

import { navigationTheme } from "../../../../app-theme-provider";

export type BreadcrumbLinkProps = Omit<TextLinkProps, "to"> & { to: string };

const StyledBreadcrumbLink = styled(TextLink)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: "bold",
  "&:focus, &:hover, &:visited, &:link, &:active": {
    textDecoration: "none",
    fontWeight: "bold",
    color: theme.palette.grey[600],
  },
  "&:hover": {
    textDecoration: "underline",
  },
}));

export const BreadcrumbLink: FC<BreadcrumbLinkProps> = ({
  children,
  to,
  ...props
}) => {
  const match = useMatch({ path: to });
  const theme = useThemeState();

  if (match) {
    return <Typography {...props}>{children}</Typography>;
  }

  return (
    <ThemeProvider theme={navigationTheme(theme.mode)}>
      <StyledBreadcrumbLink to={to} {...props}>
        {children}
      </StyledBreadcrumbLink>
    </ThemeProvider>
  );
};
