import type { AlertsQuery } from "#shared/generated/graphql";

import {
  type DataGridHook,
  type GroupDataHook,
  type GroupsTitlesHook,
  useGridData,
  useGroupData,
  useGroupsTitles,
} from "#organization/hooks";
import { globalTimeParser } from "#organization/utils";

import type { AlertLogsGroupBy } from "./page";

import { alertLogsGQL } from "../../data-center";

export type AlertsData = AlertsQuery["alerts"]["edges"][0]["node"];

const ALERTS_TABLE_KEY = "alerts";

export const useAlertLogsData: DataGridHook = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  enabled,
  filterVariables,
) =>
  useGridData<AlertsData, AlertsData>(
    ALERTS_TABLE_KEY,
    alertLogsGQL,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    filterVariables,
    mapToAdaptableOutput,
    enabled,
  );

export const useAlertsGroupsTitles: GroupsTitlesHook<AlertLogsGroupBy> = (
  pageSize,
  page,
  setPage,
  groupByKey,
  filterVariables,
) => {
  const { query, ...other } = useGroupsTitles<AlertLogsGroupBy, AlertsData>(
    ALERTS_TABLE_KEY,
    pageSize,
    page,
    setPage,
    groupByKey,
    alertLogsGQL,
    (node) => node[groupByKey!],
    filterVariables,
  );

  return { query, ...other };
};

export const useAlertsGroupData: GroupDataHook<AlertLogsGroupBy | ""> = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  groupByKey,
  groupByValue,
  filterVariables,
) =>
  useGroupData<AlertLogsGroupBy | "", AlertsData, AlertsData>(
    ALERTS_TABLE_KEY,
    alertLogsGQL,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    groupByKey,
    groupByValue,
    mapToAdaptableOutput,
    filterVariables,
  );

export const mapToAdaptableOutput = ({ startsAt, ...logs }: AlertsData) => ({
  ...logs,
  startsAt: globalTimeParser(startsAt),
});
