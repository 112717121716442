import React, { type FC } from "react";
import { Route, Routes } from "react-router-dom";

import { BreadcrumbLink } from "#shared/components/breadcrumbs";

import { TopBarBreadcrumbAppender } from "#organization/recoil/breadcrumbs";

import { ProjectsPage } from "./page";
import { ProjectPage } from "./project";
import { PROJECTS_SETTINGS_ROUTE } from "./routes.definitions";

export const SettingsProjectsRoutes: FC = () => (
  <>
    <TopBarBreadcrumbAppender>
      <BreadcrumbLink to={PROJECTS_SETTINGS_ROUTE.ABSOLUTE_PATH}>
        {PROJECTS_SETTINGS_ROUTE.TITLE}
      </BreadcrumbLink>
    </TopBarBreadcrumbAppender>

    <Routes>
      <Route
        path={PROJECTS_SETTINGS_ROUTE.CHILD_PATH}
        element={<ProjectPage />}
      />
      <Route index element={<ProjectsPage />} />
    </Routes>
  </>
);
