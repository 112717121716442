import { get } from "lodash";
import { useMemo } from "react";

import { LoggerService } from "#shared/services/logger";

import { dashboardsByResourceType } from "#organization/pages/authenticated/dashboard/config";
import type {
  DashboardConfig,
  ResourceWithDashboard,
} from "#organization/pages/types";
import { useProjectContextState } from "#organization/recoil/project";

import {
  HIDE_FILTERS_BY_DASHBOARD_TYPE,
  type GenericDashboardType,
} from "../../../components/grafana/consts";
import type { UseGrafanaDashboardOptions } from "../../../components/grafana/hooks";
import type { DashboardQueryParam } from "../../types";

type UseDashboardProps = {
  [K in keyof Omit<
    UseGrafanaDashboardOptions,
    "containerRef"
  >]: K extends "queryParams"
    ? Omit<Required<UseGrafanaDashboardOptions>, "containerRef">[K]
    : NonNullable<
        Omit<Required<UseGrafanaDashboardOptions>, "containerRef">[K]
      >;
};

/**
 * NOTE:
 *
 * 1. Look up dashboard's configuration by
 * - resource type (e.g. fluxMeter, classifier, concurrencyLimiter, rateLimiter)
 * - dashboard type (optional, e.g. FLOW_ANALYTICS, PROMETHEUS)
 *
 * 2. Return dashboard props
 *
 * Dashboard props contain page filters (var-policy_name, var-fn_project_id) that are merged with optional 'queryParams' arg
 */
export const useDashboardProps = <D extends GenericDashboardType>(
  resourceType: ResourceWithDashboard | null,
  dashboardType: D | null,
  queryParams?: DashboardConfig<D>["queryParams"] | null,
  hiddenVariables: UseGrafanaDashboardOptions["hiddenVariables"] = [],
): UseDashboardProps | null => {
  const { id } = useProjectContextState();

  const dashboardProps = useMemo<UseDashboardProps | null>(() => {
    const config = get(
      dashboardsByResourceType,
      [resourceType, dashboardType].filter(Boolean).join("."),
    ) as DashboardConfig;

    if (!config || !dashboardType) {
      return null;
    }

    const mergedHiddenVariables = [
      ...(config.hiddenVariables || []),
      ...(hiddenVariables || []),
      ...HIDE_FILTERS_BY_DASHBOARD_TYPE[dashboardType](config.filters),
    ] as DashboardQueryParam[];

    const mergedQueryParams = !queryParams
      ? null
      : {
          "var-fn_project_id": id,
          ...queryParams,
        };

    const props: UseDashboardProps = {
      slug: config.slug,
      version: config.version,
      name: config.name,
      uuid: config.uuid,
      queryParams: mergedQueryParams,
      hiddenVariables: mergedHiddenVariables,
    };

    return props;
  }, [id, resourceType, dashboardType, hiddenVariables, queryParams]);

  if (!resourceType || !dashboardProps?.uuid) {
    LoggerService.verbose(
      "[FN]:",
      "Configuration of policy dashboard not found.",
      "Resource type:",
      resourceType,
      "Dashboard type:",
      dashboardType,
    );

    return null;
  }

  return dashboardProps;
};
