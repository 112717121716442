import { find, pick } from "lodash";
import { useMemo } from "react";

import type { Link, SignalName } from "#shared/generated/graphql";

import type {
  EdgeData,
  UiData,
} from "#organization/pages/authenticated/policies/policy/types";
import type { WithUiData } from "#organization/pages/types";

import { mapToListItem as map } from "./common";
import type { UseMenuOptions } from "./types";
import { useMenu } from "./use-menu";

import { PolicyUtils } from "../../../policy-utils";

export function useSignalsMenu(
  options: Pick<UseMenuOptions<EdgeData>, "policy" | "search">,
) {
  const accessor: UseMenuOptions<EdgeData>["accessor"] = useMemo(
    () =>
      ({ circuit }) =>
        PolicyUtils.mergeInternalAndExternalLinks(circuit.graph) as Link[],
    [],
  );

  const filterPredicate: UseMenuOptions<EdgeData>["filterPredicate"] =
    (_, search) => (edge, index, edges) => {
      const signalName = edge.value
        ? (edge.value as unknown as SignalName).signalName
        : undefined;

      if (!signalName) return false;

      const isUnique = !find(edges.slice(index + 1), { value: edge.value });

      const isSearched =
        !search || new RegExp(search, "i").test(signalName || "");

      return isUnique && isSearched;
    };

  const mapToListItem: UseMenuOptions<EdgeData>["mapToListItem"] = useMemo(
    () => () => (data) => {
      const signalName = data.value
        ? (data.value as unknown as SignalName).signalName
        : undefined;

      const listItem = map<EdgeData>(
        "value.signalName",
        (link) => PolicyUtils.encodeEdgeId(link),
        () => undefined,
      )(data);

      /**
       * NOTE:
       * We deduplicate signals.
       * That's why we should not use other data than the uiData,
       * because it may be misleading.
       */
      const withUiData = {
        ...pick(listItem, "uiData"),
        value: {
          signalName,
        },
      } as WithUiData<EdgeData, UiData>;

      return withUiData;
    },
    [],
  );

  return useMenu({ ...options, mapToListItem, accessor, filterPredicate });
}
