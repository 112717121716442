import { CodeSnippet } from "#organization/components"

export const language = "ts";

# Limit By Label Key

## Overview

The `limit_by_label_key` determines the specific label key used for request scheduling.

## Example

For instance, `limit_by_label_key` can be set as `user_id`. This way, each user will have its own token bucket and, if the `startFlow` call contains the `user_id`, Aperture will either allow or queue the requests on a per user basis.

<CodeSnippet lang={language} snippetName="QSUI" />

## Note

In the absence of a specified `limit_by_label_key`, quota scheduling will default to a global bucket for all requests matching the criteria. Defining a label key allows for more granular quota scheduling per user or per device.
