import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  CircularProgress,
  MenuItem,
  Paper,
  TextField,
  Popper,
  Box,
  Button,
} from "@mui/material";
import { isFunction, noop } from "lodash";
import React, {
  useMemo,
  type FC,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import {
  useFormContext,
  type FieldValues,
  type Path,
  Controller,
  type ControllerRenderProps,
  type PathValue,
} from "react-hook-form";

import { useArrayFields, useOnClickDeleteFields } from "./hooks";
import { LabelMatcher } from "./label-matcher";
import type { BlueprintFieldProps } from "./types";
import { createFieldNameWithPrefix } from "./utils";

import { useUniqueControlPointNames } from "../../../../hooks/controlPoints";
import "../../inputs";
import {
  DeleteSelectorButton,
  FieldWithTitle,
  FormFieldWrapper,
  TreeItemStyled,
} from "../../styled";
import { validateFieldWithRequiredSign } from "../consts";
import * as RateLimitBlueprint from "../rate-limiting/@types";

export const useControlPointHelper = () => {
  const [points, setControlPoints] = useState<string[] | undefined>();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const inputRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!inputRef.current) return;

    if (points?.length && !anchorEl) {
      setAnchorEl(inputRef.current);
    }

    if (!points?.length && anchorEl) {
      setAnchorEl(null);
    }
  }, [anchorEl, points]);

  return {
    points,
    setControlPoints,
    anchorEl,
    setAnchorEl,
    inputRef,
  };
};

export declare type SelectorControlPointInputProps<
  TFields extends FieldValues = FieldValues,
> = {
  index: number;
  getDocMdFileAddress?: (fieldName: string) => string;
} & BlueprintFieldProps<TFields>;

export const SelectorControlPointInput = <
  TFields extends FieldValues = RateLimitBlueprint.Coordinate,
>({
  fieldsPrefix = "policy.rate_limiter" as Path<TFields>,
  label = "Control point",
  index,
  getDocMdFileAddress,
}: SelectorControlPointInputProps<TFields>) => {
  const { control, setValue, getValues } = useFormContext<TFields>();

  const { points, setControlPoints, anchorEl, setAnchorEl, inputRef } =
    useControlPointHelper();

  const fieldNameRef = useRef(["selectors", index, "control_point"].join("."));

  const {
    isLoading,
    isError,
    data: controlPoints,
  } = useUniqueControlPointNames();

  const defaultValues = getValues(fieldsPrefix);

  const currentSelectorValues = useMemo(
    () => defaultValues?.selectors?.[index]?.control_point,
    [defaultValues?.selectors, index],
  );

  const controlPointFieldAddress = createFieldNameWithPrefix(
    fieldsPrefix,
    fieldNameRef.current,
  );

  const onChangeControlPoints = useCallback(
    (field: ControllerRenderProps<TFields, Path<TFields>>) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        field.onChange(value);

        setControlPoints([
          ...(controlPoints?.filter((point) => point.includes(value)) || []),
        ]);
      },
    [controlPoints, setControlPoints],
  );

  return (
    <>
      {isLoading && !isError ? (
        <CircularProgress />
      ) : (
        <FieldWithTitle
          label={label}
          fieldAddress={
            isFunction(getDocMdFileAddress)
              ? getDocMdFileAddress("control_point")
              : controlPointFieldAddress
          }
          required
        >
          <Controller
            name={controlPointFieldAddress}
            defaultValue={currentSelectorValues || ""}
            rules={validateFieldWithRequiredSign<TFields>(
              "control_point is required",
            )}
            control={control}
            render={({ field, fieldState }) => (
              <>
                <PopOverControlPointsList
                  controlPoints={points}
                  useAnchorEl={[anchorEl, setAnchorEl]}
                  onClose={(c) => {
                    setControlPoints(undefined);

                    setValue(
                      controlPointFieldAddress,
                      c as PathValue<TFields, typeof controlPointFieldAddress>,
                    );
                  }}
                />
                <TextField
                  aria-describedby="control-point-suggestions-popover"
                  {...{
                    ...field,
                    ref: inputRef,
                    placeholder: "Control point",
                    onChange: onChangeControlPoints(field),
                    error: !!fieldState.error,
                    helperText: fieldState.error?.message,
                  }}
                />
              </>
            )}
          />
        </FieldWithTitle>
      )}
    </>
  );
};

export interface PopOverControlPointsListProps {
  controlPoints?: string[];
  useAnchorEl: [
    HTMLDivElement | null,
    React.Dispatch<React.SetStateAction<HTMLDivElement | null>>,
  ];
  onClose?: (v: string) => void;
}

export const PopOverControlPointsList: FC<PopOverControlPointsListProps> = ({
  controlPoints,
  useAnchorEl,
  onClose = noop,
}) => {
  const [anchorEl] = useAnchorEl;

  const open = Boolean(anchorEl);
  const id = open ? "control-point-suggestions-popover" : undefined;

  return (
    <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
      <Box sx={{ width: 350 }} component={Paper} elevation={5} mt={0.5}>
        {controlPoints?.map((controlPoint, i) => (
          <MenuItem
            onClick={() => onClose(controlPoint)}
            key={[controlPoint, i].join("-")}
          >
            {controlPoint}
          </MenuItem>
        ))}
      </Box>
    </Popper>
  );
};

const SELECTOR_DEFAULTS = {
  control_point: "",
  agent_group: "aperture-cloud",
  service: "any",
};

export declare type SelectorProps<
  TFields extends FieldValues = RateLimitBlueprint.Coordinate,
> = {
  index: number;
  setSelectorList: React.Dispatch<React.SetStateAction<string[]>>;
} & BlueprintFieldProps<TFields>;

export const Selector = <
  TFields extends FieldValues = RateLimitBlueprint.Coordinate,
>({
  fieldsPrefix = "policy.rate_limiter" as Path<TFields>,
  index,
  setSelectorList,
}: SelectorProps<TFields>) => {
  const { control, getValues } = useFormContext<TFields>();

  const selectorAddress = createFieldNameWithPrefix(
    fieldsPrefix,
    ["selectors", index].join("."),
  );

  const defaultValues = getValues(fieldsPrefix);

  const currentSelectorValues = useMemo(
    () => defaultValues?.selectors?.[index],
    [defaultValues?.selectors, index],
  );

  const onClickDeleteSelector = useOnClickDeleteFields<TFields>({
    fieldsPrefix: [fieldsPrefix, "selectors"].join(".") as Path<TFields>,
    index,
    setFieldList: setSelectorList,
  });

  const docMdFileAddress = useCallback(
    (fieldName: string) =>
      createFieldNameWithPrefix(
        fieldsPrefix,
        ["selectors", "0", fieldName].join("."),
      ),
    [fieldsPrefix],
  );

  return (
    <Box py={2} position="relative">
      <DeleteSelectorButton
        startIcon={<DeleteOutlineOutlinedIcon />}
        variant="outlined"
        size="small"
        onClick={onClickDeleteSelector}
      >
        Delete
      </DeleteSelectorButton>
      <FormFieldWrapper>
        <SelectorControlPointInput<TFields>
          {...{ index, getDocMdFileAddress: docMdFileAddress, fieldsPrefix }}
        />
      </FormFieldWrapper>

      <TreeItemStyled nodeId={`${selectorAddress}.advanced`} label="Advanced">
        <FieldWithTitle
          label="Label Matcher"
          fieldAddress={docMdFileAddress("label_matcher")}
        >
          <LabelMatcher
            labelMatcherAddress={createFieldNameWithPrefix(
              selectorAddress,
              "label_matcher",
            )}
            lengthOfSelectors={index}
          />
        </FieldWithTitle>
        <FormFieldWrapper>
          <Controller
            name={createFieldNameWithPrefix(selectorAddress, "agent_group")}
            defaultValue={
              currentSelectorValues?.agent_group ||
              SELECTOR_DEFAULTS.agent_group
            }
            control={control}
            render={({ field, fieldState }) => (
              <FieldWithTitle
                label="Agent group"
                fieldAddress={createFieldNameWithPrefix(
                  selectorAddress,
                  "agent_group",
                )}
              >
                <TextField
                  {...{
                    ...field,
                    placeholder: "Agent group",
                    error: !!fieldState.error,
                    helperText: fieldState.error?.message,
                  }}
                />
              </FieldWithTitle>
            )}
          />

          <Controller
            name={createFieldNameWithPrefix(selectorAddress, "service")}
            defaultValue={
              currentSelectorValues?.service || SELECTOR_DEFAULTS.service
            }
            control={control}
            render={({ field, fieldState }) => (
              <FieldWithTitle
                label="Service"
                fieldAddress={docMdFileAddress("service")}
              >
                <TextField
                  {...{
                    ...field,
                    placeholder: "Service",
                    error: !!fieldState.error,
                    helperText: fieldState.error?.message,
                  }}
                />
              </FieldWithTitle>
            )}
          />
        </FormFieldWrapper>
      </TreeItemStyled>
    </Box>
  );
};

export const Selectors = <
  TFields extends FieldValues = RateLimitBlueprint.Coordinate,
>({
  fieldsPrefix = "policy.rate_limiter" as Path<TFields>,
}: BlueprintFieldProps<TFields>) => {
  const {
    fieldList: selectorList,
    addNewFields: addNewSelector,
    setFieldList: setSelectorList,
  } = useArrayFields<TFields>({
    fieldsPrefix: [fieldsPrefix, "selectors"].join(".") as Path<TFields>,
    defaultValues: SELECTOR_DEFAULTS,
  });

  return (
    <TreeItemStyled nodeId="selectors" label="Selectors">
      {selectorList.map((selector, i) => (
        <Selector
          key={[selector, i].join("-")}
          {...{
            fieldsPrefix,
            index: i,
            setSelectorList,
          }}
        />
      ))}
      <Box pb={2}>
        <Button
          variant="outlined"
          onClick={addNewSelector}
          startIcon={<AddOutlinedIcon />}
          size="small"
        >
          Add Selector
        </Button>
      </Box>
    </TreeItemStyled>
  );
};
