import { Logout } from "@mui/icons-material";
import {
  Toolbar,
  Box,
  Avatar,
  Popper,
  ListItem,
  ListItemAvatar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, {
  type FC,
  useRef,
  useState,
  type MouseEventHandler,
} from "react";
import { useRecoilValue } from "recoil";

import { BreadcrumbsContainer } from "#shared/components/breadcrumbs";
import { MenuListItemRow } from "#shared/components/menu-list-item-row";
import { TopBarMenu } from "#shared/components/top-bar-menu";
import { TopBarUser } from "#shared/components/top-bar-user";
import { useLogout } from "#shared/hooks";
import { userNewState } from "#shared/recoil";
import type { Texts } from "#shared/types";
import { getGlobalAppUrl } from "#shared/utils";

import { useGrafanaContext } from "#organization/components/grafana/grafana-context";
import { UserDropdown } from "#organization/pages/authenticated/sidebar/components/user-dropdown";
import { NestedMenuItem } from "#organization/pages/authenticated/sidebar/components/user-dropdown/components/nested-menu-item";
import { breadcrumbsInTopBarAtom } from "#organization/recoil/breadcrumbs/top-bar/atom";
import { useOrganizationState } from "#organization/recoil/organization";

import { HamburgerButton } from "./hamburger-button";

import { ACCOUNT_SETTINGS_ROUTE } from "../../../pages/authenticated/settings/account";
import { ThemeSwitch } from "../../theme-switch/theme-switch";

const StyledToolbar = styled(Toolbar)(({ theme }) => {
  const padding = theme.spacing(2);

  return {
    // TODO: find where we add padding and remove important
    paddingLeft: `${padding}!important`,
    paddingRight: `${padding}!important`,
    display: "grid",
    gridTemplateColumns: `auto auto`,
  };
});

export const BreadcrumbsBar: FC = () => {
  const breadcrumbs = useRecoilValue(breadcrumbsInTopBarAtom);

  return <BreadcrumbsContainer>{breadcrumbs}</BreadcrumbsContainer>;
};

export interface TopBarProps {
  texts?: Texts<Text>;
}

type Text = "switchOrg" | "orgsError";

const enTexts: Required<TopBarProps["texts"]> = {
  switchOrg: "Switch organization",
  orgsError: "Error when fetching organizations.",
};

export const TopBar: FC<TopBarProps> = ({ texts = enTexts }) => {
  const organization = useOrganizationState();
  const { data: user } = useRecoilValue(userNewState.state);
  const { grafanaUtils } = useGrafanaContext();

  const logout = useLogout(true, (isSuc) => {
    if (isSuc) {
      grafanaUtils?.unload();
    }
  });

  const badgeRef = useRef<HTMLDivElement>(null);
  const [openUserDropdown, setOpenUserDropdown] = useState(false);

  const toggleOpen: MouseEventHandler = () => {
    setOpenUserDropdown((prevState) => !prevState);
  };

  const onClose = () => {
    setOpenUserDropdown(false);
  };

  if (!user) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        borderBottom: 1,
        borderColor: "divider",
        minHeight: 60,
        backgroundColor: "background.default",
        zIndex: 850,
      }}
    >
      <StyledToolbar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <HamburgerButton />
          <UserDropdown
            organizationId={organization.id}
            subMenuItem={
              <NestedMenuItem
                title={
                  <Box display="flex" alignItems="center">
                    <Typography
                      sx={(theme) => ({ marginRight: theme.spacing() })}
                      onClick={() =>
                        window.open(
                          getGlobalAppUrl(`/organizations`),
                          "__black",
                        )
                      }
                    >
                      {texts.switchOrg}
                    </Typography>
                  </Box>
                }
                nestedMenu={<>{}</>}
              />
            }
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            flexGrow: 1,
          }}
        >
          <ThemeSwitch />
          <TopBarUser
            {...{
              user,
              outerContainerProps: { onClick: toggleOpen },
              badgeProps: { onClick: toggleOpen, ref: badgeRef },
            }}
          />
        </Box>
      </StyledToolbar>
      <Box
        component={Popper}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [20, 9],
            },
          },
        ]}
        sx={{ zIndex: "tooltip" }}
        open={openUserDropdown}
        anchorEl={badgeRef.current}
        placement="bottom-end"
      >
        <TopBarMenu
          {...{
            growProps: { in: openUserDropdown },
            clickAwayListenerProps: { onClickAway: onClose },
          }}
        >
          <ListItem
            onClick={onClose}
            sx={{
              minHeight: 48,
            }}
          >
            <ListItemAvatar
              sx={{
                minWidth: 36,
              }}
            >
              <Avatar
                sx={{ width: 24, height: 24 }}
                alt={user.name || ""}
                src={user.picture || ""}
              />
            </ListItemAvatar>
            {user.name}
          </ListItem>

          <MenuListItemRow
            to={ACCOUNT_SETTINGS_ROUTE.ABSOLUTE_PATH}
            onClick={onClose}
            listItemTextProps={{
              primary: ACCOUNT_SETTINGS_ROUTE.TITLE,
            }}
          />
          <MenuListItemRow
            onClick={logout}
            endIcon={<Logout sx={{ fontSize: 15, color: "text.primary" }} />}
            listItemTextProps={{
              primary: "Sign out",
            }}
          />
        </TopBarMenu>
      </Box>
    </Box>
  );
};
