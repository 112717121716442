import { gql } from "graphql-request";
import { useMutation } from "react-query";

import { gqlClient } from "#shared/utils/graphql-client";

export const ChangeUserName = gql`
  mutation ChangeUserName($input: UpdateUserNameInput!) {
    updateUserName(input: $input) {
      user {
        id
        name
      }
    }
  }
`;

export const useChangeUserName = (id?: string | null) =>
  useMutation<unknown, unknown, string>(async (name) =>
    id
      ? gqlClient.request(ChangeUserName, {
          input: {
            id,
            name,
          },
        })
      : Promise.reject(),
  );
