import { gql } from "graphql-request";
import { useState } from "react";

import type { ProjectsQuery } from "#shared/generated/graphql";
import { useGqlQuery } from "#shared/utils";

const QUERY_KEY = "allProjectQuery";
const AllProjectsQuery = gql`
  query projects($first: Int, $where: ProjectBoolExp!) {
    projects(first: $first, where: $where) {
      edges {
        node {
          id
          name
          userGroupProjectAssignments {
            edges {
              node {
                userGroup {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useAllProjectsQuery = () => {
  const [search, setSearch] = useState<string>("");
  const findBy = { first: 4, where: { name: { like: `${search}%` } } };
  const { isError, isLoading, data, refetch } = useGqlQuery<ProjectsQuery>(
    [QUERY_KEY, findBy],
    AllProjectsQuery,
    findBy,
  );

  return {
    error: isError,
    loading: isLoading,
    projects: data?.projects.edges,
    setSearch,
    search,
    refetch,
  };
};
