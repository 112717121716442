import { Typography } from "@mui/material";
import { Box, alpha, hexToRgb } from "@mui/system";
import React, { type FC } from "react";
import { Navigate, useParams, useSearchParams } from "react-router-dom";

import { ChargebeePlans, useBillingPageInfo } from "#shared/billing";
import {
  ErrorLayout,
  GlobalFrame,
  LoaderLayout,
} from "#shared/components/layouts";
import type { Texts } from "#shared/types";
import { getOrgDomain } from "#shared/utils";

import { BillingCheckout, PaymentSuccessful } from "./new-billing";

import { useOrganizationById } from "../organizations/use-organization-by-id";

type Text = "choosePlan" | "subscriptionFor" | "failedLoadBilling";

interface BillingPageProps {
  texts?: Texts<Text>;
}
const enTexts: BillingPageProps["texts"] = {
  choosePlan: "Select a subscription plan",
  subscriptionFor: "Subscription plan for",
  failedLoadBilling:
    "Failed to load billing information. Please try again later",
};

export const BillingPage: FC<BillingPageProps> = ({ texts = enTexts }) => {
  const { id } = useParams<{
    id: string;
  }>();

  const { organization } = useOrganizationById(id || "");

  const [searchParams] = useSearchParams();

  const {
    data: subscriptionInfo,
    isLoading: isLoadingBillingInfo,
    isError: isErrorBillingInfo,
  } = useBillingPageInfo({
    organization_id: organization?.hasura_id || "unknown",
    proxy: false,
  });

  if (searchParams.get("state") === "succeeded") {
    return <PaymentSuccessful />;
  }

  if (isLoadingBillingInfo) {
    return (
      <GlobalFrame hideLogout>
        <LoaderLayout />
      </GlobalFrame>
    );
  }

  if (isErrorBillingInfo) {
    return (
      <GlobalFrame hideLogout>
        <ErrorLayout texts={{ defaultError: texts.failedLoadBilling }} />
      </GlobalFrame>
    );
  }

  // User has already completed billing, redirect to the organizations list page
  if (
    subscriptionInfo?.data.list.find(
      (sub) => sub?.subscription.cf_organization_id === id,
    )
  ) {
    return <Navigate to="/organizations" />;
  }

  return (
    <GlobalFrame hideLogout>
      <Box
        sx={{
          display: "grid",
          gridTemplateRows: "auto auto 1fr",
          rowGap: 4,
          pt: 4,
          pb: 5,
          textAlign: "left",
        }}
      >
        <Typography variant="h4" textAlign="center">
          {ChargebeePlans.length > 1 ? texts.choosePlan : texts.subscriptionFor}
        </Typography>
        <Box display="flex" justifyContent="center">
          <Typography
            variant="h5"
            textAlign="center"
            color="primary.main"
            sx={{
              background: (theme) =>
                alpha(hexToRgb(theme.palette.primary.main), 0.08),
              p: 1,
              borderRadius: 2,
              wordBreak: "break-word",
            }}
          >
            {getOrgDomain(organization?.domain_name)}
          </Typography>
        </Box>

        <BillingCheckout />
      </Box>
    </GlobalFrame>
  );
};
