import { CodeSnippet } from "#organization/components"

export const language = "ts";

# Fairness Label Key

## Overview

`fairness_label_key` is a key for a flow label that can be used to override the default priority for this flow. The value associated with this key must be a valid number. Higher numbers means higher priority. If this parameter is not provided, the priority for the flow will be determined by the matched workload's priority.

<CodeSnippet lang={language} snippetName="UIQSFairness" />
