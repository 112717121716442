import agent_group from "./agent_group.mdx";
import blueprint from "./blueprint.mdx";
import bucket_capacity from "./bucket_capacity.mdx";
import continuous_fill from "./continuous_fill.mdx";
import control_point from "./control_point.mdx";
import delay_initial_fill from "./delay_initial_fill.mdx";
import denied_response_status_code from "./denied_response_status_code.mdx";
import enabled from "./enabled.mdx";
import fill_amount from "./fill_amount.mdx";
import interval from "./interval.mdx";
import label_matcher from "./label_matcher.mdx";
import limit_label_key from "./limit_label_key.mdx";
import max_idle_time from "./max_idle_time.mdx";
import num_sync from "./num_sync.mdx";
import service from "./service.mdx";
import tokens_label_key from "./tokens_label_key.mdx";

import type { RecursiveStringify } from "../../types";
import * as RateLimitingTypes from "../@types";

export type RateLimitingDocsTree =
  RecursiveStringify<RateLimitingTypes.Coordinate>;

export const rateLimitingDocsTree: RateLimitingDocsTree = {
  blueprint,
  policy: {
    policy_name: blueprint,
    rate_limiter: {
      bucket_capacity,
      fill_amount,
      parameters: {
        interval,
        limit_by_label_key: limit_label_key,
        continuous_fill,
        max_idle_time,
        delay_initial_fill,
        lazy_sync: {
          enabled,
          num_sync,
        },
      },
      request_parameters: {
        tokens_label_key,
        denied_response_status_code,
      },
      selectors: [
        {
          control_point,
          agent_group,
          service,
          label_matcher,
        },
      ],
    },
  },
};
