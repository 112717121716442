import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, type ButtonProps } from "@mui/material";
import { noop } from "lodash";
import React, { type FC } from "react";
import { useNavigate } from "react-router-dom";

import type { Texts } from "#shared/types";

import { POLICIES_ROUTE } from "../../routes.definitions";
import { useCreatePolicyContext } from "../context";

export const defaultBackButtonTexts = {
  back: "Back",
};

export interface BackButtonProps {
  handleBack: () => void;
  activeStep: number;
  texts?: Texts<"back">;
  buttonProps?: ButtonProps;
  totalSteps: number;
}

export const BackButton: FC<BackButtonProps> = ({
  handleBack,
  activeStep,
  texts = defaultBackButtonTexts,
  buttonProps = {},
  totalSteps = 3,
}) => {
  const navigate = useNavigate();
  const { setCreatePolicyCtx, editor } = useCreatePolicyContext();

  const onClick = () => {
    // do not remove saved editor state if it is last step
    if (activeStep !== totalSteps - 1 && activeStep !== 0) {
      setCreatePolicyCtx((prev) => ({
        ...prev,
        blueprintJson: null,
        blueprintYaml: null,
        editorModel: null,
        editorSavedState: null,
        submitYamlValues: noop,
        docFieldAddress: "blueprint",
        miniEditors: null,
      }));
      editor?.dispose();

      handleBack();

      return;
    }

    if (activeStep === totalSteps - 1) {
      setCreatePolicyCtx((prev) => ({
        ...prev,
        submitYamlValues: noop,
      }));
    }

    if (activeStep === 0) {
      navigate(POLICIES_ROUTE.ABSOLUTE_PATH);

      return;
    }

    handleBack();
  };

  return (
    <Button
      {...buttonProps}
      onClick={onClick}
      variant="outlined"
      size="small"
      startIcon={<ArrowBackIcon />}
    >
      {texts.back}
    </Button>
  );
};
