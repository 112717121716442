import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, type FC } from "react";

import { ErrorLayout, LoaderLayout } from "#shared/components/layouts";
import { LoggerService } from "#shared/services";
import type { Texts } from "#shared/types";

import { useCompleteSubscription } from "./hooks";

type Text = "content" | "success" | "button" | "failedBilling";

interface BillingPageProps {
  texts?: Texts<Text>;
}
const enTexts: BillingPageProps["texts"] = {
  content: "Click the button below to complete your subscription",
  success: "Your subscription has been completed successfully. Redirecting...",
  button: "Complete subscription",
  failedBilling: "Failed to complete billing. Please try again later",
};

export const CompleteSubscription: FC<BillingPageProps> = ({
  texts = enTexts,
}) => {
  const { completeSubscription } = useCompleteSubscription();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);
  const [isSuccess, setIsSuccess] = React.useState<boolean>();

  const handleCompleteSubscription = async () => {
    if (isSuccess) {
      return;
    }

    setIsLoading(true);

    try {
      await completeSubscription();
      setIsSuccess(true);
    } catch (error) {
      setIsError(true);
      LoggerService.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleCompleteSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <LoaderLayout />;
  }

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateRows: "auto auto 1fr",
        rowGap: 4,
        pt: 4,
        pb: 5,
        textAlign: "center",
      }}
    >
      {isError ? (
        <ErrorLayout texts={{ defaultError: texts.failedBilling }} />
      ) : (
        <Typography variant="h6" textAlign="center">
          {isSuccess ? texts.success : texts.content}
        </Typography>
      )}
      {!isSuccess && (
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleCompleteSubscription}
            sx={{ px: 8 }}
          >
            {texts.button}
          </Button>
        </Box>
      )}
    </Box>
  );
};
