import type { Edge, Node } from "react-flow-renderer";
import { atom, useRecoilState, useRecoilValue } from "recoil";

import { ATOM_KEYS } from "#shared/consts";

export type HoverNode = {
  componentId: string;
};

export interface HoverSignalProps {
  sourceComponentID: string;
  hoverSourceSignal: string;
}

export const nodes = atom<Node[]>({
  key: ATOM_KEYS.nodes,
  default: [],
});

export const edges = atom<Edge[]>({
  key: ATOM_KEYS.edges,
  default: [],
});

export const useFlowNodesValue = () => useRecoilValue(nodes);

export const useFlowEdgesValue = () => useRecoilValue(edges);

export const useFlowNodesState = () => useRecoilState(nodes);

export const useFlowEdgesState = () => useRecoilState(edges);

export const hoverNode = atom<HoverNode | null>({
  key: ATOM_KEYS.hoverNode,
  default: null,
});

export const useHoverNodeState = () => useRecoilState(hoverNode);
export const useHoverNodeValue = () => useRecoilValue(hoverNode);

export const hoverSignal = atom<HoverSignalProps | null>({
  key: ATOM_KEYS.hoverSignal,
  default: {
    sourceComponentID: "",
    hoverSourceSignal: "",
  },
});

export const useHoverSignalState = () => useRecoilState(hoverSignal);
export const useHoverSignalValue = () => useRecoilValue(hoverSignal);
