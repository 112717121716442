import type { ChargebeeInstance } from "@chargebee/chargebee-js-types";
import { useState } from "react";
import { useQuery } from "react-query";

import { ENV } from "#shared/env";
import { LoggerService } from "#shared/services";
import { httpClient } from "#shared/utils/http-client";

import { API_URLS } from "#global/consts";

import type { ItemPricesApiData } from "./types";

/* Outdated since it uses a hardcoded price id */
const parsePrice = (object: ItemPricesApiData) => {
  const prices = {
    tier0: 0,
  };

  object.list.forEach((item) => {
    if (item.item_price.id === "Pro-metered-USD-Monthly") {
      prices.tier0 = item.item_price.tiers[0].price / 100;
    } else {
      LoggerService.debug(`Unknown pricing plan: ${item.item_price.id}`);
    }
  });

  return prices;
};

/* Unused currently and is outdated since it uses a hardcoded plan id */
export const useGetPrices = () => {
  const prices = {
    tier0: 0,
  };
  const [currency] = useState<string>("USD");

  const getPrices = async () => {
    const params = new URLSearchParams({ item_id: "Pro-metered" });
    const url = `${API_URLS.BILLING.ITEMS_PRICES}?${params.toString()}`;
    const request = await fetch(url);

    return request.json();
  };

  const { data } = useQuery<unknown, unknown, ItemPricesApiData>(
    "getPrices",
    getPrices,
  );

  if (data) {
    return { prices: parsePrice(data), currency };
  }

  return { prices, currency };
};

type ChargebeePortalSessionResponse = {
  id: string;
  token: string;
  access_url: string;
  status: string;
  created_at: number;
  expires_at: number;
  object: string;
  customer_id: string;
};

type ChargebeePortalSessionBody = {
  cf_billing_organization_id?: string;
  cf_organization_id?: string;
  user?: { email: string };
};

/**
 * Chargebee Singleton
 */
// TODO: move to shared
export class ChargebeeClient {
  private static instance: ChargebeeInstance;

  // Avoid using the chargebee-js-types library as it has incorrect types and missing fields
  // See https://github.com/chargebee/chargebee-js-types/issues/3
  public static getObject = () => {
    const { Chargebee } = window;

    if (!Chargebee) {
      LoggerService.error("Chargebee is not defined");

      return null;
    }

    return Chargebee;
  };

  public static getInstance = (): ChargebeeInstance | null => {
    if (!ChargebeeClient.instance) {
      const Chargebee = ChargebeeClient.getObject();

      if (!Chargebee) {
        return null;
      }

      Chargebee.init({
        site: ENV.CHARGEBEE_SITE,
      });

      ChargebeeClient.instance = Chargebee.getInstance();
    }

    return ChargebeeClient.instance;
  };

  public static getPortalSession = (
    billingOrganizationId: string | undefined,
    organizationId: string | undefined,
    user?: { email: string },
  ) => {
    const instance = ChargebeeClient.getInstance();

    // Handles Chargebee SSO login
    instance?.setPortalSession(() => {
      const body: ChargebeePortalSessionBody = {
        cf_billing_organization_id: billingOrganizationId,
        cf_organization_id: organizationId,
        ...(user && { user }),
      };

      return httpClient
        .post<
          ChargebeePortalSessionResponse,
          false,
          ChargebeePortalSessionBody
        >({
          url: API_URLS.BILLING.CREATE_PORTAL_SESSION,
          body,
        })
        .then((response) => response.json());
    });

    return instance?.createChargebeePortal();
  };
}
