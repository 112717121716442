import { gql } from "graphql-request";

import { useGqlMutation } from "#shared/utils";

const CreateUserGroupMutationQuery = gql`
  mutation createUserGroup($input: CreateUserGroupInput!) {
    createUserGroup(input: $input) {
      userGroup {
        name
      }
    }
  }
`;

export const useAddUserGroupMutation = () => {
  const { mutate, data, error } = useGqlMutation<
    { createUserGroup: { userGroup: { name: string } } },
    Error,
    { name: string; organization: string }
  >(CreateUserGroupMutationQuery);

  return {
    addUserGroup: mutate,
    addUserGroupData: data,
    addUserGroupError: error,
  };
};
