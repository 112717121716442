import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Box, Button, TextField } from "@mui/material";
import React, { type Dispatch, type SetStateAction, useCallback } from "react";
import { useFormContext, type FieldValues, type Path } from "react-hook-form";

import { SchedulerDefaultWorkloadParameters } from "./default-workload-parameters";

import {
  DeleteSelectorButton,
  FieldWithTitle,
  FormFieldWrapper,
  TreeItemStyled,
} from "../../../styled";
import * as QuotaSchedulerBlueprint from "../../quota-scheduling/@types";
import { useArrayFields, useOnClickDeleteFields } from "../hooks";
import { LabelMatcher } from "../label-matcher";
import type { BlueprintFieldProps } from "../types";
import { createFieldNameWithPrefix } from "../utils";

export declare type StaticWorkloadsProps<
  TFields extends FieldValues = QuotaSchedulerBlueprint.Coordinate,
> = {} & BlueprintFieldProps<TFields>;

const defaultWorkloadValues = {};

export const StaticWorkloads = <
  TFields extends FieldValues = QuotaSchedulerBlueprint.Coordinate,
>({
  fieldsPrefix = "policy.quota_scheduler.scheduler.workloads" as Path<TFields>,
}: StaticWorkloadsProps<TFields>) => {
  const { fieldList, addNewFields, setFieldList } = useArrayFields<TFields>({
    fieldsPrefix,
    defaultValues: defaultWorkloadValues,
  });

  return (
    <>
      {fieldList.map((selector, i) => (
        <SchedulerWorkload
          key={[selector, i].join("-")}
          {...{
            fieldsPrefix,
            index: i,
            setFieldList,
          }}
        />
      ))}
      <Box pb={2}>
        <Button
          variant="outlined"
          onClick={addNewFields}
          startIcon={<AddOutlinedIcon />}
          size="small"
        >
          Add Workload
        </Button>
      </Box>
    </>
  );
};

export declare type SchedulerWorkloadProps<
  TFields extends FieldValues = QuotaSchedulerBlueprint.Coordinate,
> = {
  index: number;
  setFieldList: Dispatch<SetStateAction<string[]>>;
} & BlueprintFieldProps<TFields>;

export const SchedulerWorkload = <
  TFields extends FieldValues = QuotaSchedulerBlueprint.Coordinate,
>({
  fieldsPrefix = "policy.quota_scheduler.scheduler.workloads" as Path<TFields>,
  setFieldList,
  index,
}: SchedulerWorkloadProps<TFields>) => {
  const { register } = useFormContext<TFields>();

  const currentWorkloadAddress = createFieldNameWithPrefix(
    fieldsPrefix,
    [index].join("."),
  );

  const onClickDeleteSelector = useOnClickDeleteFields<TFields>({
    fieldsPrefix,
    index,
    setFieldList,
  });

  const docMdFileAddress = useCallback(
    (fieldName: string) =>
      createFieldNameWithPrefix(
        currentWorkloadAddress,
        ["0", fieldName].join("."),
      ),
    [currentWorkloadAddress],
  );

  const nameFieldAddress = createFieldNameWithPrefix(
    currentWorkloadAddress,
    "name",
  );

  const labelMatcherFieldName = createFieldNameWithPrefix(
    currentWorkloadAddress,
    "label_matcher",
  );

  return (
    <Box position="relative">
      <DeleteSelectorButton
        startIcon={<DeleteOutlineOutlinedIcon />}
        variant="outlined"
        size="small"
        onClick={onClickDeleteSelector}
      >
        Delete
      </DeleteSelectorButton>
      <FormFieldWrapper>
        <br />
        <FieldWithTitle
          label="Label Matcher"
          fieldAddress={docMdFileAddress("label_matcher")}
        >
          <LabelMatcher
            labelMatcherAddress={labelMatcherFieldName}
            lengthOfSelectors={index}
          />
        </FieldWithTitle>
        <FieldWithTitle label="Name" fieldAddress={docMdFileAddress("name")}>
          <TextField
            {...{
              ...register(nameFieldAddress),
              placeholder: "Name",
            }}
          />
        </FieldWithTitle>
        <TreeItemStyled
          nodeId={`${currentWorkloadAddress}.parameters`}
          label="Parameters"
        >
          <SchedulerDefaultWorkloadParameters<TFields>
            {...{
              fieldsPrefix: createFieldNameWithPrefix(
                currentWorkloadAddress,
                "parameters",
              ),
            }}
          />
        </TreeItemStyled>
      </FormFieldWrapper>
    </Box>
  );
};
