import { ExpandMore, Warning } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { noop } from "lodash";
// eslint-disable-next-line import/no-extraneous-dependencies
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import React, { useState, type FC, useEffect } from "react";

import { getYamlEditorErrors } from "../hooks";

export interface YAMLErrorsProps {
  editor: monaco.editor.IStandaloneCodeEditor | null;
}

export const YAMLErrors: FC<YAMLErrorsProps> = ({ editor }) => {
  const [errors, setErrors] = useState<monaco.editor.IMarker[]>([]);

  useEffect(() => {
    if (!editor) return noop;

    const deposable = editor.onDidChangeModelDecorations(() => {
      setErrors(getYamlEditorErrors(editor));
    });

    return () => {
      deposable.dispose();
    };
  }, [editor]);

  if (!editor || errors.length === 0) {
    return null;
  }

  return (
    <Accordion elevation={5}>
      <AccordionSummary
        expandIcon={
          <ExpandMore sx={{ color: (theme) => theme.palette.text.primary }} />
        }
      >
        <Typography sx={{ pointerEvents: "none" }}>
          Errors ({errors.length})
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {errors.map((error) => {
          const errorMsg = error.message;
          const lineNumber = error.startLineNumber;
          const column = error.startColumn;

          const onClick = () => {
            if (!editor) return;

            editor.focus();

            editor.setPosition({
              lineNumber,
              column,
            });
            editor.revealLineInCenter(lineNumber);
          };

          return (
            <Box
              display="flex"
              alignItems="center"
              key={`${errorMsg}-${lineNumber}-${column}`}
            >
              <Warning color="warning" />
              <Button
                variant="text"
                onClick={onClick}
                sx={{
                  whiteSpace: "pre-wrap",
                  maxHeight: 200,
                  textAlign: "start",
                }}
              >
                {`Line ${lineNumber}, Column ${column}: ${errorMsg}`}
              </Button>
            </Box>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};
