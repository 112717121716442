import { Typography } from "@mui/material";
import React, { type Dispatch, type SetStateAction } from "react";

import { emptyColumnValue, type HeadCell } from "#shared/components/data-grid";
import type { UpdateUserGroupProjectAssignmentPermissionsInput } from "#shared/generated/graphql";

import { LinkWithoutUnderline } from "#organization/components/link-without-underline";
import { ChangeUserGroupPermission } from "#organization/pages/authenticated/settings/organization/projects/project/components/user-groups-section/components/table";

import type { UserGroupProjectsData } from "./types";

import { PROJECTS_SETTINGS_ROUTE } from "../../../../../../projects/routes.definitions";

export const headCells = (
  permissionLock: boolean,
  setUpdateUserGroupPermission: Dispatch<
    SetStateAction<UpdateUserGroupProjectAssignmentPermissionsInput[] | null>
  >,
): HeadCell<UserGroupProjectsData>[] => [
  {
    accessor: (row) => (
      <LinkWithoutUnderline
        to={`${PROJECTS_SETTINGS_ROUTE.ABSOLUTE_PATH}/${row.id}`}
      >
        <Typography>{row.name || emptyColumnValue}</Typography>
      </LinkWithoutUnderline>
    ),
    label: "Project name",
    key: "name",
  },
  {
    accessor: (row) => (
      <ChangeUserGroupPermission<UserGroupProjectsData>
        {...{
          setUpdateUserGroupPermission,
          permissionLock,
          row,
        }}
      />
    ),
    label: "Permissions",
    key: "permissions",
  },
];
