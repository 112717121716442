import { Box } from "@mui/material";
import { styled, type Theme } from "@mui/system";
import React, { type FC } from "react";

import { RowWrapper } from "#organization/pages/authenticated/alert-manager/styled";

export interface AssignedNotifiersProps {
  webhookLength: number;
  emailLength: number;
  slackLength: number;
}

export const AssignedNotifiers: FC<AssignedNotifiersProps> = ({
  webhookLength,
  emailLength,
  slackLength,
}) => {
  const allNotifiers: { name: string; length: number }[] = [
    {
      name: "Webhook",
      length: webhookLength,
    },
    {
      name: "Email",
      length: emailLength,
    },
    {
      name: "Slack",
      length: slackLength,
    },
  ];

  return (
    <ModifiedRowWrapper>
      {allNotifiers.map(
        (notifier, i) =>
          notifier.length !== 0 && (
            <InnerWrapper key={[notifier.name, i].join("-")}>
              <Box sx={boxSx}>{notifier.name}</Box>
              <Box>{notifier.length}</Box>
            </InnerWrapper>
          ),
      )}
    </ModifiedRowWrapper>
  );
};

const ModifiedRowWrapper = styled(RowWrapper)(({ theme }) => ({
  padding: theme.spacing(0),
  justifyContent: "flex-start",
  gap: theme.spacing(1),
}));

const InnerWrapper = styled(RowWrapper)(({ theme }) => ({
  justifyContent: "flex-start",
  borderRadius: theme.spacing(0.4),
  gap: theme.spacing(1),
  backgroundColor:
    theme.palette.grey[theme.palette.mode === "light" ? 300 : 600],
  padding: "2px 8px",
}));

const boxSx = (theme: Theme) => ({
  borderRightWidth: "thin",
  borderRightStyle: "solid",
  borderRightColor: theme.palette.divider,
  paddingRight: 1,
});
