import React from "react";
import {
  FormProvider,
  useForm,
  type FieldValues,
  type UseFormReturn,
  type DefaultValues,
} from "react-hook-form";

export declare type FormContextProviderProps<
  TFields extends FieldValues = FieldValues,
> = {
  children: (methods: UseFormReturn<TFields>) => JSX.Element;
  defaultValues?: DefaultValues<TFields>;
};

export const FormContextProvider = <TFields extends FieldValues = FieldValues>({
  children,
  defaultValues,
}: FormContextProviderProps<TFields>): JSX.Element => {
  const methods = useForm<TFields>({
    mode: "all",
    defaultValues,
  });

  return <FormProvider {...methods}>{children(methods)}</FormProvider>;
};
