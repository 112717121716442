import type { Texts } from "#shared/types";

export enum PermissionEnum {
  null = -1,
  user_group_member = 1,
  user_group_admin = 2,
  // manager = 3,
  owner = 4,
  // admin = 5,
}

export type Permission = keyof typeof PermissionEnum;

// NOTE: without admin role ("admin" role is reserved for FN developers)
export type UserRole = Extract<
  keyof typeof PermissionEnum,
  "owner" | "user_group_member" | "user_group_admin"
>;

export const roles: ReadonlyArray<UserRole> = [
  "owner",
  "user_group_admin",
  "user_group_member",
];

export const userRoleEnTexts: Texts<UserRole> = {
  owner: "Owner",
  user_group_admin: "Admin",
  user_group_member: "Member",
};
