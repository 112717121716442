import { useTheme } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { atom, useRecoilState, useRecoilValue } from "recoil";

import { ATOM_KEYS } from "#shared/consts";
import { saveToLS } from "#shared/utils/local-storage";

import type { FnTheme, SidebarState } from "../../../app-theme-provider/types";

export const sidebarState = atom<SidebarState>({
  key: ATOM_KEYS.sidebar,
  default: "closed",
});

export const useSidebarState = () => useRecoilValue(sidebarState);

export const useSidebarWidth = () => {
  const { shape } = useTheme() as FnTheme;

  const state = useSidebarState();
  const [width, setWidth] = useState(shape.sidebar.width[state]);

  const prevWidth = useRef(width);

  useEffect(() => {
    const newWidth = shape.sidebar.width[state];

    if (newWidth === width) {
      return;
    }
    prevWidth.current = width;

    setWidth(newWidth);
  }, [state, width, shape.sidebar.width]);

  return useMemo(() => ({ width, prevWidth: prevWidth.current }), [width]);
};

export const useIsSidebarOpen = (): boolean =>
  useRecoilValue(sidebarState) !== "closed";

export const useIsLarge = (): boolean =>
  useRecoilValue(sidebarState) === "large";

export interface SidebarController {
  toggleSidebar: () => void;
  toggleSidebarSize: () => void;
  setSmall: () => void;
  setLarge: () => void;
}

export const useSidebarController = (): SidebarController => {
  const [state, setState] = useRecoilState(sidebarState);

  const toggleSidebar = () => {
    if (state === "large") {
      setState("small");
      saveToLS("sidebar", "small");
    } else if (state === "small") {
      setState("closed");
      saveToLS("sidebar", "closed");
    } else {
      setState("large");
      saveToLS("sidebar", "large");
    }
  };

  const toggleSidebarSize = () => {
    if (state === "small") {
      setState("large");
    } else {
      setState("small");
    }
    saveToLS("sidebar", state === "small" ? "large" : "small");
  };

  const setSmall = () => {
    setState("small");
    saveToLS("sidebar", "small");
  };

  const setLarge = () => {
    setState("large");
    saveToLS("sidebar", "large");
  };

  return {
    toggleSidebar,
    toggleSidebarSize,
    setSmall,
    setLarge,
  };
};
