import type { PortalForwardOptions } from "@chargebee/chargebee-js-types";
import {
  CreditCard,
  LockOutlined,
  Person,
  PinDropOutlined,
} from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Button, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, type FC, useMemo, useEffect } from "react";
import { useRecoilValue } from "recoil";

import { useBillingPageInfo } from "#shared/billing";
import { ErrorLayout, LoaderLayout } from "#shared/components/layouts";
import {
  DEFAULT_CHARGEBEE_OPEN_PORTAL_OPTIONS,
  DEFAULT_CHARGEBEE_OPEN_PORTAL_FORWARD_OPTIONS,
  SUPPORT_EMAIL,
} from "#shared/consts";
import { userNewState } from "#shared/recoil";
import type { Texts } from "#shared/types";

import { ChargebeeClient } from "#global/pages/authenticated/billing/hooks";

import {
  useGetOrganizationData,
  useOrganizationState,
} from "#organization/recoil/organization";

import { useGetProjectNames } from "./hooks";
import { parseSubscription } from "./utils";

import {
  SectionHeader,
  SettingsButtons,
  SettingsSectionLabel,
} from "../../components";
import {
  SectionBody,
  SectionSelect,
  SectionWrapper,
} from "../../components/styled";
import { useIsAuthnSettings } from "../hooks";

export type ChargebeeSectionType =
  | "account_details"
  | "portal_address"
  | "portal_payment_methods"
  | "portal_billing_history";

export interface BillingSettingsPageProps {
  texts?: Texts<Text>;
}

type Text =
  | "billing"
  | "billingDescription"
  | "billingPeriod"
  | "plan"
  | "pricePerAgentHour"
  | "paidAgentHourUsed"
  | "agentHourUsed"
  | "inThisBillingPeriod"
  | "goodRequestsUsed"
  | "accountInformation"
  | "billingAndShippingAddresses"
  | "paymentMethods"
  | "billingHistoryAndInvoices"
  | "ownerOnly"
  | "noBillingFound";

const enTexts: Required<BillingSettingsPageProps["texts"]> = {
  billing: "Billing",
  billingDescription: "Here you can find a summary of your subscription",
  billingPeriod: "Billing period",
  plan: "Plan",
  pricePerAgentHour: "Price per Agent-hour",
  paidAgentHourUsed: "Paid Agent-hours used",
  agentHourUsed: "Agent-hours used",
  inThisBillingPeriod: "In this billing period",
  goodRequestsUsed: "Good requests used",
  accountInformation: "Account information",
  billingAndShippingAddresses: "Billing and shipping addresses",
  paymentMethods: "Payment methods",
  billingHistoryAndInvoices: "Billing history and invoices",
  ownerOnly: "Only organization owners can manage billing",
  noBillingFound:
    "We could not find any billing information for this organization. If you think this is an error, please contact us at",
};

export const BillingSettingsPage: FC<BillingSettingsPageProps> = ({
  texts = enTexts,
}) => {
  const isAuthnSettings = useIsAuthnSettings();

  const { id: orgID, subdomain } = useOrganizationState();
  const firebaseEmail = useRecoilValue(userNewState.email);
  const { organization } = useGetOrganizationData(subdomain);

  const {
    data: subscriptionInfoAll,
    isLoading: isLoadingBillingInfo,
    isError: isErrorBillingInfo,
  } = useBillingPageInfo({
    organization_id: orgID,
  });

  // Filter active/in_trial subscriptions
  const subscriptionInfo = useMemo(
    () => ({
      ...subscriptionInfoAll,
      data: {
        ...subscriptionInfoAll?.data,
        list:
          subscriptionInfoAll?.data?.list?.filter(
            (sub) =>
              sub.subscription.status === "active" ||
              sub.subscription.status === "in_trial",
          ) || [],
      },
    }),
    [subscriptionInfoAll],
  );

  const projectIDs = useMemo(
    () =>
      subscriptionInfo?.data.list
        .map((s) => s.subscription.cf_project_id)
        .filter(Boolean),
    [subscriptionInfo?.data.list],
  );
  const { data: projectNames } = useGetProjectNames(projectIDs);

  const getSubName = useCallback(
    ({
      projectName = "",
      projectID = "",
      organizationName = "",
      organizationID = "",
    }) => {
      if (projectName) {
        return projectName;
      }

      if (projectID) {
        return (
          projectNames?.find((p) => p.node.id === projectID)?.node.name ||
          projectID
        );
      }

      if (organizationName) {
        return organizationName;
      }

      return orgID === organizationID ? subdomain : organizationID;
    },
    [orgID, projectNames, subdomain],
  );

  const subscriptionList = useMemo(() => {
    if (!subscriptionInfo?.data?.list) {
      return [];
    }

    return subscriptionInfo.data.list.map((s) => ({
      key: getSubName({
        projectName: s.subscription.cf_project_name,
        projectID: s.subscription.cf_project_id,
        organizationName: s.subscription.cf_organization_name,
        organizationID: s.subscription.cf_organization_id,
      }),
      value: s.subscription.cf_project_id || s.subscription.cf_organization_id,
    }));
  }, [getSubName, subscriptionInfo.data.list]);

  const [selectedSub, setSelectedSub] = React.useState<string>(
    subscriptionList[0]?.value || "",
  );

  useEffect(() => {
    setSelectedSub(subscriptionList[0]?.value || "");
  }, [subscriptionList]);

  const subscriptionData = useMemo(
    () =>
      subscriptionInfo?.data?.list?.find(
        (sub) =>
          sub.subscription.cf_project_id === selectedSub ||
          (!sub.subscription.cf_project_id &&
            sub.subscription.cf_organization_id === selectedSub),
      ),
    [selectedSub, subscriptionInfo?.data?.list],
  );

  const billingData = useMemo(
    () => parseSubscription(subscriptionData?.subscription),
    [subscriptionData],
  );

  const openChargebeeSubscriptions = useCallback(
    (sectionType?: ChargebeeSectionType) => {
      ChargebeeClient.getPortalSession(
        organization?.id,
        organization?.hasura_id,
        {
          email: firebaseEmail || "",
        },
      )?.open(
        DEFAULT_CHARGEBEE_OPEN_PORTAL_OPTIONS,
        !sectionType
          ? (undefined as unknown as PortalForwardOptions)
          : {
              ...DEFAULT_CHARGEBEE_OPEN_PORTAL_FORWARD_OPTIONS,
              params: {
                subscriptionId: subscriptionData?.subscription.id || "",
                paymentSourceId: "",
              },
              sectionType,
            },
      );
    },
    [
      subscriptionData?.subscription.id,
      firebaseEmail,
      organization?.hasura_id,
      organization?.id,
    ],
  );

  if (isLoadingBillingInfo) {
    return <LoaderLayout />;
  }

  if (isErrorBillingInfo) {
    return <ErrorLayout />;
  }

  return (
    <>
      <SettingsButtons disabled title="Billing" />
      <SectionWrapper>
        <SectionHeader title={texts.billing} />
        <Typography
          variant="body2"
          color={billingData ? "textSecondary" : "error"}
          mx={1}
          mt={3}
          mb={1}
        >
          {billingData ? (
            texts.billingDescription
          ) : (
            <>
              {texts.noBillingFound} <strong>{SUPPORT_EMAIL}</strong>
            </>
          )}
        </Typography>

        {!billingData ? null : (
          <>
            {subscriptionList.length > 1 && (
              <SectionSelect
                value={selectedSub}
                size="small"
                onChange={(event) => {
                  setSelectedSub(event.target.value as string);
                }}
                renderValue={(data) =>
                  subscriptionList.find((s) => s.value === data)?.key ||
                  (data as string)
                }
              >
                {subscriptionList.map((s) => (
                  <MenuItem key={s.key} value={s.value}>
                    {s.key}
                  </MenuItem>
                ))}
              </SectionSelect>
            )}

            {billingData.map((billingDataItem) => (
              <SectionBody key={billingDataItem.title}>
                <SettingsSectionLabel
                  primaryText={billingDataItem.title}
                  secondaryText={billingDataItem.subtitle}
                />
                <Typography variant="body2" color="textSecondary">
                  {billingDataItem.value}
                </Typography>
              </SectionBody>
            ))}

            <Box
              my={1}
              width={["100%", "100%", "100%", "60%"]}
              sx={{
                borderBottom: "1.5px",
                borderBottomColor: "primary.light",
                borderBottomStyle: "solid",
              }}
            />

            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              alignItems="start"
              mt={3}
            >
              <Button
                variant="text"
                startIcon={<Person />}
                disabled={!isAuthnSettings}
                onClick={() => openChargebeeSubscriptions("account_details")}
              >
                {texts.accountInformation}
              </Button>

              <Button
                variant="text"
                startIcon={<PinDropOutlined />}
                disabled={!isAuthnSettings}
                onClick={() => openChargebeeSubscriptions("portal_address")}
              >
                {texts.billingAndShippingAddresses}
              </Button>

              <Button
                variant="text"
                startIcon={<CreditCard />}
                disabled={!isAuthnSettings}
                onClick={() =>
                  openChargebeeSubscriptions("portal_payment_methods")
                }
              >
                {texts.paymentMethods}
              </Button>

              <Button
                variant="text"
                startIcon={<LockOutlined />}
                disabled={!isAuthnSettings}
                onClick={() =>
                  openChargebeeSubscriptions("portal_billing_history")
                }
              >
                {texts.billingHistoryAndInvoices}
              </Button>

              {!isAuthnSettings ? (
                <Typography
                  color="error.main"
                  display="flex"
                  alignItems="center"
                  columnGap={0.5}
                  mt={2}
                >
                  <ErrorOutlineIcon />
                  {texts.ownerOnly}
                </Typography>
              ) : null}
            </Box>
          </>
        )}
      </SectionWrapper>
    </>
  );
};
