import { Box, Typography, type BoxProps } from "@mui/material";
import React, { type FC, isValidElement, type PropsWithChildren } from "react";

import { FLUXNINJA, sharedEnTexts } from "#shared/consts";
import {
  withErrorBoundary,
  type WithErrorBoundaryProps,
} from "#shared/hocs/error-boundary";
import errorImageUrl from "#shared/logo-error.svg";
import type { Texts } from "#shared/types";

import { convertToString } from "./convert-to-string";
import type { ErrorTextText } from "./error-text";

export type LayoutProps = WithErrorBoundaryProps<
  PropsWithChildren<{
    texts?: Texts<LayoutText>;
    errorIcon?: JSX.Element;
    boxWrapperProps?: BoxProps;
  }>
>;

type LayoutText = ErrorTextText;

const enLayoutTexts: Required<LayoutProps["texts"]> = {
  defaultError: sharedEnTexts.error,
};

/** Screen shown when there is an error when loading the organization */
const Layout: FC<LayoutProps> = ({
  children,
  error,
  errorIcon,
  boxWrapperProps,
  texts = enLayoutTexts,
}) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyItems="center"
    alignItems="center"
    height="100%"
    gap={2}
    {...boxWrapperProps}
  >
    {errorIcon || (
      <img
        src={errorImageUrl}
        alt={[FLUXNINJA, "error logo"].join(" ")}
        style={{ height: "300px", alignSelf: "center" }}
      />
    )}

    <Typography component="h1" variant="h3" textAlign="center">
      {texts.defaultError}
    </Typography>
    {error ? null : (
      <Box mt={2}>
        {isValidElement(children) ? children : convertToString(children)}
      </Box>
    )}
  </Box>
);

export const ErrorLayout = withErrorBoundary(Layout);
