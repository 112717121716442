import type { RJSFSchema } from "@rjsf/utils";
import { cloneDeep, get, isFunction, update } from "lodash";
import type { FieldValues, Path } from "react-hook-form";

import type { AnyObject, JSONObject } from "#shared/types";

import { convertToYaml } from "../../../context";

export const findFieldTypeBySchema = (schema: RJSFSchema, key: string) => {
  const properties = schema?.properties;
  const definitions = schema?.definitions;

  if (!properties || typeof properties !== "object") return "text";

  const keys = key.split(".");
  const schemaKeys: string[] = [];

  keys.forEach((k, i) => {
    if (keys.length === 1) {
      schemaKeys.push(k);

      return;
    }

    schemaKeys.push(
      i !== keys.length - 1 && Number.isNaN(+k) ? `${k}.properties` : k,
    );
  });

  const numberKeyIndex = schemaKeys.findIndex((k) => !Number.isNaN(+k));

  const sk = numberKeyIndex !== -1 ? schemaKeys.slice(0, numberKeyIndex) : [];

  const subKeys = sk.map((k, i) => (i !== sk.length - 1 ? k : k.split(".")[0]));

  const field = get(properties, subKeys.join(".") || schemaKeys.join("."));

  let fieldType: "string" | "number" | "integer" | "boolean" | undefined;

  if (
    typeof field === "object" &&
    field?.items &&
    numberKeyIndex !== -1 &&
    definitions
  ) {
    const item = Array.isArray(field?.items)
      ? // @ts-ignore
        field?.items[numberKeyIndex]?.$ref
      : // @ts-ignore
        field?.items?.$ref;

    fieldType =
      // @ts-ignore
      definitions?.[item.split("/")[item.split("/").length - 1]]?.properties?.[
        keys[keys.length - 1]
      ]?.type;
  } else {
    // @ts-ignore
    fieldType = field?.type;
  }

  switch (fieldType) {
    case "string":
      return "text";
    case "boolean":
      return "number";
    case "number":
      return "number";
    default:
      return undefined;
  }
};

// TODO: allow other field types as well
export const allowRenderField = (value: AnyObject | unknown): boolean =>
  typeof value === "string" || typeof value === "number";

type ReturnForGenerateUpdatedBlueprintJson = {
  blueprintJson: JSONObject | null;
  blueprintYaml: string;
};

/**
 *
 * @param value - current value of the fields
 * @param blueprintJson - stored in context blueprint json
 * @param fieldPath - path of the field that you want to update
 * @returns { blueprintJson: JSONObject | null, blueprintYaml: string }
 */
export function generateUpdatedBlueprintJson<
  TFields extends FieldValues = FieldValues,
>(
  value: JSONObject | null,
  blueprintJson: JSONObject | null,
  fieldPath?: Path<TFields>,
  updateBlueprint?: (
    json: JSONObject | null,
    fieldPath?: Path<TFields>,
  ) => JSONObject | null,
): ReturnForGenerateUpdatedBlueprintJson {
  const jsonCopy = cloneDeep(blueprintJson);

  if (jsonCopy && fieldPath) {
    update(jsonCopy, fieldPath, () =>
      !isFunction(updateBlueprint)
        ? get(value, fieldPath)
        : updateBlueprint(value, fieldPath),
    );
  }

  return {
    blueprintJson: jsonCopy || value,
    blueprintYaml: convertToYaml(jsonCopy || value || {}),
  };
}
