import { gql } from "graphql-request";

import type { FilterLabelsType } from "#shared/components/filtering";

export const alertsLogFilterHeaders = (
  projectId: string,
  additionalFilters?: {},
): FilterLabelsType[] => {
  const commonFilterHeader = {
    first: 5,
    where: {
      projectID: { eq: projectId },
      ...additionalFilters,
    },
  };

  return [
    {
      id: 0,
      name: "Name",
      gql: gql`
        query alertsByName($where: AlertBoolExp, $first: Int) {
          alerts(where: $where, first: $first, distinctOn: [name]) {
            edges {
              node {
                name
              }
            }
          }
        }
      `,
      selected: false,
      key: "name",
      category: "alerts",
      variable: (prop = "") => ({
        ...commonFilterHeader,
        where: {
          ...commonFilterHeader.where,
          name: prop !== "" ? { like: `${prop}%` } : {},
        },
      }),
    },

    {
      id: 1,
      name: "Severity",
      gql: gql`
        query alertsBySeverity($where: AlertBoolExp, $first: Int) {
          alerts(where: $where, first: $first, distinctOn: [severity]) {
            edges {
              node {
                severity
              }
            }
          }
        }
      `,
      selected: false,
      key: "severity",
      category: "alerts",
      variable: (prop = "") => ({
        ...commonFilterHeader,
        where: {
          ...commonFilterHeader.where,
          severity: prop !== "" ? { eq: prop } : {},
        },
      }),
    },
  ];
};
