import { gql } from "graphql-request";

import type { UserGroupAssignedProjectsQuery } from "#shared/generated/graphql";

import { useGridData, type DataGridHook } from "#organization/hooks";

export const userGroupProjectsQuery = gql`
  query userGroupAssignedProjects(
    $where: UserGroupProjectAssignmentBoolExp
    $orderBy: [UserGroupProjectAssignmentOrderBy]
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    userGroupProjectAssignments(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          permissions
          userGroup {
            id
            name
          }
          project {
            name
            id
          }
        }
      }
    }
  }
`;

type UserGroupProjectsData =
  UserGroupAssignedProjectsQuery["userGroupProjectAssignments"]["edges"][0]["node"];

type AdaptedUserGroupProject = {
  id: string;
  name: string;
  permissions: string;
  groupAssignmentID: string;
};

export const PROJECT_SECTION_QUERY_KEY = "userGroupProjectAssignments";

export const useUserGroupProjects: DataGridHook = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  enabled,
  filterVariables,
) =>
  useGridData<UserGroupProjectsData, AdaptedUserGroupProject>(
    PROJECT_SECTION_QUERY_KEY,
    userGroupProjectsQuery,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    filterVariables,
    ({ project, id, permissions }) => ({
      permissions: permissions || "",
      id: project.id,
      name: project.name,
      groupAssignmentID: id,
    }),
    enabled,
    false,
  );
