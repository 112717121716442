import { Box } from "@mui/material";
import { isEmpty } from "lodash";
import React, { type FC } from "react";

import { NoDataLayout } from "#shared/components/layouts/no-data/no-data";
import { TabPanel } from "#shared/components/tab-panel";

import { Tabs, type TabsProps } from "#organization/components";
import type { GenericDashboardType } from "#organization/components/grafana";
import { dashboardsByResourceType } from "#organization/pages/authenticated/dashboard";
import type { ResourceWithGenericDashboard } from "#organization/pages/types";

import { PolicyResourceDashboard } from "./dashboard";

import type { UseDashboardQueryParamsReturnType } from "../../hooks";

export type PolicyResourceDashboardPageContentProps = {
  onTabChange: Required<TabsProps>["onChange"];
  activeDashboard: GenericDashboardType | null;
  resourceType: ResourceWithGenericDashboard;
  queryParams?: UseDashboardQueryParamsReturnType["queryParams"];
};

export const PolicyResourceDashboardPageContent: FC<
  PolicyResourceDashboardPageContentProps
> = ({ queryParams, activeDashboard, resourceType, onTabChange }) => {
  if (!queryParams || isEmpty(queryParams) || !activeDashboard) {
    return <NoDataLayout />;
  }

  const dashboard = dashboardsByResourceType[resourceType];

  if (!dashboard) {
    return null;
  }

  return (
    <>
      <Tabs
        {...{
          value: activeDashboard,
          onChange: onTabChange,
          children: Object.values(dashboard),
          sx: {
            padding: 1,
          },
        }}
      />
      <Box>
        {Object.keys(dashboard).map((dashboardType) => (
          <TabPanel
            key={dashboardType}
            value={dashboardType}
            activeValue={activeDashboard}
          >
            <PolicyResourceDashboard
              {...{
                resourceType,
                dashboardType: activeDashboard,
                queryParams: queryParams ? queryParams.queryParams : {},
              }}
            />
          </TabPanel>
        ))}
      </Box>
    </>
  );
};
