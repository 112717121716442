import {
  type AuthProvider,
  GithubAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";

export type DefaultSupportedIdpConfigsResponse = {
  defaultSupportedIdpConfigs?: DefaultSupportedIdpConfig[];
};

export type DefaultSupportedIdpConfig = {
  name: string;
  enabled: boolean;
  clientId: string;
  clientSecret: string;
};

export type InboundSamlConfigsResponse = {
  inboundSamlConfigs?: InboundSamlConfig[];
};

export type InboundSamlConfig = {
  displayName: string;
  enabled: boolean;
  idpConfig: IdpConfig;
  name: string;
  spConfig?: SpConfig;
};

export type IdpConfig = {
  idpCertificates: IdpCertificate[];
  idpEntityId: string;
  ssoUrl: string;
};

export type IdpCertificate = { x509Certificate: string };

export type SpConfig = {
  callbackUri: string;
  spEntityId: string;
};

export type OauthIdpConfigsResponse = {
  oauthIdpConfigs?: OauthIdpConfig[];
};

export type OauthIdpConfig = {
  clientId: string;
  clientSecret: string;
  displayName: string;
  enabled: true;
  issuer: string;
  name: string;
  responseType: { code: boolean };
};

export type TenantsResponse = Tenant[];

export type Tenant = {
  name: string;
  displayName: string;
  allowPasswordSignup?: boolean;
  enableEmailLinkSignin?: boolean;
  disableAuth?: boolean;
  hashConfig?: HashConfig;
  enableAnonymousUser?: boolean;
  mfaConfig?: MultiFactorAuthConfig;
  testPhoneNumbers?: {
    string?: string;
  };
  inheritance?: Inheritance;
  smsRegionConfig?: SmsRegionConfig;
  autodeleteAnonymousUsers?: boolean;
  monitoring?: MonitoringConfig;
  emailPrivacyConfig?: EmailPrivacyConfig;
  client?: ClientPermissionConfig;
};

type HashConfig = {
  algorithm: HashAlgorithm;
  signerKey: string;
  saltSeparator: string;
  rounds: number;
  memoryCost: number;
};

type Inheritance = Record<string, unknown>;
type MultiFactorAuthConfig = Record<string, unknown>;
type MonitoringConfig = Record<string, unknown>;
type EmailPrivacyConfig = Record<string, unknown>;
type ClientPermissionConfig = Record<string, unknown>;
type SmsRegionConfig = Record<string, unknown>;

type HashAlgorithm =
  | "HMAC_SHA256"
  | "HMAC_SHA1"
  | "HMAC_MD5"
  | "SCRYPT"
  | "PBKDF_SHA1"
  | "MD5"
  | "HMAC_SHA512"
  | "SHA1"
  | "BCRYPT"
  | "PBKDF2_SHA256"
  | "SHA256"
  | "SHA512"
  | "STANDARD_SCRYPT";

export type UnsecuredOauthIdpConfig = Pick<OauthIdpConfig, "displayName">;

export type UnsecuredDefaultSupportedIdpConfig = {
  displayName: DefaultIdpProvider;
};

export type UnsecuredInboundSamlConfig = { name: string; displayName: string };

export type UnsecuredTenant = Pick<
  Tenant,
  "displayName" | "enableEmailLinkSignin"
>;

export type TenantIdpConfigUnsecuredResponse = {
  allowPasswordSignup?: boolean;
  enableEmailLinkSignin?: boolean;
  defaultSupportedIdpConfigs?: UnsecuredDefaultSupportedIdpConfig[];
  oauthIdpConfigs?: UnsecuredOauthIdpConfig[];
  inboundSamlConfigs?: UnsecuredInboundSamlConfig[];
  isFallbackGoogleConfigured?: boolean;
};

export type TenantIdpConfigResponse = {
  allowPasswordSignup?: boolean;
  enableEmailLinkSignin?: boolean;
  isFallbackGoogleConfigured?: boolean;
  defaultSupportedIdpConfigs?: (DefaultSupportedIdpConfig & {
    displayName: DefaultIdpProvider;
  })[];
  oauthIdpConfigs?: OauthIdpConfig[];
  inboundSamlConfigs?: InboundSamlConfig[];
};

export type TenantConfigBodyMethod = "patch" | "post";

export type TenantConfigBody = {
  defaultSupportedIdpConfigs?: DefaultSupportedIdpConfig & {
    method: TenantConfigBodyMethod;
    displayName?: DefaultIdpProvider;
  };
  inboundSamlConfigs?: InboundSamlConfig & {
    method: TenantConfigBodyMethod;
  };
};

export type IdpConfigResponse = TenantIdpConfigUnsecuredResponse;

export type DefaultIdpProvider = "google.com" | "github.com";

export const DEFAULT_IDP_CONFIG_PROVIDERS: {
  [Provider in DefaultIdpProvider]: { new (): AuthProvider };
} = {
  "google.com": GoogleAuthProvider,
  "github.com": GithubAuthProvider,
};

export type MapIdpConfigResponseKeyToIdpConfigObject<
  Key extends keyof TenantIdpConfigUnsecuredResponse,
> = Key extends "allowPasswordSignup"
  ? boolean
  : Key extends "enableEmailLinkSignin"
  ? boolean
  : Key extends "defaultSupportedIdpConfigs"
  ? NonNullable<TenantIdpConfigUnsecuredResponse[Key]>[number]
  : Key extends "oauthIdpConfigs"
  ? NonNullable<TenantIdpConfigUnsecuredResponse[Key]>[number]
  : Key extends "inboundSamlConfigs"
  ? NonNullable<TenantIdpConfigUnsecuredResponse[Key]>[number]
  : never;

export declare type DefaultIdpResponse = {
  displayName: string;
} & DefaultSupportedIdpConfig;
