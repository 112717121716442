import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { lowerCase } from "lodash";
import { type CSSProperties, useState } from "react";
import type { Node } from "react-flow-renderer";

import type {
  FnPalette,
  FnTheme,
} from "../../../../../../../../app-theme-provider/types";
import type { NodeName, NodeType } from "../../../../../../../../types";
import { useCircuitContext } from "../../tabs/circuit/circuit-context";
import type { NodeData } from "../../types";

export const NodeStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
}));

export type CircuitColors = {
  component: Pick<CSSProperties, "color" | "backgroundColor">;
  port: Pick<CSSProperties, "color" | "backgroundColor">;
  edge: Pick<CSSProperties, "stroke">;
};

/**
 * @returns component's/port's/edge's css properties: (color && backgroundColor) || stroke
 *
 * CssProperties are read from theme on the basis of componentType and componentName.
 * If any css property is not found in the theme then the 'Other' colors are used.
 */
export function getCircuitColors(
  palette: FnPalette,
  componentType?: NodeType | null,
  componentName?: NodeName | null,
  isSelected?: boolean,
): CircuitColors {
  const lowerCaseComponentType = componentType
    ? (lowerCase(componentType).replace(" ", "") as Lowercase<NodeType>)
    : null;

  const lowerCaseComponentName = componentName
    ? (lowerCase(componentName).replace(" ", "") as Lowercase<NodeName>)
    : null;

  const {
    circuit: { components, ports, componentLabels, portLabels, edges },
  } = palette as Required<FnTheme["palette"]>;

  // NOTE: Get component type's colors object. If no colors object, use 'Other' colors object.
  const componentsColors = lowerCaseComponentType
    ? components[lowerCaseComponentType] || components.Other
    : components.Other;

  const portsColors = lowerCaseComponentType
    ? ports[lowerCaseComponentType] || ports.Other
    : ports.Other;

  const edgesColors = lowerCaseComponentType
    ? edges[lowerCaseComponentType] || edges.Other
    : edges.Other;

  const componentLabelsColors = lowerCaseComponentType
    ? componentLabels[lowerCaseComponentType] || componentLabels.Other
    : componentLabels.Other;

  const portLabelsColors = lowerCaseComponentType
    ? portLabels[lowerCaseComponentType] || portLabels.Other
    : portLabels.Other;

  // NOTE: If no component name, use 'Other' color
  if (!lowerCaseComponentName) {
    const result: CircuitColors = {
      component: {
        backgroundColor: componentsColors.Other,
        color: componentLabelsColors.Other,
      },
      port: {
        backgroundColor: portsColors.Other,
        color: portLabelsColors.Other,
      },
      edge: {
        stroke: isSelected
          ? findSelectedEdgeColor(palette, componentType)
          : edgesColors.Other,
      },
    };

    return result;
  }

  // NOTE: Get component name's color. If no color, use 'Other' color.
  const result: CircuitColors = {
    component: {
      backgroundColor:
        componentsColors[lowerCaseComponentName] || componentsColors.Other,
      color:
        componentLabelsColors[lowerCaseComponentName] ||
        componentLabelsColors.Other,
    },
    port: {
      backgroundColor: portsColors[lowerCaseComponentName] || portsColors.Other,
      color: portLabelsColors[lowerCaseComponentName] || portLabelsColors.Other,
    },
    edge: {
      stroke: isSelected
        ? findSelectedEdgeColor(palette, componentType)
        : edgesColors[lowerCaseComponentName] || edgesColors.Other,
    },
  };

  return result;
}

export const findSelectedEdgeColor = (
  palette: FnTheme["palette"],
  componentType: NodeType | null | undefined,
) => {
  const {
    circuit: { edges },
  } = palette as Required<FnTheme["palette"]>;

  if (!componentType) {
    return edges.selected.SignalProcessor;
  }

  return edges.selected[componentType];
};

export type LoopedSignalReturn = Partial<{
  style: CSSProperties;
  markerEnd: string;
}>;

export function loopedSignal(
  sourceNode: Node<NodeData> | undefined | null,
): LoopedSignalReturn {
  const checkOutPorts = sourceNode?.data.outPorts;

  if (!checkOutPorts) {
    return {};
  }

  const stroke = "orange";

  if (checkOutPorts.some((port) => port && port.looped)) {
    return {
      style: {
        stroke,
      },
      markerEnd: `url(#color=${stroke}&type=arrow)`,
    };
  }

  return {};
}

export const useHandleStyle = (): CSSProperties => {
  const { circuitLabelStatus: label } = useCircuitContext();

  return {
    position: "static",
    textOverflow: "ellipsis",
    overflow: "hidden",
    minHeight: label ? 25 : 18,
    minWidth: label ? 100 : 10,
    borderRadius: 0,
    border: "none",
  };
};

export const useOnHoverEdge = (theme: FnTheme) => {
  const [onHoverEdgeColor, setOnHoverEdgeColor] = useState<LoopedSignalReturn>(
    {},
  );
  const stroke = theme.palette.circuit?.edges.onHover;

  const onEdgeMouseEnter = () => {
    setOnHoverEdgeColor({
      style: { stroke },
      markerEnd: `url(#color=${stroke}&type=arrow)`,
    });
  };

  const onEdgeMouseLeave = () => {
    setOnHoverEdgeColor({});
  };

  return { onHoverEdgeColor, onEdgeMouseEnter, onEdgeMouseLeave };
};
