import { gql } from "graphql-request";

import type {
  AgentsGroupsTitlesQuery,
  AgentsGroupDataQuery,
} from "#shared/generated/graphql";

import {
  useGridData,
  useGroupData,
  useGroupsTitles,
  type DataGridHook,
  type GroupDataHook,
  type GroupsTitlesHook,
} from "#organization/hooks";

import { orderByStatusTemplate } from "../utils";

export type AgentsGroupBy = "agentGroup" | "version" | "agentKeyID" | "status";

const AGENTS_ENTITY_KEY = "agents";

export const agentGroupsTitles = gql`
  query AgentsGroupsTitles(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: AgentBoolExp
    $distinctOn: [AgentSelectColumn!]
  ) {
    agents(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      distinctOn: $distinctOn
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          status
          agentGroup
          version
          agentKey {
            key
          }
        }
      }
    }
  }
`;

type AgentGroupTitleData =
  AgentsGroupsTitlesQuery["agents"]["edges"][0]["node"];

export const useAgentsGroupsTitles: GroupsTitlesHook<AgentsGroupBy> = (
  pageSize,
  page,
  setPage,
  groupByKey,
  filterVariables,
) =>
  useGroupsTitles<AgentsGroupBy, AgentGroupTitleData>(
    AGENTS_ENTITY_KEY,
    pageSize,
    page,
    setPage,
    groupByKey,
    agentGroupsTitles,
    (node) => {
      if (groupByKey === "agentKeyID") {
        return node.agentKey.key;
      }

      return node[groupByKey!];
    },
    filterVariables,
  );

type AgentGroupData = AgentsGroupDataQuery["agents"]["edges"][0]["node"];
export type AdaptedAgentGroupData = Omit<AgentGroupData, "agentKey"> & {
  agentKey: string;
};

export const agentsGroupData = gql`
  query AgentsGroupData(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: AgentBoolExp
    $orderBy: [AgentOrderBy]
  ) {
    agents(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          status
          hostname
          id
          lastSeen
          agentGroup
          version
          controller {
            id
            name
            status
          }
          agentKey {
            key
          }
        }
      }
    }
  }
`;

export const useAgentsGroupData: GroupDataHook<AgentsGroupBy> = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  groupByKey,
  groupByValue,
  filterVariables,
) =>
  useGroupData<AgentsGroupBy, AgentGroupData, AdaptedAgentGroupData>(
    AGENTS_ENTITY_KEY,
    agentsGroupData,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    groupByKey,
    groupByValue,
    (agent) => ({ ...agent, agentKey: agent.agentKey.key }),
    filterVariables,
    {
      agentKeyID: {
        agentKey: {
          key: {
            eq: groupByValue,
          },
        },
      },
    },
  );

export const useAgentsData: DataGridHook = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  enabled,
  filterVariables,
) =>
  useGridData<AgentGroupData, AdaptedAgentGroupData>(
    AGENTS_ENTITY_KEY,
    agentsGroupData,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    filterVariables,
    (agent: AgentGroupData) => ({
      ...agent,
      agentKey: agent.agentKey.key,
      controllerName: agent.controller?.name,
      controllerId: agent.controller?.id,
    }),
    enabled,
    true,
    orderByStatusTemplate,
  );
