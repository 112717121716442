import { Box, MenuItem, useTheme } from "@mui/material";
import React, {
  useRef,
  type ChangeEvent,
  type Dispatch,
  type FC,
  type SetStateAction,
} from "react";
import { useRecoilValue } from "recoil";

import { Input } from "#shared/components/inputs/input";
import { PermissionEnum, userNewState } from "#shared/recoil";
import type { Texts } from "#shared/types";

import { TopBarBreadcrumbAppender } from "#organization/recoil/breadcrumbs";

import type { FnTheme } from "../../../../../../../../../app-theme-provider/types";
import { SectionHeader } from "../../../../components/section-header";
import { SettingsSectionLabel } from "../../../../components/settings-section-label";
import {
  SectionBody,
  SectionSelect,
  SectionWrapper,
} from "../../../../components/styled";
import {
  PROJECT_CLOUD_PROVIDERS,
  type CloudProvider,
} from "../../project-regions";

type Text =
  | "title"
  | "details"
  | "name"
  | "nameDescription"
  | "region"
  | "regionDescription"
  | "cloudProvider"
  | "cloudProviderDescription";

export interface ProjectDetailsSectionProps {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  texts?: Texts<Text>;
  region: string;
  cloudProvider: CloudProvider;
  setRegion: Dispatch<SetStateAction<string>>;
  setCloudProvider: Dispatch<SetStateAction<CloudProvider>>;
}

const enTexts: Required<ProjectDetailsSectionProps["texts"]> = {
  title: "Project details",
  details: "Details",
  name: "Project name",
  nameDescription: "Name your project",
  region: "Region",
  regionDescription: "Select the region for the Cloud Agents",
  cloudProvider: "Cloud provider",
  cloudProviderDescription: "Select the cloud provider for the Cloud Agents",
};

export const ProjectDetailsSection: FC<ProjectDetailsSectionProps> = ({
  name,
  setName,
  texts = enTexts,
  region,
  setRegion,
  cloudProvider,
  setCloudProvider,
}) => {
  const theme = useTheme() as unknown as FnTheme;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    sx: { zIndex: theme.zIndex.popoverInDialog },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // Initial values of region and cloud provider
  const { current: existingRegion } = useRef(region);
  const { current: existingCloudProvider } = useRef(cloudProvider);

  const userRole = useRecoilValue(userNewState.userRole)!;

  const permissionLock =
    PermissionEnum[userRole] < PermissionEnum.user_group_admin;

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  };

  return (
    <>
      <TopBarBreadcrumbAppender>{texts.details}</TopBarBreadcrumbAppender>
      <Box>
        <SectionWrapper>
          <SectionHeader title={texts.title} />
          <SectionBody>
            <SettingsSectionLabel
              primaryText={texts.name}
              secondaryText={texts.nameDescription}
              asterisk
            />
            <Input
              size="small"
              value={name}
              onChange={handleNameChange}
              disabled={permissionLock}
            />
            <SettingsSectionLabel
              primaryText={texts.cloudProvider}
              secondaryText={texts.cloudProviderDescription}
              asterisk
            />
            <SectionSelect
              value={cloudProvider}
              size="small"
              onChange={(event) => {
                const newCloudProvider = event.target.value as CloudProvider;

                setCloudProvider(newCloudProvider);

                if (newCloudProvider === existingCloudProvider) {
                  setRegion(existingRegion);
                } else {
                  setRegion(PROJECT_CLOUD_PROVIDERS[newCloudProvider][0]);
                }
              }}
              MenuProps={MenuProps}
              renderValue={(data) => (data as string).toUpperCase()}
            >
              {Object.keys(PROJECT_CLOUD_PROVIDERS).map(
                (projectCloudProvider) => (
                  <MenuItem
                    key={projectCloudProvider}
                    value={projectCloudProvider}
                  >
                    {projectCloudProvider.toUpperCase()}
                  </MenuItem>
                ),
              )}
            </SectionSelect>
            <SettingsSectionLabel
              primaryText={texts.region}
              secondaryText={texts.regionDescription}
              asterisk
            />
            <SectionSelect
              value={region}
              size="small"
              onChange={(event) => {
                setRegion(event.target.value as string);
              }}
              MenuProps={MenuProps}
              renderValue={(data) => data as string}
            >
              {PROJECT_CLOUD_PROVIDERS[cloudProvider].map((projectRegion) => (
                <MenuItem key={projectRegion} value={projectRegion}>
                  {projectRegion}
                </MenuItem>
              ))}
            </SectionSelect>
          </SectionBody>
        </SectionWrapper>
      </Box>
    </>
  );
};
