import type { Theme } from "@mui/material";
import { format } from "date-fns";

import { HEALTH_STATUSES } from "#shared/consts";
import type { AgentsGroupDataQuery } from "#shared/generated/graphql";

type Agent = Readonly<AgentsGroupDataQuery["agents"]["edges"][number]>;

type WithHealth = { node: Pick<Agent["node"], "status"> };

export const globalTimeParser = (time: string | null): string => {
  if (!time) {
    return "-";
  }

  return format(new Date(time), "MMM d, yyyy h:mm:ss a");
};

export interface ParseStatusesReturn {
  name: string;
  value: number;
  color: "success.main" | "warning.main" | "error.main" | string;
}

export function parseStatuses<A extends WithHealth>(
  data: ReadonlyArray<A> = [],
  theme: Theme = {} as Theme,
): ParseStatusesReturn[] {
  let running = 0;
  let stopped = 0;
  let archived = 0;

  data?.forEach((element) => {
    if (element?.node.status === HEALTH_STATUSES.HEALTHY) running += 1;

    if (element?.node.status === HEALTH_STATUSES.DISCONNECTED) stopped += 1;

    if (element?.node.status === HEALTH_STATUSES.ARCHIVED) archived += 1;
  });

  return [
    {
      name: "online",
      value: running,
      color: "success.main",
    },
    {
      name: "disconnected",
      value: stopped,
      color: "error.main",
    },
    {
      name: "archived",
      value: archived,
      color: theme.palette.text.disabled,
    },
  ];
}

export function parseStatus(status: string): ParseStatusesReturn {
  switch (status) {
    case HEALTH_STATUSES.HEALTHY:
      return {
        name: "online",
        value: 1,
        color: "success.main",
      };
    case HEALTH_STATUSES.UNHEALTHY:
      return {
        name: "unhealthy",
        value: 1,
        color: "warning.main",
      };
    case HEALTH_STATUSES.DISCONNECTED:
      return {
        name: "disconnected",
        value: 1,
        color: "error.main",
      };
    default:
      return {
        name: "disconnected",
        value: 1,
        color: "error.main",
      };
  }
}
