import { Check } from "@mui/icons-material";
import { MenuItem, SvgIcon } from "@mui/material";
import { Box } from "@mui/system";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useMemo, type FC } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import { Image } from "#shared/components/image";
import { ErrorLayout, LoaderLayout } from "#shared/components/layouts";
import { LoggerService } from "#shared/services";
import type { Texts } from "#shared/types";
import { FEATURE_FLAGS, FeatureFlag } from "#shared/utils";

import { QUERY_KEYS } from "#organization/pages/consts";

import googleIcon from "./assets/Google.svg";
import emailIcon from "./assets/Mail.svg";
import samlIcon from "./assets/SAML.svg";
import {
  type DefaultIdpConfigFormValues,
  DefaultSupportedIdpConfigForm,
  type SamlConfigFormValues,
  SamlSignInConfigForm,
  type DefaultSupportedIdpConfigFormProps,
  type SamlSignInConfigFormProps,
} from "./components";
import { EmailConfigForm } from "./components/email-configuration";
import {
  GOOGLE_DEFAULT_DATA,
  SAML_DEFAULT_DATA,
  type SubmitConfigsValue,
  useFormsData,
  useGetIdpConfigs,
  useSubmitIdpConfigs,
} from "./hooks";

import { SettingsButtons, SettingsSectionLabel } from "../../components";
import { SectionSelect, SectionWrapper } from "../../components/styled";
import { useIsAuthnSettings } from "../hooks";

export interface AuthPageProps {
  texts?: Texts<Text>;
}

const authList = [
  {
    title: "Default (email)",
    value: "email",
    icon: emailIcon,
  },
  {
    title: "Google Sign-In",
    value: "google",
    icon: googleIcon,
  },
  {
    title: "SSO via SAML",
    value: "saml",
    icon: samlIcon,
  },
] as const;
type AuthType = (typeof authList)[number]["value"];

type Text =
  | "google"
  | "saml"
  | "submit"
  | "submitError"
  | "notDirtyWarning"
  | "authHeading"
  | "authDescription";

const enTexts: Required<AuthPageProps["texts"]> = {
  google: "Google configuration",
  saml: "SAML configuration",
  submit: "Submit",
  submitError: "Something went wrong",
  notDirtyWarning: "Change any value to enable submit",
  authHeading: "Authorization method",
  authDescription:
    "Choose a provider for authorization (you can only choose one)",
};

export const AuthnPage: FC<AuthPageProps> = ({ texts = enTexts }) => {
  const { data, isFetching: isLoadingConfigs, error } = useGetIdpConfigs();
  const currentIdpConfig = useMemo<AuthType>(
    () =>
      (data?.inboundSamlConfigs &&
        data.inboundSamlConfigs[0]?.enabled &&
        "saml") ||
      (data?.defaultSupportedIdpConfigs &&
        data.defaultSupportedIdpConfigs[0]?.enabled &&
        "google") ||
      "email",
    [data?.defaultSupportedIdpConfigs, data?.inboundSamlConfigs],
  );

  const formsData = useFormsData(data);

  const googleForm = useForm<DefaultIdpConfigFormValues>({
    mode: "onTouched",
    values: { ...GOOGLE_DEFAULT_DATA, ...(formsData.google.data || {}) },
  });

  const samlForm = useForm<SamlConfigFormValues>({
    mode: "onTouched",
    values: { ...SAML_DEFAULT_DATA, ...(formsData.saml.data || {}) },
  });

  const {
    submit,
    httpState: { isLoading: isSubmittingConfigs, isSuccess: isSubmitSuccess },
    reset: resetSubmitConfigs,
  } = useSubmitIdpConfigs();

  const isAuthnSettings = useIsAuthnSettings();

  const disabled = useMemo(
    () => !!(isLoadingConfigs || isSubmittingConfigs || !isAuthnSettings),
    [isLoadingConfigs, isSubmittingConfigs, isAuthnSettings],
  );

  const onSamlChange = useCallback<SamlSignInConfigFormProps["onChange"]>(
    (samlChanged) => {
      formsData.saml.setData(samlChanged);
      resetSubmitConfigs();
    },
    [formsData.saml, resetSubmitConfigs],
  );

  const onGoogleChange = useCallback<
    DefaultSupportedIdpConfigFormProps["onChange"]
  >(
    (googleChanged) => {
      formsData.google.setData(googleChanged);
      resetSubmitConfigs();
    },
    [formsData.google, resetSubmitConfigs],
  );

  const isDirty = useMemo(
    () => formsData.google.isDirty || formsData.saml.isDirty,
    [formsData.google.isDirty, formsData.saml.isDirty],
  );

  const [selectedAuth, setSelectedAuth] =
    React.useState<AuthType>(currentIdpConfig);
  const filteredAuth = useMemo(
    () =>
      authList.filter(
        ({ value }) =>
          (value === "saml" && FEATURE_FLAGS.samlSignInConfiguration) ||
          (value === "google" && FEATURE_FLAGS.googleSignInConfiguration) ||
          true,
      ),
    [],
  );

  useEffect(() => {
    setSelectedAuth(currentIdpConfig);
  }, [currentIdpConfig]);

  const submitForm = useCallback(() => {
    const auth = selectedAuth;

    if (auth === "saml") {
      samlForm.trigger();
    } else if (auth === "google") {
      googleForm.trigger();
    }

    if (
      disabled ||
      (auth === "saml" && !isEmpty(samlForm.formState.errors)) ||
      (auth === "google" && !isEmpty(googleForm.formState.errors))
    ) {
      return;
    }

    if (!isDirty) {
      LoggerService.warn("Neither saml nor google form is dirty.");
    }

    const value: SubmitConfigsValue = {
      google:
        auth === "google"
          ? {
              ...(formsData.google.data || GOOGLE_DEFAULT_DATA),
              enabled: true,
              method: formsData.google.method,
            }
          : undefined,
      saml:
        auth === "saml"
          ? {
              ...(formsData.saml.data || SAML_DEFAULT_DATA),
              enabled: true,
              method: formsData.saml.method,
            }
          : undefined,
    };

    submit(value);
  }, [
    selectedAuth,
    disabled,
    samlForm,
    googleForm,
    isDirty,
    formsData.google.data,
    formsData.google.method,
    formsData.saml.data,
    formsData.saml.method,
    submit,
  ]);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isSubmitSuccess) {
      return;
    }

    queryClient.refetchQueries(QUERY_KEYS.CONFIGURED_IDP_PROVIDERS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccess]);

  return (
    <>
      <SettingsButtons
        search={false}
        onSave={submitForm}
        onResetAll={formsData.resetForm}
        disabled={disabled}
        title="Authentication"
      />
      {isLoadingConfigs ? <LoaderLayout /> : null}
      {error ? <ErrorLayout /> : null}
      {!isLoadingConfigs && !error ? (
        <SectionWrapper>
          <SettingsSectionLabel
            primaryText={texts.authHeading}
            secondaryText={texts.authDescription}
          />
          <Box my={2}>
            <SectionSelect
              value={selectedAuth}
              size="small"
              disabled={disabled}
              onChange={({ target: { value } }) => {
                setSelectedAuth(value as AuthType);
              }}
            >
              {filteredAuth.map((auth) => (
                <MenuItem key={auth.value} value={auth.value}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <SvgIcon
                      {...{ src: auth.icon, alt: auth.value, component: Image }}
                    />
                    <Box flex={1}>{auth.title}</Box>
                    {auth.value === currentIdpConfig ? <Check /> : null}
                  </Box>
                </MenuItem>
              ))}
            </SectionSelect>
          </Box>

          {selectedAuth === "saml" && (
            <FeatureFlag
              flag="samlSignInConfiguration"
              renderOn={
                <SamlSignInConfigForm
                  {...{
                    disabled,
                    onChange: onSamlChange,
                    register: samlForm.register,
                    errors: samlForm.formState.errors,
                    data: formsData.saml.data,
                    as: formsData.saml.data?.displayName,
                    onSubmit: submitForm,
                  }}
                />
              }
            />
          )}
          {selectedAuth === "google" && (
            <FeatureFlag
              flag="googleSignInConfiguration"
              renderOn={
                <DefaultSupportedIdpConfigForm
                  {...{
                    disabled,
                    onChange: onGoogleChange,
                    register: googleForm.register,
                    errors: googleForm.formState.errors,
                    displayName: formsData.google.data?.displayName,
                    data: formsData.google.data,
                    isDirty: formsData.google.isDirty,
                    onSubmit: submitForm,
                  }}
                />
              }
              renderOff={null}
            />
          )}
          {selectedAuth === "email" && (
            <EmailConfigForm
              {...{
                disabled,
                onSubmit: submitForm,
              }}
            />
          )}
        </SectionWrapper>
      ) : null}
    </>
  );
};
