import { Box, Button, Typography } from "@mui/material";
import React from "react";
import type { UseMutationResult } from "react-query";

import { CopyTextArea } from "#shared/components/inputs/copy-text-area";
import {
  type DialogControl,
  DialogLayout,
} from "#shared/components/layouts/dialog";
import type { AnyObject, Texts } from "#shared/types";

import type { ApiState } from "../../page";

export type RevokeKeyDialogProps<
  TDataRevoke = unknown,
  TVariableRevoke = unknown,
> = {
  texts?: Texts;
  dialogControl: DialogControl;
  refetch: () => void;
  selectedApiKey: ApiState;
  useRevokeKey: () => UseMutationResult<TDataRevoke, unknown, TVariableRevoke>;
  mapToInput: (data: AnyObject) => TVariableRevoke;
};

type Text =
  | "dialogTitle"
  | "dialogContentText"
  | "buttonConfirmText"
  | "cancel"
  | "confirmText";

const enTexts: Texts<Text> = {
  dialogTitle: "Revoke key",
  buttonConfirmText: "Yes, revoke the key",
  dialogContentText: "Are you sure you want to revoke the following key?",
  confirmText: "Click below to confirm.",
  cancel: "Cancel",
};

export const RevokeKeyDialog = <
  TDataRevoke = unknown,
  TVariableRevoke = unknown,
>({
  dialogControl,
  refetch,
  selectedApiKey,
  useRevokeKey,
  mapToInput,
  texts = enTexts,
}: RevokeKeyDialogProps<TDataRevoke, TVariableRevoke>): JSX.Element => {
  const agentRevokeKeyHook = useRevokeKey();

  const revokeKeyConfirm = () => {
    agentRevokeKeyHook.mutate(mapToInput({ id: selectedApiKey.id }), {
      onSuccess: () => {
        refetch();
        dialogControl.close();
      },
    });
  };

  return (
    <DialogLayout
      dialogTitle={texts.dialogTitle}
      dialogContentText={texts.dialogContentText}
      onCloseDialog={dialogControl.close}
      openDialog={dialogControl.isOpen}
      onClickDialogClose={dialogControl.close}
      onConfimrDialog={revokeKeyConfirm}
      buttonConfirmText={texts.buttonConfirmText}
      buttonConfirmVariant="contained"
      buttonConfirmColor="error"
      buttonCancelVariant="outlined"
      buttonCancelColor="primary"
      dialogAction={false}
    >
      <Box pt={1}>
        <CopyTextArea
          cliTextBody={selectedApiKey.key}
          cliTextContent={selectedApiKey.key}
          isOneLine
          hideCopyButton
        />
        <Typography mt={1}>{texts.confirmText}</Typography>
        <Box display="flex" justifyContent="flex-end" mt={4} gap={1}>
          <Button variant="text" color="primary" onClick={dialogControl.close}>
            {texts.cancel}
          </Button>
          <Button variant="contained" color="error" onClick={revokeKeyConfirm}>
            {texts.buttonConfirmText}
          </Button>
        </Box>
      </Box>
    </DialogLayout>
  );
};
