import { Box, Button, Typography } from "@mui/material";
import React, {
  useEffect,
  useMemo,
  useState,
  type FC,
  useCallback,
} from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { useAlert } from "#shared/components/alerts-provider";
import {
  ConfirmDialog,
  useControlDialog,
} from "#shared/components/layouts/dialog";
import { PermissionEnum, userNewState } from "#shared/recoil";
import { LoggerService } from "#shared/services/logger";
import type { Texts } from "#shared/types";

import { useDeleteProject } from "#organization/graphql";
import { TopBarBreadcrumbAppender } from "#organization/recoil/breadcrumbs";

import { ProjectDetailsSection } from "./components/project-details-section";
import { UserGroupsSection } from "./components/user-groups-section";
import {
  useChangeProjectDescription,
  useChangeProjectName,
  useChangeProjectRegion,
  useUpdateProjectAssignmentPermissionsHelper,
} from "./hooks";

import { SectionHeader } from "../../../components/section-header";
import { SettingsButtons } from "../../../components/settings-buttons";
import { SectionWrapper } from "../../../components/styled";
import { useGetProjectById } from "../hooks";
import {
  DEFAULT_PROJECT_CLOUD_PROVIDER,
  DEFAULT_PROJECT_REGION,
  type CloudProvider,
} from "../project-regions";
import {
  PROJECTS_SETTINGS_ROUTE,
  type ProjectIdParam,
} from "../routes.definitions";

type Text =
  | "nameChangeSuccess"
  | "nameChangeError"
  | "regionChangeSuccess"
  | "regionChangeError"
  | "descriptionChangeSuccess"
  | "descriptionChangeError"
  | "removeSuccess"
  | "removeError"
  | "removeConfirmText"
  | "deleteProjectDescription"
  | "orgOwner"
  | "or"
  | "admin"
  | "role"
  | "deleteProject"
  | "permissionsChangeSuccess"
  | "permissionsChangeError";

interface ProjectPageProps {
  texts?: Texts<Text>;
}

export type ProjectPageTexts = Texts<Text>;

export const enTextProjectPage: Texts<Text> = {
  nameChangeSuccess: "Successfully changed project name.",
  nameChangeError: "Could not change project name.",
  regionChangeSuccess: "Successfully changed project region.",
  regionChangeError: "Could not change project region.",
  descriptionChangeSuccess: "Successfully changed project description.",
  descriptionChangeError: "Could not change project description.",
  removeSuccess: "Successfully removed project from organization.",
  removeError: "Could not remove project from organization.",
  removeConfirmText: "remove this $1 from organization?",
  deleteProjectDescription: `Deleting the project will remove the project and all data associated
  with it. Deleting the project cannot be undone. You can perform this
  action freely if you are a user with`,
  orgOwner: " organization owner ",
  or: "or",
  admin: " admin ",
  role: "role.",
  deleteProject: "Delete Project",
  permissionsChangeError: "Could not change project permissions.",
  permissionsChangeSuccess: "Successfully changed project permissions.",
};

export const ProjectPage: FC<ProjectPageProps> = ({
  texts = enTextProjectPage,
}) => {
  const userRole = useRecoilValue(userNewState.userRole)!;

  const permissionLock =
    PermissionEnum[userRole] < PermissionEnum.user_group_admin;
  const { projectId } = useParams<ProjectIdParam>() as ProjectIdParam;
  const project = useGetProjectById(projectId);
  const projectName = useChangeProjectName();
  const projectRegion = useChangeProjectRegion();
  const projectDescription = useChangeProjectDescription();
  const { addAlert } = useAlert();
  const confirmDeleteDialogControl = useControlDialog();
  const projectControl = useDeleteProject();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState<boolean>(permissionLock || false);
  const [name, setName] = useState<string>(project?.data?.name || "");
  const [description, setDescription] = useState<string>(
    project?.data?.description || "",
  );
  const [cloudProvider, setCloudProvider] = useState<CloudProvider>(
    (project?.data?.cloud_provider as CloudProvider) ||
      DEFAULT_PROJECT_CLOUD_PROVIDER,
  );
  const [region, setRegion] = useState<string>(
    project?.data?.region || DEFAULT_PROJECT_REGION,
  );

  const {
    updateUserGroupPermissionPayload,
    setUpdateUserGroupPermissionPayload,
    triggerPermissionUpdate,
  } = useUpdateProjectAssignmentPermissionsHelper();

  const setData = useCallback(() => {
    setName(project?.data?.name || "");
    setRegion(project?.data?.region || DEFAULT_PROJECT_REGION);

    setCloudProvider(
      (project?.data?.cloud_provider as CloudProvider) ||
        DEFAULT_PROJECT_CLOUD_PROVIDER,
    );
    setDescription(project?.data?.description || "");
  }, [
    project?.data?.cloud_provider,
    project?.data?.description,
    project?.data?.name,
    project?.data?.region,
  ]);

  useEffect(() => {
    if (!project.loading) {
      setData();
    }
  }, [
    project.loading,
    project?.data?.name,
    project?.data?.description,
    project?.data?.region,
    project?.data?.cloud_provider,
    setData,
  ]);

  const client = useQueryClient();

  const refetchAssignedUserGroups = useMemo(
    () => () =>
      client.invalidateQueries([
        "gridData",
        "userGroupProjectAssignments",
        "",
        "asc",
        10,
        0,
        `{"projectID":{"eq":"${projectId}"}}`,
      ]),
    [client, projectId],
  );

  const onSave = () => {
    if (name !== project?.data?.name) {
      setDisabled(true);

      projectName.change(
        { id: project?.data?.id, name },
        {
          onSuccess: () => {
            setDisabled(false);

            project.refetch();

            addAlert({
              type: "success",
              message: texts.nameChangeSuccess,
            });
          },
          onError: () => {
            setDisabled(false);

            addAlert({
              type: "error",
              message: texts.nameChangeError,
            });
          },
        },
      );
    }

    if (
      cloudProvider !== project?.data?.cloud_provider ||
      region !== project?.data?.region
    ) {
      setDisabled(true);

      projectRegion.change(
        { id: project?.data?.id, region, cloud_provider: cloudProvider },
        {
          onSuccess: () => {
            setDisabled(false);

            project.refetch();

            addAlert({
              type: "success",
              message: texts.regionChangeSuccess,
            });
          },
          onError: () => {
            setDisabled(false);

            addAlert({
              type: "error",
              message: texts.regionChangeError,
            });
          },
        },
      );
    }

    if (updateUserGroupPermissionPayload?.length) {
      setDisabled(true);

      triggerPermissionUpdate(
        () => {
          setDisabled(false);
          refetchAssignedUserGroups();
        },
        () => {
          setDisabled(false);
        },
      );
    }

    if (description !== project?.data?.description) {
      setDisabled(true);

      projectDescription.change(
        { id: project?.data?.id, description },
        {
          onSuccess: () => {
            setDisabled(false);

            project.refetch();

            addAlert({
              type: "success",
              message: texts.descriptionChangeSuccess,
            });
          },
          onError: () => {
            setDisabled(false);

            addAlert({
              type: "error",
              message: texts.descriptionChangeError,
            });
          },
        },
      );
    }
  };

  const onReset = () => {
    setData();
    setUpdateUserGroupPermissionPayload([]);
  };

  const removeProject = () => {
    projectControl.remove(
      { id: projectId },
      {
        onSuccess: () => {
          navigate(PROJECTS_SETTINGS_ROUTE.ABSOLUTE_PATH);

          addAlert({
            type: "success",
            message: texts.removeSuccess,
          });
        },
        onError: (error) => {
          LoggerService.error(error);

          addAlert({
            type: "error",
            message: texts.removeError,
          });
        },
      },
    );
  };

  return (
    <>
      <TopBarBreadcrumbAppender>{project?.data?.name}</TopBarBreadcrumbAppender>

      <SettingsButtons
        onSave={onSave}
        onResetAll={onReset}
        disabled={disabled}
        backButton
        backGoTo={PROJECTS_SETTINGS_ROUTE.ABSOLUTE_PATH}
      />

      <ProjectDetailsSection
        name={name}
        setName={setName}
        region={region}
        setRegion={setRegion}
        cloudProvider={cloudProvider}
        setCloudProvider={setCloudProvider}
      />

      <UserGroupsSection
        projectName={project?.data?.name}
        projectId={project?.data?.id}
        hideCreateNewUserGroupButton
      />

      <SectionWrapper>
        <SectionHeader title="Delete project" />

        <Box sx={{ mt: 3, ml: 1, color: "text.primary" }}>
          {texts.deleteProjectDescription}
          <b>{texts.orgOwner}</b>
          {texts.or}
          <b>{texts.admin}</b>
          {texts.role}
        </Box>

        <Button
          size="small"
          onClick={confirmDeleteDialogControl.open}
          sx={{ mt: 2, ml: 1 }}
          disabled={permissionLock}
          variant="outlined"
          color="error"
        >
          {texts.deleteProject}
        </Button>

        <ConfirmDialog
          isOpen={confirmDeleteDialogControl.isOpen}
          close={confirmDeleteDialogControl.close}
          onConfirm={removeProject}
          text={texts.removeConfirmText.replace(
            "$1",
            project?.data?.name || "",
          )}
          confirmPhrase={project?.data?.name}
        />
      </SectionWrapper>

      {false && (
        <SectionWrapper>
          <SectionHeader title="transfer project" />

          <Box sx={{ mt: 3, ml: 1, color: "secondary.light" }}>
            <Typography>Transferring the project cannot be undone.</Typography>
            <Typography>
              Please enter the email of an organization owner to whom you would
              like to transfer this project. A request will be emailed to this
              address, asking the organization owner to accept the project
              transfer.
            </Typography>
          </Box>

          <Button variant="contained" size="small" sx={{ mt: 3 }} disabled>
            transfer project
          </Button>
        </SectionWrapper>
      )}
    </>
  );
};
