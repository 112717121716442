/**
 * NOTE:
 *
 * Read from env.js
 */
const DEFAULT_DASHBOARD_VERSION: Record<Dashboard, number> = {
  FLOW_MAP: 1,
  FLUX_METER: 1,
  RATE_LIMITER: 1,
  SIGNAL: 1,
  CONCURRENCY_LIMITER: 1,
  FLOW_ANALYTICS: 1,
  FLOW_ANALYTICS_BY_FLOW_LABEL: 1,
  FLOW_ANALYTICS_SDK: 1,
  FLOW_ANALYTICS_SDK_BY_FLOW_LABEL: 1,
  CACHE_HIT_MISS: 1,
};

export const DASHBOARDVER = {
  ...DEFAULT_DASHBOARD_VERSION,
  ...((window.ENV as Pick<FnEnv, Dashboard>) || {}),
};
