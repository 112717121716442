import {
  Box,
  Button,
  type ButtonProps,
  Typography,
  hexToRgb,
} from "@mui/material";
import { alpha } from "@mui/system";
import React, {
  type FC,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";

import { ErrorLayout, GlobalFrame } from "#shared/components/layouts";
import { SignInWithButtonStyle } from "#shared/components/layouts/sign-in";
import { ExternalTextLink } from "#shared/components/text-link";
import {
  type AuthnProvider,
  SIGN_IN_ROUTE,
  QUERY_PARAMS,
} from "#shared/consts";
import { FirebaseContext } from "#shared/contexts/firebase";
import { ENV } from "#shared/env";
import { isUserLoadingState } from "#shared/recoil";
import type { Texts } from "#shared/types";
import { getGlobalAppUrl } from "#shared/utils";

import { useOrganizationState } from "#organization/recoil/organization";

import { CompleteSignInFirebase } from "./complete-sign-in-firebase";
import { TenantSignIn } from "./components";

import { OWNER_SIGN_IN_PAGE_ROUTE } from "../owner-sign-in-page";

export interface SignInFirebaseProps {
  texts?: Texts<Text>;
}

type Text =
  | Extract<AuthnProvider, "github" | "google">
  | "createOrganization"
  | "createAccount"
  | "help"
  | "header"
  | "areYouOwner"
  | "signInWithLink"
  | "firebaseError"
  | "configError"
  | "wantToSignInToAnother"
  | "seeListOfOrg";

const enTexts: Required<SignInFirebaseProps["texts"]> = {
  github: "Sign in with Github",
  google: "Sign in with Google",
  createOrganization: "Don’t have an account?",
  createAccount: "Register.",
  help: "Get help",
  header: "Sign in to",
  areYouOwner: "Organization owners can also",
  signInWithLink: "sign in with link",
  firebaseError: "Failed to initialize firebase.",
  configError: "Failed to fetch firebase tenant config.",
  wantToSignInToAnother: "Want to sign in to another organization?",
  seeListOfOrg: "See the list of your organizations",
};

export const SignInPage: FC<SignInFirebaseProps> = ({ texts = enTexts }) => {
  const {
    tenantConfig,
    tenantConfig: { tenantSignInProps },
    httpState,
  } = useContext(FirebaseContext);

  const organizationState = useOrganizationState();

  const isLoading = useRecoilValueLoadable(isUserLoadingState).getValue();

  const navigate = useNavigate();

  const enableEmailLinkSignIn = useMemo(
    () => !!tenantConfig.result?.enableEmailLinkSignin,
    [tenantConfig.result?.enableEmailLinkSignin],
  );

  const allowPasswordSignIn = useMemo(
    () => !!tenantConfig.result?.allowPasswordSignup,
    [tenantConfig.result?.allowPasswordSignup],
  );

  const isFallbackGoogleConfigured = useMemo(
    () => !!tenantConfig.result?.isFallbackGoogleConfigured,
    [tenantConfig.result?.isFallbackGoogleConfigured],
  );

  useEffect(() => {
    // Refetch tenant config on mount
    tenantConfig.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * NOTE:
   *
   * Owner of organization can sign in with email link or password (only placeholder).
   */
  const navigateToOwnerSignIn = useCallback<
    Required<ButtonProps>["onClick"]
  >(() => {
    const params = [
      enableEmailLinkSignIn ? "email" : null,
      allowPasswordSignIn ? "password" : null,
    ].filter(Boolean);

    navigate(
      [
        OWNER_SIGN_IN_PAGE_ROUTE.ABSOLUTE_PATH,
        [QUERY_PARAMS.SIGN_IN_CONFIGS, params.join(",")].join("="),
      ].join("?"),
    );
  }, [navigate, allowPasswordSignIn, enableEmailLinkSignIn]);

  const isOwnerFallback =
    (!isFallbackGoogleConfigured ||
      !!tenantSignInProps.inboundSamlConfigs?.length ||
      !!tenantSignInProps.oauthIdpConfigs?.length) &&
    (enableEmailLinkSignIn || allowPasswordSignIn) &&
    !tenantConfig.httpState.isLoading &&
    !tenantConfig.httpState.isIdle;

  return (
    <GlobalFrame hideLogout hideHelp showSignInErrorMsg>
      <Typography mt={4} mb={4} variant="h4" textAlign="center">
        {texts.header}
      </Typography>
      <Typography
        variant="h5"
        textAlign="center"
        color="primary.main"
        mb={4}
        sx={{
          background: (theme) =>
            alpha(hexToRgb(theme.palette.primary.main), 0.08),
          p: 1,
          borderRadius: 2,
          wordBreak: "break-word",
        }}
      >
        {`${organizationState.subdomain}.${ENV.VITE_APP_DOMAIN}`}
      </Typography>

      <SignInWithButtonStyle />

      {tenantConfig.httpState.error || httpState.error ? (
        <ErrorLayout>
          {httpState.error ? texts.firebaseError : texts.configError}
        </ErrorLayout>
      ) : (
        <TenantSignIn {...tenantSignInProps} />
      )}

      <Box width="100%" textAlign="center" mb={4} mt={4} pt={2}>
        <Typography variant="body1">{texts.wantToSignInToAnother}</Typography>
        <Button
          variant="text"
          disabled={isLoading}
          size="large"
          sx={{
            padding: 0,
            letterSpacing: "inherit",
            "&:hover": { background: "initial" },
          }}
        >
          <ExternalTextLink href={getGlobalAppUrl(SIGN_IN_ROUTE.ABSOLUTE_PATH)}>
            {texts.seeListOfOrg}
          </ExternalTextLink>
        </Button>
      </Box>

      {isOwnerFallback ? (
        <Box
          width="100%"
          mb={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="body1" component="span">
            {texts.areYouOwner}
            <Typography
              color="primary"
              component="span"
              sx={{
                marginLeft: 0.5,
                padding: 0,
                letterSpacing: "inherit",
                cursor: "pointer",
                textDecoration: "underline",
                "&:hover": { background: "initial", textDecoration: "none" },
              }}
              onClick={navigateToOwnerSignIn}
            >
              {texts.signInWithLink}
            </Typography>
          </Typography>
        </Box>
      ) : null}
    </GlobalFrame>
  );
};

export const SignInPageFirebase: FC = () => {
  const { isSignInWithEmailLink } = useContext(FirebaseContext);

  return isSignInWithEmailLink ? (
    <CompleteSignInFirebase>
      <SignInPage />
    </CompleteSignInFirebase>
  ) : (
    <SignInPage />
  );
};
