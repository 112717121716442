import { Global, type GlobalProps } from "@emotion/react";
import { Button, CircularProgress } from "@mui/material";
import type { ButtonProps } from "@mui/material/Button";
import classnames from "classnames";
import React, { type FC } from "react";

import { ErrorText } from "../../error";

export type SignInWithButtonProps<E = unknown> = ButtonProps & {
  isLoading?: boolean;
  error?: null | E;
};

const defaultProps: Pick<
  SignInWithButtonProps,
  "fullWidth" | "variant" | "size"
> = {
  fullWidth: true,
  variant: "contained",
  size: "large",
};

export const SIGN_IN_WITH_BUTTON_CLASSNAME = "button--sign-in-with";

export const SignInWithButtonStyle: FC = () => {
  const styles: GlobalProps["styles"] = {
    ".MuiButtonBase-root": {
      [`&.${SIGN_IN_WITH_BUTTON_CLASSNAME}`]: {
        backgroundColor: "white",
        color: "black",
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
  };

  return <Global {...{ styles }} />;
};

export const SignInWithButton: FC<SignInWithButtonProps> = (props) => {
  const { children, isLoading, error, ...mergedProps } = {
    ...defaultProps,
    ...props,
  };

  return (
    <>
      <Button
        {...mergedProps}
        className={classnames(
          SIGN_IN_WITH_BUTTON_CLASSNAME,
          mergedProps.className,
        )}
      >
        {children}
        {isLoading ? (
          <CircularProgress
            sx={({ spacing }) => ({ marginLeft: spacing(Number(!!children)) })}
            size="1.5rem"
          />
        ) : null}
      </Button>
      {error ? <ErrorText {...{ error }} /> : null}
    </>
  );
};
