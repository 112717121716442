import { useEffect, useState, useMemo, useCallback, useRef } from "react";

import { LoggerService } from "#shared/services";

import { useGetDefaultProject, useGetDefaultUserGroup } from "./hooks";
import type { Invitation, UseInvite } from "./use-invite";
import {
  useSendAllRequests,
  type UseSendAllRequestsOptions,
} from "./use-send-all-requests";

import {
  DEFAULT_PROJECT_CLOUD_PROVIDER,
  DEFAULT_PROJECT_REGION,
  type CloudProvider,
} from "../../settings/organization/projects/project-regions";
import type { NewProject } from "../types";

export interface UseCreatorViewOptions {
  onResult: UseSendAllRequestsOptions["onResult"];
  emptyMember: Invitation;
  invite: UseInvite;
}

export type UseCreatorView = ReturnType<typeof useCreatorView>;

export enum Steps {
  CreateProject = "Create a Project",
  CreateUserGroup = "Create a User Group",
  InviteTeam = "Invite your Team",
}

const steps: Array<Steps> = Object.values(Steps);

export function useCreatorView({
  onResult: onComplete,
  invite,
}: UseCreatorViewOptions) {
  const defaultUserGroup = useGetDefaultUserGroup();
  const defaultProject = useGetDefaultProject();

  const {
    validateInvitations,
    resetInvitations,
    sendInvitations,
    isInviteListValid,
    isInviteListEmpty,
  } = invite;

  const [userGroupName, setUserGroupName] = useState("");
  const [project, setProject] = useState<NewProject>({
    name: "",
    description: "",
    cloudProvider: DEFAULT_PROJECT_CLOUD_PROVIDER,
    region: DEFAULT_PROJECT_REGION,
  });

  const projectId = useRef<string | null>(null);
  const userGroupId = useRef<string | null>(null);

  useEffect(() => {
    projectId.current = projectId.current || defaultProject.data?.id || null;

    userGroupId.current =
      userGroupId.current || defaultUserGroup.data?.id || null;
  }, [defaultUserGroup.data, defaultProject.data]);

  useEffect(() => {
    if (!defaultUserGroup.isLoading) {
      setUserGroupName(defaultUserGroup.data?.name || "");
    }
  }, [defaultUserGroup.isLoading, defaultUserGroup.data?.name]);

  useEffect(() => {
    if (!defaultProject.isLoading) {
      setProject({
        name: defaultProject.data?.name || "",
        description: defaultProject.data?.description || "",
        cloudProvider:
          (defaultProject.data?.cloud_provider as CloudProvider) ||
          DEFAULT_PROJECT_CLOUD_PROVIDER,
        region: defaultProject.data?.region || DEFAULT_PROJECT_REGION,
      });
    }
  }, [
    defaultProject.isLoading,
    defaultProject.data?.name,
    defaultProject.data?.description,
    defaultProject.data?.cloud_provider,
    defaultProject.data?.region,
  ]);

  const [activeStep, setActiveStep] = useState<Steps>(steps[0]);

  const isFirstStep = useMemo(() => activeStep === steps[0], [activeStep]);

  const activeStepIndex = useMemo(
    () => steps.indexOf(activeStep),
    [activeStep],
  );

  const isLastStep = useMemo(
    () => activeStep === steps[steps.length - 1],
    [activeStep],
  );

  const [isLoadingResult, setIsLoadingResult] = useState(false);

  const onResult = useCallback<UseSendAllRequestsOptions["onResult"]>(
    (result, success) => {
      setIsLoadingResult(false);
      onComplete(result, success);
    },
    [onComplete],
  );

  const resetInvites = useCallback(() => {
    /**
     * NOTE:
     *
     * If the invitations step has been already visited,
     * but the email has not been filled in, then
     * the email validation error is rendered (it popups on blur).
     * That's why we remove this error.
     */
    if (activeStep === Steps.InviteTeam && isInviteListEmpty) {
      resetInvitations();
    }
  }, [activeStep, isInviteListEmpty, resetInvitations]);

  const sendAllRequests = useSendAllRequests({
    userGroupName,
    project,
    sendInvitations,
    onResult,
  });

  const onStepChange = useCallback(
    (step: Steps, offset?: number) => {
      resetInvites();

      let newStep = step;

      if (offset) {
        const index = steps.indexOf(step);

        if (index === -1) {
          LoggerService.warn("Cannot find step.", step, offset);

          return;
        }

        newStep = steps[index + offset];
      }

      setActiveStep(newStep);
    },
    [resetInvites],
  );

  const onNext = useCallback(
    (skipSteps?: boolean) => {
      if (skipSteps) {
        onStepChange(activeStep, steps.length - 1 - activeStepIndex);
      }

      if (
        !skipSteps &&
        activeStep === Steps.InviteTeam &&
        !(isInviteListEmpty || validateInvitations())
      ) {
        return;
      }

      if (isLastStep || skipSteps) {
        if (isLoadingResult) {
          return;
        }

        setIsLoadingResult(true);
        sendAllRequests();

        return;
      }

      onStepChange(activeStep, 1);
    },
    [
      activeStep,
      activeStepIndex,
      isInviteListEmpty,
      isLastStep,
      isLoadingResult,
      onStepChange,
      sendAllRequests,
      validateInvitations,
    ],
  );

  const onBack = useCallback(() => {
    onStepChange(activeStep, -1);
  }, [activeStep, onStepChange]);

  useEffect(() => {
    if (!defaultUserGroup.isLoading) {
      setUserGroupName(defaultUserGroup.data?.name || "");
    }
  }, [
    defaultUserGroup.isLoading,
    defaultUserGroup.data?.name,
    setUserGroupName,
  ]);

  useEffect(() => {
    if (!defaultProject.isLoading) {
      setProject({
        name: defaultProject.data?.name || "",
        description: defaultProject.data?.description || "",
        cloudProvider:
          (defaultProject.data?.cloud_provider as CloudProvider) ||
          DEFAULT_PROJECT_CLOUD_PROVIDER,
        region: defaultProject.data?.region || DEFAULT_PROJECT_REGION,
      });
    }
  }, [
    defaultProject.isLoading,
    defaultProject.data?.name,
    defaultProject.data?.description,
    setProject,
    defaultProject.data?.cloud_provider,
    defaultProject.data?.region,
  ]);

  return {
    onNext,
    onStepChange,
    onBack,
    steps,
    isLoadingResult,
    activeStep,
    activeStepIndex,
    isFirstStep,
    isLastStep,
    isInviteListValid,
    userGroupName,
    setUserGroupName,
    project,
    setProject,
    projectId: projectId.current,
    userGroupId: userGroupId.current,
  };
}
