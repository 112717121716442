import { CodeBlockWithCopyButton, CodeSnippet } from "#organization/components";

export const language = "java";

[Aperture Java SDK core library][SDK-Library] can be used to manually set
feature control points within a Java service.

To do so, first create an instance of ApertureSDK:

<CodeSnippet lang={language} snippetName="StandaloneExampleSDKInit" />

The created instance can then be used to start a flow:

<CodeSnippet lang={language} snippetName="StandaloneExampleFlow" />

For more context on using Aperture Java SDK to set feature control points, refer
to the [example app][example] available in the repository.

[example]:
  https://github.com/fluxninja/aperture-java/blob/releases/aperture-java/v2.1.0/examples/standalone-example/src/main/java/com/fluxninja/example/App.java
[api-keys]: /reference/cloud-ui/api-keys.md
[SDK-Library]:
  https://search.maven.org/artifact/com.fluxninja.aperture/aperture-java-core
