import { CircularProgress, Typography } from "@mui/material";
import React, { useMemo, type FC } from "react";

import { CodeBlockWithCopyButton } from "#organization/components";
import {
  SDKList,
  useApiKey,
  useGetAgentEndpoint,
  useGetProjectRegion,
  useSDKSnippets,
} from "#organization/pages/authenticated/aperture-system/tabs/details/hooks";

type CodeSnippetProps = {
  lang: (typeof SDKList)[number]["key"];
  snippetName: string;
};

export const CodeSnippet: FC<CodeSnippetProps> = ({ lang, snippetName }) => {
  const {
    data: snippets,
    isLoading: isLoadingSnippets,
    isError: isErrorSnippets,
  } = useSDKSnippets();

  const sdk = useMemo(() => SDKList.find((s) => s.key === lang), [lang]);

  const language = sdk?.language.toLowerCase() || "";

  const { apiKey = "API_KEY" } = useApiKey();
  const { region = "REGION" } = useGetProjectRegion();

  const endpoint = useGetAgentEndpoint(region);

  const code = useMemo(() => {
    let codeSnippet = snippets?.[lang]?.[snippetName];
    const replacements = sdk?.replacements;

    if (!replacements) {
      return codeSnippet;
    }

    replacements.forEach((replacement) => {
      if (!codeSnippet) {
        return;
      }

      let replaceKV: [string, string] | undefined;

      if (replacement.key === "API_KEY" && apiKey) {
        replaceKV = ["{{API_KEY}}", apiKey];
      } else if (replacement.key === "ENDPOINT" && endpoint) {
        replaceKV = ["{{ENDPOINT}}", endpoint];
      }

      if (replaceKV) {
        codeSnippet = codeSnippet.replace(
          replacement.find,
          replacement.replace.replace(...replaceKV),
        );
      }
    });

    return codeSnippet;
  }, [apiKey, endpoint, lang, sdk?.replacements, snippetName, snippets]);

  if (isLoadingSnippets) {
    return <CircularProgress size={16} />;
  }

  if (isErrorSnippets) {
    return <Typography color="error">Failed to load code snippet</Typography>;
  }

  if (!code) {
    return <Typography color="error">Code snippet not found</Typography>;
  }

  return <CodeBlockWithCopyButton language={language} code={code} />;
};
