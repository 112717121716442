import { gql } from "graphql-request";
import { uniq } from "lodash";

import type { ControlPointAssignmentsQuery } from "#shared/generated/graphql";
import type { JSONObject } from "#shared/types";

import {
  useGridData,
  type DataGridHook,
  type GroupsTitlesHook,
  useGroupsTitles,
  type GroupDataHook,
  useGroupData,
} from "#organization/hooks";

import type { FlowControlComponentsGroupBy } from "./flow-control-components";

import {
  componentsToTableFormat,
  classifiersToTableFormat,
  fluxMetersToTableFormat,
} from "../../reduce-components";

const ASSIGNMENT_QUERY_KEY = "controlPoints";

export const controlPointAssignmentsGql = gql`
  query ControlPointAssignments(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: ControlPointBoolExp
    $distinctOn: [ControlPointSelectColumn!]
    $orderBy: [ControlPointOrderBy]
  ) {
    controlPoints(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      distinctOn: $distinctOn
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          controlPointComponentAssignments(
            where: { component: { policy: { status: { neq: "3-archived" } } } }
          ) {
            edges {
              node {
                component {
                  type
                  subtype
                  id
                  policy {
                    name
                    id
                  }
                }
              }
            }
          }
          controlPointFluxMeterAssignments(
            where: { fluxMeter: { policy: { status: { neq: "3-archived" } } } }
          ) {
            edges {
              node {
                fluxMeter {
                  name
                  id
                  policy {
                    name
                    id
                  }
                }
              }
            }
          }
          controlPointClassifierAssignments(
            where: { classifier: { policy: { status: { neq: "3-archived" } } } }
          ) {
            edges {
              node {
                classifier {
                  id
                  labelSelector
                  flowLabel
                  policy {
                    name
                    id
                  }
                }
              }
            }
          }
          name
          lastSeen
          status
          id
        }
      }
    }
  }
`;

export const commonFiltersControlPointAssignments = (
  controlPointID: string,
  additionalFilter: JSONObject = {},
) => ({
  ...additionalFilter,
  id: {
    eq: controlPointID,
  },
});

const orderByTemplate = (orderBy: string, order: string) => {
  switch (orderBy) {
    case "policyName":
      return {
        project: {
          componentsAggregate: {
            count: order,
          },
          fluxMetersAggregate: {
            count: order,
          },
          classifiersAggregate: {
            count: order,
          },
        },
      };
    default:
      return {};
  }
};

export const useControlPointAssignmentsGridData = (controlPointID: string) => {
  const useDataGridHook: DataGridHook = (
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    enabled,
    filterVariables,
  ) =>
    useGridData<ControlPointAssignments, ControlPointAssignmentsTableData[]>(
      ASSIGNMENT_QUERY_KEY,
      controlPointAssignmentsGql,
      pageSize,
      page,
      setPage,
      order,
      orderBy,
      {
        ...filterVariables,
        id: {
          eq: controlPointID,
        },
      },
      modifyControlPointAssignmentsData,
      enabled,
      false,
      orderByTemplate,
    );

  const useGroupsTitlesHook: GroupsTitlesHook<FlowControlComponentsGroupBy> = (
    pageSize,
    page,
    setPage,
    groupByKey,
    filterVariables,
  ) =>
    useGroupsTitles(
      ASSIGNMENT_QUERY_KEY,
      pageSize,
      page,
      setPage,
      groupByKey,
      controlPointAssignmentsGql,
      (node: ControlPointAssignments) => {
        const data = modifyControlPointAssignmentsData(node);

        const titleStrings = data.map((d) => d[groupByKey!]);

        return uniq(titleStrings);
      },
      {
        ...commonFiltersControlPointAssignments(controlPointID),
        ...filterVariables,
      },
      false,
      false,
    );

  const useGroupDataHook: GroupDataHook<FlowControlComponentsGroupBy> = (
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    groupByKey,
    groupByValue,
    filterVariables,
  ) =>
    useGroupData<
      FlowControlComponentsGroupBy,
      ControlPointAssignments,
      ControlPointAssignmentsTableData[]
    >(
      ASSIGNMENT_QUERY_KEY,
      controlPointAssignmentsGql,
      pageSize,
      page,
      setPage,
      order,
      orderBy,
      groupByKey,
      groupByValue,
      modifyControlPointAssignmentsData,
      {
        ...commonFiltersControlPointAssignments(controlPointID),
        ...filterVariables,
      },
      groupByVariables(groupByValue),
      true,
      false,
      orderByTemplate,
    );

  return {
    useDataGridHook,
    useGroupsTitles: useGroupsTitlesHook,
    useGroupData: useGroupDataHook,
  };
};

export const groupByVariables = (groupByValue: string) => ({
  policyName: {
    project: {
      components: {
        policy: { name: { eq: groupByValue } },
      },
      classifiers: {
        policy: { name: { eq: groupByValue } },
      },
      fluxMeters: {
        policy: { name: { eq: groupByValue } },
      },
    },
  },
});

type ControlPointAssignments =
  ControlPointAssignmentsQuery["controlPoints"]["edges"][number]["node"];

export function modifyControlPointAssignmentsData(cp: ControlPointAssignments) {
  const {
    controlPointFluxMeterAssignments: fluxMeters,
    controlPointClassifierAssignments: classifiers,
    controlPointComponentAssignments: components,
  } = cp;

  return createUnifiedListOfAllComponents(
    components.edges.map(({ node }) => node),
    classifiers.edges.map(({ node }) => node),
    fluxMeters.edges.map(({ node }) => node),
  );
}

export declare type ControlPointAssignmentsTableData = {
  policyName: string;
  policyID: string;
  componentType: string;
  componentName: string;
};

export type ComponentsForControlPoint =
  ControlPointAssignments["controlPointComponentAssignments"]["edges"][number]["node"];
export type ClassifiersForControlPoint =
  ControlPointAssignments["controlPointClassifierAssignments"]["edges"][number]["node"];
export type FluxMetersForControlPoint =
  ControlPointAssignments["controlPointFluxMeterAssignments"]["edges"][number]["node"];

export function createUnifiedListOfAllComponents(
  components: ComponentsForControlPoint[],
  classifiers: ClassifiersForControlPoint[],
  fluxMeters: FluxMetersForControlPoint[],
): ControlPointAssignmentsTableData[] {
  const componentsData = components.map(componentsToTableFormat());

  const classifiersData = classifiers.map(classifiersToTableFormat());

  const fluxMetersData = fluxMeters.map(fluxMetersToTableFormat());

  const data = [
    ...componentsData,
    ...classifiersData,
    ...fluxMetersData,
  ].filter((d) => d !== null);

  return data as ControlPointAssignmentsTableData[];
}
