import { gql } from "graphql-request";

import type {
  ResultCacheGroupsTitlesQuery,
  ResultCacheQuery,
} from "#shared/generated/graphql";

import {
  useGridData,
  useGroupData,
  useGroupsTitles,
  type DataGridHook,
  type GroupDataHook,
  type GroupsTitlesHook,
} from "#organization/hooks";

export type ResultCacheGroupBy = "name";

const RESULT_CACHE_ENTITY_KEY = "controlPoints";

export const resultCacheGroupsTitles = gql`
  query ResultCacheGroupsTitles(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: ControlPointBoolExp
    $distinctOn: [ControlPointSelectColumn!]
  ) {
    controlPoints(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      distinctOn: $distinctOn
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          service {
            id
            agentGroup
            status
            controller {
              name
            }
          }
          name
          lastSeen
          status
          type
        }
      }
    }
  }
`;

type ResultCacheGroupsTitleData =
  ResultCacheGroupsTitlesQuery["controlPoints"]["edges"][0]["node"];

export const useResultCacheGroupsTitles: GroupsTitlesHook<
  ResultCacheGroupBy
> = (pageSize, page, setPage, groupByKey, filterVariables) =>
  useGroupsTitles<ResultCacheGroupBy, ResultCacheGroupsTitleData>(
    RESULT_CACHE_ENTITY_KEY,
    pageSize,
    page,
    setPage,
    groupByKey,
    resultCacheGroupsTitles,
    (node) => node[groupByKey!],
    {
      /* SDK control points only */ type: { eq: "feature" },
      ...filterVariables,
    },
  );

type ResultCacheData = ResultCacheQuery["controlPoints"]["edges"][0]["node"];

export type AdaptedResultCacheData = ResultCacheData;

export const resultCacheQuery = gql`
  query ResultCache(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: ControlPointBoolExp
    $distinctOn: [ControlPointSelectColumn!]
    $orderBy: [ControlPointOrderBy]
  ) {
    controlPoints(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      distinctOn: $distinctOn
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          service {
            id
            name
            agentGroup
            controller {
              name
              id
            }
          }
          controlPointComponentAssignments(
            where: { component: { policy: { status: { neq: "3-archived" } } } }
          ) {
            edges {
              node {
                component {
                  policy {
                    id
                  }
                }
              }
            }
          }
          name
          status
          type
          lastSeen
        }
      }
    }
  }
`;

export const useResultCacheGroupData: GroupDataHook<ResultCacheGroupBy> = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  groupByKey,
  groupByValue,
  filterVariables,
) =>
  useGroupData<ResultCacheGroupBy, ResultCacheData, AdaptedResultCacheData>(
    RESULT_CACHE_ENTITY_KEY,
    resultCacheQuery,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    groupByKey,
    groupByValue,
    (controlPoints: ResultCacheData) => ({
      ...controlPoints,
    }),
    {
      /* SDK control points only */
      type: { eq: "feature" },
      ...filterVariables,
    },
  );

export const useResultCacheData: DataGridHook = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  enabled,
  filterVariables,
) =>
  useGridData<ResultCacheData, AdaptedResultCacheData>(
    RESULT_CACHE_ENTITY_KEY,
    resultCacheQuery,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    {
      /* SDK control points only */
      type: { eq: "feature" },
      ...filterVariables,
    },
    (controlPoints: ResultCacheData) => ({
      ...controlPoints,
    }),
    enabled,
  );
