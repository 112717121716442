import { Add, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  OutlinedInput,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { type FC, useEffect, useState, useMemo } from "react";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { ErrorText, GlobalFrame } from "#shared/components/layouts";
import { TextLink } from "#shared/components/text-link";
import { FLUXNINJA } from "#shared/consts";
import { useLogout } from "#shared/hooks";
import { userNewState } from "#shared/recoil";
import type { Texts } from "#shared/types";

import { QUERY_KEYS } from "#global/consts";

import { ClickableCard } from "./components/card";
import { CREATE_ORGANIZATION_ROUTE } from "./create/routes.definitions";
import { useMemberships } from "./use-memberships";

export interface OrganizationsPageProps {
  texts?: Texts<Text>;
}

type Text =
  | "wantDifferentOrg"
  | "welcome"
  | "welcomeBack"
  | "orgFor"
  | "noOrg"
  | "createNew"
  | "logout";

const enTexts: Required<OrganizationsPageProps["texts"]> = {
  wantDifferentOrg: [
    "Want to use",
    FLUXNINJA,
    "within a totally different organization?",
  ].join(" "),
  welcome: "Welcome!",
  welcomeBack: "Welcome back!",
  orgFor: "Organizations for {{user}}",
  noOrg: "You do not have any organizations created",
  createNew: "Create a new organization",
  logout: "Logout",
};

const CARD_HEIGHT = 72;

export const OrganizationsPage: FC<OrganizationsPageProps> = ({
  texts = enTexts,
}) => {
  const [organizationFilter, setOrganizationFilter] = useState("");
  const logout = useLogout(false);
  const {
    httpState: { isLoading, isIdle, error },
    request,
    result: memberships,
  } = useMemberships();

  const firebaseEmail = useRecoilValue(userNewState.email);

  useEffect(() => {
    request();
  }, [request]);

  const queryClient = useQueryClient();

  useEffect(() => () => {
    queryClient.invalidateQueries([QUERY_KEYS.CHECK_PROVISIONING_STATUS]);
  });

  const filteredMemberships = useMemo(
    () =>
      memberships?.filter((membership) =>
        membership.Organization.name
          .toLowerCase()
          .includes(organizationFilter.toLowerCase()),
      ) || [],
    [memberships, organizationFilter],
  );

  const isLoader = !error && (isLoading || isIdle);
  const noOrganizations = !isLoader && memberships?.length === 0;

  return (
    <GlobalFrame hideLogout>
      <Box display="grid" rowGap={1} justifyItems="center" pb={4} pt={4}>
        <Typography variant="h4" textAlign="center">
          {noOrganizations ? texts.welcome : texts.welcomeBack}
        </Typography>
        <Typography width="60%" variant="body1" textAlign="center">
          {noOrganizations
            ? texts.noOrg
            : texts.orgFor.replace("{{user}}", firebaseEmail || "your user")}
        </Typography>
      </Box>

      {!noOrganizations &&
        !error &&
        !isLoader &&
        memberships &&
        memberships.length > 4 && (
          <Box mx={1}>
            <OutlinedInput
              startAdornment={<Search sx={{ fill: "rgba(0, 0, 0, 0.54)" }} />}
              placeholder="Search"
              fullWidth
              value={organizationFilter}
              onChange={(e) => setOrganizationFilter(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
          </Box>
        )}

      <Box
        width="100%"
        sx={{
          overflowY: "auto",
          maxHeight: `calc(4 * ${CARD_HEIGHT}px + 32px)`,
        }}
      >
        {error ? <ErrorText {...{ error }} /> : null}
        {isLoader ? (
          <Box mx={1} display="flex" flexDirection="column" gap={1}>
            <Skeleton variant="rectangular" width="100%" height={CARD_HEIGHT} />
            <Skeleton variant="rectangular" width="100%" height={CARD_HEIGHT} />
            <Skeleton variant="rectangular" width="100%" height={CARD_HEIGHT} />
            <Skeleton variant="rectangular" width="100%" height={CARD_HEIGHT} />
          </Box>
        ) : (
          <>
            {noOrganizations && (
              <Link to={CREATE_ORGANIZATION_ROUTE.ABSOLUTE_PATH}>
                <Button
                  variant="contained"
                  startIcon={<Add />}
                  sx={{ height: "60px", maxHeight: "0", width: "280px" }}
                >
                  {texts.createNew}
                </Button>
              </Link>
            )}
            {!noOrganizations &&
              filteredMemberships.map((membership) => (
                <ClickableCard
                  organization={membership.Organization}
                  membership={membership}
                  key={membership.Organization.domain_name}
                />
              ))}
          </>
        )}
      </Box>

      {!noOrganizations && (
        <Box pb={1} pt={4} display="flex" flexDirection="column">
          <Typography variant="body1" textAlign="center">
            {texts.wantDifferentOrg}{" "}
          </Typography>
          <TextLink
            to={CREATE_ORGANIZATION_ROUTE.ABSOLUTE_PATH}
            sx={{ textAlign: "center" }}
          >
            {texts.createNew}
          </TextLink>
        </Box>
      )}

      <Box py={3}>
        <Button variant="text" onClick={logout}>
          {texts.logout}
        </Button>
      </Box>
    </GlobalFrame>
  );
};
