import { Typography, type TypographyProps } from "@mui/material";
import React, { type FC, isValidElement } from "react";

import type { Texts } from "#shared/types";

import { convertToString } from "./convert-to-string";

import { sharedEnTexts } from "../../../consts";

/* eslint-disable-next-line  */
export type ErrorTextProps<E extends any = unknown> = Omit<
  TypographyProps,
  "variant" | "color"
> & {
  error?: E;
  texts?: Texts<ErrorTextText>;
};

export type ErrorTextText = "defaultError";

const enErrorTextTexts: Required<ErrorTextProps["texts"]> = {
  defaultError: sharedEnTexts.error,
};

export const ErrorText: FC<ErrorTextProps> = ({
  children,
  error,
  texts = enErrorTextTexts,
  ...props
}) => (
  <Typography {...props} variant="body1" color="error">
    {(isValidElement(error) ? error : convertToString(error)) ||
      (!children ? texts.defaultError : null)}
    {isValidElement(children) ? children : convertToString(children)}
  </Typography>
);
