import { Button, Typography } from "@mui/material";
import { Box, hexToRgb, alpha } from "@mui/system";
import React, { type FC, useMemo, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import {
  ErrorLayout,
  GlobalFrame,
  LoaderLayout,
  NoDataLayout,
} from "#shared/components/layouts";
import {
  AlternativeSignInHorizontalBar,
  type SignInMainFrameProps,
} from "#shared/components/layouts/sign-in";
import { SignInWithLinkForm } from "#shared/components/sign-in";
import { SIGN_IN_ROUTE } from "#shared/consts";
import { FirebaseContext } from "#shared/contexts/firebase";
import { ENV } from "#shared/env";
import { type Texts } from "#shared/types";
import { getOrgDomain } from "#shared/utils";

import { PLACEHOLDER } from "#organization/consts";
import { organizationState } from "#organization/recoil/organization";

import { CompleteSignInFirebase } from "../sign-in/complete-sign-in-firebase";
import { EmailPasswordSignInForm } from "../sign-in/components/password";

export interface OwnerSignInPageProps {
  texts?: Texts<Text>;
}

type Text = "title" | "subtitle" | "goBack";

const enTexts: Required<OwnerSignInPageProps["texts"]> = {
  title: "Sign in to",
  subtitle: "",
  goBack: "Go back to sign in",
};

const MAX_WIDTH = "100%";
const WIDTH = "500px";

/**
 *
 * NOTE:
 *
 * Only owner of organization can sign in with password or link sent in email
 */
export const OwnerSignInPage: FC<OwnerSignInPageProps> = ({
  texts = enTexts,
}) => {
  const organization = useRecoilValue(organizationState);

  const navigate = useNavigate();

  const {
    tenantConfig,
    tenantConfig: {
      tenantSignInProps: { isSsoConfigured },
      httpState: { isSuccess },
    },
    httpState,
    isSignInWithEmailLink,
  } = useContext(FirebaseContext);

  useEffect(() => {
    // Refetch tenant config on mount
    tenantConfig.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSuccess && !isSsoConfigured) {
      navigate(SIGN_IN_ROUTE.ABSOLUTE_PATH);
    }
  }, [isSsoConfigured, isSuccess, navigate]);

  const outerContainerProps: SignInMainFrameProps = {
    headerProps: {
      children: [texts.title, organization?.subdomain]
        .filter(Boolean)
        .join(" in "),
      outerContainerProps: {
        sx: { maxWidth: MAX_WIDTH, width: WIDTH },
      },
    },
    subheaderProps: {
      children: texts.subtitle,
    },
  };

  /**
   * NOTE:
   *
   * Only Ann Place in the dev environment can sign in with password
   */
  const isPlaceholder = useMemo(
    () =>
      organization?.subdomain === PLACEHOLDER &&
      (ENV.IS_DEV.toString() === "true" || ENV.DEV.toString() === "true"),
    [organization?.subdomain],
  );

  /**
   * NOTE:
   *
   * Only placeholder in dev environment can sign in with password
   */
  const isWithPassword = useMemo<boolean>(
    () => !!(isPlaceholder && tenantConfig.result?.allowPasswordSignup),
    [isPlaceholder, tenantConfig.result?.allowPasswordSignup],
  );

  const isWithLink = useMemo<boolean>(
    () => !!tenantConfig.result?.enableEmailLinkSignin,
    [tenantConfig.result?.enableEmailLinkSignin],
  );

  const isLoadingConfig = useMemo(
    () =>
      tenantConfig.httpState.isIdle ||
      tenantConfig.httpState.isLoading ||
      httpState.isLoading,
    [
      tenantConfig.httpState.isIdle,
      tenantConfig.httpState.isLoading,
      httpState.isLoading,
    ],
  );

  const isLoading = false;

  const isData = useMemo(
    () =>
      (isWithLink || isWithPassword) &&
      tenantConfig.httpState.isSuccess &&
      httpState.isSuccess,
    [
      isWithLink,
      isWithPassword,
      tenantConfig.httpState.isSuccess,
      httpState.isSuccess,
    ],
  );

  if (isSignInWithEmailLink) {
    return <CompleteSignInFirebase />;
  }

  return (
    <GlobalFrame hideLogout {...outerContainerProps}>
      <Typography mt={4} mb={4} variant="h4" textAlign="center">
        {texts.title}
      </Typography>
      <Typography
        variant="h5"
        textAlign="center"
        color="primary.main"
        mb={4}
        sx={{
          background: (theme) =>
            alpha(hexToRgb(theme.palette.primary.main), 0.08),
          p: 1,
          borderRadius: 2,
          wordBreak: "break-word",
        }}
      >
        {getOrgDomain(organization?.subdomain)}
      </Typography>

      {httpState.error ? <ErrorLayout /> : null}

      {isLoadingConfig && !httpState.error ? <LoaderLayout /> : null}

      {isData || isLoadingConfig ? null : <NoDataLayout />}

      {isWithLink && !isLoadingConfig && !httpState.error ? (
        <SignInWithLinkForm />
      ) : null}

      {isWithPassword && !isLoadingConfig && !httpState.error ? (
        <>
          <AlternativeSignInHorizontalBar />
          <EmailPasswordSignInForm />
        </>
      ) : null}

      <Box width="100%" textAlign="left" pt={2} mb={4}>
        <Typography variant="body1" component="span">
          <Button
            variant="text"
            disabled={isLoading}
            size="large"
            sx={{
              marginLeft: 0.5,
              padding: 0,
              letterSpacing: "inherit",
              "&:hover": { background: "initial" },
              textDecoration: "underline",
            }}
            onClick={() => navigate(SIGN_IN_ROUTE.ABSOLUTE_PATH)}
          >
            {texts.goBack}
          </Button>
        </Typography>
      </Box>
    </GlobalFrame>
  );
};
