import { Search } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  InputAdornment,
  type SvgIconProps,
  type InputAdornmentProps,
  type Theme,
} from "@mui/material";
import React, { type FC, type ComponentType } from "react";

import type { FnTheme } from "../../../../../../../../../../app-theme-provider/types";

export interface AdornmentProps extends SvgIconProps {
  inputAdornmentProps?: InputAdornmentProps;
  Icon?: ComponentType<SvgIconProps>;
}

export const EndAdornment: FC<AdornmentProps> = ({
  inputAdornmentProps,
  Icon = CloseIcon,
  ...iconProps
}) => (
  <InputAdornment position="end" {...inputAdornmentProps}>
    <Icon
      {...iconProps}
      sx={{
        color: iconColor(),
        cursor: "pointer",
        ...iconProps.sx,
      }}
    />
  </InputAdornment>
);

export const StartAdornment: FC<AdornmentProps> = ({
  inputAdornmentProps,
  Icon = Search,
  ...iconProps
}) => (
  <InputAdornment position="start" {...inputAdornmentProps}>
    <Icon
      {...iconProps}
      sx={{
        color: iconColor(),
        ...iconProps.sx,
      }}
    />
  </InputAdornment>
);

function iconColor() {
  return (theme: Theme) =>
    (theme.palette as unknown as Required<FnTheme["palette"]>).text.primary;
}
