import { ThemeProvider } from "@mui/material/styles";
import { Box, styled } from "@mui/system";
import React, { type FC, type PropsWithChildren } from "react";

import { LayoutComponent } from "#shared/components/layouts/background";

import { HeaderSection } from "#organization/components/header-section";
import { TOP_BAR_ID } from "#organization/consts";
import { useIsSidebarOpen } from "#organization/recoil/sidebar";
import { useThemeState } from "#organization/recoil/theme";

import { Drawer, type DrawerProps } from "./drawer";

import { navigationTheme } from "../../../../../app-theme-provider";
import { TopBar } from "../top-bar/top-bar";

export type SidebarLayoutProps = DrawerProps & PropsWithChildren;

const CustomContainer = styled(Box)(({ theme }) => ({
  position: "sticky",
  top: 65,
  left: 0,
  height: `calc(100vh - ${theme.spacing(8)})`,
}));

export const SidebarLayout: FC<SidebarLayoutProps> = ({
  children,
  sidebar,
}) => {
  const theme = useThemeState();
  const isSidebarOpen = useIsSidebarOpen();

  return (
    <LayoutComponent>
      <ThemeProvider theme={navigationTheme(theme.mode)}>
        <Box sx={{ gridArea: "top-bar" }} id={TOP_BAR_ID}>
          <TopBar />
        </Box>
        {isSidebarOpen && (
          <Box sx={{ gridArea: "side-bar", position: "relative" }}>
            <CustomContainer>
              <Drawer sidebar={sidebar} />
            </CustomContainer>
          </Box>
        )}
      </ThemeProvider>
      <Box sx={{ gridArea: "child" }}>
        <HeaderSection />
        {children}
      </Box>
    </LayoutComponent>
  );
};
