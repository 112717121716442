import type { RJSFSchema } from "@rjsf/utils/lib/types";

import { fetchSchema } from "./fetch-schema";
import type { FetchBlueprintSchemaPayload } from "./types";

import {
  addAgentGroupsEnum,
  addControlPointsEnum,
  aggregateDefs,
  disallowPolicyNames,
  getDefName,
  mergeDefs,
  optimizeRefs,
  removeCircularNotReference,
  removeDashboardProp,
  replaceRefs,
  updateBlueprintNameProp,
} from "../../utils";

export async function fetchBlueprintSchema(p: FetchBlueprintSchemaPayload) {
  const { absoluteBlueprintPath, schema } = await fetchSchema({
    version: p.version,
    blueprintPath: p.blueprintPath,
  });
  const absoluteBlueprintValuesPath = `${absoluteBlueprintPath.slice(
    0,
    absoluteBlueprintPath.lastIndexOf("/"),
  )}/values.yaml`;
  let blueprintValues: string | undefined;

  if (!p.isCommonBlueprint) {
    try {
      const responseValues = await fetch(absoluteBlueprintValuesPath);
      blueprintValues = await responseValues.text();
    } catch (e) {
      p.log.warn(
        `Unable to fetch blueprint values from ${absoluteBlueprintValuesPath}`,
      );
    }
  }

  const mutateSchemaFuncs = [removeDashboardProp, updateBlueprintNameProp];
  const mutatedSchema = mutateSchemaFuncs.reduce<RJSFSchema>(
    (acc, mutateSchemaFunc) => mutateSchemaFunc(acc),
    schema,
  );

  const [blueprintSchemaWithoutRefs, newRefs] = replaceRefs(
    mutatedSchema,
    absoluteBlueprintPath,
  );

  const blueprintSchema = p.invalidPolicyNames
    ? disallowPolicyNames(blueprintSchemaWithoutRefs, p.invalidPolicyNames)
    : blueprintSchemaWithoutRefs;

  const fileDefList = optimizeRefs(newRefs);

  const defSchemaList = await Promise.all<RJSFSchema>(
    Object.entries(fileDefList).map(async ([file, defList]) => {
      const refResponseFetch = await fetch(file);
      const refSchema = await refResponseFetch.json();

      const [, /* newRefSchema */ externalRefs, localRefs] = replaceRefs(
        refSchema,
        file,
      );

      if (externalRefs.length > 0) {
        // We should handle refs recursively rather than just ignoring them which would cause
        // problems later on when there are recursive external refs in blueprints
        p.log.warn("The following refs weren't handled: ", externalRefs);
      }

      const aggregatedDefs = aggregateDefs(refSchema, [
        ...defList,
        ...localRefs.map(getDefName),
      ]);

      return p.isJsonSchemaForm
        ? removeCircularNotReference(aggregatedDefs)
        : aggregatedDefs;
    }),
  );

  const externalRefs = mergeDefs(defSchemaList);

  const mutateExternalRefFuncs = [addAgentGroupsEnum, addControlPointsEnum];
  const mutatedExternalRefs = await mutateExternalRefFuncs.reduce(
    async (acc, mutateExternalRefFunc) => {
      const previousResult = await acc;
      const currentResult = await mutateExternalRefFunc(previousResult);

      return currentResult;
    },
    Promise.resolve(externalRefs),
  );

  const extraProperties =
    blueprintSchema.definitions && p.isCommonBlueprint
      ? {
          policy: {
            type: "object",
            additionalProperties: false,
            properties: Object.keys(blueprintSchema.definitions).reduce(
              (acc, key) => ({
                ...acc,
                [key]: {
                  $ref: `#/definitions/${key}`,
                },
              }),
              {},
            ),
          },
        }
      : {};

  return {
    blueprint: {
      schema: {
        ...blueprintSchema,
        properties: {
          ...blueprintSchema.properties,
          ...extraProperties,
        },
        definitions: {
          ...blueprintSchema.definitions,
          ...mutatedExternalRefs,
        },
      },
      path: absoluteBlueprintPath,
    },
    values: blueprintValues,
  };
}
