import { gql } from "graphql-request";

import type { UserUserGroupByIdQuery } from "#shared/generated/graphql";

import { useGridData, type DataGridHook } from "#organization/hooks";

import type { UserGroupData } from "./components/table";

const UserUserGroupById = gql`
  query userUserGroupById($where: UserGroupBoolExp) {
    userGroups(where: $where) {
      edges {
        node {
          id
          name
          userGroupMemberships {
            totalCount
          }
        }
      }
    }
  }
`;

type UserGroupMembersGroupData =
  UserUserGroupByIdQuery["userGroups"]["edges"][0]["node"];

export const MEMBERS_USER_GROUP_QUERY_KEY = "userGroups";

export const useMembersUserGroupData: DataGridHook = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  enabled,
  filterVariables,
) =>
  useGridData<UserGroupMembersGroupData, UserGroupData>(
    MEMBERS_USER_GROUP_QUERY_KEY,
    UserUserGroupById,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    filterVariables,
    (userGroups) => ({
      id: userGroups.id,
      membersCount: userGroups.userGroupMemberships.totalCount,
      name: userGroups.name,
      action: userGroups.id,
    }),
    enabled,
    false,
  );
