import React, { type FC } from "react";
import { Route, Routes } from "react-router-dom";

import { BreadcrumbLink } from "#shared/components/breadcrumbs";

import { TopBarBreadcrumbAppender } from "#organization/recoil/breadcrumbs";

import { MemberPage } from "./member";
import { MembersPage } from "./page";
import { MEMBERS_SETTINGS_ROUTE } from "./routes.definitions";

export const MembersSettingsRoutes: FC = () => (
  <>
    <TopBarBreadcrumbAppender>
      <BreadcrumbLink to={MEMBERS_SETTINGS_ROUTE.ABSOLUTE_PATH}>
        {MEMBERS_SETTINGS_ROUTE.TITLE}
      </BreadcrumbLink>
    </TopBarBreadcrumbAppender>

    <Routes>
      <Route
        path={MEMBERS_SETTINGS_ROUTE.CHILD_PATH}
        element={<MemberPage />}
      />
      <Route index element={<MembersPage />} />
    </Routes>
  </>
);
