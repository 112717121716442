import { useMutation } from "react-query";

import { API_SERVICE_URL } from "#shared/consts";
import { ENV } from "#shared/env";
import { httpClient } from "#shared/utils/http-client";

const ZOHO_CRM_URL = (app: string | undefined = ENV.VITE_APP) =>
  app === "global" ? [API_SERVICE_URL, "zoho", "lead"].join("/") : null;

export declare type ZohoLeadPayload = {
  email: string;
  lastName: string;
  department?: string;
  description?: string;
  firstName?: string;
};

export const useZohoCRM = () => {
  const leadUrl = ZOHO_CRM_URL();

  return useMutation<unknown, unknown, ZohoLeadPayload>({
    mutationKey: "zoho-crm",
    mutationFn: async (data) => {
      const response = await httpClient.post({
        url: leadUrl || "",
        body: data,
      });

      return response.json();
    },
  });
};
