import type { BoxProps } from "@mui/material";
import { type PropsWithChildren, type ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { atom, useRecoilState, useRecoilValue } from "recoil";

import { ATOM_KEYS } from "#shared/consts";

export type HeaderSectionProps = PropsWithChildren<{
  key?: string;
  pageTitle?: ReactNode;
  outerContainerProps?: Omit<BoxProps, "children">;
}>;

export const config = atom<HeaderSectionProps | null>({
  key: ATOM_KEYS.headerSection,
  default: null,
});

export const useHeaderSection = () => useRecoilState(config);
export const useHeaderSectionValue = () => useRecoilValue(config);

export const useHeaderSectionUpdate = ({
  pageTitle,
  children,
}: HeaderSectionProps) => {
  const [, setConfig] = useHeaderSection();
  const { key } = useLocation();

  useEffect(() => {
    setConfig({
      key,
      pageTitle,
      children,
    });
  }, [key, pageTitle, children, setConfig]);
};
