import { cloneDeep, merge, pick } from "lodash";
import { type DeepPartial } from "react-hook-form";
import {
  selectorFamily,
  DefaultValue,
  type RecoilValueReadOnly,
  type RecoilState,
  atomFamily,
} from "recoil";

import { type HttpState } from "#shared/hooks";

export type HttpRequestState<D, E = unknown> = {
  data: D | null;
  httpState: HttpState<E>;
};

/**
 * NOTE:
 *
 * A set of atoms and selectors for handling a state of HTTP request, where the
 * state contains:
 * - data
 * - httpState
 *    - isLoading
 *    - isSuccess
 *    - error
 *    - isIdle
 *
 * See INITIAL_STATE for initial values.
 *
 * Extended by UserRecoilState and FirebaseUserRecoilState.
 */
export abstract class HttpRequestRecoilState<
  StateId extends string,
  Data,
  Id extends unknown = string | null,
  E = string | null,
> {
  public static ATOM_PREFIX: string;

  public static readonly ATOM_KEYS = {
    isLoading: "isLoading",
    isIdle: "isIdle",
    isSuccess: "isSuccess",
    error: "error",
    state: "state",
    httpState: "httpState",
    data: "data",
    setPartialState: "setPartialState",
    setState: "setState",
    id: "id",
  };

  public static get INITIAL_STATE(): HttpRequestState<null, null> {
    return {
      data: null,
      httpState: {
        isLoading: false,
        isSuccess: false,
        error: null,
        isIdle: true,
      },
    };
  }

  public readonly state: RecoilState<HttpRequestState<Data | null, E | null>>;

  public setState: RecoilState<HttpRequestState<Data | null, E | null>>;

  public setPartialState: RecoilState<
    DeepPartial<HttpRequestState<Data | null, E | null>>
  >;

  public httpState: RecoilState<
    Pick<HttpRequestState<Data | null, E | null>, "httpState"> &
      Partial<Pick<HttpRequestState<Data | null, E | null>, "data">>
  >;

  public data: RecoilState<HttpRequestState<Data | null, E | null>["data"]>;

  public isLoading: RecoilValueReadOnly<boolean | null>;

  public isIdle: RecoilValueReadOnly<boolean | null>;

  public isSuccess: RecoilValueReadOnly<boolean | null>;

  public error: RecoilValueReadOnly<unknown | null>;

  public id: RecoilValueReadOnly<Id | null>;

  constructor(
    public readonly atomPrefix: StateId,
    defaultState = HttpRequestRecoilState.INITIAL_STATE,
  ) {
    if (!atomPrefix) {
      throw new Error("Missing atom prefix.");
    }

    this.state = atomFamily<HttpRequestState<Data | null, E | null>, StateId>({
      key: HttpRequestRecoilState.ATOM_KEYS.state,
      default: defaultState,
    })(atomPrefix);

    this.httpState = selectorFamily<
      Pick<HttpRequestState<Data | null, E | null>, "httpState"> &
        Partial<Pick<HttpRequestState<Data | null, E | null>, "data">>,
      StateId
    >({
      key: [this.atomPrefix, HttpRequestRecoilState.ATOM_KEYS.httpState].join(
        "-",
      ),
      get:
        () =>
        ({ get }) =>
          pick(get(this.state), "httpState", "data"),
      set:
        () =>
        ({ set, get }, state) => {
          set(
            this.state,
            state instanceof DefaultValue
              ? {
                  ...get(this.state),
                  httpState: HttpRequestRecoilState.INITIAL_STATE.httpState,
                }
              : { ...get(this.state), ...state },
          );
        },
    })(atomPrefix);

    this.data = selectorFamily<
      HttpRequestState<Data | null, E | null>["data"],
      StateId
    >({
      key: HttpRequestRecoilState.ATOM_KEYS.data,
      get:
        () =>
        ({ get }) =>
          get(this.state)?.data,
      set:
        () =>
        ({ set, get }, data) => {
          set(
            this.state,
            data instanceof DefaultValue || !data
              ? cloneDeep(HttpRequestRecoilState.INITIAL_STATE)
              : { ...get(this.state), data },
          );
        },
    })(atomPrefix);

    this.id = selectorFamily<Id | null, StateId>({
      key: HttpRequestRecoilState.ATOM_KEYS.id,
      get:
        () =>
        ({ get }) =>
          (get(this.state).data as { id?: Id })?.id || null,
    })(this.atomPrefix);

    this.isIdle = selectorFamily({
      key: HttpRequestRecoilState.ATOM_KEYS.isIdle,
      get:
        () =>
        ({ get }) =>
          get(this.state).httpState.isIdle,
    })(atomPrefix);

    this.isLoading = selectorFamily({
      key: HttpRequestRecoilState.ATOM_KEYS.isLoading,
      get:
        () =>
        ({ get }) =>
          get(this.state).httpState.isLoading,
    })(this.atomPrefix);

    this.isSuccess = selectorFamily({
      key: [this.atomPrefix, HttpRequestRecoilState.ATOM_KEYS.isSuccess].join(
        "-",
      ),
      get:
        () =>
        ({ get }) =>
          get(this.state).httpState.isSuccess,
    })(atomPrefix);

    this.error = selectorFamily({
      key: HttpRequestRecoilState.ATOM_KEYS.error,
      get:
        () =>
        ({ get }) =>
          get(this.state).httpState.error,
    })(atomPrefix);

    this.setState = selectorFamily<
      HttpRequestState<Data | null, E | null>,
      StateId
    >({
      key: HttpRequestRecoilState.ATOM_KEYS.setState,
      get:
        () =>
        ({ get }) =>
          get(this.state),
      set:
        () =>
        ({ set, get }, state) => {
          set(
            this.state,
            state instanceof DefaultValue
              ? HttpRequestRecoilState.INITIAL_STATE
              : { ...get(this.state), ...state },
          );
        },
    })(atomPrefix);

    this.setPartialState = selectorFamily<
      DeepPartial<HttpRequestState<Data | null, E | null>>,
      StateId
    >({
      key: HttpRequestRecoilState.ATOM_KEYS.setPartialState,
      get:
        () =>
        ({ get }) =>
          get(this.state) as DeepPartial<
            HttpRequestState<Data | null, E | null>
          >,
      set:
        () =>
        ({ set, get }, state) => {
          if (state instanceof DefaultValue) {
            set(this.state, state);

            return;
          }

          const mergedState = merge<
            {},
            HttpRequestState<Data | null, E | null>,
            DeepPartial<HttpRequestState<Data | null, E | null>>
          >({}, cloneDeep(get(this.state)), state);

          set(this.state, mergedState);
        },
    })(atomPrefix);
  }
}
