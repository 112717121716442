import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, type FC } from "react";
import { useForm } from "react-hook-form";

import { useAlert } from "#shared/components/alerts-provider";
import type { Texts } from "#shared/types";

import { ChannelTextInput as TextInput } from "#organization/pages/authenticated/alert-manager/components";

import { type ContactSupportBody, useSendContactSupportMessage } from "./hooks";

type ContactSupportFormValues = Pick<ContactSupportBody, "subject" | "message">;

type Text =
  | "title"
  | "formUse"
  | "subject"
  | "message"
  | "submit"
  | "subjectPlaceholder"
  | "subjectRequire"
  | "messagePlaceholder"
  | "messageRequire"
  | "sendSuccessMessage"
  | "hasBeenSent"
  | "sendErrorMessage"
  | "cancel";

interface ContactSupportDialogProps {
  open: boolean;
  onClose: () => void;
  texts?: Texts<Text>;
}

const enTexts: Required<ContactSupportDialogProps["texts"]> = {
  title: "Contact support",
  formUse:
    "If you need assistance setting up your organization or have any inquiries, please feel free to contact our support team by filling out the form below.",
  subject: "Subject",
  subjectPlaceholder: "Message Subject",
  subjectRequire: "Message subject is required",
  message: "Message",
  messagePlaceholder: "Message Content",
  messageRequire: "Message content is required",
  submit: "Submit",
  sendSuccessMessage: "Request for",
  hasBeenSent: "has been sent.",
  sendErrorMessage: "Something went wrong. Please try again.",
  cancel: "Cancel",
};

export const ContactSupportDialog: FC<ContactSupportDialogProps> = ({
  open,
  onClose,
  texts = enTexts,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<ContactSupportFormValues>({
    mode: "onSubmit",
  });

  const { addAlert } = useAlert();

  const {
    sendMessage,
    isPending,
    isSuccess,
    error,
    reset: resetRequest,
  } = useSendContactSupportMessage();

  const onSubmit = useCallback(
    (data: ContactSupportFormValues) => {
      sendMessage(data);
    },
    [sendMessage],
  );

  useEffect(() => {
    if (open) {
      return;
    }

    reset();
    resetRequest();
  }, [open, resetRequest, reset]);

  useEffect(() => {
    if (!isSuccess || !open) {
      return;
    }

    addAlert({
      type: "success",
      message: [
        texts.sendSuccessMessage,
        getValues().subject,
        texts.hasBeenSent,
      ].join(" "),
    });

    onClose();
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, open]);

  const isSubmitDisabled = isPending;

  return (
    <Dialog
      onClose={() => {
        onClose();
        reset();
      }}
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle color="primary.main">{texts.title}</DialogTitle>
      <DialogContent sx={{ px: 3, py: 2 }}>
        <Typography
          variant="body2"
          pb={4}
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          {texts.formUse}
        </Typography>

        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            textFieldProps={[
              {
                autoComplete: "off",
                title: texts?.subject,
                placeholder: texts.subjectPlaceholder,
                size: "small",
                ...register("subject", { required: texts.subjectRequire }),
                error: !!errors.subject,
                helperText: errors.subject?.message as string,
              },
              {
                title: texts.message,
                placeholder: texts.messagePlaceholder,
                ...register("message", { required: texts.messageRequire }),
                multiline: true,
                size: "small",
                rows: 4,
              },
            ]}
            titleTypographyProps={{
              variant: "body2",
              color: "text.primary",
              alignSelf: "baseline",
              fontWeight: "600",
            }}
            gridWrapperStyle={{ rowGap: 3 }}
          />

          <Box display="flex" justifyContent="end" pt={3}>
            {error ? (
              <Typography
                color="warning.main"
                display="flex"
                alignItems="center"
                columnGap={0.5}
              >
                <ErrorOutlineIcon />
                {texts.sendErrorMessage}
              </Typography>
            ) : null}
          </Box>

          <Box display="flex" justifyContent="end" pt={3} gap={1}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                reset();
                resetRequest();
                onClose();
              }}
            >
              {texts.cancel}
            </Button>
            <Button
              variant="contained"
              size="small"
              type="submit"
              disabled={isSubmitDisabled}
              endIcon={isPending ? <CircularProgress size="1em" /> : null}
            >
              {texts.submit}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
