import { type CurriedFunction2, curry, noop } from "lodash";
import { createContext } from "react";

import type { AnyObject } from "#shared/types";

import type {
  CircuitViewResource,
  SelectedResource,
  SelectedResources,
} from "./consts";

export type WithSelectedResources<P extends AnyObject> = P &
  SelectedResourcesContextValue;

export type SelectedResourcesContextValue = {
  selectedResource: [keyof SelectedResources, SelectedResource[]] | null;
  selectedResources: SelectedResources;
  prevSelectedResources: SelectedResources;
  defaultSelectedResources: SelectedResources;
  selectedCircuitResources: [CircuitViewResource, SelectedResource[]] | null;
  isCircuitResourceSelected: boolean;
  isResourcesResourceSelected: boolean;
  selectOne: CurriedFunction2<keyof SelectedResources, SelectedResource, void>;
  resetSelectedResources: () => void;
  selectCircuitResources: CurriedFunction2<
    CircuitViewResource,
    SelectedResource[],
    void
  >;
  isResourceSelected: CurriedFunction2<
    keyof SelectedResources,
    Partial<SelectedResource>,
    boolean
  >;
};

const INITIAL_VALUE: SelectedResourcesContextValue = {
  selectedResource: null,
  selectedCircuitResources: null,
  selectedResources: {} as SelectedResources,
  prevSelectedResources: {} as SelectedResources,
  defaultSelectedResources: {} as SelectedResources,
  isCircuitResourceSelected: false,
  isResourcesResourceSelected: false,
  selectOne: curry(
    (_: keyof SelectedResources, __: SelectedResource) => undefined,
  ),
  selectCircuitResources: curry(
    (_: CircuitViewResource, __: SelectedResource[]) => undefined,
  ),
  resetSelectedResources: noop,
  isResourceSelected: curry(
    (__: keyof SelectedResources, ___: Partial<SelectedResource>) => false,
  ),
};

export const SelectedResourcesContext =
  createContext<SelectedResourcesContextValue>(INITIAL_VALUE);
