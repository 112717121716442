import { Box, Portal, type PortalProps } from "@mui/material";
import { isFunction } from "lodash";
import React, { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useRecoilState } from "recoil";

import { PrimaryButton } from "#shared/components/buttons";
import type { HeadCell } from "#shared/components/data-grid";
import type { FilterLabelsType } from "#shared/components/filtering";
import {
  FilteringGroup,
  useChipsContainer,
} from "#shared/components/filtering/filtering-group";
import { useParseLinkAttributesToFilterVariables } from "#shared/components/filtering/utils";
import { LoaderLayout } from "#shared/components/layouts";
import { useControlDialog } from "#shared/components/layouts/dialog";
import { UserApiKeySelectColumn } from "#shared/generated/graphql";
import type { AnyObject, Texts } from "#shared/types";

import {
  DataGrid,
  DataGridGroups,
  DataGridWrapper,
  FiltersBarWrapper,
  GroupByButtonWrapper,
  GroupBySelect,
  type GroupByOption,
} from "#organization/components/data-grid";
import { MainPageLayout } from "#organization/components/layouts";
import type {
  DataGridHook,
  GroupDataHook,
  GroupsTitlesHook,
} from "#organization/hooks";
import { projectContextState } from "#organization/recoil/project";

import { AddAgentsDialog } from "./components/add-agents-dialog";
import {
  GenerateAPIKeyDialog,
  type GenerateAPIKeyDialogProps,
} from "./components/generate-agent-api-key-dialog";
import { RevokeKeyDialog } from "./components/revoke-key-dialog";

export type ApiState = {
  id: string;
  key: string;
};

export type TableDefaults = {
  INITIAL_ORDER_BY: string;
  INITIAL_ORDER: "asc" | "desc";
  TABLE_UNIQUE_ID: string;
};

export declare type ApiKeysPageProps<
  HeadCellData extends AnyObject = AnyObject,
  GroupData extends string = string,
  GroupTitle extends string = string,
  Data = unknown,
  Error = unknown,
  TData = unknown,
  TVariable = unknown,
  TDataRevoke = unknown,
  TVariableRevoke = unknown,
> = {
  headCells: (
    onRevoke: (apiKey: ApiState) => void,
    onAddAgent: (apiKey: ApiState) => void,
  ) => HeadCell<HeadCellData>[];
  refetchQueryKey: string[];
  useGridData: DataGridHook<Data, Error>;
  generateApiKeyDialogProps: Omit<
    GenerateAPIKeyDialogProps<TData, TVariable, TDataRevoke, TVariableRevoke>,
    "refetch" | "dialogControl"
  >;
  filters?: (id: string) => FilterLabelsType[];
  useGroupData?: GroupDataHook<GroupData>;
  useGroupsTitles?: GroupsTitlesHook<GroupTitle>;
  hideFilterAndGroupBy?: boolean;
  permissionLock?: boolean;
  isLoading?: boolean;
  texts?: Texts<Text>;
  tableDefaults?: TableDefaults;
  extraInfoButton?: JSX.Element;
  actionButtonsPortal?: {
    show: boolean;
    props: PortalProps;
  };
};

type Text = "create";

const enTexts: Required<ApiKeysPageProps["texts"]> = {
  create: "Create new key",
};

const tableDefaults: TableDefaults = {
  INITIAL_ORDER_BY: "key",
  INITIAL_ORDER: "asc",
  TABLE_UNIQUE_ID: "api-keys-table",
};

const GROUP_BYS: GroupByOption<UserApiKeySelectColumn>[] = [
  {
    title: "No value",
    value: "",
  },
  {
    title: "Key value",
    value: UserApiKeySelectColumn.Key,
  },
];

export const ApiKeysPage = <
  HeadCellData extends AnyObject = AnyObject,
  GroupData extends string = string,
  GroupTitle extends string = string,
  Data = unknown,
  Error = unknown,
  TData = unknown,
  TVariable = unknown,
  TDataRevoke = unknown,
  TVariableRevoke = unknown,
>({
  refetchQueryKey,
  headCells,
  filters,
  useGroupData,
  useGroupsTitles,
  useGridData,
  generateApiKeyDialogProps,
  texts = enTexts,
  hideFilterAndGroupBy = false,
  permissionLock = true,
  isLoading = false,
  tableDefaults: {
    INITIAL_ORDER,
    INITIAL_ORDER_BY,
    TABLE_UNIQUE_ID,
  } = tableDefaults,
  extraInfoButton,
  actionButtonsPortal,
}: ApiKeysPageProps<
  HeadCellData,
  GroupData,
  GroupTitle,
  Data,
  Error,
  TData,
  TVariable,
  TDataRevoke,
  TVariableRevoke
>): JSX.Element => {
  const [projectContext] = useRecoilState(projectContextState);
  const filterHeaders: FilterLabelsType[] = isFunction(filters)
    ? filters(projectContext.id)
    : [];
  const [filterVariables, setFilterVariables] = useState(
    useParseLinkAttributesToFilterVariables(TABLE_UNIQUE_ID),
  );
  const chipsContainer = useChipsContainer();
  const [groupByKey, setGroupByKey] =
    useState<(typeof GROUP_BYS)[number]["value"]>("");

  const revokeKeyDialog = useControlDialog();
  const addAgentKey = useControlDialog();
  const addAgentsDialog = useControlDialog();

  const [selectedApiKey, setSelectedApiKey] = useState<ApiState>({
    id: "",
    key: "",
  });

  const openRevokeKeyDialog = (apiKey: ApiState) => {
    setSelectedApiKey({ id: apiKey.id, key: apiKey.key });
    revokeKeyDialog.open();
  };

  const openAddAgentDialog = (apiKey: ApiState) => {
    setSelectedApiKey({ id: apiKey.id, key: apiKey.key });
    addAgentsDialog.open();
  };

  const headerTable = headCells(openRevokeKeyDialog, openAddAgentDialog);

  const queryClient = useQueryClient();
  const refetch = () => queryClient.invalidateQueries(refetchQueryKey);

  const actionButtons = useMemo(
    () => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 2,
          pb: 2,
        }}
      >
        {extraInfoButton && extraInfoButton}
        <Box>
          <PrimaryButton
            disabled={permissionLock}
            onClick={() => {
              addAgentKey.open();
            }}
          >
            {texts.create}
          </PrimaryButton>
        </Box>
      </Box>
    ),
    [addAgentKey, extraInfoButton, permissionLock, texts.create],
  );

  if (isLoading) {
    return <LoaderLayout />;
  }

  return (
    <MainPageLayout>
      {actionButtonsPortal?.show ? (
        <Portal {...actionButtonsPortal.props}>{actionButtons}</Portal>
      ) : (
        actionButtons
      )}
      {!hideFilterAndGroupBy && (
        <FiltersBarWrapper>
          <FilteringGroup
            labels={filterHeaders}
            onFilter={setFilterVariables}
            chipsContainer={chipsContainer}
            uniqueId={TABLE_UNIQUE_ID}
          />
          <GroupByButtonWrapper>
            <GroupBySelect
              options={GROUP_BYS}
              setGroupByKey={setGroupByKey}
              groupByKey={groupByKey}
            />
          </GroupByButtonWrapper>
        </FiltersBarWrapper>
      )}

      <Box py={4}>
        <DataGridWrapper>
          {groupByKey &&
          !hideFilterAndGroupBy &&
          useGroupData &&
          useGroupsTitles ? (
            <DataGridGroups
              headCells={headerTable}
              useGroupsTitles={useGroupsTitles}
              useGroupData={useGroupData}
              groupByKey={groupByKey}
              filterVariables={filterVariables}
              initialOrderBy={INITIAL_ORDER_BY}
              initialOrder={INITIAL_ORDER}
            />
          ) : (
            <DataGrid
              headCells={headerTable}
              useGridData={useGridData}
              enabled={!groupByKey}
              filterVariables={filterVariables}
              initialOrderBy={INITIAL_ORDER_BY}
              initialOrder={INITIAL_ORDER}
            />
          )}
        </DataGridWrapper>
      </Box>

      <RevokeKeyDialog
        selectedApiKey={selectedApiKey}
        dialogControl={revokeKeyDialog}
        refetch={refetch}
        useRevokeKey={generateApiKeyDialogProps.useRevokeKey}
        mapToInput={generateApiKeyDialogProps.revokeKeyHelperFunc.mapToInput}
      />

      <GenerateAPIKeyDialog<TData, TVariable, TDataRevoke, TVariableRevoke>
        {...{
          ...generateApiKeyDialogProps,
          refetch,
          dialogControl: addAgentKey,
        }}
      />

      <AddAgentsDialog
        selectedApiKey={selectedApiKey}
        dialogControl={addAgentsDialog}
      />
    </MainPageLayout>
  );
};
