import { Button, Chip } from "@mui/material";
import React, { useMemo, type FC } from "react";
import { useNavigate } from "react-router-dom";

import type { HeadCell } from "#shared/components/data-grid";
import { getUserRoleName, type UserRole } from "#shared/recoil";
import type { Texts } from "#shared/types";

import type { MembersPageData } from "./types";

import { useMemberPagePermissionLock as usePermissionLock } from "../../member";

export const headCells = (
  currentUserId: string,
  currentUserRole: UserRole,
  permissionLock: boolean,
  onRemove: (row: MembersPageData) => void,
): HeadCell<MembersPageData>[] => [
  {
    accessor: (row) => row.name,
    label: "Name",
    key: "name",
  },
  {
    accessor: "email",
    label: "Email",
    key: "email",
  },
  {
    accessor: (row) => {
      if (row.status === "active") {
        return "Active";
      }

      return <Chip label="Pending invitation" />;
    },
    label: "Status",
    key: "status",
  },
  {
    accessor: (row) => getUserRoleName(row.role as UserRole),
    label: "Role",
    key: "role",
    noSorting: true,
  },
  {
    accessor: (row) => row.userGroupCount.toString(),
    label: "User Groups",
    key: "user groups",
  },
  {
    accessor: (row) => (
      <MemberTableActionButtons
        {...{
          permissionLock,
          row,
          currentUserId,
          onRemove,
          currentUserRole,
        }}
      />
    ),
    label: "Actions",
    key: "id",
    noSorting: true,
  },
];

export interface MemberTableActionButtonsProps {
  permissionLock: boolean;
  row: MembersPageData;
  currentUserId: string;
  onRemove: (row: MembersPageData) => void;
  currentUserRole: UserRole;
  texts?: Texts<"edit" | "leave" | "remove">;
}

export const MemberTableActionButtons: FC<MemberTableActionButtonsProps> = ({
  permissionLock,
  row,
  currentUserId,
  onRemove,
  currentUserRole,
  texts = {
    edit: "Details",
    leave: "Leave from Organization",
    remove: "Remove from Organization",
  },
}) => {
  const { role: userRole, status, id } = row;
  const isLock = usePermissionLock(
    currentUserRole,
    status,
    userRole as UserRole,
  );

  const lock = useMemo(
    () => permissionLock || isLock,
    [isLock, permissionLock],
  );

  const navigate = useNavigate();

  return (
    <>
      <Button
        variant="text"
        size="small"
        disabled={
          (currentUserId !== id && lock) ||
          currentUserRole === "user_group_member"
        }
        onClick={() => navigate(`/settings/organization/members/${id}`)}
      >
        {texts.edit}
      </Button>
      <Button
        variant="text"
        disabled={
          (currentUserId !== id && lock) ||
          currentUserRole === "user_group_member"
        }
        size="small"
        sx={({ palette }) => ({
          color: currentUserId === id ? undefined : palette.text.primary,
          width: 200,
        })}
        onClick={() => onRemove(row)}
        color={currentUserId === id ? "error" : undefined}
      >
        {currentUserId === id ? texts.leave : texts.remove}
      </Button>
    </>
  );
};
