import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { noop } from "lodash";
import React, { type FC } from "react";

import { useAlert } from "#shared/components/alerts-provider";
import {
  type DialogControl,
  DialogLayout,
} from "#shared/components/layouts/dialog";
import type { Texts } from "#shared/types";

import { useDeleteSecret } from "../hooks";

export type DeleteSecretDialogProps = {
  texts?: Texts;
  dialogControl: DialogControl;
  secretID?: string;
  onDeleteSecret?: () => void;
};

type Text =
  | "dialogTitle"
  | "dialogContentText"
  | "buttonConfirmText"
  | "cancel";

const enTexts: Texts<Text> = {
  dialogTitle: "Delete Secret",
  dialogContentText: "Are you sure you want to delete the selected secret?",
  buttonConfirmText: "Delete Secret",
  cancel: "Cancel",
};

export const DeleteSecretDialog: FC<DeleteSecretDialogProps> = ({
  dialogControl,
  secretID,
  onDeleteSecret = noop,
  texts = enTexts,
}) => {
  const { mutate: deleteSecretMutation, isLoading } = useDeleteSecret();

  const { addAlert } = useAlert();

  const deleteSecret = () => {
    if (!secretID) {
      return;
    }

    deleteSecretMutation(
      {
        id: secretID,
      },
      {
        onSuccess: () => {
          addAlert({
            message: "Secret deleted successfully",
            type: "success",
          });
          onDeleteSecret();
          dialogControl.close();
        },
        onError: (e) => {
          addAlert({
            message: `Failed to delete secret: ${e}`,
            type: "error",
          });
        },
      },
    );
  };

  return (
    <DialogLayout
      dialogTitle={texts.dialogTitle}
      onCloseDialog={dialogControl.close}
      openDialog={dialogControl.isOpen}
      onClickDialogClose={dialogControl.close}
      buttonConfirmText={texts.buttonConfirmText}
      buttonConfirmVariant="contained"
      buttonConfirmColor="primary"
      buttonCancelVariant="outlined"
      buttonCancelColor="primary"
      dialogAction={false}
      maxWidth="xs"
    >
      <Typography>{texts.dialogContentText}</Typography>
      <Box display="flex" justifyContent="end" gap={1} mt={3}>
        <Button variant="text" color="primary" onClick={dialogControl.close}>
          {texts.cancel}
        </Button>
        <Button
          variant="contained"
          endIcon={isLoading ? <CircularProgress size="1em" /> : undefined}
          disabled={isLoading}
          onClick={deleteSecret}
        >
          {texts.buttonConfirmText}
        </Button>
      </Box>
    </DialogLayout>
  );
};
