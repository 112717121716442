import { Paper, Box } from "@mui/material";
import { lowerCase } from "lodash";
import React, { type FC, forwardRef, type MutableRefObject } from "react";

import { useEventOutsideNode } from "#shared/hooks";

import { SearchBarListContainer } from "./styled";

import {
  ComponentsMenuList,
  SignalsMenuList,
  type PolicyMenuListProps,
} from "../../../../components";
import type { FlowChartStateResults } from "../../../../config-flow-chart";

export type SearchBarListProps = PolicyMenuListProps & {
  setShowList: (showList: boolean) => void;
  flowChart: FlowChartStateResults;
};

export const SearchBarList: FC<SearchBarListProps> = forwardRef(
  ({ setShowList, ...props }, ref) => {
    useEventOutsideNode(
      [ref as MutableRefObject<unknown>],
      "keydown",
      (_, event) => {
        const { key } = event as KeyboardEvent;

        if (lowerCase(key) === "escape") {
          setShowList(false);
        }
      },
    );

    return (
      <SearchBarListContainer>
        <Box component={Paper} ref={ref}>
          <ComponentsMenuList {...props} />
          <SignalsMenuList {...props} />
        </Box>
      </SearchBarListContainer>
    );
  },
);
