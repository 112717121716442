import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Tooltip,
  Typography,
  styled,
  type TooltipProps,
  tooltipClasses,
} from "@mui/material";
import React, { type FC } from "react";

import type { ComponentView } from "#shared/generated/graphql";

export interface NodeInfoTooltipProps
  extends Pick<
    ComponentView,
    "componentDescription" | "componentType" | "componentName"
  > {}

export const NodeInfoTooltip: FC<NodeInfoTooltipProps> = ({
  componentDescription,
  componentType,
  componentName,
}) => (
  <NodeInfoTooltipStyled
    {...{
      placement: "top",
      arrow: true,
      title: (
        <TooltipContent
          {...{ componentDescription, componentType, componentName }}
        />
      ),
    }}
  >
    <InfoIcon
      sx={{
        fontSize: 12,
        cursor: "pointer",
        marginBottom: 0.3,
        alignSelf: "flex-end",
      }}
    />
  </NodeInfoTooltipStyled>
);

export const TooltipContent: FC<NodeInfoTooltipProps> = ({
  componentName,
  componentType,
  componentDescription,
}) => (
  <Box
    {...{
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      gap: 0.5,
    }}
  >
    <TitleTypography>{componentName}</TitleTypography>
    <ContentStyled>
      <ContentTypography>component description</ContentTypography>
      <TitleTypography>{componentDescription}</TitleTypography>
    </ContentStyled>
    <ContentStyled>
      <ContentTypography>component type</ContentTypography>
      <TitleTypography>{componentType}</TitleTypography>
    </ContentStyled>
  </Box>
);

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 700,
  lineHeight: "143%",
  color: theme.palette.text.primary,
}));

export const ContentTypography = styled(Typography)(({ theme }) => ({
  fontSize: 10,
  fontWeight: 500,
  color: theme.palette.text.primary,
}));

export const ContentStyled = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: theme.spacing(1.5),
  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: 3,
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
  },
}));

export const NodeInfoTooltipStyled = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)(
  ({
    theme,
    theme: {
      palette: { mode },
    },
  }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "max-content",
      backgroundColor: theme.palette.grey[mode === "light" ? 100 : 700],
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.grey[mode === "light" ? 100 : 700],
    },
  }),
);
