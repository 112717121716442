import { Slide, type SlideProps } from "@mui/material";
import React, { forwardRef, type FC } from "react";

export const FLYOUT_SLIDE_TIMEOUT = 150;

export const FlyoutSlide: FC<SlideProps> = forwardRef((props, _) => (
  <Slide
    direction="left"
    timeout={{ enter: FLYOUT_SLIDE_TIMEOUT, exit: FLYOUT_SLIDE_TIMEOUT }}
    {...props}
  />
));
