# Max Idle Time

## Overview

The `max_idle_time` sets the maximum duration a token bucket can remain idle for a label before its state is cleared, with a setting of 0 meaning that the state will never be removed. By default, it is set to `7200s`.

## Example

For instance, setting `max_idle_time` to '120s' means the token bucket will retain its state for 2 minutes of inactivity. After this period without requests, the bucket's state is reset or removed, depending on the configuration.

## Note

This field employs the [Duration](https://protobuf.dev/programming-guides/proto3/#json) JSON representation from Protocol Buffers. The format accommodates fractional seconds up to nine digits after the decimal point, offering nanosecond precision. Every duration value must be suffixed with an "s" to indicate 'seconds.'
