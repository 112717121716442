import { Typography, Tabs, Tab, type TabsProps } from "@mui/material";
import React, {
  type FC,
  type ReactNode,
  useContext,
  useState,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";

import { BreadcrumbButton } from "#shared/components/breadcrumbs";
import { TabPanel } from "#shared/components/tab-panel";
import type { Texts } from "#shared/types";

import {
  FlyoutMenuContentContainer,
  FlyoutMenuHeaderContainer,
} from "#organization/components/flyout-menu/styled";
import { JsonToYaml } from "#organization/components/json-to-yaml";
import { FlyoutMenuBreadcrumbAppender } from "#organization/recoil/breadcrumbs";

import { POLICIES_ROUTE } from "../../../../../routes.definitions";
import { SelectedResourcesContext } from "../../../../selected-resource-context";

const HEADER_HEIGHT = "129px";

export type CircuitFlyoutMenuText =
  | "dashboard"
  | "alerts"
  | "yaml"
  | "policies"
  | "circuit";

export interface CircuitFlyoutMenuTabsProps {
  selectedCircuitResource: Record<string, unknown> | undefined;
  policyName: ReactNode;
  title: ReactNode;
  dashboard: JSX.Element | null;
  texts?: Texts<CircuitFlyoutMenuText>;
  hideFlyoutMenu: () => void;
}

const enTexts: Required<CircuitFlyoutMenuTabsProps["texts"]> = {
  dashboard: "Dashboard",
  alerts: "Alerts",
  yaml: "YAML",
  policies: "Policies",
  circuit: "Circuit",
};

export const CircuitFlyoutMenuTabs: FC<CircuitFlyoutMenuTabsProps> = ({
  selectedCircuitResource,
  policyName,
  title,
  dashboard,
  texts = enTexts,
  hideFlyoutMenu,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const onTabsChange: TabsProps["onChange"] = (_, tabValue: number) => {
    setActiveTab(tabValue);
  };

  const { selectedResource, resetSelectedResources } = useContext(
    SelectedResourcesContext,
  );
  const [selectedResourceType, [resource] = []] = selectedResource || [];

  const navigate = useNavigate();

  const hideOnAppenderClick = useCallback(() => {
    hideFlyoutMenu();
    resetSelectedResources();
  }, [hideFlyoutMenu, resetSelectedResources]);

  const navigateToPolicies = useCallback(() => {
    hideOnAppenderClick();

    navigate(POLICIES_ROUTE.ABSOLUTE_PATH);
  }, [hideOnAppenderClick, navigate]);

  const { name = null, componentName: resourceName = null } =
    resource?.uiData || {};

  return (
    <>
      <FlyoutMenuHeaderContainer
        sx={{
          minHeight: HEADER_HEIGHT,
          paddingBottom: 0,
          justifyContent: "space-between",
        }}
      >
        <FlyoutMenuBreadcrumbAppender>
          <BreadcrumbButton onClick={navigateToPolicies}>
            {texts.policies}
          </BreadcrumbButton>
        </FlyoutMenuBreadcrumbAppender>
        <FlyoutMenuBreadcrumbAppender>
          <BreadcrumbButton onClick={hideOnAppenderClick}>
            {policyName}
          </BreadcrumbButton>
        </FlyoutMenuBreadcrumbAppender>
        <FlyoutMenuBreadcrumbAppender>
          <BreadcrumbButton onClick={hideOnAppenderClick}>
            {enTexts.circuit}
          </BreadcrumbButton>
        </FlyoutMenuBreadcrumbAppender>
        {resource ? (
          <FlyoutMenuBreadcrumbAppender>
            <Typography>
              <Typography component="span">{name}</Typography>
              {resourceName ? (
                <Typography component="span"> ({resourceName})</Typography>
              ) : null}
            </Typography>
          </FlyoutMenuBreadcrumbAppender>
        ) : null}

        <Typography component="h1" variant="h1">
          {title}
        </Typography>
        <Tabs value={activeTab} onChange={onTabsChange}>
          <Tab label={texts.dashboard} />
          {selectedResourceType !== "Signal" && selectedCircuitResource && (
            <Tab label={texts.yaml} />
          )}
        </Tabs>
      </FlyoutMenuHeaderContainer>

      <TabPanel value={0} activeValue={activeTab}>
        <FlyoutMenuContentContainer mt={-8}>
          {dashboard}
        </FlyoutMenuContentContainer>
      </TabPanel>

      <TabPanel value={1} activeValue={activeTab}>
        <FlyoutMenuContentContainer>
          <JsonToYaml
            jsonObject={selectedCircuitResource || {}}
            customStyle={{
              overflowY: "hidden",
              maxHeight: "auto",
            }}
          />
        </FlyoutMenuContentContainer>
      </TabPanel>
    </>
  );
};
