import { useTheme } from "@mui/material";
import { dump, type DumpOptions } from "js-yaml";
import React, { type FC } from "react";
import SyntaxHighlighter, {
  type SyntaxHighlighterProps,
} from "react-syntax-highlighter";
import {
  defaultStyle,
  dark,
} from "react-syntax-highlighter/dist/esm/styles/hljs";

import { yamlCodeSnippetWrapper } from "./styled";

export interface JsonToYamlProps {
  jsonObject: Record<string, unknown>;
  options?: DumpOptions;
  customStyle?: SyntaxHighlighterProps["customStyle"];
}

export const DEFAULT_DUMP_OPTIONS: DumpOptions = {
  skipInvalid: true,
  lineWidth: -1,
};

export const JsonToYaml: FC<JsonToYamlProps> = ({
  jsonObject,
  options = {},
  customStyle = {},
}) => {
  const theme = useTheme();

  const highlighterProps: Omit<SyntaxHighlighterProps, "children"> = {
    language: "yaml",
    style: theme.palette.mode === "dark" ? dark : defaultStyle,
    customStyle: {
      ...yamlCodeSnippetWrapper(theme),
      ...customStyle,
    },
    showLineNumbers: true,
    wrapLongLines: true,
  };

  return (
    <SyntaxHighlighter {...highlighterProps}>
      {dump(jsonObject, { ...DEFAULT_DUMP_OPTIONS, ...options })}
    </SyntaxHighlighter>
  );
};
