import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { type FC } from "react";

const AlertDataCardStyled = styled(Box, {
  shouldForwardProp: (props) => props !== "type",
})<{ type?: AlertDataCardType["type"] }>(({ theme, type }) => ({
  display: "flex",
  padding: "4px 4px 4px 12px",
  flexDirection: "column",
  alignItems: "flex-start",
  alignSelf: "stretch",
  borderRadius: "4px",
  borderLeft: `3px solid ${
    type === "error" ? theme.palette.error.main : theme.palette.info.main
  }`,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0.5px 0.5px 1px 1px rgba(77, 77, 77, 0.25)",
}));

const AlertDataCardTitle = styled(Typography, {
  shouldForwardProp: (props) => props !== "type",
})<{
  type?: AlertDataCardType["type"];
}>(({ theme, type }) => ({
  color: type === "error" ? theme.palette.error.main : theme.palette.info.main,
}));

const AlertDataCardDescription = styled(Typography)(() => ({
  fontFamily: "monospace",
  fontSize: "12px",
  wordBreak: "break-all",
}));

export type AlertDataCardType = {
  title?: string;
  type?: "info" | "error";
  time?: string;
  description?: string;
};

type AlertDataCardProps = AlertDataCardType;

export const AlertDataCard: FC<AlertDataCardProps> = ({
  title,
  type = "info",
  time,
  description,
}) => (
  <Box>
    <AlertDataCardStyled type={type}>
      <AlertDataCardTitle type={type} variant="body2">
        {title}
      </AlertDataCardTitle>
      <Typography variant="body2">{time}</Typography>
      <AlertDataCardDescription variant="body2" mt={1}>
        {description}
      </AlertDataCardDescription>
    </AlertDataCardStyled>
  </Box>
);
