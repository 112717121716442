import { Box } from "@mui/material";
import React, { useMemo, type FC, useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import {
  ErrorLayout,
  LoaderLayout,
  NoDataLayout,
} from "#shared/components/layouts";

import { LoadDashboardPortal } from "#organization/components/grafana";

import { LayoutDashboardsBackground } from "./styled";
import { dashboardMenuData } from "./utils";

import { useIsSelected } from "../../components/submenu/menus/is-selected";
import { usePolicyDashboards } from "../../hooks";
import { SelectedResourcesContext } from "../../selected-resource-context";

export type DashboardsPageProps = ReturnType<typeof useDashboardPageHelper>;

export const DashboardsPage: FC<DashboardsPageProps> = ({
  isError,
  isLoading,
  listItems,
  selectedDashboard,
}) => {
  const hiddenVariables = useRef(["var-datasource"]);
  const qr = useRef({});

  const { resetSelectedResources } = useContext(SelectedResourcesContext);

  useEffect(
    () => () => {
      resetSelectedResources();
    },
    [resetSelectedResources],
  );

  if (isLoading && !isError) {
    return <LoaderLayout />;
  }

  if (isError && !isLoading) {
    return <ErrorLayout />;
  }

  if (!listItems.length) {
    return <NoDataLayout />;
  }

  return (
    <LayoutDashboardsBackground>
      <Box
        sx={{
          position: "relative",
          overflowX: "hidden",
          paddingRight: "8px",
        }}
      >
        {selectedDashboard?.uiData?.id ? (
          <LoadDashboardPortal
            slug="aperture-dashboard"
            queryParams={qr.current}
            uid={selectedDashboard.uiData.id}
            controlsContainer="grafana-controls"
            hiddenVariables={hiddenVariables.current}
          />
        ) : null}
      </Box>
    </LayoutDashboardsBackground>
  );
};

export type UseDashboardPageHelper = {
  policyHash: string;
  policyId: string;
};

export const useDashboardPageHelper = ({
  policyHash,
  policyId,
}: UseDashboardPageHelper) => {
  const {
    data: dashboards,
    isError,
    isLoading,
  } = usePolicyDashboards(policyId, policyHash);

  const { selectOne, selectedResource } = useContext(SelectedResourcesContext);

  const { pathname } = useLocation();

  const [, [selectedDashboard] = []] = selectedResource || [];

  const isSelected = useIsSelected();

  const { listItems } = useMemo(
    () => dashboardMenuData(dashboards || []),
    [dashboards],
  );

  useEffect(() => {
    if (
      !selectedResource &&
      listItems.length > 0 &&
      pathname.includes("dashboards")
    ) {
      const [firstDash] = listItems;
      selectOne("Dashboards", firstDash);
    }
  }, [listItems, pathname, selectOne, selectedResource]);

  return {
    listItems,
    isError,
    isLoading,
    selectedDashboard,
    isSelected,
    selectOne,
  };
};
