import { inputBaseClasses, outlinedInputClasses } from "@mui/material";
import { styled, Box, type BoxProps, type Theme } from "@mui/system";

import { MUI_DISABLED_CLASSNAME } from "#shared/consts";

import type { FnTheme } from "../../../../../../../../../app-theme-provider/types";

export type FakeSelectProps = BoxProps & { disabled?: boolean };

export const ListButton = styled(Box)({
  cursor: "pointer",
  display: "flex",
  gridTemplateColumns: "auto 1fr",
  alignItems: "center",
  justifyItems: "start",
});

export const FAKE_SELECT_LINE_HEIGHT = "2.6575em";

const disabledFieldsetSelector = [
  "",
  inputBaseClasses.root,
  outlinedInputClasses.root,
  inputBaseClasses.formControl,
  MUI_DISABLED_CLASSNAME,
].join(".");

export const FakeSelect = styled(Box)<FakeSelectProps>(({
  disabled,
  ...props
}) => {
  const theme = props.theme as FnTheme;

  return {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "4px",
    paddingTop: theme.spacing(1.6),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: "1rem",
    lineHeight: FAKE_SELECT_LINE_HEIGHT,
    color: (theme as Theme).palette.primary[700],
    overflow: "hidden",
    cursor: "pointer",
    "&:hover": {
      borderColor: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
    display: "flex",
    maxHeight: `calc(${FAKE_SELECT_LINE_HEIGHT} + ${theme.spacing(2)})`,
    [["div", disabledFieldsetSelector].join("")]: {
      cursor: "default",
      [["fieldset", outlinedInputClasses.notchedOutline, ``].join(".")]: {
        cursor: "default",
        borderColor: theme.palette.text.disabled,
        color: theme.palette.text.disabled,
        backgroundColor: "#ff0000",
        "&:hover": {
          borderColor: "initial",
        },
      },
    },
  };
});
