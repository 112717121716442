import React, { type FC, useMemo } from "react";

import { DASHBOARDVER } from "#shared/dashboard-versions";

import { LoadDashboardPortal } from "#organization/components/grafana";
import { DASHBOARDS } from "#organization/pages/consts";
import type {
  DashboardConfig,
  FlowAnalyticsDashboardQueryParam,
} from "#organization/pages/types";
import { useProjectContextState } from "#organization/recoil/project";

import type { FlowControlFlyoutConfig } from "../../../types";

export type FlowAnalyticsSDKProps = {
  service: Pick<
    FlowControlFlyoutConfig,
    "agentGroup" | "serviceName" | "controlPoint" | "controllerId"
  >;
};

const config: Omit<DashboardConfig<"FLOW_ANALYTICS">, "queryParams"> = {
  name: "service-summary-sdk",
  slug: "http",
  version: DASHBOARDVER.FLOW_ANALYTICS_SDK,
  uuid: DASHBOARDS.FLOW_ANALYTICS_SDK,
  hiddenVariables: [
    "var-agent_group",
    "var-fn_project_id",
    "var-services",
    "var-controller_id",
    "var-control_point",
  ],
  filters: [],
};

export const FlowAnalyticsSDK: FC<FlowAnalyticsSDKProps> = ({
  service: { agentGroup, serviceName, controllerId, controlPoint },
}) => {
  const projectState = useProjectContextState();

  const queryParams: { [Q in FlowAnalyticsDashboardQueryParam]?: string } =
    useMemo(
      () => ({
        "var-agent_group": agentGroup,
        "var-fn_project_id": projectState.id,
        "var-services": serviceName || "All",
        "var-controller_id": controllerId,
        "var-control_point": controlPoint,
      }),
      [agentGroup, controllerId, serviceName, projectState.id, controlPoint],
    );

  return (
    <>
      {queryParams && (
        <LoadDashboardPortal
          queryParams={queryParams}
          slug={config.slug}
          uid={config.uuid}
          hiddenVariables={config.hiddenVariables}
        />
      )}
    </>
  );
};
