import { Check } from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { type FC } from "react";

import { PrimaryButton } from "#shared/components/buttons";
import { CopyTextArea } from "#shared/components/inputs/copy-text-area";
import {
  type DialogControl,
  DialogLayout,
} from "#shared/components/layouts/dialog";
import { ExternalTextLink } from "#shared/components/text-link";

import { BASE_DOCUMENTATION_URL } from "#organization/pages/consts";

import { HeaderDialogNested } from "./styled";

import type { ApiState } from "../../page";

type ComponentProps = {
  dialogControl: DialogControl;
  selectedApiKey: ApiState;
};

export const AddAgentsDialog: FC<ComponentProps> = ({
  dialogControl,
  selectedApiKey,
}) => (
  <DialogLayout
    dialogTitle="Add New Agents or Controllers"
    dialogContentText=""
    onCloseDialog={dialogControl.close}
    openDialog={dialogControl.isOpen}
    buttonConfirmText="Add Agent"
    buttonConfirmVariant="contained"
    buttonConfirmColor="primary"
    buttonCancelVariant="outlined"
    buttonCancelColor="primary"
    dialogAction={false}
  >
    <>
      <HeaderDialogNested>1. Copy the API key</HeaderDialogNested>
      <CopyTextArea
        isOneLine
        cliTextBody={selectedApiKey.key}
        cliTextContent={selectedApiKey.key}
      />
      <HeaderDialogNested>
        2. Use the API Key to connect Aperture Controller or Agent to FluxNinja
        Aperture Cloud by following the{" "}
        <ExternalTextLink
          href={`${BASE_DOCUMENTATION_URL}/reference/fluxninja`}
        >
          steps from documentation.
        </ExternalTextLink>
      </HeaderDialogNested>
    </>
    <Box
      sx={(theme) => ({
        display: "flex",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
          flexGrow: 1,
        },
        justifyContent: "end",
        pt: 4,
      })}
    >
      <PrimaryButton startIcon={<Check />} onClick={dialogControl.close}>
        Got it
      </PrimaryButton>
    </Box>
  </DialogLayout>
);
