import { gql } from "graphql-request";

import type { UserGroupProjectAssignmentQuery } from "#shared/generated/graphql";

import { useGridData, type DataGridHook } from "#organization/hooks";

export const UserGroupPerProject = gql`
  query userGroupProjectAssignment(
    $where: UserGroupProjectAssignmentBoolExp
    $orderBy: [UserGroupProjectAssignmentOrderBy]
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    userGroupProjectAssignments(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          permissions
          userGroup {
            id
            name
            userGroupMemberships {
              totalCount
              edges {
                node {
                  user {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

type AdaptedUserGroups = {
  id: string;
  membersCount: number;
  name: string;
  settings: string;
  action: string;
  members: string[];
  permissions: string;
  groupAssignmentID: string;
};

type Members =
  UserGroupProjectAssignmentQuery["userGroupProjectAssignments"]["edges"][number]["node"]["userGroup"]["userGroupMemberships"]["edges"];

const getMembers = (members: Members) => {
  const table: string[] = [];

  members.forEach((member) => {
    table.push(member.node.user.id);
  });

  return table;
};

type UserGroupsData =
  UserGroupProjectAssignmentQuery["userGroupProjectAssignments"]["edges"][number]["node"];

export const useUserGroupPerProject: DataGridHook = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  enabled,
  filterVariables,
) =>
  useGridData<UserGroupsData, AdaptedUserGroups>(
    "userGroupProjectAssignments",
    UserGroupPerProject,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    filterVariables,
    (userGroups) => ({
      id: userGroups.userGroup.id,
      name: userGroups.userGroup.name,
      settings: userGroups.userGroup.id,
      action: userGroups.userGroup.id,
      membersCount: userGroups.userGroup?.userGroupMemberships?.totalCount,
      members: getMembers(userGroups.userGroup?.userGroupMemberships?.edges),
      permissions: userGroups.permissions || "-",
      groupAssignmentID: userGroups?.id || "-",
    }),
    enabled,
    false,
  );
