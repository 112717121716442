import { API_SERVICE_URL } from "#shared/consts";
import { ENV } from "#shared/env";
import { LoggerService } from "#shared/services";
import type { AnyObject } from "#shared/types";
import { httpClient } from "#shared/utils/http-client";

const intercomDefaultSettings: Intercom_.IntercomSettings = {
  app_id: "sdvy39vb",
  api_base: "https://api-iam.intercom.io",
  ...(ENV.VITE_APP === "organization" && {
    custom_launcher_selector: "#help_intercom",
    hide_default_launcher: true,
  }),
};

export declare type IntercomUserHashEndPointResponse = {
  hash: string;
};

const intercomUserHashEndPoint = (
  email: string,
  app: string | undefined = ENV.VITE_APP,
) =>
  [
    `${API_SERVICE_URL}${app === "organization" ? "/auth" : ""}`,
    "intercom",
    "user-hash",
    email,
  ].join("/");

export class FnIntercom {
  static async fetchIntercomUserHash(email: string) {
    try {
      const response = await httpClient.get<IntercomUserHashEndPointResponse>({
        url: intercomUserHashEndPoint(email),
      });

      if (!response.ok) {
        LoggerService.error(
          `error fetching intercom user hash. Status: ${response.status}`,
        );

        throw new Error("error fetching intercom user hash");
      }

      const res = await response.json();

      return res;
    } catch (err) {
      LoggerService.error(`error fetching intercom user hash. Status: ${err}`);
      throw new Error("error fetching intercom user hash");
    }
  }

  static async bootUser(userSettings: Intercom_.IntercomSettings) {
    if (!userSettings.email) {
      LoggerService.error(`error booting intercom user. Email is required`);

      return;
    }

    const { hash } = await FnIntercom.fetchIntercomUserHash(userSettings.email);

    window.Intercom("boot", {
      ...intercomDefaultSettings,
      ...userSettings,
      user_hash: hash,
    });
  }

  static update(userSettings: Intercom_.IntercomSettings = {}) {
    window.Intercom("update", {
      ...userSettings,
      last_request_at: new Date().getTime() / 1000,
    });
  }

  static shutdown() {
    window.Intercom("shutdown");
  }

  static trackEvent(eventName: string, metaData: AnyObject = {}) {
    window.Intercom("trackEvent", eventName, metaData);
  }
}
