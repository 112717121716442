import { Box } from "@mui/system";
import React, { memo, type FC } from "react";

import { type Texts } from "#shared/types";

import { SectionButtons } from "#organization/components/button";

import { SectionHeader, SettingsSectionLabel } from "../../../components";
import { SectionBody, SectionButtonWrapper } from "../../../components/styled";

export interface EmailConfigFormProps {
  texts?: Texts<Text>;
  disabled: boolean;
  onSubmit: () => void;
}

type Text = "configuration" | "noData";

const enTexts: Required<EmailConfigFormProps["texts"]> = {
  configuration: "Email Configuration",
  noData: "No settings are available for this authorization method",
};

export const EmailConfigForm: FC<EmailConfigFormProps> = memo(
  ({ disabled, texts = enTexts, onSubmit }) => (
    <>
      <SectionHeader title={texts.configuration} />
      <form>
        <SectionBody gridTemplateColumns="20vw 36vw">
          <SettingsSectionLabel primaryText="" secondaryText={texts.noData} />
          <Box />
          <SectionButtonWrapper sx={{ justifySelf: "end", display: "none" }}>
            <SectionButtons
              variant="contained"
              disabled={disabled}
              onClick={onSubmit}
            >
              Save {texts.configuration}
            </SectionButtons>
          </SectionButtonWrapper>
        </SectionBody>
      </form>
    </>
  ),
);
