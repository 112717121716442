import { useLayoutEffect, useRef } from "react";
import { RecoilState, useSetRecoilState } from "recoil";

import type { Breadcrumb } from "./types";

/**
 * Adds a new breadcrumb when the component mounts and removes it when component unmounts.
 *
 * The breadcrumb should have the `key` property set.
 */
export const getUseNewBreadcrumb =
  (state: RecoilState<Breadcrumb[]>) => (breadcrumb: Breadcrumb) => {
    const setBreadcrumbs = useSetRecoilState(state);
    const breadcrumbIndexRef = useRef(0);

    // NOTE: only reserve the spot for the breadcrumb when it mounts
    // NOTE: `useLayoutEffect` instead of `useEffect` to show the breadcrumb
    // in the upcoming render. Otherwise, it would be rendered in the next one.
    useLayoutEffect(() => {
      setBreadcrumbs((currentBreadcrumbs) => {
        // NOTE: remember the index of the breadcrumb inserted by this component.
        // The order of breadcrumbs will not change (they can only be added or removed),
        // thus, it is safe to remember it for the lifetime of the component.
        breadcrumbIndexRef.current = currentBreadcrumbs.length;

        return [...currentBreadcrumbs, breadcrumb];
      });

      return () => {
        setBreadcrumbs((currentBreadcrumbs) => currentBreadcrumbs.slice(0, -1));
      };
      // NOTE: breadcrumb changing dynamically is handled in the second `setLayoutEffect
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setBreadcrumbs]);

    useLayoutEffect(() => {
      setBreadcrumbs((currentBreadcrumbs) => [
        ...currentBreadcrumbs.slice(0, breadcrumbIndexRef.current),
        breadcrumb,
        ...currentBreadcrumbs.slice(breadcrumbIndexRef.current + 1),
      ]);
    }, [breadcrumb, setBreadcrumbs]);
  };
