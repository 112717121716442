import { Refresh } from "@mui/icons-material";
import { Box, Button, SvgIcon, Typography } from "@mui/material";
import React, {
  useContext,
  type FC,
  useCallback,
  useMemo,
  useEffect,
} from "react";

import { useAlert } from "#shared/components/alerts-provider";
import { Image } from "#shared/components/image";
import emailIcon from "#shared/components/sign-in/assets/email.svg";
import { LOCAL_STORAGE_KEYS } from "#shared/consts";
import { FirebaseContext } from "#shared/contexts/firebase";
import type { Texts } from "#shared/types";

import { GlobalFrame } from "../layouts";

export interface SignInVerificationEmailProps {
  texts?: Texts<Text>;
  email?: string;
}

type Text =
  | "verificationSent"
  | "sentEmail"
  | "yourEmail"
  | "confirmEmail"
  | "reActionMsg"
  | "reAction"
  | "reActionProgress"
  | "reActionSuccess";

const enTexts: Required<SignInVerificationEmailProps["texts"]> = {
  verificationSent: "Work Email Address",
  sentEmail: "We sent an email to",
  yourEmail: "your email ID",
  confirmEmail:
    "to confirm your email address. Please click the link in email to continue.",
  reActionMsg: "Didn’t get the email? Check Spam folder or",
  reAction: "Send again",
  reActionProgress: "Resending",
  reActionSuccess: "Sent verification email successfully",
};

export const SignInVerificationEmail: FC<SignInVerificationEmailProps> = ({
  texts = enTexts,
  email,
}) => {
  const { addAlert } = useAlert();
  const { sendSignInLinkToEmail } = useContext(FirebaseContext);

  const {
    sendEmailStatus = null,
    resetSendEmailStatus = null,
    sendEmail = null,
  } = sendSignInLinkToEmail || {};

  const resend = useCallback(() => {
    if (!email) {
      return;
    }

    if (resetSendEmailStatus) {
      resetSendEmailStatus();
    }

    if (sendEmail) {
      sendEmail(email);
    }
  }, [email, resetSendEmailStatus, sendEmail]);

  const disabled = useMemo(
    () => !!sendEmailStatus?.isLoading && !sendEmailStatus?.error,
    [sendEmailStatus?.isLoading, sendEmailStatus?.error],
  );

  useEffect(() => {
    if (sendEmailStatus?.error) {
      addAlert({ type: "error", message: sendEmailStatus.error });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendEmailStatus?.error]);

  useEffect(() => {
    if (sendEmailStatus?.isSuccess) {
      addAlert({ type: "success", message: texts.reActionSuccess });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendEmailStatus?.isSuccess]);

  return (
    <Box>
      <SvgIcon
        src={emailIcon}
        alt="Email"
        component={Image}
        sx={{ fontSize: 80, marginTop: 4, marginBottom: 4 }}
      />
      <Typography mb={4} variant="h4" textAlign="center">
        {texts.verificationSent}
      </Typography>
      <Typography mb={3}>
        {texts.sentEmail} {email || texts.yourEmail} {texts.confirmEmail}
      </Typography>
      <Typography color="text.secondary">
        {texts.reActionMsg}
        <Button
          sx={{ paddingX: 1 }}
          size="small"
          onClick={resend}
          startIcon={disabled ? undefined : <Refresh />}
          disabled={disabled}
        >
          {disabled ? texts.reActionProgress : texts.reAction}
        </Button>
      </Typography>
    </Box>
  );
};

export const SignInVerificationEmailPage: FC = () => {
  const email = window.localStorage.getItem(LOCAL_STORAGE_KEYS.signInWithEmail);

  return (
    <GlobalFrame hideHelp hideLogout>
      <SignInVerificationEmail email={email || ""} />
    </GlobalFrame>
  );
};
