import { gql } from "graphql-request";
import { useState } from "react";

import type { GetAllUserGroupsToAddQuery } from "#shared/generated/graphql";
import { useGqlQuery } from "#shared/utils";

const QUERY_KEY = "'allUserGroupsQuery'";
const GetAllUserGroupsToAdd = gql`
  query GetAllUserGroupsToAdd($first: Int, $where: UserGroupBoolExp) {
    userGroups(first: $first, where: $where) {
      edges {
        node {
          id
          name
          userGroupProjectAssignments {
            totalCount
            edges {
              node {
                project {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useAllUserGroupsQuery = (projectId: string = "") => {
  const [search, setSearch] = useState<string>("");
  const findBy = {
    first: 4,
    where: {
      ...(projectId.length && {
        not: {
          userGroupProjectAssignments: { projectID: { eq: projectId } },
        },
      }),

      name: { like: `${search}%` },
    },
  };
  const { isError, isLoading, data, refetch } =
    useGqlQuery<GetAllUserGroupsToAddQuery>(
      [QUERY_KEY, projectId?.length ? findBy : ""],
      GetAllUserGroupsToAdd,
      findBy,
    );

  return {
    error: isError,
    loading: isLoading,
    projects: data?.userGroups.edges,
    setSearch,
    refetch,
    search,
    refetchKey: [QUERY_KEY, projectId?.length ? findBy : ""],
  };
};
