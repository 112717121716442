import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  FormControl,
  FormControlLabel,
  Grow,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  type RadioGroupProps,
  Typography,
  useTheme,
  type SvgIconProps,
} from "@mui/material";
import React, {
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";

import type { Texts } from "#shared/types";

import type { FnTheme } from "../../../../../app-theme-provider/types";

export type GroupBySelectProps<T extends string = string> = PropsWithChildren<{
  options: GroupByOption<T>[];
  groupByKey: GroupByValue<T>;
  setGroupByKey: Dispatch<SetStateAction<GroupByValue<T>>>;
  texts?: Texts<Text>;
}>;

export type GroupByOption<T extends string = string> = {
  title: string;
  value: GroupByValue<T>;
};

type GroupByValue<T extends string = string> = T | "";

type Text = "addGroupBy" | "groupBy";

export const enTexts: Required<GroupBySelectProps>["texts"] = {
  addGroupBy: "Add group",
  groupBy: "Group by",
};

export const cancelButtonLabel = "cancel group by";

export const GroupBySelect = <T extends string = string>({
  options,
  groupByKey,
  setGroupByKey,
  texts = enTexts,
}: GroupBySelectProps<T>) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [openSelect, setOpenSelect] = useState(false);

  const currentOption = useMemo(
    () => options.find(({ value }) => value === groupByKey),
    [groupByKey, options],
  );

  const toggleMenu = useCallback(() => {
    setOpenSelect((prevState) => !prevState);
  }, []);

  const onMenuItemChange = useCallback<Required<RadioGroupProps>["onChange"]>(
    ({ currentTarget: { value } }) => {
      setGroupByKey(value as GroupByValue<T>);
      setOpenSelect(false);
    },
    [setGroupByKey],
  );

  const theme = useTheme() as unknown as FnTheme;

  const setIsClose = useCallback<Required<SvgIconProps>["onClick"]>(() => {
    setGroupByKey("");
  }, [setGroupByKey]);

  const label = useMemo(
    () =>
      [texts.addGroupBy, currentOption?.value ? currentOption.title : undefined]
        .filter(Boolean)
        .join(": "),
    [currentOption?.title, currentOption?.value, texts.addGroupBy],
  );

  return (
    <Box ref={anchorRef} sx={{ marginRight: 2 }}>
      <Button
        startIcon={
          <ViewModuleIcon sx={{ fontSize: "1.15rem" }} viewBox="0 0 26 26" />
        }
        variant="contained"
        color="secondary"
        type="button"
        onClick={toggleMenu}
        size="small"
      >
        {label}
        {currentOption?.value && (
          <HighlightOffIcon
            onClick={setIsClose}
            aria-label={cancelButtonLabel}
            sx={{
              cursor: "pointer",
              marginLeft: 0.8,
            }}
          />
        )}
      </Button>
      <Box
        component={Popper}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 4],
            },
          },
        ]}
        open={openSelect}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        sx={{ zIndex: theme.zIndex.popover }}
      >
        <ClickAwayListener onClickAway={toggleMenu}>
          <Grow in={openSelect} timeout={500}>
            <Paper elevation={4} role="menu">
              <Typography
                sx={{
                  textTransform: "uppercase",
                  color: "primary.main",
                  p: 2,
                }}
              >
                {texts.groupBy}
              </Typography>
              <Divider />
              <FormControl
                sx={{ m: 2, color: "primary.main" }}
                variant="standard"
                size="small"
              >
                <RadioGroup
                  aria-labelledby="group-by-radio-group"
                  name="group-by"
                  value={currentOption?.value ?? ""}
                  onChange={onMenuItemChange}
                >
                  {options.map(({ title, value }) => (
                    <FormControlLabel
                      key={value}
                      value={value}
                      control={<Radio inputProps={{ role: "menuItem" }} />}
                      label={title}
                      labelPlacement="end"
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Paper>
          </Grow>
        </ClickAwayListener>
      </Box>
    </Box>
  );
};
