import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";

import { FirebaseContext } from "#shared/contexts/firebase";
import { LoggerService } from "#shared/services";

import { userNewState } from "./new-user";

export function useCheckAuthn() {
  const isAuthenticatedWithFn = useRecoilValue(userNewState.isAuthenticated);
  const { httpState: userHttpState } = useRecoilValue(userNewState.httpState);

  const { getAccount, isSignInWithEmailLink, firebase } =
    useContext(FirebaseContext);

  const resetFirebaseUser = useResetRecoilState(userNewState.firebaseUser);
  const isFirebaseUserSuccess = useRecoilValue(userNewState.firebase.isSuccess);

  const prevIsAuthenticatedWithFirebaseRef = useRef(isFirebaseUserSuccess);

  const isLoading = useRecoilValue(userNewState.isLoading);

  useEffect(() => {
    prevIsAuthenticatedWithFirebaseRef.current = !isFirebaseUserSuccess;
  }, [isFirebaseUserSuccess]);

  /**
   * NOTE: Sign in with session (iab.connect.sid or api.connect.sid).
   */
  const authenticateWithSession = useCallback(async () => {
    if (getAccount.result) {
      return;
    }

    const account = await getAccount.getAccount();

    if (!account) {
      LoggerService.debug("Failed to authenticate with session.");

      getAccount.reset(true, false);

      return;
    }

    LoggerService.debug("Authenticated with session.");

    /**
     * NOTE:
     * Do not include getAccount in dependencies because it should fire only once
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccount.result]);

  /**
   * NOTE
   *
   * Fetch FN account to check if we are authenticated with session (iab.connect.sid or api.connect.sid).
   */
  useEffect(() => {
    authenticateWithSession();
  }, [authenticateWithSession]);

  useEffect(() => {
    if (!isSignInWithEmailLink && resetFirebaseUser) {
      resetFirebaseUser();
    }
  }, [isSignInWithEmailLink, resetFirebaseUser]);

  const firebaseToken = useRecoilValue(userNewState.firebaseToken);

  useEffect(() => {
    if (!firebaseToken || getAccount.result) {
      return;
    }

    getAccount.getAccount(firebaseToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseToken, getAccount.result]);

  useEffect(() => {
    if (getAccount.httpState.error) {
      firebase?.signOut();
    }
  }, [getAccount.httpState.error, firebase]);

  /**
   * NOTE
   *
   * User firebase token to get the FN account from authn/iab.
   */
  useEffect(() => {
    if (!firebaseToken) {
      LoggerService.debug("Do not get account.", "Missing firebase token.");

      return;
    }

    if (userHttpState.isSuccess) {
      LoggerService.debug("Do not get account. It is already fetched.");

      return;
    }

    if (userHttpState.isLoading) {
      LoggerService.debug("Do not get account. It is being fetched.");

      return;
    }

    if (userHttpState.error) {
      LoggerService.debug("Account fetched.", "Error received.", {
        error: userHttpState.error,
      });

      return;
    }

    LoggerService.debug("Get account with firebase token.");

    getAccount.getAccount(firebaseToken);
  }, [getAccount, firebaseToken, isFirebaseUserSuccess, userHttpState]);

  const error = useMemo(
    () =>
      firebaseToken &&
      (getAccount.httpState.error || getAccount.httpState.isIdle),
    [firebaseToken, getAccount.httpState.isIdle, getAccount.httpState.error],
  );

  return useMemo(
    () => ({
      isLoading: isLoading && !error,
      isAuthenticatedWithFn,
    }),
    [isLoading, isAuthenticatedWithFn, error],
  );
}
