import { Check, InfoOutlined } from "@mui/icons-material";
import { Button, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import type { UseMutationResult } from "react-query";

import { CopyTextArea } from "#shared/components/inputs/copy-text-area";
import {
  DialogLayout,
  type DialogControl,
} from "#shared/components/layouts/dialog";
import type { AnyObject, Texts } from "#shared/types";

import { DOCUMENTATION_URLS } from "#organization/pages/consts";

export declare type GenerateAPIKeyDialogProps<
  TData = unknown,
  TVariable = unknown,
  TDataRevoke = unknown,
  TVariableRevoke = unknown,
> = {
  texts?: Texts;
  dialogControl: DialogControl;
  refetch: () => void;
  useGenerateAPIKey: () => UseMutationResult<TData, unknown, TVariable>;
  generateKeyHelperFunc: {
    mapToInput: (data: AnyObject) => TVariable;
    findKey: (data: TData) => string;
    findId: (data: TData) => string;
  };
  useRevokeKey: () => UseMutationResult<TDataRevoke, unknown, TVariableRevoke>;
  revokeKeyHelperFunc: {
    mapToInput: (data: AnyObject) => TVariableRevoke;
  };
  isOneLine?: boolean;
};

type Text =
  | "dialogTitle"
  | "dialogContentText"
  | "buttonConfirmText"
  | "checkDocumentation"
  | "checkDocumentation2"
  | "documentation"
  | "makeSureToCopy"
  | "keyDeleted";

export const enTextsGenerateApiKeyDialog: Texts<Text> = {
  dialogTitle: "New API Key",
  buttonConfirmText: "Got it",
  dialogContentText: "New API key has been created:",
  checkDocumentation: "Check our",
  checkDocumentation2: "for using API keys.",
  documentation: "documentation",
  makeSureToCopy:
    "Make sure to copy your key now; you won’t be able to retrieve it later!",
  keyDeleted: "The API key has been deleted.",
};

export type TextsToGenerateAPIKeyDialog = Texts<Text>;

const CLOSE_DIALOG_TIMEOUT = 300;

export const GenerateAPIKeyDialog = <
  TData = unknown,
  TVariable = unknown,
  TDataRevoke = unknown,
  TVariableRevoke = unknown,
>({
  dialogControl,
  refetch,
  useGenerateAPIKey,
  generateKeyHelperFunc: { mapToInput, findKey, findId },
  useRevokeKey,
  revokeKeyHelperFunc: { mapToInput: mapToInputRevoke },
  texts = enTextsGenerateApiKeyDialog,
  isOneLine = true,
}: GenerateAPIKeyDialogProps<
  TData,
  TVariable,
  TDataRevoke,
  TVariableRevoke
>): JSX.Element => {
  const [generatedKey, setGeneratedKey] = useState<string>("");
  const [keyId, setKeyId] = useState<string>("");
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isButtonLocked, setIsButtonLocked] = useState<boolean>(false);
  const generateApiKey = useGenerateAPIKey();
  const revokeKeyHook = useRevokeKey();

  useEffect(() => {
    if (dialogControl.isOpen) {
      generateApiKey.mutate(mapToInput({}), {
        onSuccess: (response) => {
          refetch();
          setGeneratedKey(findKey(response));
          setKeyId(findId(response));
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogControl.isOpen]);

  const closeDialog = () => {
    refetch();
    dialogControl.close();
    setGeneratedKey("");

    setTimeout(() => {
      setIsDelete(false);
    }, CLOSE_DIALOG_TIMEOUT);
  };

  const deleteApiKey = () => {
    setIsButtonLocked(true);

    revokeKeyHook.mutate(mapToInputRevoke({ id: keyId }), {
      onSuccess: () => {
        setIsButtonLocked(false);
        refetch();
      },
    });
  };

  const keyGeneratedLayout = (
    <Box>
      <Typography variant="body1" pb={1}>
        {texts.dialogContentText}
      </Typography>
      <CopyTextArea
        isOneLine={isOneLine}
        cliTextBody={generatedKey}
        cliTextContent={generatedKey}
      />
      <Box pt={1}>
        <Typography variant="body1" component="span">
          {texts.checkDocumentation}
        </Typography>
        <Link
          px={0.5}
          target="_blank"
          sx={{
            color: "text.primary",
            textDecorationColor: (theme) => theme.palette.text.primary,
            cursor: "pointer",
          }}
          href={DOCUMENTATION_URLS.API_KEYS}
        >
          {texts.documentation}
        </Link>
        <Typography variant="body1" component="span">
          {texts.checkDocumentation2}
        </Typography>
      </Box>

      <Box
        mt={1}
        sx={{
          background: (theme) =>
            `linear-gradient(0deg, ${
              theme.palette.mode === "dark"
                ? "rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),"
                : "rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),"
            } ${theme.palette.info.main}`,
          color: "info.main",
          padding: 2,
          borderRadius: 1,
          display: "flex",
          gap: 2,
        }}
      >
        <InfoOutlined />
        {texts.makeSureToCopy}
      </Box>

      <Box mt={7} display="flex" justifyContent="space-between">
        <Button
          variant="text"
          sx={{ color: (theme) => theme.palette.error.main }}
          onClick={() => {
            deleteApiKey();
            setIsDelete(true);
          }}
        >
          Delete key
        </Button>
        <Button variant="contained" endIcon={<Check />} onClick={closeDialog}>
          {texts.buttonConfirmText}
        </Button>
      </Box>
    </Box>
  );

  const keyDeletedLayout = (
    <Box>
      <Box
        mt={1}
        sx={{
          background: (theme) =>
            `linear-gradient(0deg, ${
              theme.palette.mode === "dark"
                ? "rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),"
                : "rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),"
            } ${theme.palette.info.main}`,
          color: "info.main",
          padding: 2,
          borderRadius: 1,
          display: "flex",
          gap: 2,
        }}
      >
        <InfoOutlined />
        {texts.keyDeleted}
      </Box>
      <Box mt={7} display="flex" justifyContent="end">
        <Button
          variant="contained"
          endIcon={<Check />}
          onClick={closeDialog}
          disabled={isButtonLocked}
        >
          {texts.buttonConfirmText}
        </Button>
      </Box>
    </Box>
  );

  return (
    <DialogLayout
      dialogTitle={texts.dialogTitle}
      dialogContentText=""
      onCloseDialog={dialogControl.close}
      openDialog={dialogControl.isOpen}
      onClickDialogClose={dialogControl.close}
      onConfimrDialog={() => {
        dialogControl.close();
      }}
      buttonConfirmText={texts.buttonConfirmText}
      buttonConfirmVariant="contained"
      buttonConfirmColor="primary"
      buttonCancelVariant="outlined"
      buttonCancelColor="primary"
      dialogAction={false}
      maxWidth="md"
    >
      {isDelete ? keyDeletedLayout : keyGeneratedLayout}
    </DialogLayout>
  );
};
