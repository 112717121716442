import { useCallback, useEffect, useMemo, useState } from "react";

import { LoggerService } from "#shared/services/logger";

import { useOrganizationState } from "#organization/recoil/organization";

import {
  useChangeDefaultUserGroupName,
  useWelcomeScreenProjectNameChange,
  useWelcomeScreenProjectDescriptionChange,
  useWelcomePageOnBoarding,
  useGetDefaultUserGroup,
  useGetDefaultProject,
  useWelcomeScreenProjectRegionChange,
} from "./hooks";

import {
  DEFAULT_PROJECT_CLOUD_PROVIDER,
  DEFAULT_PROJECT_REGION,
} from "../../settings/organization/projects/project-regions";
import type { NewProject } from "../types";

export interface UseSendAllRequestsOptions {
  userGroupName: string;
  project: NewProject;
  sendInvitations: (userGroupId: string) => Promise<void>;
  onResult: (result: SendAllRequestsResult, isSuccess: boolean) => void;
}

type SendAllRequestsResult = [
  changeUserGroupName: PromiseSettledResult<void>,
  changeProject: PromiseSettledResult<[void, void, void]>,
  onBoard: PromiseSettledResult<void>,
];

/**
 * NOTE:
 *
 * - change default user group name
 * - change default project name
 * - change default project description
 * - set boarding flag
 */
export function useSendAllRequests({
  userGroupName,
  project,
  onResult,
}: UseSendAllRequestsOptions) {
  const organizationState = useOrganizationState();
  const setBoardingFlag = useWelcomePageOnBoarding();
  const changeDefaultUserGroupName = useChangeDefaultUserGroupName();
  const changeDefaultProjectName = useWelcomeScreenProjectNameChange();
  const changeDefaultProjectDescription =
    useWelcomeScreenProjectDescriptionChange();
  const changeDefaultProjectCloudProviderAndRegion =
    useWelcomeScreenProjectRegionChange();

  const [result, setResult] = useState<SendAllRequestsResult | null>(null);

  const defaultUserGroup = useGetDefaultUserGroup();
  const defaultProject = useGetDefaultProject();

  const userGroupId = useMemo(
    () => defaultUserGroup.data?.id,
    [defaultUserGroup.data?.id],
  );

  const projectId = useMemo(
    () => defaultProject.data?.id,
    [defaultProject.data?.id],
  );

  useEffect(() => {
    if (!result) {
      return;
    }

    onResult(result, result.every(isFulfilled()));
  }, [result, onResult]);

  return useCallback(async () => {
    if (!userGroupId) {
      LoggerService.warn("Missing user group id.");

      return false;
    }

    if (!projectId) {
      LoggerService.warn("Missing project id.");

      return false;
    }

    const changeUserGroupNamePromise = changeDefaultUserGroupName.mutate({
      id: userGroupId,
      name: userGroupName || defaultUserGroup.data?.name || "DefaultUserGroup",
    });

    const changeProjectPromise = Promise.all([
      changeDefaultProjectName.mutate({
        id: projectId,
        name:
          project.name.trim() || defaultProject.data?.name || "DefaultProject",
      }),
      changeDefaultProjectDescription.mutate({
        id: projectId,
        description:
          project.description.trim() ||
          defaultProject.data?.description ||
          "Default Project",
      }),
      changeDefaultProjectCloudProviderAndRegion.mutate({
        id: projectId,
        cloud_provider: project.cloudProvider || DEFAULT_PROJECT_CLOUD_PROVIDER,
        region: project.region || DEFAULT_PROJECT_REGION,
      }),
    ]);

    const onboardPromise = setBoardingFlag.mutate({ id: organizationState.id });

    try {
      setResult(
        await Promise.allSettled([
          changeUserGroupNamePromise,
          changeProjectPromise,
          onboardPromise,
        ]),
      );

      return true;
    } catch (err) {
      LoggerService.error("error", err);

      return false;
    }
  }, [
    userGroupId,
    projectId,
    changeDefaultUserGroupName,
    userGroupName,
    defaultUserGroup.data?.name,
    changeDefaultProjectName,
    project.name,
    project.description,
    project.cloudProvider,
    project.region,
    defaultProject.data?.name,
    defaultProject.data?.description,
    changeDefaultProjectDescription,
    changeDefaultProjectCloudProviderAndRegion,
    setBoardingFlag,
    organizationState.id,
  ]);
}

function isFulfilled() {
  return ({ status }: SendAllRequestsResult[number]) => status === "fulfilled";
}
