import { get } from "lodash";
import { type Dispatch, type SetStateAction, useEffect } from "react";
import type {
  FieldValues,
  UseFormGetValues,
  UseFormReset,
} from "react-hook-form";

import { Analytics } from "#shared/analytics";
import { useAlert } from "#shared/components/alerts-provider";
import { LoggerService } from "#shared/services";

import {
  executeNotifierCreateMutation,
  executeNotifierUpdateMutation,
} from "./utils";

import {
  type AlertsMutationFunctions,
  type AlertsUpdateFunctions,
  useCreateAlertsChannel,
  useCreateAlertsEmailNotifier,
  useCreateAlertsSlackNotifier,
  useCreateAlertsWebhookNotifier,
  useDataCenterContext,
  useUpdateAlertsChannel,
  useUpdateAlertsEmailNotifier,
  useUpdateAlertsSlackNotifier,
  useUpdateAlertsWebhookNotifier,
} from "../../../data-center";

export type CallBackFncType = (status: boolean) => void;

export type SubmitFormType = (
  values: FieldValues,
  cb: CallBackFncType,
) => boolean;

export const useSubmitForm = () => {
  const { alertsOrganizationConfigID, projectID } = useDataCenterContext();
  const { createAlertsChannel } = useCreateAlertsChannel();
  const { createEmailNotifier } = useCreateAlertsEmailNotifier();
  const { createSlackNotifier } = useCreateAlertsSlackNotifier();
  const { createWebhookNotifier } = useCreateAlertsWebhookNotifier();

  const { updateAlertsChannel } = useUpdateAlertsChannel();
  const { updateAlertsEmailNotifier } = useUpdateAlertsEmailNotifier();
  const { updateAlertsSlackNotifier } = useUpdateAlertsSlackNotifier();
  const { updateAlertsWebhookNotifier } = useUpdateAlertsWebhookNotifier();

  const alertsUpdateFunctions: AlertsUpdateFunctions = {
    updateAlertsEmailNotifier,
    updateAlertsSlackNotifier,
    updateAlertsWebhookNotifier,
  };

  const alertsMutateFunctions: Omit<
    AlertsMutationFunctions,
    "createAlertsChannel"
  > = {
    createEmailNotifier,
    createSlackNotifier,
    createWebhookNotifier,
  };

  const filterValues = (
    newChannel: FieldValues,
    cb: CallBackFncType,
  ): boolean => {
    if (!newChannel?.length) {
      cb(false);

      return false;
    }

    if (!alertsOrganizationConfigID) {
      cb(false);

      return false;
    }

    return true;
  };

  const submitCreateForm: SubmitFormType = (values, cb) => {
    const { name, newChannel } = values;

    if (!filterValues(newChannel, cb)) return false;

    const createAlertsChannelInput = {
      alertsOrganizationConfig: alertsOrganizationConfigID,
      name,
      project: projectID,
    };

    createAlertsChannel(createAlertsChannelInput, {
      onSuccess: (res) => {
        const channelID = get(res, "createAlertsChannel.alertsChannel.id") as
          | string
          | undefined;

        if (!channelID) {
          LoggerService.debug("Missing channelID");

          return;
        }

        newChannel.forEach((notifier: FieldValues) => {
          executeNotifierCreateMutation(
            notifier,
            channelID,
            projectID,
            alertsMutateFunctions,
            cb,
          )();
        });
      },
      onError: (err) => {
        console.error(err);
        cb(false);
      },
    });

    return true;
  };

  const submitEditForm: SubmitFormType = (values, cb) => {
    const { name, newChannel, id } = values;

    if (!filterValues(newChannel, cb)) return false;

    updateAlertsChannel(
      {
        id,
        name,
      },
      {
        onSuccess: () => {
          newChannel.forEach((notifier: FieldValues) => {
            executeNotifierUpdateMutation(alertsUpdateFunctions, {
              notifier,
              alertsMutateFunctions,
              projectID,
              channelID: id,
              cb,
            })();
          });
          cb(true);
        },
        onError: (err) => {
          console.error(err);
          cb(false);
        },
      },
    );

    return true;
  };

  return { submitCreateForm, submitEditForm };
};

const FIELD_ARRAY_NAME = "newChannel";

export const useExecuteSubmitForm = (
  formMode: "create" | "update",
  getValues: UseFormGetValues<FieldValues>,
  reset: UseFormReset<FieldValues>,
  dataFlag: [string, Dispatch<SetStateAction<string>>],
): void => {
  const { submitCreateForm, submitEditForm } = useSubmitForm();
  const { addAlert } = useAlert();
  const [storeDataFlag, setStoreDataFlag] = dataFlag;

  const {
    reFetchFlag: { setFetchFlag },
  } = useDataCenterContext();

  useEffect(() => {
    const rawData = getValues();
    const values = {
      ...rawData,
      name: rawData.name?.replace("#", ""),
      newChannel: rawData.newChannel.map(
        ({ channel, ...other }: FieldValues) => ({
          channel: channel?.replace("#", ""),
          ...other,
        }),
      ),
    };

    if (
      storeDataFlag === "infoCollected" &&
      get(values, `${FIELD_ARRAY_NAME}[0].id`)
    ) {
      if (formMode === "update") {
        submitEditForm(values, (status: boolean) => {
          if (status) {
            setFetchFlag(true);

            addAlert({
              type: "success",
              message: "Channel updated successfully",
            });

            Analytics.track("alert_channel_updated", values);
          }

          if (!status) {
            addAlert({
              type: "error",
              message: "Error updating channel.",
            });
          }
        });
      }

      if (formMode === "create") {
        submitCreateForm(values, (status: boolean) => {
          if (status) {
            setFetchFlag(true);

            addAlert({
              type: "success",
              message: "Channel created successfully.",
            });

            reset();

            Analytics.track("alert_channel_created", values);
          }

          if (!status) {
            addAlert({
              type: "error",
              message: "Error creating channel.",
            });
          }
        });
      }
      setStoreDataFlag("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeDataFlag, getValues]);
};
