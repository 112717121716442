import { Tab, Tabs, type TabsProps } from "@mui/material";
import React, { type FC } from "react";

import type { Texts } from "#shared/types";
import { FEATURE_FLAGS } from "#shared/utils";

type Text =
  | "policyComponents"
  | "alerts"
  | "flowAnalytics"
  | "flowAnalyticsByLabel"
  | "flowMap";

export interface FlyoutMenuTabsProps extends TabsProps {
  activeTab: number;
  texts?: Texts<Text>;
  controlPointType: string;
}

export const enTexts: Required<FlyoutMenuTabsProps>["texts"] = {
  policyComponents: "Policy Components",
  alerts: "Triggering Alerts",
  flowAnalytics: "Flow Analytics",
  flowAnalyticsByLabel: "Analytics By Label",
  flowMap: "Flow Map",
};

export const FlyoutMenuTabs: FC<FlyoutMenuTabsProps> = ({
  activeTab,
  onChange,
  controlPointType,
  texts = enTexts,
}) => {
  const tabsLabels = [
    texts.flowAnalytics,
    texts.flowAnalyticsByLabel,
    controlPointType === "http" ? texts.flowMap : null,
    texts.alerts,
    ...(FEATURE_FLAGS.policyComponents ? [texts.policyComponents] : []),
  ].filter(Boolean); // Filter out null values

  return (
    <Tabs
      value={activeTab}
      onChange={onChange}
      sx={{ justifySelf: "flex-end" }}
    >
      {tabsLabels.map((label) => (
        <Tab label={label} key={label} />
      ))}
    </Tabs>
  );
};
