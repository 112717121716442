import React, { type FC } from "react";

import { useBoundaryDimensions } from "./hooks";
import { CircuitBoundaryStyled } from "./styled";

export const CircuitBoundary: FC = () => {
  const { dimensions, transform, hide } = useBoundaryDimensions();

  return (
    <CircuitBoundaryStyled
      transform={transform}
      dimensions={dimensions}
      hidden={hide}
    />
  );
};
