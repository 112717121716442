import { get, isEmpty, omit, omitBy } from "lodash";
import React, {
  useState,
  type ReactNode,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import {
  useFormContext,
  type FieldValues,
  type Path,
  type PathValue,
} from "react-hook-form";

import type { JSONObject } from "#shared/types";

import { useCreatePolicyContext } from "#organization/pages/authenticated/policies/create-policy/context";

import { SchedulerDefaultWorkloadParameters } from "./default-workload-parameters";
import { DynamicWorkloads } from "./dynamic-workloads";
import { StaticWorkloads } from "./static-workloads";

import * as QuotaSchedulerBlueprint from "../../quota-scheduling/@types";
import { SchedulerTemplate, type MethodNames } from "../../templates";
import { generateUpdatedBlueprintJson } from "../../utils";
import type { BlueprintFieldProps } from "../types";
import { createFieldNameWithPrefix } from "../utils";

type DynamicWorkloadsAddress =
  | "priority_label_key"
  | "tokens_label_key"
  | "workload_label_key"
  | "fairness_label_key";

export declare type SchedulerProps<
  TFields extends FieldValues = QuotaSchedulerBlueprint.Coordinate,
> = {
  dynamicWorkloadsAddress?: DynamicWorkloadsAddress[];
  staticWorkloadsAddress?: Path<TFields>;
} & BlueprintFieldProps<TFields>;

export declare type UseSchedulerProps<
  TFields extends FieldValues = QuotaSchedulerBlueprint.Coordinate,
> = Required<Pick<SchedulerProps<TFields>, "staticWorkloadsAddress">> & {
  schedulerValues: PathValue<TFields, Path<TFields>>;
  dynamicWorkloadsAddress: DynamicWorkloadsAddress[];
} & Required<Pick<BlueprintFieldProps<TFields>, "fieldsPrefix">>;

// true dynamic workloads (no static workloads)
export const useOmitAlternativeWorkloads = <TFields extends FieldValues>(
  p: UseSchedulerProps<TFields> & { selected: boolean },
) => {
  const { setCreatePolicyCtx } = useCreatePolicyContext();

  useEffect(() => {
    // selected static workloads
    if (!p.selected) {
      const value = omitBy<JSONObject>(p.schedulerValues, (_, key) =>
        p.dynamicWorkloadsAddress.includes(key as DynamicWorkloadsAddress),
      );

      if (isEmpty(value)) {
        return;
      }

      setCreatePolicyCtx((prev) => ({
        ...prev,
        updateException: () =>
          generateUpdatedBlueprintJson(
            value,
            prev.blueprintJson,
            p.fieldsPrefix,
            (v) => v,
          ),
      }));
    } else {
      // selected dynamic workloads
      const value = omit<JSONObject>(
        p.schedulerValues,
        p.staticWorkloadsAddress,
      );

      if (isEmpty(value)) {
        return;
      }

      setCreatePolicyCtx((prev) => ({
        ...prev,
        updateException: () =>
          generateUpdatedBlueprintJson(
            value,
            prev.blueprintJson,
            p.fieldsPrefix,
            (v) => v,
          ),
      }));
    }
  }, [
    p.dynamicWorkloadsAddress,
    p.fieldsPrefix,
    p.schedulerValues,
    p.selected,
    p.staticWorkloadsAddress,
    setCreatePolicyCtx,
  ]);
};

export const useScheduler = <TFields extends FieldValues>({
  staticWorkloadsAddress,
  schedulerValues,
  dynamicWorkloadsAddress,
  fieldsPrefix,
}: UseSchedulerProps<TFields>) => {
  const [selected, setSelected] = useState(true);

  const initializeSelectValue = useCallback(() => {
    const staticWorkloads = get(schedulerValues, staticWorkloadsAddress);

    setSelected(!staticWorkloads?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOmitAlternativeWorkloads({
    schedulerValues,
    dynamicWorkloadsAddress,
    staticWorkloadsAddress,
    selected,
    fieldsPrefix,
  });

  return {
    selected,
    setSelected,
    initializeSelectValue,
  };
};

export const Scheduler = <
  TFields extends FieldValues = QuotaSchedulerBlueprint.Coordinate,
>({
  fieldsPrefix = "policy.quota_scheduler.scheduler" as Path<TFields>,
  label = "Scheduler",
  dynamicWorkloadsAddress = [
    "priority_label_key",
    "tokens_label_key",
    "workload_label_key",
    "fairness_label_key",
  ],
  staticWorkloadsAddress = "workloads" as Path<TFields>,
}: SchedulerProps<TFields>) => {
  const { getValues } = useFormContext<TFields>();
  const { blueprintJson } = useCreatePolicyContext();

  // Prevents re-rendering as a result of new array creation
  const dynamicWorkloadsAddressConst = useRef(dynamicWorkloadsAddress);

  const schedulerValues = useMemo(
    () => get(blueprintJson, fieldsPrefix) || getValues(fieldsPrefix),
    [blueprintJson, fieldsPrefix, getValues],
  ) as PathValue<TFields, Path<TFields>>;

  const { selected, setSelected, initializeSelectValue } = useScheduler({
    dynamicWorkloadsAddress: dynamicWorkloadsAddressConst.current,
    staticWorkloadsAddress,
    schedulerValues,
    fieldsPrefix,
  });

  useEffect(() => {
    initializeSelectValue();
  }, [initializeSelectValue]);

  return (
    <SchedulerTemplate useSelection={[selected, setSelected]}>
      {(method) => (
        <>
          {methods[method]<TFields>({
            fieldsPrefix,
            label,
          })}
          <SchedulerDefaultWorkloadParameters<TFields>
            fieldsPrefix={createFieldNameWithPrefix(
              fieldsPrefix,
              "default_workload_parameters",
            )}
          />
        </>
      )}
    </SchedulerTemplate>
  );
};

type MethodFunc = <
  TFields extends FieldValues = QuotaSchedulerBlueprint.Coordinate,
>(
  p: SchedulerProps<TFields>,
) => ReactNode;

const methods: Record<MethodNames, MethodFunc> = {
  "Static workloads": (p) =>
    p.fieldsPrefix ? (
      <StaticWorkloads
        {...{
          ...p,
          fieldsPrefix: createFieldNameWithPrefix(p.fieldsPrefix, "workloads"),
        }}
      />
    ) : (
      <StaticWorkloads />
    ),
  "Dynamic workloads": (p) => <DynamicWorkloads {...p} />,
};
