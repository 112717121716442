import { gql } from "graphql-request";

import type {
  AgentsJobDataQuery,
  ControllersJobDataQuery,
} from "#shared/generated/graphql";

import { useGridData } from "#organization/hooks";

import type { AlertJobsProps } from "./tabs/alert-jobs";

type AgentsAlertJobData = AgentsJobDataQuery["agents"]["edges"][0]["node"];
type AdaptedAgentsAlertJobData = Omit<AgentsAlertJobData, "healthDetails"> & {
  healthDetails: string[];
};

type ControllersAlertJobData =
  ControllersJobDataQuery["controllers"]["edges"][0]["node"];
type AdaptedControllersAlertJobData = Omit<
  ControllersAlertJobData,
  "healthDetails"
> & {
  healthDetails: string[];
};

export const controllersJobData = gql`
  query ControllersJobData(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: ControllerBoolExp
    $orderBy: [ControllerOrderBy]
  ) {
    controllers(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          lastSeen
          healthDetails
        }
      }
    }
  }
`;

export const agentsJobData = gql`
  query AgentsJobData(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: AgentBoolExp
    $orderBy: [AgentOrderBy]
  ) {
    agents(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          lastSeen
          healthDetails
        }
      }
    }
  }
`;

export const useAlertJobs = (
  { id: name, type }: AlertJobsProps["data"],
  order = "desc",
  orderBy = "lastSeen",
) => {
  const entityKey = type === "controller" ? "controllers" : "agents";
  const query = type === "controller" ? controllersJobData : agentsJobData;
  const pageSize = 1;
  const page = 1;
  const setPage = () => {};
  const filterVariables = { id: { eq: name } };

  const updateHealthDetails = <T extends { healthDetails: string }>(
    data: T,
  ) => ({
    ...data,
    healthDetails:
      data.healthDetails
        ?.split(";")
        .map((h) => h.trim())
        .filter(Boolean) ?? [],
  });

  const controllerAlertJobs = useGridData<
    ControllersAlertJobData,
    AdaptedControllersAlertJobData
  >(
    entityKey,
    query,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    filterVariables,
    updateHealthDetails,
    type === "controller",
  );

  const agentAlertJobs = useGridData<
    AgentsAlertJobData,
    AdaptedAgentsAlertJobData
  >(
    entityKey,
    query,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    filterVariables,
    updateHealthDetails,
    type === "agent",
  );

  return type === "controller" ? controllerAlertJobs : agentAlertJobs;
};
