import { gql } from "graphql-request";
import { useRecoilValue } from "recoil";

import type {
  AddUserGroupToProjectMutation,
  AddUserGroupToProjectMutationVariables,
} from "#shared/generated/graphql";
import { PermissionEnum, userNewState } from "#shared/recoil";
import { useGqlMutation } from "#shared/utils";

const addUserGroupToProjectMutation = gql`
  mutation addUserGroupToProject(
    $input: CreateUserGroupProjectAssignmentInput!
  ) {
    addUserGroupToProject(input: $input) {
      userGroupProjectAssignment {
        id
        project {
          name
        }
      }
    }
  }
`;

export const useAddUserGroupToProjectMutation = () => {
  const { data, isLoading, isError, mutate, mutateAsync } = useGqlMutation<
    AddUserGroupToProjectMutation,
    unknown,
    AddUserGroupToProjectMutationVariables["input"],
    unknown
  >(addUserGroupToProjectMutation);

  return {
    addUserGroupToProject: mutate,
    asyncAddUserGroupToProject: mutateAsync,
    data,
    error: isError,
    loading: isLoading,
  };
};

export function useIsAuthnSettings() {
  const userRole = useRecoilValue(userNewState.userRole)!;

  return PermissionEnum[userRole] >= PermissionEnum.owner;
}
