import { Box } from "@mui/material";
import React, { useContext, type FC, useState, useRef, useEffect } from "react";
import { UNSAFE_NavigationContext, useLocation } from "react-router-dom";

import {
  ErrorLayout,
  ErrorText,
  LoaderLayout,
} from "#shared/components/layouts";
import { useLocalStorageFilters } from "#shared/hooks";
import { LoggerService } from "#shared/services";

import { GRAFANA_PORTAL_DIV } from "../consts";
import { useGrafanaContext, type GrafanaPortal } from "../grafana-context";
import {
  usePortalKey,
  useRootAppStatus,
  useValidateDashboardUid,
} from "../hooks";

export interface LoadDashboardPortalProps extends GrafanaPortal {}

const DEFAULT_ERROR_MESSAGE = `Our analytics and dashboard API is currently unavailable. Please refresh the browser to try again`;

export const LoadDashboardPortal: FC<LoadDashboardPortalProps> = ({
  uid,
  slug,
  queryParams,
  hiddenVariables = [],
  version = undefined,
  controlsContainer = null,
}) => {
  const { action, grafanaError, isGrafanaBroken } = useGrafanaContext();
  const { portalKey } = usePortalKey();

  const { navigator } = useContext(UNSAFE_NavigationContext);
  /**
   * There are scenarios in ui where pathname get changed but uid of dashboard remains same.
   * In such case, grafana get confused and dashboard doesn't get updated. Pathname is added as a key which unmount and mount portal
   * with updated dashboard.
   */
  const { pathname } = useLocation();

  const { filters } = useLocalStorageFilters(); // filters applied to the ui table
  const isError = useValidateDashboardUid(uid);

  const { checkRootAppMountStatus } = useRootAppStatus();
  const [isRootAppError, setIsRootAppError] = useState(false);
  const [isRootAppLoading, setIsRootAppLoading] = useState(false);

  const { current: currentVersion } = useRef(version);

  useEffect(
    () => () => {
      navigator.replace({ search: createSearchParams(filters).toString() });

      if (!action) return;

      action({
        type: "RESET_GRAFANA_PORTAL",
      });
    },
    [filters, navigator, action, controlsContainer],
  );

  useEffect(() => {
    if (!action || !uid.length || !slug.length) {
      LoggerService.warn("Missing uid | slug | action");

      return;
    }

    checkRootAppMountStatus({
      onSuccess: () => {
        setIsRootAppError(false);
        setIsRootAppLoading(false);

        action({
          type: "SET_GRAFANA_PORTAL",
          payload: {
            uid,
            slug,
            queryParams,
            hiddenVariables: sendableHiddenVariables(hiddenVariables),
            version: currentVersion,
            controlsContainer,
          },
        });
      },
      onError: () => {
        setIsRootAppError(true);
        setIsRootAppLoading(false);
      },
      isLoading: () => {
        setIsRootAppError(false);
        setIsRootAppLoading(true);
      },
    });
  }, [
    action,
    checkRootAppMountStatus,
    controlsContainer,
    currentVersion,
    hiddenVariables,
    queryParams,
    slug,
    uid,
  ]);

  useEffect(() => {
    if (grafanaError) LoggerService.warn({ grafanaError });
  }, [grafanaError]);

  if (isRootAppLoading && !isRootAppError && !isGrafanaBroken) {
    return <LoaderLayout />;
  }

  return (
    <Box>
      {isError || !uid || isRootAppError || isGrafanaBroken ? (
        <ErrorLayout>
          <ErrorText
            texts={{
              defaultError: DEFAULT_ERROR_MESSAGE,
            }}
          />
        </ErrorLayout>
      ) : (
        <Box
          id={GRAFANA_PORTAL_DIV}
          key={[pathname, uid, portalKey, queryParams?.resourceId || ""].join(
            "-",
          )}
        />
      )}
    </Box>
  );
};

export const sendableHiddenVariables = (variables: string[]) =>
  variables?.map((hv) => hv.replace("var-", ""));

export const createSearchParams = (
  loadableSearchParams: Record<string, string>,
) => new URLSearchParams(loadableSearchParams);
