import {
  IDP_PROXY_PATHNAME,
  API_SERVICE_URL,
  IAB_PROXY_PATHNAME,
  API_SERVICE_URL_WS,
} from "#shared/consts";
import { ENV } from "#shared/env";

export const API_URLS = {
  TOKEN: {
    INDEX: `${API_SERVICE_URL}/idp/token/custom-token`, // fetch custom token from api for current user
  },
  ORGANIZATIONS: {
    INDEX: [API_SERVICE_URL, "/organizations/user"].join(""),
    MEMBERSHIPS: [API_SERVICE_URL, "/organizations/memberships/user"].join(""),
    BY_ID: [API_SERVICE_URL, "/organizations/user/:id"].join(""),
    PROVISION: [API_SERVICE_URL, "/organizations/provision"].join(""),
    PROVISIONING_STATUS: [
      API_SERVICE_URL,
      "/organizations/check-provisioning-status",
    ].join(""),
    WS_WORKFLOW_STATUS: [
      API_SERVICE_URL_WS,
      "/ws",
      "/check-workflow-status",
    ].join(""),
  },
  SIGN_UP: {
    GET_CODE: [API_SERVICE_URL, IDP_PROXY_PATHNAME, "/register/code"].join(""),
    POST_CODE: [API_SERVICE_URL, IDP_PROXY_PATHNAME, "/register/code"].join(""),
  },
  SIGN_IN: {
    GET_CODE: [API_SERVICE_URL, IDP_PROXY_PATHNAME, "/login/code"].join(""),
    POST_CODE: [API_SERVICE_URL, IDP_PROXY_PATHNAME, "/login/code"].join(""),
  },
  CUSTOM_PLAN: {
    POST: [`${API_SERVICE_URL}/custom-plan`].join(""),
  },
  BILLING: {
    SUBSCRIPTIONS: [API_SERVICE_URL, "/billing/subscriptions"].join(""),
    ITEMS: [API_SERVICE_URL, "/billing/items"].join(""),
    ITEMS_PRICES: [API_SERVICE_URL, "/billing/items-prices"].join(""),
    SUBSCRIPTION: [API_SERVICE_URL, "/billing/subscription"].join(""),
    // TODO: move to shared
    CREATE_PORTAL_SESSION: [
      API_SERVICE_URL,
      ENV.VITE_APP === "organization" ? `/auth${IAB_PROXY_PATHNAME}` : "",
      "/billing/create-portal-session",
    ].join(""),
    CREATE_CHECKOUT_HOSTED_PAGE: [
      API_SERVICE_URL,
      "/billing/create-checkout-hosted-page",
    ].join(""),
    CREATE_SUBSCRIPTION: [API_SERVICE_URL, "/billing/create-subscription"].join(
      "",
    ),
  },
};

export const GLOBAL_LOCAL_STORAGE_KEYS = {
  INVITATION_TOKEN: "invitationToken",
};

export const BASE_FLUXNINJA_URL = "https://fluxninja.com";

export const QUERY_KEYS = {
  CHECK_PROVISIONING_STATUS: "check-provisioning-status",
  CUSTOM_TOKEN: "custom-token",
  NEW_PROJECT_ID: "new-project-id",
  CREATE_SUBSCRIPTION: "create-subscription",
};
