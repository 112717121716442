import { type ThemeOptions, typographyClasses } from "@mui/material";

import type { FnTheme } from "../../types";

const styleOverrides: Required<
  Required<ThemeOptions>["components"]
>["MuiTooltip"]["styleOverrides"] = {
  tooltip: (options) => {
    const { palette } = options.theme as unknown as FnTheme;

    return {
      backgroundColor: palette.background?.tooltip,
      color: palette.text.primary,
      borderRadius: "4px",
      padding: "8px",
      lineHeight: "1.4",
      maxWidth: "400px",
      fontSize: "0.8rem",
      [`.${typographyClasses.h6}`]: {
        fontSize: "1.2rem",
      },
      [`.${typographyClasses.body1}`]: {
        fontSize: "1rem",
      },
    };
  },
  arrow: (options) => {
    const { palette } = options.theme as unknown as FnTheme;

    return {
      color: palette.background?.tooltip,
    };
  },
};

export const MuiTooltip: Required<
  Required<ThemeOptions>["components"]
>["MuiTooltip"] = {
  styleOverrides,
};
