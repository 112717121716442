import { Box, type BoxProps } from "@mui/material";
import { styled } from "@mui/system";
import React, { type FC } from "react";

const MainDiv = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: "100%",
  width: "100%",
  display: "grid",
  gridTemplateAreas: "'top-bar top-bar' 'side-bar child'",
  gridTemplateColumns: "auto 1fr",
  gridTemplateRows: `${theme.spacing(8)} 1fr`,
}));

export const LayoutComponent: FC<BoxProps> = ({ children }) => (
  <MainDiv>{children}</MainDiv>
);
