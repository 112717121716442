import { Box, Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import { emptyColumnValue, type HeadCell } from "#shared/components/data-grid";
import { HealthStatus } from "#shared/components/data-grid/components/health-status";
import { FeatureFlag } from "#shared/utils";

import { ProjectProvisioningStatus } from "./project-provisioning-status";
import { ProjectSubscriptionStatus } from "./project-subscription-status";

import type { AdaptedProjects } from "../../hooks-new";

export const headCells = (
  permissionLock: boolean,
  handleOpenConfirmDialogToRemove: (id: string, name: string) => void,
  handleOpenConfirmDialogToPause: (id: string, name: string) => void,
  handleOpenConfirmDialogToResume: (id: string, name: string) => void,
  isLastProject?: boolean,
): HeadCell<AdaptedProjects>[] => [
  {
    accessor: (row) => (
      <Box display="flex" gap={2} alignItems="center">
        {row.name || emptyColumnValue}{" "}
        <ProjectProvisioningStatus projectID={row.id} />
        <FeatureFlag
          flag="globalBilling"
          renderOn={<ProjectSubscriptionStatus projectID={row.id} />}
        />
      </Box>
    ),
    label: "Project Name",
    key: "name",
  },
  {
    accessor: (row) => row.cloudProvider.toString().toUpperCase(),
    label: "Project Cloud Provider",
    key: "cloud_provider",
  },
  {
    accessor: (row) => row.region.toString(),
    label: "Project Region",
    key: "region",
  },
  {
    accessor: ({ status }) => <HealthStatus status={status} />,
    label: "Project Status",
    key: "status",
  },
  {
    accessor: (row) => row.userGroupCount.toString(),
    label: "Assigned User Groups",
    key: "userGroupCount",
  },
  {
    accessor: (row) => (
      <>
        <Link to={row.id}>
          <Button variant="text" size="small">
            Settings
          </Button>
        </Link>
        {row.status !== "3-paused" ? (
          <Button
            variant="text"
            size="small"
            color="error"
            disabled={permissionLock}
            onClick={() => {
              handleOpenConfirmDialogToPause(row.id, row.name);
            }}
          >
            Pause
          </Button>
        ) : (
          <Button
            variant="text"
            size="small"
            sx={(theme) => ({ color: theme.palette.text.primary })}
            disabled={permissionLock}
            onClick={() => {
              handleOpenConfirmDialogToResume(row.id, row.name);
            }}
          >
            Resume
          </Button>
        )}
        <Button
          variant="text"
          size="small"
          color="error"
          disabled={permissionLock || isLastProject}
          onClick={() => {
            handleOpenConfirmDialogToRemove(row.id, row.name);
          }}
        >
          Delete
        </Button>
      </>
    ),
    label: "Actions",
    key: "actions",
    noSorting: true,
    width: "300px",
  },
];
