import {
  ListItem,
  ListItemButton,
  type ListItemProps,
  ListItemText,
  type ListItemTextProps,
} from "@mui/material";
import { Box, type SxProps, type Theme } from "@mui/system";
import React, { type FC, type PropsWithChildren } from "react";

import { TextLink } from "#shared/components/text-link";

export type ListItemRowProps = PropsWithChildren<{
  to?: string;
  onClick?: () => void;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  disableRipple?: boolean;
  sx?: SxProps<Theme>;
  listItemProps?: Omit<ListItemProps, "children">;
  listItemTextProps?: ListItemTextProps;
}>;

export const MenuListItemRow: FC<ListItemRowProps> = ({
  to = "",
  onClick,
  startIcon,
  endIcon,
  children,
  disableRipple,
  sx = { minHeight: "48px" },
  listItemProps,
  listItemTextProps,
}) => (
  <ListItem disableGutters disablePadding {...listItemProps}>
    <Box
      component={TextLink}
      to={to}
      onClick={onClick}
      width="100%"
      sx={{ textDecoration: "none" }}
    >
      <ListItemButton disableRipple={disableRipple} sx={sx}>
        {startIcon}
        <ListItemText
          {...listItemTextProps}
          sx={{ color: "text.primary", ...listItemTextProps?.sx }}
        />
        {endIcon}
      </ListItemButton>
    </Box>
    {children}
  </ListItem>
);
