import { Button, type ButtonProps } from "@mui/material";
import React, { type FC } from "react";

export type BreadcrumbButtonProps = ButtonProps;

export const BreadcrumbButton: FC<BreadcrumbButtonProps> = (props) => (
  <Button
    variant="text"
    size="small"
    color="inherit"
    disableRipple
    {...props}
  />
);
