import { Button, type ButtonProps, Link, type LinkProps } from "@mui/material";
import React, { type FC } from "react";

type DocumentationButtonProps = LinkProps & {
  disabled?: boolean;
  buttonProps?: Omit<ButtonProps, "children" | "disabled">;
};

export const DocumentationButton: FC<DocumentationButtonProps> = ({
  children,
  buttonProps,
  disabled,
  ...linkProps
}) => {
  const button = (
    <Button
      sx={{ borderRadius: "4px", width: "fit-content" }}
      variant="outlined"
      {...{ ...buttonProps, disabled }}
    >
      {children}
    </Button>
  );

  return (
    <>
      {!disabled ? (
        <Link
          {...{
            sx: { textDecoration: "none", color: "inherit" },
            ...safeBlank({ target: "_blank", ...linkProps }),
          }}
        >
          {button}
        </Link>
      ) : (
        button
      )}
    </>
  );
};

function safeBlank<P extends Pick<LinkProps, "target" | "rel">>(props: P): P {
  const { target } = props;

  return target === "_blank" ? { ...props, rel: "noopener noreferrer" } : props;
}
