import { CodeSnippet, CodeBlockWithCopyButton } from "#organization/components"

export const language = "ts";

# Concurrency Limiting Blueprint

## Overview

This blueprint provides a concurrency-limiting policy. This policy uses the ConcurrencyLimiter component.

Concurrency limiting is essential for managing simultaneous request processing in an API. By restricting the number of concurrent requests, it prevents a few users from abusing the service, ensuring fair access for all API users.

To create a valid concurrency limiting policy, it is essential to fill all the required fields.

## Example

Here is a code example:

<CodeSnippet lang={language} snippetName="handleConcurrencyLimit" />

This concurrency limiter component takes the input for maximum allowed concurrency value, permitting users to make up to those many simultaneous requests before getting limited. The concurrency limit is reset when the flow is ended or the `max_inflight_duration` is exceeded. The limit is set on the request based on the value given with `limit_by_label_key`. However, if `limit_by_label_key` is not provided, the limit is imposed for all requests. For further information about each component of the concurrency limiter, hover over them to see examples and detailed explanations.

## Note

The concurrency limiting policy must be tailored to align with your specific business requirements and the capacity of your system. The primary objective is to maintain fair access for all API users and safeguards the system from unexpected surges by limiting API abusers.
