import { Button, MenuItem, Select } from "@mui/material";
import { uniqBy } from "lodash";
import React, { useState, type Dispatch, type SetStateAction } from "react";

import type { HeadCell } from "#shared/components/data-grid";
import type { UpdateUserGroupProjectAssignmentPermissionsInput } from "#shared/generated/graphql";

import type { ProjectUserGroupsData } from "./types";

export const headCells = (
  permissionLock: boolean,
  onRemoveUserGroup: (id: string) => void,
): HeadCell<ProjectUserGroupsData>[] => [
  {
    accessor: (row) => row.name,
    label: "User Group",
    key: "user group",
  },
  {
    accessor: (row) => row.membersCount.toString(),
    label: "Users",
    key: "users",
  },
  {
    accessor: (row) => (
      <Button
        variant="text"
        size="small"
        disabled={permissionLock}
        onClick={() => onRemoveUserGroup(row.groupAssignmentID)}
      >
        Unassign from Project
      </Button>
    ),
    label: "Actions",
    key: "actions",
    noSorting: true,
    width: "260px",
  },
];

export type ChangeUserGroupPermissionProps<
  TRow extends {
    permissions: string | null;
    groupAssignmentID: string | null;
  } = ProjectUserGroupsData,
> = {
  setUpdateUserGroupPermission: Dispatch<
    SetStateAction<UpdateUserGroupProjectAssignmentPermissionsInput[] | null>
  >;
  permissionLock: boolean;
  row: TRow;
};

export const ChangeUserGroupPermission = <
  TRow extends {
    permissions: string | null;
    groupAssignmentID: string | null;
  } = ProjectUserGroupsData,
>({
  setUpdateUserGroupPermission,
  permissionLock,
  row,
}: ChangeUserGroupPermissionProps<TRow>) => {
  const [permission, setPermission] = useState("");

  return (
    <Select
      disabled={permissionLock}
      size="small"
      value={permission || row?.permissions || ""}
      onChange={({ target: { value } }) => {
        setPermission(value);

        const { groupAssignmentID, permissions } = row || {};

        if (!groupAssignmentID || !permissions) {
          return;
        }

        setUpdateUserGroupPermission((prev) => {
          if (!prev?.length) {
            return [
              {
                id: row.groupAssignmentID || "",
                permissions: value || "",
              },
            ];
          }

          return uniqBy(
            [...prev, { id: row.groupAssignmentID || "", permissions: value }],
            (p) => p.id,
          );
        });
      }}
      MenuProps={{
        disableScrollLock: true,
      }}
      sx={(theme) => ({
        width: 200,
        textTransform: "uppercase",
        "& .MuiSvgIcon-root": {
          color: theme.palette.text.primary,
        },
      })}
    >
      {["read", "write"].map((p, i) => (
        <MenuItem
          key={[p, i].join("-")}
          value={p}
          sx={{ textTransform: "uppercase" }}
        >
          {p}
        </MenuItem>
      ))}
    </Select>
  );
};
