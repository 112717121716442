import { Alert, type AlertColor, Box, useTheme } from "@mui/material";
import React, {
  type FC,
  useEffect,
  useState,
  createContext,
  useCallback,
  useContext,
  useMemo,
  type PropsWithChildren,
} from "react";
import { createPortal } from "react-dom";

import type { FnTheme } from "../../../../app-theme-provider/types";

export type AlertContent = {
  type: AlertColor | undefined;
  message: string;
};

interface AlertType {
  id: number;
  content: AlertContent;
}

interface AlertContextValue {
  addAlert: (content: AlertContent) => void;
  removeAlert: (alertId: number) => void;
}

const AlertContext = createContext<AlertContextValue>({
  addAlert: () => {},
  removeAlert: () => {},
});
export const useAlert = () => useContext(AlertContext);

export const AlertsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [alerts, setAlerts] = useState<AlertType[]>([]);
  const [id, setId] = useState<number>(0);

  const addAlert = useCallback<AlertContextValue["addAlert"]>(
    (content) => {
      setId(id + 1);
      setAlerts((old) => [...old, { id, content }]);
    },
    [setAlerts, setId, id],
  );

  const removeAlert = useCallback<AlertContextValue["removeAlert"]>(
    (alertId) => {
      setAlerts((toast) => toast.filter((t) => t.id !== alertId));
    },
    [setAlerts],
  );

  const value = useMemo(
    () => ({ addAlert, removeAlert }),
    [addAlert, removeAlert],
  );

  return (
    <AlertContext.Provider value={value}>
      <AlertContainer alerts={alerts} />
      {children}
    </AlertContext.Provider>
  );
};

interface AlertContainerProps {
  alerts: AlertType[];
}

export const AlertContainer: FC<AlertContainerProps> = ({ alerts }) => {
  const theme = useTheme() as FnTheme;

  return createPortal(
    <Box
      sx={{
        position: "fixed",
        right: "16px",
        bottom: "8px",
        display: "grid",
        rowGap: 1,
        width: "20vw",
        zIndex: theme.zIndex.alert,
      }}
    >
      {alerts.map((alert, i) => (
        <AlertBox
          alert={alert.content}
          alertId={alert.id}
          key={[alert.id, alert.content.message, i].join("-")}
        />
      ))}
    </Box>,
    document.body,
  );
};

interface AlertBoxProps {
  alert: AlertContent;
  alertId: number;
}

const TIMEOUT = 3000;

export const AlertBox: FC<AlertBoxProps> = ({ alert, alertId }) => {
  const { removeAlert } = useAlert();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeAlert(alertId);
    }, TIMEOUT);

    return () => {
      clearTimeout(timer);
    };
  }, [alertId, removeAlert]);

  return <Alert severity={alert.type}>{alert.message}</Alert>;
};
