import { gql } from "graphql-request";

import type {
  GlobalCacheGroupsTitlesQuery,
  GlobalCacheQuery,
} from "#shared/generated/graphql";

import {
  useGridData,
  useGroupData,
  useGroupsTitles,
  type DataGridHook,
  type GroupDataHook,
  type GroupsTitlesHook,
} from "#organization/hooks";

export type GlobalCacheGroupBy = "controller";

const RESULT_CACHE_ENTITY_KEY = "services";

export const globalCacheGroupsTitles = gql`
  query GlobalCacheGroupsTitles(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: ServiceBoolExp
    $distinctOn: [ServiceSelectColumn!]
    $orderBy: [ServiceOrderBy]
  ) {
    services(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      distinctOn: $distinctOn
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          status
          agentGroup
          controller {
            id
            name
          }
        }
      }
    }
  }
`;

type GlobalCacheGroupsTitleData =
  GlobalCacheGroupsTitlesQuery["services"]["edges"][0]["node"];

export const useGlobalCacheGroupsTitles: GroupsTitlesHook<
  GlobalCacheGroupBy
> = (pageSize, page, setPage, groupByKey, filterVariables) =>
  useGroupsTitles<GlobalCacheGroupBy, GlobalCacheGroupsTitleData>(
    RESULT_CACHE_ENTITY_KEY,
    pageSize,
    page,
    setPage,
    groupByKey,
    globalCacheGroupsTitles,
    (node) => node[groupByKey!]?.id,
    filterVariables,
    true,
    true,
    undefined,
    { distinctOn: ["agentGroup"] },
  );

type GlobalCacheData = GlobalCacheQuery["services"]["edges"][0]["node"];

export type AdaptedGlobalCacheData = GlobalCacheData;

export const globalCacheQuery = gql`
  query GlobalCache(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: ServiceBoolExp
    $distinctOn: [ServiceSelectColumn!]
    $orderBy: [ServiceOrderBy]
  ) {
    services(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      distinctOn: $distinctOn
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          status
          agentGroup
          controller {
            id
            name
          }
        }
      }
    }
  }
`;

export const useGlobalCacheGroupData: GroupDataHook<GlobalCacheGroupBy> = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  groupByKey,
  groupByValue,
  filterVariables,
) =>
  useGroupData<GlobalCacheGroupBy, GlobalCacheData, AdaptedGlobalCacheData>(
    RESULT_CACHE_ENTITY_KEY,
    globalCacheQuery,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    groupByKey,
    groupByValue,
    (controlPoints: GlobalCacheData) => ({
      ...controlPoints,
    }),
    filterVariables,
    undefined,
    true,
    true,
    undefined,
    { distinctOn: ["agentGroup"] },
  );

export const useGlobalCacheData: DataGridHook = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  enabled,
  filterVariables,
) =>
  useGridData<GlobalCacheData, AdaptedGlobalCacheData>(
    RESULT_CACHE_ENTITY_KEY,
    globalCacheQuery,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    filterVariables,
    (controlPoints: GlobalCacheData) => ({
      ...controlPoints,
    }),
    enabled,
    true,
    undefined,
    { distinctOn: ["agentGroup"] },
  );
