import React, { type FC, useEffect, type PropsWithChildren } from "react";
import { useRecoilState } from "recoil";

import { sidebarState } from "./apps/organization/recoil/sidebar";
import { themeState } from "./apps/organization/recoil/theme";

export const AppLocalStorageVariablesProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [, setTheme] = useRecoilState(themeState);
  const [, setSidebar] = useRecoilState(sidebarState);

  useEffect(() => {
    const theme = window.localStorage.getItem("theme");

    if (theme) {
      if (theme === "light") setTheme({ mode: "light" });

      if (theme === "dark") setTheme({ mode: "dark" });
    }
  }, [setTheme]);

  useEffect(() => {
    const sidebar = window.localStorage.getItem("sidebar");

    if (sidebar) {
      if (sidebar === "closed") setSidebar("closed");

      if (sidebar === "small") setSidebar("small");

      if (sidebar === "large") setSidebar("large");
    }
  }, [setSidebar]);

  return <>{children}</>;
};
