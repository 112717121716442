import { CodeSnippet, CodeBlockWithCopyButton } from "#organization/components"

export const language = "ts";

# Limit By Label Key

## Overview

The `limit_by_label_key` determines the specific label key used for enforcing rate limits.

## Example

For instance, `limit_by_label_key` can be set as `user_id`. This way, each user will have its own token bucket and, if the `StartFlow` call contains the `user_id`, Aperture will rate limit on a per user basis.

<CodeSnippet lang={language} snippetName="RLStartFlow" />

## Note

In the absence of a specified `limit_by_label_key`, rate limiting will default to a global bucket for all requests matching the criteria. Defining a label key allows for more granular rate limiting per user or per device.
