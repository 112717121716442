import { cloneDeep, remove, update } from "lodash";
import { useMemo, useState, type Dispatch, type SetStateAction } from "react";
import { get, type FieldValues } from "react-hook-form";

import type { AnyObject } from "#shared/types";

import type { BlueprintFieldProps } from "./types";

import { convertToYaml, useCreatePolicyContext } from "../../../../context";

type UseArrayFieldsProps<TFields extends FieldValues> = Required<
  Pick<BlueprintFieldProps<TFields>, "fieldsPrefix">
> & {
  defaultValues: AnyObject;
};

export const useArrayFields = <TFields extends FieldValues>({
  fieldsPrefix,
  defaultValues,
}: UseArrayFieldsProps<TFields>) => {
  const { blueprintJson, setCreatePolicyCtx, editor } =
    useCreatePolicyContext();
  const fieldValues = useMemo(
    () => get(blueprintJson, fieldsPrefix),
    [blueprintJson, fieldsPrefix],
  );
  const [fieldList, setFieldList] = useState<string[]>(
    Array.from({ length: fieldValues?.length || 1 }, (_, i) => `${i}`),
  );

  const addNewFields = () => {
    const blueprintCopy = cloneDeep(blueprintJson);
    const arr = get(blueprintCopy, fieldsPrefix) || [];

    if (!blueprintCopy || !Array.isArray(arr)) {
      return;
    }

    arr.push(defaultValues);

    update(blueprintCopy, fieldsPrefix, () => arr);

    const yaml = convertToYaml(blueprintCopy);

    setCreatePolicyCtx((prev) => ({
      ...prev,
      blueprintJson: blueprintCopy,
      blueprintYaml: yaml,
    }));

    editor?.setValue(yaml);

    setFieldList((prev) => [...prev, `${prev.length}`]);
  };

  return {
    fieldList,
    addNewFields,
    fieldValues,
    setFieldList,
  };
};

type UseOnClickDeleteFieldsProps<TFields extends FieldValues> = Required<
  Pick<BlueprintFieldProps<TFields>, "fieldsPrefix">
> & {
  index: number;
  setFieldList: Dispatch<SetStateAction<string[]>>;
};

export const useOnClickDeleteFields = <TFields extends FieldValues>({
  fieldsPrefix,
  index,
  setFieldList,
}: UseOnClickDeleteFieldsProps<TFields>) => {
  const { setCreatePolicyCtx, blueprintJson, editor } =
    useCreatePolicyContext();

  const onClickDeleteFields = () => {
    const blueprintCopy = cloneDeep(blueprintJson);
    const arr = get(blueprintCopy, fieldsPrefix);

    remove(arr, (_, i) => i === index);

    if (!blueprintCopy) {
      return;
    }

    update(blueprintCopy, fieldsPrefix, () => arr);

    const yaml = convertToYaml(blueprintCopy);

    editor?.setValue(yaml);

    setCreatePolicyCtx((prev) => ({
      ...prev,
      blueprintJson: blueprintCopy,
      blueprintYaml: yaml,
      miniEditors: prev.miniEditors?.filter((_, i) => i !== index) || null,
    }));

    setFieldList((prev) => prev.filter((_, i) => i !== index));
  };

  return onClickDeleteFields;
};
