import { useCallback } from "react";
import { useReactFlow } from "react-flow-renderer";

const DURATION = 800;

const INITIAL_VIEWPORT_X = 50;
const INITIAL_VIEWPORT_Y = 20;

export const useInitialViewport = () => {
  const { setViewport } = useReactFlow();

  const applyInitialViewport = useCallback(
    (edgesLength: number) => {
      setViewport(
        {
          zoom: setZoomLevelByEdgesLength(edgesLength),
          x: INITIAL_VIEWPORT_X,
          y: INITIAL_VIEWPORT_Y,
        },
        {
          duration: DURATION,
        },
      );
    },
    [setViewport],
  );

  return { applyInitialViewport };
};

function setZoomLevelByEdgesLength(edgesLength: number) {
  if (edgesLength >= 25) {
    return 0.2;
  }

  if (edgesLength <= 25 && edgesLength > 15) {
    return 0.3;
  }

  if (edgesLength <= 15 && edgesLength > 5) {
    return 0.8;
  }

  return 0.8;
}
