import type { UserCredential } from "firebase/auth";
import React, {
  useContext,
  useEffect,
  useMemo,
  type FC,
  type PropsWithChildren,
} from "react";
import { useMutation } from "react-query";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { LoaderLayout } from "#shared/components/layouts";
import { FirebaseContext } from "#shared/contexts/firebase";
import {
  useConfirmEmailWhenSignInWithEmailLink,
  type SetUser,
} from "#shared/contexts/firebase/hooks";
import { firebaseUserState } from "#shared/recoil";
import { LoggerService } from "#shared/services";

const SIGN_IN_WITH_LINK_FAILED_URL =
  "/sign-in/owner?signInConfigs=email,password&signInWithLinkFailed=true";

/**
 * NOTE:
 *
 * Used when completing "sign in with email link".
 *
 * Firebase recommendation:
 *
 * "To prevent a sign-in link from being used to sign in as an unintended user or on an unintended device,
 * Firebase Auth requires the user's email address to be provided when completing the sign-in flow.
 * For sign-in to succeed, this email address must match the address to which the sign-in link was originally sent.
 *
 * You can streamline this flow for users who open the sign-in link on the same device they request the link,
 * by storing their email address locally - for instance using localStorage or cookies - when you send the sign-in email.
 * Then, use this address to complete the flow.
 * Do not pass the user’s email in the redirect URL parameters and reuse it as this may enable session injections."
 *
 * https://firebase.google.com/docs/auth/web/email-link-auth#send_an_authentication_link_to_the_users_email_address
 * https://firebase.google.com/docs/auth/web/email-link-auth#complete_sign_in_with_the_email_link
 *
 */
export const CompleteSignInFirebase: FC<PropsWithChildren> = ({ children }) => {
  const {
    signInWithEmailLink,
    signInEmail,
    isSignInWithEmailLink,
    refreshIsSignInWithEmailLink,
  } = useContext(FirebaseContext);

  const isAuthenticatedWithFirebase = useRecoilValue(
    firebaseUserState.isAuthenticated,
  );

  const isSuccess = useRecoilValue(firebaseUserState.isSuccess);
  const isLoading = useRecoilValue(firebaseUserState.isLoading);
  const error = useRecoilValue(firebaseUserState.error);
  const setPartialUser = useSetRecoilState(firebaseUserState.setPartialState);

  const { signIn = null } = signInWithEmailLink || {};
  const { mutate: signInWithLink, isLoading: isLinkSignInLoading } =
    useSignInWithLinkMutation(signIn);

  useEffect(() => {
    if (isSuccess) {
      refreshIsSignInWithEmailLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const shouldCompleteSignIn = useMemo(() => {
    const shouldComplete =
      signInEmail &&
      !isAuthenticatedWithFirebase &&
      !isLoading &&
      isSignInWithEmailLink &&
      !error &&
      !isLinkSignInLoading;

    LoggerService.debug("Complete sign in", {
      shouldComplete,
      isAuthenticatedWithFirebase,
      isLoading,
      isSignInWithEmailLink,
      error,
    });

    return shouldComplete;
  }, [
    signInEmail,
    error,
    isAuthenticatedWithFirebase,
    isLoading,
    isSignInWithEmailLink,
    isLinkSignInLoading,
  ]);

  useEffect(() => {
    if (!isSignInWithEmailLink || !error || !shouldCompleteSignIn) {
      return;
    }

    window.location.href = SIGN_IN_WITH_LINK_FAILED_URL;
  }, [error, isSignInWithEmailLink, shouldCompleteSignIn]);

  /**
   * NOTE:
   *
   * Automatic sign in.
   * Takes place when user clicks the sign in link in the email.
   */
  useEffect(() => {
    if (!signIn || !shouldCompleteSignIn || !signInEmail) {
      return;
    }

    LoggerService.debug(
      null,
      "Complete sign in",
      "Trying to complete sign in.",
    );

    signInWithLink(
      {
        signInEmail,
        setUser: setPartialUser,
      },
      {
        onSuccess: () => {
          LoggerService.debug("Complete sign in", "Completed sign in.");
        },
        onError: (err) => {
          LoggerService.error(
            null,
            "Complete sign in",
            "Failed to complete sign in.",
            err,
          );

          window.location.href = SIGN_IN_WITH_LINK_FAILED_URL;
        },
      },
    );
  }, [
    setPartialUser,
    shouldCompleteSignIn,
    signIn,
    signInEmail,
    signInWithLink,
  ]);

  const promptForEmailToCompleteSignIn = useMemo(
    () => !isLoading && !signInEmail && !error && isSignInWithEmailLink,
    [error, isLoading, isSignInWithEmailLink, signInEmail],
  );

  const { askEmailAgain } = useConfirmEmailWhenSignInWithEmailLink();

  useEffect(() => {
    if (askEmailAgain && promptForEmailToCompleteSignIn) {
      LoggerService.debug(
        null,
        "CompleteSignInFirebase",
        "Asking for email again",
      );

      askEmailAgain((email) => {
        if (email.length && !!signIn) {
          LoggerService.debug(
            null,
            "CompleteSignInFirebase",
            "Completing sign in with email",
          );

          signIn(email, setPartialUser);
        }
      });
    }
  }, [askEmailAgain, promptForEmailToCompleteSignIn, setPartialUser, signIn]);

  if (isLoading || isLinkSignInLoading) {
    return <LoaderLayout />;
  }

  return <>{children}</>;
};

export type SignInWithLinkPayload = {
  signInEmail: string;
  setUser: SetUser;
};
export type SignInWithLinkFn = (
  signInEmail: string,
  setUser: SetUser,
) => Promise<UserCredential | null>;

const useSignInWithLinkMutation = (signInWithLinkFn: SignInWithLinkFn | null) =>
  useMutation({
    mutationKey: ["signInWithLink", "firebase"],
    mutationFn: ({ signInEmail, setUser }: SignInWithLinkPayload) =>
      signInWithLinkFn
        ? signInWithLinkFn(signInEmail, setUser)
        : Promise.resolve(null),
  });
