import { SvgIcon, type SvgIconProps } from "@mui/material";
import React, { type FC, forwardRef, type PropsWithRef } from "react";

export const HelpIcon: FC<PropsWithRef<SvgIconProps>> = forwardRef(
  (props, ref) => (
    <SvgIcon {...props} ref={ref}>
      <path
        style={{ fill: "#f6e5c1" }}
        d="m10.07,12.79c-.11,0-.2-.04-.26-.12s-.1-.19-.1-.32c0-.61.11-1.12.32-1.55.22-.43.54-.9.95-1.41.33-.42.58-.78.73-1.07.17-.29.25-.62.25-.98,0-.42-.15-.75-.45-.98-.3-.24-.71-.37-1.23-.37-.44,0-.84.09-1.22.26-.37.17-.79.42-1.26.78-.17.12-.3.18-.41.18-.12,0-.21-.05-.29-.15-.07-.11-.1-.23-.1-.37,0-.2.08-.37.23-.51.39-.37.86-.66,1.42-.88.57-.22,1.13-.34,1.68-.34.85,0,1.53.21,2.05.63.53.42.79.97.79,1.66,0,.52-.11.97-.34,1.35-.21.37-.55.81-1,1.32-.42.48-.75.9-.98,1.27-.22.36-.36.78-.41,1.26,0,.11-.05.19-.12.25-.07.06-.16.09-.26.09Zm.01,2.7c-.23,0-.43-.08-.59-.23-.15-.16-.22-.35-.22-.59s.07-.43.22-.59c.16-.17.35-.25.59-.25s.42.08.57.25c.16.16.23.35.23.59s-.08.43-.23.59c-.15.16-.34.23-.57.23Z"
      />
      <path
        style={{ fill: "#f6e5c1" }}
        d="m10,1.3c4.8,0,8.7,3.9,8.7,8.7s-3.9,8.7-8.7,8.7S1.3,14.8,1.3,10,5.2,1.3,10,1.3m0-.8C4.75.5.5,4.75.5,10s4.25,9.5,9.5,9.5,9.5-4.25,9.5-9.5S15.25.5,10,.5h0Z"
      />
    </SvgIcon>
  ),
);
