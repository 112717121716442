import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { type FC } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Analytics } from "#shared/analytics";
import { TextInput } from "#shared/components/inputs";
import { GlobalFrame } from "#shared/components/layouts";
import { STATUS_CODES, SUPPORT_EMAIL, sharedEnTexts } from "#shared/consts";
import { ENV } from "#shared/env";
import type { Texts } from "#shared/types";

import { useOrganizationSubmit } from "./hooks";

interface FormValues {
  domainName: string;
}

type Text =
  | "title"
  | "create"
  | "required"
  | "pattern"
  | "length"
  | "label"
  | "placeholder"
  | "provisioningError"
  | "conflictingError"
  | "back"
  | "finishingUp";

interface CreateOrganizationPageProps {
  texts?: Texts<Text>;
}

const enText: Required<CreateOrganizationPageProps["texts"]> = {
  title: "Create a new Organization and get a 30 Days Free Trial",
  create: "Create",
  label: "Organization domain",
  required: "Please enter a valid organization domain.",
  pattern:
    "Organization domain must contain only lowercase, numbers or hyphen as a special character.",
  length: "Organization domain must be between $1 and $2 characters",
  placeholder: "your-organization-name",
  provisioningError: "Please try again or contact support at ",
  conflictingError: "Organization with this domain already exists.",
  finishingUp: "Finishing up...",
  back: "Back",
};

const MIN_ORG_NAME_LENGTH = 4;
const MAX_ORG_NAME_LENGTH = 20;

export const CreateOrganizationPage: FC<CreateOrganizationPageProps> = ({
  texts = enText,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ mode: "onChange" });

  const {
    submit,
    isLoading,
    provisioningError,
    isProvisioned,
    reset: resetOrganizationSubmit,
  } = useOrganizationSubmit();

  const onSubmit: SubmitHandler<FormValues> = ({ domainName: domain }) => {
    submit(domain);

    Analytics.track("creating_organization", {
      domain,
    });
  };

  const navigate = useNavigate();

  return (
    <GlobalFrame hideLogout>
      <Box width={["100%", "100%", "90%"]} mx="auto">
        <Box textAlign="center" mb={4}>
          <Typography variant="h6" mb={4} mt={4}>
            {texts.title}
          </Typography>
        </Box>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Box mt={4} mb={2} display="flex" alignItems="baseline" gap={2}>
              <TextInput
                id="domainName"
                fullWidth
                label={texts.label}
                disabled={isLoading}
                {...register("domainName", {
                  required: texts.required,
                  pattern: {
                    value: /^[0-9a-z-]+$/,
                    message: texts.pattern,
                  },
                  minLength: {
                    value: MIN_ORG_NAME_LENGTH,
                    message: texts.length
                      .replace("$1", `${MIN_ORG_NAME_LENGTH}`)
                      .replace("$2", `${MAX_ORG_NAME_LENGTH}`),
                  },
                  maxLength: {
                    value: MAX_ORG_NAME_LENGTH,
                    message: texts.length
                      .replace("$1", `${MIN_ORG_NAME_LENGTH}`)
                      .replace("$2", `${MAX_ORG_NAME_LENGTH}`),
                  },
                  onChange: () => {
                    resetOrganizationSubmit();
                  },
                })}
                placeholder={texts.placeholder}
                error={!!errors.domainName}
                helperText={errors.domainName?.message}
              />
              <Typography variant="body1">.{ENV.VITE_APP_DOMAIN}</Typography>
            </Box>
            <Box>
              {provisioningError ? (
                <Box>
                  <Typography color="error" variant="body2">
                    {Number(provisioningError) === STATUS_CODES.Conflict
                      ? texts.conflictingError
                      : sharedEnTexts.error}
                    <br />
                  </Typography>
                  <Typography color="error" variant="body2">
                    {texts.provisioningError}
                    <strong>{SUPPORT_EMAIL}</strong>
                  </Typography>
                </Box>
              ) : null}
            </Box>
            <Box display="flex" justifyContent="center" mt={4} mb={4}>
              <Button
                variant="contained"
                type="submit"
                disabled={isLoading || isProvisioned}
                fullWidth
                startIcon={
                  isLoading && !provisioningError ? (
                    <CircularProgress
                      size="1em"
                      sx={{ marginLeft: ({ spacing }) => spacing(1) }}
                    />
                  ) : null
                }
              >
                {!isProvisioned ? texts.create : texts.finishingUp}
              </Button>
            </Box>
          </form>

          <Box pb={3}>
            <Button variant="text" onClick={() => navigate(-1)}>
              {texts.back}
            </Button>
          </Box>
        </Box>
      </Box>
    </GlobalFrame>
  );
};
