import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { userNewState } from "#shared/recoil";
import { LoggerService } from "#shared/services";
import { type StandardResponse } from "#shared/types";
import {
  httpClient,
  type ResponseWithTypedJson,
} from "#shared/utils/http-client";

import { API_URLS } from "#global/consts";

import { useOrganizationById } from "../../organizations/use-organization-by-id";

export type RequestCustomPlanBody = {
  customPlan: string;
  comment: string;
  organization: { id: string; domain_name: string };
  user: {
    name: string;
    id: string;
    email: string;
  };
};

type SendCustomPlanRequest = (
  partialBody: Pick<RequestCustomPlanBody, "comment" | "customPlan">,
) => Promise<null | ResponseWithTypedJson<StandardResponse>>;

export function useSendCustomPlanRequest() {
  const { data: user } = useRecoilValue(userNewState.state);

  const [isPending, setIsPending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const { id } = useParams<{
    id: string;
  }>();

  const { organization } = useOrganizationById(id || "");

  const reset = useCallback(() => {
    setIsPending(false);
    setIsSuccess(false);
    setError(null);
  }, []);

  const sendCustomPlanRequest = useCallback<SendCustomPlanRequest>(
    async (partialBody) => {
      if (!user?.id || !user?.email || !user.name) {
        LoggerService.warn("Failed to send custom plan request. Missing user.");

        setError("Failed to send custom plan request. Missing user.");

        return null;
      }

      if (!organization) {
        LoggerService.warn(
          "Failed to send custom plan request. Missing organization ID.",
        );

        setError(
          "Failed to send custom plan request. Missing organization ID.",
        );

        return null;
      }

      const body: RequestCustomPlanBody = {
        ...partialBody,
        organization: {
          domain_name: organization.domain_name,
          id: organization.id,
        },
        user: { id: user.id, email: user.email, name: user.name },
      };

      setIsPending(true);
      setError(null);
      setIsSuccess(false);

      try {
        const response = await httpClient.post<
          Partial<StandardResponse>,
          false,
          RequestCustomPlanBody
        >({
          url: API_URLS.CUSTOM_PLAN.POST,
          body,
        });

        setIsPending(false);

        if (!response.ok) {
          setError("Something went wrong.");
        } else {
          setIsSuccess(true);
        }

        return response;
      } catch (err) {
        setIsPending(false);

        setError(
          err instanceof Error
            ? err.message || "Something went wrong."
            : String(err),
        );

        return null;
      }
    },
    [user?.id, user?.email, user?.name, organization],
  );

  return {
    sendCustomPlanRequest,
    isPending,
    isSuccess,
    error,
    reset,
  };
}
