import React, { type FC, useMemo } from "react";

import { DASHBOARDVER } from "#shared/dashboard-versions";

import { LoadDashboardPortal } from "#organization/components/grafana";
import { DASHBOARDS } from "#organization/pages/consts";
import type {
  CacheDashboardQueryParam,
  DashboardConfig,
} from "#organization/pages/types";
import { useProjectContextState } from "#organization/recoil/project";

import type { CacheDashboardConfig } from "./types";

export type CacheDashboardProps = {
  vars: CacheDashboardConfig;
};

const config: Omit<DashboardConfig<"CACHE_HIT_MISS">, "queryParams"> = {
  name: "cache-hit-miss",
  slug: "cache-hit-miss",
  version: DASHBOARDVER.CACHE_HIT_MISS,
  uuid: DASHBOARDS.CACHE_HIT_MISS,
  hiddenVariables: [
    "var-fn_project_id",
    "var-controller_id",
    "var-control_point",
    "var-type",
    "var-agent_group",
  ],
  filters: [],
};

export const CacheDashboard: FC<CacheDashboardProps> = ({
  vars: { controllerId, controlPoint, type, agentGroup },
}) => {
  const projectState = useProjectContextState();

  const queryParams: { [Q in CacheDashboardQueryParam]?: string } = useMemo(
    () => ({
      "var-fn_project_id": projectState.id,
      "var-controller_id": controllerId,
      "var-control_point": controlPoint,
      "var-agent_group": agentGroup,
      "var-type": type,
    }),
    [projectState.id, controllerId, controlPoint, type],
  );

  return (
    <>
      {queryParams && (
        <LoadDashboardPortal
          queryParams={queryParams}
          slug={config.slug}
          uid={config.uuid}
          hiddenVariables={config.hiddenVariables}
        />
      )}
    </>
  );
};
