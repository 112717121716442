import type { RJSFSchema } from "@rjsf/utils";
import * as yaml from "js-yaml";
import { isFunction, noop } from "lodash";
// eslint-disable-next-line import/no-extraneous-dependencies
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import React, {
  createContext,
  useContext,
  type FC,
  useState,
  useMemo,
  type SetStateAction,
  type Dispatch,
  useEffect,
  type ReactNode,
} from "react";

import {
  PopUpBox,
  usePopUpBoxInitializer,
  type AddPopUpBox,
} from "#shared/components/pop-up";
import type { AnyObject, JSONObject } from "#shared/types";

import type { Size } from "#organization/components/flyout-menu/hooks/use-content-view-port-size";

import type { RecursiveStringify } from "../components/form-builder/builder/types";
import { useEditBlueprint } from "../hooks/use-edit-blueprint";
import { useCreatePolicySearchParams } from "../hooks/use-step-change";

export declare type SubmitPolicyValues = (
  callback: (
    isErr: boolean,
    payload: Pick<CreatePolicyCtx, "blueprintJson" | "blueprintYaml">,
  ) => void,
) => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export declare type ConvertToJson = <TResponse extends any = JSONObject>(
  valuesYaml: string,
) => TResponse | null;

export declare type CreatePolicyCtx<TDocTree extends AnyObject = AnyObject> = {
  schema: RJSFSchema | null;
  blueprintYaml: string | null;
  blueprintJson: JSONObject | null;
  setCreatePolicyCtx: Dispatch<SetStateAction<CreatePolicyCtx>>;
  convertToJson: ConvertToJson | null;
  submitPolicyValues: SubmitPolicyValues | null;
  editorSavedState: monaco.editor.ICodeEditorViewState | null;
  editorModel: monaco.editor.ITextModel | null;
  editor: monaco.editor.IStandaloneCodeEditor | null;
  saveEditorState: (() => void) | null;
  addPopUpBox: AddPopUpBox;
  fullScreen: boolean;
  showDocumentation: boolean;
  docFieldAddress: keyof TDocTree | null;
  docTree: RecursiveStringify<TDocTree> | null;
  flyoutMenuSize: Size | null;
  miniEditors: monaco.editor.IStandaloneCodeEditor[] | null;
  showEditor: boolean;
  updateException:
    | (() => Pick<CreatePolicyCtx, "blueprintJson" | "blueprintYaml">)
    | null;
};

export const defaultCreatePolicyCtx: CreatePolicyCtx = {
  schema: null,
  blueprintYaml: null,
  blueprintJson: null,
  setCreatePolicyCtx: noop,
  convertToJson: null,
  submitPolicyValues: null,
  editorSavedState: null,
  editorModel: null,
  editor: null,
  saveEditorState: null,
  addPopUpBox: noop,
  fullScreen: false,
  showDocumentation: true,
  docFieldAddress: "blueprint",
  flyoutMenuSize: null,
  docTree: null,
  miniEditors: null,
  showEditor: false,
  updateException: null,
};

export const CreatePolicyContext = createContext(defaultCreatePolicyCtx);

export const useCreatePolicyContext = <
  TDocTree extends AnyObject = AnyObject,
>() =>
  useContext<CreatePolicyCtx<TDocTree>>(
    // @ts-ignore
    CreatePolicyContext,
  );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertToJson = <TResponse extends any = JSONObject>(
  valuesYaml: string,
) => {
  const yamlObject = yaml.load(valuesYaml);

  return yamlObject as TResponse;
};

export const convertToYaml = (valuesJson: JSONObject) => yaml.dump(valuesJson);

export interface CreatePolicyContextProviderProps {
  children: ReactNode | ((props: CreatePolicyCtx) => ReactNode | JSX.Element);
}

export const CreatePolicyContextProvider: FC<
  CreatePolicyContextProviderProps
> = ({ children }) => {
  const [ctx, setCreatePolicyCtx] = useState<CreatePolicyCtx>(
    defaultCreatePolicyCtx,
  );

  const {
    deleteBoxProps: { message, callback },
    showDeleteBox: showPopUpBox,
    onClick: onClickPopUpBox,
    addDeleteBox: addPopUpBox,
    deleteConfirmBoxOptionalProps: optionalProps,
  } = usePopUpBoxInitializer();

  const {
    searchInfo: { policyID, status: policyStatus },
  } = useCreatePolicySearchParams();

  const disableUpdate = useMemo(
    () =>
      !!policyStatus?.length &&
      policyStatus !== "1-draft" &&
      policyStatus !== "0-error",
    [policyStatus],
  );

  const isFindPolicyValues = useMemo(
    () =>
      Boolean(
        policyID?.length &&
          !ctx.blueprintJson &&
          !ctx.blueprintYaml &&
          !disableUpdate,
      ),
    [ctx.blueprintJson, ctx.blueprintYaml, disableUpdate, policyID?.length],
  );

  const { values: editBlueprintValues } = useEditBlueprint(isFindPolicyValues);
  const values = editBlueprintValues?.values;

  useEffect(() => {
    if (!values || disableUpdate) return;

    const blueprintYaml = yaml.dump(values);

    setCreatePolicyCtx((v) => ({
      ...v,
      blueprintYaml,
      blueprintJson: values,
    }));
  }, [disableUpdate, values]);

  const value = useMemo(
    () => ({ ...ctx, setCreatePolicyCtx, convertToJson, addPopUpBox }),
    [ctx, addPopUpBox],
  );

  return (
    <CreatePolicyContext.Provider value={value}>
      <PopUpBox
        callback={callback}
        onClickDeleteBox={onClickPopUpBox}
        open={showPopUpBox}
        {...optionalProps}
      >
        {message}
      </PopUpBox>
      {isFunction(children) ? children(value) : children}
    </CreatePolicyContext.Provider>
  );
};
