import React, { type FC, useMemo } from "react";

import { DASHBOARDVER } from "#shared/dashboard-versions";

import { LoadDashboardPortal } from "#organization/components/grafana";
import { DASHBOARDS } from "#organization/pages/consts";
import type {
  DashboardConfig,
  FlowAnalyticsByFlowLabelDashboardQueryParam,
} from "#organization/pages/types";
import { useProjectContextState } from "#organization/recoil/project";

import type { FlowControlFlyoutConfig } from "../../../types";

export type FlowAnalyticsByLabelProps = {
  service: Pick<
    FlowControlFlyoutConfig,
    "agentGroup" | "serviceName" | "controlPoint" | "controllerId"
  >;
};

const config: Omit<
  DashboardConfig<"FLOW_ANALYTICS_BY_FLOW_LABEL">,
  "queryParams"
> = {
  name: "service-summary",
  slug: "http",
  version: DASHBOARDVER.FLOW_ANALYTICS_BY_FLOW_LABEL,
  uuid: DASHBOARDS.FLOW_ANALYTICS_BY_FLOW_LABEL,
  hiddenVariables: [
    "var-agent_group",
    "var-fn_project_id",
    "var-services",
    "var-controller_id",
    "var-control_point",
  ],
  filters: [],
};

export const FlowAnalyticsByLabel: FC<FlowAnalyticsByLabelProps> = ({
  service: { agentGroup, serviceName, controllerId, controlPoint },
}) => {
  const projectState = useProjectContextState();

  const queryParams: {
    [Q in FlowAnalyticsByFlowLabelDashboardQueryParam]?: string;
  } = useMemo(
    () => ({
      "var-agent_group": agentGroup,
      "var-fn_project_id": projectState.id,
      "var-services": serviceName || "All",
      "var-controller_id": controllerId,
      "var-control_point": controlPoint,
    }),
    [agentGroup, controllerId, serviceName, projectState.id, controlPoint],
  );

  return (
    <>
      {queryParams && (
        <LoadDashboardPortal
          queryParams={queryParams}
          slug={config.slug}
          uid={config.uuid}
          hiddenVariables={config.hiddenVariables}
        />
      )}
    </>
  );
};
