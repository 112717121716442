import { Box, CircularProgress, Typography } from "@mui/material";
import React, {
  type FormHTMLAttributes,
  useCallback,
  useContext,
  useMemo,
  type FC,
} from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { PrimaryButton } from "#shared/components/buttons";
import { TextInput, type TextInputProps } from "#shared/components/inputs";
import { ErrorText } from "#shared/components/layouts";
import { EMAIL_PATTERN } from "#shared/consts";
import { FirebaseContext } from "#shared/contexts/firebase";
import type { Texts } from "#shared/types";

export interface SignInFirebaseProps {
  texts?: Texts<Text>;
  formProps?: FormHTMLAttributes<HTMLFormElement>;
}

interface SignInFormValues {
  email: string;
  toc: boolean;
}

type Text =
  | "emailLabel"
  | "emailRequired"
  | "tocRequired"
  | "submit"
  | "success"
  | "failed";

const enTexts: Required<SignInFirebaseProps["texts"]> = {
  emailLabel: "Work Email Address",
  emailRequired: "Please enter a valid email.",
  tocRequired: "Please accept the terms and conditions.",
  submit: "Continue",
  success:
    "An email with a sign in link has been sent to the email address. You should receive it shortly.",
  failed: "Failed to sign in with link",
};

const EMAIL_ERROR = "emailError";

export const SignInWithLinkForm: FC<SignInFirebaseProps> = ({
  texts = enTexts,
  formProps = {},
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm<SignInFormValues>({ mode: "onBlur" });

  const { sendSignInLinkToEmail } = useContext(FirebaseContext);

  const failedSignInWithLink = useFailedSignInWithLinkCheck();

  const {
    sendEmailStatus = null,
    resetSendEmailStatus = null,
    sendEmail = null,
  } = sendSignInLinkToEmail || {};

  const onChange = useCallback<Required<TextInputProps>["onChange"]>(() => {
    if (!sendEmailStatus?.isIdle && resetSendEmailStatus) {
      resetSendEmailStatus();
    }

    clearErrors("email");
  }, [sendEmailStatus, clearErrors, resetSendEmailStatus]);

  const submit = useCallback<SubmitHandler<SignInFormValues>>(
    ({ email }) => {
      if (sendEmail) {
        sendEmail(email);
      }
    },
    [sendEmail],
  );

  const disabled = useMemo(
    () => !!sendEmailStatus?.isLoading && !sendEmailStatus?.error,
    [sendEmailStatus?.isLoading, sendEmailStatus?.error],
  );

  return (
    <form
      onSubmit={handleSubmit(submit)}
      autoComplete="off"
      style={{ width: "100%", ...(formProps?.style || {}) }}
      {...formProps}
    >
      <TextInput
        id="email"
        fullWidth
        type="email"
        label={texts.emailLabel}
        {...register("email", {
          required: texts.emailRequired,
          onChange,
          value: "",
          validate: {
            [EMAIL_ERROR]: (email) => EMAIL_PATTERN.value.test(email),
          },
        })}
        error={!!errors.email}
        helperText={
          errors.email ? errors.email?.message || EMAIL_PATTERN.message : ""
        }
        disabled={disabled}
      />
      <Box marginTop={2.5} width="100%">
        <PrimaryButton
          fullWidth
          size="large"
          type="submit"
          disabled={!!sendEmailStatus?.isLoading}
          sx={({ spacing }) => ({ marginBottom: spacing(2) })}
        >
          {texts.submit}
          {sendEmailStatus?.isLoading ? (
            <CircularProgress
              size="1em"
              sx={{ marginLeft: ({ spacing }) => spacing(1) }}
            />
          ) : null}
        </PrimaryButton>
        {sendEmailStatus?.error ? (
          <ErrorText {...{ error: sendEmailStatus?.error }} />
        ) : null}
        {failedSignInWithLink ? (
          <ErrorText {...{ error: texts.failed }} />
        ) : null}
        {sendEmailStatus?.isSuccess ? (
          <Typography>{texts.success}</Typography>
        ) : null}
      </Box>
    </form>
  );
};

const SIGN_IN_WITH_LINK_FAILED = "signInWithLinkFailed";

export const useFailedSignInWithLinkCheck = () => {
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);

  const signInWithLinkFailed = queryParams.get(SIGN_IN_WITH_LINK_FAILED);

  if (signInWithLinkFailed) {
    queryParams.delete(SIGN_IN_WITH_LINK_FAILED);

    window.history.replaceState(
      null,
      "",
      `${window.location.pathname}?${queryParams.toString()}`,
    );
  }

  return useMemo(() => !!signInWithLinkFailed, [signInWithLinkFailed]);
};
