import React, { type FC } from "react";
import { Route, Routes } from "react-router-dom";

import { useSetPageTitle } from "#shared/hooks";
import { FEATURE_FLAGS } from "#shared/utils";

import { TopBarLayout } from "#organization/components/layouts";

import { CreatePolicyPage, EditPolicyPage } from "./create-policy";
import { PoliciesPage } from "./page";
import { PolicyPage, SelectedResourcesProvider } from "./policy";
import {
  CREATE_POLICY_ROUTE,
  EDIT_POLICY_ROUTE,
  POLICIES_ROUTE,
} from "./routes.definitions";

import { useUgMemberPermission } from "../settings/account/hooks";

export const PoliciesRoutes: FC = () => {
  useSetPageTitle(POLICIES_ROUTE.TITLE);

  /* Members cannot create policies if the user group does not have write permissions */
  const permissionLock = useUgMemberPermission();

  return (
    <TopBarLayout>
      <SelectedResourcesProvider>
        {FEATURE_FLAGS.canCreatePolicy && !permissionLock ? (
          <Routes>
            <Route
              path={CREATE_POLICY_ROUTE.ABSOLUTE_PATH}
              element={<CreatePolicyPage />}
            />
            <Route
              path="create-custom-policy"
              element={
                <EditPolicyPage
                  texts={{
                    title: "Create Custom Policy",
                  }}
                  updateDraftedPolicy={false}
                />
              }
            />
            <Route
              path={EDIT_POLICY_ROUTE.ABSOLUTE_PATH}
              element={<EditPolicyPage />}
            />
            <Route path="/policy/:policyId/*" element={<PolicyPage />} />
            <Route index element={<PoliciesPage />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/policy/:policyId/*" element={<PolicyPage />} />
            <Route index element={<PoliciesPage />} />
          </Routes>
        )}
      </SelectedResourcesProvider>
    </TopBarLayout>
  );
};
