import { gql } from "graphql-request";

export const filters = (projectId: string, controllerId: string) => [
  {
    id: 0,
    name: "Hostname",
    key: "hostname",
    category: "agents",
    gql: gql`
      query GetControllerAgentsHostname($where: AgentBoolExp, $first: Int) {
        agents(where: $where, first: $first) {
          edges {
            node {
              hostname
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      const variable = {
        first: 5,
        where: {
          projectID: { eq: projectId },
          controllerID: { eq: controllerId },
          hostname: {},
        },
      };

      if (prop !== "") {
        variable.where.hostname = {
          like: `${prop}%`,
        };
      }

      return variable;
    },
  },
  {
    id: 1,
    name: "Agent key",
    key: "agentKey.key",
    category: "agents",
    gql: gql`
      query GetControllerAgentsAgentKey($where: AgentBoolExp, $first: Int) {
        agents(where: $where, first: $first, distinctOn: [agentKeyID]) {
          edges {
            node {
              agentKey {
                key
              }
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      const variable = {
        first: 5,
        where: {
          projectID: { eq: projectId },
          controllerID: { eq: controllerId },
          agentKey: {
            key: {},
          },
        },
      };

      if (prop !== "") {
        variable.where.agentKey.key = {
          like: `${prop}%`,
        };
      }

      return variable;
    },
  },
  {
    id: 2,
    name: "Version",
    key: "version",
    category: "agents",
    gql: gql`
      query GetControllerAgentsVersion($where: AgentBoolExp, $first: Int) {
        agents(where: $where, first: $first, distinctOn: [version]) {
          edges {
            node {
              version
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      const variable = {
        first: 5,
        where: {
          projectID: { eq: projectId },
          controllerID: { eq: controllerId },
          version: {},
        },
      };

      if (prop !== "") {
        variable.where.version = {
          like: `${prop}%`,
        };
      }

      return variable;
    },
  },
  {
    id: 3,
    name: "Status",
    key: "status",
    category: "agents",
    gql: gql`
      query GetControllerAgentsStatus($where: AgentBoolExp, $first: Int) {
        agents(where: $where, first: $first, distinctOn: [status]) {
          edges {
            node {
              status
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      const variable = {
        first: 5,
        where: {
          projectID: { eq: projectId },
          controllerID: { eq: controllerId },
          status: {},
        },
      };

      if (prop !== "") {
        variable.where.status = {
          like: `${prop}%`,
        };
      }

      return variable;
    },
  },
  {
    id: 4,
    name: "Last seen",
    key: "lastSeen",
    category: "agents",
    isLikeFilterBlocked: true,
    gql: gql`
      query GetControllerAgentsLastSeen($where: AgentBoolExp, $first: Int) {
        agents(where: $where, first: $first, distinctOn: [lastSeen]) {
          edges {
            node {
              lastSeen
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      const variable = {
        first: 5,
        where: {
          projectID: { eq: projectId },
          controllerID: { eq: controllerId },
          lastSeen: {},
        },
      };

      if (prop !== "") {
        variable.where.lastSeen = {
          like: `${prop}%`,
        };
      }

      return variable;
    },
  },
  {
    id: 5,
    name: "Agent group",
    key: "agentGroup",
    category: "agents",
    gql: gql`
      query GetControllerAgentsLastAgentGroup(
        $where: AgentBoolExp
        $first: Int
      ) {
        agents(where: $where, first: $first, distinctOn: [agentGroup]) {
          edges {
            node {
              agentGroup
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      const variable = {
        first: 5,
        where: {
          projectID: { eq: projectId },
          controllerID: { eq: controllerId },
          agentGroup: {},
        },
      };

      if (prop !== "") {
        variable.where.agentGroup = {
          like: `${prop}%`,
        };
      }

      return variable;
    },
  },
];
