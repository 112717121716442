import {
  signInWithCustomToken,
  type Auth,
  signInWithPopup,
} from "firebase/auth";
import { pick } from "lodash";
import { useContext, useEffect, useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";

import { createSamlProvider } from "#shared/components/sign-in";
import { API_ACCOUNT_URLS, QUERY_PARAMS } from "#shared/consts";
import { FirebaseContext } from "#shared/contexts/firebase";
import {
  DEFAULT_IDP_CONFIG_PROVIDERS,
  type TenantIdpConfigUnsecuredResponse,
} from "#shared/types";
import { httpClient } from "#shared/utils/http-client";

import { useOrganizationState } from "#organization/recoil/organization";

import { QUERY_KEYS } from "./consts";

/**
 * This look for custom token in url or globalUI search param.
 * If token is found, it tries to login with it using signInWithCustomToken
 * If token is not found and it is globalUI redirect, it starts SSO provider using signInWithPopup.
 */
export const useAutoSignIn = () => {
  const { firebase } = useContext(FirebaseContext);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const customToken = queryParams.get("customToken");
  const globalUI = queryParams.get("globalUI");
  const userEmail = queryParams.get("email");

  const { idpConfigs, isIdpConfigsLoading, isIdpConfigsError } =
    useFetchDefaultIdpConfigs();

  const {
    isError: isCustomTokenSignInError,
    isLoading: isCustomTokenLoginLoading,
    mutate: tokenSignIn,
  } = useCustomTokenSignIn();

  const {
    isError: isStartSsoFlowError,
    isLoading: isStartSsoFlowLoading,
    mutate: startSso,
  } = useStartSsoFlow();

  useEffect(() => {
    if (!firebase) {
      return;
    }

    if (globalUI) {
      startSso({
        firebase,
        globalUI,
        tenantSignInProps: idpConfigs,
      });

      return;
    }

    if (customToken) {
      tokenSignIn({
        firebase,
        token: customToken || "",
      });
    }
  }, [
    globalUI,
    customToken,
    userEmail,
    firebase,
    startSso,
    idpConfigs,
    tokenSignIn,
  ]);

  return {
    isLoading:
      isIdpConfigsLoading || isCustomTokenLoginLoading || isStartSsoFlowLoading,
    isError:
      isIdpConfigsError || isCustomTokenSignInError || isStartSsoFlowError,
  };
};

export const useFetchDefaultIdpConfigs = () => {
  const { idpTenantID } = useOrganizationState();

  const {
    data,
    isLoading: isIdpConfigsLoading,
    isError,
  } = useQuery({
    queryKey: [QUERY_KEYS.FETCH_DEFAULT_IDP_CONFIG, idpTenantID],
    queryFn: async () => {
      const url = new URL(API_ACCOUNT_URLS.TENANTS.CONFIG(idpTenantID));
      url.searchParams.append(QUERY_PARAMS.ENABLED, "true");

      const configs = await httpClient.get<TenantIdpConfigUnsecuredResponse>({
        url,
      });

      return configs.json();
    },
  });

  const idpConfigs = useMemo(
    () =>
      ({
        ...pick(data, "defaultSupportedIdpConfigs", "inboundSamlConfigs"),
      }) as Pick<
        TenantIdpConfigUnsecuredResponse,
        "inboundSamlConfigs" | "defaultSupportedIdpConfigs"
      >,
    [data],
  );

  return {
    idpConfigs,
    isIdpConfigsLoading,
    isIdpConfigsError: isError,
  };
};

export type StartSsoFlowProps = {
  firebase: Auth | null;
  globalUI: string;
  tenantSignInProps: Pick<
    TenantIdpConfigUnsecuredResponse,
    "defaultSupportedIdpConfigs" | "inboundSamlConfigs"
  >;
};

export async function startSsoFlow({
  firebase,
  globalUI,
  tenantSignInProps,
}: StartSsoFlowProps) {
  if (!firebase || !globalUI.length || !tenantSignInProps) {
    return;
  }

  const { defaultSupportedIdpConfigs, inboundSamlConfigs } = tenantSignInProps;

  const SSOProvider = defaultSupportedIdpConfigs?.length
    ? DEFAULT_IDP_CONFIG_PROVIDERS[defaultSupportedIdpConfigs[0].displayName]
    : null;
  const SAMLProvider = inboundSamlConfigs?.length
    ? createSamlProvider(inboundSamlConfigs[0].name)
    : null;

  const Provider = (SSOProvider && new SSOProvider()) || SAMLProvider;

  if (!Provider) {
    return;
  }

  await signInWithPopup(firebase, Provider);
}

export const useStartSsoFlow = () =>
  useMutation({
    mutationKey: ["startSsoFlow", "firebase"],
    mutationFn: (props: StartSsoFlowProps) => startSsoFlow(props),
  });

export type CustomTokenSignInProps = {
  firebase: Auth | null;
  token: string;
};

export async function customTokenSignIn({
  firebase,
  token,
}: CustomTokenSignInProps) {
  if (!token.length || !firebase) {
    return;
  }

  await signInWithCustomToken(firebase, token);
}

export const useCustomTokenSignIn = () =>
  useMutation({
    mutationKey: ["customTokenSignIn", "firebase"],
    mutationFn: (props: CustomTokenSignInProps) => customTokenSignIn(props),
  });
