import { Search } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import React, { type FC } from "react";

import { Input } from "#shared/components/inputs/input";
import type { Texts } from "#shared/types";

const DEBOUNCE_TIMER = 275;

export interface SettingsSearchProps {
  disabled?: boolean;
  onSearch?: (searchPhrase: string) => void;
  texts?: Texts<Text>;
}

type Text = "search";

const enTexts: Required<SettingsSearchProps["texts"]> = {
  search: "Search",
};

export const SettingsSearch: FC<SettingsSearchProps> = ({
  disabled,
  onSearch,
  texts = enTexts,
}) => {
  let timer: number | null | NodeJS.Timeout = null;

  React.useEffect(
    () => () => {
      if (timer) window.clearTimeout(timer);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Input
      size="small"
      placeholder={texts.search}
      sx={{ gridArea: "search" }}
      disabled={disabled}
      onChange={(event) => {
        if (onSearch) {
          if (timer) window.clearTimeout(timer);
          const { value } = event.currentTarget;

          timer = setTimeout(() => {
            onSearch(value);
          }, DEBOUNCE_TIMER);
        }
      }}
      InputProps={{
        sx: { minWidth: "120px", maxWidth: "400px" },
        startAdornment: (
          <InputAdornment position="start">
            <Search sx={{ color: (theme) => theme.palette.grey[500] }} />
          </InputAdornment>
        ),
      }}
    />
  );
};
