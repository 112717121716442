import { Typography, type SelectChangeEvent, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import React, {
  type Dispatch,
  type FC,
  type SetStateAction,
  type ChangeEvent,
} from "react";

import { Input } from "#shared/components/inputs/input";
import type { Texts } from "#shared/types";

import { menuProps } from "#organization/pages/authenticated/policies/create-policy/components/form-builder/inputs";
import { SectionSelect } from "#organization/pages/authenticated/settings/components/styled";
import {
  PROJECT_CLOUD_PROVIDERS,
  type CloudProvider,
} from "#organization/pages/authenticated/settings/organization/projects/project-regions";

import { GridContainer } from "./styled";

import { useGetDefaultProject } from "../../hooks";
import type { NewProject } from "../../types";

interface CreateProjectTabProps {
  project: NewProject;
  setProject: Dispatch<SetStateAction<NewProject>>;
  texts?: Texts<Text>;
}

type Text =
  | "projectName"
  | "projectDescription"
  | "projectCloudProvider"
  | "projectRegion"
  | "skipProject"
  | "projectNamePlaceholder"
  | "projectDescriptionPlaceholder"
  | "changeLater";

const enText: Texts<Text> = {
  projectName: "Project name",
  projectDescription: "Project description",
  projectCloudProvider: "Cloud provider",
  projectRegion: "Region",
  skipProject:
    "Leaving the project name and description empty will create a project named",
  projectNamePlaceholder: "Backend Team",
  projectDescriptionPlaceholder:
    "Rate limiting, caching and prioritization for backend services.",
  changeLater: "You will be able to change it later in settings.",
};

export const CreateProjectTab: FC<CreateProjectTabProps> = ({
  project,
  setProject,
  texts = enText,
}) => {
  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProject({ ...project, name: event.target.value });
  };

  const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProject({ ...project, description: event.target.value });
  };

  const onCloudProviderChange = (event: SelectChangeEvent<unknown>) => {
    setProject({
      ...project,
      cloudProvider: event.target.value as CloudProvider,
      region: PROJECT_CLOUD_PROVIDERS[event.target.value as CloudProvider][0],
    });
  };

  const onRegionChange = (event: SelectChangeEvent<unknown>) => {
    setProject({ ...project, region: event.target.value as string });
  };

  const defaultProject = useGetDefaultProject();

  const helperText = [
    texts.skipProject,
    `"${defaultProject.data?.name || "DefaultProject"}".`,
    texts.changeLater,
  ].join(" ");

  return (
    <GridContainer>
      <Typography>{texts.projectName}</Typography>
      <Input
        size="small"
        fullWidth
        width="auto"
        value={project.name}
        onChange={onNameChange}
        placeholder={texts.projectNamePlaceholder}
      />
      <Typography alignSelf="start">{texts.projectDescription}</Typography>
      <Input
        size="small"
        rows={4}
        multiline
        fullWidth
        width="auto"
        value={project.description}
        onChange={onDescriptionChange}
        placeholder={texts.projectDescriptionPlaceholder}
      />
      <Typography>{texts.projectCloudProvider}</Typography>
      <SectionSelect
        value={project.cloudProvider}
        size="small"
        sx={{ width: "auto" }}
        onChange={onCloudProviderChange}
        MenuProps={menuProps}
        renderValue={(data) => (data as string).toUpperCase()}
      >
        {Object.keys(PROJECT_CLOUD_PROVIDERS).map((cloudProvider) => (
          <MenuItem key={cloudProvider} value={cloudProvider}>
            {cloudProvider.toUpperCase()}
          </MenuItem>
        ))}
      </SectionSelect>
      <Typography>{texts.projectRegion}</Typography>
      <SectionSelect
        value={project.region}
        size="small"
        sx={{ width: "auto" }}
        onChange={onRegionChange}
        MenuProps={menuProps}
        renderValue={(data) => data as string}
      >
        {PROJECT_CLOUD_PROVIDERS[project.cloudProvider].map((region) => (
          <MenuItem key={region} value={region}>
            {region}
          </MenuItem>
        ))}
      </SectionSelect>
      <Box />
      <Typography color="text.secondary" variant="caption" alignSelf="start">
        {helperText}
      </Typography>
    </GridContainer>
  );
};
