import React, { type FC } from "react";

import {
  LoaderLayout,
  ErrorLayout,
  ErrorText,
  NoDataLayout,
} from "#shared/components/layouts";
import type { Policy } from "#shared/generated/graphql";
import type { Texts } from "#shared/types";

import type { FlowChartStateResults } from "../../../../config-flow-chart";
import { FlowChart } from "../flow-chart";

export type PolicyCircuitProps = {
  texts?: Texts<Text>;
  policy: Policy;
  flowChart: FlowChartStateResults;
};

type Text = "noData";

const enTexts: Required<PolicyCircuitProps["texts"]> = {
  noData: "No policy circuit to display.",
};

export const PolicyCircuit: FC<PolicyCircuitProps> = (props) => {
  const { policy, flowChart, texts = enTexts } = props;

  const { isLoading, error, noData, setNodes, ...flowChartState } = flowChart;

  if (isLoading) {
    return <LoaderLayout />;
  }

  if (error) {
    return (
      <ErrorLayout>
        <ErrorText {...{ error }} />
      </ErrorLayout>
    );
  }

  if (noData) {
    return <NoDataLayout>{texts.noData}</NoDataLayout>;
  }

  return <FlowChart {...flowChartState} policyId={policy.id || null} />;
};
