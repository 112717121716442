import { FiberManualRecord } from "@mui/icons-material";
import { Box, SvgIcon } from "@mui/material";
import React, { type FC } from "react";

interface HealthStatusProps {
  status: string;
  showText?: boolean;
  showIcon?: boolean;
}

export function statusTextColors(element: string) {
  if (element.includes("applying") || element.includes("pending"))
    return "info.light";

  if (
    element.includes("online") ||
    element.includes("applied") ||
    element.includes("active")
  )
    return "success.main";

  if (element.includes("unhealthy")) return "warning.main";

  if (element.includes("disconnected")) return "error.light";

  if (element.includes("error")) return "error.light";

  if (element.includes("archived") || element.includes("paused"))
    return "grey.500";

  if (element.includes("draft")) return "plum.main";

  if (element.includes("deleting")) return "info.light";

  return "text.secondary";
}

export function statusIconColors(element: string) {
  if (element.includes("applying") || element.includes("pending"))
    return "info.light";

  if (
    element.includes("online") ||
    element.includes("applied") ||
    element.includes("active")
  )
    return "success.main";

  if (element.includes("unhealthy")) return "warning.main";

  if (element.includes("disconnected")) return "error.main";

  if (element.includes("error")) return "error.main";

  if (element.includes("archived") || element.includes("paused"))
    return "grey.400";

  if (element.includes("draft")) return "plum";

  if (element.includes("deleting")) return "info.light";

  return "disabled";
}

export function statusText(element: string) {
  if (element.includes("applying")) return "applying...";

  if (element.includes("online")) return "online";

  if (element.includes("applied")) return "applied";

  if (element.includes("unhealthy")) return "error";

  if (element.includes("error")) return "error";

  if (element.includes("disconnected")) return "disconnected";

  if (element.includes("archived")) return "archived";

  if (element.includes("draft")) return "draft";

  if (element.includes("deleting")) return "deleting...";

  if (element.includes("active")) return "active";

  if (element.includes("paused")) return "paused";

  if (element.includes("pending")) return "pending...";

  return "disabled";
}

export const HealthStatus: FC<HealthStatusProps> = ({
  status,
  showText = true,
  showIcon = true,
}) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      width: "fit-content",
      flexShrink: 0,
    }}
    title={statusText(status)}
  >
    {showIcon && (
      <>
        {!status.includes("draft") ? (
          <FiberManualRecord
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              color: statusIconColors(status),
            }}
          />
        ) : (
          <SvgIcon
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            sx={{
              display: "flex",
              alignItems: "center",
              color: "transparent",
            }}
          >
            <circle
              cx="6"
              cy="6"
              r="3.5"
              stroke={statusIconColors(status)}
              strokeLinecap="round"
              strokeDasharray="2 2"
            />
          </SvgIcon>
        )}
      </>
    )}

    {showText && (
      <Box
        color={statusTextColors(status)}
        sx={{ pl: showIcon ? 0.8 : 0, fontSize: "0.875rem" }}
      >
        {statusText(status)}
      </Box>
    )}
  </Box>
);
