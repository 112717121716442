# Max Idle Time

## Overview

The `max_idle_time` sets the maximum time before the concurrency count for a label is removed. By default, it is set to `7200s`.


## Example

For instance, setting `max_idle_time` to '120s' means the concurrency count will retain its state for 2 minutes of inactivity. After this period without requests, the concurrency count will be removed.

## Note

This field employs the [Duration](https://protobuf.dev/programming-guides/proto3/#json) JSON representation from Protocol Buffers. The format accommodates fractional seconds up to nine digits after the decimal point, offering nanosecond precision. Every duration value must be suffixed with an "s" to indicate 'seconds.'
