import React, { type FC } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { PaymentGateway } from "#organization/components/payment-gateway";
import { ProjectContextProvider } from "#organization/components/project-context-provider";
import { useOrganizationState } from "#organization/recoil/organization";

import { SidebarRoutes } from "./sidebar-routes";
import { OrganizationsProvider } from "./sidebar/organizations-context";
import { WELCOME_ROUTE, WelcomeRoutes } from "./welcome";

export const AuthenticatedPages: FC = () => {
  const organizationState = useOrganizationState();

  return (
    <PaymentGateway>
      <ProjectContextProvider>
        {organizationState.onboarded ? (
          <OrganizationsProvider>
            <SidebarRoutes />
          </OrganizationsProvider>
        ) : (
          <Routes>
            <Route
              path={WELCOME_ROUTE.ABSOLUTE_PATH}
              element={<WelcomeRoutes />}
            />
            <Route
              path="*"
              element={<Navigate to={WELCOME_ROUTE.ABSOLUTE_PATH} />}
            />
          </Routes>
        )}
      </ProjectContextProvider>
    </PaymentGateway>
  );
};
