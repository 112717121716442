import { useMemo } from "react";

import type {
  NodeData,
  UiData,
} from "#organization/pages/authenticated/policies/policy/types";

import type { UseMenuOptions, UseMenuReturnType } from "./types";

import {
  CIRCUIT_VIEW_RESOURCES,
  type CircuitViewResource,
} from "../../../selected-resource-context";

export const useComponentsMenu = (
  options: Pick<UseMenuOptions<NodeData>, "policy" | "search">,
): UseMenuReturnType => {
  const { policy, search } = options;

  const listItems = useMemo(
    () =>
      policy.circuit?.actuators
        ?.filter((v) =>
          search?.length
            ? v?.componentName?.toLowerCase().includes(search.toLowerCase())
            : v,
        )
        ?.map((actuator) => {
          const uiData: UiData = {
            id: actuator?.componentId || "",
            name: actuator?.componentName || "",
            componentName: !CIRCUIT_VIEW_RESOURCES.includes(
              (actuator?.componentName || "") as CircuitViewResource,
            )
              ? "ConcurrencyLimiter"
              : actuator?.componentName || "",
            componentType: actuator?.componentType || "",
            component: actuator?.component || "",
          };

          return {
            ...actuator,
            uiData,
          };
        }) || [],
    [policy, search],
  );

  return {
    listItems,
  };
};
