import React, { type FC } from "react";
import { Route, Routes } from "react-router-dom";

import { useSetPageTitle } from "#shared/hooks";

import { TopBarLayout } from "#organization/components/layouts";

import { CachePage } from "./page";
import { CACHE_ROUTE } from "./routes.definitions";

export const CacheRoutes: FC = () => {
  useSetPageTitle(CACHE_ROUTE.TITLE);

  return (
    <TopBarLayout>
      <Routes>
        <Route index element={<CachePage />} />
        <Route path="*" element={<CachePage />} />
      </Routes>
    </TopBarLayout>
  );
};
