import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Typography } from "@mui/material";
import React, { type FC, useState } from "react";
import type { FieldValues } from "react-hook-form";

import type { Texts } from "#shared/types";

import { DataGrid } from "#organization/components/data-grid";
import { FlyoutMenu } from "#organization/components/flyout-menu";
import {
  FlyoutMenuHeaderContainer,
  FlyoutMenuContentContainer,
} from "#organization/components/flyout-menu/styled";
import type { DataGridHook } from "#organization/hooks";

import { channelTableHead } from "./components";

import { ChannelForm } from "../../components";
import { useAlertsChannels, useDataCenterContext } from "../../data-center";
import { RowWrapper } from "../../styled";

const INITIAL_ORDER_BY = "id";
const INITIAL_ORDER = "asc";

export interface ChannelsProps {
  texts?: Texts<Text>;
}

type Text = "title" | "subtitle" | "editTitle" | "editSubtitle";

const enTexts: Required<ChannelsProps["texts"]> = {
  title: "Add new channel",
  subtitle: "Add channels and set notifiers",
  editTitle: "Edit channel",
  editSubtitle: "Edit channel and set notifiers",
};

export const Channels: FC<ChannelsProps> = ({ texts = enTexts }) => {
  const [editChannel, setEditChannel] = useState(false);
  const [isAddChannelOpen, setIsAddChannelOpen] = useState(false);
  const [editFormValues, setEditFormValues] = useState<FieldValues>({});
  const { projectID } = useDataCenterContext();

  const filterVariables = {
    where: {
      projectID: { eq: projectID },
    },
  };

  return (
    <Box px={3}>
      <RowWrapper
        sx={{
          justifyContent: "flex-start",
          marginBottom: 3,
        }}
      >
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setIsAddChannelOpen(true)}
          disabled={isAddChannelOpen}
        >
          {texts.title}
        </Button>
      </RowWrapper>
      {!editChannel && (
        <FlyoutMenu useShowHide={[isAddChannelOpen, setIsAddChannelOpen]}>
          {isAddChannelOpen && (
            <>
              <FlyoutMenuHeaderContainer>
                <Typography component="h1" variant="h1">
                  {texts.title}
                </Typography>
              </FlyoutMenuHeaderContainer>
              <FlyoutMenuContentContainer sx={{ maxWidth: 1200 }}>
                <Typography fontSize={16} mb={3} mt={3}>
                  {texts.subtitle}
                </Typography>
                <ChannelForm />
              </FlyoutMenuContentContainer>
            </>
          )}
        </FlyoutMenu>
      )}

      {!isAddChannelOpen && (
        <FlyoutMenu useShowHide={[editChannel, setEditChannel]}>
          {editChannel && (
            <>
              <FlyoutMenuHeaderContainer>
                <Typography component="h1" variant="h1">
                  {texts.editTitle}
                </Typography>
              </FlyoutMenuHeaderContainer>
              <FlyoutMenuContentContainer sx={{ maxWidth: 1200 }}>
                <Typography fontSize={16} mb={3} mt={3}>
                  {texts.editSubtitle}
                </Typography>
                <ChannelForm defaultValues={editFormValues} formMode="update" />
              </FlyoutMenuContentContainer>
            </>
          )}
        </FlyoutMenu>
      )}
      <DataGrid
        headCells={channelTableHead(setEditChannel, setEditFormValues)}
        useGridData={useAlertsChannels as DataGridHook}
        initialOrderBy={INITIAL_ORDER_BY}
        initialOrder={INITIAL_ORDER}
        enabled={false}
        filterVariables={filterVariables}
      />
    </Box>
  );
};
