import {
  type RecoilValueReadOnly,
  selectorFamily,
  RecoilState,
  atom,
} from "recoil";

import { ATOM_KEYS } from "#shared/consts";

import { FirebaseUserRecoilState } from "./user-firebase";

import { type UserRole } from "../consts";
import { type FirebaseUser } from "../firebase.types";
import {
  HttpRequestRecoilState,
  type HttpRequestState,
} from "../http-request-state";
import { type User } from "../types";

type StateId = string;

export const permissionsState = atom<string | null>({
  key: "permissions",
  default: null,
});

class UserRecoilState extends HttpRequestRecoilState<StateId, User | null> {
  public static override readonly ATOM_KEYS = {
    ...HttpRequestRecoilState.ATOM_KEYS,
    email: "email",
    isAuthenticated: "isAuthenticated",
    userRole: "userRole",
  };

  public email: RecoilValueReadOnly<string | null>;

  public isAuthenticated: RecoilValueReadOnly<boolean>;

  public readonly firebase: FirebaseUserRecoilState;

  public readonly isAuthenticatedInFirebase: RecoilValueReadOnly<boolean>;

  public readonly firebaseToken: RecoilValueReadOnly<string | null>;

  public readonly firebaseUser: RecoilState<
    HttpRequestState<FirebaseUser | null, string | null>
  >;

  public readonly userRole: RecoilValueReadOnly<UserRole | null>;

  public permissions: RecoilState<"read" | "write" | string | null>;

  constructor(atomPrefix: StateId, firebaseAtomPrefix: StateId) {
    super(atomPrefix);

    this.firebase = new FirebaseUserRecoilState(firebaseAtomPrefix);

    this.email = selectorFamily<string | null, StateId>({
      key: UserRecoilState.ATOM_KEYS.email,
      get:
        () =>
        ({ get }) =>
          get(this.state).data?.email || null,
    })(this.atomPrefix);

    this.isAuthenticated = selectorFamily<boolean, StateId>({
      key: UserRecoilState.ATOM_KEYS.isAuthenticated,
      get:
        () =>
        ({ get }) =>
          !!get(this.state).data?.id,
    })(this.atomPrefix);

    this.userRole = selectorFamily<UserRole | null, StateId>({
      key: UserRecoilState.ATOM_KEYS.userRole,
      get:
        () =>
        ({ get }) =>
          get(this.state).data?.organization?.role || null,
    })(atomPrefix);

    this.isAuthenticatedInFirebase = this.firebase.isAuthenticated;
    this.firebaseToken = this.firebase.token;

    this.firebaseUser = this.firebase.state;

    this.permissions = permissionsState;

    this.isLoading = selectorFamily({
      key: UserRecoilState.ATOM_KEYS.isLoading,
      get:
        () =>
        ({ get }) =>
          get(this.state).httpState.isLoading ||
          get(this.state).httpState.isIdle,
    })(this.atomPrefix);
  }
}

export const userNewState = new UserRecoilState(
  ATOM_KEYS.newUser,
  ATOM_KEYS.firebaseUser,
);
