import { NavigateNext } from "@mui/icons-material";
import { Button, CircularProgress, Link, Typography } from "@mui/material";
import { Box, alpha, hexToRgb } from "@mui/system";
import React, { type FC, type PropsWithChildren } from "react";

import { GlobalFrame } from "#shared/components/layouts";
import logo from "#shared/logo-sad.svg";
import type { Texts } from "#shared/types";
import { getGlobalAppUrl } from "#shared/utils";

import {
  useGetOrganizationData,
  useOrganizationState,
} from "#organization/recoil/organization";

export type BillingNotDoneProps = PropsWithChildren<{
  texts?: Texts<Text>;
}>;

type Text = "title" | "message" | "button" | "error";

const enTexts: Required<BillingNotDoneProps["texts"]> = {
  title: "Oops!",
  button: "Complete checkout",
  message:
    "It seems that you haven't completed the subscription checkout process or your existing subscription has expired.",
  error: "Something went wrong, please try again later",
};

export const BillingNotDone: FC<BillingNotDoneProps> = ({
  texts = enTexts,
}) => {
  const { subdomain } = useOrganizationState();
  const { organization, isLoading, isError } =
    useGetOrganizationData(subdomain);

  return (
    <GlobalFrame
      hideLogout
      logoSrc={logo}
      logoStyle={{ transform: "scale(1)" }}
    >
      <Box display="flex" justifyContent="center" mt={3}>
        <Typography
          variant="h5"
          textAlign="center"
          color="primary.main"
          sx={{
            background: (theme) =>
              alpha(hexToRgb(theme.palette.primary.main), 0.08),
            p: 1,
            borderRadius: 2,
            wordBreak: "break-word",
          }}
        >
          {window.location.hostname}
        </Typography>
      </Box>

      <Box
        sx={{
          background: (theme) => theme.palette.background.bgOnPaper,
          mt: 4,
          borderRadius: 1,
          px: 2.5,
          py: 3,
          mb: 4,
          display: "grid",
          rowGap: 3,
        }}
      >
        <Typography variant="h4" textAlign="center">
          {texts.title}
        </Typography>

        <Typography variant="body1" textAlign="center">
          {texts.message}
        </Typography>

        <Box px={14}>
          <Link href={getGlobalAppUrl(`/billing/${organization?.id}`)}>
            <Button
              variant="contained"
              fullWidth
              disabled={isLoading || isError}
              endIcon={
                isLoading ? (
                  <CircularProgress color="secondary" size={20} />
                ) : (
                  <NavigateNext />
                )
              }
            >
              {!isLoading && isError ? texts.error : texts.button}
            </Button>
          </Link>
        </Box>
      </Box>
    </GlobalFrame>
  );
};
