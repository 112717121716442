import { gql } from "graphql-request";

import { useGqlMutation } from "#shared/utils";

export const RemoveUserFromUserGroupMutation = gql`
  mutation removeUserFromUserGroup($input: DeleteUserGroupMembershipInput!) {
    removeUserFromUserGroup(input: $input) {
      userGroupMembership {
        id
        userGroup {
          id
        }
        user {
          email
        }
      }
    }
  }
`;

// TODO: User should be also removed from IAB!
export const useRemoveUserFromUserGroup = () => {
  const { mutate, data, isError } = useGqlMutation<
    unknown,
    unknown,
    { id: string }
  >(RemoveUserFromUserGroupMutation);

  return {
    removeUser: mutate,
    data,
    error: isError,
  };
};
