import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Paper } from "@mui/material";
import React, { type FC } from "react";
import { useReactFlow } from "react-flow-renderer";

import { FlowChartButtons } from "./flow-chart-buttons";
import { PortLabelSwitch } from "./port-label-switch";
import { FlowChartControlsContainer } from "./style-components";

export interface FlowChartControlsProps {}

const DURATION = 800;

export const FlowChartControls: FC<FlowChartControlsProps> = () => {
  const { zoomIn, zoomOut } = useReactFlow();

  return (
    <FlowChartControlsContainer component={Paper} mb={1}>
      <FlowChartButtons
        btnLeftClick={() => zoomIn({ duration: DURATION })}
        btnRightClick={() => zoomOut({ duration: DURATION })}
        btnStyle={{ minWidth: 35, paddingBottom: 0 }}
        wrpLftElement={<AddIcon color="primary" />}
        wrpRgtElement={<RemoveIcon color="primary" />}
        zoomBtn
      />
      <PortLabelSwitch />
    </FlowChartControlsContainer>
  );
};
