import { gql } from "graphql-request";
import { identity } from "lodash";

import type {
  DiscoveryKubernetesGroupsTitlesQuery,
  DiscoveryKubernetesKindCountQuery,
  KubernetesControlPoint as ControlPoint,
} from "#shared/generated/graphql";
import { useGqlQuery } from "#shared/utils";

import {
  type DataGridHook,
  type GroupsTitlesHook,
  useGridData,
  useGroupsTitles,
  useGroupData,
  type GroupDataHook,
} from "#organization/hooks";

export type KubernetesControlPoint = Pick<
  ControlPoint,
  "name" | "namespace" | "kind" | "id" | "controller" | "lastSeen" | "agent"
>;

const discoveryKubernetesKindCount = gql`
  query DiscoveryKubernetesKindCount(
    $replicaSet: KubernetesControlPointBoolExp
    $statefulSets: KubernetesControlPointBoolExp
    $deployments: KubernetesControlPointBoolExp
  ) {
    replicaSet: kubernetesControlPoints(where: $replicaSet) {
      totalCount
    }
    statefulSets: kubernetesControlPoints(where: $statefulSets) {
      totalCount
    }
    deployments: kubernetesControlPoints(where: $deployments) {
      totalCount
    }
  }
`;

export const useDiscoveryKubernetesKindCount = (projectId: string) => {
  const { data, isLoading, isError, refetch } =
    useGqlQuery<DiscoveryKubernetesKindCountQuery>(
      ["discovery-kubernetes-kind-count"],
      discoveryKubernetesKindCount,
      {
        replicaSet: {
          kind: { eq: "ReplicaSet" },
          projectID: { eq: projectId },
        },
        statefulSets: {
          kind: { eq: "StatefulSet" },
          projectID: { eq: projectId },
        },
        deployments: {
          kind: { eq: "Deployment" },
          projectID: { eq: projectId },
        },
      },
    );

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};

export type DiscoveryKubernetesGroupBy = "kind" | "namespace" | "apiVersion";

const DISCOVERY_KUBERNETES_ENTITY_KEY = "kubernetesControlPoints";

export const discoveryKubernetesGroupsTitles = gql`
  query DiscoveryKubernetesGroupsTitles(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: KubernetesControlPointBoolExp
    $distinctOn: [KubernetesControlPointSelectColumn!]
  ) {
    kubernetesControlPoints(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      distinctOn: $distinctOn
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          name
          kind
          namespace
          apiVersion
          status
          controller {
            name
          }
          agent {
            agentGroup
          }
          lastSeen
        }
      }
    }
  }
`;

type DiscoveryKubernetesTitleData =
  DiscoveryKubernetesGroupsTitlesQuery["kubernetesControlPoints"]["edges"][0]["node"];

export const useDiscoveryKubernetesGroupsTitles: GroupsTitlesHook<
  DiscoveryKubernetesGroupBy
> = (pageSize, page, setPage, groupByKey, filterVariables) =>
  useGroupsTitles<DiscoveryKubernetesGroupBy, DiscoveryKubernetesTitleData>(
    DISCOVERY_KUBERNETES_ENTITY_KEY,
    pageSize,
    page,
    setPage,
    groupByKey,
    discoveryKubernetesGroupsTitles,
    (node) => node[groupByKey!],
    filterVariables,
  );

export const discoveryKubernetesGroupData = gql`
  query KubernetesControlPoint(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: KubernetesControlPointBoolExp
    $distinctOn: [KubernetesControlPointSelectColumn!]
    $orderBy: [KubernetesControlPointOrderBy!]
  ) {
    kubernetesControlPoints(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      distinctOn: $distinctOn
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          name
          kind
          namespace
          apiVersion
          status
          controller {
            name
          }
          agent {
            agentGroup
          }
          lastSeen
        }
      }
    }
  }
`;

export const useDiscoveryKubernetesGroupData: GroupDataHook<
  DiscoveryKubernetesGroupBy
> = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  groupByKey,
  groupByValue,
  filterVariables,
) =>
  useGroupData<
    DiscoveryKubernetesGroupBy,
    KubernetesControlPoint,
    KubernetesControlPoint
  >(
    DISCOVERY_KUBERNETES_ENTITY_KEY,
    discoveryKubernetesGroupData,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    groupByKey,
    groupByValue,
    identity,
    filterVariables,
  );

export const useDiscoveryKubernetesData: DataGridHook = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  enabled,
  filterVariables,
) =>
  useGridData<KubernetesControlPoint, KubernetesControlPoint>(
    DISCOVERY_KUBERNETES_ENTITY_KEY,
    discoveryKubernetesGroupData,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    filterVariables,
    identity,
    enabled,
  );
