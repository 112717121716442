import { gql } from "graphql-request";

import type {
  DeleteProjectInput,
  DeleteProjectMutation,
  PauseProjectInput,
  PauseProjectMutation,
  ResumeProjectInput,
  ResumeProjectMutation,
} from "#shared/generated/graphql";
import { useGqlMutation } from "#shared/utils";

const DeleteProject = gql`
  mutation DeleteProject($input: DeleteProjectInput!) {
    deleteProject(input: $input) {
      project {
        name
      }
    }
  }
`;

export const useDeleteProject = () => {
  const { mutate, isLoading, isError, data } = useGqlMutation<
    DeleteProjectMutation,
    unknown,
    DeleteProjectInput
  >(DeleteProject);

  return {
    remove: mutate,
    data,
    error: isError,
    loading: isLoading,
  };
};

const PauseProject = gql`
  mutation PauseProject($input: PauseProjectInput!) {
    pauseProject(input: $input) {
      project {
        name
      }
    }
  }
`;

export const usePauseProject = () => {
  const { mutate, isLoading, isError, data } = useGqlMutation<
    PauseProjectMutation,
    unknown,
    PauseProjectInput
  >(PauseProject);

  return {
    pause: mutate,
    data,
    error: isError,
    loading: isLoading,
  };
};

const ResumeProject = gql`
  mutation ResumeProject($input: ResumeProjectInput!) {
    resumeProject(input: $input) {
      project {
        name
      }
    }
  }
`;

export const useResumeProject = () => {
  const { mutate, isLoading, isError, data } = useGqlMutation<
    ResumeProjectMutation,
    unknown,
    ResumeProjectInput
  >(ResumeProject);

  return {
    resume: mutate,
    data,
    error: isError,
    loading: isLoading,
  };
};
