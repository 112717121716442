import { NavigateNext } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, type FC } from "react";
import { useNavigate } from "react-router-dom";

import { Analytics } from "#shared/analytics";
import { GlobalFrame } from "#shared/components/layouts";
import logoSuccesful from "#shared/logo-succesfully.svg";
import type { Texts } from "#shared/types";

type Text = "success" | "thankYou" | "proceed" | "billingSettingsReview";

interface PaymentSuccessfulProps {
  texts?: Texts<Text>;
}

const enTexts: Required<PaymentSuccessfulProps["texts"]> = {
  success: "Success!",
  thankYou: "Thank you, your subscription was processed successfully.",
  billingSettingsReview:
    "You will be able to review your payments, change or cancel your plan and get your receipts in the Organization settings.",
  proceed: "Proceed",
};

export const PaymentSuccessful: FC<PaymentSuccessfulProps> = ({
  texts = enTexts,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    Analytics.track("paid_for_a_plan");
  }, []);

  return (
    <GlobalFrame hideLogout hideLogo>
      <Box display="grid" justifyItems="center" mb={4}>
        <img src={logoSuccesful} alt="happy fluxninja logo" />
        <Box
          sx={{
            background: (theme) => theme.palette.background.bgOnPaper,
            borderRadius: 1,
            px: 2.5,
            width: "100%",
            py: 3,
          }}
        >
          <Box display="flex" flexDirection="column" px={18} gap={4}>
            <Typography variant="h4" textAlign="center">
              {texts.success}
            </Typography>
            <Typography variant="h6" textAlign="center">
              {texts.thankYou}
            </Typography>

            <Button
              variant="contained"
              onClick={() => {
                navigate("/");
              }}
              endIcon={<NavigateNext />}
            >
              {texts.proceed}
            </Button>
            <Typography variant="body2" textAlign="center">
              {texts.billingSettingsReview}
            </Typography>
          </Box>
        </Box>
      </Box>
    </GlobalFrame>
  );
};
