import { GracefulProvider, useGraceful } from "@fluxninja/graceful-js";
import React, { useEffect, type FC, type PropsWithChildren } from "react";

import { API_SERVICE_URL } from "#shared/consts";
import { ENV } from "#shared/env";
import { useLogout } from "#shared/hooks";
import { axiosApi } from "#shared/utils";

const urlList =
  ENV.VITE_APP === "organization" ? [`${API_SERVICE_URL}/query`] : undefined;

/**
 * Using graceful-js to handle logout on 401. We are monitoring only graphQL requests in organization app.
 * And we are monitoring all requests in the global app.
 */
export const UiGracefulProvider: FC<PropsWithChildren> = ({ children }) => (
  <GracefulProvider
    config={{
      axios: axiosApi,
      urlList,
      maxBackOffTime: 5,
    }}
  >
    <LogoutOn401>{children}</LogoutOn401>
  </GracefulProvider>
);

export const LogoutOn401: FC<PropsWithChildren> = ({ children }) => {
  const { ctx } = useGraceful();

  const logout = useLogout(ENV.VITE_APP === "organization");

  useEffect(() => {
    const { status } = ctx;

    if (status !== 401) return;

    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx]);

  return <>{children}</>;
};
