import { SvgIcon, type SvgIconProps } from "@mui/material";
import React, { type FC, forwardRef, type PropsWithRef } from "react";

export const CacheIcon: FC<PropsWithRef<SvgIconProps>> = forwardRef(
  (props, ref) => (
    <SvgIcon {...props} ref={ref}>
      <path d="M14.0713 0H2.07129C0.971289 0 0.0712891 0.9 0.0712891 2V14C0.0712891 15.1 0.971289 16 2.07129 16H14.0713C15.1713 16 16.0713 15.1 16.0713 14V2C16.0713 0.9 15.1713 0 14.0713 0ZM15.0713 14C15.0713 14.6 14.6713 15 14.0713 15H2.07129C1.47129 15 1.07129 14.6 1.07129 14V11H15.0713V14ZM15.0713 10H1.07129V6H15.0713V10ZM1.07129 5V2C1.07129 1.4 1.47129 1 2.07129 1H14.0713C14.6713 1 15.0713 1.4 15.0713 2V5H1.07129ZM14.0713 3.5C14.0713 3.8 13.8713 4 13.5713 4H12.5713C12.2713 4 12.0713 3.8 12.0713 3.5V2.5C12.0713 2.2 12.2713 2 12.5713 2H13.5713C13.8713 2 14.0713 2.2 14.0713 2.5V3.5ZM14.0713 8.5C14.0713 8.8 13.8713 9 13.5713 9H12.5713C12.2713 9 12.0713 8.8 12.0713 8.5V7.5C12.0713 7.2 12.2713 7 12.5713 7H13.5713C13.8713 7 14.0713 7.2 14.0713 7.5V8.5ZM14.0713 13.5C14.0713 13.8 13.8713 14 13.5713 14H12.5713C12.2713 14 12.0713 13.8 12.0713 13.5V12.5C12.0713 12.2 12.2713 12 12.5713 12H13.5713C13.8713 12 14.0713 12.2 14.0713 12.5V13.5Z" />
    </SvgIcon>
  ),
);
