import { noop, pick } from "lodash";
import {
  createContext,
  type Dispatch,
  type SetStateAction,
  useContext,
} from "react";

import type { ComponentView, Maybe } from "#shared/generated/graphql";

export declare type CircuitBreadcrumb = {
  id: string;
  name: string;
};

export declare type CircuitContextProps = {
  nestedCircuitSelected: boolean;
  nestedCircuitGqlString: string;
  createGqlString: (value: string) => string;
  circuitBreadcrumb: CircuitBreadcrumb[] | [];
  currentPolicyName: string | null;
  circuitDispatch: Dispatch<CircuitReducerAction> | null;
  updateCircuitBreadcrumb: (
    value: CircuitBreadcrumb,
    state: CircuitContextProps,
  ) => CircuitBreadcrumb[];
  circuitLabelStatus: boolean;
  isFlyoutMenuVisible: boolean;
  setIsFlyoutMenuVisible: Dispatch<SetStateAction<boolean>>;
  onHoverSignal: {
    animated: boolean;
    id: string | null;
  };
  externalComponents: ComponentView[] | null | readonly Maybe<ComponentView>[];
};

export const circuitContext: CircuitContextProps = {
  nestedCircuitSelected: false,
  nestedCircuitGqlString: "",
  createGqlString: (value: string) => {
    if (!value.length) return "";

    const nestedIndexes = value.split(".").slice(1);

    return nestedIndexes.map((index) => `children[${index}]`).join(".");
  },
  circuitDispatch: null,
  circuitBreadcrumb: [],
  currentPolicyName: null,
  updateCircuitBreadcrumb: (value, state) => {
    const { circuitBreadcrumb, currentPolicyName } = state;

    if (!currentPolicyName) return [];

    if (!circuitBreadcrumb?.length) {
      return [
        {
          id: "root",
          name: currentPolicyName,
        },
        value,
      ];
    }

    if (
      circuitBreadcrumb?.length &&
      circuitBreadcrumb.some((breadcrumb) => breadcrumb.id === value.id)
    ) {
      return circuitBreadcrumb;
    }

    return [...circuitBreadcrumb, value];
  },
  circuitLabelStatus: false,
  isFlyoutMenuVisible: false,
  setIsFlyoutMenuVisible: noop,
  onHoverSignal: {
    animated: false,
    id: null,
  },
  externalComponents: null,
};

export const CircuitContext =
  createContext<CircuitContextProps>(circuitContext);
export const useCircuitContext = () => useContext(CircuitContext);

export declare type CircuitReducerAction =
  | {
      type: "selectedNestedCircuit";
      payload: Pick<
        CircuitContextProps,
        "nestedCircuitGqlString" | "nestedCircuitSelected"
      > & {
        circuitBreadcrumb: CircuitBreadcrumb;
      };
    }
  | {
      type: "setNestedCircuitSelected";
      payload: boolean;
    }
  | {
      type: "setNestedCircuitGqlString";
      payload: string;
    }
  | {
      type: "setCircuitBreadcrumb";
      payload: CircuitBreadcrumb[];
    }
  | {
      type: "updateCircuitBreadcrumb";
      payload: CircuitBreadcrumb;
    }
  | {
      type: "updateCurrentPolicyName";
      payload: string;
    }
  | {
      type: "reset";
      payload: undefined;
    }
  | {
      type: "setCircuitLabelStatus";
      payload: boolean;
    }
  | {
      type: "setIsOnHoverSignal";
      payload: {
        animated: boolean;
        id: string | null;
      };
    }
  | {
      type: "setExternalComponents";
      payload: CircuitContextProps["externalComponents"];
    };

export declare type CircuitReducer = (
  state: CircuitContextProps,
  action: CircuitReducerAction,
) => CircuitContextProps;

export const circuitReducer: CircuitReducer = (state, action) => {
  const { type, payload } = action;
  const { createGqlString, updateCircuitBreadcrumb, circuitBreadcrumb } = state;

  switch (type) {
    case "setNestedCircuitSelected":
      return {
        ...state,
        nestedCircuitSelected: payload,
      };
    case "setNestedCircuitGqlString":
      return {
        ...state,
        nestedCircuitGqlString: createGqlString(payload),
      };
    case "setCircuitBreadcrumb":
      return {
        ...state,
        circuitBreadcrumb: payload,
      };
    case "selectedNestedCircuit":
      return {
        ...state,
        nestedCircuitGqlString: createGqlString(payload.nestedCircuitGqlString),
        nestedCircuitSelected: payload.nestedCircuitSelected,
        circuitBreadcrumb: updateCircuitBreadcrumb(
          payload.circuitBreadcrumb,
          state,
        ),
      };
    case "updateCircuitBreadcrumb":
      return {
        ...state,
        circuitBreadcrumb: updateCircuitBreadcrumb(payload, state),
      };
    case "updateCurrentPolicyName":
      return {
        ...state,
        currentPolicyName: payload,
        circuitBreadcrumb: !circuitBreadcrumb?.length
          ? [{ id: "root", name: payload }]
          : circuitBreadcrumb,
      };
    case "setCircuitLabelStatus":
      return {
        ...state,
        circuitLabelStatus: payload,
      };
    case "reset":
      return {
        ...circuitContext,
        ...pick(state, "circuitDispatch", "currentPolicyName"),
      };
    case "setIsOnHoverSignal":
      return {
        ...state,
        onHoverSignal: payload,
      };
    case "setExternalComponents":
      return {
        ...state,
        externalComponents: payload,
      };
    default:
      return state;
  }
};
