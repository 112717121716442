import { get } from "lodash";

import type { Route } from "#shared/types";

export const getActiveVerticalTab = (
  pathName: string,
  verticalTabsRoutes: Route[],
) =>
  get(
    verticalTabsRoutes.filter((tab) => pathName.startsWith(tab.ABSOLUTE_PATH)),
    [0, "ABSOLUTE_PATH"],
  );
