import { alpha, type Theme } from "@mui/material";
import type { CSSProperties } from "react";

import type { FnTheme } from "../../../../app-theme-provider/types";

export const yamlCodeSnippetWrapper = (theme: Theme): CSSProperties => ({
  whiteSpace: "pre",
  borderRadius: theme.spacing(0.5),
  backgroundColor: `${
    (theme as unknown as FnTheme).palette.codeBlock.background
  } !important`,
  boxShadow: `4px 4px 0px ${alpha(
    (theme as unknown as FnTheme).palette.codeBlock.shadow,
    0.3,
  )}`,
  overflowY: "auto",
  maxHeight: 700,
  minHeight: 350,
});
