import type { ThemeOptions } from "@mui/material";

import type { FnTheme } from "../../types";

const styleOverrides: Required<
  Required<ThemeOptions>["components"]
>["MuiTableCell"]["styleOverrides"] = {
  head: ({ theme }) =>
    (theme as unknown as FnTheme).palette.mode === "light"
      ? {}
      : { borderBottomColor: "transparent" },
};

export const MuiTableCell: Required<
  Required<ThemeOptions>["components"]
>["MuiTableCell"] = { styleOverrides };
