import { gql } from "graphql-request";
import { merge } from "lodash";
import { useQuery } from "react-query";

import type { PolicyControllerQuery } from "#shared/generated/graphql";
import { useGqlQuery } from "#shared/utils";
import { gqlClient } from "#shared/utils/graphql-client";

import { QUERY_KEYS } from "#organization/pages/consts";
import type { UseFnQueryOptions } from "#organization/pages/types";

export const useDashboardDataQuery = (options: UseFnQueryOptions = {}) => {
  const { queryOptions = {}, variables, queryKey, gql: gqlString } = options;

  const mergedVariables = merge(
    {},
    {
      where: {},
      orderBy: [
        {
          id: "asc",
        },
      ],
      distinctOn: [],
    },
    variables || {},
  );

  const key = gqlString && queryKey ? queryKey : QUERY_KEYS.INVALID_QUERY;

  const keyWithVariables = [key, JSON.stringify(mergedVariables.where)].join(
    "",
  );

  return useQuery(
    keyWithVariables,
    () => {
      if (!gqlString) {
        return Promise.reject();
      }

      return gqlClient.request(gqlString, mergedVariables);
    },
    {
      enabled: true,
      keepPreviousData: true,
      ...queryOptions,
    },
  );
};

export const policyController = gql`
  query PolicyController($where: PolicyBoolExp) {
    policies(where: $where) {
      edges {
        node {
          name
          hash
          controller {
            id
          }
        }
      }
    }
  }
`;

export const usePolicyController = (id: string = "") => {
  const findBy = { where: { id: { eq: id } } };
  const { error, data, isLoading, isError } = useGqlQuery<
    unknown,
    unknown,
    PolicyControllerQuery
  >([QUERY_KEYS.POLICY_CONTROLLER, findBy], policyController, findBy);

  return {
    response: data,
    error,
    isLoading,
    isError,
    noData: !!data?.policies.edges[0].node.controller,
  };
};
