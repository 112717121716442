import { Box, useTheme } from "@mui/material";
import React, { type FC, type PropsWithChildren } from "react";

import type { FnTheme } from "../../../../../app-theme-provider/types";

export const TopBarLayout: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme() as FnTheme;

  const minHeight = `calc(100vh - ${theme.shape.topBarHeight} - ${theme.shape.policyView.headerHeight})`;

  return (
    <Box
      id="fn-top-bar-container-outer"
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight,
      }}
    >
      <Box
        id="fn-top-bar-container-inner"
        overflow="auto"
        sx={{
          backgroundColor: "background.default",
          maxHeight: "100%",
          height: "100%",
          minHeight,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
