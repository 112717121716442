import axios, { AxiosError, type AxiosResponse } from "axios";
import {
  capitalize,
  cloneDeep,
  merge,
  reduce,
  isEqual,
  find,
  uniqBy,
} from "lodash";
import { useMemo, useState, useCallback, useEffect, useRef } from "react";
import type { DeepRequired } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";

import { Analytics } from "#shared/analytics";
import type { AlertContent } from "#shared/components/alerts-provider";
import { useIsMounted } from "#shared/hooks";
import { PermissionEnum, userNewState, type UserRole } from "#shared/recoil";
import type { DeepPartial, Texts } from "#shared/types";

import { API_URLS, QUERY_KEYS } from "#organization/pages/consts";
import { useOrganizationState } from "#organization/recoil/organization";

import type {
  InvitedUserByEmailResponse,
  InviteMembersFormData,
  InviteUsersByEmailBody,
  InviteUsersByEmailResponse,
} from "./types";

export interface UseInviteMembersOptions {
  loading?: boolean;
  texts?: Texts<Text>;
  emptyMember?: Partial<InviteMembersFormData>;
}

type Text =
  | "generalError"
  | "invitation"
  | "invitations"
  | "hasBeenSent"
  | "haveBeenSent"
  | "notAll"
  | "clickAgainToSend"
  | "again"
  | "notSent";

export type PartialInvitedUserByEmailResponse = {
  isFilteredOut?: boolean;
  error: DeepPartial<NonNullable<InvitedUserByEmailResponse["error"]>> | null;
  success?: DeepPartial<
    NonNullable<InvitedUserByEmailResponse["success"]>
  > | null;
};

const enTexts: Required<UseInviteMembersOptions["texts"]> = {
  generalError: "Invitations not sent",
  invitation: "invitation",
  invitations: "invitations",
  hasBeenSent: "has been sent",
  haveBeenSent: "have been sent",
  notAll: "Not all",
  clickAgainToSend: "Click again to send",
  again: "again",
  notSent: "not sent",
};

const DEFAULT_OPTIONS: DeepRequired<UseInviteMembersOptions> = {
  loading: false,
  texts: enTexts,
  emptyMember: {
    id: "0",
    email: "",
    userRole: PermissionEnum.user_group_member,
    userGroup: "",
    isValid: false,
  },
};

export const useInviteMembers = (options: UseInviteMembersOptions = {}) => {
  const { loading, texts, emptyMember } = merge<
    DeepRequired<UseInviteMembersOptions>,
    UseInviteMembersOptions
  >(DEFAULT_OPTIONS, options);

  const isMounted = useIsMounted();

  const organization = useOrganizationState();

  const { data: user } = useRecoilValue(userNewState.state);

  const [members, setMembers] = useState<InviteMembersFormData[]>([
    cloneDeep(emptyMember),
  ]);

  const [invitationResponse, setInvitationResponse] = useState<
    PartialInvitedUserByEmailResponse[] | undefined
  >(undefined);

  const [sendInvitationsError, setSendInvitationsError] =
    useState<AlertContent | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const queryClient = useQueryClient();

  const refetch = useMemo(
    () => () => {
      queryClient.invalidateQueries([
        QUERY_KEYS.DATA_GRID,
        QUERY_KEYS.SETTINGS_MEMBERS_QUERY_KEY,
      ]);
    },
    [queryClient],
  );

  useEffect(() => {
    setIsDirty(!isEqual(members, [cloneDeep(emptyMember)]));
  }, [members, emptyMember]);

  const resetMembers = useCallback(() => {
    setMembers([cloneDeep(emptyMember)]);
  }, [emptyMember]);

  const resetInviteMembersStatus = useCallback(() => {
    setSendInvitationsError(null);
    resetMembers();
  }, [resetMembers]);

  const filteredMembers = members.filter(
    (member, index, itemsStore) =>
      itemsStore.findIndex((item) => item.email === member.email) === index &&
      member.email !== user?.email,
  );

  const [isInviteListValid, setIsInviteListValid] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  const validate = useCallback(
    (dirty = isDirty) => {
      if (!dirty) {
        setIsInviteListValid(true);
        setIsDirty(dirty);

        return true;
      }

      const isInvalid = members.some(isMemberInvalid());

      setIsInviteListValid(!isInvalid);
      setIsDirty(dirty);

      return !isInvalid;
    },
    [members, isDirty],
  );

  useEffect(() => {
    if (!isDirty) {
      setIsInviteListValid(true);

      return;
    }

    validate();
  }, [validate, isDirty]);

  const isAllInvitationsSent = useMemo(
    () =>
      invitationResponse?.every(({ success, error }) => !!success && !error),
    [invitationResponse],
  );

  const { mutate: inviteByEmail, isLoading } = useInviteUsersByEmail();

  const usersToInvite = useMemo(
    () => filteredMembers.filter(filterNotInvitedYet(invitationResponse)),
    [filteredMembers, invitationResponse],
  );

  const textRef = useRef(texts);

  const callOnError = useCallback(
    (errorMessage?: string) => () => {
      setIsSuccess(false);

      setSendInvitationsError({
        type: "error",
        message:
          errorMessage ||
          getErrorMessage(usersToInvite.length > 1, textRef.current),
      });
    },
    [usersToInvite],
  );

  const collectInviteErrors = useCallback(
    (r: AxiosResponse<InviteUsersByEmailResponse>) => {
      if (!r?.data) {
        return;
      }

      const {
        data: { responses },
      } = r;

      const filteredOutInvitations = uniqBy(
        [...(invitationResponse || []), ...responses],
        ({ success, error }) => success?.invite?.email || error?.invite?.email,
      );

      setInvitationResponse(filteredOutInvitations);

      const errors = countErrors(filteredOutInvitations);

      if (errors > 0) {
        callOnError()();

        return;
      }

      refetch();

      setIsSuccess(true);

      Analytics.track("invited_members", {
        usersToInvite: responses.map(({ success }) => success?.invite?.email),
      });
    },
    [callOnError, invitationResponse, refetch],
  );

  const sendInvitations: () => Promise<void> = useCallback(async () => {
    if (loading || !isMounted() || !user?.email) {
      return;
    }

    setSendInvitationsError(null);
    setIsSuccess(false);

    inviteByEmail(
      {
        ownerEmail: user.email,
        domainName: organization.subdomain,
        inviteUsers: usersToInvite.map(mapMemberToInvitationRequest()),
      },
      {
        onError: (err) =>
          callOnError(
            (err as AxiosError<{ message: string }>)?.response?.data?.message,
          )(),
        onSuccess: collectInviteErrors,
      },
    );
  }, [
    callOnError,
    collectInviteErrors,
    inviteByEmail,
    isMounted,
    loading,
    organization.subdomain,
    user?.email,
    usersToInvite,
  ]);

  const resetForm = useCallback(() => {
    setSendInvitationsError(null);
    setInvitationResponse([]);
  }, [setSendInvitationsError]);

  return {
    sendInvitations,
    sendInvitationsError,
    isSendingInvitations: isLoading,
    invitationResponse,
    isAllInvitationsSent,
    setMembers,
    members,
    emptyMember: cloneDeep(emptyMember),
    isSuccess,
    resetForm,
    resetInviteMembersStatus,
    validate,
    isInviteListValid,
    setIsSuccess,
  };
};

function filterNotInvitedYet(
  invitationResponse?: PartialInvitedUserByEmailResponse[],
) {
  return (member: InviteMembersFormData) =>
    !find(
      invitationResponse,
      ({ success }) => success?.invite?.email === member.email,
    );
}

function mapMemberToInvitationRequest() {
  return ({
    email,
    userGroup,
    userRole,
  }: InviteMembersFormData): InviteUsersByEmailBody["inviteUsers"][number] => {
    const role = PermissionEnum[userRole] as UserRole;

    return {
      email,
      organization: {
        userGroupId: userGroup,
        role,
      },
    };
  };
}

function countErrors(
  response: PartialInvitedUserByEmailResponse[] | undefined,
) {
  return reduce(
    response,
    (errorsCount, { error }) => errorsCount + Number(!!error),
    0,
  );
}

function getErrorMessage(
  isPlural: boolean,
  texts: Required<UseInviteMembersOptions>["texts"],
) {
  return [
    capitalize(isPlural ? texts.invitations : texts.invitation),
    `${texts.notSent}.`,
    texts.clickAgainToSend,
    isPlural ? texts.invitations : texts.invitation,
    `${texts.again}.`,
  ].join(" ");
}

async function inviteUsersByEmail(body: InviteUsersByEmailBody) {
  return axios<InviteUsersByEmailResponse>({
    url: API_URLS.INVITE.EMAIL,
    data: body,
    method: "POST",
  });
}

export const useInviteUsersByEmail = () =>
  useMutation({
    mutationFn: inviteUsersByEmail,
    mutationKey: API_URLS.INVITE.EMAIL,
  });

function isMemberInvalid() {
  return ({ isValid, email }: InviteMembersFormData) => !isValid || !email;
}

export function findInvitationResponse(email: string) {
  return ({ error, success }: PartialInvitedUserByEmailResponse) =>
    error?.invite?.email === email || success?.invite?.email === email;
}
