import { CodeSnippet, CodeBlockWithCopyButton } from "#organization/components"

export const language = "ts";

# Quota Scheduler

## Overview

This blueprint provides a quota scheduling policy. This policy is uses a combination of a Scheduler and a Rate Limiter components.

The Quota Scheduler is used to schedule requests based on importance while ensuring that the application adheres to third-party API rate limits or inter-service API quotas. This policy optimizes the utilization of request quotas by prioritizing access based on business-critical workloads. This strategic prioritization ensures that the most crucial requests receive their fair share of request quotas, aligning API usage with business objectives and preventing cost overages.

## Example

Here is a code example where priorities are defined according to business logic.

<CodeSnippet lang={language} snippetName="Priority" />

Next is an example of how to pass the priorities when making a `startFlow` call, so that high priority can get precedence over low priority.

<CodeSnippet lang={language} snippetName="QSStartFlow" />

## Note

In a scenario where the token fill rate and bucket capacity (API quota) is known upfront, the Quota Scheduler becomes particularly beneficial to enforce client-side rate limits. The tokens represent a fixed quota that is divided among the Agents. Each agent has access to this global ledger and consumes tokens from it when admitting requests. If the ledger runs out of tokens, new requests are queued until more tokens become available or until timeout.
