import React, { type FC } from "react";

export interface NestedCircuitIconProps {
  color: string;
}

export const NestedCircuitIcon: FC<NestedCircuitIconProps> = ({ color }) => (
  <svg
    width="38"
    height="28"
    viewBox="0 0 38 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="5.26123" y="11.0966" width="10" height="6" rx="1" stroke={color} />
    <line
      x1="15.7612"
      y1="13.5966"
      x2="18.7612"
      y2="13.5966"
      stroke={color}
      strokeLinecap="round"
    />
    <path
      d="M23.2612 7.99127H20.2612C19.7089 7.99127 19.2612 8.43899 19.2612 8.99127V19.2018C19.2612 19.7541 19.7089 20.2018 20.2612 20.2018H23.2612"
      stroke={color}
      strokeLinecap="round"
    />
    <rect x="23.2612" y="5.36633" width="10" height="6" rx="1" stroke={color} />
    <rect x="23.2612" y="16.8268" width="10" height="6" rx="1" stroke={color} />
    <rect
      x="1.26123"
      y="1.36633"
      width="36"
      height="25.4604"
      rx="4"
      stroke={color}
    />
  </svg>
);
