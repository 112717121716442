import type { GraphQLError } from "graphql";
import { isBoolean } from "lodash";

export function convertToString<V>(value: V) {
  if (isBoolean(value)) {
    return `${value}`;
  }

  if (!value) {
    return "";
  }

  if ((value as unknown as GraphQLError).originalError) {
    const { originalError } = value as unknown as GraphQLError;

    return originalError?.message || "";
  }

  switch (typeof value) {
    case "object": {
      if (value instanceof Error) {
        return value instanceof Error ? value.message : "";
      }

      return "";
    }

    default: {
      return String(value);
    }
  }
}
