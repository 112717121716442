import React, { type FC } from "react";

import {
  DialogLayout,
  type DialogControl,
} from "#shared/components/layouts/dialog";

import { useOrganizationOwners } from "../hooks";

type ComponentProps = {
  dialogControlCloseAccount: DialogControl;
};

export const CloseAccountDialog: FC<ComponentProps> = ({
  dialogControlCloseAccount,
}) => {
  const ownersList = useOrganizationOwners();

  return (
    <DialogLayout
      dialogTitle={
        ownersList.total === 1
          ? "Close the account and delete the organizations"
          : "Close the account"
      }
      dialogContentText="This is permanent and cannot be undone. Please confirm that you are sure about this."
      onCloseDialog={dialogControlCloseAccount.close}
      openDialog={dialogControlCloseAccount.isOpen}
      onClickDialogClose={dialogControlCloseAccount.close}
      buttonConfirmText="Confirm"
      buttonConfirmVariant="contained"
      buttonConfirmColor="error"
      buttonCancelVariant="outlined"
      buttonCancelColor="primary"
    />
  );
};
