import { gql } from "graphql-request";

export const filters = (id: string) => [
  {
    id: 0,
    name: "Controller name",
    key: "name",
    category: "controllers",
    gql: gql`
      query GetControllersName($where: ControllerBoolExp, $first: Int) {
        controllers(where: $where, first: $first) {
          edges {
            node {
              name
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      const variable = {
        first: 5,
        where: {
          projectID: { eq: id },
          name: {},
        },
      };

      if (prop !== "") {
        variable.where.name = {
          like: `${prop}%`,
        };
      }

      return variable;
    },
  },
  {
    id: 1,
    name: "Controller key",
    key: "agentKey.key",
    category: "controllers",
    gql: gql`
      query GetControllersAgentKey($where: ControllerBoolExp, $first: Int) {
        controllers(where: $where, first: $first, distinctOn: [agentKeyID]) {
          edges {
            node {
              agentKey {
                key
              }
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      const variable = {
        first: 5,
        where: {
          projectID: { eq: id },
          agentKey: {
            key: {},
          },
        },
      };

      if (prop !== "") {
        variable.where.agentKey.key = {
          like: `${prop}%`,
        };
      }

      return variable;
    },
  },
  {
    id: 2,
    name: "Version",
    key: "version",
    category: "controllers",
    gql: gql`
      query GetControllersVersion($where: ControllerBoolExp, $first: Int) {
        controllers(where: $where, first: $first) {
          edges {
            node {
              version
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      const variable = {
        first: 5,
        where: {
          projectID: { eq: id },
          version: {},
        },
      };

      if (prop !== "") {
        variable.where.version = {
          like: `${prop}%`,
        };
      }

      return variable;
    },
  },
  {
    id: 3,
    name: "Status",
    key: "status",
    category: "controllers",
    gql: gql`
      query GetControllersStatus($where: ControllerBoolExp, $first: Int) {
        controllers(where: $where, first: $first, distinctOn: [status]) {
          edges {
            node {
              status
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      const variable = {
        first: 5,
        where: {
          projectID: { eq: id },
          status: {},
        },
      };

      if (prop !== "") {
        variable.where.status = {
          like: `${prop}%`,
        };
      }

      return variable;
    },
  },
  {
    id: 4,
    name: "Last seen",
    key: "lastSeen",
    category: "controllers",
    isLikeFilterBlocked: true,
    gql: gql`
      query GetControllersLastSeen($where: ControllerBoolExp, $first: Int) {
        controllers(where: $where, first: $first, distinctOn: [status]) {
          edges {
            node {
              lastSeen
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      const variable = {
        first: 5,
        where: {
          projectID: { eq: id },
          lastSeen: {},
        },
      };

      if (prop !== "") {
        variable.where.lastSeen = {
          like: `${prop}%`,
        };
      }

      return variable;
    },
  },
];
