import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { Button, CircularProgress } from "@mui/material";
import React, { type FC } from "react";

import type { Texts } from "#shared/types";

import {
  useGetDefaultProject,
  useGetDefaultUserGroup,
  type UseCreatorView,
  type UseInvite,
  Steps,
} from "../hooks";

export interface CreatorViewActionsProps {
  texts?: Texts<Text>;
  creator: UseCreatorView;
  invite: UseInvite;
}

type Text = "finish" | "next" | "back";

export const enTexts: Required<CreatorViewActionsProps>["texts"] = {
  finish: "Finish",
  next: "Next",
  back: "Back",
};

export const CreatorViewActions: FC<CreatorViewActionsProps> = ({
  texts = enTexts,
  creator,
  invite,
}) => {
  const {
    isLoadingResult,
    activeStep,
    activeStepIndex,
    onBack,
    onNext,
    isInviteListValid,
    isFirstStep,
    isLastStep,
  } = creator;
  const { isSendingInvitations } = invite;

  const defaultUserGroup = useGetDefaultUserGroup();
  const defaultProject = useGetDefaultProject();

  const isLoading =
    defaultUserGroup.isLoading || defaultProject.isLoading || isLoadingResult;

  return (
    <>
      {activeStepIndex ? (
        <Button
          disabled={isFirstStep || isLoadingResult || isSendingInvitations}
          onClick={() => onBack()}
          sx={{ mr: 1 }}
          variant="outlined"
          startIcon={<ArrowBack />}
        >
          {texts.back}
        </Button>
      ) : null}
      <Button
        onClick={() => onNext()}
        variant="contained"
        disabled={
          isLoading ||
          (activeStep === Steps.InviteTeam && !isInviteListValid) ||
          isSendingInvitations
        }
        endIcon={isLoading ? <CircularProgress size="1em" /> : <ArrowForward />}
      >
        {isLastStep ? texts.finish : texts.next}
      </Button>
    </>
  );
};
