import { get, isEqual, noop } from "lodash";
import React, { useEffect, type FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import type { JSONObject } from "#shared/types";

import {
  convertToJson,
  convertToYaml,
  useCreatePolicyContext,
} from "#organization/pages/authenticated/policies/create-policy/context";

import {
  YAMLEditor,
  usePolicyEditor,
} from "../../../../blueprint-policy-editor";
import { generateUpdatedBlueprintJson } from "../../utils";

const LABEL_MATCHER_SCHEMA_PATH =
  "https://raw.githubusercontent.com/fluxninja/aperture/latest/blueprints/gen/jsonschema/_definitions.json#/definitions/LabelMatcher";

export interface LabelMatcherProps {
  labelMatcherAddress: string;
  lengthOfSelectors: number;
}

export const LabelMatcher: FC<LabelMatcherProps> = ({
  labelMatcherAddress,
  lengthOfSelectors,
}) => {
  const {
    setCreatePolicyCtx,
    blueprintJson,
    editor: masterEditor,
    miniEditors,
  } = useCreatePolicyContext();

  const { getValues } = useFormContext();

  const storedLabelMatcher = useMemo(
    () =>
      get(blueprintJson, labelMatcherAddress) ||
      getValues(labelMatcherAddress) ||
      null,
    [blueprintJson, getValues, labelMatcherAddress],
  );

  const editorProps = usePolicyEditor({
    defaultValue: storedLabelMatcher
      ? convertToYaml(storedLabelMatcher as JSONObject)
      : "",
    enableAutoTriggerSuggestion: false,
    schema: {},
    uri: LABEL_MATCHER_SCHEMA_PATH,
    updateGlobalState: false,
    monacoEditorOptions: {
      fontSize: 14,
    },
  });

  useEffect(() => {
    if (!editorProps.editor || lengthOfSelectors === miniEditors?.length) {
      return;
    }

    setCreatePolicyCtx((prev) => ({
      ...prev,
      miniEditors: editorProps.editor
        ? [...(prev.miniEditors || []), editorProps.editor]
        : prev.miniEditors,
    }));
  }, [
    editorProps.editor,
    lengthOfSelectors,
    miniEditors?.length,
    setCreatePolicyCtx,
  ]);

  useEffect(() => {
    const { editor } = editorProps;

    if (!editor || !blueprintJson) return noop;

    const disposable = editor?.getModel()?.onDidChangeContent(() => {
      const value = editor.getValue();
      const json = convertToJson(value);

      const { blueprintJson: blueprintCopy, blueprintYaml } =
        generateUpdatedBlueprintJson(
          json,
          blueprintJson,
          labelMatcherAddress,
          (j) => j,
        );

      if (isEqual(blueprintCopy, blueprintJson)) {
        return;
      }

      setCreatePolicyCtx((ctx) => ({
        ...ctx,
        blueprintJson: blueprintCopy,
        blueprintYaml,
      }));

      masterEditor?.setValue(blueprintYaml);
    });

    return () => disposable?.dispose();
  }, [
    blueprintJson,
    editorProps,
    labelMatcherAddress,
    masterEditor,
    setCreatePolicyCtx,
  ]);

  return <YAMLEditor {...{ ...editorProps, editorHeight: "300px" }} />;
};
