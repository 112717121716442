import { gql } from "graphql-request";

import type {
  DiscoveryServicesAllAndWithControlPointsQuery,
  DiscoveryServicesGroupsTitlesQuery,
  DiscoveryServicesGroupDataQuery,
} from "#shared/generated/graphql";
import { useGqlQuery } from "#shared/utils";

import {
  type DataGridHook,
  type GroupsTitlesHook,
  useGridData,
} from "#organization/hooks";
import {
  type GroupDataHook,
  useGroupData,
  useGroupsTitles,
} from "#organization/hooks/group-by";
import { QUERY_KEYS } from "#organization/pages/consts";

const DiscoveryServicesAllAndWithControlPoints = gql`
  query DiscoveryServicesAllAndWithControlPoints(
    $where: ServiceBoolExp
    $whereControlPoint: ServiceBoolExp
  ) {
    services(where: $where) {
      totalCount
    }
    servicesWithControlPoint: services(where: $whereControlPoint) {
      totalCount
    }
  }
`;

export const useDiscoveryServicesAllAndWithControlPoints = (
  projectId: string,
) => {
  const { data, isLoading, isError, error, refetch } = useGqlQuery<
    DiscoveryServicesAllAndWithControlPointsQuery,
    Error
  >(
    [QUERY_KEYS.DISCOVERY_SERVICES_ALL_AND_WITH_CONTROL_POINTS],
    DiscoveryServicesAllAndWithControlPoints,
    {
      where: {
        projectID: { eq: projectId },
      },
      whereControlPoint: {
        projectID: { eq: projectId },
        controlPoints: { name: { isNull: false } },
      },
    },
  );

  return {
    services: data?.services.totalCount || 0,
    servicesWithControlPoint: data?.servicesWithControlPoint.totalCount || 0,
    percentage: Math.floor(
      (100 * (data?.servicesWithControlPoint.totalCount || 1)) /
        (data?.services.totalCount || 1),
    ),
    isLoading,
    isError,
    error,
    refetch,
  };
};

export type DiscoveryServicesGroupBy = "name" | "agentGroup" | "controllerID";

const DISCOVERY_SERVICES_ENTITY_KEY = "services";

export const discoveryServicesGroupsTitles = gql`
  query DiscoveryServicesGroupsTitles(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: ServiceBoolExp
    $distinctOn: [ServiceSelectColumn!]
  ) {
    services(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      distinctOn: $distinctOn
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          name
          agentGroup
          status
          controller {
            name
          }
          lastSeen
          controlPoints {
            totalCount
          }
        }
      }
    }
  }
`;

type DiscoveryServicesTitleData =
  DiscoveryServicesGroupsTitlesQuery["services"]["edges"][0]["node"];

export const useDiscoveryServicesGroupsTitles: GroupsTitlesHook<
  DiscoveryServicesGroupBy
> = (pageSize, page, setPage, groupByKey, filterVariables) =>
  useGroupsTitles<DiscoveryServicesGroupBy, DiscoveryServicesTitleData>(
    DISCOVERY_SERVICES_ENTITY_KEY,
    pageSize,
    page,
    setPage,
    groupByKey,
    discoveryServicesGroupsTitles,
    (node) => {
      if (groupByKey === "controllerID") {
        return node.controller?.name;
      }

      return node[groupByKey!];
    },
    filterVariables,
  );

export const discoveryServicesGroupData = gql`
  query DiscoveryServicesGroupData(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: ServiceBoolExp
    $distinctOn: [ServiceSelectColumn!]
    $orderBy: [ServiceOrderBy!]
  ) {
    services(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      distinctOn: $distinctOn
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          name
          agentGroup
          status
          controller {
            name
          }
          lastSeen
          controlPoints {
            totalCount
          }
          alertsCount {
            count
            severity
          }
        }
      }
    }
  }
`;

type DiscoveryServicesGroupData =
  DiscoveryServicesGroupDataQuery["services"]["edges"][0]["node"];

export type AdaptedDiscoveryServicesGroupData = DiscoveryServicesGroupData;

export const useDiscoveryServicesGroupData: GroupDataHook<
  DiscoveryServicesGroupBy
> = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  groupByKey,
  groupByValue,
  filterVariables,
) =>
  useGroupData<
    DiscoveryServicesGroupBy,
    DiscoveryServicesGroupData,
    AdaptedDiscoveryServicesGroupData
  >(
    DISCOVERY_SERVICES_ENTITY_KEY,
    discoveryServicesGroupData,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    groupByKey,
    groupByValue,
    (services: DiscoveryServicesGroupData) => ({
      ...services,
    }),
    {
      ...filterVariables,
      and: [
        { name: { neq: "" } },
        { name: { neq: "any" } },
        ...(filterVariables.and && Array.isArray(filterVariables.and)
          ? filterVariables.and
          : []),
      ],
    },
  );

export const useDiscoveryServicesData: DataGridHook = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  enabled,
  filterVariables,
) =>
  useGridData<DiscoveryServicesGroupData, AdaptedDiscoveryServicesGroupData>(
    DISCOVERY_SERVICES_ENTITY_KEY,
    discoveryServicesGroupData,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    {
      ...filterVariables,
      and: [
        { name: { neq: "" } },
        { name: { neq: "any" } },
        ...(filterVariables.and && Array.isArray(filterVariables.and)
          ? filterVariables.and
          : []),
      ],
    },
    (services: DiscoveryServicesGroupData) => ({
      ...services,
    }),
    enabled,
  );
