import type { PaletteMode } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import React, { type FC, type PropsWithChildren } from "react";
import { useRecoilValue } from "recoil";

import { userNewState } from "#shared/recoil";

import { getNavigationTheme } from "./navigation";
import { getTheme } from "./theme/get-theme";

import { useThemeState } from "../apps/organization/recoil/theme";

export const applicationTheme = (mode: PaletteMode) =>
  createTheme(getTheme(mode));

export const navigationTheme = (mode: PaletteMode) =>
  createTheme(getNavigationTheme(mode));

export const AppThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const theme = useThemeState();
  const isAuthenticated = useRecoilValue(userNewState.isAuthenticated);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider
        theme={applicationTheme(isAuthenticated ? theme.mode : "light")}
      >
        <>
          <CssBaseline />
          {children}
        </>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
