import { find, get, isEqual, merge } from "lodash";
import {
  type Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  type DeepPartial,
  type TenantConfigBodyMethod,
  type TenantIdpConfigResponse,
} from "#shared/types";

import { useIdpConfigsEnabledState } from "./use-idp-configs-enabled-state";

import {
  type SamlSignInConfig,
  type DefaultSupportedIdpConfig,
  type DefaultIdpConfigFormValues,
  type SamlConfigFormValues,
} from "../components";

export type UseFormsData = {
  google: {
    data: DefaultIdpConfigFormValues | undefined;
    isDirty: boolean;
    method: TenantConfigBodyMethod;
    setData: Dispatch<DeepPartial<DefaultSupportedIdpConfig> | undefined>;
  };
  saml: {
    data: SamlConfigFormValues | undefined;
    isDirty: boolean;
    method: TenantConfigBodyMethod;
    setData: Dispatch<DeepPartial<SamlConfigFormValues> | undefined>;
  };
  resetForm: () => void;
};

export const GOOGLE_DEFAULT_DATA: DefaultIdpConfigFormValues = {
  displayName: "google.com",
  clientId: "",
  clientSecret: "",
  name: "google.com",
};

export const SAML_DEFAULT_DATA: SamlConfigFormValues = {
  name: "",
  displayName: "",
  ssoUrl: "",
  certificate: "",
  entityId: "",
  spConfig: {
    callbackUri: "",
    spEntityId: "",
  },
};

export const useFormsData = (
  data: TenantIdpConfigResponse | undefined,
): UseFormsData => {
  const fetchedGoogleConfig = useMemo(
    () => find(data?.defaultSupportedIdpConfigs, { displayName: "google.com" }),
    [data?.defaultSupportedIdpConfigs],
  );

  const fetchedSamlConfig = useMemo(
    () => (data?.inboundSamlConfigs || [])[0],
    [data?.inboundSamlConfigs],
  );

  const [googleData, setGoogleData] =
    useState<UseFormsData["google"]["data"]>(fetchedGoogleConfig);

  const [samlData, setSamlData] = useState<UseFormsData["saml"]["data"]>(
    mapSaml(fetchedSamlConfig),
  );

  const { enabledIdpConfigs } = useIdpConfigsEnabledState(data);

  const setSamlConfigIfChanged = useCallback<UseFormsData["saml"]["setData"]>(
    (newSamlData) => {
      setSamlData((previousSamlData) => {
        if (!newSamlData && !previousSamlData) {
          return previousSamlData;
        }

        if (
          newSamlData &&
          previousSamlData &&
          isEqual(newSamlData, previousSamlData)
        ) {
          return previousSamlData;
        }

        const merged = merge(
          {},
          previousSamlData || {},
          newSamlData || {},
        ) as SamlConfigFormValues;

        return merged;
      });
    },
    [],
  );

  const setGoogleConfigIfChanged = useCallback<
    UseFormsData["google"]["setData"]
  >((newGoogleData) => {
    setGoogleData((previousGoogleData) => {
      if (!newGoogleData && !previousGoogleData) {
        return previousGoogleData;
      }

      if (
        newGoogleData &&
        previousGoogleData &&
        isEqual(newGoogleData, previousGoogleData)
      ) {
        return previousGoogleData;
      }

      const merged = merge(
        {},
        previousGoogleData || {},
        newGoogleData || {},
      ) as DefaultSupportedIdpConfig;

      return merged;
    });
  }, []);

  // NOTE: When data is fetched
  useEffect(() => {
    setGoogleConfigIfChanged(fetchedGoogleConfig);
  }, [fetchedGoogleConfig, setGoogleConfigIfChanged]);

  // NOTE: When data is fetched
  useEffect(() => {
    setSamlConfigIfChanged(mapSaml(fetchedSamlConfig));
  }, [fetchedSamlConfig, setSamlConfigIfChanged]);

  useEffect(() => {
    if (!googleData) {
      return;
    }

    setGoogleConfigIfChanged({
      ...googleData,
    });
  }, [enabledIdpConfigs.google, googleData, setGoogleConfigIfChanged]);

  useEffect(() => {
    if (!samlData) {
      return;
    }

    setSamlConfigIfChanged({
      ...samlData,
    });
  }, [enabledIdpConfigs.saml, samlData, setSamlConfigIfChanged]);

  const isSamlDirty = useMemo(
    () =>
      !isEqual(samlData || {}, {
        // eslint-disable-next-line
        // @ts-ignore
        enabled: false,
        ...mapSaml(fetchedSamlConfig),
      }),
    [samlData, fetchedSamlConfig],
  );

  const isGoogleDirty = useMemo(
    () =>
      !isEqual(googleData || {}, {
        // eslint-disable-next-line
        // @ts-ignore
        enabled: false,
        ...(fetchedGoogleConfig || {}),
      }),
    [googleData, fetchedGoogleConfig],
  );

  const resetForm = useCallback(() => {
    setGoogleConfigIfChanged(fetchedGoogleConfig);
    setSamlConfigIfChanged(mapSaml(fetchedSamlConfig));
  }, [
    fetchedGoogleConfig,
    fetchedSamlConfig,
    setGoogleConfigIfChanged,
    setSamlConfigIfChanged,
  ]);

  return useMemo(
    () => ({
      google: {
        data: googleData
          ? { ...googleData, enabled: enabledIdpConfigs.google }
          : undefined,
        setData: setGoogleConfigIfChanged,
        isDirty: isGoogleDirty,
        /* eslint-disable-next-line  */
        method: fetchedGoogleConfig ? "patch" : "post",
      },
      saml: {
        data: samlData
          ? { ...samlData, enabled: enabledIdpConfigs.saml }
          : undefined,
        setData: setSamlConfigIfChanged,
        isDirty: isSamlDirty,
        /* eslint-disable-next-line  */
        method: fetchedSamlConfig ? "patch" : "post",
      },
      resetForm,
    }),
    [
      googleData,
      samlData,
      setSamlConfigIfChanged,
      setGoogleConfigIfChanged,
      isSamlDirty,
      isGoogleDirty,
      enabledIdpConfigs,
      fetchedGoogleConfig,
      fetchedSamlConfig,
      resetForm,
    ],
  );
};

function mapSaml(samlData?: SamlSignInConfig): SamlConfigFormValues {
  return {
    name: samlData?.name || SAML_DEFAULT_DATA.name,
    displayName: samlData?.displayName || SAML_DEFAULT_DATA.displayName,
    entityId: samlData?.idpConfig?.idpEntityId || SAML_DEFAULT_DATA.entityId,
    ssoUrl: samlData?.idpConfig?.ssoUrl || SAML_DEFAULT_DATA.ssoUrl,
    certificate:
      get(samlData, "idpConfig.idpCertificates.0.x509Certificate", "") ||
      SAML_DEFAULT_DATA.certificate,
    spConfig: samlData?.spConfig || SAML_DEFAULT_DATA.spConfig,
  };
}
