/* eslint-disable @typescript-eslint/naming-convention */
import { Box, styled, Typography } from "@mui/material";
import React, { type CSSProperties, type FC } from "react";
import { Handle, Position } from "react-flow-renderer";

import type { CustomNodeProps } from "./custom-nodes";

const HEIGHT = 65;
const WIDTH = 65;

export interface ConstantCircleNodeProps {
  styles: CSSProperties;
  data: CustomNodeProps["data"];
}

export const ConstantNode: FC<ConstantCircleNodeProps> = ({ data, styles }) => {
  const { componentId, inPorts, outPorts, componentDescription } = data;

  return (
    <Box position="relative">
      {inPorts?.map((port) => {
        const id = [componentId, port].join(".");

        return (
          <Handle
            key={id}
            id={id}
            type="target"
            position={Position.Left}
            style={{ opacity: 0 }}
          />
        );
      })}
      {outPorts?.map((port) => {
        const id = [componentId, port].join(".");

        return (
          <Handle
            key={id}
            id={id}
            type="source"
            position={Position.Right}
            style={{ opacity: 0 }}
          />
        );
      })}
      <svg style={SvgStyle} width={WIDTH} height={HEIGHT}>
        {/* @ts-ignore */}
        <ellipse
          cx={WIDTH / 2}
          cy={HEIGHT / 2}
          rx={WIDTH / 2}
          ry={HEIGHT / 2}
          {...styles}
        />
      </svg>
      <CircleNodeInnerContainer>
        <ConstantValueText>{componentDescription}</ConstantValueText>
      </CircleNodeInnerContainer>
    </Box>
  );
};

export const CircleNodeInnerContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
});

export const ConstantValueText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 14,
}));

export const SvgStyle: CSSProperties = {
  display: "block",
  overflow: "visible",
};
