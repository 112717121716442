const repo = "fluxninja/aperture";

declare type FetchSchemaPayload = {
  version: string;
  blueprintPath: string;
};

export async function fetchSchema(p: FetchSchemaPayload) {
  const absoluteBlueprintPath = `https://raw.githubusercontent.com/${repo}/${p.version}/${p.blueprintPath}`;
  const response = await fetch(absoluteBlueprintPath);
  const schema = await response.json();

  return { schema, absoluteBlueprintPath };
}
