import { Box, Typography } from "@mui/material";
import React, { type FC } from "react";

export const RadioLabel: FC<{
  name: string;
  description: string;
}> = ({ name, description }) => (
  <Box sx={{ pt: 2, pb: 2, pl: 1 }}>
    <Typography>{name}</Typography>
    <Typography sx={{ pt: 0.5, fontSize: "12px" }} color="textSecondary">
      {description}
    </Typography>
  </Box>
);
