import { Logout, PowerSettingsNew } from "@mui/icons-material";
import { IconButton, ListItemButton, Popover, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { type FC } from "react";
import { useRecoilValue } from "recoil";

import { useLogout } from "#shared/hooks";
import { userNewState } from "#shared/recoil";
import { type Texts } from "#shared/types";

type Text = "loggedIn";

interface WelcomePageLogoutButtonProps {
  texts?: Texts<Text>;
  isOrgContext?: boolean;
}

const enTexts: Required<WelcomePageLogoutButtonProps["texts"]> = {
  loggedIn: "Logged in as:",
};

export const WelcomePageLogoutButton: FC<WelcomePageLogoutButtonProps> = ({
  texts = enTexts,
  isOrgContext = true,
}) => {
  const { data: user } = useRecoilValue(userNewState.state);

  const logout = useLogout(isOrgContext);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "logout" : undefined;

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <PowerSettingsNew
          sx={{ fill: (theme) => theme.palette.primary.main }}
        />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: -130,
        }}
      >
        <Box width="fit-content" pt={2}>
          <Typography px={2} variant="body2">
            {texts.loggedIn}
          </Typography>
          <Typography px={2} variant="body2" color="text.secondary">
            {user?.email}
          </Typography>
          <ListItemButton
            onClick={logout}
            sx={{
              mt: 1,
              height: "56px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography px={1} variant="body2">
              Logout
            </Typography>
            <Logout sx={{ marginRight: 2 }} />
          </ListItemButton>
        </Box>
      </Popover>
    </Box>
  );
};
