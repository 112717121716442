import { useEffect, type FC } from "react";
import { useLocation } from "react-router-dom";

import { FnIntercom } from "#shared/intercom";

import { Analytics } from "./initialize-analytics";
import { FnUserInteractionOnPage } from "./record-user-activity";

export const PageEventAnalytics: FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const page = [window.location.host, pathname].join("");

    // Start recording user interaction on page
    FnUserInteractionOnPage.startTime();

    // Ping intercom on route change
    FnIntercom.update();

    return () => {
      // Stop recording user interaction on page
      FnUserInteractionOnPage.stopTime();

      // Send page view event to analytics
      Analytics.page(page, {
        time_on_page: FnUserInteractionOnPage.getInteractionTime(),
      });
    };
  }, [pathname]);

  return null;
};
