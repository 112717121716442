import { Typography, Tooltip, Box } from "@mui/material";
import React from "react";

import { emptyColumnValue, type HeadCell } from "#shared/components/data-grid";
import { HealthStatus } from "#shared/components/data-grid/components/health-status";

import { ArchivedStatus } from "#organization/components";
import { LinkWithoutUnderline } from "#organization/components/link-without-underline";

import { TableActions } from "./head-components";

import type { AdaptedPoliciesGroupData } from "../../hooks";
import { POLICY_ROUTE } from "../../routes.definitions";

export const headCells = (
  showActions = true,
): HeadCell<AdaptedPoliciesGroupData>[] => [
  {
    accessor: (row) => (
      <ArchivedStatus status={row.status}>
        {(row.status === "3-applied" ||
          row.status === "5-archived" ||
          row.status === "3-archived") &&
        row.policy?.id !== undefined ? (
          <LinkWithoutUnderline
            to={`${POLICY_ROUTE.ABSOLUTE_PATH(row.policy.id)}/summary`}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
              {row.name || emptyColumnValue}
            </Typography>
          </LinkWithoutUnderline>
        ) : (
          <Typography sx={{ fontSize: "14px" }}>
            {row.name || emptyColumnValue}
          </Typography>
        )}
      </ArchivedStatus>
    ),
    label: "Policy name",
    key: "name",
  },
  {
    accessor: ({ controllerName }) => controllerName,
    label: "Controller",
    key: "controllerID",
    noSorting: true,
  },
  {
    accessor: ({ origin }) => origin,
    label: "Origin",
    key: "origin",
  },
  {
    accessor: ({ status, message }) => (
      <Tooltip title={message} placement="top-start">
        <Box>
          <HealthStatus status={status} />
        </Box>
      </Tooltip>
    ),
    label: "Status",
    key: "status",
  },
  {
    accessor: ({ createdBy }) => createdBy,
    label: "Created by",
    key: "createdBy",
    noSorting: true,
  },
  {
    accessor: ({ lastModifiedBy }) => lastModifiedBy,
    label: "Last modified by",
    key: "lastModifiedBy",
    noSorting: true,
  },
  {
    accessor: ({ lastSeen }) => lastSeen,
    label: "Last seen at",
    key: "lastSeen",
    noSorting: true,
  },
  ...(showActions
    ? [
        {
          accessor: (props) => <TableActions {...props} />,
          label: "Actions",
          key: "actions",
          width: "100px",
          noSorting: true,
        } as HeadCell<AdaptedPoliciesGroupData>,
      ]
    : []),
];
