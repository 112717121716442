import React, { type FC } from "react";

import { InfoDataPage } from "#shared/components/layouts";
import type { Texts } from "#shared/types";

export interface NoKubernetesProps {
  texts?: Texts<Text>;
}

type Text = "noKubernetes" | "kubernetesDesc";

const enTexts: NoKubernetesProps["texts"] = {
  noKubernetes: "Kubernetes not configured",
  kubernetesDesc:
    "In order to use Kubernetes, ensure some Agents are installed in Kubernetes environment and Agent's Kubernetes Client is properly configured.",
};

export const NoKubernetes: FC<NoKubernetesProps> = ({ texts = enTexts }) => (
  <InfoDataPage
    title={texts.noKubernetes}
    description={texts.kubernetesDesc}
    docButtonLink="/self-hosting/integrations/auto-scale/"
  />
);
