import { ListItemText, List, ListItem } from "@mui/material";
import React, { type FormEvent, type FC } from "react";
import { useParams } from "react-router-dom";

import { useAlert } from "#shared/components/alerts-provider";
import { useControlDialog } from "#shared/components/layouts/dialog";
import { LoggerService } from "#shared/services/logger";
import type { Texts } from "#shared/types";

import {
  DropdownSearchBox,
  type DropdownControl,
} from "#organization/components/layouts/dropdown-search-box";
import { useAddUserToUserGroup } from "#organization/graphql/usergroup";

import { useOrganizationEmailList } from "./hooks";

import { InviteMembersDialog } from "../../../../../../../components/invite-members-dialog";
import { MEMBERS_SETTINGS_ROUTE } from "../../../../../../members";

type Text = "addMemberToUserGroupSuccess" | "addMemberToUserGroupError";

interface AddMemberToUserGroupDropdownProps {
  dropdownControl: DropdownControl;
  refetch: () => void;
  texts?: Texts<Text>;
}

const enTexts: Required<AddMemberToUserGroupDropdownProps["texts"]> = {
  addMemberToUserGroupSuccess: "Successfully added new user to user group!",
  addMemberToUserGroupError: "Could not added new user to user group.",
};

export const AddMemberToUserGroupDropdown: FC<
  AddMemberToUserGroupDropdownProps
> = ({ dropdownControl, refetch, texts = enTexts }) => {
  const { userGroupId } = useParams<{ userGroupId: string }>();
  const membersList = useOrganizationEmailList(userGroupId);
  const userGroupActions = useAddUserToUserGroup();
  const { addAlert } = useAlert();
  const inviteMembersDialog = useControlDialog();

  const searchUser = (event: FormEvent<HTMLInputElement>) => {
    membersList.setSearch(event.currentTarget?.value);
  };

  const handleAddUserToUserGroup = (user: string) => {
    userGroupActions.add(
      { user, userGroup: userGroupId },
      {
        onSuccess: () => {
          refetch();
          membersList.refetch();

          addAlert({
            type: "success",
            message: texts.addMemberToUserGroupSuccess,
          });
        },
        onError: () => {
          LoggerService.error(Error);

          addAlert({
            type: "error",
            message: texts.addMemberToUserGroupError,
          });
        },
      },
    );
  };

  const handleAction = () => {
    dropdownControl.closePopper();
    inviteMembersDialog.open();
  };

  return (
    <>
      <DropdownSearchBox
        idDropdown={dropdownControl.id}
        isOpen={dropdownControl.open}
        anchorEl={dropdownControl.dropdownElement}
        dropdownHeaderName={MEMBERS_SETTINGS_ROUTE.TITLE}
        inputOnChange={searchUser}
        defaultInputValue={membersList.search}
        onClickAwayHandler={dropdownControl.closePopper}
        onClickOpenDialog={handleAction}
        inviteMembers
      >
        <List disablePadding aria-label="dropdown list">
          {membersList.users?.map((user) => (
            <ListItem
              key={user.node.id}
              button
              onClick={() => handleAddUserToUserGroup(user.node.id)}
            >
              <ListItemText primary={user.node.email} />
            </ListItem>
          ))}
        </List>
      </DropdownSearchBox>
      <InviteMembersDialog dialogControl={inviteMembersDialog} />
    </>
  );
};
