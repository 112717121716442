import { Typography, Box, CircularProgress } from "@mui/material";
import React, { type FC } from "react";

import { ErrorLayout } from "#shared/components/layouts";
import { LoggerService } from "#shared/services";
import type { Texts } from "#shared/types";

import { InviteSummary } from "./invite-summary";
import { Logo } from "./logo";

import {
  type UseCreatorView,
  useGetDefaultProject,
  useGetDefaultUserGroup,
} from "../hooks";

export interface SummaryViewProps {
  texts?: Texts<Text>;
  creator: Pick<UseCreatorView, "projectId" | "userGroupId">;
}

type Text =
  | "almostFinish"
  | "review"
  | "projectName"
  | "userGroup"
  | "userGroupMembers"
  | "detectedAgents"
  | "referToDocumentation"
  | "projectRegion";

const enTexts: Required<SummaryViewProps["texts"]> = {
  almostFinish: "You’re all set!",
  review:
    "Review your settings, then you're all set to start your Aperture Cloud journey.",
  projectName: "Project name",
  userGroup: "User group",
  userGroupMembers: "User group members",
  detectedAgents: "Detected Agents",
  referToDocumentation: "learn more",
  projectRegion: "Project region",
};

const LABEL_COLUMN = "180px";

export const SummaryView: FC<SummaryViewProps> = ({
  texts = enTexts,
  creator,
}) => {
  const { userGroupId, projectId } = creator;
  const project = useGetDefaultProject({ where: { id: { eq: projectId } } });
  const userGroup = useGetDefaultUserGroup({
    where: { id: { eq: userGroupId } },
  });

  if (!userGroupId || !projectId) {
    LoggerService.warn(
      userGroupId ? "Missing project id." : "Missing user group id.",
    );

    return <ErrorLayout />;
  }

  return (
    <Box display="grid" gridTemplateRows="auto auto 1fr" gridAutoColumns="1fr">
      <Box display="grid" gridTemplateColumns="1fr 0.3fr" width="100%">
        <Box gridColumn="1">
          <Typography color="text.primary" variant="h3" pb={3}>
            {texts.almostFinish}
          </Typography>
          <Typography color="text.secondary" minHeight={48}>
            {texts.review}
          </Typography>
        </Box>

        <Logo />
      </Box>

      <Box
        gridColumn={1}
        display="grid"
        gridTemplateColumns={[LABEL_COLUMN, "1fr"].join(" ")}
        pt={6}
        rowGap={2}
        columnGap={3}
      >
        <Typography justifySelf="end">{texts.projectName}</Typography>
        <Typography justifySelf="start" color="primary">
          {project.isLoading || project.isIdle ? (
            <CircularProgress size="1em" />
          ) : (
            project.data?.name || "-"
          )}
        </Typography>

        <Typography justifySelf="end">{texts.projectRegion}</Typography>
        <Typography justifySelf="start" color="primary">
          {project.isLoading || project.isIdle ? (
            <CircularProgress size="1em" />
          ) : (
            <>
              {project.data?.region || "-"}{" "}
              {project.data?.cloud_provider &&
                `(${project.data.cloud_provider.toUpperCase()})`}
            </>
          )}
        </Typography>

        <Typography justifySelf="end">{texts.userGroup}</Typography>
        <Typography justifySelf="start" color="primary">
          {userGroup.isLoading || userGroup.isIdle ? (
            <CircularProgress size="1em" />
          ) : (
            userGroup.data?.name || "-"
          )}
        </Typography>
        <Typography justifySelf="end">{texts.userGroupMembers}</Typography>
        <Box justifySelf="start">
          <InviteSummary {...{ userGroupId }} />
        </Box>
      </Box>
    </Box>
  );
};
