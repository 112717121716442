import { type ButtonProps } from "@mui/material";
import {
  GoogleAuthProvider,
  signInWithPopup,
  type UserCredential,
} from "firebase/auth";
import { noop } from "lodash";
import React, {
  type FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { SignInWithGoogleButton } from "#shared/components/buttons";
import { FirebaseContext } from "#shared/contexts/firebase";
import { type UseFetch } from "#shared/hooks";
import { type MapIdpConfigResponseKeyToIdpConfigObject } from "#shared/types";

export type SignInWithOauthProps = ButtonProps & {
  config: NonNullable<
    UseFetch<
      MapIdpConfigResponseKeyToIdpConfigObject<"oauthIdpConfigs">
    >["result"]
  >;
};

export const SignInWithOauth: FC<SignInWithOauthProps> = ({
  config,
  ...buttonProps
}) => {
  const { firebase } = useContext(FirebaseContext);

  const provider = useMemo(() => new GoogleAuthProvider(), []);

  const [, setUserCredential] = useState<UserCredential | null>(null);

  const onClick = useCallback<Required<ButtonProps>["onClick"]>(
    async (event) => {
      (buttonProps.onClick || noop)(event);

      if (!firebase) {
        return;
      }

      try {
        const response = await signInWithPopup(firebase, provider);

        setUserCredential(response || null);
      } catch {
        setUserCredential(null);
      }
    },
    [firebase, provider, buttonProps],
  );

  if (config.displayName === "google.com") {
    return <SignInWithGoogleButton {...{ ...buttonProps, onClick }} />;
  }

  return null;
};
