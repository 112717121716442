import { some } from "lodash";
import React, { type FC, useMemo, useContext, useState } from "react";
import { useRecoilValue } from "recoil";

import { LoaderLayout, NoDataLayout } from "#shared/components/layouts";
import { AlternativeSignInHorizontalBar } from "#shared/components/layouts/sign-in";
import {
  SignInWithDefaultSupportedIdpConfig,
  SignInWithLinkForm,
  SignInWithOauth,
  SignInWithSaml,
  type LinkCredential,
} from "#shared/components/sign-in";
import {
  FirebaseContext,
  type TenantSignInProps,
} from "#shared/contexts/firebase";
import { ENV } from "#shared/env";

import { PLACEHOLDER } from "#organization/consts";
import { organizationState } from "#organization/recoil/organization";

import { EmailPasswordSignInForm } from "./password";

/**
 * NOTE:
 *
 * Renders dynamic sign in form.
 *
 * Content of the form depends on the configured authentication methods.
 * The configured authentication methods are fetched from the api (the authn service).
 */
export const TenantSignIn: FC<TenantSignInProps> = ({
  inboundSamlConfigs,
  oauthIdpConfigs,
  defaultSupportedIdpConfigs,
  isSsoConfigured,
  isFallbackGoogleConfigured,
  ...props
}) => {
  const {
    tenantConfig: { result, httpState },
  } = useContext(FirebaseContext);

  const organization = useRecoilValue(organizationState);

  const isWithLink = !isSsoConfigured && props.isWithLink;

  /**
   * NOTE:
   *
   * Only Ann Place in the dev environment can sign in with password
   */
  const isPlaceholder = useMemo(
    () =>
      organization?.subdomain === PLACEHOLDER &&
      (ENV.IS_DEV.toString() === "true" || ENV.DEV.toString() === "true"),
    [organization?.subdomain],
  );

  /**
   * NOTE:
   *
   * Only placeholder in dev environment can sign in with password
   */
  const isWithPassword = useMemo<boolean>(
    () => !!(isPlaceholder && result?.allowPasswordSignup),
    [isPlaceholder, result?.allowPasswordSignup],
  );

  const [linkCredentials, setLinkCredentials] = useState<LinkCredential>();

  const isAnyConfig = useMemo(
    () =>
      some(
        [defaultSupportedIdpConfigs, inboundSamlConfigs, oauthIdpConfigs],
        (signinConfig) => !!signinConfig?.length,
      ),
    [oauthIdpConfigs, defaultSupportedIdpConfigs, inboundSamlConfigs],
  );

  if (httpState.isLoading) {
    return <LoaderLayout />;
  }

  if (!result || !isAnyConfig) {
    return <NoDataLayout />;
  }

  return (
    <>
      {/* Default supported idp configs */}
      {defaultSupportedIdpConfigs?.map((defaultSupportedIdpConfig) => (
        <SignInWithDefaultSupportedIdpConfig
          key={defaultSupportedIdpConfig.displayName}
          linkCredentials={linkCredentials}
          setLinkCredentials={setLinkCredentials}
          {...{ config: defaultSupportedIdpConfig }}
        />
      ))}

      {defaultSupportedIdpConfigs?.length && inboundSamlConfigs?.length ? (
        <AlternativeSignInHorizontalBar />
      ) : null}

      {/* Saml */}
      {inboundSamlConfigs?.map((inboundSamlConfig) => (
        <SignInWithSaml
          key={inboundSamlConfig.displayName}
          {...{ config: inboundSamlConfig }}
        />
      ))}

      {(defaultSupportedIdpConfigs?.length || inboundSamlConfigs?.length) &&
      oauthIdpConfigs?.length ? (
        <AlternativeSignInHorizontalBar />
      ) : null}

      {/* Oauth */}
      {oauthIdpConfigs?.map((oauthConfig) => (
        <SignInWithOauth
          key={oauthConfig.displayName}
          {...{ config: oauthConfig }}
        />
      ))}

      {(defaultSupportedIdpConfigs?.length ||
        inboundSamlConfigs?.length ||
        oauthIdpConfigs?.length) &&
      isWithLink ? (
        <AlternativeSignInHorizontalBar />
      ) : null}

      {isWithLink ? <SignInWithLinkForm /> : null}

      {(defaultSupportedIdpConfigs?.length ||
        inboundSamlConfigs?.length ||
        oauthIdpConfigs?.length ||
        isWithLink) &&
      isWithPassword ? (
        <AlternativeSignInHorizontalBar />
      ) : null}

      {isWithPassword ? <EmailPasswordSignInForm /> : null}
    </>
  );
};
