import {
  ArrowDropDown,
  ArrowDropUp,
  Inventory2Outlined,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import { capitalize } from "lodash";
import React, { useCallback, useMemo, type FC } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { useAlert } from "#shared/components/alerts-provider";
import { HealthStatus } from "#shared/components/data-grid/components/health-status";
import type { AddPopUpBoxOptions } from "#shared/components/pop-up";
import { useDeleteBox } from "#shared/components/pop-up/delete-confirm-box";
import type { Texts } from "#shared/types";
import { FeatureFlag } from "#shared/utils";

import { useUgMemberPermission } from "#organization/pages/authenticated/settings/account/hooks";
import { useOrganizationState } from "#organization/recoil/organization";
import { useProjectContextState } from "#organization/recoil/project";

import {
  useRemoveBlueprintDefinedPolicy,
  useRemoveCustomDefinedPolicy,
  type AdaptedPoliciesGroupData,
} from "../../hooks";

type PolicyAction = {
  icon: JSX.Element;
  actionText: string;
  action: () => void;
};

export interface TableActionsProps extends AdaptedPoliciesGroupData {}

export const TableActions: FC<TableActionsProps & { isMenu?: boolean }> = (
  props,
) => {
  const { origin, status, isMenu } = props;

  /* Members cannot edit/archive/delete policies if the user group does not have write permissions */
  const permissionLock = useUgMemberPermission();

  const { editPolicy, archivePolicy, deletePolicy } = usePolicyActions(props);

  const policyActions = useMemo<PolicyAction[]>(() => {
    const showEdit = origin !== "Self-Hosted" && origin !== "Aperturectl";
    const showArchive = status !== "5-archived" && status !== "3-archived";
    const showDelete = origin !== "Self-Hosted";

    const commonIconStyle = {
      opacity: 0.5,
      cursor: "pointer",
      fontSize: 20,
      ":hover": {
        opacity: 1,
      },
    };

    return [
      ...(showEdit
        ? [
            {
              icon: (
                <EditOutlinedIcon
                  sx={{
                    ...commonIconStyle,
                  }}
                />
              ),
              actionText: "Edit policy",
              action: editPolicy,
            },
          ]
        : []),
      ...(showArchive
        ? [
            {
              icon: (
                <Inventory2Outlined
                  sx={{
                    ...commonIconStyle,
                    fontSize: "18px",
                  }}
                />
              ),
              actionText: "Archive policy",
              action: archivePolicy,
            },
          ]
        : []),
      ...(showDelete
        ? [
            {
              icon: <DeleteOutlineIcon sx={commonIconStyle} color="error" />,
              actionText: "Delete policy",
              action: deletePolicy,
            },
          ]
        : []),
    ];
  }, [archivePolicy, deletePolicy, editPolicy, origin, status]);

  if ((origin !== "Blueprint" && origin !== "Custom") || permissionLock) {
    return null;
  }

  return (
    <FeatureFlag
      flag="canCreatePolicy"
      renderOn={
        <>
          {!isMenu && <PolicyActionButtons policyActions={policyActions} />}
          {isMenu && <PolicyActionMenu policyActions={policyActions} />}
        </>
      }
    />
  );
};

export const PolicyActionButtons: FC<{ policyActions: PolicyAction[] }> = ({
  policyActions,
}) => (
  <TableActionsStyled>
    {policyActions.map(({ action, actionText, icon }) => (
      <Tooltip key={actionText} title={actionText} placement="top">
        <Box onClick={() => action()}>{icon}</Box>
      </Tooltip>
    ))}
  </TableActionsStyled>
);

export const TableActionsStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: theme.spacing(1),
}));

type TextsType =
  | "deletePolicy"
  | "summary"
  | "organization"
  | "project"
  | "status"
  | "warning";
export interface DeletePolicyConfirmBoxMessageProps {
  name: string;
  status: AdaptedPoliciesGroupData["status"];
  texts?: Texts<TextsType>;
}

const defaultTexts: Texts<TextsType> = {
  deletePolicy: "You are about to delete Policy:",
  summary: "Summary:",
  organization: "Organization",
  project: "Project",
  status: "Status",
  warning: "",
};

export const DeletePolicyConfirmBoxMessage: FC<
  DeletePolicyConfirmBoxMessageProps
> = ({ name, status, texts = defaultTexts }) => {
  const { name: projectName } = useProjectContextState();

  const { subdomain: org } = useOrganizationState();

  return (
    <PopUpMessageWrapper py={3}>
      <MessageInnerWrapper>
        <Typography variant="body1">{texts.deletePolicy}</Typography>
        <Box className="info-row">
          <InfoCapsule
            sx={(theme) => ({
              fontSize: 18,
              backgroundColor: alpha(theme.palette.primary.light, 0.1),
              color: theme.palette.primary.main,
            })}
          >
            {name}
          </InfoCapsule>
        </Box>
      </MessageInnerWrapper>

      <MessageInnerWrapper>
        <Typography variant="body1">{texts.summary}</Typography>
        <Box className="info-row">
          <InfoCapsule>{[texts.organization, org].join(": ")}</InfoCapsule>
          <InfoCapsule>{[texts.project, projectName].join(": ")}</InfoCapsule>
          <InfoCapsule>
            {`${texts.status}:`}
            <HealthStatus status={status} />
          </InfoCapsule>
        </Box>
      </MessageInnerWrapper>

      {texts.warning && (
        <WarningInfoWrapper>
          <ErrorOutlineOutlinedIcon />
          {texts.warning}
        </WarningInfoWrapper>
      )}
    </PopUpMessageWrapper>
  );
};

// Delete policy pop up message wrapper
export const PopUpMessageWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  justifyContent: "center",
}));

export const MessageInnerWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  "& .info-row": {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    gap: theme.spacing(1),
    width: "fit-content",
  },
}));

// chips used inside the pop up message
export const InfoCapsule = styled(Box)(({ theme }) => ({
  backgroundColor:
    theme.palette.grey[theme.palette.mode === "light" ? 200 : 700],
  color: theme.palette.text.primary,
  fontWeight: 400,
  fontSize: 16,
  padding: [theme.spacing(0.3), theme.spacing(1)].join(" "),
  borderRadius: theme.spacing(0.5),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

export const WarningInfoWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  fontSize: 14,
  color: theme.palette.error.main,
}));

const usePolicyEdit = ({
  id,
  name,
  origin,
  status,
}: AdaptedPoliciesGroupData) => {
  const navigate = useNavigate();

  const searchParams = useMemo(
    () =>
      new URLSearchParams({
        policyID: id || "",
        origin,
        name,
        status,
      }),
    [id, name, origin, status],
  );

  const editPolicy = useCallback(() => {
    if (id === undefined) {
      return;
    }

    navigate(["/policies/edit-policy", searchParams.toString()].join("?"));
  }, [id, navigate, searchParams]);

  return editPolicy;
};

const usePolicyArchiveDelete = ({
  id,
  origin,
  name,
  status,
  policyAction,
}: AdaptedPoliciesGroupData & { policyAction: "archive" | "delete" }) => {
  const queryClient = useQueryClient();
  const { addAlert } = useAlert();
  const { id: projectID } = useProjectContextState();

  /**
   * We only want to refetch the polices table. So we need to pass the same query key.
   */
  const policiesGridQueryKey = useMemo(
    () => [
      "gridData",
      "policiesWithDefinition",
      "name",
      "asc",
      10,
      0,
      '{"status":{"nilike":"%archived%"}}',
      projectID,
    ],
    [projectID],
  );

  // Archive/delete mutation callbacks
  const commonAlerter = useCallback(
    () => ({
      onSuccess: () => {
        addAlert({
          message: `Policy ${policyAction}d successfully`,
          type: "success",
        });

        // deletion/archive is slow, refetch right away doesn't work
        setTimeout(() => {
          queryClient.invalidateQueries(policiesGridQueryKey);
        }, 500);
      },
      onError: () => {
        addAlert({
          message: `Policy ${policyAction} failed`,
          type: "error",
        });
      },
    }),
    [addAlert, policiesGridQueryKey, policyAction, queryClient],
  );

  const { mutate: mutateBlueprint } = useRemoveBlueprintDefinedPolicy();
  const { mutate: mutateCustom } = useRemoveCustomDefinedPolicy();

  // Pop up to show, where we ask the user to confirm archive/delete
  const { addDeleteBox: addPopUpBox } = useDeleteBox();

  // Trigger archive/delete mutation if user confirms.
  const triggerArchiveDelete = useCallback(
    (policyOrigin: AdaptedPoliciesGroupData["origin"]) => {
      if (policyOrigin === "Self-Hosted" || id === undefined) {
        return;
      }

      // Remove definitions only if the action is delete, otherwise archive it
      const removeDefinition = policyAction === "delete";

      if (policyOrigin === "Custom") {
        mutateCustom({ id, removeDefinition }, commonAlerter());
      } else {
        mutateBlueprint({ id, removeDefinition }, commonAlerter());
      }
    },
    [commonAlerter, id, mutateBlueprint, mutateCustom, policyAction],
  );

  // Pop up box options to change the default behavior.
  const popUpOptionProps = useMemo<AddPopUpBoxOptions>(
    () => ({
      yesButtonProps: {
        showYesButton: true,
        props: {
          variant: "contained",
        },
      },
      noButtonProps: {
        showNoButton: true,
        props: {
          startIcon: <CloseIcon />,
        },
      },
      texts: {
        yes: `${capitalize(policyAction)} policy`,
        no: "Cancel",
        title: `${capitalize(policyAction)} policy`,
      },
    }),
    [policyAction],
  );

  const archiveDeletePolicy = useCallback(() => {
    addPopUpBox(
      {
        message: (
          <DeletePolicyConfirmBoxMessage
            {...{
              name: name || "",
              status,
              texts: {
                ...defaultTexts,
                deletePolicy: `You are about to ${policyAction} the following policy:`,
                summary:
                  policyAction === "delete"
                    ? "This will remove the policy from the Aperture Controller and delete the definition."
                    : "This will remove the policy from the Aperture Controller and change status to archived. The definition will remain.",
                ...(policyAction === "delete" && {
                  warning:
                    "This action is irreversible. Please consider it carefully.",
                }),
              },
            }}
          />
        ),
        callback: (confirm) => {
          if (confirm) {
            triggerArchiveDelete(origin);
          }
        },
      },
      popUpOptionProps,
    );
  }, [
    addPopUpBox,
    name,
    origin,
    policyAction,
    popUpOptionProps,
    status,
    triggerArchiveDelete,
  ]);

  return archiveDeletePolicy;
};

const usePolicyActions = (policyData: AdaptedPoliciesGroupData) => {
  const editPolicy = usePolicyEdit(policyData);

  const archivePolicy = usePolicyArchiveDelete({
    ...policyData,
    policyAction: "archive",
  });

  const deletePolicy = usePolicyArchiveDelete({
    ...policyData,
    policyAction: "delete",
  });

  return { editPolicy, archivePolicy, deletePolicy };
};

export const PolicyActionMenu: FC<{ policyActions: PolicyAction[] }> = ({
  policyActions,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={openMenu}
        endIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
        sx={{
          padding: "0 16px",
        }}
      >
        Actions
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList>
          {policyActions.map(({ action, actionText, icon }) => (
            <MenuItem
              key={actionText}
              onClick={() => {
                closeMenu();
                action();
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ width: 40 }}
              >
                {icon}
              </Box>
              <Typography mr={2}>{actionText}</Typography>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
};
