import { Typography } from "@mui/material";
import React, { type Dispatch, type FC, type SetStateAction } from "react";

import { Input } from "#shared/components/inputs/input";
import type { Texts } from "#shared/types";

import { GridContainer } from "./styled";

import { useGetDefaultUserGroup } from "../../hooks";

interface CreateUserGroupTabProps {
  userGroupName: string | undefined;
  setUserGroupName: Dispatch<SetStateAction<string>>;
  texts?: Texts<Text>;
}

type Text = "title" | "skip" | "placeholder" | "changeLater";

const enText: Texts<Text> = {
  title: "User Group name",
  skip: "Leaving the name empty will create a user group named",
  placeholder: "Backend Admins",
  changeLater: "You will be able to change it later in settings.",
};

export const CreateUserGroupTab: FC<CreateUserGroupTabProps> = ({
  userGroupName,
  setUserGroupName,
  texts = enText,
}) => {
  const { data } = useGetDefaultUserGroup();

  const helperText = [
    texts.skip,
    `"${data?.name || ""}".`,
    texts.changeLater,
  ].join(" ");

  return (
    <GridContainer mb={1}>
      <Typography alignSelf="baseline">{texts.title}</Typography>
      <Input
        size="small"
        fullWidth
        width="auto"
        value={userGroupName}
        onChange={(event) => {
          setUserGroupName(event.currentTarget.value);
        }}
        placeholder={texts.placeholder}
        helperText={helperText}
      />
    </GridContainer>
  );
};
