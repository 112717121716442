import React, { type FC } from "react";
import { useRecoilValue } from "recoil";

import { FirebaseContextProvider } from "#shared/contexts/firebase";

import { OrganizationAppRoutes } from "./pages";
import { OrganizationLoader, organizationState } from "./recoil/organization";
import { verifyUrlInDevMode } from "./verify-url-in-dev-mode";

export const OrganizationApp: FC = () => {
  const organization = useRecoilValue(organizationState);

  return verifyUrlInDevMode(
    <OrganizationLoader>
      <FirebaseContextProvider
        {...{
          isOrganizationContext: true,
          organizationId: organization?.id,
          idpTenantID: organization?.idpTenantID,
        }}
      >
        <OrganizationAppRoutes />
      </FirebaseContextProvider>
    </OrganizationLoader>,
  );
};
