import type { ControllerProps, FieldValues } from "react-hook-form";

export const REQUIRED_FIELD_SIGN = "__REQUIRED_FIELD__";

export const validateFieldWithRequiredSign = <TFields extends FieldValues>(
  message: string,
): ControllerProps<TFields>["rules"] => ({
  required: message,
  validate: (value) => (value === REQUIRED_FIELD_SIGN ? "invalid value" : true),
});
