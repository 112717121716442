import { SvgIcon, type SvgIconProps } from "@mui/material";
import React, { forwardRef, type FC, type PropsWithRef } from "react";

export const AgentsIcon: FC<PropsWithRef<SvgIconProps>> = forwardRef(
  (props, ref) => (
    <SvgIcon {...props} ref={ref}>
      <path d="M8.0901 17.15C3.4701 17.15 0.600098 14.8 0.600098 11.02C0.600098 6.90004 3.9601 3.54004 8.0901 3.54004C12.2201 3.54004 15.5801 6.90004 15.5801 11.03C15.5801 14.81 12.7101 17.16 8.0901 17.16V17.15ZM8.0901 4.34004C4.4001 4.34004 1.4001 7.34004 1.4001 11.03C1.4001 14.36 3.9001 16.36 8.0901 16.36C12.2801 16.36 14.7801 14.37 14.7801 11.03C14.7801 7.34004 11.7801 4.34004 8.0901 4.34004V4.34004Z" />
      <path d="M5.51006 11.6002C6.03473 11.6002 6.46006 11.1749 6.46006 10.6502C6.46006 10.1255 6.03473 9.7002 5.51006 9.7002C4.98539 9.7002 4.56006 10.1255 4.56006 10.6502C4.56006 11.1749 4.98539 11.6002 5.51006 11.6002Z" />
      <path d="M10.66 11.6002C11.1846 11.6002 11.61 11.1749 11.61 10.6502C11.61 10.1255 11.1846 9.7002 10.66 9.7002C10.1353 9.7002 9.70996 10.1255 9.70996 10.6502C9.70996 11.1749 10.1353 11.6002 10.66 11.6002Z" />
      <path d="M8.08979 14.07C4.82979 14.07 2.27979 12.56 2.27979 10.64C2.27979 8.71996 4.82979 7.20996 8.08979 7.20996C11.3498 7.20996 13.8998 8.71996 13.8998 10.64C13.8998 12.56 11.3498 14.07 8.08979 14.07ZM8.08979 7.99996C5.36979 7.99996 3.07979 9.20996 3.07979 10.63C3.07979 12.05 5.37979 13.26 8.08979 13.26C10.7998 13.26 13.0998 12.05 13.0998 10.63C13.0998 9.20996 10.7998 7.99996 8.08979 7.99996V7.99996Z" />
      <path d="M16.3702 8.38008C16.2802 8.38008 16.1802 8.35008 16.1102 8.28008C15.9402 8.14008 15.9202 7.88008 16.0702 7.72008C16.6402 7.06008 16.8302 6.18008 16.9002 5.66008C16.3402 5.73008 15.3702 5.95008 14.7002 6.62008C14.5502 6.77008 14.4202 6.92008 14.2902 7.07008C14.1502 7.24008 13.9002 7.27008 13.7302 7.13008C13.5602 6.99008 13.5302 6.74008 13.6702 6.57008C13.8102 6.40008 13.9702 6.22008 14.1302 6.06008C15.3502 4.84008 17.2502 4.83008 17.3302 4.83008C17.5502 4.83008 17.7302 5.01008 17.7302 5.23008C17.7302 5.30008 17.7202 7.03008 16.6702 8.25008C16.5902 8.34008 16.4802 8.39008 16.3702 8.39008V8.38008Z" />
      <path d="M16.1498 10.9398C15.8498 10.9398 15.5598 10.9198 15.2898 10.8798C15.0698 10.8498 14.9198 10.6498 14.9498 10.4298C14.9798 10.2098 15.1798 10.0598 15.3998 10.0898C15.6398 10.1198 15.8898 10.1398 16.1498 10.1398C17.0898 10.1398 17.9398 9.60982 18.3898 9.25982C17.9398 8.91982 17.0998 8.37982 16.1498 8.37982C15.6798 8.37982 15.2098 8.43982 14.8098 8.53982C14.5898 8.59982 14.3798 8.46982 14.3198 8.24982C14.2598 8.03982 14.3898 7.81982 14.6098 7.75982C15.0798 7.63982 15.6098 7.56982 16.1498 7.56982C17.8698 7.56982 19.2298 8.90982 19.2798 8.95982C19.4398 9.11982 19.4398 9.36982 19.2798 9.52982C19.2198 9.58982 17.8698 10.9198 16.1498 10.9198V10.9398Z" />
    </SvgIcon>
  ),
);
