import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const EdgeButtonStyled = styled("button")(({ theme }) => ({
  background: "transparent",
  cursor: "pointer",
  zIndex: 5,
  padding: 0,
  width: 30,
  height: 30,
  border: `2px solid ${
    theme.palette.mode === "light"
      ? theme.palette.grey[400]
      : theme.palette.grey[600]
  }`,
  outline: `4px solid ${theme.palette.background.paper}`,
  borderRadius: theme.spacing(0.5),
  outlineRadius: theme.spacing(0.5),
  backgroundColor: theme.palette.background.paper,
  "&:hover": {
    borderColor: theme.palette.circuit.edges.onHover,
    "& svg > path": {
      fill: theme.palette.circuit.edges.onHover,
    },
  },
}));

export type NodeDetailWrapperProps = {
  componentColor: string;
};

export const NodeDetailsWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "componentColor",
})<NodeDetailWrapperProps>(({ componentColor, theme }) => ({
  borderRadius: theme.spacing(0.5),
  border: `1px solid ${componentColor}`,
  maxWidth: "99%",
  padding: [theme.spacing(0.4), theme.spacing(1)].join(" "),
}));

export const NodeNameWrapper = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "componentColor",
})<NodeDetailWrapperProps>(({ componentColor }) => ({
  color: componentColor,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 2,
  fontSize: 13,
  fontWeight: 600,
}));
