import {
  Box,
  Tooltip,
  type TooltipProps,
  Button,
  Typography,
} from "@mui/material";
import React, { type FC } from "react";

import { useAlert } from "../alerts-provider";

type CopyTextButtonProps = {
  text?: string;
  tooltipProps?: Partial<TooltipProps>;
};

export const CopyTextButton: FC<CopyTextButtonProps> = ({
  text,
  tooltipProps,
}) => {
  const { addAlert } = useAlert();

  const copy = async (copyText?: string) => {
    if (copyText === undefined) return;

    try {
      await navigator.clipboard.writeText(copyText);

      addAlert({
        type: "success",
        message: "Copied to the clipboard successfully",
      });
    } catch (err) {
      addAlert({
        type: "error",
        message: "Failed to copy to the clipboard",
      });
    }
  };

  return (
    <Tooltip title="Click to copy" placement="top" arrow {...tooltipProps}>
      <Box>
        <Button variant="text" onClick={() => copy(text)} sx={{ padding: 0 }}>
          <Typography variant="body1">{text}</Typography>
        </Button>
      </Box>
    </Tooltip>
  );
};
