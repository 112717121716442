import { policiesGroupData } from "../../hooks";

export const policiesFilters = (id: string) => [
  {
    id: 0,
    name: "Policy name",
    key: "name",
    category: "policiesWithDefinition",
    gql: policiesGroupData,
    selected: false,
    variable: (prop = "") => {
      const variable = {
        first: 5,
        where: {
          projectID: { eq: id },
          name: {},
        },
      };

      if (prop !== "") {
        variable.where.name = {
          like: `${prop}%`,
        };
      }

      return variable;
    },
  },
  {
    id: 1,
    name: "Controller",
    key: "policy.controller.name",
    category: "policiesWithDefinition",
    gql: policiesGroupData,
    selected: false,
    variable: (prop = "") => {
      const variable = {
        first: 5,
        where: {
          projectID: { eq: id },
          policy: {
            controller: {
              name: {},
            },
          },
        },
      };

      if (prop !== "") {
        variable.where.policy.controller.name = {
          like: `${prop}%`,
        };
      }

      return variable;
    },
  },
  {
    id: 2,
    name: "Status",
    key: "status",
    category: "policiesWithDefinition",
    gql: policiesGroupData,
    selected: false,
    variable: (prop = "") => ({
      ...(prop?.length
        ? {
            first: 5,
            where: {
              projectID: { eq: id },
              status: {
                like: `${prop}%`,
              },
            },
          }
        : {
            first: 5,
            where: {
              projectID: { eq: id },
              status: {},
            },
          }),
    }),
  },
];
