import React, { type FC, type PropsWithChildren } from "react";

import { BreadcrumbAppender } from "#shared/components/breadcrumbs";

import { useNewFlyoutMenuBreadcrumb } from "./use-new-flyout-menu-breadcrumb";

export const FlyoutMenuBreadcrumbAppender: FC<PropsWithChildren> = ({
  children,
}) => (
  <BreadcrumbAppender
    {...{
      children,
      useNewBreadcrumb: useNewFlyoutMenuBreadcrumb,
    }}
  />
);
