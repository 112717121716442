import {
  type FirebaseApp,
  initializeApp,
  type FirebaseOptions,
} from "firebase/app";
import { type Auth, getAuth } from "firebase/auth";
import { useCallback, useMemo, useRef } from "react";

import { ENV } from "#shared/env";
import { useHttpStatus } from "#shared/hooks";
import { LoggerService } from "#shared/services";

export function useInitializeFirebase() {
  const status = useHttpStatus<string | null>();

  const appRef = useRef<FirebaseApp | null>(null);
  const authRef = useRef<Auth | null>(null);

  const initialize = useCallback(async () => {
    if (
      (appRef.current && authRef.current) ||
      status.isLoading ||
      status.error
    ) {
      return;
    }

    try {
      status.setIsLoading(true);
      status.setIsIdle(false);
      status.setIsSuccess(false);
      status.setError(null);

      LoggerService.debug("Parsed firebase credentials.");

      appRef.current =
        appRef.current ||
        (await initializeFirebase({
          apiKey: ENV.FIREBASE_API_KEY,
          authDomain: ENV.FIREBASE_DOMAIN,
        }));

      status.setIsLoading(false);
      status.setIsSuccess(true);

      authRef.current = authRef.current || getAuth(appRef.current);

      LoggerService.debug("Initialized firebase.");
    } catch (err) {
      LoggerService.error("Failed to parse firebase credentials.", err);

      status.setIsLoading(false);
      status.setError(err instanceof Error ? err.message : String(err));

      authRef.current = null;
    }
  }, [status]);

  return useMemo(
    () => ({
      initialize,
      auth: authRef.current,
      error: status.error,
      isLoading: status.isLoading,
      isIdle: status.isIdle,
      isSuccess: status.isSuccess,
    }),
    [initialize, status],
  );
}

export const initializeFirebase = (options: FirebaseOptions, name?: string) =>
  new Promise<FirebaseApp>((resolve, reject) => {
    try {
      const app = initializeApp(options, name);
      resolve(app);
    } catch (error) {
      reject(new Error("Failed to initialize firebase app."));
    }
  });
