import { atom, useRecoilValue } from "recoil";

import { ATOM_KEYS } from "#shared/consts";

export type ProjectContextState = { id: string; name: string };

export const projectContextState = atom<ProjectContextState>({
  key: ATOM_KEYS.projectContext,
  default: { id: "", name: "" },
});

export const useProjectContextState = () => {
  const project = useRecoilValue(projectContextState);

  return project;
};
