import { type FC } from "react";
import { RecoilState, useRecoilCallback } from "recoil";
import { BehaviorSubject } from "rxjs";

const recoilSubject = new BehaviorSubject<{
  recoilObj: { key: string };
  value: unknown;
} | null>(null);

const getRecoilSubject = new BehaviorSubject<string | null>(null);
const setRecoilSubject = new BehaviorSubject<
  [key: string, value: unknown] | null
>(null);

export function setRecoilOutsideComponent([key, value]: [
  key: string | null,
  value: unknown,
]) {
  if (!key) {
    return;
  }

  setRecoilSubject.next([key, value]);
}

export function getRecoilOutsideComponent<V>(key: string) {
  return new Promise<V | null>((resolve) => {
    getRecoilSubject.next(key);

    recoilSubject.subscribe({
      next: (value) => {
        if (key === value?.recoilObj.key) {
          resolve((value?.value as V) || null);
        }
      },
    });
  });
}

export const RecoilOutsideComponent: FC = () => {
  const setStore = useRecoilCallback<
    [key: string | null, value: unknown],
    void
  >(
    ({ set }) =>
      (key, value) => {
        if (!key) {
          return;
        }

        set({ key } as RecoilState<unknown>, () => value);
      },
    [],
  );

  const getStore = useRecoilCallback<[key: string | null], Promise<void>>(
    ({ snapshot }) =>
      async (key) => {
        if (!key) {
          return;
        }

        const valueRecoilObj = await snapshot.getPromise({
          key,
        } as RecoilState<unknown>);

        recoilSubject.next({ recoilObj: { key }, value: valueRecoilObj });
      },
    [],
  );

  setRecoilSubject.subscribe({
    next: (value) => {
      const [key, keyValue] = value || [null];

      setStore(key, keyValue);
    },
  });

  getRecoilSubject.subscribe({
    next: (recoilObj) => {
      getStore(recoilObj);
    },
  });

  return null;
};
