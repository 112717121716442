import { Typography, type ButtonProps } from "@mui/material";
import { Box } from "@mui/system";
import type { FirebaseError } from "firebase/app";
import {
  signInWithPopup,
  linkWithCredential,
  GoogleAuthProvider,
  GithubAuthProvider,
  OAuthCredential,
} from "firebase/auth";
import { noop } from "lodash";
import React, {
  type FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import {
  SignInWithGithubButton,
  SignInWithGoogleButton,
} from "#shared/components/buttons";
import { FirebaseContext } from "#shared/contexts/firebase";
import { type UseFetch } from "#shared/hooks";
import { LoggerService } from "#shared/services";
import {
  DEFAULT_IDP_CONFIG_PROVIDERS,
  type MapIdpConfigResponseKeyToIdpConfigObject,
  type Texts,
} from "#shared/types";

export type LinkCredential = {
  pendingCredentials: OAuthCredential;
  email: string;
};

export type SignInWithDefaultSupportedIdpConfigProps = ButtonProps & {
  config: NonNullable<
    UseFetch<
      MapIdpConfigResponseKeyToIdpConfigObject<"defaultSupportedIdpConfigs">
    >["result"]
  >;
  linkCredentials: LinkCredential | undefined;
  setLinkCredentials: (linkCredential: LinkCredential | undefined) => void;
  texts?: Texts<"tocRequired">;
};

export const SignInWithDefaultSupportedIdpConfig: FC<
  SignInWithDefaultSupportedIdpConfigProps
> = ({
  config,
  linkCredentials,
  setLinkCredentials,
  texts = {
    tocRequired: "Please accept the terms and conditions.",
  },
  ...buttonProps
}) => {
  const { firebase } = useContext(FirebaseContext);
  const [errorMsg, setErrorMsg] = useState<string>();

  const provider = useMemo(() => {
    const Provider = DEFAULT_IDP_CONFIG_PROVIDERS[config.displayName];

    return new Provider();
  }, [config.displayName]);

  const handleGenericError = useCallback(
    (err: unknown) => {
      const error = err instanceof Error ? err.message : String(err);
      LoggerService.error("Failed to signInWithPopup", error);
      setErrorMsg(error);

      if (firebase) {
        firebase.signOut();
      }
    },
    [firebase],
  );

  const handleFirebaseError = useCallback(
    (err: unknown) => {
      const firebaseError = err as FirebaseError;

      if (
        firebase &&
        provider &&
        firebaseError.code === "auth/account-exists-with-different-credential"
      ) {
        const email = firebaseError.customData?.email as string | undefined;

        // Get the credential for the current (failed) login
        const pendingCred =
          config.displayName === "google.com"
            ? GoogleAuthProvider.credentialFromError(firebaseError)
            : GithubAuthProvider.credentialFromError(firebaseError);

        const otherProviderName =
          config.displayName === "google.com" ? "Github" : "Google";

        LoggerService.info(
          `User has signed up with a different provider (${otherProviderName}) for the same email.`,
          email,
        );

        if (!pendingCred) {
          LoggerService.error(
            "Failed to get pending credential.",
            firebaseError,
          );

          handleGenericError(
            new Error(
              `Your email is associated with ${otherProviderName}. Try signing in with that provider.`,
            ),
          );

          return;
        }

        setLinkCredentials({
          pendingCredentials: pendingCred,
          email: email || "",
        });

        handleGenericError(
          new Error(
            `Your email is associated with ${otherProviderName}. Please sign in with that provider to link your accounts.`,
          ),
        );
      } else {
        handleGenericError(err);
      }
    },
    [
      config.displayName,
      firebase,
      handleGenericError,
      provider,
      setLinkCredentials,
    ],
  );

  const onClick = useCallback<Required<ButtonProps>["onClick"]>(
    (event) => {
      (buttonProps.onClick || noop)(event);

      if (!firebase) {
        handleGenericError(new Error("Missing firebase."));

        return;
      }

      if (!provider) {
        handleGenericError(new Error("Missing provider."));

        return;
      }

      signInWithPopup(firebase, provider)
        .then((result) => {
          if (linkCredentials && result.user.email === linkCredentials.email) {
            LoggerService.info("Linking provider accounts...");

            return linkWithCredential(
              result.user,
              linkCredentials.pendingCredentials,
            );
          }

          return result;
        })
        .then(() => {
          if (linkCredentials) {
            LoggerService.info("Successfully linked accounts.");
            setLinkCredentials(undefined);
          }
        })
        .catch(handleFirebaseError);
    },
    [
      buttonProps.onClick,
      firebase,
      handleFirebaseError,
      handleGenericError,
      linkCredentials,
      provider,
      setLinkCredentials,
    ],
  );

  return (
    <Box mb={1}>
      {config.displayName === "google.com" && (
        <SignInWithGoogleButton
          {...{
            ...buttonProps,
            onClick,
          }}
        />
      )}
      {config.displayName === "github.com" && (
        <SignInWithGithubButton
          {...{
            ...buttonProps,
            onClick,
          }}
        />
      )}
      {errorMsg ? (
        <Box marginTop={1} marginBottom={2}>
          <Typography color="error">{errorMsg}</Typography>
        </Box>
      ) : null}
    </Box>
  );
};
