import { Typography, Box, type TabsProps, type BoxProps } from "@mui/material";
import { isEmpty, merge } from "lodash";
import React, { type FC, useRef, useState, useEffect } from "react";

import {
  ErrorLayout,
  ErrorText,
  LoaderLayout,
  NoDataLayout,
} from "#shared/components/layouts";
import type { Policy } from "#shared/generated/graphql";
import type { Texts } from "#shared/types";

import type { GenericDashboardType } from "#organization/components/grafana";
import type { DashboardType } from "#organization/pages/consts";

import { PolicyResourceDashboardPageContent } from "./content";

import {
  DEFAULT_DASHBOARDS,
  type PolicyDetailsParams,
} from "../../../routes.definitions";
import { type UseDashboardQueryParamsReturnType } from "../../hooks";
import { usePolicyQueryParams } from "../../hooks/use-policy-query-params";

export interface PolicyResourceDashboardPageProps {
  texts: Required<Texts<Extract<Text, "select">>> &
    Partial<Texts<Exclude<Text, "select">>>;
  policy: Policy;
  outerContainerProps?: Omit<BoxProps, "children">;
  queryParams: UseDashboardQueryParamsReturnType;
}

type Text = "policy" | "noData" | "select";

const enTexts: Required<PolicyResourceDashboardPageProps["texts"]> = {
  policy: "Policy",
  noData: "No dashboard to be displayed.",
  select: "",
};

const OUTER_CONTAINER_PROPS: BoxProps = {
  sx: {
    maxHeight: "100%",
    height: "100%",
    maxWidth: "100%",
    width: "100%",
    overflow: "hidden",
  },
};

export const PolicyResourceDashboardPage: FC<
  PolicyResourceDashboardPageProps
> = ({ policy, texts: propsTexts, outerContainerProps, queryParams }) => {
  const texts = { ...enTexts, ...propsTexts };

  const {
    policySearchParams: { resourceId, resourceType },
  } = usePolicyQueryParams();

  const prevResourceTypeRef = useRef<
    PolicyDetailsParams["resourceType"] | null
  >(null);

  const [activeDashboard, setActiveDashboard] = useState<DashboardType | null>(
    resourceType ? DEFAULT_DASHBOARDS[resourceType] : null,
  );

  useEffect(() => {
    const { current: prevResourceType } = prevResourceTypeRef;

    if (!prevResourceType && resourceType) {
      setActiveDashboard(DEFAULT_DASHBOARDS[resourceType]);
    }
  }, [resourceType]);

  const isLoading = !!(
    (queryParams.isLoading || queryParams.isIdle || !queryParams.isFetched) &&
    !policy
  );

  const onTabChange: TabsProps["onChange"] = (_, newValue) => {
    if (newValue === activeDashboard) {
      return;
    }

    setActiveDashboard(newValue);
  };

  const { isError } = queryParams;
  const isData =
    policy && !isEmpty(queryParams.queryParams || {}) && resourceType;

  const isEmptyPolicy =
    policy &&
    !policy.classifiers.edges.length &&
    !policy.fluxMeters.edges.length;

  return (
    <Box {...merge({}, OUTER_CONTAINER_PROPS, outerContainerProps)}>
      {isError ? (
        <ErrorLayout>
          <ErrorText />
        </ErrorLayout>
      ) : null}
      {isLoading && !isError ? <LoaderLayout /> : null}
      {!isLoading && !isError && !isData ? (
        <NoDataLayout>
          <Typography>{texts.noData}</Typography>
          {resourceType || isEmptyPolicy ? null : (
            <Typography component="span">{texts.select}</Typography>
          )}
        </NoDataLayout>
      ) : null}
      {!isLoading && !isError && isData ? (
        <PolicyResourceDashboardPageContent
          {...{
            onTabChange,
            activeDashboard: activeDashboard as GenericDashboardType,
            queryParams,
            resourceType,
            resourceId,
          }}
        />
      ) : null}
    </Box>
  );
};
