import type { Subscription } from "#shared/types";

export const parseSubscription = (
  subscriptionData: Subscription | undefined,
) => {
  if (!subscriptionData) {
    return undefined;
  }

  // Get billing period
  const billingStart = subscriptionData?.current_term_start;
  const billingEnd = subscriptionData?.current_term_end;
  const billingPeriod =
    billingStart && billingEnd
      ? `${new Date(Number(billingStart) * 1000).toLocaleDateString("en-US", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })} - ${new Date(Number(billingEnd) * 1000).toLocaleDateString(
          "en-US",
          {
            day: "numeric",
            month: "short",
            year: "numeric",
          },
        )}`
      : "-";

  // Check if subscription is in trial
  const inTrial = subscriptionData?.status === "in_trial";

  // Get the plan
  const planData = subscriptionData?.subscription_items?.find(
    (subItem) => subItem?.item_type === "plan",
  );
  const plan = planData?.item_price_id || "-";

  const planSpecificData = [];

  // Plan data specific to each plan
  if (plan.startsWith("Pro-metered")) {
    const paidAgentHourUsed = planData?.metered_quantity ?? "-";

    planSpecificData.push({
      title: "Paid Agent-hours used",
      value: paidAgentHourUsed.toString(),
      subtitle: "in this billing period",
    });
  } else if (plan.startsWith("Pro-cloud")) {
    const agentHourSub = subscriptionData?.subscription_items?.find(
      (subItem) =>
        subItem?.item_type === "addon" &&
        (subItem.item_price_id?.startsWith("Agent-minutes") ||
          subItem.item_price_id?.startsWith("Agent-hours")),
    );

    const paidAgentHourUsed =
      (agentHourSub?.item_price_id.includes("minutes") &&
      agentHourSub?.metered_quantity != null
        ? (agentHourSub.metered_quantity / 60).toFixed(2)
        : agentHourSub?.metered_quantity) ?? "-";

    planSpecificData.push({
      title: inTrial ? "Agent-hours used" : "Paid Agent-hours used",
      value: paidAgentHourUsed.toString(),
      subtitle: "in this billing period",
    });

    const goodRequestsUsed =
      subscriptionData?.subscription_items?.find(
        (subItem) => subItem?.item_price_id.includes("Good-requests"),
      )?.metered_quantity ?? "-";

    planSpecificData.push({
      title: inTrial ? "Good requests used" : "Paid Good requests used",
      value: goodRequestsUsed.toString(),
      subtitle: "in this billing period",
    });
  }

  return [
    { title: "Billing period", value: billingPeriod },
    { title: "Plan", value: plan },
    ...planSpecificData,
  ];
};
