import { Box } from "@mui/material";
import { get, isFunction } from "lodash";
import type { MDXContent } from "mdx/types";
import React, {
  useEffect,
  type Dispatch,
  type SetStateAction,
  type FC,
  useMemo,
} from "react";
import type { FieldValues } from "react-hook-form";

import type { AnyObject } from "#shared/types";

import { FlyoutMenu } from "#organization/components/flyout-menu";
import type { Size } from "#organization/components/flyout-menu/hooks";

import type { RecursiveStringify } from "./builder/types";

import type { FnTheme } from "../../../../../../../../app-theme-provider/types";
import { useCreatePolicyContext } from "../../context";

export declare type DocFlyoutProps<Tree extends AnyObject> = {
  docTree: RecursiveStringify<Tree>;
};

export const DocFlyout = <Tree extends FieldValues>({
  docTree,
}: DocFlyoutProps<Tree>) => {
  const { setCreatePolicyCtx, showDocumentation, docFieldAddress, fullScreen } =
    useCreatePolicyContext<Tree>();

  const setIsFlyoutOpen: Dispatch<SetStateAction<boolean>> = (
    isOpen: boolean | ((prev: boolean) => boolean),
  ) => {
    const open = isFunction(isOpen) ? isOpen(showDocumentation) : isOpen;

    setCreatePolicyCtx((prev) => ({
      ...prev,
      showDocumentation: open,
    }));
  };

  useEffect(() => {
    setCreatePolicyCtx((prev) => ({
      ...prev,
      docTree,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mdxComponent = useMemo(
    () => (docFieldAddress ? get(docTree, docFieldAddress) : undefined),
    [docFieldAddress, docTree],
  );

  return (
    <FlyoutMenu
      useShowHide={[showDocumentation, setIsFlyoutOpen]}
      isFullScreenModeAllowed={false}
      topPosition={fullScreen ? "80px" : "178px"}
      fixedHeight={fullScreen ? "calc(100vh - 150px)" : undefined}
      controlBarProps={{
        sx: (theme) => ({
          backgroundColor: (theme.palette as unknown as FnTheme["palette"])
            .tableGrid.headColor,
          "& .MuiSvgIcon-root": {
            color: theme.palette.text.primary,
          },
        }),
      }}
    >
      {({ size }) => (
        <DocFlyoutContent
          {...{
            mdxComponent,
            size,
          }}
        />
      )}
    </FlyoutMenu>
  );
};

export declare type DocFlyoutContentProps = {
  mdxComponent: MDXContent | undefined;
  size: Size;
};

export const DocFlyoutContent: FC<DocFlyoutContentProps> = ({
  mdxComponent,
  size,
}) => {
  const { setCreatePolicyCtx, fullScreen } = useCreatePolicyContext();

  useEffect(() => {
    setCreatePolicyCtx((prev) => ({
      ...prev,
      flyoutMenuSize: size,
    }));

    return () => {
      setCreatePolicyCtx((prev) => ({
        ...prev,
        flyoutMenuSize: null,
      }));
    };
  }, [size, setCreatePolicyCtx]);

  const MdxComponent = mdxComponent;

  return (
    <Box
      px={1}
      py={2}
      height={fullScreen ? "calc(100% - 72px)" : "calc(100% - 150px)"}
      sx={{ overflowY: "auto" }}
    >
      {MdxComponent && <MdxComponent />}
    </Box>
  );
};
