import { isEqual } from "lodash";
import { useEffect, useRef, useState } from "react";

import type { AnyObject } from "#shared/types";

import type { WithUiData } from "#organization/pages/types";

import { useIsPolicyChanged } from "./common";
import type { UseMenuOptions, UseMenuReturnType } from "./types";

import type { UiData } from "../../../types";

export function useMenu<M extends AnyObject>(options: UseMenuOptions<M>) {
  const { policy, mapToListItem, accessor, filterPredicate, search } = options;

  const [listItems, setListItems] = useState<WithUiData<AnyObject, UiData>[]>(
    [],
  );

  const isPolicyChanged = useIsPolicyChanged(policy);
  const prevSearchRef = useRef(search);

  /**
   * NOTE:
   * set menu list on policy or search changed
   */
  useEffect(() => {
    const { current: prevSearch } = prevSearchRef;
    prevSearchRef.current = search;

    if (!isPolicyChanged && search === prevSearch) {
      return;
    }

    const data = accessor(policy);

    const filteredData = filterPredicate
      ? data.filter(filterPredicate(data, search))
      : data;

    const newListItems = (filteredData || []).map(mapToListItem(data));

    if (!isEqual(listItems, newListItems)) {
      setListItems(newListItems);
    }
  }, [
    policy,
    isPolicyChanged,
    listItems,
    search,
    accessor,
    mapToListItem,
    filterPredicate,
  ]);

  const menu: UseMenuReturnType = {
    listItems,
  };

  return menu;
}
