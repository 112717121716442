import Add from "@mui/icons-material/Add";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import Send from "@mui/icons-material/Send";
import { Typography, Button, Box, CircularProgress, List } from "@mui/material";
import { find } from "lodash";
import React, { type FC, useCallback, useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import type { Texts } from "#shared/types";

import {
  findInvitationResponse,
  type PartialInvitedUserByEmailResponse,
} from "#organization/pages/authenticated/common/use-invite-members";

import { InviteMemberItem } from "./invite-member-item";
import { ListItem } from "./styled";

import type { UseInvite } from "../../hooks";

export interface InviteYourTeamTabProps {
  texts?: Texts<Text>;
  invite: UseInvite;
}

type Text = "addMemberToInvite" | "sendInvitations";

export const enText: Texts<Text> = {
  addMemberToInvite: "Add member to invite",
  sendInvitations: "Send invitations",
};

export const InviteYourTeamTab: FC<InviteYourTeamTabProps> = ({
  texts = enText,
  invite,
}) => {
  const {
    inviteList,
    addInvite,
    sendInvitations,
    isInviteListEmpty,
    isInviteListValid,
    validateInvitations,
    isSendingInvitations,
    invitationResponse,
    isSomeInviteEmpty,
    sendInvitationsError,
    isAllInvitationsSent,
  } = invite;

  const [disabled, setDisabled] = useState(
    isInviteListEmpty ||
      !isInviteListValid ||
      isSendingInvitations ||
      isAllInvitationsSent,
  );

  useEffect(() => {
    setDisabled(
      isInviteListEmpty ||
        !isInviteListValid ||
        isSendingInvitations ||
        isAllInvitationsSent,
    );
  }, [
    isInviteListEmpty,
    isInviteListValid,
    isSendingInvitations,
    isAllInvitationsSent,
  ]);

  const onClick = useCallback(() => {
    const isValid = validateInvitations();

    const isDisabled = isInviteListEmpty || !isValid;

    setDisabled(isDisabled);

    if (!isDisabled) {
      sendInvitations();
    }
  }, [sendInvitations, isInviteListEmpty, validateInvitations]);

  const { register } = useForm({ mode: "onSubmit" });

  return (
    <>
      <Typography mb={2}>
        Provide a valid email address for a user from your organization domain
        and assign a role in the user group created in the last step.
      </Typography>

      {inviteList.length === 0 && (
        <Box
          display="grid"
          gridTemplateColumns="1fr auto"
          columnGap={2}
          rowGap={1}
          alignItems="baseline"
        >
          <Box />
          <Button size="small" onClick={addInvite} startIcon={<Add />}>
            {texts.addMemberToInvite}
          </Button>
        </Box>
      )}

      <List component={Box} display="flex" flexDirection="column" rowGap={1}>
        {inviteList.map((inviteItem, index, invitations) => (
          <InviteMemberItem
            {...{
              // eslint-disable-next-line react/no-array-index-key
              key: index,
              ...inviteItem,
              invite,
              invitations,
              index,
              isSendingInvitations,
              invitationResponse:
                (find(
                  invitationResponse,
                  findInvitationResponse(inviteItem.email),
                ) as PartialInvitedUserByEmailResponse) || null,
              isSomeInviteEmpty,
              register,
            }}
          />
        ))}
        {isAllInvitationsSent ? null : (
          <ListItem sx={({ spacing }) => ({ marginTop: spacing(6) })}>
            {sendInvitationsError?.message ? (
              <Typography
                color="warning.main"
                display="flex"
                alignItems="center"
                columnGap={1}
              >
                <ErrorOutline />
                {sendInvitationsError.message}
              </Typography>
            ) : (
              <Box />
            )}
            <Box />
            <Box />
            <Button
              endIcon={
                isSendingInvitations ? (
                  <CircularProgress size="1em" />
                ) : (
                  <Send />
                )
              }
              variant="contained"
              color="primary"
              onClick={onClick}
              disabled={disabled}
            >
              {texts.sendInvitations}
            </Button>
          </ListItem>
        )}
      </List>
    </>
  );
};
