import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import type { GeneratedQueryParams } from "../selected-resource-context";

export declare type PolicyQueryParams = {
  resourceId: string;
  resourceType: string;
  dashboardType: string;
  resourceView: string;
};

export const usePolicyQueryParams = () => {
  const [search, setPolicySearchParams] = useSearchParams();

  const policySearchParams = useMemo(
    () =>
      ({
        resourceId: search.get("resourceId") || "",
        resourceType: search.get("resourceType") || "",
        dashboardType: search.get("dashboardType") || "",
        resourceView: search.get("resourceView") || "",
      }) as unknown as GeneratedQueryParams,
    [search],
  );

  return {
    policySearchParams,
    setPolicySearchParams,
    search,
  };
};
