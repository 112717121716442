import React, { type FC } from "react";
import { Route, Routes } from "react-router-dom";

import { useSetPageTitle } from "#shared/hooks";

import { TopBarLayout } from "#organization/components/layouts";

import { ControlPointsPage } from "./page";
import { CONTROL_POINTS_ROUTE } from "./routes.definitions";

export const ControlPointsRoutes: FC = () => {
  useSetPageTitle(CONTROL_POINTS_ROUTE.TITLE);

  return (
    <TopBarLayout>
      <Routes>
        <Route index element={<ControlPointsPage />} />
        <Route path="*" element={<ControlPointsPage />} />
      </Routes>
    </TopBarLayout>
  );
};
