import { gql } from "graphql-request";
import { useMemo } from "react";

import type {
  GetProjectDetailsQuery,
  GetProjectStatusQuery,
} from "#shared/generated/graphql";
import { useGqlQuery } from "#shared/utils";

import { QUERY_KEYS } from "#organization/pages/consts";

const GetProjectDetails = gql`
  query GetProjectDetails {
    projects {
      edges {
        node {
          name
          id
          region
          status
        }
      }
    }
  }
`;

export const useGetProjectDetails = () => {
  const { isError, isLoading, refetch, data } =
    useGqlQuery<GetProjectDetailsQuery>(
      [QUERY_KEYS.PROJECT_DETAILS, "projects"],
      GetProjectDetails,
    );

  const projectDetails = useMemo(
    () => data?.projects.edges.map((edge) => edge.node),
    [data],
  );

  return {
    error: isError,
    loading: isLoading,
    refetch,
    data: projectDetails,
  };
};

const GetProjectStatus = gql`
  query GetProjectStatus($where: ProjectBoolExp) {
    projects(where: $where) {
      edges {
        node {
          status
        }
      }
    }
  }
`;

export const useProjectStatus = (projectId: string) => {
  const { data, ...rest } = useGqlQuery<GetProjectStatusQuery>(
    [QUERY_KEYS.PROJECT_STATUS, "projects", projectId],
    GetProjectStatus,
    { where: { id: { eq: projectId } } },
  );

  const projectStatus = data?.projects.edges[0]?.node.status;

  return {
    projectStatus,
    ...rest,
  };
};
