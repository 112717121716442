import { type Auth, sendSignInLinkToEmail } from "firebase/auth";
import { pick } from "lodash";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { LOCAL_STORAGE_KEYS } from "#shared/consts";
import { type HttpState, useHttpStatus } from "#shared/hooks";

export type UseSendSignInLinkToEmail = {
  sendEmail: (emailInForm: string | null) => Promise<void>;
  resetSendEmailStatus: () => void;
  sendEmailStatus: HttpState<string | null>;
};

const SIGN_UP_PATHNAME = "sign-up";
const SIGN_IN_PATHNAME = "sign-in";

export function useSendSignInLinkToEmail(
  firebase: Auth | null,
): UseSendSignInLinkToEmail | null {
  const sendEmailStatus = useHttpStatus<string>();
  const navigate = useNavigate();

  const continueUri = useMemo(
    () =>
      new URL(window.location.href)
        .toString()
        .replace(SIGN_UP_PATHNAME, SIGN_IN_PATHNAME),
    [],
  );

  const resetSendEmailStatus = useMemo<
    UseSendSignInLinkToEmail["resetSendEmailStatus"]
  >(() => sendEmailStatus.reset, [sendEmailStatus]);

  const sendEmail = useMemo<UseSendSignInLinkToEmail["sendEmail"]>(
    () => async (emailInForm: string | null) => {
      try {
        sendEmailStatus.reset();

        if (!firebase) {
          throw new Error("Missing idp in sendEmail.");
        }

        if (!emailInForm) {
          throw new Error("Missing email in sendEmail.");
        }

        sendEmailStatus.setIsLoading(true);
        sendEmailStatus.setIsIdle(false);

        await sendSignInLinkToEmail(firebase, emailInForm, {
          url: continueUri,
          handleCodeInApp: true,
        });

        sendEmailStatus.setIsLoading(false);
        sendEmailStatus.setIsSuccess(true);

        /**
         * NOTE:
         *
         * As recommended in firebase documentation, i.e.:
         *
         * "Send the authentication link to the user's email,
         * and save the user's email
         * in case the user completes the email sign-in on the same device.""
         *
         * "To prevent a sign-in link from being used to sign in as an unintended user or on an unintended device,
         * Firebase Auth requires the user's email address to be provided when completing the sign-in flow.
         * For sign-in to succeed, this email address must match the address to which the sign-in link was originally sent.
         *
         * You can streamline this flow for users who open the sign-in link on the same device they request the link,
         * by storing their email address locally - for instance using localStorage or cookies - when you send the sign-in email.
         * Then, use this address to complete the flow.
         * Do not pass the user’s email in the redirect URL parameters and reuse it as this may enable session injections."
         *
         * https://firebase.google.com/docs/auth/web/email-link-auth#send_an_authentication_link_to_the_users_email_address
         * https://firebase.google.com/docs/auth/web/email-link-auth#complete_sign_in_with_the_email_link
         *
         */
        window.localStorage.setItem(
          LOCAL_STORAGE_KEYS.signInWithEmail,
          emailInForm,
        );

        navigate("/email-sent");
      } catch (err) {
        sendEmailStatus.setIsLoading(false);
        sendEmailStatus.setIsSuccess(false);

        sendEmailStatus.setError(
          err instanceof Error ? err.message : String(err),
        );
      }
    },
    [sendEmailStatus, continueUri, firebase, navigate],
  );

  return useMemo(
    () => ({
      sendEmail,
      resetSendEmailStatus,
      sendEmailStatus: pick(
        sendEmailStatus,
        "isIdle",
        "isLoading",
        "isSuccess",
        "error",
      ),
    }),
    [sendEmail, sendEmailStatus, resetSendEmailStatus],
  );
}
