import type { GraphQLError } from "graphql";
import { gql, type Variables } from "graphql-request";
import { useMutation, type UseQueryResult } from "react-query";

import type {
  GetAgentsCountQuery,
  GetDefaultProjectQuery,
  GetDefaultUserGroupQuery,
  OnboardOrganizationInput,
  UpdateProjectDescriptionInput,
  UpdateProjectNameInput,
  UpdateProjectRegionInput,
  UpdateUserGroupNameInput,
} from "#shared/generated/graphql";
import { useGqlQuery } from "#shared/utils";
import { gqlClient } from "#shared/utils/graphql-client";

import { QUERY_KEYS } from "../../../consts";

export const GetDefaultUserGroup = gql`
  query GetDefaultUserGroup($where: UserGroupBoolExp) {
    userGroups(where: $where) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

type GetDefaultUserGroupData =
  GetDefaultUserGroupQuery["userGroups"]["edges"][0]["node"];

export const useGetDefaultUserGroup = (
  where: Variables = {},
): {
  data: GetDefaultUserGroupData | undefined;
} & Omit<UseQueryResult<GetDefaultUserGroupQuery, GraphQLError>, "data"> => {
  const query = useGqlQuery<GetDefaultUserGroupQuery>(
    QUERY_KEYS.WELCOME_USER_GROUP,
    GetDefaultUserGroup,
    where,
  );
  const { data, ...other } = query;

  return {
    // NOTE: Assumption made: there is only one userGroup
    data: data?.userGroups.edges[0].node,
    ...other,
  };
};

const WelcomeScreenUserGroupNameChange = gql`
  mutation WelcomeScreenUserGroupNameChange($input: UpdateUserGroupNameInput!) {
    updateUserGroupName(input: $input) {
      userGroup {
        id
      }
    }
  }
`;

export const useChangeDefaultUserGroupName = () =>
  useMutation<unknown, unknown, UpdateUserGroupNameInput>(async (input) =>
    gqlClient.request(WelcomeScreenUserGroupNameChange, {
      input,
    }),
  );

export const GetDefaultProject = gql`
  query GetDefaultProject($where: ProjectBoolExp) {
    projects(where: $where) {
      edges {
        node {
          id
          name
          cloud_provider
          region
          description
        }
      }
    }
  }
`;

type GetDefaultProjectQueryData =
  GetDefaultProjectQuery["projects"]["edges"][0]["node"];

export const useGetDefaultProject = (
  where: Variables = {},
): {
  data: GetDefaultProjectQueryData | undefined;
} & Omit<UseQueryResult<GetDefaultProjectQuery, GraphQLError>, "data"> => {
  const query = useGqlQuery<GetDefaultProjectQuery>(
    QUERY_KEYS.WELCOME_DEFAULT_PROJECT,
    GetDefaultProject,
    where,
  );

  const { data, ...other } = query;

  return {
    // NOTE: Assumption made: there is only one project
    data: data?.projects.edges[0].node,
    ...other,
  };
};

const WelcomeScreenProjectNameChange = gql`
  mutation WelcomeScreenProjectNameChange($input: UpdateProjectNameInput!) {
    updateProjectName(input: $input) {
      project {
        id
      }
    }
  }
`;

export const useWelcomeScreenProjectNameChange = () =>
  useMutation<unknown, unknown, UpdateProjectNameInput>(async (input) =>
    gqlClient.request(WelcomeScreenProjectNameChange, {
      input,
    }),
  );

const WelcomeScreenProjectDescriptionChange = gql`
  mutation WelcomeScreenProjectDescriptionChange(
    $input: UpdateProjectDescriptionInput!
  ) {
    updateProjectDescription(input: $input) {
      project {
        id
      }
    }
  }
`;

export const useWelcomeScreenProjectDescriptionChange = () =>
  useMutation<unknown, unknown, UpdateProjectDescriptionInput>(async (input) =>
    gqlClient.request(WelcomeScreenProjectDescriptionChange, {
      input,
    }),
  );

const WelcomeScreenProjectRegionChange = gql`
  mutation WelcomeScreenProjectRegionChange($input: UpdateProjectRegionInput!) {
    updateProjectRegion(input: $input) {
      project {
        id
      }
    }
  }
`;

export const useWelcomeScreenProjectRegionChange = () =>
  useMutation<unknown, unknown, UpdateProjectRegionInput>(async (input) =>
    gqlClient.request(WelcomeScreenProjectRegionChange, {
      input,
    }),
  );

const WelcomePageOnBoarding = gql`
  mutation WelcomePageOnBoarding($input: OnboardOrganizationInput!) {
    onboardOrganization(input: $input) {
      organization {
        name
      }
    }
  }
`;

export const useWelcomePageOnBoarding = () =>
  useMutation<unknown, unknown, OnboardOrganizationInput>(async (input) =>
    gqlClient.request(WelcomePageOnBoarding, {
      input,
    }),
  );

const GetAgentsCount = gql`
  query GetAgentsCount {
    agents {
      totalCount
    }
  }
`;

export const useGetAgentsCount = () => {
  const query = useGqlQuery<GetAgentsCountQuery>(
    QUERY_KEYS.WELCOME_AGENTS_COUNT,
    GetAgentsCount,
    {},
  );

  const { data, ...other } = query;

  return {
    count: data?.agents.totalCount || 0,
    ...other,
  };
};
