import { styled } from "@mui/system";
import { Link, type LinkProps } from "react-router-dom";

export type TextLinkProps = LinkProps;

/** A styled link to internal pages. Use it to route to a page within the same application */
export const TextLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 500,
  textDecoration: "underline",
}));
