import React, { type Dispatch, type SetStateAction } from "react";
import type { FieldValues } from "react-hook-form";

import { emptyColumnValue, type HeadCell } from "#shared/components/data-grid";

import { ActionsButtons } from "./actions-buttons";
import { AssignedNotifiers } from "./assigned-notifiers";
import type { ChannelData } from "./types";

export const channelTableHead = (
  setShowHide: Dispatch<SetStateAction<boolean>>,
  setEditFormValues: Dispatch<SetStateAction<FieldValues>>,
): HeadCell<ChannelData>[] => [
  {
    accessor: (row) => (row.node.name ? `#${row.node.name}` : emptyColumnValue),
    label: "Channel name",
    key: "name",
    width: "300px",
  },
  {
    accessor: ({ node }) => (
      <AssignedNotifiers
        webhookLength={node.alertsWebhookNotifiers.edges.length}
        emailLength={node.alertsEmailNotifiers.edges.length}
        slackLength={node.alertsSlackNotifiers.edges.length}
      />
    ),
    label: "Assigned notifiers",
    key: "notifiers",
    noSorting: true,
  },
  {
    accessor: (row) => (
      <ActionsButtons
        action={setShowHide}
        setEditFormValues={setEditFormValues}
        row={row}
      />
    ),
    label: "Actions",
    key: "actions",
    width: "100px",
    noSorting: true,
  },
];
