import React, { type ComponentType, type FC, useContext } from "react";

import {
  errorBoundaryContext,
  type ErrorBoundaryContextValue,
} from "./context";

export type WithErrorBoundaryProps<P> = P & ErrorBoundaryContextValue;

export const withErrorBoundary = <P,>(
  ChildComponent: ComponentType<WithErrorBoundaryProps<P>>,
): FC<P> =>
  function WithErrorBoundary(props) {
    const context = useContext(errorBoundaryContext);

    return <ChildComponent {...props} {...context} />;
  };
