import { gql } from "graphql-request";
import { useState } from "react";

import type { GetAllUserGroupsQuery } from "#shared/generated/graphql";
import { useGqlQuery } from "#shared/utils";

export const ALL_USER_GROUP_DATA_KEY = "userGroups";

export const AllUserGroupsQuery = gql`
  query GetAllUserGroups($first: Int, $where: UserGroupBoolExp) {
    userGroups(first: $first, where: $where) {
      edges {
        node {
          id
          name
          userGroupMemberships {
            totalCount
          }
        }
      }
    }
  }
`;

export const useAllUserGroupsQuery = () => {
  const [search, setSearch] = useState<string>("");

  const variable = { first: 4, where: { name: { like: `${search}%` } } };

  const { data, isLoading, isError, refetch } =
    useGqlQuery<GetAllUserGroupsQuery>(
      [ALL_USER_GROUP_DATA_KEY, search],
      AllUserGroupsQuery,
      variable,
    );

  return {
    error: isError,
    loading: isLoading,
    userGroups: data?.userGroups.edges,
    refetch,
    setSearch,
    search,
  };
};
