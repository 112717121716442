import { gql } from "graphql-request";

// mutation gql for alert manager

export const createAlertsChannelGQL = gql`
  mutation createAlertsChannel($input: CreateAlertsChannelInput!) {
    createAlertsChannel(input: $input) {
      alertsChannel {
        id
      }
    }
  }
`;

export const createAlertsEmailNotifierGQL = gql`
  mutation createAlertsEmailNotifier($input: CreateAlertsEmailNotifierInput!) {
    createAlertsEmailNotifier(input: $input) {
      alertsEmailNotifier {
        id
        email
      }
    }
  }
`;

export const createAlertsSlackNotifierGQL = gql`
  mutation createAlertsSlackNotifier($input: CreateAlertsSlackNotifierInput!) {
    createAlertsSlackNotifier(input: $input) {
      alertsSlackNotifier {
        channel
        id
        url
      }
    }
  }
`;

export const createAlertsWebhookNotifierGQL = gql`
  mutation createAlertsWebhookNotifier(
    $input: CreateAlertsWebhookNotifierInput!
  ) {
    createAlertsWebhookNotifier(input: $input) {
      alertsWebhookNotifier {
        id
        url
      }
    }
  }
`;

export const silentChannelGQL = gql`
  mutation silenceAlertsChannel($input: SilenceAlertsChannelInput!) {
    silenceAlertsChannel(input: $input) {
      alertsChannel {
        id
        name
        silencedUntil
      }
    }
  }
`;

export const unmuteAlertsChannelGQL = gql`
  mutation unmuteAlertsChannel($input: UnmuteAlertsChannelInput!) {
    unmuteAlertsChannel(input: $input) {
      alertsChannel {
        id
        name
        silencedUntil
      }
    }
  }
`;

// delete mutations

export const deleteAlertsChannelGQL = gql`
  mutation deleteAlertsChannel($input: DeleteAlertsChannelInput!) {
    deleteAlertsChannel(input: $input) {
      alertsChannel {
        name
        id
      }
    }
  }
`;

export const deleteAlertsEmailNotifierGQL = gql`
  mutation deleteAlertsEmailNotifier($input: DeleteAlertsEmailNotifierInput!) {
    deleteAlertsEmailNotifier(input: $input) {
      alertsEmailNotifier {
        email
        id
      }
    }
  }
`;

export const deleteAlertsSlackNotifierGQL = gql`
  mutation deleteAlertsSlackNotifier($input: DeleteAlertsSlackNotifierInput!) {
    deleteAlertsSlackNotifier(input: $input) {
      alertsSlackNotifier {
        id
        channel
        url
      }
    }
  }
`;

export const deleteAlertsWebhookNotifierGQL = gql`
  mutation deleteAlertsWebhookNotifier(
    $input: DeleteAlertsWebhookNotifierInput!
  ) {
    deleteAlertsWebhookNotifier(input: $input) {
      alertsWebhookNotifier {
        id
        url
      }
    }
  }
`;

// update mutation

export const updateAlertsChannelGQL = gql`
  mutation updateAlertsChannel($input: UpdateAlertsChannelInput!) {
    updateAlertsChannel(input: $input) {
      alertsChannel {
        name
        id
      }
    }
  }
`;

export const updateAlertsEmailNotifierGQL = gql`
  mutation updateAlertsEmailNotifier($input: UpdateAlertsEmailNotifierInput!) {
    updateAlertsEmailNotifier(input: $input) {
      alertsEmailNotifier {
        email
        id
      }
    }
  }
`;

export const updateAlertsSlackNotifierGQL = gql`
  mutation updateAlertsSlackNotifier($input: UpdateAlertsSlackNotifierInput!) {
    updateAlertsSlackNotifier(input: $input) {
      alertsSlackNotifier {
        channel
        id
        url
      }
    }
  }
`;

export const updateAlertsWebhookNotifierGQL = gql`
  mutation updateAlertsWebhookNotifier(
    $input: UpdateAlertsWebhookNotifierInput!
  ) {
    updateAlertsWebhookNotifier(input: $input) {
      alertsWebhookNotifier {
        id
        url
      }
    }
  }
`;

// query gql for alerts manager

export const alertsOrganizationConfigsGQL = gql`
  query alertsOrganizationConfigs($where: AlertsOrganizationConfigBoolExp) {
    alertsOrganizationConfigs(where: $where) {
      edges {
        node {
          id
          config
        }
      }
    }
  }
`;

export const alertsChannelsGQL = gql`
  query alertsChannels(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: AlertsChannelBoolExp
    $orderBy: [AlertsChannelOrderBy]
  ) {
    alertsChannels(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          name
          silencedUntil
          project {
            id
          }
          alertsEmailNotifiers {
            edges {
              node {
                email
                id
                sendResolved
              }
            }
          }
          alertsSlackNotifiers {
            edges {
              node {
                channel
                id
                url
                sendResolved
              }
            }
          }

          alertsWebhookNotifiers {
            edges {
              node {
                id
                url
                sendResolved
              }
            }
          }
        }
      }
    }
  }
`;

export const alertLogsGQL = gql`
  query alerts(
    $first: Int
    $after: String
    $before: String
    $where: AlertBoolExp
    $distinctOn: [AlertSelectColumn!]
    $orderBy: [AlertOrderBy]
  ) {
    alerts(
      first: $first
      after: $after
      before: $before
      where: $where
      orderBy: $orderBy
      distinctOn: $distinctOn
    ) {
      totalCount
      pageInfo {
        endCursor
      }
      edges {
        node {
          id
          name
          startsAt
          severity
          annotations
          labels
          alertsChannelAssignments {
            edges {
              node {
                alertsChannelName
                alertsChannel {
                  id
                  silencedUntil
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const alertsCountBySeverityGQL = gql`
  query alertsCountBySeverity($where: AlertBoolExp) {
    alerts(where: $where) {
      totalCount
      edges {
        node {
          severity
        }
      }
    }
  }
`;
