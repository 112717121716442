import {
  Tooltip as MuiTooltip,
  tooltipClasses,
  type TooltipProps,
  Typography,
  type TypographyProps,
  Zoom,
  styled,
} from "@mui/material";
import { Box, useTheme } from "@mui/system";
import classNames from "classnames";
import React, {
  type FC,
  type ReactNode,
  type RefObject,
  useEffect,
  useState,
  type ReactElement,
  useCallback,
} from "react";

import type { FnTheme } from "../../../../app-theme-provider/types";

type Option = {
  title?: string;
  value?: string;
  color?: string;
};

export type CustomTooltipProps = Pick<TooltipProps, "children"> & {
  className?: string;
  tooltipProps?: Omit<TooltipProps, "title" | "children">;
  typographyProps?: TypographyProps;
  title?: ReactNode;
  description?: ReactNode;
  options?: Option[];
  placement?: "right" | "top" | "bottom" | "left";
  disable?: boolean;
};

export type TooltipOnOverflowType = (tooltipRef: RefObject<HTMLDivElement>) => {
  disable: boolean;
};

export const useTooltipOnOverflow: TooltipOnOverflowType = (tooltipRef) => {
  const [disable, setDisable] = useState(true);

  const compare = useCallback(() => {
    if (!tooltipRef?.current) {
      return;
    }

    const { scrollWidth, clientWidth } = tooltipRef.current;

    setDisable(!(scrollWidth > clientWidth));
  }, [tooltipRef, setDisable]);

  useEffect(() => {
    compare();
  }, [compare]);

  useEffect(() => {
    window.addEventListener("resize", compare);

    return () => {
      window.removeEventListener("resize", compare);
    };
  }, [compare]);

  return { disable };
};

const Tooltip: FC<CustomTooltipProps> = ({
  tooltipProps,
  typographyProps,
  children,
  className,
  title = null,
  description = null,
  options,
  placement = "right",
  disable = false,
}) => {
  const theme = useTheme();

  return (
    <MuiTooltip
      TransitionComponent={Zoom}
      placement={placement}
      arrow
      {...tooltipProps}
      title={
        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Typography
            sx={{ typography: "body3-semibold" }}
            {...typographyProps}
          >
            {title}
          </Typography>
          {description && (
            <Typography sx={{ fontWeight: 400, fontSize: "12px" }}>
              {description}
            </Typography>
          )}
          {options?.length ? (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              {options.map((option) => (
                <Box key={option.title} sx={{ display: "flex", gap: "6px" }}>
                  <Box
                    sx={{
                      width: "3px",
                      height: "42px",
                      borderRadius: "10px",
                      backgroundColor:
                        option.color || theme.palette.primary.main,
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      justifyContent: "center",
                    }}
                  >
                    {option.title && (
                      <Typography sx={{ fontWeight: 400, fontSize: "12px" }}>
                        {option.title}
                      </Typography>
                    )}
                    {option.value && (
                      <Typography sx={{ typography: "body3-semibold" }}>
                        {option.value}
                      </Typography>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          ) : null}
        </Box>
      }
      classes={{
        ...tooltipProps?.classes,
        popper: classNames(className, tooltipProps?.classes?.popper),
      }}
      disableHoverListener={disable}
    >
      {children}
    </MuiTooltip>
  );
};

export interface OnOverflowTooltipProps
  extends Pick<CustomTooltipProps, "title" | "typographyProps" | "placement"> {
  passedRef: RefObject<HTMLDivElement>;
  children: ReactElement & ReactNode;
  tooltipProps?: Omit<CustomTooltipProps["tooltipProps"], "disabled">;
}

export const OnOverflowTooltip: FC<OnOverflowTooltipProps> = ({
  passedRef,
  placement = "top",
  ...props
}) => {
  const { disable } = useTooltipOnOverflow(passedRef);

  return (
    <CustomTooltip
      {...{ ...props, placement, arrow: true }}
      disable={disable}
    />
  );
};

export const CustomTooltip = styled(Tooltip)((options) => {
  const {
    palette: { mode },
  } = options.theme as unknown as FnTheme;

  const color = mode === "light" ? "#000000" : "#ffffff";
  const backgroundColor = mode === "light" ? "#eeeeee" : "#56524d";

  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor,
      color,
      boxShadow: "0px 2px 16px rgb(10 9 9 / 20%)",
      fontSize: "0.75rem",
      maxWidth: "max-content",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: backgroundColor,
    },
  };
});
