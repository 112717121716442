import { ContentCopy } from "@mui/icons-material";
import { Box, Button, Typography, styled, type BoxProps } from "@mui/material";
import React, { type FC, type ReactElement, useState } from "react";

import type { FnTheme } from "../../../../../app-theme-provider/types";

const CodeBlockText = styled(Typography)(({ theme }) => ({
  pl: 1,
  color: (theme as unknown as FnTheme).palette.codeBlock.text,
  fontSize: "0.9rem",
  overflow: "auto",
  fontFamily: "Consolas, monaco, monospace",
}));

const CodeBlockWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  border: `1px solid ${
    (theme as unknown as FnTheme).palette.codeBlock.background
  }`,
  backgroundColor: (theme as unknown as FnTheme).palette.codeBlock.background,
  borderRadius: theme.shape.borderRadius,
}));

const COPY_TIMEOUT = 2500;

type TextAreaProps = {
  cliTextBody: string | ReactElement;
  isOneLine?: boolean;
  cliTextContent: string;
  infoText?: string;
  hideCopyButton?: boolean;
  boxProps?: BoxProps;
};

export const CopyTextArea: FC<TextAreaProps> = ({
  isOneLine,
  cliTextBody,
  cliTextContent,
  infoText = "",
  hideCopyButton = false,
  boxProps = {},
}) => {
  const [copySuccess, setCopySuccess] = useState("Copy");

  const handleClickCopyIcon = () => {
    navigator.clipboard.writeText(cliTextContent.trim());
    setCopySuccess("Copied!");

    setTimeout(() => {
      setCopySuccess("Copy");
    }, COPY_TIMEOUT);
  };

  const cliText = <CodeBlockText>{cliTextBody}</CodeBlockText>;

  return (
    <Box>
      <CodeBlockWrapper {...boxProps}>
        <Box
          sx={{
            display: "flex",
            justifyContent: `${isOneLine ? "space-between" : "flex-end"}`,
            alignItems: "center",
          }}
        >
          {isOneLine && cliText}
          {!hideCopyButton && (
            <Button
              sx={{
                transition: "550ms",
              }}
              disableRipple
              variant="outlined"
              onClick={handleClickCopyIcon}
              startIcon={<ContentCopy />}
              size="small"
            >
              <Typography
                sx={{
                  textTransform: "uppercase",
                  pl: 1,
                }}
              >
                {copySuccess}
              </Typography>
            </Button>
          )}
        </Box>
        {!isOneLine && cliText}
      </CodeBlockWrapper>
      {infoText?.length ? (
        <Typography
          sx={{
            mt: 1,
            fontSize: "small",
            color: "text.secondary",
          }}
        >
          {infoText}
        </Typography>
      ) : null}
    </Box>
  );
};

CopyTextArea.defaultProps = {
  isOneLine: false,
};
