import { gql } from "graphql-request";

import { useGqlMutation } from "#shared/utils";

const RemoveUserGroup = gql`
  mutation deleteUserGroup($input: DeleteUserGroupInput!) {
    deleteUserGroup(input: $input) {
      userGroup {
        id
      }
    }
  }
`;

export const useRemoveUserGroup = () => {
  const { mutate } = useGqlMutation<unknown, unknown, {}>(RemoveUserGroup);

  return {
    remove: mutate,
  };
};

// TODO: refactor this
export const GetUserGroupMembershipsById = gql`
  query getUserGroupMembershipsById($where: UserGroupMembershipBoolExp) {
    userGroupMemberships(where: $where) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export const getUserGroupMembershipsByIdVariables = (
  userGroupId: string,
  userId: string,
) => ({
  where: {
    userGroupID: {
      eq: userGroupId,
    },
    userID: {
      eq: userId,
    },
  },
});
