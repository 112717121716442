import { CircularProgress, Typography } from "@mui/material";
import React, { type FC, type ReactNode } from "react";

import type { AlertSeverityData } from "#organization/pages/authenticated/alert-manager/data-center";

import { AlertTileStyled } from "./styled";

import type { AlertsLogType } from "../alerts-table/types";

export interface AlertTileProps {
  title: string;
  severity: AlertsLogType["severity"];
  data: AlertSeverityData;
  icon?: ReactNode;
}

// TODO: Add isError logic
export const AlertTile: FC<AlertTileProps> = ({
  title,
  severity,
  data,
  icon,
}) => (
  <AlertTileStyled severity={severity}>
    <Typography component="span" className="__titleOfTile">
      {icon} {title}
    </Typography>
    {data.isLoading || data.isIdle ? (
      <CircularProgress size="1em" />
    ) : (
      <Typography component="span" className="__bodyOfTile">
        {data.data}
      </Typography>
    )}
  </AlertTileStyled>
);
