import React, { type FC } from "react";
import { useNavigate } from "react-router-dom";

import { InfoDataPage } from "#shared/components/layouts";
import { BASE_DOCUMENTATION_URL } from "#shared/consts";
import type { Texts } from "#shared/types";
import { FEATURE_FLAGS } from "#shared/utils";

import { useUgMemberPermission } from "../settings/account/hooks";

export interface NoPoliciesProps {
  texts?: Texts<Text>;
}

type Text =
  | "noPolicies"
  | "policyDesc"
  | "blueprints"
  | "customPolicies"
  | "blueprintsDesc"
  | "customPoliciesDesc"
  | "customize"
  | "create"
  | "policyDoc"
  | "apertureCtlPolicies"
  | "apertureCtlPoliciesDesc"
  | "aperturectlButton";

const enTexts: NoPoliciesProps["texts"] = {
  noPolicies: "We haven’t found any Policies",
  policyDesc:
    "A policy is a set of rules for managing reliability conditions. It monitors signals and responds accordingly, often through alerting, auto-scaling, or load shedding. It's always active and can allocate extra resources for better visibility.",
  blueprints: "Blueprints",
  blueprintsDesc:
    "FluxNinja provides pre-packaged blueprints as templates for easy policy creation. These blueprints cover many common scenarios, making it simpler to create complex policy circuits.",
  customize: "Customize",
  create: "Create",
  customPolicies: "Creating Custom Policies",
  customPoliciesDesc:
    "FluxNinja Aperture lets you build policies from scratch. Choose this option to use circuit components and define your own control logic. ",
  policyDoc: "Policy Documentation",
  apertureCtlPolicies: "CLI (aperturectl)",
  apertureCtlPoliciesDesc:
    "aperturectl is a CLI tool which can be used to interact with Aperture seamlessly. You can use aperturectl to create, update, delete policies and also to get the status of policies.",
  aperturectlButton: "Learn More",
};

export const NoPolicies: FC<NoPoliciesProps> = ({ texts = enTexts }) => {
  const navigate = useNavigate();

  /* Members cannot create policies if the user group does not have write permissions */
  const permissionLock = useUgMemberPermission();

  const actions = [
    ...(FEATURE_FLAGS.canCreatePolicy && !permissionLock
      ? [
          {
            title: texts.blueprints,
            description: texts.blueprintsDesc,
            buttonText: texts.customize,
            buttonProps: {
              onClick: () =>
                navigate("/policies/create-policy?origin=Blueprint"),
            },
          },
          ...(FEATURE_FLAGS.showCreateCustomPolicy
            ? [
                {
                  title: texts.customPolicies,
                  description: texts.customPoliciesDesc,
                  buttonText: texts.create,
                  buttonProps: {
                    onClick: () =>
                      navigate("/policies/create-custom-policy?origin=Custom"),
                  },
                },
              ]
            : []),
        ]
      : []),
    {
      title: texts.apertureCtlPolicies,
      description: texts.apertureCtlPoliciesDesc,
      buttonText: texts.aperturectlButton,
      buttonProps: {
        href: `${BASE_DOCUMENTATION_URL}/reference/aperture-cli/`,
        target: "__blank",
      },
    },
  ];

  return (
    <InfoDataPage
      title={texts.noPolicies}
      description={texts.policyDesc}
      actions={actions}
      docButtonText={texts.policyDoc}
      docButtonLink="/get-started/policies/"
    />
  );
};
