import React, { type FC, type PropsWithChildren } from "react";

import { LoaderLayout } from "#shared/components/layouts";
import { FEATURE_FLAGS } from "#shared/utils";

import { useIsSubscribed } from "#organization/pages/authenticated/settings/organization/billing/hooks";

import { BillingNotDone } from "./billing-not-done";

export type PaymentGatewayProps = PropsWithChildren<{
  // texts?: Texts<Text>;
}>;

/* type Text = "error";

const enTexts: Required<PaymentGatewayProps["texts"]> = {
  error: "Something went wrong checking billing details",
}; */

export const PaymentGateway: FC<PaymentGatewayProps> = ({
  children,
  /* texts = enTexts, */
}) => {
  const {
    data: isSubscribedData,
    isLoading: isLoadingSubscribed,
    isError: isErrorSubscribed,
  } = useIsSubscribed();

  if (!FEATURE_FLAGS.globalBilling) {
    return <>{children}</>;
  }

  if (isLoadingSubscribed) {
    return (
      <LoaderLayout outerContainerProps={{ height: "100vh", width: "100vw" }} />
    );
  }

  /* Commented out this check as it is a bit problematic and we don't want users to get stuck as a result of it */
  /* Plus we already have a similar check for 402 when querying for projects so this is not a problem */
  /* if (isErrorSubscribed) {
    return (
      <GlobalFrame hideLogo hideLogout>
        <ErrorLayout>
          <ErrorText>{texts.error}</ErrorText>
        </ErrorLayout>
      </GlobalFrame>
    );
  } */

  if (!isSubscribedData?.data && !isErrorSubscribed) {
    return <BillingNotDone />;
  }

  return <>{children}</>;
};
