import { type LinkProps } from "react-router-dom";

import type { RouteDefinition } from "#shared/types";

import type { GenericDashboardType } from "#organization/components/grafana";
import type { DashboardType } from "#organization/pages/consts";
import {
  type ResourceWithDashboard,
  type ResourceWithGenericDashboard,
} from "#organization/pages/types";

export type PolicyDetailsParams = {
  policyId: string;
  /**
   * NOTE:
   * resourceId equals to  resource id for Classifier/FluxMeter, component index for RateLimiter/ConcurrencyLimiter
   */
  resourceId?: string;
  resourceType?: ResourceWithGenericDashboard;
  dashboardType?: GenericDashboardType;
  resourceView?: ResourceView;
};

export type ResourceView = "dashboards" | "circuit" | "alerts";

export type PolicyTab = ResourceView;

export type AbsolutePath = (
  policyId: string,
  resourceId: string,
  dashboardType: DashboardType,
) => LinkProps["to"];

export const POLICIES_ROUTE: RouteDefinition<string> = {
  TITLE: "Policies",
  KEY: "policies",
  CHILD_KEY: "policyId",
  ABSOLUTE_PATH: "/policies",
  PARENT_PATH: "policies/*",
  CHILD_PATH: "policy/:policyId/*",
};

const POLICY_PATH = "policy/:policyId";

export const POLICY_ROUTE: RouteDefinition<(...urlParams: string[]) => string> &
  Required<Pick<RouteDefinition, "PARENT_PATH">> = {
  TITLE: "Policy",
  KEY: "policy",
  CHILD_KEY: "resourceId",
  PARENT_PATH: "policies/policy/*",
  CHILD_PATH: ":resourceId/*",
  ABSOLUTE_PATH: (policyId = ":resourceId") =>
    [POLICIES_ROUTE.ABSOLUTE_PATH, "policy", policyId].join("/"),
  PATH: POLICY_PATH,
};

export const CREATE_POLICY_ROUTE: RouteDefinition<string> = {
  TITLE: "Create Policy",
  KEY: "create-policy",
  ABSOLUTE_PATH: "/create-policy",
  PARENT_PATH: "policies/*",
};

export const EDIT_POLICY_ROUTE: RouteDefinition<string> = {
  TITLE: "Edit Policy",
  KEY: "edit-policy",
  ABSOLUTE_PATH: "/edit-policy",
  PARENT_PATH: "policies/*",
};

export const DEFAULT_DASHBOARDS: {
  [R in ResourceWithDashboard]: DashboardType;
} = {
  Classifier: "FLOW_ANALYTICS",
  ConcurrencyLimiter: "CONCURRENCY_LIMITER",
  FluxMeter: "FLOW_ANALYTICS",
  RateLimiter: "RATE_LIMITER",
  Signal: "SIGNAL",
  Dashboards: "DASHBOARDS",
};
