import type { AxiosError } from "axios";
import { gql } from "graphql-request";
import React, {
  type FC,
  type PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { useRecoilState } from "recoil";

import { LoaderLayout } from "#shared/components/layouts";
import type { FirstProjectQuery } from "#shared/generated/graphql";
import type { Texts } from "#shared/types";
import { useGqlQuery } from "#shared/utils";

import { QUERY_KEYS } from "#organization/pages/consts";
import { projectContextState } from "#organization/recoil/project";

import { BillingNotDone } from "../payment-gateway/billing-not-done";

type ErrorResponse = {
  response?: { errors: { message: string }[] };
};

export type ProjectContextProviderProps = PropsWithChildren<{
  texts?: Texts<Text>;
}>;

type Text = "error";

const FirstProject = gql`
  query firstProject {
    projects(first: 1) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const useFirstProject = () => {
  const { data, isLoading, ...other } = useGqlQuery<FirstProjectQuery>(
    [QUERY_KEYS.FIRST_PROJECT, FirstProject],
    FirstProject,
    {},
  );

  const { edges: [{ node = null } = {}] = [] } = data?.projects || {};

  return {
    id: node?.id || "",
    name: node?.name || "",
    loading: isLoading,
    data,
    ...other,
  };
};

const enTexts: Required<ProjectContextProviderProps["texts"]> = {
  error: "Something went wrong when loading the project...",
};

export const ProjectContextProvider: FC<ProjectContextProviderProps> = ({
  children,
  texts = enTexts,
}) => {
  const [projectContext, setProjectContext] =
    useRecoilState(projectContextState);

  const defaultValues = useFirstProject();

  const [error, setError] = useState<string | null>(null);

  const [billingPending, setBillingPending] = React.useState<boolean>(false);

  useEffect(() => {
    if (defaultValues.error) {
      const { response: { errors: [{ message = null } = {}] = [] } = {} } =
        defaultValues.error as ErrorResponse;

      setBillingPending(
        (defaultValues.error as unknown as AxiosError)?.response?.status ===
          402,
      );
      setError(message || texts.error);

      return;
    }

    setError(null);
    setBillingPending(false);

    if (
      (projectContext.id === "" || projectContext.name === "") &&
      !defaultValues.loading
    ) {
      const id = localStorage.getItem("projectId") || defaultValues.id || "";
      const name =
        localStorage.getItem("projectName") || defaultValues.name || "";

      setProjectContext({ id, name });
    }
  }, [
    projectContext,
    defaultValues.loading,
    projectContext.name,
    setProjectContext,
    defaultValues.id,
    defaultValues.name,
    defaultValues.error,
    texts.error,
  ]);

  if (billingPending) {
    return <BillingNotDone />;
  }

  if (error) {
    /**
     * NOTE: rendered in logout -> sign in process
     * (on expired cookie)
     * Be careful with replacing the loader with any other component
     */
    return (
      <LoaderLayout
        outerContainerProps={{ sx: { height: "100vh", width: "100vw" } }}
        text={texts.error}
      />
    );
  }

  return projectContext.id !== "" && projectContext.name !== "" ? (
    <>{children}</>
  ) : null;
};
