import React, { type FC, type PropsWithChildren } from "react";

import {
  DataGrid,
  DataGridPagination,
  useDataGridOrder,
  useDataGridPagination,
  type HeadCell,
  type Order,
} from "#shared/components/data-grid";
import {
  ErrorLayout,
  ErrorText,
  LoaderLayout,
} from "#shared/components/layouts";
import type { JSONObject } from "#shared/types";

import type { GroupDataHook } from "#organization/hooks";

/* eslint-disable @typescript-eslint/no-explicit-any */
export type DataGridGroupProps<
  T = any,
  P extends string = any,
  /* eslint-enable @typescript-eslint/no-explicit-any */
> = PropsWithChildren<{
  groupByKey: P;
  groupByValue: string;
  useGroupData: GroupDataHook<P>;
  headCells: HeadCell<T>[];
  initialOrder: Order;
  initialOrderBy: string;
  filterVariables: JSONObject;
  modifyQueryData?: (data: T[]) => T[] | null;
  rowOnClick?: (dataItem: T) => null | (() => void);
}>;

export const DataGridGroup: FC<DataGridGroupProps> = ({
  groupByKey,
  groupByValue,
  useGroupData,
  headCells,
  initialOrder,
  initialOrderBy,
  filterVariables,
  modifyQueryData,
  rowOnClick,
}) => {
  const dataGridOrderController = useDataGridOrder(
    initialOrderBy,
    initialOrder,
  );
  const pagingControl = useDataGridPagination();
  const groupData = useGroupData(
    pagingControl.rowsPerPage,
    pagingControl.page,
    pagingControl.setPage,
    dataGridOrderController.order,
    dataGridOrderController.orderBy,
    groupByKey,
    groupByValue,
    filterVariables,
  );

  // @ts-ignore
  if (!groupData.query.data?.edges.length && groupData.query.isLoading) {
    return <LoaderLayout />;
  }

  if (groupData.query.error) {
    return (
      <ErrorLayout>
        {/* @ts-ignore */}
        <ErrorText>{groupData.query.error}</ErrorText>
      </ErrorLayout>
    );
  }

  const tableEdges = modifyQueryData
    ? // @ts-ignore
      modifyQueryData(groupData.query?.data?.edges)
    : // @ts-ignore
      groupData.query?.data?.edges;

  return (
    <>
      <DataGrid
        headers={headCells}
        // @ts-ignore
        data={tableEdges || []}
        rowOnClick={rowOnClick}
        orderController={dataGridOrderController}
      />

      <DataGridPagination
        page={pagingControl.page}
        rowsPerPage={pagingControl.rowsPerPage}
        handleChangePage={groupData.onPageChange}
        handleChangeRowsPerPage={pagingControl.handleChangeRowsPerPage}
        // @ts-ignore
        length={groupData.query?.data?.totalCount}
      />
    </>
  );
};
