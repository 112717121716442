import { Box, Select, type BoxProps } from "@mui/material";
import { styled } from "@mui/system";
import React, { type FC } from "react";

export const SectionWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const SectionBody = styled(Box)<{ gridTemplateColumns?: string }>(
  ({ theme, gridTemplateColumns }) => ({
    display: "grid",
    gridTemplateColumns: gridTemplateColumns || "20vw auto",
    columnGap: theme.spacing(1),
    rowGap: "30px",
    padding: "30px 8px 30px 8px",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr 1fr",
    },
  }),
);

export const SectionSelect = styled(Select)(({ theme }) => ({
  width: "20vw",
  color: theme.palette.text.primary,
  borderColor: theme.palette.background.inputField,
  backgroundColor: theme.palette.background.inputField,
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  "& .MuiSvgIcon-root": {
    color: theme.palette.text.primary,
  },
}));

export const SectionButtonWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export interface SettingsButtonsWrapperProps extends BoxProps {
  backButton?: boolean;
  search?: boolean;
}

const ButtonsWrapper: FC<SettingsButtonsWrapperProps> = (props) => {
  const { search, backButton, ...boxProps } = props;

  return <Box {...boxProps} />;
};

export const SettingsButtonsWrapper = styled(ButtonsWrapper)(({
  theme,
  backButton,
  search,
}) => {
  let gridTemplateColumns =
    "auto 1fr minmax(120px, 400px) auto auto auto auto auto";
  let gridTemplateAreas =
    '". page-title search reset-section reset-all save icon other"';
  let gridTemplateAreasMd = `"page-title" "reset-section" "reset-all" "save" "icon" "other"`;

  if (search) {
    gridTemplateAreasMd = `${'"search"'} ${gridTemplateAreasMd}`;
  }

  if (backButton) {
    gridTemplateColumns = "auto 1fr minmax(120px, 400px) auto auto auto auto";

    gridTemplateAreas =
      '"back-button . search reset-section reset-all save icon"';
    gridTemplateAreasMd = `${'"back-button"'}  ${gridTemplateAreasMd}`;
  }

  return {
    display: "grid",
    gridTemplateColumns,
    gridTemplateAreas,
    gridTemplateRows: "40px",
    padding: "40px 25px",
    borderBottom: `1px solid ${theme.palette.divider}`,
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      gridTemplateAreas: gridTemplateAreasMd,
      gridTemplateColumns: "1fr",
    },
  };
});
