import React, { type FC } from "react";

import type { Texts } from "#shared/types";

import { TermsAndConditionsLink } from "./styled";

type Text = "intro" | "toc" | "privacyPolicy" | "privacyLink";

export interface TermsAndConditionsProps {
  texts?: Texts<Text>;
}

const enTexts: TermsAndConditionsProps["texts"] = {
  intro:
    "By creating an account or signing in, you understand and agree to FluxNinja's",
  toc: "Terms and Conditions.",
  privacyPolicy: "You also acknowledge our",
  privacyLink: "Cookies and Privacy Policy.",
};

export const TermsAndConditions: FC<TermsAndConditionsProps> = ({
  texts = enTexts,
}) => (
  <>
    {texts.intro}{" "}
    <TermsAndConditionsLink
      href="https://www.fluxninja.com/terms-of-service"
      target="_blank"
    >
      {texts.toc}
    </TermsAndConditionsLink>{" "}
    {texts.privacyPolicy}{" "}
    <TermsAndConditionsLink
      href="https://www.fluxninja.com/privacy-policy"
      target="_blank"
    >
      {texts.privacyLink}
    </TermsAndConditionsLink>{" "}
  </>
);
