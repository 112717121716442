import { Add } from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { type FC, useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";

import { useAlert } from "#shared/components/alerts-provider";
import {
  useControlDialog,
  ConfirmDialog,
} from "#shared/components/layouts/dialog";
import { PermissionEnum, userNewState, type UserRole } from "#shared/recoil";
import type { Texts } from "#shared/types";

import { DataGrid } from "#organization/components/data-grid";

import { headCells } from "./components/table";
import { useRemoveMembersFromOrg } from "./hooks";
import { useActiveOwnerMembership, useMembersData } from "./hooks-new";
import { MEMBERS_SETTINGS_ROUTE } from "./routes.definitions";

import { QUERY_KEYS } from "../../../../consts";
import { InviteMembersDialog } from "../../components/invite-members-dialog";
import { SettingsButtons } from "../../components/settings-buttons";
import { SettingsSearch } from "../../components/settings-search";
import { SectionWrapper } from "../../components/styled";

const INITIAL_ORDER_BY = "name";
const INITIAL_ORDER = "asc";

export interface MembersPageProps {
  texts?: Texts<Text>;
}

type Text =
  | "addMembers"
  | "user"
  | "failedToRemove"
  | "removed"
  | "lastOrgOwnerWarning";

const enTexts: Required<MembersPageProps["texts"]> = {
  addMembers: "Invite users to Organization",
  user: "user",
  removed: "removed.",
  failedToRemove: "Failed to remove",
  lastOrgOwnerWarning:
    "Cannot leave the organization, please assign owner role to another member first.",
};

export const MembersPage: FC<MembersPageProps> = ({ texts = enTexts }) => {
  const { data: user } = useRecoilValue(userNewState.state);

  const [searchPhrase, setSearchPhrase] = useState("");

  const { addAlert } = useAlert();

  const userRole = useRecoilValue(userNewState.userRole)!;

  const inviteMembersDialog = useControlDialog();
  const confirmDeleteDialogControl = useControlDialog();
  const permissionLock =
    PermissionEnum[userRole] < PermissionEnum.user_group_admin;

  const queryClient = useQueryClient();

  const refetch = useCallback(
    () =>
      queryClient.invalidateQueries([
        QUERY_KEYS.DATA_GRID,
        QUERY_KEYS.SETTINGS_MEMBERS_QUERY_KEY,
      ]),
    [queryClient],
  );

  const { data: activeOwnerMembership } = useActiveOwnerMembership();

  const {
    organizationMemberships: { totalCount: totalOwnerCount } = {
      totalCount: 0,
    },
  } = activeOwnerMembership || {};

  const [disableLeaveAsOwner, setDisableLeaveAsOwner] = useState(false);
  const { removeMembership, selectedMember, setSelectedMember } =
    useRemoveMembersFromOrg();

  useEffect(() => {
    const isOpen = !!selectedMember && confirmDeleteDialogControl.isOpen;

    if (!isOpen) {
      setDisableLeaveAsOwner(false);

      return;
    }

    if (totalOwnerCount === 1 && selectedMember.role === "owner") {
      setDisableLeaveAsOwner(true);
    }
  }, [confirmDeleteDialogControl.isOpen, selectedMember, totalOwnerCount]);

  const removeMember = useCallback(async () => {
    if (!selectedMember || disableLeaveAsOwner) {
      return;
    }

    removeMembership(
      () => {
        addAlert({
          type: "success",
          message: [texts.user, selectedMember.name, texts.removed]
            .filter(Boolean)
            .join(" "),
        });

        confirmDeleteDialogControl.close();
        setSelectedMember(null);
        refetch();
      },
      () => {
        addAlert({
          type: "error",
          message: `${[texts.failedToRemove, selectedMember.name]
            .filter(Boolean)
            .join(" ")}.`,
        });
      },
    );
  }, [
    selectedMember,
    disableLeaveAsOwner,
    removeMembership,
    refetch,
    addAlert,
    texts.user,
    texts.removed,
    texts.failedToRemove,
    confirmDeleteDialogControl,
    setSelectedMember,
  ]);

  useEffect(() => {
    if (!confirmDeleteDialogControl.isOpen) {
      setSelectedMember(null);
    }
  }, [confirmDeleteDialogControl.isOpen, removeMember, setSelectedMember]);

  if (!user?.id) {
    return null;
  }

  const headers = headCells(
    user?.id,
    userRole as UserRole,
    permissionLock,
    (member) => {
      setSelectedMember(member);

      confirmDeleteDialogControl.open();
    },
  );

  return (
    <>
      <SettingsButtons noButtons title={MEMBERS_SETTINGS_ROUTE.TITLE} />
      <SectionWrapper>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          mb={2}
        >
          <SettingsSearch
            onSearch={(search: string) => setSearchPhrase(search)}
          />
          <>
            <Button
              onClick={inviteMembersDialog.open}
              variant="contained"
              size="small"
              disabled={permissionLock}
              startIcon={<Add />}
            >
              {texts.addMembers}
            </Button>

            <InviteMembersDialog dialogControl={inviteMembersDialog} />
          </>
        </Box>

        <DataGrid
          headCells={headers}
          useGridData={useMembersData}
          enabled
          initialOrderBy={INITIAL_ORDER_BY}
          initialOrder={INITIAL_ORDER}
          filterVariables={getFilterVariable(searchPhrase)}
        />
      </SectionWrapper>

      <ConfirmDialog
        isOpen={!!selectedMember && confirmDeleteDialogControl.isOpen}
        close={confirmDeleteDialogControl.close}
        onConfirm={() => {
          confirmDeleteDialogControl.close();
          removeMember();
        }}
        confirmButtonProps={{
          disabled: disableLeaveAsOwner,
        }}
        text={`remove member ${selectedMember?.name} from the organization?`}
        actions={
          disableLeaveAsOwner
            ? [
                {
                  id: "warning",
                  node: (
                    <Typography
                      display="flex"
                      alignItems="center"
                      sx={(theme) => ({
                        color: theme.palette.warning.main,
                        gap: 1,
                      })}
                    >
                      <ErrorOutlineIcon />
                      {texts.lastOrgOwnerWarning}
                    </Typography>
                  ),
                },
              ]
            : undefined
        }
      />
    </>
  );
};

function getFilterVariable(searchPhrase: string) {
  return {
    name: { ilike: `%${searchPhrase}%` },
    organizationMemberships: {},
  };
}
