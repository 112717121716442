import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import { omit } from "lodash";
import React, { useCallback, useEffect, type FC } from "react";
import { useForm } from "react-hook-form";

import { useAlert } from "#shared/components/alerts-provider";
import { TermsAndConditions } from "#shared/components/toc";
import type { Texts } from "#shared/types";

import { type RequestCustomPlanBody, useSendCustomPlanRequest } from "./hooks";
import { GridWrapper, TEXT_FIELD_PROPS } from "./styled";

type RequestPlanFormValues = Pick<
  RequestCustomPlanBody,
  "comment" | "customPlan"
> & { toc: boolean };

type Text =
  | "title"
  | "formUse"
  | "request"
  | "additional"
  | "submit"
  | "planRequired"
  | "requestPlaceholder"
  | "additionalPlaceholder"
  | "sendSuccessMessage"
  | "hasBeenSent"
  | "sendErrorMessage"
  | "tokens";

interface CustomPlanDialogProps {
  open: boolean;
  onClose: () => void;
  texts?: Texts<Text>;
}

const enTexts: Required<CustomPlanDialogProps["texts"]> = {
  title: "Custom Plan",
  formUse:
    "Please use this form so we can prepare a custom plan tailored to your specific needs.",
  request: "Request plan",
  requestPlaceholder: "E.g. 10000 tokens",
  planRequired: "Plan is required",
  additional: "Additional comments",
  additionalPlaceholder: "E.g. I have a very large infrastructure",
  submit: "Submit",
  sendSuccessMessage: "Request for",
  hasBeenSent: "has been sent.",
  sendErrorMessage: "Something went wrong. Please try again.",
  tokens: "tokens",
};

export const CustomPlanDialog: FC<CustomPlanDialogProps> = ({
  open,
  onClose,
  texts = enTexts,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    getValues,
  } = useForm<RequestPlanFormValues>({
    mode: "onSubmit",
  });

  const { addAlert } = useAlert();

  const {
    sendCustomPlanRequest,
    isPending,
    isSuccess,
    error,
    reset: resetRequest,
  } = useSendCustomPlanRequest();

  const onSubmit = useCallback(
    (data: RequestPlanFormValues) => {
      sendCustomPlanRequest(data);
    },
    [sendCustomPlanRequest],
  );

  useEffect(() => {
    if (open) {
      return;
    }

    reset();
    resetRequest();
  }, [open, resetRequest, reset]);

  useEffect(() => {
    if (!open) {
      return;
    }

    reset({
      customPlan: undefined,
    });
  }, [open, reset, texts.tokens]);

  useEffect(() => {
    if (!isSuccess || !open) {
      return;
    }

    addAlert({
      type: "success",
      message: [
        texts.sendSuccessMessage,
        getValues().customPlan,
        texts.hasBeenSent,
      ].join(" "),
    });

    onClose();
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSuccess,
    open,
    addAlert,
    texts.sendSuccessMessage,
    texts.hasBeenSent,
    getValues,
  ]);

  const isSubmitDisabled = isPending || !watch("toc");

  return (
    <Dialog
      onClose={() => {
        onClose();
        reset();
      }}
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle color="primary.main">{texts.title}</DialogTitle>
      <DialogContent sx={{ px: 3, py: 2 }}>
        <Typography
          variant="body2"
          pb={2}
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          {texts.formUse}
        </Typography>

        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <GridWrapper sx={{ rowGap: 3 }}>
            {[
              {
                autoComplete: "off",
                title: texts?.request,
                placeholder: texts.requestPlaceholder,
                size: "small",
                ...register("customPlan", {
                  required: texts.planRequired,
                }),
                error: !!errors.customPlan,
                helperText: errors.customPlan?.message as string,
              },
              {
                title: texts.additional,
                placeholder: texts.additionalPlaceholder,
                ...register("comment"),
                multiline: true,
                size: "small",
                rows: 4,
              },
            ].map((fieldProps, index) => (
              <React.Fragment key={[fieldProps.title, index].join("-")}>
                <Typography
                  fontSize={14}
                  variant="body2"
                  color="text.primary"
                  alignSelf="baseline"
                  fontWeight="600"
                >
                  {fieldProps.title}
                </Typography>
                {/* @ts-ignore (This is a very confusing implementation, needs cleanups) */}
                <TextField
                  {...omit(fieldProps, "title")}
                  {...TEXT_FIELD_PROPS}
                  InputLabelProps={{ shrink: false }}
                  variant="outlined"
                />
              </React.Fragment>
            ))}
          </GridWrapper>
          <FormControlLabel
            sx={{ pt: 3 }}
            control={
              <Checkbox
                {...register("toc", { required: true })}
                checked={watch("toc") || false}
              />
            }
            label={<TermsAndConditions />}
            componentsProps={{ typography: { variant: "body2" } }}
          />

          <Box display="flex" justifyContent="end" pt={3}>
            {error ? (
              <Typography
                color="warning.main"
                display="flex"
                alignItems="center"
                columnGap={0.5}
              >
                <ErrorOutlineIcon />
                {texts.sendErrorMessage}
              </Typography>
            ) : null}
          </Box>

          <Box display="flex" justifyContent="end" pt={3}>
            <Button
              variant="contained"
              size="small"
              type="submit"
              disabled={isSubmitDisabled}
              endIcon={isPending ? <CircularProgress size="1em" /> : null}
            >
              {texts.submit}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
