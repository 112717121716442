import { Checkbox, DialogContentText, FormControlLabel } from "@mui/material";
import { Box } from "@mui/system";
import React, { type ChangeEvent, type FC, useState } from "react";

import { DialogLayout } from "#shared/components/layouts/dialog";

import type { DialogLayoutProps } from "../../../../../../../shared/components/layouts/dialog";

interface ConfirmDialogProps {
  onConfirm: () => void;
  close: () => void;
  isOpen: boolean;
  localStorageKey: string;
}

export const ConfirmProjectChangeDialog: FC<ConfirmDialogProps> = ({
  close,
  isOpen,
  onConfirm,
  localStorageKey,
}) => {
  const [reminder, setReminder] = useState(false);

  const reminderChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReminder(event.target.checked);
  };

  const onConfirmConfirm: DialogLayoutProps["onConfimrDialog"] = () => {
    if (reminder) {
      localStorage.setItem(localStorageKey, "true");
    }
    onConfirm();
  };

  return (
    <DialogLayout
      dialogTitle="Confirmation"
      onCloseDialog={close}
      openDialog={isOpen}
      onClickDialogClose={close}
      onConfimrDialog={onConfirmConfirm}
      dialogContentText="Are you sure you want to switch the project?"
      buttonConfirmText="Switch project"
      buttonConfirmVariant="contained"
      buttonConfirmColor="primary"
      buttonCancelVariant="outlined"
      buttonCancelColor="primary"
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto",
          columnGap: 1,
        }}
      >
        <DialogContentText>
          <FormControlLabel
            control={<Checkbox onChange={reminderChange} />}
            label="Don't show this again"
          />
        </DialogContentText>
      </Box>
    </DialogLayout>
  );
};
