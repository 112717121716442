import { gql } from "graphql-request";

import type { FilterLabelsType } from "#shared/components/filtering";

export const flowControlFilters = (projectId: string): FilterLabelsType[] => [
  {
    id: 0,
    name: "Service name",
    key: "service.name",
    category: "controlPoints",
    uniqueKey: "serviceName",
    gql: gql`
      query FlowControlName($where: ControlPointBoolExp, $first: Int) {
        controlPoints(where: $where, first: $first, distinctOn: [serviceID]) {
          edges {
            node {
              service {
                name
              }
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      if (prop === "") {
        return {
          first: 5,
          where: {
            projectID: { eq: projectId },
          },
        };
      }

      return {
        first: 5,
        where: {
          projectID: { eq: projectId },
          service: {
            name: {
              like: `${prop}%`,
            },
          },
        },
      };
    },
  },
  {
    id: 1,
    name: "Agent group",
    key: "agentGroup",
    category: "services",
    uniqueKey: "agentGroup",
    filterPath: "service.agentGroup",
    variable: (prop = "") => {
      if (prop === "") {
        return {
          first: 5,
          where: {
            projectID: { eq: projectId },
            controlPoints: {
              id: { isNull: false },
            },
          },
        };
      }

      return {
        first: 5,
        where: {
          projectID: { eq: projectId },
          controlPoints: {
            id: { isNull: false },
          },
          agentGroup: {
            like: `${prop}%`,
          },
        },
      };
    },
    gql: gql`
      query FlowControlAgentGroup($where: ServiceBoolExp, $first: Int) {
        services(where: $where, first: $first, distinctOn: [agentGroup]) {
          edges {
            node {
              agentGroup
            }
          }
        }
      }
    `,
    selected: false,
  },
  {
    id: 2,
    name: "Controller",
    key: "controller.name",
    category: "services",
    uniqueKey: "controllerName",
    filterPath: "service.controller.name",
    variable: (prop = "") => {
      if (prop === "") {
        return {
          first: 5,
          where: {
            projectID: { eq: projectId },
            controlPoints: {
              id: { isNull: false },
            },
          },
        };
      }

      return {
        first: 5,
        where: {
          projectID: { eq: projectId },
          controller: {
            name: {
              like: `${prop}%`,
            },
          },
          controlPoints: {
            id: { isNull: false },
          },
        },
      };
    },
    gql: gql`
      query FlowControlControllerName($where: ServiceBoolExp, $first: Int) {
        services(where: $where, first: $first, distinctOn: [controllerID]) {
          edges {
            node {
              controller {
                name
              }
            }
          }
        }
      }
    `,
    selected: false,
  },
  {
    id: 3,
    name: "Control points",
    key: "name",
    category: "controlPoints",
    uniqueKey: "controlPointsName",
    variable: (prop = "") => {
      if (prop === "") {
        return {
          first: 5,
          where: {
            projectID: { eq: projectId },
          },
        };
      }

      return {
        first: 5,
        where: {
          projectID: { eq: projectId },
          name: {
            like: `${prop}%`,
          },
        },
      };
    },
    gql: gql`
      query FlowControlPointsControlPointName(
        $where: ControlPointBoolExp
        $first: Int
      ) {
        controlPoints(where: $where, first: $first, distinctOn: [name]) {
          edges {
            node {
              name
            }
          }
        }
      }
    `,
    selected: false,
  },
];
