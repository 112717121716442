import type { BreadcrumbsProps } from "@mui/material";
import React, { type FC } from "react";
import { useRecoilValue } from "recoil";

import { BreadcrumbsContainer } from "#shared/components/breadcrumbs";

import { breadcrumbsInFlyoutMenuAtom } from "../../recoil/breadcrumbs/flyout-menu/atom";

export type FlyoutMenuBreadcrumbsBarProps = Omit<BreadcrumbsProps, "children">;

export const FlyoutMenuBreadcrumbsBar: FC<FlyoutMenuBreadcrumbsBarProps> = (
  props,
) => {
  const breadcrumbs = useRecoilValue(breadcrumbsInFlyoutMenuAtom);

  return <BreadcrumbsContainer {...props}>{breadcrumbs}</BreadcrumbsContainer>;
};
