import { Breadcrumbs, type BreadcrumbsProps } from "@mui/material";
import React, { type FC, useContext } from "react";

import { SelectedResourcesContext } from "#organization/pages/authenticated/policies/policy";

import { CircuitNavigatorStyled } from "./styled";

import {
  type CircuitBreadcrumb,
  useCircuitContext,
} from "../../../circuit-context";

export const CircuitBreadcrumbs: FC<BreadcrumbsProps> = (props) => {
  const { circuitBreadcrumb } = useCircuitContext();

  if (!circuitBreadcrumb.length) return null;

  return (
    <Breadcrumbs {...props}>
      {circuitBreadcrumb.map((breadcrumb, index) => (
        <CircuitNavigator
          key={[breadcrumb, index].join("-")}
          circuitBreadcrumb={breadcrumb}
          last={index === circuitBreadcrumb.length - 1}
          breadcrumbs={circuitBreadcrumb}
          index={index}
        />
      ))}
    </Breadcrumbs>
  );
};

export interface CircuitNavigatorProps {
  circuitBreadcrumb: CircuitBreadcrumb;
  last: boolean;
  breadcrumbs: CircuitBreadcrumb[];
  index: number;
}

export const CircuitNavigator: FC<CircuitNavigatorProps> = ({
  circuitBreadcrumb,
  last,
  breadcrumbs,
  index,
}) => {
  const { circuitDispatch, setIsFlyoutMenuVisible } = useCircuitContext();
  const { resetSelectedResources } = useContext(SelectedResourcesContext);

  const onClick = () => {
    if (last || !circuitDispatch) return;

    setIsFlyoutMenuVisible(false);
    resetSelectedResources();

    circuitDispatch({
      type: "setNestedCircuitGqlString",
      payload: index === 0 ? "" : circuitBreadcrumb.id,
    });

    if (breadcrumbs.length - 1 !== index && breadcrumbs.length > 1) {
      circuitDispatch({
        type: "setCircuitBreadcrumb",
        payload: breadcrumbs.slice(0, index + 1),
      });
    }
  };

  return (
    <CircuitNavigatorStyled last={last} onClick={onClick}>
      {circuitBreadcrumb.name}
    </CircuitNavigatorStyled>
  );
};
