import { MenuItem } from "@mui/material";
import React, { type ChangeEvent, useEffect, useState, type FC } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { useAlert } from "#shared/components/alerts-provider";
import { Input } from "#shared/components/inputs/input";
import {
  getUserRoleName,
  USER_ROLE_DICTIONARY,
  PermissionEnum,
  type UserRole,
  userNewState,
} from "#shared/recoil";
import { LoggerService } from "#shared/services/logger";
import type { Texts } from "#shared/types";

import { useOrganizationState } from "#organization/recoil/organization";

import {
  useChangeOrganizationDefaultRole,
  useChangeOrganizationName,
  useOrganizationDefaultRole,
} from "./hooks";

import { organizationState } from "../../../../../recoil/organization";
import { SectionHeader } from "../../components/section-header";
import { SettingsButtons } from "../../components/settings-buttons";
import { SettingsSectionLabel } from "../../components/settings-section-label";
import {
  SectionBody,
  SectionSelect,
  SectionWrapper,
} from "../../components/styled";

type Text =
  | "nameChangeSuccess"
  | "nameChangeError"
  | "roleChangeSuccess"
  | "roleChangeError"
  | "orgName"
  | "orgNameDescription"
  | "defaultRole"
  | "defaultRoleDescription"
  | "save"
  | "orgDomain"
  | "orgDomainDescription"
  | "membership";

interface GeneralSettingsPageProps {
  texts?: Texts<Text>;
}

const enText: Required<GeneralSettingsPageProps["texts"]> = {
  nameChangeSuccess: "Successfully changed organization name.",
  nameChangeError: "Could not change organization name.",
  roleChangeSuccess: "Successfully changed default user role.",
  roleChangeError: "Could not change default user role.",
  orgName: "Organization name",
  orgNameDescription:
    "A simplified version or personal identifier for organization.",
  defaultRole: "Default user role",
  defaultRoleDescription: "The default role new members will receive.",
  save: "Save",
  orgDomain: "Organization domain name",
  orgDomainDescription: "Domain name.",
  membership: "Membership",
};

export const GeneralSettingsPage: FC<GeneralSettingsPageProps> = ({
  texts = enText,
}) => {
  const organizationId = useOrganizationState();

  const userRole = useRecoilValue(userNewState.userRole)!;

  const permissionLock =
    PermissionEnum[userRole] <= PermissionEnum.user_group_admin;
  const defaultRole = useOrganizationDefaultRole(organizationId.id);
  const [, setOrgState] = useRecoilState(organizationState);
  const { addAlert } = useAlert();
  const changeRole = useChangeOrganizationDefaultRole();
  const organizationNameController = useChangeOrganizationName();
  const [organizationName, setOrganizationName] = useState<string>(
    organizationId.name,
  );
  const [role, setRole] = useState<string | undefined | unknown>("");
  const [disabled, setDisabled] = useState<boolean>(permissionLock || false);

  const handleOrganizationName = (event: ChangeEvent<HTMLInputElement>) => {
    setOrganizationName(event.currentTarget.value);
  };

  useEffect(() => {
    if (!defaultRole.loading) {
      setRole(defaultRole.data);
    }
  }, [defaultRole.loading, defaultRole.data]);

  const onSave = () => {
    if (role !== defaultRole.data) {
      setDisabled(true);

      changeRole.change(
        { id: organizationId.id, defaultRole: role },
        {
          onSuccess: () => {
            setDisabled(false);
            defaultRole.refetch();

            addAlert({
              type: "success",
              message: texts.roleChangeSuccess,
            });
          },
          onError: (error) => {
            setDisabled(false);
            LoggerService.error(error);
            addAlert({ type: "error", message: texts.roleChangeError });
          },
        },
      );
    }

    if (organizationId.name !== organizationName) {
      setDisabled(true);

      organizationNameController.change(
        { id: organizationId.id, name: organizationName },
        {
          onSuccess: () => {
            setDisabled(false);

            setOrgState({
              id: organizationId.id,
              subdomain: organizationId.subdomain,
              name: organizationName,
              onboarded: organizationId.onboarded,
              idpTenantID: organizationId.idpTenantID,
            });

            addAlert({
              type: "success",
              message: texts.nameChangeSuccess,
            });
          },
          onError: (error) => {
            setDisabled(false);
            LoggerService.error(error);

            addAlert({
              type: "error",
              message: texts.nameChangeError,
            });
          },
        },
      );
    }
  };

  const onReset = () => {
    setOrganizationName(organizationId.name);
    setRole(defaultRole.data);
  };

  return (
    <>
      <SettingsButtons
        onSave={onSave}
        onResetAll={onReset}
        disabled={disabled}
        title="General Settings"
      />

      <SectionWrapper>
        <SectionHeader title={texts.orgName} />
        <SectionBody>
          <SettingsSectionLabel
            primaryText={texts.orgName}
            secondaryText={texts.orgNameDescription}
            asterisk
          />
          <Input
            size="small"
            value={organizationName}
            onChange={handleOrganizationName}
          />

          <SettingsSectionLabel
            primaryText={texts.orgDomain}
            secondaryText={texts.orgDomainDescription}
            asterisk
          />
          <Input size="small" value={organizationId.subdomain} disabled />
        </SectionBody>

        <SectionHeader title={texts.membership} />
        <SectionBody>
          <SettingsSectionLabel
            primaryText={texts.defaultRole}
            secondaryText={texts.defaultRoleDescription}
            asterisk
          />
          <SectionSelect
            size="small"
            disabled={permissionLock}
            onChange={(e) => {
              setRole(e.target.value);
              setDisabled(false);
            }}
            value={role}
          >
            {Object.keys(USER_ROLE_DICTIONARY).map((usersRole) => (
              <MenuItem key={usersRole} value={usersRole}>
                {getUserRoleName(usersRole as UserRole)}
              </MenuItem>
            ))}
          </SectionSelect>
        </SectionBody>
      </SectionWrapper>
    </>
  );
};
