import { Box, styled } from "@mui/material";
import { Link } from "react-router-dom";

export const ChannelsLink = styled(Link)({
  textDecoration: "none",
  fontSize: 14,
  fontWeight: 400,
});

export const ChannelsWrapper = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  gap: 1,
  alignItems: "center",
});
