import { styled, Typography } from "@mui/material";

export interface CircuitNavigatorStyledProps {
  last?: boolean;
}

export const CircuitNavigatorStyled = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "last",
})<CircuitNavigatorStyledProps>(({ theme, last }) => ({
  ...(!last
    ? {
        color: "inherit",
        cursor: "pointer",
        "& :hover": {
          textDecoration: "underline",
        },
      }
    : {
        color: theme.palette.text.primary,
        cursor: "default",
        "& :hover": {
          textDecoration: "none",
        },
      }),
}));
