# Num Sync

## Overview

The `num_lazy_sync` defines the frequency of lazy synchronization occurrences within a given interval, adjusting the balance between performance and accuracy. 0 to 4 lazy syncs are allowed within an interval.

## Example

Setting `num_lazy_sync` to 2 within an interval of 1 minute means the rate limiter will attempt to synchronize the token bucket state lazily 2 times per minute.

## Note

Configuring `num_lazy_sync` is essential to optimize the balance between rate limiting accuracy and system performance. This setting determines how frequently the lazy synchronization occurs within a given interval. A higher number indicates more frequent updates, offering greater accuracy at the cost of increased system load. Choose a value that aligns with your application's traffic patterns and performance requirements.
