import { Box, Typography } from "@mui/material";
import { pick, uniqBy } from "lodash";
import React, { type FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { type HeadCell } from "#shared/components/data-grid";
import {
  FilteringGroup,
  useChipsContainer,
} from "#shared/components/filtering/filtering-group";
import { useParseLinkAttributesToFilterVariables } from "#shared/components/filtering/utils";
import type {
  ControlPointAssignmentsQuery,
  ControlPointComponentAssignmentSelectColumn,
} from "#shared/generated/graphql";
import type { AnyObject } from "#shared/types";

import {
  DataGrid,
  FiltersBarWrapper,
  type DataGridProps,
  GroupByButtonWrapper,
  GroupBySelect,
  DataGridGroups,
  type GroupByOption,
} from "#organization/components/data-grid";

import { flowControlComponentsFilters } from "./filter";
import {
  modifyControlPointAssignmentsData,
  useControlPointAssignmentsGridData,
  type ControlPointAssignmentsTableData,
} from "./hooks";

const INITIAL_ORDER_BY: string = "";
const INITIAL_ORDER: "asc" | "desc" = "desc";
const TABLE_UNIQUE_ID = "flow-control-component-table";

export type FlowControlComponentsGroupBy = "policyName";

const GROUP_BYS: GroupByOption<ControlPointComponentAssignmentSelectColumn>[] =
  [
    {
      title: "No value",
      value: "",
    },
  ];

export interface FlowControlComponentsProps {
  service: {
    name: string;
    id: string;
    controlPoint: string;
    controlPointID: string;
  };
}

function modifyFilterQuery(data: ControlPointAssignmentsQuery) {
  if (!data?.controlPoints?.edges?.length) {
    return data;
  }

  const { controlPoints } = data;

  const tableAdaptableData: ControlPointAssignmentsTableData[] =
    controlPoints.edges
      .map(({ node }) => node)
      .map(modifyControlPointAssignmentsData)
      .flat();

  const response = {
    ...data,
    controlPoints: {
      ...controlPoints,
      edges: uniqBy(
        tableAdaptableData.map((d) => ({ node: d })),
        ({ node }) => node.policyID,
      ),
    },
  };

  return response;
}

export const FlowControlComponents: FC<FlowControlComponentsProps> = ({
  service,
}) => {
  const [filterVariables, setFilterVariables] = useState(
    useParseLinkAttributesToFilterVariables(TABLE_UNIQUE_ID),
  );

  const [groupByKey, setGroupByKey] =
    useState<(typeof GROUP_BYS)[number]["value"]>("");

  const chipsContainer = useChipsContainer();
  const { useDataGridHook, useGroupData, useGroupsTitles } =
    useControlPointAssignmentsGridData(service?.controlPointID);

  const modifyDataFunc: DataGridProps["modifyDataFunc"] = (data) =>
    data?.flat();

  const dataGridProps: DataGridProps<ControlPointAssignmentsTableData> = {
    headCells: headCell,
    useGridData: useDataGridHook,
    initialOrder: INITIAL_ORDER,
    initialOrderBy: INITIAL_ORDER_BY,
    enabled: !groupByKey,
    filterVariables,
    modifyDataFunc,
  };

  return (
    <>
      <Typography p={1}>
        Control point{" "}
        <Typography component="span" color="primary">
          {[service.controlPoint, service.name].join("@")}{" "}
        </Typography>
        is used in the following policies
      </Typography>
      <FiltersBarWrapper>
        <FilteringGroup
          labels={flowControlComponentsFilters(service?.controlPointID)}
          onFilter={(finishFilter) => {
            const modifiedFilters = (
              finishFilter as { and: AnyObject[] }
            ).and.map((filter) => {
              const { or: orOperator } = filter;

              const variable = orOperator.map((orFilter: AnyObject) => {
                if (Object.hasOwn(orFilter, "policyName")) {
                  return {
                    project: {
                      components: {
                        policy: { name: { ...orFilter.policyName } },
                      },
                      classifiers: {
                        policy: { name: { ...orFilter.policyName } },
                      },
                      fluxMeters: {
                        policy: { name: { ...orFilter.policyName } },
                      },
                    },
                  };
                }

                return filter;
              });

              return { or: variable };
            });

            setFilterVariables({ and: modifiedFilters });
          }}
          chipsContainer={chipsContainer}
          uniqueId={TABLE_UNIQUE_ID}
          modifyFilterQueryResponseData={modifyFilterQuery}
        />
        <GroupByButtonWrapper>
          <GroupBySelect
            options={GROUP_BYS}
            setGroupByKey={setGroupByKey}
            groupByKey={groupByKey}
          />
        </GroupByButtonWrapper>
      </FiltersBarWrapper>
      <Box pt={3}>
        {groupByKey ? (
          <DataGridGroups
            headCells={headCell}
            useGroupsTitles={useGroupsTitles}
            useGroupData={useGroupData}
            groupByKey={groupByKey}
            filterVariables={filterVariables}
            initialOrderBy={INITIAL_ORDER_BY}
            initialOrder={INITIAL_ORDER}
            modifyQueryData={(data) => data.flat()}
          />
        ) : (
          <DataGrid {...dataGridProps} />
        )}
      </Box>
    </>
  );
};

const headCell: HeadCell<ControlPointAssignmentsTableData>[] = [
  {
    accessor: "componentType",
    label: "Component type",
    key: "componentType",
    noSorting: true,
  },
  {
    accessor: "componentName",
    label: "Component name",
    key: "componentName",
    noSorting: true,
  },
  {
    accessor: (row) => <PolicyLink {...pick(row, "policyName", "policyID")} />,
    label: "Policy name",
    key: "policyName",
  },
];

export interface PolicyLinkProps
  extends Pick<ControlPointAssignmentsTableData, "policyID" | "policyName"> {}

export const PolicyLink: FC<PolicyLinkProps> = ({ policyID, policyName }) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/policies/policy/${policyID}/circuit`);
  };

  return (
    <Typography
      variant="body2"
      fontWeight="bold"
      onClick={onClick}
      sx={{
        cursor: "pointer",
        "&:hover": { color: (theme) => theme.palette.primary.main },
      }}
    >
      {policyName}
    </Typography>
  );
};
