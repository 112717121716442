import { GlobalStyles, type GlobalStylesProps, useTheme } from "@mui/material";
import React, { type FC } from "react";

export const GLOBAL_CLASSNAMES = {
  hiddenScrollbar: "fn-scrollbar--hidden",
  narrowScrollbar: "fn-scrollbar--narrow",
  noDataLayout: "fn-nodata--layout",
};

const NARROW_SCROLLBAR_WIDTH = "8px";

export const AppGlobalStyles: FC = () => {
  const theme = useTheme();
  const scrollThumbColor =
    theme.palette.mode === "dark"
      ? theme.palette.grey[800]
      : theme.palette.grey[400];
  const scrollTrackColor =
    theme.palette.mode === "dark"
      ? theme.palette.grey[500]
      : theme.palette.grey[50];

  const styles: GlobalStylesProps["styles"] = {
    "#grafana-portal": {
      marginLeft: "-16px",
      marginRight: "-16px",
    },
    [`.${GLOBAL_CLASSNAMES.hiddenScrollbar}`]: {
      "&::-webkit-scrollbar": {
        display: "none",
      },
      scrollbarWidth: "none" /* Firefox */,
    },
    [`.${GLOBAL_CLASSNAMES.narrowScrollbar}`]: {
      "::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "darkgrey",
        outline: "1px solid slategrey",
      },
      "*::-webkit-scrollbar": {
        width: NARROW_SCROLLBAR_WIDTH,
        height: NARROW_SCROLLBAR_WIDTH,
      },
    },

    "*::-webkit-scrollbar": {
      width: "13px",
      padding: 3,
    },
    "*::-webkit-scrollbar-track": {
      backgroundColor: scrollTrackColor,
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: scrollThumbColor,
      border: `3px solid ${scrollTrackColor}`,
      backgroundClip: "padding-box",
      borderRadius: "10px",
      width: "7px",
    },
    a: {
      color: "inherit",
      "&:hover": {
        // textDecoration: "underline",
      },
    },
  };

  return <GlobalStyles {...{ styles }} />;
};
