import {
  HelpOutline,
  CreditCard,
  InfoOutlined,
  OpenInNew,
} from "@mui/icons-material";
import { Button, CircularProgress, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { type FC, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { ChargebeePlans } from "#shared/billing";
import { useAlert } from "#shared/components/alerts-provider";
import { useControlDialog } from "#shared/components/layouts/dialog/dialog";
import { LoggerService } from "#shared/services";
import type { AnyObject, Texts } from "#shared/types";
import { FeatureFlag, getOrganizationAbsoluteUrl } from "#shared/utils";
import { httpClient } from "#shared/utils/http-client";

import { API_URLS } from "#global/consts";
import { useOrganizationById } from "#global/pages/authenticated/organizations/use-organization-by-id";

import { CustomPlanDialog } from "./custom-plan-dialog";
import { PlanChooser } from "./plan-chooser";

export type CheckoutHostPageBody = {
  organization_id: string;
  project_id: string;
  plan_id: string;
  redirect_url?: string;
  user?: { email: string };
};

export type ChargeBeeHostedPage = {
  id: string;
  type: string;
  url: string;
  state: string;
  embed: boolean;
  created_at: number;
  expires_at: number;
  object: string;
  updated_at: number;
  resource_version: number;
};

export type SubscriptionResult = {
  subscription_id: string;
};

type Text =
  | "learnMore"
  | "personalized"
  | "selectAPlan"
  | "proceedToCheckout"
  | "creditCard";

interface BillingCheckoutProps {
  texts?: Texts<Text>;
}

const enTexts: Required<BillingCheckoutProps["texts"]> = {
  learnMore: "Learn more about plans and the pricing model",
  personalized:
    "Looking for a personalized plan that fits like a glove into your infrastructure?",
  selectAPlan: "You need to select a plan to proceed to checkout",
  proceedToCheckout: "Proceed to checkout",
  creditCard: "No credit card required for trial subscriptions",
};

export const BillingCheckout: FC<BillingCheckoutProps> = ({
  texts = enTexts,
}) => {
  const [selectedPlan, setSelectedPlan] = useState<
    (typeof ChargebeePlans)[number]["planID"] | null
  >(ChargebeePlans.length === 1 ? ChargebeePlans[0].planID : null);

  const customPlanDialog = useControlDialog();

  const { id: orgID } = useParams<{
    id: string;
  }>();

  const { organization } = useOrganizationById(orgID || "");

  const { addAlert } = useAlert();

  const [processingBilling, setProcessingBilling] =
    React.useState<boolean>(false);

  const startCheckout = async () => {
    if (!organization?.hasura_id || !selectedPlan) {
      addAlert({
        message: "Something went wrong. Please try again later",
        type: "error",
      });

      LoggerService.error(
        "Checkout failed, missing organization hasura_id or selected plan",
        organization,
        selectedPlan,
      );

      return;
    }

    const body: CheckoutHostPageBody = {
      organization_id: organization.hasura_id,
      project_id: "", // IaB will fetch the first project ID from api-service
      plan_id: selectedPlan,
    };

    setProcessingBilling(true);

    try {
      const response = await httpClient.post<
        Response,
        true,
        CheckoutHostPageBody
      >({
        url: API_URLS.BILLING.CREATE_SUBSCRIPTION,
        body,
      });

      const result = (await response.json()) as SubscriptionResult;

      if (!response.ok || !result?.subscription_id) {
        throw new Error(
          (response as AnyObject).errors ||
            response.statusText ||
            "checkout failed",
        );
      }

      window.location.href = getOrganizationAbsoluteUrl(
        organization.domain_name,
      );
    } catch (e) {
      LoggerService.error("Checkout failed", e);

      addAlert({
        message: "Failed to proceed to checkout. Please try again later",
        type: "error",
      });
    }

    setProcessingBilling(false);
  };

  return (
    <Box>
      <PlanChooser
        plans={ChargebeePlans}
        selectedPlan={selectedPlan}
        onSelectPlan={(planID) => {
          setSelectedPlan(planID);
        }}
      />

      <Link to="https://www.fluxninja.com/pricing" target="_blank">
        <Box display="flex" gap={1} alignItems="center" mt={3.5}>
          <OpenInNew
            sx={{ color: "primary.main", fontSize: "18px", marginTop: "2px" }}
          />
          <Typography
            color="primary.main"
            variant="body2"
            sx={{ textDecoration: "underline", cursor: "pointer" }}
          >
            {texts.learnMore}
          </Typography>
        </Box>
      </Link>

      <FeatureFlag
        flag="customPersonalizedPlan"
        renderOn={
          <>
            <Box display="flex" gap={1} alignItems="center" mt={2}>
              <InfoOutlined
                sx={{
                  color: "primary.main",
                  fontSize: "18px",
                  marginTop: "2px",
                }}
              />
              <Typography
                onClick={() => {
                  setSelectedPlan(null);
                  customPlanDialog.open();
                }}
                color="primary.main"
                variant="body2"
                sx={{ textDecoration: "underline", cursor: "pointer" }}
              >
                {texts.personalized}
              </Typography>
            </Box>
            <CustomPlanDialog
              open={customPlanDialog.isOpen}
              onClose={customPlanDialog.close}
            />
          </>
        }
      />

      <Tooltip title={!selectedPlan ? texts.selectAPlan : undefined} arrow>
        <Box>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={!selectedPlan || processingBilling}
            onClick={startCheckout}
            endIcon={
              processingBilling ? (
                <CircularProgress size="1em" />
              ) : (
                <CreditCard />
              )
            }
            sx={{ marginTop: 3 }}
          >
            {texts.proceedToCheckout}
          </Button>
        </Box>
      </Tooltip>

      <Box
        pt={1}
        display="flex"
        gap={0.5}
        alignItems="top"
        justifyContent="center"
      >
        <HelpOutline
          sx={{
            color: "text.secondary",
            fontSize: "14px",
            marginTop: 0.35,
          }}
        />
        <Typography variant="body2" color="text.secondary">
          {texts.creditCard}
        </Typography>
      </Box>
    </Box>
  );
};
