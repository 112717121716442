import {
  Box,
  MenuItem,
  Select,
  styled,
  type SelectChangeEvent,
  Typography,
  type Theme,
  type SelectProps,
  InputLabel,
  FormControl,
} from "@mui/material";
import React, { useState, type FC } from "react";

import { FieldWithTitle, type FieldWithTitleProps } from "../styled";

export interface TimeRangePickerFieldProps {
  error?: boolean;
  helperText?: string;
  value?: string;
  fieldLabelProps: FieldWithTitleProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void;
}

export declare type TimePickerValue = {
  hours: string;
  minutes: string;
  seconds: string;
};

export const TimeRangePickerField: FC<TimeRangePickerFieldProps> = ({
  fieldLabelProps,
  error,
  onChange,
  value,
  helperText,
}) => {
  const [time, setTime] = useState<TimePickerValue>(
    secondsToHMS(Number(value?.toString()?.replace("s", ""))),
  );

  const handleHourChange = (e: SelectChangeEvent<string>) => {
    setTime({ ...time, hours: e.target.value });
    onChangeInterval(onChange)({ ...time, hours: e.target.value });
  };

  const handleMinuteChange = (e: SelectChangeEvent<string>) => {
    setTime({ ...time, minutes: e.target.value });
    onChangeInterval(onChange)({ ...time, minutes: e.target.value });
  };

  const handleSecondChange = (e: SelectChangeEvent<string>) => {
    setTime({ ...time, seconds: e.target.value });
    onChangeInterval(onChange)({ ...time, seconds: e.target.value });
  };

  return (
    <FieldWithTitle {...fieldLabelProps}>
      <FieldWrapperStyled>
        <FormControl>
          <InputLabel id="hh-label">hh</InputLabel>
          <Select
            labelId="hh-label"
            id="hh-select"
            label="hh"
            value={time.hours}
            onChange={handleHourChange}
            sx={pickerSelectFieldSx}
            error={error}
            MenuProps={menuProps}
          >
            {createMenuItems(300)}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="mm-label">mm</InputLabel>
          <Select
            labelId="mm-label"
            id="mm-select"
            label="mm"
            value={time.minutes}
            onChange={handleMinuteChange}
            sx={pickerSelectFieldSx}
            error={error}
            MenuProps={menuProps}
          >
            {createMenuItems(59)}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="ss-label">ss</InputLabel>
          <Select
            labelId="ss-label"
            id="ss-select"
            label="ss"
            value={time.seconds}
            onChange={handleSecondChange}
            sx={pickerSelectFieldSx}
            error={error}
            MenuProps={menuProps}
          >
            {createMenuItems(59)}
          </Select>
        </FormControl>
      </FieldWrapperStyled>
      {helperText && (
        <Typography
          variant="body2"
          color={error ? "error" : "textSecondary"}
          fontSize="0.75rem"
        >
          {helperText}
        </Typography>
      )}
    </FieldWithTitle>
  );
};

export const FieldWrapperStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  fontSize: 15,
  fontWeight: 500,
}));

export const menuProps: SelectProps["MenuProps"] = {
  sx: {
    maxHeight: 300,
  },
};

export const pickerSelectFieldSx = (theme: Theme) => ({
  width: 75,
  "& .MuiSelect-icon": {
    color: theme.palette.text.primary,
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function onChangeInterval(onChange: (...event: any[]) => void) {
  return (v: TimePickerValue) => {
    const { hours, minutes, seconds } = v;

    const totalSeconds =
      (Number(hours) || 0) * 3600 +
      (Number(minutes) || 0) * 60 +
      (Number(seconds) || 0);

    onChange(`${totalSeconds}s`);
  };
}

export function secondsToHMS(seconds: number): TimePickerValue {
  if (Number.isNaN(seconds))
    return { hours: "00", minutes: "00", seconds: "00" };

  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  return {
    hours: `${h}`.length === 1 ? `0${h}` : `${h}`,
    minutes: `${m}`.length === 1 ? `0${m}` : `${m}`,
    seconds: `${s}`.length === 1 ? `0${s}` : `${s}`,
  };
}

export const createMenuItems = (range: number) =>
  Array.from({ length: range + 1 }, (_, index) => index).map((v, i) => (
    <MenuItem
      key={[v, i].join(".")}
      value={`${v}`.length === 1 ? `0${v}` : `${v}`}
    >
      {`${v}`.length === 1 ? `0${v}` : `${v}`}
    </MenuItem>
  ));
