import Button, { type ButtonProps } from "@mui/material/Button";
import React, { type ElementType } from "react";

export type SecondaryButtonProps<C extends ElementType = "button"> = Omit<
  ButtonProps<C, { component?: C }>,
  "color" | "variant"
>;

export const SecondaryButton = <C extends ElementType = "button">(
  // NOTE: hack to make the `component` prop work with `Omit`
  // See https://material-ui.com/guides/typescript/#usage-of-component-prop
  props: { component?: C } & SecondaryButtonProps<C>,
) => <Button color="primary" variant="outlined" {...props} />;
