import { type Dispatch, type SetStateAction } from "react";

import { type DataGridProps } from "#shared/components/data-grid";

import type { AdaptedFlowControlGroupData } from "../hooks";
import type { FlowControlFlyoutConfig } from "../types";

export const rowClick: (
  setIsFlyoutMenuVisible: Dispatch<SetStateAction<boolean>>,
  setSelectedService: Dispatch<SetStateAction<FlowControlFlyoutConfig>>,
) => DataGridProps<AdaptedFlowControlGroupData>["rowOnClick"] =
  (setIsFlyoutMenuVisible, setSelectedService) => (row) => () => {
    setSelectedService({
      serviceName: row.service?.name || "",
      serviceId: row.service?.id || "",
      agentGroup: row.service?.agentGroup || "",
      controllerId: row.service?.controller.name || "",
      controlPoint: row.name,
      status: row.status,
      controlPointId: row.id,
      controlPointType: row.type,
    });

    setIsFlyoutMenuVisible(true);
  };
