import { Box, styled } from "@mui/material";

import type { FnTheme } from "../../../../app-theme-provider/types";

export const SectionContainer = styled(Box)(({ theme }) => {
  const { spacing, shape, palette } = theme as unknown as FnTheme;

  return {
    backgroundColor: (palette as Required<FnTheme["palette"]>).background.paper,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: spacing(shape.pageBarYM),
    width: "100%",
  };
});
