import { gql } from "graphql-request";
import { useParams } from "react-router-dom";

import type {
  GetUserByIdQuery,
  GetUserGroupsQuery,
} from "#shared/generated/graphql";
import { useGqlQuery } from "#shared/utils";

import type { MemberIdParam } from "../routes.definitions";

const QUERY_KEY_USER_GROUP = "userGroupById";
const QUERY_KEY_GET_USER_BY_ID = "userByIdQuery";
const UserUserGroupById = gql`
  query userUserGroupById($where: UserGroupBoolExp) {
    userGroups(where: $where) {
      edges {
        node {
          id
          name
          userGroupMemberships {
            totalCount
          }
        }
      }
    }
  }
`;

export const useUserGroupsQuery = () => {
  const { memberId } = useParams<MemberIdParam>() as MemberIdParam;
  const options = {
    where: {
      userGroupMemberships: { user: { id: { eq: memberId } } },
    },
  };
  const { isLoading, isError, data, refetch } = useGqlQuery<GetUserGroupsQuery>(
    [QUERY_KEY_USER_GROUP, options],
    UserUserGroupById,
    options,
  );

  return {
    loading: isLoading,
    error: isError,
    data: data?.userGroups.edges,
    refetch,
  };
};

const UserByIdQuery = gql`
  query getUserById($where: UserBoolExp!) {
    users(where: $where) {
      edges {
        node {
          id
          email
          name
          organizationMemberships {
            edges {
              node {
                id
                start
                role
                status
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetUserById = () => {
  const { memberId } = useParams<MemberIdParam>() as MemberIdParam;
  const options = { where: { id: { eq: memberId } } };
  const { error, isLoading, refetch, data } = useGqlQuery<
    unknown,
    Error,
    GetUserByIdQuery
  >([QUERY_KEY_GET_USER_BY_ID, options], UserByIdQuery, options);

  return {
    error,
    loading: isLoading,
    refetch,
    user: data?.users?.edges[0],
    status:
      data?.users?.edges[0]?.node?.organizationMemberships?.edges[0]?.node
        .status,
    start:
      data?.users?.edges[0].node.organizationMemberships.edges[0]?.node.start ||
      "",
    role: data?.users?.edges[0].node.organizationMemberships?.edges[0]?.node
      .role,
    membershipId:
      data?.users?.edges[0].node.organizationMemberships?.edges[0]?.node.id,
  };
};
