# Interval

## Overview

The `interval` specifies the time frequency for refilling the token bucket, directly influencing the rate at which `fill_amount` is applied.

## Example

If the `interval` is set to 60 second and the `fill_amount` is 20, the bucket will be replenished with 20 tokens within a minute.

## Note

The `interval` works in conjunction with the `fill_amount` to ensure a constant refilling of the bucket. This will allow users to keep making steady request after getting rate limited. Determining the right interval is crucial for sustaining application performance and enabling controlled user access. Align this setting with the expected frequency of user requests and your application's request handling capacity, to maintain a constant flow of traffic.
