import type { UseMenuReturnType } from "../../components";
import type { DashboardFolderSearch } from "../../hooks";
import type { UiData } from "../../types";

export function dashboardMenuData(dashboards: DashboardFolderSearch[]) {
  const listItems: UseMenuReturnType<DashboardFolderSearch>["listItems"] =
    dashboards.map((dash) => {
      const uiData: UiData = {
        id: dash.uid,
        name: dash.title.split(" - ")[0].trim(),
        componentName: dash.title,
      };

      return {
        ...dash,
        uiData,
      };
    });

  return { listItems };
}
