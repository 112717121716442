import { SvgIcon } from "@mui/material";
import React, { type FC } from "react";

import { Image } from "#shared/components/image";
import {
  googleIcon,
  SignInWithButton,
  type SignInWithButtonProps,
} from "#shared/components/layouts/sign-in";
import { type Texts } from "#shared/types";

export interface SignInWithGoogleButtonProps extends SignInWithButtonProps {
  texts?: Texts<Text>;
}

type Text = "label";

const enTexts: Required<SignInWithGoogleButtonProps["texts"]> = {
  label: "Continue with Google",
};

export const SignInWithGoogleButton: FC<SignInWithGoogleButtonProps> = ({
  texts = enTexts,
  ...props
}) => (
  <SignInWithButton
    startIcon={
      <SvgIcon {...{ src: googleIcon, alt: "Google", component: Image }} />
    }
    {...props}
  >
    {texts.label}
  </SignInWithButton>
);
