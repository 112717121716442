import type { ThemeOptions } from "@mui/material";

const styleOverrides: Required<
  Required<ThemeOptions>["components"]
>["MuiPaper"]["styleOverrides"] = {
  root: { margin: 0, padding: 0 },
  elevation: {
    boxShadow: `-3px 0px 30px rgba(80, 76, 73, 0.15)`,
  },
};

export const MuiPaper: Required<
  Required<ThemeOptions>["components"]
>["MuiPaper"] = { styleOverrides };
