import { List, ListItem, ListItemText } from "@mui/material";
import React, { type FormEvent, type FC } from "react";
import { useParams } from "react-router-dom";

import { useAlert } from "#shared/components/alerts-provider";
import { LoggerService } from "#shared/services/logger";
import type { Texts } from "#shared/types";

import {
  DropdownSearchBox,
  type DropdownControl,
} from "#organization/components/layouts/dropdown-search-box";

import { useAllProjectsQuery } from "./hooks";

import { useAddUserGroupToProjectMutation } from "../../../../../../hooks";
import { PROJECTS_SETTINGS_ROUTE } from "../../../../../../projects/routes.definitions";

type Text = "addProjectToUserGroupSuccess" | "addProjectToUserGroupError";

interface AddProjectToUserGroupDropdownProps {
  dropdownControl: DropdownControl;
  refetch: () => void;
  texts?: Texts<Text>;
}

const enTexts: Required<AddProjectToUserGroupDropdownProps["texts"]> = {
  addProjectToUserGroupSuccess: "Successfully added new project to user group!",
  addProjectToUserGroupError: "Could not added new project to user group.",
};

export const AddProjectToUserGroupDropdown: FC<
  AddProjectToUserGroupDropdownProps
> = ({ dropdownControl, refetch, texts = enTexts }) => {
  const { userGroupId } = useParams<{ userGroupId: string }>();
  const allProjects = useAllProjectsQuery();
  const { addUserGroupToProject } = useAddUserGroupToProjectMutation();
  const { addAlert } = useAlert();

  const searchProject = (event: FormEvent<HTMLInputElement>) => {
    allProjects.setSearch(event.currentTarget?.value);
  };

  const handleAddProjectToUserGroup = (projectId: string) => {
    if (!userGroupId) return;

    addUserGroupToProject(
      {
        project: projectId,
        userGroup: userGroupId,
        permissions: "read",
      },
      {
        onSuccess: () => {
          refetch();
          allProjects.refetch();

          addAlert({
            type: "success",
            message: texts.addProjectToUserGroupSuccess,
          });
        },
        onError: (error) => {
          LoggerService.error(error);

          addAlert({
            type: "error",
            message: texts.addProjectToUserGroupError,
          });
        },
      },
    );
  };

  return (
    <DropdownSearchBox
      idDropdown={dropdownControl.id}
      isOpen={dropdownControl.open}
      anchorEl={dropdownControl.dropdownElement}
      dropdownHeaderName={PROJECTS_SETTINGS_ROUTE.TITLE}
      inputOnChange={searchProject}
      defaultInputValue={allProjects.search}
      onClickAwayHandler={dropdownControl.closePopper}
    >
      <List disablePadding aria-label="dropdown list">
        {allProjects.projects?.map((project) => (
          <ListItem
            key={project.node.id}
            button
            onClick={() => handleAddProjectToUserGroup(project.node.id)}
            disabled={project?.node?.userGroupProjectAssignments?.edges?.some(
              ({ node }) => node.userGroup.id === userGroupId,
            )}
          >
            <ListItemText primary={project.node.name} />
          </ListItem>
        ))}
      </List>
    </DropdownSearchBox>
  );
};
