import { gql } from "graphql-request";
import { useMemo } from "react";
import { useQuery } from "react-query";

import { ENV } from "#shared/env";
import type {
  ApiKeyQuery,
  GetProjectRegionQuery,
} from "#shared/generated/graphql";
import { gqlRequest } from "#shared/utils";

import { useProjectContextState } from "#organization/recoil/project";

import CSharpDocs from "./docs/sdk/csharp.mdx";
import GoDocs from "./docs/sdk/go.mdx";
import JavaDocs from "./docs/sdk/java.mdx";
import PyDocs from "./docs/sdk/py.mdx";
import TsDocs from "./docs/sdk/ts.mdx";

export const SDKList = [
  {
    key: "ts",
    language: "TypeScript",
    link: "https://github.com/fluxninja/aperture/tree/main/sdks/aperture-js",
    docs: TsDocs,
    replacements: [
      {
        key: "API_KEY",
        find: `apiKey: "API_KEY"`,
        replace: `apiKey: "{{API_KEY}}"`,
      },
      {
        key: "ENDPOINT",
        find: `address: "ORGANIZATION.app.fluxninja.com:443",`,
        replace: `address: "{{ENDPOINT}}",`,
      },
    ],
  },
  {
    key: "py",
    language: "Python",
    link: "https://github.com/fluxninja/aperture/tree/main/sdks/aperture-py",
    docs: PyDocs,
    replacements: [
      {
        key: "API_KEY",
        find: `os.getenv("APERTURE_API_KEY", "")`,
        replace: `os.getenv("APERTURE_API_KEY", "{{API_KEY}}")`,
      },
      {
        key: "ENDPOINT",
        find: `agent_address = os.getenv("APERTURE_AGENT_ADDRESS", default_agent_address)`,
        replace: `agent_address = os.getenv("APERTURE_AGENT_ADDRESS", "{{ENDPOINT}}")`,
      },
    ],
  },
  {
    key: "go",
    language: "Go",
    link: "https://github.com/fluxninja/aperture/tree/main/sdks/aperture-go",
    docs: GoDocs,
    replacements: [
      {
        key: "API_KEY",
        find: `getEnvOrDefault("APERTURE_API_KEY", "")`,
        replace: `getEnvOrDefault("APERTURE_API_KEY", "{{API_KEY}}")`,
      },
      {
        key: "ENDPOINT",
        find: `Address:     "ORGANIZATION.app.fluxninja.com:443",`,
        replace: `Address:     "{{ENDPOINT}}",`,
      },
    ],
  },
  {
    key: "java",
    language: "Java",
    link: "https://github.com/fluxninja/aperture/tree/main/sdks/aperture-java",
    docs: JavaDocs,
    replacements: [
      {
        key: "API_KEY",
        find: `.setAPIKey(apiKey)`,
        replace: `.setAPIKey("{{API_KEY}}")`,
      },
      {
        key: "ENDPOINT",
        find: `.setAddress(agentAddress)`,
        replace: `.setAgentAddress("{{ENDPOINT}}")`,
      },
    ],
  },
  {
    key: "csharp",
    language: "C#",
    link: "https://github.com/fluxninja/aperture/tree/main/sdks/aperture-csharp",
    docs: CSharpDocs,
    replacements: [
      {
        key: "API_KEY",
        find: `.SetAgentApiKey("API_KEY")`,
        replace: `.SetAgentApiKey("{{API_KEY}}")`,
      },
      {
        key: "ENDPOINT",
        find: `.SetAddress("ORGANIZATION.app.fluxninja.com:443")`,
        replace: `.SetAddress("{{ENDPOINT}}")`,
      },
    ],
  },
] as const;

type SDKSnippets = {
  [k in (typeof SDKList)[number]["key"]]?: {
    [key: string]: string;
  };
};

export const fetchSDKSnippets = async () => {
  const fetchResponse = await fetch(
    `https://raw.githubusercontent.com/fluxninja/aperture/main/docs/content/code-snippets.json`,
  );
  const snippets = await fetchResponse.json();

  return snippets;
};

export const useSDKSnippets = () =>
  useQuery<SDKSnippets>({
    queryKey: ["aperture", "sdk", "snippets"],
    queryFn: fetchSDKSnippets,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

export const apiKeyQuery = gql`
  query ApiKey($where: AgentKeyBoolExp, $first: Int) {
    agentKeys(where: $where, first: $first) {
      edges {
        node {
          key
        }
      }
    }
  }
`;

export const useApiKey = (projectIDProp?: string) => {
  const { id: currentProjectID } = useProjectContextState();

  const projectID = projectIDProp || currentProjectID;

  const query = useQuery<ApiKeyQuery>({
    queryKey: ["agentKeys", "first", projectID],
    queryFn: () =>
      gqlRequest(apiKeyQuery, {
        first: 1,
        where: {
          ...(projectID && {
            project: {
              id: {
                eq: projectID,
              },
            },
          }),
        },
      }),
  });

  const apiKey = useMemo(
    () => query.data?.agentKeys.edges[0]?.node.key,
    [query.data?.agentKeys.edges],
  );

  return {
    ...query,
    apiKey,
  };
};

export const GetProjectRegion = gql`
  query GetProjectRegion($where: ProjectBoolExp) {
    projects(where: $where) {
      edges {
        node {
          region
        }
      }
    }
  }
`;

export const useGetProjectRegion = (projectIDProp?: string) => {
  const { id: currentProjectID } = useProjectContextState();

  const projectID = projectIDProp || currentProjectID;

  const query = useQuery<GetProjectRegionQuery>({
    queryKey: ["project", projectID, "region"],
    queryFn: () =>
      gqlRequest(GetProjectRegion, {
        where: {
          id: {
            eq: projectID,
          },
        },
      }),
  });

  const region = useMemo(
    () => query.data?.projects.edges[0]?.node.region,
    [query.data?.projects.edges],
  );

  return {
    ...query,
    region,
  };
};

export const useGetAgentEndpoint = (region: string) =>
  `agents.${region}.gcp.${ENV.VITE_APP_DOMAIN}:443`;
