import { CodeBlockWithCopyButton, CodeSnippet } from "#organization/components";

export const language = "cs";

[Aperture C# SDK](https://www.nuget.org/packages/ApertureSDK/) can be used to
manually set feature control points within a .NET service.

To do so, first create an instance of ApertureClient:

<CodeBlockWithCopyButton language={language} code={`
var sdk = ApertureSdk
    .Builder()
    .SetAddress("ORGANIZATION.app.fluxninja.com:443")
    .SetAgentApiKey("API_KEY")
    .Build();
`} />

The created instance can then be used to start a flow:

<CodeSnippet lang={language} snippetName="handleRequest" />

For more context on using the Aperture C# SDK to set feature control points,
refer to the [example app][example] available in the repository.

[example]: https://github.com/fluxninja/aperture-csharp/tree/main/Examples
[api-keys]: /reference/cloud-ui/api-keys.md
