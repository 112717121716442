import { useEffect, useMemo, useState } from "react";
import { useQueries } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { QUERY_PARAMS } from "#shared/consts";
import { type HttpState, useFetch } from "#shared/hooks";
import { FnIntercom } from "#shared/intercom";
import { userEmailState, userNewState } from "#shared/recoil";
import type { AnyObject, Organization } from "#shared/types";
import { httpClient, type RequestArgs } from "#shared/utils/http-client";
import { getOrganizationAbsoluteUrl } from "#shared/utils/urls";

import { API_URLS, QUERY_KEYS } from "#global/consts";

import { CREATE_ORGANIZATION_ROUTE } from "./create/routes.definitions";

export type UseOrganizations = {
  httpState: HttpState<string | null>;
  result: Organization[] | null;
  request: () => void;
  reset: () => void;
};

/**
 * NOTE:
 *
 * Fetch organizations
 */
export const useOrganizations = (): UseOrganizations => {
  const [organizations, setOrganizations] = useState<
    UseOrganizations["result"]
  >([]);
  const navigate = useNavigate();

  const { httpState, request, result, reset, updateHttpState } =
    useFetch<Organization[]>();

  const firebaseEmail = useRecoilValue(userNewState.email);
  const email = useRecoilValue(userEmailState);
  const token = useRecoilValue(userNewState.firebaseToken);

  const emailQueryParam = firebaseEmail ?? "";

  const requestOrganizations = useMemo(
    () => () => {
      if (httpState.isSuccess || httpState.isLoading || !httpState.isIdle) {
        return;
      }

      if (!emailQueryParam) {
        updateHttpState.setError(
          "Failed to fetch organizations due to missing email.",
        );

        return;
      }

      const url = new URL(API_URLS.ORGANIZATIONS.INDEX);

      url.searchParams.append(QUERY_PARAMS.EMAIL, emailQueryParam);

      request({
        url,
        shouldParse: true,
      });
    },
    [
      emailQueryParam,
      httpState.isIdle,
      httpState.isLoading,
      httpState.isSuccess,
      request,
      updateHttpState,
    ],
  );

  useEffect(() => {
    if (httpState.isSuccess || httpState.isLoading || httpState.error) {
      return;
    }

    if (!token) {
      return;
    }

    const url = new URL(API_URLS.ORGANIZATIONS.INDEX);
    url.searchParams.append(QUERY_PARAMS.EMAIL, email || "");

    request({
      url,
      shouldParse: true,
      headers: token ? { Authorization: token } : {},
    });
  }, [
    navigate,
    token,
    email,
    httpState.error,
    httpState.isLoading,
    httpState.isSuccess,
    request,
  ]);

  useEffect(() => {
    if (httpState.isSuccess && result) {
      if (!result.length) {
        FnIntercom.trackEvent("no_org", {
          email: emailQueryParam,
        });

        navigate(CREATE_ORGANIZATION_ROUTE.ABSOLUTE_PATH);

        return;
      }

      setOrganizations(
        result.map((organization) => ({
          ...organization,
          link: getOrganizationAbsoluteUrl(organization.domain_name),
        })),
      );
    }
  }, [result, navigate, httpState.isSuccess, emailQueryParam]);

  const orgs = useCustomToken(
    organizations || [],
    {
      headers: token ? { Authorization: token } : {},
    },
    firebaseEmail || email || "",
  );

  return useMemo<UseOrganizations>(
    () => ({
      result: orgs,
      httpState,
      request: requestOrganizations,
      reset,
    }),
    [orgs, httpState, requestOrganizations, reset],
  );
};

/**
 * This hook adds the token to the organization link to do the auto sign-in to the organization ui.
 * @param organization
 * @param reqArgs
 * @returns {Organization[]}
 */
export const useCustomToken = (
  organization: Organization[],
  reqArgs: Omit<RequestArgs<false, AnyObject>, "url">,
  email: string,
) => {
  const { data } = useRecoilValue(userNewState.state);

  const queries = useQueries(
    organization.map((org, i) => ({
      queryKey: [QUERY_KEYS.CUSTOM_TOKEN, i],
      queryFn: async () => {
        const response = await httpClient.post<{ token: string }>({
          url: API_URLS.TOKEN.INDEX,
          body: {
            tenantId: org.idp_tenant_id,
            email,
            claims: data,
          },
          ...reqArgs,
        });

        return response.json();
      },
    })),
  );

  return useMemo(
    () =>
      queries.map((query, i) => ({
        ...organization[i],
        link: addSearchParams(
          organization[i].link,
          query.data?.token || null,
          email,
        ),
      })),
    [email, organization, queries],
  );
};

export const addSearchParams = <T extends string | null>(
  link: string,
  token: T,
  email: string,
) => {
  const url = new URL(link);

  if (!token?.length) {
    url.searchParams.append("globalUI", "true");

    return url.toString();
  }

  url.searchParams.append(QUERY_PARAMS.CUSTOM_TOKEN, token);
  url.searchParams.append(QUERY_PARAMS.EMAIL, email);

  return url.toString();
};
