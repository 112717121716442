import { DeleteSweep, AddBox } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { format } from "date-fns";
import React from "react";

import type { HeadCell } from "#shared/components/data-grid";
import { PermissionEnum } from "#shared/recoil";
import { FeatureFlag } from "#shared/utils";

import { PermissionWrapper } from "#organization/components/permission-wrapper";

import type { AdaptedAgentKeysGroupData } from "../hooks";
import type { ApiState } from "../page";

export const headCells = (
  onRevoke: (agentKey: ApiState) => void,
  onAddAgent: (agentKey: ApiState) => void,
): HeadCell<AdaptedAgentKeysGroupData>[] => [
  {
    accessor: "key",
    label: "Key value",
    key: "key",
  },
  {
    accessor: (row) => (row.createdBy ? row.createdBy.name : "FluxNinja Admin"),
    label: "Created by",
    key: "createdBy.name",
  },
  {
    accessor: (row) => format(new Date(row.createdAt), "dd/MM/yyyy HH:mm:ss"),
    label: "Creation date",
    key: "createdAt",
  },
  {
    accessor: "agents.totalCount",
    label: "No of agents",
    key: "agentsAggregate.count",
  },
  {
    accessor: "controllers.totalCount",
    label: "No of controllers",
    key: "controllersAggregate.count",
  },
  {
    accessor: (row) => (
      <Box>
        <PermissionWrapper level={PermissionEnum.user_group_admin}>
          <Button
            color="error"
            startIcon={<DeleteSweep />}
            onClick={() => onRevoke({ id: row.id, key: row.key })}
          >
            Revoke
          </Button>
        </PermissionWrapper>

        <PermissionWrapper level={PermissionEnum.user_group_admin}>
          <FeatureFlag
            flag="addControllersAgentsColumn"
            renderOn={
              <Button
                sx={{ minWidth: 170 }}
                startIcon={<AddBox />}
                onClick={() => onAddAgent({ id: row.id, key: row.key })}
              >
                Add
              </Button>
            }
          />
        </PermissionWrapper>
      </Box>
    ),
    label: "Actions",
    key: "revokeKey",
    noSorting: true,
  },
];
