import { Box, Paper, TextField } from "@mui/material";
import React, { type FC, type PropsWithChildren, useState } from "react";
import { useFormContext, type FieldValues, type Path } from "react-hook-form";

import { LoaderLayout } from "#shared/components/layouts";
import type { Texts } from "#shared/types";

import * as ConcurrencyLimitBlueprint from "./@types";
import { concurrencyLimitingFormDefaultValues } from "./default-values";
import { concurrencyLimitingDocsTree } from "./docs";

import {
  FieldWithTitle,
  FormFieldWrapper,
  PolicyFormTreeView,
  TreeItemStyled,
} from "../../styled";
import {
  MaxConcurrency,
  ConcurrencyLimiterParameterInputs,
  ConcurrencyLimiterParametersAdvanced,
  Selectors,
} from "../common";
import { DeniedResponseStatusCodeInput } from "../common/scheduler";
import { createFieldNameWithPrefix } from "../common/utils";
import { FormContextProvider } from "../form-context-provider";
import { useBlueprintJson, useSyncWithEditor } from "../hooks";
import { PolicyFormTemplate } from "../templates";

export type ConcurrencyLimitingLabelNames = "max_concurrency";

export declare type ConcurrencyLimitingInputsProps<
  TFields extends FieldValues = ConcurrencyLimitBlueprint.Coordinate,
> = {
  fieldsPrefix?: Path<TFields>;
  texts?: Texts<ConcurrencyLimitingLabelNames>;
};

const defaultInputTexts = {
  max_concurrency: "Max Concurrency",
};

export const ConcurrencyLimitingInputs = <
  TFields extends FieldValues = ConcurrencyLimitBlueprint.Coordinate,
>({
  fieldsPrefix = "policy.concurrency_limiter" as Path<TFields>,
  texts = defaultInputTexts,
}: ConcurrencyLimitingInputsProps<TFields>) => {
  const { watch, register } = useFormContext<TFields>();

  useSyncWithEditor<TFields>(watch);

  return (
    <TreeItemStyled nodeId="concurrencyLimiter" label="Concurrency Limiting">
      <MaxConcurrency<TFields>
        {...{
          fieldsPrefix,
          texts,
        }}
      />
      <ConcurrencyLimiterParameterInputs />
      <Selectors<TFields>
        {...{
          fieldsPrefix,
        }}
      />
      <ConcurrencyLimiterParametersAdvanced>
        <FormFieldWrapper>
          <FieldWithTitle
            label="Tokens label key"
            fieldAddress={createFieldNameWithPrefix<TFields>(
              fieldsPrefix,
              "request_parameters.tokens_label_key",
            )}
          >
            <TextField
              {...{
                ...register(
                  createFieldNameWithPrefix<TFields>(
                    fieldsPrefix,
                    "request_parameters.tokens_label_key",
                  ),
                ),
                placeholder: "Tokens label key",
              }}
            />
          </FieldWithTitle>
          <DeniedResponseStatusCodeInput<TFields>
            fieldsPrefix={createFieldNameWithPrefix(
              fieldsPrefix,
              "request_parameters",
            )}
          />
        </FormFieldWrapper>
      </ConcurrencyLimiterParametersAdvanced>
    </TreeItemStyled>
  );
};

export const ConcurrencyLimitingForm: FC<PropsWithChildren> = ({
  children,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const { blueprintJson: policy } =
    useBlueprintJson<ConcurrencyLimitBlueprint.Coordinate>(
      concurrencyLimitingFormDefaultValues,
      isDirty,
    );

  if (!policy) {
    return <LoaderLayout />;
  }

  return (
    <FormContextProvider<ConcurrencyLimitBlueprint.Coordinate>
      defaultValues={policy}
    >
      {(methods) => (
        <PolicyFormTemplate<ConcurrencyLimitBlueprint.Coordinate>
          {...{
            methods,
            recordIsDirty: [isDirty, setIsDirty],
            docTree: concurrencyLimitingDocsTree,
            texts: {
              blueprintLabel: "Concurrency Limiting Blueprint",
              policyName: "Policy name",
            },
          }}
        >
          <PolicyFormTreeView
            treeViewProps={{
              defaultExpanded: ["concurrencyLimiter", "selectors"],
            }}
          >
            <Box component={Paper} p={2}>
              <ConcurrencyLimitingInputs />
            </Box>
          </PolicyFormTreeView>
          {children}
        </PolicyFormTemplate>
      )}
    </FormContextProvider>
  );
};
