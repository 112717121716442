import { get } from "lodash";
import type { Dispatch, SetStateAction } from "react";
import type {
  FieldValues,
  UseFieldArrayRemove,
  UseFieldArrayReplace,
} from "react-hook-form";

import { useAlert } from "#shared/components/alerts-provider";
import type {
  CreateAlertsEmailNotifierInput,
  CreateAlertsSlackNotifierInput,
  CreateAlertsWebhookNotifierInput,
  UpdateAlertsEmailNotifierInput,
  UpdateAlertsSlackNotifierInput,
  UpdateAlertsWebhookNotifierInput,
} from "#shared/generated/graphql";

import { useDeleteAlertsNotifiers } from "./delete-notifiers-hook";
import type { NotifierTypeMenuList } from "./form-by-notifier";

import {
  type AlertsMutationFunctions,
  type AlertsUpdateFunctions,
  useDataCenterContext,
} from "../../../data-center";

export const executeNotifierCreateMutation = (
  notifier: FieldValues,
  channelID: string,
  projectID: string,
  alertsMutateFunctions: Omit<AlertsMutationFunctions, "createAlertsChannel">,
  cb: (status: boolean) => void,
) => {
  const notifierType: NotifierTypeMenuList["value"] = get(
    notifier,
    "notifierType",
  );

  const commonLoad: { project: string; alertsChannel: string } = {
    project: projectID,
    alertsChannel: channelID,
  };

  const reportError = {
    onSuccess: () => cb(true),
    onError: () => cb(false),
  };

  switch (notifierType) {
    case "createEmailNotifier":
      return () => {
        const { email, sendResolved } = notifier;
        const payload: CreateAlertsEmailNotifierInput = {
          ...commonLoad,
          email,
          sendResolved,
        };

        get(alertsMutateFunctions, "createEmailNotifier")(payload, reportError);
      };

    case "createSlackNotifier":
      return () => {
        const { url, channel, sendResolved } = notifier;
        const payload: CreateAlertsSlackNotifierInput = {
          ...commonLoad,
          channel,
          url,
          sendResolved,
        };
        get(alertsMutateFunctions, "createSlackNotifier")(payload, reportError);
      };

    case "createWebhookNotifier":
      return () => {
        const { url, sendResolved } = notifier;
        const payload: CreateAlertsWebhookNotifierInput = {
          ...commonLoad,
          url,
          sendResolved,
        };

        get(alertsMutateFunctions, "createWebhookNotifier")(
          payload,
          reportError,
        );
      };

    default:
      return () => {};
  }
};

export interface AddNewIfNotExistsPayload {
  notifier: FieldValues;
  alertsMutateFunctions: Omit<AlertsMutationFunctions, "createAlertsChannel">;
  projectID: string;
  channelID: string;
  cb: (status: boolean) => void;
}

export const executeNotifierUpdateMutation = (
  alertsUpdateFunctions: AlertsUpdateFunctions,
  payload: AddNewIfNotExistsPayload,
) => {
  const { notifier, cb } = payload;
  const notifierType: keyof AlertsUpdateFunctions = get(
    notifier,
    "notifierType",
  ).replace("create", "updateAlerts");

  const reportError = {
    onError: () => cb(false),
  };

  switch (notifierType) {
    case "updateAlertsEmailNotifier":
      return () => {
        const { id, email, sendResolved } = notifier;

        get(alertsUpdateFunctions, "updateAlertsEmailNotifier")(
          { id, email, sendResolved } as UpdateAlertsEmailNotifierInput,
          {
            ...reportError,
            onSuccess: (res) => {
              const resID = get(
                res,
                "updateAlertsEmailNotifier.alertsEmailNotifier.id",
              ) as string | null | undefined;
              addNewIfNotExists(resID, payload);
            },
          },
        );
      };

    case "updateAlertsSlackNotifier":
      return () => {
        const { channel, id, url, sendResolved } = notifier;

        get(alertsUpdateFunctions, "updateAlertsSlackNotifier")(
          { channel, id, url, sendResolved } as UpdateAlertsSlackNotifierInput,
          {
            ...reportError,
            onSuccess: (res) => {
              const resID = get(
                res,
                "updateAlertsSlackNotifier.alertsSlackNotifier.id",
              ) as string | null | undefined;
              addNewIfNotExists(resID, payload);
            },
          },
        );
      };

    case "updateAlertsWebhookNotifier":
      return () => {
        const { id, url, sendResolved } = notifier;

        get(alertsUpdateFunctions, "updateAlertsWebhookNotifier")(
          { id, url, sendResolved } as UpdateAlertsWebhookNotifierInput,
          {
            ...reportError,
            onSuccess: (res) => {
              const resID = get(
                res,
                "updateAlertsWebhookNotifier.alertsWebhookNotifier.id",
              ) as string | null | undefined;
              addNewIfNotExists(resID, payload);
            },
          },
        );
      };

    default:
      return () => {};
  }
};

const addNewIfNotExists = (
  resID: string | null | undefined,
  {
    notifier,
    channelID,
    projectID,
    alertsMutateFunctions,
    cb,
  }: AddNewIfNotExistsPayload,
): void => {
  if (!resID?.length) {
    executeNotifierCreateMutation(
      notifier,
      channelID,
      projectID,
      alertsMutateFunctions,
      cb,
    )();
  }
};

export type OnClickDeleteNotifier = (
  index: number,
  remove: UseFieldArrayRemove,
  replace: UseFieldArrayReplace<FieldValues, "newChannel">,
) => void;

export type UseOnClickDeleteNotifier = ([
  notifierFieldsArr,
  setNotifierFieldsArr,
]: [FieldValues[], Dispatch<SetStateAction<FieldValues[]>>]) => {
  onClickDeleteNotifier: OnClickDeleteNotifier;
};

export const useOnClickDeleteNotifier: UseOnClickDeleteNotifier = ([
  notifierFieldsArr,
  setNotifierFieldsArr,
]) => {
  const { addAlert } = useAlert();
  const {
    reFetchFlag: { setFetchFlag },
  } = useDataCenterContext();
  const { deleteNotifier } = useDeleteAlertsNotifiers();

  const onClickDeleteNotifier: OnClickDeleteNotifier = (
    index,
    remove,
    replace,
  ) => {
    if (!notifierFieldsArr[index]?.id) {
      remove(index);

      return;
    }

    deleteNotifier(notifierFieldsArr[index], (status: boolean, res) => {
      if (status && res) {
        addAlert({
          type: "success",
          message: "Notifier deleted successfully",
        });

        setFetchFlag(true);

        setNotifierFieldsArr((prev) => {
          const updatedFields = prev.filter((p) => p.id !== res.id);
          replace(updatedFields);

          return [...updatedFields];
        });
      }

      if (!status) {
        addAlert({
          type: "error",
          message: "Error deleting notifier",
        });
      }
    });
  };

  return { onClickDeleteNotifier };
};
