import { Box, styled } from "@mui/material";

export const SilentChannelBoxStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 1,
  padding: theme.spacing(2),
  color: theme.palette.text.primary,
  width: 498,
  boxShadow: `-3px 0px 30px rgba(80, 76, 73, 0.15)`,
}));

export const SilentChannelFormWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  paddingTop: theme.spacing(2),
  minHeight: 150,
}));

export const SilentChannelFormButtonWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
});
