import { useCallback, useMemo, useState } from "react";
import { useMutation } from "react-query";

import { QUERY_PARAMS } from "#shared/consts";
import { httpClient } from "#shared/utils/http-client";

import { API_URLS } from "#organization/pages/consts";

import type { MembersPageData } from "./components/table";

// TODO: User should be also removed from IAB!
export const useRemoveMembersFromOrg = <
  TMemberData extends { organizationMembershipId: string } = MembersPageData,
>() => {
  const [selectedMember, setSelectedMember] = useState<TMemberData | null>(
    null,
  );

  const removeMembershipRaw = useMemo(
    () => (ids: string[]) => {
      const url = new URL(API_URLS.INVITE.REMOVE);

      ids.filter(Boolean).forEach((id) => {
        url.searchParams.append(QUERY_PARAMS.MEMBERSHIP_ID, id);
      });

      return httpClient.delete<{ data: string[] }>({ url: url.toString() });
    },
    [],
  );

  const { mutate: deleteMemberships } = useMutation({
    mutationKey: "removeMembership",
    mutationFn: removeMembershipRaw,
  });

  const removeMembership = useCallback(
    async (onSuccess?: () => void, onError?: () => void) => {
      if (!selectedMember) {
        return;
      }

      deleteMemberships([selectedMember.organizationMembershipId], {
        onError,
        onSuccess,
      });
    },
    [deleteMemberships, selectedMember],
  );

  return {
    removeMembership,
    selectedMember,
    setSelectedMember,
  };
};
