import { Typography, Box, CircularProgress } from "@mui/material";
import React, { type FC } from "react";

import { type UserRole, userRoleEnTexts } from "#shared/recoil";
import { LoggerService } from "#shared/services";
import type { Texts } from "#shared/types";

import { useMembersData } from "../hooks";

export interface InviteSummaryProps {
  texts?: Texts<Text>;
  userGroupId: string;
}

type Text = UserRole | "none";

const enTexts: InviteSummaryProps["texts"] = {
  ...userRoleEnTexts,
  none: "none",
};

export const InviteSummary: FC<InviteSummaryProps> = ({
  texts = enTexts,
  userGroupId,
}) => {
  LoggerService.debug("Use where in useMembersData", { userGroupId });

  const {
    isLoading,
    isIdle,
    data: { edges: data },
  } = useMembersData({
    where: { userGroupMemberships: { userGroupID: { eq: userGroupId } } },
  });

  if (isLoading || isIdle) {
    return <CircularProgress size="1em" />;
  }

  if (!data.length) {
    return <Typography color="text.secondary">{texts.none}</Typography>;
  }

  return (
    <>
      {data
        .filter(({ email }) => email)
        .map(({ email, role }, index) => (
          <Box display="flex" columnGap={2} key={[email, index].join("-")}>
            <Typography color="primary">{email}</Typography>
            <Typography color="text.secondary"> {texts[role]}</Typography>
          </Box>
        ))}
    </>
  );
};
