import { Add } from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Button, CircularProgress, type ButtonProps } from "@mui/material";
import { cloneDeep, cloneDeepWith, find } from "lodash";
import React, { type FC, useEffect, useMemo, useCallback } from "react";

import {
  DialogLayout,
  type DialogControl,
  type DialogLayoutProps,
} from "#shared/components/layouts/dialog";
import { LoaderLayout } from "#shared/components/layouts/loader/loader";
import { PermissionEnum } from "#shared/recoil";
import { LoggerService } from "#shared/services";
import type { Texts } from "#shared/types";

import { InviteMemberItem, type InviteMemberItemProps } from "./components";
import { DialogContentTextWrapper, ErrorMessageWrapper } from "./styled";

import {
  findInvitationResponse,
  type InviteMembersFormData,
  useInviteMembers,
} from "../../../common/use-invite-members";
import { useAllUserGroupsQuery } from "../../organization/hooks-new";

export interface InviteMembersDialogProps {
  dialogControl: DialogControl;
  texts?: Texts<Text>;
}

type Text =
  | "send"
  | "add"
  | "expires"
  | "invitedAgain"
  | "days"
  | "enterEmail"
  | "title"
  | "invitations"
  | "invitation";

const enTexts: Required<InviteMembersDialogProps["texts"]> = {
  send: "Send",
  add: "Add another",
  enterEmail: "Enter email address to invite user.",
  expires: "Invitation sent by email expires after",
  days: "days",
  invitedAgain: "and the user will need to be invited again.",
  title: "Invite member",
  invitation: "invitation",
  invitations: "invitations",
};

/**
 * NOTE:
 *
 * IabTokenClaims expiresIn 14 days
 */
const TOKEN_EXPIRATION_DAYS = 14;

export const InviteMembersDialog: FC<InviteMembersDialogProps> = ({
  dialogControl,
  texts = enTexts,
}) => {
  const { userGroups, loading } = useAllUserGroupsQuery();

  const {
    sendInvitations,
    sendInvitationsError,
    isSendingInvitations,
    setMembers,
    members,
    emptyMember,
    invitationResponse,
    isSuccess,
    resetForm,
    resetInviteMembersStatus,
    setIsSuccess,
  } = useInviteMembers({ loading });

  useEffect(() => {
    if (!dialogControl.isOpen) {
      return;
    }

    resetInviteMembersStatus();
  }, [dialogControl.isOpen, resetInviteMembersStatus]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      dialogControl.close();

      setIsSuccess(false);
    }
  }, [isSuccess, dialogControl, resetForm, setIsSuccess]);

  useEffect(() => {
    if (dialogControl.isOpen) {
      resetForm();
    }
  }, [dialogControl.isOpen, resetForm]);

  const firstUserGroup = useMemo(() => {
    const [
      { node: userGroup = { id: null, name: null } } = {
        node: { id: null, name: null },
      },
    ] = userGroups || [];

    return userGroup;
  }, [userGroups]);

  const { id: firstUserGroupId, name: firstUserGroupName } = firstUserGroup;

  const onAddButtonClick: ButtonProps["onClick"] = useCallback(() => {
    if (!firstUserGroupId) {
      LoggerService.warn("Missing first user group id.");

      return;
    }

    setMembers((prevMembers) => {
      const newMembers = cloneDeepWith(prevMembers);

      const newMember: InviteMembersFormData = {
        email: "",
        userRole: PermissionEnum.user_group_member,
        userGroup: firstUserGroupId,
        isValid: false,
        id: `${newMembers.length}`,
      };

      newMembers.push(newMember);

      return newMembers;
    });
  }, [firstUserGroupId, setMembers]);

  const addButtonProps: ButtonProps = {
    sx: { mt: 2 },
    variant: "text",
    startIcon: <Add />,
    onClick: onAddButtonClick,
    disabled: members.some(({ isValid }) => !isValid),
  };

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!firstUserGroupId) {
      LoggerService.warn("Missing first user group id.");

      return;
    }

    const membersCopy = cloneDeep(members);

    const [firstMember] = membersCopy;

    if (!firstMember || firstMember.userGroup) {
      return;
    }

    firstMember.userGroup = firstUserGroupId;

    setMembers(membersCopy);
  }, [userGroups, loading, members, firstUserGroupId, setMembers]);

  const userGroupSelectProps: InviteMemberItemProps["userGroupSelectProps"] =
    useMemo(
      () =>
        firstUserGroupId
          ? {
              initialData: {
                id: firstUserGroupId,
                name: firstUserGroupName,
              },
              selectProps: { disabled: isSendingInvitations },
            }
          : null,
      [firstUserGroupId, firstUserGroupName, isSendingInvitations],
    );

  const setMember: (index: number) => InviteMemberItemProps["setMember"] =
    useCallback(
      (index) => (updatedMember) => {
        setMembers((prevMembers) => {
          const clonedMembers = cloneDeep(prevMembers);

          clonedMembers[index] = {
            ...updatedMember,
            id: `${index}`,
          };

          return clonedMembers;
        });
      },
      [setMembers],
    );

  const deleteMember: (
    id: string,
    index: number,
  ) => InviteMemberItemProps["deleteButtonProps"]["onClick"] = useCallback(
    (_, index) => () => {
      setMembers((prevMembers) => {
        const clonedMembers = cloneDeep(prevMembers);
        const { length } = clonedMembers;

        if (length === 1) {
          return [cloneDeep(emptyMember)];
        }

        clonedMembers.splice(index, 1);

        return clonedMembers;
      });
    },
    [setMembers, emptyMember],
  );

  const mapMemberToComponent = useCallback(
    (member: InviteMembersFormData, index: number) => {
      const props: InviteMemberItemProps = {
        member,
        userGroupSelectProps,
        deleteButtonProps: {
          onClick: deleteMember(member.id, index),
          disabled:
            isSendingInvitations ||
            (!index && member.email === emptyMember.email),
        },
        memberRoleSelectProps: { disabled: isSendingInvitations },
        emailInputProps: {
          disabled: isSendingInvitations,
        },
        setMember: setMember(index),
        invitationResponse:
          find(invitationResponse, findInvitationResponse(member.email)) ||
          null,
      };

      return <InviteMemberItem key={member.id} {...props} />;
    },
    [
      userGroupSelectProps,
      deleteMember,
      isSendingInvitations,
      emptyMember.email,
      setMember,
      invitationResponse,
    ],
  );

  const actions: DialogLayoutProps["actions"] = [
    {
      id: "error",
      node:
        sendInvitationsError?.message && !isSendingInvitations ? (
          <ErrorMessageWrapper>
            <ErrorOutlineIcon />
            {sendInvitationsError.message}
          </ErrorMessageWrapper>
        ) : null,
    },
    {
      id: "spinner",
      node: isSendingInvitations ? (
        <CircularProgress
          disableShrink
          size="1em"
          sx={({ spacing }) => ({ marginRight: spacing(1) })}
        />
      ) : null,
    },
  ];

  const dialogContentText = [
    texts.enterEmail,
    texts.expires,
    TOKEN_EXPIRATION_DAYS,
    texts.days,
    texts.invitedAgain,
  ].join(" ");

  return (
    <DialogLayout
      maxWidth="lg"
      dialogTitle={texts.title}
      dialogContentText={
        <DialogContentTextWrapper>{dialogContentText}</DialogContentTextWrapper>
      }
      openDialog={dialogControl.isOpen}
      onConfimrDialog={sendInvitations}
      onClickDialogClose={() => {
        if (loading) {
          return;
        }

        dialogControl.close();
      }}
      buttonConfirmText={[
        texts.send,
        members.length > 1 ? texts.invitations : texts.invitation,
      ].join(" ")}
      buttonConfirmVariant="contained"
      buttonConfirmColor="primary"
      buttonCancelVariant="text"
      buttonCancelColor="primary"
      buttonConfirmDisabled={
        isSendingInvitations || !!members.find((member) => !member.isValid)
      }
      buttonCancelDisabled={isSendingInvitations}
      actions={actions}
      disabled={isSendingInvitations}
    >
      {loading ? (
        <LoaderLayout />
      ) : (
        <>
          {members.map(mapMemberToComponent)}
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "flex-end",
            }}
          >
            <Button {...addButtonProps}>{texts.add}</Button>
          </Box>
        </>
      )}
    </DialogLayout>
  );
};
