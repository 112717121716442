import { curry, findIndex, find } from "lodash";

import type {
  Classifier,
  Policy,
  FluxMeter,
  SignalName,
  Graph,
  ComponentView,
  Link,
} from "#shared/generated/graphql";
import type { DeepPartial } from "#shared/types";

import type { CircuitViewResource } from "./selected-resource-context";
import type {
  CircuitComponent,
  EdgeData,
  NodeData,
  ParentChildNodeName,
  PolicyCircuit,
  PolicyWithCircuitAndBody,
} from "./types";

import type { NodeName } from "../../../../../../types";

type Node = DeepPartial<CircuitComponent>;

interface EdgeId {
  signalName: string | null;
  sourceComponentId: string | null;
  sourcePortName: string | null;
  targetComponentId: string | null;
  targetPortName: string | null;
}

export type PartialGraph = Partial<Graph>;
export type AllLinks = Pick<Graph, "externalLinks" | "internalLinks">;
export type AllComponents = Pick<
  Graph,
  "externalComponents" | "internalComponents"
>;

export class PolicyUtils {
  public static parentOrComponentNameAccessor = (
    node?: DeepPartial<CircuitComponent> | null,
  ) => {
    if (!node) {
      return null;
    }

    return node.componentName as NodeName;
  };

  public static readonly circuitNodesWithDashboard: (
    | NodeName
    | Lowercase<NodeName>
  )[] = [
    "ConcurrencyLimiter",
    "RateLimiter",
    "concurrencylimiter",
    "ratelimiter",
  ];

  public static readonly circuitViewResources: CircuitViewResource[] = [
    "Signal",
    "ConcurrencyLimiter",
    "RateLimiter",
  ];

  private static isConcurrencyLimiter = (
    node?: DeepPartial<CircuitComponent> | null,
    componentName = PolicyUtils.parentOrComponentNameAccessor(node),
  ) => componentName?.toLowerCase() === "concurrencylimiter";

  private static isRateLimiter = (
    node?: DeepPartial<CircuitComponent> | null,
    componentName = PolicyUtils.parentOrComponentNameAccessor(node),
  ) => componentName?.toLowerCase() === "ratelimiter";

  public static isLimiter = <N extends Node>(
    node?: N | null,
    componentName = PolicyUtils.parentOrComponentNameAccessor(node),
  ) =>
    PolicyUtils.isConcurrencyLimiter(node, componentName) ||
    PolicyUtils.isRateLimiter(node, componentName);

  public static isNodeWithDashboard = <N extends Node>(node?: N | null) => {
    const componentName = PolicyUtils.parentOrComponentNameAccessor(node);

    return (
      componentName &&
      (PolicyUtils.circuitNodesWithDashboard as string[]).includes(
        componentName.toLowerCase(),
      )
    );
  };

  public static someNodeWithDashboard = (
    circuit?: DeepPartial<PolicyCircuit> | null,
  ) =>
    !![
      ...(circuit?.graph?.externalComponents
        ? circuit.graph.externalComponents
        : []),
      ...(circuit?.graph?.internalComponents
        ? circuit.graph.internalComponents
        : []),
    ]?.some(PolicyUtils.isNodeWithDashboard);

  public static findCircuitNodeByComponentIndex = (
    index: null | number | undefined,
    body: Partial<PolicyWithCircuitAndBody["body"]> | null | undefined,
  ) => {
    if (
      typeof index === "undefined" ||
      index === null ||
      index < 0 ||
      !body?.circuit
    ) {
      return null;
    }

    const circuitNode = Object.values(
      body.circuit.components[index as number],
    )[0];

    return circuitNode;
  };

  public static mergeInternalAndExternalComponents(
    graph: AllComponents | null,
  ) {
    if (!graph) {
      return null;
    }

    const { internalComponents, externalComponents } = graph;

    return [
      ...(externalComponents || []),
      ...(internalComponents || []),
    ] as ComponentView[];
  }

  public static mergeInternalAndExternalLinks(graph: AllLinks | null) {
    if (!graph) {
      return null;
    }

    const { internalLinks, externalLinks } = graph;

    return [...(externalLinks || []), ...(internalLinks || [])] as Link[];
  }

  public static getLimitersQueryParamValueByComponentIndex = (
    componentId: number | null | undefined | string,
    componentName: NodeName | null | undefined,
    policy: Partial<Pick<Policy, "name" | "hash" | "body">> | null | undefined,
  ) => {
    if (
      componentId === null ||
      componentId === undefined ||
      !PolicyUtils.isLimiter(null, componentName) ||
      !policy ||
      !policy.hash ||
      !policy.name
    ) {
      return null;
    }

    return {
      "var-policy_name": policy.name,
      "var-policy_hash": policy.hash,
      "var-component_id": componentId?.toString(),
    };
  };

  // TEST TEST
  public static findClassifierIndex = (
    classifier?: Pick<Classifier, "flowLabel"> | null,
    policy?: Pick<PolicyWithCircuitAndBody, "body"> | null,
  ) =>
    classifier?.flowLabel
      ? findIndex(
          policy?.body?.resources?.flowControl?.classifiers,
          ({ rules }) => Object.keys(rules).includes(classifier?.flowLabel),
        )
      : -1;

  public static findFluxMeterIndex = (
    fluxMeter?: Pick<FluxMeter, "name"> | null,
    policy?: Pick<PolicyWithCircuitAndBody, "body"> | null,
  ) =>
    fluxMeter?.name
      ? findIndex(
          policy?.body?.resources?.flowControl?.fluxMeters,
          ({ rules }) => Object.keys(rules).includes(fluxMeter?.name),
        )
      : -1;

  public static findNodeById = curry(
    (
      nodes: ReadonlyArray<NodeData> | NodeData[] | null,
      id: string | undefined,
    ) => (id ? find(nodes, { componentId: id }) || null : null),
  );

  public static findSignalById = curry(
    (signals: ReadonlyArray<EdgeData> | EdgeData[], id: string | undefined) => {
      if (!id) {
        return null;
      }

      const edgeId = PolicyUtils.decodeEdgeId(id);

      const {
        signalName,
        sourceComponentId,
        sourcePortName,
        targetComponentId,
        targetPortName,
      } = edgeId as { [K in keyof EdgeId]: string | null };

      const searchedSignal = {
        value: {
          signalName,
        },
        source: { componentId: sourceComponentId, portName: sourcePortName },
        target: { componentId: targetComponentId, portName: targetPortName },
      };

      const signal = find(signals, searchedSignal) || null;

      return signal;
    },
  );

  public static concatParentName = (
    nodeData: NodeData,
    parent?: NodeData | null,
  ) =>
    [parent?.componentName, nodeData.componentName]
      .filter(Boolean)
      .join("/") as NodeName | ParentChildNodeName;

  public static isComponentWithDashboardPredicate() {
    return (node: NodeData) => PolicyUtils.isNodeWithDashboard(node);
  }

  /**
   * NOTE:
   * converts NodeData[] into CircuitComponent[]
   */
  public static reduceCircuitComponentsWithDashboard() {
    const filterNodesWithDashboardPredicate =
      PolicyUtils.filterNodeWithDashboardPredicate();

    return (result: CircuitComponent[], node: NodeData) =>
      filterNodesWithDashboardPredicate(node)
        ? [...result, PolicyUtils.mapToCircuitComponent()(node)]
        : result;
  }

  public static filterNodeWithDashboardPredicate() {
    const isComponentWithDashboardPredicate =
      PolicyUtils.isComponentWithDashboardPredicate();

    return (
      node: NodeData,
      _?: number,
      __?: ReadonlyArray<NodeData> | NodeData[],
    ) => isComponentWithDashboardPredicate(node);
  }

  public static mapToCircuitComponent() {
    return (
      node: NodeData,
      _?: number,
      __?: ReadonlyArray<NodeData> | NodeData[],
    ) => {
      const circuitComponent: CircuitComponent = {
        ...node,
        uiData: {
          id: node.componentId,
          name: node.componentName,
          componentName: node.componentName as NodeName,
        },
      };

      return circuitComponent;
    };
  }

  public static encodeEdgeId = (edge: EdgeData) => {
    const source = edge.source && edge.source.componentId;
    const target = edge.target && edge.target.componentId;

    const sourceHandle = [source, edge.source && edge.source.portName].join(
      ".",
    );
    const targetHandle = [target, edge.target && edge.target.portName].join(
      ".",
    );

    const id = [
      sourceHandle,
      targetHandle,
      (edge.value as unknown as SignalName)?.signalName,
    ].join("-");

    return id;
  };

  public static decodeEdgeId = (id?: string | null): EdgeId => {
    if (!id) {
      return {
        signalName: null,
        sourceComponentId: null,
        sourcePortName: null,
        targetComponentId: null,
        targetPortName: null,
      };
    }

    const [sourceHandle = "", targetHandle = "", signalName] = id.split("-");

    const [sourcePortName, ...sourceComponentId] = sourceHandle
      .split(".")
      .reverse();

    const [targetPortName, ...targetComponentId] = targetHandle
      .split(".")
      .reverse();

    return {
      signalName,
      sourceComponentId: sourceComponentId.reverse().join("."),
      sourcePortName,
      targetComponentId: targetComponentId.reverse().join("."),
      targetPortName,
    };
  };
}
