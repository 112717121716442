import { Box, Typography } from "@mui/material";
import React, { type FC } from "react";

import type { Texts } from "#shared/types";

export interface CreateOrEditPolicyHeaderProps {
  texts?: Texts<"title">;
}

export const CreateOrEditPolicyHeader: FC<CreateOrEditPolicyHeaderProps> = ({
  texts = {
    title: "Create Policy",
  },
}) => (
  <Box display="flex" justifyContent="space-between" alignItems="center">
    <Typography variant="h1" component="h1" alignSelf="center">
      {texts.title}
    </Typography>
    <Box id="header-action-buttons" alignSelf="center" />
  </Box>
);
