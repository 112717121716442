import { CodeSnippet, CodeBlockWithCopyButton } from "#organization/components"

export const language = "ts";

# Rate Limiting Blueprint

## Overview

This blueprint provides a token bucket based rate-limiting policy. This policy uses the RateLimiter component.

Rate limiting is a critical strategy for managing the load on an API. For example, by imposing restrictions on the number of requests a unique user can make within a specific time frame, rate limiting prevents a small set of users from abusing a service. By restricting from sending repeated requests to a service, fair access for all API users can be ensured.

To create a valid rate limiting policy, it is essential to fill all the required fields.

## Example

Here is a code example:

<CodeSnippet lang={language} snippetName="handleRequestRateLimit" />

This rate limiter components has a token bucket with a capacity of 40, permitting users to make up to 40 requests rapidly before getting rate limited. The bucket refills 20 tokens between each minute. Users are assigned an individual token bucket based on the `limit_by_label_key`, which is `limit_key` in this example. However, if `limit_by_label_key` is not provided, a global token bucket is created for all users. For further information about each component of the rate limiter, hover over them to see examples and detailed explanations.

## Note

The rate limiting policy must be tailored to align with your specific business requirements and the capacity of your system. The primary objective is to maintain fair access for all API users and safeguards the system from unexpected surges by limiting API abusers.
