import { useMemo } from "react";
import { useNodes, useViewport, type Node } from "react-flow-renderer";

import type { CircuitComponent } from "#organization/pages/authenticated/policies/policy/types";

import type { CircuitBoundaryStyledProps } from "./styled";

import { useCircuitContext } from "../../../circuit-context";

const emptyResponse: CircuitBoundaryStyledProps & { hide: boolean } = {
  dimensions: { width: 0, height: 0, top: 0, left: 0 },
  transform: { zoom: 1, x: 0, y: 0 },
  hide: false,
};

export const useBoundaryDimensions = () => {
  const { zoom, x: viewX, y: viewY } = useViewport();

  const nodes = useNodes<CircuitComponent>();
  const { externalComponents } = useCircuitContext();

  return useMemo(() => {
    if (!nodes.length) return emptyResponse;

    const onlyInternalNodes = nodes.filter((n) => {
      const isExternal = externalComponents?.find(
        (c) => c?.componentId === n.id,
      );

      return !isExternal;
    });

    const d = findBoundaryDimensions(onlyInternalNodes, zoom);

    const viewport = {
      zoom,
      x: viewX,
      y: viewY,
    };

    return {
      dimensions: d,
      transform: viewport,
      hide: !externalComponents?.length,
    };
  }, [externalComponents, nodes, viewX, viewY, zoom]);
};

export const findBoundaryDimensions = (
  nodes: Node<CircuitComponent>[],
  zoom: number,
) => {
  const maxWidth = Math.max(
    ...nodes.map(({ position: { x }, width }) => x + (width || 0)),
  );
  const minWidth = Math.min(
    ...nodes.map(({ position: { x }, width }) => x + (width || 0)),
  );

  const maxHeight = Math.max(
    ...nodes.map(({ position: { y } }, height) => y + (height || 0)),
  );
  const minHeight = Math.min(
    ...nodes.map(({ position: { y } }, height) => y + (height || 0)),
  );

  return {
    width: maxWidth - minWidth + 300 + zoom * 100,
    height: maxHeight - minHeight + 300 + zoom * 100,
    top: (Math.min(...nodes.map((node) => node.position.y)) - 100) * zoom,
    left: (Math.min(...nodes.map((node) => node.position.x)) - 100) * zoom,
  };
};
