import { Tab, Tabs, type TabsProps } from "@mui/material";
import React, { type FC } from "react";

import type { Texts } from "#shared/types";

type Text = "alerts" | "status";

export interface FlyoutMenuTabsProps extends TabsProps {
  activeTab: number;
  texts?: Texts<Text>;
}

export const enTexts: Required<FlyoutMenuTabsProps>["texts"] = {
  alerts: "Alerts",
  status: "Status",
};

export const AgentFlyoutMenuTabs: FC<FlyoutMenuTabsProps> = ({
  activeTab,
  onChange,
  texts = enTexts,
}) => (
  <Tabs value={activeTab} onChange={onChange} sx={{ justifySelf: "flex-end" }}>
    <Tab label={texts.alerts} />
    <Tab label={texts.status} />
  </Tabs>
);
