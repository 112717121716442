import React, { type FC } from "react";

import { AlertTable } from "#organization/pages/authenticated/alert-manager/tabs/alert-logs/components/alerts-table";

interface PolicyAlertsProps {
  policyName?: string;
}

export const PolicyAlerts: FC<PolicyAlertsProps> = ({ policyName }) => {
  const additionalFilterVariables = {
    labels: { contains: { policy_name: policyName } },
  };

  return <AlertTable additionalFilterVariables={additionalFilterVariables} />;
};
