import { Box, type BoxProps } from "@mui/material";
import classnames from "classnames";
import React, {
  type ComponentType,
  type FC,
  forwardRef,
  type PropsWithChildren,
} from "react";

import type { AnyObject } from "#shared/types";

import { useGridAreaStyles } from "./use-grid-area-styles";
import type { GridViewAreaActionsProps } from "./view-actions";
import type { GridViewAreaTitleProps } from "./view-area-title";

export type GridViewAreaProps<T extends AnyObject = GridViewAreaTitleProps> =
  PropsWithChildren<{
    containerProps?: Omit<BoxProps, "children" | "id">;
    innerContainerProps?: Omit<BoxProps, "children">;
    contentContainerProps?: Omit<BoxProps, "children">;
    className?: string;
    id?: string;
    actionsProps?: GridViewAreaActionsProps;
    Title?: ComponentType<T> | null;
    titleProps?: T;
  }>;

export const GridViewArea: FC<GridViewAreaProps> = forwardRef((props, ref) => {
  const {
    containerProps,
    Title = null,
    actionsProps,
    contentContainerProps,
    children,
    className,
    innerContainerProps,
    id,
    titleProps,
    ...otherProps
  } = props;

  const { classNames } = useGridAreaStyles();

  return (
    <Box
      ref={ref}
      id={id}
      {...containerProps}
      {...otherProps}
      className={classnames(
        className,
        classNames.area,
        containerProps?.className,
      )}
    >
      {Title && <Title {...titleProps} />}
      <Box
        {...innerContainerProps}
        className={classnames(
          classNames.innerContainer,
          innerContainerProps?.className,
        )}
      >
        <Box
          {...contentContainerProps}
          className={classnames(
            classNames.contentContainer,
            contentContainerProps?.className,
          )}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
});
