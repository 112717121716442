import { TextField } from "@mui/material";
import React from "react";
import {
  useFormContext,
  type FieldValues,
  type Path,
  Controller,
} from "react-hook-form";

import { TimeRangePickerField } from "../../../inputs";
import {
  FieldWithTitle,
  FormFieldWrapper,
  TreeItemStyled,
} from "../../../styled";
import * as QuotaSchedulerBlueprint from "../../quota-scheduling/@types";
import type { BlueprintFieldProps } from "../types";
import { createFieldNameWithPrefix } from "../utils";

export const SchedulerDefaultWorkloadParameters = <
  TFields extends FieldValues = QuotaSchedulerBlueprint.Coordinate,
>({
  fieldsPrefix = "policy.quota_scheduler.scheduler.default_workload_parameters" as Path<TFields>,
}: BlueprintFieldProps<TFields>) => {
  const { getValues, control } = useFormContext<TFields>();

  const defaultValues = getValues(fieldsPrefix);

  return (
    <TreeItemStyled nodeId={fieldsPrefix} label="Default Workload Parameters">
      <FormFieldWrapper>
        <FieldWithTitle
          label="Priority"
          fieldAddress={createFieldNameWithPrefix(fieldsPrefix, "priority")}
        >
          <Controller
            control={control}
            defaultValue={defaultValues?.priority}
            name={createFieldNameWithPrefix(fieldsPrefix, "priority")}
            render={({ field, field: { onChange } }) => (
              <TextField
                {...{
                  ...field,
                  onChange: (e) => onChange(Number(e.target.value)),
                  type: "number",
                  placeholder: "Priority",
                }}
              />
            )}
          />
        </FieldWithTitle>
        <Controller
          name={createFieldNameWithPrefix(fieldsPrefix, "queue_timeout")}
          control={control}
          defaultValue={defaultValues?.queue_timeout}
          render={({ field }) => (
            <TimeRangePickerField
              fieldLabelProps={{
                label: "Queue timeout",
                fieldAddress: createFieldNameWithPrefix(
                  fieldsPrefix,
                  "queue_timeout",
                ),
              }}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <FieldWithTitle
          label="Tokens"
          fieldAddress={createFieldNameWithPrefix(fieldsPrefix, "tokens")}
        >
          <Controller
            control={control}
            defaultValue={defaultValues?.tokens}
            name={createFieldNameWithPrefix(fieldsPrefix, "tokens")}
            render={({ field, field: { onChange } }) => (
              <TextField
                {...{
                  ...field,
                  onChange: (e) => onChange(Number(e.target.value)),
                  type: "number",
                  placeholder: "Tokens",
                }}
              />
            )}
          />
        </FieldWithTitle>
      </FormFieldWrapper>
    </TreeItemStyled>
  );
};
