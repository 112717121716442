import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { type FC } from "react";

import { Chip } from "#shared/components/chip";
import { HEALTH_STATUSES } from "#shared/consts";
import type { Texts } from "#shared/types";

import { globalTimeParser, parseStatus } from "#organization/utils";

import type { AgentsFlyoutMenuConfig } from "../../types";

type Text =
  | "agent"
  | "agentsGroups"
  | "agentKey"
  | "status"
  | "version"
  | "lastSeen";

interface AgentFlyoutMenuHeaderProps {
  agent: AgentsFlyoutMenuConfig;
  texts?: Texts<Text>;
}

const enTexts: Required<AgentFlyoutMenuHeaderProps["texts"]> = {
  agent: "Agent",
  agentsGroups: "Agents groups",
  agentKey: "Agent key",
  status: "Status",
  version: "Version",
  lastSeen: "Last seen",
};

export const AgentFlyoutMenuHeader: FC<AgentFlyoutMenuHeaderProps> = ({
  agent,
  texts = enTexts,
}) => {
  const agentStatus = parseStatus(agent.status);

  return (
    <>
      <Box display="flex" alignItems="center" columnGap={1}>
        <Typography variant="h6">{agent.name}</Typography>
        {agent.status === HEALTH_STATUSES.ARCHIVED && (
          <Chip label="archived" variant="outlined" />
        )}
        <Chip label={texts.agent.toLocaleLowerCase()} color="success" />
      </Box>

      <Box display="grid" gridTemplateColumns="268px 1fr" pb={1} pt={2}>
        <Typography variant="body2">{texts.agentsGroups}</Typography>
        <Typography variant="body2">{agent.agentGroup}</Typography>
        <Typography variant="body2">{texts.agentKey}</Typography>
        <Typography variant="body2">{agent.agentKey}</Typography>
        <Typography variant="body2">{texts.status}</Typography>
        <Box display="flex" gap={1} alignItems="center">
          <Box
            sx={{
              height: "10px",
              width: "10px",
              borderRadius: "100%",
              backgroundColor: agentStatus.color,
            }}
          />
          <Typography variant="body2" color={agentStatus.color}>
            {agentStatus.name}
          </Typography>
        </Box>
        <Typography variant="body2">{texts.version}</Typography>
        <Typography variant="body2" color="success.main">
          {agent.version}
        </Typography>
        <Typography variant="body2">{texts.lastSeen}</Typography>
        <Typography variant="body2">
          {globalTimeParser(agent.lastSeen)}
        </Typography>
      </Box>
    </>
  );
};
