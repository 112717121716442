import React, { type FC } from "react";
import { Route, Routes } from "react-router-dom";

import { CompleteSubscription } from "./complete-subscription";

export const CompleteSubscriptionRoutes: FC = () => (
  <Routes>
    <Route index element={<CompleteSubscription />} />
  </Routes>
);
