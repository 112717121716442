import { ORGANIZATION_SETTINGS_ROUTE } from "../routes.definitions";

export const USER_GROUPS_SETTINGS_ROUTE = {
  TITLE: "User Groups",
  KEY: "user-groups",
  ABSOLUTE_PATH: `${ORGANIZATION_SETTINGS_ROUTE.ABSOLUTE_PATH}/user-groups`,
  PARENT_PATH: "user-groups/*",
  CHILD_KEY: "userGroupId",
  CHILD_PATH: ":userGroupId",
};

export type UserGroupIdParam = {
  userGroupId: string;
};
