import { Box, type BoxProps } from "@mui/material";
import React, { memo, type FC, useMemo } from "react";

import { Card, type CardProps } from "./card";

import { useCheckProvisioningStatus } from "../../hooks";

export interface ClickableCardProps extends Omit<CardProps, "error"> {
  boxProps?: Omit<BoxProps, "children">;
}

export const ClickableCard: FC<ClickableCardProps> = memo(
  ({ boxProps, organization, membership }) => {
    const { provisioningStatus, hasError } =
      useCheckProvisioningStatus(organization);

    const organizationWithUpdatedStatus = useMemo(() => {
      /**
       * NOTE:
       * If provisioning status is the same as the one from the server, then
       * use the organization from the server in order to avoid unnecessary re-renders
       * (Card component is memoized)
       */
      if (provisioningStatus === organization.provisioning_status) {
        return organization;
      }

      return { ...organization, provisioning_status: provisioningStatus };
    }, [organization, provisioningStatus]);

    return (
      <Box px={1} mb={1} {...boxProps}>
        <Card
          organization={organizationWithUpdatedStatus}
          membership={membership}
          error={hasError}
        />
      </Box>
    );
  },
);
