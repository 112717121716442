import type {
  ClassifiersForControlPoint,
  ComponentsForControlPoint,
  FluxMetersForControlPoint,
  ControlPointAssignmentsTableData,
} from "./tabs/flow-control-components/hooks";

/**
 * NOTE: Transform circuit components to desired format
 */
export function componentsToTableFormat() {
  return (
    c: ComponentsForControlPoint,
  ): ControlPointAssignmentsTableData | null =>
    c?.component
      ? {
          componentName: c.component.subtype || "-",
          componentType: c.component.type,
          policyName: c.component.policy.name,
          policyID: c.component.policy.id,
        }
      : null;
}

export function classifiersToTableFormat() {
  return (
    classifier: ClassifiersForControlPoint,
  ): ControlPointAssignmentsTableData | null => {
    const { classifier: c } = classifier || {};

    return c
      ? {
          componentName: c?.flowLabel,
          componentType: "Classifier",
          policyName: c.policy.name,
          policyID: c.policy.id,
        }
      : null;
  };
}

export function fluxMetersToTableFormat() {
  return (
    fluxMeter: FluxMetersForControlPoint,
  ): ControlPointAssignmentsTableData | null => {
    const { fluxMeter: f } = fluxMeter || {};

    return f
      ? {
          componentName: f.name,
          componentType: "FluxMeter",
          policyName: f.policy.name,
          policyID: f.policy.id,
        }
      : null;
  };
}
