import { CodeSnippet, CodeBlockWithCopyButton } from "#organization/components"

export const language = "ts";

# Label Matcher

## Overview

The label matcher is used to narrow down the selected flows using conditions defined on Labels. It allows for precise filtering of flows based on specific criteria. SDKs allow developers to apply labels that correspond to predefined criteria within the policy.

## Example

Labels can be defined within the policy and then passed via the SDK when making a `Start` flow call. Here is how:

<CodeSnippet lang={language} snippetName="QSUI" />

There are multiple ways to match labels. Here are a few examples:

- **Exact Match:** It is the simplest way to match a label. It matches the label value exactly.

<CodeBlockWithCopyButton language="yaml" code={`
 match_labels:
  customer_tier: gold
  product_tier: paid
`} />

- **Matching Expressions:** It allows for more complex matching conditions using operators such as `In`, `NotIn`, `Exists`, and `DoesNotExists`.

<CodeBlockWithCopyButton language="yaml" code={`
match_expressions:
  - key: http.method
    operator: In
    values:
      - GET
      - POST
`} />

- **Arbitrary Expression:** This allows for defining complex matching conditions, including regular expression matching.


<CodeBlockWithCopyButton language="yaml" code={`
 expression:
  label_matches:
    - label: user_agent
      regex: ^(?!.*Chrome).*Safari
`} />


## Note

There are multiple ways to define a label matcher. If multiple match criteria are defined simultaneously, then they all must match for a flow to be selected.
