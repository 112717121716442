import { Chip as MuiChip, type ChipProps as MuiChipProps } from "@mui/material";
import React, { type FC } from "react";

import type {
  FnColor,
  MuiColor,
  AlertColor,
} from "../../../../app-theme-provider/types";

export type ChipProps = Omit<MuiChipProps, "color"> & { color?: ChipColor };

export type ChipColor = MuiColor | Extract<FnColor, "plum" | AlertColor>;

export const Chip: FC<ChipProps> = ({ color, ...props }) =>
  color ? (
    <MuiChip {...{ ...props, color: color as MuiColor }} />
  ) : (
    <MuiChip {...props} />
  );
