import { Box, styled } from "@mui/material";
import React, { type FC } from "react";

import { Input } from "#shared/components/inputs/input";

import type { FnTheme } from "../../../../../../../../../../app-theme-provider/types";

export const TopBar = styled(Box)(({ theme }) => {
  const palette = theme.palette as unknown as Required<FnTheme["palette"]>;
  const shape = theme.shape as unknown as Required<FnTheme["shape"]>;

  return {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: shape.policyView.circuitBarHeight,
    backgroundColor: palette.tableGrid.headColor,
  };
});

const SEARCH_BOX_WIDTH = "380px";
const SEARCH_BOX_HEIGHT = "40px";

export const SearchBoxInnerContainer = styled(Box)(({ theme }) => {
  const palette = theme.palette as unknown as Required<FnTheme["palette"]>;

  return {
    width: SEARCH_BOX_WIDTH,
    height: SEARCH_BOX_HEIGHT,
    backgroundColor: palette.tableGrid.headColor,
    border: ["1px", "solid", palette.text.primary].join(" "),
    borderRadius: theme.spacing(0.5),
    zIndex: 100,
    padding: `0px ${theme.spacing(1)}`,
  };
});

export const SearchBarListContainer = styled(Box)({
  position: "absolute",
  top: 70,
  left: 0,
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  width: "100%",
  zIndex: 4,
  padding: "0px !important",
  "& > div": {
    width: "378px",
    alignSelf: "flex-end",
    display: "grid",
    gridTemplateRows: "auto auto 1fr",
    maxHeight: 600,
  },
});

export const SearchBarInput = styled(Input)(({ theme }) => {
  const palette = theme.palette as unknown as Required<FnTheme["palette"]>;

  return {
    "& .MuiOutlinedInput-root": {
      padding: 0,
      color: palette.text.primary,
      backgroundColor: "transparent",
      borderColor: palette.text.primary,
      "& fieldset": {
        border: "none",
      },
      "& ::placeholder": {
        color: palette.text.primary,
        opacity: 1,
      },
    },
  };
});

export const OnSelectStartAdornmentStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.text.primary,
  borderRadius: theme.spacing(0.5),
  fontWeight: 400,
  fontSize: 14,
  lineHeight: "143%",
  padding: [theme.spacing(0.3), theme.spacing(0.6)].join(" "),
  marginLeft: theme.spacing(0.6),
  textOverflow: "ellipsis",
  whiteSpace: "pre",
}));

export interface OnSelectStartAdornmentProps {
  type: string;
  value: string;
}
export const OnSelectStartAdornment: FC<OnSelectStartAdornmentProps> = ({
  type,
  value,
}) => (
  <>
    {type}
    <OnSelectStartAdornmentStyled>{value}</OnSelectStartAdornmentStyled>
  </>
);
