# Fill Amount

## Overview

The `fill_amount` configures the quantity of tokens added to the bucket within the selected `interval`.

## Example

Consider a scenario where the bucket capacity is set to 40. Once a user depletes this initial allowance, if `fill_amount` is set to 20, it means that between each `interval`, 20 tokens are added to the bucket.

## Note

Proper configuration of `fill_amount` is key to achieving a fair and efficient quota scheduling process. It must be set to avoid overprovisioning users while also ensuring that they aren't excessively restricted. The balance between access and control is crucial for a smooth user experience.
