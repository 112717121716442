import { SvgIcon, type SvgIconProps } from "@mui/material";
import React, { useMemo, type FC, useState } from "react";

import { ErrorLayout, LoaderLayout } from "#shared/components/layouts";

import { rateLimitingIcon, requestPrioritizationIcon } from "./icons";
import { ChooseBlueprintLayout } from "./layout";

import { useBlueprints, type HandleNext } from "../../hooks";
import { Widget } from "../widget";

export interface ChooseBlueprintProps {
  handleNext: HandleNext;
  version: string;
  commitSha: string;
}

/**
 * Step One: Choose use case and blueprint
 * Step Two: Customize blueprint
 * Step Three: Apply
 * Currently available use cases:
 * - Request Prioritization
    - Load Scheduling
    - Quota Scheduling
 * - Rate Limiting
 */
export const ChooseBlueprint: FC<ChooseBlueprintProps> = ({
  handleNext,
  version,
  commitSha,
}) => {
  const { isBlueprintsError, isBlueprintsLoading, blueprints } =
    useSelectedBlueprints({ version, commitSha });

  const [expanded, setIsExpended] = useState(false);
  const [expandedRateLimiting, setIsExpendedRateLimiting] = useState(false);

  const blueprintVersion = useMemo(
    () => (commitSha && commitSha !== "unknown" ? commitSha : version),
    [commitSha, version],
  );

  if (isBlueprintsLoading && !isBlueprintsError) {
    return <LoaderLayout />;
  }

  if (isBlueprintsError) {
    return <ErrorLayout />;
  }

  return (
    <ChooseBlueprintLayout>
      <Widget
        title="Rate Limiting"
        subText="Safeguard APIs and code features by enforcing fair access across users"
        onSelectItem={(item) => {
          handleNext("Customize Blueprint", {
            blueprintName: item.name,
            blueprintPath: item.path,
            selectedUseCase: item.name.split("/")[1] || "",
            blueprintVersion,
          });
        }}
        listItems={blueprints.filter(
          ({ path }) =>
            !path.includes("quota-scheduling") &&
            !path.includes("concurrency-scheduling"),
        )}
        accordionProps={{
          expanded: expandedRateLimiting,
          onClick: () => setIsExpendedRateLimiting(!expandedRateLimiting),
        }}
        Icon={<SvgIcon {...svgIconProps}>{rateLimitingIcon}</SvgIcon>}
      />
      <Widget
        title="Request Prioritization"
        subText="Manage peak load with request scheduling and prioritization"
        onSelectItem={(item) => {
          handleNext("Customize Blueprint", {
            blueprintName: item.name,
            blueprintPath: item.path,
            selectedUseCase: item.name.split("/")[1] || "",
            blueprintVersion,
          });
        }}
        listItems={blueprints.filter(
          ({ path }) =>
            !path.includes("rate-limiting") &&
            !path.includes("concurrency-limiting"),
        )}
        accordionProps={{
          expanded,
          onClick: () => setIsExpended(!expanded),
        }}
        Icon={<SvgIcon {...svgIconProps}>{requestPrioritizationIcon}</SvgIcon>}
      />
    </ChooseBlueprintLayout>
  );
};

const svgIconProps: SvgIconProps = {
  viewBox: "0 0 60 60",
  fill: "none",
  sx: (theme) => ({
    width: 60,
    height: 60,
    stroke: theme.palette.primary.main,
    color: "transparent",
    marginTop: theme.spacing(0.8),
  }),
};

export const defaultUseCases = [
  "rate-limiting",
  "quota-scheduling",
  "concurrency-limiting",
  "concurrency-scheduling",
];

const ignoreBlueprints = [
  "average-latency",
  "cpu-overload-protection-k8s",
  "java-gc-generic",
  "java-gc-k8s",
];

const useSelectedBlueprints = ({
  version,
  commitSha,
}: Pick<ChooseBlueprintProps, "commitSha" | "version">) => {
  const {
    isLoading: isBlueprintsLoading,
    data,
    isError: isBlueprintsError,
  } = useBlueprints(version, commitSha);

  const blueprints = useMemo(() => {
    if (!data) {
      return [];
    }

    return data
      .filter((d) =>
        defaultUseCases.find((useCase) => d.path.includes(useCase)),
      )
      .filter(
        (bp) => !ignoreBlueprints.find((ignore) => bp.path.includes(ignore)),
      );
  }, [data]);

  return {
    isBlueprintsError,
    isBlueprintsLoading,
    blueprints,
    data,
  };
};
