import React, { type FC } from "react";

import { useAlert } from "#shared/components/alerts-provider";
import {
  DialogLayout,
  type DialogControl,
} from "#shared/components/layouts/dialog";
import { LoggerService } from "#shared/services/logger";
import type { Texts } from "#shared/types";

import { useRemoveUserFromUserGroup } from "./hooks";

import type { SelectedToRemoveType } from "../../members-section";

type Text =
  | "removeSuccess"
  | "removeError"
  | "title"
  | "description"
  | "button";

interface RemoveUserFromUserGroupProps {
  texts?: Texts<Text>;
  dialogControl: DialogControl;
  selectedToRemove: SelectedToRemoveType;
  refetch: () => void;
}

const enTexts: Required<RemoveUserFromUserGroupProps["texts"]> = {
  removeSuccess: "Successfully removed user from user group.",
  removeError: "Could not remove user from user group.",
  title: "Remove User From User Group",
  description: "Are you sure you want to remove $1 from user group $2?",
  button: "Remove user",
};

export const RemoveUserFromUserGroup: FC<RemoveUserFromUserGroupProps> = ({
  dialogControl,
  selectedToRemove,
  refetch,
  texts = enTexts,
}) => {
  const removeUserFromUserGroup = useRemoveUserFromUserGroup();
  const { addAlert } = useAlert();

  const removeUser = () => {
    removeUserFromUserGroup.removeUser(
      {
        id: selectedToRemove.membershipId,
      },
      {
        onSuccess: () => {
          dialogControl.close();
          refetch();

          addAlert({
            type: "success",
            message: texts.removeSuccess,
          });
        },
        onError: (error) => {
          LoggerService.error(error);

          addAlert({
            type: "error",
            message: texts.removeError,
          });
        },
      },
    );
  };

  return (
    <DialogLayout
      dialogTitle={texts.title}
      dialogContentText={`${texts.description
        .replace("$1", selectedToRemove.email)
        .replace("$2", selectedToRemove.userGroupName || "")}`}
      onCloseDialog={dialogControl.close}
      openDialog={dialogControl.isOpen}
      onClickDialogClose={dialogControl.close}
      onConfimrDialog={removeUser}
      buttonConfirmText="Remove user"
      buttonConfirmVariant="contained"
      buttonConfirmColor="primary"
      buttonCancelVariant="outlined"
      buttonCancelColor="primary"
    />
  );
};
