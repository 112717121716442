import { getUseNewBreadcrumb } from "#shared/components/breadcrumbs";

import { breadcrumbsInFlyoutMenuAtom } from "./atom";

/**
 * Adds a new breadcrumb when the component mounts and removes it when component unmounts.
 *
 * The breadcrumb should have the `key` property set.
 */
export const useNewFlyoutMenuBreadcrumb = getUseNewBreadcrumb(
  breadcrumbsInFlyoutMenuAtom,
);
