import {
  DeleteConfirmBox,
  useDeleteBoxInitializer,
  type DeleteConfirmBoxProps,
  type AddDeleteBox,
  DeleteBoxProvider,
  useDeleteBox,
} from "./delete-confirm-box";

export const PopUpBox = DeleteConfirmBox;
export type PopUpBoxProps = DeleteConfirmBoxProps;
export const usePopUpBoxInitializer = () => useDeleteBoxInitializer();
export type AddPopUpBox = AddDeleteBox;
export type AddPopUpBoxOptions = Omit<
  DeleteConfirmBoxProps,
  "children" | "callback" | "message" | "onClickDeleteBox" | "open"
>;
export const AddPopUpProvider = DeleteBoxProvider;
export const usePopUp = useDeleteBox;
