import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { type FC, useState, useEffect } from "react";
import { useRecoilState } from "recoil";

import {
  FilteringGroup,
  useChipsContainer,
} from "#shared/components/filtering/filtering-group";
import { useParseLinkAttributesToFilterVariables } from "#shared/components/filtering/utils";
import { KubernetesControlPointSelectColumn } from "#shared/generated/graphql";
import type { Texts } from "#shared/types";

import {
  DataGrid,
  DataGridGroups,
  DataGridWrapper,
  FiltersBarWrapper,
  GroupByButtonWrapper,
  GroupBySelect,
  type GroupByOption,
} from "#organization/components/data-grid";
import { projectContextState } from "#organization/recoil/project";

import {
  useDiscoveryKubernetesData,
  useDiscoveryKubernetesGroupData,
  useDiscoveryKubernetesGroupsTitles,
  useDiscoveryKubernetesKindCount,
} from "./hooks";
import { headCells, discoveryKubernetesFilters } from "./table";

type Text =
  | "info"
  | "documentationButton"
  | "replicaSet"
  | "statefulSets"
  | "deployments";

interface DiscoveryKubernetesTabProps {
  texts?: Texts<Text>;
}

const enTexts: Required<DiscoveryKubernetesTabProps["texts"]> = {
  info: "Below you can find a list of Kubernetes entities (deployments, replica sets and pods) discovered in your infrastructure by Aperture agents.",
  documentationButton: "Learn how to use Kubernetes for autoscaling",
  replicaSet: "ReplicaSet",
  statefulSets: "StatefulSets",
  deployments: "Deployments",
};

const GROUP_BYS: GroupByOption<KubernetesControlPointSelectColumn>[] = [
  {
    title: "No value",
    value: "",
  },
  {
    title: "Kind",
    value: KubernetesControlPointSelectColumn.Kind,
  },
  {
    title: "Namespace",
    value: KubernetesControlPointSelectColumn.Namespace,
  },
  {
    title: "API version",
    value: KubernetesControlPointSelectColumn.ApiVersion,
  },
];

const INITIAL_ORDER_BY = "name";
const INITIAL_ORDER = "asc";
const TABLE_UNIQUE_ID = "discovery-kubernetes-table";

export const DiscoveryKubernetesTab: FC<DiscoveryKubernetesTabProps> = ({
  texts = enTexts,
}) => {
  const [projectContext] = useRecoilState(projectContextState);
  const kubernetesKindCount = useDiscoveryKubernetesKindCount(
    projectContext.id,
  );

  const chipsContainer = useChipsContainer();
  const filterHeaders = discoveryKubernetesFilters(projectContext.id);
  const [groupByKey, setGroupByKey] =
    useState<(typeof GROUP_BYS)[number]["value"]>("");

  const [filterVariables, setFilterVariables] = useState(
    useParseLinkAttributesToFilterVariables(TABLE_UNIQUE_ID),
  );

  const headerTable = headCells();

  // refetch kubernetes kind count when project context changes
  useEffect(() => {
    kubernetesKindCount.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectContext]);

  return (
    <Box px={3}>
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        rowGap={1}
        columnGap={3}
        paddingBottom={3}
      >
        <Typography variant="body1">{texts.info}</Typography>

        <Box
          display="grid"
          gridTemplateColumns="auto auto auto auto 1fr"
          columnGap={2}
        >
          <Typography component="span" variant="body1" color="text.secondary">
            {kubernetesKindCount.data?.replicaSet.totalCount || 0}{" "}
            {texts.replicaSet}
          </Typography>

          <Divider orientation="vertical" />

          <Typography component="span" variant="body1" color="text.secondary">
            {kubernetesKindCount.data?.statefulSets.totalCount || 0}{" "}
            {texts.statefulSets}
          </Typography>

          <Divider orientation="vertical" />

          <Typography variant="body1" component="span" color="text.secondary">
            {kubernetesKindCount.data?.deployments.totalCount || 0}{" "}
            {texts.deployments}
          </Typography>
        </Box>
      </Box>

      <FiltersBarWrapper enableArchivedData>
        <FilteringGroup
          labels={filterHeaders}
          onFilter={setFilterVariables}
          chipsContainer={chipsContainer}
          uniqueId={TABLE_UNIQUE_ID}
        />
        <GroupByButtonWrapper>
          <GroupBySelect
            options={GROUP_BYS}
            setGroupByKey={setGroupByKey}
            groupByKey={groupByKey}
          />
        </GroupByButtonWrapper>
      </FiltersBarWrapper>

      <Box py={4}>
        <DataGridWrapper>
          {groupByKey ? (
            <DataGridGroups
              headCells={headerTable}
              useGroupsTitles={useDiscoveryKubernetesGroupsTitles}
              useGroupData={useDiscoveryKubernetesGroupData}
              groupByKey={groupByKey}
              filterVariables={{
                ...filterVariables,
              }}
              initialOrderBy={INITIAL_ORDER_BY}
              initialOrder={INITIAL_ORDER}
              enableArchivedData
            />
          ) : (
            <DataGrid
              headCells={headerTable}
              useGridData={useDiscoveryKubernetesData}
              enabled={!groupByKey}
              filterVariables={filterVariables}
              initialOrderBy={INITIAL_ORDER_BY}
              initialOrder={INITIAL_ORDER}
              enableArchivedData
            />
          )}
        </DataGridWrapper>
      </Box>
    </Box>
  );
};
