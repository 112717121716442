import React, { Component, type PropsWithChildren } from "react";

import { ErrorLayout, ErrorText } from "#shared/components/layouts";

import {
  type ErrorBoundaryContextValue,
  errorBoundaryContext,
} from "./context";

export type ErrorBoundaryProviderProps =
  PropsWithChildren<ErrorBoundaryContextValue>;
export type ErrorBoundaryProviderState = ErrorBoundaryContextValue;

const { Provider } = errorBoundaryContext;

export class ErrorBoundaryProvider extends Component<
  ErrorBoundaryProviderProps,
  ErrorBoundaryProviderState
> {
  constructor(props: ErrorBoundaryProviderProps) {
    super(props);
    this.state = { error: props.error };
  }

  public override componentDidCatch(error: unknown) {
    this.setState({ error });
  }

  public override render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <ErrorLayout>
          <ErrorText {...{ error }} />
        </ErrorLayout>
      );
    }

    return <Provider value={{ error }}>{children}</Provider>;
  }
}
