import {
  ListItemButton,
  styled,
  svgIconClasses,
  Box,
  type BoxProps as MuiBoxProps,
  type TypographyProps as MuiTypographyProps,
  alpha,
  Typography,
} from "@mui/material";
import React, { type FC } from "react";

import type { FnTheme } from "../../../../../app-theme-provider/types";

export type StyledBoxProps = MuiBoxProps & {
  drawerOpen: boolean;
  selected: boolean;
};

export type StyledTypographyProps = MuiTypographyProps & {
  selected: boolean;
};

const MIN_HEIGHT = "24px";

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  position: "relative",
  minHeight: MIN_HEIGHT,
  display: "grid",
  gridTemplateColumns: "1fr",
  borderBottom: `0.5px solid ${theme.palette.divider}`,
}));

const BoxComponent: FC<StyledBoxProps> = ({ drawerOpen, ...props }) => (
  <Box {...props} />
);

export const StyledBox = styled(BoxComponent)(
  ({ drawerOpen, selected, theme: { palette, spacing } }) => ({
    display: "grid",
    gridTemplateColumns: drawerOpen ? "auto 1fr" : "1fr",
    justifyItems: drawerOpen ? "start" : "center",
    alignItems: "center",
    backgroundColor: selected ? palette.secondary.main : "",
    columnGap: spacing(1),
    minHeight: MIN_HEIGHT,
    margin: drawerOpen ? `0 ${spacing(1)} 0 0` : `0 ${spacing(1)}`,
    [`.${svgIconClasses.root}`]: selected
      ? {
          fill: (palette as unknown as FnTheme["palette"]).text
            .secondaryMain as string,
        }
      : {},
    "&:hover": {
      backgroundColor: selected ? "" : alpha(palette.secondary.main, 0.15),
    },
  }),
);

const TypographyComponent: FC<StyledTypographyProps> = ({ ...props }) => (
  <Typography {...props} />
);

export const StyledTypography = styled(TypographyComponent)(
  ({ theme, selected }) => ({
    color: selected
      ? ((theme.palette as unknown as FnTheme["palette"]).text
          .secondaryMain as string)
      : "inherit",
    fontSize: "0.875rem",
  }),
);

export const StyledNav = styled("nav")(() => ({
  width: "100%",
}));
