import type { ThemeOptions } from "@mui/material";

const styleOverrides: Required<
  Required<ThemeOptions>["components"]
>["MuiDialogTitle"]["styleOverrides"] = {
  root: {
    display: "flex",
  },
};

export const MuiDialogTitle: Required<
  Required<ThemeOptions>["components"]
>["MuiDialogTitle"] = { styleOverrides };
