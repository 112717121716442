import { useTheme } from "@mui/material";
import { gql } from "graphql-request";

import type {
  ControllerAgentsGroupsTitlesQuery,
  GetControllerAgentInfoQuery,
  GetControllerAgentsDataQuery,
} from "#shared/generated/graphql";
import { useGqlQuery } from "#shared/utils";

import {
  type DataGridHook,
  type GroupsTitlesHook,
  useGridData,
} from "#organization/hooks";
import { parseStatuses } from "#organization/utils";

import {
  type GroupDataHook,
  useGroupData,
  useGroupsTitles,
} from "../../../../../../hooks/group-by";
import { orderByStatusTemplate } from "../../utils";

const ControllerAgentInfo = gql`
  query GetControllerAgentInfo($first: Int, $where: AgentBoolExp) {
    agents(first: $first, where: $where) {
      totalCount
      edges {
        node {
          status
        }
      }
    }
    agentGroupsCount: agents(where: $where, distinctOn: [agentGroup]) {
      totalCount
    }
  }
`;

export const useControllerAgentInfo = (
  controllerId: string,
  projectId: string,
  first = 100,
) => {
  const theme = useTheme();
  const { data, isLoading, isError } = useGqlQuery<GetControllerAgentInfoQuery>(
    ["controllerAgentInfo", controllerId],
    ControllerAgentInfo,
    {
      first,
      where: {
        controllerID: {
          eq: controllerId,
        },
        projectID: {
          eq: projectId,
        },
      },
    },
  );

  return {
    agentsTotalCount: data?.agents?.totalCount,
    agentGroupsCount: data?.agentGroupsCount?.totalCount,
    agentsHealth: parseStatuses(data?.agents?.edges, theme),
    isLoading,
    isError,
  };
};

export type ControllersAgentGroupBy =
  | "hostname"
  | "agentGroup"
  | "agentKeyID"
  | "status";

const CONTROLLER_AGENTS_ENTITY_KEY = "agents";

export const controllerAgentsGroupsTitles = gql`
  query ControllerAgentsGroupsTitles(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: AgentBoolExp
    $distinctOn: [AgentSelectColumn!]
  ) {
    agents(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      distinctOn: $distinctOn
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          hostname
          agentGroup
          lastSeen
          status
          version
          agentKey {
            key
          }
        }
      }
    }
  }
`;

type ControllerAgentsGroupTitleData =
  ControllerAgentsGroupsTitlesQuery["agents"]["edges"][0]["node"];

export const useControllerAgentsGroupsTitles: GroupsTitlesHook<
  ControllersAgentGroupBy
> = (pageSize, page, setPage, groupByKey, filterVariables) =>
  useGroupsTitles<ControllersAgentGroupBy, ControllerAgentsGroupTitleData>(
    CONTROLLER_AGENTS_ENTITY_KEY,
    pageSize,
    page,
    setPage,
    groupByKey,
    controllerAgentsGroupsTitles,
    (node) => {
      if (groupByKey === "agentKeyID") {
        return node.agentKey.key;
      }

      return node[groupByKey!];
    },
    filterVariables,
  );

type ControllerAgentsGroupData =
  GetControllerAgentsDataQuery["agents"]["edges"][0]["node"];
export type AdaptedControllerAgentsGroupData = Omit<
  ControllerAgentsGroupData,
  "agentKey"
> & {
  agentKey: string;
};

export const controllerAgentsGroupData = gql`
  query GetControllerAgentsData(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: AgentBoolExp
    $orderBy: [AgentOrderBy]
  ) {
    agents(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          hostname
          agentGroup
          lastSeen
          status
          version
          agentKey {
            key
          }
        }
      }
    }
  }
`;

export const useControllerAgentsGroupData: GroupDataHook<
  ControllersAgentGroupBy
> = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  groupByKey,
  groupByValue,
  filterVariables,
) =>
  useGroupData<
    ControllersAgentGroupBy,
    ControllerAgentsGroupData,
    AdaptedControllerAgentsGroupData
  >(
    CONTROLLER_AGENTS_ENTITY_KEY,
    controllerAgentsGroupData,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    groupByKey,
    groupByValue,
    (agents) => ({
      ...agents,
      agentKey: agents.agentKey.key,
    }),
    filterVariables,
    {
      agentKeyID: {
        agentKey: {
          key: {
            eq: groupByValue,
          },
        },
      },
    },
  );

export const useControllerAgentsData: DataGridHook = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  enabled,
  filterVariables,
) =>
  useGridData<ControllerAgentsGroupData, AdaptedControllerAgentsGroupData>(
    CONTROLLER_AGENTS_ENTITY_KEY,
    controllerAgentsGroupData,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    filterVariables,
    (agents: ControllerAgentsGroupData) => ({
      ...agents,
      agentKey: agents.agentKey.key,
    }),
    enabled,
    true,
    orderByStatusTemplate,
  );
