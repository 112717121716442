import { Box, type BoxProps } from "@mui/material";
import React, {
  type ComponentType,
  type FC,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";

import { useIsMounted } from "#shared/hooks";

import type { Breadcrumb } from "./types";

export type BreadcrumbAppenderProps = BoxProps & {
  useNewBreadcrumb: (breadcrumb: Breadcrumb) => void;
  Container?: ComponentType<BoxProps>;
};

export const BreadcrumbAppender: FC<BreadcrumbAppenderProps> = ({
  children,
  useNewBreadcrumb,
  Container = Box,
  ...otherProps
}) => {
  const isMounted = useIsMounted();
  const breadcrumbContainerRef = useRef<HTMLDivElement | null>(null);

  const [, setBreadcrumbContainerMounted] = useState(false);

  const key = useMemo(() => Math.random(), []);

  const breadcrumbContainer = useMemo(
    // NOTE: using a random key is safe thanks to `useMemo` (it is only
    // generated once and stable afterwards)
    () => (
      <Container
        ref={(element) => {
          breadcrumbContainerRef.current = element as HTMLDivElement;

          // NOTE: need to tell the BreadcrumbAppender to rerender and create the portal
          if (isMounted()) {
            setBreadcrumbContainerMounted(true);
          }
        }}
        {...{ ...otherProps, key }}
      />
    ),
    [isMounted, otherProps, Container, key],
  );

  useNewBreadcrumb(breadcrumbContainer);

  if (breadcrumbContainerRef.current) {
    return createPortal(children, breadcrumbContainerRef.current);
  }

  return null;
};
