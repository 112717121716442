import type { PageInfo } from "#shared/generated/graphql";

type Data<T> = ReadonlyArray<{ readonly node: T }>;

type MapFn<T, P> = (v: T) => P;

export function mapEdges<T, P>(
  data: Data<T>,
  mapFn: MapFn<T, P> = (v) => v as unknown as P,
): P[] {
  return data.map((node) => node.node).map(mapFn);
}

export type GqlResponseEdgeMapped<T> = {
  readonly totalCount?: GqlResponse<T>["totalCount"];
  readonly pageInfo?: GqlResponse<T>["pageInfo"];
  readonly edges: ReadonlyArray<T>;
};

type GqlResponse<T> = {
  readonly totalCount?: number;
  readonly pageInfo?: PageInfo;
  readonly edges: ReadonlyArray<{ readonly node: T }>;
};

export function adaptGqlResponse<T, P>(
  { totalCount, pageInfo, edges }: GqlResponse<T>,
  mapFn?: MapFn<T, P>,
) {
  return {
    totalCount,
    pageInfo,
    edges: mapEdges<T, P>(edges, mapFn),
  };
}
