import { SvgIcon, type SvgIconProps } from "@mui/material";
import React, { type FC, type PropsWithRef } from "react";

export const CryingNinjaIcon: FC<PropsWithRef<SvgIconProps>> = React.forwardRef(
  (props, ref) => (
    <SvgIcon width="200" height="200" viewBox="0 0 80 80" {...props} ref={ref}>
      <g clipPath="url(#clip0_4809_173165)">
        <path
          d="M39.55 67.16C53.6056 67.16 65 65.0513 65 62.45C65 59.8487 53.6056 57.74 39.55 57.74C25.4944 57.74 14.1 59.8487 14.1 62.45C14.1 65.0513 25.4944 67.16 39.55 67.16Z"
          fill="#8EC4AD"
        />
        <path
          d="M61.97 40.98C61.16 39.86 61.29 38.29 62.29 37.33C62.99 36.65 63.8 36.04 64.68 35.51C65.56 34.98 66.09 34.07 66.09 33.05V29.76C66.09 20.75 57.35 19.9 52.65 19.45C46.19 18.82 44.51 18.07 43.74 15.67C43.6 15.23 43.37 14.82 43.04 14.5C40.17 11.76 36.33 13.11 35.25 15.97C34.43 18.13 32.62 18.85 26.44 19.45C21.75 19.91 13 20.75 13 29.76V33.06C13 34.07 13.55 35 14.41 35.52C15.27 36.04 16.09 36.65 16.79 37.34C17.78 38.3 17.92 39.87 17.11 40.99C16.47 41.88 15.61 42.58 14.63 43.02C13.65 43.46 13 44.49 13 45.59V49.32C13 58.33 21.74 59.18 26.44 59.63C32.9 60.26 34.58 61.01 35.35 63.41C35.49 63.85 35.72 64.26 36.05 64.58C38.92 67.32 42.76 65.97 43.84 63.1C44.66 60.94 46.47 60.22 52.65 59.62C57.34 59.16 66.09 58.32 66.09 49.31V45.59C66.09 44.49 65.46 43.47 64.46 43.02C63.46 42.57 62.62 41.88 61.97 40.99V40.98Z"
          fill="#EFEEED"
        />
        <path
          d="M39.56 63.23C38.83 63.23 38.18 62.78 37.92 62.1C36.38 58.03 32.49 57.36 26.73 56.8C21.89 56.33 15.87 55.75 15.87 49.32V46.79C15.87 45.82 16.66 45.04 17.62 45.04C18.58 45.04 19.37 45.83 19.37 46.79V49.32C19.37 52.14 21.1 52.73 27.06 53.31C31.35 53.73 36.51 54.23 39.55 57.97C42.59 54.23 47.75 53.73 52.04 53.31C58 52.73 59.73 52.14 59.73 49.32V46.79C59.73 45.82 60.52 45.04 61.48 45.04C62.44 45.04 63.23 45.83 63.23 46.79V49.32C63.23 55.75 57.21 56.33 52.37 56.8C46.61 57.36 42.72 58.03 41.18 62.1C40.92 62.78 40.27 63.23 39.54 63.23H39.56Z"
          fill="#F8773D"
        />
        <path
          d="M73 34.92C69.6 34.76 65.66 36.49 63.49 39.12C64.37 41.3 66.67 42.53 68.97 42.02C68.31 40.37 66.84 39.28 65.17 39.05C68.12 38.7 71.18 37.12 73 34.92Z"
          fill="#F8773D"
        />
        <path
          d="M61.47 34.04C60.5 34.04 59.72 33.25 59.72 32.29V29.76C59.72 26.94 57.99 26.35 52.03 25.77C47.74 25.35 42.58 24.85 39.54 21.11C36.5 24.85 31.34 25.35 27.05 25.77C21.09 26.35 19.36 26.94 19.36 29.76V32.29C19.36 33.26 18.57 34.04 17.61 34.04C16.65 34.04 15.86 33.25 15.86 32.29V29.76C15.86 23.33 21.88 22.75 26.72 22.28C32.48 21.72 36.37 21.05 37.91 16.98C38.17 16.3 38.82 15.85 39.55 15.85C40.28 15.85 40.93 16.3 41.19 16.98C42.73 21.05 46.62 21.72 52.38 22.28C57.22 22.75 63.24 23.33 63.24 29.76V32.29C63.24 33.26 62.45 34.04 61.49 34.04H61.47Z"
          fill="#F8773D"
        />
        <path
          d="M43.68 47.4C43.68 44.69 41.83 42.49 39.55 42.49C37.27 42.49 35.42 44.69 35.42 47.4C35.42 48.76 35.31 50.14 36.06 51.03C36.81 51.92 38.41 52.31 39.55 52.31C40.69 52.31 42.3 51.92 43.04 51.03C43.78 50.14 43.68 48.76 43.68 47.4Z"
          fill="#56AE89"
        />
        <path
          d="M37.18 46.88C36.75 46.88 36.41 46.48 36.49 46.06C36.8 44.41 38.05 43.49 39.55 43.49C41.05 43.49 42.29 44.41 42.61 46.06C42.69 46.49 42.35 46.88 41.92 46.88H37.2H37.18Z"
          fill="#EFEEED"
        />
        <path d="M32.29 34.46H24.38V64.34H32.29V34.46Z" fill="#8EC4AD" />
        <path
          d="M24.39 35.16C26.88 33.94 29.79 33.94 32.28 35.16"
          stroke="#56AE89"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M25.98 29.31C25.98 29.31 24.9 31.76 21.81 32.02"
          stroke="#56AE89"
          strokeLinecap="round"
        />
        <path d="M46.8 64.35H54.71L54.71 34.47H46.8V64.35Z" fill="#8EC4AD" />
        <path
          d="M54.7 35.16C52.21 33.94 49.3 33.94 46.81 35.16"
          stroke="#56AE89"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M53.11 29.31C53.11 29.31 54.19 31.76 57.28 32.02"
          stroke="#56AE89"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4809_173165">
          <rect
            width="60"
            height="54.16"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  ),
);
