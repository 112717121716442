import {
  Box,
  Chip as MuiChip,
  styled,
  typographyClasses,
  chipClasses,
} from "@mui/material";

const ID_WIDTH = 20;
const FONT_SIZE = "14px";

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  [`>.${typographyClasses.root}`]: {
    width: "100%",
    textAlign: "left",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    columnGap: theme.spacing(0.5),
    span: {
      fontSize: FONT_SIZE,
      whiteSpace: "nowrap",
      lineHeight: "1em",
    },
  },
}));

export const Chip = styled(MuiChip)(({ theme }) => ({
  maxWidth: `calc(100% - ${ID_WIDTH}px)`,
  height: "1.5em",
  padding: "0",
  borderRadius: theme.shape.borderRadius,
  [`>.${typographyClasses.root}`]: {
    width: "100%",
  },
  [`.${chipClasses.label}`]: {
    color: theme.palette.primary,
    padding: "2px",
  },
}));
