import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  type Transitions,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { styled, type CSSObject, type Theme } from "@mui/system";
import React, { type FC, type ReactElement } from "react";

import { DRAWER_HEIGHT } from "#shared/consts";

import { useIsLarge, useSidebarController } from "#organization/recoil/sidebar";

import { SIDEBAR_WIDTH } from "../../../../../app-theme-provider/theme";

const openedMixin = (theme: Theme): CSSObject => ({
  width: SIDEBAR_WIDTH.large,
  backgroundColor: theme.palette.background.default,
  transition: (theme.transitions as Transitions).create("width", {
    easing: (theme.transitions as Transitions).easing.sharp,
    duration: (theme.transitions as Transitions).duration.enteringScreen,
  }),
  position: "static",
  overflowX: "hidden",
  height: DRAWER_HEIGHT,
  boxShadow:
    "0px 3px 5px -1px rgba(27, 25, 24, 0.2), 0px 5px 8px rgba(27, 25, 24, 0.14), 0px 1px 14px rgba(27, 25, 24, 0.12)",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: (theme.transitions as Transitions).create("width", {
    easing: (theme.transitions as Transitions).easing.sharp,
    duration: (theme.transitions as Transitions).duration.leavingScreen,
  }),
  position: "static",
  backgroundColor: theme.palette.background.default,
  overflowX: "hidden",
  width: SIDEBAR_WIDTH.small,
  [theme.breakpoints.up("sm")]: {
    width: SIDEBAR_WIDTH.small,
  },
  height: DRAWER_HEIGHT,
  boxShadow:
    "0px 3px 5px -1px rgba(27, 25, 24, 0.2), 0px 5px 8px rgba(27, 25, 24, 0.14), 0px 1px 14px rgba(27, 25, 24, 0.12)",
});

const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "small",
})(({ theme, open }) => ({
  width: SIDEBAR_WIDTH.large,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor: theme.palette.background.paper,
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export interface DrawerProps {
  sidebar?: ReactElement;
}

export const Drawer: FC<DrawerProps> = ({ sidebar }) => {
  const { setLarge, setSmall } = useSidebarController();
  const isLarge = useIsLarge();

  return (
    <StyledDrawer
      variant="permanent"
      open={isLarge}
      PaperProps={{
        sx: {
          display: "grid",
          gridTemplateRows: "auto 1fr auto",
          gridAutoColumns: isLarge ? SIDEBAR_WIDTH.large : SIDEBAR_WIDTH.small,
        },
      }}
    >
      {sidebar}
      <ListItemButton
        sx={{
          minHeight: 32,
          p: 0,
        }}
        onClick={isLarge ? setSmall : setLarge}
      >
        <ListItemIcon
          sx={{
            minWidth: isLarge ? 25 : 60,
          }}
        >
          {isLarge ? (
            <ChevronLeft
              sx={{
                color: (theme) => theme.palette.secondary.main,
                ml: isLarge ? 0.75 : 2,
              }}
            />
          ) : (
            <ChevronRight
              sx={{
                color: (theme) => theme.palette.secondary.main,
                ml: isLarge ? 0.75 : 2,
              }}
            />
          )}
        </ListItemIcon>
        <ListItemText
          sx={{
            color: (theme) => theme.palette.secondary.main,
          }}
          primaryTypographyProps={{
            fontSize: "0.875rem",
            fontWeight: 400,
            letterSpacing: 0.4,
          }}
          primary="collapse"
        />
      </ListItemButton>
    </StyledDrawer>
  );
};
