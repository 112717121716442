import { useEffect, useMemo, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import type { AdaptedPoliciesGroupData } from "../../hooks";

export declare type HandleNext = (
  selectedItem?: string,
  extraParams?: Record<string, string>,
) => void;

/**
 *
 * This hook changes step along with the search params. SelectedItem and step are the search params.
 *
 * They are used to keep track of the current step and the selected item in the current step.
 */
export const useStepChange = (steps: string[]) => {
  const stepsRef = useRef(steps);
  const [value, setValue] = useState(0);
  const { searchInfo, setSearchParams } = useCreatePolicySearchParams();

  const stepName = useMemo(() => stepsRef.current[value], [value]);

  useEffect(() => {
    const { step } = searchInfo;

    if (!step) {
      setSearchParams(
        new URLSearchParams({
          ...searchInfo,
          step: stepsRef.current[0],
        }),
      );

      return;
    }

    const index = stepsRef.current.indexOf(step);
    const currentTab = index !== -1 ? index : 0;

    if (currentTab === value) return;

    setSearchParams(
      new URLSearchParams({
        ...searchInfo,
        step: stepsRef.current[currentTab],
      }),
    );

    setValue(currentTab);
  }, [searchInfo, setSearchParams, setValue, value]);

  const handleNext = (
    selectedItem: string = "",
    extraParams: Record<string, string> = {},
  ) => {
    const newURLSearchParams = new URLSearchParams({
      ...searchInfo,
      step: stepsRef.current[value + 1],
      ...(stepName === "Choose Use Case" && {
        selectedUseCase: selectedItem,
        origin: "Blueprint",
      }),
      ...(stepName === "Choose Blueprint" && {
        blueprintPath: selectedItem,
      }),
      ...extraParams,
    });

    setSearchParams(newURLSearchParams);

    setValue((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setSearchParams(
      new URLSearchParams({
        ...searchInfo,
        step: stepsRef.current[value - 1],
      }),
    );

    setValue((prevStep) => prevStep - 1);
  };

  return {
    handleNext,
    handleBack,
    value,
  };
};

export declare type SearchInfo = {
  step: string;
  selectedUseCase: string;
  blueprintName: string;
  blueprintPath: string;
  blueprintVersion: string;
  policyID: string;
  origin: "Custom" | "Blueprint";
  name: string;
  editedAt: string;
  status: AdaptedPoliciesGroupData["status"];
};

export const useCreatePolicySearchParams = () => {
  const [queryParams, setSearchParams] = useSearchParams();
  const searchInfo: SearchInfo = useMemo(
    () => ({
      step: queryParams.get("step") || "",
      selectedUseCase: queryParams.get("selectedUseCase") || "",
      blueprintName: queryParams.get("blueprintName") || "",
      policyID: queryParams.get("policyID") || "",
      origin:
        (queryParams.get("origin") as unknown as "Custom" | "Blueprint") || "",
      name: queryParams.get("name") || "",
      blueprintPath: queryParams.get("blueprintPath") || "",
      blueprintVersion: queryParams.get("blueprintVersion") || "",
      editedAt: queryParams.get("editedAt") || "",
      status:
        (queryParams.get("status") as AdaptedPoliciesGroupData["status"]) || "",
    }),
    [queryParams],
  );

  return {
    searchInfo,
    setSearchParams,
    queryParams,
    search: queryParams,
  };
};
