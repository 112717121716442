import { gql } from "graphql-request";

import type { GetCloudControllerVersionQuery } from "#shared/generated/graphql";
import { useGqlQuery } from "#shared/utils";

import { useProjectContextState } from "#organization/recoil/project";

export const blueprintControllers = gql`
  query GetCloudControllerVersion($where: ControllerBoolExp) {
    controllers(where: $where) {
      edges {
        node {
          id
          name
          version
          commitSha
        }
      }
    }
  }
`;

export const useCloudControllerVersion = () => {
  const { id: projectID } = useProjectContextState();

  const filters = {
    where: {
      projectID: {
        eq: projectID,
      },
      name: {
        eq: "cloud",
      },
    },
  };

  return useGqlQuery<GetCloudControllerVersionQuery>(
    ["controllerVersion", "policyBuilder"],
    blueprintControllers,
    filters,
  );
};
