import { Box } from "@mui/material";
import React, {
  type Dispatch,
  type FC,
  type SetStateAction,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";

import { useLocalStorageFilters } from "#shared/hooks";

import {
  type FilterLabelsType,
  type ChipsType,
  Filter,
  type ModifyFilterQueryResponseData,
} from "./filter";
import { FilteringKeyChooser } from "./filtering-key-chooser";
import { type AndType, FILTER_EMPTY_LABEL, type OrType } from "./types";
import {
  createFiltersSharableLink,
  nestedGetDown,
  parseValueToType,
  template,
  useGetFiltersFromURL,
} from "./utils";

const createFilterVariable = (
  allChips: [ChipsType[]],
  onFilter: Dispatch<SetStateAction<{}>>,
) => {
  const and: AndType[] = [];

  allChips.forEach((chipsArray) => {
    const or: OrType[] = [];

    if (chipsArray.length) {
      chipsArray.forEach((chips) => {
        const splitedKey = chips.filterPath.split(".");

        if (splitedKey.length > 1) {
          const valueTemplate = template(
            splitedKey.at(-1) || "",
            parseValueToType(chips.value, chips.operator),
            chips.operator,
          );
          const lastValue = valueTemplate[splitedKey.at(-1) || ""];
          const nestedOr = JSON.parse(
            nestedGetDown(
              splitedKey.pop(),
              splitedKey,
              "",
              JSON.stringify(lastValue),
            ),
          );

          or.push(nestedOr);
        } else {
          or.push(template(chips.key, chips.value, chips.operator));
        }
      });
      and.push(JSON.parse(`{ "or": ${JSON.stringify(or)} }`));
    }
  });
  const finishFilters = JSON.parse(`{ "and": ${JSON.stringify(and)} }`);
  onFilter(finishFilters);
};

interface ChipsContainerType {
  allChips: [ChipsType[]];
  setAllChips: Dispatch<SetStateAction<[ChipsType[]]>>;
  getNChips: (index: number) => ChipsType[];
  setNChips: (index: number, data: ChipsType[]) => void;
  clearNChips: (index: number) => void;
  selectedKeys: [FilterLabelsType | null];
  setSelectedKeys: Dispatch<SetStateAction<[FilterLabelsType | null]>>;
}

export const useChipsContainer = (
  startingChips?: ChipsType[],
  startingKeys?: FilterLabelsType,
) => {
  const [allChips, setAllChips] = useState<[ChipsType[]]>([[]]);
  const [selectedKeys, setSelectedKeys] = useState<[null | FilterLabelsType]>([
    null,
  ]);

  useEffect(() => {
    if (startingChips) {
      setAllChips([startingChips]);
    }

    if (startingKeys) {
      setSelectedKeys([startingKeys]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNChips = (index: number) => allChips[index];

  const setNChips = (index: number, data: ChipsType[]) => {
    const copy = JSON.parse(JSON.stringify(allChips));
    copy[index] = data;
    setAllChips(copy);
  };

  const clearNChips = (index: number) => {
    const copy = JSON.parse(JSON.stringify(allChips));
    copy[index] = [];
    setAllChips(copy);
  };

  return {
    allChips,
    setAllChips,
    getNChips,
    setNChips,
    clearNChips,
    selectedKeys,
    setSelectedKeys,
  };
};

interface FilteringGroupProps {
  labels: FilterLabelsType[];
  onFilter: Dispatch<SetStateAction<{}>>;
  chipsContainer: ChipsContainerType;
  uniqueId: string;
  modifyFilterQueryResponseData?: ModifyFilterQueryResponseData;
}

export const FilteringGroup: FC<FilteringGroupProps> = ({
  labels,
  onFilter,
  chipsContainer,
  uniqueId,
  modifyFilterQueryResponseData,
}) => {
  const loadedFilters = useGetFiltersFromURL(uniqueId);
  const [, setSearchParams] = useSearchParams();
  const { setLocalStorageFilters } = useLocalStorageFilters();

  useEffect(() => {
    if (loadedFilters.length) {
      const filterContainer: [ChipsType[]] = [[]];
      const selectedKeys: [FilterLabelsType | null] = [null];

      loadedFilters.forEach((filter, index) => {
        const choseLabel =
          labels.find((label) => label.uniqueKey === filter.uniqueKey) ||
          labels.find((label) => label.key === filter.uniqueKey) ||
          FILTER_EMPTY_LABEL;
        const chip: ChipsType[] = [];

        if (
          choseLabel &&
          choseLabel.uniqueKey !== FILTER_EMPTY_LABEL.uniqueKey
        ) {
          filter.values.forEach((value) => {
            chip.push({
              key: choseLabel.key,
              name: choseLabel.name,
              value,
              operator: filter.operator,
              uniqueKey: choseLabel.uniqueKey || choseLabel.key,
              filterPath: choseLabel.filterPath || choseLabel.key,
            });
          });
        }
        filterContainer[index] = chip;
        selectedKeys[index] = choseLabel;
      });
      filterContainer.push([]);
      selectedKeys.push(null);
      chipsContainer.setAllChips(filterContainer);
      chipsContainer.setSelectedKeys(selectedKeys);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applyFilterVariables = () => {
    createFilterVariable(chipsContainer.allChips, onFilter);
    addParamsToURl();
  };

  const addParamsToURl = () => {
    const sharableLink = createFiltersSharableLink(
      chipsContainer.allChips,
      uniqueId,
    );
    const joinedParams = sharableLink.split("?")[1].split("&");
    let parsedParams = {};

    joinedParams.forEach((param) => {
      const [key, value] = param.split("=");

      parsedParams = {
        ...parsedParams,
        [key]: value,
      };
    });
    setLocalStorageFilters(parsedParams);
    setSearchParams(parsedParams);
  };

  const setKeyOnIndex = (index: number, key: FilterLabelsType) => {
    const copy = chipsContainer.selectedKeys;
    copy[index] = key;
    copy.push(null);
    chipsContainer.setSelectedKeys(copy);
  };

  const clearSpecifyFilter = (index: number) => {
    const copyAllChips = chipsContainer.allChips;
    const copySelectedKeys = chipsContainer.selectedKeys;
    copyAllChips.splice(index, 1);
    copySelectedKeys.splice(index, 1);
    applyFilterVariables();
    addParamsToURl();
  };

  return (
    <>
      <FilteringKeyChooser
        labels={labels}
        selectedKeys={chipsContainer.selectedKeys}
        createNewChipsContainer={() =>
          chipsContainer.setNChips(chipsContainer.allChips.length, [])
        }
        onKeySelect={(key) =>
          setKeyOnIndex(chipsContainer.allChips.length, key)
        }
      />
      <Box
        sx={{
          gridArea: "filter",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          alignItems: "center",
          rowGap: 2,
        }}
      >
        {chipsContainer.selectedKeys.map((selectedKey, index) => {
          if (selectedKey !== null) {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Box key={`${selectedKey}${index}`} sx={{ marginRight: 2 }}>
                <Filter
                  selectedKey={selectedKey}
                  allChips={chipsContainer.allChips[index]}
                  setAllChips={(data) => chipsContainer.setNChips(index, data)}
                  clearChips={() => chipsContainer.clearNChips(index)}
                  onConfirm={applyFilterVariables}
                  deleteWholeFilter={() => {
                    clearSpecifyFilter(index);
                  }}
                  modifyFilterQueryResponseData={modifyFilterQueryResponseData}
                />
              </Box>
            );
          }

          return null;
        })}
      </Box>
    </>
  );
};
