import React, { type FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { SignInVerificationEmailPage } from "#shared/components/sign-in";
import { EMAIL_SENT_ROUTE, SIGN_IN_ROUTE } from "#shared/consts";

import { SignInPageFirebase } from "./sign-in";

export const UnauthenticatedPagesFirebase: FC = () => (
  <Routes>
    <Route path="/*">
      <Route path={SIGN_IN_ROUTE.KEY} element={<SignInPageFirebase />} />
      <Route
        path="*"
        element={<Navigate to={`${SIGN_IN_ROUTE.ABSOLUTE_PATH}`} />}
      />
    </Route>
    <Route
      path={EMAIL_SENT_ROUTE.KEY}
      element={<SignInVerificationEmailPage />}
    />
  </Routes>
);
