import { ArrowBack } from "@mui/icons-material";
import {
  type ButtonProps,
  Typography,
  type BoxProps,
  Box,
} from "@mui/material";
import React, { type FC } from "react";
import { useNavigate } from "react-router-dom";

import type { Texts } from "#shared/types";

import { SectionButtons } from "#organization/components/button";

import { SettingsSearch } from "./settings-search";
import { SectionButtonWrapper, SettingsButtonsWrapper } from "./styled";

export interface SettingsButtonsProps {
  onSectionReset?: () => void;
  onResetAll?: () => void;
  onSave?: () => void;
  search?: boolean;
  disabled?: boolean;
  backButton?: boolean;
  backGoTo?: string;
  noButtons?: boolean;
  onSearch?: (searchPhrase: string) => void;
  texts?: Texts<Text>;
  saveButtonProps?: Omit<ButtonProps, "onClick" | "children">;
  resetAllButtonProps?: Omit<ButtonProps, "onClick" | "children">;
  sectionResetButtonProps?: Omit<ButtonProps, "onClick" | "children">;
  title?: string;
  portalButtons?: {
    show: boolean;
    boxProps: BoxProps;
  };
}

type Text = "resetSection" | "resetAll" | "saveChanges" | "back";

const enTexts: Required<SettingsButtonsProps["texts"]> = {
  resetSection: "Reset section",
  resetAll: "Reset all",
  saveChanges: "Save changes",
  back: "Back",
};

export const SettingsButtons: FC<SettingsButtonsProps> = ({
  onResetAll,
  onSectionReset,
  onSave,
  disabled,
  search,
  backButton,
  backGoTo,
  noButtons,
  onSearch,
  texts = enTexts,
  saveButtonProps,
  resetAllButtonProps,
  sectionResetButtonProps,
  title,
  portalButtons,
}) => {
  const navigate = useNavigate();

  return (
    <SettingsButtonsWrapper backButton={backButton} search={search}>
      {title && (
        <Typography
          variant="h6"
          fontWeight={700}
          sx={{ gridArea: "page-title" }}
        >
          {title}
        </Typography>
      )}
      {backButton && (
        <SectionButtonWrapper sx={{ gridArea: "back-button" }}>
          <SectionButtons
            onClick={() => {
              navigate(backGoTo || "");
            }}
            size="small"
            variant="outlined"
            startIcon={<ArrowBack />}
          >
            {texts.back}
          </SectionButtons>
        </SectionButtonWrapper>
      )}
      {search && <SettingsSearch onSearch={onSearch} disabled={disabled} />}
      {!noButtons && (
        <>
          {onSectionReset ? (
            <SectionButtonWrapper sx={{ gridArea: "reset-section" }}>
              <SectionButtons
                variant="outlined"
                disabled={disabled}
                {...sectionResetButtonProps}
                onClick={onSectionReset}
              >
                {texts.resetSection}
              </SectionButtons>
            </SectionButtonWrapper>
          ) : null}
          {onResetAll ? (
            <SectionButtonWrapper sx={{ gridArea: "reset-all" }}>
              <SectionButtons
                variant="outlined"
                disabled={disabled}
                {...resetAllButtonProps}
                onClick={onResetAll}
              >
                {texts.resetAll}
              </SectionButtons>
            </SectionButtonWrapper>
          ) : null}
          {onSave ? (
            <SectionButtonWrapper sx={{ gridArea: "save" }}>
              <SectionButtons
                variant="contained"
                disabled={disabled}
                {...saveButtonProps}
                onClick={onSave}
              >
                {texts.saveChanges}
              </SectionButtons>
            </SectionButtonWrapper>
          ) : null}
        </>
      )}
      {portalButtons?.show && (
        <Box
          {...portalButtons?.boxProps}
          sx={{
            ...portalButtons?.boxProps.sx,
            gridArea: "other",
          }}
        />
      )}
    </SettingsButtonsWrapper>
  );
};
