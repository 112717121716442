import { isEqual } from "lodash";
import { useRef, useEffect, useState } from "react";

import { LoggerService } from "#shared/services/logger";

import type {
  ResourceWithDashboard,
  ResourceWithGenericDashboard,
} from "#organization/pages/types";

import {
  CIRCUIT_VIEW_RESOURCES,
  RESOURCES_VIEW_RESOURCES,
  type CircuitViewResource,
  type ResourcesViewResource,
} from "./consts";
import type { SelectedResourcesContextValue } from "./context";

import {
  DEFAULT_DASHBOARDS,
  type ResourceView,
} from "../../routes.definitions";

export type GeneratedQueryParams = {
  resourceType: ResourceWithGenericDashboard;
  resourceView: ResourceView;
  resourceId: string;
  dashboardType:
    | "FLOW_ANALYTICS"
    | "FLOW_MAP"
    | "PROMETHEUS"
    | "SIGNAL"
    | "RATE_LIMITER"
    | "CONCURRENCY_LIMITER";
};

export function useSelectedResourceParams(
  selectedResource: SelectedResourcesContextValue["selectedResource"],
) {
  const prevSelectedResourceRef = useRef<
    null | SelectedResourcesContextValue["selectedResource"]
  >(null);

  const [selectedResourceQueryParams, setSelectedResourceQueryParams] =
    useState<GeneratedQueryParams | null>(null);

  useEffect(() => {
    if (!selectedResource?.length) {
      setSelectedResourceQueryParams(null);

      return;
    }

    const { current: prevSelectedResource } = prevSelectedResourceRef;
    prevSelectedResourceRef.current = selectedResource;

    if (
      prevSelectedResource &&
      selectedResource &&
      isEqual(selectedResource, prevSelectedResource)
    ) {
      LoggerService.verbose("[FN] Selected resource did not change.");

      return;
    }

    const [type = null, resources] = selectedResource || [];

    if (!type) {
      LoggerService.verbose("[FN] Selected resource did not change.");

      return;
    }

    const dashboardType = type ? DEFAULT_DASHBOARDS[type] : null;

    if (!dashboardType || !selectedResource || !resources) {
      return;
    }

    const resourceView = getResourceViewByResourceType(
      (selectedResource || [])[0],
    );

    if (!resourceView) {
      return;
    }

    const generateQueryParams = {
      resourceType: type,
      resourceView,
      resourceId: resources
        .map(({ uiData: { id } }) => encodeURIComponent(id))
        .join("&"),
      dashboardType,
    };

    setSelectedResourceQueryParams(generateQueryParams as GeneratedQueryParams);
  }, [selectedResource]);

  return selectedResourceQueryParams;
}

export function getResourceViewByResourceType(
  resourceType: ResourceWithDashboard | undefined,
): ResourceView | null {
  if (!resourceType) {
    return null;
  }

  if (
    RESOURCES_VIEW_RESOURCES.includes(resourceType as ResourcesViewResource)
  ) {
    return "dashboards";
  }

  if (CIRCUIT_VIEW_RESOURCES.includes(resourceType as CircuitViewResource)) {
    return "circuit";
  }

  return null;
}
