import type { LoadableApp, loadMicroApp } from "qiankun";
import { createContext, type Dispatch, useContext } from "react";

import type { AnyObject } from "#shared/types";

import type { DashboardConfig } from "#organization/pages/types";

import type { GrafanaProps, GrafanaUtils } from "./utils";

export declare type GrafanaPortal = {
  uid: string;
  version?: number;
  slug: string;
  queryParams: AnyObject;
  hiddenVariables?: DashboardConfig["hiddenVariables"] | string[];
  controlsContainer?: string | null;
};

export declare type GrafanaContextProps = {
  grafanaProvider:
    | (LoadableApp<Partial<GrafanaProps>> & {
        container: HTMLElement | null;
        configuration?: Parameters<typeof loadMicroApp>[1];
      })
    | null;
  grafanaPortal: GrafanaPortal;
  grafanaError: Record<string, unknown> | string | null | unknown;
  action: Dispatch<Action> | null;
  grafanaUtils: GrafanaUtils | null;
  isGrafanaBroken: boolean;
};

export const grafanaContextInitial: GrafanaContextProps = {
  grafanaProvider: null,
  grafanaPortal: {
    uid: "",
    version: undefined,
    slug: "",
    queryParams: {},
    hiddenVariables: undefined,
    controlsContainer: null,
  },
  grafanaError: null,
  action: null,
  grafanaUtils: null,
  isGrafanaBroken: false,
};

export const GrafanaContext = createContext<GrafanaContextProps>(
  grafanaContextInitial,
);
export const useGrafanaContext = () => useContext(GrafanaContext);

export type ContextAction =
  | "SET_GRAFANA_PROVIDER"
  | "SET_GRAFANA_PORTAL"
  | "SET_GRAFANA_ERROR";

type Action =
  | {
      type: "SET_GRAFANA_ERROR";
      payload: GrafanaContextProps["grafanaError"];
    }
  | {
      type: "SET_GRAFANA_PROVIDER";
      payload: GrafanaContextProps["grafanaProvider"];
    }
  | {
      type: "SET_GRAFANA_PORTAL";
      payload: GrafanaContextProps["grafanaPortal"];
    }
  | {
      type: "RESET_GRAFANA_PORTAL";
    }
  | {
      type: "SET_GRAFANA_UTILS";
      payload: GrafanaContextProps["grafanaUtils"];
    }
  | {
      type: "SET_GRAFANA_BROKEN";
      payload: GrafanaContextProps["isGrafanaBroken"];
    };

export declare type GrafanaReducer = (
  state: GrafanaContextProps,
  action: Action,
) => GrafanaContextProps;

export const grafanaReducer: GrafanaReducer = (state, action) => {
  switch (action.type) {
    case "SET_GRAFANA_PROVIDER":
      return {
        ...state,
        grafanaProvider: action.payload,
      };
    case "SET_GRAFANA_PORTAL":
      return {
        ...state,
        grafanaPortal: action.payload,
      };
    case "SET_GRAFANA_ERROR":
      return {
        ...state,
        grafanaError: action.payload,
      };
    case "RESET_GRAFANA_PORTAL":
      return {
        ...state,
        grafanaPortal: grafanaContextInitial.grafanaPortal,
      };
    case "SET_GRAFANA_UTILS":
      return {
        ...state,
        grafanaUtils: action.payload,
      };
    case "SET_GRAFANA_BROKEN":
      return {
        ...state,
        isGrafanaBroken: action.payload,
      };
    default:
      return state;
  }
};
