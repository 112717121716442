import * as Sentry from "@sentry/react";
import React, { type FC } from "react";
import { Routes, Route } from "react-router-dom";

import { LoaderLayout } from "#shared/components/layouts/loader";
import { useCheckAuthn } from "#shared/recoil";

import { AuthenticatedPages } from "./authenticated";
import { UnauthenticatedPagesFirebase } from "./unauthenticated";

/**
 * NOTE:
 *
 * Routes pages that:
 * - do not trigger the authentication (e.g. 'sign-up'),
 * - trigger the authentication (GlobalAppPages).
 */
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
export const GlobalAppRoutes: FC = () => (
  <SentryRoutes>
    <Route path="/sign-up/*" element={<Firebase />} />
    <Route path="/*" element={<Firebase />} />
  </SentryRoutes>
);

const Firebase: FC = () => {
  const { isLoading, isAuthenticatedWithFn } = useCheckAuthn();

  if (isLoading) {
    return (
      <LoaderLayout outerContainerProps={{ height: "100vh", width: "100vw" }} />
    );
  }

  return isAuthenticatedWithFn ? (
    <AuthenticatedPages />
  ) : (
    <UnauthenticatedPagesFirebase />
  );
};
