import type { Theme } from "@mui/system";
import React, { type FC, useRef } from "react";

import { OnOverflowTooltip } from "#organization/components/custom-tooltip";

import { LabelBoxCapsule, LabelBoxWrapper, LabelText } from "./styled";
import type { AlertsLogType } from "./types";

export type CreateKeyType = (
  name: string,
  index: number,
  joinBy?: string,
) => string;

const CREATE_KEY: CreateKeyType = (name, index, joinBy = "-") =>
  [name, index].join(joinBy);
export interface LabelsProps {
  labels: AlertsLogType["labels"] | Record<string, string>;
}

export const Labels: FC<LabelsProps> = ({ labels }) => (
  <LabelBoxWrapper>
    {Object.keys(labels).map((key, index) => (
      <LabelWithToolTip
        labelKey={key}
        label={labels[key]}
        key={CREATE_KEY(key, index, "**")}
      />
    ))}
  </LabelBoxWrapper>
);

export interface LabelWithToolTipProps {
  labelKey: string;
  label: string;
}

export const LabelWithToolTip: FC<LabelWithToolTipProps> = ({
  labelKey,
  label,
}) => {
  const passedRef = useRef<HTMLDivElement>(null);

  return (
    <OnOverflowTooltip
      passedRef={passedRef}
      title={
        <>
          {`${labelKey}: `}
          {label}
        </>
      }
      typographyProps={{
        sx: (theme: Theme) => ({
          color: theme.palette.text.primary,
          fontSize: 12,
          fontWight: 500,
        }),
      }}
    >
      <LabelBoxCapsule
        size="small"
        label={
          <LabelText ref={passedRef}>
            <span>{labelKey}:</span>
            {label}
          </LabelText>
        }
      />
    </OnOverflowTooltip>
  );
};
