import React, { type FC } from "react";
import { Route, Routes } from "react-router-dom";

import { BreadcrumbLink } from "#shared/components/breadcrumbs";

import { TopBarBreadcrumbAppender } from "#organization/recoil/breadcrumbs";

import { AuthnPage } from "./page";
import { AUTHN_SETTINGS_ROUTE } from "./routes.definitions";

export const AuthnSettingsRoutes: FC = () => (
  <>
    <TopBarBreadcrumbAppender>
      <BreadcrumbLink to={AUTHN_SETTINGS_ROUTE.ABSOLUTE_PATH}>
        {AUTHN_SETTINGS_ROUTE.TITLE}
      </BreadcrumbLink>
    </TopBarBreadcrumbAppender>

    <Routes>
      <Route index element={<AuthnPage />} />
    </Routes>
  </>
);
