import { Button } from "@mui/material";
import { Box } from "@mui/system";
import React, {
  type FC,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useQueryClient } from "react-query";

import { Analytics } from "#shared/analytics";
import { useControlDialog } from "#shared/components/layouts/dialog";

import { QUERY_KEYS } from "#organization/pages/consts";

import { ContactSupportDialog } from "./components";
import {
  useGetDefaultUserGroup,
  useInvite,
  type Invitation,
  useCreatorView,
  type UseCreatorViewOptions,
} from "./hooks";
import {
  ActionsDivider,
  ActionsInnerContainer,
  ActionsOuterContainer,
  InnerContainer,
  OuterContainer,
  ToolbarOuterContainer,
  ToolbarDivider,
  ViewOuterContainer,
  ToolbarInnerContainer,
  ViewInnerContainer,
} from "./styled";
import {
  CreatorView,
  SummaryView,
  WelcomePageToolbar,
  CreatorViewActions,
  SummaryViewActions,
} from "./views";

export interface WelcomePageProps {}

const EMPTY_INVITE: Omit<Invitation, "userGroup"> = {
  userRole: 1,
  email: "",
  isValid: true,
  id: "0",
};

export const WelcomePage: FC<WelcomePageProps> = () => {
  const contactSupportDialogControls = useControlDialog();

  const defaultUserGroup = useGetDefaultUserGroup();

  const userGroupId = useMemo(
    () => defaultUserGroup.data?.id || "",
    [defaultUserGroup.data?.id],
  );

  const emptyMember = useMemo<Invitation>(
    () => ({ ...EMPTY_INVITE, userGroup: userGroupId }),
    [userGroupId],
  );

  const invite = useInvite({
    userGroupId: defaultUserGroup.data?.id || null,
    emptyMember,
  });

  const [completed, setCompleted] = useState(false);

  const queryClient = useQueryClient();

  const onResult = useCallback<UseCreatorViewOptions["onResult"]>(
    async (_, isSuccess) => {
      if (isSuccess) {
        queryClient.removeQueries([QUERY_KEYS.WELCOME_AGENTS_COUNT]);
        queryClient.removeQueries([QUERY_KEYS.WELCOME_MEMBERS_QUERY_KEY]);
        queryClient.removeQueries([QUERY_KEYS.WELCOME_DEFAULT_PROJECT]);
        queryClient.removeQueries([QUERY_KEYS.WELCOME_USER_GROUP]);
      }

      setCompleted(isSuccess);
    },
    [queryClient],
  );

  const creator = useCreatorView({
    onResult,
    emptyMember,
    invite,
  });

  useEffect(() => {
    if (!completed) return;

    Analytics.track("onboarding_completed");
  }, [completed]);

  return (
    <>
      <OuterContainer>
        <InnerContainer>
          <ViewOuterContainer>
            <ToolbarOuterContainer>
              <ToolbarInnerContainer>
                <WelcomePageToolbar />
              </ToolbarInnerContainer>
            </ToolbarOuterContainer>
            <ToolbarDivider />
            <ViewInnerContainer elevation={0}>
              {completed ? (
                <SummaryView
                  {...{
                    invite,
                    creator,
                  }}
                />
              ) : (
                <CreatorView
                  {...{
                    onResult,
                    invite,
                    emptyMember,
                    creator,
                  }}
                />
              )}
            </ViewInnerContainer>
            <ActionsDivider />
            <ActionsOuterContainer>
              <ActionsInnerContainer>
                <Button
                  variant="text"
                  color="secondary"
                  onClick={contactSupportDialogControls.open}
                >
                  Need help?
                </Button>
                {!completed && (
                  <Button
                    variant="text"
                    color="inherit"
                    onClick={() => creator.onNext(true)}
                  >
                    Skip with defaults
                  </Button>
                )}
                <Box flex={1} />
                <Box>
                  {completed ? (
                    <SummaryViewActions onBack={() => setCompleted(false)} />
                  ) : (
                    <CreatorViewActions {...{ invite, creator }} />
                  )}
                </Box>
              </ActionsInnerContainer>
            </ActionsOuterContainer>
          </ViewOuterContainer>
        </InnerContainer>
      </OuterContainer>
      <ContactSupportDialog
        open={contactSupportDialogControls.isOpen}
        onClose={contactSupportDialogControls.close}
      />
    </>
  );
};
