import { gql } from "graphql-request";

import type { GetUserGroupsQuery } from "#shared/generated/graphql";

import { type DataGridHook, useGridData } from "#organization/hooks";

export const UserGroupsQuery = gql`
  query GetUserGroups(
    $where: UserGroupBoolExp
    $first: Int
    $last: Int
    $after: String
    $before: String
    $orderBy: [UserGroupOrderBy]
  ) {
    userGroups(
      where: $where
      first: $first
      last: $last
      after: $after
      before: $before
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          name
          userGroupMemberships {
            totalCount
            edges {
              node {
                user {
                  id
                }
              }
            }
          }
          userGroupProjectAssignments {
            totalCount
            edges {
              node {
                project {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

type UserGroup = GetUserGroupsQuery["userGroups"]["edges"][0]["node"];

type AdaptedUserGroup = {
  id: string;
  userGroupName: string;
  noOfUsers: number;
  noOfProjects: number;
};

export const useUserGroupsQuery: DataGridHook = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  enabled,
  filterVariables,
) =>
  useGridData<UserGroup, AdaptedUserGroup>(
    "userGroups",
    UserGroupsQuery,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    filterVariables,
    (node) => ({
      id: node.id,
      userGroupName: node.name,
      noOfUsers: node.userGroupMemberships.totalCount,
      noOfProjects: node.userGroupProjectAssignments.totalCount,
    }),
    enabled,
    false,
  );
