import type { Texts } from "#shared/types";

import type { dashboardsByResourceType } from "#organization/pages/authenticated/dashboard";

export type PolicyResourceType = Extract<
  keyof typeof dashboardsByResourceType,
  "FluxMeter" | "Classifier" | "ConcurrencyLimiter" | "RateLimiter" | "Signal"
>;

export const policyResourceEnTexts: Texts<PolicyResourceType> = {
  FluxMeter: "FluxMeter",
  Classifier: "Classifier",
  ConcurrencyLimiter: "Concurrency Limiter",
  RateLimiter: "Rate Limiter",
  Signal: "Signal",
};
