import { Box, Button, ButtonGroup } from "@mui/material";
import React, { type FC } from "react";

import type { Texts } from "#shared/types";

import { FieldWithTitle } from "../styled";

export interface BoolFieldProps {
  onChange: (value: boolean) => void;
  label?: string;
  value?: boolean;
  fieldAddress?: string;
  texts?: Texts<"true" | "false">;
}

export const BoolField: FC<BoolFieldProps> = ({
  onChange,
  value,
  label = "",
  fieldAddress = "",
  texts = {
    true: "True",
    false: "False",
  },
}) => (
  <Box>
    <FieldWithTitle label={label} fieldAddress={fieldAddress}>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
        sx={{ width: "60%" }}
      >
        <Button
          onClick={() => onChange(true)}
          variant={value ? "contained" : "outlined"}
          fullWidth
        >
          {texts.true}
        </Button>
        <Button
          onClick={() => onChange(false)}
          variant={!value ? "contained" : "outlined"}
          fullWidth
        >
          {texts.false}
        </Button>
      </ButtonGroup>
    </FieldWithTitle>
  </Box>
);
