import {
  buttonClasses,
  type ThemeOptions,
  typographyClasses,
} from "@mui/material";

const styleOverrides: Required<
  Required<ThemeOptions>["components"]
>["MuiBreadcrumbs"]["styleOverrides"] = {
  root: {
    fontSize: "12px",
    lineHeight: "18px",
  },
  ol: {
    fontSize: "inherit",
    lineHeight: "inherit",
    [`.${typographyClasses.root}`]: {
      fontSize: "inherit",
      lineHeight: "inherit",
    },
  },
  li: ({ theme }) => ({
    fontSize: "inherit",
    lineHeight: "inherit",
    color: theme.palette.text.secondary,
    [`.${typographyClasses.root}`]: {
      fontSize: "inherit",
      lineHeight: "inherit",
    },
    [`.${buttonClasses.text}`]: {
      padding: 0,
      fontSize: "inherit",
      letterSpacing: "inherit",
      minWidth: 0,
      "&:hover": {
        backgroundColor: "initial",
        textDecoration: "underline",
      },
    },
    ":last-of-type": {
      color: theme.palette.text.primary,
    },
  }),
  separator: {
    fontSize: "inherit",
    lineHeight: "inherit",
  },
};

export const MuiBreadcrumbs: Required<
  Required<ThemeOptions>["components"]
>["MuiBreadcrumbs"] = { styleOverrides };
