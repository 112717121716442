import { cloneDeep } from "lodash";
import React, {
  type FC,
  useCallback,
  useMemo,
  useState,
  createContext,
  type PropsWithChildren,
  useEffect,
} from "react";
import { useRecoilValue } from "recoil";

import { userEmailState } from "#shared/recoil";
import { LoggerService } from "#shared/services";
import type { Organization } from "#shared/types";
import { httpClient } from "#shared/utils/http-client";

import { useUserGroupPermissions } from "#organization/hooks";
import { API_URLS } from "#organization/pages/consts";

interface HttpRequestContext<Result> {
  isSuccess: boolean;
  error: null | string;
  isLoading: boolean;
  isIdle: boolean;
  result: Result;
  fetch: () => Promise<Result>;
  reset: () => void;
}

const initialValue: HttpRequestContext<null> = {
  isSuccess: false,
  isIdle: true,
  isLoading: false,
  error: null,
  result: null,
  fetch: () => Promise.resolve(null),
  reset: () => {},
};

export const OrganizationsContext = createContext<
  HttpRequestContext<Organization[] | null>
>(cloneDeep(initialValue));

export const OrganizationsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isIdle, setIsIdle] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [result, setResult] = useState<Organization[] | null>(null);

  const email = useRecoilValue(userEmailState);

  // update permissions in global user context.
  const { updateGlobalPermissions } = useUserGroupPermissions();

  useEffect(() => {
    updateGlobalPermissions();
  }, [updateGlobalPermissions]);

  const reset = useCallback(() => {
    setIsSuccess(false);
    setIsLoading(false);
    setResult(null);

    setIsIdle(true);
  }, []);

  const url = useMemo(() => {
    if (!email) {
      LoggerService.debug("Missing email in organizations provider.");

      return null;
    }

    const organizationsUrl = new URL(API_URLS.ORGANIZATIONS.INDEX);
    organizationsUrl.searchParams.append("email", email);

    return organizationsUrl.toString();
  }, [email]);

  const fetch = useCallback(async () => {
    setIsSuccess(false);
    setIsLoading(true);
    setResult(null);

    setIsIdle(false);

    try {
      if (!url) {
        throw new Error("Missing url.");
      }

      const response = await httpClient.get<{ data: Organization[] }, true>({
        url,
        shouldParse: true,
      });

      setIsLoading(false);
      setIsSuccess(true);
      setResult(response.data || null);

      return response.data || null;
    } catch (err) {
      setIsLoading(false);
      setError(err instanceof Error ? err.message : String(err));

      return null;
    }
  }, [url]);

  const value = useMemo<HttpRequestContext<Organization[] | null>>(
    () => ({
      fetch,
      isSuccess,
      isLoading,
      error,
      isIdle,
      result,
      reset,
    }),
    [fetch, isSuccess, isLoading, error, isIdle, result, reset],
  );

  return (
    <OrganizationsContext.Provider value={value}>
      {children}
    </OrganizationsContext.Provider>
  );
};
