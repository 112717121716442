import {
  type BoxProps,
  type PopoverProps,
  Popover,
  useTheme,
} from "@mui/material";
import { isFunction, pick } from "lodash";
import React, {
  type Dispatch,
  type FC,
  type SetStateAction,
  useRef,
  useMemo,
  type CSSProperties,
  useCallback,
  forwardRef,
  type ReactNode,
} from "react";
import { Rnd } from "react-rnd";

import { TOP_BAR_ID } from "#organization/consts";

import { CloseButton, type CloseButtonProps } from "./close-button";
import { FlyoutSlide } from "./flyout-slide";
import {
  FullScreenModeButton,
  type FullScreenModeButtonProps,
} from "./full-screen-mode-button";
import { useFlyoutMenuSize, type Size } from "./hooks";
import { ControlBar, InnerContainer, OuterContainer } from "./styled";

type PassToChildrenProps = {
  size: Size;
};

export type FlyoutMenuProps = {
  useShowHide: [boolean, Dispatch<SetStateAction<boolean>>];
  outerContainerProps?: Omit<PopoverProps, "children" | "open">;
  innerContainerProps?: Omit<BoxProps, "children">;
  controlBarProps?: Omit<BoxProps, "children">;
  fullScreenModeButtonProps?: FullScreenModeButtonProps;
  closeButtonProps?: Omit<CloseButtonProps, "useShowHide">;
  isFullScreenModeAllowed?: boolean;
  children?:
    | ReactNode
    | ((pass: PassToChildrenProps) => ReactNode | null | JSX.Element);
  topPosition?: string;
  fixedHeight?: string;
  hideActionButtons?: boolean;
};

export const FlyoutMenu: FC<FlyoutMenuProps> = forwardRef<
  HTMLDivElement,
  FlyoutMenuProps
>((props, ref) => {
  const {
    useShowHide,
    outerContainerProps,
    innerContainerProps,
    children,
    controlBarProps,
    fullScreenModeButtonProps,
    closeButtonProps,
    isFullScreenModeAllowed = true,
    topPosition,
    fixedHeight,
    hideActionButtons,
  } = props;

  const rndRef = useRef<Rnd | null>(null);
  const topBarRef = useRef(document.getElementById(TOP_BAR_ID));

  const topBarHeight = useMemo(
    () => topBarRef.current?.getBoundingClientRect().height || 60,
    [],
  );

  const [open] = useShowHide;

  const { onResize, size, anchorEl, setIsFullScreenMode, isFullScreenMode } =
    useFlyoutMenuSize(open);

  /**
   * NOTE:
   *
   * Reset the isFullScreenMode and the size on close.
   * (wait until animation ends).
   */
  const resetOnClose = useCallback<
    Required<Required<PopoverProps>["TransitionProps"]>["addEndListener"]
  >(() => {
    if (!anchorEl) {
      setIsFullScreenMode(false);
    }
  }, [anchorEl, setIsFullScreenMode]);

  const theme = useTheme();

  const popoverStyle: CSSProperties = {
    position: "fixed",
    left: "auto",
    right: 0,
    top: topPosition || (isFullScreenMode ? 0 : topBarHeight),
    overflow: "hidden",
    boxShadow:
      theme.palette.mode === "light"
        ? "-5px 0px 10px 0px rgba(0,0,0,0.1), 5px 0px 10px 0px transparent, 0px 5px 10px 0px transparent, 0px -5px 10px 0px transparent"
        : "-5px 0px 10px 0px rgba(0,0,0,0.5), 5px 0px 10px 0px transparent, 0px 5px 10px 0px transparent, 0px -5px 10px 0px transparent",
    ...pick(size, "maxHeight", "height", "width"),
    ...(fixedHeight ? { height: fixedHeight } : {}),
  };

  // NOTE: the popover backdrop should not hide the app
  const backdropStyle: CSSProperties = { height: 0, width: 0 };

  const paperStyle: CSSProperties = {
    overflow: "hidden",
    backgroundColor: "transparent",
    maxWidth: "100%",
    minHeight: "100%",
    top: 0,
    ...size,
    ...(outerContainerProps?.PaperProps?.style || {}),
  };

  return (
    <Popover
      {...{
        ref,
        open: Boolean(anchorEl),
        anchorEl,
        anchorReference: "anchorPosition",
        anchorPosition: {
          top: 0,
          left: 0,
        },
        marginThreshold: 0,
        transformOrigin: { horizontal: "right", vertical: "top" },
        TransitionComponent: FlyoutSlide,
        TransitionProps: {
          mountOnEnter: true,
          unmountOnExit: true,
          addEndListener: resetOnClose,
        },
        BackdropProps: {
          invisible: true,
          style: backdropStyle,
        },
        PaperProps: {
          ...(outerContainerProps?.PaperProps || {}),
          style: paperStyle,
        },
        style: popoverStyle,
      }}
    >
      <Rnd
        {...{
          onResize,
          disableDragging: true,
          enableResizing: {
            left: !isFullScreenMode,
          },
          position: { x: NaN, y: NaN }, // This fixes a bug where the Rnd component would not resize correctly
          ref: rndRef,
          size: pick(size, "height", "width"),
          minWidth: size.minWidth,
          lockAspectRatio: true,
          dragAxis: "none",
          resizeGrid: [5, 5],
        }}
      >
        <OuterContainer style={size}>
          {!hideActionButtons && (
            <ControlBar {...controlBarProps}>
              {isFullScreenModeAllowed && (
                <FullScreenModeButton
                  useFullScreen={[isFullScreenMode, setIsFullScreenMode]}
                  {...fullScreenModeButtonProps}
                />
              )}
              <CloseButton {...{ useShowHide, ...closeButtonProps }} />
            </ControlBar>
          )}
          <InnerContainer {...innerContainerProps}>
            {isFunction(children)
              ? children({
                  size,
                })
              : children}
          </InnerContainer>
        </OuterContainer>
      </Rnd>
    </Popover>
  );
});
