import { CodeBlockWithCopyButton, CodeSnippet } from "#organization/components";

export const language = "go";

<a href={`https://pkg.go.dev/github.com/fluxninja/aperture-go/v2`}>Aperture Go
SDK</a> can be used to manually set feature control points within a Go service.

<CodeBlockWithCopyButton language="shell" code={` go get github.com/fluxninja/aperture-go/v2 `} />

To do so, first create an instance of ApertureClient:

<CodeSnippet lang={language} snippetName="clientConstructor" />

The created instance can then be used to start a flow:

<CodeSnippet lang={language} snippetName="startFlow" />

For more context on using Aperture Go SDK to set feature control points, refer
to the [example app][example] available in the repository.

## HTTP Middleware

You can also automatically set middleware for your HTTP server using the SDK. To
do so, after creating an instance of ApertureClient, use the middleware on your
router:

<CodeSnippet lang={language} snippetName="middleware" />

For simplicity, you can also pass a list of regexp patterns to match against the
request path, for which the middleware will pass through. This is especially
useful for endpoints like `/healthz`:

<CodeBlockWithCopyButton language={language} code={`
  mux.Use(aperturemiddlewares.NewHTTPMiddleware(
    apertureClient,
    "awesomeFeature",
    labels,
    []regexp.Regexp{regexp.MustCompile("/health.*")},
    false,
    200 * time.Millisecond).Handle
  )`} />

[example]: https://github.com/fluxninja/aperture-go/tree/main/example
[api-keys]: /reference/cloud-ui/api-keys.md
