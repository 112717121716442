import { Box, Divider, Stack } from "@mui/material";
import { styled } from "@mui/system";
import React, { type FC, type ReactElement } from "react";

interface PageLayoutProps {
  borderColor: string;
}

export const PageLayout = styled(Box)<PageLayoutProps>((props) => ({
  display: "grid",
  height: "100%",
  width: "100%",
  gridTemplateRows: "33% 1fr",
  gridTemplateColumns: "1fr",
  "& > *:first-of-type": {
    paddingTop: "72px",
    paddingLeft: "54px",
    borderBottom: `solid 1px ${props.borderColor}`,
  },
}));

export const HorizontalHalfLayout = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateRows: "1fr",
  gridTemplateColumns: "1fr 1fr",
  "& > *:first-of-type": {
    borderRight: `solid 1px ${theme.palette.grey[800]}`,
    paddingLeft: "54px",
  },
}));

const ContainerWrapper = styled(Stack)(({ theme }) => ({
  border: "1px solid red",
  padding: theme.spacing(2),
  height: "33%",
}));

interface ServicesGridProps {
  header: ReactElement;
  content: ReactElement;
}

export const PageGridLayout: FC<ServicesGridProps> = ({ header, content }) => (
  <Box flexGrow={1}>
    <ContainerWrapper
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      {header}
      <Divider />
    </ContainerWrapper>
    <Box
      sx={{
        height: "67%",
      }}
    >
      {content}
    </Box>
  </Box>
);
