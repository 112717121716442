import React, { type FC } from "react";
import { Route, Routes } from "react-router-dom";

import { BreadcrumbLink } from "#shared/components/breadcrumbs";

import { TopBarBreadcrumbAppender } from "#organization/recoil/breadcrumbs";

import { UserGroupsPage } from "./page";
import { USER_GROUPS_SETTINGS_ROUTE } from "./routes.definitions";
import { UserGroupPage } from "./user-group";

export const UserGroupsSettingsRoutes: FC = () => (
  <>
    <TopBarBreadcrumbAppender>
      <BreadcrumbLink to={USER_GROUPS_SETTINGS_ROUTE.ABSOLUTE_PATH}>
        {USER_GROUPS_SETTINGS_ROUTE.TITLE}
      </BreadcrumbLink>
    </TopBarBreadcrumbAppender>

    <Routes>
      <Route
        path={USER_GROUPS_SETTINGS_ROUTE.CHILD_PATH}
        element={<UserGroupPage />}
      />

      <Route index element={<UserGroupsPage />} />
    </Routes>
  </>
);
