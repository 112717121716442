import { atom, selector, useRecoilValue, RecoilState } from "recoil";

import { ATOM_KEYS } from "#shared/consts";

export interface OrganizationState {
  name: string;
  subdomain: string;
  id: string;
  onboarded: boolean;
  idpTenantID: string;
}

export const organizationState = atom<OrganizationState | null>({
  key: ATOM_KEYS.organization,
  default: null,
});

export const useOrganizationState = (): OrganizationState => {
  const orgState = useRecoilValue(organizationState);

  if (orgState === null) {
    throw new Error(
      `Cannot use the ${useOrganizationState.name} hook before the org state is loaded`,
    );
  }

  return orgState;
};

export const domainNameSelector = selector({
  key: "domainName",
  get: ({ get }) => {
    try {
      return (
        get(organizationState as RecoilState<OrganizationState>)?.subdomain ||
        null
      );
    } catch {
      return null;
    }
  },
});
