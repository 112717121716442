import { Box, Paper, TextField } from "@mui/material";
import React, { type FC, type PropsWithChildren, useState } from "react";
import { useFormContext, type FieldValues, type Path } from "react-hook-form";

import { LoaderLayout } from "#shared/components/layouts";
import type { Texts } from "#shared/types";

import * as RateLimitBlueprint from "./@types";
import { rateLimitingFormDefaultValues } from "./default-values";
import { rateLimitingDocsTree } from "./docs";

import {
  FieldWithTitle,
  FormFieldWrapper,
  PolicyFormTreeView,
  TreeItemStyled,
} from "../../styled";
import {
  BucketCapacityAndFillAmount,
  ParameterInputs,
  ParametersAdvanced,
  Selectors,
} from "../common";
import { DeniedResponseStatusCodeInput } from "../common/scheduler";
import { createFieldNameWithPrefix } from "../common/utils";
import { FormContextProvider } from "../form-context-provider";
import { useBlueprintJson, useSyncWithEditor } from "../hooks";
import { PolicyFormTemplate } from "../templates";

export type RateLimitingLabelNames = "fill_amount" | "bucket_capacity";

export declare type RateLimitingInputsProps<
  TFields extends FieldValues = RateLimitBlueprint.Coordinate,
> = {
  fieldsPrefix?: Path<TFields>;
  texts?: Texts<RateLimitingLabelNames>;
};

const defaultInputTexts = {
  fill_amount: "Fill amount",
  bucket_capacity: "Bucket capacity",
};

export const RateLimitingInputs = <
  TFields extends FieldValues = RateLimitBlueprint.Coordinate,
>({
  fieldsPrefix = "policy.rate_limiter" as Path<TFields>,
  texts = defaultInputTexts,
}: RateLimitingInputsProps<TFields>) => {
  const { watch, register } = useFormContext<TFields>();

  useSyncWithEditor<TFields>(watch);

  return (
    <TreeItemStyled nodeId="rateLimiter" label="Rate Limiting">
      <BucketCapacityAndFillAmount<TFields>
        {...{
          fieldsPrefix,
          texts,
        }}
      />
      <ParameterInputs />
      <Selectors />
      <ParametersAdvanced>
        <FormFieldWrapper>
          <FieldWithTitle
            label="Tokens label key"
            fieldAddress={createFieldNameWithPrefix<TFields>(
              fieldsPrefix,
              "request_parameters.tokens_label_key",
            )}
          >
            <TextField
              {...{
                ...register(
                  createFieldNameWithPrefix<TFields>(
                    fieldsPrefix,
                    "request_parameters.tokens_label_key",
                  ),
                ),
                placeholder: "Tokens label key",
              }}
            />
          </FieldWithTitle>
          <DeniedResponseStatusCodeInput<TFields>
            fieldsPrefix={createFieldNameWithPrefix(
              fieldsPrefix,
              "request_parameters",
            )}
          />
        </FormFieldWrapper>
      </ParametersAdvanced>
    </TreeItemStyled>
  );
};

export const RateLimitingForm: FC<PropsWithChildren> = ({ children }) => {
  const [isDirty, setIsDirty] = useState(false);
  const { blueprintJson: policy } =
    useBlueprintJson<RateLimitBlueprint.Coordinate>(
      rateLimitingFormDefaultValues,
      isDirty,
    );

  if (!policy) {
    return <LoaderLayout />;
  }

  return (
    <FormContextProvider<RateLimitBlueprint.Coordinate> defaultValues={policy}>
      {(methods) => (
        <PolicyFormTemplate<RateLimitBlueprint.Coordinate>
          {...{
            methods,
            recordIsDirty: [isDirty, setIsDirty],
            docTree: rateLimitingDocsTree,
          }}
        >
          <PolicyFormTreeView
            treeViewProps={{
              defaultExpanded: ["rateLimiter", "selectors"],
            }}
          >
            <Box component={Paper} p={2}>
              <RateLimitingInputs />
            </Box>
          </PolicyFormTreeView>
          {children}
        </PolicyFormTemplate>
      )}
    </FormContextProvider>
  );
};
