import { Typography, type TabsProps } from "@mui/material";
import React, { useCallback, type FC, useState } from "react";

import { BreadcrumbButton } from "#shared/components/breadcrumbs";
import { TabPanel } from "#shared/components/tab-panel";
import type { Texts } from "#shared/types";

import {
  FlyoutMenuContentContainer,
  FlyoutMenuHeaderContainer,
} from "#organization/components/flyout-menu/styled";
import { AlertTable } from "#organization/pages/authenticated/alert-manager/tabs/alert-logs/components";
import { FlyoutMenuBreadcrumbAppender } from "#organization/recoil/breadcrumbs";

import { AgentFlyoutMenuHeader } from "./components";
import { AgentFlyoutMenuTabs } from "./tabs";
import { AlertJobs } from "./tabs/alert-jobs";

import type { AgentsFlyoutMenuConfig } from "../types";

type Text = "aperture" | "agent" | "agents";

interface AgentsFlyoutMenuWrapperProps {
  agent: AgentsFlyoutMenuConfig;
  texts?: Texts<Text>;
  hideFlyoutMenu: () => void;
}

const enTexts: Required<AgentsFlyoutMenuWrapperProps["texts"]> = {
  aperture: "Aperture",
  agents: "Agents",
  agent: "Agent",
};

export const AgentsFlyoutMenuWrapper: FC<AgentsFlyoutMenuWrapperProps> = ({
  agent,
  texts = enTexts,
  hideFlyoutMenu,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const onTabsChange = useCallback<Required<TabsProps>["onChange"]>(
    (_, tabValue: number) => {
      if (tabValue === activeTab) {
        return;
      }
      setActiveTab(tabValue);
    },
    [activeTab],
  );

  return (
    <>
      <FlyoutMenuHeaderContainer
        sx={{
          paddingBottom: 0,
          justifyContent: "space-between",
        }}
      >
        <FlyoutMenuBreadcrumbAppender>
          <BreadcrumbButton onClick={hideFlyoutMenu}>
            {texts.aperture}
          </BreadcrumbButton>
        </FlyoutMenuBreadcrumbAppender>
        <FlyoutMenuBreadcrumbAppender>
          <BreadcrumbButton onClick={hideFlyoutMenu}>
            {texts.agents}
          </BreadcrumbButton>
        </FlyoutMenuBreadcrumbAppender>
        <FlyoutMenuBreadcrumbAppender>
          <Typography>{agent.name}</Typography>
        </FlyoutMenuBreadcrumbAppender>

        <AgentFlyoutMenuHeader agent={agent} />

        <AgentFlyoutMenuTabs {...{ activeTab, onChange: onTabsChange }} />
      </FlyoutMenuHeaderContainer>

      <TabPanel value={0} activeValue={activeTab}>
        <FlyoutMenuContentContainer>
          <AlertTable
            additionalFilterVariables={{
              labels: { contains: { instance: agent.name } },
            }}
          />
        </FlyoutMenuContentContainer>
      </TabPanel>
      <TabPanel value={1} activeValue={activeTab}>
        <FlyoutMenuContentContainer>
          <AlertJobs data={{ type: "agent", id: agent.id }} />
        </FlyoutMenuContentContainer>
      </TabPanel>
    </>
  );
};
