import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { type SubmitHandler } from "react-hook-form";
import { useRecoilValue } from "recoil";

import { useAlert } from "#shared/components/alerts-provider";
import { API_ACCOUNT_URLS } from "#shared/consts";
import { type UseFetch, useFetch } from "#shared/hooks";
import { LoggerService } from "#shared/services";
import {
  type TenantConfigBody,
  type TenantIdpConfigResponse,
} from "#shared/types";

import { organizationState } from "#organization/recoil/organization";

import { type DefaultIdpConfigFormValues } from "../components";
import { type SamlConfigFormValues } from "../components/saml-sign-in-config-form";

export type UseSubmitIdpConfigs = Pick<UseFetch, "httpState" | "reset"> & {
  submit: (value: SubmitConfigsValue) => void;
};

type SubmitConfigCommon = {
  method: "patch" | "post";
  enabled: boolean;
};
export type SubmitConfigsValue = {
  saml?: (SamlConfigFormValues & SubmitConfigCommon) | undefined;
  google?: (DefaultIdpConfigFormValues & SubmitConfigCommon) | undefined;
};

export function useSubmitIdpConfigs(): UseSubmitIdpConfigs {
  const fetch = useFetch<TenantIdpConfigResponse, "post">("post");

  const organization = useRecoilValue(organizationState);

  const { addAlert } = useAlert();

  useEffect(() => {
    if (!fetch.httpState.error) {
      return;
    }

    addAlert({ type: "error", message: "Something went wrong." });
  }, [addAlert, fetch.httpState.error]);

  useEffect(() => {
    if (!fetch.httpState.isSuccess) {
      return;
    }

    const message = [
      ...[fetch.result?.inboundSamlConfigs?.length ? "saml config" : ""].filter(
        Boolean,
      ),
      ...[
        fetch.result?.defaultSupportedIdpConfigs?.length ? "google config" : "",
      ].filter(Boolean),
    ].join("and")
      ? ""
      : "";

    addAlert({
      type: "success",
      message: message
        ? ["Successfully updated", message].join(" ")
        : "Successfully updated tenant configs.",
    });
  }, [addAlert, fetch.httpState.isSuccess, fetch.result]);

  const request = useCallback<SubmitHandler<SubmitConfigsValue>>(
    ({ saml, google }) => {
      const body: TenantConfigBody = {
        ...(google ? { defaultSupportedIdpConfigs: google } : {}),
        ...(saml
          ? {
              inboundSamlConfigs: {
                idpConfig: {
                  ssoUrl: saml.ssoUrl,
                  idpEntityId: saml.entityId,
                  idpCertificates: [{ x509Certificate: saml.certificate }],
                },
                displayName: saml.displayName,
                name: saml.name,
                enabled: saml.enabled,
                method: saml.method,
              },
            }
          : {}),
      };

      if (isEmpty(body)) {
        LoggerService.warn("No saml and no google data to submit");

        return;
      }

      const url = new URL(
        API_ACCOUNT_URLS.TENANTS.CONFIG(organization?.idpTenantID),
      );

      fetch.request({
        url,
        shouldParse: true,
        body,
      });
    },
    [fetch, organization?.idpTenantID],
  );

  return useMemo(
    () => ({
      submit: request,
      httpState: fetch.httpState,
      reset: fetch.reset,
    }),
    [fetch, request],
  );
}
