import { noop, isObject, isString } from "lodash";
import { v4 } from "uuid";

import { LogoutService } from "#shared/services";
import type { AnyObject, StandardResponse } from "#shared/types";

export interface RequestArgs<
  ShouldParse extends True | False = False,
  Body extends AnyObject = AnyObject,
> {
  url: string | URL;
  body?: Body;
  headers?: Headers;
  shouldParse?: ShouldParse;
  /**
   * TODO: rename to requestInit
   */
  options?: Omit<RequestInit, "headers" | "body" | "method">;
  signal?: AbortSignal | null;
}

type RequestArgsWithoutUrl<ShouldParse extends True | False> = Omit<
  RequestArgs<ShouldParse>,
  "url"
>;

export type ReturnFromFetch<
  R extends AnyResponse = Partial<StandardResponse>,
  ShouldParse extends True | False = False,
> = ShouldParse extends True
  ? R
  : ShouldParse extends False
  ? ResponseWithTypedJson<R>
  : never;

export type RequestMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export type False = false;
export type True = true;

export type Headers = Partial<{
  Authorization: string;
  "Content-Type": "application/json";
  "X-Request-ID": string;
}>;

export type XRequestId = typeof X_REQUEST_ID;

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export type ResponseWithTypedJson<R extends any = any> = Omit<
  Response,
  "json"
> & {
  json: () => Promise<R>;
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type AnyResponse = any;

const X_REQUEST_ID = "X-Request-ID";

export const TOKEN_PREFIX = "Bearer";

/**
 * NOTE: Singleton
 */
class HttpClient {
  private static instance: HttpClient;

  public static readonly notAuthenticatedStatusRegexp = /^401$/;

  private static readonly DEFAULT_REQUEST_ARGS: Omit<
    RequestArgs,
    "url" | "shouldParse"
  > = {
    options: {},
    headers: {},
  };

  public onNotAuthenticated: (url: string, res: Response) => void = noop;

  static getInstance = () => {
    if (!HttpClient.instance) {
      HttpClient.instance = new HttpClient();
    }

    return HttpClient.instance;
  };

  constructor() {
    if (!HttpClient.instance) {
      HttpClient.instance = this;
    }

    // eslint-disable-next-line no-constructor-return
    return HttpClient.instance;
  }

  public get<
    R extends AnyResponse = Partial<StandardResponse>,
    ShouldParse extends True | False = False,
  >(reqArgs: RequestArgs<ShouldParse>) {
    return this.fetch<R, ShouldParse>("GET", reqArgs);
  }

  public post<
    R extends AnyResponse = Partial<StandardResponse>,
    ShouldParse extends True | False = False,
    Body extends AnyObject = AnyObject,
  >(reqArgs: RequestArgs<ShouldParse, Body>) {
    return this.fetch<R, ShouldParse, Body>("POST", reqArgs);
  }

  public put<
    R extends AnyResponse = Partial<StandardResponse>,
    ShouldParse extends True | False = False,
    Body extends AnyObject = AnyObject,
  >(reqArgs: RequestArgs<ShouldParse, Body>) {
    return this.fetch<R, ShouldParse, Body>("PUT", reqArgs);
  }

  public patch<
    R extends AnyResponse = Partial<StandardResponse>,
    ShouldParse extends True | False = False,
  >(reqArgs: RequestArgs<ShouldParse>) {
    return this.fetch<R, ShouldParse>("PATCH", reqArgs);
  }

  public delete<
    R extends AnyResponse = Partial<StandardResponse>,
    ShouldParse extends True | False = False,
  >(reqArgs: RequestArgs<ShouldParse>) {
    return this.fetch<R, ShouldParse>("DELETE", reqArgs);
  }

  public static readonly fnRequestsKey = "fn_requests";

  private async fetch<
    R extends AnyResponse = Partial<StandardResponse>,
    ShouldParse extends True | False = False,
    Body extends AnyObject = AnyObject,
  >(method: RequestMethod, requestArgs: RequestArgs<ShouldParse, Body>) {
    const { url, signal, shouldParse } = requestArgs;

    const requestInit = this.getRequestInit(method, requestArgs);

    let res: ResponseWithTypedJson<R> | null = null;

    try {
      res = (await fetch(url, requestInit)) as ResponseWithTypedJson<R>;

      if (signal && signal.aborted) {
        return await Promise.reject(new Error("Aborted"));
      }

      /**
       * NOTE: Logout on 401
       */
      if (
        HttpClient.isNotAuthenticated(res.status) &&
        !(LogoutService.allLogoutUrls as string[]).includes(
          url instanceof URL ? url.toString() : url,
        )
      ) {
        this.onNotAuthenticated(url instanceof URL ? url.toString() : url, res);
      }

      return await HttpClient.returnFromFetch<R, ShouldParse>(res, shouldParse);
    } catch (err) {
      throw err instanceof Error ? err : new Error(String(err));
    }
  }

  private static returnFromFetch = <
    R extends AnyResponse = Partial<StandardResponse>,
    ShouldParse extends True | False = False,
  >(
    res: ResponseWithTypedJson<R>,
    shouldParse?: boolean,
  ): Promise<ReturnFromFetch<R, ShouldParse>> => {
    if (!shouldParse) {
      return Promise.resolve(res) as Promise<ReturnFromFetch<R, ShouldParse>>;
    }

    const result = res.ok
      ? Promise.resolve(res.json() as Promise<R>)
      : Promise.reject(new Error(String(res.status)));

    return result as Promise<ReturnFromFetch<R, ShouldParse>>;
  };

  private static isNotAuthenticated = (status?: Response["status"]): boolean =>
    !!(status && HttpClient.notAuthenticatedStatusRegexp.test(`${status}`));

  private getRequestInit<ShouldParse extends True | False = False>(
    method: RequestMethod,
    requestArgs: RequestArgsWithoutUrl<ShouldParse>,
  ): RequestInit {
    const { headers, options, body, signal } = {
      ...HttpClient.DEFAULT_REQUEST_ARGS,
      ...requestArgs,
    };

    const isBody = method !== "GET" && method !== "DELETE" && isObject(body);

    // NOTE: Use the "Content-Type: application/json" header only if isBody
    const mergedHeaders: RequestInit["headers"] = {
      ...(isBody ? { "Content-Type": "application/json" } : {}),
      ...this.headers,
      ...headers,
    };

    // NOTE: Stringify body only if isBody
    const mergedBody: Partial<Pick<RequestInit, "body">> = isBody
      ? {
          body: JSON.stringify(body),
        }
      : {};

    const requestInit: RequestInit = {
      method,
      ...(signal ? { signal } : {}),
      ...options,
      ...mergedBody,
      headers: mergedHeaders,
    };

    return requestInit;
  }

  /* eslint-disable-next-line  */
  public getTokenWithPrefix(token?: string | null) {
    if (!isString(token)) {
      return undefined;
    }

    return token && !token.trim().startsWith(TOKEN_PREFIX)
      ? [TOKEN_PREFIX, token].join(" ")
      : token || undefined;
  }

  /* eslint-disable-next-line  */
  private get headers(): Omit<Headers, "Content-Type"> {
    const commonHeaders: Omit<Headers, "Content-Type" | "Authorization"> = {
      [X_REQUEST_ID]: v4(),
    };

    return commonHeaders;
  }
}

export const httpClient = HttpClient.getInstance();
