import { ArrowForwardIosSharp } from "@mui/icons-material";
import { Box } from "@mui/material";
import MuiAccordion, { type AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  type AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { find, get } from "lodash";
import React, { type FC, type ReactNode } from "react";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(() => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: theme.palette.text.primary,
    "&.Mui-expanded": {
      transform: "rotate(90deg)",
    },
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(3),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const getGroupByKeyLabel = (groupBys: GroupBysType) => {
  const groupByKey = get(groupBys, "node.props.groupByKey") as
    | string
    | undefined;
  const headCells = get(groupBys, "node.props.headCells") as
    | { key: string; label: string }[]
    | undefined;

  return find(headCells, (headCell) => headCell.key === groupByKey)?.label;
};

interface GroupBysType {
  title: string;
  node: ReactNode;
}

export interface GroupsLayoutProps {
  groupBys: GroupBysType[];
}

export const GroupsLayout: FC<GroupsLayoutProps> = ({ groupBys }) => (
  <Box>
    {groupBys.map((groupBy) => (
      <Accordion key={groupBy.title} defaultExpanded>
        <AccordionSummary
          aria-controls={`${groupBy.title}-content`}
          id={`${groupBy.title}-header`}
        >
          <Typography fontWeight="bold" pr={1}>
            {getGroupByKeyLabel(groupBy)}:
          </Typography>
          <Typography>{groupBy.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{groupBy.node}</AccordionDetails>
      </Accordion>
    ))}
  </Box>
);
