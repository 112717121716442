# Enabled

## Overview

The `enabled` activates the lazy synchronization feature, optimizing the update mechanism of the rate limiter under certain conditions. By default, it is set to false.

## Example

Enabling lazy sync might mean that the token bucket's state is only updated periodically, rather than immediately with each request. This can improve performance under high load at the cost of slight imprecision in rate limiting.

## Note

Enabling lazy synchronization is recommended when the rate limiter needs to handle a high volume of requests efficiently. This feature allows for periodic synchronization of the token bucket's state, reducing computational overhead. It's particularly useful in scenarios where slight deviations from precise rate limiting are acceptable in exchange for improved system performance.
