import type { FnColor, FnTheme } from "../../types";

export const commonPalette: Omit<
  FnTheme["palette"],
  "form" | "codeBlock" | FnColor | "tableGrid" | "filterBar"
> = {
  primary: {
    main: "#8EC4AD",
    light: "#BFDED1",
    dark: "#56AE89",
    text: {
      dark: "#3F8367",
    },
  },
  secondary: {
    main: "#F27A40",
    light: "#FC9A69",
    dark: "#F06929",
  },
  text: {
    primary: "#F0E4B6",
    secondary: "#F0E4B6",
    disabled: "#9DA7B8",
    secondaryMain: "#302E2C",
  },
  grey: {
    200: "rgb(196 196 196 / 16%)",
  },
  action: {
    active: "#F0E4B6",
    hover: "rgba(50, 57, 69, 0.04)",
    selected: "rgba(50, 57, 69, 0.08)",
    disabled: "rgba(50, 57, 69, 0.26)",
    disabledBackground: "rgba(50, 57, 69, 0.12)",
    focus: "rgba(50, 57, 69, 0.12)",
  },
  divider: "rgba(219, 217, 215, 0.12)",
  flyoutMenu: {
    background: "#F6F5F3",
    textColor: "#2D333E",
  },
};
