import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { Box, Button, ButtonGroup, Portal, styled } from "@mui/material";
import { isFunction } from "lodash";
import React, { type FC, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { ErrorLayout, LoaderLayout } from "#shared/components/layouts";
import type { Texts } from "#shared/types";

import type { Size } from "#organization/components/flyout-menu/hooks/use-content-view-port-size";

import { ActionBar, type ActionBarProps } from "./action-bar";

import { convertToJson, useCreatePolicyContext } from "../../context";
import {
  useBlueprintJsonSchema,
  useCreatePolicySearchParams,
} from "../../hooks";
import {
  BlueprintPolicyEditor,
  type BlueprintPolicyEditorProps,
} from "../blueprint-policy-editor";
import {
  PolicyFormBuilder,
  availablePolicyBuilderForms,
  type InBuildFNBlueprintNames,
} from "../form-builder";

export interface CustomizeBlueprintProps {
  blueprintPath: string | null;
  controllerVersion: string;
  actionBarProps: ActionBarProps;
  defaultValues?: string;
  disallowPolicyNames?: string[];
  policyNameInput?: JSX.Element;
  controllerCommitSha: string;
}

export const CustomizeBlueprint: FC<CustomizeBlueprintProps> = ({
  blueprintPath,
  controllerVersion,
  actionBarProps,
  defaultValues,
  disallowPolicyNames,
  policyNameInput,
  controllerCommitSha,
}) => {
  const { pathname } = useLocation();

  const {
    searchInfo: { origin: policyOrigin, blueprintName, policyID, name },
  } = useCreatePolicySearchParams();

  const {
    data: schema,
    isLoading: isLoadingSchema,
    error: errorSchema,
  } = useBlueprintJsonSchema(
    blueprintPath || "",
    controllerVersion,
    controllerCommitSha,
    pathname.includes("edit-policy") || policyID?.length
      ? disallowPolicyNames?.filter((v) => v !== name)
      : disallowPolicyNames,
  );

  const { blueprintYaml, setCreatePolicyCtx, fullScreen } =
    useCreatePolicyContext();

  useEffect(
    () => () => {
      setCreatePolicyCtx((prev) => ({
        ...prev,
        updateException: null,
      }));
    },
    [setCreatePolicyCtx],
  );

  const schemaData = schema?.blueprint;
  const values = schema?.values;

  const isPolicyFormBuilder =
    policyOrigin === "Blueprint" &&
    availablePolicyBuilderForms.includes(
      blueprintName as InBuildFNBlueprintNames,
    );

  const isDefaultValuesNotAllowed = useMemo(
    () => !policyID && !pathname.includes("edit-policy") && defaultValues,
    [defaultValues, pathname, policyID],
  );

  useEffect(() => {
    if (!values || !isPolicyFormBuilder) return;

    setCreatePolicyCtx((prev) => ({
      ...prev,
      blueprintJson: convertToJson(
        !isDefaultValuesNotAllowed ? defaultValues || "" : values,
      ),
      blueprintYaml: !isDefaultValuesNotAllowed
        ? defaultValues || null
        : values,
    }));
  }, [
    values,
    setCreatePolicyCtx,
    isPolicyFormBuilder,
    pathname,
    defaultValues,
    isDefaultValuesNotAllowed,
  ]);

  const yamlEditor = useMemo(
    () => (
      <>
        <BlueprintPolicyEditor
          {...{
            schemaData:
              schemaData as unknown as BlueprintPolicyEditorProps["schemaData"],
            defaultValue: blueprintYaml || defaultValues || values,
            enableAutoTriggerSuggestion: !pathname.includes(
              "create-custom-policy",
            ),
            editorHeight: fullScreen
              ? "calc(100vh - 250px)"
              : "calc(100vh - 400px)",
          }}
        />
        <ActionBar {...actionBarProps} />
      </>
    ),
    [
      actionBarProps,
      blueprintYaml,
      defaultValues,
      fullScreen,
      pathname,
      schemaData,
      values,
    ],
  );

  if (isLoadingSchema) {
    return <LoaderLayout />;
  }

  if (errorSchema) {
    return <ErrorLayout />;
  }

  const formEditor = (
    <PolicyFormBuilder>
      <ActionBar {...actionBarProps} />
    </PolicyFormBuilder>
  );

  const policyBuilder = isPolicyFormBuilder ? (
    <PolicyBuilderWrapper yamlEditor={yamlEditor} formBuilder={formEditor} />
  ) : (
    yamlEditor
  );

  return (
    <CustomizeBlueprintStyled>
      {!isLoadingSchema && !errorSchema && schemaData && (
        <>
          {policyNameInput}
          {policyBuilder}
        </>
      )}
      {!isPolicyFormBuilder && addBreaks(3)}
    </CustomizeBlueprintStyled>
  );
};

export const CustomizeBlueprintStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

export interface PolicyBuilderWrapperProps {
  yamlEditor: JSX.Element;
  formBuilder: JSX.Element;
  texts?: Texts<"builder" | "codeEditor">;
}

export const PolicyBuilderWrapper: FC<PolicyBuilderWrapperProps> = ({
  yamlEditor,
  formBuilder,
  texts = {
    builder: "Builder",
    codeEditor: "YAML",
  },
}) => {
  const {
    setCreatePolicyCtx,
    fullScreen,
    flyoutMenuSize,
    showEditor,
    updateException,
  } = useCreatePolicyContext();

  const setFullScreen = (value: boolean) => {
    setCreatePolicyCtx((prev) => ({
      ...prev,
      fullScreen: value,
    }));
  };

  const setShowEditor = (value: boolean) => {
    setCreatePolicyCtx((prev) => ({
      ...prev,
      showEditor: value,
      ...(isFunction(updateException) && value ? updateException() : {}),
    }));
  };

  const actionButtonGroup = (
    <Box
      {...{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: 2,
        ...(!fullScreen
          ? {}
          : {
              paddingBottom: 2,
              paddingTop: 1,
            }),
      }}
    >
      <ButtonGroup
        variant="outlined"
        aria-label="outlined primary button group"
        size="small"
      >
        <Button
          onClick={() => setShowEditor(false)}
          variant={!showEditor ? "contained" : "outlined"}
        >
          {texts.builder}
        </Button>
        <Button
          onClick={() => setShowEditor(true)}
          variant={showEditor ? "contained" : "outlined"}
        >
          {texts.codeEditor}
        </Button>
      </ButtonGroup>
      {!fullScreen ? (
        <FullscreenIcon
          sx={{ cursor: "pointer" }}
          onClick={() => {
            setFullScreen(true);
          }}
        />
      ) : (
        <FullscreenExitIcon
          sx={{ cursor: "pointer" }}
          onClick={() => {
            setFullScreen(false);
          }}
        />
      )}
    </Box>
  );

  return (
    <SnapToTheTop
      snap={fullScreen}
      fullScreen={fullScreen}
      sx={{ overflowY: showEditor ? "auto" : "hidden" }}
    >
      {!fullScreen ? (
        <Portal container={document.getElementById("header-action-buttons")}>
          {actionButtonGroup}
        </Portal>
      ) : (
        actionButtonGroup
      )}
      {showEditor ? (
        yamlEditor
      ) : (
        <ResizingBox size={flyoutMenuSize} fullScreen={fullScreen}>
          <Box maxWidth={!flyoutMenuSize ? 800 : "auto"}>{formBuilder}</Box>
        </ResizingBox>
      )}
      {showEditor && addBreaks(3)}
    </SnapToTheTop>
  );
};

export const addBreaks = (breaks: number) =>
  Array.from({ length: breaks }).map((v, i) => <br key={[v, i].join("-")} />);

const ResizingBox = styled(Box, {
  shouldForwardProp: (props) => props !== "size" && props !== "fullScreen",
})<{ size: Size | null; fullScreen: boolean }>(
  ({ theme, size, fullScreen }) => ({
    width: `calc(100% - ${size?.width || "0"}px)`,
    overflowY: "auto",
    height: `calc(100vh - ${!fullScreen ? 200 : 150}px)`,
    paddingBottom: !fullScreen ? theme.spacing(5) : 0,
    "::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
  }),
);

const SnapToTheTop = styled(Box, {
  shouldForwardProp: (props) => props !== "snap" && props !== "fullScreen",
})<{ snap: boolean; fullScreen: boolean }>(({ snap, fullScreen, theme }) => ({
  ...(snap
    ? {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 900,
      }
    : {}),
  ...(fullScreen
    ? {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
      }
    : {}),
}));
