export const saveToLS = (key: string, token: string) =>
  window.localStorage.setItem(key, token);

export const deleteFromLS = (key: string) =>
  window.localStorage.removeItem(key);

export const getFromLS = (key: string) =>
  window.localStorage.getItem(key) || "";

export const clearLS = () => window.localStorage.clear();
