import GitHubIcon from "@mui/icons-material/GitHub";
import React, { type FC } from "react";

import {
  SignInWithButton,
  type SignInWithButtonProps,
} from "#shared/components/layouts/sign-in";
import { type Texts } from "#shared/types";

export interface SignInWithGithubButtonProps extends SignInWithButtonProps {
  texts?: Texts<Text>;
}

type Text = "label";

const enTexts: Required<SignInWithGithubButtonProps["texts"]> = {
  label: "Continue with Github",
};

export const SignInWithGithubButton: FC<SignInWithGithubButtonProps> = ({
  texts = enTexts,
  ...props
}) => (
  <SignInWithButton startIcon={<GitHubIcon />} {...props}>
    {texts.label}
  </SignInWithButton>
);
