import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { type FC } from "react";

import { DocumentationButton } from "#shared/components/buttons";
import { NoDataLayout } from "#shared/components/layouts";
import type { Texts } from "#shared/types";

import { BASE_DOCUMENTATION_URL } from "#organization/pages/consts";

type Text = "title" | "message" | "documentationButton";

interface NoKubernetesLayoutProps {
  texts?: Texts<Text>;
}

const enTexts: Required<NoKubernetesLayoutProps["texts"]> = {
  title: "Configure Kubernetes",
  message:
    "In order to use Kubernetes in FluxNinja App, ensure some Agents are installed in Kubernetes environment and Agent's Kubernetes Client is properly configured.",
  documentationButton: "Learn more about configuring Kubernetes",
};

const KUBERNETES_DOCUMENTATION = [
  BASE_DOCUMENTATION_URL,
  "aperture-for-infra/integrations/auto-scale/kubernetes/",
].join("/");

export const NoKubernetesLayout: FC<NoKubernetesLayoutProps> = ({
  texts = enTexts,
}) => (
  <Box px={3} pb={3}>
    <NoDataLayout>
      <Box
        display="flex"
        flexDirection="column"
        rowGap={3}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h4">{texts.title}</Typography>
        <Typography variant="body2" color="secondary.main">
          {texts.message}
        </Typography>
        <DocumentationButton href={KUBERNETES_DOCUMENTATION}>
          {texts.documentationButton}
        </DocumentationButton>
      </Box>
    </NoDataLayout>
  </Box>
);
