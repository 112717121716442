import type { PortalForwardOptions } from "@chargebee/chargebee-js-types";
import {
  CreditCardOffOutlined,
  CreditScoreOutlined,
  DomainOutlined,
} from "@mui/icons-material";
import { Avatar, Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useMemo, type FC } from "react";
import { Link } from "react-router-dom";

import { ErrorLayout, LoaderLayout } from "#shared/components/layouts";
import {
  DialogLayout,
  type DialogControl,
} from "#shared/components/layouts/dialog";
import { DEFAULT_CHARGEBEE_OPEN_PORTAL_OPTIONS } from "#shared/consts";
import type { Texts } from "#shared/types";

import { ChargebeeClient } from "#global/pages/authenticated/billing/hooks";
import { BILLING_ROUTE } from "#global/pages/authenticated/billing/routes.definitions";

import { useIsSubscriptionValid } from "../billing-info-button/hooks";

export declare type BillingInfoDialogProps = {
  texts?: Texts;
  dialogControl: DialogControl;
  id: string;
  hasuraId: string;
  name: string;
};

type Text =
  | "dialogTitle"
  | "noSubscriptions"
  | "createSubscription"
  | "viewSubscriptions"
  | "organization"
  | "activeSubscriptions"
  | "inactiveSubscriptions"
  | "close";

export const enTexts: Texts<Text> = {
  dialogTitle: "Subscriptions",
  noSubscriptions: "It seems you don't have any subscriptions yet",
  createSubscription: "Create subscription",
  organization: "Organization",
  activeSubscriptions: "Active subscriptions",
  inactiveSubscriptions: "Inactive subscriptions",
  viewSubscriptions: "View all subscriptions",
  close: "Close",
};

export const BillingInfoDialog: FC<BillingInfoDialogProps> = ({
  id,
  hasuraId,
  name,
  dialogControl,
  texts = enTexts,
}) => {
  const subscription = useIsSubscriptionValid(id, hasuraId);

  const openBillingPortal = () => {
    ChargebeeClient.getPortalSession(id, hasuraId)?.open(
      DEFAULT_CHARGEBEE_OPEN_PORTAL_OPTIONS,
      undefined as unknown as PortalForwardOptions,
    );
  };

  const activeSubscriptions = useMemo(
    () =>
      subscription.data?.list.filter(
        (sub) =>
          sub.subscription.status === "active" ||
          sub.subscription.status === "in_trial",
      ).length,
    [subscription.data?.list],
  );

  const inactiveSubscriptions = useMemo(
    () =>
      subscription.data?.list.filter(
        (sub) =>
          sub.subscription.status === "not_renewing" ||
          sub.subscription.status === "paused" ||
          sub.subscription.status === "cancelled",
      ).length,
    [subscription.data?.list],
  );

  const trialSubscriptions = useMemo(
    () =>
      subscription.data?.list.filter(
        (sub) => sub.subscription.status === "in_trial",
      ).length,
    [subscription.data?.list],
  );

  const noActiveSubscriptions =
    !subscription.isLoading &&
    !subscription.isError &&
    (!subscription.isValid || !activeSubscriptions);

  const hasSubscriptions =
    !subscription.isLoading && !subscription.isError && subscription.isValid;

  return (
    <DialogLayout
      dialogTitle={texts.dialogTitle}
      onCloseDialog={dialogControl.close}
      openDialog={dialogControl.isOpen}
      onClickDialogClose={dialogControl.close}
      dialogAction={false}
      maxWidth="sm"
    >
      {subscription.isLoading && <LoaderLayout />}

      {!subscription.isLoading && subscription.isError && <ErrorLayout />}

      {!subscription.isLoading && !subscription.isError && (
        <Box display="flex" gap={3} ml={2} flexDirection="column">
          <Box display="flex" gap={2.5} alignItems="center">
            <Avatar sx={{ backgroundColor: "primary.main", padding: "22px" }}>
              <DomainOutlined sx={{ fontSize: "26px" }} />
            </Avatar>
            <Box display="flex" flexDirection="column">
              <Typography sx={{ fontWeight: "bold" }} variant="body2">
                {texts.organization}
              </Typography>
              <Typography variant="body2" color="primary.main">
                {name}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" gap={2.5} alignItems="center">
            <Avatar sx={{ backgroundColor: "primary.main", padding: "22px" }}>
              <CreditScoreOutlined sx={{ fontSize: "26px" }} />
            </Avatar>
            <Box display="flex" flexDirection="column">
              <Typography sx={{ fontWeight: "bold" }} variant="body2">
                {texts.activeSubscriptions}
              </Typography>
              <Typography
                variant="body2"
                color={activeSubscriptions ? "primary.main" : "error.main"}
              >
                {activeSubscriptions || "None"}{" "}
                {trialSubscriptions ? (
                  <>&nbsp;({trialSubscriptions} in trial)</>
                ) : null}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" gap={2.5} alignItems="center">
            <Avatar sx={{ backgroundColor: "primary.main", padding: "22px" }}>
              <CreditCardOffOutlined sx={{ fontSize: "26px" }} />
            </Avatar>
            <Box display="flex" flexDirection="column">
              <Typography sx={{ fontWeight: "bold" }} variant="body2">
                {texts.inactiveSubscriptions}
              </Typography>
              <Typography variant="body2" color="primary.main">
                {inactiveSubscriptions || "None"}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      <Box display="flex" justifyContent="end" gap={1} mt={3}>
        <Button variant="text" color="primary" onClick={dialogControl.close}>
          {texts.close}
        </Button>
        {hasSubscriptions && (
          <Button variant="contained" onClick={openBillingPortal}>
            {texts.viewSubscriptions}
          </Button>
        )}
        {noActiveSubscriptions && (
          <Link to={`${BILLING_ROUTE.ABSOLUTE_PATH}${id}`}>
            <Button variant="contained">{texts.createSubscription}</Button>
          </Link>
        )}
      </Box>
    </DialogLayout>
  );
};
