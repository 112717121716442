import React, { type FC } from "react";

import { NameAndIdContent } from "./name-and-id";
import { Container } from "./styled";
import type { ListItemProps } from "./types";

export const ParentNameAndId: FC<ListItemProps> = ({ item }) => {
  const {
    uiData: { name, id },
  } = item;

  if (!name && !id) {
    return null;
  }

  return (
    <Container>
      {name || id ? <NameAndIdContent {...{ item }} /> : null}
    </Container>
  );
};
