import { useCircuitContext } from "../../tabs/circuit/circuit-context";

export const useFindHeight = (
  data?: {
    componentType: string;
    componentDescription: string;
  },
  inPorts = 0,
  outPorts = 0,
): number => {
  const { circuitLabelStatus: label } = useCircuitContext();
  const { componentType, componentDescription } = data || {};

  const evaluateHeight = (height: number) =>
    componentType?.toLowerCase() !== "source" &&
    height < 120 &&
    componentDescription?.length
      ? 120
      : height;

  if (inPorts > outPorts) {
    return evaluateHeight(label ? inPorts * 55 + inPorts * 7 : inPorts * 45);
  }

  return evaluateHeight(label ? outPorts * 55 + outPorts * 7 : outPorts * 45);
};

export const findHeight = (inPorts = 0, outPorts = 0): number => {
  if (inPorts > outPorts) {
    return inPorts * 35;
  }

  return outPorts * 35;
};
