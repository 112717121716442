import { SHA256 } from "crypto-js";

import type { AnyObject } from "#shared/types";

const referrer = "https://*.fluxninja.com/";
const commonInfo = {
  tag: "ui",
};

export class Analytics {
  static page(url: string, properties?: AnyObject) {
    window.analytics.page(document.title, {
      referrer,
      url,
      ...properties,
      ...commonInfo,
    });
  }

  static track(event: string, properties?: AnyObject) {
    window.analytics.track(event, {
      ...properties,
      ...commonInfo,
    });
  }

  /**
   * We are using email, because it is unique for a user and creating a hash of it.
   */
  static identify(email: string, traits?: AnyObject) {
    window.analytics.identify(SHA256(email).toString(), {
      ...traits,
      ...commonInfo,
    });
  }
}
