import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import React, { type FC, useState } from "react";
import { useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";

import { useControlDialog } from "#shared/components/layouts/dialog";
import { PermissionEnum, userNewState } from "#shared/recoil";
import type { Texts } from "#shared/types";

import { DataGrid } from "#organization/components/data-grid";
import { useDropdownSearchBox } from "#organization/components/layouts/dropdown-search-box";
import { SettingsSearch } from "#organization/pages/authenticated/settings/components/settings-search";
import { QUERY_KEYS } from "#organization/pages/consts";

import { AddMemberToUserGroupDropdown } from "./components/add-member-to-user-group-dropdown";
import { RemoveUserFromUserGroup } from "./components/remove-user-from-user-group";
import { headCells } from "./components/table";
import { useMembers, MEMBERS_SECTION_QUERY_KEY } from "./hooks";

import { SectionHeader } from "../../../../../components/section-header";
import { SectionWrapper } from "../../../../../components/styled";

type Text = "userGroupMembers" | "addMembers" | "removeUserFromUserGroup";

export interface SelectedToRemoveType {
  email: string;
  userGroupName: string | undefined;
  membershipId: string;
}

interface MembersSectionProps {
  userGroupId: string;
  userGroupName: string | undefined;
  texts?: Texts<Text>;
}

const INITIAL_ORDER_BY = "name";
const INITIAL_ORDER = "asc";

const enTexts: Required<MembersSectionProps["texts"]> = {
  userGroupMembers: "user group members",
  addMembers: "Add Users",
  removeUserFromUserGroup: "Remove user from user group",
};

export const MembersSection: FC<MembersSectionProps> = ({
  userGroupId,
  userGroupName,
  texts = enTexts,
}) => {
  const userRole = useRecoilValue(userNewState.userRole)!;

  const [searchPhrase, setSearchPhrase] = useState<string>("");

  const addMemberDropdown = useDropdownSearchBox();
  const permissionLock =
    PermissionEnum[userRole] < PermissionEnum.user_group_admin;
  const dataGridVariables = {
    userGroupID: { eq: userGroupId },
    user: { name: { ilike: `%${searchPhrase}%` } },
  };
  const queryClient = useQueryClient();
  const refetch = () =>
    queryClient.invalidateQueries([
      QUERY_KEYS.DATA_GRID,
      MEMBERS_SECTION_QUERY_KEY,
    ]);

  // remove user
  const [selectedToRemove, setSelectedToRemove] =
    useState<SelectedToRemoveType>({
      email: "",
      userGroupName: "",
      membershipId: "",
    });
  const removeUserConfirmDialog = useControlDialog();

  const header = headCells(permissionLock, userRole, (email, membershipId) => {
    setSelectedToRemove({
      email,
      membershipId,
      userGroupName,
    });
    removeUserConfirmDialog.open();
  });

  return (
    <SectionWrapper>
      <SectionHeader title={`${userGroupName} ${texts.userGroupMembers}`} />

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        mb={2}
        mt={2}
      >
        <SettingsSearch
          onSearch={(search: string) => setSearchPhrase(search)}
        />

        <Button
          variant="contained"
          size="small"
          disabled={permissionLock}
          onClick={addMemberDropdown.togglePopper}
          startIcon={<Add />}
        >
          {texts.addMembers}
        </Button>
      </Box>

      <DataGrid
        headCells={header}
        useGridData={useMembers}
        enabled
        filterVariables={dataGridVariables}
        initialOrderBy={INITIAL_ORDER_BY}
        initialOrder={INITIAL_ORDER}
      />

      <RemoveUserFromUserGroup
        selectedToRemove={selectedToRemove}
        refetch={refetch}
        dialogControl={removeUserConfirmDialog}
      />

      <AddMemberToUserGroupDropdown
        dropdownControl={addMemberDropdown}
        refetch={refetch}
      />
    </SectionWrapper>
  );
};
