import { gql } from "graphql-request";

export const agentsFilters = (id: string) => [
  {
    id: 0,
    name: "Agent group",
    key: "agentGroup",
    category: "agents",
    gql: gql`
      query GetAgentsGroup($where: AgentBoolExp, $first: Int) {
        agents(where: $where, first: $first, distinctOn: [agentGroup]) {
          edges {
            node {
              agentGroup
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      if (prop === "") {
        return {
          first: 5,
          where: {
            projectID: { eq: id },
          },
        };
      }

      return {
        first: 5,
        where: {
          projectID: { eq: id },
          agentGroup: {
            like: `${prop}%`,
          },
        },
      };
    },
  },
  {
    id: 1,
    name: "Controller",
    key: "controller.name",
    category: "agents",
    gql: gql`
      query GetAgentsControllerName($where: AgentBoolExp, $first: Int) {
        agents(where: $where, first: $first, distinctOn: [controllerID]) {
          edges {
            node {
              controller {
                name
              }
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      if (prop === "") {
        return {
          first: 5,
          where: {
            projectID: { eq: id },
            controller: {
              name: {
                isNull: false,
              },
            },
          },
        };
      }

      return {
        first: 5,
        where: {
          projectID: { eq: id },
          controller: {
            name: {
              like: `${prop}%`,
              isNull: false,
            },
          },
        },
      };
    },
  },
  {
    id: 2,
    name: "Hostname",
    key: "hostname",
    category: "agents",
    gql: gql`
      query GetAgentsHostname($where: AgentBoolExp, $first: Int) {
        agents(where: $where, first: $first) {
          edges {
            node {
              hostname
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      if (prop === "") {
        return {
          first: 5,
          where: {
            projectID: { eq: id },
          },
        };
      }

      return {
        first: 5,
        where: {
          projectID: { eq: id },
          hostname: {
            like: `${prop}%`,
          },
        },
      };
    },
  },
  {
    id: 3,
    name: "Agent key",
    key: "agentKey.key",
    category: "agents",
    gql: gql`
      query GetAgentsAgentKey($where: AgentBoolExp, $first: Int) {
        agents(where: $where, first: $first, distinctOn: [agentKeyID]) {
          edges {
            node {
              agentKey {
                key
              }
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      if (prop === "") {
        return {
          first: 5,
          where: {
            projectID: { eq: id },
          },
        };
      }

      return {
        first: 5,
        where: {
          projectID: { eq: id },
          agentKey: {
            key: {
              like: `${prop}%`,
            },
          },
        },
      };
    },
  },
  {
    id: 4,
    name: "Version",
    key: "version",
    category: "agents",
    gql: gql`
      query GetAgentsControllerVersion($where: AgentBoolExp, $first: Int) {
        agents(where: $where, first: $first, distinctOn: [version]) {
          edges {
            node {
              version
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      if (prop === "") {
        return {
          first: 5,
          where: {
            projectID: { eq: id },
            version: {
              isNull: false,
            },
          },
        };
      }

      return {
        first: 5,
        where: {
          projectID: { eq: id },
          version: {
            like: `${prop}%`,
            isNull: false,
          },
        },
      };
    },
  },
  {
    id: 5,
    name: "Agent status",
    key: "status",
    category: "agents",
    gql: gql`
      query GetAgentsStatus($where: AgentBoolExp, $first: Int) {
        agents(where: $where, first: $first, distinctOn: [status]) {
          edges {
            node {
              status
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      if (prop === "") {
        return {
          first: 5,
          where: {
            projectID: { eq: id },
            status: {
              isNull: false,
            },
          },
        };
      }

      return {
        first: 5,
        where: {
          projectID: { eq: id },
          status: {
            like: `${prop}%`,
            isNull: false,
          },
        },
      };
    },
  },
  {
    id: 6,
    name: "Last seen",
    key: "lastSeen",
    category: "agents",
    isLikeFilterBlocked: true,
    gql: gql`
      query GetAgentsLastSeen($where: AgentBoolExp, $first: Int) {
        agents(where: $where, first: $first, distinctOn: [lastSeen]) {
          edges {
            node {
              lastSeen
            }
          }
        }
      }
    `,
    selected: false,
    variable: (prop = "") => {
      if (prop === "") {
        return {
          first: 5,
          where: {
            projectID: { eq: id },
          },
        };
      }

      return {
        first: 5,
        where: {
          projectID: { eq: id },
          lastSeen: {
            like: `${prop}%`,
          },
        },
      };
    },
  },
];
