import { Add } from "@mui/icons-material";
import { Alert, Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, type FC } from "react";
import { useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { useBillingPageInfo } from "#shared/billing";
import { useAlert } from "#shared/components/alerts-provider";
import {
  ConfirmDialog,
  useControlDialog,
} from "#shared/components/layouts/dialog";
import { PermissionEnum, userNewState } from "#shared/recoil";
import { LoggerService } from "#shared/services/logger";
import type { Texts } from "#shared/types";

import { DataGrid } from "#organization/components/data-grid";
import {
  useDeleteProject,
  usePauseProject,
  useResumeProject,
} from "#organization/graphql";
import { useOrganizationState } from "#organization/recoil/organization";
import { useProjectContextState } from "#organization/recoil/project";

import { CreateProjectDialog } from "./components/create-project-dialog";
import { useCreateProjectSubscription } from "./components/create-project-dialog/hooks";
import { headCells } from "./components/table";
import { useProjectCount, useProjectData } from "./hooks-new";

import { SettingsButtons } from "../../components/settings-buttons";
import { SettingsSearch } from "../../components/settings-search";
import { SectionWrapper } from "../../components/styled";
import { useCancelSubscription } from "../billing/hooks";

const INITIAL_ORDER_BY = "name";
const INITIAL_ORDER = "asc";

type Text =
  | "createNewProject"
  | "removeSuccess"
  | "removeError"
  | "removeConfirmText"
  | "pauseSuccess"
  | "pauseError"
  | "pauseConfirmText"
  | "resumeSuccess"
  | "resumeError"
  | "resumeConfirmText";

interface ProjectsPageProps {
  texts?: Texts<Text>;
}

const enText: Required<ProjectsPageProps["texts"]> = {
  createNewProject: "Create New Project",
  removeSuccess: "Successfully removed project from organization.",
  removeError: "Could not remove project from organization.",
  removeConfirmText: "remove $1 from organization?",
  pauseSuccess: "Successfully paused project.",
  pauseError: "Could not pause project.",
  pauseConfirmText: "pause $1?",
  resumeSuccess: "Successfully resumed project.",
  resumeError: "Could not resume project.",
  resumeConfirmText: "resume $1?",
};

export const ProjectsPage: FC<ProjectsPageProps> = ({ texts = enText }) => {
  const [selectedToRemove, setSelectedToRemove] = useState<string>("");
  const [selectedToPause, setSelectedToPause] = useState<string>("");
  const [selectedToResume, setSelectedToResume] = useState<string>("");
  const [selectedNameToRemove, setSelectedNameToRemove] = useState<string>("");
  const [selectedNameToPause, setSelectedNameToPause] = useState<string>("");
  const [selectedNameToResume, setSelectedNameToResume] = useState<string>("");
  const [searchPhrase, setSearchPhrase] = useState<string>("");

  const userRole = useRecoilValue(userNewState.userRole)!;

  const { addAlert } = useAlert();
  const permissionLock =
    PermissionEnum[userRole] < PermissionEnum.user_group_admin;

  const deleteProjectControl = useDeleteProject();
  const pauseProjectControl = usePauseProject();
  const resumeProjectControl = useResumeProject();

  const queryClient = useQueryClient();

  const projectContext = useProjectContextState();

  const refetchProjects = () => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("projects"),
    });
  };

  const createProjectDialog = useControlDialog();
  const confirmPauseDialogControl = useControlDialog();
  const confirmResumeDialogControl = useControlDialog();
  const confirmDeleteDialogControl = useControlDialog();

  const handleOpenConfirmDialogToRemove = (id: string, name: string) => {
    if (projectContext.id === id) {
      addAlert({
        type: "error",
        message:
          "You cannot delete the project you are currently in. Please switch to another project first.",
      });

      return;
    }

    setSelectedToRemove(id);
    setSelectedNameToRemove(name);
    confirmDeleteDialogControl.open();
  };

  const handleOpenConfirmDialogToPause = (id: string, name: string) => {
    setSelectedToPause(id);
    setSelectedNameToPause(name);
    confirmPauseDialogControl.open();
  };

  const handleOpenConfirmDialogToResume = (id: string, name: string) => {
    setSelectedToResume(id);
    setSelectedNameToResume(name);
    confirmResumeDialogControl.open();
  };

  const projectCount = useProjectCount();
  const { cancelSubscription } = useCancelSubscription();

  const header = headCells(
    permissionLock,
    handleOpenConfirmDialogToRemove,
    handleOpenConfirmDialogToPause,
    handleOpenConfirmDialogToResume,
    projectCount <= 1,
  );

  const { createProjectSubscription } = useCreateProjectSubscription();

  const { id } = useOrganizationState();

  const {
    data: subscriptionInfo,
    isLoading: isLoadingBillingInfo,
    isError: isErrorBillingInfo,
  } = useBillingPageInfo({ organization_id: id });

  const [resumingBilling, setResumingBilling] = React.useState<boolean>(false);
  const [cancellingBilling, setCancellingBilling] =
    React.useState<boolean>(false);

  const isLoading =
    isLoadingBillingInfo ||
    resumingBilling ||
    cancellingBilling ||
    deleteProjectControl.loading ||
    pauseProjectControl.loading ||
    resumeProjectControl.loading;

  const pauseProject = () => {
    pauseProjectControl.pause(
      { id: selectedToPause },
      {
        onSuccess: () => {
          addAlert({
            type: "success",
            message: texts.pauseSuccess,
          });
          setSelectedToPause("");
          setSelectedNameToPause("");
          confirmPauseDialogControl.close();
          refetchProjects();
        },
        onError: (error) => {
          LoggerService.error(error);

          addAlert({
            type: "error",
            message: texts.pauseError,
          });
        },
      },
    );
  };

  const resumeProject = () => {
    resumeProjectControl.resume(
      { id: selectedToResume },
      {
        onSuccess: () => {
          addAlert({
            type: "success",
            message: texts.resumeSuccess,
          });
          confirmResumeDialogControl.close();

          if (isErrorBillingInfo) {
            addAlert({
              type: "error",
              message: "Failed to fetch billing info.",
            });
          } else {
            const subscriptionStatus = subscriptionInfo?.data.list.find(
              (sub) => sub.subscription.cf_project_id === selectedToResume,
            )?.subscription.status;

            // If the subscription for the project is not done, trigger billing checkout flow
            if (subscriptionStatus == null) {
              setResumingBilling(true);

              createProjectSubscription(selectedToResume)
                .catch((error) => {
                  LoggerService.error("error", error);

                  addAlert({
                    type: "error",
                    message: "Failed to create project subscription.",
                  });
                })
                .then(() => setResumingBilling(false));
            }
          }

          setSelectedToResume("");
          setSelectedNameToResume("");
          refetchProjects();
        },
        onError: (error) => {
          LoggerService.error(error);

          addAlert({
            type: "error",
            message: texts.resumeError,
          });
        },
      },
    );
  };

  const removeProject = () => {
    deleteProjectControl.remove(
      { id: selectedToRemove },
      {
        onSuccess: () => {
          addAlert({
            type: "success",
            message: texts.removeSuccess,
          });

          if (isErrorBillingInfo) {
            addAlert({
              type: "error",
              message: "Failed to fetch billing info.",
            });
          } else {
            const subscription = subscriptionInfo?.data.list.find(
              (sub) => sub.subscription.cf_project_id === selectedToRemove,
            )?.subscription;

            // If the subscription for the project is active, trigger cancel flow
            if (subscription?.status === "active") {
              setCancellingBilling(true);

              cancelSubscription(subscription.id)
                .catch((error) => {
                  LoggerService.error("error", error);

                  addAlert({
                    type: "error",
                    message: "Failed to cancel project subscription",
                  });
                })
                .then(() => {
                  addAlert({
                    type: "success",
                    message: "Project subscription cancelled successfully",
                  });

                  setCancellingBilling(false);
                  setSelectedToRemove("");
                  setSelectedNameToRemove("");
                  confirmDeleteDialogControl.close();
                  refetchProjects();
                });

              return;
            }
          }

          setSelectedToRemove("");
          setSelectedNameToRemove("");
          confirmDeleteDialogControl.close();
          refetchProjects();
        },
        onError: (error) => {
          LoggerService.error(error);

          addAlert({
            type: "error",
            message: texts.removeError,
          });
        },
      },
    );
  };

  const [searchParams] = useSearchParams();
  const state = searchParams.get("state");
  const action = searchParams.get("action");

  return (
    <>
      <SettingsButtons title="Projects" noButtons />

      <SectionWrapper>
        {state === "succeeded" && (
          <Alert
            severity="success"
            sx={{ marginBottom: "12px", maxWidth: 600 }}
          >
            {action !== "cancel" && (
              <>
                Project subscription added successfully. $50 have been added to
                your monthly bill.
              </>
            )}
            {action === "cancel" && (
              <>Project subscription cancelled successfully.</>
            )}
          </Alert>
        )}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          mb={2}
        >
          <SettingsSearch
            onSearch={(search: string) => setSearchPhrase(search)}
          />
          <Button
            onClick={createProjectDialog.open}
            variant="contained"
            size="small"
            disabled={PermissionEnum[userRole] < PermissionEnum.owner}
            startIcon={<Add />}
          >
            {texts.createNewProject}
          </Button>
        </Box>

        <DataGrid
          headCells={header}
          useGridData={useProjectData}
          enabled
          initialOrderBy={INITIAL_ORDER_BY}
          initialOrder={INITIAL_ORDER}
          filterVariables={{ name: { ilike: `%${searchPhrase}%` } }}
        />
      </SectionWrapper>

      <CreateProjectDialog
        dialogControl={createProjectDialog}
        refetch={refetchProjects}
      />

      <ConfirmDialog
        isOpen={confirmPauseDialogControl.isOpen}
        close={confirmPauseDialogControl.close}
        onConfirm={pauseProject}
        buttonConfirmText={isLoading ? "Please wait..." : "Pause Project"}
        confirmButtonProps={{
          endIcon: isLoading ? (
            <CircularProgress
              size="1em"
              sx={{ marginLeft: ({ spacing }) => spacing(1) }}
            />
          ) : null,
        }}
        buttonConfirmDisabled={isLoading}
        text={texts.pauseConfirmText.replace("$1", selectedNameToPause)}
        confirmPhrase={selectedNameToPause}
      />

      <ConfirmDialog
        isOpen={confirmResumeDialogControl.isOpen}
        buttonConfirmText={isLoading ? "Please wait..." : "Resume Project"}
        confirmButtonProps={{
          endIcon: isLoading ? (
            <CircularProgress
              size="1em"
              sx={{ marginLeft: ({ spacing }) => spacing(1) }}
            />
          ) : null,
        }}
        buttonConfirmDisabled={isLoading}
        close={confirmResumeDialogControl.close}
        onConfirm={resumeProject}
        text={texts.resumeConfirmText.replace("$1", selectedNameToResume)}
        confirmPhrase={selectedNameToResume}
      />

      <ConfirmDialog
        isOpen={confirmDeleteDialogControl.isOpen}
        close={confirmDeleteDialogControl.close}
        buttonConfirmText={isLoading ? "Please wait..." : "Delete Project"}
        confirmButtonProps={{
          endIcon: isLoading ? (
            <CircularProgress
              size="1em"
              sx={{ marginLeft: ({ spacing }) => spacing(1) }}
            />
          ) : null,
        }}
        buttonConfirmDisabled={isLoading}
        onConfirm={removeProject}
        text={texts.removeConfirmText.replace("$1", selectedNameToRemove)}
        confirmPhrase={selectedNameToRemove}
      />
    </>
  );
};
