import { SvgIcon, type SvgIconProps } from "@mui/material";
import React, { forwardRef, type FC, type PropsWithRef } from "react";

export const ControlPointsIcon: FC<PropsWithRef<SvgIconProps>> = forwardRef(
  (props, ref) => (
    <SvgIcon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9998 14.4338C13.344 14.4338 14.4337 13.3442 14.4337 12C14.4337 10.6559 13.344 9.5662 11.9998 9.5662C10.6557 9.5662 9.56601 10.6559 9.56601 12C9.56601 13.3442 10.6557 14.4338 11.9998 14.4338ZM11.9998 15.0829C13.7024 15.0829 15.0827 13.7026 15.0827 12C15.0827 10.2974 13.7024 8.91718 11.9998 8.91718C10.2972 8.91718 8.91699 10.2974 8.91699 12C8.91699 13.7026 10.2972 15.0829 11.9998 15.0829Z"
      />
      <path d="M13.623 12C13.623 12.8961 12.8966 13.6225 12.0005 13.6225C11.1044 13.6225 10.3779 12.8961 10.3779 12C10.3779 11.1039 11.1044 10.3774 12.0005 10.3774C12.8966 10.3774 13.623 11.1039 13.623 12Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6581 17.9036L14.6581 14.983C14.6581 14.8038 14.8034 14.6585 14.9827 14.6585L17.9032 14.6585C18.0825 14.6585 18.2278 14.8038 18.2278 14.983C18.2278 15.1622 18.0825 15.3075 17.9032 15.3075L15.7661 15.3075L18.3099 17.8513C18.4366 17.978 18.4366 18.1835 18.3099 18.3102C18.1831 18.437 17.9777 18.437 17.8509 18.3102L15.3072 15.7665L15.3072 17.9036C15.3072 18.0828 15.1619 18.2281 14.9827 18.2281C14.8034 18.2281 14.6581 18.0828 14.6581 17.9036Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9036 9.34149L14.983 9.34149C14.8038 9.34149 14.6585 9.1962 14.6585 9.01698L14.6585 6.09639C14.6585 5.91716 14.8038 5.77187 14.983 5.77187C15.1622 5.77188 15.3075 5.91716 15.3075 6.09639L15.3075 8.23354L17.8513 5.68976C17.978 5.56303 18.1835 5.56303 18.3102 5.68976C18.437 5.81649 18.437 6.02196 18.3102 6.14868L15.7665 8.69247L17.9036 8.69247C18.0828 8.69247 18.2281 8.83775 18.2281 9.01698C18.2281 9.1962 18.0828 9.34149 17.9036 9.34149Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.34185 17.9036L9.34185 14.983C9.34185 14.8038 9.19656 14.6585 9.01734 14.6585L6.09675 14.6585C5.91753 14.6585 5.77224 14.8038 5.77224 14.983C5.77224 15.1622 5.91753 15.3075 6.09675 15.3075L8.23391 15.3075L5.69012 17.8513C5.56339 17.978 5.56339 18.1835 5.69012 18.3102C5.81685 18.437 6.02232 18.437 6.14905 18.3102L8.69283 15.7665L8.69283 17.9036C8.69283 18.0828 8.83812 18.2281 9.01734 18.2281C9.19656 18.2281 9.34185 18.0828 9.34185 17.9036Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.34185 6.09639L9.34185 9.01698C9.34185 9.1962 9.19656 9.34149 9.01734 9.34149L6.09675 9.34149C5.91753 9.34149 5.77224 9.1962 5.77224 9.01698C5.77224 8.83776 5.91753 8.69247 6.09675 8.69247L8.23391 8.69247L5.69012 6.14869C5.56339 6.02196 5.56339 5.81649 5.69012 5.68976C5.81685 5.56303 6.02232 5.56303 6.14905 5.68976L8.69283 8.23354L8.69283 6.09639C8.69283 5.91716 8.83812 5.77188 9.01734 5.77188C9.19656 5.77188 9.34185 5.91716 9.34185 6.09639Z"
      />
    </SvgIcon>
  ),
);
