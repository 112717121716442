import { Box, Typography, styled } from "@mui/material";
import React, { type FC, type PropsWithChildren } from "react";

import type { Texts } from "#shared/types";

export interface ChooseBlueprintLayoutProps {
  texts?: Texts<"title" | "subTitle">;
}

export const defaultBlueprintLayoutTexts: Texts<"title" | "subTitle"> = {
  title: "Choose a blueprint for your policy",
  subTitle: "What do you want to do?",
};

export const ChooseBlueprintLayout: FC<
  PropsWithChildren<ChooseBlueprintLayoutProps>
> = ({ texts = defaultBlueprintLayoutTexts, children }) => (
  <ChooseBlueprintWrapper>
    <Typography variant="h6" fontWeight={700} textAlign="center">
      {texts.title}
    </Typography>
    <Typography variant="body2" textAlign="center">
      {texts.subTitle}
    </Typography>
    <Box className="row-of-use-case" py={2}>
      {children}
    </Box>
  </ChooseBlueprintWrapper>
);

export const ChooseBlueprintWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateRows: "auto 1fr",
  rowGap: theme.spacing(2),
  alignItems: "center",
  [`& .row-of-use-case`]: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(400px, auto))",
    gridAutoRows: "minmax(150px, auto)",
    gap: theme.spacing(3),
    justifyContent: "center",
  },
}));
