import React, { type FC } from "react";

import { FirebaseContextProvider } from "#shared/contexts/firebase";

import { GlobalAppRoutes } from "./pages";
import { verifyUrlInDevMode } from "./verify-url-in-dev-mode";

export const GlobalApp: FC = () =>
  verifyUrlInDevMode(
    <FirebaseContextProvider
      {...{
        organizationId: null,
        isOrganizationContext: false,
        idpTenantID: null,
      }}
    >
      <GlobalAppRoutes />
    </FirebaseContextProvider>,
  );
