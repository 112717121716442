import { Box, Table, Typography } from "@mui/material";
import { get } from "lodash";
import React, {
  useState,
  type MouseEvent,
  type PropsWithChildren,
  type Dispatch,
  type SetStateAction,
} from "react";

import type { Texts } from "#shared/types";

import { DataGridHeader } from "./data-grid-header";
import { BodyRow, BodyCell, StyledTableBody } from "./styled";
import type { DataGridVariants, HeadCell, Order } from "./types";

export const emptyColumnValue = "-";

export const useDataGridOrder = (
  initialOrderBy: string,
  initialOrder: Order,
) => {
  const [order, setOrder] = useState<Order>(initialOrder);
  const [orderBy, setOrderBy] = useState<string>(initialOrderBy);

  return {
    order,
    setOrder,
    orderBy,
    setOrderBy,
  };
};

function useTable<T>(
  columns: HeadCell<T>[],
  data: T[],
  rowOnClick?: (dataItem: T) => null | (() => void),
) {
  const rows = data.map((dataItem, i) => ({
    key: i,
    align: columns.map((column) => {
      if (column.align) {
        return column.align;
      }

      return "left";
    }),
    cells: columns.map((column) => {
      const columnValue =
        typeof column.accessor === "function"
          ? column.accessor(dataItem)
          : get(dataItem, column.accessor);

      if (columnValue == null || columnValue === "") {
        return emptyColumnValue;
      }

      return columnValue;
    }),
    onClick: (rowOnClick && rowOnClick(dataItem)) || undefined,
  }));

  return {
    headers: columns,
    rows,
  };
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export interface DataGridProps<T = any> {
  data: T[];
  headers: HeadCell<T>[];
  rowOnClick?: (dataItem: T) => null | (() => void);
  variant?: DataGridVariants;
  loading?: boolean;
  error?: boolean;
  texts?: Texts<Text>;
  orderController?: {
    order: Order;
    orderBy: string;
    setOrder: Dispatch<SetStateAction<Order>>;
    setOrderBy: Dispatch<SetStateAction<string>>;
  };
}

type Text = "loading" | "error" | "noData";

const mockedTexts: DataGridProps["texts"] = {
  loading: "Data is loading...",
  error: "Error when pulling data!",
  noData: "no data to display",
};

// eslint-disable-next-line react/function-component-definition
export function DataGrid<T>(props: PropsWithChildren<DataGridProps<T>>) {
  const {
    data,
    headers,
    rowOnClick,
    variant = "basic",
    error = false,
    loading = false,
    orderController = {
      order: "asc",
      orderBy: "",
      setOrder: () => {},
      setOrderBy: () => {},
    },
  } = props;

  const { texts = mockedTexts } = props;

  const table = useTable<T>(headers, data, rowOnClick);

  const handleRequestSort = (_event: MouseEvent, property: string) => {
    const isAsc =
      orderController.orderBy === property && orderController.order === "asc";
    orderController.setOrder(isAsc ? "desc" : "asc");
    orderController.setOrderBy(property);
  };

  const emptyTableView = () => {
    if (loading) {
      return (
        <Typography color="primary" variant="h4">
          {texts?.loading}
        </Typography>
      );
    }

    if (error) {
      return (
        <Typography color="primary" variant="h4">
          {texts?.error}
        </Typography>
      );
    }

    return (
      <Typography color="primary" variant="h4">
        {texts?.noData}
      </Typography>
    );
  };

  const bodyCellStyles = {
    color: "",
    borderColor: "",
  };

  if (variant === "flow") {
    bodyCellStyles.color = "info.main";
    bodyCellStyles.borderColor = "transparent";
  }

  if (variant === "primary") {
    bodyCellStyles.color = "rgba(0,0,0,0.6)";
  }

  const bodyCellStylesModified = {
    ...bodyCellStyles,
    paddingLeft: 2,
    paddingRight: 2,
  };

  return (
    <>
      {data.length > 0 && (
        <Box sx={{ overflow: "auto" }}>
          <Table>
            <DataGridHeader
              order={orderController.order}
              orderBy={orderController.orderBy}
              onRequestSort={handleRequestSort}
              headers={table.headers}
              variant={variant}
            />
            <StyledTableBody>
              {table.rows.map((row, rowIndex) => (
                <BodyRow
                  variant={variant}
                  key={[row.key, rowIndex].join("-")}
                  onClick={row.onClick}
                >
                  {Object.values(row.cells).map((cell, cellIndex) => (
                    <BodyCell
                      sx={bodyCellStylesModified}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${rowIndex}-${cellIndex}`}
                      align={row.align[cellIndex]}
                    >
                      {" "}
                      {cell}
                    </BodyCell>
                  ))}
                </BodyRow>
              ))}
            </StyledTableBody>
          </Table>
        </Box>
      )}

      {!data.length && (
        <>
          <Table>
            <DataGridHeader
              order={orderController.order}
              orderBy={orderController.orderBy}
              onRequestSort={handleRequestSort}
              headers={table.headers}
              variant={variant}
            />
          </Table>
          <Box
            sx={{
              display: "grid",
              justifyItems: "center",
              alignItems: "center",
              marginTop: "24px",
              color: "rgba(0, 0, 0, 0.38)",
            }}
          >
            {emptyTableView()}
          </Box>
        </>
      )}
    </>
  );
}
