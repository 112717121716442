import {
  Box,
  type BoxProps,
  type TypographyProps,
  Typography,
  useTheme,
} from "@mui/material";
import type { CSSSelectorObjectOrCssVariables } from "@mui/system";
import classnames from "classnames";
import React, { type FC } from "react";

import { Chip } from "#shared/components/chip";

import {
  GridViewAreaActions,
  type GridViewAreaActionsProps,
} from "./view-actions";

import type { FnTheme } from "../../../../../../../../app-theme-provider/types";

export type GridViewAreaTitleProps = {
  className?: string;
  containerProps?: Omit<BoxProps, "children">;
  innerContainerProps?: Omit<BoxProps, "children">;
  actionsProps?: GridViewAreaActionsProps;
  h3TypographyProps?: Omit<TypographyProps, "children">;
  spanTypographyProps?: Omit<TypographyProps, "children">;
  texts?: string[];
  sx?: CSSSelectorObjectOrCssVariables<FnTheme>;
};

const HEIGHT = "50px";

export const GridViewAreaTitle: FC<GridViewAreaTitleProps> = (props) => {
  const {
    containerProps,
    innerContainerProps,
    texts,
    actionsProps,
    className,
    spanTypographyProps,
    h3TypographyProps,
    sx,
  } = props;

  const palette = useTheme().palette as unknown as Required<FnTheme["palette"]>;

  return (
    <Box
      {...containerProps}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        height: HEIGHT,
        ...sx,
        ...containerProps?.sx,
      }}
      className={classnames(containerProps?.className, className)}
    >
      <Box
        sx={{ display: "flex", alignItems: "center" }}
        {...innerContainerProps}
      >
        {texts?.length ? (
          <Typography
            variant="h3"
            {...h3TypographyProps}
            sx={{
              lineHeight: "1em",
              display: "flex",
              alignItems: "center",
              columnGap: 1,
            }}
          >
            {texts.map((text, index) => {
              const [name, type] = text.split(":");

              return (
                <React.Fragment key={[name, type, index].join("-")}>
                  <Typography
                    component="span"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "20px",
                      lineHeight: "32px",
                      fontWeight: "600",
                      "&:last-of-type": { color: palette.text.primary },
                    }}
                    {...spanTypographyProps}
                  >
                    {name}
                  </Typography>
                  {type && <Chip label={type} color="plum" />}
                </React.Fragment>
              );
            })}
          </Typography>
        ) : null}
      </Box>
      {actionsProps ? <GridViewAreaActions {...actionsProps} /> : null}
    </Box>
  );
};
