import {
  Box,
  ListItemButton as MuiListItemButton,
  styled,
} from "@mui/material";

import type { FnTheme } from "../../../../app-theme-provider/types";

export const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  backgroundColor: (theme as unknown as FnTheme).palette.tableGrid.headColor,
  display: "flex",
  justifyContent: "space-between",
  height: "48px",
  position: "sticky",
  top: 0,
  left: 0,
  zIndex: 1,
}));

export const ListItemTextStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  alignItems: "center",
}));
