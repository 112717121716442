import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import React, { useMemo, useState } from "react";

import { useTabNavigation } from "#shared/hooks";

import { useHeaderSectionUpdate } from "#organization/recoil/organization/header-section";

import { APERTURE_SYSTEM_ROUTE } from "./routes.definitions";
import {
  ControllersPage,
  AgentApiKeysPage,
  AgentsPage,
  SecretsPage,
  DetailsPage,
} from "./tabs";
import { DiscoveryPage } from "./tabs/discovery";
import { DiscoveryTab } from "./tabs/discovery/discovery-tab";

const tabs = [
  { path: "details", name: "Details", component: DetailsPage },
  { path: "controllers", name: "Controllers", component: ControllersPage },
  { path: "agents", name: "Agents", component: AgentsPage },
  {
    path: "agent-api-keys",
    name: "API Keys",
    component: AgentApiKeysPage,
  },
  { path: "secrets", name: "Secrets", component: SecretsPage },
  { path: "discovery", name: "Discovery", component: null },
];

export const ApertureSystemPage = () => {
  const { onTabsChange, currentTab } = useTabNavigation(
    tabs.map((tab) => tab.path),
    tabs[0].path,
  );

  const [selectedDiscoveryItem, setSelectedDiscoveryItem] = useState<number>(0);

  useHeaderSectionUpdate({
    pageTitle: APERTURE_SYSTEM_ROUTE.TITLE,
    children: useMemo(
      () => (
        <Tabs
          value={currentTab}
          onChange={onTabsChange}
          aria-label="aperture system tabs - agents and controllers"
        >
          {tabs
            .filter((tab) => tab.component)
            .map((tab) => (
              <Tab label={tab.name} value={tab.path} key={tab.path} />
            ))}
          <DiscoveryTab
            value="discovery"
            label="Discovery"
            onClick={(e) => onTabsChange(e, "discovery")}
            currentTab={selectedDiscoveryItem}
            onChangeTab={setSelectedDiscoveryItem}
          />
        </Tabs>
      ),
      [currentTab, onTabsChange, selectedDiscoveryItem],
    ),
  });

  const TabComponent = tabs.find((tab) => tab.path === currentTab)?.component;

  return (
    <Box>
      {TabComponent && <TabComponent />}
      {currentTab === "discovery" && (
        <DiscoveryPage currentTab={selectedDiscoveryItem} />
      )}
    </Box>
  );
};
