import { gql } from "graphql-request";
import { useCallback } from "react";
import { useRecoilValue } from "recoil";

import { userNewState } from "#shared/recoil";
import { useGqlMutation } from "#shared/utils";
import { httpClient } from "#shared/utils/http-client";

import type {
  ChargeBeeHostedPage,
  CheckoutHostPageBody,
} from "#global/pages/authenticated/billing/new-billing";

import { API_URLS } from "#organization/pages/consts";
import { useOrganizationState } from "#organization/recoil/organization";

import { usePlanID } from "../../../billing/hooks";

const createUserGroup = gql`
  mutation CreateUserGroupSettings($input: CreateUserGroupInput!) {
    createUserGroup(input: $input) {
      userGroup {
        id
      }
    }
  }
`;

export const useCreateUserGroup = () => {
  const { mutate, isError, isLoading } = useGqlMutation<
    { createUserGroup: { userGroup: { id: string } } },
    unknown,
    {}
  >(createUserGroup);

  return {
    create: mutate,
    error: isError,
    loading: isLoading,
  };
};

const addProjectMutation = gql`
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      project {
        name
        id
      }
    }
  }
`;
type AddProjectMutation = {
  name: string;
  organization: string;
  userGroup: string | unknown;
  description: string;
  region: string;
  cloud_provider: string;
};

export const useAddProjectMutation = () => {
  const { mutate, isLoading, isError, data } = useGqlMutation<
    {
      createProject: {
        project: {
          name: string;
          id: string;
          region: string;
          cloud_provider: string;
        };
      };
    },
    unknown,
    AddProjectMutation
  >(addProjectMutation);

  return {
    addProject: mutate,
    data,
    error: isError,
    loading: isLoading,
  };
};

export const useCreateProjectSubscription = () => {
  const { id: orgHasuraID } = useOrganizationState();

  const firebaseEmail = useRecoilValue(userNewState.email);
  const { planID, isLoadingPlan, isErrorPlan } = usePlanID();

  const createProjectSubscription = useCallback(
    (projectID: string) =>
      httpClient
        .post<ChargeBeeHostedPage, false, CheckoutHostPageBody>({
          url: API_URLS.BILLING.CREATE_CHECKOUT_HOSTED_PAGE,
          body: {
            organization_id: orgHasuraID,
            project_id: projectID,
            plan_id: planID || "",
            redirect_url: window.location.href,
            user: {
              email: firebaseEmail || "",
            },
          },
        })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to create project subscription`);
          }

          return response.json();
        })
        .then((resp) => {
          window.location.href = resp.url;
        }),
    [firebaseEmail, orgHasuraID, planID],
  );

  return {
    createProjectSubscription,
    isLoading: isLoadingPlan,
    isError: isErrorPlan,
  };
};
