import type { Classifier, FluxMeter } from "#shared/generated/graphql";

import type { ResourceWithDashboard } from "#organization/pages/types";

import type { DashboardFolderSearch } from "../hooks";
import type {
  CircuitComponent,
  CircuitEdge,
  ResourceWithUiData,
  UiData,
} from "../types";

export type SelectedResources = {
  [R in ResourceWithDashboard]: R extends "FluxMeter"
    ? SelectedResource<FluxMeter>[]
    : R extends "Classifier"
    ? SelectedResource<Classifier>[]
    : R extends "ConcurrencyLimiter"
    ? SelectedResource<CircuitComponent>[]
    : R extends "RateLimiter"
    ? SelectedResource<CircuitComponent>[]
    : R extends "Signal"
    ? SelectedResource<CircuitEdge>[]
    : R extends "Dashboards"
    ? (DashboardFolderSearch & { uiData: UiData })[]
    : never;
};

export type Resource = FluxMeter | Classifier | CircuitComponent | CircuitEdge;

export type SelectedResource<O extends Resource = Resource> =
  ResourceWithUiData<O>;

export type OnSelected = (selectedResources: SelectedResources) => void;

export type CircuitViewResource = Extract<
  ResourceWithDashboard,
  "Signal" | "ConcurrencyLimiter" | "RateLimiter" | "ConcurrencyScheduler"
>;

export type ResourcesViewResource = Extract<
  ResourceWithDashboard,
  "FluxMeter" | "Classifier" | "Dashboards" | "ConcurrencyScheduler"
>;

export const CIRCUIT_VIEW_RESOURCES: CircuitViewResource[] = [
  "Signal",
  "ConcurrencyLimiter",
  "RateLimiter",
];

export const RESOURCES_VIEW_RESOURCES: ResourcesViewResource[] = [
  "FluxMeter",
  "Classifier",
  "Dashboards",
];
