import { Warning } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/material";
import React, {
  type ChangeEvent,
  type FC,
  useState,
  type ReactNode,
} from "react";
import { Link } from "react-router-dom";

import { useAlert } from "#shared/components/alerts-provider";
import { Input } from "#shared/components/inputs/input";
import {
  type DialogControl,
  DialogLayout,
} from "#shared/components/layouts/dialog";
import { LoggerService } from "#shared/services/logger";
import { FEATURE_FLAGS } from "#shared/utils";

import { useOrganizationState } from "#organization/recoil/organization";

import {
  useAddProjectMutation,
  useCreateProjectSubscription,
  useCreateUserGroup,
} from "./hooks";

import type { FnTheme } from "../../../../../../../../../app-theme-provider/types";
import { SettingsSectionLabel } from "../../../../components/settings-section-label";
import { SectionSelect } from "../../../../components/styled";
import { useUserGroupsQuery } from "../../../graphql";
import { useAddUserGroupToProjectMutation } from "../../../hooks";
import {
  DEFAULT_PROJECT_REGION,
  PROJECT_CLOUD_PROVIDERS,
  DEFAULT_PROJECT_CLOUD_PROVIDER,
  type CloudProvider,
} from "../../project-regions";

export const CreateProjectDialog: FC<{
  dialogControl: DialogControl;
  refetch: () => void;
}> = ({ dialogControl, refetch }) => {
  const { addAlert } = useAlert();

  const organizationState = useOrganizationState();

  const addProjectHook = useAddProjectMutation();
  const { yourUserGroups, otherUserGroups } = useUserGroupsQuery();
  const newUserGroup = useCreateUserGroup();
  const addUserGroupHook = useAddUserGroupToProjectMutation();
  const newProjectSub = useCreateProjectSubscription();

  const userGroups = yourUserGroups?.concat(otherUserGroups);

  const [inputProjectName, setInputProjectName] = useState<string>("");
  const [inputUserGroupName, setInputUserGroupName] = useState<string>("");
  const [selectUserGroupName, setSelectUserGroupName] = useState<string[]>([]);
  const [selectRegion, setSelectRegion] = useState<string>(
    DEFAULT_PROJECT_REGION,
  );
  const [selectCloudProvider, setSelectCloudProvider] = useState<CloudProvider>(
    DEFAULT_PROJECT_CLOUD_PROVIDER,
  );

  const [userGroupRadio, setUserGroupRadio] = useState<string>("new");
  const [creatingSub, setCreatingSub] = React.useState<boolean>(false);

  const watchConfirm =
    userGroupRadio === "new"
      ? inputProjectName && inputUserGroupName
      : inputProjectName && selectUserGroupName.length;

  const handleRadio = (event: ChangeEvent<HTMLInputElement>) => {
    setUserGroupRadio(event.target.value);
  };

  const handleProjectName = (event: ChangeEvent<HTMLInputElement>) => {
    setInputProjectName(event.currentTarget.value);
  };

  const handleUserGroupName = (event: ChangeEvent<HTMLInputElement>) => {
    setInputUserGroupName(event.currentTarget.value);
  };

  const theme = useTheme() as unknown as FnTheme;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    sx: { zIndex: theme.zIndex.popoverInDialog },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const isLoading =
    addProjectHook.loading ||
    newUserGroup.loading ||
    addUserGroupHook.loading ||
    newProjectSub.isLoading;

  const addProjectConfirm = (
    userGroupId: string | undefined,
    projectName: string,
    region: string,
    cloud_provider: string,
  ) => {
    addProjectHook.addProject(
      {
        name: projectName,
        organization: organizationState.id,
        userGroup: userGroupId,
        description: "",
        region,
        cloud_provider,
      },
      {
        onSuccess: (response) => {
          addAlert({
            type: "success",
            message: "Project created successfully.",
          });

          if (FEATURE_FLAGS.globalBilling) {
            createProjectSubscriptionHandler(response.createProject.project.id);
          }

          refetch();
        },
        onError: (error) => {
          LoggerService.error(error);
          addAlert({ type: "error", message: "Could not create project." });
        },
      },
    );
  };

  const addProjectConfirmForExisting = (
    userGroupIds: string[],
    projectName: string,
    region: string,
    cloud_provider: string,
  ) => {
    addProjectHook.addProject(
      {
        name: projectName,
        organization: organizationState.id,
        userGroup: userGroupIds[0],
        description: "",
        region,
        cloud_provider,
      },
      {
        onSuccess: (response) => {
          addAlert({
            type: "success",
            message: "Project created successfully.",
          });

          const promiseAll = userGroupIds?.slice(1).map((userGroupId) =>
            addUserGroupHook.asyncAddUserGroupToProject({
              project: response.createProject.project.id,
              userGroup: userGroupId,
              permissions: "read",
            }),
          );

          Promise.allSettled(promiseAll)
            .then((res) => {
              if (res.length !== userGroupIds.length - 1) {
                addAlert({
                  type: "error",
                  message: "Could not add user groups.",
                });

                return;
              }

              addAlert({
                type: "success",
                message: "User groups added successfully.",
              });

              if (FEATURE_FLAGS.globalBilling) {
                createProjectSubscriptionHandler(
                  response.createProject.project.id,
                );
              }

              refetch();
            })
            .catch((error) => {
              LoggerService.error("error", error);

              addAlert({
                type: "error",
                message: "Could not add user groups.",
              });
            });
        },
        onError: (error) => {
          LoggerService.error(error);
          addAlert({ type: "error", message: "Could not create project." });
        },
      },
    );
  };

  const createProjectSubscriptionHandler = (projectID: string) => {
    setCreatingSub(true);

    newProjectSub.createProjectSubscription(projectID).catch((error) => {
      LoggerService.error("error", error);

      addAlert({
        type: "error",
        message: "Failed to create project subscription.",
      });

      setCreatingSub(false);
    });
  };

  const onCreate = () => {
    if (inputProjectName) {
      if (!inputProjectName.match(/^[a-zA-Z0-9-]*$/)) {
        addAlert({
          type: "error",
          message:
            "Project name can only contain alphanumeric characters and dashes.",
        });

        return;
      }

      if (userGroupRadio === "new") {
        newUserGroup.create(
          {
            name: inputUserGroupName,
            organization: organizationState.id,
          },
          {
            onSuccess: (response) => {
              const userGroupIds = response.createUserGroup.userGroup.id;

              addAlert({
                type: "success",
                message: "User group created successfully.",
              });

              addProjectConfirm(
                userGroupIds,
                inputProjectName,
                selectRegion,
                selectCloudProvider,
              );
            },
            onError: (error) => {
              LoggerService.error("error", error);

              addAlert({
                type: "error",
                message: "Could not create user group.",
              });
            },
          },
        );
      }

      if (userGroupRadio === "existing") {
        if (selectUserGroupName?.length !== 0) {
          addProjectConfirmForExisting(
            selectUserGroupName,
            inputProjectName,
            selectRegion,
            selectCloudProvider,
          );
        }
      }
    }
  };

  const getConfirmButtonText = () => {
    if (isLoading) {
      return "Please wait...";
    }

    if (creatingSub) {
      return "Redirecting to checkout...";
    }

    if (newProjectSub.isError) {
      return "Something went wrong, please try again later";
    }

    return "Create project and checkout";
  };

  return (
    <DialogLayout
      dialogTitle="Create Project"
      onCloseDialog={dialogControl.close}
      openDialog={dialogControl.isOpen}
      onClickDialogClose={dialogControl.close}
      onConfimrDialog={onCreate}
      buttonConfirmText={getConfirmButtonText()}
      buttonConfirmVariant="contained"
      buttonConfirmColor="primary"
      buttonCancelVariant="outlined"
      buttonCancelColor="primary"
      maxWidth="md"
      buttonConfirmDisabled={!watchConfirm || creatingSub || isLoading}
      confirmButtonProps={{
        endIcon:
          creatingSub || isLoading ? (
            <CircularProgress
              size="1em"
              sx={{ marginLeft: ({ spacing }) => spacing(1) }}
            />
          ) : null,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          rowGap: 1,
          alignItems: "center",
        }}
      >
        <SettingsSectionLabel primaryText="Project name" />
        <Input
          placeholder="Ninja Project"
          size="small"
          onChange={handleProjectName}
        />

        <SettingsSectionLabel primaryText="Cloud Provider" />
        <SectionSelect
          value={selectCloudProvider}
          size="small"
          onChange={(event) => {
            const newCloudProvider = event.target.value as CloudProvider;

            setSelectCloudProvider(newCloudProvider);

            setSelectRegion(PROJECT_CLOUD_PROVIDERS[newCloudProvider][0]);
          }}
          MenuProps={MenuProps}
          renderValue={(data) => (data as string).toUpperCase()}
        >
          {Object.keys(PROJECT_CLOUD_PROVIDERS).map((cloudProvider) => (
            <MenuItem key={cloudProvider} value={cloudProvider}>
              {cloudProvider.toUpperCase()}
            </MenuItem>
          ))}
        </SectionSelect>

        <SettingsSectionLabel primaryText="Region" />
        <SectionSelect
          value={selectRegion}
          size="small"
          onChange={(event) => {
            setSelectRegion(event.target.value as string);
          }}
          MenuProps={MenuProps}
          renderValue={(data) => data as string}
        >
          {PROJECT_CLOUD_PROVIDERS[selectCloudProvider].map((region) => (
            <MenuItem key={region} value={region}>
              {region}
            </MenuItem>
          ))}
        </SectionSelect>

        <Box
          sx={{
            display: "grid",
            rowGap: 3,
          }}
        >
          <RadioGroup
            aria-label="gender"
            name="controlled-radio-buttons-group"
            defaultValue="existing"
            value={userGroupRadio}
            onChange={handleRadio}
          >
            <FormControlLabel
              value="new"
              control={<Radio />}
              label="Create new user group"
            />
            <FormControlLabel
              value="existing"
              control={<Radio />}
              label="Assign existing user group"
            />
          </RadioGroup>
        </Box>
        <Box
          sx={{
            display: "grid",
            rowGap: 3,
          }}
        >
          {userGroupRadio === "new" && (
            <Input
              onChange={handleUserGroupName}
              placeholder="ninja-usergroup-1"
              size="small"
            />
          )}

          {userGroupRadio === "existing" && (
            <SectionSelect
              value={selectUserGroupName}
              size="small"
              multiple
              onChange={(event) => {
                const value =
                  typeof event.target.value === "string"
                    ? event.target.value.split(",")
                    : event.target.value;
                setSelectUserGroupName(value as string[]);
              }}
              input={<OutlinedInput />}
              MenuProps={MenuProps}
              renderValue={(data: unknown) => {
                const userGroupsFiltering = userGroups.filter(
                  (userGroup) =>
                    (data as string[]).indexOf(userGroup.node.id) !== -1,
                );

                return userGroupsFiltering
                  .map((userGroup) => userGroup.node.name)
                  .join(", ") as ReactNode;
              }}
            >
              {userGroups.map((userGroup) => (
                <MenuItem key={userGroup.node.id} value={userGroup.node.id}>
                  <Checkbox
                    checked={
                      selectUserGroupName?.indexOf(userGroup.node.id) > -1
                    }
                  />
                  <ListItemText primary={userGroup.node.name} />
                </MenuItem>
              ))}
            </SectionSelect>
          )}
        </Box>
      </Box>
      <Box display="flex" gap={1} alignItems="center" mt={3} mb={-2}>
        <Warning sx={{ color: "warning.main", fontSize: "24px" }} />
        <Typography variant="body1">
          Each new project adds $50 to your monthly bill. New projects do not
          have a trial period.
        </Typography>
        <Link to="https://www.fluxninja.com/pricing" target="_blank">
          <Typography
            color="primary.main"
            variant="body1"
            sx={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Learn more
          </Typography>
        </Link>
      </Box>
    </DialogLayout>
  );
};
