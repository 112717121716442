import type { ThemeOptions } from "@mui/material";

const styleOverrides: Required<
  Required<ThemeOptions>["components"]
>["MuiTablePagination"]["styleOverrides"] = {
  displayedRows: {
    marginBottom: 0,
  },
  selectLabel: {
    marginBottom: 0,
  },
};

export const MuiTablePagination: Required<
  Required<ThemeOptions>["components"]
>["MuiTablePagination"] = { styleOverrides };
