import { useCallback, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { userNewState } from "#shared/recoil";
import { httpClient } from "#shared/utils/http-client";

import { API_URLS } from "#organization/pages/consts";

export const useCompleteSubscription = () => {
  const [searchParams] = useSearchParams();

  const redirectURL = searchParams.get("redirect_url");
  const navigate = useNavigate();

  const url = useMemo(() => {
    const u = new URL(API_URLS.BILLING.FINISH_SUBSCRIPTION);

    [...searchParams.entries()].forEach(([key, value]) =>
      u.searchParams.append(key, value),
    );

    return u.toString();
  }, [searchParams]);

  const firebaseEmail = useRecoilValue(userNewState.email);

  const completeSubscription = useCallback(
    () =>
      httpClient
        .post<string, false, { user: { email: string } }>({
          url,
          body: {
            user: {
              email: firebaseEmail || "",
            },
          },
        })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to finish subscription`);
          }

          return response.json();
        })
        .then(() => {
          if (redirectURL) {
            const u = new URL(redirectURL);
            u.searchParams.append("state", searchParams.get("state") || "");
            navigate(`${u.pathname}${u.search}`, { replace: true });
          }

          return redirectURL;
        }),
    [firebaseEmail, navigate, redirectURL, searchParams, url],
  );

  return {
    completeSubscription,
  };
};
