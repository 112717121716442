import { Stack, TextField } from "@mui/material";
import React, { useMemo, type FC } from "react";
import { type UseFormReturn } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { usePolicies } from "../../hooks";
import { useCreatePolicyContext } from "../context";
import { useCreatePolicySearchParams, type HandleNext } from "../hooks";

export declare type PolicyFormNameValues = {
  policyName: string;
};

export interface PolicyNameFormProps {
  projectID: string;
  handleNext: HandleNext;
  useForm: UseFormReturn<PolicyFormNameValues>;
}

export const POLICY_NAME_FORM_ID = "policy-name-form";

export const PolicyNameForm: FC<PolicyNameFormProps> = ({
  projectID,
  useForm,
}) => {
  const { setCreatePolicyCtx } = useCreatePolicyContext();
  const { setSearchParams, searchInfo } = useCreatePolicySearchParams();
  const { data: policyData, isLoading } = usePolicies(projectID);
  const { pathname } = useLocation();
  const policyNames = useMemo(
    () =>
      policyData?.policiesWithDefinition.edges.map((edge) => edge.node.name) ||
      [],
    [policyData?.policiesWithDefinition.edges],
  );

  const {
    register,
    formState: { errors },
    getValues,
  } = useForm;

  const onBlur = () => {
    const data = getValues();

    setCreatePolicyCtx((prev) => ({
      ...prev,
      currentCustomPayload: {
        name: data.policyName,
        project: projectID,
      },
    }));

    setSearchParams(
      new URLSearchParams({
        ...searchInfo,
        name: data.policyName,
      }),
    );
  };

  const validatePolicyName = (value: string) => {
    if (isLoading) {
      return "Loading policies, please retry after a few seconds";
    }

    const policies =
      pathname.includes("edit-policy") || searchInfo.policyID?.length
        ? policyNames?.filter((v) => v !== searchInfo.name)
        : policyNames;

    if (policies.includes(value.trim())) {
      return "Policy name already exists";
    }

    return true;
  };

  return (
    <Stack component="form" mt={1}>
      <TextField
        type="text"
        placeholder="Policy Name"
        label="Policy Name"
        variant="outlined"
        {...register("policyName", {
          required: "Policy name is required",
          validate: validatePolicyName,
        })}
        error={!!errors.policyName}
        helperText={errors.policyName?.message}
        size="small"
        sx={{
          width: 300,
        }}
        onBlur={onBlur}
      />
    </Stack>
  );
};
