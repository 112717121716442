import { atom } from "recoil";

import type { Breadcrumb } from "#shared/components/breadcrumbs";
import { ATOM_KEYS } from "#shared/consts";

/**
 * A stack of breadcrumbs.
 * Only push and pop operations should be performed.
 * ReactElements already have the `key` prop applied.
 */
export const breadcrumbsInTopBarAtom = atom<Breadcrumb[]>({
  key: ATOM_KEYS.breadcrumbsInTopBar,
  default: [],
});
