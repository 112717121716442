import { useMemo } from "react";
import { useQuery } from "react-query";

import { fetchBlueprintSchema } from "./fetch-blueprint-helper";

import { LoggerService } from "../../../../../../shared/services";
import { normalizeVersion } from "../utils";

const repo = "fluxninja/aperture";
const blueprintDirectory = "blueprints";
const blueprintDefinitionFile = "gen/definitions.json";
const blueprintCommonFile =
  "gen/jsonschema/_definitions.json#/definitions/Policy";

export const commonBlueprint = {
  name: "Custom blueprint",
  id: "Default",
  path: `${blueprintDirectory}/${blueprintCommonFile}`,
};

type GithubTreeResponse = {
  sha: string;
  url: string;
  tree: Array<{
    path: string;
    mode: string;
    type: string;
    sha: string;
    url: string;
  }>;
};

export const useBlueprints = (
  controllerVersion: string,
  controllerCommitSha?: string,
  enabled: boolean = true,
) => {
  const tag = useMemo(
    () => normalizeVersion(controllerVersion),
    [controllerVersion],
  );

  const version = getVersion(controllerCommitSha, tag);

  const {
    data: ghResponse,
    isLoading,
    error,
    isError,
  } = useQuery<GithubTreeResponse>(
    ["blueprints", version],
    async () => {
      const response = await fetch(
        `https://api.github.com/repos/${repo}/git/trees/${version}?recursive=1`,
      );

      return response.json();
    },
    {
      enabled: !!version && enabled,
    },
  );

  const blueprints = useMemo(() => {
    const blueprintList = ghResponse?.tree
      ?.filter(
        (item) =>
          item.type === "blob" &&
          item.path.startsWith(blueprintDirectory) &&
          item.path.endsWith(blueprintDefinitionFile),
      )
      ?.map((item) => ({
        name: item.path.slice(
          blueprintDirectory.length + 1,
          -(blueprintDefinitionFile.length + 1),
        ),
        id: item.sha,
        path: item.path,
      }));

    return blueprintList
      ? [...blueprintList, commonBlueprint]
      : [commonBlueprint];
  }, [ghResponse]);

  return { data: blueprints, isLoading, error, isError };
};

export const useBlueprintJsonSchema = (
  blueprintPath: string,
  controllerVersion: string,
  controllerCommitSha?: string,
  invalidPolicyNames?: string[],
  isJsonSchemaForm?: boolean,
) => {
  const tag = useMemo(
    () => normalizeVersion(controllerVersion),
    [controllerVersion],
  );

  const version = getVersion(controllerCommitSha, tag);

  const isCommonBlueprint =
    blueprintPath === `${blueprintDirectory}/${blueprintCommonFile}`;

  const {
    data: blueprintRefObj,
    isLoading: isLoadingBlueprintRefObj,
    error: errorBlueprintRefObj,
  } = useQuery(
    ["blueprints", version, "schema", blueprintPath],
    async () =>
      fetchBlueprintSchema({
        blueprintPath,
        version,
        isCommonBlueprint,
        invalidPolicyNames,
        isJsonSchemaForm,
        log: LoggerService,
      }),
    {
      enabled: !!version && !!blueprintPath,
    },
  );

  return {
    data: blueprintRefObj,
    isLoading: isLoadingBlueprintRefObj,
    error: errorBlueprintRefObj,
  };
};

const getVersion = (commitSha?: string, tag?: string) => {
  if (commitSha && commitSha !== "unknown") {
    return commitSha;
  }

  if (tag) {
    return `v${tag}`;
  }

  return "latest";
};
