import { Box } from "@mui/material";
import React, { type FC, useState } from "react";
import { useRecoilState } from "recoil";

import {
  FilteringGroup,
  useChipsContainer,
} from "#shared/components/filtering/filtering-group";
import { useParseLinkAttributesToFilterVariables } from "#shared/components/filtering/utils";
import { ControllerSelectColumn } from "#shared/generated/graphql";

import {
  DataGrid,
  DataGridGroups,
  DataGridWrapper,
  FiltersBarWrapper,
  GroupByButtonWrapper,
  GroupBySelect,
  type GroupByOption,
} from "#organization/components/data-grid";
import { FlyoutMenu } from "#organization/components/flyout-menu";
import { projectContextState } from "#organization/recoil/project";

import { ControlPointsInstalled } from "./control-points-installed";
import { FlowControlFlyoutMenuWrapper } from "./flyout-menu";
import {
  useFlowControlGroupsTitles,
  useServicesControlPointsGroupData,
  useFlowControlData,
  useFlowControl,
} from "./hooks";
import { NoFlowControl } from "./no-flow-control";
import { flowControlFilters, headCells } from "./table";
import { rowClick } from "./table/row";
import type { FlowControlFlyoutConfig } from "./types";

const GROUP_BYS: GroupByOption<ControllerSelectColumn>[] = [
  {
    title: "No value",
    value: "",
  },
  {
    title: "Control Point",
    value: ControllerSelectColumn.Name,
  },
];

const INITIAL_ORDER_BY = "service.name";
const INITIAL_ORDER = "asc";
const TABLE_UNIQUE_ID = "flow-control-table";

export const ControlPointsFlowControlTab: FC = () => {
  const [projectContext] = useRecoilState(projectContextState);
  const chipsContainer = useChipsContainer();
  const filterHeaders = flowControlFilters(projectContext.id);
  const [selectedService, setSelectedService] =
    useState<FlowControlFlyoutConfig>({
      serviceName: "",
      serviceId: "",
      agentGroup: "",
      controllerId: "",
      controlPoint: "",
      controlPointId: "",
      controlPointType: "",
      status: "",
    });
  const [groupByKey, setGroupByKey] =
    useState<(typeof GROUP_BYS)[number]["value"]>("");
  const [filterVariables, setFilterVariables] = useState(
    useParseLinkAttributesToFilterVariables(TABLE_UNIQUE_ID),
  );
  const [flyoutMenuVisibility, setFlyoutMenuVisibility] = useState(false);

  const headerTable = headCells(setFlyoutMenuVisibility, setSelectedService);
  const rowOnClick = rowClick(setFlyoutMenuVisibility, setSelectedService);

  const { isEmpty, justInstalled } = useFlowControl();

  return (
    <Box pt={2}>
      <FlyoutMenu useShowHide={[flyoutMenuVisibility, setFlyoutMenuVisibility]}>
        {flyoutMenuVisibility && (
          <FlowControlFlyoutMenuWrapper
            service={selectedService}
            hideFlyoutMenu={() => setFlyoutMenuVisibility(false)}
          />
        )}
      </FlyoutMenu>

      <FiltersBarWrapper enableArchivedData>
        <FilteringGroup
          labels={filterHeaders}
          onFilter={setFilterVariables}
          chipsContainer={chipsContainer}
          uniqueId={TABLE_UNIQUE_ID}
        />
        <GroupByButtonWrapper>
          <GroupBySelect
            options={GROUP_BYS}
            setGroupByKey={setGroupByKey}
            groupByKey={groupByKey}
          />
        </GroupByButtonWrapper>
      </FiltersBarWrapper>

      <Box py={4}>
        {isEmpty && <NoFlowControl />}
        {justInstalled && <ControlPointsInstalled />}
        {!isEmpty && (
          <DataGridWrapper>
            {groupByKey ? (
              <DataGridGroups
                headCells={headerTable}
                useGroupsTitles={useFlowControlGroupsTitles}
                useGroupData={useServicesControlPointsGroupData}
                groupByKey={groupByKey}
                rowOnClick={rowOnClick}
                filterVariables={{
                  ...filterVariables,
                }}
                initialOrderBy={INITIAL_ORDER_BY}
                initialOrder={INITIAL_ORDER}
                enableArchivedData
              />
            ) : (
              <DataGrid
                headCells={headerTable}
                useGridData={useFlowControlData}
                enabled={!groupByKey}
                rowOnClick={rowOnClick}
                filterVariables={filterVariables}
                initialOrderBy={INITIAL_ORDER_BY}
                initialOrder={INITIAL_ORDER}
                enableArchivedData
              />
            )}
          </DataGridWrapper>
        )}
      </Box>
    </Box>
  );
};
