import { BrowserTracing } from "@sentry/browser";
import * as Sentry from "@sentry/react";
import { isNaN } from "lodash";
import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { App } from "./app";
import { IS_DEV } from "./apps/organization/pages/consts";
import { ErrorLayout } from "./apps/shared/components/layouts";
import { ENV } from "./apps/shared/env";
import { LoggerService } from "./apps/shared/services/logger";
import "./userWorker";

const isSentryInitiated = initSentry();

const root = createRoot(document.getElementById("root") as HTMLDivElement);

root.render(
  <>
    {isSentryInitiated ? (
      <Sentry.ErrorBoundary fallback={<ErrorLayout />}>
        <App />
      </Sentry.ErrorBoundary>
    ) : (
      <App />
    )}
  </>,
);

function initSentry(): boolean {
  if (!ENV.SENTRY_DSN) {
    LoggerService.warn("Sentry not initiated. Missing dsn.");

    return false;
  }

  const isTracesSampleRateNan = isNaN(Number(ENV.SENTRY_TRACES_SAMPLE_RATE));

  if (isTracesSampleRateNan) {
    LoggerService.warn(
      "Sentry traces sample rate is NaN.",
      "Environment:",
      ENV.SENTRY_ENVIRONMENT,
    );
  }

  const tracesSampleRate: Sentry.BrowserOptions["tracesSampleRate"] =
    isTracesSampleRateNan ? 1 : Number(ENV.SENTRY_TRACES_SAMPLE_RATE);

  const ignoreErrors: Sentry.BrowserOptions["ignoreErrors"] = [
    // ResizeObserver errors can be ignored; ref https://stackoverflow.com/a/50387233/3049655
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications.",
  ];

  const options: Sentry.BrowserOptions = {
    dsn: ENV.SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
    ],
    environment: ENV.SENTRY_ENVIRONMENT || "",
    tracesSampleRate,
    ignoreErrors,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: IS_DEV ? 1 : 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  };

  Sentry.init(options);

  LoggerService.debug(
    true,
    "Sentry initiated.",
    "Environment:",
    ENV.SENTRY_ENVIRONMENT,
  );

  return true;
}
