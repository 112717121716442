import { useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, type FC } from "react";
import { Route, Routes } from "react-router-dom";

import { SidebarLayout } from "#organization/components/layouts/sidebar";
import { useSidebarController } from "#organization/recoil/sidebar";

import { AlertManagerRoutes, ALERT_MANAGER_ROUTE } from "./alert-manager";
import { APERTURE_SYSTEM_ROUTE, ApertureSystemRoutes } from "./aperture-system";
import { CACHE_ROUTE, CacheRoutes } from "./cache";
import { ControlPointsRoutes, CONTROL_POINTS_ROUTE } from "./control-points";
import { POLICIES_ROUTE, PoliciesRoutes } from "./policies";
import { SettingsRoutes, SETTINGS_ROUTE } from "./settings";
import { AuthenticatedSidebar } from "./sidebar";
import { SidebarRedirect } from "./sidebar-redirect";

export const SidebarRoutes: FC = () => {
  const { setSmall } = useSidebarController();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (matches) {
      setSmall();
    }
  }, [matches, setSmall]);

  return (
    <SidebarLayout sidebar={<AuthenticatedSidebar />}>
      <Routes>
        <Route path="/*">
          <Route
            path={SETTINGS_ROUTE.PARENT_PATH}
            element={<SettingsRoutes />}
          />
          <Route
            path={CONTROL_POINTS_ROUTE.PARENT_PATH}
            element={<ControlPointsRoutes />}
          />
          <Route
            path={POLICIES_ROUTE.PARENT_PATH}
            element={<PoliciesRoutes />}
          />
          <Route
            path={APERTURE_SYSTEM_ROUTE.PARENT_PATH}
            element={<ApertureSystemRoutes />}
          />

          <Route
            path={ALERT_MANAGER_ROUTE.PARENT_PATH}
            element={<AlertManagerRoutes />}
          />

          <Route path={CACHE_ROUTE.PARENT_PATH} element={<CacheRoutes />} />

          {/* <Route
            path={INTEGRATIONS_ROUTE.PARENT_PATH}
            element={<IntegrationsRoutes />}
          /> */}

          <Route path="*" element={<SidebarRedirect />} />
        </Route>
      </Routes>
    </SidebarLayout>
  );
};
