import { Typography, Box, FormHelperText } from "@mui/material";
import { red } from "@mui/material/colors";
import React, { type FC, useState } from "react";

import { useAlert } from "#shared/components/alerts-provider";
import { Input } from "#shared/components/inputs/input";
import {
  DialogLayout,
  type DialogControl,
} from "#shared/components/layouts/dialog";
import type { Texts } from "#shared/types";

import { useOrganizationState } from "#organization/recoil/organization";

import { useAddUserGroupMutation } from "./hooks";

type Text =
  | "title"
  | "description"
  | "addUserGroupSuccess"
  | "addUserGroupError"
  | "userGroupName"
  | "nameRules"
  | "addUserGroup"
  | "inputPlaceholder";

interface CreateUserGroupDialogProps {
  dialogControl: DialogControl;
  refetch: () => void;
  texts?: Texts<Text>;
}

const enTexts: Required<CreateUserGroupDialogProps["texts"]> = {
  title: "Create New User Group",
  description:
    "Members of a user group have specific permissions and have access to specific areas.",
  addUserGroupSuccess: "User group created successfully.",
  addUserGroupError: "Failed to create user group.",
  userGroupName: "User Group Name",
  nameRules: "Letters, numbers, dashes etc are allowed",
  addUserGroup: "Create User Group",
  inputPlaceholder: "e.g. frontend_ninja, backend_US",
};

export const CreateUserGroupDialog: FC<CreateUserGroupDialogProps> = ({
  dialogControl,
  refetch,
  texts = enTexts,
}) => {
  const organizationState = useOrganizationState();
  const [userGroupName, setUserGroupName] = useState<string>("");
  const { addUserGroup, addUserGroupError } = useAddUserGroupMutation();
  const { addAlert } = useAlert();

  const handleConfirmCreateUserGroup = () => {
    if (userGroupName !== "") {
      addUserGroup(
        { name: userGroupName, organization: organizationState.id },
        {
          onSuccess: () => {
            dialogControl.close();
            setUserGroupName("");
            refetch();

            addAlert({
              type: "success",
              message: texts.addUserGroupSuccess,
            });
          },
          onError: () => {
            addAlert({
              type: "error",
              message: texts.addUserGroupError,
            });
          },
        },
      );
    }
  };

  const onClose = () => {
    setUserGroupName("");
    dialogControl.close();
  };

  return (
    <DialogLayout
      dialogTitle={texts.title}
      dialogContentText={texts.description}
      onCloseDialog={onClose}
      openDialog={dialogControl.isOpen}
      onConfimrDialog={handleConfirmCreateUserGroup}
      onClickDialogClose={onClose}
      buttonConfirmText={texts.addUserGroup}
      buttonConfirmDisabled={userGroupName === ""}
      buttonConfirmVariant="contained"
      buttonConfirmColor="primary"
      buttonCancelVariant="outlined"
      buttonCancelColor="primary"
    >
      <Box display="flex" gap={4} mt={2}>
        <Box minWidth={150} mt={2}>
          <Typography variant="subtitle1">{texts.userGroupName}</Typography>
        </Box>
        <Box flex={1}>
          <Input
            sx={{
              minWidth: "330px",
              "& input:invalid": {
                border: `1px solid ${red[500]}`,
              },
            }}
            value={userGroupName}
            onChange={(event) => setUserGroupName(event.target.value)}
            placeholder={texts.inputPlaceholder}
          />
          <FormHelperText>{texts.nameRules}</FormHelperText>
        </Box>
      </Box>
      <Box>
        {addUserGroupError && (
          <Typography variant="body2" color="error">
            {addUserGroupError.message}
          </Typography>
        )}
      </Box>
    </DialogLayout>
  );
};
