import { Typography, type TypographyProps } from "@mui/material";
import React, { type FC } from "react";

import type { Texts } from "#shared/types";

import { Container } from "./styled";
import type { ListItemProps } from "./types";

export interface NameAndIdContentProps extends ListItemProps {
  customInfo?: {
    name: string;
  } | null;
  texts?: Texts<"infoTitle">;
}

export const NameAndIdContent: FC<NameAndIdContentProps> = ({
  item,
  customInfo = null,
  texts = { infoTitle: "id:" },
}) => {
  const {
    uiData: { name, id },
  } = item;

  if (!name && !id) {
    return null;
  }

  return (
    <>
      {name ? <Typography>{name}</Typography> : null}
      {id || customInfo?.name ? (
        <Typography component="span">
          <Typography {...commonTypographyProps}>{texts.infoTitle}</Typography>
          <Typography {...commonTypographyProps}>
            {customInfo?.name ? customInfo?.name : id}
          </Typography>
        </Typography>
      ) : null}
    </>
  );
};

const commonTypographyProps: TypographyProps = {
  variant: "body2",
  fontWeight: 400,
  sx: {
    color: "text.secondary",
  },
};

export const NameAndId: FC<NameAndIdContentProps> = (props) => (
  <Container>
    <NameAndIdContent {...props} />
  </Container>
);
