import { TextField } from "@mui/material";
import React, { type ReactNode } from "react";
import {
  useFormContext,
  type FieldValues,
  type Path,
  Controller,
} from "react-hook-form";

import { createFieldNameWithPrefix } from "./utils";

import { BoolField, TimeRangePickerField } from "../../inputs";
import { FieldWithTitle, FormFieldWrapper, TreeItemStyled } from "../../styled";
import { validateFieldWithRequiredSign } from "../consts";
import * as RateLimitBlueprint from "../rate-limiting/@types";

export declare type ParameterInputsProps<
  TFields extends FieldValues = RateLimitBlueprint.Coordinate,
> = {
  fieldsPrefix?: Path<TFields>;
  children?: ReactNode;
};

export const ParameterInputs = <
  TFields extends FieldValues = RateLimitBlueprint.Coordinate,
>({
  fieldsPrefix = "policy.rate_limiter.parameters" as Path<TFields>,
}: ParameterInputsProps<TFields>): JSX.Element => {
  const { register, control, getValues } = useFormContext<TFields>();

  const defaultValues = getValues(fieldsPrefix);

  return (
    <FormFieldWrapper mt={1}>
      <Controller
        name={createFieldNameWithPrefix(fieldsPrefix, "interval")}
        control={control}
        defaultValue={defaultValues?.interval}
        rules={validateFieldWithRequiredSign<TFields>("interval is required")}
        render={({ field, fieldState }) => (
          <TimeRangePickerField
            fieldLabelProps={{
              label: "Interval",
              fieldAddress: createFieldNameWithPrefix(fieldsPrefix, "interval"),
              required: true,
            }}
            value={field.value}
            onChange={field.onChange}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
          />
        )}
      />
      <FieldWithTitle
        label="Limit by label key"
        fieldAddress={createFieldNameWithPrefix(
          fieldsPrefix,
          "limit_by_label_key",
        )}
      >
        <TextField
          {...{
            ...register(
              createFieldNameWithPrefix(fieldsPrefix, "limit_by_label_key"),
            ),
            placeholder: "limit_by_label_key",
          }}
        />
      </FieldWithTitle>
    </FormFieldWrapper>
  );
};

// NOTE: Advance is not inside the schema it is added to show optional fields
export const ParametersAdvanced = <
  TFields extends FieldValues = RateLimitBlueprint.Coordinate,
>({
  fieldsPrefix = "policy.rate_limiter.parameters" as Path<TFields>,
  children,
}: ParameterInputsProps<TFields>): JSX.Element => {
  const { control, getValues } = useFormContext<TFields>();

  const defaultValues = getValues(fieldsPrefix);

  return (
    <TreeItemStyled nodeId={`${fieldsPrefix}.advanced`} label="Advanced">
      <FormFieldWrapper mt={1}>
        <Controller
          name={createFieldNameWithPrefix(fieldsPrefix, "max_idle_time")}
          control={control}
          defaultValue={defaultValues?.max_idle_time}
          render={({ field, fieldState }) => (
            <TimeRangePickerField
              fieldLabelProps={{
                label: "Max idle time",
                fieldAddress: createFieldNameWithPrefix(
                  fieldsPrefix,
                  "max_idle_time",
                ),
              }}
              value={field.value}
              onChange={field.onChange}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />

        <Controller
          name={createFieldNameWithPrefix(fieldsPrefix, "continuous_fill")}
          control={control}
          defaultValue={defaultValues?.continuous_fill}
          render={({ field }) => (
            <BoolField
              onChange={field.onChange}
              value={field.value}
              label="Continuous fill"
              fieldAddress={createFieldNameWithPrefix(
                fieldsPrefix,
                "continuous_fill",
              )}
            />
          )}
        />

        <Controller
          name={createFieldNameWithPrefix(fieldsPrefix, "delay_initial_fill")}
          control={control}
          defaultValue={defaultValues?.delay_initial_fill}
          render={({ field }) => (
            <BoolField
              onChange={field.onChange}
              value={field.value}
              label="Delay initial fill"
              fieldAddress={createFieldNameWithPrefix(
                fieldsPrefix,
                "delay_initial_fill",
              )}
            />
          )}
        />
      </FormFieldWrapper>
      <ParametersLazySyncInputs
        fieldsPrefix={createFieldNameWithPrefix(fieldsPrefix, "lazy_sync")}
      />
      {children}
    </TreeItemStyled>
  );
};

export const ParametersLazySyncInputs = <
  TFields extends FieldValues = RateLimitBlueprint.Coordinate,
>({
  fieldsPrefix = "policy.rate_limiter.parameters.lazy_sync" as Path<TFields>,
}: ParameterInputsProps<TFields>): JSX.Element => {
  const { control, getValues } = useFormContext<TFields>();

  const defaultValues = getValues(fieldsPrefix);

  return (
    <TreeItemStyled nodeId="parameters.lazy_sync" label="Lazy Sync">
      <FormFieldWrapper mt={1}>
        <Controller
          name={createFieldNameWithPrefix(fieldsPrefix, "enabled")}
          control={control}
          defaultValue={defaultValues?.enabled}
          render={({ field }) => (
            <BoolField
              onChange={field.onChange}
              value={field.value}
              label="Enabled"
              fieldAddress={createFieldNameWithPrefix(fieldsPrefix, "enabled")}
            />
          )}
        />
        <FieldWithTitle
          label="Syncs per interval"
          fieldAddress={createFieldNameWithPrefix(fieldsPrefix, "num_sync")}
        >
          <Controller
            control={control}
            defaultValue={defaultValues?.num_sync}
            name={createFieldNameWithPrefix(fieldsPrefix, "num_sync")}
            render={({ field, field: { onChange }, fieldState }) => (
              <TextField
                {...{
                  ...field,
                  onChange: (e) => onChange(Number(e.target.value)),
                  type: "number",
                  placeholder: "Syncs per interval",
                  error: !!fieldState.error,
                  helperText: <>{fieldState.error}</>,
                }}
              />
            )}
          />
        </FieldWithTitle>
      </FormFieldWrapper>
    </TreeItemStyled>
  );
};
