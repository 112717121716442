# Continuous Fill

## Overview

The `continuous_fill` can be used to continuously refill the token bucket over time or at fixed intervals. By default, it is set to true.

## Example

If `continuous_fill` is set to false, the bucket will not refill incrementally. For instance, with `interval` and `fill_amount` set to 1 minute and 40 respectively, the bucket will receive a bulk addition of 40 tokens every minute, rather than a continuous flow of tokens throughout the `interval`.

## Note

Use `continuous_fill` to provide users with consistent, but limited, access to the service. This setting allows for a steady flow of token replenishment, as opposed to bulk addition after intervals, maintaining consistent user experience during high-traffic periods. On the other hand, if the bucket is getting depleted because of too many burst requests, therefore slowing application performance, setting `continuous_fill` to false would allow the system to recover before the bucket gets refilled leading to more requests coming in.
