import { gql, type Variables } from "graphql-request";
import { noop, uniqBy } from "lodash";
import { useMemo } from "react";

import type {
  RemoveBlueprintDefinedPolicyMutation,
  RemoveBlueprintDefinedPolicyMutationVariables,
  RemoveCustomDefinedPolicyMutation,
  RemoveCustomDefinedPolicyMutationVariables,
  PoliciesGroupDataQuery,
  PoliciesGroupsTitlesQuery,
  PoliciesQuery,
  PolicyWithDefinitionBoolExp,
} from "#shared/generated/graphql";
import type { AnyObject } from "#shared/types";
import {
  useGqlMutation,
  useGqlQuery,
  type UseGqlMutationOptions,
} from "#shared/utils";

import {
  useGridData,
  useGroupData,
  useGroupsTitles,
  type DataGridHook,
  type GroupDataHook,
  type GroupsTitlesHook,
} from "#organization/hooks";
import type { UseFnQueryOptions } from "#organization/pages/types";
import {
  globalTimeParser,
  type GqlResponseEdgeMapped,
} from "#organization/utils";

export const addPathToCircuitGql = (
  gqlString: string,
  circuitPath: string | undefined = undefined,
) => {
  const CIRCUIT = "circuit";
  const circuitIndex = gqlString.indexOf(CIRCUIT) + CIRCUIT.length;
  const newGqlString = [
    gqlString.slice(0, circuitIndex),
    circuitPath && circuitPath?.length ? `(path:"${circuitPath}")` : "",
    gqlString.slice(circuitIndex),
  ].join("");

  return newGqlString;
};

const policyGql = gql`
  query Policies(
    $where: PolicyBoolExp!
    $orderBy: [PolicyOrderBy]
    $distinctOn: [PolicySelectColumn!]
  ) {
    policies(where: $where, orderBy: $orderBy, distinctOn: $distinctOn) {
      totalCount
      edges {
        node {
          id
          name
          circuit {
            actuators {
              componentName
              componentId
              componentType
              componentDescription
            }
            children {
              hasChildren
              node {
                componentId
              }
            }
            graph {
              externalLinks {
                looped
                source {
                  componentId
                  portName
                }
                target {
                  componentId
                  portName
                }
                subCircuitId
                value {
                  ... on SignalName {
                    signalName
                  }
                  ... on ConstantValue {
                    constantValue
                  }
                }
              }

              internalLinks {
                looped
                source {
                  componentId
                  portName
                }
                target {
                  componentId
                  portName
                }
                subCircuitId
                value {
                  ... on SignalName {
                    signalName
                  }
                  ... on ConstantValue {
                    constantValue
                  }
                }
              }

              internalComponents {
                component
                componentId
                componentName
                componentType
                componentDescription
                inPorts {
                  looped
                  portName
                  subCircuitId
                  value {
                    ... on SignalName {
                      signalName
                    }
                    ... on ConstantValue {
                      constantValue
                    }
                  }
                }
                outPorts {
                  looped
                  portName
                  subCircuitId
                  value {
                    ... on SignalName {
                      signalName
                    }
                    ... on ConstantValue {
                      constantValue
                    }
                  }
                }
              }

              externalComponents {
                component
                componentId
                componentName
                componentType
                componentDescription
                inPorts {
                  looped
                  portName
                  subCircuitId
                  value {
                    ... on SignalName {
                      signalName
                    }
                    ... on ConstantValue {
                      constantValue
                    }
                  }
                }
                outPorts {
                  looped
                  portName
                  subCircuitId
                  value {
                    ... on SignalName {
                      signalName
                    }
                    ... on ConstantValue {
                      constantValue
                    }
                  }
                }
              }
            }
            node {
              componentId
            }
          }
          body
          controller {
            name
          }
          hash
          classifiers {
            edges {
              node {
                id
                flowLabel
                policy {
                  id
                  name
                  body
                  controller {
                    name
                  }
                }
              }
            }
          }
          fluxMeters {
            edges {
              node {
                name
                id
                policy {
                  id
                  name
                  controller {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const usePolicy = (
  options: Omit<UseFnQueryOptions, "queryKey" | "gpl">,
  circuitPath: string | undefined = undefined,
) => {
  const { variables } = options;

  const queryKey: UseFnQueryOptions["queryKey"] = "POLICY";
  const gqlString = useMemo(
    () => addPathToCircuitGql(policyGql, circuitPath),
    [circuitPath],
  );

  const mergedVariables: Variables &
    Record<"where", Partial<Record<"id", unknown>>> = {
    where: {},
    orderBy: [
      {
        id: "asc",
      },
    ],
    distinctOn: [],
    ...variables,
  };

  const key = (
    [
      queryKey,
      (
        variables?.where as Partial<
          Record<"id", Record<"eq", string | number | boolean>>
        >
      )?.id?.eq,
      circuitPath || "",
    ].filter(Boolean) as (string | number | boolean)[]
  )
    .map(encodeURIComponent)
    .join("-");

  const query = useGqlQuery<unknown, unknown, PoliciesQuery>(
    key,
    gqlString,
    mergedVariables,
  );

  return { query };
};

export type PoliciesGroupBy =
  | Extract<keyof PolicyWithDefinitionBoolExp, "origin">
  | "";

type PoliciesGroupTitleData =
  PoliciesGroupsTitlesQuery["policiesWithDefinition"]["edges"][0]["node"];

export const POLICIES_ENTITY_KEY = "policiesWithDefinition";

export const policiesGroupsTitles = gql`
  query PoliciesGroupsTitles(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: PolicyWithDefinitionBoolExp
    $orderBy: [PolicyWithDefinitionOrderBy]
    $distinctOn: [PolicyWithDefinitionSelectColumn!]
  ) {
    policiesWithDefinition(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      orderBy: $orderBy
      distinctOn: $distinctOn
    ) {
      totalCount
      edges {
        node {
          name
          origin
          status
          policy {
            start
            lastSeen
            controller {
              name
              id
            }
          }
        }
      }
    }
  }
`;

export const usePoliciesGroupsTitles: GroupsTitlesHook<
  Exclude<PoliciesGroupBy, "">
> = (pageSize, page, setPage, groupByKey) => {
  const groupTitles = useGroupsTitles<
    Exclude<PoliciesGroupBy, "">,
    PoliciesGroupTitleData
  >(
    POLICIES_ENTITY_KEY,
    pageSize,
    page,
    setPage,
    groupByKey,
    policiesGroupsTitles,
    (node) => node[groupByKey!],
    {},
    true,
    false,
    /**
     * Distinct on origin is not working in the backend so we are doing it here.
     *
     * This is a temporary fix and should be removed once the backend is fixed.
     */
    modifyResponseInTiles,
  );

  return groupTitles;
};

function modifyResponseInTiles<
  T extends AnyObject = { edges: { node: PoliciesGroupTitleData }[] },
>(response: T) {
  const uniqTitles = response?.edges?.length
    ? uniqBy(response?.edges, ({ node }) => node?.origin)
    : [];

  return {
    ...response,
    edges: uniqTitles,
  };
}

type PoliciesGroupData =
  PoliciesGroupDataQuery["policiesWithDefinition"]["edges"][0]["node"];

export const policiesGroupData = gql`
  query PoliciesGroupData(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: PolicyWithDefinitionBoolExp
    $orderBy: [PolicyWithDefinitionOrderBy]
  ) {
    policiesWithDefinition(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          name
          origin
          status
          policy {
            id
            start
            lastSeen
            controller {
              id
              name
            }
          }
          blueprintPolicyDefinition {
            id
            message
            createdBy {
              name
            }
            lastModifiedBy {
              name
            }
          }
          customPolicyDefinition {
            id
            message
            createdBy {
              name
            }
            lastModifiedBy {
              name
            }
          }
        }
      }
    }
  }
`;

export type AdaptedPoliciesGroupData = ReturnType<typeof modifyResponseData>;

export const usePoliciesGroupData: GroupDataHook<PoliciesGroupBy> = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  groupByKey,
  groupByValue,
  filterVariables,
) => {
  const gridData = useGroupData<
    PoliciesGroupBy,
    PoliciesGroupData,
    AdaptedPoliciesGroupData
  >(
    POLICIES_ENTITY_KEY,
    policiesGroupData,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    groupByKey,
    groupByValue,
    modifyResponseData,
    filterVariables,
  );

  return gridData;
};

export const usePoliciesData: DataGridHook = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  enabled,
  filterVariables,
) => {
  const gridData = useGridData<PoliciesGroupData, AdaptedPoliciesGroupData>(
    POLICIES_ENTITY_KEY,
    policiesGroupData,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    filterVariables,
    modifyResponseData,
    enabled,
  );

  return gridData;
};

export const usePoliciesDataById = (policyID?: string) => {
  const {
    query: { data },
  } = usePoliciesData(1, 1, noop, "asc", "", !!policyID, {
    policy: {
      id: {
        eq: policyID,
      },
    },
  });

  const queryData = data as GqlResponseEdgeMapped<AdaptedPoliciesGroupData>;

  if (!policyID || !queryData?.edges.length) {
    return null;
  }

  return queryData.edges[0];
};

export const modifyResponseData = (policy: PoliciesGroupData) => ({
  ...policy,
  origin: policy.origin as
    | "Custom"
    | "Self-Hosted"
    | "Blueprint"
    | "Aperturectl",
  /* NOTE: There's two archived statuses '3-archived' and '5-archived' */
  status: policy.status as
    | "0-error"
    | "1-draft"
    | "2-applying"
    | "3-applied"
    | "3-archived"
    | "4-deleting"
    | "5-archived",
  ...(policy?.policy && {
    start: globalTimeParser(policy.policy.start) || "-",
    lastSeen: globalTimeParser(policy.policy.lastSeen) || "-",
    id: policy.policy.id,
    controllerName: policy.policy.controller.name || "-",
  }),
  ...(policy?.blueprintPolicyDefinition && {
    id: policy.blueprintPolicyDefinition.id,
    message: policy.blueprintPolicyDefinition.message,
  }),
  ...(policy?.customPolicyDefinition && {
    id: policy.customPolicyDefinition.id,
    message: policy.customPolicyDefinition.message,
  }),
  ...(policy?.blueprintPolicyDefinition?.createdBy && {
    createdBy: policy.blueprintPolicyDefinition.createdBy.name,
  }),
  ...(policy?.blueprintPolicyDefinition?.lastModifiedBy && {
    lastModifiedBy: policy.blueprintPolicyDefinition.lastModifiedBy.name,
  }),
  ...(policy?.customPolicyDefinition?.createdBy && {
    createdBy: policy.customPolicyDefinition.createdBy.name,
  }),
  ...(policy?.customPolicyDefinition?.lastModifiedBy && {
    lastModifiedBy: policy.customPolicyDefinition.lastModifiedBy.name,
  }),
});

/**
 * Used to check if there is any policy in the project otherwise show no data screen.
 */
export const usePolicies = (projectID: string) => {
  const { data, ...rest } = useGqlQuery<PoliciesGroupDataQuery>(
    ["policies", "for", "this", "project", projectID],
    policiesGroupData,
    {
      where: {
        projectID: {
          eq: projectID,
        },
      },
    },
  );

  const { policiesWithDefinition: { totalCount = 0 } = {} } = data || {};

  return {
    isData: !!totalCount,
    data,
    ...rest,
  };
};

export const removeBlueprintDefinedPolicy = gql`
  mutation RemoveBlueprintDefinedPolicy(
    $input: RemoveBlueprintDefinedPolicyInput!
  ) {
    removeBlueprintDefinedPolicy(input: $input) {
      workflowID
    }
  }
`;

export const useRemoveBlueprintDefinedPolicy = (
  options: UseGqlMutationOptions = {},
) =>
  useGqlMutation<
    RemoveBlueprintDefinedPolicyMutation,
    unknown,
    RemoveBlueprintDefinedPolicyMutationVariables["input"]
  >(removeBlueprintDefinedPolicy, options);

export const removeCustomDefinedPolicy = gql`
  mutation RemoveCustomDefinedPolicy($input: RemoveCustomDefinedPolicyInput!) {
    removeCustomDefinedPolicy(input: $input) {
      workflowID
    }
  }
`;

export const useRemoveCustomDefinedPolicy = (
  options: UseGqlMutationOptions = {},
) =>
  useGqlMutation<
    RemoveCustomDefinedPolicyMutation,
    unknown,
    RemoveCustomDefinedPolicyMutationVariables["input"]
  >(removeCustomDefinedPolicy, options);
