import { Typography } from "@mui/material";
import { uniqBy } from "lodash";
import React, { type Dispatch, type FC, type SetStateAction } from "react";

import { emptyColumnValue, type HeadCell } from "#shared/components/data-grid";

import { ArchivedStatus } from "#organization/components";
import { globalTimeParser } from "#organization/utils";

import { PoliciesCountBox } from "../../components";
import type { AdaptedFlowControlGroupData } from "../hooks";
import type { FlowControlFlyoutConfig } from "../types";

export const headCells = (
  setIsFlyoutMenuVisible: Dispatch<SetStateAction<boolean>>,
  setSelectedService: Dispatch<SetStateAction<FlowControlFlyoutConfig>>,
): HeadCell<AdaptedFlowControlGroupData>[] => [
  {
    accessor: (row) => (
      <ArchivedStatus status={row.status}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            cursor: "pointer",
            "&:hover": { color: (theme) => theme.palette.primary.main },
          }}
          onClick={() => {
            setSelectedService({
              serviceName: row.service?.name || "",
              serviceId: row.service?.id || "",
              agentGroup: row.service?.agentGroup || "",
              controllerId: row.service?.controller.name || "",
              controlPoint: row.name,
              status: row.status,
              controlPointId: row.id,
              controlPointType: row.type,
            });

            setIsFlyoutMenuVisible(true);
          }}
        >
          {row.name || emptyColumnValue}
        </Typography>
      </ArchivedStatus>
    ),
    label: "Control point",
    key: "name",
  },
  {
    accessor: (row) => (
      <ArchivedStatus status={row.service?.status}>
        {row.service?.name || emptyColumnValue}
      </ArchivedStatus>
    ),
    label: "Service name",
    key: "service.name",
  },
  {
    accessor: (row) => <PolicyCountFinder row={row} />,
    label: "Policies",
    key: "policesCount",
    noSorting: true,
  },
  {
    accessor: "service.agentGroup",
    label: "Agent group",
    key: "service.agentGroup",
  },
  {
    accessor: "service.controller.name",
    label: "Controller",
    key: "service.controller.name",
  },
  {
    accessor: (row) => globalTimeParser(row.lastSeen),
    label: "Last seen",
    key: "lastSeen",
  },
];

export interface PolicyCountFinderProps {
  row: {
    controlPointComponentAssignments?: {
      edges?: ReadonlyArray<{
        node?: {
          component?: {
            policy: {
              id: string;
            };
          } | null;
        };
      }>;
    };
  };
}

export const PolicyCountFinder: FC<PolicyCountFinderProps> = ({ row }) => {
  const modifiedData = row?.controlPointComponentAssignments?.edges?.map(
    ({ node }) => node?.component?.policy.id,
  );

  const noOfPolicies = !modifiedData
    ? 0
    : uniqBy(modifiedData.flat(), (c) => c).length;

  return <PoliciesCountBox count={noOfPolicies} />;
};
