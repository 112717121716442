import { isArray } from "lodash";
import React, { type FC, type PropsWithChildren, useState } from "react";

import {
  DataGridPagination,
  useDataGridPagination,
  type HeadCell,
  type Order,
} from "#shared/components/data-grid";
import type { JSONObject } from "#shared/types";

import type { GroupDataHook, GroupsTitlesHook } from "#organization/hooks";

import { CreateArchiveButton } from "./archive-button";
import { archiveFilterGroupData } from "./filters";
import { DataGridGroup } from "./group";
import { GroupsLayout } from "./groups-layout";

/* eslint-disable @typescript-eslint/no-explicit-any */
export type DataGridGroupsProps<
  T = any,
  P extends string = any,
  /* eslint-enable @typescript-eslint/no-explicit-any */
> = PropsWithChildren<{
  useGroupsTitles: GroupsTitlesHook<P>;
  useGroupData: GroupDataHook<P>;
  groupByKey: P;
  initialOrderBy: string;
  initialOrder: Order;
  headCells: HeadCell<T>[];
  filterVariables: JSONObject;
  enableArchivedData?: boolean;
  modifyQueryData?: (data: T[]) => T[] | null;
  rowOnClick?: (dataItem: T) => null | (() => void);
}>;

export const DataGridGroups: FC<DataGridGroupsProps> = ({
  useGroupsTitles,
  useGroupData,
  groupByKey,
  initialOrderBy,
  initialOrder,
  headCells,
  filterVariables,
  enableArchivedData,
  modifyQueryData,
  rowOnClick,
}) => {
  const [showArchives, setShowArchives] = useState(false);
  const filters = showArchives
    ? { ...filterVariables }
    : { ...filterVariables, ...archiveFilterGroupData };

  const pagingControl = useDataGridPagination();
  const groupsTitles = useGroupsTitles(
    pagingControl.rowsPerPage,
    pagingControl.page,
    pagingControl.setPage,
    groupByKey,
    enableArchivedData ? filters : filterVariables,
  );

  // @ts-ignore
  const titleEdges = groupsTitles.query?.data?.edges || [];

  // @ts-ignore
  const adaptableTitleData = titleEdges.some((title) => isArray(title))
    ? titleEdges.flat()
    : titleEdges;

  return (
    <>
      <CreateArchiveButton
        {...{
          enableArchivedData,
          useSwitch: { showArchives, setShowArchives },
          checkLoading: groupsTitles.query.isLoading,
        }}
      />
      <GroupsLayout
        groupBys={
          // @ts-ignore
          adaptableTitleData.map((title) => ({
            title: groupsTitles?.modifyTitle
              ? groupsTitles.modifyTitle(title)
              : title,
            node: (
              <DataGridGroup
                key={title}
                headCells={headCells}
                groupByKey={groupByKey}
                groupByValue={title}
                useGroupData={useGroupData}
                filterVariables={enableArchivedData ? filters : filterVariables}
                initialOrderBy={initialOrderBy}
                initialOrder={initialOrder}
                modifyQueryData={modifyQueryData}
                rowOnClick={rowOnClick}
              />
            ),
          })) || []
        }
      />
      <DataGridPagination
        page={pagingControl.page}
        rowsPerPage={pagingControl.rowsPerPage}
        handleChangePage={groupsTitles.onPageChange}
        handleChangeRowsPerPage={pagingControl.handleChangeRowsPerPage}
        // @ts-ignore
        length={groupsTitles.query?.data?.totalCount}
      />
    </>
  );
};
