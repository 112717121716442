import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, {
  type ChangeEvent,
  useEffect,
  useState,
  type FC,
  useMemo,
  useCallback,
} from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { useAlert } from "#shared/components/alerts-provider";
import {
  ErrorLayout,
  ErrorText,
  LoaderLayout,
} from "#shared/components/layouts";
import {
  ConfirmDialog,
  useControlDialog,
} from "#shared/components/layouts/dialog";
import { PermissionEnum, userNewState, type UserRole } from "#shared/recoil";
import { LoggerService } from "#shared/services/logger";
import type { Texts } from "#shared/types";

import { DataGrid } from "#organization/components/data-grid";
import { useChangeUserRole } from "#organization/graphql/members";
import { QUERY_KEYS } from "#organization/pages/consts";
import { TopBarBreadcrumbAppender } from "#organization/recoil/breadcrumbs";
import { gqlRequest, globalTimeParser } from "#organization/utils";

import { RadioLabel } from "./components/radio-label";
import { headCells } from "./components/table";
import { useGetUserById, useUserGroupsQuery } from "./hooks";
import {
  MEMBERS_USER_GROUP_QUERY_KEY,
  useMembersUserGroupData,
} from "./hooks-new";

import { SectionHeader } from "../../../components/section-header";
import { SettingsButtons } from "../../../components/settings-buttons";
import { SettingsSearch } from "../../../components/settings-search";
import { SectionBody, SectionWrapper } from "../../../components/styled";
import { GetUserGroupMembershipsById } from "../../user-groups/hooks";
import { useRemoveUserFromUserGroup } from "../../user-groups/user-group/components/members-section/components/remove-user-from-user-group/hooks";
import { useRemoveMembersFromOrg } from "../hooks";
import { useActiveOwnerMembership } from "../hooks-new";
import {
  type MemberIdParam,
  MEMBERS_SETTINGS_ROUTE,
} from "../routes.definitions";

const INITIAL_ORDER_BY = "name";
const INITIAL_ORDER = "asc";

type Text =
  | "leftUserGroupSuccess"
  | "leftUserGroupError"
  | "roleChangeSuccess"
  | "roleChangeError"
  | "user"
  | "orgrole"
  | "status"
  | "username"
  | "email"
  | "added"
  | "owner"
  | "ownerDescription"
  | "member"
  | "memberDescription"
  | "admin"
  | "adminDescription"
  | "userGroups"
  | "userDetails"
  | "addMemberToUG"
  | "deleteUser"
  | "deleteUserDescription"
  | "lastOrgOwnerWarning"
  | "deletedMemberSuccess"
  | "deletedMemberError";

interface MembersPageProps {
  texts?: Texts<Text>;
}

const enTexts: Required<MembersPageProps["texts"]> = {
  user: "User",
  orgrole: "Organization Role",
  leftUserGroupSuccess: "User left user group successfully",
  leftUserGroupError: "Could not leave user group",
  roleChangeSuccess: "User role changed successfully",
  roleChangeError: "Could not change user role",
  username: "User Name",
  email: "Email",
  added: "Added",
  status: "Status",
  owner: "Organization Owner",
  ownerDescription:
    "Owners have full access to the Organization's data (including Projects and User Groups), billing, and settings. They can manage roles within the Organization.",
  admin: "Admin",
  adminDescription:
    "Admins have full management access to all User Groups and Projects. They can also assign User Groups to projects and set permissions for User Groups within Projects.",
  member: "Member",
  memberDescription:
    "Members can browse the organization’s data and access Projects that the User Groups are assigned to.",
  userGroups: "User Groups",
  userDetails: "User details",
  addMemberToUG: "Add Member to User Groups",
  deleteUser: "Delete User",
  deleteUserDescription:
    "Deleting a user from the organization is a permanent action and cannot be undone. This will remove all data and permissions associated with this user.",
  lastOrgOwnerWarning:
    "Cannot leave the organization, please assign owner role to another member first.",
  deletedMemberError: "Could not delete member from organization",
  deletedMemberSuccess: "Successfully deleted member from organization",
};

export const useMemberPagePermissionLock = (
  currentUserRole: UserRole,
  status: string,
  selectedMemberRole: UserRole,
) => {
  /**
   * Owner can do everything no locker for owner.
   * Otherwise can only change role for the user lower than current user role.
   * Locked if member is not active (ie: haven't accepted invite).
   */
  const permissionLock = useMemo(
    () =>
      (PermissionEnum[selectedMemberRole] >= PermissionEnum[currentUserRole] &&
        PermissionEnum[currentUserRole] !== PermissionEnum.owner) ||
      status !== "active",
    [currentUserRole, status, selectedMemberRole],
  );

  return permissionLock;
};

export const MemberPage: FC<MembersPageProps> = ({ texts = enTexts }) => {
  const { memberId } = useParams<MemberIdParam>() as MemberIdParam;

  const currentUserRole = useRecoilValue(userNewState.userRole)!;

  const { error, loading, user, refetch, membershipId, role, start, status } =
    useGetUserById();
  const { addAlert } = useAlert();
  const userGroupData = useUserGroupsQuery();
  const [newUserRole, setNewUserRole] = useState<string | undefined>(
    "user_group_member",
  );
  const userRoleController = useChangeUserRole();
  const { removeUser } = useRemoveUserFromUserGroup();

  const { removeMembership, selectedMember, setSelectedMember } =
    useRemoveMembersFromOrg<{ organizationMembershipId: string }>();

  const confirmDeleteDialogControl = useControlDialog();
  // const addMemberToUG = useControlDialog();

  const [searchPhrase, setSearchPhrase] = useState<string>("");

  const leaveGroup = async (userGroupId: string) => {
    const data: {
      userGroupMemberships: { edges: { node: { id: string } }[] };
    } = await gqlRequest(GetUserGroupMembershipsById, {
      where: {
        userGroupID: {
          eq: userGroupId,
        },
        userID: {
          eq: memberId,
        },
      },
    });

    if (data.userGroupMemberships.edges?.length !== 0) {
      removeUser(
        {
          id: data.userGroupMemberships.edges[0].node.id,
        },
        {
          onSuccess: () => {
            userGroupData.refetch();
            refreshTable();

            addAlert({
              type: "success",
              message: texts.leftUserGroupSuccess,
            });
          },
          onError: (errorResponse) => {
            LoggerService.error(errorResponse);

            addAlert({
              type: "error",
              message: texts.leftUserGroupError,
            });
          },
        },
      );
    }
  };

  const handleRoleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewUserRole(event.currentTarget.value);
  };

  const onSave = () => {
    userRoleController.change(
      { id: membershipId, role: newUserRole },
      {
        onSuccess: () => {
          addAlert({
            type: "success",
            message: texts.roleChangeSuccess,
          });
          refetch();
        },
        onError: (responseError) => {
          LoggerService.error(responseError);
          addAlert({ type: "error", message: texts.roleChangeError });
        },
      },
    );
  };

  const queryClient = useQueryClient();
  const refreshTable = () =>
    queryClient.invalidateQueries([
      QUERY_KEYS.DATA_GRID,
      MEMBERS_USER_GROUP_QUERY_KEY,
    ]);

  const onReset = () => {
    setNewUserRole(role);
  };

  useEffect(() => {
    if (!loading) {
      setNewUserRole(role);
    }
  }, [loading, role]);

  const permissionLock = useMemo(
    () =>
      PermissionEnum[currentUserRole] !== PermissionEnum.owner ||
      status !== "active",
    [currentUserRole, status],
  );

  const permissionLockAssignUserGroup = useMemo(
    () => PermissionEnum[currentUserRole] < PermissionEnum.user_group_admin,
    [currentUserRole],
  );

  const { data: activeOwnerMembership } = useActiveOwnerMembership();

  const {
    organizationMemberships: { totalCount: totalOwnerCount } = {
      totalCount: 0,
    },
  } = activeOwnerMembership || {};

  const [disableLeaveAsOwner, setDisableLeaveAsOwner] = useState(false);

  useEffect(() => {
    const isOpen = !!selectedMember && confirmDeleteDialogControl.isOpen;

    if (!isOpen) {
      setDisableLeaveAsOwner(false);

      return;
    }

    if (totalOwnerCount === 1 && role === "owner") {
      setDisableLeaveAsOwner(true);
    }
  }, [
    confirmDeleteDialogControl.isOpen,
    role,
    selectedMember,
    totalOwnerCount,
  ]);

  const navigate = useNavigate();

  const deleteUser = useCallback(() => {
    if (!selectedMember || disableLeaveAsOwner) {
      return;
    }

    removeMembership(
      () => {
        addAlert({
          type: "success",
          message: texts.deletedMemberSuccess,
        });

        confirmDeleteDialogControl.close();
        setSelectedMember(null);
        navigate("settings/organization/members");
      },
      () => {
        addAlert({
          type: "error",
          message: texts.deletedMemberError,
        });
      },
    );
  }, [
    addAlert,
    confirmDeleteDialogControl,
    disableLeaveAsOwner,
    navigate,
    removeMembership,
    selectedMember,
    setSelectedMember,
    texts.deletedMemberError,
    texts.deletedMemberSuccess,
  ]);

  if (error)
    return (
      <ErrorLayout>
        <ErrorText>{error.message}</ErrorText>
      </ErrorLayout>
    );

  if (!user && loading) return <LoaderLayout />;

  return (
    <>
      <TopBarBreadcrumbAppender>{user?.node.name}</TopBarBreadcrumbAppender>
      <SettingsButtons
        onResetAll={onReset}
        onSave={onSave}
        backButton
        backGoTo={MEMBERS_SETTINGS_ROUTE.ABSOLUTE_PATH}
        disabled={permissionLock}
      />
      <SectionWrapper>
        <SectionHeader title={texts.userDetails} />
        <SectionBody>
          <Typography variant="body2" fontWeight="600">
            {texts.username}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {user?.node.name}
          </Typography>

          <Typography variant="body2" fontWeight="600">
            {texts.email}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {user?.node.email}
          </Typography>

          <Typography variant="body2" fontWeight="600">
            {texts.added}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {start ? globalTimeParser(start) : "-"}
          </Typography>

          <Typography variant="body2" fontWeight="600">
            {texts.status}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {status}
          </Typography>
        </SectionBody>

        <SectionHeader title={texts.orgrole} />
        <SectionBody sx={{ gridTemplateColumns: "35vw" }}>
          <form>
            <FormControl
              sx={{ width: "100%" }}
              component="fieldset"
              disabled={permissionLock}
            >
              <RadioGroup
                aria-label="role"
                name={texts.orgrole}
                value={newUserRole}
                onChange={handleRoleChange}
              >
                <FormControlLabel
                  value="owner"
                  control={<Radio />}
                  disabled={
                    PermissionEnum[currentUserRole] < PermissionEnum.owner ||
                    permissionLock
                  }
                  label={
                    <RadioLabel
                      name={texts.owner}
                      description={texts.ownerDescription}
                    />
                  }
                />

                <FormControlLabel
                  value="user_group_admin"
                  control={<Radio />}
                  disabled={
                    PermissionEnum[currentUserRole] <
                      PermissionEnum.user_group_admin || permissionLock
                  }
                  label={
                    <RadioLabel
                      name={texts.admin}
                      description={texts.adminDescription}
                    />
                  }
                />
                <FormControlLabel
                  value="user_group_member"
                  control={<Radio />}
                  disabled={
                    PermissionEnum[currentUserRole] <
                      PermissionEnum.user_group_member || permissionLock
                  }
                  label={
                    <RadioLabel
                      name={texts.member}
                      description={texts.memberDescription}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          </form>
        </SectionBody>

        <SectionHeader title={texts.userGroups} />

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          mb={2}
          mt={2}
        >
          <SettingsSearch
            onSearch={(search: string) => setSearchPhrase(search)}
          />
          {/* <Button
              TODO: Add onclick for adding member to user group
              onClick={addMemberToUG.open}
              variant="contained"
              size="small"
              disabled={permissionLock}
              startIcon={<Add />}
            >
              {texts.addMemberToUG}
            </Button> */}
        </Box>
        <DataGrid
          headCells={headCells(permissionLockAssignUserGroup, (id) =>
            leaveGroup(id),
          )}
          useGridData={useMembersUserGroupData}
          enabled
          initialOrderBy={INITIAL_ORDER_BY}
          initialOrder={INITIAL_ORDER}
          filterVariables={{
            userGroupMemberships: {
              user: {
                id: { eq: memberId },
              },
              userGroup: { name: { ilike: `%${searchPhrase}%` } },
              status: { eq: "active" },
            },
          }}
        />

        <SectionHeader title={texts.deleteUser} />
        <Box ml={1.2}>
          <Typography my="24px">{texts.deleteUserDescription}</Typography>
          <Button
            variant="outlined"
            size="small"
            color="error"
            disabled={permissionLock}
            onClick={() => {
              if (!membershipId) return;

              setSelectedMember({
                organizationMembershipId: membershipId,
              });

              confirmDeleteDialogControl.open();
            }}
          >
            {texts.deleteUser}
          </Button>
        </Box>

        <ConfirmDialog
          isOpen={!!selectedMember && confirmDeleteDialogControl.isOpen}
          close={confirmDeleteDialogControl.close}
          onConfirm={() => {
            confirmDeleteDialogControl.close();
            deleteUser();
          }}
          text={`remove member ${user?.node?.name} from the organization?`}
          actions={
            disableLeaveAsOwner
              ? [
                  {
                    id: "warning",
                    node: (
                      <Typography
                        display="flex"
                        alignItems="center"
                        sx={(theme) => ({
                          color: theme.palette.warning.main,
                          gap: 1,
                        })}
                      >
                        <ErrorOutlineIcon />
                        {texts.lastOrgOwnerWarning}
                      </Typography>
                    ),
                  },
                ]
              : undefined
          }
        />
      </SectionWrapper>
    </>
  );
};
