import { useEffect, useState } from "react";

const FILTER_KEY = "stored-filters";

export const useLocalStorageFilters = () => {
  const storage = window.localStorage;
  const storedFilters = storage.getItem(FILTER_KEY);
  const parse = JSON.parse(storedFilters || "{}");
  const [filters, setFilters] = useState<Record<string, string>>(parse || {});

  useEffect(
    () => () => {
      storage.removeItem(FILTER_KEY);
      setFilters({});
    },
    [storage],
  );

  useEffect(() => {
    window.addEventListener(FILTER_KEY, () => {
      setFilters(parse);
    });
  }, [parse, storage]);

  const setLocalStorageFilters = (
    value: Record<string, string> | undefined,
  ) => {
    storage.setItem(FILTER_KEY, JSON.stringify(value || {}));
    window.dispatchEvent(new Event(FILTER_KEY));
  };

  return { setLocalStorageFilters, filters };
};
