import {
  Box,
  Typography,
  type BoxProps,
  CircularProgress,
  type CircularProgressProps,
} from "@mui/material";
import React, {
  type ReactNode,
  type FC,
  type HTMLAttributes,
  type PropsWithChildren,
} from "react";

import { FLUXNINJA } from "#shared/consts";
import logoUrl from "#shared/logo.svg";

export type LoaderLayoutProps = {
  outerContainerProps?: Omit<BoxProps, "children">;
  innerContainerProps?: Omit<BoxProps, "children">;
  circularProgressProps?: CircularProgressProps;
  imageProps?: HTMLAttributes<HTMLImageElement>;
  text?: ReactNode;
} & PropsWithChildren;

export const LoaderLayout: FC<LoaderLayoutProps> = ({
  outerContainerProps,
  innerContainerProps,
  circularProgressProps,
  imageProps,
  text,
}) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    paddingTop="150px"
    {...outerContainerProps}
  >
    <img
      src={logoUrl}
      alt={[FLUXNINJA, "logo"].join(" ")}
      style={{ transform: "scale(4)" }}
      {...imageProps}
    />
    <Box marginTop="100px" {...innerContainerProps}>
      <CircularProgress
        role="alert"
        aria-busy="true"
        aria-label="Loading..."
        disableShrink
        {...circularProgressProps}
      />
    </Box>
    {typeof text === "string" ? <Typography>{text}</Typography> : text || null}
  </Box>
);
