import React, { type FC } from "react";
import { Route, Routes } from "react-router-dom";

import { useSetPageTitle } from "#shared/hooks";

import { TopBarLayout } from "#organization/components/layouts";

import { ApertureSystemPage } from "./page";
import { APERTURE_SYSTEM_ROUTE } from "./routes.definitions";

export const ApertureSystemRoutes: FC = () => {
  useSetPageTitle(APERTURE_SYSTEM_ROUTE.TITLE);

  return (
    <TopBarLayout>
      <Routes>
        <Route index element={<ApertureSystemPage />} />
        <Route path="*" element={<ApertureSystemPage />} />
      </Routes>
    </TopBarLayout>
  );
};
