import { Typography } from "@mui/material";
import React, { type Dispatch, type SetStateAction } from "react";

import { emptyColumnValue, type HeadCell } from "#shared/components/data-grid";
import { HealthStatus } from "#shared/components/data-grid/components/health-status";

import { ArchivedStatus } from "#organization/components";
import { globalTimeParser } from "#organization/utils";

import type { AdaptedAgentGroupData } from "../hooks";
import type { AgentsFlyoutMenuConfig } from "../types";

export const headCells = (
  setIsFlyoutMenuVisible: Dispatch<SetStateAction<boolean>>,
  setSelectedAgent: Dispatch<SetStateAction<AgentsFlyoutMenuConfig>>,
): HeadCell<AdaptedAgentGroupData>[] => [
  {
    accessor: (row) => (
      <ArchivedStatus status={row.status}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            cursor: "pointer",
            "&:hover": { color: (theme) => theme.palette.primary.main },
          }}
          onClick={() => {
            setSelectedAgent({
              id: row.id,
              name: row.hostname,
              agentGroup: row.agentGroup,
              agentKey: row.agentKey,
              status: row.status,
              version: row.version,
              lastSeen: row.lastSeen,
            });

            setIsFlyoutMenuVisible(true);
          }}
        >
          {row.hostname || emptyColumnValue}
        </Typography>
      </ArchivedStatus>
    ),
    label: "Hostname",
    key: "hostname",
  },
  {
    accessor: (row) => (
      <ArchivedStatus status={row.controller?.status}>
        <Typography>{row.controller?.name || emptyColumnValue}</Typography>
      </ArchivedStatus>
    ),
    label: "Controller",
    key: "controller.name",
  },
  {
    accessor: "agentGroup",
    label: "Agent group",
    key: "agentGroup",
  },
  {
    accessor: "agentKey",
    label: "Agent key",
    key: "agentKey.id",
  },
  {
    accessor: "version",
    label: "Version",
    key: "version",
  },
  {
    accessor: (row) => <HealthStatus status={row.status} />,
    label: "Agent status",
    key: "status",
  },
  {
    accessor: (row) => globalTimeParser(row.lastSeen),
    label: "Last seen",
    key: "lastSeen",
  },
];
