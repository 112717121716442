import { Typography, type TabsProps } from "@mui/material";
import { Box } from "@mui/system";
import React, { type FC, useState, useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";

import { BreadcrumbButton } from "#shared/components/breadcrumbs";
import { Chip } from "#shared/components/chip";
import type { FilterLabelsType } from "#shared/components/filtering";
import {
  FilteringGroup,
  useChipsContainer,
} from "#shared/components/filtering/filtering-group";
import { useParseLinkAttributesToFilterVariables } from "#shared/components/filtering/utils";
import { TabPanel } from "#shared/components/tab-panel";
import { HEALTH_STATUSES } from "#shared/consts";
import { ControllerSelectColumn } from "#shared/generated/graphql";
import type { Texts } from "#shared/types";

import {
  DataGrid,
  DataGridGroups,
  DataGridWrapper,
  FiltersBarWrapper,
  GroupByButtonWrapper,
  GroupBySelect,
  type GroupByOption,
} from "#organization/components/data-grid";
import { AlertTable } from "#organization/pages/authenticated/alert-manager/tabs/alert-logs/components/alerts-table";
import { FlyoutMenuBreadcrumbAppender } from "#organization/recoil/breadcrumbs";
import { projectContextState } from "#organization/recoil/project";

import {
  useControllerAgentInfo,
  useControllerAgentsData,
  useControllerAgentsGroupData,
  useControllerAgentsGroupsTitles,
} from "./hooks";
import { filters, headCells } from "./table";
import { FlyoutMenuTabs } from "./tabs";

import {
  FlyoutMenuContentContainer,
  FlyoutMenuHeaderContainer,
} from "../../../../../../components/flyout-menu/styled";
import { AgentFlyoutMenuHeader } from "../../agents/flyout-menu/components";
import { AgentFlyoutMenuTabs } from "../../agents/flyout-menu/tabs";
import { AlertJobs } from "../../agents/flyout-menu/tabs/alert-jobs";
import type { AgentsFlyoutMenuConfig } from "../../agents/types";
import type { ControllersFlyoutMenuConfig } from "../types";

type Text =
  | "title"
  | "agents"
  | "agentsGroups"
  | "agentsStatus"
  | "aperture"
  | "controller";

interface ControllerFlyoutMenuWrapperProps {
  controller: ControllersFlyoutMenuConfig;
  texts?: Texts<Text>;
  hideFlyoutMenu: () => void;
}

const enTexts: Required<ControllerFlyoutMenuWrapperProps["texts"]> = {
  aperture: "Aperture",
  title: "Controllers",
  controller: "Controller",
  agents: "Agents",
  agentsGroups: "Agents groups",
  agentsStatus: "Agents status",
};

const GROUP_BYS: GroupByOption<ControllerSelectColumn>[] = [
  {
    title: "No value",
    value: "",
  },
  {
    title: "Controller keys",
    value: ControllerSelectColumn.AgentKeyId,
  },
  {
    title: "Version",
    value: ControllerSelectColumn.Version,
  },
  {
    title: "Status",
    value: ControllerSelectColumn.Status,
  },
];

const INITIAL_ORDER_BY = "hostname";
const INITIAL_ORDER = "asc";
const TABLE_UNIQUE_ID = "controllers-agent-table";

export const ControllerFlyoutMenuWrapper: FC<
  ControllerFlyoutMenuWrapperProps
> = ({ controller, texts = enTexts, hideFlyoutMenu }) => {
  const [projectContext] = useRecoilState(projectContextState);
  const controllerAgentInfo = useControllerAgentInfo(
    controller.id,
    projectContext.id,
  );
  const filterHeaders: FilterLabelsType[] = filters(
    projectContext.id,
    controller.id,
  );
  const [filterVariables, setFilterVariables] = useState(
    useParseLinkAttributesToFilterVariables(TABLE_UNIQUE_ID),
  );
  const chipsContainer = useChipsContainer();
  const [groupByKey, setGroupByKey] =
    useState<(typeof GROUP_BYS)[number]["value"]>("");
  const [selectedAgent, setSelectedAgent] = useState<AgentsFlyoutMenuConfig>({
    id: "",
    name: "",
    agentGroup: "",
    agentKey: "",
    status: "",
    version: "",
    lastSeen: "",
  });
  const [isAgent, setIsAgent] = useState(false);

  const headerTable = headCells(setIsAgent, setSelectedAgent);

  const [activeTab, setActiveTab] = useState(0);

  const onTabsChange = useCallback<Required<TabsProps>["onChange"]>(
    (_, tabValue: number) => {
      if (tabValue === activeTab) {
        return;
      }
      setActiveTab(tabValue);
    },
    [activeTab],
  );

  useEffect(() => {
    if (!isAgent && activeTab !== 0) {
      setActiveTab(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAgent]);

  return (
    <>
      <FlyoutMenuHeaderContainer
        sx={{
          paddingBottom: 0,
          justifyContent: "space-between",
        }}
      >
        <FlyoutMenuBreadcrumbAppender>
          <BreadcrumbButton onClick={hideFlyoutMenu}>
            {texts.aperture}
          </BreadcrumbButton>
        </FlyoutMenuBreadcrumbAppender>
        <FlyoutMenuBreadcrumbAppender>
          <BreadcrumbButton onClick={hideFlyoutMenu}>
            {texts.title}
          </BreadcrumbButton>
        </FlyoutMenuBreadcrumbAppender>
        <FlyoutMenuBreadcrumbAppender>
          {isAgent ? (
            <BreadcrumbButton
              onClick={() => {
                setIsAgent(false);
              }}
            >
              {controller.name}
            </BreadcrumbButton>
          ) : (
            <Typography>{controller.name}</Typography>
          )}
        </FlyoutMenuBreadcrumbAppender>
        {isAgent && (
          <FlyoutMenuBreadcrumbAppender>
            <Typography>{selectedAgent.name}</Typography>
          </FlyoutMenuBreadcrumbAppender>
        )}

        {isAgent ? (
          <AgentFlyoutMenuHeader agent={selectedAgent} />
        ) : (
          <>
            <Box display="flex" alignItems="center" columnGap={1}>
              <Typography variant="h6">{controller.name}</Typography>
              {controller.status === HEALTH_STATUSES.ARCHIVED && (
                <Chip label="archived" variant="outlined" />
              )}
              <Chip
                label={texts.controller.toLocaleLowerCase()}
                color="success"
              />
            </Box>

            <Box display="grid" gridTemplateColumns="268px 1fr" pt={2} pb={1}>
              <Typography variant="body2">{texts.agentsGroups}</Typography>
              <Typography variant="body2">
                {controllerAgentInfo.agentGroupsCount}
              </Typography>
              <Typography variant="body2">{texts.agents}</Typography>
              <Typography variant="body2">
                {controllerAgentInfo.agentsTotalCount}
              </Typography>
              <Typography variant="body2">{texts.agentsStatus}</Typography>
              <Box display="flex" gap={1} alignItems="center">
                {controllerAgentInfo.agentsHealth.map((status) => (
                  <React.Fragment key={status.name}>
                    <Box
                      sx={{
                        height: "10px",
                        width: "10px",
                        borderRadius: "100%",
                        backgroundColor: status.color,
                      }}
                    />
                    <Typography variant="body2" color={status.color}>
                      {status.value} {status.name}
                    </Typography>
                  </React.Fragment>
                ))}
              </Box>
            </Box>
          </>
        )}

        {isAgent ? (
          <AgentFlyoutMenuTabs {...{ activeTab, onChange: onTabsChange }} />
        ) : (
          <FlyoutMenuTabs {...{ activeTab, onChange: onTabsChange }} />
        )}
      </FlyoutMenuHeaderContainer>

      <TabPanel value={0} activeValue={activeTab}>
        <FlyoutMenuContentContainer>
          {isAgent ? (
            <AlertTable
              additionalFilterVariables={{
                labels: { contains: { instance: selectedAgent.name } },
              }}
            />
          ) : (
            <>
              <FiltersBarWrapper enableArchivedData>
                <FilteringGroup
                  labels={filterHeaders}
                  onFilter={setFilterVariables}
                  chipsContainer={chipsContainer}
                  uniqueId={TABLE_UNIQUE_ID}
                />
                <GroupByButtonWrapper>
                  <GroupBySelect
                    options={GROUP_BYS}
                    setGroupByKey={setGroupByKey}
                    groupByKey={groupByKey}
                  />
                </GroupByButtonWrapper>
              </FiltersBarWrapper>

              <Box py={4}>
                <DataGridWrapper>
                  {groupByKey ? (
                    <DataGridGroups
                      headCells={headerTable}
                      useGroupsTitles={useControllerAgentsGroupsTitles}
                      useGroupData={useControllerAgentsGroupData}
                      groupByKey={groupByKey}
                      filterVariables={{
                        ...filterVariables,
                        controllerID: { eq: controller.id },
                      }}
                      initialOrderBy={INITIAL_ORDER_BY}
                      initialOrder={INITIAL_ORDER}
                      enableArchivedData
                    />
                  ) : (
                    <DataGrid
                      headCells={headerTable}
                      useGridData={useControllerAgentsData}
                      enabled={!groupByKey}
                      filterVariables={{
                        ...filterVariables,
                        controllerID: { eq: controller.id },
                      }}
                      initialOrderBy={INITIAL_ORDER_BY}
                      initialOrder={INITIAL_ORDER}
                      enableArchivedData
                    />
                  )}
                </DataGridWrapper>
              </Box>
            </>
          )}
        </FlyoutMenuContentContainer>
      </TabPanel>
      <TabPanel value={1} activeValue={activeTab}>
        <FlyoutMenuContentContainer>
          <AlertJobs
            data={{
              type: isAgent ? "agent" : "controller",
              id: isAgent ? selectedAgent.id : controller.id,
            }}
          />
        </FlyoutMenuContentContainer>
      </TabPanel>
    </>
  );
};
