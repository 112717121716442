import React, { type FC } from "react";

import {
  SignInWithButton,
  type SignInWithButtonProps,
} from "#shared/components/layouts/sign-in";
import { type Texts } from "#shared/types";

export interface SignInWithSamlButtonProps extends SignInWithButtonProps {
  texts?: Texts<Text>;
  displayName: string;
}

type Text = "label";

const enTexts: Required<SignInWithSamlButtonProps["texts"]> = {
  label: "Sign in with",
};

export const SignInWithSamlButton: FC<SignInWithSamlButtonProps> = ({
  texts = enTexts,
  displayName,
  ...props
}) => (
  <SignInWithButton {...props}>
    {texts.label} {displayName}
  </SignInWithButton>
);
