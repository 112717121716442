import { CodeSnippet, CodeBlockWithCopyButton } from "#organization/components";

export const language = "py";

[Aperture Python SDK][pythonsdk] can be used to manually set feature control
points within a Python service.

<CodeBlockWithCopyButton language="shell" code={` pip install aperture-py `} />

To do so, first create an instance of ApertureClient:

<CodeSnippet lang={language} snippetName="clientConstructor" />

The created instance can then be used to start a flow:

<CodeSnippet lang={language} snippetName="manualFlow" />

You can also use the flow as a context manager:

<CodeSnippet lang={language} snippetName="contextManagerFlow" />

Additionally, you can decorate any function with Aperture client. This will skip
running the function if the flow is rejected by Aperture Agent. This might be
helpful to handle specific routes in your service.

<CodeSnippet lang={language} snippetName="apertureDecorator" />

You can use the caching feature as follows:

<CodeSnippet lang={language} snippetName="cacheFlow" />

For more context on using the Aperture Python SDK to set feature control points,
refer to the [example app][example] available in the repository.

[pythonsdk]: https://pypi.org/project/aperture-py/
[example]:
  https://github.com/fluxninja/aperture/tree/main/sdks/aperture-py/example
