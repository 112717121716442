import {
  Tabs as MuiTabs,
  Tab,
  type TabsProps as MuiTabsProps,
  type TabProps,
} from "@mui/material";
import { isEmpty } from "lodash";
import React, { type FC } from "react";

import type { AnyObject } from "#shared/types";

export type TabsProps = Omit<MuiTabsProps, "children"> & {
  children: ObjectWithTabProps[];
};

type ObjectWithTabProps<O extends AnyObject = AnyObject> = O & {
  tabProps?: Omit<TabProps, "id"> & { id: string };
};

export const Tabs: FC<TabsProps> = ({ children, ...props }) => {
  if (!props.value) {
    return null;
  }

  return (
    <MuiTabs {...props}>
      {children.map(({ tabProps }) => {
        if (!tabProps || isEmpty(tabProps)) {
          return null;
        }

        const { id, ...otherTabProps } = tabProps;

        return <Tab key={id} {...{ id, ...otherTabProps }} />;
      })}
    </MuiTabs>
  );
};
