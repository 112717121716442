import { List, ListItem, ListItemText } from "@mui/material";
import React, { type FormEvent, type FC } from "react";
import { useParams } from "react-router-dom";

import { LoggerService } from "#shared/services/logger";

import {
  DropdownSearchBox,
  type DropdownControl,
} from "#organization/components/layouts/dropdown-search-box";

import { useAllUserGroupsQuery } from "./hooks";

import { useAddUserGroupToProjectMutation } from "../../../../hooks";
import { USER_GROUPS_SETTINGS_ROUTE } from "../../../../user-groups";

export const AddUserGroupToProjectDropdown: FC<{
  dropdownControl: DropdownControl;
  refetch: () => void;
}> = ({ dropdownControl, refetch }) => {
  const { projectId } = useParams<"projectId">() as { projectId: string };
  const allUserGroups = useAllUserGroupsQuery(projectId);
  const { addUserGroupToProject } = useAddUserGroupToProjectMutation();

  const searchUserGroup = (event: FormEvent<HTMLInputElement>) => {
    allUserGroups.setSearch(event.currentTarget?.value);
  };

  const handleAddProjectToUserGroup = (userGroupId: string | undefined) => {
    if (!userGroupId) return;

    addUserGroupToProject(
      {
        project: projectId,
        userGroup: userGroupId,
        permissions: "read",
      },
      {
        onSuccess: () => {
          refetch();
          allUserGroups.refetch();
        },
        onError: (error) => {
          LoggerService.error(error);
        },
      },
    );
  };

  return (
    <DropdownSearchBox
      idDropdown={dropdownControl.id}
      isOpen={dropdownControl.open}
      anchorEl={dropdownControl.dropdownElement}
      dropdownHeaderName={USER_GROUPS_SETTINGS_ROUTE.TITLE}
      inputOnChange={searchUserGroup}
      defaultInputValue={allUserGroups.search}
      onClickAwayHandler={dropdownControl.closePopper}
    >
      <List disablePadding aria-label="dropdown list">
        {allUserGroups.projects?.map((userGroup) => (
          <ListItem
            key={userGroup.node.id}
            button
            onClick={() => handleAddProjectToUserGroup(userGroup.node.id)}
            disabled={userGroup?.node?.userGroupProjectAssignments?.edges?.some(
              (edge) => edge?.node?.project?.id === projectId,
            )}
          >
            <ListItemText primary={userGroup.node.name} />
          </ListItem>
        ))}
        {!allUserGroups.projects?.length && (
          <ListItem>
            <ListItemText>No more user group to add.</ListItemText>
          </ListItem>
        )}
      </List>
    </DropdownSearchBox>
  );
};
