import { gql } from "graphql-request";

import type { GetUserGroupMembershipsQuery } from "#shared/generated/graphql";

import { useGridData, type DataGridHook } from "#organization/hooks";

export const UserGroupMembershipsQuery = gql`
  query getUserGroupMemberships(
    $where: UserGroupMembershipBoolExp!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    userGroupMemberships(
      where: $where
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          user {
            id
            name
            email
            organizationMemberships {
              edges {
                node {
                  role
                  status
                }
              }
            }
          }
        }
      }
    }
  }
`;

type UserGroupMembersData =
  GetUserGroupMembershipsQuery["userGroupMemberships"]["edges"][0]["node"];

type AdaptedUserGroupMembers = {
  id: string;
  name: string;
  email: string;
  role: string;
  membershipId: string;
  status: string;
};

export const MEMBERS_SECTION_QUERY_KEY = "userGroupMemberships";

export const useMembers: DataGridHook = (
  pageSize,
  page,
  setPage,
  order,
  orderBy,
  enabled,
  filterVariables,
) =>
  useGridData<UserGroupMembersData, AdaptedUserGroupMembers>(
    MEMBERS_SECTION_QUERY_KEY,
    UserGroupMembershipsQuery,
    pageSize,
    page,
    setPage,
    order,
    orderBy,
    filterVariables,
    (userGroupMemberships) => ({
      id: userGroupMemberships.user.id,
      name: userGroupMemberships.user.name,
      email: userGroupMemberships.user.email,
      role: userGroupMemberships.user.organizationMemberships.edges[0].node
        .role,
      membershipId: userGroupMemberships.id,
      status:
        userGroupMemberships.user.organizationMemberships.edges[0].node?.status,
    }),
    enabled,
    false,
  );
