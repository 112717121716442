import React, { type FC, type PropsWithChildren, useMemo } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import { STATUS_CODES, QUERY_RETRY_COUNT } from "./apps/shared/consts";
import { LoggerService } from "./apps/shared/services";

export type AppQueryProviderProps = PropsWithChildren;

export const AppQueryProvider: FC<AppQueryProviderProps> = ({ children }) => {
  const queryClient = useMemo(() => {
    const client = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: (failureCount, error) => {
            if (isUnauthorized(error)) {
              return false;
            }

            if (
              error instanceof Error &&
              String(STATUS_CODES.NotFound) === error.message
            ) {
              return false;
            }

            return failureCount < QUERY_RETRY_COUNT;
          },
          onError: async (error) => {
            if (!isUnauthorized(error)) {
              return;
            }

            try {
              await client.cancelQueries();
            } catch {
              LoggerService.error("Failed to cancel queries");
            }
          },
        },
      },
    });

    return client;
  }, []);

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

function isUnauthorized<E extends unknown>(error: E) {
  /* eslint-disable-next-line  */
  // @ts-ignore
  const response = error?.response as Response;

  return response?.status === 401;
}
