import { isEqual, isFunction } from "lodash";
import { useEffect, useMemo, useState } from "react";
import {
  useFormContext,
  type FieldValues,
  type UseFormWatch,
} from "react-hook-form";
import { useLocation } from "react-router-dom";

import { useAlert } from "#shared/components/alerts-provider";
import type { AnyObject } from "#shared/types";

import { generateUpdatedBlueprintJson } from "./utils";

import {
  convertToJson,
  convertToYaml,
  useCreatePolicyContext,
  type SubmitPolicyValues,
} from "../../../context";
import {
  getYamlEditorErrors,
  useCreatePolicySearchParams,
} from "../../../hooks";

export const useBlueprintJson = <TJson extends AnyObject>(
  defaultValues?: TJson,
  isDirty?: boolean,
) => {
  const {
    editor,
    setCreatePolicyCtx,
    blueprintJson: storedJson,
  } = useCreatePolicyContext();
  const [blueprintJson, setBlueprintJson] = useState<TJson | null>(null);
  const { pathname } = useLocation();
  const {
    searchInfo: { policyID },
  } = useCreatePolicySearchParams();

  const isDefaultValuesAllowed = useMemo(
    () =>
      !policyID &&
      !pathname.includes("edit-policy") &&
      defaultValues &&
      !isDirty,
    [defaultValues, isDirty, pathname, policyID],
  );

  useEffect(() => {
    const blueprint = editor?.getValue();

    if (!blueprint?.length || !editor) {
      if (!storedJson && isDefaultValuesAllowed && defaultValues) {
        setCreatePolicyCtx((prev) => ({
          ...prev,
          blueprintJson: defaultValues,
          blueprintYaml: convertToYaml(defaultValues),
        }));
      }

      if (!blueprintJson) {
        setBlueprintJson(
          isDefaultValuesAllowed
            ? defaultValues || (storedJson as TJson)
            : (storedJson as TJson),
        );
      }

      return;
    }

    // editor exits and we will use editor values
    const json = convertToJson<TJson>(blueprint);

    if (isEqual(storedJson, json) && blueprintJson) {
      return;
    }

    setCreatePolicyCtx((prev) => ({
      ...prev,
      blueprintJson: json,
      blueprintYaml: blueprint,
    }));

    setBlueprintJson(json);
  }, [
    blueprintJson,
    defaultValues,
    editor,
    isDefaultValuesAllowed,
    setCreatePolicyCtx,
    storedJson,
  ]);

  return { blueprintJson };
};

export const useSyncWithEditor = <TFields extends FieldValues = FieldValues>(
  watch: UseFormWatch<TFields>,
) => {
  const { editor, setCreatePolicyCtx, blueprintJson } =
    useCreatePolicyContext();

  useEffect(() => {
    const subscribe = watch((value, { name }) => {
      const { blueprintJson: json, blueprintYaml: yaml } =
        generateUpdatedBlueprintJson<TFields>(value, blueprintJson, name);

      setCreatePolicyCtx((prev) => ({
        ...prev,
        blueprintJson: json,
        blueprintYaml: yaml,
      }));

      editor?.setValue(yaml);
    });

    return () => subscribe.unsubscribe();
  }, [blueprintJson, editor, setCreatePolicyCtx, watch]);
};

export const useSubmitPolicyBuilder = (checkMiniEditorsError = false) => {
  const {
    blueprintJson: data,
    miniEditors,
    showEditor,
    submitPolicyValues,
    setCreatePolicyCtx,
    updateException,
  } = useCreatePolicyContext();
  const { addAlert } = useAlert();

  const formCtx = useFormContext();

  const submitPolicyForm: SubmitPolicyValues = (callback) => {
    const blueprintYaml = data ? convertToYaml(data) : "";

    if (formCtx?.trigger) {
      formCtx.trigger().then((isValid) => {
        const payload = isFunction(updateException)
          ? updateException()
          : {
              blueprintJson: data,
              blueprintYaml,
            };

        setCreatePolicyCtx((prev) => ({
          ...prev,
          ...payload,
        }));

        if (!isValid) {
          callback(true, payload);

          addAlert({
            type: "error",
            message: "Invalid form values, please fill required fields",
          });

          return;
        }

        if (checkMiniEditorsError) {
          const editorErrors = miniEditors
            ?.map((e) => getYamlEditorErrors(e))
            .flat();

          if (editorErrors?.length) {
            addAlert({
              type: "error",
              message: "Error in editor",
            });

            callback(true, payload);

            return;
          }
        }

        callback(false, payload);
      });
    }
  };

  return {
    submitter:
      showEditor && submitPolicyValues ? submitPolicyValues : submitPolicyForm,
    submitPolicyForm,
  };
};
