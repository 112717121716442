import {
  ArrowDropDown,
  ArrowDropUp,
  Search,
  AddBox,
} from "@mui/icons-material";
import {
  Box,
  Checkbox,
  ClickAwayListener,
  Divider,
  Grow,
  InputAdornment,
  Popper,
  Typography,
  type BoxProps,
  useTheme,
} from "@mui/material";
import { noop } from "lodash";
import React, { type CSSProperties, type FC, useState } from "react";
import { useQueryClient } from "react-query";

import { useAlert } from "#shared/components/alerts-provider";
import { Input } from "#shared/components/inputs/input";
import { LoggerService } from "#shared/services/logger";

import { useOrganizationState } from "#organization/recoil/organization";

import { FakeSelect, ListButton, type FakeSelectProps } from "./styled";

import type { FnTheme } from "../../../../../../../../../app-theme-provider/types";
import type { InviteMembersFormData } from "../../../../../common/use-invite-members/types";
import { useAddUserGroupMutation } from "../../../../organization/components/create-user-group-dialog/hooks";
import {
  ALL_USER_GROUP_DATA_KEY,
  useAllUserGroupsQuery,
} from "../../../../organization/hooks-new";

export interface UserGroupSelectProps {
  initialData: { id: string; name: string };
  onChange: (userGroup: InviteMembersFormData["userGroup"]) => void;
  selectProps?: Omit<FakeSelectProps, "children">;
}

export const UserGroupSelect: FC<UserGroupSelectProps> = ({
  initialData,
  onChange,
  selectProps: { disabled, ...selectProps } = {},
}) => {
  const { userGroups, search, setSearch, loading } = useAllUserGroupsQuery();
  const organizationState = useOrganizationState();
  const [currentUserGroup, setCurrentUserGroup] = useState({
    id: initialData.id,
    name: initialData.name,
  });
  const { addAlert } = useAlert();
  const [isOpen, openToggle] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [newUserGroup, setNewUserGroup] = useState("");
  const userGroupController = useAddUserGroupMutation();

  const theme = useTheme() as unknown as FnTheme;

  const handleAddUserGroup: BoxProps["onClick"] = () => {
    if (newUserGroup !== "") {
      userGroupController.addUserGroup(
        { name: newUserGroup, organization: organizationState.id },
        {
          onSuccess: (response) => {
            addAlert({
              type: "success",
              message: `User group ${response.createUserGroup.userGroup.name} added successfully!`,
            });
            refetch();
          },
          onError: (error) => {
            LoggerService.error(error);

            addAlert({ type: "error", message: "User group was not added!" });
          },
        },
      );
    }
  };

  const onClick: FakeSelectProps["onClick"] = (event) => {
    if (disabled) {
      return;
    }

    setAnchorEl(event.currentTarget);
    openToggle(!isOpen);
    (selectProps?.onClick || noop)(event);
  };

  const canBeOpen = !disabled && isOpen && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  const queryClient = useQueryClient();

  const refetch = () =>
    queryClient.invalidateQueries([ALL_USER_GROUP_DATA_KEY]);

  return (
    <>
      <FakeSelect {...{ onClick }}>
        <Typography
          component="span"
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            color: "inherit",
            cursor: "inherit",
          }}
          noWrap
        >
          {currentUserGroup.name}
        </Typography>
        {isOpen ? (
          <ArrowDropUp sx={{ marginLeft: "auto" }} />
        ) : (
          <ArrowDropDown sx={{ marginLeft: "auto" }} />
        )}
      </FakeSelect>
      <Popper
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 4],
            },
          },
        ]}
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        transition
        style={{ zIndex: theme.zIndex.popoverInDialog }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              openToggle(!isOpen);
            }}
          >
            <Grow {...TransitionProps} timeout={350}>
              <Box
                boxShadow="8"
                sx={{
                  width: "260px",
                  borderRadius: "4px",
                  backgroundColor: "background.paper",
                  backgroundImage:
                    "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
                }}
              >
                <Input
                  width="100%"
                  InputProps={{
                    style: { border: "none" } as CSSProperties,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search sx={{ color: "primary.100" }} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event) => {
                    setSearch(event.currentTarget.value);
                  }}
                  placeholder="Search"
                  defaultValue={search}
                />
                <Divider />
                <Box display="grid" gridTemplateColumns="1fr auto">
                  <Input
                    sx={{ width: "100%" }}
                    placeholder="Add user group"
                    onChange={(event) => {
                      setNewUserGroup(event.currentTarget.value);
                    }}
                  />
                  <Box
                    display="flex"
                    justifyItems="center"
                    alignItems="center"
                    px={1}
                    onClick={handleAddUserGroup}
                    sx={{ backgroundColor: "background.inputField" }}
                  >
                    <AddBox
                      sx={{
                        fontSize: "17px",
                        fill: "primary.500",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                </Box>
                <Divider />
                {!loading && (
                  <Box py={1}>
                    {userGroups?.map((userGroup) => (
                      <ListButton
                        key={userGroup.node.id}
                        onClick={() => {
                          setCurrentUserGroup({
                            id: userGroup.node.id,
                            name: userGroup.node.name,
                          });
                          onChange(userGroup.node.id);
                        }}
                      >
                        <Checkbox
                          checked={currentUserGroup.id === userGroup.node.id}
                          size="small"
                        />
                        <Typography noWrap>{userGroup.node.name}</Typography>
                      </ListButton>
                    ))}
                  </Box>
                )}
              </Box>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};
