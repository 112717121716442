import { Box } from "@mui/system";
import React, { type FC } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { BreadcrumbLink } from "#shared/components/breadcrumbs";
import { FeatureFlag } from "#shared/utils";

import { TabsPanelLayout } from "#organization/components/layouts/tabs-panel";
import { TopBarBreadcrumbAppender } from "#organization/recoil/breadcrumbs";

import {
  ACCOUNT_DETAILS_ROUTE,
  AccountDetailsSettingsPage,
} from "./account-details";
import { CloseAccountSettingsPage, CLOSE_ACCOUNT_ROUTE } from "./close-account";
import { ACCOUNT_SETTINGS_ROUTE } from "./routes.definitions";
import { USER_API_KEYS_ROUTE, UserApiKeysPage } from "./user-api-keys";

import { SETTINGS_ROUTE } from "../routes.definitions";
import { getActiveVerticalTab } from "../utils";

// NOTE: Hide security route if firebase is enabled
const ACCOUNT_SETTINGS_VERTICAL_TABS_WITH_CLOSE_ACCOUNT = [
  ACCOUNT_DETAILS_ROUTE,
  CLOSE_ACCOUNT_ROUTE,
];
// NOTE: Hide security route if firebase is enabled
const ACCOUNT_SETTINGS_VERTICAL_TABS_WITHOUT_CLOSE_ACCOUNT = [
  ACCOUNT_DETAILS_ROUTE,
  USER_API_KEYS_ROUTE,
];

export const AccountSettingsRoutes: FC = () => {
  const { pathname } = useLocation();

  const routes = (
    <Routes>
      <Route
        path={ACCOUNT_DETAILS_ROUTE.KEY}
        element={<AccountDetailsSettingsPage />}
      />
      <Route path={USER_API_KEYS_ROUTE.KEY} element={<UserApiKeysPage />} />
      <Route
        path={CLOSE_ACCOUNT_ROUTE.KEY}
        element={
          <FeatureFlag
            flag="closeAccount"
            renderOn={<CloseAccountSettingsPage />}
            // TODO - navigate to main page
            // TODO - we need some 404 page
            renderOff={null}
          />
        }
      />
      <Route
        path="*"
        element={<Navigate to={ACCOUNT_DETAILS_ROUTE.ABSOLUTE_PATH} />}
      />
    </Routes>
  );

  return (
    <>
      <TopBarBreadcrumbAppender>
        <BreadcrumbLink to={SETTINGS_ROUTE.ABSOLUTE_PATH}>
          {SETTINGS_ROUTE.TITLE}
        </BreadcrumbLink>
      </TopBarBreadcrumbAppender>
      <TopBarBreadcrumbAppender>
        {ACCOUNT_SETTINGS_ROUTE.TITLE}
      </TopBarBreadcrumbAppender>
      <Box px={3}>
        <FeatureFlag
          flag="closeAccount"
          renderOn={
            <TabsPanelLayout
              activeTab={getActiveVerticalTab(
                pathname,
                ACCOUNT_SETTINGS_VERTICAL_TABS_WITH_CLOSE_ACCOUNT,
              )}
              tabsRoutes={ACCOUNT_SETTINGS_VERTICAL_TABS_WITH_CLOSE_ACCOUNT}
              tabPanelRoutes={routes}
              pageTitle={ACCOUNT_SETTINGS_ROUTE.TITLE}
            />
          }
          renderOff={
            <TabsPanelLayout
              activeTab={getActiveVerticalTab(
                pathname,
                ACCOUNT_SETTINGS_VERTICAL_TABS_WITHOUT_CLOSE_ACCOUNT,
              )}
              tabsRoutes={ACCOUNT_SETTINGS_VERTICAL_TABS_WITHOUT_CLOSE_ACCOUNT}
              tabPanelRoutes={routes}
              pageTitle={ACCOUNT_SETTINGS_ROUTE.TITLE}
            />
          }
        />
      </Box>
    </>
  );
};
