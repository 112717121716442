import { gql } from "graphql-request";

import type { FilterLabelsType } from "./filter";

export const FILTER_EMPTY_LABEL: FilterLabelsType = {
  key: "not found",
  name: "",
  category: "",
  id: 0,
  gql: gql`
    query null {
      users {
        totalCount
      }
    }
  `,
  selected: false,
  variable: (prop) => ({ prop }),
  uniqueKey: "",
  filterPath: "not found",
};

export const OPERATORS_SYMBOLS: string[] = [
  "isNull",
  "isNull",
  "eq",
  "regex",
  "like",
];

export interface OrType {
  [key: string]: { eq: string | number };
}

export interface AndType {
  [key: string]: OrType[];
}
