import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { noop } from "lodash";
import React, { type FC } from "react";

import { useAlert } from "#shared/components/alerts-provider";
import {
  DialogLayout,
  type DialogControl,
} from "#shared/components/layouts/dialog";
import type { Texts } from "#shared/types";

import { useProjectContextState } from "#organization/recoil/project";

import { useAddSecret, useSecretNames } from "../hooks";

export declare type AddSecretDialogProps = {
  texts?: Texts;
  dialogControl: DialogControl;
  onSecretCreated?: () => void;
};

type Text =
  | "dialogTitle"
  | "dialogContentText"
  | "inputSecretName"
  | "inputSecretValue"
  | "buttonConfirmText"
  | "cancel";

export const enTexts: Texts<Text> = {
  dialogTitle: "Create New Secret",
  dialogContentText:
    "Enter the secret name and value to create a new aperture secret",
  inputSecretName: "Secret Name",
  inputSecretValue: "Secret Value",
  buttonConfirmText: "Create Secret",
  cancel: "Cancel",
};

export const AddSecretDialog: FC<AddSecretDialogProps> = ({
  dialogControl,
  onSecretCreated = noop,
  texts = enTexts,
}) => {
  const [secretName, setSecretName] = React.useState<string>("");
  const [secretValue, setSecretValue] = React.useState<string>("");

  const { mutate: createSecretMutation, isLoading } = useAddSecret();
  const secretNames = useSecretNames();

  const { addAlert } = useAlert();

  const { id: projectID } = useProjectContextState();

  const createSecret = () => {
    const secretNameTrimmed = secretName.trim();

    if (secretNames.includes(secretNameTrimmed)) {
      addAlert({
        message: `Secret already exists. Please use a unique secret name`,
        type: "error",
      });

      return;
    }

    createSecretMutation(
      { name: secretNameTrimmed, value: secretValue, project: projectID },
      {
        onSuccess: () => {
          addAlert({
            message: "Secret created successfully",
            type: "success",
          });
          setSecretName("");
          setSecretValue("");
          onSecretCreated();
          dialogControl.close();
        },
        onError: (e) => {
          addAlert({
            message: `Failed to create secret: ${e}`,
            type: "error",
          });
        },
      },
    );
  };

  return (
    <DialogLayout
      dialogTitle={texts.dialogTitle}
      onCloseDialog={dialogControl.close}
      openDialog={dialogControl.isOpen}
      onClickDialogClose={dialogControl.close}
      buttonConfirmText={texts.buttonConfirmText}
      buttonConfirmVariant="contained"
      buttonConfirmColor="primary"
      buttonCancelVariant="outlined"
      buttonCancelColor="primary"
      dialogAction={false}
      buttonConfirmDisabled={!secretName || !secretValue}
      maxWidth="sm"
    >
      <Typography>{texts.dialogContentText}</Typography>
      <Box display="flex" flexDirection="column" gap={2} my={2}>
        <FormControl>
          <InputLabel>{texts.inputSecretName}</InputLabel>
          <OutlinedInput
            value={secretName}
            onChange={(event) => setSecretName(event.target.value)}
            label={texts.inputSecretName}
          />
        </FormControl>
        <FormControl>
          <InputLabel>{texts.inputSecretValue}</InputLabel>
          <OutlinedInput
            value={secretValue}
            onChange={(event) => setSecretValue(event.target.value)}
            label={texts.inputSecretValue}
            maxRows={10}
            multiline
          />
        </FormControl>
        <Box display="flex" justifyContent="end" gap={1} mt={1}>
          <Button variant="text" color="primary" onClick={dialogControl.close}>
            {texts.cancel}
          </Button>
          <Button
            variant="contained"
            endIcon={isLoading ? <CircularProgress size="1em" /> : undefined}
            disabled={!secretName || !secretValue || isLoading}
            onClick={createSecret}
          >
            {texts.buttonConfirmText}
          </Button>
        </Box>
      </Box>
    </DialogLayout>
  );
};
