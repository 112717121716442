import {
  Box,
  Paper,
  type PaperProps,
  styled,
  typographyClasses,
} from "@mui/material";
import React, { type FC } from "react";

import { FlyoutMenuBreadcrumbsBar } from "./flyout-menu-breadcrumbs-bar";

import type { FnTheme } from "../../../../app-theme-provider/types";

export type FlyoutWrapperProps = { isFullScreenMode: boolean };

const CONTROL_BAR_Z_INDEX = 2;

export const ControlBar = styled(Box)(({ theme }) => {
  const { topBarHeight } = (theme as unknown as FnTheme).shape.flyoutMenu;

  return {
    position: "sticky",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    padding: [theme.spacing(2), theme.spacing(1)].join(" "),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.grey[50],
    zIndex: CONTROL_BAR_Z_INDEX,
    height: topBarHeight,
  };
});

export const OuterContainer = styled(Box)(({ theme }) => {
  const {
    shape: { flyoutMenu },
    palette,
  } = theme as unknown as FnTheme;

  return {
    overflow: "hidden",
    paddingBottom: flyoutMenu.topBarHeight,
    backgroundColor: palette.flyoutMenu.background,
  };
});

export const InnerContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  height: "100%",
  width: "100%",
  maxWidth: "100%",
  overflowY: "auto",
  overflowX: "hidden",
}));

const HeaderContainer: FC<PaperProps> = ({ children, ...props }) => (
  <Paper {...props}>
    <FlyoutMenuBreadcrumbsBar />
    {children}
  </Paper>
);

export const FlyoutMenuHeaderContainer = styled(HeaderContainer)(({
  theme,
}) => {
  const { spacing } = theme as unknown as FnTheme;

  return {
    padding: spacing(2),
    display: "flex",
    flexDirection: "column",
    borderRadius: 0,
    boxShadow: "none",
    [`.${typographyClasses.h1}`]: {
      margin: 0,
    },
  };
});

export const FlyoutMenuContentContainer = styled(Box)(({ theme }) => {
  const {
    spacing,
    shape: { flyoutMenu },
  } = theme as unknown as FnTheme;

  return {
    padding: spacing(2),
    minHeight: `calc(100% - 2 * ${flyoutMenu.topBarHeight})`,
    height: `calc(100% - ${flyoutMenu.topBarHeight})`,
    width: "100%",
    maxWidth: "100%",
  };
});
