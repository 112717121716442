import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { format } from "date-fns";
import React from "react";

import type { HeadCell } from "#shared/components/data-grid";
import { PermissionEnum } from "#shared/recoil";

import type { ApiState } from "#organization/components/api-keys";
import { PermissionWrapper } from "#organization/components/permission-wrapper";

import type { AdaptedApiKeysGroupData } from "../hooks";

export const headCells = (
  onRevoke: (apiKey: ApiState) => void,
  _: (apiKey: ApiState) => void,
): HeadCell<AdaptedApiKeysGroupData>[] => [
  {
    accessor: "key",
    label: "Key value",
    key: "key",
  },
  {
    accessor: (row) => format(new Date(row.createdAt), "dd/MM/yyyy HH:mm:ss"),
    label: "Creation date",
    key: "createdAt",
  },
  {
    accessor: (row) => format(new Date(row.expiresAt), "dd/MM/yyyy HH:mm:ss"),
    label: "Expiration date",
    key: "expiresAt",
  },
  {
    accessor: (row) => (
      <Box>
        <PermissionWrapper level={PermissionEnum.user_group_admin}>
          <Button
            color="error"
            startIcon={<ReplyOutlinedIcon />}
            onClick={() => onRevoke({ id: row.id, key: row.key })}
          >
            Revoke
          </Button>
        </PermissionWrapper>
      </Box>
    ),
    label: "Actions",
    key: "revokeKey",
    noSorting: true,
  },
];
