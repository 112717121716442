import {
  CircularProgress,
  type CircularProgressProps,
  FormControlLabel,
  Portal,
  Switch,
  Box,
} from "@mui/material";
import React, {
  type FC,
  type Dispatch,
  type SetStateAction,
  type PropsWithChildren,
} from "react";

export interface CreateArchiveButtonProps {
  enableArchivedData: boolean | undefined;
  useSwitch: {
    showArchives: boolean;
    setShowArchives: Dispatch<SetStateAction<boolean>>;
  };
  checkLoading: boolean;
  circularProgressProps?: CircularProgressProps;
  portalContainerClass?: string;
}

export const CreateArchiveButton: FC<
  PropsWithChildren<CreateArchiveButtonProps>
> = ({
  enableArchivedData,
  useSwitch: { showArchives, setShowArchives },
  checkLoading,
  children,
  portalContainerClass = "__archive-switch",
  circularProgressProps,
}) => (
  <>
    {Array.from(document.getElementsByClassName(portalContainerClass)).map(
      (container, index) => (
        <Portal
          container={container}
          disablePortal={!enableArchivedData}
          key={[index, "archive-portal"].join("")}
        >
          {enableArchivedData &&
            (checkLoading ? (
              <CircularProgress
                role="alert"
                aria-busy="true"
                aria-label="Loading..."
                disableShrink
                size={22}
                {...circularProgressProps}
              />
            ) : (
              <Box
                {...{
                  display: "flex",
                  gap: 0.3,
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={showArchives}
                      onChange={(event) => {
                        setShowArchives(event.target.checked);
                      }}
                    />
                  }
                  label="Show archives"
                  sx={{
                    alignSelf: "flex-end",
                  }}
                />
                {children}
              </Box>
            ))}
        </Portal>
      ),
    )}
  </>
);
