import { get } from "lodash";
import { useEffect, useMemo } from "react";

import type {
  BlueprintPolicyDefinitionsQuery,
  CustomPolicyDefinitionsQuery,
} from "#shared/generated/graphql";
import type { AnyObject } from "#shared/types";

import { useCloudControllerVersion } from "./controllers";
import {
  useBlueprintPolicyDefinition,
  useCustomPolicyDefinitions,
} from "./use-blueprint";
import {
  useCreatePolicySearchParams,
  type SearchInfo,
} from "./use-step-change";

import { normalizeVersion } from "../utils";

export const useEditBlueprint = (enabled: boolean = true) => {
  const {
    searchInfo,
    searchInfo: { policyID, origin, editedAt },
    setSearchParams,
  } = useCreatePolicySearchParams();

  const {
    isLoading: isPolicyDefinitionLoading,
    isError: isPolicyDefinitionError,
    data: policyDefinition,
  } = useBlueprintPolicyDefinition(
    policyID,
    !!(policyID && origin === "Blueprint" && enabled),
    ["getBlueprintPolicyDefinitions", policyID, origin, editedAt],
  );

  const {
    isLoading: isCustomPolicyDefinitionLoading,
    isError: isCustomPolicyDefinitionError,
    data: customPolicyDefinition,
  } = useCustomPolicyDefinitions(
    policyID,
    !!(policyID?.length && origin === "Custom" && enabled),
    ["getCustomPolicyDefinitions", policyID, origin, editedAt],
  );

  const {
    isLoading: isCloudControllerVersionLoading,
    data: cloudControllerVersion,
    isError: isCloudControllerVersionError,
  } = useCloudControllerVersion();

  const { version, commitSha } = get(
    cloudControllerVersion,
    "controllers.edges.0.node",
    {
      version: "",
      commitSha: "",
    },
  );

  const normalizedVersion = useMemo(() => normalizeVersion(version), [version]);

  const { values, search } = useMemo(
    () =>
      getPolicyValues(
        origin,
        policyDefinition,
        customPolicyDefinition,
        searchInfo,
        commitSha,
      ),
    [customPolicyDefinition, origin, policyDefinition, searchInfo, commitSha],
  );

  useEffect(() => {
    if (!search) {
      return;
    }

    setSearchParams(search);
  }, [search, setSearchParams]);

  const { isError, isLoading } = useMemo(
    () => ({
      isLoading:
        (isCloudControllerVersionLoading && !isCloudControllerVersionError) ||
        (isPolicyDefinitionLoading && !isPolicyDefinitionError) ||
        (isCustomPolicyDefinitionLoading && !isCustomPolicyDefinitionError),
      isError:
        isCloudControllerVersionError || origin === "Blueprint"
          ? isPolicyDefinitionError
          : isCustomPolicyDefinitionError,
    }),
    [
      isCloudControllerVersionError,
      isCloudControllerVersionLoading,
      isCustomPolicyDefinitionError,
      isCustomPolicyDefinitionLoading,
      isPolicyDefinitionError,
      isPolicyDefinitionLoading,
      origin,
    ],
  );

  return {
    isLoading,
    isError,
    values,
    blueprintVersion:
      commitSha && commitSha !== "unknown" ? commitSha : normalizedVersion,
    controllerCommitSha: commitSha,
  };
};

export const getPolicyValues = (
  origin: string | null,
  policyDefinition?: BlueprintPolicyDefinitionsQuery,
  customPolicyDefinition?: CustomPolicyDefinitionsQuery,
  searchInfo?: SearchInfo,
  commitSha?: string,
): {
  values: AnyObject | null;
  search: URLSearchParams | null;
} => {
  const {
    blueprintPolicyDefinitions: {
      edges: [
        {
          node: { values, ...remaining },
        },
      ],
    },
  } = policyDefinition || {
    blueprintPolicyDefinitions: {
      edges: [
        {
          node: {
            values: null,
            name: "",
            blueprintName: "",
            blueprintVersion: "",
            editedAt: "",
            status: "",
          },
        },
      ],
    },
  };

  const {
    customPolicyDefinitions: {
      edges: [
        {
          node: { body, ...customRemaining },
        },
      ],
    },
  } = customPolicyDefinition || {
    customPolicyDefinitions: {
      edges: [
        {
          node: {
            body: null,
            id: "",
            name: "",
            editedAt: "",
            status: "",
          },
        },
      ],
    },
  };

  if ((!values && !body) || !origin) {
    return { values: null, search: null };
  }

  if (origin === "Custom" && body) {
    return {
      values: body,
      search: new URLSearchParams({
        ...searchInfo,
        name: customRemaining?.name,
        editedAt: customRemaining?.editedAt,
      }),
    };
  }

  if (origin === "Blueprint" && values) {
    const search: Partial<SearchInfo> = {
      ...(searchInfo || {}),
      blueprintName: remaining.blueprintName,
      selectedUseCase: remaining.blueprintName.split("/")[0] || "",
      name: remaining.name,
      blueprintVersion:
        commitSha && commitSha !== "unknown"
          ? commitSha
          : remaining.blueprintVersion,
      editedAt: remaining.editedAt,
    };

    const blueprintPolicySearchParams = new URLSearchParams(search);

    return {
      values: {
        values,
        blueprintName: remaining.blueprintName,
      },
      search: blueprintPolicySearchParams,
    };
  }

  return {
    values: null,
    search: null,
  };
};
