import type { Variables } from "graphql-request";
import React, { type FC, useState } from "react";

import {
  FilteringGroup,
  useChipsContainer,
} from "#shared/components/filtering/filtering-group";
import { useParseLinkAttributesToFilterVariables } from "#shared/components/filtering/utils";
import { AlertSelectColumn } from "#shared/generated/graphql";

import {
  DataGrid,
  DataGridGroups,
  type DataGridProps,
  DataGridWrapper,
  FiltersBarWrapper,
  GroupByButtonWrapper,
  GroupBySelect,
  type GroupByOption,
} from "#organization/components/data-grid";
import { useProjectContextState } from "#organization/recoil/project";

import { alertsTableHead } from "./head";

import { alertsLogFilterHeaders } from "../../filters";
import {
  useAlertLogsData,
  useAlertsGroupData,
  useAlertsGroupsTitles,
} from "../../hooks";

const INITIAL_ORDER_BY: string = "startsAt";
const INITIAL_ORDER: "asc" | "desc" = "desc";
const TABLE_UNIQUE_ID = "alerts-table";

export type AlertLogsGroupBy = "name" | "severity";

const GROUP_BYS: GroupByOption<AlertSelectColumn>[] = [
  {
    title: "Name",
    value: AlertSelectColumn.Name,
  },
  {
    title: "Severity",
    value: AlertSelectColumn.Severity,
  },
];

export interface AlertTableProps {
  additionalFilterVariables?: Variables;
  hideFilterAndGroupBy?: boolean;
}

export const AlertTable: FC<AlertTableProps> = ({
  additionalFilterVariables = {},
  hideFilterAndGroupBy = false,
}) => {
  const [filterVariables, setFilterVariables] = useState(
    useParseLinkAttributesToFilterVariables(TABLE_UNIQUE_ID),
  );

  const [groupByKey, setGroupByKey] =
    useState<(typeof GROUP_BYS)[number]["value"]>("");
  const project = useProjectContextState();
  const filterLabels = alertsLogFilterHeaders(
    project.id,
    additionalFilterVariables,
  );
  const chipsContainer = useChipsContainer();

  const dataGridProps: DataGridProps = {
    headCells: alertsTableHead(),
    useGridData: useAlertLogsData,
    initialOrder: INITIAL_ORDER,
    initialOrderBy: INITIAL_ORDER_BY,
    enabled: !groupByKey,
    filterVariables: {
      ...filterVariables,
      ...additionalFilterVariables,
    },
  };

  return (
    <>
      {!hideFilterAndGroupBy && (
        <FiltersBarWrapper>
          <FilteringGroup
            labels={filterLabels}
            onFilter={setFilterVariables}
            chipsContainer={chipsContainer}
            uniqueId={TABLE_UNIQUE_ID}
          />
          <GroupByButtonWrapper>
            <GroupBySelect
              options={GROUP_BYS}
              setGroupByKey={setGroupByKey}
              groupByKey={groupByKey}
            />
          </GroupByButtonWrapper>
        </FiltersBarWrapper>
      )}
      <DataGridWrapper boxProps={{ pt: 3 }}>
        {groupByKey ? (
          <DataGridGroups
            headCells={alertsTableHead()}
            useGroupsTitles={useAlertsGroupsTitles}
            useGroupData={useAlertsGroupData}
            groupByKey={groupByKey}
            filterVariables={{
              ...filterVariables,
              ...additionalFilterVariables,
            }}
            initialOrderBy={INITIAL_ORDER_BY}
            initialOrder={INITIAL_ORDER}
          />
        ) : (
          <DataGrid {...dataGridProps} />
        )}
      </DataGridWrapper>
    </>
  );
};
