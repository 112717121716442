import { SvgIcon, type SvgIconProps } from "@mui/material";
import React, { forwardRef, type FC, type PropsWithRef } from "react";

export const BookmarkFolder: FC<PropsWithRef<SvgIconProps>> = forwardRef(
  (props, ref) => (
    <SvgIcon {...props} ref={ref}>
      <path
        d="M10.3916 5.73909L9.34817 4.69561L8.30469 5.73909V2.08691H10.3916V5.73909Z"
        stroke="#F27A40"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.73913 2.08694H12.2174C12.425 2.08694 12.624 2.16939 12.7708 2.31616C12.9175 2.46292 13 2.66198 13 2.86954V10.1739C13 10.3815 12.9175 10.5805 12.7708 10.7273C12.624 10.874 12.425 10.9565 12.2174 10.9565H1.78261C1.57505 10.9565 1.37599 10.874 1.22922 10.7273C1.08245 10.5805 1 10.3815 1 10.1739V1.82607C1 1.61851 1.08245 1.41945 1.22922 1.27268C1.37599 1.12591 1.57505 1.04346 1.78261 1.04346H5.69565L6.73913 2.08694Z"
        stroke="#F27A40"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  ),
);
