import "@mdx-js/react";
import { OpenInNew } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, type FC, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";

import { CopyTextButton } from "#shared/components/copy-text-button";
import type { Texts } from "#shared/types";

import { MainPageLayout } from "#organization/components/layouts";

import {
  SDKList,
  useApiKey,
  useGetAgentEndpoint,
  useGetProjectRegion,
} from "./hooks";

export interface DetailsPageProps {
  texts?: Texts<Text>;
}

type Text =
  | "region"
  | "endpoint"
  | "apiKey"
  | "fetchSnippetFailed"
  | "error"
  | "handleRequest"
  | "setResultCache";

const enTexts: Required<DetailsPageProps["texts"]> = {
  region: "Region",
  endpoint: "Endpoint",
  apiKey: "API Key",
  error: "Error",
  fetchSnippetFailed: "Something went wrong while fetching SDK code snippets",
  handleRequest: "The created instance can then be used to start a flow:",
  setResultCache: "You can use the caching feature to improve performance:",
};

const MAX_PAPER_WIDTH = 1000;

export const DetailsPage: FC<DetailsPageProps> = ({ texts = enTexts }) => {
  const {
    region = "REGION",
    isLoading: isLoadingProjectRegion,
    isError: isErrorProjectRegion,
  } = useGetProjectRegion();

  const endpoint = useGetAgentEndpoint(region);

  const {
    apiKey = "-",
    isLoading: isLoadingApiKey,
    isError: isErrorApiKey,
  } = useApiKey();

  const [selectedLanguage, setSelectedLanguage] = useState<
    (typeof SDKList)[number]["key"] | undefined
  >(SDKList[0]?.key);

  useEffect(() => {
    if (!selectedLanguage && SDKList.length > 0) {
      setSelectedLanguage(SDKList[0].key);
    }
  }, [selectedLanguage]);

  const selectedSDK = useMemo(() => {
    if (!selectedLanguage) return undefined;

    const sdk = SDKList.find((lang) => lang.key === selectedLanguage)!;

    return {
      name: sdk.language,
      link: sdk.link,
      docs: sdk.docs,
    };
  }, [selectedLanguage]);

  const MDXDocs = selectedSDK?.docs;

  return (
    <MainPageLayout>
      <Box display="flex" flexDirection="column" gap={2}>
        <Paper
          sx={(theme) => ({
            padding: theme.spacing(2),
            display: "flex",
            gap: theme.spacing(4),
            maxWidth: MAX_PAPER_WIDTH,
            "> *": {
              minWidth: 100,
            },
          })}
        >
          <Box>
            <Typography variant="subtitle2">{texts.region}</Typography>
            {isLoadingProjectRegion && <CircularProgress size={14} />}
            {isErrorProjectRegion && (
              <Typography color="error">{texts.error}</Typography>
            )}
            {!isLoadingProjectRegion && !isErrorProjectRegion && (
              <CopyTextButton text={region} />
            )}
          </Box>
          <Box>
            <Typography variant="subtitle2">{texts.endpoint}</Typography>
            {isLoadingProjectRegion && <CircularProgress size={14} />}
            {isErrorProjectRegion && (
              <Typography color="error">{texts.error}</Typography>
            )}
            {!isLoadingProjectRegion && !isErrorProjectRegion && (
              <CopyTextButton text={endpoint} />
            )}
          </Box>
          <Box>
            <Typography variant="subtitle2">{texts.apiKey}</Typography>
            {isLoadingApiKey && <CircularProgress size={14} />}
            {isErrorApiKey && (
              <Typography color="error">{texts.error}</Typography>
            )}
            {!isLoadingApiKey && !isErrorApiKey && (
              <CopyTextButton text={apiKey} />
            )}
          </Box>
        </Paper>

        <Paper
          sx={(theme) => ({
            padding: theme.spacing(2),
            marginBottom: theme.spacing(3),
            maxWidth: MAX_PAPER_WIDTH,
          })}
        >
          <Box mb={3}>
            <ButtonGroup
              variant="outlined"
              aria-label="outlined primary button group"
              size="small"
            >
              {SDKList.map((sdk) => (
                <Button
                  key={sdk.key}
                  onClick={() => setSelectedLanguage(sdk.key)}
                  variant={
                    selectedLanguage === sdk.key ? "contained" : "outlined"
                  }
                >
                  {sdk.language}
                </Button>
              ))}
            </ButtonGroup>
          </Box>

          {selectedLanguage && selectedSDK && (
            <Box>
              <Box display="flex" gap={1.5} alignItems="center">
                <Typography variant="h6">
                  Aperture {selectedSDK.name} SDK
                </Typography>
                <Link to={selectedSDK.link} target="_blank">
                  <OpenInNew sx={{ marginTop: "7px" }} />
                </Link>
              </Box>

              {MDXDocs && (
                <Box>
                  <MDXDocs />
                </Box>
              )}
            </Box>
          )}
        </Paper>
      </Box>
    </MainPageLayout>
  );
};
