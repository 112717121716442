import { omit } from "lodash";

// @ts-ignore
import values from "./default.json";

import * as types from "../@types";

export const quotaSchedulingFormDefaultValues = omit(
  values,
  "$schema",
) as types.Coordinate;
