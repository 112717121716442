import {
  API_SERVICE_URL,
  IAB_PROXY_PATHNAME,
  IDP_PROXY_PATHNAME,
} from "#shared/consts";
import { ENV } from "#shared/env";
import { LoggerService } from "#shared/services/logger";

export type QueryKey = keyof typeof QUERY_KEYS;
export type DashboardType = keyof typeof DASHBOARDS;

export const BASE_DOCUMENTATION_URL = "https://docs.fluxninja.com";

export const OWNER_SIGN_IN_PATHNAME = "/sign-in/owner";
export const RESTORE_ACCESS_PATHNAME = "/password/restore";

export const API_URLS = {
  LOGIN: {
    /**
     * NOTE:
     *
     * with short term token that was received in redirect url from idp when signing in with google/idp
     */
    IDP: `${API_SERVICE_URL}/auth/login/idp`,
    BASIC: `${API_SERVICE_URL}/auth/login/basic`,
    GOOGLE: [
      `${API_SERVICE_URL}/auth`,
      IDP_PROXY_PATHNAME,
      "/login/google",
    ].join(""),
    GITHUB: [
      `${API_SERVICE_URL}/auth`,
      IDP_PROXY_PATHNAME,
      "/login/github",
    ].join(""),
  },
  ALERTMANAGER_CONFIG: `${API_SERVICE_URL}/alertmanager-config`,
  INVITE: {
    REMOVE: [
      `${API_SERVICE_URL}/auth`,
      IAB_PROXY_PATHNAME,
      `/organizations-memberships`,
    ].join(""),
    EMAIL: [
      `${API_SERVICE_URL}/auth`,
      IAB_PROXY_PATHNAME,
      `/users/invite/email`,
    ].join(""),
  },
  INTEGRATIONS: {
    POST: [`${API_SERVICE_URL}/auth`, IAB_PROXY_PATHNAME, `/integrations`].join(
      "",
    ),
  },
  CUSTOM_PLAN: {
    POST: [`${API_SERVICE_URL}/auth`, IAB_PROXY_PATHNAME, `/custom-plan`].join(
      "",
    ),
  },
  BILLING: {
    SUBSCRIPTIONS: [
      `${API_SERVICE_URL}/auth`,
      IAB_PROXY_PATHNAME,
      "/billing/subscriptions",
    ].join(""),
    IS_SUBSCRIBED: [
      `${API_SERVICE_URL}/auth`,
      IAB_PROXY_PATHNAME,
      "/billing/is-subscribed",
    ].join(""),
    CANCEL_SUBSCRIPTION: [
      `${API_SERVICE_URL}/auth`,
      IAB_PROXY_PATHNAME,
      "/billing/cancel-subscription",
    ].join(""),
    CREATE_CHECKOUT_HOSTED_PAGE: [
      `${API_SERVICE_URL}/auth`,
      IAB_PROXY_PATHNAME,
      "/billing/create-checkout-hosted-page",
    ].join(""),
    FINISH_SUBSCRIPTION: [
      `${API_SERVICE_URL}/auth`,
      IAB_PROXY_PATHNAME,
      "/billing/finish-subscription",
    ].join(""),
  },
  CONTACT_SUPPORT: {
    POST: [
      `${API_SERVICE_URL}/auth`,
      IAB_PROXY_PATHNAME,
      `/contact-support`,
    ].join(""),
  },
  ORGANIZATIONS: {
    INDEX: [
      API_SERVICE_URL,
      "/auth",
      IAB_PROXY_PATHNAME,
      "/organizations/user",
    ].join(""),
    PROJECT_PROVISIONING_STATUS: [
      API_SERVICE_URL,
      "/auth",
      IAB_PROXY_PATHNAME,
      "/organizations/project/check-provisioning-status",
    ].join(""),
  },
  DEFAULT_SUPPORTED_IDP_CONFIGS: {
    INDEX: [
      API_SERVICE_URL,
      "/auth",
      "/idp",
      "/defaultSupportedIdpConfigs",
    ].join(""),
  },
  INBOUND_SAML_CONFIGS: {
    INDEX: [API_SERVICE_URL, "/auth", "/idp", "/inboundSamlConfigs"].join(""),
  },
  OAUTH_IDP_CONFIGS: {
    INDEX: [API_SERVICE_URL, "/auth", "/idp", "/oauthIdpConfigs"].join(""),
  },
  TENANTS: {
    INDEX: [API_SERVICE_URL, "/auth", "/idp", "/tenants"].join(""),
  },
  TENANT: {
    ID: [
      `${API_SERVICE_URL}/auth`,
      IAB_PROXY_PATHNAME,
      "/tenant/tenantId",
    ].join(""),
  },
  USERS: {
    PASSWORD: (tenantId?: string | null) =>
      [
        API_SERVICE_URL,
        "/auth",
        "/idp",
        "/tenants",
        `/${tenantId}`,
        "/users/password",
      ].join(""),
  },
};

export const DOCUMENTATION_URLS = {
  // TODO: Add documentation url for API keys when there is a page for it
  API_KEYS: `${BASE_DOCUMENTATION_URL}/`,
};

export const QUERY_KEYS = {
  FETCH_DEFAULT_IDP_CONFIG: "fetch-default-idp-config",
  WIDGETS: "widgets",
  FILTER_NAME: "filterName",
  DATA_GRID: "gridData",
  GROUP_DATA: "groupData",
  GROUP_TITLES: "groupTitles",
  ORGANIZATION_MEMBER: "getOrganizationMember",
  FIRST_PROJECT: "getFirstProject",
  SERVICE_CONTROL_POINTS_FLAG: "getServiceControlPointsFlag",
  SERVICE_DETAIL_PAGE: "serviceDetailPage",
  SERVICE_SUMMARY: "serviceSummary",
  ORGANIZATION_OWNER: "getOrganizationOwner",
  USER_GROUP_PER_MEMBERSHIP: "getUserGroupPerMembership",
  ORGANIZATION_DEFAULT_ROLE: "getOrganizationDefaultRole",
  PROJECT_BY_ID: "getProjectById",
  PROJECT_COUNT: "getProjectCount",
  ALERT_MANAGER_ORGANIZATION_CONFIG: "getAlertManagerOrganizationConfig",
  ALERT_MANAGER_PROJECT_CONFIG: "getAlertManagerProjectConfig",
  ORGANIZATION_EMAIL_LIST: "getOrganizationEmailList",
  API_KEYS_SETTINGS: "getApiKeysSettings",
  POLICY_FLOW_CHART: "policyFlowChart",
  POLICY_CONTROLLER: "policyController",
  POLICY_CLASSIFIERS_LIST: "policyClassifiersList",
  POLICY_FLUX_METERS_LIST: "policyFluxMetersList",
  POLICIES: "policies",
  POLICY: "policy",
  FLUX_METERS: "fluxMeters",
  CLASSIFIERS: "classifiers",
  DASHBOARD_FLUX_METER: "dashboardFluxMeter",
  DASHBOARD_CLASSIFIER: "dashboardClassifier",
  DASHBOARD_CIRCUIT: "dashboardCircuit",
  INVALID_QUERY: "invalidQuery",
  SETTINGS_MEMBERS_QUERY_KEY: "users",
  ALERT_CHANNELS: "alertChannels",
  ALERT_LOGS: "alertLogs",
  ALERTS_COUNT_BY_SEVERITY: "alertCountBySeverity",
  POLICY_COMPONENTS_FILTERED_BY_SERVICE: "policyComponentsFilteredByService",
  DISCOVERY_SERVICES_ALL_AND_WITH_CONTROL_POINTS:
    "discoveryServicesAllAndWithControlPoints",
  WELCOME_MEMBERS_QUERY_KEY: "welcomeInvitedUsers",
  WELCOME_AGENTS_COUNT: "welcomeAgentsCount",
  WELCOME_DEFAULT_PROJECT: "welcomeDefaultProject",
  WELCOME_USER_GROUP: "welcomeUserGroup",
  GET_USER_GROUPS: "getUserGroups",
  ORGANIZATIONS: "get-organization-list-data",
  CONFIGURED_IDP_PROVIDERS: "configured-idp-providers",
  CHECK_GQL: "check-gql",
  DEFAULT_SAML_CONFIG: "default-saml-config",
  PROJECT_PROVISIONING_STATUS: "project-provisioning-status",
  PROJECT_STATUS: "project-status",
  PROJECT_NAMES: "project-names",
  PROJECT_DETAILS: "project-details",
};

export const DASHBOARDS = {
  FLOW_ANALYTICS: "flow-analytics",
  FLOW_ANALYTICS_BY_FLOW_LABEL: "flow-analytics-by-flow-label",
  FLOW_ANALYTICS_SDK: "flow-analytics-sdk",
  FLOW_ANALYTICS_SDK_BY_FLOW_LABEL: "flow-analytics-sdk-by-flow-label",
  FLOW_MAP: "flow-map",
  PROMETHEUS: "flux-meter",
  SIGNAL: "signal",
  RATE_LIMITER: "rate-limiter",
  CONCURRENCY_LIMITER: "concurrency-limiter",
  DASHBOARDS: "dashboards",
  CACHE_HIT_MISS: "cache-hit-miss",
};

export const IS_DEV = import.meta.env.DEV;

export const SHOW_IMPLICIT_FILTERS = IS_DEV && false;

LoggerService.debug("[FN] App version:", ENV.FN_VERSION);

export const DOCUMENTATION = {
  INSTALL_APERTURE: `${BASE_DOCUMENTATION_URL}/get-started`,
};
