import { Box, Divider, Typography } from "@mui/material";
import React, { type FC } from "react";

type AlternativeSignInHorizontalBarProps = {
  orText?: string;
};

export const AlternativeSignInHorizontalBar: FC<
  AlternativeSignInHorizontalBarProps
> = ({ orText = "or" }) => (
  <Box display="flex" width="100%" my={4} alignItems="center">
    <Divider sx={{ flex: 1 }} variant="middle" />
    <Typography variant="body1" color="textSecondary">
      {orText}
    </Typography>
    <Divider sx={{ flex: 1 }} variant="middle" />
  </Box>
);
