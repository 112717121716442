import { useContext, useCallback } from "react";

import type { ResourcesMenuListItemProps } from "#organization/components/resources-menu";

import { SelectedResourcesContext } from "../../../selected-resource-context";

export function useIsSelected() {
  const { selectedResource } = useContext(SelectedResourcesContext);

  const isSelected = useCallback(
    (item: ResourcesMenuListItemProps) => {
      if (selectedResource) {
        const selectedItem = selectedResource[1][0];

        return selectedItem.uiData.id === item.item.uiData.id;
      }

      return false;
    },
    [selectedResource],
  );

  return isSelected;
}
