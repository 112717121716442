import { GLOBAL_APP_ABSOLUTE_URL } from "#shared/consts";
import { APP_PORTS } from "#shared/dev-config";
import { ENV } from "#shared/env";

export const getGlobalAppUrl = (path: string) =>
  `${GLOBAL_APP_ABSOLUTE_URL}${path}`;

export const getOrganizationAbsoluteUrl = (organizationSubdomain: string) =>
  `https://${organizationSubdomain}.${ENV.VITE_APP_DOMAIN}${
    ENV.DEV ? `:${APP_PORTS.organization}` : ""
  }`;
