import type { Variables } from "graphql-request";
import type { UseQueryOptions } from "react-query";

import type { AnyObject } from "#shared/types";

import type { DashboardType, QueryKey } from "#organization/pages/consts";

export type UseFnQueryOptions = {
  queryKey?: QueryKey;
  gql?: string;
  variables?: Variables;
  queryOptions?: Pick<UseQueryOptions, "enabled">;
};

/**
 * NOTE:
 * filters: filters enabled in the UI
 * implicitQueryPArams: hidden filters derived from the context
 */
export type DashboardConfig<D extends DashboardType = DashboardType> = {
  uuid: string;
  slug: string;
  version: number;
  name: string;
  /**
   * Explicit filters -> visible in the UI, can be applied by the user
   */
  filters: MapDashboardTypeToDashboardFilters<D>[];
  /**
   * Hidden filters derived from the context
   */
  hiddenVariables: MapDashboardTypeToDashboardFilters<D>[];
  /**
   * Filters passed to grafana in query-params (can be implicit or explicit)
   */
  queryParams: { [F in MapDashboardTypeToDashboardFilters<D>]?: string };
};

export type MapDashboardTypeToDashboardFilters<
  D extends DashboardType = DashboardType,
> = D extends "FLOW_ANALYTICS"
  ? FlowAnalyticsDashboardQueryParam
  : D extends "FLOW_ANALYTICS_BY_FLOW_LABEL"
  ? FlowAnalyticsByFlowLabelDashboardQueryParam
  : D extends "FLOW_MAP"
  ? FlowMapDashboardQueryParam
  : D extends "PROMETHEUS"
  ? PrometheusDashboardQueryParam
  : D extends "SIGNAL"
  ? SignalDashboardQueryParam
  : D extends "RATE_LIMITER"
  ? RateLimiterDashboardQueryParam
  : D extends "CONCURRENCY_LIMITER"
  ? ConcurrencyLimiterDashboardQueryParam
  : D extends "CACHE_HIT_MISS"
  ? CacheDashboardQueryParam
  : never;

export type ResourceType =
  | "FluxMeter"
  | "Classifier"
  | "ConcurrencyLimiter"
  | "RateLimiter"
  | "Signal"
  | "Dashboards"
  | "ConcurrencyScheduler";

export const FluxMeterResource: ResourceType = "FluxMeter";
export const ClassifierResource: ResourceType = "Classifier";
export const ConcurrencyLimiterResource: ResourceType = "ConcurrencyLimiter";
export const RateLimiterResource: ResourceType = "RateLimiter";
export const SignalResource: ResourceType = "Signal";

export type FlowAnalyticsDashboardFilter =
  (typeof httpDashboardFilters)[number];
export type FlowAnalyticsByFlowLabelDashboardFilter =
  (typeof httpDashboardFilters)[number];
export type FlowMapDashboardFilter = (typeof flowMapDashboardFilter)[number];
export type PrometheusDashboardFilter =
  (typeof prometheusDashboardFilters)[number];
export type SignalDashboardFilter = (typeof signalDashboardFilters)[number];

export type CacheDashboardFilter = (typeof cacheDashboardFilters)[number];

export type RateLimiterDashboardFilter =
  (typeof rateLimiterDashboardFilters)[number];

export type ConcurrencyLimiterDashboardFilter =
  (typeof concurrencyLimiterDashboardFilters)[number];

export type FlowAnalyticsDashboardQueryParam =
  `var-${FlowAnalyticsDashboardFilter}`;
export type FlowAnalyticsByFlowLabelDashboardQueryParam =
  `var-${FlowAnalyticsByFlowLabelDashboardFilter}`;
export type FlowMapDashboardQueryParam = `var-${FlowMapDashboardFilter}`;

export type CacheDashboardQueryParam = `var-${CacheDashboardFilter}`;

export type PrometheusDashboardQueryParam = `var-${PrometheusDashboardFilter}`;

export type SignalDashboardQueryParam = `var-${SignalDashboardFilter}`;

export type RateLimiterDashboardQueryParam =
  `var-${RateLimiterDashboardFilter}`;

export type ConcurrencyLimiterDashboardQueryParam =
  `var-${ConcurrencyLimiterDashboardFilter}`;

export type DashboardFilter =
  | FlowAnalyticsDashboardFilter
  | FlowAnalyticsByFlowLabelDashboardFilter
  | PrometheusDashboardQueryParam
  | SignalDashboardFilter
  | RateLimiterDashboardFilter
  | ConcurrencyLimiterDashboardFilter
  | CacheDashboardFilter;

export type DashboardQueryParam =
  | FlowAnalyticsDashboardQueryParam
  | FlowAnalyticsByFlowLabelDashboardQueryParam
  | PrometheusDashboardQueryParam
  | SignalDashboardQueryParam
  | RateLimiterDashboardQueryParam
  | ConcurrencyLimiterDashboardQueryParam
  | CacheDashboardQueryParam;

export const flowMapDashboardFilter = [
  "fn_project_id",
  "controller_id",
  "agent_group",
  "services",
  "control_point",
] as const;

/**
 * NOTE:
 * 1. Based on *dashboards.libsonnet
 *
 * 2. fn_organization_id is read from token so we do not have to provide it.
 * We may have to hide it (list in implicit filters)
 */
export const httpDashboardFilters = [
  "fn_project_id",
  "controller_id",
  "agent_group",
  "services",
  "control_point",
  "load_schedulers",
  "workloads",
  "rate_limiters",
  "flux_meter_name",
  "classifiers",
  "fn_organization_id",
] as const;

export const cacheDashboardFilters = [
  "fn_project_id",
  "controller_id",
  "control_point",
  "type",
  "agent_group",
] as const;

export const prometheusDashboardFilters = [
  "flux_meter_name",
  "fn_project_id",
] as const;

export const signalDashboardFilters = [
  "policy_name",
  "signal_name",
  "sub_circuit_id",
  "fn_project_id",
] as const;

export const rateLimiterDashboardFilters = [
  "controller_id",
  "policy_name",
  "component_id",
  "policy_hash",
  "fn_project_id",
] as const;

export const concurrencyLimiterDashboardFilters = [
  "controller_id",
  "policy_name",
  "component_id",
  "policy_hash",
  "fn_project_id",
] as const;

export const httpDashboardQueryParams = httpDashboardFilters.map(
  (filter) => `var-${filter}` as DashboardQueryParam,
);

export const prometheusDashboardQueryParams = prometheusDashboardFilters.map(
  (filter) => `var-${filter}` as DashboardQueryParam,
);

export const signalDashboardQueryParams = signalDashboardFilters.map(
  (filter) => `var-${filter}` as DashboardQueryParam,
);

export const rateLimiterDashboardQueryParams = rateLimiterDashboardFilters.map(
  (filter) => `var-${filter}` as DashboardQueryParam,
);

export const concurrencyLimiterDashboardQueryParams =
  concurrencyLimiterDashboardFilters.map(
    (filter) => `var-${filter}` as DashboardQueryParam,
  );

export type ResourceWithDashboard = Extract<
  ResourceType,
  | "FluxMeter"
  | "Classifier"
  | "ConcurrencyLimiter"
  | "RateLimiter"
  | "Signal"
  | "Dashboards"
>;

export type ResourceWithGenericDashboard = Extract<
  ResourceType,
  "FluxMeter" | "Classifier" | "ConcurrencyLimiter" | "RateLimiter" | "Signal"
>;

export type WithUiData<P extends AnyObject, UiData extends AnyObject> = P & {
  uiData: UiData;
};
