import { Box } from "@mui/system";
import React, { memo, type FC } from "react";
import { type UseFormReturn } from "react-hook-form";

import { TextInput } from "#shared/components/inputs";
import { CopyTextArea } from "#shared/components/inputs/copy-text-area";
import {
  type DeepPartial,
  type TenantIdpConfigResponse,
  type Texts,
} from "#shared/types";

import { SectionButtons } from "#organization/components/button";

import { SectionHeader, SettingsSectionLabel } from "../../../components";
import { SectionBody, SectionButtonWrapper } from "../../../components/styled";

export interface SamlSignInConfigFormProps {
  texts?: Texts<Text>;
  disabled: boolean;
  onChange: (value: DeepPartial<SamlConfigFormValues>) => void;
  register: UseFormReturn<SamlConfigFormValues, unknown>["register"];
  errors: UseFormReturn<SamlConfigFormValues, unknown>["formState"]["errors"];
  data: SamlConfigFormValues | undefined;
  onSubmit: () => void;
}

export declare type SpConfig = {
  callbackUri: string;
  spEntityId: string;
};

export type SamlConfigFormValues = {
  displayName: string;
  entityId: string;
  ssoUrl: string;
  certificate: string;
  name: string;
  spConfig: SpConfig;
};

export type SamlSignInConfig = NonNullable<
  NonNullable<TenantIdpConfigResponse>["inboundSamlConfigs"]
>[number];

type Text =
  | "configuration"
  | Exclude<keyof SamlConfigFormValues, "name" | "spConfig">
  | "submit"
  | `${Exclude<
      keyof SamlConfigFormValues,
      "enabled" | "name" | "spConfig"
    >}Error`
  | `${Extract<keyof SamlConfigFormValues, "certificate">}Helper`;

const enTexts: Required<SamlSignInConfigFormProps["texts"]> = {
  configuration: "SAML Configuration",
  displayName: "Name",
  entityId: "Entity ID/Issuer",
  ssoUrl: "SSO URL",
  certificate: "Certificate",
  submit: "Submit",
  displayNameError: "Name is required",
  entityIdError: "Entity ID/Issuer is required",
  ssoUrlError: "SSO URL is required",
  certificateError: "Certificate is required",
  certificateHelper:
    'Must begin with "-----BEGIN CERTIFICATE-----", and end with "-----END CERTIFICATE-----"',
};

export const SamlSignInConfigForm: FC<SamlSignInConfigFormProps & {}> = memo(
  ({
    texts = enTexts,
    disabled,
    onChange,
    register,
    errors,
    data,
    onSubmit,
  }) => (
    <>
      <SectionHeader title={texts.configuration} />
      {data?.spConfig.callbackUri && data?.spConfig.spEntityId && (
        <SamlConfigInfo
          callbackUri={data?.spConfig.callbackUri}
          spEntityId={data?.spConfig.spEntityId}
        />
      )}
      <form>
        <SectionBody gridTemplateColumns="20vw 46vw">
          <SettingsSectionLabel primaryText={texts.displayName} asterisk />
          <TextInput
            {...{
              ...register("displayName", {
                required: true,
                disabled,
                onChange: ({ target: { value } }) => {
                  onChange({ displayName: value });
                },
              }),
              placeholder: texts.displayName,
              disabled,
              helperText: errors?.displayName ? texts.displayNameError : "",
              error: !!errors?.displayName,
              value: data?.displayName || "",
            }}
          />
          <SettingsSectionLabel primaryText={texts.entityId} asterisk />
          <TextInput
            {...{
              ...register("entityId", {
                required: true,
                disabled,
                onChange: ({ target: { value } }) => {
                  onChange({ entityId: value });
                },
              }),
              helperText: errors?.entityId ? texts.entityIdError : "",
              error: !!errors?.entityId,
              placeholder: texts.entityId,
              disabled,
              value: data?.entityId || "",
            }}
          />
          <SettingsSectionLabel primaryText={texts.ssoUrl} asterisk />
          <TextInput
            {...{
              ...register("ssoUrl", {
                required: true,
                disabled,
                onChange: ({ target: { value } }) => {
                  onChange({ ssoUrl: value });
                },
              }),
              helperText: errors?.ssoUrl ? texts.ssoUrlError : "",
              error: !!errors?.ssoUrl,
              placeholder: texts.ssoUrl,
              disabled,
              value: data?.ssoUrl || "",
            }}
          />
          <Box alignSelf="flex-start">
            <SettingsSectionLabel primaryText={texts.certificate} asterisk />
          </Box>
          <TextInput
            {...{
              ...register("certificate", {
                required: true,
                disabled,
                onChange: ({ target: { value } }) => {
                  onChange({
                    certificate: value,
                  });
                },
              }),
              helperText: errors?.certificate
                ? texts.certificateError
                : texts?.certificateHelper,
              error: !!errors?.certificate,
              placeholder: texts.certificate,
              multiline: true,
              rows: 20,
              disabled,
              value: data?.certificate || "",
            }}
          />
          <Box />
          <SectionButtonWrapper sx={{ justifySelf: "end" }}>
            <SectionButtons
              variant="contained"
              disabled={disabled}
              onClick={onSubmit}
            >
              Save {texts.configuration}
            </SectionButtons>
          </SectionButtonWrapper>
        </SectionBody>
      </form>
    </>
  ),
);

export interface SamlConfigInfoProps extends SpConfig {
  texts?: Texts<"spEntityID" | "callbackURL">;
}

export const SamlConfigInfo: FC<SamlConfigInfoProps> = ({
  callbackUri,
  spEntityId,
  texts = {
    spEntityID: "SP Entity ID",
    callbackURL: "Callback URL",
  },
}) => (
  <SectionBody gridTemplateColumns="20vw 46vw">
    {[
      {
        label: texts.spEntityID,
        value: spEntityId,
        infoText: "You need to provide this Entity ID to your SAML provider.",
      },
      {
        label: texts.callbackURL,
        value: callbackUri,
        infoText:
          "You need to provide this Callback URL to your SAML provider.",
      },
    ].map(({ label, value, infoText }, index) => (
      <React.Fragment key={[index, value].join("-")}>
        <SettingsSectionLabel primaryText={label} />
        <CopyTextArea
          cliTextBody={value}
          cliTextContent={value}
          isOneLine
          infoText={infoText}
          boxProps={{
            sx: (theme) => ({
              backgroundColor: theme.palette.background.paper,
            }),
          }}
        />
      </React.Fragment>
    ))}
  </SectionBody>
);
