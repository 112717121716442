import type { AnyObject } from "#shared/types";

import type { AlertSeverity } from "../../../types";
import type { AlertColor, FnTheme } from "../../types";

export const commonPalette: Omit<
  FnTheme["palette"],
  | "primary"
  | "secondary"
  | "flyoutMenu"
  | "form"
  | "codeBlock"
  | "plum"
  | AlertColor
  | "tableGrid"
  | "filterBar"
> = {
  text: {
    primary: "#2D333E",
    secondary: "rgba(45, 51, 62, 0.6)",
    disabled: "#9DA7B8",
    yellowFont: "#F0E4B6",
  },
  info: {
    main: "#3F80EA",
    dark: "#0B79D0",
    light: "#64B6F7",
  },
  success: {
    main: "#4BBF73",
    dark: "#318B50",
    light: "#83D39E",
  },
  warning: {
    main: "#ECB43F",
    dark: "#CF8E07",
    light: "#F3D086",
  },
  grey: {
    50: "#FAFAFB",
    100: "#F4F4F6",
    200: "#ECEDF0",
    300: "#DDDEE3",
    400: "#B6B9C4",
    500: "#9499A8",
    600: "#696F81",
    700: "#575C6B",
    800: "#3B3E49",
    900: "#1E1F24",
    A100: "#E8E6E3",
    A200: "#B3ADA2",
    A400: "#6B6457",
    A700: "#35322B",
  },
  divider: "rgba(50, 57, 69, 0.12)",
  action: {
    active: "rgba(50, 57, 69, 0.54)",
    hover: "rgba(50, 57, 69, 0.04)",
    selected: "rgba(50, 57, 69, 0.08)",
    disabled: "rgba(50, 57, 69, 0.26)",
    disabledBackground: "rgba(50, 57, 69, 0.12)",
    focus: "rgba(50, 57, 69, 0.12)",
  },
};

export const ALERT_SEVERITY_COLOR_MAP: AnyObject<
  AlertSeverity | "total",
  AlertColor
> = {
  crit: "critAlert",
  warn: "warnAlert",
  info: "infoAlert",
  total: "totalAlert",
};
