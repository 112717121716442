import { CodeSnippet, CodeBlockWithCopyButton } from "#organization/components"

export const language = "ts";

# Tokens Label Key

## Overview

Flow label key that will be used to override the number of tokens for this request. This is an optional parameter and takes highest precedence when assigning tokens to a request.

## Example

If a request is more important than others, you can assign it more tokens by adding a label key and weight when making a `startFlow` call.
In the example below, we are passing `tokens` as the label key with a value of `5`. This means that this request will be assigned 5 tokens instead of the default 1 token.

<CodeSnippet lang={language} snippetName="UIRLTokens" />


## Note

The label value must be a valid number.
