import { gql } from "graphql-request";
import { useState } from "react";

import type { GetOrganizationMembersQuery } from "#shared/generated/graphql";
import { useGqlMutation, useGqlQuery } from "#shared/utils";

import { QUERY_KEYS } from "#organization/pages/consts";

export const OrganizationMembers = gql`
  query GetOrganizationMembers($first: Int, $where: UserBoolExp!) {
    users(first: $first, where: $where) {
      edges {
        node {
          email
          name
          id
          organizationMemberships {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const useOrganizationMembers = () => {
  const [search, setSearch] = useState<string>("");
  const option = { first: 4, where: { email: { like: `${search}%` } } };
  const { isError, isLoading, data } = useGqlQuery<GetOrganizationMembersQuery>(
    [QUERY_KEYS.ORGANIZATION_MEMBER, option],
    OrganizationMembers,
    option,
  );

  return {
    error: isError,
    loading: isLoading,
    users: data?.users.edges,
    organizationMemberId:
      data?.users.edges[0].node.organizationMemberships.edges[0],
    setSearch,
    search,
  };
};

export const UpdateUserRole = gql`
  mutation updateUserRole($input: UpdateUserRoleInput!) {
    updateUserRole(input: $input) {
      organizationMembership {
        user {
          id
        }
        role
      }
    }
  }
`;

export const useUpdateUserRole = () => {
  const { mutate, isError, data } = useGqlMutation<
    unknown,
    unknown,
    { id: string | undefined; role: string }
  >(UpdateUserRole);

  return {
    change: mutate,
    data,
    error: isError,
  };
};
