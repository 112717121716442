import { TextField } from "@mui/material";
import { get } from "lodash";
import React, { useMemo, type FC } from "react";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";

import type { Texts } from "#shared/types";

import { usePolicies } from "#organization/pages/authenticated/policies/hooks";
import { useProjectContextState } from "#organization/recoil/project";

import { useCreatePolicySearchParams } from "../../../../hooks";
import { FieldWithTitle, FormFieldWrapper } from "../../styled";
import { REQUIRED_FIELD_SIGN } from "../consts";

export declare type PolicyNameInputProps = {
  texts?: Texts<"policyName" | "blueprintLabel">;
  fieldAddress?: string;
};

export const PolicyNameInput: FC<PolicyNameInputProps> = ({
  texts = {
    policyName: "Policy name",
    blueprintLabel: "Rate Limiting Blueprint",
  },
  fieldAddress = "policy.policy_name",
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { id: projectID } = useProjectContextState();

  const { data: policyData, isLoading } = usePolicies(projectID);
  const { pathname } = useLocation();
  const policyNames = useMemo(
    () =>
      policyData?.policiesWithDefinition.edges.map((edge) => edge.node.name) ||
      [],
    [policyData?.policiesWithDefinition.edges],
  );

  const {
    searchInfo: { policyID, name },
  } = useCreatePolicySearchParams();

  const validatePolicyName = (value: string) => {
    if (isLoading) {
      return "Loading policies, please retry after a few seconds";
    }

    if (value === REQUIRED_FIELD_SIGN) {
      return "Policy name is required";
    }

    const policies =
      pathname.includes("edit-policy") || policyID?.length
        ? policyNames?.filter((v) => v !== name)
        : policyNames;

    if (policies.includes(value.trim())) {
      return "Policy name already exists";
    }

    return true;
  };

  return (
    <>
      <FieldWithTitle label={texts.blueprintLabel} fieldAddress="blueprint" />
      <FormFieldWrapper>
        <FieldWithTitle
          fieldAddress={fieldAddress}
          label={texts.policyName}
          hideInfoIcon
          required
        >
          <TextField
            {...{
              ...register(fieldAddress, {
                validate: validatePolicyName,
                required: "Policy name is required",
              }),
              error: !!get(errors, fieldAddress),
              helperText: <>{get(errors, fieldAddress)?.message}</>,
              placeholder: "Policy name",
            }}
          />
        </FieldWithTitle>
      </FormFieldWrapper>
    </>
  );
};
