import { ExpandMore } from "@mui/icons-material";
import EastIcon from "@mui/icons-material/East";
import {
  Accordion,
  AccordionSummary,
  Typography,
  type AccordionProps,
  AccordionDetails,
  styled,
  MenuItem,
  Box,
} from "@mui/material";
import { noop } from "lodash";
import React, { type FC, type ReactNode } from "react";

export interface WidgetDropdownProps extends WidgetMenuListProps {
  title: ReactNode;
  accordionProps?: Omit<AccordionProps, "children">;
}

export const WidgetDropdown: FC<WidgetDropdownProps> = ({
  title,
  accordionProps,
  ...listProps
}) => (
  <AccordionStyled {...accordionProps} disableGutters>
    <AccordionSummary
      expandIcon={
        <ExpandMore sx={{ color: (theme) => theme.palette.text.primary }} />
      }
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      {title}
    </AccordionSummary>
    <AccordionDetails>
      <WidgetMenuList {...listProps} />
    </AccordionDetails>
  </AccordionStyled>
);

export const AccordionStyled = styled(Accordion)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: 400,
  minHeight: 120,
  height: "fit-content",
  borderRadius: theme.spacing(0.5),
  boxShadow: theme.shadows[2],
  "&::before": {
    display: "none",
  },
  "&:hover": {
    boxShadow: theme.shadows[8],
  },
}));

export type ItemInfo = {
  name: string;
  id: string;
  path: string;
};

export interface WidgetMenuListProps {
  listItems?: ItemInfo[];
  onSelectItem?: (item: ItemInfo) => void;
}

export const WidgetMenuList: FC<WidgetMenuListProps> = ({
  listItems = [],
  onSelectItem = noop,
}) => (
  <Box display="flex" flexDirection="column" gap={1}>
    {listItems.reverse().map((item, i) => (
      <WidgetMenuItem
        key={[item.name, i].join("-")}
        onClick={() => onSelectItem(item)}
      >
        <Typography>{convertBlueprintNamesToNiceNames(item.name)}</Typography>
        <EastIcon color="primary" />
      </WidgetMenuItem>
    ))}
  </Box>
);

function convertBlueprintNamesToNiceNames(blueprintName: string) {
  const replacements = {
    "quota-scheduling/base": "Quota based",
    "load-scheduling/elasticsearch": "Elasticsearch",
    "load-scheduling/postgresql": "PgSQL",
    "load-scheduling/promql": "Metric based",
    "concurrency-scheduling/base": "Concurrency based",
    "rate-limiting/base": "Request rate",
    "concurrency-limiting/base": "Concurrency",
  };

  return blueprintName in replacements
    ? replacements[blueprintName as keyof typeof replacements]
    : blueprintName;
}

export const WidgetMenuItem = styled(MenuItem)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.background.default,
}));
