import { svgIconClasses, type ThemeOptions } from "@mui/material";

const ICON_SIZE = 14;

const styleOverrides: Required<
  Required<ThemeOptions>["components"]
>["MuiListItemText"]["styleOverrides"] = {
  root: {
    fontSize: 16,
    padding: 0,
    width: "100%",
  },
  primary: ({ theme }) => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontWeight: 700,
    [`.${svgIconClasses.root}`]: {
      fill: theme.palette.primary.contrastText,
      height: ICON_SIZE,
      width: "auto",
      marginRight: theme.spacing(0.5),
    },
  }),
};

export const MuiListItemText: Required<
  Required<ThemeOptions>["components"]
>["MuiListItemText"] = { styleOverrides };
