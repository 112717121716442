import {
  Button,
  Paper,
  Typography,
  type TypographyProps,
  type ButtonProps,
  Dialog,
  type DialogProps,
  Box,
} from "@mui/material";
import { noop } from "lodash";
import React, {
  createContext,
  type FC,
  type PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
  type CSSProperties,
} from "react";

import type { Texts } from "#shared/types";

import {
  DeleteConfirmBoxButtonWrapper,
  deleteConfirmBoxStyled,
} from "./styled";

type TextsKeys = "yes" | "no" | "title";

const defaultTexts: Texts<TextsKeys> = {
  yes: "Delete",
  no: "Cancel",
  title: "Confirmation",
};

export type DeleteConfirmBoxProps = PropsWithChildren<{
  open: boolean;
  callback: (status: boolean) => void;
  onClickDeleteBox: () => void;
  texts?: Texts<TextsKeys>;
  titleProps?: {
    showTitle: boolean;
    props: TypographyProps;
  };
  outerBoxProps?: Omit<DialogProps, "open" | "children">;
  footerWrapperProps?: CSSProperties & {
    translate?: "yes" | "no";
  };
  yesButtonProps?: {
    showYesButton: boolean;
    props: ButtonProps;
  };
  noButtonProps?: {
    showNoButton: boolean;
    props: ButtonProps;
  };
  isClickAwayListener?: boolean;
}>;

export interface DisplayDeleteConfirmBoxProps {
  message: string | JSX.Element;
  callback: DeleteConfirmBoxProps["callback"];
}

export type AddDeleteBox = (
  content: DisplayDeleteConfirmBoxProps,
  optionalProps?: Omit<
    DeleteConfirmBoxProps,
    "children" | "callback" | "message" | "onClickDeleteBox" | "open"
  >,
) => void;

export type DeleteBoxInitial = {
  addDeleteBox: AddDeleteBox;
  setShowDeleteBox: React.Dispatch<React.SetStateAction<boolean>>;
};

const initialValue: DeleteBoxInitial = {
  addDeleteBox: noop,
  setShowDeleteBox: noop,
};

const DeleteBox = createContext<DeleteBoxInitial>(initialValue);

export const useDeleteBox = () => useContext(DeleteBox);

export const useDeleteBoxInitializer = () => {
  const [showDeleteBox, setShowDeleteBox] = useState<boolean>(false);
  const [deleteBoxProps, setDeleteBoxProps] =
    useState<DisplayDeleteConfirmBoxProps>({ message: "", callback: () => {} });

  const [deleteConfirmBoxOptionalProps, setDeleteConfirmBoxOptionalProps] =
    useState<
      Omit<
        DeleteConfirmBoxProps,
        "children" | "callback" | "message" | "onClickDeleteBox" | "open"
      >
    >();

  const addDeleteBox = useCallback<DeleteBoxInitial["addDeleteBox"]>(
    (content, optionalProps) => {
      setShowDeleteBox(true);
      setDeleteBoxProps(content);
      setDeleteConfirmBoxOptionalProps(optionalProps);
    },
    [setShowDeleteBox],
  );

  const value: DeleteBoxInitial = useMemo(
    () => ({
      addDeleteBox,
      setShowDeleteBox,
    }),
    [addDeleteBox],
  );

  const onClick = () => {
    setShowDeleteBox((prev) => !prev);
  };

  return {
    value,
    showDeleteBox,
    deleteBoxProps,
    onClick,
    addDeleteBox,
    deleteConfirmBoxOptionalProps,
  };
};

export const DeleteBoxProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    value,
    showDeleteBox,
    deleteBoxProps,
    onClick,
    deleteConfirmBoxOptionalProps,
  } = useDeleteBoxInitializer();
  const { message, callback } = deleteBoxProps;

  return (
    <DeleteBox.Provider value={value}>
      <DeleteConfirmBox
        callback={callback}
        onClickDeleteBox={onClick}
        open={showDeleteBox}
        {...deleteConfirmBoxOptionalProps}
      >
        {message}
      </DeleteConfirmBox>
      {children}
    </DeleteBox.Provider>
  );
};

export const DeleteConfirmBox: FC<DeleteConfirmBoxProps> = ({
  callback,
  onClickDeleteBox,
  children,
  texts = defaultTexts,
  titleProps = {
    showTitle: true,
    props: {},
  },
  outerBoxProps = {
    PaperProps: {
      sx: deleteConfirmBoxStyled,
    },
  },
  footerWrapperProps = {},
  yesButtonProps = {
    showYesButton: true,
    props: {},
  },
  noButtonProps = {
    showNoButton: true,
    props: {},
  },
  open,
}) => (
  <Dialog
    {...{
      open,
      onClose: onClickDeleteBox,
      PaperComponent: Paper,
      fullWidth: true,
      maxWidth: "sm",
      ...outerBoxProps,
    }}
  >
    {titleProps.showTitle && (
      <Typography fontSize={20} fontWeight={700} {...titleProps.props}>
        {texts.title}
      </Typography>
    )}
    <Box>{children}</Box>
    <DeleteConfirmBoxButtonWrapper {...footerWrapperProps}>
      {noButtonProps.showNoButton && (
        <Button
          variant="outlined"
          color="success"
          onClick={onClickDeleteBox}
          {...noButtonProps.props}
        >
          {texts.no}
        </Button>
      )}

      {yesButtonProps.showYesButton && (
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            callback(true);
            onClickDeleteBox();
          }}
          {...yesButtonProps.props}
        >
          {texts.yes}
        </Button>
      )}
    </DeleteConfirmBoxButtonWrapper>
  </Dialog>
);
