import {
  type Dispatch,
  type SetStateAction,
  useMemo,
  useState,
  useCallback,
} from "react";

import { httpClient } from "#shared/utils/http-client";

export type FetchOptions<M extends UseFetchMethod = "get"> = Parameters<
  (typeof httpClient)[M]
>;

export type UseFetch<Result = unknown, M extends UseFetchMethod = "get"> = {
  request: (...options: FetchOptions<M>) => Promise<Result | null>;
  result: Result | null;
  reset: (shouldRestResult?: boolean, isIdle?: boolean) => void;
  httpState: HttpState<string | null>;
  updateHttpState: Pick<UseHttpStatus<string | null>, UpdateHttpStateKey>;
};

type UpdateHttpStateKey = Exclude<
  keyof UseHttpStatus<string | null>,
  keyof HttpState<string | null>
>;

type QueryParams = { [key: string]: string | string[] };

export type UseFetchMethod = Extract<
  keyof typeof httpClient,
  "get" | "post" | "put"
>;

type DefaultMethod = Extract<UseFetchMethod, "get">;

const DEFAULT_METHOD: DefaultMethod = "get";

export function useFetch<Result, M extends UseFetchMethod = DefaultMethod>(
  method?: M,
  shouldResetResult = true,
  defaultErrorMessage = "Something went wrong.",
): UseFetch<Result, M> {
  const {
    isLoading,
    setIsLoading,
    isIdle,
    setIsIdle,
    error,
    setError,
    isSuccess,
    setIsSuccess,
  } = useHttpStatus<string | null>();

  const [result, setResult] = useState<Result | null>(null);

  const reset = useCallback(
    (resetResult = shouldResetResult, idle: boolean = true) => {
      setIsSuccess(false);
      setIsLoading(false);

      if (resetResult) {
        setResult(null);
      }

      setError(null);

      setIsIdle(idle);
    },
    [setIsSuccess, setIsLoading, setIsIdle, setError, shouldResetResult],
  );

  const fetchCallback = useMemo<UseFetch<Result, M>["request"]>(
    () =>
      async (...requestArgs) => {
        setIsSuccess(false);
        setIsLoading(true);
        setError(null);

        setIsIdle(false);

        try {
          if (!requestArgs.length) {
            throw new Error("Missing request args.");
          }

          const httpMethod =
            httpClient[method || DEFAULT_METHOD].bind(httpClient);

          const response = (await (httpMethod as Function)(
            ...requestArgs,
          )) as Result;

          const [{ shouldParse }] = requestArgs;

          setIsLoading(false);
          setResult(response);

          setIsSuccess(shouldParse ? true : (response as Response).ok);

          return response;
        } catch (err) {
          setIsLoading(false);

          setError(
            err instanceof Error
              ? err.message
              : String(err) || defaultErrorMessage,
          );

          return null;
        }
      },
    /**
     * NOTE:
     *
     * Do not include setIsSuccess, setIsLoading, setError, setIsIdle
     * in the dependencies array
     * because it causes unnecessary rerenders
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [method, defaultErrorMessage],
  );

  const httpState = useMemo<UseFetch<Result, M>["httpState"]>(
    () => ({ isLoading, isSuccess, error, isIdle }),
    [isLoading, isSuccess, error, isIdle],
  );
  const updateHttpState = useMemo<UseFetch<Result, M>["updateHttpState"]>(
    () => ({ setIsLoading, setIsSuccess, setError, setIsIdle, reset }),
    [setIsLoading, setIsSuccess, setError, setIsIdle, reset],
  );

  return useMemo<UseFetch<Result, M>>(
    () => ({
      request: fetchCallback,
      httpState,
      reset,
      result: result as Result,
      updateHttpState,
    }),
    [fetchCallback, httpState, reset, result, updateHttpState],
  );
}

export function appendQueryParams(
  url: URL | string,
  queryParams?: QueryParams,
) {
  const urlObject = new URL(url instanceof URL ? url.toString() : url);

  if (!queryParams) {
    return urlObject;
  }

  Object.entries(queryParams).forEach(([key, values]) => {
    (Array.isArray(values) ? values : [values]).forEach((value) => {
      urlObject.searchParams.append(key, value);
    });
  });

  return urlObject;
}

export type HttpState<E = unknown> = {
  isLoading: boolean | null;
  isSuccess: boolean | null;
  isIdle: boolean | null;
  error: E;
};

export const DEFAULT_HTTP_STATUS: Required<HttpState<null>> = {
  isLoading: false,
  isSuccess: false,
  isIdle: true,
  error: null,
};

export type UseHttpStatus<E = unknown> = HttpState<E> & {
  reset: () => void;
  setIsLoading: Dispatch<SetStateAction<boolean | null>>;
  setIsIdle: Dispatch<SetStateAction<boolean | null>>;
  setIsSuccess: Dispatch<SetStateAction<boolean | null>>;
  setError: Dispatch<SetStateAction<E>>;
};

export function useHttpStatus<E>(
  initialValues?: Partial<HttpState<E>>,
): UseHttpStatus<E> {
  const defaults = useMemo(
    () =>
      ({ ...DEFAULT_HTTP_STATUS, ...(initialValues || {}) }) as HttpState<E>,
    [initialValues],
  );

  const [isLoading, setIsLoading] = useState(defaults.isLoading);
  const [isSuccess, setIsSuccess] = useState(defaults.isSuccess);
  const [isIdle, setIsIdle] = useState(defaults.isIdle);
  const [error, setError] = useState(defaults.error);

  const reset = useMemo(
    () => () => {
      setIsLoading(defaults.isLoading);
      setIsSuccess(defaults.isSuccess);
      setIsIdle(defaults.isIdle);
      setError(defaults.error);
    },
    [defaults],
  );

  return useMemo<UseHttpStatus<E>>(
    () => ({
      isLoading,
      setIsLoading,
      isIdle,
      setIsIdle,
      error,
      setError,
      isSuccess,
      setIsSuccess,
      reset,
    }),
    [isLoading, isSuccess, isIdle, error, reset],
  );
}
