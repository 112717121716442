import { CodeSnippet, CodeBlockWithCopyButton } from "#organization/components"

export const yaml = "yaml";
export const language = "ts";

# Control Point

## Overview

Control Point identifies location within services where policies can act on flows. For an SDK based insertion, a Control Point can represent a particular feature or execution block within a service. In case of service mesh or middleware insertion, a Control Point can identify ingress or egress calls or distinct listeners or filter chains.

## SDK Example

SDKs provide the functionality to pass a `control_point` directly within your code when starting a flow. For instance, if you designate `concurrency-limiting-feature` as the `control_point` in the policy, concurrency limiting will be applied to all incoming requests to that particular feature.

<CodeSnippet lang={language} snippetName="UICS" />

## Infrastructure Example

<CodeBlockWithCopyButton language={yaml} code={`
blueprint: concurrency-limiting/base
policy:
  policy_name: "per-user-concurrency-limiting"
  concurrency_limiter:
    max_concurrency: 40
    selectors:
      - service: "catalog-service.prod.svc.cluster.local"
        control_point: "ingress"
        agent_group: "default"
    parameters:
      limit_by_label_key: "http.request.header.user_id"
      max_inflight_duration: "120s"
`} />

This infrastructure based configuration will apply concurrency limiting at the `ingress` control point on the service `catalog-service.prod.svc.cluster.local` and identifies unique users by referencing the `user_id` header present in the HTTP traffic. Provided by the Envoy proxy, this header can be located under the label key `http.request.header.user_id`.

## Note

Regardless of choosing an SDK or an infrastructure-based approach, it's crucial to accurately define control points for concurrency limiting.
