# Max Concurrency

## Overview

The `max_concurrency` specifies the maximum number of concurrent requests to be allowed.

## Example

If the `max_concurrency` is set to 10, a maximum of 10 requests would be allowed to be handled simultaneously.

## Note

The `max_concurrency` must be a valid number and is required.
