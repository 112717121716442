import { Box } from "@mui/material";
import React, { type FC, useState } from "react";
import { useRecoilState } from "recoil";

import {
  FilteringGroup,
  useChipsContainer,
} from "#shared/components/filtering/filtering-group";
import { useParseLinkAttributesToFilterVariables } from "#shared/components/filtering/utils";
import { ServiceSelectColumn } from "#shared/generated/graphql";

import {
  DataGrid,
  DataGridGroups,
  DataGridWrapper,
  FiltersBarWrapper,
  GroupByButtonWrapper,
  GroupBySelect,
  type GroupByOption,
} from "#organization/components/data-grid";
import { FlyoutMenu } from "#organization/components/flyout-menu";
import { projectContextState } from "#organization/recoil/project";

import {
  useGlobalCacheGroupsTitles,
  useGlobalCacheGroupData,
  useGlobalCacheData,
} from "./hooks";
import { flowControlFilters, headCells } from "./table";
import { rowClick } from "./table/row";

import { CacheFlyoutMenuWrapper } from "../../cache-dashboard/cache-flyout-menu-wrapper";
import type { CacheDashboardConfig } from "../../cache-dashboard/types";

const GROUP_BYS: GroupByOption<ServiceSelectColumn>[] = [
  {
    title: "No value",
    value: "",
  },
];

const INITIAL_ORDER_BY = "agentGroup";
const INITIAL_ORDER = "asc";
const TABLE_UNIQUE_ID = "global-cache-table";

export const GlobalCacheTab: FC = () => {
  const [projectContext] = useRecoilState(projectContextState);
  const chipsContainer = useChipsContainer();
  const filterHeaders = flowControlFilters(projectContext.id);
  const [cacheConfig, setCacheConfig] = useState<CacheDashboardConfig>({
    controllerId: "",
    controlPoint: "",
    agentGroup: "",
    type: "global",
  });
  const [groupByKey, setGroupByKey] =
    useState<(typeof GROUP_BYS)[number]["value"]>("");
  const [filterVariables, setFilterVariables] = useState(
    useParseLinkAttributesToFilterVariables(TABLE_UNIQUE_ID),
  );
  const [flyoutMenuVisibility, setFlyoutMenuVisibility] = useState(false);

  const headerTable = headCells(setFlyoutMenuVisibility, setCacheConfig);
  const rowOnClick = rowClick(setFlyoutMenuVisibility, setCacheConfig);

  return (
    <Box pt={2}>
      <FlyoutMenu useShowHide={[flyoutMenuVisibility, setFlyoutMenuVisibility]}>
        {flyoutMenuVisibility && (
          <CacheFlyoutMenuWrapper
            config={cacheConfig}
            hideFlyoutMenu={() => setFlyoutMenuVisibility(false)}
          />
        )}
      </FlyoutMenu>

      <FiltersBarWrapper enableArchivedData>
        <FilteringGroup
          labels={filterHeaders}
          onFilter={setFilterVariables}
          chipsContainer={chipsContainer}
          uniqueId={TABLE_UNIQUE_ID}
        />
        <GroupByButtonWrapper>
          <GroupBySelect
            options={GROUP_BYS}
            setGroupByKey={setGroupByKey}
            groupByKey={groupByKey}
          />
        </GroupByButtonWrapper>
      </FiltersBarWrapper>

      <Box py={4}>
        <DataGridWrapper>
          {groupByKey ? (
            <DataGridGroups
              headCells={headerTable}
              useGroupsTitles={useGlobalCacheGroupsTitles}
              useGroupData={useGlobalCacheGroupData}
              groupByKey={groupByKey}
              rowOnClick={rowOnClick}
              filterVariables={{
                ...filterVariables,
              }}
              initialOrderBy={INITIAL_ORDER_BY}
              initialOrder={INITIAL_ORDER}
              enableArchivedData
            />
          ) : (
            <DataGrid
              headCells={headerTable}
              useGridData={useGlobalCacheData}
              enabled={!groupByKey}
              rowOnClick={rowOnClick}
              filterVariables={filterVariables}
              initialOrderBy={INITIAL_ORDER_BY}
              initialOrder={INITIAL_ORDER}
              enableArchivedData
            />
          )}
        </DataGridWrapper>
      </Box>
    </Box>
  );
};
