import { Tabs, Tab, type TabsProps, Box } from "@mui/material";
import React, { useMemo, type FC } from "react";
import { Link } from "react-router-dom";

import type { Route } from "#shared/types";

import { useHeaderSectionUpdate } from "#organization/recoil/organization/header-section";

export interface TabsPanelProps {
  tabsRoutes: Route[];
  activeTab: string;
  tabPanelRoutes: JSX.Element;
  pageTitle: string;
  tabsProps?: TabsProps;
}

export const TabsPanelLayout: FC<TabsPanelProps> = ({
  tabsRoutes,
  activeTab,
  tabPanelRoutes,
  pageTitle,
  tabsProps,
}) => {
  useHeaderSectionUpdate({
    pageTitle,
    children: useMemo(
      () => (
        <Tabs value={activeTab} aria-label="Tabs layout" {...tabsProps}>
          {tabsRoutes.map((route) => (
            <Tab
              key={route.ABSOLUTE_PATH}
              component={Link}
              to={route.ABSOLUTE_PATH}
              label={route.TITLE}
              value={route.ABSOLUTE_PATH}
              disabled={route.ABSOLUTE_PATH === ""}
            />
          ))}
        </Tabs>
      ),

      [activeTab, tabsProps, tabsRoutes],
    ),
  });

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: "background.paper",
        minHeight: 550,
        border: [theme.spacing(0.1), "solid", theme.palette.divider].join(" "),
        borderRadius: theme.spacing(1),
        marginBottom: theme.spacing(4),
      })}
    >
      {tabPanelRoutes}
    </Box>
  );
};
