import { Box, Typography } from "@mui/material";
import React, { type FC } from "react";

interface SettingsSectionLabelProps {
  primaryText: string;
  secondaryText?: string | null;
  asterisk?: boolean;
}

export const SettingsSectionLabel: FC<SettingsSectionLabelProps> = ({
  primaryText,
  secondaryText,
  asterisk = false,
}) => (
  <Box>
    <Typography variant="body2" fontSize="16px" fontWeight="400">
      {primaryText}
      {asterisk && (
        <Typography
          component="span"
          color="error"
          fontSize="16px"
          fontWeight="400"
        >
          *
        </Typography>
      )}
    </Typography>

    {secondaryText && (
      <Typography variant="body2" color="textSecondary">
        {secondaryText}
      </Typography>
    )}
  </Box>
);

SettingsSectionLabel.defaultProps = {
  secondaryText: null,
  asterisk: false,
};
