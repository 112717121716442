import { Box, Button, Paper, Tooltip, styled } from "@mui/material";
import { noop } from "lodash";
import React, { useCallback, type FC, useMemo, useEffect } from "react";
import type { UseMutateFunction } from "react-query";
import { useNavigate } from "react-router-dom";

import { Analytics } from "#shared/analytics";
import { useAlert } from "#shared/components/alerts-provider";
import type {
  ApplyBlueprintDefinedPolicyInput,
  ApplyBlueprintDefinedPolicyPayload,
  ApplyCustomDefinedPolicyInput,
  ApplyCustomDefinedPolicyPayload,
} from "#shared/generated/graphql";
import type { Texts } from "#shared/types";
import type { UseGqlMutationOptions } from "#shared/utils";

import { useProjectContextState } from "#organization/recoil/project";
import { useSidebarWidth } from "#organization/recoil/sidebar";

import {
  useDraftPolicyHelper,
  findLastEditedAt,
  findPolicyIdOutOfResponse,
} from "./utils";

import { POLICIES_ROUTE } from "../../../routes.definitions";
import { useCreatePolicyContext } from "../../context";
import {
  useApplyBlueprintPolicy,
  type HandleNext,
  useApplyCustomDefinedPolicy,
  useCreatePolicySearchParams,
} from "../../hooks";
import { BackButton } from "../back-button";
import { useSubmitPolicyBuilder } from "../form-builder/builder/hooks";
import { type PolicyNameFormProps } from "../policy-name-form";

export interface ActionBarProps {
  handleNext: HandleNext;
  handleBack: () => void;
  activeStep: number;
  confirmAndApplyPolicy?: boolean;
  updateDraftedPolicy?: boolean;
  policyNameFormControl?: PolicyNameFormProps["useForm"];
  backButtonOnly?: boolean;
  totalSteps: number;
}

export const ActionBar: FC<ActionBarProps> = ({
  handleNext,
  handleBack,
  activeStep,
  confirmAndApplyPolicy = false,
  updateDraftedPolicy = false,
  backButtonOnly = false,
  totalSteps,
  policyNameFormControl,
}) => {
  const { width: sideBarWidth } = useSidebarWidth();

  const { fullScreen } = useCreatePolicyContext();

  return (
    <ActionBarStyled
      sideBarWidth={!fullScreen ? sideBarWidth : 0}
      component={Paper}
      px={2}
      py={1}
    >
      {!backButtonOnly ? (
        <>
          <LeaveWithoutSaving />
          <SaveAsDraft
            updateDraftedPolicy={updateDraftedPolicy}
            policyNameFormControl={policyNameFormControl}
          />
          <BackButton
            {...{
              handleBack,
              activeStep,
              totalSteps,
            }}
          />
          {confirmAndApplyPolicy ? (
            <ConfirmAndApplyPolicy />
          ) : (
            <ContinueWithBlueprint
              {...{ handleNext, updateDraftedPolicy, policyNameFormControl }}
            />
          )}
        </>
      ) : (
        <BackButton
          {...{
            handleBack,
            activeStep,
            totalSteps,
          }}
        />
      )}
    </ActionBarStyled>
  );
};

export const ActionBarStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "sideBarWidth",
})<{ sideBarWidth: string | number }>(({ theme, sideBarWidth }) => ({
  position: "fixed",
  bottom: 0,
  left: Number(sideBarWidth),
  width: `calc(100% - ${Number(sideBarWidth)}px)`,
  display: "grid",
  gridTemplateColumns: "auto 1fr auto auto",
  alignItems: "center",
  gap: theme.spacing(2),
  borderRadius: theme.spacing(0),
  zIndex: 1500,
}));

export interface ContinueWithBlueprintProps {
  handleNext: HandleNext;
  updateDraftedPolicy?: boolean;
  policyNameFormControl?: PolicyNameFormProps["useForm"];
  texts?: Texts<"continue">;
}

export const ContinueWithBlueprint: FC<ContinueWithBlueprintProps> = ({
  handleNext,
  updateDraftedPolicy,
  policyNameFormControl,
  texts = {
    continue: "Continue",
  },
}) => {
  const { saveEditorState } = useCreatePolicyContext();

  const { submitter } = useSubmitPolicyBuilder(true);
  const {
    searchInfo,
    searchInfo: { editedAt, origin, name },
  } = useCreatePolicySearchParams();

  const isInEditMode = useMemo(
    () =>
      updateDraftedPolicy ||
      Boolean(editedAt?.length || searchInfo?.policyID?.length),
    [editedAt?.length, searchInfo?.policyID?.length, updateDraftedPolicy],
  );

  const {
    isLoading,
    handleSubmitYamlValues,
    data: responseAfterSave,
    policyNameFromJson,
    disabled,
  } = useDraftPolicyHelper(isInEditMode, true);

  useEffect(() => {
    if (!responseAfterSave) {
      return;
    }

    const policyID = findPolicyIdOutOfResponse(
      isInEditMode,
      origin,
      responseAfterSave,
    );
    const lastEditedAt = findLastEditedAt(
      isInEditMode,
      origin,
      responseAfterSave,
    );

    if (!policyID) {
      return;
    }

    handleNext("review", {
      policyID,
      editedAt: lastEditedAt || editedAt,
      name: policyNameFromJson?.length ? policyNameFromJson : name,
    });
  }, [
    disabled,
    editedAt,
    handleNext,
    isInEditMode,
    name,
    origin,
    policyNameFromJson,
    responseAfterSave,
    searchInfo,
  ]);

  const { watch, formState } = policyNameFormControl || {};
  const invalidPolicyName =
    policyNameFormControl &&
    (!!formState?.errors?.policyName || !watch?.("policyName"));

  return (
    <Tooltip
      title={
        invalidPolicyName ? "Please enter a valid policy name to continue" : ""
      }
      placement="top-start"
      arrow
    >
      <Box>
        <Button
          variant="contained"
          disabled={isLoading || invalidPolicyName}
          type="submit"
          id="submit-policy-builder"
          onClick={() => {
            submitter(
              handleSubmitYamlValues(() => {
                if (saveEditorState) {
                  saveEditorState();
                }

                if (disabled) {
                  handleNext("review", {
                    name: policyNameFromJson?.length
                      ? policyNameFromJson
                      : name,
                  });
                }
              }),
            );
          }}
          size="small"
        >
          {texts.continue}
        </Button>
      </Box>
    </Tooltip>
  );
};

export const LeaveWithoutSaving: FC<{ texts?: Texts<"leave"> }> = ({
  texts = {
    leave: "Leave without saving",
  },
}) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(POLICIES_ROUTE.ABSOLUTE_PATH);
  };

  return (
    <Button variant="text" onClick={onClick} size="small">
      {texts.leave}
    </Button>
  );
};

export interface SaveAsDraftProps {
  policyNameFormControl?: PolicyNameFormProps["useForm"];
  updateDraftedPolicy?: boolean;
  texts?: Texts<"save">;
}

export const SaveAsDraft: FC<SaveAsDraftProps> = ({
  texts = {
    save: "Save as draft",
  },
  updateDraftedPolicy = false,
  policyNameFormControl,
}) => {
  const {
    searchInfo,
    searchInfo: { editedAt, origin, name },
    setSearchParams,
  } = useCreatePolicySearchParams();

  const isInEditMode = useMemo(
    () =>
      updateDraftedPolicy ||
      Boolean(editedAt?.length || searchInfo?.policyID?.length),
    [editedAt?.length, searchInfo?.policyID?.length, updateDraftedPolicy],
  );
  const {
    isLoading,
    handleSubmitYamlValues,
    disabled,
    data,
    policyNameFromJson,
  } = useDraftPolicyHelper(isInEditMode);

  const { submitter } = useSubmitPolicyBuilder(true);

  const { watch, formState } = policyNameFormControl || {};

  useEffect(() => {
    if (!data) return;

    const policyID = findPolicyIdOutOfResponse(isInEditMode, origin, data);
    const lastEditedAt = findLastEditedAt(isInEditMode, origin, data);

    if (!policyID) {
      return;
    }

    setSearchParams(
      new URLSearchParams({
        ...searchInfo,
        policyID,
        editedAt: lastEditedAt || editedAt,
        name: policyNameFromJson?.length ? policyNameFromJson : name,
      }),
    );
  }, [
    data,
    editedAt,
    isInEditMode,
    name,
    origin,
    policyNameFromJson,
    searchInfo,
    setSearchParams,
  ]);

  return (
    <Button
      variant="outlined"
      sx={{ maxWidth: "180px" }}
      size="small"
      disabled={
        disabled ||
        isLoading ||
        (policyNameFormControl &&
          (!!formState?.errors?.policyName || !watch?.("policyName")))
      }
      onClick={() => {
        submitter(handleSubmitYamlValues());
      }}
    >
      {texts.save}
    </Button>
  );
};

export interface ConfirmAndApplyPolicyProps {
  texts?: Texts<"confirmAndApplyPolicy">;
}

export const ConfirmAndApplyPolicy: FC<ConfirmAndApplyPolicyProps> = ({
  texts = {
    confirmAndApplyPolicy: "Apply Policy",
  },
}) => {
  const { addAlert } = useAlert();

  const navigate = useNavigate();

  const mutationOptions = useMemo<UseGqlMutationOptions>(
    () => ({
      onSuccess: () => {
        addAlert({
          type: "success",
          message: "Policy has been applied.",
        });

        navigate(POLICIES_ROUTE.ABSOLUTE_PATH);
      },
      onError: () => {
        addAlert({
          type: "error",
          message: "Error applying policy.",
        });
      },
    }),
    [addAlert, navigate],
  );

  const { mutate: applyBlueprintPolicy, isLoading: isBlueprintApplyLoading } =
    useApplyBlueprintPolicy(mutationOptions);
  const { mutate: applyCustomPolicy, isLoading: isCustomApplyLoading } =
    useApplyCustomDefinedPolicy(mutationOptions);

  const {
    searchInfo: {
      origin,
      blueprintName,
      blueprintVersion,
      policyID,
      name,
      editedAt,
    },
  } = useCreatePolicySearchParams();

  const { blueprintJson: policyValues } = useCreatePolicyContext();

  const { id: projectID } = useProjectContextState();

  const generatePayload = useCallback(() => {
    const blueprintApplyInput: ApplyBlueprintDefinedPolicyInput = {
      blueprintName,
      blueprintVersion,
      projectID,
      values: policyValues,
      id: policyID,
      name,
    };

    const customApplyPolicyInput: ApplyCustomDefinedPolicyInput = {
      name,
      id: policyID,
      projectID,
      body: policyValues,
      editedAt,
    };

    return {
      blueprint: blueprintApplyInput,
      custom: customApplyPolicyInput,
    };
  }, [
    blueprintName,
    blueprintVersion,
    projectID,
    policyValues,
    policyID,
    name,
    editedAt,
  ]);

  const addAnalytics = useCallback(() => {
    Analytics.track("applied_policy", {
      origin,
      blueprintName,
    });
  }, [blueprintName, origin]);

  const onClick = useCallback(() => {
    applyPolicyDecider(
      origin,
      applyBlueprintPolicy,
      applyCustomPolicy,
      generatePayload(),
    )();

    addAnalytics();
  }, [
    addAnalytics,
    applyBlueprintPolicy,
    applyCustomPolicy,
    generatePayload,
    origin,
  ]);

  return (
    <Button
      variant="contained"
      size="small"
      disabled={isBlueprintApplyLoading || isCustomApplyLoading}
      onClick={onClick}
    >
      {texts.confirmAndApplyPolicy}
    </Button>
  );
};

export function applyPolicyDecider(
  origin: string,
  applyBlueprintPolicy: UseMutateFunction<
    ApplyBlueprintDefinedPolicyPayload,
    unknown,
    ApplyBlueprintDefinedPolicyInput,
    unknown
  >,
  applyCustomPolicy: UseMutateFunction<
    ApplyCustomDefinedPolicyPayload,
    unknown,
    ApplyCustomDefinedPolicyInput,
    unknown
  >,
  payload: {
    blueprint: ApplyBlueprintDefinedPolicyInput;
    custom: ApplyCustomDefinedPolicyInput;
  },
) {
  switch (origin) {
    case "Blueprint":
      return () => {
        applyBlueprintPolicy(payload.blueprint);
      };

    case "Custom":
      return () => {
        applyCustomPolicy(payload.custom);
      };
    default:
      return noop;
  }
}
