import { Box, Paper } from "@mui/material";
import React, { useState, type FC, type PropsWithChildren } from "react";
import { useFormContext, type FieldValues, type Path } from "react-hook-form";

import { LoaderLayout } from "#shared/components/layouts";

import * as ConcurrencySchedulingBlueprint from "./@types";
import { concurrencySchedulingFormDefaultValues } from "./default-values";
import { concurrencySchedulingDocsTree } from "./docs";

import { PolicyFormTreeView, TreeItemStyled } from "../../styled";
import {
  MaxConcurrency,
  ConcurrencyLimiterParameterInputs,
  ConcurrencyLimiterParametersAdvanced,
  Selectors,
} from "../common";
import { Scheduler, SchedulerAdvanced } from "../common/scheduler";
import type { BlueprintFieldProps } from "../common/types";
import { createFieldNameWithPrefix } from "../common/utils";
import { FormContextProvider } from "../form-context-provider";
import { useBlueprintJson, useSyncWithEditor } from "../hooks";
import { PolicyFormTemplate } from "../templates";

export declare type ConcurrencySchedulingInputsProps<
  TFields extends FieldValues,
> = {} & BlueprintFieldProps<TFields>;

export const ConcurrencySchedulingInputs = <
  TFields extends FieldValues = ConcurrencySchedulingBlueprint.Coordinate,
>({
  fieldsPrefix = "policy.concurrency_scheduler" as Path<TFields>,
  label = "Concurrency Scheduler",
}: ConcurrencySchedulingInputsProps<TFields>) => {
  const { watch } = useFormContext<TFields>();

  useSyncWithEditor<TFields>(watch);

  return (
    <TreeItemStyled nodeId="concurrencyScheduler" label={label}>
      <MaxConcurrency<TFields>
        {...{
          fieldsPrefix,
        }}
      />
      <ConcurrencyLimiterParameterInputs<TFields>
        {...{
          fieldsPrefix: createFieldNameWithPrefix(
            fieldsPrefix,
            "concurrency_limiter",
          ),
        }}
      />
      <Scheduler<TFields>
        {...{
          fieldsPrefix: createFieldNameWithPrefix(fieldsPrefix, "scheduler"),
        }}
      />
      <Selectors<TFields>
        {...{
          fieldsPrefix,
        }}
      />
      <ConcurrencyLimiterParametersAdvanced<TFields>
        {...{
          fieldsPrefix: createFieldNameWithPrefix(
            fieldsPrefix,
            "concurrency_limiter",
          ),
        }}
      >
        <SchedulerAdvanced<TFields>
          {...{
            fieldsPrefix: createFieldNameWithPrefix(fieldsPrefix, "scheduler"),
          }}
        />
      </ConcurrencyLimiterParametersAdvanced>
    </TreeItemStyled>
  );
};

export const ConcurrencySchedulingForm: FC<PropsWithChildren> = ({
  children,
}) => {
  const [isDirty, setIsDirty] = useState(false);

  const { blueprintJson: policy } =
    useBlueprintJson<ConcurrencySchedulingBlueprint.Coordinate>(
      concurrencySchedulingFormDefaultValues,
      isDirty,
    );

  if (!policy) {
    return <LoaderLayout />;
  }

  return (
    <FormContextProvider<ConcurrencySchedulingBlueprint.Coordinate>
      defaultValues={policy}
    >
      {(methods) => (
        <PolicyFormTemplate<ConcurrencySchedulingBlueprint.Coordinate>
          {...{
            methods,
            recordIsDirty: [isDirty, setIsDirty],
            docTree: concurrencySchedulingDocsTree,
            texts: {
              policyName: "Policy name",
              blueprintLabel: "Concurrency Scheduling Blueprint",
            },
          }}
        >
          <PolicyFormTreeView
            treeViewProps={{
              defaultExpanded: ["concurrencyScheduler", "selectors"],
            }}
          >
            <Box component={Paper} p={2}>
              <ConcurrencySchedulingInputs />
            </Box>
          </PolicyFormTreeView>
          {children}
        </PolicyFormTemplate>
      )}
    </FormContextProvider>
  );
};
