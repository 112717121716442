import type { ThemeOptions } from "@mui/material";

const styleOverrides: Required<
  Required<ThemeOptions>["components"]
>["MuiCssBaseline"]["styleOverrides"] = {
  body: {
    maxHeight: "100vh",
    height: "100vh",
    // TODO: Find where we hide overflowY, remove important
    // overflowY: "scroll!important",
    // TODO: Find where we add body's right padding of 15px, remove important
    paddingRight: "0!important",
    overflowX: "hidden !important",
  },
  "#root": {
    height: "100%",
  },
  "::selection": {
    background: "#8EC4AD",
  },
};

export const MuiCssBaseline: Required<
  Required<ThemeOptions>["components"]
>["MuiCssBaseline"] = { styleOverrides };
