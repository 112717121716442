import { InfoOutlined } from "@mui/icons-material";
import { Paper, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import React, { type FC, type ImgHTMLAttributes, type ReactNode } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { TextLink } from "#shared/components/text-link";
import {
  BACKDROP_GRADIENT_DARK_MODE,
  BACKDROP_GRADIENT_LIGHT_MODE,
  FLUXNINJA,
  SUPPORT_EMAIL,
  BASE_DOCUMENTATION_URL,
} from "#shared/consts";
import logo from "#shared/fn-aperture-logo-full.svg";
import { firebaseUserState } from "#shared/recoil";
import type { Texts } from "#shared/types";

import { ErrorText } from "./error";

import { WelcomePageLogoutButton as LogoutButton } from "../logout-button";

const DEFAULT_IMAGE_PROPS: Required<
  Pick<ImgHTMLAttributes<HTMLImageElement>, "alt" | "src" | "style">
> = {
  src: logo,
  alt: [FLUXNINJA, logo].join(" "),
  style: { maxWidth: "400px" },
};

type Text = "needHelp" | "view" | "documentation" | "orSend" | "logout";

interface GlobalFrameProps {
  children: ReactNode;
  texts?: Texts<Text>;
  hideHelp?: boolean;
  hideLogout?: boolean;
  hideLogo?: boolean;
  logoSrc?: string;
  logoStyle?: React.CSSProperties;
  showSignInErrorMsg?: boolean;
}

const enText: Required<GlobalFrameProps["texts"]> = {
  needHelp: "Need help?",
  view: "View",
  documentation: "documentation",
  orSend: "or send email at",
  logout: "Logout",
};

export const GlobalFrame: FC<GlobalFrameProps> = ({
  children,
  texts = enText,
  hideHelp = false,
  hideLogout = false,
  hideLogo = false,
  logoSrc = "",
  logoStyle = {},
  showSignInErrorMsg = false,
}) => {
  const error = useRecoilValue(firebaseUserState.error);

  return (
    <GlobalFrameOuterWrapper>
      <GlobalFrameInnerWrapper component={Paper} position="relative">
        {hideLogo ? null : (
          <Box textAlign="center" pt={5}>
            <Link to="/">
              <img
                alt={DEFAULT_IMAGE_PROPS.alt}
                src={logoSrc !== "" ? logoSrc : DEFAULT_IMAGE_PROPS.src}
                style={{ ...DEFAULT_IMAGE_PROPS.style, ...logoStyle }}
              />
            </Link>
          </Box>
        )}
        <Box gridRow={3}>{children}</Box>

        {hideLogout ? null : (
          <LogoutWrapper>
            <LogoutButton isOrgContext={false} />
          </LogoutWrapper>
        )}

        {error && showSignInErrorMsg ? <ErrorText {...{ error }} /> : null}
      </GlobalFrameInnerWrapper>

      {hideHelp ? null : (
        <Box
          gridRow={4}
          gridColumn={2}
          pb={1}
          mx={2}
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          gap={0.5}
        >
          <InfoOutlined sx={{ color: "primary.main" }} />
          <Typography variant="body2" fontWeight="600" color="primary.main">
            {texts.needHelp}
          </Typography>
          <Typography variant="body2" color="primary.main" textAlign="center">
            {texts.view}{" "}
            <TextLink
              to={BASE_DOCUMENTATION_URL}
              target="_blank"
              sx={{ fontSize: "0.875rem" }}
            >
              {texts.documentation}
            </TextLink>{" "}
            {texts.orSend}{" "}
            <TextLink
              to={`mailto:${SUPPORT_EMAIL}`}
              sx={{ fontSize: "0.875rem" }}
            >
              {SUPPORT_EMAIL}
            </TextLink>
          </Typography>
        </Box>
      )}
    </GlobalFrameOuterWrapper>
  );
};

export const GlobalFrameOuterWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  textAlign: "center",
  background:
    theme.palette.mode === "light"
      ? BACKDROP_GRADIENT_LIGHT_MODE
      : BACKDROP_GRADIENT_DARK_MODE,
}));

export const GlobalFrameInnerWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  maxWidth: "800px",
  minHeight: "450px",
  width: "100%",
  gridTemplateRows: "repeat(auto, 1fr)",
  padding: theme.spacing(1, 8, 3, 8),
  margin: theme.spacing(3, 2),
  boxShadow:
    theme.palette.mode === "light"
      ? "0px 4px 5px -2px rgba(96, 101, 117, 0.2), 0px 7px 10px 1px rgba(96, 101, 117, 0.14), 0px 2px 16px 1px rgba(96, 101, 117, 0.12)"
      : "0px 0px 20px rgba(22, 20, 19, 0.25);",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1, 4, 3, 4),
  },
}));

export const LogoutWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
}));
