import {
  Box,
  type BoxProps,
  type TypographyProps,
  Typography,
} from "@mui/material";
import classnames from "classnames";
import { isString, isNumber, isNull } from "lodash";
import React, {
  type FC,
  type ImgHTMLAttributes,
  type PropsWithChildren,
  isValidElement,
} from "react";

import { GLOBAL_CLASSNAMES } from "#shared/components/global-styles/global-styles";
import { FLUXNINJA } from "#shared/consts";
import noDataImage from "#shared/logo-no-data.svg";

export type NoDataProps = PropsWithChildren<{
  outerContainerProps?: Omit<BoxProps, "children">;
  innerContainerProps?: Omit<BoxProps, "children">;
  typographyProps?: Omit<TypographyProps, "children">;
  imageProps?: Partial<ImgHTMLAttributes<HTMLImageElement>>;
}>;

const enText = "No data to display.";

const LOGO_SIZE = "320px";

const COMMON_BOX_PROPS: Omit<BoxProps, "children"> = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  overflow: "hidden",
};

export const NoDataLayout: FC<NoDataProps> = ({
  outerContainerProps,
  innerContainerProps,
  typographyProps,
  imageProps,
  children,
}) => {
  const defaultText = enText;

  const isStringOrNumberChildren = isString(children) || isNumber(children);

  return (
    <Box
      {...COMMON_BOX_PROPS}
      {...outerContainerProps}
      className={classnames(
        GLOBAL_CLASSNAMES.noDataLayout,
        outerContainerProps?.className,
      )}
    >
      <Box {...COMMON_BOX_PROPS} {...innerContainerProps}>
        <img
          src={noDataImage}
          alt={[FLUXNINJA, "logo"].join(" ")}
          style={{ height: LOGO_SIZE, width: LOGO_SIZE }}
          {...imageProps}
        />
        {isStringOrNumberChildren || !children ? (
          <Typography {...typographyProps}>
            {children || defaultText}
          </Typography>
        ) : null}
        {!isStringOrNumberChildren &&
        (isValidElement(children) ||
          (Array.isArray(children) &&
            children.every((child) => isValidElement(child) || isNull(child))))
          ? children
          : null}
      </Box>
    </Box>
  );
};
