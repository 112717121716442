import { Box } from "@mui/system";
import React, { type FC, useState } from "react";
import { useRecoilState } from "recoil";

import {
  FilteringGroup,
  useChipsContainer,
} from "#shared/components/filtering/filtering-group";
import { useParseLinkAttributesToFilterVariables } from "#shared/components/filtering/utils";
import { KubernetesControlPointSelectColumn } from "#shared/generated/graphql";

import {
  DataGrid,
  DataGridGroups,
  DataGridWrapper,
  FiltersBarWrapper,
  GroupByButtonWrapper,
  GroupBySelect,
  type GroupByOption,
} from "#organization/components/data-grid";
import { projectContextState } from "#organization/recoil/project";

import {
  useControlPointsKubernetesData,
  useControlPointsKubernetesGroupData,
  useControlPointsKubernetesGroupsTitles,
  useControlPointsKubernetes,
} from "./hooks";
import { KubernetesInstalled } from "./kubernetes-installed";
import { NoKubernetes } from "./no-kubernetes";
import { filters, headCells } from "./table";

const GROUP_BYS: GroupByOption<KubernetesControlPointSelectColumn>[] = [
  {
    title: "No value",
    value: "",
  },
  {
    title: "Name",
    value: KubernetesControlPointSelectColumn.Name,
  },
];

const INITIAL_ORDER_BY = "name";
const INITIAL_ORDER = "asc";
const TABLE_UNIQUE_ID = "kubernetes-table";

export const ControlPointsKubernetesTab: FC = () => {
  const [projectContext] = useRecoilState(projectContextState);
  const chipsContainer = useChipsContainer();
  const filterHeaders = filters(projectContext.id);
  const [groupByKey, setGroupByKey] =
    useState<(typeof GROUP_BYS)[number]["value"]>("");
  const [filterVariables, setFilterVariables] = useState(
    useParseLinkAttributesToFilterVariables(TABLE_UNIQUE_ID),
  );

  const headerTable = headCells();

  const { isEmpty, justInstalled } = useControlPointsKubernetes();

  return (
    <Box pt={2}>
      {justInstalled && <KubernetesInstalled />}

      <FiltersBarWrapper enableArchivedData>
        <FilteringGroup
          labels={filterHeaders}
          onFilter={setFilterVariables}
          chipsContainer={chipsContainer}
          uniqueId={TABLE_UNIQUE_ID}
        />
        <GroupByButtonWrapper>
          <GroupBySelect
            options={GROUP_BYS}
            setGroupByKey={setGroupByKey}
            groupByKey={groupByKey}
          />
        </GroupByButtonWrapper>
      </FiltersBarWrapper>

      <Box py={4}>
        {isEmpty && <NoKubernetes />}
        {!isEmpty && (
          <DataGridWrapper>
            {groupByKey ? (
              <DataGridGroups
                headCells={headerTable}
                useGroupsTitles={useControlPointsKubernetesGroupsTitles}
                useGroupData={useControlPointsKubernetesGroupData}
                groupByKey={groupByKey}
                filterVariables={{
                  ...filterVariables,
                }}
                initialOrderBy={INITIAL_ORDER_BY}
                initialOrder={INITIAL_ORDER}
                enableArchivedData
              />
            ) : (
              <DataGrid
                headCells={headerTable}
                useGridData={useControlPointsKubernetesData}
                enabled={!groupByKey}
                filterVariables={filterVariables}
                initialOrderBy={INITIAL_ORDER_BY}
                initialOrder={INITIAL_ORDER}
                enableArchivedData
              />
            )}
          </DataGridWrapper>
        )}
      </Box>
    </Box>
  );
};
