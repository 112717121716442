import { TextField } from "@mui/material";
import React, { type ReactNode } from "react";
import {
  Controller,
  useFormContext,
  type FieldValues,
  type Path,
} from "react-hook-form";

import { createFieldNameWithPrefix } from "./utils";

import { TimeRangePickerField } from "../../inputs";
import { FieldWithTitle, FormFieldWrapper, TreeItemStyled } from "../../styled";
import * as ConcurrencyLimitBlueprint from "../concurrency-limiting/@types";
import { validateFieldWithRequiredSign } from "../consts";

export declare type ConcurrencyLimiterParameterInputsProps<
  TFields extends FieldValues = ConcurrencyLimitBlueprint.Coordinate,
> = {
  fieldsPrefix?: Path<TFields>;
  children?: ReactNode;
};

export const ConcurrencyLimiterParameterInputs = <
  TFields extends FieldValues = ConcurrencyLimitBlueprint.Coordinate,
>({
  fieldsPrefix = "policy.concurrency_limiter.parameters" as Path<TFields>,
}: ConcurrencyLimiterParameterInputsProps<TFields>): JSX.Element => {
  const { register, control, getValues } = useFormContext<TFields>();

  const defaultValues = getValues(fieldsPrefix);

  return (
    <FormFieldWrapper mt={1}>
      <Controller
        name={createFieldNameWithPrefix(fieldsPrefix, "max_inflight_duration")}
        control={control}
        defaultValue={defaultValues?.max_inflight_duration}
        rules={validateFieldWithRequiredSign<TFields>(
          "max_inflight_duration is required",
        )}
        render={({ field, fieldState }) => (
          <TimeRangePickerField
            fieldLabelProps={{
              label: "Max Inflight Duration",
              fieldAddress: createFieldNameWithPrefix(
                fieldsPrefix,
                "max_inflight_duration",
              ),
              required: true,
            }}
            value={field.value}
            onChange={field.onChange}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
          />
        )}
      />
      <FieldWithTitle
        label="Limit by label key"
        fieldAddress={createFieldNameWithPrefix(
          fieldsPrefix,
          "limit_by_label_key",
        )}
      >
        <TextField
          {...{
            ...register(
              createFieldNameWithPrefix(fieldsPrefix, "limit_by_label_key"),
            ),
            placeholder: "limit_by_label_key",
          }}
        />
      </FieldWithTitle>
    </FormFieldWrapper>
  );
};

// NOTE: Advance is not inside the schema it is added to show optional fields
export const ConcurrencyLimiterParametersAdvanced = <
  TFields extends FieldValues = ConcurrencyLimitBlueprint.Coordinate,
>({
  fieldsPrefix = "policy.concurrency_limiter.parameters" as Path<TFields>,
  children,
}: ConcurrencyLimiterParameterInputsProps<TFields>): JSX.Element => {
  const { control, getValues } = useFormContext<TFields>();

  const defaultValues = getValues(fieldsPrefix);

  return (
    <TreeItemStyled nodeId={`${fieldsPrefix}.advanced`} label="Advanced">
      <FormFieldWrapper mt={1}>
        <Controller
          name={createFieldNameWithPrefix(fieldsPrefix, "max_idle_time")}
          control={control}
          defaultValue={defaultValues?.max_idle_time}
          render={({ field, fieldState }) => (
            <TimeRangePickerField
              fieldLabelProps={{
                label: "Max idle time",
                fieldAddress: createFieldNameWithPrefix(
                  fieldsPrefix,
                  "max_idle_time",
                ),
              }}
              value={field.value}
              onChange={field.onChange}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </FormFieldWrapper>
      {children}
    </TreeItemStyled>
  );
};
