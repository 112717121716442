import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined";
import type { SvgIconProps } from "@mui/material";
import React, { type CSSProperties, type FC, type MouseEvent } from "react";

export interface SilentChannelIconProps
  extends Omit<SvgIconProps, "sx" | "color" | "onClick"> {
  silencedUntil: string | undefined;
  onClick?: (event: MouseEvent<SVGSVGElement>) => void;
}

export const SilentChannelIcon: FC<SilentChannelIconProps> = ({
  silencedUntil,
  onClick,
  ...otherProps
}) => (
  <>
    {silencedUntil ? (
      <NotificationsOffOutlinedIcon
        aria-describedby="fn-mute-channel-popper"
        onClick={onClick}
        sx={{ cursor: "pointer" }}
        color="error"
        {...otherProps}
      />
    ) : (
      <NotificationsNoneIcon
        aria-describedby="fn-mute-channel-popper"
        onClick={onClick}
        sx={iconSx}
        {...otherProps}
      />
    )}
  </>
);

const iconSx: CSSProperties = {
  cursor: "pointer",
  opacity: 0.5,
};
