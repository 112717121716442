import { CircularProgress, Tooltip } from "@mui/material";
import React, { type FC } from "react";

import { useProjectProvisioningStatus } from "../../hooks-new";

type ProjectProvisioningStatusProps = {
  projectID: string;
};

export const ProjectProvisioningStatus: FC<ProjectProvisioningStatusProps> = ({
  projectID,
}) => {
  const projectProvisioningStatus = useProjectProvisioningStatus(projectID);

  if (projectProvisioningStatus !== "PROVISIONING") {
    return null;
  }

  return (
    <Tooltip title="Project is being provisioned">
      <CircularProgress size={20} />
    </Tooltip>
  );
};
