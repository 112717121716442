import React, { type FC, type ReactNode } from "react";

import { ENV } from "#shared/env";

export const FEATURE_FLAGS = {
  /**
   * Close account feature in settings
   */
  closeAccount: false,
  /**
   * Resend verification email feature in settings
   */
  resendVerificationEmail: false,
  /**
   * Set time zone feature in settings
   */
  setTimeZone: false,
  /**
   * Added on migration from React@17 to React@18
   * in order to avoid regression bugs in demo cluster.
   *
   * React.StrictMode should be restored asap.
   */
  reactStrictMode: false,
  /**
   * Ability to create/edit policies
   */
  canCreatePolicy: true,
  /**
   * Ability to create custom policies
   */
  showCreateCustomPolicy: false,
  /**
   * Websocket organization polling
   */
  wsOrganizationPolling: false,
  /**
   * Is add controllers/agents column visible in Aperture/API keys table
   */
  addControllersAgentsColumn: false,
  /**
   * Is policy components tab in policy side panel enabled
   */
  policyComponents: true,
  /**
   * For now we hide the skip guide button,
   * because we need to rethink the flow with install aperture step and the future billing step
   */
  skipWelcomeGuideButton: false,
  /**
   * Is request integration form enabled in integrations page
   */
  requestIntegrationForm: true,
  /**
   * Is SAML sign in configuration enabled in organization settings
   */
  samlSignInConfiguration: true,
  /**
   * Is Google sign in configuration enabled in organization settings
   */
  googleSignInConfiguration: true,
  /**
   * Is custom personalized plan enabled in billing page
   */
  customPersonalizedPlan: false,
  /**
   * Is billing enabled in global ui
   */
  globalBilling:
    ENV.GLOBAL_BILLING_ENABLED?.toString().toLowerCase() === "true",
} as const;

export type FeatureFlagType = keyof typeof FEATURE_FLAGS;

type FeatureFlagProps = {
  flag: FeatureFlagType[] | FeatureFlagType;
  renderOn?: ReactNode;
  renderOff?: ReactNode;
  all?: boolean;
};

/**
 * This renders the component in the renderOn prop if the feature flag
 * is active and the component in renderOff if it's not.
 *
 * The 'all' property is true by default which means that all flags provided must be active rather than just one.
 *
 * @example <caption>Example usage of FeatureFlag component</caption>
 * <FeatureFlag flag="billing" renderOn={<Billing />} renderOff={<BillingDisabled />} />
 *
 * @example <caption>Example usage of FeatureFlag component with multiple flags</caption>
 * <FeatureFlag flag={["billing", "specialBilling"]} renderOn={<SpecialBilling />} renderOff={<SpecialBillingDisabled />} />
 */
export const FeatureFlag: FC<FeatureFlagProps> = ({
  flag,
  renderOff = null,
  renderOn = null,
  all = true,
}) => {
  const flagsArray = Array.isArray(flag) ? flag : [flag];

  const isActive = all
    ? flagsArray.every((f) => FEATURE_FLAGS[f])
    : flagsArray.some((f) => FEATURE_FLAGS[f]);

  return <>{isActive ? renderOn : renderOff}</>;
};
