import { gql } from "graphql-request";
import { useState } from "react";

import type { GetOrganizationEmailListQuery } from "#shared/generated/graphql";
import { useGqlQuery } from "#shared/utils";

import { QUERY_KEYS } from "#organization/pages/consts";

export const OrganizationEmailList = gql`
  query GetOrganizationEmailList($first: Int, $where: UserBoolExp!) {
    users(first: $first, where: $where) {
      edges {
        node {
          email
          id
        }
      }
    }
  }
`;

export const useOrganizationEmailList = (userGroupId: string | undefined) => {
  const [search, setSearch] = useState<string>("");
  const findBy = {
    first: 4,
    where: {
      not: {
        userGroupMemberships: {
          userGroupID: { eq: userGroupId },
        },
      },
      email: { like: `${search}%` },
    },
  };
  const { isError, isLoading, data, refetch } =
    useGqlQuery<GetOrganizationEmailListQuery>(
      [QUERY_KEYS.ORGANIZATION_EMAIL_LIST, findBy],
      OrganizationEmailList,
      findBy,
    );

  return {
    error: isError,
    loading: isLoading,
    users: data?.users.edges,
    setSearch,
    search,
    refetch,
  };
};
