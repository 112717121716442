import { GlobalStyles, Box, styled } from "@mui/material";
import classnames from "classnames";
import React, { type FC, type PropsWithChildren } from "react";

import { useGridAreaStyles } from "./use-grid-area-styles";

export interface GridViewProps {
  snapToTop?: boolean;
}

export const GridView: FC<PropsWithChildren<GridViewProps>> = (props) => {
  const { children, snapToTop } = props;

  const { styles, classNames } = useGridAreaStyles();

  return (
    <>
      <GlobalStyles {...{ styles }} />
      <CircuitViewStyled
        className={classnames(classNames.grid)}
        snapToTop={snapToTop}
      >
        {children}
      </CircuitViewStyled>
    </>
  );
};

const Z_INDEX = 900;

export const CircuitViewStyled = styled(Box, {
  shouldForwardProp: (props) => props !== "snapToTop",
})<{ snapToTop?: boolean }>(({ snapToTop = false }) => ({
  ...(snapToTop && {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: Z_INDEX,
  }),
}));
