import { List, ListItemButton } from "@mui/material";
import { styled } from "@mui/system";
import React, { type FC } from "react";
import { Link } from "react-router-dom";

import type { Route } from "#shared/types";

const LIST_ITEM_HEIGHT = 40;

const LinkWithoutUnderline = styled(Link)(() => ({
  textDecoration: "none",
}));

export interface QuickLinksProps {
  routes: Route[];
}

export const QuickLinks: FC<QuickLinksProps> = ({ routes }) => (
  <List disablePadding sx={{ width: "100%" }}>
    {routes.map((route) => {
      const isDisabled = route.ABSOLUTE_PATH === null;

      const listItem = (
        <ListItemButton
          sx={({ spacing }) => ({
            backgroundColor: "transparent",
            overflow: "hidden",
            width: "100%",
            paddingLeft: spacing(2),
            fontSize: 16,
            height: LIST_ITEM_HEIGHT,
          })}
          disabled={isDisabled}
          key={route.TITLE}
        >
          {route.TITLE}
        </ListItemButton>
      );

      return isDisabled ? (
        listItem
      ) : (
        <LinkWithoutUnderline
          key={route.ABSOLUTE_PATH}
          to={route.ABSOLUTE_PATH as string}
          sx={{ width: "100%", display: "block", height: LIST_ITEM_HEIGHT }}
        >
          {listItem}
        </LinkWithoutUnderline>
      );
    })}
  </List>
);
