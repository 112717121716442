import { WarningAmber } from "@mui/icons-material";
import {
  Box,
  Divider,
  Paper,
  Tooltip,
  tooltipClasses,
  type TooltipProps,
  Typography,
  Zoom,
  styled,
  type BoxProps,
} from "@mui/material";
import React, { type FC, type ReactNode } from "react";

// TODO: Sizes can be moved to theme, then they are accessible in all components
const PAPER_HEIGHT = 400;
const ACTIONS_HEIGHT = 58;
const HEADER_HEIGHT = 65;

const PaperWrapper = styled(Paper)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: PAPER_HEIGHT,
});

const WarningTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
    fontSize: "1rem",
    padding: theme.spacing(1.5, 2.5),
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.warning.light,
  },
}));

export interface CardProps {
  title: string;
  subtitle?: string;
  avatar: ReactNode;
  content: ReactNode;
  additionalNotification?: boolean;
  actions?: ReactNode;
  actionsContainerProps?: BoxProps;
  contentContainerProps?: BoxProps;
  headerContainerProps?: BoxProps;
}

export const Card: FC<CardProps> = ({
  title,
  subtitle,
  avatar,
  content,
  additionalNotification = false,
  actions,
  actionsContainerProps,
  contentContainerProps,
  headerContainerProps,
}) => (
  <PaperWrapper elevation={1} sx={() => ({ overflow: "hidden" })}>
    <Box
      sx={{
        boxSizing: "border-box",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flexGrow: 0,
        flexShrink: 0,
        height: HEADER_HEIGHT,
        minHeight: HEADER_HEIGHT,
      }}
    >
      <Box
        p={2}
        sx={{
          overflow: "hidden",
        }}
      >
        <Box display="flex" {...headerContainerProps}>
          <Box>{avatar}</Box>
          <Box
            {...{
              display: "flex",
              flexGrow: 0,
              flexShrink: 1,
              height: "100%",
              minHeight: "100%",
              flexDirection: "column",
              ml: 2,
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontWeight: 500,
                  color: "text.primary",
                }}
                fontSize={16}
              >
                {title}
              </Typography>
            </Box>
            {subtitle !== null && (
              <Box>
                <Typography
                  sx={{
                    color: "text.secondary",
                  }}
                  fontSize={14}
                  variant="body2"
                >
                  {subtitle}
                </Typography>
              </Box>
            )}
          </Box>
          {additionalNotification && (
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "flex-end",
              }}
            >
              <WarningTooltip
                placement="top"
                arrow
                TransitionComponent={Zoom}
                title={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <WarningAmber
                        sx={{
                          color: "warning.main",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        ml: 2,
                      }}
                    >
                      <Typography>
                        Organization settings can only be changed by users with
                        Organization Owner role
                      </Typography>
                    </Box>
                  </Box>
                }
              >
                <WarningAmber
                  sx={{
                    color: "warning.main",
                  }}
                />
              </WarningTooltip>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
    <Divider sx={({ spacing }) => ({ margin: [spacing(2), 0].join(" ") })} />
    <Box
      {...{
        display: "flex",
        flexGrow: 1,
        flexShrink: 1,
        overflow: "hidden",
      }}
      {...contentContainerProps}
    >
      {content}
    </Box>
    <Divider sx={({ spacing }) => ({ margin: [spacing(2), 0].join(" ") })} />
    <Box
      display="flex"
      flexGrow={0}
      flexShrink={0}
      justifyContent="flex-end"
      height={ACTIONS_HEIGHT}
      sx={({ spacing }) => ({
        maxHeight: ACTIONS_HEIGHT,
        padding: [0, spacing(2), spacing(2)].join(" "),
      })}
      {...actionsContainerProps}
    >
      {actions}
    </Box>
  </PaperWrapper>
);

Card.defaultProps = {
  subtitle: undefined,
  additionalNotification: false,
};
