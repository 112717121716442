import {
  CircularProgress,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { get, isEmpty } from "lodash";
import React, { type FC, useContext, useEffect, useMemo, useRef } from "react";
import { ReactFlowProvider } from "react-flow-renderer";
import { useParams } from "react-router-dom";

import { BreadcrumbLink } from "#shared/components/breadcrumbs";
import {
  LoaderLayout,
  ErrorLayout,
  ErrorText,
  NoDataLayout,
} from "#shared/components/layouts";
import { TabPanel } from "#shared/components/tab-panel";
import type { Policy } from "#shared/generated/graphql";
import { useDomRect } from "#shared/hooks";
import type { Texts } from "#shared/types";

import { useTabNavigation } from "#organization/hooks";
import { TopBarBreadcrumbAppender } from "#organization/recoil/breadcrumbs";
import { useHeaderSectionUpdate } from "#organization/recoil/organization/header-section";

import { FindMisconfiguredPolicy } from "./components/find-misconfigured-policy";
import { usePolicyQueryParams } from "./hooks";
import {
  SelectedResourcesContext,
  useSelectedResourceParams,
} from "./selected-resource-context";
import { PolicyAlerts } from "./tabs";
import { CircuitPage } from "./tabs/circuit";
import { CircuitProvider } from "./tabs/circuit/circuit-context";
import { DashboardsPage, useDashboardPageHelper } from "./tabs/dashboards";
import { DashboardTab } from "./tabs/dashboards/components/dashboard-tab";

import type { FnTheme } from "../../../../../../app-theme-provider/types";
import { DeleteBoxProvider } from "../../alert-manager/components";
import { TableActions } from "../components/table/head-components";
import { usePoliciesDataById, usePolicy } from "../hooks";
import {
  POLICIES_ROUTE,
  type PolicyDetailsParams,
} from "../routes.definitions";

export interface PolicyPageProps {
  texts?: Texts<Text>;
}

type Text =
  | "policy"
  | "circuit"
  | "dashboards"
  | "alerts"
  | "policiesDocumentation";

const enTexts: Required<PolicyPageProps["texts"]> = {
  policy: "Policy",
  circuit: "Circuit",
  dashboards: "Dashboards",
  alerts: "Triggering Alerts",
  policiesDocumentation: "See policies documentation",
};

const TAB_NAMES = ["dashboards", "circuit", "alerts"];

export const PolicyPage: FC<PolicyPageProps> = (props) => {
  const { texts = enTexts } = props;

  const { policyId } = useParams<PolicyDetailsParams>();
  const { policySearchParams, setPolicySearchParams } = usePolicyQueryParams();

  const theme = useTheme() as FnTheme;

  const policyContainerHeight = `calc(100vh - ${theme.shape.topBarHeight} - ${
    theme.shape.policyView.headerHeight
  } - ${theme.spacing(4)})`;

  const { onTabsChange: setActiveTab, currentTab: activeTab } =
    useTabNavigation(TAB_NAMES);

  const { resetSelectedResources, selectedResource } = useContext(
    SelectedResourcesContext,
  );

  const queryResult = usePolicy({
    variables: { where: { id: { eq: policyId } } },
  });

  const {
    query: { data, isLoading, error, isFetched },
  } = queryResult;

  const policyData = usePoliciesDataById(policyId);

  const containerRef = useRef<HTMLDivElement | null>();
  const boundingRect = useDomRect(containerRef);

  const { policies: { edges: [{ node: policy } = { node: null }] = [] } = {} } =
    data || {};

  const selectedResourceSearchParams =
    useSelectedResourceParams(selectedResource);

  /**
   * NOTE:
   * Load selected resource info to URLSearchParams
   */
  useEffect(() => {
    if (!selectedResourceSearchParams) {
      return;
    }

    if (
      selectedResourceSearchParams.resourceId === policySearchParams.resourceId
    ) {
      return;
    }

    if (TAB_NAMES[activeTab] !== selectedResourceSearchParams?.resourceView) {
      setPolicySearchParams("");

      return;
    }

    if (TAB_NAMES[activeTab] !== "circuit") {
      return;
    }

    // Load dashboard query params. Used on circuit page.
    setPolicySearchParams({
      ...selectedResourceSearchParams,
    });
  }, [
    activeTab,
    policySearchParams.resourceId,
    selectedResourceSearchParams,
    setPolicySearchParams,
  ]);

  const circuit = get(policy, "circuit", {});
  const isNoData = isFetched && isEmpty(circuit);
  const name = get(policy, "name", "-");

  const isData = !!(!error && !isLoading && !isNoData && policy);

  const dashboardPageProps = useDashboardPageHelper({
    policyHash: policy?.hash || "",
    policyId: policy?.id || "",
  });

  useHeaderSectionUpdate({
    pageTitle: useMemo(
      () =>
        isLoading ? (
          <CircularProgress disableShrink size="1em" />
        ) : (
          <Box
            {...{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {name}
            <>
              {TAB_NAMES[activeTab] === "dashboards" && (
                <Box
                  id="grafana-controls"
                  sx={({ palette }) => ({
                    "& .button-group > button": {
                      backgroundColor:
                        palette.mode === "light"
                          ? palette.background.default
                          : palette.grey[800],
                    },
                  })}
                />
              )}
            </>
          </Box>
        ),
      [activeTab, isLoading, name],
    ),
    children: useMemo(
      () => (
        <>
          <Tabs
            value={activeTab}
            onChange={(e, v) => {
              resetSelectedResources();
              setPolicySearchParams("");
              setActiveTab(e, v);
            }}
          >
            <DashboardTab
              {...{
                label: texts.dashboards,
                onClick: (e) => {
                  resetSelectedResources();
                  setPolicySearchParams("");
                  setActiveTab(e, 0);
                },
                dashboards: dashboardPageProps.listItems,
                isSelected: dashboardPageProps.isSelected,
                selectOne: dashboardPageProps.selectOne,
              }}
            />
            <Tab label={texts.circuit} />
            <Tab label={texts.alerts} />
          </Tabs>
          {policyData && (
            <DeleteBoxProvider>
              <Box position="absolute" top={5} right={19}>
                <TableActions {...policyData} />
              </Box>
            </DeleteBoxProvider>
          )}
        </>
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [activeTab, policyData, dashboardPageProps.listItems],
    ),
  });

  return (
    <CircuitProvider>
      <Box
        ref={containerRef}
        id="fn-policy-container-outer"
        sx={{ maxHeight: "100%", height: "100%" }}
      >
        <TopBarBreadcrumbAppender>
          <BreadcrumbLink to={POLICIES_ROUTE.ABSOLUTE_PATH}>
            {POLICIES_ROUTE.TITLE}
          </BreadcrumbLink>
        </TopBarBreadcrumbAppender>
        <TopBarBreadcrumbAppender>
          <Typography>
            <Typography component="span">
              {isFetched ? policy?.name : <CircularProgress size="1em" />}
            </Typography>
          </Typography>
        </TopBarBreadcrumbAppender>
        <Box px={3} sx={{ maxHeight: "100%", height: policyContainerHeight }}>
          {error && !isLoading ? (
            <ErrorLayout>
              <ErrorText>{(error as Error).message || null}</ErrorText>
            </ErrorLayout>
          ) : null}
          {isLoading && <LoaderLayout />}
          {!error && !isLoading && isNoData && (
            <NoDataLayout outerContainerProps={{ my: 4 }} />
          )}
          {isData ? (
            <>
              <TabPanel
                value="dashboards"
                activeValue={TAB_NAMES[activeTab]}
                sx={{ maxHeight: "100%", height: "100%", overflow: "hidden" }}
              >
                <DashboardsPage {...dashboardPageProps} />
              </TabPanel>
              <TabPanel
                value="circuit"
                activeValue={TAB_NAMES[activeTab]}
                sx={{ maxHeight: "100%", height: "100%" }}
              >
                <FindMisconfiguredPolicy policy={policy as Policy}>
                  {boundingRect ? (
                    <ReactFlowProvider>
                      <CircuitPage policyId={policy.id} width={0} />
                    </ReactFlowProvider>
                  ) : null}
                </FindMisconfiguredPolicy>
              </TabPanel>
              <TabPanel
                value="alerts"
                activeValue={TAB_NAMES[activeTab]}
                sx={{ maxHeight: "100%", height: "100%" }}
              >
                <PolicyAlerts policyName={name} />
              </TabPanel>
            </>
          ) : null}
        </Box>
      </Box>
    </CircuitProvider>
  );
};
