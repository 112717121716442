import React, { type FC } from "react";

import { InfoDataPage } from "#shared/components/layouts";
import type { Texts } from "#shared/types";

export interface NoFlowControlProps {
  texts?: Texts<Text>;
}

type Text = "noControlPoints" | "controlPointsDesc";

const enTexts: NoFlowControlProps["texts"] = {
  noControlPoints: "No Control Points found",
  controlPointsDesc:
    "To see metrics and set up policies, please install Control Points on your services. Check out the documentation for easy steps on how to do it.",
};

export const NoFlowControl: FC<NoFlowControlProps> = ({ texts = enTexts }) => (
  <InfoDataPage
    title={texts.noControlPoints}
    description={texts.controlPointsDesc}
    docButtonLink="/concepts/control-point"
  />
);
