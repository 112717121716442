import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import type { Variables } from "graphql-request";
import { pick } from "lodash";
import React, { type FC } from "react";

import type { AlertsQuery } from "#shared/generated/graphql";

import {
  useAlertLogs,
  useAlertsCountBySeverity,
} from "#organization/pages/authenticated/alert-manager/data-center";

import { AlertTile, type AlertTileProps } from "./alert-tile";
import { TileRowWrapper } from "./styled";

import type { AlertSeverity } from "../../../../../../../../../types";

export type AlertsLogTileType = AlertsQuery["alerts"]["edges"][number];

export interface AlertTilesSectionProps {
  severity: AlertSeverity[];
  where?: Variables;
}

export const AlertTilesSection: FC<AlertTilesSectionProps> = ({
  severity,
  where,
}) => {
  const { data, ...totalData } = useAlertLogs(where);

  const { crit, warn, info } = useAlertsCountBySeverity(severity, where);

  const mapTileArray: AlertTileProps[] = [
    {
      severity: "crit",
      title: "Crit Alerts",
      data: crit,
      icon: <ErrorOutlineIcon />,
    },
    {
      severity: "warn",
      title: "Warn Alerts",
      data: warn,
      icon: <WarningAmberIcon />,
    },
    {
      severity: "info",
      title: "Info Alerts",
      data: info,
      icon: <CheckCircleOutlineIcon />,
    },
  ];

  return (
    <TileRowWrapper>
      <AlertTile
        title="Alerts"
        severity="total"
        data={{
          data: data?.alerts.totalCount || 0,
          ...pick(totalData, "isError", "isLoading", "isIdle", "isFetched"),
        }}
        icon={<NotificationsNoneIcon />}
      />
      {mapTileArray.map((tileProps) => (
        <AlertTile {...tileProps} key={tileProps.severity} />
      ))}
    </TileRowWrapper>
  );
};
