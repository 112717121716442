import { Box, Chip, styled, Typography } from "@mui/material";

export const LabelBoxCapsule = styled(Chip)(({ theme }) => ({
  backgroundColor:
    theme.palette.grey[theme.palette.mode === "light" ? 300 : 600],
  marginBottom: theme.spacing(0.5),
  cursor: "pointer",
  maxWidth: 200,
  marginRight: 5,
  borderRadius: theme.spacing(0.5),
}));

export const LabelText = styled(Typography)(({ theme }) => ({
  width: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textAlign: "left",
  fontSize: 14,
  fontWeight: 400,
  color: theme.palette.grey[theme.palette.mode === "light" ? 600 : 300],
  "> span": {
    fontWeight: "500",
  },
}));

export const LabelBoxWrapper = styled(Box)(({ theme }) => ({
  maxWidth: 200,
  gap: 0.5,
  lineHeight: "200%",
  padding: theme.spacing(1),
}));

export interface BulletExtraProps {
  status: string;
}

export const Bullet = styled(Box, {
  shouldForwardProp: (prop) => prop !== "status",
})<BulletExtraProps>(({ theme, status }) => ({
  backgroundColor:
    status === "resolved" ? theme.palette.info.main : theme.palette.error.main,
  borderRadius: "50%",
  width: 8,
  height: 8,
  alignSelf: "center",
}));
