import type { RouteDefinition } from "#shared/types";

import type { GenericDashboardType } from "#organization/components/grafana";
import type { ResourceWithGenericDashboard } from "#organization/pages/types";

export const CONTROL_POINTS_ROUTE: RouteDefinition<string> = {
  TITLE: "Control Points",
  KEY: "control-points",
  CHILD_KEY: "controlPointsId",
  ABSOLUTE_PATH: "/control-points",
  PARENT_PATH: "control-points/*",
  CHILD_PATH: ":controlPointsId",
};

export type ControlPointIdParam = {
  controlPointsId: string;
};

export const DEFAULT_DASHBOARDS: {
  [R in ResourceWithGenericDashboard]: GenericDashboardType;
} = {
  Classifier: "FLOW_ANALYTICS",
  ConcurrencyLimiter: "FLOW_ANALYTICS",
  FluxMeter: "FLOW_ANALYTICS",
  RateLimiter: "FLOW_ANALYTICS",
  Signal: "SIGNAL",
};
