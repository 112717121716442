import { useTheme, type PaletteMode } from "@mui/material";
import { isEqual, noop } from "lodash";
import type { MicroApp } from "qiankun";
import {
  type RefObject,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { useMutation } from "react-query";

import { API_SERVICE_URL } from "#shared/consts";
import { useLogout } from "#shared/hooks";
import { LoggerService } from "#shared/services";
import { httpClient } from "#shared/utils/http-client";

import { useThemeState } from "#organization/recoil/theme";

import { GRAFANA_APP_NAME, GRAFANA_PROVIDER_VIEWPORT_ID } from "./consts";
import { useGrafanaContext } from "./grafana-context";
import { GrafanaUtils, type LoadAppOptions } from "./utils";

export type UseGrafanaDashboardOptions<Q extends string = string> = Partial<
  Pick<
    LoadAppOptions<Q>,
    | "name"
    | "version"
    | "slug"
    | "entry"
    | "queryParams"
    | "hiddenVariables"
    | "fnError"
  >
> & {
  uuid?: string | null;
  containerRef?: RefObject<HTMLElement> | null;
  isQueryParamsRequired?: boolean;
};

export const useGrafanaDashboard = () => {
  const { grafanaPortal, action } = useGrafanaContext();

  const grafanaUtils = useRef(
    new GrafanaUtils((err) => {
      if (!action) {
        return;
      }

      const error = err instanceof Event ? err.type || "" : err;

      action({
        type: "SET_GRAFANA_ERROR",
        payload: error,
      });
    }),
  );

  const {
    mutate: loadGrafanaApp,
    isLoading: isGrafanaAppLoading,
    isError: isGrafanaAppError,
  } = useMutation({
    mutationFn: grafanaUtils.current.load,
  });

  const {
    mutate: updateGrafanaApp,
    isLoading: isGrafanaAppUpdating,
    isError: isGrafanaAppUpdateError,
  } = useMutation({
    mutationFn: grafanaUtils.current.update,
  });

  useEffect(() => {
    if (!action) {
      return;
    }

    action({
      type: "SET_GRAFANA_UTILS",
      payload: grafanaUtils.current,
    });
  }, [action]);

  useEffect(() => {
    if (
      !grafanaPortal ||
      isEqual(grafanaPortal, grafanaUtils.current.appData?.props)
    ) {
      return;
    }

    updateGrafanaApp(grafanaPortal);
  }, [grafanaPortal, updateGrafanaApp]);

  return {
    isGrafanaAppUpdating,
    isGrafanaAppLoading,
    isGrafanaAppError,
    isGrafanaAppUpdateError,
    updateGrafanaApp,
    loadGrafanaApp,
  };
};

const DARK_PORTAL = "dark-theme-portal";
const LIGHT_PORTAL = "light-theme-portal";

export const usePortalKey = () => {
  const { mode } = useThemeState();

  return { portalKey: mode === "light" ? LIGHT_PORTAL : DARK_PORTAL };
};

/**
 *
 * @param uid - Grafana dashboard uid
 * @returns isError - true if dashboard with provided uid doesn't exist.
 *
 * Additionally, if user is not authenticated, it will logout the user
 */
export const useValidateDashboardUid = (uid: string | undefined) => {
  const [isError, setIsError] = useState(false);
  const logout = useLogout(true);

  useEffect(() => {
    if (!uid) {
      return;
    }

    httpClient
      .get({
        url: `${API_SERVICE_URL.origin}/grafana/api/dashboards/uid/${uid}`,
      })
      .then((res) => {
        setIsError(!res.ok);

        if (!res.ok && res.status === 401) {
          logout();
        }
      })
      .catch(() => setIsError(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  return isError;
};

export const useRootGrafanaApp = (
  mode: PaletteMode,
  containerRef: React.RefObject<HTMLElement> | null,
) => {
  const { action } = useGrafanaContext();

  const { loadGrafanaApp, updateGrafanaApp } = useGrafanaDashboard();

  const isRootAppLoaded = useRef(false);

  const load = useCallback(() => {
    if (!containerRef?.current || isRootAppLoaded.current) return;

    loadGrafanaApp(
      {
        name: GRAFANA_APP_NAME,
        container: containerRef.current,
        entry: GrafanaUtils.DEFAULT_ENTRY,
        props: {
          mode,
        },
        configuration: {
          singular: true,
          sandbox: false,
        },
      },
      {
        onSuccess: () => {
          LoggerService.info(["Grafana root app loaded successfully"]);
          isRootAppLoaded.current = true;
        },
        onError: (err) => {
          if (action) {
            action({
              type: "SET_GRAFANA_ERROR",
              payload: err,
            });
          }
        },
      },
    );
  }, [action, containerRef, loadGrafanaApp, mode]);

  const updateThemeMode = useCallback(() => {
    if (!isRootAppLoaded.current) {
      return;
    }

    updateGrafanaApp({ mode });
  }, [mode, updateGrafanaApp]);

  return {
    load,
    updateThemeMode,
  };
};

export declare type GrafanaRootAppStatus =
  | "NOT_LOADED"
  | "LOADING_SOURCE_CODE"
  | "NOT_BOOTSTRAPPED"
  | "BOOTSTRAPPING"
  | "NOT_MOUNTED"
  | "MOUNTING"
  | "MOUNTED"
  | "UPDATING"
  | "UNMOUNTING"
  | "UNLOADING"
  | "SKIP_BECAUSE_BROKEN"
  | "LOAD_ERROR";

type CheckRootAppMountStatusOptions = {
  onSuccess?: () => void;
  onError?: (status?: GrafanaRootAppStatus) => void;
  isLoading?: () => void;
};

const LOADING_STATUS: GrafanaRootAppStatus[] = [
  "LOADING_SOURCE_CODE",
  "BOOTSTRAPPING",
  "UNMOUNTING",
  "UNLOADING",
  "MOUNTING",
  "UPDATING",
];

const BROKEN_STATUS: GrafanaRootAppStatus[] = [
  "NOT_MOUNTED",
  "SKIP_BECAUSE_BROKEN",
  "LOAD_ERROR",
  "NOT_LOADED",
  "NOT_BOOTSTRAPPED",
];

const DELAY = 3000;
const MAX_RETRY = 3;

/**
 *
 * Check if root grafana app is mounted otherwise try to reload it 3 times.
 */
export const useRootAppStatus = () => {
  const { grafanaUtils, grafanaProvider } = useGrafanaContext();

  const {
    palette: { mode },
  } = useTheme();

  const getAppStatus = useCallback(
    (
      callback: (
        app?: MicroApp,
        appStatus?: GrafanaRootAppStatus,
      ) => Promise<void>,
    ) => {
      if (grafanaUtils?.appData?.app) {
        const { app } = grafanaUtils.appData;

        grafanaUtils.log("Grafana Root App Status", app?.getStatus());
        callback(app, app?.getStatus());
      }
    },
    [grafanaUtils],
  );

  const checkRootAppMountStatus = useCallback(
    (
      {
        onSuccess = noop,
        onError = noop,
        isLoading = noop,
      }: CheckRootAppMountStatusOptions,
      retry = 0,
    ) => {
      const waitAndRetry = async () => {
        await new Promise((resolve) => {
          setTimeout(resolve, DELAY);
        });

        if (retry > MAX_RETRY) {
          LoggerService.error(
            "Grafana root app is not mounted",
            `Retry: ${retry}`,
          );
          onError();

          return;
        }

        checkRootAppMountStatus({ onSuccess, onError }, retry + 1);
      };

      getAppStatus(async (_, status) => {
        if (BROKEN_STATUS.includes(status!)) {
          LoggerService.info("Grafana root app is not mounted", status);

          grafanaUtils?.unload();

          if (grafanaProvider) {
            LoggerService.info("Re-trying to load grafana root app");
            const newRootAppDiv = document.createElement("div");
            newRootAppDiv.id = GRAFANA_PROVIDER_VIEWPORT_ID;
            newRootAppDiv.hidden = true;
            document.getElementById("root")?.appendChild(newRootAppDiv);

            grafanaUtils?.load({
              ...grafanaProvider,
              container: newRootAppDiv,
              props: {
                mode,
              },
            });
            isLoading();
            await waitAndRetry();
          } else {
            LoggerService.warn("Grafana provider is missing");
            onError(status);
          }

          return;
        }

        if (LOADING_STATUS.includes(status!)) {
          LoggerService.info("Grafana root app is loading", status);
          isLoading();

          await waitAndRetry();

          return;
        }

        if (status === "MOUNTED") {
          LoggerService.info("Grafana root app is already mounted");

          onSuccess();
        }
      });
    },
    [getAppStatus, grafanaProvider, grafanaUtils, mode],
  );

  return { getAppStatus, checkRootAppMountStatus };
};

export declare type FNGrafanaStartupState = {
  isLoading: boolean;
  isError: boolean;
  error?: unknown;
  isIdeal: boolean;
};

export const DefaultGrafanaStartupState: FNGrafanaStartupState = {
  isLoading: false,
  isError: false,
  isIdeal: true,
};

// Not in use right now
export const useConfirmGrafanaInitialization = () => {
  const [grafanaStatus, setIsGrafanaInitialized] =
    useState<FNGrafanaStartupState>(DefaultGrafanaStartupState);

  useEffect(() => {
    window.addEventListener("grafana-startup", (e) => {
      const { detail } = e as CustomEvent<FNGrafanaStartupState>;

      LoggerService.info("grafana-startup", detail);

      setIsGrafanaInitialized((prev) => ({
        ...prev,
        ...detail,
      }));
    });

    return () => {
      window.removeEventListener("grafana-startup", noop);
    };
  }, []);

  return {
    ...grafanaStatus,
    isLoading: grafanaStatus.isLoading || grafanaStatus.isIdeal,
  };
};
