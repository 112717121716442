import { useCallback, useState, useEffect, useMemo } from "react";
import { useQuery, useQueryClient, type UseQueryOptions } from "react-query";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useRecoilValue } from "recoil";

import { ENV } from "#shared/env";
import { type HttpState } from "#shared/hooks";
import { userNewState } from "#shared/recoil";
import { LoggerService } from "#shared/services";
import type { Organization, ProvisioningStatusText } from "#shared/types";
import {
  FEATURE_FLAGS,
  defaultExponentialBackOffDelay,
  exponentialBackoff,
} from "#shared/utils";
import { httpClient } from "#shared/utils/http-client";

import { API_URLS, QUERY_KEYS } from "#global/consts";

import type { CardProps } from "../components/card/card";

export type CheckProvisioningStatusBody = {
  organization: { domain_name: string };
};

export type CheckProvisioningStatusResponse = {
  provisioning_status: ProvisioningStatusText;
  id?: string;
  error?: string;
};

export type UseCheckProvisioningStatus = {
  checkProvisioningStatusAtInterval: () => void;
  httpState: HttpState;
  isPolling: boolean;
  result: CheckProvisioningStatusResponse | null;
};

type GetInterval = (count: number) => number;

const DEFAULT_GET_INTERVAL: GetInterval = exponentialBackoff(
  defaultExponentialBackOffDelay(ENV.MAX_CHECK_PROVISIONING_BACKOFF_MS),
);

export function useCheckProvisioningStatusPoll(
  domainName: string,
  getInterval: GetInterval = DEFAULT_GET_INTERVAL,
  queryOptions: UseQueryOptions<
    CheckProvisioningStatusResponse,
    CheckProvisioningStatusResponse
  > = {},
): UseCheckProvisioningStatus {
  const [isQueryEnabled, setIsQueryEnabled] = useState(false);

  const queryKey = useMemo(
    () => [QUERY_KEYS.CHECK_PROVISIONING_STATUS, domainName],
    [domainName],
  );

  const queryFn = useCallback(async () => {
    if (!domainName) {
      return Promise.reject(new Error("Domain name is required."));
    }

    return httpClient.post<CheckProvisioningStatusResponse, true>({
      url: API_URLS.ORGANIZATIONS.PROVISIONING_STATUS,
      body: {
        organization: {
          domain_name: domainName,
        },
      },
      shouldParse: true,
    });
  }, [domainName]);

  const checkStatus = useQuery<
    CheckProvisioningStatusResponse,
    CheckProvisioningStatusResponse
  >({
    queryKey,
    queryFn,
    retryOnMount: true,
    enabled: isQueryEnabled,
    retry: true,
    refetchInterval: (response, { state: { dataUpdateCount } }) => {
      if (
        response?.provisioning_status === "PROVISIONED" ||
        response?.provisioning_status === "FAILED" ||
        response?.error
      ) {
        setIsQueryEnabled(false);

        LoggerService.debug(
          "Check provisioning status.",
          { provisioningStatus: response?.provisioning_status },
          "Query disabled.",
        );

        return false;
      }

      return getInterval(dataUpdateCount);
    },
    retryDelay: getInterval,
    ...queryOptions,
  });

  const queryClient = useQueryClient();

  const provisioningStatus = useMemo(
    () => checkStatus.data?.provisioning_status,
    [checkStatus.data?.provisioning_status],
  );

  useEffect(() => {
    if (
      provisioningStatus === "PROVISIONED" ||
      provisioningStatus === "FAILED" ||
      checkStatus.error
    ) {
      setIsQueryEnabled(false);
    }
  }, [provisioningStatus, checkStatus.error]);

  const checkProvisioningStatusAtInterval = useCallback<
    UseCheckProvisioningStatus["checkProvisioningStatusAtInterval"]
  >(() => {
    if (!domainName) {
      LoggerService.error(
        null,
        "Check provisioning status.",
        "Missing domainName.",
      );

      return;
    }

    if (
      provisioningStatus === "PROVISIONED" ||
      provisioningStatus === "FAILED" ||
      checkStatus.error
    ) {
      LoggerService.debug("Check provisioning status.", "Disable query.", {
        provisioningStatus,
      });

      setIsQueryEnabled(false);

      return;
    }

    if (isQueryEnabled) {
      LoggerService.debug(
        "Check provisioning status.",
        "Query is already enabled.",
      );

      return;
    }

    LoggerService.debug("Check provisioning status.", "Enable query.", {
      domainName,
    });

    setIsQueryEnabled(() => {
      queryClient.invalidateQueries(queryKey);

      return true;
    });
  }, [
    domainName,
    checkStatus.error,
    provisioningStatus,
    isQueryEnabled,
    queryClient,
    queryKey,
  ]);

  const isPolling = useMemo(
    () =>
      isQueryEnabled &&
      (checkStatus.isLoading ||
        checkStatus.isFetching ||
        checkStatus.data?.provisioning_status === "PROVISIONING"),
    [
      isQueryEnabled,
      checkStatus.isLoading,
      checkStatus.isFetching,
      checkStatus.data?.provisioning_status,
    ],
  );

  return useMemo<UseCheckProvisioningStatus>(
    () => ({
      httpState: {
        isLoading: checkStatus.isLoading,
        isIdle: checkStatus.isIdle,
        isSuccess: checkStatus.isSuccess,
        error: checkStatus.error,
      },
      checkProvisioningStatusAtInterval,
      isPolling,
      result: checkStatus.data
        ? {
            ...checkStatus.data,
            provisioning_status:
              checkStatus.data?.provisioning_status || provisioningStatus,
          }
        : null,
    }),
    [
      checkStatus.isLoading,
      checkStatus.isIdle,
      checkStatus.isSuccess,
      checkStatus.error,
      checkStatus.data,
      checkProvisioningStatusAtInterval,
      isPolling,
      provisioningStatus,
    ],
  );
}

type CheckProvisioningWSResponse = {
  provisioning_status: ProvisioningStatusText | null;
};

export const useCheckProvisioningStatusWS = (
  organizationDomain: Organization["domain_name"],
  callbacks: {
    onMessage: (
      message: CheckProvisioningWSResponse["provisioning_status"],
    ) => void;
    onError?: (e: Event) => void;
  },
  enabled = true,
) => {
  const firebaseToken = useRecoilValue(userNewState.firebaseToken);

  const { sendJsonMessage, lastJsonMessage, getWebSocket, readyState } =
    useWebSocket<CheckProvisioningWSResponse>(
      API_URLS.ORGANIZATIONS.WS_WORKFLOW_STATUS,
      {
        onOpen: () => {
          LoggerService.info("Websocket opened", organizationDomain);

          sendJsonMessage({
            token: firebaseToken,
            workflowID: `create-organization-${organizationDomain}`,
          });
        },
        onError: (e) => {
          LoggerService.error("Error in websocket", e);

          callbacks.onError?.(e);
        },
        onMessage: (e) => {
          LoggerService.info("Message received", e.data);

          try {
            callbacks.onMessage(JSON.parse(e.data).provisioning_status);
          } catch (err) {
            LoggerService.info("Failed to parse message", e);
          }
        },
        onClose: () => {
          LoggerService.info("Websocket closed", organizationDomain);
        },
      },
      !!firebaseToken && enabled,
    );

  useEffect(() => {
    const status = lastJsonMessage?.provisioning_status;

    if (
      status === "PROVISIONED" ||
      status === "FAILED" ||
      status === "UNKNOWN"
    ) {
      LoggerService.debug(
        `Organization provisioning status: ${status}, closing websocket`,
        organizationDomain,
      );

      getWebSocket()?.close();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage]);

  // Cleanup ws
  useEffect(
    () => () => {
      getWebSocket()?.close();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return { getWebSocket, readyState, state: getWsState(readyState) };
};

export const getWsState = (readyState: ReadyState) =>
  (
    ({
      [ReadyState.CONNECTING]: "Connecting",
      [ReadyState.OPEN]: "Open",
      [ReadyState.CLOSING]: "Closing",
      [ReadyState.CLOSED]: "Closed",
      [ReadyState.UNINSTANTIATED]: "Uninstantiated",
    }) as const
  )[readyState];

export const useCheckProvisioningStatus = (
  organization: Pick<
    CardProps["organization"],
    "domain_name" | "provisioning_status"
  >,
  enabled = true,
) => {
  const [provisioningStatus, setProvisioningStatus] =
    useState<ProvisioningStatusText | null>(organization.provisioning_status);

  useCheckProvisioningStatusWS(
    organization.domain_name,
    {
      onMessage: (newProvisioningStatus) => {
        if (newProvisioningStatus === "UNKNOWN") {
          // Something went wrong, fallback to polling
          startPolling();
        } else {
          setProvisioningStatus(newProvisioningStatus);
        }
      },
      onError: (e) => {
        LoggerService.debug(
          "Websocket check failed; falling back to polling",
          e,
        );

        /* Fallback to polling */
        startPolling();
      },
    },
    ENV.VITE_APP_DOMAIN !== "localfluxninja.com" &&
      enabled &&
      FEATURE_FLAGS.wsOrganizationPolling &&
      organization.provisioning_status !== "PROVISIONED",
  );

  /* Used as fallback */
  const checkProvisioningStatus = useCheckProvisioningStatusPoll(
    organization.domain_name,
  );

  const startPolling = () => {
    if (
      checkProvisioningStatus.isPolling ||
      organization.provisioning_status === "PROVISIONED" ||
      !enabled
    ) {
      return;
    }

    LoggerService.debug(
      "Check provisioning status at interval",
      organization.domain_name,
    );

    checkProvisioningStatus.checkProvisioningStatusAtInterval();
  };

  useEffect(() => {
    /* Websocket endpoint can't be accessed if we're doing local ui dev as /etc/hosts doesn't support the ws protocol */
    if (
      (ENV.VITE_APP_DOMAIN === "localfluxninja.com" ||
        !FEATURE_FLAGS.wsOrganizationPolling) &&
      enabled
    ) {
      startPolling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  useEffect(() => {
    if (
      checkProvisioningStatus.httpState.isSuccess ||
      checkProvisioningStatus.isPolling
    ) {
      setProvisioningStatus(
        checkProvisioningStatus.result?.provisioning_status || null,
      );
    }
  }, [
    checkProvisioningStatus.httpState.isSuccess,
    checkProvisioningStatus.isPolling,
    checkProvisioningStatus.result?.provisioning_status,
  ]);

  return {
    provisioningStatus,
    setProvisioningStatus,
    hasError: !!checkProvisioningStatus.httpState.error,
  };
};
