import { Add, DeleteOutline } from "@mui/icons-material";
import { MenuItem, Select, Button, Box } from "@mui/material";
import React, { type FC, useEffect, useMemo } from "react";
import type { UseFormRegister } from "react-hook-form";

import { Input } from "#shared/components/inputs/input";
import { EMAIL_PATTERN, EMAIL_REGEXP } from "#shared/consts";
import { userRoleEnTexts, type UserRole, PermissionEnum } from "#shared/recoil";
import { LoggerService } from "#shared/services";
import type { Texts } from "#shared/types";

import {
  InviteUserResultIcon,
  type PartialInvitedUserByEmailResponse,
} from "#organization/pages/authenticated/common/use-invite-members";

import { ListItem } from "./styled";

import type { Invitation, UseInvite } from "../../hooks";

export interface InviteMemberItemProps extends Invitation {
  texts?: Texts<Text>;
  index: number;
  isSendingInvitations: boolean;
  invitationResponse: PartialInvitedUserByEmailResponse | null;
  register: UseFormRegister<Record<string, string>>;
  invite: UseInvite;
}

type Text =
  | "remove"
  | "addAnother"
  | "emailPlaceholder"
  | "invalidEmail"
  | UserRole;

const enText: Texts<Text> = {
  remove: "Delete",
  addAnother: "Add another",
  emailPlaceholder: "Email address",
  invalidEmail: "Please enter a valid email address",
  ...userRoleEnTexts,
};

export const InviteMemberItem: FC<InviteMemberItemProps> = ({
  texts = enText,
  index,
  email,
  isValid,
  userRole,
  register,
  invitationResponse,
  invite,
  isSendingInvitations,
}) => {
  const {
    isSomeInviteEmpty,
    invitationsCount,
    addInvite,
    removeInvite,
    onInviteEmailChange,
    onInviteErrorChange,
    onInviteRoleChange,
    isInviteSuccess,
  } = invite;

  useEffect(() => {
    if (isInviteSuccess && !invitationResponse) {
      LoggerService.warn(
        "InviteMemberItem",
        "Neither success nor error",
        email,
      );
    }
  }, [isInviteSuccess, invitationResponse, email]);

  const isLastInvitation = useMemo(
    () => index === invitationsCount - 1,
    [invitationsCount, index],
  );

  const disabled = !!(isSendingInvitations || invitationResponse?.success);
  const isDeleteButton = (index || email) && !invitationResponse?.success;

  return (
    <ListItem>
      <Input
        value={email}
        size="small"
        fullWidth
        width="auto"
        type="email"
        error={!isValid}
        helperText={isValid ? null : texts.invalidEmail}
        autoFocus={isLastInvitation}
        disabled={disabled}
        placeholder={texts.emailPlaceholder}
        {...register(["email", index].join("-"), {
          required: texts.invalidEmail,
          pattern: EMAIL_PATTERN,
          onChange: ({ currentTarget: { value } }) => {
            onInviteEmailChange(index, value);
          },
          onBlur: ({ currentTarget: { value } }) => {
            onInviteErrorChange(index, !EMAIL_REGEXP.test(value));
          },
        })}
      />
      <Select
        value={userRole}
        size="small"
        onChange={({ target: { value } }) => {
          onInviteRoleChange(index, value);
        }}
        disabled={disabled}
      >
        <MenuItem value={PermissionEnum.user_group_member}>
          {texts.user_group_member}
        </MenuItem>
        <MenuItem value={PermissionEnum.user_group_admin}>
          {texts.user_group_admin}
        </MenuItem>
        <MenuItem value={PermissionEnum.owner}>{texts.owner}</MenuItem>
      </Select>

      {isDeleteButton ? (
        <Button
          startIcon={<DeleteOutline />}
          size="small"
          onClick={() => {
            removeInvite(index);
          }}
          disabled={disabled}
        >
          {texts.remove}
        </Button>
      ) : null}
      {invitationResponse ? (
        <InviteUserResultIcon
          isSuccess={!!invitationResponse?.success}
          isError={!!invitationResponse?.error}
          containerProps={{ sx: { alignSelf: "center" } }}
        />
      ) : null}

      {!isDeleteButton && !invitationResponse ? <Box width={0} /> : null}

      {isLastInvitation && !isSomeInviteEmpty && !invitationResponse ? (
        <Button
          size="small"
          onClick={addInvite}
          startIcon={<Add />}
          sx={{ paddingLeft: 0, justifyContent: "flex-start" }}
          disabled={disabled}
        >
          {texts.addAnother}
        </Button>
      ) : null}
    </ListItem>
  );
};
