import { Box } from "@mui/material";
import React, { type FC } from "react";

import { BtnWrapper, s } from "./style-components";

import type { FlowChartButtonProps } from "../flow-chart-types";

export const FlowChartButtons: FC<FlowChartButtonProps> = ({
  btnLeftClick,
  btnRightClick,
  wrpLftElement,
  wrpRgtElement,
  btnStyle,
  clickStatus,
  zoomBtn,
}) => (
  <Box display="flex">
    <BtnWrapper
      sx={
        !clickStatus && !zoomBtn
          ? { ...s.leftButton, ...btnStyle }
          : { ...s.leftButton, ...btnStyle }
      }
      onClick={btnLeftClick}
    >
      {wrpLftElement}
    </BtnWrapper>

    <BtnWrapper
      sx={
        clickStatus && !zoomBtn
          ? { ...s.rightButton, ...btnStyle }
          : { ...s.rightButton, ...btnStyle }
      }
      onClick={btnRightClick}
    >
      {wrpRgtElement}
    </BtnWrapper>
  </Box>
);
