import { Check, UnfoldMore } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Grow,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import React, { type FC, useEffect, useRef, useState, useMemo } from "react";
import { useRecoilState } from "recoil";

import {
  HealthStatus,
  statusIconColors,
} from "#shared/components/data-grid/components/health-status";
import { useControlDialog } from "#shared/components/layouts/dialog";
import { styledBadge } from "#shared/components/top-bar-badge/styled";

import { CustomTooltip } from "#organization/components/custom-tooltip";
import { projectContextState } from "#organization/recoil/project";
import { useIsLarge } from "#organization/recoil/sidebar";

import { ConfirmProjectChangeDialog } from "./components/confirm-project-change";
import { useGetProjectDetails } from "./hooks";

import {
  darkPalette,
  lightPalette,
} from "../../../../../../../app-theme-provider/navigation/palette";
import { useThemeState } from "../../../../../recoil/theme";

const DIALOG_REMINDER_LOCAL_STORAGE_KEY = "dont-show-project-change-dialog";

export const ProjectSelectorSidebar: FC = () => {
  const isSidebarLarge = useIsLarge();
  const [projectContext, setProjectContext] =
    useRecoilState(projectContextState);

  const [isSelectorOpen, setSelectorOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { mode } = useThemeState();

  const projectDetails = useGetProjectDetails();
  const orderedProjectDetails = useMemo(() => {
    if (!projectDetails.data) {
      return [];
    }

    const currentProject = projectDetails.data.find(
      (project) => project.id === projectContext.id,
    );

    if (!currentProject) {
      return projectDetails.data;
    }

    const otherProjects = projectDetails.data.filter(
      (project) => project.id !== projectContext.id,
    );

    return [currentProject, ...otherProjects];
  }, [projectContext.id, projectDetails.data]);

  const handleProjectSelectorOpen = () => {
    setSelectorOpen((prevOpen) => !prevOpen);
  };

  const handleProjectSelectorClose = () => {
    setSelectorOpen(false);
  };

  useEffect(() => {
    if (isSelectorOpen) {
      projectDetails.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectorOpen]);

  const dontShowDialog = JSON.parse(
    (localStorage.getItem(DIALOG_REMINDER_LOCAL_STORAGE_KEY) || null)!,
  );

  const confirmDialog = useControlDialog();

  const [selectedProject, setSelectedProject] =
    React.useState<typeof projectContext>();

  const switchProject = () => {
    if (!selectedProject) {
      return;
    }

    setProjectContext(selectedProject);
    localStorage.setItem("projectId", selectedProject.id);
    localStorage.setItem("projectName", selectedProject.name);

    closeConfirmDialog();
  };

  const openConfirmDialog = () => {
    handleProjectSelectorClose();

    if (!dontShowDialog) {
      confirmDialog.open();
    } else {
      switchProject();
    }
  };

  const closeConfirmDialog = () => {
    confirmDialog.close();
    setSelectedProject(undefined);
  };

  useEffect(() => {
    if (selectedProject) {
      openConfirmDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  return (
    <>
      <Button
        variant="text"
        fullWidth
        sx={{ minWidth: 0, padding: 0, gap: "8px", textTransform: "none" }}
        ref={anchorRef}
        onClick={handleProjectSelectorOpen}
      >
        {isSidebarLarge ? (
          /* Expanded sidebar project switcher icon */
          <Box
            sx={(theme) => ({
              padding: theme.spacing(1),
              flex: 1,
              minWidth: 0,
            })}
          >
            <CustomTooltip title={projectContext.name} placement="top">
              <Box display="flex" gap="8px" alignItems="center">
                <Avatar
                  sx={{
                    borderRadius: "4px",
                    width: "36px",
                    height: "36px",
                  }}
                >
                  {projectContext.name.charAt(0)}
                </Avatar>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  flex={1}
                  sx={{ minWidth: 0 }}
                >
                  <Typography
                    color="secondary"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      width: "100%",
                      marginBottom: "-4px",
                      textAlign: "left",
                    }}
                    variant="body2"
                  >
                    {projectContext.name}
                  </Typography>
                  {orderedProjectDetails[0]?.status && (
                    <HealthStatus
                      status={orderedProjectDetails[0].status}
                      showIcon={false}
                    />
                  )}
                </Box>
                <UnfoldMore
                  sx={{
                    color: "text.primary",
                  }}
                />
              </Box>
            </CustomTooltip>
          </Box>
        ) : (
          /* Collapsed sidebar project switcher icon */
          <Box
            sx={(theme) => ({
              padding: theme.spacing(1.5),
            })}
          >
            <CustomTooltip
              title={
                <>
                  <Typography variant="body2" color="grey.400">
                    Current project
                  </Typography>
                  <Typography variant="body1">{projectContext.name}</Typography>
                </>
              }
              placement="top"
            >
              <Badge
                sx={styledBadge(
                  orderedProjectDetails[0]?.status
                    ? statusIconColors(orderedProjectDetails[0].status)
                    : "transparent",
                )}
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar
                  sx={{
                    borderRadius: "4px",
                    width: "30px",
                    height: "30px",
                  }}
                >
                  {projectContext.name.charAt(0)}
                </Avatar>
              </Badge>
            </CustomTooltip>
          </Box>
        )}
      </Button>

      {/* Project switcher popover */}
      <Box
        component={Popper}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [-10, 10],
            },
          },
        ]}
        sx={{
          zIndex: "modal",
        }}
        open={isSelectorOpen}
        anchorEl={anchorRef.current}
        placement="right-start"
      >
        <Grow
          in={isSelectorOpen}
          timeout={500}
          style={{ transformOrigin: "0 0 0" }}
        >
          <Paper elevation={4} sx={{ background: "none" }}>
            <ClickAwayListener onClickAway={handleProjectSelectorClose}>
              <List
                sx={{
                  bgcolor: (mode === "light" ? lightPalette : darkPalette)
                    .background.dropdown,
                  position: "relative",
                  overflowX: "hidden",
                  maxHeight: 300,
                  minWidth: 300,
                  borderRadius: 2,
                  "& ul": {
                    padding: 0,
                  },
                }}
                /* subheader={
                  <Box px={2} py={1.5}>
                    <Typography variant="body1">Projects</Typography>
                  </Box>
                } */
              >
                {orderedProjectDetails.map((project, index) => (
                  <ListItemButton
                    sx={{
                      "&.Mui-selected, &:hover": {
                        backgroundColor: "background.inputField",
                      },
                    }}
                    key={project.id}
                    selected={index === 0}
                    onClick={
                      index === 0
                        ? undefined
                        : () => setSelectedProject(project)
                    }
                  >
                    <ListItemText
                      sx={{
                        wordWrap: "break-word",
                        wordBreak: "break-all",
                        fontSize: "0.875rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "12px",
                      }}
                      disableTypography
                    >
                      <Avatar
                        sx={{
                          borderRadius: "4px",
                          width: "36px",
                          height: "36px",
                        }}
                      >
                        {project.name.charAt(0)}
                      </Avatar>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        flex={1}
                      >
                        <Typography variant="body2" color="secondary.main">
                          {project.name}
                        </Typography>
                        <Box display="flex" alignItems="center" gap="6px">
                          <HealthStatus
                            status={project.status}
                            showIcon={false}
                          />
                          •
                          <Typography variant="body2">
                            {project.region}
                          </Typography>
                        </Box>
                      </Box>
                      {index === 0 && <Check color="success" />}
                    </ListItemText>
                  </ListItemButton>
                ))}
              </List>
            </ClickAwayListener>
          </Paper>
        </Grow>
      </Box>

      {orderedProjectDetails.length > 1 && (
        <ConfirmProjectChangeDialog
          isOpen={confirmDialog.isOpen}
          close={closeConfirmDialog}
          onConfirm={switchProject}
          localStorageKey={DIALOG_REMINDER_LOCAL_STORAGE_KEY}
        />
      )}
    </>
  );
};
