import React from "react";

import { emptyColumnValue, type HeadCell } from "#shared/components/data-grid";

import { ArchivedStatus } from "#organization/components";
import { globalTimeParser } from "#organization/utils/utils";

import type { AlertSeverity } from "../../../../../../../../../../types";
import {
  AlertRowWrapper,
  AlertsCountBox,
  type RadiusType,
} from "../../components";
import type { AdaptedDiscoveryServicesGroupData } from "../hooks";

export const headCells = (): HeadCell<AdaptedDiscoveryServicesGroupData>[] => [
  {
    accessor: (row) => (
      <ArchivedStatus status={row.status}>
        {row.name || emptyColumnValue}
      </ArchivedStatus>
    ),
    label: "Service name",
    key: "name",
  },
  {
    accessor: "agentGroup",
    label: "Agent group",
    key: "agentGroup",
  },
  {
    accessor: "controller.name",
    label: "Controller",
    key: "controller.name",
  },
  {
    accessor: "controlPoints.totalCount",
    label: "Control points",
    key: "controlPointsAggregate.count",
  },
  {
    accessor: (row) => {
      const filtered = row.alertsCount?.filter((alert) => alert.count > 0);

      return (
        <AlertRowWrapper>
          {filtered
            ?.sort(
              (x, y) =>
                sortOrder.indexOf(x.severity) - sortOrder.indexOf(y.severity),
            )
            .map((alert, i) => {
              let radius: RadiusType;

              if (filtered.length === 1) {
                radius = "all";
              } else if (i === 0) {
                radius = "left";
              } else if (i === filtered.length - 1) {
                radius = "right";
              } else {
                radius = "none";
              }

              return (
                <AlertsCountBox
                  count={alert.count}
                  severity={alert.severity as AlertSeverity}
                  radius={radius}
                />
              );
            })}
        </AlertRowWrapper>
      );
    },
    label: "Alerts",
    key: "alertsCount",
  },
  {
    accessor: (row) => globalTimeParser(row.lastSeen),
    label: "Last seen",
    key: "lastSeen",
  },
];
const sortOrder = ["crit", "warn", "info"];
