import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { type FC, useEffect, useState } from "react";
import { useRecoilState } from "recoil";

import {
  FilteringGroup,
  useChipsContainer,
} from "#shared/components/filtering/filtering-group";
import { useParseLinkAttributesToFilterVariables } from "#shared/components/filtering/utils";
import { ServiceSelectColumn } from "#shared/generated/graphql";
import type { Texts } from "#shared/types";

import {
  DataGrid,
  DataGridGroups,
  DataGridWrapper,
  FiltersBarWrapper,
  GroupByButtonWrapper,
  GroupBySelect,
  type GroupByOption,
} from "#organization/components/data-grid";
import { projectContextState } from "#organization/recoil/project";

import {
  useDiscoveryServicesAllAndWithControlPoints,
  useDiscoveryServicesData,
  useDiscoveryServicesGroupData,
  useDiscoveryServicesGroupsTitles,
} from "./hooks";
import { headCells, discoveryServicesFilters } from "./table";

type Text =
  | "info"
  | "documentationButton"
  | "services"
  | "servicesWithControlPoints";

interface DiscoveryServicesTabProps {
  texts?: Texts<Text>;
}

const enTexts: Required<DiscoveryServicesTabProps["texts"]> = {
  info: "Below you can find a list of services discovered in your infrastructure by Aperture Agents. In order to view metrics and use Services in policies you need to install Control Points. ",
  documentationButton: "Learn how to use Services for Flow Control",
  services: "services",
  servicesWithControlPoints: "services with control points installed",
};

const GROUP_BYS: GroupByOption<ServiceSelectColumn>[] = [
  {
    value: "",
    title: "No value",
  },
  {
    title: "Service name",
    value: ServiceSelectColumn.Name,
  },
  {
    title: "Agent group",
    value: ServiceSelectColumn.AgentGroup,
  },
  {
    title: "Controller",
    value: ServiceSelectColumn.ControllerId,
  },
];

const INITIAL_ORDER_BY = "name";
const INITIAL_ORDER = "asc";
const TABLE_UNIQUE_ID = "discovery-services-table";

export const DiscoveryServicesTab: FC<DiscoveryServicesTabProps> = ({
  texts = enTexts,
}) => {
  const [projectContext] = useRecoilState(projectContextState);
  const servicesCounts = useDiscoveryServicesAllAndWithControlPoints(
    projectContext.id,
  );

  const headerTable = headCells();
  const chipsContainer = useChipsContainer();
  const filterHeaders = discoveryServicesFilters(projectContext.id);
  const [groupByKey, setGroupByKey] =
    useState<(typeof GROUP_BYS)[number]["value"]>("");

  const [filterVariables, setFilterVariables] = useState(
    useParseLinkAttributesToFilterVariables(TABLE_UNIQUE_ID),
  );

  // refetch servicesCounts when project context change
  useEffect(() => {
    servicesCounts.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectContext]);

  return (
    <Box px={3}>
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        rowGap={1}
        columnGap={3}
        paddingBottom={3}
      >
        <Typography variant="body1">{texts.info}</Typography>

        <Box display="grid" gridTemplateColumns="auto auto 1fr" columnGap={2}>
          <Typography component="span" variant="body1" color="text.secondary">
            {servicesCounts.services} {texts.services}
          </Typography>

          <Divider orientation="vertical" />

          <Typography variant="body1" component="span" color="text.secondary">
            {servicesCounts.servicesWithControlPoint}{" "}
            {texts.servicesWithControlPoints}{" "}
            {`(${servicesCounts.percentage}%)`}
          </Typography>
        </Box>
      </Box>

      <FiltersBarWrapper enableArchivedData>
        <FilteringGroup
          labels={filterHeaders}
          onFilter={setFilterVariables}
          chipsContainer={chipsContainer}
          uniqueId={TABLE_UNIQUE_ID}
        />
        <GroupByButtonWrapper>
          <GroupBySelect
            options={GROUP_BYS}
            setGroupByKey={setGroupByKey}
            groupByKey={groupByKey}
          />
        </GroupByButtonWrapper>
      </FiltersBarWrapper>

      <Box py={4}>
        <DataGridWrapper>
          {groupByKey ? (
            <DataGridGroups
              headCells={headerTable}
              useGroupsTitles={useDiscoveryServicesGroupsTitles}
              useGroupData={useDiscoveryServicesGroupData}
              groupByKey={groupByKey}
              filterVariables={filterVariables}
              initialOrderBy={INITIAL_ORDER_BY}
              initialOrder={INITIAL_ORDER}
              enableArchivedData
            />
          ) : (
            <DataGrid
              headCells={headerTable}
              useGridData={useDiscoveryServicesData}
              enabled={!groupByKey}
              filterVariables={filterVariables}
              initialOrderBy={INITIAL_ORDER_BY}
              initialOrder={INITIAL_ORDER}
              enableArchivedData
            />
          )}
        </DataGridWrapper>
      </Box>
    </Box>
  );
};
