import { Button, type ButtonProps, type Theme } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format, isFuture } from "date-fns";
import dayjs from "dayjs";
import React, {
  type CSSProperties,
  type FC,
  type FormEvent,
  useState,
} from "react";

import { useAlert } from "#shared/components/alerts-provider";
import { LoggerService } from "#shared/services";
import type { Texts } from "#shared/types";

import {
  useDataCenterContext,
  useSilentChannel,
  useUnmuteChannel,
} from "#organization/pages/authenticated/alert-manager/data-center";

import type { SilentChannelBoxProps } from "./silent-channel-box";
import {
  SilentChannelFormButtonWrapper,
  SilentChannelFormWrapper,
} from "./styled";

export interface SilentChannelFormProps
  extends Pick<
    SilentChannelBoxProps,
    "id" | "openCloseHook" | "silencedUntil"
  > {
  texts?: Texts<Required<SilentChannelFormText>>;
}

export type SilentChannelFormText =
  | "dateError"
  | "unmuteQueryError"
  | "unmuteQuerySuccess"
  | "muteQuerySuccess"
  | "muteQueryError"
  | "silentUntil"
  | "unmuteChannel"
  | "silentChannel"
  | "cancel";

const defaultTexts: Texts<Required<SilentChannelFormText>> = {
  dateError: "Silent until date must be a future date.",
  unmuteQueryError: "Error, channel is not unmuted.",
  unmuteQuerySuccess: "Channel has been unmuted",
  muteQueryError: "Error silencing channel",
  muteQuerySuccess: "Channel has been silenced",
  silentUntil: "Mute channel until",
  unmuteChannel: "Unmute channel",
  cancel: "Cancel",
  silentChannel: "Mute channel",
};

export const SilentChannelForm: FC<SilentChannelFormProps> = ({
  id,
  openCloseHook,
  silencedUntil,
  texts = defaultTexts,
}) => {
  const [selectedDate, setSelectedDate] = useState<string>(
    formatDateUtil(new Date().toString()),
  );
  const [, setOpenDialog] = openCloseHook;
  const { addAlert } = useAlert();
  const [dateFieldError, setDateFieldError] = useState<string | null>(null);

  const {
    reFetchFlag: { setFetchFlag },
  } = useDataCenterContext();

  const { silentChannel } = useSilentChannel();
  const { unmuteChannel } = useUnmuteChannel();

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (!isFuture(new Date(selectedDate))) {
      setDateFieldError(texts.dateError);

      return;
    }

    setDateFieldError(null);

    const silentMutationPayload = {
      id,
      silencedUntil: selectedDate,
    };

    silentChannel(silentMutationPayload, {
      onError: (err) => {
        addAlert({
          type: "error",
          message: texts.muteQueryError,
        });

        LoggerService.error(err);
      },
      onSuccess: () => {
        addAlert({
          type: "success",
          message: texts.muteQuerySuccess,
        });
        setOpenDialog(false);
        setFetchFlag(true);
      },
    });
  };

  const onChange = (d: string | null) => {
    if (!d) {
      return;
    }
    const newDate = formatDateUtil(d);

    setDateFieldError(null);

    const formattedDate = formatDateUtil(newDate);

    setSelectedDate(formattedDate);
  };

  const onCancel = () => {
    setOpenDialog(false);
  };

  const onClickUnmute = () => {
    unmuteChannel(
      { id },
      {
        onError: (err) => {
          addAlert({
            type: "error",
            message: texts.unmuteQueryError,
          });

          LoggerService.error(err);
        },
        onSuccess: () => {
          addAlert({
            type: "success",
            message: texts.unmuteQuerySuccess,
          });
          setOpenDialog(false);
          setFetchFlag(true);
        },
      },
    );
  };

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <SilentChannelFormWrapper>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label={texts.silentUntil}
            value={dayjs(selectedDate) as unknown as string}
            onChange={onChange}
            disablePast
            slotProps={{
              textField: {
                error: !!dateFieldError,
                helperText: dateFieldError,
                sx: { svg: { color: calenderIconColor }, width: "100%" },
              },
            }}
          />
        </LocalizationProvider>

        <SilentChannelFormButtonWrapper>
          <Button
            variant="text"
            onClick={onClickUnmute}
            disabled={!silencedUntil}
            {...commonButtonProps}
          >
            {texts.unmuteChannel}
          </Button>

          <SilentChannelFormButtonWrapper sx={modifyButtonWrapper}>
            <Button
              variant="outlined"
              onClick={onCancel}
              {...commonButtonProps}
            >
              {texts.cancel}
            </Button>
            <Button variant="contained" type="submit" {...commonButtonProps}>
              {texts.silentChannel}
            </Button>
          </SilentChannelFormButtonWrapper>
        </SilentChannelFormButtonWrapper>
      </SilentChannelFormWrapper>
    </form>
  );
};

export const formatDateUtil = (
  date: string,
  dateFormat: string = "yyyy-MM-dd'T'HH:mm:ssXXX",
): string => format(new Date(date), dateFormat);

const calenderIconColor = (theme: Theme) => theme.palette.text.primary;
const commonButtonProps: Pick<ButtonProps, "color" | "size"> = {
  color: "primary",
  size: "small",
};
const modifyButtonWrapper: CSSProperties = {
  width: "50%",
  justifyContent: "flex-end",
  gap: 1,
};
