import { useRecoilValue } from "recoil";

import { PermissionEnum, userNewState } from "#shared/recoil";

export const useUgMemberPermission = () => {
  const ugPermissions = useRecoilValue(userNewState.permissions);
  const userRole = useRecoilValue(userNewState.userRole)!;

  /* Permission restriction for users with a member role belonging to a user group with read only permissions */
  const permissionLock =
    PermissionEnum[userRole] <= PermissionEnum.user_group_member &&
    ugPermissions !== "write";

  return permissionLock;
};
