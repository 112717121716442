import { Box } from "@mui/material";
import React, { useState } from "react";
import { useRecoilState } from "recoil";

import type { FilterLabelsType } from "#shared/components/filtering";
import {
  FilteringGroup,
  useChipsContainer,
} from "#shared/components/filtering/filtering-group";
import { useParseLinkAttributesToFilterVariables } from "#shared/components/filtering/utils";
import { AgentSelectColumn } from "#shared/generated/graphql";

import {
  DataGrid,
  DataGridGroups,
  GroupBySelect,
  GroupByButtonWrapper,
  FiltersBarWrapper,
  DataGridWrapper,
  type GroupByOption,
} from "#organization/components/data-grid";
import { FlyoutMenu } from "#organization/components/flyout-menu";
import { MainPageLayout } from "#organization/components/layouts";
import { projectContextState } from "#organization/recoil/project";

import { AgentsFlyoutMenuWrapper } from "./flyout-menu";
import {
  useAgentsGroupData,
  useAgentsGroupsTitles,
  useAgentsData,
} from "./hooks";
import { agentsFilters, headCells } from "./table";
import type { AgentsFlyoutMenuConfig } from "./types";

const GROUP_BYS: GroupByOption<AgentSelectColumn>[] = [
  {
    title: "No value",
    value: "",
  },
  {
    title: "Agent group",
    value: AgentSelectColumn.AgentGroup,
  },
  {
    title: "Version",
    value: AgentSelectColumn.Version,
  },
  {
    title: "Agent key",
    value: AgentSelectColumn.AgentKeyId,
  },
  {
    title: "Agent status",
    value: AgentSelectColumn.Status,
  },
];
const INITIAL_ORDER_BY = "agentGroup";
const INITIAL_ORDER = "asc";
const TABLE_UNIQUE_ID = "agents-table";

export const AgentsPage = () => {
  const chipsContainer = useChipsContainer();
  const [projectContext] = useRecoilState(projectContextState);
  const filterHeaders: FilterLabelsType[] = agentsFilters(projectContext.id);
  const [filterVariables, setFilterVariables] = useState(
    useParseLinkAttributesToFilterVariables(TABLE_UNIQUE_ID),
  );

  const [groupByKey, setGroupByKey] =
    useState<(typeof GROUP_BYS)[number]["value"]>("");

  const [flyoutMenuVisibility, setFlyoutMenuVisibility] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState<AgentsFlyoutMenuConfig>({
    id: "",
    name: "",
    agentGroup: "",
    version: "",
    agentKey: "",
    status: "",
    lastSeen: "",
  });

  const headerTable = headCells(setFlyoutMenuVisibility, setSelectedAgent);

  return (
    <MainPageLayout>
      <FlyoutMenu useShowHide={[flyoutMenuVisibility, setFlyoutMenuVisibility]}>
        {flyoutMenuVisibility && (
          <AgentsFlyoutMenuWrapper
            agent={selectedAgent}
            hideFlyoutMenu={() => setFlyoutMenuVisibility(false)}
          />
        )}
      </FlyoutMenu>
      <FiltersBarWrapper enableArchivedData>
        <FilteringGroup
          labels={filterHeaders}
          onFilter={setFilterVariables}
          chipsContainer={chipsContainer}
          uniqueId={TABLE_UNIQUE_ID}
        />

        <GroupByButtonWrapper>
          <GroupBySelect
            options={GROUP_BYS}
            setGroupByKey={setGroupByKey}
            groupByKey={groupByKey}
          />
        </GroupByButtonWrapper>
      </FiltersBarWrapper>

      <Box py={4}>
        <DataGridWrapper>
          {groupByKey ? (
            <DataGridGroups
              headCells={headerTable}
              useGroupsTitles={useAgentsGroupsTitles}
              useGroupData={useAgentsGroupData}
              groupByKey={groupByKey}
              filterVariables={filterVariables}
              initialOrderBy={INITIAL_ORDER_BY}
              initialOrder={INITIAL_ORDER}
              enableArchivedData
            />
          ) : (
            <DataGrid
              headCells={headerTable}
              useGridData={useAgentsData}
              enabled={!groupByKey}
              filterVariables={filterVariables}
              initialOrderBy={INITIAL_ORDER_BY}
              initialOrder={INITIAL_ORDER}
              enableArchivedData
            />
          )}
        </DataGridWrapper>
      </Box>
    </MainPageLayout>
  );
};
