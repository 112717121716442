import type { Variables } from "graphql-request";
import {
  type Dispatch,
  type SetStateAction,
  type MouseEvent,
  useEffect,
  useMemo,
} from "react";
import { useQuery, type UseQueryResult } from "react-query";
import { useRecoilState } from "recoil";

import type { AnyObject, JSONObject } from "#shared/types";
import { gqlClient } from "#shared/utils/graphql-client";

import { QUERY_KEYS } from "#organization/pages/consts";
import { projectContextState } from "#organization/recoil/project";
import { adaptGqlResponse } from "#organization/utils";

import { createSortingObject } from "./data-grid";
import { useGraphqlPagination } from "./graphql-pagination";

export const useGroupsTitles = <T extends string, P>(
  entityKey: string,
  pageSize: number,
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  groupByKey: T,
  gql: string,
  gqlResponseMapFn: (node: P) => string | string[],
  filterVariables?: JSONObject,
  filterByProject: boolean = true,
  applyDistinctOn: boolean = true,
  modifyGroupTitlesResponse?: <R extends AnyObject = AnyObject>(
    response: R,
  ) => R,
  customFilterVariables?: JSONObject,
) => {
  const { paginationVariables, onPageChange, resetPagination } =
    useGraphqlPagination(setPage);
  const [projectContext] = useRecoilState(projectContextState);
  const variables: Variables = useMemo(
    () => ({
      where: {
        ...(filterByProject && { projectID: { eq: projectContext.id } }),
        ...filterVariables,
      },
      ...(applyDistinctOn && { distinctOn: [groupByKey] }),
      ...paginationVariables(pageSize),
      ...customFilterVariables,
    }),
    [
      customFilterVariables,
      applyDistinctOn,
      filterByProject,
      filterVariables,
      groupByKey,
      pageSize,
      paginationVariables,
      projectContext.id,
    ],
  );

  useEffect(() => {
    resetPagination();
  }, [filterVariables, filterByProject, resetPagination]);

  const query = useQuery(
    [
      QUERY_KEYS.GROUP_TITLES,
      entityKey,
      groupByKey,
      pageSize,
      page,
      JSON.stringify(filterVariables),
    ],
    () =>
      gqlClient
        .request<AnyObject, Variables>(gql, variables)
        .then((gqlResponse) =>
          adaptGqlResponse<P, string | string[]>(
            modifyGroupTitlesResponse
              ? modifyGroupTitlesResponse(gqlResponse[entityKey])
              : gqlResponse[entityKey],
            gqlResponseMapFn,
          ),
        ),
    {
      enabled: !!groupByKey,
    },
  );

  return {
    query,
    onPageChange: onPageChange(
      query?.data?.pageInfo || {
        startCursor: null,
        endCursor: null,
        hasNextPage: false,
        hasPreviousPage: false,
      },
    ),
  };
};

export type GroupsTitlesHook<T extends string> = (
  pageSize: number,
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  groupByKey: T,
  filterVariables: JSONObject,
) => {
  query: UseQueryResult;
  onPageChange: (
    _event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void;
  modifyTitle?: (title: string) => string | undefined | null;
  filterVariables?: JSONObject;
  filterByProject?: boolean;
  applyDistinctOn?: boolean;
  modifyGroupTitlesResponse?: <R extends AnyObject = AnyObject>(
    response: R,
  ) => R;
};

export const getGroupByVariables = <T extends string>(
  groupByKey: T,
  groupByValue: string,
  variablesMap: AnyObject = {},
) => {
  if (!variablesMap[groupByKey]) {
    return {
      [groupByKey]: {
        eq: groupByValue,
      },
    };
  }

  return variablesMap[groupByKey];
};

export const useGroupData = <T extends string, P, K>(
  entityKey: string,
  gql: string,
  pageSize: number,
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  order: string,
  orderBy: string,
  groupByKey: T,
  groupByValue: string,
  gqlResponseMapFn: (node: P) => K,
  filterVariables: JSONObject,
  groupByVariablesMap?: AnyObject,
  enabled = true,
  filterByProject: boolean = true,
  orderByTemplate?: (orderBy: string, order: string) => JSONObject,
  customFilterVariables?: JSONObject,
) => {
  const { paginationVariables, onPageChange, resetPagination } =
    useGraphqlPagination(setPage);
  const [projectContext] = useRecoilState(projectContextState);
  const variables: Variables = useMemo(() => {
    const orderByObject = orderByTemplate
      ? orderByTemplate(orderBy, order)
      : createSortingObject(orderBy, order);
    const useOrderBy = Object.keys(orderByObject).length > 0;

    return {
      where: {
        ...(filterByProject && { projectID: { eq: projectContext.id } }),
        ...getGroupByVariables(groupByKey, groupByValue, groupByVariablesMap),
        ...filterVariables,
      },
      ...(useOrderBy && { orderBy: orderByObject }),
      ...paginationVariables(pageSize),
      ...customFilterVariables,
    };
  }, [
    customFilterVariables,
    filterByProject,
    filterVariables,
    groupByKey,
    groupByValue,
    groupByVariablesMap,
    order,
    orderBy,
    orderByTemplate,
    pageSize,
    paginationVariables,
    projectContext.id,
  ]);

  useEffect(() => {
    resetPagination();
  }, [
    filterVariables,
    filterByProject,
    groupByKey,
    groupByValue,
    groupByVariablesMap,
    resetPagination,
  ]);

  const query = useQuery(
    [
      QUERY_KEYS.GROUP_DATA,
      entityKey,
      groupByValue,
      orderBy,
      order,
      pageSize,
      page,
      JSON.stringify(filterVariables),
      projectContext.id,
    ],
    () =>
      gqlClient
        .request<AnyObject, Variables>(gql, variables)
        .then((gqlResponse) =>
          adaptGqlResponse<P, K>(gqlResponse[entityKey], gqlResponseMapFn),
        ),
    { enabled },
  );

  return {
    query,
    onPageChange: onPageChange(
      query?.data?.pageInfo || {
        startCursor: null,
        endCursor: null,
        hasNextPage: false,
        hasPreviousPage: false,
      },
    ),
  };
};

export type GroupDataHook<T extends string> = (
  pageSize: number,
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  order: string,
  orderBy: string,
  groupByKey: T,
  groupByValue: string,
  filterVariables: JSONObject,
) => {
  query: UseQueryResult;
  onPageChange: (
    _event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void;
};
