import { Box } from "@mui/material";
import React, { type FC, useState, useMemo } from "react";
import { useRecoilState } from "recoil";

import type { FilterLabelsType } from "#shared/components/filtering";
import {
  FilteringGroup,
  useChipsContainer,
} from "#shared/components/filtering/filtering-group";
import { useParseLinkAttributesToFilterVariables } from "#shared/components/filtering/utils";
import { DeleteBoxProvider } from "#shared/components/pop-up/delete-confirm-box";
import {
  PolicyWithDefinitionSelectColumn,
  type PolicyWithDefinition,
} from "#shared/generated/graphql";

import {
  DataGridGroups,
  GroupBySelect,
  DataGrid,
  GroupByButtonWrapper,
  DataGridWrapper,
  FiltersBarWrapper,
  type GroupByOption,
} from "#organization/components/data-grid";
import { useHeaderSectionUpdate } from "#organization/recoil/organization/header-section";
import { projectContextState } from "#organization/recoil/project";

import { PoliciesPageTitle } from "./components/policies-page-title";
import { policiesFilters, headCells } from "./components/table";
import {
  usePoliciesData,
  usePoliciesGroupData,
  usePoliciesGroupsTitles,
  usePolicies,
} from "./hooks";
import { NoPolicies } from "./no-policies";

import { useUgMemberPermission } from "../settings/account/hooks";

const GROUP_BYS: GroupByOption<PolicyWithDefinitionSelectColumn>[] = [
  {
    title: "No value",
    value: "",
  },
  {
    title: "Origin",
    value: PolicyWithDefinitionSelectColumn.Origin,
  },
];

const INITIAL_ORDER_BY: keyof PolicyWithDefinition = "name";
const INITIAL_ORDER = "asc";
const TABLE_UNIQUE_ID = "policies-table";

export const PoliciesPage: FC = () => {
  const chipsContainer = useChipsContainer();
  const [projectContext] = useRecoilState(projectContextState);
  const filterHeaders: FilterLabelsType[] = policiesFilters(projectContext.id);
  const [filterVariables, setFilterVariables] = useState(
    useParseLinkAttributesToFilterVariables(TABLE_UNIQUE_ID),
  );

  const [groupByKey, setGroupByKey] =
    useState<(typeof GROUP_BYS)[number]["value"]>("");

  const { isData: isPolicies, isLoading } = usePolicies(projectContext.id);

  useHeaderSectionUpdate({
    pageTitle: useMemo(() => <PoliciesPageTitle />, []),
  });

  /* Members cannot see the actions table column if the user group does not have write permissions */
  const permissionLock = useUgMemberPermission();
  const headerTable = headCells(!permissionLock);

  if (!isLoading && !isPolicies) {
    return <NoPolicies />;
  }

  return (
    <DeleteBoxProvider>
      <Box px={3} id="fn-policies-container-outer">
        <FiltersBarWrapper enableArchivedData>
          <FilteringGroup
            labels={filterHeaders}
            onFilter={setFilterVariables}
            chipsContainer={chipsContainer}
            uniqueId={TABLE_UNIQUE_ID}
          />
          <GroupByButtonWrapper>
            <GroupBySelect
              options={GROUP_BYS}
              setGroupByKey={setGroupByKey}
              groupByKey={groupByKey}
            />
          </GroupByButtonWrapper>
        </FiltersBarWrapper>
        <Box py={4}>
          <DataGridWrapper>
            {groupByKey ? (
              <DataGridGroups
                headCells={headerTable}
                useGroupsTitles={usePoliciesGroupsTitles}
                useGroupData={usePoliciesGroupData}
                groupByKey={groupByKey}
                filterVariables={filterVariables}
                initialOrderBy={INITIAL_ORDER_BY}
                initialOrder={INITIAL_ORDER}
                enableArchivedData
              />
            ) : (
              <DataGrid
                headCells={headerTable}
                useGridData={usePoliciesData}
                enabled={!groupByKey}
                filterVariables={filterVariables}
                initialOrderBy={INITIAL_ORDER_BY}
                initialOrder={INITIAL_ORDER}
                enableArchivedData
              />
            )}
          </DataGridWrapper>
        </Box>
      </Box>
    </DeleteBoxProvider>
  );
};
