import { SvgIcon, type SvgIconProps } from "@mui/material";
import React, { forwardRef, type PropsWithRef, type FC } from "react";

export const PoliciesIcon: FC<PropsWithRef<SvgIconProps>> = forwardRef(
  (props, ref) => (
    <SvgIcon {...props} ref={ref}>
      <path d="M9.99977 16.3501C5.68977 16.3501 3.00977 14.1601 3.00977 10.6301C3.00977 6.78014 6.13977 3.64014 9.99977 3.64014C13.8598 3.64014 16.9898 6.77014 16.9898 10.6301C16.9898 14.1601 14.3098 16.3501 9.99977 16.3501V16.3501ZM9.99977 4.44014C6.58977 4.44014 3.80977 7.22014 3.80977 10.6301C3.80977 13.7101 6.11977 15.5501 9.99977 15.5501C13.8798 15.5501 16.1898 13.7101 16.1898 10.6301C16.1898 7.22014 13.4098 4.44014 9.99977 4.44014V4.44014Z" />
      <path d="M7.60998 10.8801C8.09599 10.8801 8.48998 10.4861 8.48998 10.0001C8.48998 9.51411 8.09599 9.12012 7.60998 9.12012C7.12397 9.12012 6.72998 9.51411 6.72998 10.0001C6.72998 10.4861 7.12397 10.8801 7.60998 10.8801Z" />
      <path d="M12.3898 10.8801C12.8758 10.8801 13.2698 10.4861 13.2698 10.0001C13.2698 9.51411 12.8758 9.12012 12.3898 9.12012C11.9038 9.12012 11.5098 9.51411 11.5098 10.0001C11.5098 10.4861 11.9038 10.8801 12.3898 10.8801Z" />
      <path d="M9.99982 13.2198C6.95982 13.2198 4.56982 11.8098 4.56982 9.99978C4.56982 8.18979 6.94982 6.77979 9.99982 6.77979C13.0498 6.77979 15.4298 8.18979 15.4298 9.99978C15.4298 11.8098 13.0498 13.2198 9.99982 13.2198ZM9.99982 7.57978C7.48982 7.57978 5.36982 8.68979 5.36982 9.99978C5.36982 11.3098 7.48982 12.4198 9.99982 12.4198C12.5098 12.4198 14.6298 11.3098 14.6298 9.99978C14.6298 8.68979 12.5098 7.57978 9.99982 7.57978V7.57978Z" />
      <path d="M10.0001 6.04035C9.7801 6.04035 9.6001 5.86035 9.6001 5.64035V2.01035C9.6001 1.79035 9.7801 1.61035 10.0001 1.61035C10.2201 1.61035 10.4001 1.79035 10.4001 2.01035V5.64035C10.4001 5.86035 10.2201 6.04035 10.0001 6.04035Z" />
      <path d="M4.6301 10.4802H1.0001C0.780098 10.4802 0.600098 10.3002 0.600098 10.0802C0.600098 9.86018 0.780098 9.68018 1.0001 9.68018H4.6301C4.8501 9.68018 5.0301 9.86018 5.0301 10.0802C5.0301 10.3002 4.8501 10.4802 4.6301 10.4802Z" />
      <path d="M10.0001 18.5501C9.7801 18.5501 9.6001 18.3701 9.6001 18.1501V14.5201C9.6001 14.3001 9.7801 14.1201 10.0001 14.1201C10.2201 14.1201 10.4001 14.3001 10.4001 14.5201V18.1501C10.4001 18.3701 10.2201 18.5501 10.0001 18.5501Z" />
      <path d="M19.0002 10.4802H15.3702C15.1502 10.4802 14.9702 10.3002 14.9702 10.0802C14.9702 9.86018 15.1502 9.68018 15.3702 9.68018H19.0002C19.2202 9.68018 19.4002 9.86018 19.4002 10.0802C19.4002 10.3002 19.2202 10.4802 19.0002 10.4802Z" />
      <path d="M11.82 2.2502H8.19004C7.97004 2.2502 7.79004 2.0702 7.79004 1.8502C7.79004 1.6302 7.97004 1.4502 8.19004 1.4502H11.82C12.04 1.4502 12.22 1.6302 12.22 1.8502C12.22 2.0702 12.04 2.2502 11.82 2.2502Z" />
    </SvgIcon>
  ),
);
